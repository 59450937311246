import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import { requestList,getDataFromUrl } from "../../../server/server";
import moment from "moment";
import { getCookie } from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SamplePDF from "../../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import Cookies from "universal-cookie/lib/Cookies";
import TextField from "../../../components/CustomInput/CustomTextField";



class MonthBranchSalSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "01-01-2021",
            todate: moment().format('DD-MM-YYYY'),
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "indentNo",
            year: moment().format('YYYY'),
            dataTable: null,
            ebid: "",
            dataTableList: null,


        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount() {
        var LeavesList = {
            "data": [], "filterTable": true, "defaultPageSize": 5, "showPaginationTop": false, "showPaginationBottom": true,
            "column": [{ "filterable": false, "Header": "EB ID", "accessor": "employeeid", "minWidth": 50, "style": { "textAlign": "center" }, "sortable": false },
            { "filterable": false, "Header": "Emp Name", "accessor": "workerName", "minWidth": 100, "style": { "textAlign": "center" }, "sortable": false },
            { "filterable": false, "Header": "Branch Name", "accessor": "brname", "minWidth": 50, "style": { "textAlign": "center" }, "sortable": false },
            { "filterable": false, "Header": "Amount", "accessor": "amount", "minWidth": 50, "style": { "textAlign": "center" }, "sortable": false },
            { "filterable": false, "Header": "From Date", "accessor": "fromDate", "minWidth": 50, "style": { "textAlign": "center" }, "sortable": false },
            { "filterable": false, "Header": "To Date", "accessor": "toDate", "minWidth": 50, "style": { "textAlign": "center" }, "sortable": false },
        ]
        };
        this.setState({
            dataTableList: LeavesList,
        });
        this.getReportsTable();
    }
    getReportsTable = () => {
        var datas = {
            "employeeid":this.state.ebid
        }
        this.handleLoading();
        requestList(serverApi.MONTH_BRANCH_SAL_SUMMARY, datas, this.handleReports)
    };
    handleReports = (resp) => {
        var dataTable=this.state.dataTableList
        if(resp){
            dataTable.data=resp 
        }
        if (dataTable !== null) {
            var columns = dataTable.column.map((prop, key) => {
                var obj = prop;
                obj.filterable = false;
                obj.sortable = false;
                // obj.minWidth = 100;
                // obj.maxWidth = 250;
                // delete obj.minWidth;
                // delete obj.maxWidth;
                return obj;
            });
        }
        dataTable.data.column = columns;
         console.log("columns", JSON.stringify(dataTable));

            this.setState({
                dataTable:dataTable,
                isRefresh: true,
            })
        this.handleLoading();

    };

    handleSeacrh = () => {
        var loading = false;
        if (!this.state.isRefresh)
            this.getReportsTable();
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    getDefaultUnitConvertion = (name) => {
        var val = "", label = "", data = "";
        if (name !== null) {
            if (name === 'LOOSE') {
                val = 1;
                label = "LOOSE";
            } else {
                val = 2;
                label = 'BALE';
            }
            data = {
                value: val, label: label
            };
        }
        return data;
    }
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }


    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "taskCode": 1011,
            "payloadSize": 2,
            "data": {
                "fromDate": this.state.fromDate,
                "toDate": this.state.todate
            },
            "cipher": getCookie(cons.CIPHER),
            "userId": getCookie(cons.USERID),
            "companyId": getCookie(cons.COMPANY_ID)
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

        // this.dateChanged();
    };
    dateChanged = () => {
        var myDate = this.state.date1;
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    }

    onChangeValues = (event) => {
        var value = event.target.value;
        if (event.target.id === "indentQuantity") {
            //   console.log("val "+this.state.indentQuantity);
            if (value > 0 && value <= 100) {
                this.setState({
                    selectedVehicleType: 3,
                    vehicleQuantity: 1,
                })

            } else if (value > 100 && value <= 120) {
                this.setState({
                    selectedVehicleType: 2,
                    vehicleQuantity: 1,
                })

            } else if (value > 120 && value <= 160) {
                this.setState({
                    selectedVehicleType: 1,
                    vehicleQuantity: 1,
                })

            } else if (value > 160 && value <= 200) {
                this.setState({
                    selectedVehicleType: 4,
                    vehicleQuantity: 1,
                })

            } else if (value > 200) {
                this.setState({
                    selectedVehicleType: 4,
                    vehicleQuantity: Math.round(value / 200),
                })
            } else if (value === '') {
                this.setState({
                    selectedVehicleType: "",
                    vehicleQuantity: "",
                })
            }
        }

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            selectedJuteType: "",
            selectedJuteName: "",
            selectedJuteQuality: "",
            selectedQualityName: "",
            quantity: "",
            stock: ""
        });
    }

    hideAlertMessage() {
        this.setState({
            canChangeMukam: true,
            canChangeUnit: true,
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            indentNo: "",
            unitConvertion: "",
            unitConvertionId: 0,
            indentQuantity: "",
            indentQuantityTon: "",
            indentBales: "",
            createdBy: "",
            createdOn: "",
            selectedVehicleType: "",
            vehicleQuantity: "",
            quantity: "",
            stock: "",
            selectedJuteQuality: "",
            selectedQualityName: "",
            selectedMukam: "",
            selectedTax: "",
            invoiceList: [],
            selectedJuteType: "",
            selectedJuteName: "",
            selectedIndentQuantity: "",
            sumOFQuantities: 0,
            selectedDeptId: "",
            selectedGrpId: "",
            selectedUnit: "",
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red" }}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };

    render() {
        const { classes } = this.props;
        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Employee Id
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <TextField
                                                                    id="ebid"
                                                                    className={classes.textField}
                                                                    onChange={this.onChangeValues}
                                                                    value={this.state.ebid}
                                                                    fullWidth={true}
                                                                    //iType={"number"}

                                                                />
                                                                {/* <Datetime
                                                                    //   closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        { height: "10px", inlineBlock: "none" }
                                                                    }

                                                                    inputProps={{
                                                                        id: "fromDate",
                                                                        value: this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                /> */}
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                {/* <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime

                                                                    //  closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}
                                                                    formControlProps={
                                                                        { height: "10px", inlineBlock: "none" }
                                                                    }
                                                                    inputProps={{
                                                                        id: "toDate",
                                                                        value: this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid> */}


                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                {/*<ItemGrid xs={12}>*/}
                                                {/*<IconButton*/}
                                                {/*onClick={this.handleCreate}*/}
                                                {/*color="success" right={true}>*/}
                                                {/*<ExpandMoreIcon/>*/}
                                                {/*</IconButton>*/}

                                                {/*</ItemGrid>*/}
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>
                                                    <br />
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                            style={{
                                                height: 2,
                                                backgroundColor: "#00acc1",
                                                marginTop: "5px"
                                            }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            {this.state.dataTable !== null && this.state.dataTable !== undefined ?
                                                <ReportsTableComponent
                                                    // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                                    staticData={this.state.dataTable}
                                                    //  searchData={this.getData()}
                                                    isRefresh={this.state.isRefresh}
                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                    // isLoading={this.state.isRefresh}
                                                    //  onActionClick={this.handleViewClick}
                                                    fileName={"Month_Branch_Sal_Summary"}
                                                    itemViewType={this.state.itemViewType} /> : null

                                            }

                                            {/*
                                            <ExportToExcel posts={this.state.dataTable.data}
                                                           dataTable={this.state.dataTable}
                                                           fileName={"Jute indent"}
                                                           sheetName={"Order1"}/>
*/}
                                            {/* <SamplePDF printDocument={this.printDocument.bind(this)} /> */}


                                            {/*
                                            <TableComponent
                                                url={serverApi.DATATABLE_SERVICE_URL + "/getAllIndent"}
                                                searchData={this.getData()}
                                                stat
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                printDoc={this.printDocument}
                                                actionType={VIEW}/>
*/}


                                        </ItemGrid>
                                        {/*<ItemGrid xs={12} sm={6}></ItemGrid>*/}
                                        {/*<ItemGrid xs={12} sm={4}><SamplePDF printDocument={this.printDocument}/></ItemGrid>*/}
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }

    printDocument = () => {
        var datas = {
            "taskCode": 1035,
            "payloadSize": 3,
            "data": {
                "fromDate": this.state.fromDate,
                "toDate": this.state.todate,
                "finYear": getCookie(cons.ACADEMIC_YEAR)
            },
            "companyId": getCookie(cons.COMPANY_ID)
        };
        this.handleLoading();
        requestList(serverApi.JUTE_MARKET_RATE, datas, this.handlePrintReports)
        //requestList(serverApi.REPORTS_JUTE_WITH_VALUE, data, this.handlePrintReports)
    };
    handlePrintReports = (resp) => {
        // console.log("reports",JSON.stringify(resp));
        if (resp.status) {
            // this.setState({
            //     dataTable:resp,
            //     isRefresh: true,
            // })
            var modules = resp.data.reportValues;
            var grandTotals = resp.data.totalValues;
            var companyName = resp.data.companyName;
        }
        console.log('modules', modules);
        var pageNo = 1;

        //var modules=[]

        var config = { printHeaders: true, autoSize: true, margins: { left: 0, top: 0, bottom: 0, width: 0 }, fontSize: 10 };
        const doc = new Jspdf('l', 'pt', 'l');
        doc.setFontSize(14);
        doc.text(300, 40, companyName);
        doc.setFontSize(10);
        var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 16);

        doc.text(700, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
        // doc.setFont("courier");
        // doc.setFontType("normal");
        doc.setFontSize(10);
        doc.text(350, 60, 'Report No.:[JUTE/01]');
        doc.text(270, 80, 'Market Rate Wise Report From  ' + this.state.fromDate + ' to ' + this.state.todate + '');
        doc.text(650, 80, 'Quantity in : Quintal');
        doc.setFontSize(9);
        doc.setFontType("bold");
        doc.cellInitialize();
        doc.cell(40, 100, 50, 30, 'sNo', '', 'center');
        doc.cell(141, 100, 50, 30, 'poDate', '', 'center');
        var OpeningWeight = doc.splitTextToSize('poNo', 38);
        doc.cell(172, 100, 85, 30, OpeningWeight, '', 'center');
        var OpeningBales = doc.splitTextToSize('supplier', 40);
        doc.cell(203, 100, 95, 30, OpeningBales, '', 'center');
        var OpeningDrums = doc.splitTextToSize('juteType', 38);
        doc.cell(203, 100, 70, 30, OpeningDrums, '', 'center');
        var ReceiptWeight = doc.splitTextToSize('quality', 38);
        doc.cell(203, 100, 50, 30, ReceiptWeight, '', 'center');
        var ReceiptBales = doc.splitTextToSize('poQty', 38);
        doc.cell(203, 100, 60, 30, ReceiptBales, '', 'center');
        var ReceiptDrums = doc.splitTextToSize('marketRate', 50);
        doc.cell(203, 100, 55, 30, ReceiptDrums, '', 'center');
        var IssuedWeight = doc.splitTextToSize('poRate', 38);
        doc.cell(203, 100, 50, 30, IssuedWeight, '', 'center');
        var IssuedBales = doc.splitTextToSize('saved', 38);
        doc.cell(203, 100, 50, 30, IssuedBales, '', 'center');
        var IssuedDrums = doc.splitTextToSize('deviation', 45);
        doc.cell(203, 100, 50, 30, IssuedDrums, '', 'center');
        var ClosingWeight = doc.splitTextToSize('deviationValue', 80);
        doc.cell(203, 100, 80, 30, ClosingWeight, '', 'center');
        // var ClosingBales = doc.splitTextToSize('ClosingBales', 38);
        // doc.cell(203, 100,50,30, ClosingBales,'','center');
        // var ClosingDrums = doc.splitTextToSize('Closing Drums', 38);
        // doc.cell(203, 100,50,30, ClosingDrums,'','center');


        // doc.cellInitialize();

        var cellWidths = ['', 50, 50, 85, 95, 70, 50, 60, 55, 50, 50, 50, 80];
        var cellTextaligns = ['', 'left', 'left', 'right', 'center', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k = 105;
            var i = 0;
            modules.map((prop, key) => {
                k = k + 25;
                i = i + 1;
                doc.cellInitialize();
                if (i === 17) {
                    k = 130;
                    i = 0;
                    pageNo = pageNo + 1;
                    var today = moment().format('DD/MM/YYYY H:mm:ss');
                    var date = moment().format('DD/MM/YYYY');
                    var name = getCookie(cons.USERID);
                    var newdat = " Printed on: " + today;
                    var printBy = " Printed By: " + name;
                    doc.text(40, 580, newdat);
                    doc.text(650, 580, printBy);
                    doc.addPage('a4', 'l');
                    doc.setFontSize(14);
                    doc.text(300, 40, companyName);
                    doc.setFontSize(10);
                    var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 16);

                    doc.text(700, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
                    // doc.setFont("courier");
                    // doc.setFontType("normal");
                    doc.setFontSize(10);
                    doc.text(350, 60, 'Report No.:[JUTE/03]');
                    doc.text(270, 80, 'Market Rate Wise Report From  ' + this.state.fromDate + ' to ' + this.state.todate + '');
                    doc.text(650, 80, 'Quantity in : Quintal');
                    doc.setFontSize(9);
                    doc.setFontType("bold");
                    doc.cellInitialize();
                    doc.cell(40, 100, 50, 30, 'sNo', '', 'center');
                    doc.cell(141, 100, 50, 30, 'poDate', '', 'center');
                    var OpeningWeight = doc.splitTextToSize('poNo', 38);
                    doc.cell(172, 100, 85, 30, OpeningWeight, '', 'center');
                    var OpeningBales = doc.splitTextToSize('supplier', 40);
                    doc.cell(203, 100, 95, 30, OpeningBales, '', 'center');
                    var OpeningDrums = doc.splitTextToSize('juteType', 38);
                    doc.cell(203, 100, 70, 30, OpeningDrums, '', 'center');
                    var ReceiptWeight = doc.splitTextToSize('quality', 38);
                    doc.cell(203, 100, 50, 30, ReceiptWeight, '', 'center');
                    var ReceiptBales = doc.splitTextToSize('poQty', 38);
                    doc.cell(203, 100, 60, 30, ReceiptBales, '', 'center');
                    var ReceiptDrums = doc.splitTextToSize('marketRate', 50);
                    doc.cell(203, 100, 55, 30, ReceiptDrums, '', 'center');
                    var IssuedWeight = doc.splitTextToSize('poRate', 38);
                    doc.cell(203, 100, 50, 30, IssuedWeight, '', 'center');
                    var IssuedBales = doc.splitTextToSize('saved', 38);
                    doc.cell(203, 100, 50, 30, IssuedBales, '', 'center');
                    var IssuedDrums = doc.splitTextToSize('deviation', 45);
                    doc.cell(203, 100, 50, 30, IssuedDrums, '', 'center');
                    var ClosingWeight = doc.splitTextToSize('deviationValue', 80);
                    doc.cell(203, 100, 80, 30, ClosingWeight, '', 'center');
                    doc.setFontSize(7);

                }
                var a = 0;
                return (
                    Object.keys(prop).map((key, index) => {
                        a = a + 1;
                        var myItem = doc.splitTextToSize(prop[key] + ' ', 85);
                        doc.setFontType("normal")
                        return (
                            doc.cell(40, k, cellWidths[a], 25, myItem, 2, cellTextaligns[a])
                        )
                    })

                )

            });

        }
        // var grandTotals={ "opBs":" ", "opHbs":" ","opDrm":" ","opWght":"5282.04","recBs":" ","recHbs":" ","recDrm":" ","recWght":" ","issBs":"11 ","issHbs":" ","issDrm":" ","issWght":"14.58","delBs":" ","delHbs":" ","delDrm":" ","delWght":" ","clsoeBs":"1251","clsoeHbs":" ","clsoeDrm":" ","clsoeWght":"1670.86"};

        doc.cellInitialize();
        doc.setFontType("bold");
        doc.setFontSize(10);
        doc.cell(40, k + 25, 400, 30, 'Grand Total', '', 'C');
        // // //Opening
        doc.setFontSize(8);
        doc.cell(110, k + 25, 60, 30, grandTotals.totalPOQty + ' ', '', 'right');
        doc.cell(141, k + 25, 55, 30, grandTotals.totalMarketRate + '', '', 'right');
        doc.cell(172, k + 25, 50, 30, grandTotals.totalPORate + ' ', '', 'right');
        doc.cell(203, k + 25, 50, 30, grandTotals.totalSaved + ' ', '', 'right');
        doc.cell(248, k + 25, 50, 30, grandTotals.totalDeviation + ' ', '', 'right');
        doc.cell(248, k + 25, 80, 30, grandTotals.totalDeviationValue + ' ', '', 'right');
        // doc.cell(203, k+25,50,30, grandTotals.issuedWeightTot + ' ','','right');
        // doc.cell(248, k+25,50,30, grandTotals.issuedbalesTot + ' ','','right');
        // doc.cell(248, k+25,50,30, grandTotals.issuedDrumTot + ' ','','right');
        // doc.cell(203, k+25,50,30, grandTotals.closingWeightTot + ' ','','right');
        // doc.cell(248, k+25,50,30, grandTotals.closingbalesTot + ' ','','right');
        // doc.cell(248, k+25,50,30, grandTotals.closingDrumTot + ' ','','right');




        doc.text(40, k + 130, 'Dept. Incharge');
        doc.text(650, k + 130, 'Authorised Signature');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today = moment().format('DD/MM/YYYY H:mm:ss');
        var name = getCookie(cons.USERID);
        var newdat = " Printed on: " + today;
        var printBy = " Printed By: " + name;
        doc.text(40, k + 170, newdat);
        doc.text(650, k + 170, printBy);
        doc.save('Market_Rate_Report.pdf');
        this.handleLoading();


    }


}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(MonthBranchSalSummary);
