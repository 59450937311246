import React from "react";
// @material-ui/icons
import defaultImage from "assets/img/default-avatar.png";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from "material-ui/Input/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import withStyles from "material-ui/styles/withStyles";
import {getDataFromUrl, getFileDownload, uploadFile, uploadProfilePic} from "../../server/server";
import {serverApi,serverConfig} from "../../helper/Consts";
import {getCookie, setCookie} from "../../helper/Cookies";
import cons from "../../helper/Consts";
import SweetAlert from "react-bootstrap-sweetalert";
import Popup from "../../components/Popup/PopUp";
import Button from "../../components/CustomButtons/Button";

/*const pieChart = {
    data: {
        labels: ["73%",""],
        series: [73,27]
    },
    options: {
        height: 100,
        width:100,
        color:"#fff",
        legendPosition: 'topLeft'
    }
};*/

class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            contactNo: '',
            emailID: '',
            portalRoleDesc: '',
            uploadInvoice: '',
            file:'',
            picUrl:null,
            imagePreviewUrl:''
        };
            this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    componentDidMount = () => {
        console.log("in component did mount");
        if(getCookie(cons.EB_ID)!==null&&getCookie(cons.EB_ID)!==undefined&&getCookie(cons.EB_ID)!=="null")
        getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"1/"+ getCookie(cons.EB_ID)+"/"+getCookie(cons.COMPANY_ID),this.handleProfileImage);
        getDataFromUrl(serverApi.GET_USER_DETAILS_BY_ID + getCookie(cons.USERID) + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
    };
    handleProfileImage=(resp)=>{
        console.log("profileimage resp",resp);
        if(resp.status){
            if(resp.data.length>0)
                getFileDownload(serverConfig.SERVER_URL +resp.data[0].retrievalPath,resp.data[0].fileName,resp.data[0].fileExtension,this.handleDownloadedFile);

        }
    };
    handleDownloadedFile=(url)=>{
        this.setState({imagePreviewUrl:url})
    };


    handleViewClickResp = (resp) => {
        var header = resp.data;
        //  var list = resp.data;
        //console.log("indentWeight "+header.indentWeight);
        if (header !== null) {
            this.setState({
                selectedApprole: header.appRole,
                portalRoleDesc: header.portalRoleDesc,
                userId: header.userId,
                userName: header.userName,
                profileName: header.profileName,
                contactNo: header.mobile,
                emailID: header.email,
                // activeStatus:this.getActiveStatus(header.status),
                activeStatusId: header.status,
                createdBy: header.createdBy,
                createdOn: header.createdOn,
              //  picUrl: header.picUrl,
              //  imagePreviewUrl:serverConfig.SERVER_URL + header.picUrl
            });

        }


    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        //  console.log("file type",file.type);
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG') {
                this.setState({
                    file: file,
                    fileName: file.name,
                    fileType:fileName[1],
                    //filesList: arr,
                    uploadPic: true,
                     imagePreviewUrl: reader.result
                });
                this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
            } else {
                this.basicAlert("File type you have selected can not be upload. Please select another file!!!")
            }


        };
        reader.readAsDataURL(file);
    }
    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert:false,
            basicAlert:null
        });
        window.location.reload();
    }

    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status){
                            this.hideAlert();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    UploadbasicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideUploadBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }



                />
            )
        });
    }

    hideUploadBasicAlert() {
        const {file,fileType,fileName}=this.state;
        if (this.state.uploadPic) {
            var id = getCookie(cons.USERID);
            uploadFile(file.name,file,fileType,1,fileName,getCookie(cons.EB_ID), this.handleAddImage);

           // uploadProfilePic(id, this.state.file, this.handleUploadResp);
            //  this.uploadInvoiceFile();
        }

        this.setState({
            basicAlert: null,
        });

    }
    handleAddImage=(resp)=>{
        console.log("respoiii",resp);
        if(resp.fileUrl!==null)
            this.showMessage(resp.message, false)
           // this.setState({imagePreviewUrl:resp.fileUrl})
        // this.setState({uploadFile:false})
        //this.showMessage(resp.message, false)

    };

    handleUploadResp = (resp)=>{
          console.log("resp",JSON.stringify(resp));
      let  reader='';
        if(resp.status){
            this.setState({
                uploadPic: false,
                picUrl:resp.data.url,
                basicAlert:null
            });
            setCookie(cons.USER_IMG, resp.data.url);
            this.showMessage(resp.message,resp.status);
         //   getDataFromUrl(serverApi.GET_USER_DETAILS_BY_ID + getCookie(cons.USERID) + "/" + getCookie(cons.USERID), this.handleViewClickResp)

        }else {
            this.setState({
                uploadPic: false,
               // picUrl:this.state.imagePreviewUrl,
                basicAlert:null
            });

        }

    };
    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            //imagePreviewUrl:serverConfig.SERVER_URL+this.state.picUrl
        });

    }

    render() {
        // const { classes } = this.props;
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                <ItemGrid xs={12} sm={12}>
                    <RegularCard
                        title="Security"
                        content={

                            <GridContainer>
                                {this.state.basicAlert}
                                {this.state.showMessagealert}
                                <ItemGrid xs={12} sm={12} md={12}>
                                    <legend style={{padding: 20}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} md={4} >
                                               {/* <ImageUpload btnTitle="Upload Image" avatar={true}/>*/}
                                                <div className="fileinput text-center">
                                                <div className={"thumbnail" + (" img-circle")}>

                                                <img
                                                    src={this.state.imagePreviewUrl!==null?this.state.imagePreviewUrl:defaultImage}
                                                    alt="..."
                                                    style={{width:150,height:150,color:"#FFF"}}
                                                />
                                                </div>
                                                    <div>
                                                    <Button color={"infoNoBackground"}
                                                            onClick={this.handleFileClick} style={{padding: 20}}>Upload
                                                        Photo</Button>

                                                </div>
                                                </div>
                                            </ItemGrid>

                                            <ItemGrid xs={12} sm={6} md={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12}>

                                                        <legend style={{paddingTop: 10}}>
                                              <span style={{fontSize: "16px"}}>Name : {this.state.userName}
                                                  <br/>
                                                 Role      : {this.state.portalRoleDesc}
                                                  <br/>

                                                  </span>
                                                        </legend>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={1} md={1}>
                                                       {/* <Avatar style={{
                                                            color: "#fff",
                                                            backgroundColor: "grey",
                                                            width: 30,
                                                            height: 30
                                                        }}>*/}
                                                            <Email style={{width: 20, height: 20}}/>
                                                       {/* </Avatar>*/}
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={11} md={11}>
                                                        <legend style={{fontSize: "16px"}}>
                                                            {this.state.emailID}

                                                        </legend>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={1} md={1}>
                                                       {/* <Avatar style={{
                                                            color: "#fff",
                                                            backgroundColor: "grey",
                                                            width: 30,
                                                            height: 30
                                                        }}>*/}
                                                            <Phone style={{width: 20, height: 20}}/>
                                                      {/*  </Avatar>*/}
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={10} md={10}>
                                                        <legend style={{fontSize: "16px"}}>
                                                            {this.state.contactNo}
                                                        </legend>
                                                    </ItemGrid>


                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={4} md={4}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={12} md={12}>
                                                        {/*<legend style={{fontSize: "16px"}}>*/}
                                                        {/*<Button variant="outlined" color="transparent" style={{marginLeft:"40px",marginBottom:"40px"}}>*/}
                                                        {/*View in Org Chart*/}
                                                        {/*</Button>*/}
                                                        {/*<br/>*/}
                                                        {/*<br/>*/}
                                                        {/*<br/>*/}
                                                        {/*<br/>*/}
                                                        {/*</legend>*/}
                                                    </ItemGrid>


                                                </GridContainer>
                                            </ItemGrid>


                                        </GridContainer>
                                    </legend>
                                </ItemGrid>
                                {/*<ItemGrid xs={12} sm={4} md={4}>*/}
                                {/*<legend style={{color:"#00ABDC" ,fontSize:"25px",fontFamily:"Bold"}}>SNAP SHOT*/}
                                {/*<GridContainer style={{backgroundColor: "#00ABDC"}}>*/}
                                {/*<ItemGrid xs={12} sm={12} md={12}>*/}
                                {/*<GridContainer>*/}
                                {/*<ItemGrid sm={10} md={10}>*/}
                                {/*<legend>    </legend>*/}
                                {/*</ItemGrid>*/}
                                {/*<ItemGrid sm={2} md={2}>*/}
                                {/*<IconButton style={{width:30,height:30,paddingBottom:50}}>*/}
                                {/*<ExpandMoreIcon style={{width:30,height:30,backgroundColor:"#fff",color:"#00ABDC"}}/>*/}
                                {/*</IconButton>*/}
                                {/*</ItemGrid>*/}
                                {/*</GridContainer>*/}
                                {/*</ItemGrid>*/}
                                {/*<ItemGrid xs={12} sm={12} md={12}>*/}
                                {/*<GridContainer>*/}
                                {/*<ItemGrid xs={12} sm={8} md={8}>*/}
                                {/*<PieChart*/}
                                {/*size={100}*/}
                                {/*labels*/}
                                {/*styles={{*/}
                                {/*'.chart_lines': {*/}
                                {/*strokeWidth: 1*/}
                                {/*},*/}
                                {/*'.chart_text': {*/}
                                {/*fontFamily: 'serif',*/}
                                {/*fontSize: '1.25em',*/}
                                {/*color: '#00ABDC',*/}
                                {/*}*/}
                                {/*}}*/}
                                {/*data={[ {key: '73%', value: 73, color: '#fff'},*/}
                                {/*{key: '', value: 27, color: '#dce7c5'},]}*/}
                                {/*/>*/}
                                {/*/!**/}
                                {/*<ChartistGraph*/}
                                {/*data={pieChart.data}*/}
                                {/*type="Pie"*/}
                                {/*options={pieChart.options}*/}
                                {/*/>*/}
                                {/**!/*/}
                                {/*<br/>*/}
                                {/*<legend style={{color:"#fff" ,fontSize:"16px",fontFamily:"Arial"}}>Attendance*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*</legend>*/}
                                {/*</ItemGrid>*/}
                                {/*<ItemGrid xs={12} sm={4} md={4}>*/}
                                {/*<legend style={{color:"#fff" ,fontSize:"16px",fontFamily:"Arial"}}>*/}
                                {/*<br/>*/}
                                {/*INR*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*2500*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*Expense*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*</legend>*/}
                                {/*</ItemGrid>*/}
                                {/*</GridContainer>*/}

                                {/*</ItemGrid>*/}

                                {/*</GridContainer>*/}

                                {/*</legend>*/}
                                {/*</ItemGrid>*/}
                                {/*<ItemGrid xs={12} sm={8} md={8} >*/}
                                {/*<div>*/}
                                {/*<legend style={{color:"#94BA33" ,fontSize:"25px",fontFamily:"Bold",marginLeft:"20px"}}>TASKS*/}
                                {/*<GridContainer  style={{backgroundColor: "#94BA33"}}>*/}
                                {/*<ItemGrid xs={12} sm={12} md={12}>*/}
                                {/*<GridContainer >*/}
                                {/*<ItemGrid sm={10} md={10}>*/}
                                {/*<legend>    </legend>*/}
                                {/*</ItemGrid>*/}
                                {/*<ItemGrid sm={2} md={2}>*/}
                                {/*<IconButton style={{width:30,height:30,paddingBottom:50}}>*/}
                                {/*<ExpandMoreIcon style={{width:30,height:30,backgroundColor:"#fff",color:"#94BA33"}}/>*/}
                                {/*</IconButton>*/}
                                {/*</ItemGrid>*/}
                                {/*</GridContainer>*/}
                                {/*</ItemGrid>*/}
                                {/*<ItemGrid xs={12} sm={8} md={8}>*/}
                                {/*<GridContainer style={{padding:"10px",spacing:16}}>*/}
                                {/*<ItemGrid xs={12}sm={8} style={{backgroundColor: "#fff"}}>*/}
                                {/*<legend style={{color:"#94BA33" ,fontSize:"16px"}}>*/}
                                {/*<br/>*/}
                                {/*23.04.2018*/}
                                {/*<br/>*/}
                                {/*<span style={{fontSize:"20px",paddingTop:"10px"}}> Check On 1325</span>*/}
                                {/*</legend>*/}

                                {/*</ItemGrid>*/}
                                {/*<ItemGrid xs={12}sm={8} style={{backgroundColor: "#fff",marginTop:"10px"}}>*/}
                                {/*<legend style={{color:"#94BA33" ,fontSize:"16px"}}> 23.04.2018*/}
                                {/*<br/>*/}
                                {/*<span style={{fontSize:"20px",paddingTop:"10px"}}> Check On 1325</span>*/}
                                {/*<br/>*/}
                                {/*<span style={{fontSize:"20px",paddingTop:"10px"}}> With person 1 and 2</span>*/}
                                {/*</legend>*/}
                                {/*</ItemGrid>*/}

                                {/*</GridContainer>*/}

                                {/*</ItemGrid>*/}


                                {/*</GridContainer>*/}


                                {/*</legend>*/}
                                {/*</div>*/}
                                {/*</ItemGrid>*/}


                            </GridContainer>
                        }


                    />
                </ItemGrid>
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...chartsStyle,
};
export default withStyles(extendedFormsStyle)(MyProfile);