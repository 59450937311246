import React from "react";
// react component for creating dynamic tables
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
	serverConfig,
	serverApi,
	getConfigValue,
	configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
	getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import { Check } from "@material-ui/icons";
import Checkbox from "material-ui/Checkbox/Checkbox";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { yesOrNo } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

class CustomerMaster extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			basicAlert: null,
			isCreated: false,
			fromDate: "",
			todate: "",
			status: "",
			isRefresh: false,
			isLoading: false,
			itemViewType: "id",
			approveStatus: false,
			name: "",
			contactPerson: "",
			contactNo: "",
			alternateContactNo: "",
			emailId: "",
			address: "",
			city: "",
			state: "",
			pincode: "",
			gstNo: "",
			panNo: "",
			cinNo: "",
			invoiceList: [],
			shippingAddress: "",
			ledgerGroup: "",
			billingStateCode: "",
			shippingStateCode: "",
			city2: "",
			state2: "",
			pincode2: "",
			gstNo2: "",
			openningBalance: "",
			itemsList: [],
			statesList: [],
			companyList: [],
			finalStatus: "N",
		};
		this.hideAlert = this.hideAlert.bind(this);
		this.hideAlertMessage = this.hideAlertMessage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeValues = this.onChangeValues.bind(this);
		this.handleAddItem = this.handleAddItem.bind(this);
	}

	handleSeacrh = () => {
		var loading = false;
		if (this.state.isRefresh) {
			loading = true;
		}
		this.setState({
			isRefresh: !this.state.isRefresh,
		});
	};

	handleViewClick = (id) => {
		// var data = this.state.dataTable;
		var juteId = "";
		console.log("jute  " + "  " + id);
		if (!getMenuPermission(0)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			getDataFromUrl(
				serverApi.CUSTOMER_MASTER_DETAILS_BY_ID +
					id +
					"/" +
					getCookie(cons.CIPHER),
				this.handleViewClickResp
			);
		}
	};

	handleViewClickResp = (resp) => {
		var o = resp.customerHdr;
		var list = [];
		if (o !== null) {
			if (resp.customerLineitems.length > 0) {
				resp.customerLineitems.forEach((prop, key) => {
					var obj = {
						id: key,
						mid: key,
						branchAddress: prop.address,
						city: prop.city,
						autoId: prop.customerId,
						customerLineId: prop.customerLineId,
						gstNo: prop.gstNo,
						isActive: prop.isActive,
						pinCode: prop.pincode,
						stateName: prop.state,
						stateCode: prop.stateCode,
					};
					list.push(obj);
				});
			}
			var branchArray = this.state.itemsList;
			branchArray.map((t, i) => {
				o.mappedBranchIds.forEach((id) => {
					if (t.bId === id) {
						t.isEnabled = true;
					}
				});
			});

			this.setState({
				updateView: true,
				isCreated: true,
				id: o.id,
				customerCode: o.customerCode,
				name: o.name,
				contactPerson: o.contactPerson,
				contactNo: o.contactNo,
				alternateContactNo: o.alternateContactNo,
				emailId: o.emailId,
				address: o.address,
				city: o.city,
				state: o.state,
				pincode: o.pincode,
				gstNo: o.gstNo,
				panNo: o.panNo,
				cinNo: o.cinNo,
				billingStateCode: o.billingStateCode,
				ledgerGroup: o.ledgerGroup,
				shippingAddress: o.shippingAddress,
				shippingStateCode: o.shippingStateCode,
				city2: o.city2,
				state2: o.state2,
				pincode2: o.pincode2,
				gstNo2: o.gstNo2,
				openningBalance: o.openningBalance,
				createdBy: o.createdBy,
				createdOn: o.createDateTime,
				itemsList: branchArray,
				internalCompanyId: o.internalCompanyId,
				finalStatus: o.finalStatus === "Y" ? "Y" : "N",
				invoiceList: list,
			});
		}
	};

	handleLoading = () => {
		this.setState({
			isLoading: !this.state.isLoading,
		});
	};

	handleCreate = () => {
		//alert();
		if (!this.state.isCreated) {
			this.hideAlertMessage();
		}
		if (this.state.isCreated) {
			this.setState({
				canChangeMukam: true,
				canChangeUnit: true,
			});
		}
		console.log(this.state);
		this.setState({
			isCreated: !this.state.isCreated,
		});
		//  this.hideAlertMessage();
	};
	getData = () => {
		var data = {
			companyId: getCookie(cons.COMPANY_ID),
			cipher: getCookie(cons.CIPHER),
		};
		return data;
	};

	handleChange = (name) => (event) => {
		this.setState({ [name]: Number(event.target.value) });
	};

	onCheckBranch = (index) => (event) => {
		var arr = this.state.itemsList;
		arr[index].isEnabled = !arr[index].isEnabled;
		this.setState({
			itemsList: arr,
		});
	};

	onChangeValues = (event, type) => {
		var value = event.target.value;
		//console.log("val "+value);
		if (type === "AN") {
			if (/[^0-9a-zA-Z\s]/.test(value)) {
				value = this.state[event.target.id];
			} else
				this.setState({
					[event.target.id]: value,
				});
		} else if (type === "N") {
			if (/[^0-9]/.test(value)) {
				value = this.state[event.target.id];
			} else
				this.setState({
					[event.target.id]: value,
				});
		} else if (type === "D") {
			this.setState({
				[event.target.id]: value,
			});
		} else {
			this.setState({
				[event.target.id]: value,
			});
		}
	};

	onSelect = (selectedValue, stateKey) => {
		this.setState({
			[stateKey]: selectedValue,
		});
	};
	handleSelectedLGValues = (value) => {
		this.setState({
			ledgerGroup: value,
		});
	};

	hideBasicAlert() {
		if (this.state.updateItemAlert) this.hideAlert();
		this.setState({
			basicAlert: null,
			deleteItem: false,
			updateItemAlert: false,
			designationName: "",
		});
	}

	hideItemBasicAlert() {
		if (this.state.updateItemAlert) {
			this.setState({
				alert: true,
				updateItemAlert: false,
			});
		}
		if (this.state.deleteItem) {
			var data = this.state.invoiceList;
			if (this.state.updateView) data[this.state.mid].isActive = 0;
			else data.splice(this.state.mid, 1);
			//  if(this.state.approveStatus)
			this.setState({
				invoiceList: data,
				deleteItem: false,
			});
		}
		this.setState({
			basicAlert: null,
		});
	}

	hideAlert() {
		console.log("alert");
		this.setState({
			updateItem: false,
			isJuteSelected: false,
			hideJuteType: false,
			alert: false,
			showMessagealert: false,
			loading: false,
			branchAddress: "",
			city: "",
			stateName: "",
			stateCode: "",
			cgstNo: "",
			pinCode: "",
		});
	}

	hideAlertMessage() {
		var list = this.state.itemsList.map((prop, i) => {
			prop.isEnabled = false;
			return prop;
		});
		this.setState({
			isCreated: false,
			approveStatus: false,
			showMessagealert: false,
			updateView: false,
			alert: false,
			basicAlert: false,
			id: null,
			customerCode: null,
			name: "",
			contactPerson: "",
			contactNo: "",
			alternateContactNo: "",
			emailId: "",
			address: "",
			city: "",
			state: "",
			pincode: "",
			gstNo: "",
			panNo: "",
			cinNo: "",
			billingStateCode: "",
			shippingAddress: "",
			ledgerGroup: "",
			shippingStateCode: "",
			city2: "",
			state2: "",
			pincode2: "",
			gstNo2: "",
			createdBy: null,
			createdOn: null,
			openningBalance: "",
			itemsList: list,
			finalStatus: "N",
			internalCompanyId: "",
			invoiceList: [],
		});
	}

	basicAlert(msg) {
		this.setState({
			basicAlert: (
				<SweetAlert
					style={{
						display: "block",
						marginTop: "-100px",
						marginLeft: "-80px",
						fontSize: "8px",
						color: "red",
					}}
					title={msg}
					onConfirm={() => this.hideBasicAlert()}
					onCancel={() => this.hideBasicAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
				/>
			),
		});
	}

	hideDeleteAlert(id) {
		this.setState({ basicAlert: null });
		getDataFromUrl(
			serverApi.DATATABLE_SERVICE_URL +
				"/deleteJuteIssue/" +
				id +
				"/" +
				getCookie(cons.CIPHER),
			this.handleDeleteClickResp
		);
	}

	basicDeleteAlert(msg, id) {
		this.setState({
			basicAlert: (
				<SweetAlert
					style={{
						display: "block",
						marginTop: "-100px",
						marginLeft: "-80px",
						fontSize: "8px",
						color: "red",
					}}
					title={msg}
					onConfirm={() => this.hideDeleteAlert(id)}
					onCancel={() => this.hideBasicAlert()}
					showCancel
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
					cancelBtnCssClass={
						this.props.classes.button + " " + this.props.classes.danger
					}
					confirmBtnText="Yes"
					cancelBtnText="No"
				/>
			),
		});
	}

	basicItemAlert(msg) {
		this.setState({
			basicAlert: (
				<SweetAlert
					style={{
						display: "block",
						marginTop: "-100px",
						marginLeft: "-80px",
						fontSize: "8px",
						color: "red",
					}}
					title={msg}
					onConfirm={() => this.hideItemBasicAlert()}
					onCancel={() => this.hideBasicAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
					cancelBtnCssClass={
						this.props.classes.button + " " + this.props.classes.danger
					}
					confirmBtnText="Yes"
					cancelBtnText="No"
					showCancel
				/>
			),
		});
	}
	handleAddItem() {
		const { selectedJuteType } = this.state;
		console.log("unitConversionName " + this.state.unitConvertion);
		//  var id="";
		console.log(this.state);
		this.setState({
			loading: true,
			alert: true,
		});
	}
	getDataTable = () => {
		var data = [];
		//  console.log("dta",JSON.stringify(this.state.invoiceList))
		if (this.state.invoiceList.length > 0)
			this.state.invoiceList.forEach((prop, key) => {
				prop.id = key;
				if (prop.isActive === 1) {
					var obj = {
						...prop,
						actions: (
							// we've added some custom button actions
							<div className="actions-right">
								{/* use this button to remove the data row */}
								<IconButton
									onClick={() => {
										var data = this.state.invoiceList;
										data.find((o, i) => {
											if (o.id === obj.id) {
												// alert(o.qualityId);
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  data.splice(i,1);

												this.basicItemAlert("Do You Like To Update This Item?");

												this.setState({
													//alert: true,
													updateItem: true,
													updateItemAlert: true,
													mid: o.id,
													customerLineId: o.customerLineId,
													autoId: o.autoId,
													branchAddress: o.branchAddress,
													cgstNo: o.gstNo,
													pinCode: o.pinCode,
													stateCode: o.stateCode,
													stateName: o.stateName,
													city: o.city,
												});

												return true;
											}
										});
									}}
									color="primaryNoBackground"
									customClass="edit"
								>
									<Edit />
								</IconButton>
								<IconButton
									onClick={() => {
										var data = this.state.invoiceList;
										data.find((o, i) => {
											if (o.id === obj.id) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												this.setState({
													deleteItem: true,
													mid: o.id,
												});
												this.basicItemAlert("Do You Like To Delete This Item?");
												return true;
											}
										});
									}}
									color="dangerNoBackground"
									customClass="remove"
								>
									<Close />
								</IconButton>
							</div>
						),
					};

					data.push(obj);
				}
			});
		return data;
	};
	onClickAddItem() {
		var arr = this.state.invoiceList;
		var itemIndex = 0;
		if (this.state.updateItem) {
			itemIndex = this.state.mid;
		} else {
			itemIndex = this.state.invoiceList.length;
		}
		const {
			autoId,
			customerLineId,
			branchAddress,
			cgstNo,
			pinCode,
			stateCode,
			stateName,
			city,
		} = this.state;

		var addItemObj = {
			mid: itemIndex,
			customerLineId: customerLineId,
			autoId: autoId,
			isActive: 1,
			branchAddress: branchAddress,
			gstNo: cgstNo,
			pinCode: pinCode,
			stateCode: stateCode,
			stateName: stateName,
			city: city,
		};

		if (
			branchAddress !== "" &&
			city !== "" &&
			stateName !== "" &&
			stateCode !== "" &&
			pinCode !== "" &&
			pinCode !== null &&
			pinCode !== undefined
		) {
			const stateCode = this.state.stateCode;
			const cgstNo = this.state.cgstNo;
			if(cgstNo != undefined && cgstNo !==""){
				if (cgstNo.slice(0, 2) === stateCode.slice(0, 2)) {
					if (this.state.updateItem) {
						arr[itemIndex] = addItemObj;
					} else arr.splice(arr.length, 0, addItemObj);
					//console.log(arr.toString());

					this.setState({
						loading: true,
						invoiceList: arr,
					});
					this.hideAlert();
				} else {
					this.basicAlert("Enter the correct gst no");
				}
			}else{
				if (this.state.updateItem) {
					arr[itemIndex] = addItemObj;
				} else arr.splice(arr.length, 0, addItemObj);
				//console.log(arr.toString());

				this.setState({
					loading: true,
					invoiceList: arr,
				});
				this.hideAlert();
			}

		} else {
			this.basicAlert("Please Enter All Mandatory Fields..!!");
		}
	}

	onUpdateButtonClick = () => {
		if (!getMenuPermission(1)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			const {
				id,
				invoiceList,
				customerCode,
				name,
				contactPerson,
				contactNo,
				alternateContactNo,
				emailId,
				shippingAddress,
				address,
				city,
				state,
				billingStateCode,
				pincode,
				gstNo,
				panNo,
				cinNo,
				ledgerGroup,
				shippingStateCode,
				city2,
				state2,
				pincode2,
				gstNo2,
				openningBalance,
				itemsList,
				internalCompanyId,
				finalStatus,
			} = this.state;
			var list = [];
			itemsList.map((prop, i) => {
				if (prop.isEnabled) {
					list.push(prop.bId);
				}
			});
			var invoiceList = invoiceList.map((prop, key) => {
				return {
					customerLineId: prop.customerLineId,
					customerId: prop.autoId,
					address: prop.branchAddress,
					city: prop.city,
					stateCode: prop.stateCode,
					state: prop.stateName,
					pincode: prop.pinCode,
					gstNo: prop.gstNo,
					isActive: prop.isActive,
					createdBy: getCookie(cons.USERID),
				};
			});
			var ldata = {
				id: id,
				customerCode: customerCode,
				name: name,
				contactPerson: contactPerson,
				contactNo: contactNo,
				alternateContactNo: alternateContactNo,
				emailId: emailId,
				// "address":address,
				// "city":city,
				// "state":state,
				// "billingStateCode":billingStateCode,
				// "pincode":pincode,
				//"gstNo":gstNo,
				panNo: panNo,
				cinNo: cinNo,
				// "shippingAddress":shippingAddress,
				// "shippingStateCode":shippingStateCode,
				// "city2":city2,
				// "state2":state2,
				// "pincode2":pincode2,
				// "gstNo2":gstNo2,
				ledgerGroup: ledgerGroup,
				openningBalance: openningBalance,
				createdBy: getCookie(cons.USERID),
				companyId: getCookie(cons.COMPANY_ID),
				mappedBranchIds: list,
				internalCompanyId: internalCompanyId,
				finalStatus: finalStatus,
			};
			var data = {
				customerHdr: ldata,
				customerLineitems: invoiceList,
			};
			if (name !== "" && list.length > 0) {
				if (invoiceList.length > 0) {
					if (panNo !== "" && panNo !== null) {
						//var cName = this.state.name;
						if (this.state.panNo.length === 10) {
							this.handleLoading();
							requestList(
								serverApi.CUSTOMER_MASTER_UPDATE,
								data,
								this.handleAddTax
							);
						} else {
							this.basicAlert("Enter 10 characters pan no !!");
						}
						// if (panNo.substring(4, 5) === cName.substring(0, 1)) {

						// } else {
						//   this.basicAlert("Enter Correct PAN No.!!");
						// }
					} //else {
					//this.handleLoading();
					//requestList(
					//serverApi.CUSTOMER_MASTER_UPDATE,
					//data,
					//this.handleAddTax
					//);
					//}
					else {
						this.basicAlert("Please enter the mandatory fields");
					}
				} else {
					this.basicAlert("Please Add Atleast One Item..!!");
				}
				// if(gstNo===""){
				//     if(gstNo2===""){
				//         if(panNo!==""){
				//             var cName=getCookie(cons.COMPANY_NAME);
				//             if(panNo.substring(4,5)===cName.substring(0,1)){
				//                 this.handleLoading();
				//                 requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//             }
				//             else{
				//                 this.basicAlert("Enter Correct PAN No.!!")
				//             }
				//         }
				//         else{
				//             this.handleLoading();
				//             requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//         }
				//     }
				//     else{
				//         if(gstNo2.substring(0,2)===shippingStateCode){
				//             if(panNo!==""){
				//                 var cName=getCookie(cons.COMPANY_NAME);
				//                 if(panNo.substring(4,5)===cName.substring(0,1)){
				//                     this.handleLoading();
				//                     requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//                 }
				//                 else{
				//                     this.basicAlert("Enter Correct PAN No.!!")
				//                 }
				//             }
				//             else{

				//                 this.handleLoading();
				//                 requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//         }
				//         }
				//         else{
				//             this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
				//         }

				//     }
				// }
				// else{
				//     if(gstNo.substring(0,2)===billingStateCode){
				//         if(this.state.gstNo2===""){
				//             if(panNo!==""){
				//                 var cName=getCookie(cons.COMPANY_NAME);
				//                 if(panNo.substring(4,5)===cName.substring(0,1)){
				//                     this.handleLoading();
				//                     requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//                 }
				//                 else{
				//                     this.basicAlert("Enter Correct PAN No.!!")
				//                 }
				//             }
				//             else{
				//                 this.handleLoading();
				//                 requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//         }
				//         }
				//         else{
				//             if(gstNo2.substring(0,2)===shippingStateCode){
				//                 if(panNo!==""){
				//                     var cName=getCookie(cons.COMPANY_NAME);
				//                     if(panNo.substring(4,5)===cName.substring(0,1)){
				//                         this.handleLoading();
				//                         requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//                     }
				//                     else{
				//                         this.basicAlert("Enter Correct PAN No.!!")
				//                     }
				//                 }
				//                 else{
				//                         this.handleLoading();
				//                         requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
				//                     }
				//             }
				//             else{
				//                 this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
				//             }

				//         }
				//     }
				//     else{
				//         this.basicAlert("Billing statecode be first two letters of Billing GST NO.!!")
				//     }

				// }
			} else {
				this.basicAlert("Please Enter All Mandatory Fields..!!");
			}
		}
	};

	onCreateButtonClick = () => {
		if (!getMenuPermission(2)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			const {
				invoiceList,
				name,
				contactPerson,
				contactNo,
				alternateContactNo,
				emailId,
				shippingAddress,
				address,
				city,
				state,
				billingStateCode,
				pincode,
				gstNo,
				panNo,
				cinNo,
				ledgerGroup,
				shippingStateCode,
				city2,
				state2,
				pincode2,
				gstNo2,
				openningBalance,
				itemsList,
				internalCompanyId,
				finalStatus,
			} = this.state;
			var list = [];
			itemsList.map((prop, i) => {
				if (prop.isEnabled) {
					list.push(prop.bId);
				}
			});
			var invoiceList = invoiceList.map((prop, key) => {
				return {
					address: prop.branchAddress,
					city: prop.city,
					stateCode: prop.stateCode,
					state: prop.stateName,
					pincode: prop.pinCode,
					gstNo: prop.gstNo,
					isActive: 1,
					createdBy: getCookie(cons.USERID),
				};
			});

			var ldata = {
				name: name,
				contactPerson: contactPerson,
				contactNo: contactNo,
				alternateContactNo: alternateContactNo,
				emailId: emailId,
				// "address":address,
				// "city":city,
				// "state":state,
				// "billingStateCode":billingStateCode,
				// "pincode":pincode,
				// "gstNo":gstNo,
				panNo: panNo,
				cinNo: cinNo,
				// "shippingAddress":shippingAddress,
				// "shippingStateCode":shippingStateCode,
				// "city2":city2,
				// "state2":state2,
				// "pincode2":pincode2,
				// "gstNo2":gstNo2,
				ledgerGroup: ledgerGroup,
				openningBalance: openningBalance,
				createdBy: getCookie(cons.USERID),
				companyId: getCookie(cons.COMPANY_ID),
				mappedBranchIds: list,
				internalCompanyId: internalCompanyId,
				finalStatus: finalStatus,
			};
			var data = {
				customerHdr: ldata,
				customerLineitems: invoiceList,
			};

			if (name !== "" && list.length > 0) {
				if (invoiceList.length > 0) {
					if (panNo !== "") {
						//var cName = this.state.name;

						if (this.state.panNo.length === 10) {
							this.handleLoading();
							requestList(
								serverApi.CUSTOMER_MASTER_ADD,
								data,
								this.handleAddTax
							);
							// } else if (
							//   this.state.panNo.match(
							//     /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
							//   )
							// ) {
							//   this.basicAlert(" Donot Enter any special character !!");
						} else {
							this.basicAlert("Enter 10 character pan no !!");
						}

						// if (panNo.substring(4, 5) === cName.substring(0, 1)) {

						// } else {
						//   this.basicAlert("Enter Correct PAN No.!!");
						// }
					} //else {
					//this.handleLoading();
					//requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax);
					//}
					else {
						this.basicAlert("Please enter the mandatory fields");
					}
				} else {
					this.basicAlert("Please Add Atleast One Item..!!");
				}
				// if(gstNo===""){
				//     if(gstNo2===""){
				//         if(panNo!==""){
				//             var cName=getCookie(cons.COMPANY_NAME);
				//             if(panNo.substring(4,5)===cName.substring(0,1)){
				//                 this.handleLoading();
				//                 requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//             }
				//             else{
				//                 this.basicAlert("Enter Correct PAN No.!!")
				//             }
				//         }
				//         else{
				//             this.handleLoading();
				//             requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//         }
				//     }
				//     else{
				//         if(gstNo2.substring(0,2)===shippingStateCode){
				//             if(panNo!==""){
				//                 var cName=getCookie(cons.COMPANY_NAME);
				//                 if(panNo.substring(4,5)===cName.substring(0,1)){
				//                     this.handleLoading();
				//                     requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//                 }
				//                 else{
				//                     this.basicAlert("Enter Correct PAN No.!!")
				//                 }
				//             }
				//             else{

				//                 this.handleLoading();
				//                 requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//         }
				//         }
				//         else{
				//             this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
				//         }

				//     }
				// }
				// else{
				//     if(gstNo.substring(0,2)===billingStateCode){
				//         if(this.state.gstNo2===""){
				//             if(panNo!==""){
				//                 var cName=getCookie(cons.COMPANY_NAME);
				//                 if(panNo.substring(4,5)===cName.substring(0,1)){
				//                     this.handleLoading();
				//                     requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//                 }
				//                 else{
				//                     this.basicAlert("Enter Correct PAN No.!!")
				//                 }
				//             }
				//             else{
				//                 this.handleLoading();
				//                 requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//         }
				//         }
				//         else{
				//             if(gstNo2.substring(0,2)===shippingStateCode){
				//                 if(panNo!==""){
				//                     var cName=getCookie(cons.COMPANY_NAME);
				//                     if(panNo.substring(4,5)===cName.substring(0,1)){
				//                         this.handleLoading();
				//                         requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//                     }
				//                     else{
				//                         this.basicAlert("Enter Correct PAN No.!!")
				//                     }
				//                 }
				//                 else{
				//                         this.handleLoading();
				//                         requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
				//                     }
				//             }
				//             else{
				//                 this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
				//             }

				//         }
				//     }
				//     else{
				//         this.basicAlert("Billing statecode be first two letters of Billing GST NO.!!")
				//     }

				// }
			} else {
				this.basicAlert("Please Enter All Mandatory Fields..!!");
			}
		}
	};
	handleAddTax = (resp) => {
		if (resp.status) {
			console.log("resp " + JSON.stringify(resp));
			this.showMessage(resp.message, resp.status);
		} else {
			this.showMessage(resp.message, resp.status);
		}
		this.handleLoading();
	};

	//add Item

	showDeleteMessage = (msg, status) => {
		this.setState({
			showMessagealert: (
				<Popup
					//  onClickSave={() => this.onClickAddItem()}
					onClickSave={() => {
						if (status) {
							this.hideAlertMessage();
						} else {
							this.hideAlert();
						}
					}}
					isShowSave={true}
					save_btn_txt={"OK"}
					content={
						<form>
							<legend style={{ textAlign: "center" }}>{msg}</legend>
						</form>
					}
				/>
			),
		});
	};

	showMessage = (msg, status) => {
		this.setState({
			showMessagealert: (
				<Popup
					//  onClickSave={() => this.onClickAddItem()}
					onClickSave={() => {
						if (status) {
							this.hideAlertMessage();
						} else {
							this.hideAlert();
						}
					}}
					isShowSave={true}
					save_btn_txt={"OK"}
					content={
						<form>
							<legend style={{ textAlign: "center" }}>{msg}</legend>
						</form>
					}
				/>
			),
		});
	};
	componentDidMount() {
		var data = {
			companyId: getCookie(cons.COMPANY_ID),
			cipher: getCookie(cons.CIPHER),
		};
		requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);
		requestList(serverApi.BRANCHMASTER_DATATABLE, data, this.handleBranches);
		getDataFromUrl(serverApi.GET_ALL_STATES, this.handleStates);
		if (getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) === "2") {
			data = {
				companyId: getCookie(cons.COMPANY_ID),
				cipher: getCookie(cons.CIPHER),
			};
			requestList(
				serverApi.COMPANYMASTER_DATATABLE,
				data,
				this.handleCompanies
			);
		}
	}
	handleCompanies = (resp) => {
		console.log("companujj", resp.data);
		var companyList = [];
		if (resp.data) {
			resp.data.forEach((prop) => {
				companyList.push({
					label: prop.companyName,
					value: prop.companyId,
					name: prop.companyName,
				});
			});
			this.setState({ companyList: companyList });
		}
	};
	handleStates = (resp) => {
		var list = [];
		if (resp.status === undefined) {
			resp.forEach((prop) => {
				var obj = prop;
				if (prop.value !== 0) obj.value = prop.label;
				else obj.value = "";
				list.push(obj);
			});
		}
		this.setState({
			statesList: list,
		});
	};

	handleBranches = (resp) => {
		var list = [];
		resp.data.map((prop, key) => {
			var val = {
				id: 0,
				bId: prop.id,
				bName: prop.brname,
				isEnabled: false,
			};

			// if(key>0)
			list.push(val);
		});
		this.setState({
			itemsList: list,
		});
	};

	handleLedgerGroup = (resp) => {
		const lgoptions = [];
		var ledger_data = resp.data;
		if (ledger_data !== null || ledger_data !== undefined) {
			ledger_data.forEach((r) => {
				const { name, id } = r;
				lgoptions.push({
					value: r.id,
					label: r.name,
					name: r.name,
				});
			});
		}
		this.setState({ ledger_data: lgoptions });
	};

	render() {
		const { classes } = this.props;
		const { selectedJuteType, ledger_data } = this.state;
		const options = ledger_data;
		var labelType = getConfigValue(configNames.LABELS_TYPE);

		const inputFocus = {
			autoComplete: "off",
		};
		return (
			<GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
				{this.state.isCreated ? (
					<ItemGrid xs={12}>
						<RegularCard
							isLoading={this.state.isLoading}
							cardTitle={
								<Button color={"info"} onClick={this.handleCreate}>
									Back To List
								</Button>
							}
							content={
								<GridContainer>
									{this.state.alert ? (
										<Popup
											onClickSave={() => this.onClickAddItem()}
											closePopup={() => this.hideAlert()}
											title="Post Temperature"
											isShowSave={true}
											isShowCancel={true}
											save_btn_txt={this.state.updateItem ? "Update" : "save"}
											cancel_button_text={"Cancel"}
											//juteType={itemtype}
											content={
												<GridContainer>
													<ItemGrid
														xs={12}
														md={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Address
																	<span
																		style={{
																			color: "red",
																			fontSize: "12px",
																		}}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="branchAddress"
																	value={this.state.branchAddress}
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	fullWidth={true}
																	multiline={true}
																	rowsMax={3}
																	height={60}
																	InputProps={inputFocus}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid
														xs={12}
														md={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	City
																	<span
																		style={{
																			color: "red",
																			fontSize: "12px",
																		}}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="city"
																	value={this.state.city}
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	fullWidth={true}
																	InputProps={inputFocus}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid
														xs={12}
														sm={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	State
																	<span
																		style={{
																			color: "red",
																			fontSize: "12px",
																		}}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<SelectboxTemp
																	onSelect={this.onSelect}
																	defValue={this.state.stateName}
																	getName={(name) => {
																		var val = name.split("^");
																		this.setState({
																			stateCode:
																				val[1] !== undefined ? val[1] : "",
																		});
																	}}
																	stateKey="stateName"
																	staticData={this.state.statesList}
																	// url={serverApi.GET_ALL_STATES}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid
														xs={12}
														md={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	State Code
																	<span
																		style={{
																			color: "red",
																			fontSize: "12px",
																		}}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	disabled={true}
																	id="stateCode"
																	value={this.state.stateCode}
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	fullWidth={true}
																	InputProps={inputFocus}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid
														xs={12}
														md={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	GST No
															
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="cgstNo"
																	value={this.state.cgstNo}
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	fullWidth={true}
																	InputProps={inputFocus}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid
														xs={12}
														md={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Pin Code
																	<span
																		style={{
																			color: "red",
																			fontSize: "12px",
																		}}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<input
																	id="pinCode"
																	value={this.state.pinCode}
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	fullWidth={true}
																	InputProps={inputFocus}
																	iType={"number"}
																	//type="number"
																	// iType="tel"
																	maxLength={6}
																	style={{
																		color: "#000",
																		// fontFamily: defaultFont,
																		paddingLeft: "10px",
																		//textAlign: "right",
																		// width: '20%',
																		height: "30px",
																		width: "160px",
																		borderRadius: 4,
																		// backgroundColor: 'white',
																		border: "1px solid #D2D2D2",
																		fontSize: "14px",
																		fontWeight: "400",
																	}}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
												</GridContainer>
											}
										/>
									) : null}
									{this.state.basicAlert}
									{this.state.showMessagealert}
									{this.state.updateView ? (
										<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={4}>
													<InputLabel className={classes.label}>
														Customer Code
														<span style={{ color: "red", fontSize: "12px" }}>
															*
														</span>
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={8}>
													<TextField
														diabled={true}
														id="customerCode"
														value={this.state.customerCode}
														className={classes.textField}
														onChange={this.onChangeValues}
														fullWidth={true}
														variant="outlined"
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}

									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Name
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="name"
													value={this.state.name}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "");
													}}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Contact Person
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="contactPerson"
													value={this.state.contactPerson}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "AN");
													}}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Contact No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="contactNo"
													value={this.state.contactNo}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "AN");
													}}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Alternate Contact No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="alternateContactNo"
													value={this.state.alternateContactNo}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "AN");
													}}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Email ID
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="emailId"
													value={this.state.emailId}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "");
													}}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                 Billing Address
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="address"
                                                    value={this.state.address}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Billing City
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="city"
                                                    value={this.state.city}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Billing State
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.state}
                                                    getName={(name)=>{
                                                        var val=name.split("^");
                                                        this.setState({billingStateCode:val[1]!==undefined?val[1]:""})

                                                    }}
                                                    stateKey="state"
                                                    staticData={this.state.statesList}
                                                /> */}

									{/*
                                                <TextField
                                                    id="state"
                                                    value={this.state.state}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
*/}

									{/* </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}

									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Billing StateCode
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>

                                                <TextField
                                                    id="billingStateCode"
                                                    value={this.state.billingStateCode}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}

									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Billing PinCode
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="pincode"
                                                    value={this.state.pincode}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'N')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    iType={"number"}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="3"?"Billing VAT No":"Billing GST No"}
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="gstNo"
                                                    value={this.state.gstNo}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													{labelType === "3" ? "CR No" : "PAN No"}
													<span
														style={{
															color: "red",
															fontSize: "12px",
														}}
													>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<input
													id="panNo"
													value={
														this.state.panNo
															? this.state.panNo.toUpperCase()
															: ""
													}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "AN");
													}}
													fullWidth={true}
													variant="outlined"
													maxLength={10}
													style={{
														color: "#000",
														// fontFamily: defaultFont,
														paddingLeft: "10px",
														//textAlign: "right",
														// width: '20%',
														height: "30px",
														width: "260px",
														borderRadius: 4,
														// backgroundColor: 'white',
														border: "1px solid #D2D2D2",
														fontSize: "14px",
														fontWeight: "400",
													}}

													// style={{ textTransform: "upperCase" }}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													CIN No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="cinNo"
													value={this.state.cinNo}
													className={classes.textField}
													onChange={(event) => {
														this.onChangeValues(event, "AN");
													}}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Shipping Address
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="shippingAddress"
                                                    value={this.state.shippingAddress}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    multiline={true}
                                                    rowsMax={3}
                                                    height={60}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Shipping State
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.state2}
                                                    getName={(name)=>{
                                                        var val=name.split("^");
                                                        this.setState({shippingStateCode:val[1]!==undefined?val[1]:""})
                                                    }}
                                                    stateKey="state2"
                                                    staticData={this.state.statesList}
                                                /> */}

									{/*
                                                <TextField
                                                    id="shippingStateCode"
                                                    value={this.state.shippingStateCode}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
*/}

									{/* </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Shipping State Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="shippingStateCode"
                                                    value={this.state.shippingStateCode}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}

									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Shipping City
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="city2"
                                                    value={this.state.city2}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}

									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Shipping PinCode
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="pincode2"
                                                    value={this.state.pincode2}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
									{/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="3"?"Shipping VAT No":"Shipping GST No"}
                                            </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="gstNo2"
                                                    value={this.state.gstNo2}
                                                    className={classes.textField}
                                                    onChange={(event)=>{
                                                        this.onChangeValues(event,'AN')
                                                    }}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}

									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Ledger Group
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<SelectboxTemp
													defValue={this.state.ledgerGroup}
													id={"ledgerGroup"}
													getName={this.onSelect}
													onSelect={this.onSelect}
													stateKey="ledgerGroup"
													staticData={options}
													//  url={serverApi.GET_STOCK_DETAILS_BY_GODOWN_NO + this.state.godownValue + "/" + getCookie(cons.CIPHER)}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									{getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) ===
									"2" ? (
										<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={4}>
													<InputLabel className={classes.label}>
														Internal Company
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={8}>
													<SelectboxTemp
														defValue={this.state.internalCompanyId}
														id={"internalCompanyId"}
														getName={(name) => {}}
														onSelect={this.onSelect}
														stateKey="internalCompanyId"
														staticData={this.state.companyList}
														//  url={serverApi.GET_STOCK_DETAILS_BY_GODOWN_NO + this.state.godownValue + "/" + getCookie(cons.CIPHER)}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
									{getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) ===
									"2" ? (
										<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={4}>
													<InputLabel className={classes.label}>
														Final Status
														<span style={{ color: "red", fontSize: "12px" }}>
															*
														</span>
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={8}>
													<SelectboxTemp
														defValue={this.state.finalStatus}
														onSelect={this.onSelect}
														stateKey="finalStatus"
														staticData={yesOrNo}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}

									<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Opening Balance
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="openningBalance"
													value={this.state.openningBalance}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													iType={"number"}
													InputProps={inputFocus}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={2}>
												<InputLabel className={classes.label}>
													Branches:
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} md={10}>
												<GridContainer>
													{this.state.itemsList.map((prop, key) => {
														return (
															<ItemGrid xs={12} sm={4}>
																<FormControlLabel
																	control={
																		<Checkbox
																			tabIndex={-1}
																			value={prop.isEnabled ? 1 : 0}
																			checked={prop.isEnabled ? 1 : 0}
																			onChange={this.onCheckBranch(key)}
																			// onClick={() => this.handleToggle(prop)}
																			checkedIcon={
																				<Check
																					className={classes.checkedIcon}
																				/>
																			}
																			icon={
																				<Check
																					className={classes.uncheckedIcon}
																				/>
																			}
																			classes={{
																				checked: classes.checked,
																			}}
																			style={{ marginLeft: 10 }}
																		/>
																	}
																	classes={{
																		label: classes.menulabel,
																	}}
																	label={prop.bName}
																/>
															</ItemGrid>
														);
													})}
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12}>
										<LineItemTable
											data={this.getDataTable()}
											loading={this.state.loading}
											columns={[
												{
													Header: "Branch Address",
													accessor: "branchAddress",
													minWidth: 150,
													style: { color: "#000", textAlign: "left" },
												},
												{
													Header: "State",
													accessor: "stateName",
													minWidth: 100,
													style: { color: "#000", textAlign: "left" },
												},
												{
													Header: "State Code",
													accessor: "stateCode",
													minWidth: 100,
													style: { color: "#000", textAlign: "left" },
												},
												{
													Header: "City",
													accessor: "city",
													minWidth: 100,
													style: { color: "#000", textAlign: "left" },
												},
												{
													Header: "Pin Code",
													accessor: "pinCode",
													minWidth: 100,
													style: { color: "#000", textAlign: "left" },
												},
												{
													Header: "GST Number",
													accessor: "gstNo",
													minWidth: 150,
													style: { color: "#000", textAlign: "left" },
												},
												{
													Header: "Actions",
													minWidth: 100,
													accessor: "actions",
													style: { color: "#000", textAlign: "center" },
													sortable: false,
													filterable: false,
												},
											]}
										/>
									</ItemGrid>
									<ItemGrid xs={12}>
										<Button
											color={"info"}
											right={true}
											onClick={this.handleAddItem}
										>
											Add Item
										</Button>
									</ItemGrid>

									{this.state.updateView ? (
										<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={4}>
													<InputLabel className={classes.label}>
														Created By
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={8}>
													<TextField
														disabled={true}
														id="createdBy"
														value={this.state.createdBy}
														className={classes.textField}
														onChange={this.onChangeValues}
														fullWidth={true}
														InputProps={inputFocus}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
									{this.state.updateView ? (
										<ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={4}>
													<InputLabel className={classes.label}>
														Created On
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={8}>
													<TextField
														disabled={true}
														id="createdOn"
														value={this.state.createdOn}
														className={classes.textField}
														onChange={this.onChangeValues}
														fullWidth={true}
														variant="outlined"
														InputProps={inputFocus}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
									<ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
										<GridContainer justify="center" />
									</ItemGrid>

									{!this.state.updateView ? (
										<ItemGrid xs={12} style={{ marginTop: "5px" }}>
											<GridContainer justify="center">
												<ItemGrid>
													<Button
														color="primary"
														onClick={this.onCreateButtonClick}
													>
														Create
													</Button>
												</ItemGrid>
												<ItemGrid>
													<Button color="danger" onClick={this.handleCreate}>
														Cancel
													</Button>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
									{this.state.updateView ? (
										<ItemGrid xs={12} style={{ marginTop: "5px" }}>
											<GridContainer justify="center">
												<ItemGrid>
													<Button
														color="primary"
														onClick={this.onUpdateButtonClick}
													>
														Update
													</Button>
												</ItemGrid>
												<ItemGrid>
													<Button color="danger" onClick={this.handleCreate}>
														Cancel
													</Button>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
								</GridContainer>
							}
						/>
					</ItemGrid>
				) : (
					<ItemGrid xs={12}>
						<RegularCard
							/* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
							content={
								<div>
									<GridContainer>
										<ItemGrid xs={10}>
											<GridContainer>
												<ItemGrid xs={12} sm={6}>
													{this.state.basicAlert}
													{this.state.showMessagealert}
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
										<ItemGrid xs={12} sm={2}>
											<GridContainer>
												<ItemGrid xs={12}>
													<IconButton
														onClick={this.handleCreate}
														color="success"
														right={true}
													>
														<ExpandMoreIcon />
													</IconButton>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>

										<ItemGrid
											xs={12}
											style={{
												height: 2,
												backgroundColor: "#00acc1",
												marginTop: "5px",
											}}
										/>

										<ItemGrid xs={12}>
											<TableComponent
												url={serverApi.CUSTOMER_MASTER}
												searchData={this.getData()}
												isRefresh={this.state.isRefresh}
												handleSearch={this.handleSeacrh.bind(this)}
												isLoading={this.state.isRefresh}
												onActionClick={this.handleViewClick}
												itemViewType={this.state.itemViewType}
												actionType={VIEW}
												showDelete={false}
											/>
										</ItemGrid>
									</GridContainer>
								</div>
							}
						/>
					</ItemGrid>
				)}
			</GridContainer>
		);
	}
}

const extendedFormsStyle = {
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#00ABDC",
		fontSize: "15px",
		//  lineHeight: "1.0",
		fontWeight: "400",
		display: "inline-flex",
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	textField: {},
	input: {
		border: "1px solid #ccc",
		borderRadius: "4px",
	},

	input_group_addonunit: {
		padding: 0,
		width: "70px",
		borderTopRightRadius: "4px",
		borderBottomRightRadius: "4px",
		overflow: "hidden",
	},
	buttontxt: {
		textTransform: "none",
		borderRadius: "4px",
		lineHeight: "1.2",
	},
	...customSelectStyle,
	...sweetAlertStyle,
	...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(CustomerMaster);
