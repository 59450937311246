import { getCookie } from "./Cookies";

export const configNames = {
  INVOICING_MODEL: "invoicingModel",
  INVOICING_PRINT_MODEL: "invoicingPrintModel",
  BACK_DATE_ALLOWABLE: "backDateAllowable",
  GATE_ENTRY_OUT: "gateEntryOut",
  MR_PRINT_MODE: "mrPrintMode",
  SUPPLIER_TYPES: "supplierTypes",
  LABELS_TYPE: "labelType",
  FUTURE_DATE_ALLOWABLE: "futureDateAllowable",
  INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS: "indentWithMultiDepartments",
  APPROVAL_TASKS_LIST: "tasksList",
  GATE_ENTRY_WITHOUT_PO: "gateEntryWithoutPo",
  PO_PRINT_BEFORE_APPROVE: "poPrintBeforeApproval",
  EMAIL_ALERTS: "emailAlert",
  TAX_TYPES: "taxTypes",
  BROKER_TYPES: "brokerTypes",
  SENDING_MR_INVOICE: "sendingMR",
  CUSTOMER_INTERNAL_MAPPING: "customerInternalMapping",
  SALE_ORDER_TYPES: "saleOrderTypes",
  RAIL_RATE: "rail_rate",
  CONTAINER_RATE: "container_rate",
  TALLY_SYNC: "autoSync",
  INVOICE_NO_MODIFY: "invoiceNoMdicfication",
};

export const cons = {
  AUTH: "auth",
  USER_ROLE_ID: "roleId",
  SUPP_CODE: "supplierCode",
  TOKEN_DATA: "tokenData",
  TOKEN_ID: "tokenId",
  MENUS_DATA: "menus",
  UDATA: "uData",
  USER_GDATA: "userGroupData",
  USER_GRP_ID: "userGroupId",
  USERID: "userId",
  USER_NAME: "userName",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  USER_IMG: "userImg",
  USER_TYPE: "userType",
  CIPHER: "cipher",
  COMPANIES_LIST: "companies",
  COMPANY_ID: "companyId",
  COMPANY_NAME: "companyName",
  COMPANY_TITLE: "companyTitle",
  COMPANY_ADDRESS: "address1",
  COMPANY_ADDRESS2: "address2",
  COMPANY_MAIL_ID: "emailId",
  COMPANY_CONTACT_NO: "contactNo",
  COMPANY_GST: "gstNo",
  COMPANY_ICON: "companyIcon",
  COMPANY_LOGO: "companyLogo",
  COMPANY_CIN: "cinNo",
  SHIPPING_ADDRESS: "companyAddress2",
  COMPANY_GST2: "gstNo2",
  COMPANY_STATECODE2: "stateCode2",
  COMPANY_STATECODE: "stateCode1",
  COMPANY_STATE: "stateName1",
  COMPANY_STATE2: "stateName2",
  CONTACT_PERSON: "contactPerson",
  ERROR: -1,
  SESSION_OK: 0,
  SESSION_EXP: 1,
  NOT_CHECKED: -2,
  menus: "Menus",
  subMenus: "SubMenus",
  MENU_ID: "menuId",
  MENU_NAME: "menuName",
  MENU_TYPE: "menuType",
  EMAIL: "email",
  ORG_ID: "orgId",
  ACADEMIC_YEAR: "academicYear",
  ACADEMIC_YEAR_LIST: "acYearList",
  TOKEN_TYPE: "tokentype",
  ACCESS_TOKEN: "accesstoken",
  EB_ID: "ebId",
  EB_NO: "ebNo",
  QUOTATION_ID: "quotationId",
  SALE_ID: "saleId",
  ADVANCE_ID: "advanceId",
  LEDGER_ID: "ledgerId",
  WIDGETMENUS: "WidgetMenus",
  CONFIG_LIST: "configList",
  HRMS_FULL_ACCESS: "hrmsFullAccess",
  SUB_SUB_MENU_PATH: "subSubMenuPath",
};
export const PHP_LINK =
  "http://ims.bitspvtltd.in/login.php?username=" +
  getCookie(cons.USER_NAME) +
  "&tokken=" +
  getCookie(cons.ACCESS_TOKEN);
export const WEBSITE_URL = "http://swarnatech.com/";

export const MULTI_TENANT_DATA = [
  /* {host: "localhost",serverIp: "localhost:8080", tenantId: "localhost"},
    {host: "192.168.100.53", serverIp: "192.168.100.53:8080", tenantId: "localhost"},
    {host: "178.80.95.108", serverIp: "178.80.95.108:8080", tenantId: "localhost"},
    {host: "178.80.131.224", serverIp: "178.80.131.224:8080", tenantId: "localhost"},
    {host: "itis.vowerp.com", serverIp: "itis.vowerp.com:8080", tenantId: "itis.vowerp.com"},
    {host: "erp.itis.com.sa", serverIp: "erp.itis.com.sa:8080", tenantId: "erp.itis.com.sa"},
    {host: "bits.bitspvtltd.com", serverIp: "bits.bitspvtltd.com:8080", tenantId: "bits.bitspvtltd.com"},*/
  //devapi
  {
    host: "localhost",
    // serverIp: "tenantec1.vowerp.com",
    // tenantId: "slsold.vowerp.com",
    // serverIp: "qaapi.vowerp.com",
    // tenantId: "qasls.vowerp.com",
  },
  {
    host: "dev1.vowerp.com",
    serverIp: "qaapi.vowerp.com",
    tenantId: "qasls.vowerp.com",
  },

  // {
  //   host: "sw.vowerp.com",
  //   serverIp: "tenantec1.vowerp.com",
  //   tenantId: "sw.vowerp.com",
  // },

  {
    host: "sls.vowerp.com",
    serverIp: "tenantec1.vowerp.com",
    tenantId: "sls.vowerp.com",
  },
  {
    host: "slsold.vowerp.com",
    serverIp: "tenantec1.vowerp.com",
    tenantId: "slsold.vowerp.com",
  },
];

export function getHostIp(hostName) {
  var hostIp = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostIp = prop.serverIp;
    }
  });
  if (hostIp !== "") return hostIp;
  else {
    alert("Host not found, Please contact your administrator..!!");
    return null;
  }
}

export function getTenantId(hostName) {
  var hostId = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostId = prop.tenantId;
    }
  });
  if (hostId !== "") return hostId;
  else {
    return "";
  }
}

export const serverConfig = {
  // SERVER_URL: (process.env.NODE_ENV === 'development') ? "http://localhost:8080/" : "http://127.0.0.1:8080/",//put
  SERVER_URL:
    process.env.NODE_ENV === "development"
      ? "https://" + getHostIp("localhost") + "/"
      : "https://" + getHostIp(window.location.hostname) + "/", //put dev
  //  SERVER_URL: (process.env.NODE_ENV === 'development') ? "http://portal.vowjute.com:8080/" :  "http://"+window.location.hostname+":8080/",//putlive
};

/*export const serverVars ={
  PO_API:serverConfig.SERVER_URL+'po_api-v2/',
  MASTER_API:serverConfig.SERVER_URL+'master-v2/',
  SECURITY_API:serverConfig.SERVER_URL+'security-v2/',
  USER_MANAGEMENT_API:serverConfig.SERVER_URL+'usermanagement-v2/',
  REPORTS_API:serverConfig.SERVER_URL+'vow-reports-v2/',
  STORE_REPORTS:serverConfig.SERVER_URL+'storeindent-v2/',
  SECURITY: serverConfig.SERVER_URL
};*/
export function getConfigValue(configName) {
  var val = "1";
  if (
    configName === configNames.CONTAINER_RATE ||
    configName === configNames.RAIL_RATE
  )
    val = 0;
  if (
    getCookie(cons.CONFIG_LIST) !== undefined &&
    getCookie(cons.CONFIG_LIST) !== null
  ) {
    var list = JSON.parse(getCookie(cons.CONFIG_LIST));
    // console.log("list",list);

    list.forEach((prop) => {
      if (prop[configName] !== undefined) {
        val = prop[configName];
      }
    });
  }
  return val;
}

export function getDecimal(value, decimal) {
  var decimalValue = value;
  if (value !== null && value !== "") {
    if (isNaN(parseFloat(value))) decimalValue = value;
    else {
      if (decimal > 2) decimalValue = parseFloat(value).toFixed(decimal);
      else decimalValue = parseFloat(value).toFixed(2);
    }
  }

  return decimalValue;
}

export const serverVars = {
  PO_API_OLD: serverConfig.SERVER_URL + "po_api-v2/",
  MASTER_OLD: serverConfig.SERVER_URL + "master-v2/",
  PO_API: serverConfig.SERVER_URL + "security-api/api/poapi/",
  MASTER_API: serverConfig.SERVER_URL + "security-api/api/",
  HRMS_API: serverConfig.SERVER_URL + "hrms-v2/",
  SECURITY_API: serverConfig.SERVER_URL + "CustomerBranch/AllBranch/",
  SECURITY_API: serverConfig.SERVER_URL + "security-api/api/",
  USER_MANAGEMENT_API: serverConfig.SERVER_URL + "security-api/api/",
  REPORTS_API: serverConfig.SERVER_URL + "security-api/api/vowreports/",
  STORE_REPORTS: serverConfig.SERVER_URL + "security-api/api/",
  SECURITY: serverConfig.SERVER_URL + "security-api/",
  CALL_MANAGEMENT: serverConfig.SERVER_URL + "security-api/api/",
  PAYROLL_API: serverConfig.SERVER_URL + "payroll-v2/",
  TALLY_API: serverConfig.SERVER_URL + "security-api/api/",
  TENANT_API: serverConfig.SERVER_URL + "sls-config/api/",
  HRMS_REPORTS: serverConfig.SERVER_URL + "hrmsreports-v2/",
};
export const serverApi = {
  API_USER: serverVars.USER_MANAGEMENT_API + "easybusiness/",
  API_PO: serverVars.PO_API + "api/v1/",
  LOGIN:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/authenticateUserLogin",
  OAUTH_LOGIN: serverVars.SECURITY + "oauth/token",
  GET_USR_BY_USRNM: "user/getByUserName/",
  GET_USER_GROUP: "usergroup/getByUserId/",
  GET_MENU_BY_GROUP: "usergroupmenu/getUserGroupMenuByGroupId/",
  //GET_MENUS_SUBMENUS_BY_GROUP:"usergroupmenusubmenu/getAllSubMenusAndMenusByGroupId/",
  GET_MENUS_SUBMENUS_BY_GROUP:
    "usergroupmenusubmenu/getUserMenusResultByGroupId/",
  GET_ACADEMIC_YEAR: serverVars.PO_API + "api/v1/getAcademicYearsList/",
  ADD_TOKEN: "addToken",
  DATATABLE_SERVICE_URL: serverVars.PO_API + "api/v1/",
  GET_MR_LIST: serverVars.PO_API + "api/v1/getAllMaterialGoodReceiveDetails",
  SEARCH_MUKAM: serverVars.PO_API + "api/v1/getAllMukam/",
  SEARCH_JUTE_STATUS:
    serverVars.MASTER_API + "statusMaster/getAllStatusMaster/J",
  SEARCH_STORE_STATUS:
    serverVars.MASTER_API + "statusMaster/getAllStatusMaster/S",
  SELECT_VEHICLE_TYPE: serverVars.MASTER_API + "vehicle/getAllVehicles/",
  SELECT_Quality_BY_ITEM: serverVars.PO_API + "api/v1/0/getQualityByItem",
  SEARCH_VENDOR: serverVars.PO_API + "api/v1/getSupplierForJuteByType/V/",
  SEARCH_SUPPLIER: serverVars.PO_API + "api/v1/getSupplierForJuteByType/J",
  SEARCH_MR_SUPPLIER: serverVars.PO_API + "api/v1/getSupplierForJuteByType/JO",
  SEARCH_STORE_SUPPLIER:
    serverVars.PO_API + "api/v1/getSupplierForJuteByType/S",
  SEARCH_BROKER: serverVars.MASTER_API + "mbroker/getAllBroker/",
  GET_BROKERS_BY_SUPPLIER:
    serverVars.MASTER_API + "mbroker/getBrokersBySupplier/",
  ADD_INDENT: serverVars.PO_API + "api/v1/addIndent",
  UPDATE_INDENT: serverVars.PO_API + "api/v1/updateIndent",
  GET_INDENT_DETAILS_BY_ID: serverVars.PO_API + "getIndentDetailsByIndentId/",
  SELECT_ALL_JUTE_TYPE: serverVars.PO_API + "api/v1/999/getAllItemByMukam/",
  SELECT_QUALITIES:
    serverVars.MASTER_API + "juteQualityPrice/getAllJuteQualityListCompany/",
  SELECT_GODOWN_LIST:
    serverVars.MASTER_API + "godownmaster/getSelectonListWarehouses",
  SELCET_VEHICLE_LIST:
    serverVars.MASTER_API + "vehicle/api/v1/getVehicleNumList/",
  STORE_PURCHASE_TYPES:
    serverVars.MASTER_API + "scmIndentTypeMaster/api/v1/S/getIndentTypeList",
  SEARCH_DEPATMENTS: serverVars.MASTER_API + "mdepartment/getDeptList/",
  SEARCH_ITEM_GROUPS:
    serverVars.MASTER_API + "itemgroup/getAllItemGroupMasterList/N/",
  SEARCH_FINISHED_ITEM_GROUPS:
    serverVars.MASTER_API + "itemgroup/getAllItemGroupMasterList/Y/",
  SEARCH_COST_FACTOR: serverVars.MASTER_API + "costmaster/getCostMasterList/",
  GET_STORE_INDENT_TYPES:
    serverVars.MASTER_API + "scmIndentTypeMaster/api/v1/S/getIndentTypeList",
  GET_ITEMS_BY_GRPID: serverVars.MASTER_API + "item/getItemsByItemGroupId/",
  APPROVED_INDENTS: serverVars.PO_API + "api/v1/getApprovedIndents/",
  TAX_MASTER_LIST: serverVars.MASTER_API + "tax/getAllTax",
  ADD_JUTE_PO: serverVars.PO_API + "api/v1/addPO",
  UPDATE_JUTE_PO: serverVars.PO_API + "api/v1/updatePO",
  APPROVED_POS: serverVars.PO_API + "api/v1/getApprovedPOList/",
  GET_PO_BY_SUPPLIER: serverVars.PO_API + "api/v1/getPoBySupp/",
  GET_JUTE_GATE_ENTRY_TABLE:
    serverVars.SECURITY_API + "juteentryhdr/getAllJuteEntryHdr",
  GET_STORE_GATE_ENTRY_TABLE:
    serverVars.SECURITY_API + "gatestoreentryhdr/getAllStoreEntryHdr",
  ADD_STORE_GATE_ENTRY:
    serverVars.SECURITY_API + "gatestoreentryhdr/saveStoreEntryHdr",
  GET_STORE_GATE_ENTRY:
    serverVars.SECURITY_API + "gatestoreentryhdr/getAllStoreEntryHdrById/",
  SELECT_SUPPLIER_STORE:
    serverVars.PO_API + "api/v1/getSupplierForJuteByType/S/",
  GET_PO_DETAILS: serverVars.PO_API + "api/v1/getPODetails",
  GET_PO_LINE_ITEMS: serverVars.PO_API + "api/v1/getPoLineItems",
  UPDATE_STORE_GATE_ENTRY:
    serverVars.SECURITY_API + "gatestoreentryhdr/updateStoreEntryHdr",
  AGENTS_LIST: serverVars.PO_API + "api/v1/getSupplierForJuteByType/O/",
  ADD_JUTE_GATE_ENTRY:
    serverVars.SECURITY_API + "juteentryhdr/saveJuteEntryHdr",
  GET_JUTE_GATE_ENTRY:
    serverVars.SECURITY_API + "juteentryhdr/getAllJuteEntryHdrById/",
  UPDATE_JUTE_GATE_ENTRY:
    serverVars.SECURITY_API + "juteentryhdr/updateJuteEntryHdr",
  GET_GATE_ENTRY_LIST_BY_SUP:
    serverVars.SECURITY_API + "juteentryhdr/getAllJuteEntryBySuppCode/",
  UPDATE_JUTE_MR: serverVars.PO_API + "api/v1/updateMaterialGoodReceiveDetails",
  UPDATE_JUTE_QUALITY_READING:
    serverVars.SECURITY_API + "juteentryhdr/addUpdateJuteQualityReading",
  GET_QUALITY_CHECK_DETAILS_TABLE:
    serverVars.SECURITY_API + "juteentryhdr/getQualityCheckEntries",
  GET_MATERIAL_INSPECTION_TABLE:
    serverVars.SECURITY_API + "gatestoreentryhdr/getStoreInspectionList",
  GET_JUTE_READINGS_BY_LINE_ITEM:
    serverVars.SECURITY_API + "juteentryhdr/getJuteReadingByPoAndPoLineItemId/",
  ADD_JUTE_ISSUE: serverVars.PO_API + "api/v1/addJuteIssue",
  ADD_OR_UPDATE_JUTE_QUALITY_CHECK:
    serverVars.SECURITY_API + "juteentryhdr/addUpdateJuteQualityReading",
  COMMIT_QUALITY_CHECK_READINGS:
    serverVars.SECURITY_API + "juteentryhdr/CommitMaterialReading/",
  GET_BILL_PASS_DETAILS_BY_ID: serverVars.PO_API + "api/v1/getBillPassDetails/",
  DELETE_BILL_INVOICE: serverVars.PO_API + "api/v1/deleteBillDoc/",
  UPDATE_BILL_PASS: serverVars.PO_API + "api/v1/updateBillPass",
  ADD_STORE_MATERIAL_INSPECTION:
    serverVars.SECURITY_API + "gatestoreentryhdr/addMaterialInspection",
  COMMIT_STORE_MATERIAL_INSPECTION:
    serverVars.SECURITY_API + "gatestoreentryhdr/commitMaterialInspection",

  UPDATE_JUTE_ISSUE: serverVars.PO_API + "api/v1/updateJuteIssue",
  WASTAGE_TYPES: serverVars.PO_API + "api/v1/getWastageTypeList",
  GET_WASTAGE_STOCK: serverVars.PO_API + "api/v1/getWastageStock",
  UPDATE_STORE_SR: serverVars.PO_API + "api/v1/updateStoreGoodReceiveDetails",
  GET_ALL_USER_GROUP:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/getAllUserGroup/",
  GET_ALL_USERS_LIST:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/getAllUserListByComId/",
  //  GET_ALL_MENUS_LIST:serverConfig.SERVER_URL+'usermanagement-v2/easybusiness/menu/getUserMenus',
  ADD_STORE_DEBIT_CREDIT: serverVars.PO_API + "api/v1/addDebitCreditNotes",
  GET_SR_BY_SUPPLIER:
    serverVars.PO_API + "api/v1/getStoreGoodReceiveDetailsBySuppCode/",
  GET_ROLES_LIST_BY_TYPE:
    serverVars.USER_MANAGEMENT_API +
    "easybusiness/usergroup/getRolesByReqType/",
  ADD_USER: serverVars.USER_MANAGEMENT_API + "easybusiness/user/addUser",
  ADD_USER_MAP_GRP:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/addUserGroupMap",
  GET_USER_DETAILS_BY_ID:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/getUserDetails/",
  UPDATE_USER: serverVars.USER_MANAGEMENT_API + "easybusiness/user/updateUser/",
  GET_MENUS_BY_ROLE:
    serverVars.USER_MANAGEMENT_API + "easybusiness/menu/getUserMenus/",
  ADD_STORE_ISSUE: serverVars.PO_API + "api/v1/addStoreIssue",
  ADD_DEBIT_CREDIT_NOTE: serverVars.PO_API + "api/v1/addDebitCreditNotes",
  SELECT_PROCESS_TYPE:
    serverVars.MASTER_API + "processMaster/getProcessMasterList",
  GET_TASKS_LIST: serverVars.PO_API + "api/v1/getApproveMasterList",
  UPDATE_APPOVE_HIERACHY: serverVars.PO_API + "api/v1/updateApproveMasterList",
  GET_ALL_USERS:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/getAllUser",
  UPDATE_STORE_ISSUE: serverVars.PO_API + "api/v1/updateStoreIssue",
  ADD_USER_GROUP_ROLE:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/add",
  UPDATE_USER_GROUP_ROLE:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/updateRole",
  GET_USER_GROUP_BY_ID:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/getRoleById/",
  CHANGE_PASSWORD:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/updatePassWord",
  FORGOT_PASSWORD:
    serverConfig.SERVER_URL +
    "usermanagement-v2/easybusiness/user/forgotPassword",
  MODIFY_PASSWORD:
    serverConfig.SERVER_URL +
    "usermanagement-v2/easybusiness/user/modifyPassword",
  COMMIT_STORE_DEBIT_CREDIT: serverVars.PO_API + "api/v1/commitDebitCredit/",
  APP_DATA_JUTE_ISSUE_TABLE: serverVars.PO_API + "getJuteIssuePrimaryList",
  SELECT_SPREADER_LIST: serverVars.MASTER_API + "machinemaster/getSpreaders/",
  PROFILE_PIC_UPLOAD:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/updateUserProfile",
  GET_ALL_SPELL_LIST: serverVars.MASTER_API + "dutyspell/getAllSpellSelection/",
  ADD_BALES_OPENED: serverVars.PO_API + "api/v1/addOpenedBales",
  ADD_APP_DATA_JUTE_ISSUE: serverVars.PO_API + "addJuteIssuePrimary",
  // GET_EMP_DETAILS_BY_ID:serverVars.MASTER_API+'workerMaster/getEmployeeDetailsById/',
  GET_TOTAL_BALES_OPENED: serverVars.PO_API + "api/v1/getCountOfOpenedBales",
  GET_OPENED_BALES_BY_ID: serverVars.PO_API + "api/v1/getOpenedBalesById/",
  DELETE_BALES_OPENED: serverVars.PO_API + "api/v1/deleteOpenedBales",
  BALES_OPENED_TABLE_LIST: serverVars.PO_API + "api/v1/getOpenedBalesList",
  SPREADER_ROLL_TABLE_LIST: serverVars.PO_API + "api/v1/getSpreaderRoleList",
  UPDATE_OPENED_BALES: serverVars.PO_API + "api/v1/updateOpenedBales",
  GET_NET_WEIGHT_SPREADER:
    serverVars.PO_API + "api/v1/getSpreaderTotalNetWeight",
  ADD_SPREADER_ROLE: serverVars.PO_API + "api/v1/addSpreaderRole",
  GET_DOFFNO_SPINNING: serverVars.PO_API + "spinning/getNoOfDoffs",
  GET_NETWEIGHT_DOFF_SPINNING:
    serverVars.PO_API + "spinning/getDoffTotNetWeight",
  ADD_SPINNING_DOFF: serverVars.PO_API + "spinning/addDoffEntry",
  SELECTION_ENTRY_TABLE_LIST: serverVars.PO_API + "api/v1/getSelectionEntry",
  GET_NET_WEIGHT_SELECTION_ENTRY: serverVars.PO_API + "api/v1/getTotNetWt",
  ADD_SELECTION_ENTRY: serverVars.PO_API + "api/v1/addSelectionEntry",
  DELETE_SELECTION_ENTRY: serverVars.PO_API + "api/v1/deleteSelectionEntry/",
  DELETE_SPREADER_ROLE: serverVars.PO_API + "api/v1/deleteSpreaderRole",
  ADD_WASTAGE_ENTRY: serverVars.PO_API + "api/v1/addWastageEntry",
  GET_FRAME_NUMBERS: serverVars.MASTER_API + "machinemaster/getFrameNoList/",
  GET_ALL_USERS_LIST_BY_COMPANY:
    serverVars.USER_MANAGEMENT_API +
    "easybusiness/user/getAllUserByCompanyIdAndCipher/",
  SPINNIG_DOFF_TABLE: serverVars.PO_API + "spinning/getDoffEntryList",
  ADD_FINISHING_ENTRY: serverVars.PO_API + "api/v1/addFinishingEntries",
  FINISHING_ENTRY_TABLE: serverVars.PO_API + "api/v1/getFinishingEntries",
  ADD_WINDING_DOFF_ENTRY: serverVars.PO_API + "winding/addDoffEntry",
  GET_WINDING_ENTRY_TABLE: serverVars.PO_API + "winding/getDoffEntryList",
  DELETE_WINDING_DOFF_ENTRY: serverVars.PO_API + "winding/deleteDoffEntry/",
  DELETE_SPINNING_DOFF_ENTRY: serverVars.PO_API + "spinning/deleteDoffEntry/",
  GET_EMP_DETAILS_BY_LOOMNO: serverVars.PO_API + "api/v1/getEmpNoBySpell",
  GET_CUTS_JUGAR_LOOMNO:
    serverVars.PO_API + "api/v1/getsCutsJugarByLoomAndSpell",
  CUTS_JUGAR_PRODUCTION_TABLE_LIST:
    serverVars.PO_API + "api/v1/getCutsJugarProductionList",
  CUTS_JUGAR_DATA_LIST: serverVars.PO_API + "api/v1/getWeavingQualityMapping/",
  CUTS_JUGAR_WEAVING_ADD: serverVars.PO_API + "api/v1/addWeavingQualityMapping",
  CUTS_JUGAR_WEAVING_DELETE:
    serverVars.PO_API + "api/v1/deleteWeavingQualityMapping/",
  TAXMASTER_DATATABLE: serverVars.MASTER_API + "tax/getAllTaxList",
  TAXMASTER_ADD: serverVars.MASTER_API + "tax/addTax",
  TAXMASTER_UPDATE: serverVars.MASTER_API + "tax/updateTax",
  TAXMASTER_DETAILS_BYID: serverVars.MASTER_API + "tax/getTaxDetailsById",
  GET_TROLLY_LIST: serverVars.MASTER_API + "trollyMaster/getTrollys/",
  WASTAGE_MASTER_DATATABLE:
    serverVars.MASTER_API + "wastageTypeMaster/getAllWastageTypeList",
  WASTAGE_MASTER_ADD:
    serverVars.MASTER_API + "wastageTypeMaster/addWastageType",
  WASTAGE_MASTER_UPDATE:
    serverVars.MASTER_API + "wastageTypeMaster/updateWastageType",
  WASTAGE_MASTER_DETAILS_BYID:
    serverVars.MASTER_API + "wastageTypeMaster/getWastageTypeDetailsById",
  SUPPLIER_MASTER_TABLE_LIST:
    serverVars.MASTER_API + "mastersupplier/supplierMaster",
  DEPARTMENT_MASTER_TABLE_LIST:
    serverVars.MASTER_API + "mdepartment/getAllDepartmentList",
  DEPARTMENT_MASTER_ADD:
    serverVars.MASTER_API + "mdepartment/addDepartmentList",
  DEPARTMENT_MASTER_UPDATE:
    serverVars.MASTER_API + "mdepartment/updateDepartmentList",
  DEPARTMENT_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "mdepartment/getDepartmentDetailsById",
  SPELL_MASTER_TABLE_LIST: serverVars.MASTER_API + "dutyspell/getAllSpells",
  YARN_MASTER_TYPE_LIST: serverVars.MASTER_API + "yarn/getYarnTypeLists/",
  SELECT_JUTE_ISSUE_GODOWN_LIST: serverVars.PO_API + "api/v1/getGodownNos",
  GET_STOCK_DETAILS_BY_GODOWN_NO:
    serverVars.PO_API + "api/v1/getStockDetailsByGodownNo/",
  SPELLMASTER_DATATABLE: serverVars.MASTER_API + "dutyspell/getAllSpells",
  SPELLMASTER_ADD: serverVars.MASTER_API + "dutyspell/addSpell",
  SPELLMASTER_DETAILS_BYID: serverVars.MASTER_API + "dutyspell/getSpellById",
  SPELLMASTER_UPDATE: serverVars.MASTER_API + "dutyspell/updateSpell",
  COMPANYMASTER_ADD: serverVars.MASTER_API + "company/addCompany",
  COMPANYMASTER_DETAILS_BYID:
    serverVars.MASTER_API + "company/getCompanyDetailsById",
  COMPANYMASTER_UPDATE: serverVars.MASTER_API + "company/updateCompany",
  COMPANYMASTER_DATATABLE: serverVars.MASTER_API + "company/getAllCompanyList",
  VEHICLETYPE_MASTER_DATATABLE:
    serverVars.MASTER_API + "vehicle/getAllVehicles",
  VEHICLETYPE_MASTER_ADD: serverVars.MASTER_API + "vehicle/addVehicle",
  VEHICLETYPE_MASTER_UPDATE: serverVars.MASTER_API + "vehicle/updateVehicle",
  VEHICLETYPE_MASTER_DETAILS_BYID:
    serverVars.MASTER_API + "vehicle/getVehicleById",
  QUALITY_MASTER_DATA_TABLE:
    serverVars.MASTER_API + "qualityPriceMaster/getAllQualityMasterList",
  QUALITY_MASTER_ADD:
    serverVars.MASTER_API + "qualityPriceMaster/addQualityPriceMaster",
  QUALITY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "qualityPriceMaster/getJuteQualityPriceMasterById",
  QUALITY_MASTER_UPDATE:
    serverVars.MASTER_API + "qualityPriceMaster/updateQualityPriceMaster",

  RATE_QUALITY_LIST:
    serverVars.MASTER_API + "qualityPriceMaster/inputRateQualityList",
  Add_Daily_Jute_Rate: serverVars.PO_API + "api/v1/addDailyJuteRate",

  MUKAM_MASER_DATA_TABLE: serverVars.MASTER_API + "mmukam/getAllMukamList",
  MUKAM_MASTER_ADD: serverVars.MASTER_API + "mmukam/addMukam",
  MUKAM_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "mmukam/getMukamDetailsById/",
  MUKAM_MASTER_UPDATE: serverVars.MASTER_API + "mmukam/updateMukamList",
  BROKER_MASTER_TABLE_LIST: serverVars.MASTER_API + "mbroker/getAllBrokerList",
  BROKER_MASTER_ADD: serverVars.MASTER_API + "mbroker/addBroker", //add
  BROKER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "mbroker/getBrokerDetailsById",
  BROKER_MASTER_UPDATE: serverVars.MASTER_API + "mbroker/updateBroker", // update
  REPORTS_JUTE_WITH_VALUE: serverVars.REPORTS_API + "vowJutereportsApi",
  EMP_BANK_STATEMENT: serverVars.REPORTS_API + "vowJutereportsApi",
  DESIGNATION_MASTER_TABLE:
    serverVars.MASTER_API + "mdesignation/getAllDesignationList",
  DESIGNATION_MASTER_ADD: serverVars.MASTER_API + "mdesignation/addDesignation",
  DESIGNATION_MASTER_UPDATE:
    serverVars.MASTER_API + "mdesignation/updateDesignation",
  DESIGNATION_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "mdesignation/getDesignationDetailsById",
  SELECT_DESIGNATION:
    serverVars.MASTER_API + "mdesignation/getDesignationSelectionList/",
  SELECT_CUSTOMER_BRANCH: serverVars.SECURITY_API + "CustomerBranch/AllBranch/",

  AGENT_MASTER_TABLE_LIST:
    serverVars.MASTER_API + "agent/companyAgentsTableList",
  AGENT_COMPANY_LIST:
    serverVars.MASTER_API + "company/companyForAgentsSelectionList",
  AGENT_MASTER_ADD: serverVars.MASTER_API + "agent/addCompanyAgent",
  JUUTE_MR_PRINT_DETAILS: serverVars.PO_API + "api/v1/getMRPrint",
  JUUTE_MR_AGENT_PRINT_DETAILS: serverVars.PO_API + "api/v1/getAgentMRPrint",
  BRANCHMASTER_DATATABLE:
    serverVars.MASTER_API + "branchMaster/getAllBranchList",
  BRANCHMASTER_ADD: serverVars.MASTER_API + "branchMaster/addBranchList",
  BRANCHMASTER_UPDATE: serverVars.MASTER_API + "branchMaster/updateBranchList",
  BRANCHMASTER_DETAILS_BYID:
    serverVars.MASTER_API + "branchMaster/getBranchDetailsById",
  SELECT_BRANCH: serverVars.MASTER_API + "branchMaster/getBranchSelectionList/",
  ITEM_GROUP_MATSER_ADD: serverVars.MASTER_API + "itemgroup/addItemGroup",
  ITEM_GROUP_MASTER_TABLE:
    serverVars.MASTER_API + "itemgroup/itemGroupTableList",
  ITEM_MASTER_TABLE: serverVars.MASTER_API + "item/getMasterItemList",
  ITEM_MASTER_ADD: serverVars.MASTER_OLD + "item/addItemMaster",
  ITEM_MASTER_DETAILS_BYID: serverVars.MASTER_API + "item/getItemsById/",
  SUPPLIER_MASTER_ADD:
    serverVars.MASTER_API + "mastersupplier/addSupplierMaster",
  SUPPLIER_MASTER_UPDATE:
    serverVars.MASTER_API + "mastersupplier/updateSupplierMaster",
  GET_SIPPLIER_DETAILS_BY_ID:
    serverVars.MASTER_API + "mastersupplier/getSupplierById/",
  ITEM_MASTER_UPDATE: serverVars.MASTER_OLD + "item/updateItmMaster",
  ITEM_GROUP_MASTER_UPDATE: serverVars.MASTER_API + "itemgroup/updateItemGroup",
  ITEM_GROUP_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "itemgroup/getItemGroupById",
  SUNDAY_WEAVING_SUMMARY:
    serverVars.SECURITY_API + "sundaywages/getWeavingSummary",
  SUNDAY_WEAVING_TICKETS:
    serverVars.SECURITY_API + "sundaywages/getWeavingTickets",
  SUNDAY_WINDING_SUMMARY:
    serverVars.SECURITY_API + "sundaywages/getWindingSummary",
  SUNDAY_WINDING_TICKETS:
    serverVars.SECURITY_API + "sundaywages/getWindingTickets",
  YARN_MASTER_ADD: serverVars.MASTER_API + "yarn/addYarnMaster",
  YARN_MASTER_DETAILS_BY_ID: serverVars.MASTER_API + "yarn/getYarnMasterById",
  YARN_MASTER_TABLE: serverVars.MASTER_API + "yarn/getYarnMasterList",
  YARN_MASTER_UPDATE: serverVars.MASTER_API + "yarn/updateYarnMaster",
  COMPANY_LIST_BY_ORG_ID:
    serverVars.MASTER_API + "company/organizationMappedCompanies",
  MR_MAIL_SERVICE: serverVars.PO_API_OLD + "api/v1/sendMRMailToSupplier",
  PO_MAIL_SERVICE: serverVars.PO_API_OLD + "api/v1/sendPOMailToSupplier",
  UPDATE_EMAIL_CONFIGURATION:
    serverVars.MASTER_API + "company/updateEmailConfiguration",
  GET_EMAIL_CONFIGURATION:
    serverVars.MASTER_API + "company/getEmailConfigDetails",
  UPDATE_AGENT_MR_DETAILS: serverVars.PO_API + "api/v1/updateAgentMRDetails",
  WORKER_MASTER_TABLE: serverVars.MASTER_API + "workerMaster/getWorkersList",
  WORKER_MASTER_DETAIL_BY_ID:
    serverVars.MASTER_API + "workerMaster/getWorkerDetailsById/",
  WORKER_MASTER_DETAIL_BY_EBNO:
    serverVars.MASTER_API + "workerMaster/getWorkersByEbNo/",
  SEARCH_CATEGORIES: serverVars.MASTER_API + "cateogory/getCategories/",
  SEARCH_OCCUPATION: serverVars.MASTER_API + "occupations/getOccupations/",
  WORKER_MASTER_ADD: serverVars.HRMS_API + "workerMaster/addWorkerDetails",
  WORKER_MASTER_UPDATE:
    serverVars.HRMS_API + "workerMaster/updateWorkerDetails",
  // SELECT_CUSTOMER_BRANCH: serverVars.SECURITY_API + "CustomerBranch/AllBranch/",
  UPLOAD_INVOICE: serverVars.PO_API_OLD + "api/v1/uploadBillPassDoc",
  UPLOAD_CHALLAN: serverVars.PO_API_OLD + "api/v1/uploadBillPassChallan",
  MACHINE_MASTER: serverVars.MASTER_API + "machinemaster/getAllMachineMaster",
  MACHINE_MASTER_ADD: serverVars.MASTER_API + "machinemaster/addMachineMaster",
  MACHINE_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "machinemaster/getMachineMasterById/",
  MACHINE_MASTER_UPDATE:
    serverVars.MASTER_API + "machinemaster/updateMachineMaster",
  MACHINE_MASTER_SELECTION:
    serverVars.MASTER_API + "machinemaster/getMachineMasterList/",
  COST_CENTER_MASTER:
    serverVars.MASTER_API + "CostCenterMaster/getAllCostCenterList",
  COST_CENTER_MASTER_ADD:
    serverVars.MASTER_API + "CostCenterMaster/addCostCenter",
  COST_CENTER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "CostCenterMaster/getCostCenterById/",
  COST_CENTER_MASTER_UPDATE:
    serverVars.MASTER_API + "CostCenterMaster/updateCostCenter",
  LEDGER_MASTER: serverVars.MASTER_API + "ledgerMaster/getAllLedgerMaster",
  LEDGER_MASTER_ADD: serverVars.MASTER_API + "ledgerMaster/addLedger",
  LEDGER_MASTER_UPDATE: serverVars.MASTER_API + "ledgerMaster/updateLedger",
  LEDGER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "ledgerMaster/getLedgerById/",
  WORKER_CATEGORY_MASTER: serverVars.MASTER_API + "category/getAllCategories",
  WORKER_CATEGORY_MASTER_ADD: serverVars.MASTER_API + "category/addCategories",
  WORKER_CATEGORY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "category/getCategoryById/",
  WORKER_CATEGORY_MASTER_UPDATE:
    serverVars.MASTER_API + "category/updateCategory",
  WEAVING_QUALITY_MASTER:
    serverVars.MASTER_API + "weavingQualityMaster/getWeavingQualities",
  WEAVING_QUALITY_MASTER_ADD:
    serverVars.MASTER_API + "weavingQualityMaster/addWeavingQualities",
  WEAVING_QUALITY_MASTER_UPDATE:
    serverVars.MASTER_API + "weavingQualityMaster/updateWeavingQuality",
  WEAVING_QUALITY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "weavingQualityMaster/getWeavingQualityById",
  SELECT_PARENT_LEDGER_LIST:
    serverVars.MASTER_API + "ledgerMaster/getParentLedgerList/",
  SELECT_LEDGER_GROUP_LIST:
    serverVars.MASTER_API + "ledgerGroup/getLedgerGroupList/",
  LEDGER_GROUP_MASTER: serverVars.MASTER_API + "ledgerGroup/getAllLedgerGroup",
  LEDGER_GROUP_MASTER_ADD: serverVars.MASTER_API + "ledgerGroup/addLedgerGroup",
  LEDGER_GROUP_MASTER_UPDATE:
    serverVars.MASTER_API + "ledgerGroup/updateLedgerGroup",
  LEDGER_GROUP_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "ledgerGroup/getLedgerGroupById/",
  SUB_DEPARTMENT_MASTER:
    serverVars.MASTER_API + "subDepartmentMaster/getAllSubDepartments",
  SUB_DEPARTMENT_MASTER_ADD:
    serverVars.MASTER_API + "subDepartmentMaster/addSubDepartment",
  SUB_DEPARTMENT_MASTER_UPDATE:
    serverVars.MASTER_API + "subDepartmentMaster/updateSubDepartment",
  SUB_DEPARTMENT_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "subDepartmentMaster/getSubDepartmentById/",
  SELECT_SUB_DEPARTMENT_MASTER:
    serverVars.MASTER_API +
    "subDepartmentMaster/getSubDepartmentSelectionListByCompany/",
  SELECT_WORKER_CATEGORY: serverVars.MASTER_API + "category/getCategories/",
  TRAVEL_CATEGORY_MASTER:
    serverVars.MASTER_API + "travelController/getAllTravelCategories",
  TRAVEL_CATEGORY_MASTER_ADD:
    serverVars.MASTER_API + "travelController/addTravelCategory",
  TRAVEL_CATEGORY_MASTER_UPDATE:
    serverVars.MASTER_API + "travelController/updateTravelCategory",
  TRAVEL_CATEGORY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "travelController/getTravelCategoryById",
  BANK_ACCOUNTS_MASTER:
    serverVars.MASTER_API + "bankAccountsController/getAllBankAccountsDetails",
  BANK_ACCOUNTS_MASTER_ADD:
    serverVars.MASTER_API + "bankAccountsController/addBankAccount",
  BANK_ACCOUNTS_MASTER_UPDATE:
    serverVars.MASTER_API + "bankAccountsController/updateBankAccount",
  BANK_ACCOUNTS_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "bankAccountsController/getBankAccountById",
  CUSTOMER_MASTER: serverVars.MASTER_API + "customerMaster/getAllCustomers",
  CUSTOMER_MASTER_ADD: serverVars.MASTER_API + "customerMaster/addCustomer",
  CUSTOMER_MASTER_UPDATE:
    serverVars.MASTER_API + "customerMaster/updateCustomer",
  CUSTOMER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "customerMaster/getCustomerById/",
  HOLIDAY_MASTER: serverVars.MASTER_API + "holidaymaster/getAllHolidayMaster",
  HOLIDAY_MASTER_ADD: serverVars.MASTER_API + "holidaymaster/addHolidayMaster",
  HOLIDAY_MASTER_DELETE:
    serverVars.MASTER_API + "holidaymaster/deleteHolidayMaster/",
  HOLIDAY_MASTER_UPDATE:
    serverVars.MASTER_API + "holidaymaster/updateHolidayMaster",
  JUTE_STOCK_WIDGET: serverVars.REPORTS_API + "vowJutereportsApi",
  STORE_REPORTS_INDENT:
    serverVars.STORE_REPORTS + "storeindent/getIndentReport",
  STORE_INDENT_REPIRT_FOR_ALL_COMPANY:
    serverVars.STORE_REPORTS +
    "storeindent/getIndentReportDetailsForAllCompany",
  STORE_INDENT_REPIRT_FOR_ALL_COMPANY_WITH_QTY:
    serverVars.STORE_REPORTS +
    "storeindent/getIndentReportDetailsWithQtyForAllCompany",
  PO_REPORT_FOR_ALL_COMPANY_WITH_QTY:
    serverVars.STORE_REPORTS +
    "storereport/getPOReportDetailsWithQtyForAllCompany",
  GET_BATCH_PLAN_LIST: serverVars.PO_API + "getBatchPlanList",
  BATCH_PLAN_ADD: serverVars.PO_API + "addBatchPlan",
  BATCH_PLAN_DETAIL_ADD: serverVars.PO_API + "addBatchPlanDailyImpl",
  BATCH_PLAN_DETAILS_PLAN_CODE: serverVars.PO_API + "getBatchPlanData",
  BATCH_PLAN_DETAIL_DELETE: serverVars.PO_API + "inActiveBatchPlanDailyImpl",
  SELECT_BATCH_PLAN: serverVars.PO_API + "getPlanNames/",
  ITEM_WISE_INDENT_REPORT:
    serverVars.STORE_REPORTS + "storeindent/getItemwiseIndentReport",
  INDENT_WAITING_FOR_PO_REPORT:
    serverVars.STORE_REPORTS + "storeindent/getIndentSummaryWaitingForPO",
  INDENT_DETAIL_REPORT:
    serverVars.STORE_REPORTS + "storeindent/indentDetailReport",
  ISSUE_CHECK_LIST_REPORT:
    serverVars.STORE_REPORTS + "storeissue/issuechecklistreport",
  ACCOUNT_BILLS_ADD: serverVars.PO_API + "api/v1/addBillPass",
  ACCOUNT_BILLS_TABLE: serverVars.PO_API + "api/v1/getManualBillList",
  ACCOUNT_BILLS_BY_ID: serverVars.PO_API + "api/v1/getManualBillByNo/",
  ACCOUNT_BILLS_UPDATE: serverVars.PO_API + "api/v1/updateManualBill",
  ACCOUNT_BILLS_DELETE: serverVars.PO_API + "api/v1/deleteBillPass/",
  GET_TRANSACTIONS_TABLE_LIST: serverVars.PO_API + "api/v1/getTransactionsList",
  ACCOUNT_TRANSACTIONS_ADD: serverVars.PO_API + "api/v1/addTransactions",
  ACCOUNT_TRANSACTIONS_UPDATE: serverVars.PO_API + "api/v1/updateTransactions",
  ACCOUNT_TRANSACTIONS_BY_ID: serverVars.PO_API + "api/v1/getTransactionsById/",
  ACCOUNT_TRANSACTION_DELETE: serverVars.PO_API + "api/v1/delTransaction/",
  ACCOUNT_APPROVED_BILLS: serverVars.PO_API + "api/v1/getTraApprovedBills/",
  QUOTATIONS_ADD: serverVars.PO_API + "api/v1/addQuotes",
  QUOTATIONS_LIST: serverVars.PO_API + "api/v1/getQuoteList",
  QUOTATIONS_UPDATE: serverVars.PO_API + "api/v1/updateQuotes",
  QUOTATIONS_DETAILS_BY_ID: serverVars.PO_API + "api/v1/getQuoteByNo/",
  INVOICE_LIST: serverVars.PO_API + "api/v1/getInvoiceList",
  INVOICE_ADD: serverVars.PO_API + "api/v1/addInvoice",
  INVOICE_UPDATE: serverVars.PO_API + "api/v1/updateInvoice",
  INVOICE_DETAILS_BY_ID: serverVars.PO_API + "api/v1/getInvoiceByNo/",
  APPROVED_INVOICES: serverVars.PO_API + "api/v1//",
  QUOTATIONS_MAIL_SERVICE: serverVars.PO_API + "api/v1/sendQuoteMail",
  INVOICE_MAIL_SERVICE: serverVars.PO_API_OLD + "api/v1/sendInvoiceMail",
  SELECTION_PAY_SCHEME:
    serverVars.MASTER_API + "payscheme/getPaySchemeSelectionListByCompanyId/",
  WORKERS_EB_LIST_SELECTION:
    serverVars.MASTER_API + "workerMaster/getWorkersEbIdList/",
  TABLE_WORKERS_LIST: serverVars.MASTER_API + "workerMaster/getWorkersList",
  BEAMING_PRODUCTION_TABLE: serverVars.PO_API + "api/v1/getBeamingProduction",
  BEAMING_PRODUCTION_DELETE:
    serverVars.PO_API + "api/v1/deleteBeamingProduction/",
  BEAMING_PRODUCTION_ADD: serverVars.PO_API + "api/v1/addBeamingProduction",
  SELECT_CONTRACTOR:
    serverVars.MASTER_API + "contractor/getContractorSelectionList/",
  WORKER_EDUCATION_DETAILS_ADD:
    serverVars.MASTER_API + "workersubDetails/addWorkerEducationDetails",
  WORKER_EDUCATION_DETAILS_DELETE:
    serverVars.MASTER_API + "workersubDetails/deleteWorkerEducationDetails/",
  WORKER_DOCUMENT_DETAILS_ADD:
    serverVars.HRMS_API + "workersubDetails/addWorkerDocumentDetails/",
  WORKER_DOCUMENT_DETAILS_DELETE:
    serverVars.MASTER_API + "workersubDetails/deleteWorkerDocumentDetailsId/",
  WORKER_LEVEL_SELECTION:
    serverVars.MASTER_API + "workerLevel/getWorkersHierarchySelectionList/",
  WEAVING_PRODUCTION_ADD: serverVars.PO_API + "api/v1/addWeavingProduction",
  WEAVING_PRODUCTION_TABLE: serverVars.PO_API + "api/v1/getWeavingProduction",
  CUTS_JUGAR_PRODUCTION_ADD: serverVars.PO_API + "api/v1/addCutsJugar",
  WEAVING_PRODUCTION_DELETE:
    serverVars.PO_API + "api/v1/deleteWeavingProduction/",
  WEAVING_GET_CUTS: serverVars.PO_API + "api/v1/getCutsFromCutsBuff",
  CUTS_JUGAR_PRODUCTION_DELETE: serverVars.PO_API + "api/v1/deleteCutsJugar/",

  SELECT_WORKER_BIO_METRIC:
    serverVars.MASTER_API + "biometricmaster/getBiometricmasterSelectionList",
  WORKER_BIOMETRIC_ADD:
    serverVars.MASTER_API + "biometricEmpMap/addBiometricEmpMap",
  WORKER_BIOMETRIC_DELETE:
    serverVars.MASTER_API + "biometricEmpMap/deleteBiometricEmpMap/",
  WORKER_SHIFT_MAPPING_ADD:
    serverVars.MASTER_API + "employeeShiftMapping/addEmployeeShiftMapping",
  WORKER_SHIFT_MAPPING_DELETE:
    serverVars.MASTER_API + "employeeShiftMapping/deleteEmployeeShiftMapping/",
  SELECT_MACHINE_TYPES:
    serverVars.MASTER_API + "machinemaster/getAllMachineTypes",
  SELECT_SPINNING_MACHINE_TYPE:
    serverVars.MASTER_API + "machinemaster/getAllMachineTypesByVariant",
  GET_JUTE_ISSUE_NET_WEIGHT_BY_DATE:
    serverVars.PO_API + "getJuteIssueNetWeightByDate/",
  GET_SPINNING_YARN_MAPPING_LIST:
    serverVars.PO_API + "api/v1/getDailySpinningYarnMcMappingProd",
  WORKER_DAILY_ATTENDACE_TABLE:
    serverVars.MASTER_API + "dailyAttendance/getDailyAttendanceList",
  WORKER_DAILY_ATTENDANCE_ADD:
    serverVars.MASTER_API + "dailyAttendance/addWorkerDailyAttendance",
  WORKER_DAILY_ATTENDANE_DETAILS_BY_ID:
    serverVars.MASTER_API + "dailyAttendance/getWorkerDailyAttendanceById/",
  PAY_SCHEME_PARAMETERS_ADD: serverVars.MASTER_API + "payschemeparam/addParam",
  GET_PAY_SCHEME_PARAMETERS_CATEGORY:
    serverVars.MASTER_API + "payschemeparam/getParamsCategory",
  PAY_SCHEME_PARAMETERS_DETAILS_BYID:
    serverVars.MASTER_API + "parameters/getParamById",
  PAY_SCHEME_PARAMETERS_DELETE_BYID:
    serverVars.MASTER_API + "parameters/removeParam",
  PAY_SCHEME_PARAMETERS_UPDATE: serverVars.MASTER_API + "parameters/editParam",
  PAY_SCHEME_PARAMETERS_DATATABLE:
    serverVars.MASTER_API + "payschemeparam/getAllParams",
  WORKER_DAILY_ATTENDANCE_DETAILS_DELETE:
    serverVars.MASTER_API + "dailyAttendance/deleteWorkerDailyAttendance/",
  WORKER_DAILY_ATTENDANCE_UPDATE:
    serverVars.MASTER_API + "dailyAttendance/updateWorkerDailyAttendance",
  WORKER_DAILY_ATTENDANCE_SUMMARY_LIST:
    serverVars.MASTER_API + "dailyAttendance/getDailyAttendanceSummaryList",
  GET_LEAVE_TRANSACTIONS_LIST:
    serverVars.MASTER_API + "leavetransaction/getLeaveTransactionsList/",
  LEAVE_TRANSACTION_ADD:
    serverVars.MASTER_API + "leavetransaction/addLeaveTransaction",
  LEAVE_TYPES_SELECTION:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesSelectionList/",
  LEAVE_TRANSACTIONS_DETAILS_BY_ID:
    serverVars.MASTER_API + "leavetransaction/getLeaveTransactionById/",
  LEAVE_TRANSACTIONS_UPDATE:
    serverVars.MASTER_API + "leavetransaction/updateLeaveTransactions",
  UPDATE_MC_NO_IN_WINDING_PROD: serverVars.PO_API + "updateMcIdInWinProd",
  UPDATE_YARN_EB_MAPPING_PROD:
    serverVars.PO_API + "updateEbNoAndYarnIdInSpgProd",
  UPDATE_YARN_EB_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/updateEbNoInCutsJugarBuff1",
  UPDATE_PRODUCT_EFFICIENCY_MAPPING:
    serverVars.PO_API + "api/v1/updateProductionInCutsJugarBuff1",
  AGENT_MASTER_TABLE_LIST:
    serverVars.MASTER_API + "agent/companyAgentsTableList",
  AGENT_COMPANY_LIST:
    serverVars.MASTER_API + "company/companyForAgentsSelectionList",
  AGENT_MASTER_ADD: serverVars.MASTER_API + "agent/addCompanyAgent",
  JUUTE_MR_PRINT_DETAILS: serverVars.PO_API + "api/v1/getMRPrint",
  JUUTE_MR_AGENT_PRINT_DETAILS: serverVars.PO_API + "api/v1/getAgentMRPrint",
  BRANCHMASTER_DATATABLE:
    serverVars.MASTER_API + "branchMaster/getAllBranchList",
  BRANCHMASTER_ADD: serverVars.MASTER_API + "branchMaster/addBranchList",
  BRANCHMASTER_UPDATE: serverVars.MASTER_API + "branchMaster/updateBranchList",
  BRANCHMASTER_DETAILS_BYID:
    serverVars.MASTER_API + "branchMaster/getBranchDetailsById",
  SELECT_BRANCH: serverVars.MASTER_API + "branchMaster/getBranchSelectionList/",
  ITEM_GROUP_MATSER_ADD: serverVars.MASTER_API + "itemgroup/addItemGroup",
  ITEM_GROUP_MASTER_TABLE:
    serverVars.MASTER_API + "itemgroup/itemGroupTableList",
  ITEM_MASTER_TABLE: serverVars.MASTER_API + "item/getMasterItemList",
  ITEM_MASTER_ADD: serverVars.MASTER_OLD + "item/addItemMaster",
  ITEM_MASTER_DETAILS_BYID: serverVars.MASTER_API + "item/getItemsById/",
  SUPPLIER_MASTER_ADD:
    serverVars.MASTER_API + "mastersupplier/addSupplierMaster",
  SUPPLIER_MASTER_UPDATE:
    serverVars.MASTER_API + "mastersupplier/updateSupplierMaster",
  GET_SIPPLIER_DETAILS_BY_ID:
    serverVars.MASTER_API + "mastersupplier/getSupplierById/",
  ITEM_MASTER_UPDATE: serverVars.MASTER_OLD + "item/updateItmMaster",
  ITEM_GROUP_MASTER_UPDATE: serverVars.MASTER_API + "itemgroup/updateItemGroup",
  ITEM_GROUP_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "itemgroup/getItemGroupById",
  SUNDAY_WEAVING_SUMMARY:
    serverVars.SECURITY_API + "sundaywages/getWeavingSummary",
  SUNDAY_WEAVING_TICKETS:
    serverVars.SECURITY_API + "sundaywages/getWeavingTickets",
  SUNDAY_WINDING_SUMMARY:
    serverVars.SECURITY_API + "sundaywages/getWindingSummary",
  SUNDAY_WINDING_TICKETS:
    serverVars.SECURITY_API + "sundaywages/getWindingTickets",
  YARN_MASTER_ADD: serverVars.MASTER_API + "yarn/addYarnMaster",
  YARN_MASTER_DETAILS_BY_ID: serverVars.MASTER_API + "yarn/getYarnMasterById",
  YARN_MASTER_TABLE: serverVars.MASTER_API + "yarn/getYarnMasterList",
  YARN_MASTER_UPDATE: serverVars.MASTER_API + "yarn/updateYarnMaster",
  COMPANY_LIST_BY_ORG_ID:
    serverVars.MASTER_API + "company/organizationMappedCompanies",
  MR_MAIL_SERVICE: serverVars.PO_API_OLD + "api/v1/sendMRMailToSupplier",
  PO_MAIL_SERVICE: serverVars.PO_API_OLD + "api/v1/sendPOMailToSupplier",
  UPDATE_EMAIL_CONFIGURATION:
    serverVars.MASTER_API + "company/updateEmailConfiguration",
  GET_EMAIL_CONFIGURATION:
    serverVars.MASTER_API + "company/getEmailConfigDetails",
  UPDATE_AGENT_MR_DETAILS: serverVars.PO_API + "api/v1/updateAgentMRDetails",
  WORKER_MASTER_TABLE: serverVars.MASTER_API + "workerMaster/getWorkersList",
  WORKER_MASTER_DETAIL_BY_ID:
    serverVars.MASTER_API + "workerMaster/getWorkerDetailsById/",
  WORKER_MASTER_DETAIL_BY_EBNO:
    serverVars.MASTER_API + "workerMaster/getWorkersByEbNo/",
  SEARCH_CATEGORIES: serverVars.MASTER_API + "cateogory/getCategories/",
  SEARCH_OCCUPATION: serverVars.MASTER_API + "occupations/getOccupations/",
  WORKER_MASTER_ADD: serverVars.HRMS_API + "workerMaster/addWorkerDetails",
  WORKER_MASTER_UPDATE:
    serverVars.HRMS_API + "workerMaster/updateWorkerDetails",
  // SELECT_CUSTOMER_BRANCH: serverVars.SECURITY_API + "CustomerBranch/AllBranch/",
  UPLOAD_INVOICE: serverVars.PO_API_OLD + "api/v1/uploadBillPassDoc",
  UPLOAD_CHALLAN: serverVars.PO_API_OLD + "api/v1/uploadBillPassChallan",
  MACHINE_MASTER: serverVars.MASTER_API + "machinemaster/getAllMachineMaster",
  MACHINE_MASTER_ADD: serverVars.MASTER_API + "machinemaster/addMachineMaster",
  MACHINE_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "machinemaster/getMachineMasterById/",
  MACHINE_MASTER_UPDATE:
    serverVars.MASTER_API + "machinemaster/updateMachineMaster",
  MACHINE_MASTER_SELECTION:
    serverVars.MASTER_API + "machinemaster/getMachineMasterList/",
  COST_CENTER_MASTER:
    serverVars.MASTER_API + "CostCenterMaster/getAllCostCenterList",
  COST_CENTER_MASTER_ADD:
    serverVars.MASTER_API + "CostCenterMaster/addCostCenter",
  COST_CENTER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "CostCenterMaster/getCostCenterById/",
  COST_CENTER_MASTER_UPDATE:
    serverVars.MASTER_API + "CostCenterMaster/updateCostCenter",
  LEDGER_MASTER: serverVars.MASTER_API + "ledgerMaster/getAllLedgerMaster",
  LEDGER_MASTER_ADD: serverVars.MASTER_API + "ledgerMaster/addLedger",
  LEDGER_MASTER_UPDATE: serverVars.MASTER_API + "ledgerMaster/updateLedger",
  LEDGER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "ledgerMaster/getLedgerById/",
  WORKER_CATEGORY_MASTER: serverVars.MASTER_API + "category/getAllCategories",
  WORKER_CATEGORY_MASTER_ADD: serverVars.MASTER_API + "category/addCategories",
  WORKER_CATEGORY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "category/getCategoryById/",
  WORKER_CATEGORY_MASTER_UPDATE:
    serverVars.MASTER_API + "category/updateCategory",
  WEAVING_QUALITY_MASTER:
    serverVars.MASTER_API + "weavingQualityMaster/getWeavingQualities",
  WEAVING_QUALITY_MASTER_ADD:
    serverVars.MASTER_API + "weavingQualityMaster/addWeavingQualities",
  WEAVING_QUALITY_MASTER_UPDATE:
    serverVars.MASTER_API + "weavingQualityMaster/updateWeavingQuality",
  WEAVING_QUALITY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "weavingQualityMaster/getWeavingQualityById",
  SELECT_PARENT_LEDGER_LIST:
    serverVars.MASTER_API + "ledgerMaster/getParentLedgerList/",
  SELECT_LEDGER_GROUP_LIST:
    serverVars.MASTER_API + "ledgerGroup/getLedgerGroupList/",
  LEDGER_GROUP_MASTER: serverVars.MASTER_API + "ledgerGroup/getAllLedgerGroup",
  LEDGER_GROUP_MASTER_ADD: serverVars.MASTER_API + "ledgerGroup/addLedgerGroup",
  LEDGER_GROUP_MASTER_UPDATE:
    serverVars.MASTER_API + "ledgerGroup/updateLedgerGroup",
  LEDGER_GROUP_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "ledgerGroup/getLedgerGroupById/",
  SUB_DEPARTMENT_MASTER:
    serverVars.MASTER_API + "subDepartmentMaster/getAllSubDepartments",
  SUB_DEPARTMENT_MASTER_ADD:
    serverVars.MASTER_API + "subDepartmentMaster/addSubDepartment",
  SUB_DEPARTMENT_MASTER_UPDATE:
    serverVars.MASTER_API + "subDepartmentMaster/updateSubDepartment",
  SUB_DEPARTMENT_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "subDepartmentMaster/getSubDepartmentById/",
  SELECT_SUB_DEPARTMENT_MASTER:
    serverVars.MASTER_API +
    "subDepartmentMaster/getSubDepartmentSelectionListByCompany/",
  SELECT_WORKER_CATEGORY: serverVars.MASTER_API + "category/getCategories/",
  TRAVEL_CATEGORY_MASTER:
    serverVars.MASTER_API + "travelController/getAllTravelCategories",
  TRAVEL_CATEGORY_MASTER_ADD:
    serverVars.MASTER_API + "travelController/addTravelCategory",
  TRAVEL_CATEGORY_MASTER_UPDATE:
    serverVars.MASTER_API + "travelController/updateTravelCategory",
  TRAVEL_CATEGORY_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "travelController/getTravelCategoryById",
  BANK_ACCOUNTS_MASTER:
    serverVars.MASTER_API + "bankAccountsController/getAllBankAccountsDetails",
  BANK_ACCOUNTS_MASTER_ADD:
    serverVars.MASTER_API + "bankAccountsController/addBankAccount",
  BANK_ACCOUNTS_MASTER_UPDATE:
    serverVars.MASTER_API + "bankAccountsController/updateBankAccount",
  BANK_ACCOUNTS_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "bankAccountsController/getBankAccountById",
  CUSTOMER_MASTER: serverVars.MASTER_API + "customerMaster/getAllCustomers",
  CUSTOMER_MASTER_ADD: serverVars.MASTER_API + "customerMaster/addCustomer",
  CUSTOMER_MASTER_UPDATE:
    serverVars.MASTER_API + "customerMaster/updateCustomer",
  CUSTOMER_MASTER_DETAILS_BY_ID:
    serverVars.MASTER_API + "customerMaster/getCustomerById/",
  HOLIDAY_MASTER: serverVars.MASTER_API + "holidaymaster/getAllHolidayMaster",
  HOLIDAY_MASTER_ADD: serverVars.MASTER_API + "holidaymaster/addHolidayMaster",
  HOLIDAY_MASTER_DELETE:
    serverVars.MASTER_API + "holidaymaster/deleteHolidayMaster/",
  HOLIDAY_MASTER_UPDATE:
    serverVars.MASTER_API + "holidaymaster/updateHolidayMaster",
  JUTE_STOCK_WIDGET: serverVars.REPORTS_API + "vowJutereportsApi",
  STORE_REPORTS_INDENT:
    serverVars.STORE_REPORTS + "storeindent/getIndentReport",
  STORE_INDENT_REPIRT_FOR_ALL_COMPANY:
    serverVars.STORE_REPORTS +
    "storeindent/getIndentReportDetailsForAllCompany",
  STORE_INDENT_REPIRT_FOR_ALL_COMPANY_WITH_QTY:
    serverVars.STORE_REPORTS +
    "storeindent/getIndentReportDetailsWithQtyForAllCompany",
  PO_REPORT_FOR_ALL_COMPANY_WITH_QTY:
    serverVars.STORE_REPORTS +
    "storereport/getPOReportDetailsWithQtyForAllCompany",
  GET_BATCH_PLAN_LIST: serverVars.PO_API + "getBatchPlanList",
  BATCH_PLAN_ADD: serverVars.PO_API + "addBatchPlan",
  BATCH_PLAN_DETAIL_ADD: serverVars.PO_API + "addBatchPlanDailyImpl",
  BATCH_PLAN_DETAILS_PLAN_CODE: serverVars.PO_API + "getBatchPlanData",
  BATCH_PLAN_DETAIL_DELETE: serverVars.PO_API + "inActiveBatchPlanDailyImpl",
  SELECT_BATCH_PLAN: serverVars.PO_API + "getPlanNames/",
  ITEM_WISE_INDENT_REPORT:
    serverVars.STORE_REPORTS + "storeindent/getItemwiseIndentReport",
  INDENT_WAITING_FOR_PO_REPORT:
    serverVars.STORE_REPORTS + "storeindent/getIndentSummaryWaitingForPO",
  INDENT_DETAIL_REPORT:
    serverVars.STORE_REPORTS + "storeindent/indentDetailReport",
  ISSUE_CHECK_LIST_REPORT:
    serverVars.STORE_REPORTS + "storeissue/issuechecklistreport",
  ACCOUNT_BILLS_ADD: serverVars.PO_API + "api/v1/addBillPass",
  ACCOUNT_BILLS_TABLE: serverVars.PO_API + "api/v1/getManualBillList",
  ACCOUNT_BILLS_BY_ID: serverVars.PO_API + "api/v1/getManualBillByNo/",
  ACCOUNT_BILLS_UPDATE: serverVars.PO_API + "api/v1/updateManualBill",
  ACCOUNT_BILLS_DELETE: serverVars.PO_API + "api/v1/deleteBillPass/",
  GET_TRANSACTIONS_TABLE_LIST: serverVars.PO_API + "api/v1/getTransactionsList",
  ACCOUNT_TRANSACTIONS_ADD: serverVars.PO_API + "api/v1/addTransactions",
  ACCOUNT_TRANSACTIONS_UPDATE: serverVars.PO_API + "api/v1/updateTransactions",
  ACCOUNT_TRANSACTIONS_BY_ID: serverVars.PO_API + "api/v1/getTransactionsById/",
  ACCOUNT_TRANSACTION_DELETE: serverVars.PO_API + "api/v1/delTransaction/",
  ACCOUNT_APPROVED_BILLS: serverVars.PO_API + "api/v1/getTraApprovedBills/",
  QUOTATIONS_ADD: serverVars.PO_API + "api/v1/addQuotes",
  QUOTATIONS_LIST: serverVars.PO_API + "api/v1/getQuoteList",
  QUOTATIONS_UPDATE: serverVars.PO_API + "api/v1/updateQuotes",
  QUOTATIONS_DETAILS_BY_ID: serverVars.PO_API + "api/v1/getQuoteByNo/",
  INVOICE_LIST: serverVars.PO_API + "api/v1/getInvoiceList",
  INVOICE_ADD: serverVars.PO_API + "api/v1/addInvoice",
  INVOICE_UPDATE: serverVars.PO_API + "api/v1/updateInvoice",
  INVOICE_DETAILS_BY_ID: serverVars.PO_API + "api/v1/getInvoiceByNo/",
  APPROVED_INVOICES: serverVars.PO_API + "api/v1//",
  QUOTATIONS_MAIL_SERVICE: serverVars.PO_API + "api/v1/sendQuoteMail",
  INVOICE_MAIL_SERVICE: serverVars.PO_API_OLD + "api/v1/sendInvoiceMail",
  SELECTION_PAY_SCHEME:
    serverVars.MASTER_API + "payscheme/getPaySchemeSelectionListByCompanyId/",
  WORKERS_EB_LIST_SELECTION:
    serverVars.MASTER_API + "workerMaster/getWorkersEbIdList/",
  TABLE_WORKERS_LIST: serverVars.MASTER_API + "workerMaster/getWorkersList",
  BEAMING_PRODUCTION_TABLE: serverVars.PO_API + "api/v1/getBeamingProduction",
  BEAMING_PRODUCTION_DELETE:
    serverVars.PO_API + "api/v1/deleteBeamingProduction/",
  BEAMING_PRODUCTION_ADD: serverVars.PO_API + "api/v1/addBeamingProduction",
  SELECT_CONTRACTOR:
    serverVars.MASTER_API + "contractor/getContractorSelectionList/",
  WORKER_EDUCATION_DETAILS_ADD:
    serverVars.MASTER_API + "workersubDetails/addWorkerEducationDetails",
  WORKER_EDUCATION_DETAILS_DELETE:
    serverVars.MASTER_API + "workersubDetails/deleteWorkerEducationDetails/",
  WORKER_DOCUMENT_DETAILS_ADD:
    serverVars.HRMS_API + "workersubDetails/addWorkerDocumentDetails/",
  WORKER_DOCUMENT_DETAILS_DELETE:
    serverVars.MASTER_API + "workersubDetails/deleteWorkerDocumentDetailsId/",
  WORKER_LEVEL_SELECTION:
    serverVars.MASTER_API + "workerLevel/getWorkersHierarchySelectionList/",
  WEAVING_PRODUCTION_ADD: serverVars.PO_API + "api/v1/addWeavingProduction",
  WEAVING_PRODUCTION_TABLE: serverVars.PO_API + "api/v1/getWeavingProduction",
  CUTS_JUGAR_PRODUCTION_ADD: serverVars.PO_API + "api/v1/addCutsJugar",
  WEAVING_PRODUCTION_DELETE:
    serverVars.PO_API + "api/v1/deleteWeavingProduction/",
  WEAVING_GET_CUTS: serverVars.PO_API + "api/v1/getCutsFromCutsBuff",
  CUTS_JUGAR_PRODUCTION_DELETE: serverVars.PO_API + "api/v1/deleteCutsJugar/",
  SELECT_WORKER_BIO_METRIC:
    serverVars.MASTER_API + "biometricmaster/getBiometricmasterSelectionList",
  WORKER_BIOMETRIC_ADD:
    serverVars.MASTER_API + "biometricEmpMap/addBiometricEmpMap",
  WORKER_BIOMETRIC_DELETE:
    serverVars.MASTER_API + "biometricEmpMap/deleteBiometricEmpMap/",
  WORKER_SHIFT_MAPPING_ADD:
    serverVars.MASTER_API + "employeeShiftMapping/addEmployeeShiftMapping",
  WORKER_SHIFT_MAPPING_DELETE:
    serverVars.MASTER_API + "employeeShiftMapping/deleteEmployeeShiftMapping/",
  SELECT_MACHINE_TYPES:
    serverVars.MASTER_API + "machinemaster/getAllMachineTypes",
  GET_JUTE_ISSUE_NET_WEIGHT_BY_DATE:
    serverVars.PO_API + "getJuteIssueNetWeightByDate/",
  GET_SPINNING_YARN_MAPPING_LIST:
    serverVars.PO_API + "api/v1/getDailySpinningYarnMcMappingProd",
  WORKER_DAILY_ATTENDACE_TABLE:
    serverVars.MASTER_API + "dailyAttendance/getDailyAttendanceList",
  WORKER_DAILY_ATTENDANCE_ADD:
    serverVars.MASTER_API + "dailyAttendance/addWorkerDailyAttendance",
  WORKER_DAILY_ATTENDANE_DETAILS_BY_ID:
    serverVars.MASTER_API + "dailyAttendance/getWorkerDailyAttendanceById/",
  PAY_SCHEME_PARAMETERS_ADD: serverVars.MASTER_API + "payschemeparam/addParam",
  GET_PAY_SCHEME_PARAMETERS_CATEGORY:
    serverVars.MASTER_API + "payschemeparam/getParamsCategory",
  PAY_SCHEME_PARAMETERS_DETAILS_BYID:
    serverVars.MASTER_API + "parameters/getParamById",
  PAY_SCHEME_PARAMETERS_DELETE_BYID:
    serverVars.MASTER_API + "parameters/removeParam",
  PAY_SCHEME_PARAMETERS_UPDATE: serverVars.MASTER_API + "parameters/editParam",
  PAY_SCHEME_PARAMETERS_DATATABLE:
    serverVars.MASTER_API + "payschemeparam/getAllParams",
  WORKER_DAILY_ATTENDANCE_DETAILS_DELETE:
    serverVars.MASTER_API + "dailyAttendance/deleteWorkerDailyAttendance/",
  WORKER_DAILY_ATTENDANCE_UPDATE:
    serverVars.MASTER_API + "dailyAttendance/updateWorkerDailyAttendance",
  WORKER_DAILY_ATTENDANCE_SUMMARY_LIST:
    serverVars.MASTER_API + "dailyAttendance/getDailyAttendanceSummaryList",
  GET_LEAVE_TRANSACTIONS_LIST:
    serverVars.MASTER_API + "leavetransaction/getLeaveTransactionsList/",
  LEAVE_TRANSACTION_ADD:
    serverVars.MASTER_API + "leavetransaction/addLeaveTransaction",
  LEAVE_TYPES_SELECTION:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesSelectionList/",
  LEAVE_TRANSACTIONS_DETAILS_BY_ID:
    serverVars.MASTER_API + "leavetransaction/getLeaveTransactionById/",
  LEAVE_TRANSACTIONS_UPDATE:
    serverVars.MASTER_API + "leavetransaction/updateLeaveTransactions",
  UPDATE_MC_NO_IN_WINDING_PROD: serverVars.PO_API + "updateMcIdInWinProd",
  UPDATE_YARN_EB_MAPPING_PROD:
    serverVars.PO_API + "updateEbNoAndYarnIdInSpgProd",
  UPDATE_YARN_EB_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/updateEbNoInCutsJugarBuff1",
  UPDATE_PRODUCT_EFFICIENCY_MAPPING:
    serverVars.PO_API + "api/v1/updateProductionInCutsJugarBuff1",

  GET_DAILY_WEAVING_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/getDailyWeavingQualityMapping",
  LIST_OF_ONFLOOR_STOCK: serverVars.PO_API + "ListOfOnFloorStock/",
  LEAVE_TYPE_DATATABLE: serverVars.MASTER_API + "LeaveTypes/getLeaveTypesList",
  LEAVE_TYPE_ADD: serverVars.MASTER_API + "LeaveTypes/postLeaveTypes",
  LEAVE_TYPE_UPDATE: serverVars.MASTER_API + "LeaveTypes/editLeaveTypes",
  LEAVE_TYPE_DETAILS_BYID:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesById",
  LEAVE_TYPE_SELECTION_LIST:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesSelectionList",
  LEAVE_POLICIES_ADD: serverVars.MASTER_API + "leavepolicy/addLeavePolicy",
  LEAVE_POLICIES_UPDATE:
    serverVars.MASTER_API + "leavepolicy/updateLeavePolicy",
  LEAVE_POLICIES_LIST: serverVars.MASTER_API + "leavepolicy/getLeavePolicyList",
  LEAVE_POLICIES_GET_BYID:
    serverVars.MASTER_API + "leavepolicy/getLeavePolicyById",
  LEAVE_POLICIES_DELETE_BYID:
    serverVars.MASTER_API + "leavepolicy/deleteLeavePolicyById",
  UPDATE_DAILY_WEAVING_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/UpadteDailyWeavingQualityMapping",
  UPDATE_SPINNING_YARN_MAPPING_LIST:
    serverVars.PO_API + "api/v1/updateSpinningYarnMappingList",
  CLIENT_VISIT_LIST:
    serverVars.MASTER_API + "clientvisitplan/getClientVisitPlanList",
  CLIENT_VISIT_ADD:
    serverVars.MASTER_API + "clientvisitplan/postClientVisitPlan",
  CLIENT_VISIT_UPDATE:
    serverVars.MASTER_API + "clientvisitplan/editclientVisitPlan",
  CLIENT_VISIT_GET_BYID:
    serverVars.MASTER_API + "clientvisitplan/getClientVisitPlanById",
  EXPENSE_BOOKING_ADD:
    serverVars.MASTER_API + "expensesBooking/addExpenseBooking",
  EXPENSE_BOOKING_UPDATE:
    serverVars.MASTER_API + "expensesBooking/updateExpenseBooking",
  EXPENSE_BOOKING_GET_BYID:
    serverVars.MASTER_API + "expensesBooking/getExpenseBookingByyId",
  EXPENSE_BOOKING_LIST:
    serverVars.MASTER_API + "expensesBooking/getExpenseBookingList",
  WORKER_DAILY_ATTENDANCE_SUMMARY:
    serverVars.MASTER_API + "dailyAttendance/getAttendanceSummaryByEbId",
  WORKER_DAILY_BIOMETRIC_LOGS:
    serverVars.MASTER_API + "biometriclogs/getLastUpdatedBioMetricLogs",
  LEAVE_REQUEST_AVAILABILITY:
    serverVars.MASTER_API + "LeaveLedger/getLeaveLedgerList",
  LEAVE_POLICY_MAPPING_ADD:
    serverVars.MASTER_API + "leavePolicymap/postEmpLeavePolicyMapping",
  LEAVE_POLICY_MAPPING_UPDATE:
    serverVars.MASTER_API + "leavePolicymap/updateEmpLeavePolicyMapping",
  LEAVE_POLICY_MAPPING_DELETE:
    serverVars.MASTER_API + "leavePolicymap/deleteEmpLeavePolicyById/",
  FORMULA_MASTER_ADD: serverVars.MASTER_API + "formula/addFormula",
  FORMULA_MASTER_LIST: serverVars.MASTER_API + "formula/getAllFormulas",
  ADVANCE_REQUEST_LIST_TABLE:
    serverVars.MASTER_API + "advancerequest/getAdvanceRequestList/",
  ADVANCE_REQUEST_ADD:
    serverVars.MASTER_API + "advancerequest/addAdvanceRequest",
  ADVANCE_REQUEST_DETAILS_BY_ID:
    serverVars.MASTER_API + "advancerequest/getAdvanceRequestById/",
  ADVANCE_REQUEST_UPDATE:
    serverVars.MASTER_API + "advancerequest/updateAdvanceRequest",
  PAY_SCHEME_ADD: serverVars.MASTER_API + "PayScheme/addPayScheme",
  PAY_SCHEME_GET_LIST: serverVars.MASTER_API + "PayScheme/getAllPaySchemes",
  PAY_SCHEME_GET_BYID:
    serverVars.MASTER_API + "PayScheme/getPaySchemeByPaySchemeId",
  PAY_SCHEME_EDIT: serverVars.MASTER_API + "PayScheme/updatePayScheme",
  GET_EMPLOYEE_PAYSCHHME_BYID:
    serverVars.MASTER_API + "empPayscheme/getEmpPayschemeById",
  ADD_EMP_PAYSCHME:
    serverVars.MASTER_API + "PayEmployeeStructure/addPayEmployeeStructure",
  GET_SR_STOCK_LIST: serverVars.PO_API + "api/v1/getSrStockByDeptAndItemId",
  GET_BALANCE_STOCK_LIST:
    serverVars.PO_API + "api/v1/getBalStockByDeptAndItemId",
  GET_BRANCH_WISE_STOCK: serverVars.PO_API + "api/v1/getBranchWiseStock",
  GET_PAYSCHME_PROCESSING_REQUEST_LIST:
    serverVars.MASTER_API +
    "processingPayscheme/getPayschemeProcessingRequestList",
  CREATE_PAYSCHME_PROCESSING_REQUEST:
    serverVars.MASTER_API +
    "processingPayscheme/createPayschemeProcessingRequest",
  UPDATE_EMP_PAYSCHME:
    serverVars.MASTER_API + "PayEmployeeStructure/updatePayEmployeeStructure",
  ADD_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/addsalaryAdjustment",
  UPDATE_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/updatesalaryAdjustment",
  GET_ALL_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/getAllsalaryAdjustment",
  GET_BY_ID_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/getsalaryAdjustmentById",
  DELETE_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/removesalaryAdjustment",
  GET_MASTER_TYPE_LIST:
    serverVars.MASTER_OLD + "MasterType/getMasterTypeSelectionList",
  ADD_MASTER_DATA: serverVars.MASTER_OLD + "masterDataUpload/addMasterData",
  GET_MASTER_DATA_LIST:
    serverVars.MASTER_OLD + "masterDataUpload/getMasterDatalist",
  GET_MASTER_DATA_SAMPLE_FILE:
    serverVars.MASTER_OLD + "MasterType/getsamplefileURL",
  ADD_HELPTOPIC_MASTER:
    serverVars.MASTER_API + "HelpTopicMaster/addHelpTopicMaster",
  UPDATE_HELPTOPIC_MASTER:
    serverVars.MASTER_API + "HelpTopicMaster/editHelpTopicMaster",
  GET_HELPTOPIC_MASTER_BYID:
    serverVars.MASTER_API + "HelpTopicMaster/getHelpTopicMasterById",
  HELPTOPIC_MASTER_LIST:
    serverVars.MASTER_API + "HelpTopicMaster/getHelpTopicMasterList",
  ADD_PROBLEM_MASTER: serverVars.MASTER_API + "ProblemMaster/addProblemMaster",
  UPDATE_PROBLEM_MASTER:
    serverVars.MASTER_API + "ProblemMaster/editProblemMaster",
  GET_PROBLEM_MASTER_BYID:
    serverVars.MASTER_API + "ProblemMaster/getProblemMasterById",
  PROBLEM_MASTER_LIST:
    serverVars.MASTER_API + "ProblemMaster/getProblemMasterlist",
  GET_AVAILABLE_PAYSLIPS:
    serverVars.MASTER_API + "PayEmployeePayroll/getAvailablePayslips",
  GET_PAYSLIP: serverVars.MASTER_API + "PayEmployeePayroll/getpayslip",
  ADD_PACKAGE_MASTER: serverVars.MASTER_API + "PackageMaster/addPackageMaster",
  EDIT_PACKAGE_MASTER:
    serverVars.MASTER_API + "PackageMaster/updatePackageMaster",
  GET_PACKAGE_MASTER_BYID:
    serverVars.MASTER_API + "PackageMaster/getPackageMasterById",
  PACKAGE_MASTER_LIST:
    serverVars.MASTER_API + "PackageMaster/getPackageMasterList",
  DELETE_PACKAGE_MASTER:
    serverVars.MASTER_API + "PackageMaster/deletePackageMaster",
  ADD_CARRIER_MASTER: serverVars.MASTER_OLD + "WithCarriers/addWithCarrier",
  UPDATE_CARRIER_MASTER:
    serverVars.MASTER_OLD + "WithCarriers/updateWithCarrier",
  GET_CARRIER_MASTER_BYID:
    serverVars.MASTER_OLD + "WithCarriers/getWithCarrierById",
  CARRIER_MASTER_LIST:
    serverVars.MASTER_OLD + "WithCarriers/getWithCarrierlist",
  MACHINES_BY_DESIGNATION:
    serverVars.MASTER_OLD + "machinemaster/getMachineMasterByDesignation/",
  DAILY_ATTENDANCE_APPROVE:
    serverVars.MASTER_API + "dailyAttendance/approveDailyAttendanceList",
  ADD_UPDATE_MENU: "menu/addUpdateMenu",
  UPLOAD_EMP_IMAGE: serverVars.HRMS_API + "workerMaster/uploadEmpImage",
  GET_PARENT_MENU: "menu/getParentMenusByReqSource/",
  STORE_STOCK_REPORT:
    serverVars.STORE_REPORTS + "storereport/storeStockReport/",
  STORE_REJECT_REASON:
    serverVars.STORE_REPORTS + "storegate/getStoreGateItemsList",
  STORE_RECONCILIATION_REPORT:
    serverVars.STORE_REPORTS + "storereport/reconciliationReport",
  ADD_CUSTOMER_MAPPING:
    serverVars.CALL_MANAGEMENT +
    "CustomerPackageMapping/addCustomerPackageMapping",
  UPDATE_CUSTOMER_MAPPING:
    serverVars.MASTER_API + "CustomerPackageMapping/editCustomerPackageMapping",
  CUSTOMER_MAPPING_LIST:
    serverVars.MASTER_API +
    "CustomerPackageMapping/getAllCustomerPackageMapping",
  CUSTOMER_MAPPING_GET_BYID:
    serverVars.MASTER_API +
    "CustomerPackageMapping/getCustomerPackageMappingById",
  GET_PACKAGE_DETAILS:
    serverVars.MASTER_API + "CustomerPackageMapping/getSerialList",
  CUSTOMER_MASTER_BY_CONTACT:
    serverVars.MASTER_API + "customerMaster/getCustomerList",
  ADD_JOB: serverVars.MASTER_API + "jobs/addJobs",
  JOBS_LIST: serverVars.MASTER_API + "jobs/getJobslist",
  GET_SEARCH_FIELDS: serverVars.REPORTS_API + "menu/getMenuSearchfieldsByMenu/",
  GET_GRAPHS_DATA: serverVars.REPORTS_API + "menu/getGraphMenuData",
  UPDATE_CONFIGURATION: serverVars.PO_API + "api/v1/updateConfiguration",
  GET_CONFIGURATION_BY_COMPANY: serverVars.PO_API + "api/v1/getConfiguration/",
  TRANSPORT_MASTER_LIST:
    serverVars.MASTER_API + "transport/getTransportersList/",
  ADD_TRANSPORTER: serverVars.MASTER_API + "transport/addTransporter",
  UPDATE_TRANSPORTER: serverVars.MASTER_API + "transport/updateTransporter",
  GET_TRANSPORTER_BY_ID:
    serverVars.MASTER_API + "transport/getTransporterById/",
  PROJECT_MASTER_ADD: serverVars.MASTER_API + "project/addProjectMaster",
  PROJECT_MASTER_UPDATE: serverVars.MASTER_API + "project/updateProjectMaster",
  PROJECT_MASTER_LIST: serverVars.MASTER_API + "project/getProjectMasterList/",
  PROJECT_MASTER_GET_BY_ID:
    serverVars.MASTER_API + "project/getProjectMasterById/",
  STOCK_TRANSFER_LIST: serverVars.PO_API + "api/v1/getAllStoreTransferList",
  STOCK_TRANSFER_ADD: serverVars.PO_API + "api/v1/addStoreStockTransfer",
  STORE_RETURN_ADD: serverVars.PO_API + "api/v1/addReturn",
  STORE_RETURN_BY_ID: serverVars.PO_API + "api/v1/getReturnById",
  STORE_RETURN_UPDATE: serverVars.PO_API + "api/v1/updateReturn",
  PROJECT_MASTER_SELECTION_LIST:
    serverVars.MASTER_API + "project/getProjectMasterSelectionListCompany/",
  GET_STORE_ISSUE_LIST: serverVars.PO_API + "api/v1/getStoreIssueList",
  GET_CUSTOMER_BY_BRANCH:
    serverVars.MASTER_API + "customerMaster/getCustomerSelectionList/",
  GET_PAY_SUMMARY_LIST: serverVars.MASTER_API + "paysummary/getpaysummaryList",
  PRICE_REQUEST_LIST: serverVars.PO_API + "PriceMaster/getPriceMasterList",
  PRICE_REQUEST_ADD: serverVars.PO_API + "PriceMaster/addPriceMaster",
  PRICE_REQUEST_UPDATE: serverVars.PO_API + "PriceMaster/editPriceMaster",
  GET_PRICE_MASTER_BY_ID: serverVars.PO_API + "PriceMaster/getPriceMasterById/",
  GET_PRICE_REQUEST_RESPONSE_LIST:
    serverVars.PO_API + "PriceMaster/getListBySuppCode",
  UPDATE_PRICE_RESPONSE:
    serverVars.PO_API + "PriceMaster/priceRequestUpdateBySupplier",
  USER_MANAGEMENT_RESET_PASSWORD:
    serverVars.MASTER_API + "easybusiness/user/resetPassword",
  PAY_SCHEME_DATATABLE:
    serverVars.MASTER_API + "PayComponents/getAllPayComponents",
  PAY_SCHEME_ADD_PARAMS:
    serverVars.MASTER_API + "PayComponents/addPayComponents",
  PAY_SCHEME_DATA_GET_BYID:
    serverVars.MASTER_API + "PayComponents/getPayComponentsById",
  PAY_SCHEME_UPDATE_PARAMS:
    serverVars.MASTER_API + "PayComponents/updatePayComponents",
  PAY_PERIOD_ADD: serverVars.MASTER_API + "PayPeriod/addPayPeriod",
  GET_PAYPERIOD_LIST: serverVars.MASTER_API + "PayPeriod/getAllPayPeriods",
  UPLOAD_OR_PAY_PROCESS_PAY:
    serverVars.MASTER_API + "PayProcess/uploadOrPayProcessPay",
  CHECK_PAYSCHEME: serverVars.MASTER_API + "PayProcess/checkPayscheme",
  GET_PAY_PROCESSED_LIST:
    serverVars.MASTER_API + "PayEmployeePayroll/getProcessedList",
  GET_WAGE_TYPES: serverVars.SECURITY_API + "PayWagesMode/getAllPayWagesModes",
  PAY_PERIOD_BYID: serverVars.SECURITY_API + "PayPeriod/getPayPeriodById",
  UPDATE_PAY_PERIOD: serverVars.SECURITY_API + "PayPeriod/updatePayPeriod",
  GET_LAST_PURCHASE_RATE: serverVars.PO_API + "api/v1/getLastPurchaseRate/",
  GET_JUTE_LAST_MARKET_RATE:
    serverVars.PO_API + "api/v1/getLatestMarketJuteRate/",
  ITEM_SUPPLIER_MAPPING:
    serverVars.MASTER_API + "mastersupplier/itemSuppMapping",
  ITEM_MAX_NUMBER: serverVars.MASTER_API + "item/getMaxOfItemCode/",
  STOCK_RETURN_LIST: serverVars.PO_API + "api/v1/getReturnList",
  GET_PAY_PROCESS_TEMPLATE:
    serverVars.MASTER_API + "PayProcess/downloadPayTemplate",
  PENDING_STOCK: serverVars.PO_API + "api/v1/getPendingStock",
  GET_ALL_STATES: serverVars.MASTER_API + "state/getStateNameSelectionList",
  FILE_UPLOAD: serverVars.MASTER_API + "files/Uploadfile",
  GET_FILE_BY_SOURCE:
    serverVars.MASTER_API + "files/getFileBySourcemenuAndTaskIdAndCompanyId/",
  DELETE_FILE_BI_ID: serverVars.MASTER_API + "files/deleteFile/",
  GET_FILE_INFO: serverVars.MASTER_API + "files/getFileInfo/",
  UPDATE_FILE_INFO: serverVars.MASTER_API + "files/updateFile",
  PENDING_STOCK_AT_PO_RECEIPT:
    serverVars.PO_API + "api/v1/getPendingStockatPoAndReceipt",
  SALE_ORDER_LIST: serverVars.PO_API + "api/v1/getSalesOrderList",
  GET_SALE_ORDER_BY_ID: serverVars.PO_API + "api/v1/getSalesOrderByNo/",
  SALE_ORDER_ADD: serverVars.PO_API + "api/v1/addSalesOrder",
  SALE_ORDER_UPDATE: serverVars.PO_API + "api/v1/updateSalesOrder",
  PRODUCT_PROPERTY_ADD:
    serverVars.CALL_MANAGEMENT + "cmVariantProperties/addCMVariantProperties",
  PRODUCT_PROPERTY_UPDATE:
    serverVars.CALL_MANAGEMENT +
    "cmVariantProperties/updateCMVariantProperties",
  PRODUCT_PROPERTY_BY_ID:
    serverVars.CALL_MANAGEMENT +
    "cmVariantProperties/getCMVariantPropertiesById/",
  PRODUCT_PROPERTY_LIST:
    serverVars.CALL_MANAGEMENT +
    "cmVariantProperties/getCMVariantPropertiesList",
  CM_PACKAGE_MASTER_LIST:
    serverVars.CALL_MANAGEMENT + "cmPackage/getCMPackageList",
  CM_PACKAGE_MASTER_ADD: serverVars.CALL_MANAGEMENT + "cmPackage/addCMPackage",
  CM_PACKAGE_MASTER_UPDATE:
    serverVars.CALL_MANAGEMENT + "cmPackage/updateCMPackage",
  CM_PACKAGE_BY_ID: serverVars.CALL_MANAGEMENT + "cmPackage/getCMPackageById/",
  CM_CUSTOMER_PACKAGE_ADD:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/addCMPackageCustomer",
  CM_CUSTOMER_PACKAGE_UPDATE:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/updateCMPackageCustomer",
  CM_CUSTOMER_PACKAGE_LIST:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/getCMPackageCustomerList",
  CM_CUSTOMER_PACKAGE_BY_ID:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/getCMPackageCustomerById/",
  CM_ADD_JOB: serverVars.CALL_MANAGEMENT + "cmJob/addCMJob",
  CM_UPDATE_JOB: serverVars.CALL_MANAGEMENT + "cmJob/updateCMJob",
  CM_JOB_BY_ID: serverVars.CALL_MANAGEMENT + "cmJob/getCMJobById/",
  CM_JOBS_LIST: serverVars.CALL_MANAGEMENT + "cmJob/getCMJobList",
  CM_TERMS_ADD: serverVars.CALL_MANAGEMENT + "cmTerms/addCMTerms",
  CM_TERMS_LIST: serverVars.CALL_MANAGEMENT + "cmTerms/getCMTermsList",
  CM_TERMS_PACKAGE_MAPPING_LIST:
    serverVars.CALL_MANAGEMENT + "cmTermsPackagePackage/getCMTermsPackageList",
  CM_TERMS_PACKAGE_MAPPING_ADD:
    serverVars.CALL_MANAGEMENT + "cmTermsPackagePackage/addCMTermsPackage",
  CM_TERMS_PACKAGE_MAPPING_DELETE:
    serverVars.CALL_MANAGEMENT +
    "cmTermsPackagePackage/deleteCMTermsPackageById/",
  CM_CUSTOMER_MASTER_BY_CONTACT:
    serverVars.MASTER_API + "customerMaster/getCustomerByContact",
  CM_MAPPED_PRODUCT_FOR_CUSTOMER:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/isMappedProductForCustomer",
  CM_GET_HELP_TOPIC_LIST:
    serverVars.MASTER_API + "HelpTopicMaster/getglobalAndProblemHelpTopicsList",
  GET_SPINNING_DOFF_DATA:
    serverVars.MASTER_API + "vowreports/vowJutereportsApi",
  GET_BRANCHES_FOR_MULTIPLE_COMPANIES:
    serverVars.MASTER_API + "branchMaster/getBranchesByCompanies",
  GET_PROJECTS_FOR_MULTIPLE_BRANCHES:
    serverVars.MASTER_API + "project/getProjectSelectionListByCompanies",
  GET_ITEMS_MULTIPLE_ITEM_GROUPS:
    serverVars.MASTER_API + "item/getItemsBygroupCodesAndCompanies",
  EMPLOYEE_BILLING_LIST:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/getAllPayCustomerEmployeeList",
  ADD_EMPLOYEE_BILLING:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/addPayCustomerEmployeeStructure",
  UPDATE_EMPLOYEE_BILLING:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/updatePayCustomerEmployeeStructure",
  EMPLOYEE_BILLING_BY_ID:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/getPayCustomerEmployeeStructureById/",
  EMPLOYEE_BILLING_PAYRGISTER_LIST:
    serverVars.MASTER_API + "PayCustomerEmployeePeriod/getAllPayCustEmpPeriods",
  ADD_EMPLOYEE_BILLING_PAYRGISTER:
    serverVars.MASTER_API + "PayCustomerEmployeePeriod/addPayCustEmpPeriod",
  UPDATE_EMPLOYEE_BILLING_PAYRGISTER:
    serverVars.MASTER_API + "PayCustomerEmployeePeriod/updatePayCustEmpPeriod",
  EMPLOYEE_BILLING_PAYRGISTER_BY_ID:
    serverVars.MASTER_API +
    "PayCustomerEmployeePeriod/getPayCustEmpPeriodById/",
  GET_EMP_BILLING_PAY_PROCESS_TEMPLATE:
    serverVars.MASTER_API + "paycustempbilling/downloadPayTemplate/",
  UPLOAD_EMP_BULLING_PAY_PROCESS_PAY:
    serverVars.MASTER_API + "paycustempbilling/uploadOrPayProcessPay/",
  GET_ITEM_LEDGER_REPORT_DATA: serverVars.MASTER_API + "storereport/itemLedger",
  GET_EMP_BILL_PAY_PROCESSED_LIST:
    serverVars.MASTER_API + "PaycustEmpPayroll/getProcessedList",
  ADD_MENU_COMPANY_MAPPING:
    serverVars.MASTER_API + "menumaster/addMenuCompanyMapping",
  GET_MENU_COMPANY_MAPPED_LIST:
    serverVars.MASTER_API + "menumaster/getMenuCompanyMapList/",
  GET_LIST_VIDEO_BY_MENU:
    serverVars.MASTER_API + "easybusiness/menu/getMenuById/",
  GET_ITEM_GRP_SUPP_MAP_LIST:
    serverVars.MASTER_API + "itemgroupSupMapp/getItemGroupSuppMappList",
  ADD_ITEM_GRP_SUPP_MAPPING:
    serverVars.MASTER_API + "itemgroupSupMapp/addItemGroupSupMap",
  GET_SUPPLIERS_BY_ITEM_GROUP:
    serverVars.MASTER_API + "itemgroupSupMapp/getSuppliersByItemGroup/",
  ADD_INVOICE_TO_TALLY: serverVars.TALLY_API + "tally/addInvoiceToTally",
  ADD_BILLS_TO_TALLY: serverVars.TALLY_API + "tally/addBillToTally",
  UPDATE_INVOCE_TO_TALLY_BY_ID:
    serverVars.TALLY_API + "tally/updateInvoiceToTally",
  ADD_RECEIPTS_TO_TALLY: serverVars.TALLY_API + "tally/addRecieptsToTally",
  UPDATE_BILLS_TO_TALLY: serverVars.TALLY_API + "tally/updateBillToTally",
  UPDATE_RECEIPTS_TO_TALLY: serverVars.TALLY_API + "tally/updateReceiptToTally",
  UPDATE_TALLY_CONFIG: serverVars.TALLY_API + "tally/updateTallyConfig",
  GET_ALL_BILLS: serverVars.PO_API + "getAllTypesBills",
  GET_SUPP_ITEM_RATE: serverVars.PO_API + "PriceMaster/getSupplierItemRate/",
  JUTE_MARKET_RATE: serverVars.REPORTS_API + "vowJutereportsApi",
  GET_LATEST_MARKET_RATE: serverVars.PO_API + "api/v1/getLatestMarketJuteRate",
  ADD_JUTE_CONVERSION: serverVars.PO_API + "api/v1/addJuteConversion",
  UPDATE_JUTE_CONVERSION: serverVars.PO_API + "api/v1/updateJuteConversion",
  GET_TABLE_JUTE_CONVERSION: serverVars.PO_API + "api/v1/",
  JUTE_DAY_WISE_RECEIPT: serverVars.REPORTS_API + "vowJutereportsApi",
  GET_MR_BUYERS_LIST: serverVars.PO_API + "api/v1/getJuteMrBuyersList",
  GET_PO_PENDING_QUANTITY: serverVars.PO_API + "api/v1/getPoPendningQuantity/",
  GET_PENDING_RCPT__QUANTITY: serverVars.PO_API + "api/v1/getPendingRcptQty/",
  ITEM_WISE_STOCK_QUANTITY_REPORT:
    serverVars.STORE_REPORTS + "storereport/itemQuantityReport",
  ITEM_WISE_STOCK_VALUE_REPORT:
    serverVars.STORE_REPORTS + "storereport/itemValueReport",
  ITEM_GROUP_STOCK_REPORT:
    serverVars.STORE_REPORTS + "storereport/itemGroupReport",
  DATA_COLLECTION_LIST:
    serverVars.PAYROLL_API + "PayProcess/getEmployeeComponents",
  TENANT_MASTER_LIST: serverVars.TENANT_API + "fetchTenantConfig/",
  ADD_TENANT_MASTER: serverVars.TENANT_API + "storeTenantConfig/",
  DELETE_TENANT: serverVars.TENANT_API + "deleteTenantConfig/",
  DATA_COLLECTION_ADD_DATA:
    serverVars.PAYROLL_API + "PayProcess/processDatacollection/",
  DATA_COLLECTION_UPLOAD_SHEET:
    serverVars.PAYROLL_API + "PayProcess/processDatacollectionSheet",
  PAY_ROLL_TEMPLATE_DOWNLOAD:
    serverVars.MASTER_API + "PayProcess/downloadPayrollTemplate",
  //HRMS REPORTS
  EMP_TYPE_SUMMARY: serverVars.HRMS_REPORTS + "employee/getEmpTypeWiseSummary",
  EMP_DEPT_SUMMARY: serverVars.HRMS_REPORTS + "employee/getEmpDeptWiseSummary",
  EMP_CAT_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpCategoryWiseSummary",
  EMP_SUB_DEPT_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpSubDeptWiseSummary",
  EMP_CAT_AND_DEPT_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpCategoryandDeptWiseSummary",
  EMP_DESIGNATION_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpDesignationWiseSummary",
  EMP_SUB_DEPT_AND_DESIGNATION_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpSubDeptandDesignationWiseSummary",
  PAY_SCHEME_EMP_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getPayschemeWiseEmployeeSummary",
  EMP_PAY_SCHEME_LIST:
    serverVars.HRMS_REPORTS + "employee/getEmpPayschemeWiseList",

  GET_DAILY_WEAVING_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/getDailyWeavingQualityMapping",
  LIST_OF_ONFLOOR_STOCK: serverVars.PO_API + "ListOfOnFloorStock/",
  LEAVE_TYPE_DATATABLE: serverVars.MASTER_API + "LeaveTypes/getLeaveTypesList",
  LEAVE_TYPE_ADD: serverVars.MASTER_API + "LeaveTypes/postLeaveTypes",
  LEAVE_TYPE_UPDATE: serverVars.MASTER_API + "LeaveTypes/editLeaveTypes",
  LEAVE_TYPE_DETAILS_BYID:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesById",
  LEAVE_TYPE_SELECTION_LIST:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesSelectionList",
  LEAVE_POLICIES_ADD: serverVars.MASTER_API + "leavepolicy/addLeavePolicy",
  LEAVE_POLICIES_UPDATE:
    serverVars.MASTER_API + "leavepolicy/updateLeavePolicy",
  LEAVE_POLICIES_LIST: serverVars.MASTER_API + "leavepolicy/getLeavePolicyList",
  LEAVE_POLICIES_GET_BYID:
    serverVars.MASTER_API + "leavepolicy/getLeavePolicyById",
  LEAVE_POLICIES_DELETE_BYID:
    serverVars.MASTER_API + "leavepolicy/deleteLeavePolicyById",
  UPDATE_DAILY_WEAVING_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/UpadteDailyWeavingQualityMapping",
  UPDATE_SPINNING_YARN_MAPPING_LIST:
    serverVars.PO_API + "api/v1/updateSpinningYarnMappingList",
  CLIENT_VISIT_LIST:
    serverVars.MASTER_API + "clientvisitplan/getClientVisitPlanList",
  CLIENT_VISIT_ADD:
    serverVars.MASTER_API + "clientvisitplan/postClientVisitPlan",
  CLIENT_VISIT_UPDATE:
    serverVars.MASTER_API + "clientvisitplan/editclientVisitPlan",
  CLIENT_VISIT_GET_BYID:
    serverVars.MASTER_API + "clientvisitplan/getClientVisitPlanById",
  EXPENSE_BOOKING_ADD:
    serverVars.MASTER_API + "expensesBooking/addExpenseBooking",
  EXPENSE_BOOKING_UPDATE:
    serverVars.MASTER_API + "expensesBooking/updateExpenseBooking",
  EXPENSE_BOOKING_GET_BYID:
    serverVars.MASTER_API + "expensesBooking/getExpenseBookingByyId",
  EXPENSE_BOOKING_LIST:
    serverVars.MASTER_API + "expensesBooking/getExpenseBookingList",
  WORKER_DAILY_ATTENDANCE_SUMMARY:
    serverVars.MASTER_API + "dailyAttendance/getAttendanceSummaryByEbId",
  WORKER_DAILY_BIOMETRIC_LOGS:
    serverVars.MASTER_API + "biometriclogs/getLastUpdatedBioMetricLogs",
  LEAVE_REQUEST_AVAILABILITY:
    serverVars.MASTER_API + "LeaveLedger/getLeaveLedgerList",
  LEAVE_POLICY_MAPPING_ADD:
    serverVars.MASTER_API + "leavePolicymap/postEmpLeavePolicyMapping",
  LEAVE_POLICY_MAPPING_UPDATE:
    serverVars.MASTER_API + "leavePolicymap/updateEmpLeavePolicyMapping",
  LEAVE_POLICY_MAPPING_DELETE:
    serverVars.MASTER_API + "leavePolicymap/deleteEmpLeavePolicyById/",
  FORMULA_MASTER_ADD: serverVars.MASTER_API + "formula/addFormula",
  FORMULA_MASTER_LIST: serverVars.MASTER_API + "formula/getAllFormulas",
  ADVANCE_REQUEST_LIST_TABLE:
    serverVars.MASTER_API + "advancerequest/getAdvanceRequestList/",
  ADVANCE_REQUEST_ADD:
    serverVars.MASTER_API + "advancerequest/addAdvanceRequest",
  ADVANCE_REQUEST_DETAILS_BY_ID:
    serverVars.MASTER_API + "advancerequest/getAdvanceRequestById/",
  ADVANCE_REQUEST_UPDATE:
    serverVars.MASTER_API + "advancerequest/updateAdvanceRequest",
  PAY_SCHEME_ADD: serverVars.MASTER_API + "PayScheme/addPayScheme",
  PAY_SCHEME_GET_LIST: serverVars.MASTER_API + "PayScheme/getAllPaySchemes",
  PAY_SCHEME_GET_BYID:
    serverVars.MASTER_API + "PayScheme/getPaySchemeByPaySchemeId",
  PAY_SCHEME_EDIT: serverVars.MASTER_API + "PayScheme/updatePayScheme",
  GET_EMPLOYEE_PAYSCHHME_BYID:
    serverVars.MASTER_API + "empPayscheme/getEmpPayschemeById",
  ADD_EMP_PAYSCHME:
    serverVars.MASTER_API + "PayEmployeeStructure/addPayEmployeeStructure",
  GET_SR_STOCK_LIST: serverVars.PO_API + "api/v1/getSrStockByDeptAndItemId",
  GET_BALANCE_STOCK_LIST:
    serverVars.PO_API + "api/v1/getBalStockByDeptAndItemId",
  GET_BRANCH_WISE_STOCK: serverVars.PO_API + "api/v1/getBranchWiseStock",
  GET_PAYSCHME_PROCESSING_REQUEST_LIST:
    serverVars.MASTER_API +
    "processingPayscheme/getPayschemeProcessingRequestList",
  CREATE_PAYSCHME_PROCESSING_REQUEST:
    serverVars.MASTER_API +
    "processingPayscheme/createPayschemeProcessingRequest",
  UPDATE_EMP_PAYSCHME:
    serverVars.MASTER_API + "PayEmployeeStructure/updatePayEmployeeStructure",
  ADD_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/addsalaryAdjustment",
  UPDATE_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/updatesalaryAdjustment",
  GET_ALL_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/getAllsalaryAdjustment",
  GET_BY_ID_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/getsalaryAdjustmentById",
  DELETE_SALARY_ADJUSTMENT:
    serverVars.MASTER_API + "salaryAdjustment/removesalaryAdjustment",
  GET_MASTER_TYPE_LIST:
    serverVars.MASTER_OLD + "MasterType/getMasterTypeSelectionList",
  ADD_MASTER_DATA: serverVars.MASTER_OLD + "masterDataUpload/addMasterData",
  GET_MASTER_DATA_LIST:
    serverVars.MASTER_OLD + "masterDataUpload/getMasterDatalist",
  GET_MASTER_DATA_SAMPLE_FILE:
    serverVars.MASTER_OLD + "MasterType/getsamplefileURL",
  ADD_HELPTOPIC_MASTER:
    serverVars.MASTER_API + "HelpTopicMaster/addHelpTopicMaster",
  UPDATE_HELPTOPIC_MASTER:
    serverVars.MASTER_API + "HelpTopicMaster/editHelpTopicMaster",
  GET_HELPTOPIC_MASTER_BYID:
    serverVars.MASTER_API + "HelpTopicMaster/getHelpTopicMasterById",
  HELPTOPIC_MASTER_LIST:
    serverVars.MASTER_API + "HelpTopicMaster/getHelpTopicMasterList",
  ADD_PROBLEM_MASTER: serverVars.MASTER_API + "ProblemMaster/addProblemMaster",
  UPDATE_PROBLEM_MASTER:
    serverVars.MASTER_API + "ProblemMaster/editProblemMaster",
  GET_PROBLEM_MASTER_BYID:
    serverVars.MASTER_API + "ProblemMaster/getProblemMasterById",
  PROBLEM_MASTER_LIST:
    serverVars.MASTER_API + "ProblemMaster/getProblemMasterlist",
  GET_AVAILABLE_PAYSLIPS:
    serverVars.MASTER_API + "PayEmployeePayroll/getAvailablePayslips",
  GET_PAYSLIP: serverVars.MASTER_API + "PayEmployeePayroll/getpayslip",
  ADD_PACKAGE_MASTER: serverVars.MASTER_API + "PackageMaster/addPackageMaster",
  EDIT_PACKAGE_MASTER:
    serverVars.MASTER_API + "PackageMaster/updatePackageMaster",
  GET_PACKAGE_MASTER_BYID:
    serverVars.MASTER_API + "PackageMaster/getPackageMasterById",
  PACKAGE_MASTER_LIST:
    serverVars.MASTER_API + "PackageMaster/getPackageMasterList",
  DELETE_PACKAGE_MASTER:
    serverVars.MASTER_API + "PackageMaster/deletePackageMaster",
  ADD_CARRIER_MASTER: serverVars.MASTER_OLD + "WithCarriers/addWithCarrier",
  UPDATE_CARRIER_MASTER:
    serverVars.MASTER_OLD + "WithCarriers/updateWithCarrier",
  GET_CARRIER_MASTER_BYID:
    serverVars.MASTER_OLD + "WithCarriers/getWithCarrierById",
  CARRIER_MASTER_LIST:
    serverVars.MASTER_OLD + "WithCarriers/getWithCarrierlist",
  MACHINES_BY_DESIGNATION:
    serverVars.MASTER_OLD + "machinemaster/getMachineMasterByDesignation/",
  DAILY_ATTENDANCE_APPROVE:
    serverVars.MASTER_API + "dailyAttendance/approveDailyAttendanceList",
  ADD_UPDATE_MENU: "menu/addUpdateMenu",
  UPLOAD_EMP_IMAGE: serverVars.HRMS_API + "workerMaster/uploadEmpImage",
  GET_PARENT_MENU: "menu/getParentMenusByReqSource/",
  STORE_STOCK_REPORT:
    serverVars.STORE_REPORTS + "storereport/storeStockReport/",
  STORE_REJECT_REASON:
    serverVars.STORE_REPORTS + "storegate/getStoreGateItemsList",
  STORE_RECONCILIATION_REPORT:
    serverVars.STORE_REPORTS + "storereport/reconciliationReport",
  ADD_CUSTOMER_MAPPING:
    serverVars.CALL_MANAGEMENT +
    "CustomerPackageMapping/addCustomerPackageMapping",
  UPDATE_CUSTOMER_MAPPING:
    serverVars.MASTER_API + "CustomerPackageMapping/editCustomerPackageMapping",
  CUSTOMER_MAPPING_LIST:
    serverVars.MASTER_API +
    "CustomerPackageMapping/getAllCustomerPackageMapping",
  CUSTOMER_MAPPING_GET_BYID:
    serverVars.MASTER_API +
    "CustomerPackageMapping/getCustomerPackageMappingById",
  GET_PACKAGE_DETAILS:
    serverVars.MASTER_API + "CustomerPackageMapping/getSerialList",
  CUSTOMER_MASTER_BY_CONTACT:
    serverVars.MASTER_API + "customerMaster/getCustomerList",
  ADD_JOB: serverVars.MASTER_API + "jobs/addJobs",
  JOBS_LIST: serverVars.MASTER_API + "jobs/getJobslist",
  GET_SEARCH_FIELDS: serverVars.REPORTS_API + "menu/getMenuSearchfieldsByMenu/",
  GET_GRAPHS_DATA: serverVars.REPORTS_API + "menu/getGraphMenuData",
  UPDATE_CONFIGURATION: serverVars.PO_API + "api/v1/updateConfiguration",
  GET_CONFIGURATION_BY_COMPANY: serverVars.PO_API + "api/v1/getConfiguration/",
  TRANSPORT_MASTER_LIST:
    serverVars.MASTER_API + "transport/getTransportersList/",
  ADD_TRANSPORTER: serverVars.MASTER_API + "transport/addTransporter",
  UPDATE_TRANSPORTER: serverVars.MASTER_API + "transport/updateTransporter",
  GET_TRANSPORTER_BY_ID:
    serverVars.MASTER_API + "transport/getTransporterById/",
  PROJECT_MASTER_ADD: serverVars.MASTER_API + "project/addProjectMaster",
  PROJECT_MASTER_UPDATE: serverVars.MASTER_API + "project/updateProjectMaster",
  PROJECT_MASTER_LIST: serverVars.MASTER_API + "project/getProjectMasterList/",
  PROJECT_MASTER_GET_BY_ID:
    serverVars.MASTER_API + "project/getProjectMasterById/",
  STOCK_TRANSFER_LIST: serverVars.PO_API + "api/v1/getAllStoreTransferList",
  STOCK_TRANSFER_ADD: serverVars.PO_API + "api/v1/addStoreStockTransfer",
  STORE_RETURN_ADD: serverVars.PO_API + "api/v1/addReturn",
  STORE_RETURN_BY_ID: serverVars.PO_API + "api/v1/getReturnById",
  STORE_RETURN_UPDATE: serverVars.PO_API + "api/v1/updateReturn",
  PROJECT_MASTER_SELECTION_LIST:
    serverVars.MASTER_API + "project/getProjectMasterSelectionListCompany/",
  GET_STORE_ISSUE_LIST: serverVars.PO_API + "api/v1/getStoreIssueList",
  GET_CUSTOMER_BY_BRANCH:
    serverVars.MASTER_API + "customerMaster/getCustomerSelectionList/",
  GET_PAY_SUMMARY_LIST: serverVars.MASTER_API + "paysummary/getpaysummaryList",
  PRICE_REQUEST_LIST: serverVars.PO_API + "PriceMaster/getPriceMasterList",
  PRICE_REQUEST_ADD: serverVars.PO_API + "PriceMaster/addPriceMaster",
  PRICE_REQUEST_UPDATE: serverVars.PO_API + "PriceMaster/editPriceMaster",
  GET_PRICE_MASTER_BY_ID: serverVars.PO_API + "PriceMaster/getPriceMasterById/",
  GET_PRICE_REQUEST_RESPONSE_LIST:
    serverVars.PO_API + "PriceMaster/getListBySuppCode",
  UPDATE_PRICE_RESPONSE:
    serverVars.PO_API + "PriceMaster/priceRequestUpdateBySupplier",
  USER_MANAGEMENT_RESET_PASSWORD:
    serverVars.MASTER_API + "easybusiness/user/resetPassword",
  PAY_SCHEME_DATATABLE:
    serverVars.MASTER_API + "PayComponents/getAllPayComponents",
  PAY_SCHEME_ADD_PARAMS:
    serverVars.MASTER_API + "PayComponents/addPayComponents",
  PAY_SCHEME_DATA_GET_BYID:
    serverVars.MASTER_API + "PayComponents/getPayComponentsById",
  PAY_SCHEME_UPDATE_PARAMS:
    serverVars.MASTER_API + "PayComponents/updatePayComponents",
  PAY_PERIOD_ADD: serverVars.MASTER_API + "PayPeriod/addPayPeriod",
  GET_PAYPERIOD_LIST: serverVars.MASTER_API + "PayPeriod/getAllPayPeriods",
  UPLOAD_OR_PAY_PROCESS_PAY:
    serverVars.MASTER_API + "PayProcess/uploadOrPayProcessPay",
  CHECK_PAYSCHEME: serverVars.MASTER_API + "PayProcess/checkPayscheme",
  GET_PAY_PROCESSED_LIST:
    serverVars.MASTER_API + "PayEmployeePayroll/getProcessedList",
  GET_WAGE_TYPES: serverVars.SECURITY_API + "PayWagesMode/getAllPayWagesModes",
  PAY_PERIOD_BYID: serverVars.SECURITY_API + "PayPeriod/getPayPeriodById",
  UPDATE_PAY_PERIOD: serverVars.SECURITY_API + "PayPeriod/updatePayPeriod",
  GET_LAST_PURCHASE_RATE: serverVars.PO_API + "api/v1/getLastPurchaseRate/",
  ITEM_SUPPLIER_MAPPING:
    serverVars.MASTER_API + "mastersupplier/itemSuppMapping",
  ITEM_MAX_NUMBER: serverVars.MASTER_API + "item/getMaxOfItemCode/",
  STOCK_RETURN_LIST: serverVars.PO_API + "api/v1/getReturnList",
  GET_PAY_PROCESS_TEMPLATE:
    serverVars.MASTER_API + "PayProcess/downloadPayTemplate",
  PENDING_STOCK: serverVars.PO_API + "api/v1/getPendingStock",
  GET_ALL_STATES: serverVars.MASTER_API + "state/getStateNameSelectionList",
  FILE_UPLOAD: serverVars.MASTER_API + "files/Uploadfile",
  GET_FILE_BY_SOURCE:
    serverVars.MASTER_API + "files/getFileBySourcemenuAndTaskIdAndCompanyId/",
  DELETE_FILE_BI_ID: serverVars.MASTER_API + "files/deleteFile/",
  GET_FILE_INFO: serverVars.MASTER_API + "files/getFileInfo/",
  PENDING_STOCK_AT_PO_RECEIPT:
    serverVars.PO_API + "api/v1/getPendingStockatPoAndReceipt",
  SALE_ORDER_LIST: serverVars.PO_API + "api/v1/getSalesOrderList",
  GET_SALE_ORDER_BY_ID: serverVars.PO_API + "api/v1/getSalesOrderByNo/",
  SALE_ORDER_ADD: serverVars.PO_API + "api/v1/addSalesOrder",
  SALE_ORDER_UPDATE: serverVars.PO_API + "api/v1/updateSalesOrder",
  PRODUCT_PROPERTY_ADD:
    serverVars.CALL_MANAGEMENT + "cmVariantProperties/addCMVariantProperties",
  PRODUCT_PROPERTY_UPDATE:
    serverVars.CALL_MANAGEMENT +
    "cmVariantProperties/updateCMVariantProperties",
  PRODUCT_PROPERTY_BY_ID:
    serverVars.CALL_MANAGEMENT +
    "cmVariantProperties/getCMVariantPropertiesById/",
  PRODUCT_PROPERTY_LIST:
    serverVars.CALL_MANAGEMENT +
    "cmVariantProperties/getCMVariantPropertiesList",
  CM_PACKAGE_MASTER_LIST:
    serverVars.CALL_MANAGEMENT + "cmPackage/getCMPackageList",
  CM_PACKAGE_MASTER_ADD: serverVars.CALL_MANAGEMENT + "cmPackage/addCMPackage",
  CM_PACKAGE_MASTER_UPDATE:
    serverVars.CALL_MANAGEMENT + "cmPackage/updateCMPackage",
  CM_PACKAGE_BY_ID: serverVars.CALL_MANAGEMENT + "cmPackage/getCMPackageById/",
  CM_CUSTOMER_PACKAGE_ADD:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/addCMPackageCustomer",
  CM_CUSTOMER_PACKAGE_UPDATE:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/updateCMPackageCustomer",
  CM_CUSTOMER_PACKAGE_LIST:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/getCMPackageCustomerList",
  CM_CUSTOMER_PACKAGE_BY_ID:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/getCMPackageCustomerById/",
  CM_ADD_JOB: serverVars.CALL_MANAGEMENT + "cmJob/addCMJob",
  CM_UPDATE_JOB: serverVars.CALL_MANAGEMENT + "cmJob/updateCMJob",
  CM_JOB_BY_ID: serverVars.CALL_MANAGEMENT + "cmJob/getCMJobById/",
  CM_JOBS_LIST: serverVars.CALL_MANAGEMENT + "cmJob/getCMJobList",
  CM_TERMS_ADD: serverVars.CALL_MANAGEMENT + "cmTerms/addCMTerms",
  CM_TERMS_LIST: serverVars.CALL_MANAGEMENT + "cmTerms/getCMTermsList",
  CM_TERMS_PACKAGE_MAPPING_LIST:
    serverVars.CALL_MANAGEMENT + "cmTermsPackagePackage/getCMTermsPackageList",
  CM_TERMS_PACKAGE_MAPPING_ADD:
    serverVars.CALL_MANAGEMENT + "cmTermsPackagePackage/addCMTermsPackage",
  CM_TERMS_PACKAGE_MAPPING_DELETE:
    serverVars.CALL_MANAGEMENT +
    "cmTermsPackagePackage/deleteCMTermsPackageById/",
  CM_CUSTOMER_MASTER_BY_CONTACT:
    serverVars.MASTER_API + "customerMaster/getCustomerByContact",
  CM_MAPPED_PRODUCT_FOR_CUSTOMER:
    serverVars.CALL_MANAGEMENT + "cmPackageCustomer/isMappedProductForCustomer",
  CM_GET_HELP_TOPIC_LIST:
    serverVars.MASTER_API + "HelpTopicMaster/getglobalAndProblemHelpTopicsList",
  GET_SPINNING_DOFF_DATA:
    serverVars.MASTER_API + "vowreports/vowJutereportsApi",
  GET_BRANCHES_FOR_MULTIPLE_COMPANIES:
    serverVars.MASTER_API + "branchMaster/getBranchesByCompanies",
  GET_PROJECTS_FOR_MULTIPLE_BRANCHES:
    serverVars.MASTER_API + "project/getProjectSelectionListByCompanies",
  GET_ITEMS_MULTIPLE_ITEM_GROUPS:
    serverVars.MASTER_API + "item/getItemsBygroupCodesAndCompanies",
  EMPLOYEE_BILLING_LIST:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/getAllPayCustomerEmployeeList",
  ADD_EMPLOYEE_BILLING:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/addPayCustomerEmployeeStructure",
  UPDATE_EMPLOYEE_BILLING:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/updatePayCustomerEmployeeStructure",
  EMPLOYEE_BILLING_BY_ID:
    serverVars.MASTER_API +
    "PayCustomerEmployeeStructure/getPayCustomerEmployeeStructureById/",
  EMPLOYEE_BILLING_PAYRGISTER_LIST:
    serverVars.MASTER_API + "PayCustomerEmployeePeriod/getAllPayCustEmpPeriods",
  ADD_EMPLOYEE_BILLING_PAYRGISTER:
    serverVars.MASTER_API + "PayCustomerEmployeePeriod/addPayCustEmpPeriod",
  UPDATE_EMPLOYEE_BILLING_PAYRGISTER:
    serverVars.MASTER_API + "PayCustomerEmployeePeriod/updatePayCustEmpPeriod",
  EMPLOYEE_BILLING_PAYRGISTER_BY_ID:
    serverVars.MASTER_API +
    "PayCustomerEmployeePeriod/getPayCustEmpPeriodById/",
  GET_EMP_BILLING_PAY_PROCESS_TEMPLATE:
    serverVars.MASTER_API + "paycustempbilling/downloadPayTemplate/",
  UPLOAD_EMP_BULLING_PAY_PROCESS_PAY:
    serverVars.MASTER_API + "paycustempbilling/uploadOrPayProcessPay/",
  GET_ITEM_LEDGER_REPORT_DATA: serverVars.MASTER_API + "storereport/itemLedger",
  GET_EMP_BILL_PAY_PROCESSED_LIST:
    serverVars.MASTER_API + "PaycustEmpPayroll/getProcessedList",
  ADD_MENU_COMPANY_MAPPING:
    serverVars.MASTER_API + "menumaster/addMenuCompanyMapping",
  GET_MENU_COMPANY_MAPPED_LIST:
    serverVars.MASTER_API + "menumaster/getMenuCompanyMapList/",
  GET_LIST_VIDEO_BY_MENU:
    serverVars.MASTER_API + "easybusiness/menu/getMenuById/",
  GET_ITEM_GRP_SUPP_MAP_LIST:
    serverVars.MASTER_API + "itemgroupSupMapp/getItemGroupSuppMappList",
  ADD_ITEM_GRP_SUPP_MAPPING:
    serverVars.MASTER_API + "itemgroupSupMapp/addItemGroupSupMap",
  GET_SUPPLIERS_BY_ITEM_GROUP:
    serverVars.MASTER_API + "itemgroupSupMapp/getSuppliersByItemGroup/",
  ADD_INVOICE_TO_TALLY: serverVars.TALLY_API + "tally/addInvoiceToTally",
  ADD_BILLS_TO_TALLY: serverVars.TALLY_API + "tally/addBillToTally",
  UPDATE_INVOCE_TO_TALLY_BY_ID:
    serverVars.TALLY_API + "tally/updateInvoiceToTally",
  ADD_RECEIPTS_TO_TALLY: serverVars.TALLY_API + "tally/addRecieptsToTally",
  UPDATE_BILLS_TO_TALLY: serverVars.TALLY_API + "tally/updateBillToTally",
  UPDATE_RECEIPTS_TO_TALLY: serverVars.TALLY_API + "tally/updateReceiptToTally",
  UPDATE_TALLY_CONFIG: serverVars.TALLY_API + "tally/updateTallyConfig",
  GET_ALL_BILLS: serverVars.PO_API + "getAllTypesBills",
  GET_SUPP_ITEM_RATE: serverVars.PO_API + "PriceMaster/getSupplierItemRate/",
  JUTE_MARKET_RATE: serverVars.REPORTS_API + "vowJutereportsApi",
  GET_LATEST_MARKET_RATE: serverVars.PO_API + "api/v1/getLatestMarketJuteRate",
  ADD_JUTE_CONVERSION: serverVars.PO_API + "api/v1/addJuteConversion",
  UPDATE_JUTE_CONVERSION: serverVars.PO_API + "api/v1/updateJuteConversion",
  GET_TABLE_JUTE_CONVERSION: serverVars.PO_API + "api/v1/",
  JUTE_DAY_WISE_RECEIPT: serverVars.REPORTS_API + "vowJutereportsApi",
  GET_MR_BUYERS_LIST: serverVars.PO_API + "api/v1/getJuteMrBuyersList",
  GET_PO_PENDING_QUANTITY: serverVars.PO_API + "api/v1/getPoPendningQuantity/",
  GET_PENDING_RCPT__QUANTITY: serverVars.PO_API + "api/v1/getPendingRcptQty/",
  ITEM_WISE_STOCK_QUANTITY_REPORT:
    serverVars.STORE_REPORTS + "storereport/itemQuantityReport",
  ITEM_WISE_STOCK_VALUE_REPORT:
    serverVars.STORE_REPORTS + "storereport/itemValueReport",
  ITEM_GROUP_STOCK_REPORT:
    serverVars.STORE_REPORTS + "storereport/itemGroupReport",
  DATA_COLLECTION_LIST:
    serverVars.PAYROLL_API + "PayProcess/getEmployeeComponents",
  TENANT_MASTER_LIST: serverVars.TENANT_API + "fetchTenantConfig/",
  ADD_TENANT_MASTER: serverVars.TENANT_API + "storeTenantConfig/",
  DELETE_TENANT: serverVars.TENANT_API + "deleteTenantConfig/",
  DATA_COLLECTION_ADD_DATA:
    serverVars.PAYROLL_API + "PayProcess/processDatacollection/",
  DATA_COLLECTION_UPLOAD_SHEET:
    serverVars.PAYROLL_API + "PayProcess/processDatacollectionSheet",
  PAY_ROLL_TEMPLATE_DOWNLOAD:
    serverVars.MASTER_API + "PayProcess/downloadPayrollTemplate",
  //HRMS REPORTS
  EMP_TYPE_SUMMARY: serverVars.HRMS_REPORTS + "employee/getEmpTypeWiseSummary",
  EMP_DEPT_SUMMARY: serverVars.HRMS_REPORTS + "employee/getEmpDeptWiseSummary",
  EMP_CAT_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpCategoryWiseSummary",
  EMP_SUB_DEPT_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpSubDeptWiseSummary",
  EMP_CAT_AND_DEPT_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpCategoryandDeptWiseSummary",
  EMP_DESIGNATION_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpDesignationWiseSummary",
  EMP_SUB_DEPT_AND_DESIGNATION_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getEmpSubDeptandDesignationWiseSummary",
  PAY_SCHEME_EMP_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getPayschemeWiseEmployeeSummary",
  EMP_PAY_SCHEME_LIST:
    serverVars.HRMS_REPORTS + "employee/getEmpPayschemeWiseList",

  EMP_LEAVES_LIST: serverVars.HRMS_REPORTS + "employee/getEmpLeavesList",
  LEAVE_TYPE_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getLeaveTypeWiseSummary",
  EMP_PAY_STRUCT: serverVars.HRMS_REPORTS + "employee/getEmpWisePaystructures",
  BRANCH_SAL_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getBranchWiseSalarySummary",
  MONTH_BRANCH_SAL_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getMonthWiseandBranchWiseSalarySummary",
  BRANCH_PAYSCHEME_SAL_SUMMARY:
    serverVars.HRMS_REPORTS +
    "employee/getBranchWisesandPayschemeWiseSalarySummary",
  BRANCH_CAT_SAL_SUMMARY:
    serverVars.HRMS_REPORTS +
    "employee/getBranchWiseandCategoryWiseSalarySummary",
  STORE_STOCK_WITH_VALUE:
    serverVars.STORE_REPORTS + "storereport/storeStockWithValue",
  ATTENDANCE_LOGS:
    serverVars.MASTER_API + "alm_service/attendancelog/fetchAllAttendanceData",
  ATTENDANCE_LOGS_WITH_DATES:
    serverVars.MASTER_API +
    "alm_service/attendancelog/fetchDateConditionTransaction",
  // UOM_MASTER_TABLE_LIST: serverVars.MASTER_API + "uommaster/getAllUomMaster",
  // UOM_MASTER_ADD: serverVars.MASTER_API + "uommaster/addUomMaste",
  // UOM_MASTER_UPDATE: serverVars.MASTER_API + "uommaster/updateUomRelation",
  GET_CREMINAL_RECORDS_LIST:
    serverVars.MASTER_API + "verification/getVerificationQuestionsList/",
  GET_CRIMINAL_RECORD_LIST_BY_EMP:
    serverVars.MASTER_API + "verification/getVerificationAnswersByEmpId/",
  ADD_VERIFICATION_ANSWAERS:
    serverVars.MASTER_API + "verification/addVerificationAnswers",
  ADD_EXPERIENCE: serverVars.MASTER_API + "experience/addExperienceDetails",
  GET_EXPERIENCE_BY_EMP:
    serverVars.MASTER_API + "experience/getExperienceDetaislByEmpId/",
  PROCESS_DATA_COLLECTION_FROM_ATTENDANCE:
    serverVars.MASTER_API +
    "PayProcess/processDatacollectionFromDailyAttendance/",
  PROCESS_PROCESSDATA_COLLECTION_FROM_ATTENDANCE:
    serverVars.MASTER_API + "PayProcess/processDataFromLogsToDailyAttendance/",
  GENERATE_INVOICE: serverVars.PO_API + "api/v1/autoInvoice/",
  EMP_LEAVES_LIST: serverVars.HRMS_REPORTS + "employee/getEmpLeavesList",
  LEAVE_TYPE_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getLeaveTypeWiseSummary",
  EMP_PAY_STRUCT: serverVars.HRMS_REPORTS + "employee/getEmpWisePaystructures",
  BRANCH_SAL_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getBranchWiseSalarySummary",
  MONTH_BRANCH_SAL_SUMMARY:
    serverVars.HRMS_REPORTS + "employee/getMonthWiseandBranchWiseSalarySummary",
  BRANCH_PAYSCHEME_SAL_SUMMARY:
    serverVars.HRMS_REPORTS +
    "employee/getBranchWisesandPayschemeWiseSalarySummary",
  BRANCH_CAT_SAL_SUMMARY:
    serverVars.HRMS_REPORTS +
    "employee/getBranchWiseandCategoryWiseSalarySummary",
  STORE_STOCK_WITH_VALUE:
    serverVars.STORE_REPORTS + "storereport/storeStockWithValue",
  ATTENDANCE_LOGS:
    serverVars.MASTER_API + "alm_service/attendancelog/fetchAllAttendanceData",
  ATTENDANCE_LOGS_WITH_DATES:
    serverVars.MASTER_API +
    "alm_service/attendancelog/fetchDateConditionTransaction",
  UOM_MASTER_TABLE_LIST:
    serverVars.MASTER_API + "uommaster/getUomMasterCompanyId",
  UOM_MASTER_ADD: serverVars.MASTER_API + "uommaster/addNewUomMaster",
  UOM_MASTER_BY_ID: serverVars.MASTER_API + "uommaster/getUomMasterByUomCode/",
  UOM_MASTER_UPDATE: serverVars.MASTER_API + "uommaster/updateUomRelation",
  UOM_MASTER_RELATION_LIST:
    serverVars.MASTER_API + "uommaster/getUomMasterByRecordType",
  UOM_GET_RELATION_DATA: serverVars.MASTER_API + "uommaster/getLineItemUom/",
  UOM_DELETE_DATA: serverVars.MASTER_API + "uommaster/deleteUomMaster/",
  GET_CREMINAL_RECORDS_LIST:
    serverVars.MASTER_API + "verification/getVerificationQuestionsList/",
  GET_CRIMINAL_RECORD_LIST_BY_EMP:
    serverVars.MASTER_API + "verification/getVerificationAnswersByEmpId/",
  ADD_VERIFICATION_ANSWAERS:
    serverVars.MASTER_API + "verification/addVerificationAnswers",
  ADD_EXPERIENCE: serverVars.MASTER_API + "experience/addExperienceDetails",
  GET_EXPERIENCE_BY_EMP:
    serverVars.MASTER_API + "experience/getExperienceDetaislByEmpId/",
  PROCESS_DATA_COLLECTION_FROM_ATTENDANCE:
    serverVars.MASTER_API +
    "PayProcess/processDatacollectionFromDailyAttendance/",
  PROCESS_PROCESSDATA_COLLECTION_FROM_ATTENDANCE:
    serverVars.MASTER_API + "PayProcess/processDataFromLogsToDailyAttendance/",
  GENERATE_INVOICE: serverVars.PO_API + "api/v1/autoInvoice",
  GET_MACHINE_BY_ID:
    serverVars.MASTER_API + "machinemaster/getAllMachineDetails",
};

export const AppTitles = {
  GALLERY: "GALLERY ALBUMS",
  ALBUM_CREATE: "CREATE NEW ALBUM",
  SEND_UPDATE: "SEND UPDATES",
};

export const taskCodes = {
  LOGIN: 247,
  LOGOUT: 248,
  MENUS: 101,
  CREATE_COMP: 102,
  GET_BRANCH: 103,
  CREATE_DEPT: 104,
  GET_DEPT: 105,
  CREATE_USER: 106,
  CREATE_DESIG: 107,
  GET_DESIG: 108,
  GET_USERS: 109,
  GET_ROLES: 110,
  SCHEDULE_INTERVIEW: 111,
  GET_INTERVIEW_lIST: 112,
};

export default cons;
