import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
//import {Redirect} from "react-router-dom";
//import IconCard from "../../components/Cards/IconCard";
import cons, { serverConfig, serverApi, serverVars } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

class JuteConversion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "issueObj",
      approveStatus: false,
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      juteType: "",
      form_QualityId: "",
      selectedJuteType: "",
      form_QualityIdConv: "",
      selectedJuteTypeConv: "",
      form_godownNo: "",
      form_quantity: "",
      totalWeight: "",
      wastageType: "",
      processSide: "",
      processSideName: "",
      uom: "",
      godownNo: "",
      QualityId: "",
      indentStatus: "3",
      canChangeUnit: true,
      updateView: false,
      statusDesc: "",
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: "",
      unitConversionName: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      alert: false,
      selectedJuteQuality: "",
      hideJuteType: false,
      url: "",
      selectedUnit: "",
      stock: "",
      formunitConvertion: "",
      formunitConvertionId: "",
      issueStatus: "3",
      godownOptions: [],
      mrOptions: [],
      yarnTypes: [],
      godownValue: "",
      unitWeight: "",
      rate: "",
      invoiceList: [],
      selectedYarnTypeId: "",
      yarnId: "",
      yarnTypeName: "",
      yarnType: "",
      branchId: "",
      mrAlert: false,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.YARN_MASTER_TYPE_LIST, data, this.handleYarntype);
    this.callJuteType();
  }

  callJuteType() {
    var data = {
      itemGroupId: "999",
      companyId: getCookie(cons.COMPANY_ID),
    };
    requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleJuteType);
  }
  handleJuteType = (resp) => {
    this.setState({ juteTypeItems: resp });
  };
  handleYarntype = (resp) => {
    console.log("yarn  ", resp);
    if (resp.data !== null) {
      var yarnTypes = resp.data.map((prop) => {
        return {
          label: prop.yarnType,
          value: prop.yarnTypeId,
          name: prop.yarnType,
        };
      });
      this.setState({
        yarnTypes: yarnTypes,
      });
    }
  };

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleSelectedUnitValues = (defValue) => {
    this.setState({
      formunitConvertion: defValue,
      formunitConvertionId: defValue.value,
      formunitConvertionName: defValue.label,
    });
    console.log(`Option selected:`, this.state.unitConvertionName);
  };

  getQualityDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      mrNo: values[1],
      rate: values[2],
      unitWeight: values[3],
      uom: values[4],
      stock: values[5],
    });

    /*
                if (this.state.updateView) {
                    this.setState({
                        form_quantity: this.getConvertedQtyFromQNT(values[2]),
                        form_quantity_bales: parseFloat(this.state.form_quantity / 100)
                    })
                }
        */
  };

  handleSelectedYarnValues = (defValue) => {
    this.setState({
      // unitConvertion: defValue,
      yarnId: defValue.value,
      yarnType: defValue,
      yarnTypeName: defValue.label,
    });
    console.log(`Option selected:`, defValue);
  };

  handleSelectedPSValues = (defValue) => {
    this.setState({
      // unitConvertion: defValue,
      selectedYarnTypeId: defValue.value,
      selectedYarnType: defValue,
    });
    console.log(`Option selected:`, defValue);
  };
  handleViewClick = (id) => {
    var companyId = getCookie(cons.COMPANY_ID);
    var data = JSON.parse(id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_TABLE_JUTE_CONVERSION +
          "getJuteConversionById/" +
          data.jcNo +
          "/" +
          companyId,
        this.handleViewClickResp
      );
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };
  getTotalIssueValue = () => {
    var val = 0,
      arr = this.state.invoiceList;
    arr.map((q) => {
      if (!q.deleteStatus) val = val + parseFloat(q.issueValue);
    });
    val = parseFloat(val).toFixed(2);
    return val;
  };
  getTotalIssueWeight = () => {
    var val = 0,
      arr = this.state.invoiceList;
    arr.map((q) => {
      if (!q.deleteStatus) val = val + parseFloat(q.weight);
    });
    return parseFloat(val).toFixed(2);
  };
  getTotalBatchCost = () => {
    var cost = 0.0;
    var issueVal = this.getTotalIssueValue(),
      weight = this.getTotalIssueWeight();
    if (issueVal > 0 && weight > 0) {
      var val = parseFloat(issueVal / weight).toFixed(2);
      cost = issueVal + "/" + weight + "=" + val;
    }
    return cost;
  };

  handleViewClickResp = (resp) => {
    console.log("$$$$$$$$$$$$$$$$$$$", resp);
    var header = resp.materialGoodReceiveHeader,
      list = resp.materialGRItemList,
      createdBy = "",
      createdOn = "",
      yarnType = "",
      yarnName = "",
      date = "",
      finYear = "",
      approveButton = false,
      issueStatus = false,
      issueId,
      showUpdate = false,
      branchId = "",
      val = "";

    if (header !== null) {
      var invoicelist = list.map((prop, key) => {
        createdBy = prop.createdByDesc;
        createdOn = prop.createdDateTimeDesc;
        yarnType = prop.yarnId;
        date = prop.issueDateDesc;
        finYear = prop.finYear;
        yarnName = prop.side;
        approveButton = prop.approveButton;
        branchId = prop.branchId;
        if (prop.issueStatus !== 3 && prop.issueStatus !== 4) {
          showUpdate = true;
        }

        issueStatus = prop.issueStatus;
        // issueId=prop.issueId;
        return {
          id: key,
          yarnType: prop.yarnType,
          yarnId: prop.yarnId,
          indentItemId: prop.id,
          unitConversion: this.getDefaultUnitConvertion(header.unitConversion),
          unitConversionName: this.getDefaultUnitConvertion(
            header.unitConversion
          ).label,
          selectedJuteType: prop.itemId,
          selectedJuteTypeConv: prop.convertedJuteType,
          juteName: prop.itemDesc,
          form_QualityId: prop.actualQuality,
          form_QualityIdConv: prop.convertedQuality,
          qualityName: prop.qualityDesc,
          godownNo: { label: prop.warehouseNo, value: prop.warehouseNo },
          form_godownNo: { label: prop.warehouseNo, value: prop.godownNo },
          godownValue: prop.warehouseNo,
          mrName: prop.srcMrNo,
          srcMrLineId: prop.srcMrLineId,
          stockId: prop.stockId,
          issueValue: prop.issueValue,
          quantityBales: prop.quantity,
          unit: prop.uomCode,
          deptId: prop.deptId,
          wastageType: prop.wastageId,
          stockValue: prop.srcMrLineId,
          deleteStatus: false,
          percentage: prop.percentage,
          weight: prop.actualWeight,
          quantity: prop.quantity,
          actions: (
            <div className="actions-right">
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.basicItemAlert("Do You Like To Update This Item?");

                      this.setState({
                        id: o.id,
                        indentItemId: o.indentItemId,
                        updateItemAlert: true,
                        updateItem: true,
                        unitConversion: this.getDefaultUnitConvertion(
                          o.unitConversionName
                        ),
                        unitConversionId: o.unitConversion,
                        unitConversionName: o.unitConversionName,
                        selectedJuteType: o.selectedJuteType,
                        juteName: o.juteName,
                        form_QualityId: parseInt(o.form_QualityId),
                        qualityName: o.qualityName,
                        form_godownNo: o.form_godownNo,
                        godownValue: o.godownValue,
                        mrName: o.mrName,
                        stockId: o.stockId,
                        form_quantity_bales: o.quantityBales,
                        form_quantity: o.weight * 100,
                        uom: o.unit,
                        selectedDeptId: o.deptId,
                        stock: o.stockValue,
                        yarnId: o.yarnId,
                        yarnType: o.yarnType,
                        issueValue: o.issueValue,
                        percentage: o.percentage,
                        weight: o.weight,
                        quantity: o.quantity,
                        srcMrLineId: o.srcMrLineId,
                        selectedJuteTypeConv: o.selectedJuteTypeConv,
                        form_QualityIdConv: o.form_QualityIdConv,
                        //  stockValue: o.stockValue,
                        // alert:true,
                      });
                      this.getGodownList(
                        o.selectedJuteType,
                        o.form_QualityId,
                        o.unitConversionName
                      );
                      this.getMRList(
                        o.juteType,
                        o.quality,
                        o.unitConversionName,
                        2
                      );

                      return true;
                    }
                  });
                }}
                color="primaryNoBackground"
                customClass="remove"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      this.basicItemAlert("Do You Like To Delete This Item?");
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                        indentItemId: o.indentItemId,
                      });

                      //  data.splice(i,1);
                      return true;
                    }
                  });
                  this.setState({
                    selectedJuteType: "",
                    selectedJuteName: "",
                    selectedJuteQuality: "",
                    selectedQualityName: "",
                    quantity: "",
                    selectedUnit: "",
                    selectedDeptId: "",
                    selectedGrpId: "",
                    stock: "",
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>{" "}
            </div>
          ),
        };
      });
      if (header.status !== 3 && header.status !== 4) {
        showUpdate = true;
      }
      if (header.status === "1") {
        this.setState({
          approveStatus: true,
        });
      }

      this.setState({
        updateView: true,
        isCreated: true,
        // year: finYear,
        date1: moment(header.goodReceiptDate).format("DD-MM-YYYY"),
        //unitConvertion: header.baleLoose,
        //  yarnId: yarnType,
        //  yarnTypeName: yarnName,
        // yarnType: {label: yarnName, value: yarnType},
        // processSideName:header.side,
        unitConvertion: this.getDefaultUnitConvertion(header.unitConversion),
        unitConvertionId: this.getDefaultUnitConvertion(header.unitConversion)
          .value,
        unitConvertionName: this.getDefaultUnitConvertion(header.unitConversion)
          .label,
        createdBy: header.createdBy,
        createdOn: header.createdOn,
        issueStatus: header.status,
        showUpdate: showUpdate,
        invoiceList: invoicelist,
        branchId: header.branchId,
        headerId: header.id,
        goodReceiptDate: header.goodReceiptDate,
      });
    }
  };
  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else if (name === "BALE") {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getDefaultSideConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "WARP") {
        val = 1;
        label = "WARP";
      } else {
        val = 2;
        label = "WEFT";
      }

      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      acYear: getCookie(cons.ACADEMIC_YEAR),
      //"acYear": 2021,
      companyId: getCookie(cons.COMPANY_ID),
      /*   , "yarnType": this.state.selectedYarnTypeId !== 0 ? this.state.selectedYarnTypeId : "",
               "unitConversion": this.state.formunitConvertionId > 0 ? this.state.formunitConvertionName : "",*/
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;

    // if(event.target.id === 'bales'){
    //     var val=0
    //     var unitWgt = parseFloat(this.state.unitWeight);
    //     console.log("unitWgt", unitWgt + "   lllll");
    //     if (value !== '') {
    //         val=unitWgt*parseFloat(value)
    //         this.setState({
    //             weight:val
    //         })
    //     }
    // }
    if (event.target.id === "quantity" && this.state.unitWeight !== "") {
      var unitWgt = parseFloat(this.state.unitWeight);
      console.log("unitWgt", unitWgt + "   lllll");

      var qty = 0;
      var val = 0;

      console.log("bales ", this.state.unitBales);
      console.log("value ", parseFloat(value));
      if (value !== "") {
        if (parseFloat(this.state.unitBales) === parseFloat(value)) {
          console.log("unitBales ", this.state.unitBales);
          console.log("stock ", Number(this.state.stock));

          qty = (parseFloat(this.state.stock) * 100).toFixed(2);
          val = unitWgt * parseFloat(value);

          console.log("unitBales qty", qty);

          this.setState({
            form_quantity: qty,
            weight: val,

            //  bales:value
          });
        } else {
          console.log("qty unitWgt", unitWgt);

          qty = (parseFloat(value) * unitWgt * 100).toFixed(2);
          val = unitWgt * parseFloat(value);
          //  qty = Math.round(parseInt((parseFloat(value) * unitWgt)));

          /* console.log("bales values1",value);
                     console.log("bales values2",this.state.bales);*/
          this.setState({
            form_quantity: qty,
            weight:
              event.target.id === "percentage" ? val - event.target.value : val,
          });
        }
      } else {
        this.setState({
          form_quantity: "",
        });
      }
    }
    if (event.target.id === "percentage") {
      console.log("printing percentage");
      console.log("printing form_quantity", this.state.unitWeight);

      //   var weightinkgs =
      //     this.state.unitWeight -
      //     (parseFloat(event.target.value) / parseFloat(this.state.unitWeight)) *
      //       100;
      var balanceWeight = 0;
      var weight = parseFloat(this.state.stock * 100);
      if (parseFloat(event.target.value) > 0) {
        balanceWeight =
          (parseFloat(weight) * parseFloat(event.target.value)) / 100;
      }
      var val = parseFloat(weight) - parseFloat(balanceWeight);

      this.setState({
        weight: val,
      });
    }

    /*
                if (event.target.id === 'form_quantity'&& value!=='') {
                    if(this.state.unitConvertionId!==2){
                        this.setState({
                            form_quantity_kg:parseFloat(value)*100
                        })
                    }else {
                        var quantity=Math.round((parseFloat(value)*100)/150);
                        this.setState({
                            form_quantity_kg:quantity
                        })

                    }

                }
        */

    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };
  getQtyFromKG = (quantity, unitConversion) => {
    if (quantity !== "") {
      var unitweight = parseFloat(this.state.unitWeight);
      // var unitweight=150;

      if (unitConversion.value === 2) {
        console.log("unitweight  " + this.state.unitWeight);
        return Math.round(parseFloat((quantity * 100) / unitweight));
      } else {
        return parseFloat(quantity) / 100;
      }
    } else return 0;
  };
  getConvertedQtyFromQNT = (unitWeght) => {
    if (this.state.form_quantity !== "") {
      var unitweight = parseFloat(this.state.unitWeight);
      // var unitweight=150;

      if (this.state.unitConvertion.value === 2) {
        if (
          this.state.unitWeight !== undefined &&
          this.state.unitWeight !== ""
        ) {
          console.log("unitweight  " + this.state.unitWeight);
          return Math.round(
            parseFloat((this.state.form_quantity * unitweight) / 100)
          );
        } else return "";
      } else {
        return parseFloat(this.state.form_quantity) / 100;
      }
    } else return 0;
  };

  getConvertedQty = () => {
    if (this.state.form_quantity !== "") {
      var unitweight = parseFloat(this.state.unitWeight);
      // var unitweight=150;

      return parseFloat(this.state.form_quantity) / 100;
    } else return 0;
  };
  getTotalWeight = () => {
    var val = 0;
    console.log("rate", this.state.rate);
    console.log("form_quantity", this.state.form_quantity);

    if (this.state.rate !== "" && this.state.form_quantity !== "") {
      val = parseFloat(
        this.getConvertedQty() * parseFloat(this.state.rate)
      ).toFixed(2);
    }
    return val;
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "form_QualityId") {
      if (selectedValue !== this.state.form_QualityId) {
        this.setState({
          loadMr: true,
          godownValue: "",
          mrNo: "",
          rate: "",
          unitWeight: "",
          stock: "",
        });
        this.getMRList(
          this.state.selectedJuteType,
          selectedValue,
          this.getQuantityType(),
          this.state.godownValue
        );
        this.getGodownList(
          this.state.selectedJuteType,
          selectedValue,
          this.getQuantityType()
        );
      }
    }

    if (stateKey === "selectedJuteType") {
      if (selectedValue !== this.state.selectedJuteType) {
        this.setState({
          loadQuality: true,
          form_QualityId: 0,
        });
        this.getGodownList(
          selectedValue,
          this.state.form_QualityId,
          this.getQuantityType()
        );
      }
    }
    if (stateKey === "selectedJuteTypeConv") {
      if (selectedValue !== this.state.selectedJuteTypeConv) {
        this.setState({
          loadQuality: true,
          selectedJuteTypeConv: selectedValue,
        });
        //this.getGodownList(selectedValue, this.state.form_QualityId, this.getQuantityType());
      }
    }

    if (stateKey === "form_godownNo") {
      this.setState({
        loadMr: true,
      });
    }
    /* if (stateKey === 'selectedMukam' && this.state.invoiceList.length > 0 && !this.state.canChangeMukam) {
             this.unitConvertionAlert("Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!")
         }
         else*/
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      uom: values[1],
    });
  };
  getConvName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteConvName: values[0],
      uom: values[1],
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      if (this.state.updateView && this.state.indentItemId !== 0)
        data[this.state.id].deleteStatus = true;
      else {
        data.splice(this.state.id, 1);
        data.map((o, key) => {
          o.id = key;
          o.actions = (
            <div className="actions-right">
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.basicItemAlert("Do You Like To Update This Item?");

                      this.setState({
                        id: o.id,
                        indentItemId: o.indentItemId,
                        updateItemAlert: true,
                        updateItem: true,
                        unitConvertion: this.getDefaultUnitConvertion(
                          o.unitConversionName
                        ),
                        unitConvertionId: o.unitConversion,
                        unitConvertionName: o.unitConversionName,
                        selectedJuteType: parseInt(o.juteType),
                        selectedJuteName: o.juteName,
                        form_QualityId: o.quality,
                        selectedQualityName: o.qualityName,
                        form_godownNo: o.godownNo,
                        godownValue: o.godownValue,
                        mrNo: o.mrNo,
                        stockId: o.stockId,
                        form_quantity_bales: o.quantityBales,
                        form_quantity: o.weight * 100,
                        uom: o.unit,
                        selectedDeptId: o.deptId,
                        stock: o.stockValue,
                        yarnId: o.yarnId,
                        yarnType: o.yarnType,
                        // alert:true,
                      });
                      this.getGodownList(
                        o.juteType,
                        o.quality,
                        o.unitConversionName
                      );
                      this.getMRList(
                        o.juteType,
                        o.quality,
                        o.unitConversionName,
                        o.godownValue
                      );

                      return true;
                    }
                  });
                }}
                color="primaryNoBackground"
                customClass="remove"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      this.basicItemAlert("Do You Like To Delete This Item?");
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                        indentItemId: o.indentItemId,
                      });

                      //  data.splice(i,1);
                      return true;
                    }
                  });
                  this.setState({
                    selectedJuteType: "",
                    selectedJuteName: "",
                    selectedJuteQuality: "",
                    selectedQualityName: "",
                    quantity: "",
                    selectedUnit: "",
                    selectedDeptId: "",
                    selectedGrpId: "",
                    stock: "",
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>{" "}
            </div>
          );
        });
      }
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      percentage: "",
      weight: "",
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      id: 0,
      indentItemId: 0,
      selectedJuteType: "",
      selectedJuteTypeConv: "",
      selectedJuteName: "",
      form_QualityId: "",
      selectedQualityName: "",
      form_godownNo: "",
      mrNo: "",
      mrName: "",
      form_quantity_bales: "",
      form_quantity: "",
      selectedUnit: "",
      selectedDeptId: "",
      stock: "",
      unitweight: "",
      bales: "",
      quantity: "",
      unitBales: "",
      rate: "",
      stockValue: "",
      yarnId: 0,
      yarnType: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      id: 0,
      indentItemId: 0,
      unitConvertion: "",
      unitConvertionId: "",
      unitConvertionName: "",
      selectedJuteType: "",
      selectedJuteName: "",
      form_QualityId: "",
      selectedQualityName: "",
      form_godownNo: "",
      mrNo: "",
      stockId: "",
      form_quantity_bales: "",
      form_quantity: "",
      selectedUnit: "",
      selectedDeptId: "",
      stock: "",
      unitweight: "",
      rate: "",
      yarnTypeName: "",
      invoiceList: [],
      issueStatus: false,
      issueId: "",
      //   mrOptions:[],
      stockValue: "",
      showUpdate: false,
      bales: 0,
      unitBales: 0,
      yarnId: 0,
      yarnType: "",
      branchId: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    const { branchId } = this.state;

    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        issueId: prop.indentItemId,
        juteType: prop.juteType,
        finYear: this.state.year,
        issueDate: this.state.date1,
        juteQuality: prop.quality,
        baleLoose: prop.unitConversionName,
        totalWeight: prop.weight,
        quantity: prop.quantityBales,
        uomCode: prop.unit,
        godownNo: prop.godownValue,
        side: this.state.yarnTypeName,
        createdBy: getCookie(cons.USER_NAME),
        deptId: prop.deptId,
        wastageId: prop.wastageType,
        mrNo: prop.mrNo,
        stockId: prop.stockId,
        openStock: prop.stockValue,
        closeStock: (prop.stockValue - prop.weight).toFixed(2),
        yarnId: this.state.yarnId,
        issueValue: prop.issueValue,
        deleteStatus: prop.deleteStatus,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        updatedBy: getCookie(cons.USERID),
        issueStatus: "4",
        branchId: branchId,
      };
    });
    var data = {
      approveStatus: false,
      juteIssueList: invoiceList,
    };
    if (this.state.invoiceList.length > 0) {
      this.handleLoading();
      requestList(serverApi.UPDATE_JUTE_CONVERSION, data, this.handleAddIndent);
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
    this.handleLoading();
  };
  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { branchId, invoiceList, unitConvertionId } = this.state;

      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          issueId: prop.indentItemId,
          juteType: prop.juteType,
          finYear: this.state.year,
          issueDate: this.state.date1,
          juteQuality: prop.quality,
          baleLoose: prop.unitConversionName,
          totalWeight: prop.weight,
          quantity: prop.quantityBales,
          uomCode: prop.unit,
          godownNo: prop.godownValue,
          side: this.state.yarnTypeName,
          createdBy: getCookie(cons.USERID),
          deptId: prop.deptId,
          wastageId: prop.wastageType,
          mrNo: prop.mrNo,
          stockId: prop.stockId,
          openStock: prop.stockValue,
          closeStock: (prop.stockValue - prop.weight).toFixed(2),
          yarnId: prop.yarnId,
          issueValue: prop.issueValue,
          deleteStatus: prop.deleteStatus,
          companyId: getCookie(cons.COMPANY_ID),
          cipher: getCookie(cons.CIPHER),
          updatedBy: getCookie(cons.USERID),
          issueStatus: "",
          branchId: branchId,
        };
      });
      var data = {
        approveStatus: false,
        juteIssueList: invoiceList,
      };
      if (
        this.state.invoiceList.length > 0 &&
        branchId !== "" &&
        branchId !== 0 &&
        unitConvertionId !== "" &&
        unitConvertionId !== 0
      ) {
        this.handleLoading();
        requestList(
          serverApi.UPDATE_JUTE_CONVERSION,
          data,
          this.handleAddIndent
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onApproveButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { branchId, invoiceList, unitConvertionId } = this.state;
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$", invoiceList);

      var invoiceList = invoiceList.map((prop, key) => {
        return {
          id: prop.id,
          materialGrnHdrId: 4172,
          srcMrNo: prop.srcMrNo,
          srcMrLineId: prop.srcMrLineId,
          actualQuality: prop.sourceQuality,
          quantity: parseInt(prop.quantity),
          DrumConsumed: 0,
          balesConsumed: 2,
          stockQuantityConsumed: 35,
          itemId: parseInt(prop.sourceJuteType),
          actualWeight: prop.weight,
          acceptedWeight: prop.ac_weight,
          convertedQuality: prop.qualityConv,
          convertedJuteType: prop.juteConvType,
          isActive: 1,
          weight: prop.weight,
          percentage: parseFloat(prop.percentage),
          warehouseNo: prop.godownNo.value,
        };
      });
      var data = {
        materialGoodReceiveHeader: {
          id: this.state.headerId,
          goodReceiptDate: this.state.goodReceiptDate,
          unitConversion: this.state.unitConvertionName,
          status: this.state.issueStatus,
          companyId: getCookie(cons.COMPANY_ID),
          branchId: this.state.branchId,
          acYear: getCookie(cons.ACADEMIC_YEAR),
        },
        materialGRItemList: invoiceList,
      };
      if (
        this.state.invoiceList.length > 0 &&
        branchId !== "" &&
        branchId !== 0 &&
        unitConvertionId !== "" &&
        unitConvertionId !== 0
      ) {
        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$", data);
        //this.handleLoading();
        //requestList(serverApi.UPDATE_JUTE_CONVERSION, data, this.handleAddIndent)
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    this.setState({
      date1: moment(data).format("YYYY-MM-DD"),
    });
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { branchId, invoiceList, unitConvertionId } = this.state;
      var invoiceList = invoiceList.map((prop, key) => {
        return {
          srcMrNo: prop.srcMrNo,
          srcMrLineId: String(prop.srcMrLineId),
          actualQuality: prop.form_QualityId,
          quantity: parseInt(prop.quantity),
          itemId: parseInt(prop.selectedJuteType),
          actualWeight: prop.weight,
          acceptedWeight: prop.ac_weight,
          isActive: 1,
          convertedQuality: prop.form_QualityIdConv,
          convertedJuteType: prop.selectedJuteTypeConv,
          weight: prop.weight,
          percentage: parseFloat(prop.percentage),
          warehouseNo: prop.godownNo.value,
          // "issueDate": this.state.date1,
          // "juteQuality": prop.quality,
          // "baleLoose": prop.unitConversionName,
          // "totalWeight": prop.weight,
          // "quantity": prop.quantityBales,
          // "uomCode": prop.unit,
          // "godownNo": prop.godownValue,
          // "side": this.state.yarnTypeName,
          // "createdBy": getCookie(cons.USERID),
          // "deptId": prop.deptId,
          // "wastageId": prop.wastageType,
          // "mrNo": prop.mrNo,
          // "stockId": prop.stockId,
          // "openStock": prop.stockValue,
          // "closeStock": (prop.stockValue - prop.weight).toFixed(2),
          // "yarnId": prop.yarnId,
          // "issueValue": prop.issueValue,
          // "companyId": getCookie(cons.COMPANY_ID),
          // "cipher": getCookie(cons.CIPHER),
          // "branchId": branchId
        };
      });
      var data = {
        materialGoodReceiveHeader: {
          goodReceiptDate: moment().format("YYYY-MM-DD"),
          unitConversion: this.state.unitConvertionName,
          status: 1,
          companyId: parseInt(getCookie(cons.COMPANY_ID)),
          branchId: this.state.branchId,
          acYear: getCookie(cons.ACADEMIC_YEAR),
          mrType: "JC",
        },
        materialGRItemList: invoiceList,
      };

      if (
        this.state.invoiceList.length > 0 &&
        branchId !== "" &&
        branchId !== 0 &&
        unitConvertionId !== "" &&
        unitConvertionId !== 0
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_JUTE_CONVERSION, data, this.handleAddIndent);
      } else {
        console.log(
          "printing invoiceList.length",
          this.state.invoiceList.length
        );

        this.basicAlert("Please add atleast one line item...!!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };
  getQuantityType = () => {
    var val = this.state.unitConvertion.value;
    if (val === 2) {
      return "BALE";
    } else if (val === 3) {
      return "WASTAGE";
    } else if (val === 1) {
      return "LOOSE";
    } else {
      return "";
    }
  };
  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { branchId, unitConvertionId } = this.state;
    //  var id="";
    if (
      branchId !== "" &&
      branchId !== 0 &&
      unitConvertionId !== "" &&
      unitConvertionId !== 0
    )
      this.setState({
        loading: true,
        alert: true,
      });
    else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  handleSelectedValues = (defValue) => {
    if (defValue.value > 0 && defValue.value !== 3) {
      this.getGodownList(
        this.state.selectedJuteType,
        this.state.form_QualityId,
        defValue.label
      );
    }
    if (defValue.value === 3) {
      this.setState({
        selectedJuteType: "",
        selectedJuteName: "",
        form_QualityId: "",
        selectedQualityName: "",
        form_godownNo: "",
        mrNo: "",
        stockId: "",
        uom: "",
        stock: "",
        unitweight: "",
        rate: "",
      });
    }

    if (
      this.state.unitConversion !== defValue &&
      this.state.unitConversion !== "" &&
      this.state.unitConversion !== 0
    ) {
      // this.unitConvertionAlert("Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!")
      this.setState({
        selectedJuteType: 0,
        selectedJuteName: "",
        form_QualityId: 0,
        selectedQualityName: "",
        form_godownNo: "",
        godownValue: 0,
        mrNo: 0,
        stockId: 0,
        form_quantity_bales: "",
        form_quantity: "",
        selectedUnit: "",
        selectedDeptId: "",
        stock: "",
        unitweight: "",
        rate: "",
        loadQuality: true,
        loadMr: true,
        loadJuteType: true,
      });
    }
    this.setState({
      unitConvertion: defValue,
      unitConvertionId: defValue.value,
      unitConvertionName: defValue.label,
    });
  };
  getMRList = (selectedJuteType, form_QualityId, unit, godownNo) => {
    var data = {
      godownNo: godownNo,
      juteType: selectedJuteType,
      qualityId: form_QualityId > 0 ? form_QualityId : "",
      unitConversion: unit,
      cipher: getCookie(cons.CIPHER),
      companyId: getCookie(cons.COMPANY_ID),
    };
    requestList(
      serverApi.GET_STOCK_DETAILS_BY_GODOWN_NO,
      data,
      this.handleMrList
    );
  };

  //
  getGodownList = (selectedJuteType, form_QualityId, unit) => {
    var data = {
      juteType: selectedJuteType,
      qualityId: form_QualityId > 0 ? form_QualityId : "",
      unitConversion: unit,
      cipher: getCookie(cons.CIPHER),
      companyId: getCookie(cons.COMPANY_ID),
      branchId: this.state.branchId,
    };
    requestList(
      serverApi.SELECT_JUTE_ISSUE_GODOWN_LIST,
      data,
      this.handleGodownList
    );
  };
  handleMrList = (resp) => {
    if (resp.length > 0) {
      var stockValue = 0;
      //  if (this.updateItem) {
      resp.map((prop, i) => {
        //   console.log("stockValue11",prop);

        if (prop.value === this.state.stockId) {
          var values = prop.name.split("^");
          console.log("stockValue", prop);
          this.setState({
            stockValue: prop,
            stockId: prop.value,
            mrNo: values[1],
            rate: values[2],
            unitWeight: values[3],
            uom: values[4],
            unitBales: values[6],
            recQty: values[7],
          });
        }
      });

      // }
      this.setState({
        mrOptions: resp,
        loadMr: true,
        //   form_godownNo:"",
        //  godownValue:0,
        //  mrNo:"",
      });
    } else {
      this.setState({
        mrOptions: [],
        mrNo: "",
        stockId: 0,
        loadMr: true,
      });
    }
  };

  handleGodownList = (resp) => {
    if (resp.length > 0)
      this.setState({
        godownOptions: resp,
        mrOptions: [],
        //   form_godownNo:"",
        //  godownValue:0,
        //  mrNo:"",
      });
    else {
      this.setState({
        godownOptions: resp,
        loadMr: true,
        mrOptions: [],
        form_godownNo: "",
        godownValue: "",
        mrNo: "",
        stockId: 0,
      });
    }
    this.getMRList(
      this.state.selectedJuteType,
      this.state.form_QualityId,
      this.state.unitConvertionName,
      this.state.godownValue
    );
  };
  handleSelectedGodownValues = (defValue) => {
    if (this.state.godownValue !== defValue.value) {
      this.getMRList(
        this.state.selectedJuteType,
        this.state.form_QualityId,
        this.state.unitConvertionName,
        defValue.value
      );
      /*
                        this.setState({
                            loadMr: true,
                            //  mrNo:""
                        })
            */
    }

    this.setState({
      godownValue: defValue.value,
      form_godownNo: defValue,
    });
  };
  handleSelectedMrValues = (defValue) => {
    console.log("values of mr", defValue);
    var values = defValue.name,
      sumOfStock = 0,
      arr = this.state.invoiceList,
      sumOfBales = 0;
    values = values.split("^");
    var names = defValue.label;
    names = names.split("_");
    var srcMrNo = names[1].split("/");
    if (this.state.stockId !== defValue.value) {
      this.setState({
        stockId: defValue.value,
        stockValue: defValue,
        mrName: names[1],
        mrNo: values[1],
        rate: values[2],
        unitWeight: values[3],
        uom: values[4],
        stock: parseFloat(values[5]).toFixed(2),
        bales: values[6],
        quantity: values[6],
        valBale: values[6],
        unitBales: values[6],
        weight: parseFloat(values[6] * values[3] * 100).toFixed(2),
        percentage: 0,
        srcMrNo: srcMrNo[0],
        //  mrNo:""
      });
      arr.map((q) => {
        if (q.mrNo === values[1] && q.stockId === defValue.value) {
          sumOfStock = sumOfStock + parseFloat(q.weight);
          sumOfBales = sumOfBales + parseFloat(q.bales);
        }
      });
      console.log("rate", parseFloat(values[2]));

      console.log("sumOfStock", sumOfStock);
      console.log("stock", parseFloat(values[5]));
      console.log("bales", parseFloat(values[6]));

      if (sumOfStock > 0) {
        this.setState({
          stock: parseFloat(values[5] - sumOfStock).toFixed(2),
          bales: parseFloat(values[6] - sumOfBales),
          unitBales: parseFloat(values[6] - sumOfBales),
        });
      }
    } else {
      this.setState({
        mrNo: values[1],
        rate: values[2],
        unitWeight: values[3],
        uom: values[4],
      });
    }
  };

  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };
  afterRefreshMR = (val) => {
    this.setState({
      loadMr: val,
    });
  };

  onClickAddItem() {
    var arr = this.state.invoiceList;
    var sumOfQty = 0,
      quantityInItem = 0;
    var itemIndex = 0,
      quantity = 0,
      hasItem = false,
      indentItemId = 0;
    // if (this.state.updateItem) {
    //     itemIndex = this.state.id;
    //     indentItemId = this.state.indentItemId;
    //     quantityInItem = arr[itemIndex].quantityType;
    //     arr[itemIndex].quantityType = this.state.quantity
    // } else {
    //     itemIndex = this.state.invoiceList.length
    // }

    //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
    // arr.map(q => {
    //     sumOfQty = sumOfQty + parseInt(q.quantityType);
    //     /*
    //                 if (q.quality === this.state.form_QualityId && !this.state.updateItem)
    //                     hasItem = true;
    //     */
    // });
    // if (!this.state.updateItem) {
    //     sumOfQty = sumOfQty + parseInt(this.state.quantity);
    // }

    var addItemObj = {
      //id: itemIndex,
      //yarnId: this.state.yarnId,
      //yarnType: this.state.yarnType,
      //indentItemId: indentItemId,
      unitConversion: this.state.unitConvertionId,
      unitConversionName: this.state.unitConvertionName,
      sourceJuteType: this.state.selectedJuteType,
      sourceJuteName: this.state.selectedJuteName,
      juteConvType: this.state.selectedJuteTypeConv,
      juteConvName: this.state.selectedJuteConvName,
      sourceQuality: this.state.form_QualityId,
      sourceQualityName: this.state.selectedQualityName,
      qualityConv: this.state.form_QualityIdConv,
      qualityConvName: this.state.selectedQualityConvName,
      godownNo: this.state.form_godownNo,
      godownValue: this.state.godownValue,
      mrNo: this.state.mrNo,
      mrName: this.state.mrName,
      weight: this.state.weight,
      ac_weight: this.state.weight * 100,
      quantity: this.state.quantity,
      srcMrLineId: this.state.stockId,
      srcMrNo: this.state.srcMrNo,
      //issueValue: this.getTotalWeight(),
      //quantityBales: this.state.unitConvertionId !== 3 ? this.state.form_quantity_bales : this.state.form_quantity,
      //weight: (this.state.form_quantity / 100).toFixed(2),
      //unit: this.state.uom,
      //deptId: this.state.selectedDeptId,

      stockValue: this.state.stock,
      //wastageType: this.state.wastageType,
      deleteStatus: false,
      //stockOption: this.state.stockValue,
      bales: this.state.bales,
      rate: this.state.rate,
      percentage: this.state.percentage,
      weight: this.state.weight,

      actions: (
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    indentItemId: o.indentItemId,
                    updateItemAlert: true,
                    updateItem: true,
                    unitConvertionId: o.unitConversion,
                    unitConvertionName: o.unitConversionName,
                    unitConvertion: this.getDefaultUnitConvertion(
                      o.unitConversionName
                    ),
                    selectedJuteType: o.sourceJuteType,
                    selectedJuteName: o.sourceJuteName,
                    selectedJuteTypeConv: o.juteConvType,
                    selectedJuteConvName: o.juteConvName,
                    form_QualityId: o.sourceQuality,
                    selectedQualityName: o.sourceQualityName,
                    form_QualityIdConv: o.qualityConv,
                    selectedQualityConvName: o.qualityConvName,
                    form_godownNo: o.godownNo,
                    godownValue: o.godownValue,
                    mrNo: parseInt(o.mrNo),
                    mrName: o.mrName,
                    stockId: o.stockId,
                    form_quantity_bales: o.quantityBales,
                    ac_weight: o.weight * 100,
                    uom: o.unit,
                    selectedDeptId: o.deptId,
                    stock: o.stockValue,
                    stockValue: o.stockOption,
                    bales: o.bales,
                    rate: o.rate,
                    yarnId: o.yarnId,
                    yarnType: o.yarnType,
                    issueValue: o.issueValue,
                    percentage: o.percentage,
                    weight: o.weight,
                    quantity: o.quantity,
                    // alert:true,
                  });

                  //  this.getGodownList(o.juteType,o.quality,o.unitConversionName);

                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({
                    deleteItem: true,
                    id: o.id,
                    indentItemId: o.indentItemId,
                  });

                  //  data.splice(i,1);
                  return true;
                }
              });
              this.setState({
                selectedJuteType: "",
                selectedJuteName: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                quantity: "",
                selectedUnit: "",
                selectedDeptId: "",
                selectedGrpId: "",
                stock: "",
                stockId: "",
                percentage: "",
                weight: "",
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };
    // if (!hasItem) {
    if (this.state.wastageType === "") {
      if (
        this.state.unitConvertion !== "" &&
        this.state.selectedJuteType !== "" &&
        this.state.selectedJuteType !== "0" &&
        this.state.form_QualityId !== "" &&
        this.state.form_QualityId !== "0" &&
        this.state.form_godownNo !== "" &&
        this.state.form_godownNo !== "0" &&
        this.state.selectedJuteTypeConv !== "0" &&
        this.state.selectedJuteTypeConv !== "" &&
        this.state.form_QualityIdConv !== "0" &&
        this.state.form_QualityIdConv !== ""
      ) {
        if (this.state.quantity <= this.state.bales) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            hideJuteType: false,
            loading: true,
            invoiceList: arr,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Quantity Should not be greater");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      if (
        this.state.unitConvertion !== "" &&
        this.state.wastageType !== "0" &&
        this.state.selectedDepartment !== "0"
      ) {
        if (this.state.quantity <= this.state.bales) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            hideJuteType: false,
            loading: true,
            invoiceList: arr,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Quantity Should not be greater");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }

    /* if((sumOfQty) <= parseInt(this.getConvertedQty()))
         {
           */
    /*}else {
            if (this.state.updateItem){
                arr[itemIndex].quantityType = quantityInItem;
                this.setState({
                    invoiceList:arr,
                });
            }

            this.basicAlert("Quantity Should Not Exceed Converted Quantity..!!");
        }*/

    /*  }else {
              this.basicAlert("Please Enter All Mandatory Fields..!!");
          }*/
    /* } else {
             this.basicAlert("Item Already Saved For This Quality. Please Select Another One..!!");
         }*/
  }
  getYarnName = (val) => {
    this.setState({
      yarnType: val,
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      // stock: values[1]
    });
    console.log("selectedQualityName " + this.state.selectedQualityName);
  };
  getQualityNameConv = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityConvName: values[0],
      // stock: values[1]
    });
    console.log("selectedQualityName " + this.state.selectedQualityConvName);
  };
  afterRefreshJuteType = (val) => {
    this.setState({
      loadJuteType: val,
    });
  };

  getDataTable = () => {
    var data = [];
    this.state.invoiceList.map((prop, key) => {
      if (!prop.deleteStatus) {
        data.push(prop);
      }
    });
    return data;
  };
  render() {
    console.log("%%%%%%%%%%%%%%%%", this.state.invoiceList);
    const { classes } = this.props;
    const {
      selectedJuteType,
      yarnTypes,
      godownOptions,
      mrList,
      mrOptions,
    } = this.state;
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
      //{ value: 3, label: 'WASTAGE', name: 'WASTAGE' }
    ];
    const psoptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "WARP", name: "WARP" },
      { value: 2, label: "WEFT", name: "WEFT" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Source Jute Type{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      id={"selectedJuteType"}
                                      selectDisabled={this.state.updateItem}
                                      defValue={this.state.selectedJuteType}
                                      onSelect={this.onSelect}
                                      getName={this.getName}
                                      stateKey="selectedJuteType"
                                      isRefresh={this.state.loadJuteType}
                                      setIsRefresh={this.afterRefreshJuteType}
                                      staticData={this.state.juteTypeItems}
                                      //url={serverApi.GET_ITEMS_BY_GRPID + "999/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                      value={this.state.selectedJuteType}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Source Quality
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={this.state.updateItem}
                                      defValue={this.state.form_QualityId}
                                      id={"form_QualityId"}
                                      getName={this.getQualityName}
                                      onSelect={this.onSelect}
                                      isRefresh={this.state.loadQuality}
                                      setIsRefresh={this.afterRefreshQuality}
                                      stateKey="form_QualityId"
                                      url={
                                        serverVars.PO_API +
                                        "api/v1/" +
                                        this.state.selectedJuteType +
                                        "/" +
                                        getCookie(cons.COMPANY_ID) +
                                        "/getQualityByItem"
                                      }
                                      value={this.state.form_QualityId}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Godown No
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <Select
                                      defValue={this.state.form_godownNo}
                                      name={"form_godownNo"}
                                      value={this.state.form_godownNo}
                                      onChange={this.handleSelectedGodownValues}
                                      options={godownOptions}
                                      className={classes.selectFormControl}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: "4px",

                                        // height:"20px",
                                        colors: {
                                          ...theme.colors,
                                          //  primary25: '#00ABDC',
                                          primary: "#00ABDC",
                                          //   primary50:"#00ABDC"
                                        },
                                      })}
                                      styles={{
                                        control: (base, state) => ({
                                          ...base,
                                          height: "30px",
                                          "min-height": "30px",
                                        }),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Source MR No.
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    {/*
                                                                            <Select
                                                                                defValue={this.state.stockValue}
                                                                                name={"stockValue"}
                                                                                value={this.state.stockValue}
                                                                                onChange={this.handleSelectedMrValues}
                                                                                options={mrOptions}
                                                                                className={classes.selectFormControl}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    borderRadius: '4px',

                                                                                    // height:"20px",
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        //  primary25: '#00ABDC',
                                                                                        primary: '#00ABDC',
                                                                                        //   primary50:"#00ABDC"
                                                                                    },
                                                                                })}
                                                                                styles={{
                                                                                    control: (base, state) => ({
                                                                                        ...base,
                                                                                        height: '30px',
                                                                                        'min-height': '30px',
                                                                                    }),

                                                                                }}
                                                                            />
*/}

                                    <TextField
                                      id="mrName"
                                      value={this.state.mrName}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  mrAlert: true,
                                                });
                                                console.log(
                                                  "mrAlert",
                                                  this.state.mrAlert
                                                );
                                              }}
                                            >
                                              Get
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer className={classes}>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Stock
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      disabled={true}
                                      id="stock"
                                      value={this.state.stock}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      iType={"number"}
                                      InputProps={{
                                        style: { height: 30, color: "#000" },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              QNT
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {/* {this.state.unitConvertion.value === 3 ?
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Department<span
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "12px"
                                                                                    }}>*</span>
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <SelectboxTemp
                                                                                id={"selectedDepartment"}
                                                                                onSelect={this.onSelect}
                                                                                defValue={this.state.selectedDepartment}
                                                                                getName={this.getDeptName}
                                                                                stateKey="selectedDepartment"
                                                                                url={serverApi.SEARCH_DEPATMENTS + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                                value={this.state.selectedDepartment}
                                                                            />

                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid> : null}
                                                            {this.state.unitConvertion.value === 3 ?
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Wastage Type<span
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "12px"
                                                                                    }}>*</span>
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <SelectboxTemp
                                                                                id={"wastageType"}
                                                                                onSelect={this.onSelect}
                                                                                defValue={this.state.wastageType}
                                                                                //  getName={this.getQualityDetails}
                                                                                stateKey="wastageType"
                                                                                url={serverApi.WASTAGE_TYPES + '/' + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                                value={this.state.wastageType}
                                                                            />

                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid> : null} */}

                            {/* {this.getQuantityType() !== 'WASTAGE' ?
                                                                <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>

                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} md={6}>

                                                                            <GridContainer>
                                                                                <ItemGrid xs={12} sm={4}>
                                                                                    <InputLabel
                                                                                        className={classes.label}>
                                                                                        Quantity
                                                                                    </InputLabel>
                                                                                </ItemGrid>
                                                                                <ItemGrid xs={12} sm={8}>
                                                                                    <TextField
                                                                                        id="form_quantity_bales"
                                                                                        value={this.state.form_quantity_bales}
                                                                                        className={classes.textField}
                                                                                        onChange={this.onChangeValues}
                                                                                        fullWidth={true}
                                                                                        variant="outlined"
                                                                                        iType={"number"}

                                                                                        InputProps={{
                                                                                            style: {
                                                                                                height: 30,
                                                                                                color: "#000"
                                                                                            },
                                                                                            endAdornment:
                                                                                                <InputAdornment
                                                                                                    position="end">
                                                                                                    <span style={{
                                                                                                        color: "#00ABDC",
                                                                                                        fontSize: "16px",
                                                                                                        fontWeight: "400"
                                                                                                    }}>{this.state.unitConvertion.value === 2 ? 'BALES' : 'DRUMS'}</span>
                                                                                                </InputAdornment>,
                                                                                        }}
                                                                                    />

                                                                                </ItemGrid>
                                                                            </GridContainer>
                                                                        </ItemGrid>
                                                                        {parseFloat(this.state.form_quantity_bales).toFixed(1) === this.state.valBale ?
                                                                            <ItemGrid xs={12} md={6}>
                                                                                <GridContainer>

                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity"
                                                                                            value={(this.state.stock * 100).toFixed(2)}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>KG</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity_qnt"
                                                                                            value={this.state.stock}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>QNT</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                </GridContainer>
                                                                            </ItemGrid> :
                                                                            <ItemGrid xs={12} md={6}>
                                                                                <GridContainer>

                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity"
                                                                                            value={this.state.form_quantity}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>KG</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity_qnt"
                                                                                            value={(this.state.form_quantity / 100).toFixed(2)}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>QNT</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                </GridContainer>
                                                                            </ItemGrid>}


                                                                    </GridContainer>
                                                                </ItemGrid>
                                                                :
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Quantity
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <TextField
                                                                                id="form_quantity"
                                                                                value={this.state.form_quantity}
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                iType={"number"}

                                                                                InputProps={{
                                                                                    style: { height: 30, color: "#000" },
                                                                                    endAdornment: <InputAdornment
                                                                                        position="end">
                                                                                        <span style={{
                                                                                            color: "#00ABDC",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "400"
                                                                                        }}>KG</span>
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            />

                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <TextField
                                                                                disabled={true}
                                                                                id="form_quantity_QNT"
                                                                                value={this.getConvertedQty()}
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                InputProps={{
                                                                                    style: { height: 30, color: "#000" },
                                                                                    endAdornment: <InputAdornment
                                                                                        position="end">
                                                                                        <span style={{
                                                                                            color: "#00ABDC",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "400"
                                                                                        }}>QNT</span>
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            />

                                                                        </ItemGrid>


                                                                    </GridContainer>
                                                                </ItemGrid>
                                                            } */}
                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Quantity
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="quantity"
                                      value={this.state.quantity}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      iType={"number"}
                                      InputProps={{
                                        style: {
                                          height: 30,
                                          color: "#000",
                                        },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {this.state.unitConvertion
                                                .value === 2
                                                ? "BALES"
                                                : "DRUMS"}
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Jute Type Conv.{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      id={"selectedJuteTypeConv"}
                                      selectDisabled={this.state.updateItem}
                                      defValue={this.state.selectedJuteTypeConv}
                                      onSelect={this.onSelect}
                                      getName={this.getConvName}
                                      stateKey="selectedJuteTypeConv"
                                      isRefresh={this.state.loadJuteType}
                                      setIsRefresh={this.afterRefreshJuteType}
                                      staticData={this.state.juteTypeItems}
                                      //url={serverApi.GET_ITEMS_BY_GRPID + "999/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                      value={this.state.selectedJuteTypeConv}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Quality Conv.
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={this.state.updateItem}
                                      defValue={this.state.form_QualityIdConv}
                                      id={"form_QualityIdConv"}
                                      getName={this.getQualityNameConv}
                                      onSelect={this.onSelect}
                                      isRefresh={this.state.loadQuality}
                                      setIsRefresh={this.afterRefreshQuality}
                                      stateKey="form_QualityIdConv"
                                      url={
                                        serverVars.PO_API +
                                        "api/v1/" +
                                        this.state.selectedJuteTypeConv +
                                        "/" +
                                        getCookie(cons.COMPANY_ID) +
                                        "/getQualityByItem"
                                      }
                                      value={this.state.form_QualityIdConv}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Percentage
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      disabled={false}
                                      id="percentage"
                                      value={this.state.percentage}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      InputProps={inputFocus}
                                      iType={"number"}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.unitConvertion.value !== 3 ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Weight
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      disabled={true}
                                      defValue={this.state.weight}
                                      id="weight"
                                      value={this.state.weight}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      InputProps={inputFocus}
                                      iType={"number"}
                                      InputProps={{
                                        style: {
                                          height: 30,
                                          color: "#000",
                                        },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              KG
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {/* {this.state.unitConvertion.value !== 3 ?
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Total Value
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <TextField
                                                                                id="totalWeight"
                                                                                value={this.getTotalWeight()}
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                InputProps={inputFocus}
                                                                                iType={"number"}

                                                                            />

                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid>
                                                                : null}

                                                            <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Yarn Type<span
                                                                                style={{ color: "red", fontSize: "12px" }}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            id={"yarnId"}
                                                                            onSelect={this.onSelect}
                                                                            defValue={this.state.yarnId}
                                                                            getName={this.getYarnName}
                                                                            stateKey="yarnId"
                                                                            // staticData={yarnTypes}
                                                                            url={serverApi.YARN_MASTER_TYPE_LIST + getCookie(cons.COMPANY_ID)}
                                                                            value={this.state.yarnId}
                                                                        />

                                                                    </ItemGrid> 
                                                                </GridContainer>
                                                            </ItemGrid>*/}
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          JC Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Branch
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.updateView}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Unit Conversion
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateItem}
                          defValue={this.state.unitConvertion}
                          name={"unitConvertion"}
                          value={this.state.unitConvertion}
                          onChange={this.handleSelectedValues}
                          options={options}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Source Jute Type{" "}
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedJuteType"}
                            selectDisabled={this.state.updateItem}
                            defValue={this.state.selectedJuteType}
                            onSelect={this.onSelect}
                            getName={this.getName}
                            stateKey="selectedJuteType"
                            isRefresh={this.state.loadJuteType}
                            setIsRefresh={this.afterRefreshJuteType}
                            staticData={this.state.juteTypeItems}
                            //url={serverApi.GET_ITEMS_BY_GRPID + "999/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                            value={this.state.selectedJuteType}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Source Quality
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            selectDisabled={this.state.updateItem}
                            defValue={this.state.form_QualityId}
                            id={"form_QualityId"}
                            getName={this.getQualityName}
                            onSelect={this.onSelect}
                            isRefresh={this.state.loadQuality}
                            setIsRefresh={this.afterRefreshQuality}
                            stateKey="form_QualityId"
                            url={
                              serverVars.PO_API +
                              "api/v1/" +
                              this.state.selectedJuteType +
                              "/" +
                              getCookie(cons.COMPANY_ID) +
                              "/getQualityByItem"
                            }
                            value={this.state.form_QualityId}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Godown No
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <Select
                            defValue={this.state.form_godownNo}
                            name={"form_godownNo"}
                            value={this.state.form_godownNo}
                            onChange={this.handleSelectedGodownValues}
                            options={godownOptions}
                            className={classes.selectFormControl}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "4px",

                              // height:"20px",
                              colors: {
                                ...theme.colors,
                                //  primary25: '#00ABDC',
                                primary: "#00ABDC",
                                //   primary50:"#00ABDC"
                              },
                            })}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                height: "30px",
                                "min-height": "30px",
                              }),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Source MR No.
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          {/*
                                                                            <Select
                                                                                defValue={this.state.stockValue}
                                                                                name={"stockValue"}
                                                                                value={this.state.stockValue}
                                                                                onChange={this.handleSelectedMrValues}
                                                                                options={mrOptions}
                                                                                className={classes.selectFormControl}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    borderRadius: '4px',

                                                                                    // height:"20px",
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        //  primary25: '#00ABDC',
                                                                                        primary: '#00ABDC',
                                                                                        //   primary50:"#00ABDC"
                                                                                    },
                                                                                })}
                                                                                styles={{
                                                                                    control: (base, state) => ({
                                                                                        ...base,
                                                                                        height: '30px',
                                                                                        'min-height': '30px',
                                                                                    }),

                                                                                }}
                                                                            />
*/}

                          <TextField
                            id="mrName"
                            value={this.state.mrName}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        mrAlert: true,
                                      });
                                      console.log(
                                        "printing mrAlert",
                                        this.state.mrAlert
                                      );
                                    }}
                                  >
                                    Get
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.mrAlert ? (
                    /* {this.state.loadMr ? ( */
                    <Popup
                      closePopup={() => {
                        this.setState({ mrAlert: false });
                      }}
                      title="Post Temperature"
                      isShowSave={false}
                      isShowCancel={true}
                      cancel_button_text={"Close"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={3}>MR No</ItemGrid>
                            <ItemGrid xs={2}>Date</ItemGrid>
                            <ItemGrid xs={2}>Stock</ItemGrid>
                            <ItemGrid xs={2}>Unit Weight</ItemGrid>
                            <ItemGrid xs={2}>Bales/Drums</ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                height: 235,
                                overflowY: "auto",
                                overflowScrolling: "touch",
                              }}
                            >
                              <GridContainer>
                                {mrOptions.map((prop) => {
                                  var name = prop.label.split("_");
                                  var values = prop.name.split("^");
                                  return (
                                    <ItemGrid xs={12}>
                                      <GridContainer>
                                        <ItemGrid xs={3}>
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checked={
                                                  this.state.stockValue === prop
                                                }
                                                onChange={(event) => {
                                                  if (event.target.checked) {
                                                    this.handleSelectedMrValues(
                                                      prop
                                                    );
                                                  }
                                                }}
                                                value={2}
                                                name="radio button demo"
                                                aria-label="A"
                                                icon={
                                                  <FiberManualRecord
                                                    className={
                                                      classes.radioUnchecked
                                                    }
                                                  />
                                                }
                                                checkedIcon={
                                                  <FiberManualRecord
                                                    className={
                                                      classes.radioChecked
                                                    }
                                                  />
                                                }
                                                classes={{
                                                  checked: classes.radio,
                                                }}
                                              />
                                            }
                                            label={name[1]}
                                          />
                                        </ItemGrid>
                                        <ItemGrid
                                          xs={2}
                                          style={{
                                            marginTop: "10px",
                                          }}
                                        >
                                          {name[0]}
                                        </ItemGrid>
                                        <ItemGrid
                                          xs={2}
                                          style={{
                                            marginTop: "10px",
                                          }}
                                        >
                                          {values[5]}
                                        </ItemGrid>
                                        <ItemGrid
                                          xs={2}
                                          style={{
                                            marginTop: "10px",
                                          }}
                                        >
                                          {values[3]}
                                        </ItemGrid>
                                        <ItemGrid
                                          xs={2}
                                          style={{
                                            marginTop: "10px",
                                          }}
                                        >
                                          {values[6]}
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  );
                                })}
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer className={classes}>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Stock
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="stock"
                            value={this.state.stock}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            iType={"number"}
                            InputProps={{
                              style: { height: 30, color: "#000" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    QNT
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {/* {this.state.unitConvertion.value === 3 ?
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Department<span
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "12px"
                                                                                    }}>*</span>
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <SelectboxTemp
                                                                                id={"selectedDepartment"}
                                                                                onSelect={this.onSelect}
                                                                                defValue={this.state.selectedDepartment}
                                                                                getName={this.getDeptName}
                                                                                stateKey="selectedDepartment"
                                                                                url={serverApi.SEARCH_DEPATMENTS + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                                value={this.state.selectedDepartment}
                                                                            />

                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid> : null}
                                                            {this.state.unitConvertion.value === 3 ?
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Wastage Type<span
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "12px"
                                                                                    }}>*</span>
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <SelectboxTemp
                                                                                id={"wastageType"}
                                                                                onSelect={this.onSelect}
                                                                                defValue={this.state.wastageType}
                                                                                //  getName={this.getQualityDetails}
                                                                                stateKey="wastageType"
                                                                                url={serverApi.WASTAGE_TYPES + '/' + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                                value={this.state.wastageType}
                                                                            />

                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid> : null} */}

                  {/* {this.getQuantityType() !== 'WASTAGE' ?
                                                                <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>

                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} md={6}>

                                                                            <GridContainer>
                                                                                <ItemGrid xs={12} sm={4}>
                                                                                    <InputLabel
                                                                                        className={classes.label}>
                                                                                        Quantity
                                                                                    </InputLabel>
                                                                                </ItemGrid>
                                                                                <ItemGrid xs={12} sm={8}>
                                                                                    <TextField
                                                                                        id="form_quantity_bales"
                                                                                        value={this.state.form_quantity_bales}
                                                                                        className={classes.textField}
                                                                                        onChange={this.onChangeValues}
                                                                                        fullWidth={true}
                                                                                        variant="outlined"
                                                                                        iType={"number"}

                                                                                        InputProps={{
                                                                                            style: {
                                                                                                height: 30,
                                                                                                color: "#000"
                                                                                            },
                                                                                            endAdornment:
                                                                                                <InputAdornment
                                                                                                    position="end">
                                                                                                    <span style={{
                                                                                                        color: "#00ABDC",
                                                                                                        fontSize: "16px",
                                                                                                        fontWeight: "400"
                                                                                                    }}>{this.state.unitConvertion.value === 2 ? 'BALES' : 'DRUMS'}</span>
                                                                                                </InputAdornment>,
                                                                                        }}
                                                                                    />

                                                                                </ItemGrid>
                                                                            </GridContainer>
                                                                        </ItemGrid>
                                                                        {parseFloat(this.state.form_quantity_bales).toFixed(1) === this.state.valBale ?
                                                                            <ItemGrid xs={12} md={6}>
                                                                                <GridContainer>

                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity"
                                                                                            value={(this.state.stock * 100).toFixed(2)}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>KG</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity_qnt"
                                                                                            value={this.state.stock}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>QNT</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                </GridContainer>
                                                                            </ItemGrid> :
                                                                            <ItemGrid xs={12} md={6}>
                                                                                <GridContainer>

                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity"
                                                                                            value={this.state.form_quantity}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>KG</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="form_quantity_qnt"
                                                                                            value={(this.state.form_quantity / 100).toFixed(2)}
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            iType={"number"}
                                                                                            InputProps={{
                                                                                                style: {
                                                                                                    height: 30,
                                                                                                    color: "#000"
                                                                                                },
                                                                                                endAdornment:
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <span style={{
                                                                                                            color: "#00ABDC",
                                                                                                            fontSize: "16px",
                                                                                                            fontWeight: "400"
                                                                                                        }}>QNT</span>
                                                                                                    </InputAdornment>,
                                                                                            }}
                                                                                        />

                                                                                    </ItemGrid>
                                                                                </GridContainer>
                                                                            </ItemGrid>}


                                                                    </GridContainer>
                                                                </ItemGrid>
                                                                :
                                                                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Quantity
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <TextField
                                                                                id="form_quantity"
                                                                                value={this.state.form_quantity}
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                iType={"number"}

                                                                                InputProps={{
                                                                                    style: { height: 30, color: "#000" },
                                                                                    endAdornment: <InputAdornment
                                                                                        position="end">
                                                                                        <span style={{
                                                                                            color: "#00ABDC",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "400"
                                                                                        }}>KG</span>
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            />

                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <TextField
                                                                                disabled={true}
                                                                                id="form_quantity_QNT"
                                                                                value={this.getConvertedQty()}
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                InputProps={{
                                                                                    style: { height: 30, color: "#000" },
                                                                                    endAdornment: <InputAdornment
                                                                                        position="end">
                                                                                        <span style={{
                                                                                            color: "#00ABDC",
                                                                                            fontSize: "16px",
                                                                                            fontWeight: "400"
                                                                                        }}>QNT</span>
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            />

                                                                        </ItemGrid>


                                                                    </GridContainer>
                                                                </ItemGrid>
                                                            } */}
                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Quantity
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="quantity"
                            value={this.state.quantity}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            iType={"number"}
                            InputProps={{
                              style: {
                                height: 30,
                                color: "#000",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {this.state.unitConvertion.value === 2
                                      ? "BALES"
                                      : "DRUMS"}
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Jute Type Conv.{" "}
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedJuteTypeConv"}
                            selectDisabled={this.state.updateItem}
                            defValue={this.state.selectedJuteTypeConv}
                            onSelect={this.onSelect}
                            getName={this.getConvName}
                            stateKey="selectedJuteTypeConv"
                            isRefresh={this.state.loadJuteType}
                            setIsRefresh={this.afterRefreshJuteType}
                            staticData={this.state.juteTypeItems}
                            //url={serverApi.GET_ITEMS_BY_GRPID + "999/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                            value={this.state.selectedJuteTypeConv}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Quality Conv.
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            selectDisabled={this.state.updateItem}
                            defValue={this.state.form_QualityIdConv}
                            id={"form_QualityIdConv"}
                            getName={this.getQualityNameConv}
                            onSelect={this.onSelect}
                            isRefresh={this.state.loadQuality}
                            setIsRefresh={this.afterRefreshQuality}
                            stateKey="form_QualityIdConv"
                            url={
                              serverVars.PO_API +
                              "api/v1/" +
                              this.state.selectedJuteTypeConv +
                              "/" +
                              getCookie(cons.COMPANY_ID) +
                              "/getQualityByItem"
                            }
                            value={this.state.form_QualityIdConv}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Percentage
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={false}
                            id="percentage"
                            value={this.state.percentage}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                            iType={"number"}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.unitConvertion.value !== 3 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Weight
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            defValue={this.state.weight}
                            id="weight"
                            value={this.state.weight}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                            iType={"number"}
                            InputProps={{
                              style: {
                                height: 30,
                                color: "#000",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    KG
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {/*<ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                <LineItemTable
                                                    data={this.getDataTable()}
                                                    loading={this.state.loading}
                                                    disableActions={this.state.issueStatus === '3' || this.state.issueStatus === '4' || this.state.issueStatus === '5' || this.state.issueStatus === '6'}

                                                    columns={[
                                                        {
                                                            Header: "Source MR No",
                                                            accessor: "mrName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" },


                                                        },
                                                        {
                                                            Header: "Source Jute Type",
                                                            accessor: "sourceJuteName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }

                                                        },
                                                        {
                                                            Header: "Source Quality",
                                                            accessor: "sourceQualityName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Jute Type Conv.",
                                                            accessor: "juteConvName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }

                                                        },
                                                        /*  {
                                                              Header: "Quantity",
                                                              accessor: "quantity",
                                                              minWidth:100,
                                                              style: {color: "#000", textAlign: "left"}
                                                          },*/
                  /* {
                                                            Header: "Quality Conv.",
                                                            accessor: "qualityConvName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Godown No.",
                                                            accessor: "godownValue",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Percentage",
                                                            accessor: "percentage",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Weight",
                                                            accessor: "weight",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        // {
                                                        //     Header: "Issue Value",
                                                        //     accessor: "issueValue",
                                                        //     minWidth: 100,
                                                        //     style: { color: "#000", textAlign: "left" }
                                                        // },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: { color: "#000", textAlign: "center" },
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}

                                                />


                                            </ItemGrid>
                                            {/* {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={6}>
                                                            <span style={{fontStyle:"italic",fontWeight:400,color:"#003F66"}}>Status:</span>
                                                            <span style={{fontSize:"12px",color:"#003F66"}}>{this.state.statusDesc}<br/></span>
                                                            <span style={{fontStyle:"italic",fontWeight:400,color:"red"}}>Note:</span>
                                                            <span style={{fontSize:"12px",color:"red",fontStyle:"italic"}}>{"Indent Type can't be modified"}</span>
                                                        </ItemGrid>
                                                        <ItemGrid xs={6}>
                                                            <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                :*/}

                  {/*<ItemGrid xs={12}>
                                                {this.state.issueStatus !== '3' || this.state.issueStatus !== '4' && this.state.issueStatus !== '5' && this.state.issueStatus !== '6' ? <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button> : null}
                                            </ItemGrid>

                                            {/* <ItemGrid xs={12}
                                                style={{
                                                    height: 1,
                                                    backgroundColor: "#00acc1",
                                                    marginTop: "10px"
                                                }} />
                                            <ItemGrid xs={12} style={{ marginTop: "10px" }}>

                                                <GridContainer >
                                                    <ItemGrid xs={12} sm={7}>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>
                                                        <InputLabel className={classes.label}>
                                                            Total Issue Weight  :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>{this.getTotalIssueWeight() + " QNT"}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} style={{ marginTop: "10px" }}>

                                                <GridContainer >
                                                    <ItemGrid xs={12} sm={7}>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>
                                                        <InputLabel className={classes.label}>
                                                            Average Rate  :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>{this.getTotalBatchCost()}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} style={{ marginTop: "10px" }}>

                                                <GridContainer >
                                                    <ItemGrid xs={12} sm={7}>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>
                                                        <InputLabel className={classes.label}>
                                                            Total Issue Value :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>{this.getTotalIssueValue() + " INR"}


                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid> */}

                  {/* </GridContainer>
                                        </ItemGrid>  */}

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={
                              (this.onClickAddItem, this.onCreateButtonClick)
                            }
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onApproveButtonClick}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView && this.state.showUpdate ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={this.onRejectButtonClick}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={
                          serverApi.GET_TABLE_JUTE_CONVERSION +
                          "getJuteConersionList"
                        }
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        fileName={"jute_Conversion"}

                        //showDelete={true}
                        // onDeleteClick={this.handleDeleteClick}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  gridcontainer: {
    padding: "0 0 0 0",
    spacing: "2px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(JuteConversion);
