import React from "react";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";
import SelectboxMulti from "../CustumComponents/SelectboxMultiple";

class BeamingProduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      itemViewType: "id",
      tranDate: moment().format("DD-MM-YYYY"),
      time: moment().format("hh:mm:ss"),
      noOfBeams: "",
      noOfCuts: "",
      beamMcNo: "",
      ends: "",
      qualityCode: "",
      spell: "",
      qualityList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      qualityType: 1,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.WEAVING_QUALITY_MASTER, data, this.handleQuality);
  }
  handleQuality = (resp) => {
    var list = resp.data.map((prop) => {
      var obj = {
        label: prop.qualityName + "_" + prop.qualityCode,
        value: prop.qualityCode,
        name: prop.qualityName,
      };
      return obj;
    });
    this.setState({ qualityList: list, loadQuality: true });
  };

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "/getJuteIssueById/" +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp;

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
      });
    }
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    if (this.state.fromDate !== "" || this.state.todate !== "") {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: getCookie(cons.COMPANY_ID),
      };
    } else
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      yarnType: "",
      cuts: "",
      ends: "",
      machineNo: "",
      noOfBeams: "",
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      tranDate: moment().format("DD-MM-YYYY"),
      time: moment().format("hh:mm:ss"),
      noOfBeams: "",
      noOfCuts: "",
      beamMcNo: "",
      ends: "",
      qualityCode: "",
      spell: "0",
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      //tranDate:moment().format('DD-MM-YYYY'),
      time: moment().format("hh:mm:ss"),
      noOfBeams: "",
      noOfCuts: "",
      beamMcNo: 0,
      ends: "",
      //  qualityCode:"",
      // spell:"0",
      loadMachine: true,
      loadQuality: true,
      //  loadSpell:true,
      isRefresh: true,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.BEAMING_PRODUCTION_DELETE + id,
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        issueId: this.state.indentNo,
        juteType: this.state.selectedJuteType,
        finYear: this.state.year,
        issueDate: this.dateChanged(),
        juteQuality: this.state.form_QualityId,
        baleLoose: this.state.unitConvertionName,
        noBales: this.state.balesCount,
        quantity: this.state.form_quantity,
        uomCode: this.state.uom,
        openStock: "",
        closeStock: "",
        issueStatus: "",
        godownNo: this.state.form_godownNo,
        side: this.state.processSideName,
        createdBy: getCookie(cons.USER_NAME),
        wastageId: this.state.wastageType,
      };
      /*  if (this.state.date1 !== '' && this.state.unitConvertion !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                  this.handleLoading();
                  requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
              }
              else {
                  this.basicAlert("Please Enter All Mandatory Fields..!!");
              }*/
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        tranDate,
        time,
        noOfBeams,
        noOfCuts,
        beamMcNo,
        ends,
        qualityCode,
        spell,
      } = this.state;
      var data = {
        tranDate: tranDate,
        time: time,
        noOfBeams: noOfBeams,
        noOfCuts: noOfCuts,
        beamMcNo: beamMcNo,
        ends: ends,
        qualityCode: qualityCode,
        spell: spell,
        companyId: getCookie(cons.COMPANY_ID),
        createdBy: getCookie(cons.USERID),
      };

      if (
        tranDate !== "" &&
        time !== "" &&
        noOfBeams !== "" &&
        noOfCuts !== "" &&
        beamMcNo !== 0 &&
        beamMcNo !== "" &&
        ends !== "" &&
        qualityCode !== "" &&
        qualityCode !== 0 /*&&spell!==''&&spell!=='0'*/
      ) {
        this.handleLoading();
        requestList(
          serverApi.BEAMING_PRODUCTION_ADD,
          data,
          this.handleAddIndent
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss"),
        //  time2:dateObj.format('HH:mm:ss')
      });
  };

  render() {
    const { classes } = this.props;
    const { yarnTypes } = this.state;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Date
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "tranDate");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "tranDate",
                            value: this.state.tranDate,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Time
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="" fullWidth>
                        <Datetime
                          defaultValue={new Date()}
                          closeOnSelect={true}
                          // defaultValue={this.state.time1}
                          dateFormat={false}
                          onChange={(timeObj) => {
                            this.timeChanged1(timeObj, "time");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "outTime",
                            value: this.state.time,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Spell
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.spell}
                        id={"spell"}
                        onSelect={this.onSelect}
                        stateKey="spell"
                        isReload={this.state.loadSpell}
                        setIsReload={(val) => {
                          this.setState({
                            loadSpell: false,
                          });
                        }}
                        url={
                          serverApi.GET_ALL_SPELL_LIST +
                          getCookie(cons.COMPANY_ID) +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        value={this.state.spell}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Quality
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        id={"qualityCode"}
                        onSelect={this.onSelect}
                        defValue={this.state.qualityCode}
                        getName={this.getYarnName}
                        isRefresh={this.state.loadQuality}
                        setIsRefresh={(val) => {
                          this.setState({
                            loadQuality: false,
                          });
                        }}
                        stateKey="qualityCode"
                        staticData={this.state.qualityList}
                        // url={serverApi.SELECT_QUALITIES+getCookie(cons.COMPANY_ID)}
                        value={this.state.qualityCode}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Machine No's
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        id={"beamMcNo"}
                        onSelect={this.onSelect}
                        defValue={this.state.beamMcNo}
                        getName={this.getMachineName}
                        stateKey="beamMcNo"
                        isReload={this.state.loadMachine}
                        setIsReload={(val) => {
                          this.setState({
                            loadMachine: false,
                          });
                        }}
                        url={
                          serverApi.GET_FRAME_NUMBERS +
                          getCookie(cons.COMPANY_ID) +
                          "/BEAMING"
                        }
                        value={this.state.beamMcNo}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Cuts
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="noOfCuts"
                        // label="Multiline"
                        value={this.state.noOfCuts}
                        onChange={this.onChangeValues}
                        iType="number"
                        //className={classes.textField}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Ends<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="ends"
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        iType="number"
                        value={this.state.ends}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        No. Of Beams
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="noOfBeams"
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.noOfBeams}
                        iType="number"
                        fullWidth={true}
                        variant="outlined"
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                {!this.state.updateView ? (
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      <ItemGrid>
                        <Button
                          color="primary"
                          onClick={this.onCreateButtonClick}
                        >
                          Create
                        </Button>
                      </ItemGrid>

                      <ItemGrid>
                        <Button color="danger" onClick={this.handleCreate}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                ) : null}
                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={10} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={6}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            From date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            To date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "todate");
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.todate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={2}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                      <br />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={12}>
                  <TableComponent
                    url={serverApi.BEAMING_PRODUCTION_TABLE}
                    searchData={this.getData()}
                    isRefresh={this.state.isRefresh}
                    handleSearch={this.handleSeacrh.bind(this)}
                    isLoading={this.state.isRefresh}
                    onActionClick={this.handleDeleteClick}
                    itemViewType={this.state.itemViewType}
                    actionType={DELETE}
                    fileName={"BeamingProduction"}
                  />
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(BeamingProduction);
