import {
  Settings,
  LocationCity,
  GridOn,
  Dashboard as DashIcon,
} from "@material-ui/icons";
import ProductionIcon from "../assets/icons/Production.png";
import PurchaseIcon from "../assets/icons/Purchase.png";
import StoreIcon from "../assets/icons/StoreInventory.png";
import MasterIcon from "../assets/icons/Master.png";
import JuteIcon from "../assets/icons/JuteInventory.png";
import ReportIcon from "../assets/icons/Report.png";
import SecurityIcon from "../assets/icons/security.png";

import PurchaseOrder from "../views/Purchase/PurchaseOrder";
import StoreIndent from "../views/Store/StoreIndent";
import StoreReceipt from "../views/Store/StoreReceipt";
import MyProfile from "../views/Screens/MyProfile";
import JutePurchaseOrder from "../views/Jute/JutePO";
import JuteQualityCheck from "../views/Jute/JuteQualityCheck";
import JuteMaterailRec from "../views/Jute/JuteMR";
import JuteIndent from "../views/Jute/JuteIndent";
import JuteBillPass from "../views/Jute/JuteBillPass";
import juteGateEntry from "../views/Security/JuteGateEntry";
import StorGateEntry from "../views/Security/StoreGateEntry";
import StoreDebitCredit from "../views/Store/StoreDebitCredit";
import JuteIssue from "../views/Jute/JuteIssue";
import StoreBillPass from "../views/Store/StoreBillPass";
import StoreMaterialIns from "../views/Store/StoreMaterialInsc";
import StoreIssue from "../views/Store/StoreIssue";
import Jutewithvalue from "../views/Reports/Jute/JuteWithValue";
import JuteQualityWise from "../views/Reports/Jute/JuteQualityWise";
import JuteIssueReceipt from "../views/Reports/Jute/JuteIssueReceipt";
import JuteGodownWise from "../views/Reports/Jute/JuteGodownWise";
import JutePoChallanMr from "../views/Reports/Jute/JutePoChallanMr";
import JuteMrWise from "../views/Reports/Jute/JuteMRWise";
import MrInStock from "../views/Reports/Jute/MrInStock";
import MrWiseSales from "../views/Reports/Jute/MrWiseSales";
import RoleManagement from "../views/Settings/RoleManagement";
import UserManagement from "../views/Settings/UserManagement";
import ApprovalHirarchy from "../views/Settings/ApprovalHirarchy";
import AppDataJuteIssue from "../views/AppData/AppDataJuteIssue";
import AppDataBalesOpened from "../views/AppData/AppDataBalesOpened";
import AppDataSelectionEntry from "../views/AppData/AppDataSelectionEntry";
import AppDataWastageEntry from "../views/AppData/AppDataWastageEntry";
import AppDataSpreaderRolls from "../views/AppData/AppDataSpreaderRolls";
import AppDataWindingDoffEntry from "../views/AppData/AppDataWindingDoffEntry";
import AppDataFinshingEntry from "../views/AppData/AppDataFinshingEntry";
import AppDataSpinningDoffEntry from "../views/AppData/AppDataSpinningDoffEntry";
import AppDataCutsJugar from "../views/AppData/AppDataCutsJugar";
import BeamingProduction from "../views/Production/BeamingProduction";
import WeavingProdution from "../views/Production/WeavingProduction";
import supplierMaster from "../views/Master/SupplierMaster";
import DepartmentMaster from "../views/Master/DeptMaster";
import TaxMaster from "../views/Master/TaxMaster";
import WastageTypeMaster from "../views/Master/WastageTypeMaster";
import SubDeptMaster from "../views/Master/SubDeptMaster";
import spellMaster from "../views/Master/SpellMaster";
import CompanyMaster from "../views/Master/CompanyMaster";
import BranchMaster from "../views/Master/BranchMaster";
import DesignationMaster from "../views/Master/DesignationMaster";
import Machinemaster from "../views/Master/MachineMaster";
import vehicleTypeMaster from "../views/Master/VehicleTypeMaster";
import QualityMaster from "../views/Master/QualityMaster";
import MukamMaster from "../views/Master/MukamMaster";
import BrokerMaster from "../views/Master/BrokerMaster";
import AgentMaster from "../views/Master/AgentMaster";
import ItemMaster from "../views/Master/ItemMaster";
import ItemGroupMaster from "../views/Master/ItemGroupMaster";
import YarnMaster from "../views/Master/YarnMaster";
import WorkerMaster from "../views/Master/WorkerMaster";
import CostCenterMaster from "../views/Master/CostCenterMaster";
import LedgerMaster from "../views/Master/LedgerMaster";
import WorkerCategoryMaster from "../views/Master/WorkerCategoryMaster";
import WeavingQualityMaster from "../views/Master/WeavingQualityMaster";
import SpinningQualityMaster from "../views/Master/SpinningQualityMaster";
import LedgerGroupsMaster from "../views/Master/LedgerGroupsMaster";
import TravelCategoryMaster from "../views/Master/TravelCategoryMaster";
import BankAccountsMaster from "../views/Master/BankAccountsMaster";
import CustomerMaster from "../views/Master/CustomerMaster";
import HolidayMaster from "../views/Master/HolidayMaster";
import IndentReport from "../views/Reports/Store/IndentReport";
import BatchPlanForm from "../views/Jute/BatchPlanForm";
import ItemWiseIndentReport from "../views/Reports/Store/ItemWiseIndentReport";
import IndentWaitingForPo from "../views/Reports/Store/IndentWaitingForPo";
import IndentDetailsReport from "../views/Reports/Store/IndentDetailsReport";
import EmployeeDatabase from "../views/HRManagement/EmployeeDatabaseNew";
import LeaveRequest from "../views/HRManagement/LeaveRequest";
import TravelRequest from "../views/HRManagement/TravelRequest";
import PaySchemeCreation from "../views/HRManagement/PaySchemeCreation";
import PayRegister from "../views/HRManagement/PayRegister";
import PaySummary from "../views/HRManagement/PaySummary";
import PayAdvice from "../views/HRManagement/PayAdvice";
import SalaryAdjustment from "../views/HRManagement/SalaryAdjustment";
import LeavePolicies from "../views/HRManagement/LeavePolicies";
import TravelPolicies from "../views/HRManagement/TravelPolicies";
import ClientVisit from "../views/HRManagement/ClientVisit";
import AttendanceCalendar from "../views/HRManagement/AttendanceCalendar";
import PaySlip from "../views/HRManagement/PaySlip";
import Bills from "../views/Accounting/Bills";
import IssueCheckListReport from "../views/Reports/Store/IssueCheckListReport";
import Payments from "../views/Accounting/Payments";
import Receipts from "../views/Accounting/Receipts";
import LedgerWiseTrasactions from "../views/Accounting/LedgerWiseTrasactions";
import DayBook from "../views/Accounting/DayBook";
import Quotations from "../views/Sales/Quotations";
import Invoice from "../views/Sales/Invoice";
import PaySchemeParameters from "../views/HRManagement/PaySchemeParameters";
import LeaveTypes from "../views/Master/LeaveTypes";
import ExpenseBooking from "../views/HRManagement/ExpenseBooking";
import AdvanceRequest from "../views/HRManagement/AdvanceRequest";
import MasterDataUpload from "../views/Settings/MasterData";
import HelpTopics from "../views/Master/HelpTopics";
import ProblemMaster from "../views/Master/Problem_Master";
import PackageMaster from "../views/Master/PackageMasterNew";
import CarrierMaster from "../views/Master/CarrierMaster";
import Jobs from "../views/CallManagement/Jobs";
import Subscription from "../views/CallManagement/CustomerMapping";
import StockReport from "../views/Reports/Store/StockReport";
import TransportMaster from "../views/Master/TransportMaster";
import ProjectMaster from "../views/Master/ProjectMaster";
import StockTransfer from "../views/Store/StockTransfer";
import StockReturn from "../views/Store/StockReturn";
import MaterialRequest from "../views/Store/StoreMaterialRequest";
import PriceRequest from "../views/Purchase/PriceRequest";
import PriceRequestResponse from "../views/Purchase/PriceRequestResponse";
import EmailConfiguration from "../views/Settings/EmailConfiguration";
import SaleOrder from "../views/Sales/SaleOrder";
import IndentDetailsReportAllCompany from "../views/Reports/Store/IndentDetailsReportAllCompany";
import IndentDetailsReportWithQtyForAllCompany from "../views/Reports/Store/IndentDetailsReportWithQtyForAllCompany";
import PODetailsReportWithQtyForAllCompany from "../views/Reports/Store/PODetailsReportWithQtyForAllCompany";
import ProductProperties from "../views/Master/ProductProperties";
import CustomerPackageMapping from "../views/Master/CustomerPackageMapping";
import JobsNew from "../views/CallManagement/JobsNew";
import Terms from "../views/Master/Terms";
import TermPackageMapping from "../views/Master/TermPackageMapping";
import BatchDeviation from "../views/Reports/Jute/BatchDeviation";
import SpinningDoff from "../views/Reports/PiReports/SpinningDoffSummary";
import SpinningDoffDetails from "../views/Reports/PiReports/SpinningDoffDetails";
import EmployeeBilling from "../views/HRManagement/EmployeeBilling";
import EmpBillingPayRegister from "../views/HRManagement/EmpBillingPayRegister";
import Item_legder_report from "../views/Reports/Store/ItemLedgerReport";
import SupplierItemGrpMapping from "../views/Master/SupplierItemGrpMapping";
import JuteMarketRate from "../views/Jute/JuteMarketRate";
import ReconciliationReport from "../views/Reports/ReconciliationReport";
import JutePOmarketRate from "../views/Reports/Jute/JutePOMarketRate";
import DaywiseJuteReceipt from "../views/Reports/Jute/DaywiseJuteReceipt";
import MonthwiseJuteReceipt from "../views/Reports/Jute/MonthwiseJuteReceipt";
import JuteConversion from "../views/Jute/JuteConversion";
import RejectReason from "../views/Reports/Store/RejectReason";
import MaterialReciept from "../views/Reports/Jute/MaterialReceipt";
import StockView from "../views/Reports/Store/StockView";
import ItemWiseStockQuantity from "../views/Reports/Store/ItemWiseStockQuantity";
import DataColletion from "../views/HRManagement/DataCollection";
import EmpBankStatement from "../views/Reports/HrReports/EmpBankStatement";
import PaySummaryReport from "../views/Reports/HrReports/PaySummaryReport";
import PaySchemeWiseEmpSalary from "../views/Reports/HrReports/PaySchemeWiseEmpSalary";
import MonthsWiseSalarySummary from "../views/Reports/HrReports/MonthsWiseSalarySummary";
import MonthlySalaryEstimate from "../views/Reports/HrReports/MonthlySalaryEstimate";
import EsiChallan from "../views/Reports/HrReports/EsiChallan";
import SundayAttendance from "../views/Reports/HrReports/SundayAttendance";
import PfChallan from "../views/Reports/HrReports/PfChallan";
import BulkPayslip from "../views/Reports/HrReports/BulkPayslip";
import UomMaster from "../views/Master/UomMaster";
import DeliveryOrder from "../views/Sales/DeliveryOrder";
import DataPortal from "../views/Reports/DataPortal/DataPortal";

var menus = {
  Purchase: "Purchase",
  Store: "Store",
  HRMS: "HRMS",
  Payroll: "Payroll",
  MasterScreen: "masterscreen",
  Jute: "Jute",
  Reports: "Reports",
  Settings: "Settings",
  Security: "Security",

  MyProfile: "MyProfile",

  CONFIG: "/Home/Config",
  COMPANY: "/Home/Companies",
  DEPT: "/Home/Departments",
  USER: "/Home/UserCreate",
  DESIG: "/Home/Designations",
  Interview: "/Home/Resume",
};

export var subMenus = {
  //purchase sub menus
  JuteConversion: "/jute_conversion",
  Indent: "/jute_indent",
  JutePurchaseOrder: "/jute_purchase_order",
  JuteQualityCheck: "/jute_quality_check",
  JuteMaterailRec: "/jute_material_receipt",
  PurchaseOrder: "/purchase_purchase_order",
  juteGateEntry: "/security_jute_gate_entry",
  StorGateEntry: "/security_store_gate_entry",
  StoreDebitCredit: "/store_debit_credit_note",
  JuteBillPass: "/jute_bill_pass",
  StoreBillPass: "/store_bill_pass",
  StoreMaterialIns: "/store_material_inspection",
  JuteIssue: "/jute_issue",
  StoreIssue: "/store_issue",
  StoreIndent: "/store_indent",
  StoreReceipt: "/store_receipt",
  DeliveryOrder: "/delivery_order",
  //reports
  JuteWithValue: "/rj_jute_with_value",
  JuteQualityWise: "/rj_jute_qualityWise",
  JuteIssueReceipt: "/rj_issue_receipt_summary",
  JuteGodownWise: "/rj_godown_wise_stock",
  JutePoChallanMr: "/rj_deviation_po_challan_mr",
  JuteMrWise: "/rj_mr_wise_report",
  MrInStock: "/mr_in_stock",
  MrWiseSales: "/mr_wise_sales",
  JutePOMarketRate: "/po_market_rate_report",
  MonthwiseJuteReceipt: "/month_wise_jute_receipt",
  DaywiseJuteReceipt: "/day_wise_jute_receipt",
  RejectReason: "/reject_reason",
  MaterialReciept: "/material_receipt_report",
  DataPortal: "/data_portal",
  RoleManagement: "/role_settings",
  UserManagement: "/user_management",
  ApprovalHierarchy: "/approval_hirarchy",
  MasterDataUpload: "/master_dataupload",
  PurchasePriceRequest: "/purchase_price_request",
  AppDataJuteIssue: "/ad_jute_issue",
  AppDataBalesOpened: "/ad_bales_opened",
  AppDataWastageType: "/ad_wastage_entry",
  AppDataSelectionEntry: "/ad_selection_entry",
  AppDataSpreaderRolls: "/ad_speader_roles",
  AppDataWindingDoffEntry: "/ad_winding_doff_entry",
  AppDataFinishinhEntry: "/ad_finishing_entry",
  AppDataSpinningDoffEntry: "/ad_spinning_doff_entry",
  AppDataCutsJugar: "/ad_cuts_jugar_entry",
  BeamingProduction: "/pr_beaming_production",
  WeavingProdution: "/pr_weaving_production",
  //master menus
  SupplierMaster: "/supplier_master",
  DeptMaster: "/department_master",
  SubDeptMaster: "/sub_department_master",
  SpellMaster: "/spell_master",
  VehilceTypeMater: "/vehicle_types_master",
  QualityMaster: "/quality_master",
  ItemGroupMaster: "/item_group_master",
  ItemMaster: "/item_master",
  mukam_master: "/mukam_master",
  TaxMaster: "/tax_master",
  WastageTypeMaster: "/wastage_type_master",
  CompanyMaster: "/company_master",
  BranchMaster: "/branch_master",
  DesignationMaster: "/designation_master",
  Machinemaster: "/machine_master",
  vehicleTypeMaster: "/vehicle_types_master",
  BrokerMaster: "/broker_master",
  AgentMaster: "/agent_master",
  YarnMaster: "/yarn_master",
  WorkerMaster: "/worker_master",
  CostCenterMaster: "/Cost_Center_Master",
  LedgerMaster: "/Ledger_Master",
  WorkerCategoryMaster: "/Worker_Category_Master",
  WeavingQualityMaster: "/weaving_quality_master",
  SpinningQualityMaster: "/spinning_quality_master",
  LedgerGroupMaster: "/Ledger_Groups",
  TravelCategoryMaster: "/Travel_Catageries",
  BankAccountsMaster: "/Bank_Accounts",
  CustomerMaster: "/Customer_Master",
  HolidayCalendar: "/Holidays_Calendar",
  REPORTS_STORE_INDENT: "/rs_indent",
  BATCH_PLAN_FORM: "/batch_planning_form",
  ITEM_WISE_INDENT_REPORT: "/itemwise_indent",
  INDENT_WAITING_FOR_PO_REPORT: "/indent_waiting_po",
  INDENT_DETAIL_REPORT: "/rs_in_detail",
  EMPLOYEE_DATABASE: "/employeedb",
  LEAVE_REQUEST: "/leaverequest",
  TRAVEL_REQUEST: "/travelrequest",
  PAY_SCHEME_CREATION: "/payscheme",
  PAY_REGISTER: "/payregister",
  PAY_SUMMARY: "/paysummary",
  PAYMENT_ADVISE: "/paymentadvice",
  SALARY_ADJUSTMENT: "/salaryadj",
  LEAVE_POLICIES: "/leavepolicy",
  TRAVEL_POLICIES: "/travelpolicy",
  CLIENT_VISIT: "/clientvisit",
  ATTENDANCE_CALENDAR: "/attencalendar",
  PAY_SLIP: "/payslip",
  ACCOUNT_BILLS: "/bills",
  ISSUE_CHECK: "/issue_check",
  ACCOUNT_PAYMENTS: "/payments",
  ACCOUNT_RECEIPTS: "/receipts",
  LEDGER_WISE_TRANSACTIONS: "/ledgerwisetrans",
  ACCOUNT_DAY_BOOK: "/daybook",
  SALES_QUOTATIONS: "/quotation",
  SALES_INVOICE: "/invoice",
  SALES_ORDER: "/s_order",
  LEAVE_TYPES: "/leave_types",
  EXPENSE_BOOKING: "/expensebooking",
  ADVANCE_REQUEST: "/advancerequest",
  HELP_TOPICS: "/helptopic",
  PROBLEM_MASTER: "/problemmaster",
  PACKAGE_MASTER: "/packagemaster",
  CARRIER_MASTER: "/carriers",
  DataColletion: "/data_collection",
  //CUSTOMER_MAPPING:'/customerpackage',
  JOBS: "/jobs",
  STOCK_REPORT: "/stock",
  SUBSCRIPTION: "/subscriptions",
  TRANSPORT_MASTER: "/transporter_master",
  PROJECT_MASTER: "/project_master",
  STOCK_TRANSFER: "/stock_transfer",
  STOCK_RETURN: "/stock_return",
  STORE_MATERIAL_REQUEST: "/material_request",
  PR_RESPONSE: "/pr_response",
  Email_CONFIGURATION: "/emailConfiguration",
  CITY_INDENT_DETAIL_REPORT: "/city_indent_detail_report",
  CITY_INDENT_DETAIL_REPORT_WITH_QTY: "/qty_city_indent_detail_report",
  CITY_PO_DETAIL_REPORT_WITH_QTY: "/qty_city_po_detail_report",
  PRODUCT_PROPERTIES: "/product_properties",
  PACKAGE_CUSTOMER_MAPPING: "/pkg_customer_mapping",
  TERMS: "/terms",
  TERMS_PACKAGE_MAPPING: "/trm_pkg_mapping",
  BATCH_DEVIATION_REPORT: "/rj_batch_deviation",
  SPINNING_DOFF_SUMMARY: "/Spinning_Doff_Summary",
  SPINNING_DOFF_DETAILS: "/Spinning_Doff_Details",
  EMPLOYEE_BILLING: "/emp_billing",
  EMP_BILLING_PAYREGISTER: "/emp_pay_register",
  ITEM_LEDGER_REPORT: "/item_ledger_report",
  SUPPLIER_ITEM_GROUP_MAPPING: "/supplier_item_grp_mapping",
  JUTEMARKETRATE: "/jute_market_rate",
  RECONCILIATION_REPORT: "/report_reconciliation_report",
  STOCK_VIEW_REPORT: "/stock_view",
  ITEM_WISE_STOCK_QUANTITY_REPORT: "/item_wise_stock_quantity",
  ITEM_WISE_STOCK_VALUE_REPORT: "/item_stock_value",
  ITEM_GROUP_STOCK_REPORT: "/item_group_stock",
  //HR REPORTS
  EMP_TYPE_SUMMARY: "/emp_type_summary",
  EMP_DEPT_SUMMARY: "/emp_dept_summary",
  EMP_CAT_SUMMARY: "/emp_cat_summary",
  EMP_SUB_DEPT_SUMMARY: "/emp_sub_dept_summary",
  EMP_CAT_AND_DEPT_SUMMARY: "/emp_cat_and_dept_summary",
  EMP_DESIGNATION_SUMMARY: "/emp_design_summary",
  EMP_SUB_DEPT_AND_DESIGN_SUMMARY: "/emp_sub_dept_and_design_summary",
  PAY_SCHEME_EMP_SUMMARY: "/payscheme_emp_summary",
  EMP_PAY_SCHEME_LIST: "/emp_payscheme_list",
  EMP_LEAVES_LIST: "/emp_leaves_list",
  //LEAVE_TYPE_SUMMARY:'/leave_type_summary',
  EMP_PAY_STRUCTURES: "/emp_pay_structures",
  BRANCH_SAL_SUMMARY: "/branch_salary_summary",
  MONTH_BRANCH_SAL_SUMMARY: "/month_branch_sal_summary",
  BRANCH_PAYSCHEME_SAL_SUMMARY: "/branch_payscheme_sal_summary",
  BRANCH_CAT_SAL_SUMMARY: "/branch_cat_salary_summary",
  EmpBankStatement: "/_employee_bank_statement_report",
  PaySchemeWiseEmpSalary: "/pay_scheme_wise_employee_salary",
  PaySummaryReport: "/pay_summary_report",
  MonthsWiseSalarySummary: "/months_wise_salary_summary",
  MonthlySalaryEstimate: "/monthly_salary_estimate",
  EsiChallan: "/esi_challan",
  SundayAttendance: "/sunday_attendance_report",
  PfChallan: "/pf_challan",
  BulkPayslip: "/bulk_payslip",
  STORE_STOCK_WITH_VALUE_REPORT: "/store_stock_with_value",
  PAN_INDIA_STOCK_BALANCE: "/pan_india_stock_balance",
  UomMaster: "/uom_master",
};
export default function getIcon(name) {
  return icon(name);
}

export function getMini(name) {
  return mini("");
}

export function getComponent(name) {
  //    console.log("paaa", name);
  return componentSub(name);
}

export function getSubComponent(name) {
  return componentSub(name);
}

function comp(name) {
  //  console.log(name);
  switch (name) {
    /*  case menus.Purchase:
              return Purchase;
          case menus.Store:
              return StoreIndent;
          case menus.HRMS:
              return '';
          case menus.Payroll:
              return '';
          case menus.MasterScreen:
              return '';
          case menus.Jute:
              return '';
          case menus.Settings:
              return UserSettings;
          case menus.Security:
              return Security;
          case menus.Production:
              return Production;
          case menus.MyProfile:
              return MyProfile;*/

    default:
      return "";

    /* case menus.CONFIG:
         case menus.COMPANY:
             return Company;
         case menus.DEPT:
             return Department;

         case menus.DESIG:
             return Designation;
         case menus.Interview:
             return ScheduleInterview;*/
  }
}

function componentSub(name) {
  switch (name) {
    case subMenus.Indent:
      return JuteIndent;
    case subMenus.JutePurchaseOrder:
      return JutePurchaseOrder;
    case subMenus.JuteQualityCheck:
      return JuteQualityCheck;
    case subMenus.JuteMaterailRec:
      return JuteMaterailRec;
    case subMenus.PurchaseOrder:
      return PurchaseOrder;
    case subMenus.juteGateEntry:
      return juteGateEntry;
    case subMenus.StorGateEntry:
      return StorGateEntry;
    case subMenus.StoreDebitCredit:
      return StoreDebitCredit;
    case subMenus.JuteIssue:
      return JuteIssue;
    case subMenus.JuteBillPass:
      return JuteBillPass;
    case subMenus.StoreBillPass:
      return StoreBillPass;
    case subMenus.StoreMaterialIns:
      return StoreMaterialIns;
    case subMenus.JuteConversion:
      return JuteConversion;
    case subMenus.StoreIndent:
      return StoreIndent;
    case subMenus.StoreReceipt:
      return StoreReceipt;

    case subMenus.StoreIssue:
      return StoreIssue;
    //reports Sub sub menus
    case subMenus.JuteWithValue:
      return Jutewithvalue;
    case subMenus.JuteQualityWise:
      return JuteQualityWise;
    case subMenus.JuteIssueReceipt:
      return JuteIssueReceipt;
    case subMenus.JuteGodownWise:
      return JuteGodownWise;
    case subMenus.JutePoChallanMr:
      return JutePoChallanMr;
    case subMenus.JuteMrWise:
      return JuteMrWise;
    case subMenus.MrInStock:
      return MrInStock;
    case subMenus.MrWiseSales:
      return MrWiseSales;
    case subMenus.JutePOMarketRate:
      return JutePOmarketRate;
    case subMenus.RoleManagement:
      return RoleManagement;
    case subMenus.UserManagement:
      return UserManagement;
    case subMenus.ApprovalHierarchy:
      return ApprovalHirarchy;
    case subMenus.MasterDataUpload:
      return MasterDataUpload;
    case subMenus.DaywiseJuteReceipt:
      return DaywiseJuteReceipt;
    case subMenus.MonthwiseJuteReceipt:
      return MonthwiseJuteReceipt;
    case subMenus.RejectReason:
      return RejectReason;
    case subMenus.MaterialReciept:
      return MaterialReciept;
    case subMenus.DeliveryOrder:
      return DeliveryOrder;
    case subMenus.DataPortal:
      return DataPortal;

    // case subMenus.PurchasePriceRequest:
    //return AppDataJuteIssue;
    case subMenus.AppDataJuteIssue:
      return AppDataJuteIssue;
    case subMenus.AppDataBalesOpened:
      return AppDataBalesOpened;
    case subMenus.AppDataSelectionEntry:
      return AppDataSelectionEntry;
    case subMenus.AppDataWastageType:
      return AppDataWastageEntry;
    case subMenus.AppDataSpreaderRolls:
      return AppDataSpreaderRolls;
    case subMenus.AppDataSpinningDoffEntry:
      return AppDataSpinningDoffEntry;
    case subMenus.AppDataWindingDoffEntry:
      return AppDataWindingDoffEntry;
    case subMenus.AppDataFinishinhEntry:
      return AppDataFinshingEntry;
    case subMenus.AppDataCutsJugar:
      return AppDataCutsJugar;
    case subMenus.BeamingProduction:
      return BeamingProduction;
    case subMenus.WeavingProdution:
      return WeavingProdution;
    case subMenus.SupplierMaster:
      return supplierMaster;
    case subMenus.DeptMaster:
      return DepartmentMaster;
    case subMenus.SubDeptMaster:
      return SubDeptMaster;
    case subMenus.SpellMaster:
      return spellMaster;
    case subMenus.DataColletion:
      return DataColletion;

    case subMenus.JUTEMARKETRATE:
      return JuteMarketRate;

    case subMenus.RECONCILIATION_REPORT:
      return ReconciliationReport;

    case subMenus.QualityMaster:
      return QualityMaster;
    case subMenus.ItemMaster:
      return ItemMaster;
    case subMenus.mukam_master:
      return MukamMaster;
    case subMenus.TaxMaster:
      return TaxMaster;
    case subMenus.WastageTypeMaster:
      return WastageTypeMaster;
    case subMenus.BranchMaster:
      return BranchMaster;
    case subMenus.CompanyMaster:
      return CompanyMaster;
    case subMenus.DesignationMaster:
      return DesignationMaster;
    case subMenus.Machinemaster:
      return Machinemaster;
    case subMenus.vehicleTypeMaster:
      return vehicleTypeMaster;
    case subMenus.BrokerMaster:
      return BrokerMaster;
    case subMenus.AgentMaster:
      return AgentMaster;
    case subMenus.ItemGroupMaster:
      return ItemGroupMaster;
    case subMenus.YarnMaster:
      return YarnMaster;
    case subMenus.WorkerMaster:
      return WorkerMaster;
    case subMenus.PurchasePriceRequest:
      return PriceRequest;
    case subMenus.CostCenterMaster:
      return CostCenterMaster;
    case subMenus.LedgerMaster:
      return LedgerMaster;
    case subMenus.WorkerCategoryMaster:
      return WorkerCategoryMaster;
    case subMenus.WeavingQualityMaster:
      return WeavingQualityMaster;
    case subMenus.SpinningQualityMaster:
      return SpinningQualityMaster;
    case subMenus.LedgerGroupMaster:
      return LedgerGroupsMaster;
    case subMenus.TravelCategoryMaster:
      return TravelCategoryMaster;
    case subMenus.BankAccountsMaster:
      return BankAccountsMaster;
    case subMenus.CustomerMaster:
      return CustomerMaster;
    case subMenus.HolidayCalendar:
      return HolidayMaster;
    case subMenus.REPORTS_STORE_INDENT:
      return IndentReport;
    case subMenus.BATCH_PLAN_FORM:
      return BatchPlanForm;
    case subMenus.ITEM_WISE_INDENT_REPORT:
      return ItemWiseIndentReport;
    case subMenus.INDENT_WAITING_FOR_PO_REPORT:
      return IndentWaitingForPo;
    case subMenus.INDENT_DETAIL_REPORT:
      return IndentDetailsReport;
    case subMenus.EMPLOYEE_DATABASE:
      return EmployeeDatabase;
    case subMenus.LEAVE_REQUEST:
      return LeaveRequest;
    case subMenus.TRAVEL_REQUEST:
      return TravelRequest;
    case subMenus.PAY_SCHEME_CREATION:
      return PaySchemeCreation;
    case subMenus.PAY_REGISTER:
      return PayRegister;
    // case subMenus.PAY_SUMMARY:
    //   return PaySummary;
    case subMenus.PAYMENT_ADVISE:
      return PayAdvice;
    case subMenus.SALARY_ADJUSTMENT:
      return SalaryAdjustment;
    case subMenus.LEAVE_POLICIES:
      return LeavePolicies;
    case subMenus.TRAVEL_POLICIES:
      return TravelPolicies;
    case subMenus.CLIENT_VISIT:
      return ClientVisit;
    case subMenus.ATTENDANCE_CALENDAR:
      return AttendanceCalendar;
    case subMenus.PAY_SLIP:
      return PaySlip;
    case subMenus.ACCOUNT_BILLS:
      return Bills;
    case subMenus.ISSUE_CHECK:
      return IssueCheckListReport;
    case subMenus.ACCOUNT_PAYMENTS:
      return Payments;
    case subMenus.ACCOUNT_RECEIPTS:
      return Receipts;
    case subMenus.LEDGER_WISE_TRANSACTIONS:
      return LedgerWiseTrasactions;
    case subMenus.ACCOUNT_DAY_BOOK:
      return DayBook;
    case subMenus.SALES_QUOTATIONS:
      return Quotations;
    case subMenus.SALES_INVOICE:
      return Invoice;
    case subMenus.LEAVE_TYPES:
      return LeaveTypes;
    case subMenus.EXPENSE_BOOKING:
      return ExpenseBooking;
    case subMenus.ADVANCE_REQUEST:
      return AdvanceRequest;
    case subMenus.HELP_TOPICS:
      return HelpTopics;
    case subMenus.PROBLEM_MASTER:
      return ProblemMaster;
    case subMenus.PACKAGE_MASTER:
      return PackageMaster;
    case subMenus.CARRIER_MASTER:
      return CarrierMaster;
    case subMenus.SUBSCRIPTION:
      return CustomerPackageMapping;
    case subMenus.JOBS:
      return JobsNew;
    case subMenus.STOCK_REPORT:
      return StockReport;
    case subMenus.TRANSPORT_MASTER:
      return TransportMaster;
    case subMenus.PROJECT_MASTER:
      return ProjectMaster;
    case subMenus.STOCK_TRANSFER:
      return StockTransfer;
    case subMenus.STOCK_RETURN:
      return StockReturn;
    case subMenus.STORE_MATERIAL_REQUEST:
      return MaterialRequest;
    case subMenus.PR_RESPONSE:
      return PriceRequestResponse;
    case subMenus.Email_CONFIGURATION:
      return EmailConfiguration;
    case subMenus.SALES_ORDER:
      return SaleOrder;
    case subMenus.CITY_INDENT_DETAIL_REPORT:
      return IndentDetailsReportAllCompany;
    case subMenus.CITY_INDENT_DETAIL_REPORT_WITH_QTY:
      return IndentDetailsReportWithQtyForAllCompany;
    case subMenus.CITY_PO_DETAIL_REPORT_WITH_QTY:
      return PODetailsReportWithQtyForAllCompany;
    case subMenus.PRODUCT_PROPERTIES:
      return ProductProperties;
    case subMenus.PACKAGE_CUSTOMER_MAPPING:
      return CustomerPackageMapping;
    case subMenus.TERMS:
      return Terms;
    case subMenus.TERMS_PACKAGE_MAPPING:
      return TermPackageMapping;
    case subMenus.BATCH_DEVIATION_REPORT:
      return BatchDeviation;
    case subMenus.SPINNING_DOFF_SUMMARY:
      return SpinningDoff;
    case subMenus.SPINNING_DOFF_DETAILS:
      return SpinningDoffDetails;
    case subMenus.EMPLOYEE_BILLING:
      return EmployeeBilling;
    case subMenus.EMP_BILLING_PAYREGISTER:
      return EmpBillingPayRegister;
    case subMenus.ITEM_LEDGER_REPORT:
      return Item_legder_report;
    case subMenus.SUPPLIER_ITEM_GROUP_MAPPING:
      return SupplierItemGrpMapping;
    case subMenus.STOCK_VIEW_REPORT:
      return StockView;
    case subMenus.ITEM_WISE_STOCK_QUANTITY_REPORT:
      return ItemWiseStockQuantity;

    case subMenus.Emp_Bank_Statement:
      return EmpBankStatement;

    case subMenus.Pay_Summary_Report:
      return PaySummaryReport;

    case subMenus.pay_scheme_wise_employee_salary:
      return PaySchemeWiseEmpSalary;

    case subMenus.months_wise_salary_summary:
      return MonthsWiseSalarySummary;

    case subMenus.monthly_salary_estimate:
      return MonthlySalaryEstimate;

    case subMenus.esi_challan:
      return EsiChallan;

    case subMenus.sunday_attendance_report:
      return SundayAttendance;

    case subMenus.pf_challan:
      return PfChallan;

    case subMenus.bulk_payslip:
      return BulkPayslip;
    case subMenus.UomMaster:
      return UomMaster;

    default:
      return "";
  }
}

function mini(name) {
  switch (name) {
    case menus.CONFIG:
      return "CO";
    case menus.COMPANY:
      return "CC";
    case menus.DEPT:
      return "CD";
    case menus.USER:
      return "CU";
    case menus.DESIG:
      return "CD";
    case menus.Interview:
      return "SI";

    default:
      return "-";
  }
}

function icon(name) {
  switch (name) {
    case menus.Purchase:
      return PurchaseIcon;
    case menus.Store:
      return StoreIcon;
    case menus.HRMS:
      return PurchaseIcon;
    case menus.Payroll:
      return PurchaseIcon;
    case menus.MasterScreen:
      return MasterIcon;
    case menus.Jute:
      return JuteIcon;
    case menus.Reports:
      return ReportIcon;
    case menus.Settings:
      return PurchaseIcon;
    case menus.Security:
      return SecurityIcon;
    case menus.Production:
      return ProductionIcon;
    case menus.COMPANY:
      return LocationCity;
    case menus.DEPT:
      return GridOn;
    default:
      return DashIcon;
  }
}
