import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, getConfigValue, configNames} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList, uploadFile} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import LineItemTable from "../CustumComponents/LineItemTable";


class StockTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',

            isRefresh: false,
            isLoading: false,
            itemViewType: "srNo",
            approveStatus: false,
            updateView: false,


            alert: false,
            strDate:moment().format("DD-MM-YYYY"),
            invoiceList:[],srcBranch:"",desBranch:"",
            srStatus:"",
            remarks:"",
            itemId:'',
            itemDesc: '',
            quantity:'',
            uom: '',
            wareHouseName:'',
            wareHouseNo: '',
            rate: '',
            itemGrpId: '',
            itemGrp:'',
            deptId:'',
            srcSrNo:'',
            srcLineId:'',
            srcSuppCode:'',
            srcSuppName:'',
            srcSrPrintNo:'',
            expenseType:'',
            companyList:[],
            companyId:"",
            srcComp:getCookie(cons.COMPANY_ID),
            typeId:0

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
    }
    componentDidMount() {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.COMPANYMASTER_DATATABLE,data,this.handleCompaniesList)
    }
    handleCompaniesList=(resp)=>{
        var list=[];
        if(resp.data){
            resp.data.forEach(prop=>{
                var obj={label:prop.companyCode+"-"+prop.companyName,value:prop.companyId,name:prop.companyName};
                list.push(obj)
            })
        }
        this.setState({companyList:list,loadCompany:true})
    };

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };

    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getStoreMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
        }
    };
    handleViewClickResp = (resp) => {
        this.setState({printResp:resp});
        var header = resp.storeGoodReceiveHeader;
        var list = resp.storeGrnItemList,amountWithOutTax=0,amountWithTax=0;
        var taxType=header.taxType;
        var indentlist = list.map((prop, key) => {
            return (
                {
                    id: key,
                    lineItemId: prop.id,
                    storeGrnHdrId:prop.storeGrnHdrId,
                    itemId:prop.itemId,
                    itemDesc: prop.itemDesc,
                    quantity: prop.actualQuantity,
                    uom: prop.uomCode,
                    wareHouseNo: parseInt(prop.wareHouseNo),
                    rate: prop.rate,
                    itemGrpId: prop.itemGrpId,
                    itemGrp:prop.itemGrpId+""+prop.itemId,
                    deptId: prop.deptId,
                    srcSrNo:prop.srcSrNo,
                    srcLineId:prop.srcLineId,
                    srcSuppCode:prop.srcSuppCode,
                    srcSuppName:prop.srcSuppName,
                    wareName:prop.wareName,
                    srcSrPrintNo:prop.srcSrPrintNo,
                    isActive:prop.isActive
                }
            )
        });
        console.log("indentWeight " + header.indentWeight);
        if (header !== null) {
            //  alert(header.agentId);
            this.setState({
                updateView: true,
                isCreated: true,
                approveStatus: header.approveStatus,
                strNo: header.id,
                statusDesc: header.statusDesc,
                createdBy: header.submitter,
                createdOn: header.createDateDesc,
                invoiceList: indentlist,
                srStatus:header.status,
                remarks:header.remarks,
                strDate:header.strDateDesc,srcBranch:header.srcBranch,desBranch:header.desBranch,
                expenseType:header.expenseType,
                companyId:header.companyId,
                srcComp:header.srcComp
            });

        }


    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }
    getAgentName = (name) => {
        this.setState({
            agentName: name,
        });
    }
    getitemDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({

            itemDesc: values[0],
            itemGroupId: values[1],
            uom: values[2],
            hsnCode: values[4],
        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
    afterReloadSelect = (val) => {

        this.setState({
            reloadSelect: val
        });
    };
    afterRefreshquality = (val) => {

        this.setState({
            loadquality: val
        });
    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({

            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    handleTableLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }
    getData = () => {
        var data = {
            "fromDate":this.state.fromDate,
            "toDate":this.state.todate,
            "companyId":getCookie(cons.COMPANY_ID),
            "acYear": getCookie(cons.ACADEMIC_YEAR),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

        //  this.dateChanged();
    };
    dateChanged = (date) => {
        if(date!==null){
            var myDate = date;
            myDate = myDate.split("-");
            var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
            return new Date(newDate).getTime();
        }

    };


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected " + event.target.value);
        this.setState({[event.target.name]: event.target.value});
    };
    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    getConvertedQty = () => {
        if (this.state.unitConvertion.value === 2) {
            console.log("indentQuantity  " + this.state.indentQuantity);
            return Math.round(parseFloat(parseFloat((this.state.indentQuantity * 100) / 150)));
        } else {
            return "";
        }
    };
    getIndentQtyTon = () => {
        var val = this.state.indentQuantity;
        if (val !== "") {
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return parseFloat(parseFloat(val / 10).toFixed(2));
        } else {
            return "";
        }
    };
    getIndentQty = () => {
        var val = this.state.vehicleQuantity;
        console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
        if (val !== "" && this.state.indentQuantity !== "") {
            return parseInt(val) * parseInt(this.state.selectedIndentQuantity)
        } else {
            return "";
        }
    };


    onChangeValues = (event) => {
        var value = event.target.value;


        if (event.target.id === "vehicleQuantity") {
            console.log("val " + value);
            if (value !== null && value !== "") {
                if (this.state.selectedVehicleType !== "")
                    this.setState({
                        indentQuantity: parseInt(value) * parseInt(this.state.selectedIndentQuantity)
                    });
                else {
                    value = "";
                    this.basicAlert("Please Select Vehicle Type..!!")
                }
            }
        }
        if (event.target.id === "stock") {
            console.log("val " + this.state.indentQuantity);
            this.setState({
                stock: this.state.stock
            })
        }
        if (event.target.id === 'quantity' || event.target.id === 'rate') {
            var value = event.target.value;
            if (event.target.id === 'rate') {
                var Calrate = this.state.quantity;

            } else {
                var Calrate = this.state.rate;
            }
            if (Calrate !== '' && value != '') {
                var calAmount = parseFloat(Calrate) * parseFloat(value);
                calAmount = calAmount.toFixed(2);
                this.setState({
                    amount: calAmount,
                    itemTaxAmt: this.state.taxPercent > 0 ? (calAmount * this.state.taxPercent / 100) : this.state.itemTaxAmt
                });
            } else {
                this.setState({
                    amount: '',
                });
            }
        }

        this.setState({
            [event.target.id]: value
        });

    };


    onSelect = (selectedValue, stateKey) => {
        if (stateKey === 'suppielr_id') {
            this.setState({
                loadGateEntry: true
            })
        }
        if(stateKey === 'deptId'){
            this.setState({
                loadItemGroup:true
            })
        }
        if(stateKey === 'itemGrpId'&&selectedValue!==this.state.itemGrpId){
            this.setState({loadItem:true})
        }
        if(stateKey==='itemId'&&selectedValue!==this.state.itemId){
            this.setState({loadSr:true})
            var data={"itemGrpId":this.state.itemGrpId,"itemId":selectedValue,"companyId":getCookie(cons.COMPANY_ID),"branchId":this.state.branchId};
          //  requestList(serverApi.GET_BALANCE_STOCK_LIST,data,this.handleStock);
            requestList(serverApi.GET_BRANCH_WISE_STOCK,data,this.handleBrachStock);
        }
        if(stateKey==='companyId'){
            this.setState({loadBranch:true})
        }


        this.setState({
            [stateKey]: selectedValue
        });
    };
    handleBrachStock=(resp)=>{
        this.setState({branchStock:resp.data})
    };

    handleItem = (resp) => {
        this.setState({ItemOptions:resp,loadItem:true})

    }
    afterRefreshItem = (val) => {

        this.setState({
            loadItem: val
        });
    };

    afterRefreshItemGrp = (val) => {

        this.setState({
            loadItemGroup: val
        });
    };
    getDeptName = (selectedValue) => {
        this.setState({
            deptName:selectedValue
        });
    };
    getItemGroupName = (selectedValue) => {
        this.setState({
            itemGrpName:selectedValue
        });
    };

    getItemDetailsByName = (selectedValue) => {
        var values=selectedValue,amount=0;
        values=values.split("^");
        if (this.state.quantity !== "")
            amount = parseInt(values[3]) * parseInt(this.state.quantity);

        this.setState({
            itemDesc:values[0],
            uom:values[1],
           // stock:values[2],
        });


    };

    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
                updateItemAlert:false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;

            data.map((o, key) => {
                if(o.id===this.state.id){
                    if(this.state.updateView)
                        data[key].isActive=0;
                    else
                        data.splice(key, 1);
                }

            });

            //  if(this.state.approveStatus)


            this.setState({
                invoiceList: data,
                deleteItem: false
            });
            this.hideItemAlert()
        }
        this.setState({
            basicAlert: null,
        });

    }
    hideItemAlert() {
        console.log("alert");
        this.setState({
            //   alert: null,
            id: 0,
            loading: false,
            showMessagealert: false,
            indentAlert: false,
            item: '',
            itemId: "",
            selectedItemGroup: '',
            selectedDepartment: '',
            selectedItem: '',
            itemDesc: "",
            quantity: "",
            hsnCode: "",
            unit: "",
            weight: "",
            uom: "",
            taxId: '',
            itemGroupId: '',
            rate: "",
            amount: "",
            stock: "",
            actions: "",
            isgst: '',
            csgst: '',
            ssgst: '',
            purchaseType: '',
            lineItemId:0
        });
    }

    hideBasicAlert(){
        if(this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem:false,
            updateItemAlert:false
        });

    }
    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }
    hideAlert() {
        console.log("alert");
        this.setState({
            isJuteSelected: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            id: '',
            lineItemId: '',
            itemId:'',
            itemDesc: '',
            quantity:'',
            uom: '',
            wareHouseName:'',
            wareHouseNo: '',
            rate: '',
            itemGrpId: '',
            itemGrp:'',
            deptId:'',
            srcSrNo:'',
            srcLineId:'',
            srcSuppCode:'',
            srcSuppName:'',
            srcSrPrintNo:''
        });
    }

    hideAlertMessage() {
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            invoiceList:[],srcBranch:"",desBranch:"",
            srStatus:"",
            remarks:"",
            strDate:moment().format("DD-MM-YYYY"),
            expenseType:'',
            companyId:"",
            srcComp:getCookie(cons.COMPANY_ID),
            typeId:0
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: []

        });

    }
    getDefaultSideConvertion = (name) => {
        var val = 0, label = "Select..", data = "";
        if (name !== null&&name!==0 && name!=='') {
            if (name === 1) {
                val = 1;
                label = "INTER STATE";
            }
            else {
                val = 2;
                label = 'INTRA STATE';
            }

            data = {
                value: val, label: label
            };
        }
        return data;
    };

    unitConvertionAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    //warning
                    showCancel
                    style={{
                        display: "block",
                        marginTop: "-100px",
                        marginLeft: "-80px",
                        fontSize: "8px",
                        color: "black"
                    }}
                    title={msg}
                    onConfirm={() => this.hideUnitConvertionAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnText={"Yes"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    onRejectButtonClick = () => {
        var invoiceList = this.state.invoiceList.map((prop, key) => {
            return ({
                "id": prop.lineItemId,
                "storeGrnHdrId": prop.storeGrnHdrId,
                "itemId": prop.itemId,
                "additionalRequirements": "Not required",
                "advisedQuantity": '',
                "actualQuantity": prop.quantity,
                "deviation": '',
                "orderedPrice": '',
                "receivedPrice": '',
                "remarks": '',
                "receiveDate": '',
                "storeId": prop.storeId,
                "floor": '',
                "room": '',
                "rack": '',
                "position": '',
                "expiryDate": '',
                "batchno": '',
                "status": "1",
                "uomCode": prop.uom,
                "debitNotesFlag": '',
                "drNote": '',
                "drDate": '',
                "crNote": '',
                "crDate": '',
                "taxId": '',
                "taxPercentage": prop.taxPercent,
                "cTax": prop.csgst,
                "sTax": prop.ssgst,
                "iTax": prop.isgst,
                "amountWithTax": null,
                "amountWithOutTax":prop.amount,
                "wareHouseNo": prop.warehouseno,
                "rate": prop.rate
            })
        });

        var data = {
            "storeGoodReceiveHeader": {
                "id": this.state.srNo,
                "goodReceiptDate": this.dateChanged(this.state.srDate),
                "supplierId": this.state.form_supplierId,
                "poId": this.state.form_poNO,
                "poDate": this.dateChanged(this.state.poDate),
                "chalanNo": this.state.challanNo,
                "chalanDate": this.dateChanged(this.state.challanDate),
                "storeNo": "1",
                "status": "4",
                "submitter": getCookie(cons.USERID),
                "issuedTo": "21",
                "vehicleNo": this.state.form_vehicleNo,
                "taxType":this.state.taxType.value,
                consignmentNo:this.state.consignmentNo,
                consignmentDate:this.dateChanged(this.state.consignmentDate),
                "userId":getCookie(cons.USERID),
                remarks:this.state.remarks


            },
            "storeGrnItemList": invoiceList,
            "cipher":getCookie(cons.CIPHER)
        };
        this.handleLoading();
        requestList(serverApi.UPDATE_STORE_SR, data, this.handleAddIndent)
    };

    onApproveButtonClick = (status) => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var hasWareHouseNo=false;
            var createStatus=false;
            if(status===1){
                createStatus=true
            }
            const {strNo,strDate,invoiceList,srcBranch,desBranch,remarks,expenseType,companyId,srcComp}=this.state;
            var list = invoiceList.map((prop, key) => {

                return ({
                    "id":prop.lineItemId,
                    "itemId": prop.itemId,
                    "additionalRequirements": null,
                    "advisedQuantity": prop.quantity,
                    "actualQuantity": prop.quantity,
                    "rate": prop.rate,
                    "uomCode":prop.uom,
                    "finalQty": prop.quantity,
                    "wareHouseNo": prop.wareHouseNo,
                    "deptId": prop.deptId,
                    "itemGrpId": prop.itemGrpId,
                    "srcLineId":prop.srcLineId,
                    "srcSrNo":prop.srcSrNo,
                    "srcSuppCode":prop.srcSuppCode,
                    "status": status,
                    "isActive": prop.isActive,
                    "createdBy": getCookie(cons.USERID),
                })
            });

            var data = {
                "storeGoodReceiveHeader": {
                 //   "id":strNo,
                    "goodReceiptDate": this.dateChanged(strDate),
                    "status": status,
                    "submitter": getCookie(cons.USER_NAME),
                    "createdBy": getCookie(cons.USERID),
                    "srcComp":srcComp,
                    "acYear": getCookie(cons.ACADEMIC_YEAR),
                    "srcBranch":srcBranch,
                    "desBranch":desBranch,
                    "strDate":this.dateChanged(strDate),
                    "srType":"STR",
                    remarks:remarks,
                    "userId": getCookie(cons.USERID),
                    "expenseType":expenseType,
                    companyId:companyId

                },
                "storeGrnItemList": list,
                "cipher":getCookie(cons.CIPHER)
            };
            if(!createStatus){
                 data = {
                    "storeGoodReceiveHeader": {
                           "id":strNo,
                        "goodReceiptDate": this.dateChanged(strDate),
                        "status": status,
                        "submitter": getCookie(cons.USER_NAME),
                        "modBy": getCookie(cons.USERID),
                        "srcComp":srcComp,
                        "acYear": getCookie(cons.ACADEMIC_YEAR),
                        "srcBranch":srcBranch,
                        "desBranch":desBranch,
                        "strDate":this.dateChanged(strDate),
                        "srType":"STR",
                        "remarks":remarks,
                        "createStatus":createStatus,
                        "userId": getCookie(cons.USERID),
                        "expenseType":expenseType,
                        "branchId":desBranch,
                        companyId:companyId,


                    },
                    "storeGrnItemList": list,
                    "cipher":getCookie(cons.CIPHER)
                };

            }

            if (strDate !== '' && srcBranch !== "" && desBranch !== ""  && invoiceList.length >= 1&&expenseType!==""&&(srcBranch!==desBranch)) {
                this.handleLoading();
                if(createStatus)
                requestList(serverApi.STOCK_TRANSFER_ADD, data, this.handleAddIndent);
                else
                    requestList(serverApi.UPDATE_STORE_SR, data, this.handleAddIndent);
            } else if (this.state.invoiceList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            }else if(srcBranch!==desBranch){
                this.basicAlert("Source Branch And Destination Branch Should Not Be Same..!!");

            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }

    };


    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item
    onClickAddItem() {
        var arr = this.state.invoiceList;
        var itemIndex = 0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
        } else {
            itemIndex = this.state.invoiceList.length;
        }
        var addItemObj = {
            id: itemIndex,
            itemId: this.state.itemId,
            itemDesc: this.state.itemDesc,
            quantity: this.state.quantity,
            uom: this.state.uom,
            wareName:this.state.wareName,
            wareHouseNo: this.state.wareHouseNo,
            rate: this.state.rate,
            itemGrpId: this.state.itemGrpId,
            itemGrp:this.state.itemGrpId+""+this.state.itemId,
            deptId: this.state.deptId,
            lineItemId: this.state.lineItemId,
            srcSrNo:this.state.srcSrNo,
            srcLineId:this.state.srcLineId,
            srcSuppCode:this.state.srcSuppCode,
            srcSrPrintNo:this.state.srcSrPrintNo,
            srcSuppName:this.state.srcSuppName,
            isActive:1
        }

        if (this.state.itemId !== ""&&this.state.itemId !== "0"  && this.state.quantity !== "" && this.state.wareHouseNo !== "" &&this.state.srcLineId!=="") {
            if(parseFloat(this.state.quantity)<=parseFloat(this.state.stock)) {
                if (this.state.updateItem) {
                    arr[itemIndex] = addItemObj;
                } else
                    arr.splice(arr.length, 0, addItemObj);

                this.setState({
                    loading: true,
                    invoiceList: arr,
                    updateItem: false
                });
                this.hideAlert();
            }
            else {
                this.basicAlert("Quantity should be less than or equal to stock..!!");
            }
        } else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }


    }
    handleSelectedUnitValue = (defValue) => {
     /*   if (this.state.invoiceList.length > 0&&this.state.taxType!=='' && this.state.taxType !== defValue ) {
            this.unitConvertionAlert("Changing of Tax Type will remove all the line items. Click yes if you want to continue..!!")
        } else*/
        var data=this.state.invoiceList,sum_of_tax=0,sumOfamt=0;
        data.map((prop,i)=>{

            var SlectedTaxType = defValue.value;
            if (SlectedTaxType === 1) {
                prop.isgst=prop.taxPercent;
                prop.ssgst=0;
                prop.csgst=0;
            }  else if(SlectedTaxType === 2){
                var  taxPercent = prop.taxPercent / 2;
                prop.isgst=0;
                prop.ssgst=taxPercent;
                prop.csgst=taxPercent;
            }else {
                prop.isgst=0;
                prop.ssgst=0;
                prop.csgst=0;
            }
            /*  var itemAmount =parseFloat(prop.amount);

              var itemtaxAmount =itemAmount * parseFloat(prop.taxPercent) / 100;
              itemtaxAmount = Math.round(itemtaxAmount);
              prop.taxamount=itemtaxAmount;
              sum_of_tax=sum_of_tax+itemtaxAmount;
              sumOfamt=sumOfamt+itemAmount*/
        });
        this.setState(
            {
                invoiceList:data,
                //unitConversion: defValue,
                taxType: defValue,
            });
        //console.log(`Option selected:`, this.state.unitConvertionName);
    }

    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };


    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getQualityName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedQualityName: values[0],
            stock: values[1]
        });
        console.log("stock " + this.state.stock);

    };

    handleAddItem() {
        const {classes} = this.props;
        const {strDate,srcBranch,desBranch} = this.state;

        console.log(this.state);
        if(strDate !== '' && srcBranch !== ""&& srcBranch !== 0 && desBranch !== "" ) {
            this.setState({
                loading: true,
                alert: true,
                updateItem: false,
            });
        }
        else {
             this.basicAlert("Please Enter All Mandatory Fields..!!");
         }
    };

    getVehicleQlty = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedIndentQuantity: values[1],
            indentQuantity: values[1]
        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
    handleSelectedValues = (defValue) => {

        if (this.state.invoiceList.length > 0 && !this.state.canChangeUnit && this.state.unitConversion !== defValue) {
            this.unitConvertionAlert("Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!")
        } else
            this.setState(
                {
                    unitConvertion: defValue,
                    unitConvertionId: defValue.value,
                    unitConvertionName: defValue.label,
                    canChangeUnit: false

                });
        //console.log(`Option selected:`, this.state.unitConvertionName);
    };
    gettaxpercent = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        var taxPercent = values[1];

        var itemAmount = this.state.amount;
        if (taxPercent !== '' && itemAmount !== '') {
            var itemtaxAmount = parseFloat(itemAmount) * parseFloat(taxPercent) / 100;
          //  itemtaxAmount = Math.round(itemtaxAmount);
            this.setState({
                itemTaxAmt: itemtaxAmount.toFixed(2),
                taxPercent:taxPercent
            });
        }
        var SlectedTaxType = this.state.taxType.value;
        //alert(SlectedTaxType);
        if (SlectedTaxType === 1) {
            this.setState({
                igstPercent: taxPercent,
                sgstPercent: 0,
                cgstPercent: 0,
            });
        } else if(SlectedTaxType === 2){
            taxPercent = taxPercent / 2;

            this.setState({
                igstPercent: 0,
                sgstPercent: taxPercent,
                cgstPercent: taxPercent,
            });
        }


    };

    afterRefreshTax = (val) => {

        this.setState({
            loadTax: val
        });
    };
    getTotalTax(tax) {
        var totSgst = 0, totIgst = 0, totcgst = 0;
        var data = this.state.invoiceList;
        data.forEach((prop, key) => {
            console.log("itemTaxAmt", prop.itemTaxAmt);
            if (prop.ssgst !== undefined)
                totSgst = totSgst + (parseFloat(prop.amount) * parseFloat(prop.ssgst) / 100);
            if (prop.isgst !== undefined)
                totIgst = totIgst + (parseFloat(prop.amount) * parseFloat(prop.isgst) / 100);
            if (prop.csgst !== undefined)
                totcgst = totcgst + (parseFloat(prop.amount) * parseFloat(prop.csgst) / 100);
        });
        var totalTax = 0;
        if (tax === 'sgst') {
            totalTax = totSgst;
        } else if (tax === 'igst') {
            totalTax = totIgst;
        } else if (tax === 'cgst') {
            totalTax = totcgst;
        }
        return totalTax.toFixed(2);
    }
    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,entryId,isReject,isApprove,isAccept}=this.state;

        if(isAccept){
            this.setState({isAccept:false});
            this.onApproveButtonClick("29")

        }
        if(isReject){
            this.setState({isReject:false});
            this.onApproveButtonClick("4")
        }
        if(isApprove){
            this.setState({isApprove:false});
            this.onApproveButtonClick(this.state.srStatus)
        }

        if (uploadInvoice) {
            uploadFile(fileName,file,fileType,22,fileName,entryId, this.handleInvoiceResp);
            //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
            //  this.uploadInvoiceFile();
        }
        if (this.state.deleteInvoice) {

            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert1: null,
        });

    }

    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }
    getDataTable =()=>{
        var data=[];
        //  console.log("dta",JSON.stringify(this.state.invoiceList))
        if(this.state.invoiceList.length>0)
            this.state.invoiceList.forEach((prop,key)=>{
                console.log("prop",prop.isActive);
                prop.id=key;
                if(prop.isActive===1){

                    var obj={
                        ...prop,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to remove the data row */}
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;

                                        data.find((o, i) => {
                                            if (o.id === key) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                //  data.splice(i,1);
                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                this.setState({
                                                    //alert: true,
                                                    updateItemAlert:true ,
                                                    id: o.id,
                                                    lineItemId: o.lineItemId,
                                                    storeGrnHdrId:o.storeGrnHdrId,
                                                    updateItem: true,
                                                    reloadSelect: true,
                                                    itemId: o.itemId,
                                                    itemDesc: o.itemDesc,
                                                    quantity: o.quantity,
                                                    uom: o.uom,
                                                    wareHouseNo:o.wareHouseNo,
                                                    wareHouseName:o.wareHouseName,
                                                    rate: o.rate,
                                                    itemGrpId:o.itemGrpId,
                                                    itemGrp:o.itemGrp,
                                                    deptId: o.deptId,
                                                    srcSrNo:o.srcSrNo,
                                                    srcLineId:o.srcLineId,
                                                    srcSuppCode:o.srcSuppCode,
                                                    srcSuppName:o.srcSuppName,
                                                    srcSrPrintNo:o.srcSrPrintNo
                                                });

                                                return true;
                                            }
                                        });

                                    }}
                                    color="primaryNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === key) {

                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                this.setState({deleteItem: true,
                                                    id:o.id,});

                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                return true;
                                            }
                                        });
                                        this.setState({invoiceList: data});
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Close/>
                                </IconButton>{" "}


                            </div>

                        )

                    };


                    data.push(obj)
                }
            });
        return data;
    }

    render() {
        const {classes} = this.props;
        const {companyList,srcComp} = this.state;
        var companyId=getCookie(cons.COMPANY_ID);

        //console.log("jute type",selectedJuteType);
//add item button click
        const options = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'LOOSE', name: 'LOOSE'},
            {value: 2, label: 'BALE', name: 'BALE'}
        ];
        const ucoptions = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'INTER STATE', name: 'INTRA STATE'},
            {value: 2, label: 'INTRA STATE', name: 'INTER STATE'},

        ];
        const inputFocus = {
            /*classes: {
                root: classes.cssOutlinedInput,
                focused: classes.notchedOutline,
                notchedOutline: classes.notchedOutline,
            },*/
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var tomorrow = Datetime.moment().add( 0, 'day' );

        var isBackDate=getConfigValue(configNames.BACK_DATE_ALLOWABLE);
        var isfutureDate=getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);

        var valid = function( current ){
            if(isBackDate==="2"&&isfutureDate==="2")
                return current.isAfter( yesterday )&&current.isBefore( tomorrow );
            else if(isfutureDate==="2"&&isBackDate==="1"){
                return current.isBefore( tomorrow );
            } else if(isfutureDate==="1"&&isBackDate==="2"){
                return current.isAfter( yesterday );
            }
            else
                return true;
        };
        var labelType=getConfigValue(configNames.LABELS_TYPE);

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            // headerColor={"blue"}
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert ?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={this.state.updateItem ? "Update" : "save"}
                                                cancel_button_text={"Cancel"}
                                                //juteType={itemtype}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>

                                                                    <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Department<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.updateView&&this.state.updateItem}
                                                                                    id={"deptId"}
                                                                                    onSelect={this.onSelect}
                                                                                    defValue={this.state.deptId}
                                                                                    getName={this.getDeptName}
                                                                                    stateKey="deptId"
                                                                                    url={serverApi.SEARCH_DEPATMENTS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.updateView&&this.state.updateItem}
                                                                                    defValue={this.state.itemGrpId}
                                                                                    id={"itemGrpId"}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getItemGroupName}
                                                                                    isRefresh={this.state.loadItemGroup}
                                                                                    stateKey="itemGrpId"
                                                                                    setIsRefresh={(val)=>{
                                                                                        this.setState({loadItemGroup:false})
                                                                                    }}
                                                                                    url={serverApi.DATATABLE_SERVICE_URL+this.state.deptId+"/"+getCookie(cons.COMPANY_ID)+"/getAllItemGroupsByDepartmentId/"+getCookie(cons.CIPHER)}
                                                                                />


                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.updateView&&this.state.updateItem}

                                                                                    defValue={this.state.itemId}
                                                                                    id={"itemId"}
                                                                                  //  staticData={this.state.ItemOptions}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getItemDetailsByName}
                                                                                    isRefresh={this.state.loadItem}
                                                                                    stateKey="itemId"
                                                                                    setIsRefresh={this.afterRefreshItem}
                                                                                    postData={{
                                                                                        "itemGroupId":this.state.itemGrpId,
                                                                                        "saleable":"",
                                                                                        "consumable":"",
                                                                                        "companyId":getCookie(cons.COMPANY_ID),
                                                                                        "indentTypeId":this.state.typeId,
                                                                                        "inActiveItems":true
                                                                                    }}
                                                                                    url={serverApi.GET_ITEMS_BY_GRPID}
                                                                                    value={this.state.itemId}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    SR No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    //selectDisabled={this.state.updateView&&this.state.updateItem}
                                                                                    id={"srcLineId"}
                                                                                    onSelect={this.onSelect}
                                                                                    defValue={this.state.srcLineId}
                                                                                    getName={(val)=>{
                                                                                        var name=val.split('_');

                                                                                        var srStock=name[2],qty=name[2],rate=name[1];
                                                                                        if(this.state.updateView&&this.state.updateItem){
                                                                                           // srStock=parseFloat(srStock)+parseFloat(this.state.quantity);
                                                                                            qty=this.state.quantity;
                                                                                            rate=this.state.rate
                                                                                        }

                                                                                        var issueval=parseFloat(this.state.quantity)*parseFloat(this.state.rate);
                                                                                        this.setState({
                                                                                            srcSrNo:name[0],
                                                                                            rate:rate,
                                                                                            quantity:qty,
                                                                                            stock:srStock,
                                                                                            srcSuppCode:name[3]!=="null"?name[3]:"",
                                                                                            srcSuppName:name[4]!=="null"?name[4]:"",
                                                                                            issueValue:issueval.toFixed(2)
                                                                                        })
                                                                                    }}
                                                                                    getLabel={(name)=>{
                                                                                        var srprintNo=name.split("_");
                                                                                        srprintNo=srprintNo[0];
                                                                                        this.setState({srcSrPrintNo:srprintNo})
                                                                                    }}
                                                                                    isRefresh={this.state.loadSr}
                                                                                    setIsRefresh={(val)=>{
                                                                                        this.setState({loadSr:false})
                                                                                    }}
                                                                                    stateKey="srcLineId"
                                                                                    postData={{
                                                                                       // "deptId":this.state.deptId,
                                                                                        "itemGrpId":this.state.itemGrpId,
                                                                                        "itemId":this.state.itemId,
                                                                                        "companyId":getCookie(cons.COMPANY_ID),
                                                                                        "branchId":this.state.srcBranch
                                                                                    }}
                                                                                    url={serverApi.GET_SR_STOCK_LIST}
                                                                                   // staticData={this.state.srList}
                                                                                    value={this.state.srcLineId}
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Quantity<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="quantity"
                                                                                    className={classes.textField}
                                                                                    value={this.state.quantity}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    iType={"number"}
                                                                                    decimal={companyId==='1'||companyId==='2'||companyId==='116'?3:2}
                                                                                    inputProps={{
                                                                                        style: {
                                                                                            height: 30,
                                                                                            color: "#000"
                                                                                        },
                                                                                        classes: {
                                                                                            root: classes.cssOutlinedInput,
                                                                                            focused: classes.cssFocused,
                                                                                            notchedOutline: classes.notchedOutline,
                                                                                        },
                                                                                        autoComplete: 'off',
                                                                                        endAdornment: <InputAdornment
                                                                                            position="end">
                                                                                            <span style={{
                                                                                                color: "#00ABDC",
                                                                                                fontSize: "16px",
                                                                                                fontWeight: "400"
                                                                                            }}>{this.state.uom}</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                    style={{height: 30}}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={12}>
                                                                                <GridContainer>
                                                                                    <ItemGrid xs={12} sm={4}>
                                                                                        <InputLabel className={classes.label}>
                                                                                            Stock
                                                                                        </InputLabel>
                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={8}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="stock"
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            InputProps={inputFocus}
                                                                                            style={{height: 30}}
                                                                                            value={this.state.stock}
                                                                                            iType={"number"}
                                                                                        />

                                                                                    </ItemGrid>

                                                                                </GridContainer>
                                                                            </ItemGrid>


                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={6}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            {labelType==="2"?"Project Stock":" Branch Stock"}

                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="branchStock"
                                                                            disabled={true}
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            value={this.state.branchStock}
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            InputProps={inputFocus}
                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>


                                                            <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Price
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    diabled={true}
                                                                                    id="rate"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    InputProps={inputFocus}
                                                                                    style={{height: 30}}
                                                                                    value={this.state.rate}
                                                                                    decimal={companyId==='1'||companyId==='2'||companyId==='116'?3:2}
                                                                                    iType={"number"}
                                                                                />

                                                                            </ItemGrid>
                                                                            {(this.state.selectedJuteQuality > 0) ?
                                                                                <span style={{
                                                                                    fontSize: "14px",
                                                                                    marginLeft: "150px",
                                                                                    color: "red",
                                                                                    fontStyle: "italic"
                                                                                }}>{"Last purchase at  " + this.state.lastPurchase}</span> : null}
                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>
                                                            <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    {labelType==="2"?"Store No":"Warehouse No" }<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.wareHouseNo}
                                                                                    id={"wareHouseNo"}
                                                                                    getName={(name)=>{
                                                                                        this.setState({wareName:name})
                                                                                    }}
                                                                                    onSelect={this.onSelect}
                                                                                    stateKey="wareHouseNo"
                                                                                    postData={{"companyId":this.state.companyId,"type":"S","branchId":this.state.desBranch}}
                                                                                    url={serverApi.SELECT_GODOWN_LIST}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>
{/*
                                                            <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Amount<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="amount"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    inputProps={inputFocus}
                                                                                    style={{height: 30}}
                                                                                    value={this.state.amount}
                                                                                    iType={"number"}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>
*/}

                                                        </GridContainer>


                                                    </form>

                                                }
                                            >
                                            </Popup> : null
                                    }
                                    {this.state.basicAlert1}
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Stock Transfer Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        isValidDate={valid}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "strDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "strDate",
                                                            value: this.state.strDate,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.updateView?<ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Source Company  <span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={this.state.updateView}
                                                    id={"srcComp"}
                                                    defValue={this.state.srcComp}
                                                    onSelect={this.onSelect}
                                                    stateKey="srcComp"
                                                    isRefresh={this.state.loadCompany}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadCompany:false})
                                                    }}
                                                    staticData={companyList}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Source Project":"Source  Branch"} <span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={this.state.updateView}
                                                    id={"srcBranch"}
                                                    defValue={this.state.srcBranch}
                                                    onSelect={this.onSelect}
                                                    stateKey="srcBranch"
                                                    url={serverApi.SELECT_BRANCH+srcComp}
                                                    value={this.state.srcBranch}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Destination Company  <span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={this.state.updateView}
                                                    id={"companyId"}
                                                    defValue={this.state.companyId}
                                                    onSelect={this.onSelect}
                                                    stateKey="companyId"
                                                    isRefresh={this.state.loadCompany}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadCompany:false})
                                                    }}
                                                    staticData={companyList}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Destination Project":"Destination Branch"}  <span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={this.state.updateView}
                                                    id={"desBranch"}
                                                    defValue={this.state.desBranch}
                                                    onSelect={this.onSelect}
                                                    stateKey="desBranch"
                                                    isRefresh={this.state.loadBranch}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadBranch:false})
                                                    }}
                                                    url={serverApi.SELECT_BRANCH+this.state.companyId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Expenses Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <SelectboxTemp id={"expenseType"}
                                                    selectDisabled={this.state.updateView}
                                                               defValue={this.state.expenseType}
                                                               onSelect={this.onSelect}
                                                               stateKey="expenseType"
                                                               isReload={this.state.loadType}
                                                               setIsReload={(val)=>{
                                                                   this.setState({loadType:false})
                                                               }}
                                                               getName={(name)=>{
                                                                   var typeId=name.split("_");
                                                                   typeId=typeId[1];
                                                                   this.setState({typeId:typeId})
                                                               }}

                                                               url={serverApi.GET_STORE_INDENT_TYPES}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>



                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Remarks
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="remarks"
                                                    // label="Multiline"
                                                    fullWidth
                                                    multiline={true}
                                                    rowsMax="3"
                                                    value={this.state.remarks}
                                                    onChange={this.onChangeValues}
                                                    className={classes.textField}
                                                    height={60}
                                                    // helperText="hello"
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                <LineItemTable
                                                    data={this.getDataTable()}
                                                    loading={this.state.loading}
                                                    disableActions={this.state.srStatus==='3'||this.state.srStatus==='4'||this.state.srStatus==='5'||this.state.srStatus==='6'}
                                                    columns={[
                                                        {
                                                            Header: "Item Code",
                                                            accessor: "itemGrp",
                                                            minWidth:70,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Item Desc",
                                                            accessor: "itemDesc",
                                                            minWidth: 150,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "SR No",
                                                            accessor: "srcSrPrintNo",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Supplier",
                                                            accessor: "srcSuppName",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: 'Quantity',
                                                            accessor: "quantity",
                                                            minWidth: 80,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "UOM",
                                                            accessor: "uom",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header:labelType==="2"?"Store No":"Warehouse No",
                                                            accessor: "wareName",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },


                                                        {
                                                            Header: "Rate",
                                                            accessor: "rate",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },

                                                        {
                                                            Header: "Actions",
                                                            minWidth: 100,
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                />
                                            </ItemGrid>
                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={6}>
                                                            <span style={{
                                                                fontStyle: "italic",
                                                                fontWeight: 400,
                                                                color: "#003F66"
                                                            }}>Status:</span>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: "#003F66"
                                                            }}>{this.state.statusDesc}<br/></span>
                                                            {/*<span style={{*/}
                                                                {/*fontStyle: "italic",*/}
                                                                {/*fontWeight: 400,*/}
                                                                {/*color: "red"*/}
                                                            {/*}}>Note:</span>*/}
                                                            {/*<span style={{fontSize:"12px",color:"red",fontStyle:"italic"}}>{"Unit & Mukam can't modified"}</span>*/}
                                                        </ItemGrid>
                                                        <ItemGrid xs={6}>
                                                            <Button color={"info"} right={true}
                                                                    onClick={this.handleAddItem}>Add Item</Button>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add
                                                        Item</Button>
                                                </ItemGrid>

                                            }






                                        </GridContainer>
                                    </ItemGrid>
                                    {!this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{this.onApproveButtonClick(1)}}>Create</Button>

                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>:null
                                    }


                                    {this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            {this.state.approveStatus? <ItemGrid>
                                                <Button color="primary"
                                                        onClick={()=>{
                                                            this.setState({isApprove:true})
                                                            this.InvoicebasicAlert("Do You Like To Approve..!!");
                                                        }}>Approve</Button>

                                            </ItemGrid>:null}
                                                {this.state.approveStatus? <ItemGrid>
                                                <Button color="danger" onClick={()=>{
                                                    this.setState({isReject:true})
                                                    this.InvoicebasicAlert("Do You Like To Reject..!!");
                                                    }}>Reject</Button>
                                            </ItemGrid>:null}
                                            {(this.state.approveStatus&&this.state.srStatus!=='3')||this.state.srStatus=='1'?
                                                <ItemGrid>
                                                <Button color="primary"
                                                        onClick={()=>{this.onApproveButtonClick("")}}>Update</Button>
                                            </ItemGrid>:null}

                                        </GridContainer>

                                    </ItemGrid>:null}
                                   {/* {this.state.srStatus!=="21"?

                                        <ItemGrid xs={12} style={{marginTop:"15px"}}>
                                            <SamplePDF
                                                invoiceType={11} printResp={this.state.printResp}/>
                                        </ItemGrid>
                                        :null}*/}

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                            <GridContainer>

                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"fromDate",
                                                                        value:this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}

                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"toDate",
                                                                        value:this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>





                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>

                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>


                                                <ItemGrid xs={12}>

                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "15px"}}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            <TableComponent
                                                url={serverApi.STOCK_TRANSFER_LIST}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isLoading}
                                                handleLoading={this.handleTableLoading.bind(this)}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                fileName={'store_receipt_currentdate'}
                                                actionType={VIEW}/>

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }

    printDocument() {
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "srPrint/" + this.state.srNo +'/'+getCookie(cons.CIPHER), this.handlePrintResp)
    };
    handlePrintResp = (resp)=> {
        var printHeaderValues=resp.srHeader;
        var modules=[];
        var modules=resp.srLineItems;
        console.log('lineitems',modules);
        var printTotalvalues=resp.totals;
        //  this.setState({
        //      printHeaderValues:resp.srHeader,
        //      modules:resp.srLineItems,
        //      printTotalvalues:resp.totals,
        // })


        // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
        //     var printHeaderValues=this.state.printHeaderValues;
        // }
        var pageNo = 1;




        var printcopy='';
      var printCounnt=printHeaderValues.printCount;

        if(printCounnt==0){
            printcopy='NOT A VALID';
        }
        else if(printCounnt==1){
            printcopy='ORIGINAL';
        }else if(printCounnt==2){
            printcopy='DUPLICATE';
        }
        else if(printCounnt==3){
            printcopy='Triplicate';
        }
        else if(printCounnt==4){
            printcopy='Quadruplicate';
        }else{
            printcopy='COPY'+' '+printCounnt;
        }


        // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
        //      modules=this.state.printLineItems;
        //  }
        // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
        //   var  printTotalvalues=this.state.printTotalvalues;
        // }
        var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
        var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

        function inWords (num) {
            if ((num = num.toString()).length > 9) return 'overflow';
           var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
            if (!n) return; var str = '';
            str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
            str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
            str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
            str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
            str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
            return str;
        }


        function addWaterMark(doc,printcopy) {
            var totalPages = doc.internal.getNumberOfPages();

            for (i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
                doc.setFontSize(24);
                doc.setTextColor(200);
                doc.globalAlpha = 0.5
                //doc.zIndex(-1);
                doc.text(10, 30, printcopy);

                // doc.text(150, 600, printcopy,null,45);
            }

            return doc;
        }

        var config = {
            printHeaders: true,
            autoSize: true,
            margins: {left: 0, top: 0, bottom: 0, width: 0},
            fontSize: 10
        };
        const doc = new Jspdf('p', 'pt', 'a4');
        doc.setFontSize(16);
        var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth('STORES RECEIPT NOTE') * doc.internal.getFontSize() / 2);
        doc.text('STORES RECEIPT NOTE', xOffset, 40);
        doc.setFontSize(10);
        //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
        var TotalPgaeNo = doc.internal.getNumberOfPages();
        doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
        doc.setFontSize(10);
        var companyName = doc.splitTextToSize(printHeaderValues.companyName, 200);
        var supplier=doc.splitTextToSize(printHeaderValues.supplier, 200);
        doc.cell(30, 60, 260, 25, companyName, '', 'C');
        doc.cell(30, 60, 260, 25, supplier, '', 'C');
        doc.cellInitialize();
        doc.cell(30, 85, 520, 55, ' ', '', 'C');
        doc.text(250, 80, ' ');
        doc.setFontSize(9);
        doc.setFontType("bold");
        doc.text(35, 100, 'SR NO');
        doc.text(70, 100, ':');
        doc.setFontType("normal");
        doc.text(80, 100, printHeaderValues.srNo+'');
        doc.setFontType("bold");
        doc.text(150, 100, 'DATE : ');
        doc.setFontType("normal");
        doc.text(200, 100,  printHeaderValues.srDate+'');
        doc.setFontType("bold");
        doc.text(250, 100, 'CHALLAN NO');
        doc.text(320, 100, ':');
        doc.setFontType("normal");
        doc.text(340, 100, printHeaderValues.challanNo+'');
        doc.setFontType("bold");
        doc.text(400, 100, 'DATE');
        doc.text(425, 100, ':');
        doc.setFontType("normal");
        doc.text(450, 100, '12-06-2019 ');
        doc.setFontSize(9);
        doc.setFontType("bold");
        doc.text(35, 120, 'INW NO');
        doc.text(70, 120, ':');
        doc.setFontType("normal");
        doc.text(80, 120, printHeaderValues.srGateNo+'');
        doc.setFontType("bold");
        doc.text(150, 120, 'DATE : ');
        doc.setFontType("normal");
        doc.text(200, 120, printHeaderValues.srGateDate+'');


        doc.cellInitialize();
        doc.setFontType("bold");
        doc.cell(30, 140, 100, 25,'I/CODE/UNIT', '', 'C');
        doc.cell(70, 140, 150, 25,'DESCRIPTION/DETAIL/MAKE', '', 'C');
        doc.cell(170, 140, 60, 25, 'P/O NO.', '', 'C');
        doc.cell(210, 140, 70, 25, 'QUANTITY', '', 'C');
        doc.cell(310, 140, 70, 25, 'RATE', '', 'C')
        doc.cell(310, 140, 70, 25, 'AMOUNT', '', 'C')

        doc.cellInitialize();

        var cellWidths = ['', 100, 150, 60, 70, 70, 70];
        var cellTextaligns = ['', 'left', 'left', 'left', 'right', 'right', 'right', 'right', 'right', 'left', 'right'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k = 140;
            var i = 0;
            modules.map((prop, key) => {
                k = k + 25;
                i = i + 1;
                doc.cellInitialize();
                if (i === 27) {
                    k = 140;
                    i=0;
                    pageNo = pageNo + 1;
                    var today = moment().format('DD/MM/YYYY H:mm:ss');
                    var name = getCookie(cons.USERID);
                    var newdat = " Printed on: " + today;
                    var printBy = " Printed By: " + name;
                    doc.text(30, 800, newdat);
                    doc.text(500, 800, printBy);
                    doc.addPage('a4', 'p');

                    doc.setFontType("bold");
                    var companyName = doc.splitTextToSize('NELLIMARLA JUTE MILLS CO. LTD    ', 200);
                    doc.cell(30, 60, 260, 25, companyName, '', 'C');
                    doc.cell(30, 60, 260, 25, 'SANYASI RAJU & CO', '', 'C');
                    doc.cellInitialize();
                    doc.cell(30, 85, 520, 55, ' ', '', 'C');
                    doc.text(250, 80, ' ');
                    doc.setFontSize(9);
                    doc.setFontType("bold");
                    doc.text(35, 100, 'SR NO');
                    doc.text(70, 100, ':');
                    doc.setFontType("normal");
                    doc.text(80, 100, ' 123');
                    doc.setFontType("bold");
                    doc.text(150, 100, 'DATE : ');
                    doc.setFontType("normal");
                    doc.text(200, 100, '1235 ');
                    doc.setFontType("bold");
                    doc.text(250, 100, 'CHALLAN NO');
                    doc.text(320, 100, ':');
                    doc.setFontType("normal");
                    doc.text(340, 100, '258');
                    doc.setFontType("bold");
                    doc.text(400, 100, 'DATE');
                    doc.text(425, 100, ':');
                    doc.setFontType("normal");
                    doc.text(450, 100, '12-06-2019 ');
                    doc.setFontSize(9);
                    doc.setFontType("bold");
                    doc.text(35, 120, 'INW NO');
                    doc.text(70, 120, ':');
                    doc.setFontType("normal");
                    doc.text(80, 120, ' 123');
                    doc.setFontType("bold");
                    doc.text(150, 120, 'DATE : ');
                    doc.setFontType("normal");
                    doc.text(200, 120, '1235 ');
                    doc.setFontType("bold");
                    doc.text(250, 120, 'R/NOTE NO.');
                    doc.text(320, 120, ':');
                    doc.setFontType("normal");
                    doc.text(340, 120, '258');
                    doc.setFontType("bold");
                    doc.text(400, 120, 'DATE');
                    doc.text(425, 120, ':');
                    doc.setFontType("normal");
                    doc.text(450, 120, '12-06-2019 ');

                    doc.cellInitialize();
                    doc.setFontType("bold");
                    doc.setFontSize(9);
                    doc.cell(30, 140, 100, 25,'I/CODE/UNIT', '', 'C');
                    doc.cell(70, 140, 150, 25,'DESCRIPTION/DETAIL/MAKE', '', 'C');
                    doc.cell(170, 140, 60, 25, 'P/O NO.', '', 'C');
                    doc.cell(210, 140, 70, 25, 'QUANTITY', '', 'C');
                    doc.cell(310, 140, 70, 25, 'RATE', '', 'C')
                    doc.cell(310, 140, 70, 25, 'AMOUNT', '', 'C')

                    doc.setFontType("normal");
                    doc.setFontSize(7);
                }
                var a = 0;
                return (
                    Object.keys(prop).map((key, index) => {
                        a = a + 1;
                        if(prop[key]==0 || prop[key]==0.0){
                            var myItem=" ";
                        }

                        else{
                            if(key==='itemCode'){
                                var size=90;
                            }else{
                                var size=45;
                            }
                            var myItem = prop[key]+" ";
                            myItem = doc.splitTextToSize(myItem,size);
                        }


                        return (
                            doc.cell(30, k, cellWidths[a], 25, myItem, 2, cellTextaligns[a])
                        )
                    })

                )

            });

        }

        doc.cellInitialize();
        doc.setFontType("bold");
        doc.setFontSize(9);
        doc.cell(30, k + 25, 90, 25, 'GROSS AMOUNT ', '', 'right');
        doc.cell(105, k + 25, 80, 25, 'DISCOUNT ', '', 'right');
        doc.cell(147, k + 25, 40, 25, 'EXCISE ', '', 'right');
        doc.cell(189, k + 25, 40, 25, 'GST ', '', 'right');
        doc.cell(231, k + 25, 40, 25, 'SUR.CH ', '', 'right');
        doc.cell(189, k + 25, 60, 25,  'DELIVERY', '', 'right');
        doc.cell(271, k + 25, 60, 25, 'TOTAL DED ', '', 'right');
        doc.cell(271, k + 25, 40, 25, 'ROUND ', '', 'right');
        doc.cell(322, k + 25, 70, 25, 'NET AMOUNT ', '', 'right');


        doc.cellInitialize();
        doc.cell(30, k + 50, 90, 25, printTotalvalues.grossAmount +' ', '', 'right');
        doc.cell(105, k + 50, 80, 25, printTotalvalues.discount +' ', '', 'right');
        doc.cell(147, k + 50, 40, 25, printTotalvalues.excise +' ', '', 'right');
        doc.cell(189, k + 50, 40, 25, printTotalvalues.gst +' ', '', 'right');
        doc.cell(231, k + 50, 40, 25, printTotalvalues.surCh +' ', '', 'right');
        doc.cell(189, k + 50, 60, 25,  printTotalvalues.delivery +' ', '', 'right');
        doc.cell(271, k + 50, 60, 25, printTotalvalues.totDed +' ', '', 'right');
        doc.cell(271, k + 50, 40, 25, printTotalvalues.round +' ', '', 'right');
        doc.cell(322, k + 50, 70, 25,  printTotalvalues.netAmount+ ' ', '', 'right');
        doc.cellInitialize();
        var words= 'RUPEES  '+inWords(printTotalvalues.netAmount) ;
        doc.cell(30, k + 75, 520, 25, words.toUpperCase(), '', 'left');



        doc.setFontType("normal");
        doc.setFontSize(10);



        doc.text(30, k + 160, 'RECEIPT AUTHORITY');
        doc.text(200, k + 160, 'STORE AUTHORITY');
        doc.text(400, k + 160, ' AUTHORISED SIGNATORY');
        //doc.text(400, k + 180, 'Authorised Signature');
        doc.setFontSize(8);
        doc.setFontType("bold");
        var note='Note* : This is a computer generated print, Signature is not required.';
        var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
        doc.text(note, NotexOffset, k + 200);

        //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today = moment().format('DD/MM/YYYY H:mm:ss');
        var name =getCookie(cons.USERID);
        var newdat = " Printed on: " + today;
        var printBy = " Printed By: " + name;
        doc.text(30, k + 250, newdat);
        doc.text(500, k + 250, printBy);
        addWaterMark(doc,printcopy);
        doc.save('StroeReceipt.pdf');
    }

}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    cssFocused: {
        border: "1px solid #00ADBC",
        borderRadius: "4px"
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#00ADBC',
        },
        '&:after': {
            border: "2px solid #0ADBC",
            // borderColor: '#D2D2D2',
        },

    },

    notchedOutline: {},
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(StockTransfer);
