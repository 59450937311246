import React from "react";
import PropTypes from "prop-types";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "components/Cards/RegularCard.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Popup from "../../components/Popup/PopUp";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import {getDataFromUrl, requestList} from "../../server/server";
import cons, {serverApi} from "../../helper/Consts";
import InputLabel from "material-ui/Input/InputLabel";
import {getCookie} from "../../helper/Cookies";
import TextField from "../../components/CustomInput/CustomTextField";
import Datetime from "react-datetime";
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import IconButton from "components/CustomButtons/IconButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

class HolidayMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            classes_list: ["1st Standard", "2nd Standard", "3rd Standard", "4th Standard","5th Standard","6th Standard","7th Standard" ],
            selectClass: "",
            alert: null,
            eventName:"",
            showPopup: false,
            checked: [24, 22],
            description:""
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        //this.togglePopup = this.togglePopup.bind(this)
    }
    componentDidMount() {
       this.callHolidayMaster();
    }
    callHolidayMaster(){
        var data={"companyId":getCookie(cons.COMPANY_ID),"cipher":getCookie(cons.CIPHER)};
        requestList(serverApi.HOLIDAY_MASTER,data,this.handleHolidayMaster)
    }

    handleHolidayMaster=(resp)=>{
        console.log("resp  ",resp);
        var data=resp.data,eventList=[];
        //if(data!==null&&data.length>0){

            data.map((o,i)=>{
                const datedesc=o.holidayDesc.split('-');
                var today = new Date();
                console.log("getFullYear",today.getFullYear());
             var d=datedesc[0].replace(/^0+/, '');
                var date=parseInt(d),m=parseInt(datedesc[1]),y=parseInt(datedesc[2]);
                console.log("getYear",date);
                console.log("month",date);

                var obj={
                    title:o.description,
                    allDay: true,
                    start: new Date(y, m-1, date),
                    end: new Date(y, m-1, date),
                    color: "red",
                    id:o.id,
                    date:o.holidayDesc
                };
                eventList.push(obj)
            });
            this.setState({
                events:eventList
            })
        //}
    };
    onChangeValues(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }

    selectedEvent(event) {
        alert(event.title);
    }
    handleSimple =event  => {
        this.setState({ [event.target.name]: event.target.value });
    };
    onSlotChange(slotInfo) {
        console.log("toggle is true",slotInfo.start);
        var startDate = moment(slotInfo.start.toLocaleString()).format("DD-MM-YYYY");
        var selectedEvents=[];
        this.state.events.forEach((o,i)=>{
            if(o.date===startDate){
                selectedEvents.push(o)
            }
        });
        this.setState({
            holiday:startDate,
            showPopup: !this.state.showPopup,
            selectedEvents:selectedEvents
        });
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
            isLoading:false,
            description:"",
            holiday:"",
            updateEvent:false,
            addEvent:false
        });
    }
/*
    hideAlert() {
        this.setState({
            alert: null,
            showMessagealert:null
        });
    }
*/
    handleChange(e){
        this.setState({
            selectClass: e.target.value
        })
    }
    dayColors(date) {
        var style={
            backgroundColor: 'default'
        };

       if(this.state.events!==undefined){
           console.log("date",date);

           this.state.events.forEach((prop,i)=>{

               if(prop.start===date){
                   console.log("start",prop.start);

                   style={
                       backgroundColor: '#00ADBC'
                   }
               }
           })
       }
       // var backgroundColor = "event-";

        return {
            style: style
        };
    }

    eventColors(event, start, end, isSelected) {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    }
    onClickAddEvent=()=>{
        this.handleLoading();

        if(!this.state.updateEvent){
            var data={
                "holiday": this.state.holiday,
                "description":this.state.description,
                "createdby": getCookie(cons.USER_NAME),
                "companyId": getCookie(cons.COMPANY_ID)
            };

            if(this.state.description!==''&&this.state.description!==null)
            requestList(serverApi.HOLIDAY_MASTER_ADD,data,this.handleHolidayMasterAdd);
            else
                this.basicAlert("Please Enter Holiday Description..!!");


        }else {
            var data={
                id:this.state.id,
                "holiday": this.state.holiday,
                "description":this.state.description,
                "createdby": getCookie(cons.USER_NAME),
                "companyId": getCookie(cons.COMPANY_ID)
            };
            if(this.state.description!==''&&this.state.description!==null)
            requestList(serverApi.HOLIDAY_MASTER_UPDATE,data,this.handleHolidayMasterAdd);
        else
            this.basicAlert("Please Enter Holiday Description..!!");
        }

    };
    handleHolidayMasterAdd=(resp)=>{
        if (resp.status) {
            this.setState({
                showPopup:false,
                id:"",
                "holiday": "",
                "description":"",
                updateEvent:false,
                addEvent:false
            });
            this.callHolidayMaster();
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message)
        }else {
            this.showMessage(resp.message)
        }
        this.handleLoading();
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert:null,
            showPopup:false

        });
    }
    hideBasicAlert() {
        this.setState({
            basicAlert: null,
        });

    }


    showMessage = (msg) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        this.hideAlert();
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
        if (id !== 'holiday')
            this.setState({
                [id] :''
            });

    };
    eventStyleGetter=(event, start, end, isSelected) =>{
    console.log(event);
    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block'
    };
    return {
    style: style
}};
    deleteEvent=(resp)=>{
        //getDataFromUrl(serverApi.HOLIDAY_MASTER_DELETE+id+"/"+getCookie(cons.CIPHER),this.)
        if(resp.status) {
            var key = this.state.selectedEventIndex;
            var id = this.state.id;
            var selectedEvents = this.state.selectedEvents;
            selectedEvents.splice(key, 1);
            var events = this.state.events;
            events.find((o, i) => {
                console.log("id", o.id);
                if (o.id === id) {
                    events.splice(i, 1);
                    return true;
                }
            });
            this.setState({
                selectedEvents: selectedEvents,
                events: events,
                selectedEventIndex: "",
                id: "",
                showPopup:false
            });
            this.callHolidayMaster();
            this.showMessage(resp.message)
        }else {
            this.showMessage(resp.message)

        }
    };
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    render() {
        const { classes ,...rest} = this.props;
        var yesterday = Datetime.moment().subtract( 1, 'day' );

        var valid = function( current ){
            if(getCookie(cons.COMPANY_ID)!=='2')
                return current.isAfter( yesterday );
            else
                return true;
        };
        console.log("events" ,this.state.events);

        return (
            <div>
                {this.state.basicAlert}
                {this.state.alert}
                <GridContainer justify="center">
                    <ItemGrid xs={12} sm={12} md={10}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <BigCalendar
                                        selectable
                                        events={this.state.events}
                                        defaultView="month"
                                        scrollToTime={new Date(1970, 1, 1, 6)}
                                        defaultDate={new Date()}
                                        onSelectEvent={event => this.selectedEvent(event)}
                                        onSelectSlot={slotInfo =>  this.onSlotChange(slotInfo)}
                                        eventPropGetter={this.eventColors}
                                      //  dayPropGetter={this.dayColors.bind(this)}
                                    />
                                </div>

                            }
                        />
                    </ItemGrid>
                </GridContainer>

                {this.state.showMessagealert}


                {this.state.showPopup ?
                    <Popup closePopup={this.togglePopup.bind(this)}
                           onClickSave={() => this.onClickAddEvent()}
                           isShowSave={true}
                           isShowCancel={true}
                           save_btn_txt={this.state.updateEvent ? "update" : "Add"}
                           cancel_button_text={"Cancel"}
                           content={
                               <form>
                                   {(this.state.selectedEvents.length===0||this.state.updateEvent||this.state.addEvent)?
                                       <GridContainer>
                                       <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                           <GridContainer>
                                               <ItemGrid xs={12} sm={4}>
                                                   <InputLabel className={classes.label}>
                                                      Holiday Date : {this.state.holiday}
                                                   </InputLabel>
                                               </ItemGrid>
                                           </GridContainer>
                                       </ItemGrid>

                                       <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                           <GridContainer>
                                               <ItemGrid xs={12} sm={4}>
                                                   <InputLabel className={classes.label}>
                                                      Holiday Description<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                   </InputLabel>
                                               </ItemGrid>
                                               <ItemGrid xs={12} sm={8}>
                                                   <TextField
                                                       id="description"
                                                       value={this.state.description}
                                                       className={classes.textField}
                                                       onChange={this.onChangeValues}
                                                       fullWidth={true}
                                                       variant="outlined"
                                                   />
                                               </ItemGrid>
                                           </GridContainer>
                                       </ItemGrid>


                                       </GridContainer>:
                                       <GridContainer>
                                           <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                               <InputLabel className={classes.label}>
                                                   Holiday Date : {this.state.holiday}
                                               </InputLabel>
                                               <IconButton
                                                   onClick={() => {
                                                       this.setState({
                                                           addEvent:true,
                                                       })

                                                   }}
                                                   color="success" right={true}>
                                                   <Add/>
                                               </IconButton>


                                           </ItemGrid>
                                           <ItemGrid xs={12} md={8} style={{marginTop: "10px"}}>
                                               <InputLabel className={classes.label}>
                                                   Holiday Description
                                               </InputLabel>


                                           </ItemGrid>
                                           <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                               <InputLabel className={classes.label}>
                                                   Actions
                                               </InputLabel>{"  "}

                                           </ItemGrid>



                                           {this.state.selectedEvents.map((prop,key)=>{
                                               console.log("prop",prop.id);
                                               var id=prop.id;
                                               return(

                                                   <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                                       <GridContainer>
                                                           <ItemGrid xs={12} sm={8}>
                                                               {prop.title}
                                                           </ItemGrid>
                                                           <ItemGrid xs={12} sm={4}>
                                                               <IconButton
                                                                   onClick={() => {
                                                                       this.setState({
                                                                           updateEvent:true,
                                                                           id:id,
                                                                           selectedEventIndex:key,
                                                                           holiday:prop.date,
                                                                           description:prop.title,
                                                                       })
                                                                   }}
                                                                   color="primaryNoBackground"
                                                                   customClass="remove">
                                                                   <Edit/>
                                                               </IconButton>
                                                               <IconButton
                                                                   onClick={() => {
                                                                       this.setState({
                                                                           selectedEventIndex:key,
                                                                           selectedId:id
                                                                       });
                                                                       getDataFromUrl(serverApi.HOLIDAY_MASTER_DELETE+id+"/"+getCookie(cons.CIPHER),this.deleteEvent)
                                                                     //  this.deleteEvent(key,id);
                                                                   }}
                                                                   color="dangerNoBackground"
                                                                   customClass="remove">
                                                                   <Close/>
                                                               </IconButton>

                                                           </ItemGrid>

                                                       </GridContainer>

                                                   </ItemGrid>


                                               )
                                           })}
                                       </GridContainer>}


                               </form>

                           }

                    />

                    : null
                }

            </div>
        );
    }
}

const extendedCalendarStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...buttonStyle,
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle,
    ...buttonStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};

HolidayMaster.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(extendedCalendarStyle)(HolidayMaster);

