import React from "react";
// react component for creating dynamic tables
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {dataTable} from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import {NoorYes} from "../TableData";


class HelpTopics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "helpTopicId",
            approveStatus: false,
            helpTopicDescription:"",
            problemId:"",
            warrantyEligible:"",
            problemNames:"",
            itemIds:"",
            itemGroupids:''


        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);

    }

    handleSeacrh = () => {
        if(!this.state.isRefresh){
            this.callHelpTopicsList();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            getDataFromUrl(serverApi.GET_HELPTOPIC_MASTER_BYID+"/"+id, this.handleViewClickResp);
        }
    };
    handleViewClickResp = (resp) => {

        var o = resp;

        if (o !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                helpTopicId:o.helpTopicId,
                helpTopicDescription:o.helpTopicDescription,
                problemId:o.problemId,
                warrantyEligible:o.warrantyEligible,
                isActive: o.isActive,
            });
        }
    };


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        if (stateKey === 'itemGroupid' || stateKey === 'itemGroupids') {
            var data = {
                "itemGroupId": selectedValue,
                "saleable": "",
                "consumable": "",
                "companyId": getCookie(cons.COMPANY_ID)
            };
            requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };
    handleItem = (resp) => {
        this.setState({ItemOptions: resp, loadItem: true})

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false,
            helpTopicDescription:"",
            problemId:"",
            warrantyEligible:""
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        this.setState({
            basicAlert: null
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,


        });
    }

    hideAlertMessage() {
        if(this.state.isCreated){
            this.callHelpTopicsList()
        }
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            helpTopicDescription:"",
            problemId:"",
            warrantyEligible:""
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }

    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = (status) => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {helpTopicId,warrantyEligible,helpTopicDescription,problemId,isActive}=this.state;
            var data = {
                "helpTopicId":helpTopicId ,
                "isActive":status===""?isActive:status,
                "warrantyEligible":warrantyEligible,
                "helpTopicDescription":helpTopicDescription,
                "problemId":problemId,
                "updatedBy":getCookie(cons.USERID),
                "companyId":getCookie(cons.COMPANY_ID)
            };
            if (helpTopicId!=="" && warrantyEligible !=='' && helpTopicDescription !== '') {
                this.handleLoading();
                requestList(serverApi.UPDATE_HELPTOPIC_MASTER, data, this.handleAddHelpTopic)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {warrantyEligible,helpTopicDescription,problemId}=this.state;
            var data = {
                "warrantyEligible":warrantyEligible,
                "helpTopicDescription":helpTopicDescription,
                "problemId":problemId,
                "createdBy":getCookie(cons.USERID),
                "companyId":getCookie(cons.COMPANY_ID)
            };

            if (warrantyEligible !=='' && helpTopicDescription !== '') {
                this.handleLoading();
                requestList(serverApi.ADD_HELPTOPIC_MASTER, data, this.handleAddHelpTopic)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddHelpTopic = (resp) => {
        if (resp.status) {

            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    componentDidMount(){
        this.callProblemList();
        this.callHelpTopicsList()

    }
    callHelpTopicsList(){
        var data={
            "companyId":getCookie(cons.COMPANY_ID),
            "itemGroupid": this.state.itemGroupids!=="0"?this.state.itemGroupids:"",
            "itemId":this.state.itemIds!=="0"?this.state.itemIds:"",
            "problemName": this.state.problemNames
        }
        requestList(serverApi.HELPTOPIC_MASTER_LIST,data,this.handleList)
    }
    handleList=(resp)=>{
        this.setState({tableList:resp,isRefresh:true});
    };
    callProblemList(){
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "itemGroupid": "",
            "itemId":"",
            "problemName":""

        }
        requestList(serverApi.PROBLEM_MASTER_LIST,data,this.handleProblems)
    }
    handleProblems=(resp)=>{
        var problems = [];
        problems = resp.data.map(prop => {
            var obj = {
                value: prop.problemId,
                label: prop.problemName,
                name: prop.problemName
            };
            return obj;
        });
        this.setState({problemList:problems});
    };



    render() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;
         const options =[
             {label:"Yes",value:1},
             {label:"No",value:0}
             ];

        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}

                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        HelpTopic Desc<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        diabled={true}
                                                        id="helpTopicDescription"
                                                        value={this.state.helpTopicDescription}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />

                                                </ItemGrid>

                                            </GridContainer>
                                                </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Problem
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.problemId}
                                                    onSelect={this.onSelect}
                                                    stateKey="problemId"
                                                    getName={(name)=>{
                                                        this.setState({ProblemName:name})
                                                    }}
                                                    staticData={this.state.problemList}
                                                    value={this.state.problemId}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Warrenty Eligible<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.warrantyEligible}
                                                    onSelect={this.onSelect}
                                                    stateKey="warrantyEligible"
                                                    staticData={NoorYes}
                                                    value={this.state.warrantyEligible}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{this.onUpdateButtonClick("")}}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger"
                                                            onClick={()=>{this.onUpdateButtonClick(0)}}>Delete</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={4} sm={4}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <InputLabel className={classes.label}>
                                                                Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={9}>
                                                            <SelectboxTemp
                                                                defValue={this.state.itemGroupids}
                                                                id={"itemGroupids"}
                                                                onSelect={this.onSelect}
                                                                getName={(name)=>{
                                                                    this.setState({itemGroupName:name})
                                                                }}
                                                                // isRefresh={this.state.loadItemGroup}
                                                                stateKey="itemGroupids"
                                                                // setIsRefresh={this.afterRefreshItemGrp}
                                                                url={serverApi.SEARCH_ITEM_GROUPS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                value={this.state.itemGroupid}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>



                                            <ItemGrid xs={4} sm={4}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={3}>
                                                        <InputLabel className={classes.label}>
                                                            Item<span style={{
                                                            color: "red",
                                                            fontSize: "12px"
                                                        }}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={9}>
                                                        <SelectboxTemp
                                                            defValue={this.state.itemIds}
                                                            id={"itemIds"}
                                                            onSelect={this.onSelect}
                                                            getName={this.getitemDetails}
                                                            isRefresh={this.state.loadItem}
                                                            stateKey="itemIds"
                                                            setIsRefresh={(val)=>{
                                                                this.setState({ loadItem:val})
                                                            }}
                                                            staticData={this.state.ItemOptions}
                                                            //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                            value={this.state.itemIds}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

                                                <ItemGrid xs={12} md={4} >
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Problem Name
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="problemNames"
                                                                value={this.state.problemNames}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        {/*<ItemGrid xs={12}>*/}

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                //url={serverApi.LEAVE_TYPE_DATATABLE+"/"+getCookie(cons.COMPANY_ID)}
                                                searchData={this.getData()}
                                                staticData={this.state.tableList}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                fileName={'HelpTopic'}
                                                showDelete={false}
                                               // onDeleteClick={this.onDeleteParamater}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(HelpTopics);
