import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Add from "@material-ui/icons/Add";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
	getDataFromUrl,
	postDownload,
	requestList,
	getFile,
	uploadFormdataWithImage,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import { VIEW } from "../../ui-actions/UiActions";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import Search from "@material-ui/icons/Search";
import LineItemTable from "../CustumComponents/LineItemTable";
import { roseColor } from "../../assets/jss/material-dashboard-pro-react";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import { Checkbox } from "material-ui";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { components } from "react-select";

class DataCollection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			basicAlert: null,
			isCreated: false,
			fDate: "",
			tDate: "",
			fDate1: "",
			tDate1: "",
			fromDate: moment().format("DD-MM-YYYY"),
			todate: moment().format("DD-MM-YYYY"),
			status: "",
			isRefresh: false,
			isLoading: false,
			isDisabled: true,
			isDisabledEbNo: false,
			approveButton: false,
			itemViewType: "issueObj",
			year: moment().format("YYYY"),
			leaveTypeId: "",
			leavesMonth: "",
			leavesYear: "",
			dept: "",
			workerName: "",
			dataTableList: null,
			respList: [],
			respPayList: [],
			LeaveTypeOptions: [],
			invoiceList: [],
			updateView: false,
			paySchemeLineData: [],
			selectedOptions: [],
			customerId: 0,
			empName: "",
			payschemeId: 0,
			customerId1: 0,
			empName1: "",
			paySchemeId1: 0,
			deptCode: 0,
			branchId: 0,
			cataId: 0,
			calDays: 0,
			file: "",
			paySchemeListNoCalData: [],
			paySchemeListEarningsData: [],
			paySchemeListDeductionData: [],
			paySchemeListSummaryData: [],
			payComponentData: [],
			//ebNo:getCookie(cons.EB_NO)!=="null"?getCookie(cons.EB_NO):"",

			//ebIdList:getCookie(cons.EB_NO)!=="null"?getCookie(cons.EB_NO):"",
		};
		this.hideAlert = this.hideAlert.bind(this);
		this.hideAlertMessage = this.hideAlertMessage.bind(this);
		this.onChangeValues = this.onChangeValues.bind(this);
		this.onChangeInputValues = this.onChangeInputValues.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleFileClick = this.handleFileClick.bind(this);
	}
	componentDidMount() {
		var LeavesList = {
			data: [],
			filterTable: true,
			defaultPageSize: 5,
			showPaginationTop: false,
			showPaginationBottom: true,
			column: [
				{
					filterable: false,
					Header: "Emp Id",
					accessor: "empId",
					minWidth: 50,
					style: { textAlign: "center" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Emp Code",
					accessor: "empCode",
					minWidth: 50,
					style: { textAlign: "center" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Emp Name",
					accessor: "empName",
					minWidth: 100,
					style: { textAlign: "center" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Pay Scheme Id",
					accessor: "payschemeId",
					minWidth: 100,
					style: { textAlign: "center" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Pay Scheme",
					accessor: "paySchemeName",
					minWidth: 80,
					style: { textAlign: "center" },
					sortable: false,
				},
			],
		};

		this.setState({
			dataTableList: LeavesList,
			// isRefresh: true,
		});
		// var data = {
		//     "companyId": getCookie(cons.COMPANY_ID),
		//     "cipher": getCookie(cons.CIPHER)
		// };
		// requestList(serverApi.CUSTOMER_MASTER, data, this.handelCustomer);
		//this.callPaySchemeComponentList();
		this.callPaySchemeList();
		//this.getList();
	}

	callPaySchemeList() {
		var data = {
			status: "32",
			activeStatus: "",
			isCompanyFiltered: "Y",
		};
		requestList(serverApi.PAY_SCHEME_GET_LIST, data, this.handlePaySchemeList);
	}

	handlePaySchemeList = (resp) => {
		console.log("payresp", resp);
		if (resp.status === undefined) {
			var payscheme = [];
			payscheme.push({
				value: 0,
				name: "",
				label: "Select",
			});
			resp.map((prop, i) => {
				var obj = {
					value: prop.id,
					name: prop.name,
					label: prop.name,
				};
				payscheme.push(obj);
			});
			this.setState({ paySchemeList: payscheme, loadPayscheme: true });
		}
	};

	getList() {
		const {
			paySchemeId1,
			deptCode,
			fDate,
			tDate,
			branchId,
			cataId,
		} = this.state;
		var data = {
			fromDate: fDate,
			toDate: tDate,
			payschemeId: paySchemeId1,
			deptId: deptCode,
			branchId: branchId,
			empCategoryId: cataId,
			companyId: getCookie(cons.COMPANY_ID),
		};
		var date1 = new Date(this.state.fDate1);
		var date2 = new Date(this.state.tDate1);
		var time_difference = Math.ceil((date2 - date1) / 8.64e7);
		//console.log("%%%%%%%%%%%%",time_difference+1)
		// var time_difference = date2.getTime() - date1.getTime();
		// var days_difference = time_difference / (1000 * 60 * 60 * 24);
		this.setState({
			calDays: time_difference + 1,
		});
		if (fDate !== "" && tDate !== "") {
			requestList(serverApi.DATA_COLLECTION_LIST, data, this.handleList);
		} else {
			this.basicAlert("Please Select From and To Date!!");
		}
	}
	handleList = (resp) => {
		var list = this.state.dataTableList;
		var empList = resp.empPayschemeList;
		var payList = resp.payComponents;
		var invoiceList = [];
		var payRespList = [];
		payList.forEach((prop, i) => {
			var obj1 = {
				id: prop.id,
				value: "",
			};
			payRespList.push(obj1);
		});
		empList.forEach((prop, i) => {
			var obj = {
				empId: prop.empId,
				empCode: prop.empCode,
				empName: prop.empName,
				payschemeId: prop.payschemeId,
				paySchemeName: prop.paySchemeName,
				payCompValues: [],
				pccList: prop.pccList,
			};
			invoiceList.push(obj);
		});
		var components = [{ label: "All", value: "*" }];
		{
			payList.map((prop, i) => {
				var obj = {
					label: prop.name,
					value: prop.name,
				};
				components.push(obj);
			});
		}

		// if (resp) {
		//     list.data = resp.empPayschemeList
		// }
		//console.log("invoiceList", invoiceList)

		this.setState({
			respList: invoiceList,
			respPayList: payList,
			compo: components,
			selectedOptions: components,
			visible: true,
		});
	};
	handleProcess = () => {
		const {
			paySchemeId1,
			deptCode,
			fDate,
			tDate,
			branchId,
			cataId,
		} = this.state;
		if (fDate !== "" && tDate !== "") {
			var today = new Date();
    		const fdt_arr = fDate.split('-');
			const tdt_arr = tDate.split('-');
			let fromDt = fdt_arr[2]+"-"+fdt_arr[1]+"-"+fdt_arr[0];
			let toDt = tdt_arr[2]+"-"+tdt_arr[1]+"-"+tdt_arr[0];			
			let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();			
			getDataFromUrl(
				serverApi.PROCESS_PROCESSDATA_COLLECTION_FROM_ATTENDANCE +
				fromDt + " 00:00:00" +
					"/" +
					toDt + " " + time +
					"/" +
					getCookie(cons.USERID),
				this.handleFetchResp
			);
		} else {
			this.basicAlert("Please Select From and To Date!!");
		}
	};
	handleFetch = () => {
		const {
			paySchemeId1,
			deptCode,
			fDate,
			tDate,
			branchId,
			cataId,
		} = this.state;
		if (fDate !== "" && tDate !== "") {
			getDataFromUrl(
				serverApi.PROCESS_DATA_COLLECTION_FROM_ATTENDANCE +
					fDate +
					"/" +
					tDate +
					"/0/" +
					paySchemeId1 +
					"/" +
					deptCode +
					"/" +
					branchId +
					"/" +
					cataId +
					"/" +
					getCookie(cons.COMPANY_ID) +
					"/" +
					getCookie(cons.USERID),
				this.handleFetchResp
			);
		} else {
			this.basicAlert("Please Select From and To Date!!");
		}
	};
	handleFetchResp = (resp) => {
		console.log("sdd", resp);
		this.showMessage(resp.message, false);
	};
	handleSeacrh = () => {
		var loading = false;
		this.setState({
			respList: [],
			respPayList: [],
		});
		this.getList();
		// this.setState({
		//     visible: true,
		//     //isRefresh: !this.state.isRefresh,
		// });
	};

	handleLoading = () => {
		this.setState({
			isLoading: !this.state.isLoading,
		});
	};

	handleCreate = () => {
		if (!this.state.isCreated) {
			this.hideAlertMessage();
		}
		this.setState({
			isCreated: !this.state.isCreated,
		});
	};

	getData = () => {
		var data = {
			companyId: 0,
			customerId: 0,
			empName: "",
			payschemeId: 0,
		};

		return data;
	};
	timeChanged = (dateObj, id) => {
		if (typeof dateObj === "object") {
			var dates = [id] + "1";
			this.setState({
				[id]: dateObj.format("DD-MM-YYYY"),
				[dates]: dateObj.format("MM/DD/YYYY"),
			});
		} else if (id !== "date1")
			this.setState({
				[id]: "",
			});
	};

	onChangeValues = (event) => {
		var value = event.target.value;
		//console.log("val "+value);
		this.setState({
			[event.target.id]: value,
		});
	};

	hideBasicAlert() {
		if (this.state.updateItemAlert) this.hideAlert();
		this.setState({
			basicAlert: null,
			deleteItem: false,
			updateItemAlert: false,
		});
	}

	hideAlert() {
		console.log("alert");
		this.setState({
			updateItem: false,
			alert: false,
			showMessagealert: false,
			loading: false,
		});
	}

	hideAlertMessage() {
		// if (this.state.isCreated) {
		//     this.getList()
		// }
		this.setState({
			isCreated: false,
			approveStatus: false,
			showMessagealert: false,
			updateView: false,
			alert: false,
			basicAlert: false,
			empName: "",
			paySchemeLineData: [],
			customerId: 0,
			payschemeId: 0,
			paySchemeListNoCalData: [],
			paySchemeListEarningsData: [],
			paySchemeListDeductionData: [],
			paySchemeListSummaryData: [],

			empId: getCookie(cons.EB_ID) !== "null" ? getCookie(cons.EB_ID) : "",
			ebNo: getCookie(cons.EB_NO) === "null" ? "" : getCookie(cons.EB_NO),
			empPayschemeId: "",
			//fDate: "",
			//tDate: "",
			visible: false,
			file: "",
			itemName: "",
		});
	}

	basicAlert(msg) {
		this.setState({
			basicAlert: (
				<SweetAlert
					style={{
						display: "block",
						marginTop: "-100px",
						marginLeft: "-80px",
						fontSize: "8px",
						color: "red",
					}}
					title={msg}
					onConfirm={() => this.hideBasicAlert()}
					onCancel={() => this.hideBasicAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
				/>
			),
		});
	}

	getInvoiceTablePageSize = () => {
		var length = this.state.invoiceList.length;
		if (length > 8) {
			return length;
		} else {
			return 8;
		}
	};
	onSelect = (selectedValue, stateKey) => {
		if (
			stateKey === "paySchemeId" &&
			this.state.paySchemeId !== selectedValue
		) {
			getDataFromUrl(
				serverApi.PAY_SCHEME_GET_BYID + "/" + selectedValue,
				this.handleViewClickRespPayscheme
			);
		}

		this.setState({
			[stateKey]: selectedValue,
		});
	};
	onChangeCheck = (value, event) => {
		if (event.action === "select-option" && event.option.value === "*") {
			this.setState({
				selectedOptions: this.state.compo,
			});
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "*"
		) {
			this.setState({ selectedOptions: [] });
		} else if (event.action === "deselect-option") {
			var selectedOptions = value.filter((o) => o.value !== "*");
			this.setState({ selectedOptions: selectedOptions });
		} else if (value.length === this.state.compo.length - 1) {
			this.setState({ selectedOptions: this.state.compo });
		} else {
			this.setState({ selectedOptions: value });
		}
	};
	getComponentName = (id, name) => {
		var componentvalue = "";
		this.state.payComponentData.forEach(function(value) {
			if (id === value.id && name === "name") {
				componentvalue = value.name;
			}
			if (id === value.id && name === "type") {
				componentvalue = value.type;
			}
			if (id === value.id && name === "formula") {
				componentvalue = value.formula;
			}
		});
		return componentvalue;
	};

	handleViewClickRespPayscheme = (resp) => {
		var header = resp.paySchemeDTO;
		var list = resp.paySchemeDetailsDTO,
			paySchemeListEarningsData = [],
			paySchemeListDeductionData = [],
			paySchemeListNoCalData = [],
			paySchemeListSummaryData = [];
		list.forEach((prop, key) => {
			var obj = {
				id: paySchemeListNoCalData.length,
				lineItemId: prop.id,
				componentId: prop.componentId,
				componentvalue: this.getComponentName(prop.componentId, "name"),
				formula: prop.formula,
				type: parseInt(prop.type),
				value: "",
			};
			console.log("paySchemeLineData", this.state.paySchemeLineData);
			if (
				this.state.paySchemeLineData.length > 0 &&
				this.state.paySchemeIdNew === this.state.paySchemeId
			) {
				this.state.paySchemeLineData.forEach((p) => {
					if (p.componentId === prop.componentId) {
						obj.value = p.amount;
						if (obj.type === 0 && prop.status === 1) {
							obj.formula = p.amount;
						}
					}
				});
			}
			if (obj.type === 0 && prop.status === 1) {
				paySchemeListNoCalData.push(obj);
			}
			if (obj.type === 1 && prop.status === 1) {
				paySchemeListEarningsData.push(obj);
			}
			if (obj.type === 2 && prop.status === 1) {
				paySchemeListDeductionData.push(obj);
			}
			if (obj.type === 3 && prop.status === 1) {
				paySchemeListSummaryData.push(obj);
			}
		});
		//console.log("indentWeight "+header.indentWeight);
		if (header !== null) {
			this.setState({
				// loadPayscheme:true,
				//  effectiveFrom:header.effectiveFrom,
				paySchemeListEarningsData: paySchemeListEarningsData,
				paySchemeListDeductionData: paySchemeListDeductionData,
				paySchemeListNoCalData: paySchemeListNoCalData,
				paySchemeListSummaryData: paySchemeListSummaryData,
			});
		}
		if (this.state.paySchemeLineData.length === 0) {
			this.onClaculationButtonClick();
		}
	};
	onClaculationButtonClick = () => {
		const {
			payschemeId,
			description,
			effectiveFrom,
			payschemeCode,
			payschemeName,
			wageType,
			paySchemeListEarningsData,
			paySchemeListDeductionData,
			paySchemeListNoCalData,
			paySchemeListSummaryData,
		} = this.state;

		var PayschemeList = [
			...paySchemeListEarningsData,
			...paySchemeListDeductionData,
			...paySchemeListNoCalData,
			...paySchemeListSummaryData,
		];

		var obj1 = {};
		PayschemeList.forEach(function(prop, keyIndex) {
			obj1 = { ...obj1, [prop.componentId]: prop.formula };
		});
		requestList(
			serverApi.CHECK_PAYSCHEME + "/1111/" + this.state.paySchemeId,
			obj1,
			this.handleCheckPayscheme
		);
	};
	handleCheckPayscheme = (resp) => {
		if (resp.status === true) {
			console.log("checkPayscheme", resp);
			const {
				paySchemeListEarningsData,
				paySchemeListDeductionData,
				paySchemeListNoCalData,
				paySchemeListSummaryData,
			} = this.state;
			var paySchemeListEarningsData1 = paySchemeListEarningsData.map((prop) => {
				var amount = "";
				if (resp.list)
					resp.list.find((o) => {
						if (o.componentId === prop.componentId) {
							amount = o.amount;
							return true;
						}
					});
				return { ...prop, value: amount };
			});
			var paySchemeListDeductionData1 = paySchemeListDeductionData.map(
				(prop) => {
					var amount = "";
					if (resp.list)
						resp.list.find((o) => {
							if (o.componentId === prop.componentId) {
								amount = o.amount;
								return true;
							}
						});
					return { ...prop, value: amount };
				}
			);
			var paySchemeListNoCalData1 = paySchemeListNoCalData.map((prop) => {
				var amount = "";
				if (resp.list)
					resp.list.find((o) => {
						if (o.componentId === prop.componentId) {
							amount = o.amount;
							return true;
						}
					});
				return { ...prop, value: amount };
			});
			var paySchemeListSummaryData1 = paySchemeListSummaryData.map((prop) => {
				var amount = "";
				if (resp.list)
					resp.list.find((o) => {
						if (o.componentId === prop.componentId) {
							amount = o.amount;
							return true;
						}
					});
				return { ...prop, value: amount };
			});

			this.setState({
				paySchemeListEarningsData: paySchemeListEarningsData1,
				paySchemeListDeductionData: paySchemeListDeductionData1,
				paySchemeListNoCalData: paySchemeListNoCalData1,
				paySchemeListSummaryData: paySchemeListSummaryData1,
			});
		}

		// console.log("paySchemeListEarningsData1",paySchemeListEarningsData1);
	};

	onCreateButtonClick = () => {
		if (!getMenuPermission(2)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			var payscheme = [
				...this.state.paySchemeListEarningsData,
				...this.state.paySchemeListDeductionData,
				...this.state.paySchemeListNoCalData,
				...this.state.paySchemeListSummaryData,
			];

			var payschemedata = payscheme.map((prop, key) => {
				return {
					amount: prop.value,
					componentId: prop.componentId,
					effectiveFrom: this.state.effectiveFrom,
					employeeid: this.state.empId,
					endsOn: "",
					payschemeId: this.state.paySchemeId,
					remarks: "",
				};
			});

			var data = {
				payEmployeeDTO: {
					customerId: this.state.customerId,
					employeeid: this.state.empId,
					paySchemeId: this.state.paySchemeId,
					createdBy: getCookie(cons.USERID),
				},
				payEmployeeStructureDTO: payschemedata,
			};

			if (this.state.paySchemeId > 0) {
				this.handleLoading();
				requestList(
					serverApi.ADD_EMPLOYEE_BILLING,
					data,
					this.handleAddBilling
				);
			} else {
				this.basicAlert("Please Enter All Mandatory Fields..!!");
			}
		}
	};
	dateChanged = (date) => {
		var myDate = date;
		myDate = myDate.split("-");
		var newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
		return newDate;
	};
	onUpdateButtonClick = (status) => {
		if (!getMenuPermission(1)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			var payscheme = [
				...this.state.paySchemeListEarningsData,
				...this.state.paySchemeListDeductionData,
				...this.state.paySchemeListNoCalData,
				...this.state.paySchemeListSummaryData,
			];

			var payschemedata = payscheme.map((prop, key) => {
				return {
					amount: prop.value,
					componentId: prop.componentId,
					effectiveFrom: this.state.effectiveFrom,
					employeeid: this.state.empId,
					endsOn: "",
					payschemeId: this.state.paySchemeId,
					remarks: "",
				};
			});

			var data = {
				payEmployeeDTO: {
					id: this.state.empPayschemeId,
					employeeid: this.state.empId,
					paySchemeId: this.state.paySchemeId,
					customerId: this.state.customerId,
					modifiedBy: getCookie(cons.USERID),
				},
				payEmployeeStructureDTO: payschemedata,
			};

			if (this.state.paySchemeId > 0) {
				this.handleLoading();
				requestList(
					serverApi.UPDATE_EMPLOYEE_BILLING,
					data,
					this.handleAddBilling
				);
			} else {
				this.basicAlert("Please Enter All Mandatory Fields..!!");
			}
		}
	};

	handleAddBilling = (resp) => {
		if (resp.status) {
			this.showMessage(resp.message, resp.status);
		} else {
			this.showMessage(resp.message, resp.status);
		}
		this.handleLoading();
	};

	showMessage = (msg, status) => {
		this.setState({
			showMessagealert: (
				<Popup
					//  onClickSave={() => this.onClickAddItem()}
					onClickSave={() => {
						if (status) this.hideAlertMessage();
						else this.setState({ showMessagealert: false });
					}}
					isShowSave={true}
					save_btn_txt={"OK"}
					content={
						<form>
							<legend style={{ textAlign: "center" }}>{msg}</legend>
						</form>
					}
				/>
			),
		});
	};
	handleViewClick = (id) => {
		if (!getMenuPermission(0)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			var val = JSON.parse(id);

			this.setState({ empName: val.empName, empId: val.employeeid });
			getDataFromUrl(
				serverApi.EMPLOYEE_BILLING_BY_ID + val.id,
				this.handleViewClickResp
			);
		}
	};
	handleViewClickResp = (resp) => {
		if (resp.payEmployeeDTO !== null) {
			var payheader = resp.payEmployeeDTO;
			//console.log("indentWeight "+header.indentWeight);
			this.setState({
				paySchemeId: payheader.paySchemeId,
				empPayschemeId: payheader.id,
				paySchemeIdNew: payheader.paySchemeId,
				effectiveFrom: payheader.effectiveFrom,
				customerId: payheader.customerId,
				paySchemeLineData: resp.payEmployeeStructureDTO,
			});

			getDataFromUrl(
				serverApi.PAY_SCHEME_GET_BYID + "/" + payheader.paySchemeId,
				this.handleViewClickRespPayscheme
			);
		}

		if (resp) {
			this.setState({
				isCreated: true,
				updateView: true,
				isDisabledEbNo: true,
				approveButton: resp.approveButton,
			});
		}
	};
	getEmpDetails = () => {
		const { ebNo } = this.state;
		if (ebNo !== "") {
			getDataFromUrl(
				serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
					ebNo +
					"/" +
					getCookie(cons.COMPANY_ID) +
					"/" +
					getCookie(cons.USERID),
				this.handleEmpDetails
			);
		} else if (ebNo === "") {
			this.basicAlert("Please Enter Employee No..!!");
		}
	};
	handleEmpDetails = (resp) => {
		if (resp.status) {
			var header = resp.data;
			this.setState({
				//  isDisabled:false,
				empName: header.workerName,
				dept: header.deptId,
				loadDept: true,
				empId: header.ebId,
			});
			getDataFromUrl(
				serverApi.LEAVE_REQUEST_AVAILABILITY +
					"/" +
					header.ebId +
					"/" +
					getCookie(cons.USERID),
				this.handleAvailabilityCheck
			);
		} else {
			this.showMessage(resp.message, false);
		}
	};
	handleFileChange(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		//  console.log("file type",file.type);
		var fileName = file.name,
			fileType;
		fileName = fileName.split(".");
		fileType = fileName[1].toUpperCase();
		reader.onloadend = () => {
			if (fileType === "XLSX" || fileType === "XLS") {
				this.setState({
					file: file,
					itemName: file.name,
					fileType: fileType,
					//filesList: arr,
					imagePreviewUrl: reader.result,
				});
				console.log("filename", this.state.itemName);
				//  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
			} else {
				this.basicAlert(
					"File type you have selected can not be upload. Please select another file!!!"
				);
			}
		};
		reader.readAsDataURL(file);
	}

	handleFileClick() {
		var input = document.createElement("input");
		input.type = "file";
		input.onchange = this.handleFileChange;
		input.click();
	}
	saveButtonClick = () => {
		var list = this.state.respList;
		var data = [];
		console.log("List", list);

		list.forEach((prop, i) => {
			var payData = {};
			prop.payCompValues.forEach((p, j) => {
				Object.assign(payData, p);
			});

			if (prop.pccList !== null && prop.pccList.length > 0) {
				var datas = [];
				var pay = Object.keys(payData);
				prop.pccList.forEach((k, m) => {
					if (!pay.includes(String(k.componentId))) {
						var objs = {
							[k.componentId]: k.value,
						};
						datas.push(objs);
					}
				});
				datas.forEach((val, n) => {
					Object.assign(payData, val);
				});
			}
			var obj = {
				empId: prop.empId,
				payCompValues: payData,
			};
			data.push(obj);
		});
		var dat = data;
		var compList = this.state.respPayList;
		var ids = [];
		compList.forEach((pro, l) => {
			ids.push(pro.id);
		});
		//console.log("%%%",ids)
		dat.forEach((p, k) => {
			var key = Object.keys(p.payCompValues);
			ids.forEach((prop) => {
				if (prop !== 65) {
					if (!key.includes(String(prop))) {
						var obs = {
							[prop]: 0,
						};
						Object.assign(data[k].payCompValues, obs);
					}
				}
				if (prop === 65) {
					if (!key.includes(String(65))) {
						var ob = {
							65: this.state.calDays,
						};
						Object.assign(data[k].payCompValues, ob);
					}
				}
			});
		});
		if (this.state.fDate !== "" && this.state.tDate !== "") {
			this.handleLoading();
			console.log("Data", data);
			requestList(
				serverApi.DATA_COLLECTION_ADD_DATA +
					this.state.fDate +
					"/" +
					this.state.tDate +
					"/" +
					getCookie(cons.COMPANY_ID) +
					"/" +
					getCookie(cons.USERID),
				data,
				this.handleMessage
			);
		} else {
			this.basicAlert("Please Select From and To Date!!");
		}
	};
	handleMessage = (resp) => {
		if (resp.status) {
			this.showMessage(resp.message, resp.status);
		} else {
			this.showMessage(resp.message, resp.status);
		}
		this.handleLoading();
	};
	onChangeInputValues = (e, i, id) => {
		var list = this.state.respList;
		var index = "";
		if (list[i].payCompValues.length > 0)
			list[i].payCompValues.forEach((prop, k) => {
				Object.keys(prop).forEach((key) => {
					console.log("com..", key, id);
					if (key === id + "") {
						index = k;
					}
				});
			});
		if (index !== "") list[i].payCompValues[index] = { [id]: e.target.value };
		else list[i].payCompValues.push({ [id]: e.target.value });

		this.setState({ respList: list });
	};
	uploadButtonClick = () => {
		if (this.state.fDate !== "" && this.state.tDate !== "") {
			if (this.state.file !== "") {
				var data = new FormData();
				data.append("payrollInputSheet", this.state.file);
				//this.showMessage(resp.message, true);
				this.handleLoading();
				uploadFormdataWithImage(
					serverApi.DATA_COLLECTION_UPLOAD_SHEET +
						"/" +
						this.state.fDate +
						"/" +
						this.state.tDate +
						"/" +
						getCookie(cons.COMPANY_ID) +
						"/" +
						getCookie(cons.USERID),
					data,
					this.handleMessage
				);
			} else {
				this.basicAlert("Please select Excel File!!");
			}
		} else {
			this.basicAlert("Please select From and To Date!!");
		}
	};
	templateDownload = () => {
		//if(this.state.paySchemeId1>0&&this.state.deptCode>0&&this.state.cataId>0&&this.state.branchId){
		var data = {
			payschemeId: this.state.paySchemeId1,
			deptId: this.state.deptCode,
			empCategoryId: this.state.cataId,
			branchId: this.state.branchId,
			companyId: getCookie(cons.COMPANY_ID),
		};
		postDownload(serverApi.PAY_ROLL_TEMPLATE_DOWNLOAD, data);
		/* }
         else{
             this.basicAlert("Please Enter All Mandatory Fields..!!");
         }*/
	};
	isPresent = (val) => {
		return this.state.selectedOptions.some((item) => val === item.value);
	};

	render() {
		const today = moment();

		const { classes } = this.props;
		const { dataTableList } = this.state;
		const inputFocus = {
			autoComplete: "off",
		};
		var yesterday = Datetime.moment().subtract(1, "day");
		var day = Datetime.moment().subtract(0, "day");

		const options = [
			{ value: 0, name: "select...", label: "select..." },
			{ value: 1, name: "OPEN", label: "OPEN" },
			{ value: 3, name: "APPROVED", label: "APPROVED" },
			{ value: 4, name: "REJECTED", label: "REJECTED" },
			{ value: 6, name: "CANCELLED", label: "CANCELLED" },
			{
				value: 17,
				name: "PENDING APPROVAL LEVEL 2",
				label: "PENDING APPROVAL LEVEL 2",
			},
			{
				value: 18,
				name: "PENDING APPROVAL LEVEL 3",
				label: "PENDING APPROVAL LEVEL 3",
			},
			{
				value: 19,
				name: "PENDING APPROVAL LEVEL 4",
				label: "PENDING APPROVAL LEVEL 4",
			},
		];
		var valid = function(current) {
			if (getCookie(cons.COMPANY_ID) !== "2") return current.isAfter(yesterday);
			else return true;
		};
		var isvalid = function(current) {
			if (getCookie(cons.COMPANY_ID) !== "2") return current.isBefore(day);
			else return true;
		};

		return (
			<GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
				<ItemGrid xs={12}>
					<RegularCard
						isLoading={this.state.isLoading}
						content={
							<div>
								{this.state.basicAlert}
								{this.state.showMessagealert}
								<GridContainer>
									{
										<ItemGrid xs={10}>
											<GridContainer>
												<ItemGrid xs={4} md={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																From{" "}
																<span
																	style={{ color: "red", fontSize: "12px" }}
																>
																	*
																</span>
															</InputLabel>
														</ItemGrid>

														<ItemGrid xs={12} sm={8}>
															<Datetime
																closeOnSelect={true}
																isValidDate={isvalid}
																disablePast
																timeFormat={false}
																onChange={(dateObj) => {
																	this.timeChanged(dateObj, "fDate");
																}}
																height={"15px"}
																formControlProps={{
																	height: "15px",
																	inlineBlock: "none",
																}}
																inputProps={{
																	id: "fDate",
																	value: this.state.fDate,
																	autoComplete: "off",
																}}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>

												<ItemGrid xs={4} md={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																To{" "}
																<span
																	style={{ color: "red", fontSize: "12px" }}
																>
																	*
																</span>
															</InputLabel>
														</ItemGrid>

														<ItemGrid xs={12} sm={8}>
															<Datetime
																closeOnSelect={true}
																isValidDate={isvalid}
																timeFormat={false}
																onChange={(dateObj) => {
																	this.timeChanged(dateObj, "tDate");
																}}
																height={"15px"}
																formControlProps={{
																	height: "15px",
																	inlineBlock: "none",
																}}
																inputProps={{
																	id: "tDate",
																	value: this.state.tDate,
																	autoComplete: "off",
																}}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>

												<ItemGrid xs={4} md={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Pay Scheme
															</InputLabel>
														</ItemGrid>

														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.paySchemeId1}
																id={"paySchemeId1"}
																onSelect={this.onSelect}
																//getName={this.getQualityDetails}
																isRefresh={this.state.loadPayscheme}
																setIsRefresh={(val) => {
																	this.setState({ loadPayscheme: false });
																}}
																stateKey="paySchemeId1"
																staticData={this.state.paySchemeList}
																//url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}
																//value={this.state.selectedJuteQuality}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>

												<ItemGrid xs={4} md={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Department
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.deptCode}
																id={"deptCode"}
																onSelect={this.onSelect}
																getName={this.getQualityDetails}
																// isRefresh={this.state.loadquality}
																//setIsRefresh={this.afterRefreshquality}
																stateKey="deptCode"
																url={
																	serverApi.SELECT_SUB_DEPARTMENT_MASTER +
																	getCookie(cons.COMPANY_ID)
																}
																//value={this.state.selectedJuteQuality}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>

												<ItemGrid xs={4} md={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Branch
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.branchId}
																onSelect={this.onSelect}
																//  getName={this.getQualityName}
																stateKey="branchId"
																// isRefresh={this.state.loadQuality}
																// setIsRefresh={this.afterRefreshQuality}
																url={
																	serverApi.SELECT_BRANCH +
																	getCookie(cons.COMPANY_ID)
																}
																value={this.state.branchId}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={4} md={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Category
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.cataId}
																onSelect={this.onSelect}
																//  getName={this.getQualityName}
																stateKey="cataId"
																// isRefresh={this.state.loadQuality}
																// setIsRefresh={this.afterRefreshQuality}
																url={
																	serverApi.SELECT_WORKER_CATEGORY +
																	getCookie(cons.COMPANY_ID)
																}
																value={this.state.category}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
													<span
														style={{
															float: "right",
															color: roseColor,
															fontWidth: 400,
															cursor: "pointer",
														}}
														onClick={() => {
															this.templateDownload();
														}}
													>
														{" "}
														Click Here To Get Template File
													</span>
												</ItemGrid>
												<ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
													<GridContainer>
														<ItemGrid xs={12} sm={2}>
															<InputLabel className={classes.label}>
																Upload Excel
																<span
																	style={{ color: "red", fontSize: "12px" }}
																>
																	*
																</span>
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={10}>
															<TextField
																id="itemName"
																disabled={true}
																value={this.state.itemName}
																className={classes.textField}
																onChange={this.onChangeValues}
																fullWidth={true}
																InputProps={{
																	autoComplete: "off",
																	endAdornment: (
																		<InputAdornment position="end">
																			<span
																				style={{
																					color: "#003F66",
																					fontSize: "16px",
																					fontWeight: "500",
																					cursor: "pointer",
																				}}
																				onClick={this.handleFileClick}
																			>
																				BROWSE
																			</span>
																		</InputAdornment>
																	),
																}}
															/>
														</ItemGrid>
													</GridContainer>
													<ItemGrid xs={12} style={{ marginTop: "5px" }}>
														<GridContainer justify="center">
															<ItemGrid>
																<Button
																	color="primary"
																	onClick={() => {
																		this.uploadButtonClick();
																	}}
																>
																	UploadData
																</Button>
															</ItemGrid>
															{/* <ItemGrid>
                                                                <Button color="danger">PostData</Button>
                                                            </ItemGrid> */}
														</GridContainer>
													</ItemGrid>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									}
									<ItemGrid xs={2}>
										<Button
											color="primary"
											right={true}
											onClick={this.handleSeacrh}
										>
											Search
										</Button>
										<br />
										<Button
											color="info"
											right={true}
											onClick={this.handleFetch}
										>
											Fetch
										</Button>
										<br />
										<Button
											color="info"
											right={true}
											onClick={this.handleProcess}
										>
											Process
										</Button>
									</ItemGrid>
									{this.state.visible ? (
										<ItemGrid xs={12}>
											<GridContainer>
												<ItemGrid xs={12} sm={8} />
												<ItemGrid xs={12} sm={1}>
													<InputLabel className={classes.label}>
														Components Hide
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={3}>
													<ReactMultiSelectCheckboxes
														options={this.state.compo}
														value={this.state.selectedOptions}
														onChange={this.onChangeCheck}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}

									{/* {this.state.visible ?
                                        <ItemGrid xs={12} style={{ overflowX: "auto", marginTop: "10px" }}>
                                            <GridContainer>
                                                <ItemGrid >
                                                    <GridContainer >
                                                      
                                                        {this.state.respPayList.map((prop,i) => {
                                                            return (
                                                                <ItemGrid style={{ width: "200px" }}>
                                                                    <InputLabel className={classes.label}>
                                                                        {prop.name}
                                                                    </InputLabel>
                                                                    <Checkbox
                                                                    checked={prop.isChecked===undefined}
                                                                    onChange={() => {
                                                                        var data=this.state.respPayList
                                                                        if(data[i].isChecked===undefined){

                                                                            data[i].isChecked=1
                                                                        }
                                                                        else{
                                                                            data[i].isChecked=undefined
                                                                        }
                                                                        this.setState({
                                                                            respPayList:data
                                                                        })
                                                                            
                                                                    }}
                                                            />
                                                                </ItemGrid>
                                                            )
                                                        })}

                                                    </GridContainer>
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null} */}

									<ItemGrid
										xs={12}
										style={{
											height: 2,
											backgroundColor: "#00acc1",
											marginTop: "5px",
										}}
									/>

									{this.state.visible ? (
										<ItemGrid
											xs={12}
											style={{ overflowX: "auto", marginTop: "10px" }}
										>
											<GridContainer>
												<ItemGrid>
													<GridContainer>
														<ItemGrid style={{ width: "150px" }}>
															<InputLabel className={classes.label}>
																Employee Code
															</InputLabel>
														</ItemGrid>
														<ItemGrid style={{ width: "150px" }}>
															<InputLabel className={classes.label}>
																Employee Name
															</InputLabel>
														</ItemGrid>
														<ItemGrid style={{ width: "150px" }}>
															<InputLabel className={classes.label}>
																PayschemeName
															</InputLabel>
														</ItemGrid>
														{this.state.respPayList.map((prop, i) => {
															if (this.isPresent(prop.name)) {
																return (
																	<ItemGrid style={{ width: "150px" }}>
																		<InputLabel className={classes.label}>
																			{prop.name}
																		</InputLabel>
																	</ItemGrid>
																);
															}
														})}
														{/* {this.state.respPayList.map((prop,i) => {
                                                            return (
                                                                <ItemGrid style={{ width: "150px" }}>
                                                                    <InputLabel className={classes.label}>
                                                                        {prop.name}
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                )
                                                        })} */}
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={12} md={8} style={{ maxHeight: 320 }}>
													<GridContainer>
														{this.state.visible
															? this.state.respList.map((prop, i) => {
																	return (
																		<ItemGrid style={{ marginTop: "10px" }}>
																			<GridContainer>
																				<ItemGrid style={{ width: "150px" }}>
																					{prop.empCode}
																				</ItemGrid>

																				<ItemGrid style={{ width: "150px" }}>
																					{prop.empName}
																				</ItemGrid>

																				<ItemGrid style={{ width: "150px" }}>
																					{prop.paySchemeName}
																				</ItemGrid>

																				{this.state.respPayList.map((p, j) => {
																					var val = 0;
																					if (prop.pccList !== null) {
																						prop.pccList.map((k) => {
																							if (k.componentId === p.id) {
																								val = k.value;
																							} else if (p.id === 65) {
																								val = this.state.calDays;
																							}
																						});
																						if (prop.pccList.length <= 0) {
																							if (p.id === 65) {
																								val = this.state.calDays;
																							}
																						}
																					}
																					if (prop.pccList === null) {
																						if (p.id === 65) {
																							val = this.state.calDays;
																						}
																					}
																					if (this.isPresent(p.name)) {
																						return (
																							<ItemGrid
																								style={{ width: "150px" }}
																							>
																								<TextField
																									id={i + "" + j}
																									key={i + "" + j}
																									onChange={(e) => {
																										this.onChangeInputValues(
																											e,
																											i,
																											p.id
																										);
																									}}
																									defValue={val}
																									className={classes.textField}
																									fullWidth={true}
																									iType={"number"}
																									decimal={3}
																								/>
																							</ItemGrid>
																						);
																					}
																				})}
																			</GridContainer>
																		</ItemGrid>
																	);
															  })
															: null}
													</GridContainer>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
									{this.state.visible ? (
										<ItemGrid xs={12} style={{ marginTop: "15px" }}>
											<GridContainer justify="center">
												<ItemGrid>
													<Button
														color="primary"
														onClick={() => {
															this.saveButtonClick();
														}}
													>
														Save
													</Button>
												</ItemGrid>
												<ItemGrid>
													<Button
														color="danger"
														onClick={() => {
															this.setState({
																visible: false,
															});
														}}
													>
														Cancel
													</Button>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
									{/*<ItemGrid xs={12}>



                                              {dataTableList?  <ReportsTableComponent
                                                   //  url={serverApi.EMPLOYEE_BILLING_LIST}
                                                    staticData={dataTableList}
                                                    borderRight={true}
                                                     searchData={this.getData()}
                                                    isRefresh={this.state.isRefresh}
                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                    // isLoading={this.state.isRefresh}
                                                      onActionClick={this.handleViewClick}
                                                    //actionType={VIEW}

                                                    fileName={"Data_Collection"}
                                                    itemViewType={this.state.itemViewType}/>:null}  



                                            
                                            //<SamplePDF printDocument={this.printDocument.bind(this)}/>
                                            


                                        </ItemGrid>*/}
								</GridContainer>
							</div>
						}
					/>
				</ItemGrid>
			</GridContainer>
		);
	}
}

const extendedFormsStyle = {
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#00ABDC",
		fontSize: "15px",
		//  lineHeight: "1.0",
		fontWeight: "400",
		display: "inline-flex",
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	textField: {},
	input: {
		border: "1px solid #ccc",
		borderRadius: "4px",
	},
	...customSelectStyle,
	...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(DataCollection);
