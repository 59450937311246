import React, { Validator } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
import Select from "react-select";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {
  configNames,
  getConfigValue,
  serverApi,
  serverConfig,
} from "../../helper/Consts";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import moment from "moment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import InputAdornment from "material-ui/Input/InputAdornment";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import LineItemTable from "../CustumComponents/LineItemTable";

class JuteGateEntry extends React.Component {
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      mrNo: "",
      vehicleNo: "",
      gateEntryNO: "",
      selectMukam: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "99" ? getCookie(cons.SUPP_CODE) : "",
      supplier_Id:
        getCookie(cons.USER_ROLE_ID) === "99"
          ? getCookie(cons.SUPP_CODE)
          : null,
      brokerId: "",
      poNo: "",
      itemViewType: "gateEntryNum",
      entryNo: "",
      updateView: false,
      showMessagealert: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      selectedPO: "",
      poName: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      selectedJuteQuality: "",
      qualityName: "",
      unitId: "",
      unitConversion: "",

      chalanNo: "",
      chalanDate: moment().format("DD-MM-YYYY"),
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "99" ? getCookie(cons.SUPP_CODE) : "",
      selectedMukam: "",
      transporter: "",

      grossWeightKG: 0,
      grossWeightQTL: 0,
      chalanWeightKG: 0,
      chalanWeightQTL: 0,
      tareWeightKG: 0,
      tareWeightQTL: 0,
      actualWeightKG: 0,
      actualWeightQTL: 0,
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      outDate: "",
      outTime: "",
      invoiceList: [],
      outDateTime: "",
      branchId: "",
      filesList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      data.map((o, key) => {
        o.id = key;
        o.actions = (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  console.log("key in edit", key);

                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    console.log("id in edit", o.id);
                    this.setState({
                      // alert:true,
                      id: o.id,
                      updateItemAlert: true,
                      updateItem: true,
                      deptName: o.department,
                      itemGrpName: o.itemGroup,
                      itemName: o.item,
                      unitId: o.unitId,
                      stock: o.stockValue,
                      quantity: o.quantity,
                      selectedDepartment: o.deptId,
                      selectedItemGroup: o.itemGroupId,
                      selectedItem: o.itemId,
                      selectedJuteQuality: o.qualityCode,
                      qualityName: o.quality,
                      weight: o.weight,
                      amount: o.amount,
                      advisedJuteTyp: o.advisedJuteTyp,
                      advisedQuality: o.advisedQuality,
                      advisedQuantity: o.advisedQuantity,
                      advisedWeight: o.advisedWeight,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>{" "}
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //if (this.state.approveStatus) {

                    this.setState({ deleteItem: true, id: o.id });

                    this.basicItemAlert("Do You Like To Delete This Item?");
                    //  data.splice(i,1);
                    // }
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>
          </div>
        );
      });
      //  if(this.state.approveStatus)
      this.setState({ invoiceList: data, deleteItem: false });
    }
    this.setState({
      basicAlert: null,
    });
  }
  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: false,
      loading: false,
      indentAlert: false,
      updateItem: false,
      showMessagealert: false,
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      selectedJuteQuality: "",
      qualityName: "",
      rate: "",
      weight: "",
      advisedJuteTyp: "",
      advisedQuality: "",
      advisedQuantity: "",
      advisedWeight: "",
      poLineItemNum: "",
      dataId: "",
    });
  }
  hideAlertMessage = () => {
    console.log("hide ", true);
    this.setState({
      isCreated: false,
      updateView: false,
      showMessagealert: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      selectedPO: "",
      poName: "",
      unitConversionName: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      unitId: "",
      chalanNo: "",
      chalanDate: moment().format("DD-MM-YYYY"),
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier: "",
      selectedMukam: "",
      selectedJuteQuality: "",
      qualityName: "",
      transporter: "",
      remarks: "",
      grossWeightKG: 0,
      grossWeightQTL: 0,
      chalanWeightKG: 0,
      chalanWeightQTL: 0,
      tareWeightKG: 0,
      tareWeightQTL: 0,
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      outDateTime: "",
      weight: "",
      invoiceList: [],
      advisedJuteTyp: "",
      advisedQuality: "",
      advisedQuantity: "",
      advisedWeight: "",
      unitConversion: "",
      qcCheck: "",
      statusDesc: "",
      branchId: "",
      filesList: [],
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_JUTE_GATE_ENTRY + id + "/" + getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.entryHeader;
    var list = resp.juteGateLineItems;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        //   dataId:prop.recId,
        //  department:prop.deptName,
        //  itemGroup:prop.itemGrpName,
        item: prop.itemDesc,
        weight: prop.actualWeight,
        quantity: prop.actualQuantity,
        unitId: prop.unitId ? prop.unitId : prop.unitId,
        qualityCode: parseInt(prop.actualQuality),
        quality: prop.actualQualityDesc,
        //stockValue: prop.stock,
        deptId: prop.dept,
        itemGroupId: prop.itemGroupId,
        itemId: parseInt(prop.actualJuteTyp),
        advisedJuteTyp: prop.advisedJuteTyp,
        advisedQuality: prop.advisedQuality,
        advisedQuantity: prop.advisedQuantity,
        advisedWeight: prop.advisedWeight,
        poLineItemNum: prop.poLineItemNum,
        dataId: prop.recId,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    this.basicItemAlert("Do You Like To Update This Item?");
                    this.setState({
                      //alert:true,
                      updateItemAlert: true,
                      id: o.id,
                      dataId: o.dataId,
                      updateItem: true,
                      deptName: o.department,
                      itemGrpName: o.itemGroup,
                      itemName: o.item,
                      unitId: o.unitId,
                      stock: o.stockValue,
                      quantity: o.quantity,
                      selectedDepartment: o.deptId,
                      selectedItemGroup: o.itemGroupId,
                      selectedItem: o.itemId,
                      selectedJuteQuality: o.qualityCode,
                      qualityName: o.quality,
                      weight: o.weight,
                      advisedJuteTyp: o.advisedJuteTyp,
                      advisedQuality: o.advisedQuality,
                      advisedQuantity: o.advisedQuantity,
                      advisedWeight: o.advisedWeight,
                      poLineItemNum: o.poLineItemNum,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //if (this.state.approveStatus) {

                    this.setState({ deleteItem: true, id: o.id });

                    this.basicItemAlert("Do You Like To Delete This Item?");
                    //  data.splice(i,1);
                    //}
                    return true;
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });
    console.log("indentWeight " + header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        approveStatus: header.approveStatus,
        entryNo: header.id,
        chalanNo: header.chalanNo,
        chalanDate: header.challanDateDesc,
        vehicleNum: header.vehicleNo,
        driverName: header.driverName,
        transporter: header.transporter,
        selectedMukam: parseInt(header.mukam),
        selectedSupplier: header.suppCode,
        supplierName: header.supplierName,
        remarks: header.remarks,
        date1: header.createdDate,
        time1: header.createdTime,
        time2: header.createdTime,
        outDateTime: header.outDateDesc + header.outTimeDesc,
        outDate: header.outDateDesc,
        outTime: header.outTimeDesc,
        year: header.finYear,
        selectedPO: header.poNo,
        createdBy: header.createdBy,
        tareWeightQTL: header.tareWeight,
        grossWeightQTL: header.grossWeight,
        actualWeightQTL: header.actualWeight,
        chalanWeightQTL: header.challanWeight,
        tareWeightKG: (parseFloat(header.tareWeight) * 100).toFixed(2),
        grossWeightKG: (parseFloat(header.grossWeight) * 100).toFixed(2),
        actualWeightKG: (parseFloat(header.actualWeight) * 100).toFixed(2),
        chalanWeightKG: (parseFloat(header.challanWeight) * 100).toFixed(2),
        SelectedChalanType: this.getDefaultChalanType(header.withorWithOutPo),
        unitConversion: this.getDefaultUnitConvertion(header.unitConversion),
        invoiceList: indentlist,
        qcCheck: header.qcCheck,
        statusDesc: header.statusDesc,
        branchId: header.branchId,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "16/" +
          header.id +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
      if (header.indentHdrId !== null && header.indentHdrId !== "")
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "8/" +
            header.indentHdrId +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleInvoiceFiles
        );
      if (header.poHdrId !== null && header.poHdrId !== "")
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "12/" +
            header.poHdrId +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleInvoiceFiles
        );
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };

  handleCreate = () => {
    //alert();
    if (this.state.isCreated) this.hideAlertMessage();

    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };

  getSuppData = () => {
    var data = {
      frmDt: this.state.fromDate,
      toDt: this.state.todate,

      supCode: getCookie(cons.SUPP_CODE_NEW),
      vehicleNum: this.state.vehicleNo,
      gateEntryNum: this.state.gateEntryNO,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: this.state.year,
    };

    return data;
  };

  getData = () => {
    var data = {
      frmDt: this.state.fromDate,
      toDt: this.state.todate,
      supCode: this.state.supplierId,
      vehicleNum: this.state.vehicleNo,
      gateEntryNum: this.state.gateEntryNO,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: this.state.year,
    };
    console.log("vamsiii---", data);
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };
  //dateFormate
  dateChanged = (myDate) => {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  timeChangedFormat = (myDate, time) => {
    console.log("time", myDate + time);
    // var time = new Date(myDate+" "+time).getTime();
    //var localDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2], timeParts[0], timeParts[1]);
    // console.log("time",time)
    myDate = myDate.split("-");
    var timeParts = time.split(":");
    // var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2] + timeParts[0]+ timeParts[1]+timeParts[2];
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    if (event.target.id === "tareWeightKG") {
      if (value !== "") {
        if (parseFloat(value) > parseFloat(this.state.grossWeightKG)) {
          this.basicAlert("Tare Weight should be less than Gross Weight");

          this.setState({
            [event.target.id]: "",
            tareWeightQTL: "",
            tareWeightKG: "",
          });
          event.target.value = 0;
          value = 0;
          // alert(document.getElementById("tareWeightKG").value);
          document.getElementById("tareWeightKG").value = "0";
        } else {
          this.setState({
            tareWeightQTL: parseFloat(parseInt(value) / 100).toFixed(2),
          });
        }
      } else
        this.setState({
          tareWeightQTL: 0,
          // value: 0,
        });
    }

    if (event.target.id === "grossWeightKG") {
      if (value !== "")
        this.setState({
          grossWeightQTL: parseFloat(parseInt(value) / 100).toFixed(2),
        });
      else
        this.setState({
          grossWeightQTL: 0,
        });
    }
    if (event.target.id === "chalanWeightKG") {
      if (value !== "")
        this.setState({
          chalanWeightQTL: parseFloat(parseInt(value) / 100).toFixed(2),
        });
      else
        this.setState({
          chalanWeightQTL: 0,
        });
    }
    if (event.target.id === "quantity") {
      if (value !== null && value !== "") {
        if (this.state.unitConversion.value === 2) {
          var calWeight = Math.round(parseInt((parseFloat(value) * 150) / 100));
          var Calrate = this.state.rate;

          if (Calrate !== "") {
            var calAmount = parseFloat(Calrate) * parseFloat(calWeight);
            this.setState({
              amount: calAmount,
            });
          }
          this.setState({
            weight: calWeight,
          });
          /* var PoQuantity=0;
                    if(this.state.SelectedChalanType.value===2){
                        console.log("po ype","without"+this.state.SelectedChalanType.label);
                        PoQuantity=this.getActualWeightQTL();
                    }else if(this.state.SelectedChalanType.value===1){
                        PoQuantity=this.state.poQuantity;
                    }*/
          //     alert(this.state.indentQuantity);
          //  var calWeight = (parseFloat(PoQuantity) * parseFloat(value) )/ 100;
        }
      } else {
        this.setState({
          weight: "",
          amount: "",
        });
      }
    }

    this.setState({
      [event.target.id]: value,
    });
  };
  getActualWeight = () => {
    var tare = this.state.tareWeightKG;
    var gross = this.state.grossWeightKG;
    if (tare !== "" && gross !== 0 && gross !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      /* this.setState({
                actualWeightQTL:parseFloat(parseInt(gross-tare)/100)
            });*/
      return gross - tare;
    } else {
      /* this.setState({
                actualWeightQTL:parseFloat(parseInt(gross)/100)
            });*/
      return gross;
    }
  };
  getActualWeightQTL = () => {
    var tare = this.state.tareWeightKG;
    var gross = this.state.grossWeightKG;
    if (tare !== 0 && tare !== "" && gross !== 0 && gross !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(gross - tare) / 100;
    } else if (gross !== "" && (tare === "" || tare === 0)) {
      /* this.setState({
                 actualWeightQTL:parseFloat(parseInt(gross)/100)
             });*/
      return parseFloat(parseFloat(gross) / 100);
    } else if (gross === "") {
      return "";
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedMukam") {
      this.setState({
        loadSupplier: true,
      });
    }
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
        loadItem: true,
      });
    }
    if (stateKey === "selectedItemGroup") {
      this.setState({
        loadItem: true,
      });
    }
    if (stateKey === "selectedItem") {
      this.setState({
        loadQuality: true,
      });
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };
  afterRefreshsupplier = (val) => {
    this.setState({
      loadSupplier: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
      loadItem: val,
    });
  };
  getSupplierName = (selectedValue) => {
    this.setState({
      supplierName: selectedValue,
    });
  };
  getDeptName = (selectedValue) => {
    this.setState({
      deptName: selectedValue,
    });
  };

  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };
  getQualityDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      qualityName: values[0],
      stock: values[1],
      lastPurchase: values[3],
    });
    if (this.state.qualityName !== values[0]) {
      this.setState({
        rate: values[2],
        allowableMoisture: values[4],
      });
      console.log("indentQuantity " + this.state.stock);
    }
  };

  getItemDetailsByName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemName: values[0],
      selectedItemGroup: values[1],
      unitId: values[2],
      stock: values[3],
    });
  };
  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  //basic Alert
  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }
  handleSelectedValues = (selectedValue) => {
    //const {classes}=this.props;

    // const {selectedJuteType}=this.state;
    if (selectedValue.value === 1)
      this.setState({
        loading: true,
        indentAlert: true,
      });
    if (selectedValue.value === 2)
      this.setState({
        selectedPO: "",
        //  SelectedChalanType: selectedValue,
      });

    this.setState({
      SelectedChalanType: selectedValue,
    });

    //console.log(`Option selected:`, defValue);
  };
  getDefaultChalanType = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === 1) {
        val = 1;
        label = "With PO";
      } else {
        val = 2;
        label = "Without PO";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };

  indenthideAlert() {
    this.setState({
      indentAlert: false,
      loading: false,
      SelectedChalanType: "",
    });
  }
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterSelectPO = () => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + this.state.poName);
    var data = {
      poNum: this.state.poName,
      userId: getCookie(cons.USERID),
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.GET_PO_DETAILS, data, this.handlePOResp);

    // getDataFromUrl(serverApi.GET_PO_DETAILS+this.state.selectedPO+"/"+getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER),this.handlePOResp)
  };
  handlePOResp = (resp) => {
    var header = resp.poHeader;
    var list = resp.poLineItemVo;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        poLineItemNum: prop.id,
        department: prop.deptName,
        itemGroup: prop.itemGroupName,
        item: prop.itemDesc,
        quantity: prop.quantity,
        weight: prop.actualQuantity,
        unitId: prop.unitId,
        quality: prop.juteQuality,
        qualityCode: prop.qualityCode,
        allowableMoisture: prop.allowableMoisturePercentage,
        deptId: prop.deptId,
        itemGroupId: prop.itemGroupId,
        itemId: parseInt(prop.itemId),
        advisedJuteTyp: parseInt(prop.itemId),
        advisedQuality: prop.qualityCode,
        advisedQuantity: prop.quantity,
        advisedWeight: prop.actualQuantity,
        amount: prop.amount,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  console.log("key in edit", key);

                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    console.log("id in edit", o.id);
                    this.setState({
                      // alert:true,
                      id: o.id,
                      updateItemAlert: true,
                      updateItem: true,
                      deptName: o.department,
                      itemGrpName: o.itemGroup,
                      itemName: o.item,
                      unitId: o.unitId,
                      stock: o.stockValue,
                      quantity: o.quantity,
                      selectedDepartment: o.deptId,
                      selectedItemGroup: o.itemGroupId,
                      selectedItem: o.itemId,
                      selectedJuteQuality: o.qualityCode,
                      qualityName: o.quality,
                      weight: o.weight,
                      amount: o.amount,
                      advisedJuteTyp: o.advisedJuteTyp,
                      advisedQuality: o.advisedQuality,
                      advisedQuantity: o.advisedQuantity,
                      advisedWeight: o.advisedWeight,
                      poLineItemNum: o.poLineItemNum,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>{" "}
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //if (this.state.approveStatus) {
                    console.log("id in delete", o.id);

                    this.setState({ deleteItem: true, id: o.id });

                    this.basicItemAlert("Do You Like To Delete This Item?");
                    //  data.splice(i,1);
                    // }
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>
          </div>
        ),
      };
    });
    // console.log("indentWeight "+header.supplierId);
    if (header !== null) {
      this.setState({
        loading: false,
        indentAlert: false,
        reloadSelect: true,
        unitConversion: this.getDefaultUnitConvertion(header.juteUnit),
        selectedSupplier: header.supplierId,
        selectedMukam: parseInt(header.mukam),
        selectedPO: header.id,
        juteType: header.type,
        unitConversionName: header.juteUnit,
        invoiceList: indentlist,
        poQuantity: header.weight,
      });
    }
  };

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };
  onCreatePO = () => {
    var sumofWeight = 0,
      sumOfAmount = 0;

    var invoiceList = this.state.invoiceList.map((prop, key) => {
      sumofWeight = sumofWeight + parseFloat(prop.weight);
      sumOfAmount =
        sumOfAmount + parseFloat(prop.weight) * parseFloat(prop.rate);
      var baleValue = "",
        percentValue = "";
      if (this.state.unitConversion.value === 2) {
        baleValue = prop.quantity;
        percentValue = "";
      } else {
        percentValue = prop.quantity;
        baleValue = "";
      }
      return {
        itemId: prop.itemId,
        itemDesc: prop.item,
        qualityCode: prop.qualityCode,
        quantity: prop.quantity,
        unitId: prop.unitId,
        rate: prop.rate,
        allowableMoisturePercentage: prop.allowableMoisture,
        cTax: "",
        sTax: "",
        iTax: "",
        bale: baleValue,
        loose: percentValue,
        amount: parseFloat(prop.weight) * parseFloat(prop.rate),
        marka: "",
        deptId: 0,
        valueWithoutTax: parseFloat(prop.weight) * parseFloat(prop.rate),
        valueWithTax: "",
        tax: "",
        indentId: "",
        actualQuantity: prop.weight,
        itemGroupId: prop.itemGroupId,
        discount: "",
        taxId: "",
        taxPercentage: "",
      };
    });

    var data = {
      poHeader: {
        type: "J",
        poDate: this.state.date1,
        creditTerm: "",
        deliveryTimeline: "15",
        vehicleTypeId: 4,
        vehicleQuantity: "1",
        supplierId: this.state.selectedSupplier,
        mukam: this.state.selectedMukam,
        brokerId: 0,
        frieghtCharge: 0,
        remarks: this.state.remarks,
        juteUnit: this.state.unitConversion.label,
        // "exceptedDate": this.state.expecDate,
        exceptedDate: moment(this.state.date1, "DD-MM-YYYY")
          .add(15, "days")
          .format("DD-MM-YYYY"),
        cTax: "",
        sTax: "",
        iTax: "",
        valueWithoutTax: sumOfAmount,
        submitter: getCookie(cons.USERID),
        indentNum: "",
        finnacialYear: this.state.year,
        footerNote: "",
        valueWithTax: sumOfAmount,
        tax: "",
        deliveryAddress: "",
        discount: "",
        weight: sumofWeight,
        brokerName: "",
        companyId: getCookie(cons.COMPANY_ID),
        withOrWithout: 1,
        branchId: this.state.branchId,
      },
      cipher: getCookie(cons.CIPHER),
      poLineItem: invoiceList,
    };
    this.handleLoading();
    console.log(data);
    requestList(serverApi.ADD_JUTE_PO, data, this.afterPOCreate);
  };

  afterPOCreate = (resp) => {
    this.setState({
      selectedPO: resp.id,
    });
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        // itemCode:prop.itemId,
        // itemDesc:prop.item,
        // itemGroupId:prop.itemGroupId,
        advisedQuality: prop.qualityCode,
        actualQuality: prop.qualityCode,
        advisedQuantity: prop.quantity,
        actualQuantity: prop.quantity,
        advisedWeight: prop.advisedWeight,
        actualWeight: prop.weight,
        receivedIn: this.state.unitConversionName,
        // dept:prop.deptId,
        //stock:prop.stockValue,
        quantity: prop.quantity,
        advisedJuteTyp: prop.itemId,
        actualJuteTyp: prop.itemId,
        qcJuteType: prop.itemId,
        qcJuteQuality: prop.qualityCode,
        qcJuteQuantity: prop.quantity,
        qcJuteWeight: prop.weight,
        unitId: prop.unitId,
        // "poLineItemNum":"123",
        createdBy: getCookie(cons.USERID),
        isPoAmment: "",
        juteGateSrlNo: "",
        kgs: "",
        poLineItemNum: prop.poLineItemNum,
      };
    });

    var data = {
      entryHeader: {
        chalanNo: this.state.chalanNo,
        chalanDate: this.dateChanged(this.state.chalanDate),
        vehicleNo: this.state.vehicleNum,
        driverName: this.state.driverName,
        suppCode: this.state.selectedSupplier,
        supplierName: this.state.supplierName,
        transporter: this.state.transporter,
        mukam: this.state.selectedMukam,
        brokerName: this.state.brokerName,
        brokerAddress: this.state.brokerAddress,
        netWeight: this.state.netWeight,
        grossWeight: this.state.grossWeightQTL,
        actualWeight: this.getActualWeightQTL(),
        challanWeight: this.state.chalanWeightQTL,
        remarks: this.state.remarks,
        inDate: this.dateChanged(this.state.date1),
        inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
        poNo: resp.id,
        mrNo: "",
        createdBy: getCookie(cons.USERID),
        withorWithOutPo: this.state.SelectedChalanType.value,
        unitConversion: this.state.unitConversionName,
        companyId: getCookie(cons.COMPANY_ID),
        finYear: this.state.year,
      },
      juteGateLineItems: invoiceList,
      cipher: getCookie(cons.CIPHER),
    };

    if (this.state.invoiceList.length >= 1) {
      //  this.handleLoading();
      if (this.state.selectedPO !== "" && this.state.selectedPO !== null) {
        requestList(
          serverApi.ADD_JUTE_GATE_ENTRY,
          data,
          this.handleAddJuteGate
        );
      } else {
        this.basicAlert("Something went wrong.PO Not Created !!");
      }
    } else if (this.state.invoiceList.length < 1) {
      this.basicAlert("Please Add Atleast One Item..!!");
    }
  };

  onCreateButtonClick = () => {
    const {
      vehicleNum,
      driverName,
      SelectedChalanType,
      selectedSupplier,
      selectedMukam,
      grossWeightKG,
      chalanWeightKG,
      branchId,
    } = this.state;

    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var mailAlert = getConfigValue(cons.EMAIL);

      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          // dataId:prop.dataId,
          // itemCode: prop.itemId,
          // itemDesc:prop.item,
          // itemGroupId:prop.itemGroupId,
          //advisedQuality: prop.advisedQuality,
          advisedQuality: prop.qualityCode,
          actualQuality: prop.qualityCode,
          // advisedQuantity: prop.advisedQuantity,
          advisedQuantity: prop.quantity,
          actualQuantity: prop.quantity,
          // advisedWeight:prop.advisedWeight,
          advisedWeight: prop.weight,
          actualWeight: prop.weight,
          receivedIn: this.state.unitConversionName,
          // dept:prop.deptId,
          //stock:prop.stockValue,
          quantity: prop.quantity,
          // advisedJuteTyp: prop.advisedJuteTyp,
          advisedJuteTyp: prop.itemId,
          actualJuteTyp: prop.itemId,
          qcJuteType: prop.itemId,
          qcJuteQuality: prop.qualityCode,
          qcJuteQuantity: prop.quantity,
          qcJuteWeight: prop.weight,
          unitId: prop.unitId,
          poLineItemNum: prop.poLineItemNum,
          allowableMoisture: prop.allowableMoisture,
          createdBy:
            getCookie(cons.EB_NO) === "null"
              ? getCookie(cons.USER_NAME)
              : getCookie(cons.EB_NO),
          isPoAmment: "",
          juteGateSrlNo: "",
          kgs: "",
        };
      });
      //data
      var data = {
        entryHeader: {
          chalanNo: this.state.chalanNo,
          chalanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          transporter: this.state.transporter,
          mukam: this.state.selectedMukam,
          brokerName: this.state.brokerName,
          brokerAddress: this.state.brokerAddress,
          netWeight: this.state.netWeight,
          grossWeight: this.state.grossWeightQTL,
          actualWeight: this.getActualWeightQTL(),
          tareWeight: this.state.tareWeightQTL,
          challanWeight: this.state.chalanWeightQTL,
          remarks: this.state.remarks,
          inDate: this.dateChanged(this.state.date1),
          inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
          poNo: this.state.selectedPO,
          mrNo: "",
          createdBy:
            getCookie(cons.EB_NO) === "null"
              ? getCookie(cons.USER_NAME)
              : getCookie(cons.EB_NO),
          withorWithOutPo: this.state.SelectedChalanType.value === 1 ? 1 : 2,
          unitConversion: this.state.unitConversionName,
          companyId: getCookie(cons.COMPANY_ID),
          finYear: this.state.year,
          branchId: branchId,
        },
        juteGateLineItems: invoiceList,
        cipher: getCookie(cons.CIPHER),
        mailAlert: mailAlert === "2" ? "N" : "Y",
      };

      if (this.state.invoiceList.length >= 1) {
        console.log("selectedPO " + this.state.selectedPO);

        if (
          vehicleNum !== "" &&
          SelectedChalanType !== 0 &&
          selectedMukam !== "" &&
          selectedMukam !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== "0" &&
          selectedSupplier !== "" &&
          grossWeightKG !== 0 &&
          chalanWeightKG !== 0 &&
          branchId !== ""
        ) {
          if (this.state.selectedPO !== "") {
            this.handleLoading();
            requestList(
              serverApi.ADD_JUTE_GATE_ENTRY,
              data,
              this.handleAddJuteGate
            );
          } else {
            this.onCreatePO();
          }
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      }
    }
  };
  handleAddJuteGate = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var mailAlert = getConfigValue(cons.EMAIL);

      const {
        qcCheck,
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        selectedMukam,
        grossWeightKG,
        chalanWeightKG,
        branchId,
      } = this.state;

      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          recId: prop.dataId,
          // itemCode:prop.itemId,
          // itemDesc:prop.item,
          // itemGroupId:prop.itemGroupId,
          advisedQuality: prop.qualityCode,
          actualQuality: prop.qualityCode,
          advisedQuantity: prop.quantity,
          actualQuantity: prop.quantity,
          advisedWeight: prop.weight,
          actualWeight: prop.weight,
          receivedIn: this.state.unitConversionName,
          // dept:prop.deptId,
          //stock:prop.stockValue,
          quantity: prop.quantity,
          unitId: prop.unitId,
          advisedJuteTyp: prop.itemId,
          actualJuteTyp: prop.itemId,
          qcJuteType: prop.itemId,
          qcJuteQuality: prop.qualityCode,
          qcJuteQuantity: prop.quantity,
          qcJuteWeight: prop.weight,
          // "poLineItemNum":"123",
          updateBy:
            getCookie(cons.EB_NO) === "null"
              ? getCookie(cons.USER_NAME)
              : getCookie(cons.EB_NO),
          isPoAmment: "",
          juteGateSrlNo: "",
          kgs: "",
          poLineItemNum: prop.poLineItemNum,
          finYear: this.state.year,
        };
        console.log("po resp ", prop.unitId);
      });

      //console.log("get time",this.timeChangedFormat(this.state.outDate,this.state.time2));
      var data = {
        entryHeader: {
          id: this.state.entryNo,
          chalanNo: this.state.chalanNo,
          chalanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          transporter: this.state.transporter,
          mukam: this.state.selectedMukam,
          brokerName: this.state.brokerName,
          brokerAddress: this.state.brokerAddress,
          netWeight: this.state.netWeight,
          grossWeight: this.state.grossWeightQTL,
          actualWeight: this.getActualWeightQTL(),
          challanWeight: this.state.chalanWeightQTL,
          tareWeight: this.state.tareWeightQTL,
          //  remarks:this.state.remarks,
          inDate: this.dateChanged(this.state.date1),
          inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
          poNo: this.state.selectedPO,
          unitConversion: this.state.unitConversionName,
          //   mrNo: '',
          remarks: this.state.remarks,
          createdBy:
            getCookie(cons.EB_NO) === "null"
              ? getCookie(cons.USER_NAME)
              : getCookie(cons.EB_NO),
          finYear: this.state.year,
          status: this.state.statusDesc === "DRAFTED" ? "1" : "",
          branchId: branchId,
          withorWithOutPo: this.state.SelectedChalanType.value === 1 ? 1 : 2,
        },
        juteGateLineItems: invoiceList,
        cipher: getCookie(cons.CIPHER),
        mailAlert: mailAlert === "2" ? "N" : "Y",
      };

      if (this.state.invoiceList.length >= 1) {
        console.log("qcCheck " + this.state.qcCheck);
        //  if (this.state.selectedPO !== "") {
        if (
          vehicleNum !== "" &&
          SelectedChalanType !== 0 &&
          selectedMukam !== "" &&
          selectedMukam !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== "0" &&
          selectedSupplier !== "" &&
          grossWeightKG !== "" &&
          grossWeightKG !== 0 &&
          chalanWeightKG !== 0 &&
          chalanWeightKG !== "" &&
          branchId !== ""
        ) {
          if (qcCheck !== "Y") {
            this.handleLoading();
            requestList(
              serverApi.UPDATE_JUTE_GATE_ENTRY,
              data,
              this.handleAddJuteGate
            );
          } else {
            this.basicAlert(
              "Quality Check Committed For This Gate Entry, Can't Updated..!!"
            );
          }
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
        // }
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      }
    }
  };

  onOutButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        SelectedChalanType,
        selectedSupplier,
        selectedMukam,
        grossWeightKG,
        chalanWeightKG,
        outDate,
        outTime,
        selectedPO,
        branchId,
      } = this.state;

      console.log("po resp ", this.state.selectedPO + "");
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          recId: prop.dataId,
          // itemCode:prop.itemId,
          // itemDesc:prop.item,
          // itemGroupId:prop.itemGroupId,
          advisedQuality: prop.qualityCode,
          actualQuality: prop.qualityCode,
          advisedQuantity: prop.quantity,
          actualQuantity: prop.quantity,
          advisedWeight: prop.weight,
          actualWeight: prop.weight,
          receivedIn: this.state.unitConversionName,
          // dept:prop.deptId,
          //stock:prop.stockValue,
          quantity: prop.quantity,
          advisedJuteTyp: prop.itemId,
          actualJuteTyp: prop.itemId,
          qcJuteType: prop.itemId,
          qcJuteQuality: prop.qualityCode,
          qcJuteQuantity: prop.quantity,
          qcJuteWeight: prop.weight,
          unitId: prop.unitId,
          // "poLineItemNum":"123",
          createdBy:
            getCookie(cons.EB_NO) === "null"
              ? getCookie(cons.USER_NAME)
              : getCookie(cons.EB_NO),
          isPoAmment: "",
          juteGateSrlNo: "",
          kgs: "",
          poLineItemNum: prop.poLineItemNum,
        };
      });
      //console.log("get time",this.timeChangedFormat(this.state.outDate,this.state.time2));
      var data = {
        entryHeader: {
          id: this.state.entryNo,
          chalanNo: this.state.chalanNo,
          chalanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          transporter: this.state.transporter,
          mukam: this.state.selectedMukam,
          brokerName: this.state.brokerName,
          brokerAddress: this.state.brokerAddress,
          netWeight: this.state.netWeight,
          grossWeight: this.state.grossWeightQTL,
          actualWeight: this.getActualWeightQTL(),
          challanWeight: this.state.chalanWeightQTL,
          tareWeight: this.state.tareWeightQTL,
          //  remarks:this.state.remarks,
          /*inDate: this.dateChanged(this.state.date1),
                    inTime: this.timeChangedFormat(this.state.date1, this.state.time2),*/
          outDate: this.dateChanged(this.state.outDate),
          outTime: this.timeChangedFormat(this.state.outDate, this.state.time2),
          poNo: this.state.selectedPO,
          mrNo: "",
          createdBy:
            getCookie(cons.EB_NO) === "null"
              ? getCookie(cons.USER_NAME)
              : getCookie(cons.EB_NO),
          finYear: this.state.year,
          remarks: this.state.remarks,
          branchId: branchId,
          withorWithOutPo: this.state.SelectedChalanType.value === 1 ? 1 : 2,
        },
        juteGateLineItems: invoiceList,
      };

      if (this.state.invoiceList.length >= 1) {
        console.log("selectedPO " + this.state.selectedPO);
        if (
          vehicleNum !== "" &&
          SelectedChalanType !== 0 &&
          selectedMukam !== "" &&
          selectedMukam !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== "0" &&
          selectedSupplier !== "" &&
          grossWeightKG !== 0 &&
          grossWeightKG !== "" &&
          chalanWeightKG !== 0 &&
          chalanWeightKG !== "" &&
          outDate !== "" &&
          outTime !== "" &&
          branchId !== ""
        ) {
          this.handleLoading();
          requestList(
            serverApi.UPDATE_JUTE_GATE_ENTRY,
            data,
            this.handleAddJuteGate
          );
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      }
    }
  };

  onClickAddItem = () => {
    var arr = this.state.invoiceList;
    var itemIndex = 0,
      hasItem = false,
      PoQuantity = 0,
      sumOfQty = 0,
      quantityInItem = 0,
      weightInItem = 0,
      amountInItem = 0;
    if (this.state.SelectedChalanType.value === 2) {
      console.log("po ype", "without" + this.state.SelectedChalanType.label);
      PoQuantity = this.getActualWeightQTL();
    } else if (this.state.SelectedChalanType.value === 1) {
      PoQuantity = this.state.poQuantity;
      console.log("po ype", "with po");
    }

    var SumchallanWegiht = this.state.chalanWeightQTL;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      console.log("id    .....", itemIndex);
      quantityInItem = arr[itemIndex].quantity;
      weightInItem = arr[itemIndex].weight;
      amountInItem = arr[itemIndex].amount;

      arr[itemIndex].weight = this.state.weight;
      arr[itemIndex].quantity = this.state.quantity;
      arr[itemIndex].amount = this.state.amount;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    arr.map((q) => {
      sumOfQty = sumOfQty + parseFloat(q.weight);
      if (
        q.quality === this.state.selectedJuteQuality &&
        !this.state.updateItem
      )
        hasItem = true;
    });
    if (!this.state.updateItem) {
      sumOfQty = sumOfQty + parseFloat(this.state.weight);
    }
    sumOfQty = Number.parseFloat(sumOfQty).toFixed(2);
    var addItemObj = {
      id: itemIndex,
      dataId: this.state.dataId,
      poLineItemNum: this.state.poLineItemNum,
      department: this.state.deptName,
      itemGroup: this.state.itemGrpName,
      item: this.state.itemName,
      quantity: this.state.quantity,
      unitId: this.state.unitId,
      quality: this.state.qualityName,
      qualityCode: this.state.selectedJuteQuality,
      rate: this.state.rate,
      stockValue: this.state.stock,
      deptId: this.state.selectedDepartment,
      itemGroupId: this.state.selectedItemGroup,
      itemId: this.state.selectedItem,
      weight: this.state.weight,
      amount: this.state.amount,
      allowableMoisture: this.state.allowableMoisture,
      advisedJuteTyp: this.state.advisedJuteTyp,
      advisedQuality: this.state.advisedQuality,
      advisedQuantity: this.state.advisedQuantity,
      advisedWeight: this.state.advisedWeight,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === i) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Update This Item?");
                  this.setState({
                    id: o.id,
                    updateItem: true,
                    updateItemAlert: true,
                    deptName: o.department,
                    itemGrpName: o.itemGroup,
                    itemName: o.item,
                    unitId: o.unitId,
                    stock: o.stockValue,
                    quantity: o.quantity,
                    selectedDepartment: o.deptId,
                    selectedItemGroup: o.itemGroupId,
                    selectedItem: o.itemId,
                    selectedJuteQuality: o.qualityCode,
                    qualityName: o.quality,
                    rate: o.rate,
                    allowableMoisture: o.allowableMoisture,
                    weight: o.weight,
                    amount: o.amount,
                    advisedJuteTyp: o.advisedJuteTyp,
                    advisedQuality: o.advisedQuality,
                    advisedQuantity: o.advisedQuantity,
                    advisedWeight: o.advisedWeight,
                    poLineItemNum: o.poLineItemNum,
                    dataId: o.dataId,
                    //alert:true,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>{" "}
          {/* use this button to remove the data row */}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === i) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.setState({ deleteItem: true, id: o.id });
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  return true;
                }
              });
              this.setState({
                invoiceList: data,
                deptName: "",
                itemGrpName: "",
                itemName: "",
                unitId: "",
                stock: "",
                quantity: "",
                selectedDepartment: "",
                selectedItemGroup: "",
                selectedItem: "",
                rate: "",
                allowableMoisture: "",
                weight: "",
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>
        </div>
      ),
    };

    const {
      selectedItemGroup,
      selectedItem,
      quantity,
      selectedJuteQuality,
    } = this.state;
    if (!hasItem) {
      if (
        selectedItemGroup !== "" &&
        selectedItem !== "" &&
        quantity !== "" &&
        selectedJuteQuality !== ""
      ) {
        // console.log(arr.toString());

        console.log("sumOfQty", sumOfQty, SumchallanWegiht);
        /*  if(this.state.unitConversion.value===2){
                PoQuantity =Math.round(parseFloat(PoQuantity*100)/150) ;
                console.log("PoQuantity",PoQuantity);

            }else if(this.state.unitConversion.value===1){
                    PoQuantity =100
                }*/

        SumchallanWegiht = Number.parseFloat(SumchallanWegiht).toFixed(2);
        if (parseFloat(sumOfQty) <= parseFloat(SumchallanWegiht)) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);
          this.setState({
            loading: true,
            invoiceList: arr,
            updateItem: false,
          });
          this.hideAlert();
        } else {
          this.basicAlert(
            "Sum of weight should not exceed Challan Weight " +
              SumchallanWegiht +
              "..!!"
          );
          if (this.state.updateItem) {
            arr[itemIndex].weight = weightInItem;
            arr[itemIndex].quantity = quantityInItem;
            arr[itemIndex].amount = amountInItem;
          }
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This Quality. Please Select Another One..!!"
      );
    }
  };

  handleAddItem = () => {
    // const {classes}=this.props;
    const {
      vehicleNum,
      driverName,
      SelectedChalanType,
      selectedSupplier,
      selectedMukam,
      grossWeightKG,
      chalanWeightKG,
      unitConversion,
      tareWeightKG,
    } = this.state;
    // console.log("unitConversionName "+ this.state.unitConvertion);
    //  var id="";
    //   console.log(this.state);
    if (
      vehicleNum !== "" &&
      SelectedChalanType !== 0 &&
      selectedMukam !== "" &&
      selectedMukam !== 0 &&
      SelectedChalanType !== "" &&
      selectedSupplier !== "0" &&
      selectedSupplier !== "" &&
      grossWeightKG !== 0 &&
      chalanWeightKG !== 0 &&
      unitConversion !== ""
    )
      this.setState({
        loading: true,
        alert: true,
      });
    else if (
      this.state.updateView &&
      vehicleNum !== "" &&
      selectedMukam !== "" &&
      selectedMukam !== 0 &&
      selectedSupplier !== "0" &&
      selectedSupplier !== "" &&
      grossWeightKG !== 0 &&
      chalanWeightKG !== 0 &&
      tareWeightKG !== 0
    ) {
      this.setState({
        loading: true,
        alert: true,
      });
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };
  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getQuantityType = () => {
    if (this.state.unitConversion !== "") {
      var val = this.state.unitConversion.value;
      if (val === 2) {
        return "BALES";
      } else if (val === 1) {
        return "DRUMS";
      }
    } else return "";
  };

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideUnitConvertionCancelAlert()}
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
    });
  }
  hideUnitConvertionCancelAlert() {
    this.setState({
      basicAlert: null,
      unitConversion: this.state.unitValue,
      unitConversionName: this.state.unitValue.label,
    });
  }

  handleSelectedUnitValue = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      this.state.unitConversion.value !== defValue.value
    ) {
      this.setState({
        unitValue: this.state.unitConversion,
      });
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    }
    this.setState({
      unitConversion: defValue,
      unitConvertionId: defValue.value,
      unitConversionName: defValue.label,
      canChangeUnit: false,
    });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  //poname
  getPoName = (name) => {
    var val = name.split("^");
    this.setState({
      poName: val[3],
    });
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert1: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryNo,
      isReject,
      isApprove,
      srStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(this.state.srStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        16,
        fileName,
        entryNo,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  render() {
    const { classes } = this.props;
    const { selectedPO } = this.state;
    const inputFocus = {
      autoComplete: "off",
    };
    const ucoptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];

    const options = [
      { value: 0, label: "Select..." },
      { value: 1, label: "With PO" },
      { value: 2, label: "Without PO" },
    ];
    var yesterday = Datetime.moment().subtract(1, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var valid = function(current) {
      if (isBackDate === "2") return current.isAfter(yesterday);
      else return true;
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {//get indent alert
                  this.state.indentAlert ? (
                    <Popup
                      onClickSave={() => this.afterSelectPO()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        PO List
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedPO"}
                                        defValue={selectedPO}
                                        onSelect={this.onSelect}
                                        getName={this.getPoName}
                                        stateKey="selectedPO"
                                        url={
                                          serverApi.APPROVED_POS +
                                          "J/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.ACADEMIC_YEAR) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={selectedPO}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Item
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.selectedItem}
                                    id={"selectedItem"}
                                    onSelect={this.onSelect}
                                    getName={this.getItemDetailsByName}
                                    // isRefresh={this.state.loadItem}
                                    stateKey="selectedItem"
                                    // setIsRefresh={this.afterRefreshItem}
                                    url={
                                      serverApi.DATATABLE_SERVICE_URL +
                                      this.state.selectedMukam +
                                      "/getAllItemByMukam/" +
                                      getCookie(cons.COMPANY_ID) +
                                      "/" +
                                      getCookie(cons.CIPHER)
                                    }
                                    value={this.state.selectedItem}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quality
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.selectedJuteQuality}
                                    id={"selectedJuteQuality"}
                                    onSelect={this.onSelect}
                                    getName={this.getQualityDetails}
                                    isRefresh={this.state.loadQuality}
                                    stateKey="selectedJuteQuality"
                                    setIsRefresh={this.afterRefreshQuality}
                                    url={
                                      serverApi.DATATABLE_SERVICE_URL +
                                      this.state.selectedItem +
                                      "/" +
                                      getCookie(cons.COMPANY_ID) +
                                      "/getQualityByItem"
                                    }
                                    value={this.state.selectedJuteQuality}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    iType={"number"}
                                    InputProps={{
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {this.getQuantityType()}
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="weight"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.weight}
                                    iType={"number"}
                                    InputProps={{
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            QTL
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Time
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            defaultValue={new Date()}
                            dateFormat={false}
                            onChange={(timeObj) => {
                              this.timeChanged1(timeObj, "time1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "time1",
                              value: this.state.time1,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="chalanNo"
                          className={classes.textField}
                          value={this.state.chalanNo}
                          onChange={this.onChangeValues}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            //   isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "chalanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "chalanDate",
                              value: this.state.chalanDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="vehicleNum"
                          // className={classes.textField}
                          value={this.state.vehicleNum}
                          onChange={this.onChangeValues}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Driver Name
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="driverName"
                          className={classes.textField}
                          value={this.state.driverName}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Transporter
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="transporter"
                          className={classes.textField}
                          value={this.state.transporter}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={
                            this.state.updateView &&
                            this.state.statusDesc !== "OPEN" &&
                            this.state.statusDesc !== "DRAFTED"
                          }
                          defValue={this.state.SelectedChalanType}
                          name={"SelectedChalanType"}
                          value={this.state.SelectedChalanType}
                          onChange={this.handleSelectedValues}
                          options={options}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Unit Conversion
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          defValue={this.state.unitConversion}
                          name={"unitConversion"}
                          value={this.state.unitConversion}
                          onChange={this.handleSelectedUnitValue}
                          // statekey={"unitConvertion"}
                          options={ucoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Mukam
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"selectedMukam"}
                          selectDisabled={
                            this.state.updateView &&
                            this.state.statusDesc !== "DRAFTED"
                          }
                          defValue={this.state.selectedMukam}
                          isReload={this.state.reloadSelect}
                          setIsReload={this.afterReloadSelect}
                          onSelect={this.onSelect}
                          getName={this.getSupplierName}
                          stateKey="selectedMukam"
                          url={
                            serverApi.SEARCH_MUKAM + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.selectedMukam}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_GRP_ID) !== "99" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedSupplier"}
                            selectDisabled={
                              this.state.updateView &&
                              this.state.statusDesc !== "DRAFTED"
                            }
                            defValue={this.state.selectedSupplier}
                            isReload={this.state.reloadSelect}
                            setIsReload={this.afterReloadSelect}
                            onSelect={this.onSelect}
                            isRefresh={this.state.loadSupplier}
                            setIsRefresh={this.afterRefreshsupplier}
                            getName={this.getSupplierName}
                            stateKey="selectedSupplier"
                            url={
                              serverApi.DATATABLE_SERVICE_URL +
                              "getSuppListByMukamId/" +
                              this.state.selectedMukam +
                              "/company/" +
                              getCookie(cons.COMPANY_ID)
                            }
                            value={this.state.selectedSupplier}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    ""
                  )}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Gross Weight<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          disabled={this.state.statusDesc === "DRAFTED"}
                          id="grossWeightKG"
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.state.grossWeightKG}
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  KG
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          id="grossWeightQTL"
                          disabled={true}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.state.grossWeightQTL}
                          fullWidth={true}
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  QTL
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Weight
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          id="chalanWeightKG"
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.state.chalanWeightKG}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  KG
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          id="chalanWeightQTL"
                          disabled={true}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.state.chalanWeightQTL}
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  QTL
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tare Weight
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          id="tareWeightKG"
                          // disabled={
                          //   !this.state.updateView ||
                          //   this.state.statusDesc === "DRAFTED"
                          // }
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.state.tareWeightKG}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  KG
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          disabled={true}
                          id="tareWeightQTL"
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.state.tareWeightQTL}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  QTL
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Actual Weight
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          id="actualWeightKG"
                          disabled={true}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.getActualWeight()}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  KG
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          disabled={true}
                          id="actualWeightQTL"
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          value={this.getActualWeightQTL()}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  QTL
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Remarks
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="remarks"
                          // label="Multiline"
                          multiline={true}
                          rowsMax="3"
                          value={this.state.remarks}
                          onChange={this.onChangeValues}
                          height={60}
                          //className={classes.textField}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Out Date Time
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={4}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              defaultValue={new Date()}
                              //  isValidDate={valid}
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "outDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "outDate",
                                value: this.state.outDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={4}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              defaultValue={new Date()}
                              closeOnSelect={true}
                              // defaultValue={this.state.time1}
                              dateFormat={false}
                              onChange={(timeObj) => {
                                this.timeChanged1(timeObj, "outTime");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "outTime",
                                value: this.state.outTime,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {/* {console.log(this.state.invoiceList.length+" length")}*/}
                        <LineItemTable
                          data={this.state.invoiceList}
                          loading={this.state.loading}
                          disableActions={this.state.qcCheck === "Y"}
                          columns={[
                            /*  {
                                                            Header: "Item Group",
                                                            accessor: "itemGroup",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },*/
                            {
                              Header: "Item",
                              accessor: "item",
                              minWidth: 200,
                              style: {
                                color: "#000",
                                textAlign: "left",
                                textOverflow: "unset",
                              },
                            },
                            {
                              Header: "Quality",
                              accessor: "quality",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            /* {
                                                            Header: "Quantity",
                                                            accessor: "quantity",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },*/
                            {
                              Header: this.getQuantityType(),
                              accessor: "quantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Weight",
                              accessor: "weight",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Unit",
                              accessor: "unitId",
                              width: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12} sm={12}>
                          <InputLabel className={classes.label}>
                            Support Documents :
                          </InputLabel>
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView ? (
                        <ItemGrid
                          xs={8}
                          style={{ overflowY: "auto", maxHeight: 250 }}
                        >
                          {this.state.filesList.map((r, i) => (
                            <GridContainer style={{ marginTop: "10px" }}>
                              <ItemGrid xs={12} sm={2}>
                                {i + 1}
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                                <a
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL + r.retrievalPath,
                                      r.fileName,
                                      r.fileExtension,
                                      this.handleDownloadedFile
                                    );
                                  }}
                                >
                                  {r.fileName !== null && r.fileName !== ""
                                    ? r.fileName
                                    : "indent" + (i + 1)}
                                </a>
                              </ItemGrid>
                              <ItemGrid xs={4}>
                                <IconButton
                                  right={true}
                                  onClick={() => {
                                    this.setState({
                                      deleteInvoice: true,
                                      invoiceId: r.fileUploadId,
                                      fileId: i,
                                    });
                                    this.InvoicebasicAlert(
                                      "Do You Like To Delete This File?"
                                    );
                                  }}
                                  color="dangerNoBackground"
                                >
                                  <Close />
                                </IconButton>{" "}
                                {""}
                              </ItemGrid>
                            </GridContainer>
                          ))}
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView && this.state.qcCheck !== "Y" ? (
                        <ItemGrid xs={4}>
                          <Button color={"info"} onClick={this.handleFileClick}>
                            Upload File
                          </Button>
                        </ItemGrid>
                      ) : null}

                      <ItemGrid xs={12}>
                        {this.state.qcCheck !== "Y" ? (
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        ) : null}
                      </ItemGrid>

                      {/*
                                            <ItemGrid xs={12}
                                                      style={{height: 1, backgroundColor: "#00acc1", marginTop: "10px"}}/>
*/}
                    </GridContainer>
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            style={{ width: 80 }}
                            onClick={this.onCreateButtonClick}
                          >
                            in
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button
                            color="danger"
                            style={{ width: 80 }}
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {/*
                                    {this.state.updateView && this.state.outDateTime!==""?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" style={{width:80}} onClick={this.onUpdateButtonClick}>update</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" style={{width:80}} onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
*/}
                  {this.state.updateView &&
                  this.state.qcCheck === "N" &&
                  this.state.statusDesc !== "DRAFTED" ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            style={{ width: 80 }}
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        {this.state.updateView &&
                        this.state.outDateTime === "" ? (
                          <ItemGrid>
                            <Button
                              color="success"
                              style={{ width: 80 }}
                              onClick={this.onOutButtonClick}
                            >
                              Out
                            </Button>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid>
                          <Button
                            color="danger"
                            style={{ width: 80 }}
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView &&
                  this.state.qcCheck === "N" &&
                  this.state.statusDesc === "DRAFTED" ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            style={{ width: 80 }}
                            onClick={this.onUpdateButtonClick}
                          >
                            Open
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                    autoComplete: "off",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_GRP_ID) !== "99" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  url={
                                    serverApi.SEARCH_SUPPLIER +
                                    "/" +
                                    getCookie(cons.COMPANY_ID)
                                  }
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : (
                          ""
                        )}
                        {/* {getCookie(cons.USER_GRP_ID) !== "99" ? ( */}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Gate Entry No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="gateEntryNO"
                                value={this.state.gateEntryNO}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {/* ) : (
                          ""
                        )} */}
                        {/* {getCookie(cons.USER_GRP_ID) !== "99" ? ( */}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Vehicle No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.vehicleNo}
                                id={"vehicleNo"}
                                onSelect={this.onSelect}
                                stateKey="vehicleNo"
                                url={
                                  serverApi.SELCET_VEHICLE_LIST +
                                  getCookie(cons.COMPANY_ID) +
                                  "/" +
                                  getCookie(cons.CIPHER)
                                }
                                value={this.state.vehicleNo}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {/* ) : (
                          ""
                        )} */}
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <br />
                          <br />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.GET_JUTE_GATE_ENTRY_TABLE}
                        // searchData={this.getData()}
                        searchData={
                          getCookie(cons.USER_GRP_ID) == "99"
                            ? this.getSuppData()
                            : this.getData()
                        }
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        //  handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"jute_gate_entry_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textField: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },

  notchedOutline: {},
  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(JuteGateEntry);
