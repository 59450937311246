import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import LineItemTable from "../CustumComponents/LineItemTable";

class JuteIndent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "indentNo",
      approveButton: false,

      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",
      date1: moment().format("DD-MM-YYYY"),
      indentNo: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      unitConvertion: "",
      unitConvertionId: "",
      unitConversionName: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantiy: "",
      selectedMukam: "",
      alert: false,
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      indentItemId: 0,
      branchId: "",
      filesList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + !getMenuPermission(0));
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_INDENT_DETAILS_BY_ID +
          "userid/" +
          getCookie(cons.USERID) +
          "/company/" +
          getCookie(cons.COMPANY_ID) +
          "/year/" +
          getCookie(cons.ACADEMIC_YEAR) +
          "?indentId=" +
          id,
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.indentHeader;
    var list = resp.indentItems;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        juteType: prop.itemId,
        juteName: prop.itemIdDesc,
        quality: prop.qualityCode,
        qualityName: prop.qualityCodeDesc,
        quantity: prop.indentQuantity,
        unit: prop.unitId,
        deptId: prop.deptId,
        grpId: prop.itemGroupId,
        stockValue: prop.stock,
        indentstatus: prop.indentStatus,
        indentItemId: prop.indentLineItemId,
        quantityType: prop.noOfBales,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                if (this.state.approveButton)
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.basicItemAlert("Do You Like To Update This Item?");
                      // console.log("no of bales",o.quantityType);
                      this.setState({
                        id: o.id,
                        updateItemAlert: true,
                        updateItem: true,
                        indentItemId: o.indentItemId,
                        selectedJuteType: o.juteType,
                        selectedJuteName: o.juteName,
                        selectedJuteQuality: o.quality,
                        selectedQualityName: o.qualityName,
                        quantity: o.quantityType,
                        selectedUnit: o.unit,
                        selectedDeptId: o.deptId,
                        selectedGrpId: o.grpId,
                        stock: o.stockValue,
                        // alert:true,
                      });
                      return true;
                    }
                  });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    if (this.state.approveButton) {
                      this.setState({ deleteItem: true, id: o.id });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                      //  data.splice(i,1);
                    }
                    return true;
                  }
                });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });
    //console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        indentStatus: header.status,
        approveButton: header.approveButton,
        indentNo: header.id,
        statusDesc: header.statusDesc,
        hdrId: header.hdrId,
        unitConvertion: this.getDefaultUnitConvertion(header.unitConversion),
        // unitConvertionId:"0",
        indentQuantity: header.indentWeight,
        //  sumOFQuantities:header.indentWeight,
        // year:header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.autoDateTime,
        date1: header.indentDateDesc,
        selectedMukam: header.mukamNo,
        selectedVehicleType: header.vehicleTypeId,
        vehicleQuantity: header.vehicleQuantity,
        quantity: "",
        stock: "",
        selectedJuteQuality: "",
        selectedQualityName: "",
        invoiceList: indentlist,
        finalApprover: resp.finalApprover,
        branchId: header.branchId,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "8/" +
          header.hdrId +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
    }
  };
  handleInvoiceFiles = (resp) => {
    if (resp.data) {
      this.setState({ filesList: resp.data });
    }
  };

  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      indentType: "J",
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      mukam: this.state.mukamId,
      status: this.state.status,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(parseFloat((this.state.indentQuantity * 100) / 150));
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  getStock = () => {
    console.log("sttt", this.state.stock);
    return this.state.stock;
  };

  onChangeValues = (event) => {
    const re = /^[0-9\b]+$/;
    var value = event.target.value;
    if (event.target.id === "indentQuantity") {
      //   console.log("val "+this.state.indentQuantity);
      if (value > 0 && value <= 100) {
        this.setState({
          selectedVehicleType: 3,
          vehicleQuantity: 1,
        });
      } else if (value > 100 && value <= 120) {
        this.setState({
          selectedVehicleType: 2,
          vehicleQuantity: 1,
        });
      } else if (value > 120 && value <= 160) {
        this.setState({
          selectedVehicleType: 1,
          vehicleQuantity: 1,
        });
      } else if (value > 160 && value <= 200) {
        this.setState({
          selectedVehicleType: 4,
          vehicleQuantity: 1,
        });
      } else if (value > 200) {
        this.setState({
          selectedVehicleType: 4,
          vehicleQuantity: Math.round(value / 200),
        });
      } else if (value === "") {
        this.setState({
          selectedVehicleType: "",
          vehicleQuantity: "",
        });
      }
    }

    if (event.target.id === "vehicleQuantity") {
      if (value !== null && value !== "") {
        if (this.state.selectedVehicleType !== "")
          this.setState({
            indentQuantity:
              parseInt(value) * parseInt(this.state.selectedIndentQuantity),
          });
        else {
          value = "";
          this.basicAlert("Please Select Vehicle Type..!!");
        }
      }
    }
    if (event.target.id === "stock") {
      //   console.log("val "+this.state.indentQuantity);
      this.setState({
        stock: this.state.stock,
      });
    }
    if (event.target.id === "quantity" && this.state.updateItem) {
    }
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
            [stateKey]: selectedValue,
        });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    if (
      stateKey === "selectedMukam" &&
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeMukam
    ) {
      this.unitConvertionAlert(
        "Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        [stateKey]: selectedValue,
      });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      data.map((o, key) => {
        o.id = key;
        o.actions = (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  console.log("key in edit", key);

                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    console.log("id in edit", o.id);
                    this.setState({
                      // alert:true,
                      id: o.id,
                      updateItemAlert: true,
                      updateItem: true,
                      deptName: o.department,
                      itemGrpName: o.itemGroup,
                      itemName: o.item,
                      uom: o.unit,
                      stock: o.stockValue,
                      quantity: o.quantity,
                      selectedDepartment: o.deptId,
                      selectedItemGroup: o.itemGroupId,
                      selectedItem: o.itemId,
                      selectedJuteQuality: o.qualityCode,
                      qualityName: o.quality,
                      weight: o.weight,
                      amount: o.amount,
                      advisedJuteTyp: o.advisedJuteTyp,
                      advisedQuality: o.advisedQuality,
                      advisedQuantity: o.advisedQuantity,
                      advisedWeight: o.advisedWeight,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>{" "}
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //if (this.state.approveStatus) {
                    console.log("id in delete", o.id);
                    if (this.state.approveButton && this.state.updateView) {
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                    } else if (!this.state.updateView) {
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                    }
                    return true;
                    //  data.splice(i,1);
                    // }
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>
          </div>
        );
      });

      //  if(this.state.approveStatus)
      this.setState({ invoiceList: data, deleteItem: false });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      quantity: "",
      stock: "",
    });
  }
  hideAlertMessage() {
    this.setState({
      indentStatus: "",
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveButton: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      finalApprover: false,
      branchId: "",
      filesList: [],
    });
  }
  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.indentItemId,
        indentHeaderId: this.state.indentNo,
        itemId: prop.juteType,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        id: this.state.indentNo,
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantiy,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
        status: "4",
      },
      indentItems: invoiceList,
      cipher: getCookie(cons.CIPHER),
    };
    this.handleLoading();
    requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
  };

  onApproveButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          id: prop.indentItemId,

          indentHeaderId: this.state.indentNo,
          itemId: prop.juteType,
          itemGroupId: prop.grpId,
          qualityCode: prop.quality,
          deptId: prop.deptId,
          stock: prop.stockValue,
          indentQuantity: prop.quantity,
          unitId: prop.unit,
          noOfBales: prop.quantityType,
          finYear: getCookie(cons.COMPANY_ID),
        };
      });

      var data = {
        indentHeader: {
          id: this.state.indentNo,
          hdrId: this.state.hdrId,
          type: "J",
          mukam: this.state.selectedMukam,
          vehicleTypeId: this.state.selectedVehicleType,
          vehicleQuantity: this.state.vehicleQuantiy,
          modBy: getCookie(cons.USERID),
          indentDate: this.dateChanged(),
          finnacialYear: this.state.year,
          unitConversion: this.state.unitConvertionName,
          status: status,
          branchId: this.state.branchId,
        },
        indentItems: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (
        this.state.selectedMukam !== "0" &&
        this.state.selectedMukam !== "" &&
        this.state.unitConvertion !== "" &&
        this.state.selectedVehicleType !== "" &&
        this.state.invoiceList.length >= 1 &&
        this.state.branchId !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    //   var obj=getMenuPermission(0);
    // console.log("obj",obj);
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          itemId: prop.juteType,
          dtlItemDesc: prop.juteName,
          itemGroupId: prop.grpId,
          qualityCode: prop.quality,
          deptId: prop.deptId,
          stock: prop.stockValue,
          indentQuantity: prop.quantity,
          unitId: prop.unit,
          noOfBales: prop.quantityType,
          finYear: getCookie(cons.COMPANY_ID),
        };
      });

      var data = {
        indentHeader: {
          type: "J",
          mukam: this.state.selectedMukam,
          vehicleTypeId: this.state.selectedVehicleType,
          vehicleQuantity: this.state.vehicleQuantity,
          submitter: getCookie(cons.USERID),
          indentDate: this.dateChanged(),
          finnacialYear: this.state.year,
          unitConversion: this.state.unitConvertionName,
          companyId: getCookie(cons.COMPANY_ID),
          branchId: this.state.branchId,
        },
        indentItems: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (
        this.state.selectedMukam !== "0" &&
        this.state.selectedMukam !== "" &&
        this.state.unitConvertion !== "" &&
        this.state.selectedVehicleType !== "" &&
        this.state.invoiceList.length >= 1 &&
        this.state.branchId !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_INDENT, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var sumOfQty = 0,
      quantityInItem = 0;
    var itemIndex = 0,
      quantity = 0,
      hasItem = false,
      indentItemId = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      indentItemId = this.state.indentItemId;
      quantityInItem = arr[itemIndex].quantityType;
      arr[itemIndex].quantityType = this.state.quantity;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
    arr.map((q) => {
      sumOfQty = sumOfQty + parseInt(q.quantityType);
      if (
        q.quality === this.state.selectedJuteQuality &&
        !this.state.updateItem
      )
        hasItem = true;
    });
    if (!this.state.updateItem) {
      sumOfQty = sumOfQty + parseInt(this.state.quantity);
    }
    if (this.state.unitConvertion.value === 1) {
      quantity =
        (parseInt(this.state.indentQuantity) * this.state.quantity) / 100;
    } else {
      quantity = Math.round(parseInt((this.state.quantity * 150) / 100));
    }

    console.log("quantity " + quantity);
    var addItemObj = {
      id: itemIndex,
      indentItemId: indentItemId,
      juteType: this.state.selectedJuteType,
      juteName: this.state.selectedJuteName,
      quality: this.state.selectedJuteQuality,
      qualityName: this.state.selectedQualityName,
      quantityType: this.state.quantity,
      quantity: quantity,
      unit: this.state.selectedUnit,
      deptId: this.state.selectedDeptId,
      grpId: this.state.selectedGrpId,
      stockValue: this.state.stock,
      actions: (
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    indentItemId: o.indentItemId,
                    updateItemAlert: true,
                    updateItem: true,
                    selectedJuteType: o.juteType,
                    selectedJuteName: o.juteName,
                    selectedJuteQuality: o.quality,
                    selectedQualityName: o.qualityName,
                    quantity: o.quantityType,
                    selectedUnit: o.unit,
                    selectedDeptId: o.deptId,
                    selectedGrpId: o.grpId,
                    stock: o.stockValue,
                    // alert:true,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({ deleteItem: true, id: o.id });

                  //  data.splice(i,1);
                  return true;
                }
              });
              this.setState({
                selectedJuteType: "",
                selectedJuteName: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                quantity: "",
                selectedUnit: "",
                selectedDeptId: "",
                selectedGrpId: "",
                stock: "",
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };

    if (!hasItem) {
      if (
        this.state.selectedJuteType !== "" &&
        this.state.selectedJuteQuality !== "" &&
        this.state.quantity !== "" &&
        this.state.quantity > 0
      ) {
        if (this.getConvertedQty() !== "") {
          if (sumOfQty <= parseInt(this.getConvertedQty())) {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);

            this.setState({
              hideJuteType: false,
              loading: true,
              sumOFQuantities: sumOfQty,
              canChangeMukam: false,
              invoiceList: arr,
            });
            this.hideAlert();
          } else {
            if (this.state.updateItem) {
              arr[itemIndex].quantityType = quantityInItem;
              this.setState({
                invoiceList: arr,
              });
            }
            this.basicAlert(
              "Quantity Should Not Exceed Converted Quantity..!!"
            );
          }
        } else {
          if (sumOfQty <= 100) {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);
            //console.log(arr.toString());

            this.setState({
              loading: true,
              invoiceList: arr,
              sumOFQuantities: sumOfQty,
            });
            this.hideAlert();
          } else {
            this.basicAlert("Quantity % can not exceed 100..!!");
          }
        }
      } else if (this.state.quantity == 0 || this.state.quantity < 0) {
        this.basicAlert(
          "Do not Enter Negative Values and Zero in Quantity..!!"
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This Quality. Please Select Another One..!!"
      );
    }
  }
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };
  getQuantityType = () => {
    var val = this.state.unitConvertion.value;
    if (val === 2) {
      return "BALE";
    } else {
      return "%";
    }
  };
  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };
  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    console.log("unitConversionName " + this.state.unitConvertion);
    //  var id="";
    console.log(this.state);
    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConvertion !== "" &&
      this.state.selectedVehicleType !== ""
    )
      this.setState({
        loading: true,
        alert: true,
      });
    else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  handleSelectedValues = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeUnit &&
      this.state.unitConversion !== defValue
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConvertion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert1: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      isCancel,
      isClose,
      hdrId,
      isReject,
      isApprove,
      srStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(this.state.srStatus);
    }
    if (isCancel) {
      this.setState({ isCancel: false });
      this.onApproveButtonClick("6");
    }
    if (isClose) {
      this.setState({ isClose: false });
      this.onApproveButtonClick("5");
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        8,
        fileName,
        hdrId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
      isCancel: false,
      isClose: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  render() {
    console.log("meuId ", getCookie(cons.MENU_ID));
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var valid = function(current) {
      if (isBackDate === "2") return current.isAfter(yesterday);
      else return true;
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Jute Type
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedJuteType"}
                                        defValue={selectedJuteType}
                                        onSelect={this.onSelect}
                                        getName={this.getName}
                                        stateKey="selectedJuteType"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.selectedMukam +
                                          "/getAllItemByMukam/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={selectedJuteType}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quality
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      {/* {(selectedJuteType >0) ?*/}
                                      <SelectboxTemp
                                        defValue={
                                          this.state.selectedJuteQuality
                                        }
                                        id={"selectedJuteQuality"}
                                        onSelect={this.onSelect}
                                        getName={this.getQualityName}
                                        stateKey="selectedJuteQuality"
                                        isRefresh={this.state.loadQuality}
                                        setIsRefresh={this.afterRefreshQuality}
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          selectedJuteType +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/getQualityByItem"
                                        }
                                        value={this.state.selectedJuteQuality}
                                      />
                                      {/*:<legend style={{color:"red",fontSize:"12px"}}>Select Jute Type To Display Qualities</legend>
                                                                            }*/}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} md={12}>
                              <br />
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.quantity}
                                        fullWidth={true}
                                        iType={"number"}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.getQuantityType()}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Indent No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="indentNo"
                            className={classes.textField}
                            value={this.state.indentNo}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Unit Conversion
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateView}
                          defValue={this.state.selectedJuteType}
                          name={"unitConvertion"}
                          value={this.state.unitConvertion}
                          onChange={this.handleSelectedValues}
                          options={options}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                              marginBottom: "10px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Mukam
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"selectedMukam"}
                          selectDisabled={this.state.updateView}
                          defValue={this.state.selectedMukam}
                          onSelect={this.onSelect}
                          isChangeValue={this.state.canChangeMukam}
                          stateKey="selectedMukam"
                          url={
                            serverApi.SEARCH_MUKAM + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.selectedMukam}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Indent Quantity
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          // disabled={true}
                          id="indentQuantity"
                          value={this.state.indentQuantity}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            inputProps: { min: 0, max: 10 },
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  QTL
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          //  disabled={true}
                          id="indentQuantityTon"
                          value={this.getIndentQtyTon()}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            style: { height: 30, color: "#000" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Ton
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Converted Quantity
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="indentBales"
                          disabled={true}
                          value={this.getConvertedQty()}
                          className={classes.textField}
                          //  onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={{
                            style: { height: 30, color: "#000" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  BALES
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.invoiceList}
                          loading={this.state.loading}
                          disableActions={
                            this.state.indentStatus === "3" ||
                            this.state.indentStatus === "4" ||
                            this.state.indentStatus === "5" ||
                            this.state.indentStatus === "6"
                          }
                          columns={[
                            {
                              Header: "Jute Type",
                              accessor: "juteName",
                              minWidth: 60,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Quality",
                              accessor: "qualityName",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: this.getQuantityType(),
                              accessor: "quantityType",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Quantity",
                              accessor: "quantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Unit",
                              accessor: "unit",
                              width: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Stock.",
                              accessor: "stockValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{ fontSize: "12px", color: "#003F66" }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "red",
                                }}
                              >
                                Note:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  fontStyle: "italic",
                                }}
                              >
                                {"Unit & Mukam can't modified"}
                              </span>
                            </ItemGrid>
                            {this.state.approveButton ? (
                              <ItemGrid xs={6}>
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              </ItemGrid>
                            ) : null}
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          {this.state.indentStatus !== "3" &&
                          this.state.indentStatus !== "4" &&
                          this.state.indentStatus !== "5" &&
                          this.state.indentStatus !== "6" ? (
                            <Button
                              color={"info"}
                              right={true}
                              onClick={this.handleAddItem}
                            >
                              Add Item
                            </Button>
                          ) : null}
                        </ItemGrid>
                      )}
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView &&
                  this.state.indentStatus !== "4" &&
                  this.state.indentStatus !== "3" ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveButton ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onApproveButtonClick(
                                this.state.indentStatus
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveButton ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={this.onRejectButtonClick}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.updateView &&
                        this.state.indentStatus !== "3" &&
                        this.state.indentStatus !== "4" &&
                        this.state.indentStatus !== "5" &&
                        this.state.approveStatus) ||
                      (this.state.updateView &&
                        this.state.indentStatus === "1") ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onApproveButtonClick("");
                            }}
                          >
                            update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.indentStatus === "3" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isCancel: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Cancel This Indent..!!"
                              );
                              //this.onApproveButtonClick("6")
                            }}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.indentStatus === "3" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isClose: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Close This Indent..!!"
                              );
                              //this.onApproveButtonClick("6")
                            }}
                          >
                            Close
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>

                  {/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}> <SamplePDF printDocument={this.printDocument.bind(this)}/> </ItemGrid>:null}
*/}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.basicAlert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Mukam
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"mukamId"}
                                defValue={this.state.mukamId}
                                onSelect={this.onSelect}
                                stateKey="mukamId"
                                url={
                                  serverApi.SEARCH_MUKAM +
                                  getCookie(cons.COMPANY_ID)
                                }
                                value={this.state.mukamId}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"status"}
                                defValue={this.state.status}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {/*
                                            <ReactTable
                                                data={ [{
                                                    "poNo": "100",
                                                    "poDate": "09-05-2019",
                                                    "supplierName": "HARNATA RAI BANARASILAL & SONS",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "BIG",
                                                    "vehicleQty": "2",
                                                    "broker": "HARINATHRAI BANARASLAL & SONS",
                                                    "juteUnit": "JUTE",
                                                    "weight": "150.0",
                                                    "poValue": "151000.0",
                                                    "status": "OPEN"
                                                }, {
                                                    "poNo": "102",
                                                    "poDate": "07-05-2019",
                                                    "supplierName": "S.S RUNGATA & CO.",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "LARGE",
                                                    "vehicleQty": "1",
                                                    "broker": "Select....",
                                                    "juteUnit": "JUTE",
                                                    "weight": "25.0",
                                                    "poValue": "111000.0",
                                                    "status": "REJECTED"
                                                }, {
                                                    "poNo": "101",
                                                    "poDate": "07-05-2019",
                                                    "supplierName": "HARNATA RAI BANARASILAL & SONS",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "LARGE",
                                                    "vehicleQty": "1",
                                                    "broker": "",
                                                    "juteUnit": "JUTE",
                                                    "weight": "18.0",
                                                    "poValue": "54000.0",
                                                    "status": "OPEN"
                                                }, {
                                                    "poNo": "150",
                                                    "poDate": "07-05-2019",
                                                    "supplierName": "HARNATA RAI BANARASILAL & SONS",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "MEDIUM",
                                                    "vehicleQty": "1",
                                                    "broker": "HARINATHRAI BANARASLAL & SONS",
                                                    "juteUnit": "JUTE",
                                                    "weight": "20.0",
                                                    "poValue": "90100.0",
                                                    "status": "APPROVED"
                                                }, {
                                                    "poNo": "120",
                                                    "poDate": "07-05-2019",
                                                    "supplierName": "HARNATA RAI BANARASILAL & SONS",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "LARGE",
                                                    "vehicleQty": "1",
                                                    "broker": "",
                                                    "juteUnit": "JUTE",
                                                    "weight": "15.0",
                                                    "poValue": "15000.0",
                                                    "status": "OPEN"
                                                }, {
                                                    "poNo": "1112",
                                                    "poDate": "06-05-2019",
                                                    "supplierName": "HARNATA RAI BANARASILAL & SONS",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "BIG",
                                                    "vehicleQty": "5",
                                                    "broker": "HARINATHRAI BANARASLAL & SONS",
                                                    "juteUnit": "JUTE",
                                                    "weight": "100.0",
                                                    "poValue": "800500.0",
                                                    "status": "APPROVED"
                                                }, {
                                                    "poNo": "1134",
                                                    "poDate": "07-05-2019",
                                                    "supplierName": "HARNATA RAI BANARASILAL & SONS",
                                                    "mukam": "ISLAMPUR",
                                                    "vehicleType": "LARGE",
                                                    "vehicleQty": "1",
                                                    "broker": "",
                                                    "juteUnit": "JUTE",
                                                    "weight": "18.0",
                                                    "poValue": "54000.0",
                                                    "status": "OPEN"
                                                }]}
                                                filterable
                                                // resizable={false}
                                                style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}
                                                columns={[{
                                                    "filterable": "true",
                                                    "Header": "PO No",
                                                    "id":"poNo",
                                                    "accessor": d=>Number(d.poNo),
                                                    // "sortFunction": (a, b) => a > b ? 1 : -1,

                                                    "minWidth": 90,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "PO Date",
                                                    "id":'poDate',
                                                    "accessor":  data => moment.utc(data.poDate).format('DD-MM-YYYY') ,
                                                    "minWidth": 90,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Supplier Name",
                                                    "accessor": "supplierName",
                                                    "minWidth": 200,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Mukam",
                                                    "accessor": "mukam",
                                                    "minWidth": 150,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Vh. Type",
                                                    "accessor": "vehicleType",
                                                    "minWidth": 80,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Vh. Qty",
                                                    "accessor": "vehicleQty",
                                                    "minWidth": 50,
                                                    "style": {
                                                        "textAlign": "center"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Broker",
                                                    "accessor": "broker",
                                                    "minWidth": 150,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Weight",
                                                    "accessor": "weight",
                                                    "minWidth": 80,
                                                    "style": {
                                                        "textAlign": "right"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "PO Val",
                                                    "accessor": "poValue",
                                                    "minWidth": 100,
                                                    "style": {
                                                        "textAlign": "right"
                                                    },
                                                    "sortable": "true"
                                                }, {
                                                    "filterable": "true",
                                                    "Header": "Status",
                                                    "accessor": "status",
                                                    "minWidth": 100,
                                                    "style": {
                                                        "textAlign": "left"
                                                    },
                                                    "sortable": "true"
                                                }]}
                                                defaultPageSize={5}
                                                //showPaginationTop
                                                showPaginationBottom
                                                className="-striped -highlight"
                                            >

                                            </ReactTable>
*/}

                      <TableComponent
                        url={serverApi.DATATABLE_SERVICE_URL + "getAllIndent"}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"jute_indent_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          this.state.indentNo +
          "/" +
          getCookie(cons.USERID) +
          "/getIndentDetails/" +
          getCookie(cons.CIPHER),
        this.handlePrintResp
      );

      //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
    }
  }
  handlePrintResp = (resp) => {
    var printHeaderValues = resp.indentHeader;
    var modules = [];
    var lineItems = resp.indentItems;
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var obj = {
          srNo: key + 1,
          itemId: prop.itemId,
          itemIdDesc: prop.itemIdDesc,
          unitId: prop.unitId,
          stockquantity: prop.stock,
          avgQty: " ",
          indentQuantity: prop.indentQuantity,
          deptId: prop.deptIdDesc,
          pono: " ",
          rate: prop.rate + " ",
          supName: " ",
          clmn12: " ",
          clmn13: " ",
          clmn14: " ",
          clmn15: " ",
          clmn16: " ",
          orderNo: " ",
          ctrlStock: " ",
          deliveryStatus: "15 days",
        };
        modules.push(obj);
      });
    }
    console.log("lineitems", modules);
    var printTotalvalues = resp.totalValues;
    // this.setState({
    //     printHeaderValues:header,
    //     printLineItems:printLineItems,
    //     printTotalvalues:totalvalues,
    // })

    // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
    //     var printHeaderValues=this.state.printHeaderValues;
    // }
    var pageNo = 1;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.indentDateDesc;
    mr_date = mr_date + " ";
    var poId = printHeaderValues.id;
    poId = poId + " ";
    var challan_no = printHeaderValues.challanNo;
    challan_no = challan_no + " ";
    var challan_date = printHeaderValues.challanDate;
    challan_date = challan_date + " ";
    var vehicleNo = printHeaderValues.lorryNo;
    vehicleNo = vehicleNo + " ";
    var SupplierName = printHeaderValues.supplierName;
    SupplierName = SupplierName + " ";
    var brokerName = printHeaderValues.brokerName;
    brokerName = brokerName + " ";
    var frightPaid = printHeaderValues.frightPaid;
    frightPaid = frightPaid + " ";
    var gateEntryDate = printHeaderValues.gateEntryDate;
    gateEntryDate = gateEntryDate + " ";
    var mukamName = printHeaderValues.mukamName;
    mukamName = mukamName + " ";

    var companyName = getCookie(cons.COMPANY_NAME);
    var address1 =
      printHeaderValues.agentName !== ""
        ? printHeaderValues.agentAddress1 + " "
        : printHeaderValues.cAddress1;
    var address2 =
      printHeaderValues.agentName !== ""
        ? printHeaderValues.agentAddress2 + " "
        : printHeaderValues.cAddress2;

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt == 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt == 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt == 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt == 3) {
      printcopy = "Triplicate";
    } else if (printCounnt == 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " " + printCounnt;
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    doc.text(companyName, xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    /* doc.text(190, 60, address1+'');
         doc.text(250, 80, address2+'');
         doc.setFontSize(9);
        */
    doc.setFontType("bold");
    doc.text(30, 80, "JUTE REPORT NO");
    doc.text(120, 80, ":");
    doc.setFontType("normal");
    doc.text(130, 80, mrid);
    doc.setFontType("bold");
    doc.text(400, 120, "INDENT DATE : ");
    doc.setFontType("normal");
    doc.text(480, 120, mr_date);
    doc.setFontType("bold");
    doc.text(30, 120, "INDENT NO");
    doc.text(120, 120, ":");
    doc.setFontType("normal");
    doc.text(130, 120, poId);
    var srno = doc.splitTextToSize("Srl no", 20);
    doc.cell(10, 150, 20, 40, srno, "", "C");
    var itemCode = doc.splitTextToSize("Item Code", 30);
    doc.cell(30, 150, 30, 40, itemCode, "", "C");
    var description = doc.splitTextToSize("Description", 50);
    doc.cell(90, 150, 70, 40, description, "", "C");
    var unit = doc.splitTextToSize("Unit", 50);
    doc.cell(180, 150, 20, 40, unit, "", "C");
    var stockqty = doc.splitTextToSize("Stock/Appr Qty", 30);
    doc.cell(220, 150, 40, 40, stockqty, "", "C");
    var avg = doc.splitTextToSize("Avg Cons Last Mth", 40);
    doc.cell(260, 150, 40, 40, avg, "", "C");
    var indentQty = doc.splitTextToSize("Indent Quantity", 35);
    doc.cell(290, 150, 40, 40, indentQty, "", "C");
    var dept = doc.splitTextToSize("Dept", 40);
    doc.cell(310, 150, 25, 40, dept, "", "C");
    doc.cell(340, 150, 100, 20, "       Previous Details     ", "", "C");
    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.setFontSize(9);
    doc.setFontType("normal");

    var pono = doc.splitTextToSize("PO No/Dt", 30);

    doc.cell(295, 170, 30, 20, pono, "", "C");
    doc.cell(320, 170, 30, 20, "Rate", "", "C");
    var supName = doc.splitTextToSize("Supplier Name", 36);

    doc.cell(350, 170, 40, 20, supName, "", "C");
    doc.cellInitialize();
    doc.setFontSize(10);
    doc.setFontType("normal");
    doc.cell(395, 150, 100, 20, "          Comp Status          ", "", "C");
    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.setFontSize(9);
    doc.setFontType("normal");
    doc.cell(395, 170, 20, 20, "<12>", "", "C");
    doc.cell(420, 170, 20, 20, "<13>", "", "C");
    doc.cell(450, 170, 20, 20, "<14>", "", "C");
    doc.cell(470, 170, 20, 20, "<15>", "", "C");
    doc.cell(490, 170, 20, 20, "<16>", "", "C");
    doc.cellInitialize();
    doc.setFontSize(10);
    doc.setFontType("normal");
    var order = doc.splitTextToSize("Order No", 30);
    doc.cell(495, 150, 30, 40, order, "", "C");
    var cntrl = doc.splitTextToSize("Cntrl Stock", 30);
    doc.cell(525, 150, 30, 40, cntrl, "", "C");
    var delivery = doc.splitTextToSize("Delivery Status", 38);
    doc.cell(555, 150, 36, 40, delivery, "", "C");

    var cellWidths = [
      "",
      20,
      30,
      70,
      20,
      40,
      40,
      40,
      25,
      30,
      30,
      40,
      20,
      20,
      20,
      20,
      20,
      30,
      30,
      36,
    ];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "left",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 165;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 165;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var srno = doc.splitTextToSize("Srno", 20);
          doc.cell(10, 150, 20, 40, srno, "", "C");
          var itemCode = doc.splitTextToSize("Item Code", 30);
          doc.cell(30, 150, 30, 40, itemCode, "", "C");
          var description = doc.splitTextToSize("Description", 50);
          doc.cell(90, 150, 70, 40, description, "", "C");
          var unit = doc.splitTextToSize("Unit", 50);
          doc.cell(180, 150, 20, 40, unit, "", "C");
          var stockqty = doc.splitTextToSize("Stock/Appr Qty", 30);
          doc.cell(220, 150, 40, 40, stockqty, "", "C");
          var avg = doc.splitTextToSize("Avg Cons Last Mth", 40);
          doc.cell(260, 150, 40, 40, avg, "", "C");
          var indentQty = doc.splitTextToSize("Indent Quantity", 35);
          doc.cell(290, 150, 40, 40, indentQty, "", "C");
          var dept = doc.splitTextToSize("Dept", 40);
          doc.cell(310, 150, 25, 40, dept, "", "C");
          doc.cell(340, 150, 100, 20, "       Previous Details     ", "", "C");
          //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.setFontSize(9);
          doc.setFontType("normal");

          var pono = doc.splitTextToSize("PO No/Dt", 30);

          doc.cell(295, 170, 30, 20, pono, "", "C");
          doc.cell(320, 170, 30, 20, "Rate", "", "C");
          var supName = doc.splitTextToSize("Supplier Name", 36);

          doc.cell(350, 170, 40, 20, supName, "", "C");
          doc.cellInitialize();
          doc.setFontSize(10);
          doc.setFontType("normal");
          doc.cell(
            395,
            150,
            100,
            20,
            "          Comp Status          ",
            "",
            "C"
          );
          //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.setFontSize(9);
          doc.setFontType("normal");
          doc.cell(395, 170, 20, 20, "<12>", "", "C");
          doc.cell(420, 170, 20, 20, "<13>", "", "C");
          doc.cell(450, 170, 20, 20, "<14>", "", "C");
          doc.cell(470, 170, 20, 20, "<15>", "", "C");
          doc.cell(490, 170, 20, 20, "<16>", "", "C");
          doc.cellInitialize();
          doc.setFontSize(10);
          doc.setFontType("normal");
          var order = doc.splitTextToSize("Order No", 30);
          doc.cell(495, 150, 30, 40, order, "", "C");
          var cntrl = doc.splitTextToSize("Cntrl Stock", 30);
          doc.cell(525, 150, 30, 40, cntrl, "", "C");
          var delivery = doc.splitTextToSize("Delivery Status", 38);
          doc.cell(555, 150, 36, 40, delivery, "", "C");
          doc.setFontSize(7);
        }
        var mytext = "Max Quantity :30.00    Min Quantity: 15.00";

        var a = 0;
        return [
          Object.keys(prop).map((key, index) => {
            a = a + 1;
            var size = 30;

            /*    if(key==='itemCode'){
                                var size=90;
                            }else{
                                var size=45;
                            }*/
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
            // }

            return doc.cell(
              10,
              k,
              cellWidths[a],
              25,
              myItem,
              2,
              cellTextaligns[a]
            );
          }),

          // doc.text(30, k+10, mytext)-+-
        ];
      });
    }
    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(10, k + 25, 20, 30, " ", "", "right");

    doc.cell(30, k + 25, 30, 30, " ", "", "right");
    doc.cell(90, k + 25, 70, 30, " ", "", "right");
    doc.cell(180, k + 25, 20, 30, " ", "", "right");
    doc.cell(220, k + 25, 40, 30, " ", "", "right");
    doc.cell(260, k + 25, 40, 30, " ", "", "right");
    doc.cell(290, k + 25, 40, 30, " ", "", "right");
    doc.cell(310, k + 25, 25, 30, " ", "", "right");
    doc.cell(340, k + 25, 30, 30, " ", "", "right");
    doc.cell(295, k + 25, 30, 30, " ", "", "right");
    doc.cell(320, k + 25, 40, 30, " ", "", "right");
    doc.cell(350, k + 25, 20, 30, " ", "", "right");
    doc.cell(395, k + 25, 20, 30, " ", "", "right");
    doc.cell(420, k + 25, 20, 30, " ", "", "right");
    doc.cell(450, k + 25, 20, 30, " ", "", "right");
    doc.cell(490, k + 25, 20, 30, " ", "", "right");
    doc.cell(495, k + 25, 30, 30, " ", "", "right");
    doc.cell(525, k + 25, 30, 30, " ", "", "right");
    doc.cell(555, k + 25, 36, 30, " ", "", "right");

    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(30, k + 150, "Checked By");

    doc.text(200, k + 150, "Store Signatory");
    doc.text(350, k + 150, "Authorised Signatory");
    doc.text(30, k + 170, "Note For Head Office");
    doc.setFontType("normal");
    doc.text(
      30,
      k + 190,
      "1. This indent copy is made only for office use, No other outside persons are entitled to see that."
    );
    doc.text(
      30,
      k + 210,
      "2. From column No. 1 to 11 to be filled from computer ot stores department and balance 12 to 18 are kept blank"
    );
    doc.text(
      50,
      k + 230,
      "<a> 12 to 17 for rate comparison <b> 18 for writing order no after placing to supplier"
    );
    doc.text(
      30,
      k + 250,
      "3. Pl also make sure that order item code & unit should be tallyed with Indent"
    );
    doc.text(
      30,
      k + 270,
      "4. Pl also give indent & indent Srl No on order. This will help a lot for Stores job processing"
    );
    doc.text(
      30,
      k + 290,
      "5.Column No 19 is showing Central Store Stock. So,Please see carefully before sending indent to HO"
    );
    doc.text(
      30,
      k + 310,
      "3. Option available a) General Indent   b) O/H Indent   c) Open Indent"
    );

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 350, newdat);
    doc.text(500, k + 350, printBy);
    addWaterMark(doc, printcopy);

    doc.save("Indent_" + mrid + ".pdf");
  };
}
const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(JuteIndent);
