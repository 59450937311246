import React from "react";
// react component for creating dynamic tables.
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {configNames, getConfigValue, serverApi, serverConfig} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, getFileDownload, requestList, uploadFile, uploadInvoice} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import {getMenuPermission, IsTallyAvailable} from "../CustumComponents/PermissionAlert";
import {TCSPercentages, yesOrNo} from "../TableData";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import TallyIcon from "../../assets/img/tally_icon.png";
import TallyRedIcon from "../../assets/img/tally_red.png";

class Bills extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            billPassNo: "",
            filesList: [],
            file: null,
            fileName: "No file Chosen",
            isRefresh: false,
            isLoading: false,
            itemViewType: "billNo",
            //itemViewType: "mrNo",
            approveStatus: false,
            updateView: false,
            statusDesc: "",
            year: getCookie(cons.ACADEMIC_YEAR),
            alert: false,
            invoiceList: [],
            lineItemId: '0',
            billPassStatus: "",
            form_supplierId: '',
            billPassDate: moment().format('DD-MM-YYYY'),
            taxType: "",
            totalAmount: "",
            netTotal: "",
            invoiceNo: "",
            invoiceDate: "",
            paymentDueDate: "",
            invoiceAmount: "",
            invoiceReceivedDate: "",
            quantity: "",
            costCenterId: "",
            costCenter: "",
            taxId: "",
            uom: "",
            amount: "",
            rate: "",
            description: "",
            tdsPayable: "N",
            tdsReason: "",
            tdsAmount: "",
            branchId:"",
            tcsPercentage:"",tcsAmount:"",fileType:""

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    componentDidMount() {
        this.getList();
    }
    getList=()=>{
        requestList(serverApi.ACCOUNT_BILLS_TABLE,this.getData(),this.handleList)
    };
    handleList=(resp)=>{
        var list=resp;
        if( IsTallyAvailable("Tally Sync")){
            list.column.push({Header:"Tally",accessor:"tally",sortable:false,filterable:false,minWidth:100});
            list.data=resp.data.map((prop,i)=>{
                var obj={...prop,
                    tally:(
                        <div className="actions-right">
                            {prop.tallySync==="Y"?<img src={TallyIcon} color={"danger"} width={50} height={50}/>:null}
                            {prop.tallySync==="R"?<img src={TallyRedIcon} width={50} height={50} style={{cursor:"pointer"}} onClick={()=>{
                                this.showMessage(prop.tallyMessage,false)
                            }}/>:null}
                        </div>
                    )}
                return obj;
            });
        }

        if(resp){
            this.setState({tableList:list,isRefresh:true})
        }
    };
    handleSeacrh = () => {
        var loading = false;
        if (!this.state.isRefresh) {
            this.getList();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });
    };
    getDefaultSideConvertion = (name) => {
        var val = 0, label = "Select..", data = "";
        if (name !== null && name !== 0 && name !== '') {
            if (name === 1) {
                val = 1;
                label = "IGST";
            }else if(name===2) {
                val = 2;
                label = 'CGST & SGST';
            }else {
                val = 3;
                label = 'VAT';
            }
            data = {
                value: val, label: label
            };
        }
        return data;
    };

    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            getDataFromUrl(serverApi.ACCOUNT_BILLS_BY_ID + id + '/O/' + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.ACADEMIC_YEAR) + "/" + getCookie(cons.USERID), this.handleViewClickResp)
        }
    };
    handleViewClickResp = (resp) => {
        this.setState({printResp: resp});

        var header = resp.billPass;
        var list = resp.billPassLineItem;
        var indentlist = [], filesList = [], totalValue = 0;
        var sum_of_tax = 0, sumOfamt = 0, taxType = 0;
        if (resp.billDocsUrl !== null && resp.billDocsUrl !== undefined) {
            filesList = resp.billDocsUrl
        }
        if (list !== null)
            indentlist = list.map((prop, key) => {
                var itemAmount = parseFloat(prop.amount);
                var taxPercentage = 0;
                if (prop.igstPercentage > 0) {
                    taxType = 1;
                    taxPercentage = prop.igstPercentage
                } else {
                    taxType = 2;
                    taxPercentage = prop.cgstPercentage + prop.sgstPercentage
                }
                var itemtaxAmount = itemAmount * parseFloat(taxPercentage) / 100;//11 is tax percent
                console.log("taxamt", itemtaxAmount);
                //  itemtaxAmount = Math.round(itemtaxAmount);
                prop.taxamount = itemtaxAmount;
                sum_of_tax = sum_of_tax + itemtaxAmount;
                if (itemAmount > 0)
                    sumOfamt = sumOfamt + itemAmount;
                return (
                    {

                        id: key,
                        lineItemId: prop.id,
                        costCenter: prop.costCenter,
                        costCenterId: prop.costCenterId + "",
                        description: prop.description,
                        quantity: prop.qty,
                        uom: prop.uom,
                        rate: parseFloat(prop.rate).toFixed(2),
                        amount: parseFloat(prop.amount).toFixed(2),
                        isgst: prop.igstPercentage,
                        ssgst: prop.sgstPercentage,
                        csgst: prop.cgstPercentage,
                        taxId: prop.taxId + "",
                        taxPercentage: taxPercentage,
                        taxamount: itemtaxAmount,
                        isActive: prop.isActive,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to remove the data row */}
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === key) {
                                                // alert(o.qualityId);
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                //  data.splice(i,1);
                                                if (this.state.form_supplierId !== '0' && this.state.taxType.value > 0) {
                                                    this.basicItemAlert("Do You Like To Update This Item?");
                                                    this.setState({
                                                        //alert: true,
                                                        updateItem: true,
                                                        updateItemAlert: true,
                                                        id: o.id,
                                                        costCenter: o.costCenter,
                                                        costCenterId: o.costCenterId,
                                                        description: o.description,
                                                        quantity: o.quantity,
                                                        uom: o.uom,
                                                        rate: o.rate,
                                                        amount: o.amount,
                                                        lineItemId: o.lineItemId,
                                                        isgst: o.igstPercent,
                                                        ssgst: o.sgstPercent,
                                                        csgst: o.cgstPercent,
                                                        taxId: o.taxId,
                                                        taxPercentage: o.taxPercentage,
                                                        itemTaxAmt: o.taxamount,
                                                    });
                                                } else {
                                                    this.basicAlert("Please Enter All Mandatory Fields..!!");
                                                }

                                                return true;
                                            }
                                        });

                                    }}
                                    color="primaryNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === key) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                this.setState({
                                                    deleteItem: true,
                                                    id: o.id,
                                                });
                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                return true;
                                            }
                                        });
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Close/>
                                </IconButton>{" "}
                            </div>

                        )
                    }
                )
            });
        // var invoiceUrls=resp.billDocsUrl;

        // console.log("indentWeight " + header.indentWeight);
        if (header !== null) {
            //  alert(header.agentId);
            this.setState({
                updateView: true,
                isCreated: true,
                approveStatus: header.approveStatus,
                billId: header.id,
                billPassNo: header.billNo,
                billPassDate: header.billPassDate,
                statusDesc: header.statusDesc,
                //  mrDate: header.goodReceiptDateDesc,
                form_supplierId: header.supplierCode,
                // year: header.finnacialYear,
                createdBy: header.createdBy,
                createdOn: header.createDateDesc,
                mukamId: header.mukamId,
                totalAmount: header.billAmount,
                //  frieghtCharges:header.frieghtCharges,
                invoiceList: indentlist,
               // filesList: filesList,
                // challanList:header.billChallanUrl,
                billPassStatus: header.status,
                invoiceNo: header.invoiceNo,
                invoiceDate: header.invoiceDateDesc,
                paymentDueDate: header.paymentDueDateDes,
                invoiceAmount: header.invoiceAmount,
                invoiceReceivedDate: header.invoiceRecDateDes,
                //claimAmount:header.claimAmount,
                netTotal: header.netAmount,
                taxType: this.getDefaultSideConvertion(header.taxType),
                tdsPayable: header.tdsPayable !== null ? header.tdsPayable : "N",
                tdsReason: header.tdsReason,
                tdsAmount: header.tdsAmount,
                branchId:header.branchId,
                tcsPercentage:header.tcsPercentage,
                tcsAmount:header.tcsAmount!==null?parseFloat(header.tcsAmount).toFixed(2):0.00,
                roundoff:header.roundOff
            });
            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"2/"+header.id+"/"+getCookie(cons.COMPANY_ID),this.handleInvoiceFiles);

        }


    };
    handleInvoiceFiles=(resp)=>{
        if(resp.status){
            this.setState({filesList:resp.data})
        }
    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    getAgentName = (name) => {
        this.setState({
            agentName: name,
        });
    };
    getitemDetails = (selectedValue) => {
        var values = selectedValue;
        //  values = values.split("^");
        this.setState({
            costCenter: selectedValue
        });
        // console.log("indentQuantity " + this.state.indentQuantity);

    };
    afterReloadSelect = (val) => {

        this.setState({
            reloadSelect: val
        });
    };
    afterRefreshquality = (val) => {

        this.setState({
            loadquality: val
        });
    };
    getQualityDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            quality: values[0],
            stock: values[1],
            rate: values[2],
            lastPurchase: values[3],
            allowableMoisture: values[4],
        });
        console.log("indentQuantity " + this.state.stock);

    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({

            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    handleTableLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }
    getData = () => {
        var data = {
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate,
            "type": "O",
            "status": this.state.status,
            "companyId": getCookie(cons.COMPANY_ID),
            "financialYear": this.state.year,
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id]: ''
            });

        //  this.dateChanged();
    }
    dateChanged = (date) => {
        var myDate = date;
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    };


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected " + event.target.value);
        this.setState({[event.target.name]: event.target.value});
    };
    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    getConvertedQty = () => {
        if (this.state.unitConvertion.value === 2) {
            console.log("indentQuantity  " + this.state.indentQuantity);
            return Math.round(parseFloat(parseFloat((this.state.indentQuantity * 100) / 150)));
        } else {
            return "";
        }
    };
    getIndentQtyTon = () => {
        var val = this.state.indentQuantity;
        if (val !== "") {
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return parseFloat(parseFloat(val / 10).toFixed(2));
        } else {
            return "";
        }
    };
    getIndentQty = () => {
        var val = this.state.vehicleQuantity;
        console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
        if (val !== "" && this.state.indentQuantity !== "") {
            return parseInt(val) * parseInt(this.state.selectedIndentQuantity)
        } else {
            return "";
        }
    };


    onChangeValues = (event) => {
        var value = event.target.value;


        if (event.target.id === "rate" && this.state.quantity !== "" && this.state.quantity !== null) {

            console.log("val " + value);
            if (value !== null && value !== "") {
                var Calrate = this.state.quantity;
                var amount = parseFloat(Calrate) * parseFloat(value);
                this.setState({
                    amount: amount.toFixed(2)
                });

            } else {
                this.setState({
                    amount: 0
                });
            }
        }
        if (event.target.id === "quantity" && this.state.rate !== "" && this.state.rate !== null) {

            console.log("val " + value);
            if (value !== null && value !== "") {
                var Calrate = this.state.rate;
                var amount = parseFloat(Calrate) * parseFloat(value);
                this.setState({
                    amount: amount.toFixed(2)
                });

            } else {
                this.setState({
                    amount: 0
                });
            }
        }

        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        var type = this.state.selectedJuteType;
        /*  this.setState({
              [stateKey]: selectedValue,
          });*/
        if (stateKey === 'suppielr_id') {
            this.setState({
                loadGateEntry: true
            })

        }
        if (stateKey === 'item') {
            this.setState({
                loadquality: true
            })
        }
        if(stateKey==="tcsPercentage"){
            var total=parseFloat(this.getTotalTax("cgst"))+parseFloat(this.getTotalTax("sgst"))+parseFloat(this.getTotalTax("igst"))+parseFloat(this.state.totalAmount)
            var tcsAmount=parseFloat(total)*selectedValue/100;
            this.setState({tcsAmount:Math.round(tcsAmount).toFixed(2)})
        }

        if (stateKey === 'selectedJuteType' && selectedValue > 0 && selectedValue !== type) {
            this.setState({
                hideJuteType: true
            });
        }
        if (stateKey === 'selectedMukam' && this.state.invoiceList.length > 0 && !this.state.canChangeMukam) {
            this.unitConvertionAlert("Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!")
        } else if (stateKey === 'selectedMukam')
            this.setState({
                [stateKey]: selectedValue,
                canChangeMukam: false
            });
        else
            this.setState({
                [stateKey]: selectedValue
            });
    };
    afterRefreshGateEntry = (val) => {
        this.setState({
            loadGateEntry: val
        });
    };
    getGateEntryDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            form_poNO: values[0],
            poDate: values[1],
            challanNo: values[2],
            challanDate: values[3],
            form_vehicleNo: values[4],
        });
        // console.log("indentQuantity " + this.state.stock);

    };
    getName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            selectedJuteName: values[0],
            selectedGrpId: values[1],
            selectedUnit: values[2],
            selectedDeptId: values[3],
        });
    };

    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            isReject:false,isApprove:false
        });

    }

    handleDeleteInvoiceResp = (resp) => {
        if (resp.status) {
            if (this.state.deleteChallan) {
                var arr = this.state.challanList;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    challanList: arr,
                    deleteChallan: false,
                    fileId: ""
                });
            } else {
                var arr = this.state.filesList;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    filesList: arr,
                    deleteInvoice: false,
                    fileId: ""
                });
            }
        }
        console.log("resp", JSON.stringify(resp));

    };

    handleInvoiceResp = (resp) => {
        console.log("file resp",resp);
        var data = resp;
        if (resp.status) {

//            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;
            if (this.state.uploadChallan) {
                var arr = this.state.challanList;
                data={"fileUploadId": data.id,
                    "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:3};
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadChallan: false,
                    challanList: arr,
                    basicAlert: null
                });
            } else {
                var arr = this.state.filesList;
                data={"fileUploadId": data.id,
                    "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:2};
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadInvoice: false,
                    filesList: arr,
                    basicAlert: null
                });
            }

        }
        console.log("resp", JSON.stringify(resp));

    };

    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,billId,isReject,isApprove,billPassStatus}=this.state;
        console.log("isApprove",isApprove);
        if(isReject){
            this.setState({isReject:false});
            this.onUpdateButtonClick("4")
        }
        if(isApprove){
            this.setState({isApprove:false});
            this.onUpdateButtonClick(billPassStatus)
        }
        if (uploadInvoice) {
            var id = this.state.billId;
            uploadFile(fileName,file,fileType,2,fileName,billId, this.handleInvoiceResp);

        }
        if (this.state.deleteInvoice) {
            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }

        this.setState({
            basicAlert: null,
        });

    }


    hideAlert() {

        console.log("alert");
        this.setState({
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            quantity: "",
            costCenterId: "",
            costCenter: "",
            taxId: "",
            uom: "",
            amount: "",
            rate: "",
            description: "",
            taxPercentage: "",
            itemTaxAmt: "",
            igstPercent: "",
            cgstPercent: "",
            sgstPercent: ""
        });
    }

    //log

    hideAlertMessage() {
        if(this.state.isCreated){
            this.getList();
        }
        this.setState({
            billPassStatus: "",
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            createdBy: "",
            createdOn: "",
            invoiceList: [],
            totalAmount: '',
            form_supplierId: '',
            billPassDate: moment().format('DD-MM-YYYY'),
            taxType: "",
            netTotal: "",
            invoiceNo: "",
            invoiceDate: "",
            paymentDueDate: "",
            invoiceAmount: "",
            invoiceReceivedDate: "",
            costCenterId: "",
            costCenter: "",
            taxId: "",
            uom: "",
            amount: "",
            rate: "",
            description: "",
            tdsPayable: "N",
            tdsReason: "",
            tdsAmount: "",
            branchId:"",
            tcsPercentage:"",tcsAmount:"",
            fileType:"",
            fileName:"",
            roundoff:null
        });
    }

    dateChanged = (myDate) => {
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    };

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }

    basicAlert(msg) {

        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() =>{
                        this.setState({
                            basicAlert1: null,
                        });

                    }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: []

        });

    }


    unitConvertionAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    //warning
                    showCancel
                    style={{
                        display: "block",
                        marginTop: "-100px",
                        marginLeft: "-80px",
                        fontSize: "8px",
                        color: "black"
                    }}
                    title={msg}
                    onConfirm={() => this.hideUnitConvertionAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnText={"Yes"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    onUpdateButtonClick = (status) => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {billId, billPassNo, invoiceList, billPassDate, form_supplierId, totalAmount, taxType, filesList, netTotal, invoiceNo,billPassStatus,
                invoiceDate, paymentDueDate, invoiceAmount, invoiceReceivedDate, tdsPayable, tdsReason, tdsAmount,branchId,
                tcsPercentage,tcsAmount,roundoff} = this.state;
            var invoiceList = invoiceList.map((prop, key) => {
                var cgstAmount = parseFloat(prop.amount) * parseFloat(prop.csgst) / 100,
                    sgstAmount = parseFloat(prop.amount) * parseFloat(prop.ssgst) / 100,
                    igstAmount = parseFloat(prop.amount) * parseFloat(prop.isgst) / 100;
                return ({
                    "id": prop.lineItemId,
                    "costCenterId": prop.costCenterId,
                    "costCenter":prop.costCenter,
                    "description": prop.description,
                    "rate": prop.rate,
                    "qty": prop.quantity,
                    "amount": prop.amount,
                    "taxId": prop.taxId,
                    "uom": prop.uom,
                    "cgstPercentage": prop.csgst > 0 ? parseFloat(prop.csgst) : 0,
                    "sgstPercentage": prop.ssgst > 0 ? parseFloat(prop.ssgst) : 0,
                    "igstPercentage": prop.isgst > 0 ? parseFloat(prop.isgst) : 0,
                    "cgstAmount": cgstAmount > 0 ? cgstAmount.toFixed(2) : 0,
                    "sgstAmount": sgstAmount > 0 ? sgstAmount.toFixed(2) : 0,
                    "igstAmount": igstAmount > 0 ? igstAmount.toFixed(2) : 0,
                    "createdBy": getCookie(cons.USERID),
                    "companyId": getCookie(cons.COMPANY_ID),
                    "userId": getCookie(cons.USERID)
                })
            });
            var tcs=0;
            if(tcsAmount!==null&&tcsAmount!=="")
                tcs=parseFloat(tcsAmount);
            var round=(parseFloat(this.state.netTotal)+tcs).toFixed(2);

            round=round.split(".");
            var total=parseFloat(round[0]);
            round="0."+round[1];
            var showRound=false;
            if(parseFloat(round)>0){
                showRound=true
            }
            var max=(1-parseFloat(round)).toFixed(2);
            round=parseFloat("-"+round);

            console.log("round",round);
            var totalRound=roundoff;
            if(roundoff!==null&&roundoff!==undefined&&roundoff!==0){
                console.log("round",round,this.state.roundoff);

                if(parseFloat(this.state.roundoff)===parseFloat(max))
                    total=Math.round(total+parseFloat(this.state.roundoff)-round).toFixed(2);
                else if(parseFloat(this.state.roundoff)===parseFloat(round))

                    total=Math.round(total).toFixed(2);
                else {
                    if(parseFloat(max)<=0.50){
                        total=Math.round(total+parseFloat(max)-round).toFixed(2);
                    }else {
                        total=Math.round(total).toFixed(2);
                    }
                }
            }else {
                if(parseFloat(max)<=0.50){
                    total=Math.round(total+parseFloat(max)-round).toFixed(2);
                    totalRound=max;
                }else {
                    total=Math.round(total).toFixed(2);
                    totalRound=round;
                }

            }
            var data = {
                "billPass": {
                    "id": billId,
                    "billNo": billPassNo,
                    "billDate": this.dateChanged(this.state.billPassDate),
                    "supplierCode": form_supplierId,
                    "billAmount": totalAmount,
                    "type": "O",
                    "docType": "DOc",
                    "docNo": "",
                    "adjustmentType": "Adj",
                    "taxId": "",
                    "taxPercentage": "",
                    "cTax": parseFloat(this.getTotalTax('cgst')),
                    "iTax": parseFloat(this.getTotalTax('igst')),
                    "sTax": parseFloat(this.getTotalTax('sgst')),
                    "netAmount": netTotal,
                    "invoiceNo": invoiceNo,
                    "invoiceDate": this.dateChanged(invoiceDate),
                    "invoiceAmount": invoiceAmount,
                    status: status,
                    // "ledger":24,
                    "invoiceRecievedDate": this.dateChanged(invoiceReceivedDate),
                    // "frieghtCharges":11,
                    "paymentDueDate": this.dateChanged(paymentDueDate),
                    //"claimAmount":1234,
                    "taxType": taxType.value,
                    "financialYear": this.state.year,
                    "modBy": getCookie(cons.USER_NAME),
                    "companyId": getCookie(cons.COMPANY_ID),
                    "userId": getCookie(cons.USERID),
                    "tdsPayable": tdsPayable, "tdsReason": tdsReason, "tdsAmount": tdsAmount,
                    "branchId":branchId,
                    "tcsPercentage":tcsPercentage,"tcsAmount":parseFloat(tcsAmount),
                    "approveStatus":status!==""?true:false,
                    roundOff:roundoff
                },
                "billPassLineItem": invoiceList
            };
            if(filesList.length>0&&billPassStatus==="1"){
                data.billPass.status="23";
            }

            if (this.state.invoiceList.length >= 1 && billPassDate !== null && invoiceNo !== "" && form_supplierId !== null &&branchId!==""&&branchId!==0&&
                form_supplierId !== "0" && invoiceDate !== "" && paymentDueDate !== "" && invoiceAmount !== "" && invoiceReceivedDate !== "" && tdsPayable !== "") {
                if ((parseFloat(total).toFixed(2)) === (parseFloat(invoiceAmount).toFixed(2))) {
                    if (status !== "") {
                        if (filesList.length > 0) {
                            this.handleLoading();
                            requestList(serverApi.ACCOUNT_BILLS_UPDATE, data, this.handleAddIndent)
                        } else {
                            this.basicAlert("Please upload atleast one invoice..!!");
                        }
                    } else {
                        this.handleLoading();
                        requestList(serverApi.ACCOUNT_BILLS_UPDATE, data, this.handleAddIndent)
                    }
                } else {
                    this.basicAlert("The net total and invoice amount should be equal!!");
                }
            } else if (this.state.invoiceList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }

    };


    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {invoiceList, billPassDate, form_supplierId, taxType, totalAmount, filesList, netTotal, invoiceNo, invoiceDate,
                paymentDueDate, invoiceAmount, invoiceReceivedDate, tdsPayable, tdsReason, tdsAmount,branchId,tcsPercentage,tcsAmount,roundoff} = this.state;
            var invoiceList = invoiceList.map((prop, key) => {
                var cgstAmount = parseFloat(prop.amount) * parseFloat(prop.csgst) / 100,
                    sgstAmount = parseFloat(prop.amount) * parseFloat(prop.ssgst) / 100,
                    igstAmount = parseFloat(prop.amount) * parseFloat(prop.isgst) / 100;
                return ({
                    "id": 0,
                    "costCenterId": prop.costCenterId,
                    "description": prop.description,
                    "rate": prop.rate,
                    "qty": prop.quantity,
                    "amount": prop.amount,
                    "taxId": prop.taxId,
                    "uom": prop.uom,
                    "cgstPercentage": prop.csgst > 0 ? parseFloat(prop.csgst) : 0,
                    "sgstPercentage": prop.ssgst > 0 ? parseFloat(prop.ssgst) : 0,
                    "igstPercentage": prop.isgst > 0 ? parseFloat(prop.isgst) : 0,
                    "cgstAmount": cgstAmount > 0 ? cgstAmount.toFixed(2) : 0,
                    "sgstAmount": sgstAmount > 0 ? sgstAmount.toFixed(2) : 0,
                    "igstAmount": igstAmount > 0 ? igstAmount.toFixed(2) : 0,
                    "createdBy": getCookie(cons.USERID),
                    "companyId": getCookie(cons.COMPANY_ID)
                })
            });

            console.log("tax", this.getTotalTax('sgst'));
            var tcs=0;
            if(tcsAmount!==null&&tcsAmount!=="")
                tcs=parseFloat(tcsAmount);
            var round=(parseFloat(this.state.netTotal)+tcs).toFixed(2);

            round=round.split(".");
            var total=parseFloat(round[0]);
            round="0."+round[1];
            var showRound=false;
            if(parseFloat(round)>0){
                showRound=true
            }
            var max=(1-parseFloat(round)).toFixed(2);
            round=parseFloat("-"+round);

            console.log("round",round);
            var totalRound=roundoff;
            if(roundoff!==null&&roundoff!==undefined&&roundoff!==0){
                console.log("round",round,this.state.roundoff);

                if(parseFloat(this.state.roundoff)===parseFloat(max))
                    total=Math.round(total+parseFloat(this.state.roundoff)-round).toFixed(2);
                else if(parseFloat(this.state.roundoff)===parseFloat(round))

                    total=Math.round(total).toFixed(2);
                else {
                    if(parseFloat(max)<=0.50){

                        total=Math.round(total+parseFloat(max)-round).toFixed(2);
                    }else {

                        total=Math.round(total).toFixed(2);
                    }
                }
            }else {
                if(parseFloat(max)<=0.50){
                    total=Math.round(total+parseFloat(max)-round).toFixed(2);
                    totalRound=max;
                }else {
                    total=Math.round(total).toFixed(2);
                    totalRound=round;
                }


            }
            var data = {
                "billPass": {
                    "id": "",
                    "billNo": "",
                    "billDate": this.dateChanged(this.state.billPassDate),
                    "supplierCode": form_supplierId,
                    "billAmount": totalAmount,
                    "type": "O",
                    "docType": "DOc",
                    "docNo": "",
                    "adjustmentType": "Adj",
                    "taxId": "",
                    "taxPercentage": "",
                    "cTax": parseFloat(this.getTotalTax('cgst')),
                    "iTax": parseFloat(this.getTotalTax('igst')),
                    "sTax": parseFloat(this.getTotalTax('sgst')),
                    "netAmount": netTotal,
                    "invoiceNo": invoiceNo,
                    "invoiceDate": this.dateChanged(invoiceDate),
                    "invoiceAmount": invoiceAmount,
                    // "ledger":24,
                    "invoiceRecievedDate": this.dateChanged(invoiceReceivedDate),
                    // "frieghtCharges":11,
                    "paymentDueDate": this.dateChanged(paymentDueDate),
                    "taxType": taxType.value,
                    //"claimAmount":1234,
                    "financialYear": this.state.year,
                    "createdBy": getCookie(cons.USER_NAME),
                    "companyId": getCookie(cons.COMPANY_ID),
                    "tdsPayable": tdsPayable, "tdsReason": tdsReason, "tdsAmount": tdsAmount,
                    "branchId":branchId,
                    "tcsPercentage":tcsPercentage,"tcsAmount":parseFloat(tcsAmount),
                    roundOff:totalRound
                },
                "billPassLineItem": invoiceList
            };

            if (this.state.invoiceList.length >= 1 && billPassDate !== null && invoiceNo !== "" && form_supplierId !== null &&branchId!==""&&branchId!==0&&
                form_supplierId !== "0" && invoiceDate !== "" && paymentDueDate !== "" && invoiceAmount !== "" && invoiceReceivedDate !== "" && tdsPayable !== "") {
                if ((parseFloat(total).toFixed(2)) === (parseFloat(invoiceAmount).toFixed(2))) {
                    this.handleLoading();
                    requestList(serverApi.ACCOUNT_BILLS_ADD, data, this.handleAddIndent)
                } else {
                    this.basicAlert("The net total and invoice amount should be equal!!");
                }
            } else if (this.state.invoiceList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            if (this.state.deleteBill)
                console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        if (this.state.deleteBill) {
            this.setState({billId: "", deleteBill: false, isRefresh: true})
        } else
            this.handleLoading();
    };
    handleDeleteBill = (resp) => {

    };

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteBill) {
            //this.handleLoading();
            getDataFromUrl(serverApi.ACCOUNT_BILLS_DELETE + this.state.billId + "/O/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.ACADEMIC_YEAR), this.handleAddIndent);
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList, sum_of_tax = 0, sumOfamt = 0;
            //  var totalAmount = this.state.totalAmount - parseFloat(data[this.state.id].amount);
            //   var totalTax = this.state.totalTax -parseFloat(data[this.state.id].taxamount);
            //  var netTotal = this.state.netTotal -parseFloat(data[this.state.id].amount) - parseFloat(data[this.state.id].taxamount);
            data.splice(this.state.id, 1);
            data.map((o, key) => {
                o.id = key;
                sum_of_tax = sum_of_tax + parseFloat(o.taxamount);
                sumOfamt = sumOfamt + parseFloat(o.amount);
            });


            //  if(this.state.approveStatus)

            this.setState({
                totalTax: sum_of_tax.toFixed(2),
                totalAmount: sumOfamt.toFixed(2),
                netTotal: sumOfamt + sum_of_tax,
            });

            this.setState({
                invoiceList: data,
                deleteItem: false
            });
            this.hideAlert();
        }
        this.setState({
            basicAlert: null,
        });

    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    //add Item
    onClickAddItem() {
        var arr = this.state.invoiceList;
        console.log("quantity " + this.state.quantity);
        var itemIndex = 0, sum_of_tax = 0, sumOfamt = 0, hasItem = false;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            if (this.state.itemTaxAmt > 0)
                arr[itemIndex].taxamount = parseFloat(this.state.itemTaxAmt);
            arr[itemIndex].amount = parseFloat(this.state.amount)

        } else {
            itemIndex = this.state.invoiceList.length;
            if (this.state.itemTaxAmt > 0)
                sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);
            sumOfamt = sumOfamt + parseFloat(this.state.amount);
        }
        arr.map(q => {
            console.log("amount", q.amount + " aa")
            // sumOfQty = sumOfQty+parseInt(q.quantityType);
            if (q.taxamount > 0)
                sum_of_tax = sum_of_tax + parseFloat(q.taxamount);
            sumOfamt = sumOfamt + parseFloat(q.amount);
        });

        var addItemObj = {
            id: itemIndex,
            costCenter: this.state.costCenter,
            costCenterId: this.state.costCenterId,
            description: this.state.description,
            quantity: this.state.quantity,
            uom: this.state.uom,
            rate: this.state.rate !== '' ? parseFloat(this.state.rate).toFixed(2) : 0.00,
            amount: this.state.amount !== '' ? parseFloat(this.state.amount).toFixed(2) : 0.00,
            lineItemId: this.state.lineItemId,
            isgst: this.state.igstPercent,
            ssgst: this.state.sgstPercent,
            csgst: this.state.cgstPercent,
            taxId: this.state.taxId,
            taxPercentage: this.state.taxPercentage,
            taxamount: this.state.itemTaxAmt,
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to remove the data row */}
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // alert(o.qualityId);
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    //  data.splice(i,1);
                                    if (this.state.taxType.value > 0) {
                                        this.basicItemAlert("Do You Like To Update This Item?");
                                        this.setState({
                                            //alert: true,
                                            updateItem: true,
                                            updateItemAlert: true,
                                            id: o.id,
                                            costCenter: o.costCenter,
                                            costCenterId: o.costCenterId,
                                            description: o.description,
                                            quantity: o.quantity,
                                            uom: o.uom,
                                            rate: o.rate,
                                            amount: o.amount,
                                            lineItemId: o.lineItemId,
                                            isgst: o.igstPercent,
                                            ssgst: o.sgstPercent,
                                            csgst: o.cgstPercent,
                                            taxId: o.taxId,
                                            taxPercentage: o.taxPercentage,
                                            itemTaxAmt: o.taxamount,
                                        });
                                    } else {
                                        this.basicAlert("Please Enter All Mandatory Fields..!!");
                                    }

                                    return true;
                                }
                            });

                        }}
                        color="primaryNoBackground"
                        customClass="edit">
                        <Edit/>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.setState({
                                        deleteItem: true,
                                        id: o.id,
                                    });
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    return true;
                                }
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close/>
                    </IconButton>{" "}
                </div>

            )
        }

        if (this.state.rate !== "" && this.state.quantity !== "" && this.state.costCenterId !== "" && this.state.costCenterId !== 0 && this.state.description !== "" && this.state.amount !== '') {
            if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
            } else
                arr.splice(arr.length, 0, addItemObj);

            console.log("sumOfamt", sumOfamt);
            console.log("sum_of_tax", sum_of_tax);

            this.setState({
                loading: true,
                invoiceList: arr,
                totalTax: sum_of_tax.toFixed(2),
                totalAmount: sumOfamt.toFixed(2),
                netTotal:sumOfamt + sum_of_tax,
                updateItem: false
            });
            this.hideAlert();

        } else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }


    }


    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };


    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getQualityName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedQualityName: values[0],
            stock: values[1]
        });
        console.log("stock " + this.state.stock);

    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        //  console.log("file type",file.type);
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'PDF' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG' || fileType === 'XLSX') {
                if (this.state.uploadChallan) {
                    this.setState({
                        file: file,
                        fileName: file.name,
                        fileType:fileName[1]
                        //filesList: arr,
                        // imagePreviewUrl: reader.result
                    });
                    this.InvoicebasicAlert("Do you want to upload challan " + file.name + "?");

                } else {
                    this.setState({
                        file: file,
                        fileName: file.name,
                        //filesList: arr,
                        uploadInvoice: true,
                        fileType:fileName[1]
                        // imagePreviewUrl: reader.result
                    });
                }

                this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
            } else {
                this.basicAlert("File type you have selected can not be upload. Please select another file!!!")
            }


        };
        reader.readAsDataURL(file);
    }




    handleAddItem() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;

        console.log(this.state);
        if (this.state.taxType !== null && this.state.taxType !== 0)
            this.setState({
                loading: true,
                alert: true,
                updateItem: false,
            });
        else {
            this.basicAlert("Please Select Tax Type..!!");
        }
    };

    getVehicleQlty = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedIndentQuantity: values[1],
            indentQuantity: values[1]
        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
    handleSelectedValues = (defValue) => {

        if (this.state.invoiceList.length > 0 && !this.state.canChangeUnit && this.state.unitConversion.value !== defValue.value) {
            this.unitConvertionAlert("Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!")
        } else
            this.setState(
                {
                    unitConvertion: defValue,
                    unitConvertionId: defValue.value,
                    unitConvertionName: defValue.label,
                    canChangeUnit: false

                });
        //console.log(`Option selected:`, this.state.unitConvertionName);
    };

    getTotalTax(tax) {
        var totSgst = 0, totIgst = 0, totcgst = 0;
        var data = this.state.invoiceList;
        data.forEach((prop, key) => {
            console.log("itemTaxAmt", prop.itemTaxAmt);
            if (prop.ssgst !== undefined && prop.ssgst > 0)
                totSgst = totSgst + (parseFloat(prop.amount) * parseFloat(prop.ssgst) / 100);
            if (prop.isgst !== undefined && prop.isgst > 0)
                totIgst = totIgst + (parseFloat(prop.amount) * parseFloat(prop.isgst) / 100);
            if (prop.csgst !== undefined && prop.csgst > 0)
                totcgst = totcgst + (parseFloat(prop.amount) * parseFloat(prop.csgst) / 100);
        });
        var totalTax = 0;
        if (tax === 'sgst') {
            totalTax = totSgst;
        } else if (tax === 'igst') {
            totalTax = totIgst;
        } else if (tax === 'cgst') {
            totalTax = totcgst;
        }
        return parseFloat(totalTax).toFixed(2);
    }

    handleSelectedUnitValue = (defValue) => {
        var data = this.state.invoiceList, sum_of_tax = 0, sumOfamt = 0;
        data.map((prop, i) => {
            var SlectedTaxType = defValue.value;
            if (prop.taxPercentage > 0) {
                if (SlectedTaxType === 1||SlectedTaxType === 3) {
                    prop.isgst = prop.taxPercentage;
                    prop.ssgst = 0;
                    prop.csgst = 0;
                } else if (SlectedTaxType === 2) {
                    var taxPercent = prop.taxPercentage / 2;
                    prop.isgst = 0;
                    prop.ssgst = taxPercent;
                    prop.csgst = taxPercent;
                } else {
                    prop.isgst = 0;
                    prop.ssgst = 0;
                    prop.csgst = 0;
                }
            } else {
                prop.isgst = 0;
                prop.ssgst = 0;
                prop.csgst = 0;
            }

        });
        this.setState(
            {
                invoiceList: data,
                taxType: defValue,
            });
    };
    gettaxpercent = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        var taxPercent = values[1];

        var itemAmount = this.state.amount;
        if (taxPercent !== '' && itemAmount !== '') {
            var itemtaxAmount = parseFloat(itemAmount) * parseFloat(taxPercent) / 100;
            //  itemtaxAmount = Math.round(itemtaxAmount);
            this.setState({
                itemTaxAmt: itemtaxAmount,
                taxPercentage: taxPercent
            });
        }
        var SlectedTaxType = this.state.taxType.value;
        //alert(SlectedTaxType);
        if (SlectedTaxType === 1||SlectedTaxType === 3) {
            this.setState({
                igstPercent: taxPercent,
                sgstPercent: 0,
                cgstPercent: 0,
            });
        } else {
            taxPercent = taxPercent / 2;

            this.setState({
                igstPercent: 0,
                sgstPercent: taxPercent,
                cgstPercent: taxPercent,
            });
        }


    };
    onDeleteBill = (id) => {
        this.setState({
            deleteBill: true,
            billId: id,
        });
        this.basicItemAlert("Do You Like To Delete This Bill?");
    };

    handleDownloadedFile=(url)=>{
        window.open(url);
        //  console.log("url...",url)
    };

    render() {
        const {classes} = this.props;
        const {selectedJuteType,tcsAmount,taxType} = this.state;

        var tcs=0;
        if(tcsAmount!==null&&tcsAmount!=="")
            tcs=parseFloat(tcsAmount);
        //console.log("jute type",selectedJuteType);
//add item button click
        const ucoptions = [
            {value: 0, label: 'Select...', name: 'Select...'}
        ];

        var taxTypes=getConfigValue(configNames.TAX_TYPES);
        if(taxTypes!=="1"){
            taxTypes=JSON.parse(taxTypes)
            if(taxTypes.gst===1){
                ucoptions.push({value: 1, label: 'IGST', name: 'IGST'});
                ucoptions.push({value: 2, label: 'CGST & SGST', name: 'CGST & SGST'});
            }
            if(taxTypes.vat===1){
                ucoptions.push({value: 3, label: 'VAT', name: 'VAT'});
            }
            if(taxTypes.noTax===1){
                ucoptions.push({value: 4, label: 'NO TAX', name: 'NO TAX'});
            }
        }else {
            ucoptions.push({value: 1, label: 'IGST', name: 'IGST'});
            ucoptions.push({value: 2, label: 'CGST & SGST', name: 'CGST & SGST'});
        }
        const inputFocus = {
            autoComplete: 'off'
        };
        var labelType=getConfigValue(configNames.LABELS_TYPE);
        var round=(parseFloat(this.state.netTotal)+tcs).toFixed(2);

        round=round.split(".");
        var total=parseFloat(round[0]);
        round="0."+round[1];
        var showRound=false;
        if(parseFloat(round)>0){
            showRound=true
        }
        var max=(1-parseFloat(round)).toFixed(2);
        round=parseFloat("-"+round);

        console.log("round",round);
        var roundoff=this.state.roundoff;
        if(roundoff!==null&&roundoff!==undefined){
            console.log("round",round,this.state.roundoff);

            if(parseFloat(this.state.roundoff)===parseFloat(max))
                total=Math.round(total+parseFloat(this.state.roundoff)-round).toFixed(2);
            else if(parseFloat(this.state.roundoff)===parseFloat(round))

                total=Math.round(total).toFixed(2);
            else {
                if(parseFloat(max)<=0.50){
                    roundoff=max;
                    total=Math.round(total+parseFloat(max)-round).toFixed(2);
                }else {
                    roundoff=round;
                    total=Math.round(total).toFixed(2);
                }
            }
        }else {
            if(parseFloat(max)<=0.50){
                roundoff=max;
                total=Math.round(total+parseFloat(max)-round).toFixed(2);
            }else {
                roundoff=round;
                total=Math.round(total).toFixed(2);
            }

        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            // headerColor={"blue"}
                            content={
                                <GridContainer>
                                    {this.state.basicAlert1}

                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    {
                                        this.state.alert ?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={this.state.updateItem ? "Update" : "save"}
                                                cancel_button_text={"Cancel"}
                                                //juteType={itemtype}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={6} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Cost Center<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp id={"costCenterId"}
                                                                                               defValue={this.state.costCenterId}
                                                                                               onSelect={this.onSelect}
                                                                                               getName={this.getitemDetails.bind(this)}
                                                                                               stateKey="costCenterId"
                                                                                               url={serverApi.SEARCH_COST_FACTOR + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                                               value={this.state.costCenterId}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={6} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Description<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="description"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    value={this.state.description}
                                                                                    fullWidth={true}
                                                                                    InputProps={{
                                                                                        autoComplete: 'off',
                                                                                    }}

                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Price
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="rate"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    iType="number"
                                                                                    InputProps={inputFocus}
                                                                                    value={this.state.rate}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>

                                                            <ItemGrid xs={6} md={6}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}
                                                                              style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Quantity<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="quantity"
                                                                                    className={classes.textField}
                                                                                    value={this.state.quantity}
                                                                                    onChange={this.onChangeValues}
                                                                                    iType="number"
                                                                                    fullWidth={true}
                                                                                    style={{height: 30}}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>
                                                            <ItemGrid xs={6} md={6}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}
                                                                              style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    UOM<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="uom"
                                                                                    className={classes.textField}
                                                                                    value={this.state.uom}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    style={{height: 30}}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>

                                                            <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Amount<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="amount"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    inputProps={inputFocus}
                                                                                    iType="number"
                                                                                    style={{height: 30}}
                                                                                    value={this.state.amount}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>


                                                                </GridContainer>
                                                            </ItemGrid>
                                                            <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}
                                                                              style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Tax
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.taxId}
                                                                                    id={"taxId"}
                                                                                    onSelect={this.onSelect}
                                                                                    stateKey="taxId"
                                                                                    getName={this.gettaxpercent}
                                                                                    isReload={this.state.loadTax}
                                                                                    setIsReload={this.afterRefreshTax}
                                                                                    url={serverApi.TAX_MASTER_LIST + "/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                                    value={this.state.taxId}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>


                                                        </GridContainer>


                                                    </form>

                                                }
                                            >
                                            </Popup> : null
                                    }

                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Bill Date<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "billPassDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "billPassDate",
                                                            value: this.state.billPassDate,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.updateView ? <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Bill No<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="billPassNo"
                                                    value={this.state.billPassNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> : null}

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Vendor<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={this.state.updateView}
                                                    id={"form_supplierId"}
                                                    defValue={this.state.form_supplierId}
                                                    onSelect={this.onSelect}
                                                    stateKey="form_supplierId"
                                                    url={serverApi.SEARCH_VENDOR + getCookie(cons.COMPANY_ID)}

                                                    value={this.state.form_supplierId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Tax Type<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <Select
                                                    defValue={this.state.taxType}
                                                    name={"taxType"}
                                                    value={this.state.taxType}
                                                    onChange={this.handleSelectedUnitValue}
                                                    options={ucoptions}
                                                    className={classes.selectFormControl}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: '4px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: '#00ABDC',

                                                        },
                                                    })}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            height: '30px',
                                                            'min-height': '30px',
                                                        }),

                                                    }}
                                                />


                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Project":"Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    //  getName={this.getQualityName}
                                                    stateKey="branchId"
                                                    // isRefresh={this.state.loadQuality}
                                                    // setIsRefresh={this.afterRefreshQuality}
                                                    url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                    value={this.state.branchId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {console.log(this.state.invoiceList.length + " length")}
                                                <LineItemTable
                                                    data={this.state.invoiceList}
                                                    loading={this.state.loading}
                                                    disableActions={this.state.billPassStatus === '3' || this.state.billPassStatus === '4' || this.state.billPassStatus === '5' || this.state.billPassStatus === '6'}
                                                    columns={taxType.value===3?
                                                        [
                                                            {
                                                                Header: "Cost Center",
                                                                accessor: "costCenter",
                                                                minWidth: 150,
                                                                style: {color: "#000", textAlign: "left"}

                                                            },
                                                            {
                                                                Header: "Description",
                                                                accessor: "description",
                                                                minWidth: 150,
                                                                style: {color: "#000", textAlign: "left"}

                                                            },

                                                            {
                                                                Header: 'Quantity',
                                                                accessor: "quantity",
                                                                minWidth: 80,
                                                                style: {color: "#000", textAlign: "right"}
                                                            },
                                                            {
                                                                Header: "UOM",
                                                                accessor: "uom",
                                                                minWidth: 50,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Rate",
                                                                accessor: "rate",
                                                                minWidth: 50,
                                                                style: {color: "#000", textAlign: "right"}
                                                            },
                                                            {
                                                                Header: "VAT",
                                                                accessor: "isgst",
                                                                minWidth: 50,
                                                                style: {color: "#000", textAlign: "right"}
                                                            },

                                                            {
                                                                Header: "Amount",
                                                                accessor: "amount",
                                                                minWidth: 80,
                                                                style: {color: "#000", textAlign: "right"}
                                                            },
                                                            {
                                                                Header: "Actions",
                                                                minWidth: 80,
                                                                accessor: "actions",
                                                                style: {color: "#000", textAlign: "center"},
                                                                sortable: false,
                                                                filterable: false,
                                                            }
                                                        ]:
                                                        [
                                                        {
                                                            Header: "Cost Center",
                                                            accessor: "costCenter",
                                                            minWidth: 150,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Description",
                                                            accessor: "description",
                                                            minWidth: 150,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },

                                                        {
                                                            Header: 'Quantity',
                                                            accessor: "quantity",
                                                            minWidth: 80,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "UOM",
                                                            accessor: "uom",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Rate",
                                                            accessor: "rate",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "IGST",
                                                            accessor: "isgst",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "CGST",
                                                            accessor: "csgst",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "SGST",
                                                            accessor: "ssgst",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "Amount",
                                                            accessor: "amount",
                                                            minWidth: 80,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            minWidth: 80,
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                />
                                            </ItemGrid>
                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={6}>
                                                            <span style={{
                                                                fontStyle: "italic",
                                                                fontWeight: 400,
                                                                color: "#003F66"
                                                            }}>Status:</span>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: "#003F66"
                                                            }}>{this.state.statusDesc}<br/></span>
                                                        </ItemGrid>
                                                        <ItemGrid xs={6}>
                                                            {this.state.billPassStatus !== '3' && this.state.billPassStatus !== '4' && this.state.billPassStatus !== '5' && this.state.billPassStatus !== '6' ?
                                                                <Button color={"info"} right={true}
                                                                        onClick={this.handleAddItem}>Add
                                                                    Item</Button> : null}
                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    {this.state.billPassStatus !== '3' && this.state.billPassStatus !== '4' && this.state.billPassStatus !== '5' && this.state.billPassStatus !== '6' ?
                                                        <Button color={"info"} right={true}
                                                                onClick={this.handleAddItem}>Add Item</Button> : null}
                                                </ItemGrid>
                                            }

                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={9} style={{textAlign: "right"}}>
                                                        <InputLabel className={classes.label}>
                                                            {taxType.value===3?"Total VAT :":"Total IGST :"}
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2} style={{textAlign: "right"}}>
                                                        {this.getTotalTax("igst")}
                                                        {/*
        <TextField
            disabled={true}
            id="totalIgst"
            className={classes.textField}
            value={this.getTotalTax("igst")}
            onChange={this.onChangeValues}
            fullWidth={true}
            InputProps={inputFocus}
        />
*/}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={9} style={{textAlign: "right"}}>

                                                        <InputLabel className={classes.label}>
                                                            Total SGST :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2} style={{textAlign: "right"}}>
                                                        {this.getTotalTax("sgst")}
                                                        {/*
        <TextField
            disabled={true}
            id="totalSgst"
            className={classes.textField}
            value={this.getTotalTax("sgst")}
            onChange={this.onChangeValues}
            fullWidth={true}
            InputProps={inputFocus}
        />
*/}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={9} style={{textAlign: "right"}}>
                                                        <InputLabel className={classes.label}>
                                                            Total CGST :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2} style={{textAlign: "right"}}>
                                                        {this.getTotalTax("cgst")}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>


                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={9} style={{textAlign: "right"}}>
                                                        <InputLabel className={classes.label}>
                                                            Total Amount :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2} style={{textAlign: "right"}}>
                                                        {this.state.totalAmount !== "" ? parseFloat(this.state.totalAmount).toFixed(2) : 0.00}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            {showRound?  <ItemGrid xs={12} sm={12}>
                                                <GridContainer>

                                                    <ItemGrid xs={12} sm={9} style={{marginTop: "10px",textAlign: "right"}}>
                                                        <InputLabel className={classes.label}>
                                                            Round Off :
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={1} style={{textAlign: "right"}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={parseFloat(roundoff)===parseFloat(max)?1:0}
                                                                    onChange={(event)=>{
                                                                        this.setState({
                                                                            roundoff:max});
                                                                    }}
                                                                    name="radio button demo"
                                                                    aria-label="A"
                                                                    icon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioUnchecked}
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioChecked}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        checked: classes.radio
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label={max+""}
                                                        />
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={1} style={{textAlign: "right"}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={roundoff===round?1:0}
                                                                    onChange={(event)=>{

                                                                        this.setState({
                                                                            roundoff:round});
                                                                    }}
                                                                    name="radio button demo"
                                                                    aria-label="A"
                                                                    icon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioUnchecked}
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioChecked}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        checked: classes.radio
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label={round}
                                                        />
                                                    </ItemGrid>

                                                </GridContainer>

                                            </ItemGrid>:null}

                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={9} style={{textAlign: "right"}}>
                                                        <InputLabel className={classes.label}>
                                                            Net Total:
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2} style={{textAlign: "right"}}>
                                                        {total}

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>


                                            <ItemGrid xs={12}>
                                                <InputLabel className={classes.label}>
                                                    Vendor Invoice Details :
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Invoice No<span
                                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <TextField
                                                                    id="invoiceNo"
                                                                    value={this.state.invoiceNo}
                                                                    className={classes.textField}
                                                                    onChange={this.onChangeValues}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    inputProps={inputFocus}

                                                                />
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Invoice Date<span
                                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <FormControl variant="" fullWidth>
                                                                    <Datetime
                                                                        closeOnSelect={true}
                                                                        timeFormat={false}
                                                                        onChange={(dateObj) => {
                                                                            this.timeChanged(dateObj, "invoiceDate")
                                                                        }}
                                                                        height={"15px"}
                                                                        formControlProps={
                                                                            {height: "15px", inlineBlock: "none"}
                                                                        }
                                                                        inputProps={{
                                                                            id: "invoiceDate",
                                                                            value: this.state.invoiceDate,
                                                                            autoComplete: 'off'
                                                                        }}

                                                                    />
                                                                </FormControl>
                                                            </ItemGrid>
                                                        </GridContainer>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Payment Due Date<span
                                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <FormControl variant="" fullWidth>
                                                                    <Datetime
                                                                        closeOnSelect={true}
                                                                        timeFormat={false}
                                                                        onChange={(dateObj) => {
                                                                            this.timeChanged(dateObj, "paymentDueDate")
                                                                        }}
                                                                        height={"15px"}
                                                                        formControlProps={
                                                                            {height: "15px", inlineBlock: "none"}
                                                                        }
                                                                        inputProps={{
                                                                            id: "paymentDueDate",
                                                                            value: this.state.paymentDueDate,
                                                                            autoComplete: 'off'
                                                                        }}

                                                                    />
                                                                </FormControl>
                                                            </ItemGrid>
                                                        </GridContainer>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Invoice Amount<span
                                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <TextField
                                                                    id="invoiceAmount"
                                                                    value={this.state.invoiceAmount}
                                                                    className={classes.textField}
                                                                    onChange={this.onChangeValues}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    inputProps={inputFocus}

                                                                />
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Invoice Received Date<span
                                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <FormControl variant="" fullWidth>
                                                                    <Datetime
                                                                        closeOnSelect={true}
                                                                        timeFormat={false}
                                                                        onChange={(dateObj) => {
                                                                            this.timeChanged(dateObj, "invoiceReceivedDate")
                                                                        }}
                                                                        height={"15px"}
                                                                        formControlProps={
                                                                            {height: "15px", inlineBlock: "none"}
                                                                        }
                                                                        inputProps={{
                                                                            id: "invoiceReceivedDate",
                                                                            value: this.state.invoiceReceivedDate,
                                                                            autoComplete: 'off'
                                                                        }}

                                                                    />
                                                                </FormControl>
                                                            </ItemGrid>
                                                        </GridContainer>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    TDS Payable<span
                                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.tdsPayable}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="tdsPayable"
                                                                    staticData={yesOrNo}
                                                                />

                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>
                                                    {this.state.tdsPayable === 'Y' ?
                                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        TDS Amount<span style={{
                                                                        color: "red",
                                                                        fontSize: "12px"
                                                                    }}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        id="tdsAmount"
                                                                        value={this.state.tdsAmount}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        InputProps={inputFocus}

                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid> : null}

                                                    {this.state.tdsPayable === 'Y' ?
                                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        TDS Reason
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        id="tdsReason"
                                                                        value={this.state.tdsReason}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        InputProps={inputFocus}

                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid> : null}
                                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    TCS Percentage
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.tcsPercentage}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="tcsPercentage"
                                                                    staticData={TCSPercentages}
                                                                />

                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    TCS Amount
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <TextField
                                                                    disabled={true}
                                                                    id="tcsAmount"
                                                                    value={this.state.tcsAmount}
                                                                    className={classes.textField}
                                                                    onChange={this.onChangeValues}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    InputProps={inputFocus}

                                                                />
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>

                                                </GridContainer>
                                            </ItemGrid>

                                            <ItemGrid xs={12}
                                                      style={{
                                                          height: 2,
                                                          backgroundColor: "#00acc1",
                                                          marginTop: "5px"
                                                      }}/>


                                            <ItemGrid xs={12} sm={12}>
                                                <InputLabel className={classes.label}>
                                                    Invoices<span style={{color: "red", fontSize: "12px"}}>* </span> :
                                                </InputLabel>
                                            </ItemGrid>

                                            <ItemGrid xs={8} style={{overflowY: "auto", maxHeight: 250}}>
                                                {this.state.filesList.map((r, i) => (
                                                    <GridContainer style={{marginTop: "10px"}}>
                                                        <ItemGrid xs={12} sm={2}>
                                                            {i + 1}
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6}>
                                                            <a onClick={()=>{
                                                                getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                            }}>
                                                                {r.fileName!==null&&r.fileName!==""?r.fileName:"invoice"+(i+1)}
                                                            </a>
                                                        </ItemGrid>
                                                        <ItemGrid xs={4}>
                                                            <IconButton
                                                                right={true}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deleteInvoice: true,
                                                                        invoiceId: r.fileUploadId,
                                                                        fileId: i
                                                                    });
                                                                    this.InvoicebasicAlert("Do You Like To Delete This Item?");
                                                                }}

                                                                color="dangerNoBackground"
                                                            >
                                                                <Close/>
                                                            </IconButton> {""}

                                                        </ItemGrid>

                                                    </GridContainer>

                                                ))}

                                            </ItemGrid>


                                            {(this.state.approveStatus && this.state.billPassStatus !== 3) || this.state.billPassStatus === '1' ?
                                                <ItemGrid xs={4}>
                                                    <Button color={"info"}
                                                            onClick={this.handleFileClick}>Upload Invoice</Button>
                                                </ItemGrid> : null}


                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            {this.state.approveStatus ?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={() => {
                                                                this.setState({isApprove:true});
                                                                this.InvoicebasicAlert("Do You Like To Approve..!!");
                                                            }}>Approve</Button>

                                                </ItemGrid>
                                                : null}
                                            {this.state.approveStatus ?
                                                <ItemGrid>
                                                    <Button color="danger"
                                                            onClick={() => {
                                                                this.setState({isReject:true})
                                                                this.InvoicebasicAlert("Do You Like To Reject..!!");
                                                            }}>Reject</Button>
                                                </ItemGrid> : null}
                                            {(this.state.approveStatus && this.state.billPassStatus !== 3) || this.state.billPassStatus === '1' ?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={() => {
                                                                this.onUpdateButtonClick("")
                                                            }}>Update</Button>

                                                </ItemGrid> : null}
                                            {this.state.billPassStatus !== 21 ?

                                                <ItemGrid xs={12} style={{marginTop: "15px"}}>
                                                    <SamplePDF
                                                        invoiceType={17} printResp={this.state.printResp}/>
                                                </ItemGrid>
                                                : null}


                                            {!this.state.updateView ?
                                                <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                                    <GridContainer justify="center">
                                                        <ItemGrid>
                                                            <Button color="primary"
                                                                    onClick={this.onCreateButtonClick}>Create</Button>
                                                        </ItemGrid>
                                                        <ItemGrid>
                                                            <Button color="danger"
                                                                    onClick={this.handleCreate}>Cancel</Button>
                                                        </ItemGrid>

                                                    </GridContainer>

                                                </ItemGrid> : null

                                            }

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <GridContainer>

                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height: "10px", inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id: "fromDate",
                                                                        value: this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}

                                                                    formControlProps={
                                                                        {height: "10px", inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id: "toDate",
                                                                        value: this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Status
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp id={"status"}

                                                                           onSelect={this.onSelect}
                                                                           stateKey="status"
                                                                           url={serverApi.SEARCH_JUTE_STATUS}

                                                                           value={this.state.status}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>

                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        // round color="white"
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>
                                            {/*
                                            <ReactTable
                                                data={this.state.data}
                                                filterable
                                                // resizable={false}
                                                style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}
                                                columns={[
                                                    {
                                                        Header: "PO Number",
                                                        accessor: "po_num",
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Date",
                                                        accessor: "po_date",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Suplier Name",
                                                        accessor: "suplier_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Broker Name",
                                                        accessor: "broker_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Mukam",
                                                        accessor: "mukam",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Type",
                                                        accessor: "vehicle_type",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Quantity",
                                                        accessor: "vehicle_quantity",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Qualities",
                                                        accessor: "qualities",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                //showPaginationTop
                                                showPaginationBottom
                                                className="-striped -highlight"
                                            >

                                            </ReactTable>
*/}


                                            <TableComponent
                                                staticData={this.state.tableList}
                                              //  url={serverApi.ACCOUNT_BILLS_TABLE}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                fileName={getCookie(cons.COMPANY_NAME) + '_BILLS'}
                                                showDelete={true}
                                                onDeleteClick={this.onDeleteBill}
                                                actionType={VIEW}/>

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(Bills);





