import React from "react";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import {dataTable} from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, serverVars} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";


class AppDataSpreaderRolls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            itemViewType:"roleId",
            date:moment().format('DD-MM-YYYY'),
            time:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
            spell:'',
            spreader:'',
            rollWeight:'',
            totalNetWeight:'',
            itemId:'',
            qualityId:''

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount() {
        this.callJuteType()
    }

    callJuteType(){

        var data={
            "itemGroupId":"999",
            "companyId":getCookie(cons.COMPANY_ID)
        };
        requestList(serverApi.GET_ITEMS_BY_GRPID,data,this.handleJuteType);
    }
    handleJuteType=(resp)=>{
        this.setState({juteTypeItems:resp,reloadSelect:true})
    }
    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };



    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.DELETE_SPREADER_ROLE + "/" + id +"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
        }
    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";

    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            })
        }else {
            this.showMessage(resp.message,resp.status);
        }
    };

    handleViewClickResp = (resp) => {
        var header = resp;

        if (header !== null) {
            this.setState({
                updateView: true,
                isCreated: true

            });

        }


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        if(this.state.fromDate!==""||this.state.todate!==""){
            var data = {
                "fromDate": this.state.fromDate
                , "toDate": this.state.todate,
                "companyId":getCookie(cons.COMPANY_ID),
                "cipher":getCookie(cons.CIPHER)
            };
        }else
        var data = {
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate,
            "cipher":getCookie(cons.CIPHER)
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date')
            this.setState({
                [id]: ''
            });
        const {spell,spreader,date} = this.state;
        // console.log("stateKey",stateKey);

            var data={
                "date":id==='date' ?dateObj.format('DD-MM-YYYY'):date,
                "spell":this.state.spell,
                "spreaderNo":this.state.spreader,
                "companyId":getCookie(cons.COMPANY_ID),
                "cipher":getCookie(cons.CIPHER)

            };
            this.setState({
                totalNetWeight:'',
            })
            requestList(serverApi.GET_NET_WEIGHT_SPREADER,data,this.handleTotalWeight)


        //this.dateChanged();
    };
    dateChanged = () => {
        var myDate = this.state.date;
        if(myDate!=null){
            myDate = myDate.split("-");
            var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
            return new Date(newDate).getTime();
        }
    }


    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {

        this.setState({
            [stateKey]: selectedValue
        });
        const {spell,spreader,date} = this.state;
       // console.log("stateKey",stateKey);
        if (stateKey === 'itemId') {
            this.setState({
                loadQuality: true,
            });
        }
        if (stateKey === 'spell' || stateKey ==='spreader'){
            var data={
                "date":date,
                "spell":stateKey === 'spell'?selectedValue:this.state.spell,
                "spreaderNo":stateKey ==='spreader' ? selectedValue:this.state.spreader,
                "companyId":getCookie(cons.COMPANY_ID),
                "cipher":getCookie(cons.CIPHER)

            };
            this.setState({
                totalNetWeight:'',
            })
            requestList(serverApi.GET_NET_WEIGHT_SPREADER,data,this.handleTotalWeight)

        }

    };
    handleTotalWeight=(resp)=>{
        if(resp.status===true){
            this.setState({
                totalNetWeight:resp.data
            })

        }else {
            this.setState({
                totalNetWeight:0
            })

        }

    }
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }

    hideAlertMessage() {
        this.setState({
            isRefresh:true,
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
           // date:moment().format('DD-MM-YYYY'),
            time:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
          //  spell:"0",
            godownNo:"",
            trollyId:0,
            noOfBales:"",
            reloadSelect:true,
            totalNetWeight:"",
            rollWeight:"",
            "binNo":"",

           // spreader:0

        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
        getDataFromUrl(serverApi.DELETE_SPREADER_ROLE + "/" + id+"/"+getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: [],
            sumOFQuantities: 0

        });

    }



    onUpdateButtonClick = () => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data = {
                "issueId": this.state.indentNo,
                "juteType": this.state.selectedJuteType,
                "finYear": this.state.year,
                "issueDate": this.dateChanged(),
                "juteQuality": this.state.form_QualityId,
                "baleLoose": this.state.unitConvertionName,
                "noBales": this.state.balesCount,
                "quantity": this.state.form_quantity,
                "uomCode": this.state.uom,
                "openStock": "",
                "closeStock": "",
                "issueStatus":'',
                "godownNo": this.state.form_godownNo,
                "side": this.state.processSideName,
                "createdBy": getCookie(cons.USER_NAME),
                "wastageId": this.state.wastageType,


            };
          /*  if (this.state.date1 !== '' && this.state.unitConvertion !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                this.handleLoading();
                requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }*/
        }
    };


    timeChangedFormat = (myDate,time) => {
        console.log("time",myDate + time);

        myDate=myDate.split("-");
        var timeParts = time.split(":");
        return new Date(myDate[2], myDate[1]-1, myDate[0], timeParts[0], timeParts[1],timeParts[2]).getTime();
    };


    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data = {
                "spreaderDateTime":this.timeChangedFormat(this.state.date,this.state.time2),
                "spell":this.state.spell,
                "spreaderNo":this.state.spreader,
                "netWeight":this.state.totalNetWeight,
                "weight":this.state.rollWeight,
                "createdBy":getCookie(cons.USERID),
                "companyId":getCookie(cons.COMPANY_ID),
                "binNo":this.state.binNo,
                "cipher":getCookie(cons.CIPHER),
                "itemId":this.state.itemId,
                "qualityId":this.state.qualityId
            };
            if (this.state.date !== ''  && this.state.spell !== "" && this.state.spell !== "0"
                && this.state.spreader !== "" && this.state.spreader !== "0"
                && this.state.rollWeight !== ''  && this.state.totalNetWeight !== ''&&this.state.itemId!==""&&this.state.qualityId!==""&&this.state.binNo!=="") {
                this.handleLoading();
                requestList(serverApi.ADD_SPREADER_ROLE, data, this.handleAddSpreader)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddSpreader = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };


    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    timeChanged1 = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('hh:mm:ss A'),
                time2:dateObj.format('HH:mm:ss')
            });
    };
    afterReloadSelect=(val)=>{
        this.setState({
            reloadSelect:val
        })
    };


    render() {
        const {classes} = this.props;
        const psoptions = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'WARP', name: 'WARP'},
            {value: 2, label: 'WEFT', name: 'WEFT'},

        ];
        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}

                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>

                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Date<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Datetime
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    onChange={(dateObj) => {
                                                        this.timeChanged(dateObj, "date")
                                                    }}
                                                    formControlProps={
                                                        {height: "10px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id: "date",
                                                        value: this.state.date,
                                                        autoComplete: 'off'
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        defaultValue={new Date()}
                                                        closeOnSelect={true}
                                                        // defaultValue={this.state.time1}
                                                        dateFormat={false}
                                                        onChange={(timeObj) => {
                                                            this.timeChanged1(timeObj, "time")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"outTime",
                                                            value:this.state.time,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Spell<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"spell"}
                                                    selectDisabled={this.state.updateView}
                                                    defValue={this.state.spell}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    onSelect={this.onSelect}
                                                    getName={this.getName}
                                                    stateKey="spell"
                                                    url={serverApi.GET_ALL_SPELL_LIST+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.spell}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>

                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        id={"itemId"}
                                                        isRefresh={this.state.reloadSelect}
                                                        setIsRefresh={this.afterReloadSelect}
                                                        defValue={this.state.itemId}
                                                        onSelect={this.onSelect}
                                                        // getName={this.getName}
                                                        stateKey="itemId"
                                                        staticData={this.state.juteTypeItems}
                                                        //  url={serverApi.GET_ITEMS_BY_GRPID + "999/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                    />
                                                </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Quality<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.qualityId}
                                                    id={"qualityId"}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    isRefresh={this.state.loadQuality}
                                                    setIsRefresh={this.afterRefreshQuality}
                                                    //  getName={this.getQualityDetails}
                                                    onSelect={this.onSelect}
                                                    stateKey="qualityId"
                                                    url={serverVars.PO_API + 'api/v1/' + this.state.itemId + "/" + getCookie(cons.COMPANY_ID) + '/getQualityByItem'}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Spreader<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.spreader}
                                                    id={"spreader"}
                                                    getName={this.getQualityDetails}
                                                    onSelect={this.onSelect}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    stateKey="spreader"
                                                    url={serverApi.GET_FRAME_NUMBERS+getCookie(cons.COMPANY_ID)+"/SPREADER"}

                                                    value={this.state.spreader}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>



                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Roll Weight<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rollWeight"
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    value={this.state.rollWeight}
                                                    fullWidth={true}
                                                    variant="outlined"

                                                    InputProps={{
                                                        autoComplete: 'off',
                                                        endAdornment: <InputAdornment position="end">
                                                            <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>KG</span>
                                                        </InputAdornment>,
                                                    }}

                                                />

                                            </ItemGrid>


                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Total Net Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="totalNetWeight"
                                                    disabled={true}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    value={this.state.totalNetWeight}
                                                    fullWidth={true}
                                                    variant="outlined"

                                                    InputProps={{
                                                        autoComplete: 'off',
                                                        endAdornment: <InputAdornment position="end">
                                                            <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>KG</span>
                                                        </InputAdornment>,
                                                    }}

                                                />

                                            </ItemGrid>


                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Pile/Bin No<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="binNo"
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    value={this.state.binNo}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>


                                        </GridContainer>
                                    </ItemGrid>




                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>

                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>
                                    <ItemGrid xs={10} style={{marginTop: "10px"}}>
                                        <GridContainer>

                                            <ItemGrid xs={12} sm={6}>
                                                {this.state.basicAlert}
                                                {this.state.showMessagealert}
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            From date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "fromDate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "fromDate",
                                                                    value: this.state.fromDate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            To date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "todate")
                                                                }}
                                                                inputProps={{
                                                                    id: "toDate",
                                                                    value: this.state.todate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={2}>
                                        <GridContainer>
                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>
                                                <br/>
                                                <br/>
                                                <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>
                                                <br/>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>

                                    <ItemGrid xs={12}>

                                        <TableComponent
                                            url={serverApi.SPREADER_ROLL_TABLE_LIST}
                                            searchData={this.getData()}
                                            isRefresh={this.state.isRefresh}
                                            handleSearch={this.handleSeacrh.bind(this)}
                                            isLoading={this.state.isRefresh}
                                            onActionClick={this.handleDeleteClick}
                                            itemViewType={this.state.itemViewType}
                                            actionType={DELETE}
                                            fileName={'SpreaderRolls'}

                                            //showDelete={true}
                                           // onDeleteClick={this.handleDeleteClick}
                                        />


                                    </ItemGrid>


                                </GridContainer>
                            }/>

                    </ItemGrid>

            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(AppDataSpreaderRolls);
