import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";

class MachineMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "machineId",
      mcName: "",
      department: null,
      typeOfMC: null,
      mcCode: "",
      mcPostingCode: null,
      speed: null,
      readSpeed: null,
      teeth: null,
      lineNumber: null,
      displayOrderNo: null,
      bobbinCount: null,
      bobbinweight: null,
      deliverySpeed: null,
      sliverWeight: null,
      twistPerInch: null,
      outputNoOfCans: null,
      prodEff: null,
      targetEff: null,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.MACHINE_MASTER_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    var o = resp;

    if (resp !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        createdBy: o.createdBy,
        createdOn: o.createdOn,
        machineId: o.machineId,
        mcName: o.machineName,
        department: o.deptId,
        //typeOfMC:o.typeOfMC,
        mcCode: o.machineCode,
        mcPostingCode: o.mcPostingCode,
        speed: o.speed,
        readSpeed: o.reedSpeed,
        teeth: o.teeth,
        lineNumber: o.lineNumber,
        displayOrderNo: o.displayOrderNo,
        bobbinCount: o.bobbinCount,
        bobbinWeight: o.bobbinWeight,
        deliverySpeed: o.deliverySpeed,
        sliverWeight: o.sliverWeight,
        twistPerInch: o.twistPerInch,
        outputNoOfCans: o.outputNoOfCans,
        prodEff: o.prodEff,
        targetEff: o.targetEff,
        typeOfMC: parseInt(o.machineType),
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
    };
    return data;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onChangeValues = (event, type) => {
    var value = event.target.value;
    //console.log("val "+value);
    if (type === "AN") {
      if (/[^0-9a-zA-Z\s]/.test(value)) {
        value = this.state[event.target.id];
        this.setState({
          [event.target.id]: value,
        });
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "N") {
      if (/[^0-9]/.test(value)) {
        value = this.state[event.target.id];
        this.setState({
          [event.target.id]: value,
        });
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "D") {
      this.setState({
        [event.target.id]: value,
      });
    } else {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      taxName: "",
      taxPercent: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      mcName: "",
      department: null,
      typeOfMC: null,
      mcCode: "",
      mcPostingCode: null,
      speed: null,
      readSpeed: null,
      teeth: null,
      lineNumber: null,
      displayOrderNo: null,
      bobbinCount: null,
      bobbinweight: null,
      deliverySpeed: null,
      sliverWeight: null,
      twistPerInch: null,
      outputNoOfCans: null,
      prodEff: null,
      targetEff: null,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        machineId,
        mcName,
        department,
        typeOfMC,
        mcCode,
        mcPostingCode,
        speed,
        readSpeed,
        teeth,
        lineNumber,
        displayOrderNo,
        bobbinCount,
        bobbinweight,
        deliverySpeed,
        sliverWeight,
        twistPerInch,
        outputNoOfCans,
        prodEff,
        targetEff,
      } = this.state;
      var data = {
        machineId: machineId,
        machineName: mcName,
        deptId: department,
        machineType: typeOfMC,
        // "shrCode": 520,
        machineCode: mcCode,
        // "millFactory": "1",
        //"loomId": 2,
        //"qrCode": "5",
        //"frameNo": "1",
        createdBy: getCookie(cons.USERID),
        typeOfMC: typeOfMC,
        mcPostingCode: mcPostingCode,
        speed: speed,
        reedSpeed: readSpeed,
        teeth: teeth,
        lineNumber: lineNumber,
        displayOrderNo: displayOrderNo,
        bobbinCount: bobbinCount,
        bobbinWeight: bobbinweight,
        deliverySpeed: deliverySpeed,
        sliverWeight: sliverWeight,
        twistPerInch: twistPerInch,
        outputNoOfCans: outputNoOfCans,
        prodEff: prodEff,
        targetEff: targetEff,
        companyId: getCookie(cons.COMPANY_ID),
      };

      if (
        mcName !== null &&
        machineId !== null &&
        department !== null &&
        lineNumber !== null &&
        mcPostingCode !== null &&
        mcCode !== null
      ) {
        this.handleLoading();
        requestList(serverApi.MACHINE_MASTER_UPDATE, data, this.handleAddTax);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        mcName,
        department,
        typeOfMC,
        mcCode,
        mcPostingCode,
        speed,
        readSpeed,
        teeth,
        lineNumber,
        displayOrderNo,
        bobbinCount,
        bobbinweight,
        deliverySpeed,
        sliverWeight,
        twistPerInch,
        outputNoOfCans,
        prodEff,
        targetEff,
      } = this.state;
      var data = {
        machineName: mcName,
        deptId: department,
        machineType: typeOfMC,
        // "shrCode": 520,
        machineCode: mcCode,
        // "millFactory": "1",
        //"loomId": 2,
        //"qrCode": "5",
        //"frameNo": "1",
        companyId: getCookie(cons.COMPANY_ID),
        createdBy: getCookie(cons.USERID),
        typeOfMC: typeOfMC,
        mcPostingCode: mcPostingCode,
        speed: speed,
        reedSpeed: readSpeed,
        teeth: teeth,
        lineNumber: lineNumber,
        displayOrderNo: displayOrderNo,
        bobbinCount: bobbinCount,
        bobbinWeight: bobbinweight,
        deliverySpeed: deliverySpeed,
        sliverWeight: sliverWeight,
        twistPerInch: twistPerInch,
        outputNoOfCans: outputNoOfCans,
        prodEff: prodEff,
        targetEff: targetEff,
      };

      if (
        mcName !== "" &&
        mcCode !== null &&
        department !== null &&
        lineNumber !== null &&
        mcPostingCode !== null
      ) {
        this.handleLoading();
        requestList(serverApi.MACHINE_MASTER_ADD, data, this.handleAddTax);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddTax = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType, mcPostingCode } = this.state;
    console.log("selectttttttt", mcPostingCode);
    const inputFocus = {
      autoComplete: "off",
    };
    var options = [
      { label: "R/G", value: "R/G" },
      { label: "DH", value: "DH" },
      { label: "Loom", value: "Loom" },
      { label: "S4 Loom", value: "S4 Loom" },
    ];
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          MC Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="mcCode"
                          value={this.state.mcCode}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          MC Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="mcName"
                          value={this.state.mcName}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Department
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          // selectDisabled={true}
                          id={"department"}
                          onSelect={this.onSelect}
                          defValue={this.state.department}
                          // getName={this.getDeptName}
                          url={
                            serverApi.SEARCH_DEPATMENTS +
                            getCookie(cons.COMPANY_ID) +
                            "/" +
                            getCookie(cons.CIPHER)
                          }
                          stateKey="department"
                          value={this.state.department}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Type Of M/C
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          // selectDisabled={true}
                          id={"typeOfMC"}
                          onSelect={this.onSelect}
                          defValue={this.state.typeOfMC}
                          // getName={this.getDeptName}
                          //staticData={machineType}

                          url={serverApi.SELECT_MACHINE_TYPES}
                          stateKey="typeOfMC"
                          value={this.state.machineType}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          MC Posting Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="mcPostingCode"
                          value={this.state.mcPostingCode}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          iType={"number"}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Speed(RPM)
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="speed"
                          value={this.state.speed}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Reed Speed
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="readSpeed"
                          value={this.state.readSpeed}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          fullWidth={true}
                          InputProps={inputFocus}
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Teeth</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="teeth"
                          value={this.state.teeth}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          InputProps={inputFocus}
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Line Number
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="lineNumber"
                          value={this.state.lineNumber}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          InputProps={inputFocus}
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Display Order No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="displayOrderNo"
                          value={this.state.displayOrderNo}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Bobbin Count
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="bobbinCount"
                          value={this.state.bobbinCount}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Bobbin Weight
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="bobbinweight"
                          value={this.state.bobbinWeight}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Delivery Speed (Ft/Min)
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="deliverySpeed"
                          value={this.state.deliverySpeed}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Sliver Weight (per 100Yds)
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="sliverWeight"
                          value={this.state.sliverWeight}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Twist Per Inch
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="twistPerInch"
                          value={this.state.twistPerInch}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          O/P No Of Cans
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="outputNoOfCans"
                          value={this.state.outputNoOfCans}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Prod at 100% EFF(Tons per 24Hours)
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="prodEff"
                          value={this.state.prodEff}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Target Eff%
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="targetEff"
                          value={this.state.targetEff}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.MACHINE_MASTER}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={false}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(MachineMaster);
