import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { Link } from "react-router-dom";

import { requestList, oAuthList, getDataFromUrl } from "../../server/server";
import cons, { serverApi } from "../../helper/Consts";
import { setCookie, getCookie, removeCookies } from "../../helper/Cookies";

var md5 = require("md5");
const publicIp = require("public-ip");
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      uname: "",
      paswd: "",
      user_id: "",

      isLoading: false,
      errorMsg: "",
      isError: false,
    };

    this.onChangeValues = this.onChangeValues.bind(this);
  }

  onChangeValues(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });

    this.setState({
      isError: false,
    });
  }

  handleRsp = (resp) => {
    if (resp.loginStatus) {
      var uname = this.state.uname;
      if (resp.userId === 1) {
        setCookie(cons.USERID, resp.userId);
        setCookie(cons.USER_NAME, resp.userName);
        setCookie(cons.USER_IMG, resp.imageURL);
        setCookie(cons.EMAIL, resp.email);
        setCookie(cons.ORG_ID, resp.orgId);
        setCookie(cons.COMPANY_NAME, "VOW JUTE");
        setCookie(cons.COMPANY_ID, 0);
        setCookie(cons.COMPANY_TITLE, "VOW JUTE");
        setCookie(cons.USER_GRP_ID, 900);
        setCookie(cons.COMPANIES_LIST, JSON.stringify(resp.companyDetails));
        setCookie(cons.HRMS_FULL_ACCESS, resp.hrmsFullAccess);

        this.props.history.push("/menus");
      } else {
        if (this.state.paswd === "vowjute@123") {
          this.props.history.push(
            "/ResetPassword?token=1&&user=" + resp.userId
          );
        } else {
          //  getUserDetailsFromUserName(uname,this.handleUserDetails);
          setCookie(cons.CIPHER, resp.cipher);
          setCookie(cons.COMPANIES_LIST, JSON.stringify(resp.companyDetails));
          setCookie(cons.COMPANY_NAME, resp.companyDetails[0].name);
          setCookie(cons.COMPANY_ID, resp.companyDetails[0].value);
          var company_name = resp.companyDetails[0].label.split("-");
          setCookie(cons.COMPANY_TITLE, company_name[1]);
          setCookie(cons.COMPANY_ADDRESS, resp.companyDetails[0].address1);
          setCookie(cons.COMPANY_ADDRESS2, resp.companyDetails[0].address2);
          setCookie(cons.COMPANY_MAIL_ID, resp.companyDetails[0].emailId);
          setCookie(cons.COMPANY_CONTACT_NO, resp.companyDetails[0].contactNo);
          setCookie(cons.COMPANY_GST, resp.companyDetails[0].gstNo);
          setCookie(cons.COMPANY_ICON, resp.companyDetails[0].companyIcon);
          setCookie(cons.COMPANY_LOGO, resp.companyDetails[0].companyLogo);
          setCookie(cons.COMPANY_CIN, resp.companyDetails[0].cinNo);
          setCookie(
            cons.SHIPPING_ADDRESS,
            resp.companyDetails[0].companyAddress2
          );
          setCookie(cons.COMPANY_STATECODE2, resp.companyDetails[0].stateCode2);
          setCookie(cons.COMPANY_GST2, resp.companyDetails[0].gstNo2);
          setCookie(cons.COMPANY_STATECODE, resp.companyDetails[0].stateCode1);
          setCookie(cons.COMPANY_STATE, resp.companyDetails[0].stateName1);
          setCookie(cons.COMPANY_STATE2, resp.companyDetails[0].stateName2);
          setCookie(cons.CONTACT_PERSON, resp.companyDetails[0].contactPerson);

          if (resp.companyDetails[0].configList) {
            if (resp.companyDetails[0].configList.length > 0)
              setCookie(
                cons.CONFIG_LIST,
                JSON.stringify(resp.companyDetails[0].configList)
              );
          }

          setCookie(cons.USER_GRP_ID, resp.companyDetails[0].userGroupId);
          setCookie(cons.USERID, resp.userId);
          setCookie(cons.USER_NAME, resp.userName);
          setCookie(cons.USER_IMG, resp.userImageURL);
          setCookie(cons.EMAIL, resp.email);
          setCookie(cons.ORG_ID, resp.orgId);
          setCookie(cons.EB_ID, resp.ebId);
          setCookie(cons.EB_NO, resp.ebNo);
          setCookie(cons.USER_ROLE_ID, resp.roleId);
          setCookie(cons.SUPP_CODE, resp.suppCode);
          setCookie(cons.HRMS_FULL_ACCESS, resp.hrmsFullAccess);
          getDataFromUrl(
            serverApi.GET_ACADEMIC_YEAR + resp.companyDetails[0].value,
            this.handleYears
          );
        }
      }
    } else {
      this.setErrorState(true, "Enter Valid Username and Password");
    }
  };
  handleYears = (resp) => {
    console.log("years", resp);
    var yearsData = [];
    this.setState({
      isLoading: false,
    });

    if (resp.data.length > 0) {
      resp.data.forEach((prop, i) => {
        var obj = { label: prop.displayLable, value: prop.year };
        yearsData.push(obj);
        if (i === 0) {
          setCookie(cons.ACADEMIC_YEAR, prop.year);
        }
      });
      setCookie(cons.ACADEMIC_YEAR_LIST, JSON.stringify(yearsData));
      this.props.history.push("/Home");
    } else {
      this.setErrorState(true, "No Financial Year Available to Display!!");
      removeCookies();
    }
  };

  tokenRes = (res) => {
    setCookie(cons.TOKEN_TYPE, res.token_type);
    setCookie(cons.ACCESS_TOKEN, res.access_token);
    var uname = this.state.uname;
    var paswd = this.state.paswd;

    this.setState({
      isLoading: true,
    });
    var data = {
      userName: uname,
      password: paswd,
      reqSource: 0,
    };
    requestList(serverApi.LOGIN, data, this.handleRsp);

    /*
                (async () => {
                    var ip=await publicIp.v4();
                    var data={
                        "userName":uname,
                        "password":paswd,
                        "userIp":ip
                    };
                    requestList(serverApi.LOGIN,data,this.handleRsp)

                })();
        */
  };
  /*
        handleUserDetails = (resp) =>{
            if(resp.isEnabled=== 1){
                setCookie(cons.USERID,resp.id);
                setCookie(cons.USER_NAME,resp.userName);
                setCookie(cons.FIRST_NAME,resp.firstName);
                setCookie(cons.LAST_NAME,resp.lastName);
                setCookie(cons.USER_IMG,resp.userImg);
                setCookie(cons.UDATA, resp);
                setCookie(cons.COMPANY_NAME, resp.companyName);
                setCookie(cons.COMPANY_ID, resp.companyId);
                getUserGroupDetailsById(resp.id,this.handleUserGroupDetails);

               /!* this.props.history.push('/Home');
                this.setState({
                    isLoading: false
                })*!/
            }
            else {
                this.setErrorState(true,"Your account is in disable status. Please contact administrator.");
            }
        };
    */
  /*
        handleUserGroupDetails = (resp) =>{
            if(resp){
                setCookie(cons.USER_GDATA,resp);
                setCookie(cons.USER_GRP_ID,resp[0].userGroup.id);
                var today = new Date();

                var userData = getCookie(cons.UDATA) ;
                console.log("date",today.toDateString())
                var data = {
                    userId: userData.id,
                    loginId:  userData.id,
                    firstName:  userData.firstName,
                    lastName: userData.lastName,
                    isActive: "Y",
                    counter: 0,
                    createDate: today.getDate(),
                    lastModifiedDate: today.getDate()
                };
               // requestAppToken(data,this.handleToken);
                this.setState({
                    isLoading: false
                });
                this.props.history.push('/Home');
            }
            else {
                this.setErrorState(true,"Your account is in disable status. Please contact administrator.");
            }
        };
    */
  handleToken = (resp) => {
    if (resp) {
      setCookie(cons.TOKEN_DATA, resp);
      setCookie(cons.AUTH, resp.tokenId);
      this.props.history.push("/Home");
      this.setState({
        isLoading: false,
      });
    } else {
      this.setErrorState(true, "Enter Valid Username and Password");
    }
  };

  setErrorState = (isError, ErrorMsg) => {
    this.setState({
      isError: isError,
      errorMsg: ErrorMsg,
      isLoading: false,
    });
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onLogin();
    }
  };
  onLogin = () => {
    var uname = this.state.uname;
    var paswd = this.state.paswd;
    /*   paswd=paswd+'VOWSLS';
           paswd=md5(paswd);*/
    console.log("ip", publicIp.v4());
    if (uname.trim().length > 0 && paswd.trim().length > 0) {
      this.setState({
        isLoading: true,
      });
      var data = {
        userName: uname,
        password: paswd,
      };
      oAuthList(serverApi.OAUTH_LOGIN, data, this.tokenRes);

      /*
                (async () => {
                    var ip=await publicIp.v4();
                    var data={
                        "userName":uname,
                        "password":paswd,
                        "userIp":ip
                    };
                    oAuthList(serverApi.OAUTH_LOGIN, data, this.tokenRes);

                    // requestList(serverApi.LOGIN,data,this.handleRsp)



                })();
*/

      //loginRequest(taskCodes.LOGIN, data, this.handleRsp);
      //console.log(url);

      // this.handleRsp(true);
    } else {
      this.setErrorState(true, "Enter Username and Password");
    }
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  render() {
    const { classes } = this.props;
    console.log(this.state);
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              <form>
                <LoginCard
                  isLoading={this.state.isLoading}
                  isError={this.state.isError}
                  ErrorMsg={this.state.errorMsg}
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor="blue"
                  cardTitle="Login"
                  cardSubtitle=""
                  footerAlign="right"
                  footer={
                    <div>
                      <Button
                        color="primaryNoBackground"
                        wd
                        size="lg"
                        onClick={this.onLogin}
                      >
                        Login
                      </Button>
                    </div>
                  }
                  content={
                    <div>
                      <CustomInput
                        labelText="Username"
                        id="uname"
                        iType={"text"}
                        onChange={this.onChangeValues}
                        onKeyPress={this.handleKeyPress}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        labelText="Password"
                        iType={"password"}
                        id="paswd"
                        onChange={this.onChangeValues}
                        onKeyPress={this.handleKeyPress}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Link to="/ForgotPassword" className="btn btn-link">
                        <span style={{ color: "#00acc1" }}>
                          Forgot Password?
                        </span>
                      </Link>
                    </div>
                  }
                />
              </form>
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(loginPageStyle)(LoginPage);
