import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi, serverVars } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";

class AppDataWastageEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      itemViewType: "id",
      date: moment().format("DD-MM-YYYY"),
      spell: "",
      wastageType: "",
      stock: 0,
      trollyNo: "",
      grossWeightKG: 0,
      tareWeightKG: 0,
      netWeightKG: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "getJuteIssueById/" +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  getStockDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      stock: values[1],
    });
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp;
    var stock = resp.data;

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        stock: stock,
      });
    }
    var stockRate = (prop) => {
      return {
        stock: prop.data,
        status: prop.status,
        id: prop.id,
      };
    };
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    if (this.state.fromDate !== "" || this.state.todate !== "") {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
      };
    } else
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        cipher: getCookie(cons.CIPHER),
      };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });

    if (event.target.id === "grossWeightKG") {
      var grossWeight = value;
      var tareWeight = this.state.tareWeightKG;

      if (grossWeight != "") {
        var netWeight = grossWeight - tareWeight;
      }
      this.setState({
        netWeightKG: netWeight,
      });
      // if (netWeight > 0) {
      //
      // }
      //  else {
      //      this.basicAlert("Gross Weight Should Be Greater than the Tare Weight!!");
      //     this.setState({
      //         grossWeightKG: ''
      //     });
      //  }
    }
  };
  getStock = () => {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      wastageType: this.state.wastageType,
    };
    requestList(serverApi.GET_WASTAGE_STOCK, data, this.handleViewClickResp);
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    if (stateKey === "wastageType") {
      this.getStock();
      this.setState({
        loadQuality: true,
        wastageType: selectedValue,
      });
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  getTrollyDetails = (selectedValue) => {
    var val = selectedValue.split("^");

    this.setState({
      tareWeightKG: val[1],
    });
    var grossWeight = this.state.grossWeightKG;
    var tareWeight = val[1];
    console.log("weight", grossWeight, tareWeight);
    //if (parseFloat(grossWeight) > parseFloat(tareWeight)) {
    if (grossWeight != "") {
      var netWeight = grossWeight - tareWeight;
    }
    this.setState({
      netWeightKG: netWeight,
    });
    /*  }
        else {
            this.basicAlert("Gross Weight Should Be Greater than the Tare Weight!!");
            this.setState({
                grossWeightKG: '',
            netWeightKG:''
            });
        }*/
  };

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      spell: "0",
      wastageType: 0,
      stock: 0,
      trollyNo: 0,
      grossWeightKG: 0,
      tareWeightKG: 0,
      netWeightKG: "",
      date: moment().format("DD-MM-YYYY"),
    });
  }

  hideAlertMessage() {
    this.setState({
      isRefresh: true,
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      spell: this.state.spell,
      wastageType: this.state.wastageType,
      stock: 0,
      trollyNo: 0,
      grossWeightKG: 0,
      tareWeightKG: 0,
      netWeightKG: "",
      reloadSelect: true,
      date: moment().format("DD-MM-YYYY"),
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL + "deleteWastageEntry/" + id,
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        issueId: this.state.indentNo,
        spell: this.state.spell,
        finYear: this.state.year,
        issueDate: this.dateChanged(),
        juteQuality: this.state.wastageType,
        stock: this.state.stock,
        trollyNo: this.state.trollyNo,
        grossWeightKG: this.state.grossWeightKG,
        tareWeightKG: this.state.tareWeightKG,
        netWeightKG: this.state.netWeightKG,
        createdBy: getCookie(cons.USER_NAME),
      };
      /*  if (this.state.date1 !== '' && this.state.unitConvertion !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                  this.handleLoading();
                  requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
              }
              else {
                  this.basicAlert("Please Enter All Mandatory Fields..!!");
              }*/
    }
  };
  getNetWeight() {
    if (this.state.grossWeightKG !== "" && this.state.tareWeightKG !== "")
      return (
        parseFloat(this.state.grossWeightKG) -
        parseFloat(this.state.tareWeightKG)
      );
    else return 0;
  }

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        spell,
        wastageType,
        stock,
        trollyNo,
        grossWeightKG,
        tareWeightKG,
        netWeightKG,
        date,
      } = this.state;
      var data = {
        entryDate: this.dateChanged(),
        spell: spell,
        wastageType: wastageType,
        stock: parseFloat(stock),
        trollyNo: trollyNo,
        grossWeight: parseFloat(grossWeightKG),
        tareWeight: parseFloat(tareWeightKG),
        netWeight: netWeightKG,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
      };
      if (
        spell !== "0" &&
        spell !== "" &&
        wastageType !== "" &&
        wastageType !== "" &&
        stock !== 0 &&
        trollyNo !== "0" &&
        trollyNo !== "" &&
        grossWeightKG !== "" &&
        tareWeightKG !== "" &&
        netWeightKG !== ""
      ) {
        if (this.getNetWeight() > 0) {
          this.handleLoading();
          requestList(serverApi.ADD_WASTAGE_ENTRY, data, this.handleAddIndent);
        } else {
          this.basicAlert(
            "Gross Weight Should Be Greater Than Tare Weight..!!"
          );
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        //  time2:dateObj.format('HH:mm:ss')
      });
  };

  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };

  render() {
    const { classes } = this.props;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Date
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "date");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "date",
                            value: this.state.date,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Spell
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        id={"spell"}
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        onSelect={this.onSelect}
                        defValue={this.state.spell}
                        stateKey="spell"
                        url={
                          serverApi.GET_ALL_SPELL_LIST +
                          getCookie(cons.COMPANY_ID) +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        value={this.state.spell}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Wastage Type
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        id={"wastageType"}
                        onSelect={this.onSelect}
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        defValue={this.state.wastageType}
                        getName={this.getStockDetails}
                        stateKey="wastageType"
                        url={
                          serverApi.WASTAGE_TYPES +
                          "/" +
                          getCookie(cons.COMPANY_ID) +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        value={this.state.wastageType}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Stock
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id={"stock"}
                        value={this.state.stock}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        fullWidth={true}
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Trolly No
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        defValue={this.state.trollyNo}
                        id={"trollyNo"}
                        getName={this.getTrollyDetails}
                        onSelect={this.onSelect}
                        stateKey="trollyNo"
                        url={
                          serverApi.GET_TROLLY_LIST +
                          "1/" +
                          getCookie(cons.COMPANY_ID) +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        value={this.state.trollyNo}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Gross Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="grossWeightKG"
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.grossWeightKG}
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Tare Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="tareWeightKG"
                        disabled={true}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.tareWeightKG}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Net Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="netWeightKG"
                        disabled={true}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.netWeightKG}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                  <GridContainer justify="center">
                    <ItemGrid>
                      <Button
                        color="primary"
                        onClick={this.onCreateButtonClick}
                      >
                        Create
                      </Button>
                    </ItemGrid>

                    <ItemGrid>
                      <Button color="danger" onClick={this.handleCreate}>
                        Cancel
                      </Button>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={10} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={6}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            From date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            To date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "todate");
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.todate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={2}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <br />
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                      <br />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={12}>
                  {
                    <TableComponent
                      url={serverApi.DATATABLE_SERVICE_URL + "getWastageEntry"}
                      searchData={this.getData()}
                      isRefresh={this.state.isRefresh}
                      handleSearch={this.handleSeacrh.bind(this)}
                      isLoading={this.state.isRefresh}
                      onActionClick={this.handleDeleteClick}
                      itemViewType={this.state.itemViewType}
                      actionType={DELETE}
                      fileName={"WastageEntry"}

                      /*   showDelete={false}
                                               onDeleteClick={this.handleDeleteClick}*/
                    />
                  }
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(AppDataWastageEntry);
