import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory ,createHashHistory} from "history";
import { Router, Route, Switch,HashRouter } from "react-router-dom";

import indexRoutes from "./routes/index.jsx";
//import Test from 'tlvince-reduced-test-case-material-ui-component';

import "./assets/scss/material-dashboard-pro-react/material-dashboard-pro-react1.css?v=1.1.1";




const hist = createBrowserHistory();


ReactDOM.render(
  <HashRouter history={hist} >
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);