import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
  getDecimal,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
  uploadInvoice,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
  IsTallyAvailable,
} from "../CustumComponents/PermissionAlert";
import CustomInput from "../../components/CustomInput/CustomInput";
import ExpandMoreIcon from "./JuteQualityCheck";
import { TCSPercentages, yesOrNo } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import TallyIcon from "../../assets/img/tally_icon.png";
import TallyRedIcon from "../../assets/img/tally_red.png";

class JuteBillPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mrNo: "",
      mrPrintNo: "",
      ledgerGroupDesc: "",
      searchMrNo: "",
      vehicleNo: "",
      poNO: "",

      billPassNo: "",
      filesList: [],
      mrDate: moment().format("DD-MM-YYYY"),
      file: null,
      fileName: "No file Chosen",
      form_supplierId: "",
      supplierId: "",
      grnNo: "",
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      contractNo: "",
      contractDate: "",
      exstn: "",
      agentId: "",
      form_vehicleNo: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "billPassNo",
      //itemViewType: "mrNo",
      approveStatus: false,
      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",

      indentNo: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      loadGateEntry: false,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      alert: false,
      warehouseNo: "",
      warehouseName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      materialHdrId: "0",
      lineItemId: "0",
      billPassStatus: "",
      challanList: [],
      tdsPayable: "N",
      tdsReason: "",
      tdsAmount: "",
      branchId: "",
      tcsPercentage: "",
      tcsAmount: "",
      fileType: "",
      supplierList: [],
      roundoff: null,
      supportFilesList: [],
      gateEntryNo: "",
      tcsOther: "",
      invoiceAmount: "",
      total: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    getDataFromUrl(
      serverApi.SEARCH_MR_SUPPLIER + "/" + getCookie(cons.COMPANY_ID),
      this.handleSupplier
    );
    this.getList();
  }
  getList = () => {
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "getAllBillPass",
      this.getData(),
      this.handleList
    );
  };
  handleList = (resp) => {
    var list = resp;
    if (IsTallyAvailable("Tally Sync")) {
      list.column.push({
        Header: "Tally",
        accessor: "tally",
        sortable: false,
        filterable: false,
        minWidth: 100,
      });
      list.data = resp.data.map((prop, i) => {
        var obj = {
          ...prop,
          tally: (
            <div className="actions-right">
              {prop.tallySync === "Y" ? (
                <img src={TallyIcon} color={"danger"} width={50} height={50} />
              ) : null}
              {prop.tallySync === "R" ? (
                <img
                  src={TallyRedIcon}
                  width={50}
                  height={50}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.showMessage(prop.tallyMessage, false);
                  }}
                />
              ) : null}
            </div>
          ),
        };
        return obj;
      });
    }

    if (resp) {
      this.setState({ tableList: list, isRefresh: true });
    }
  };
  handleSupplier = (resp) => {
    if (resp.status === undefined) {
      this.setState({ supplierList: resp, loadSupplier: true });
    }
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_BILL_PASS_DETAILS_BY_ID +
          "J/" +
          id +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp;
    var list = resp.mrLineItems;
    var indentlist = [],
      filesList = [],
      totalValue = 0;
    if (header.billDocsUrl !== null) {
      filesList = header.billDocsUrl;
    }
    if (list !== null)
      indentlist = list.map((prop, key) => {
        var amt = (parseFloat(prop.weight) * parseFloat(prop.rate)).toFixed(2);

        totalValue = totalValue + parseFloat(amt);
        return {
          id: key,
          lineItemId: prop.srMrLineItemNo,
          materialHdrId: prop.materialGrnHdrId,
          // itemId: parseInt(prop.itemId),
          itemId: parseInt(prop.itemId),
          itemDesc: prop.itemDesc,
          itemGroupId: prop.itemGroupId,
          quantity: prop.quantity,
          quality: prop.qualityDesc,
          hsnCode: "",
          unit: prop.bale,
          weight: prop.weight,
          uom: prop.uom,
          warehouseno: prop.wareHouseNo,
          warehouseName: prop.warehouseName,
          rate: prop.rate,
          amount: amt,
          //displaying premium amount
          premiumAmount: prop.premiumAmount,
          waterDamageAmount: prop.waterDamageAmount,
          depId: prop.deptId,
          qualityCode: prop.actualQuality,
          claimRate: prop.claimRate,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to remove the data row */}
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;

                  /*
                                    data.find((o, i) => {
                                        if (o.id === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            //  data.splice(i,1);
                                            this.setState({
                                                alert: true,
                                                id: o.id,
                                                lineItemId: prop.id,
                                                materialHdrId: prop.materialGrnHdrId,
                                                updateItem: true,
                                                reloadSelect: true,
                                                item: o.itemId,
                                                itemDesc: o.itemDesc,
                                                quantity: o.quantity,
                                                selectedJuteQuality: o.qualityCode,
                                                quality: o.quality,
                                                hsnCode: o.hsnCode,
                                                rate: o.rate,
                                                weight: o.weight,
                                                amount: o.amount,
                                                itemGroupId: o.itemGroupId,
                                                depId: o.depId,
                                                warehouseNo: o.warehouseno,
                                                uom: o.uom,


                                            });

                                            return true;
                                        }
                                    });
*/
                }}
                color="primaryNoBackground"
                customClass="edit"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  /*                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            data.splice(i, 1);
                                            return true;
                                        }
                                    });
                                    this.setState({invoiceList: data});*/
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            </div>
          ),
        };
      });
    var total = parseFloat(totalValue.toFixed(2));
    if (header.tcsAmount > 0)
      total = (total - parseFloat(header.tcsAmount)).toFixed(2);

    // v               ar invoiceUrls=resp.billDocsUrl;

    // console.log("indentWeight " + header.indentWeight);
    if (header !== null) {
      //  alert(header.agentId);
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        approveStatus: header.approveStatus,
        billPassNo: header.billPassNo,
        billPassDate: header.billpassDate,
        mrNo: header.mrNo,
        mrPrintNo: header.mrPrintNo,
        ledgerGroupDesc: header.ledgerGroupDesc,
        statusDesc: header.statusDesc,
        //  mrDate: header.goodReceiptDateDesc,
        form_supplierId: header.supplierCode,
        grnNo: header.grnNo,
        form_poNO: header.poNum,
        poDate: header.poDate,
        challanNo: header.chalanNo,
        challanDate: header.chalanDate,
        contractNo: header.contractNo,
        contractDate: header.contractDate,
        exstn: header.exSTN,
        agentId: header.agentId,
        form_vehicleNo: header.vehicleNo,
        //  year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateDesc,
        mukamId: header.mukamId,
        totalAmount: totalValue,
        frieghtCharges: header.frieghtCharges,
        invoiceList: indentlist,
        /* filesList:filesList,
                challanList:header.billChallanUrl!==null?header.billChallanUrl:[],*/
        billPassStatus: header.status,
        invoiceNo: header.invoiceNo,
        invoiceDate: header.invoiceDate,
        paymentDueDate: header.paymentDueDate,
        invoiceAmount: header.invoiceAmount,
        invoiceReceivedDate: header.invoiceRecievedDate,
        claimAmount: header.claimAmount,
        netTotal: Math.round(total).toFixed(2),
        tdsPayable: header.tdsPayable !== null ? header.tdsPayable : "N",
        tdsReason: header.tdsReason,
        tdsAmount: header.tdsAmount,
        branchId: header.branchId,
        tcsPercentage: header.tcsPercentage,
        tcsAmount:
          header.tcsAmount !== null
            ? parseFloat(header.tcsAmount).toFixed(2)
            : 0.0,
        ledgerName: header.ledgerName,
        roundoff: header.roundOff,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "2/" +
          header.billPassNo +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "3/" +
          header.billPassNo +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceChallanFiles
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "29/" +
          header.billPassNo +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceSupportFiles
      );
    }
  };
  handleInvoiceSupportFiles = (resp) => {
    if (resp.status && resp.data !== undefined) {
      this.setState({ supportFilesList: resp.data });
    }
  };

  handleInvoiceFiles = (resp) => {
    if (resp.data) {
      this.setState({ filesList: resp.data });
    }
  };
  handleInvoiceChallanFiles = (resp) => {
    if (resp.status) {
      this.setState({ challanList: resp.data });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getAgentName = (name) => {
    this.setState({
      agentName: name,
    });
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemDesc: values[0],
      itemGroupId: values[1],
      uom: values[2],
      hsnCode: values[4],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      loadquality: val,
    });
  };
  getQualityDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      quality: values[0],
      stock: values[1],
      rate: values[2],
      lastPurchase: values[3],
      allowableMoisture: values[4],
    });
    console.log("indentQuantity " + this.state.stock);
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getData = () => {
    if (getCookie(cons.USER_GRP_ID) == "99") {
      var status = 3;
      console.log(`printing is refresh `, this.state.status);
      if (this.state.status == "") {
        var status = 3;
      } else {
        status = this.state.status;
      }
      console.log(`printing is refresh `, status);
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        supplier: getCookie(cons.SUPP_CODE),
        mrNo: this.state.searchMrNo,
        type: "J",
        status: status,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        financialYear: getCookie(cons.ACADEMIC_YEAR),
        gateEntryNo: this.state.gateEntryNo,
      };
    } else {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        supplier: this.state.supplierId,
        mrNo: this.state.searchMrNo,
        type: "J",
        status: this.state.status,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        financialYear: getCookie(cons.ACADEMIC_YEAR),
        gateEntryNo: this.state.gateEntryNo,
      };
    }
    return data;
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });

    //  this.dateChanged();
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / 150))
      );
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    if (event.target.id === "tcsPercentageO") {
      console.log("val " + value);
      this.setState({
        tcsOther: value,
      });
    }

    if (event.target.id === "vehicleQuantity") {
      console.log("val " + value);
      if (value !== null && value !== "") {
        if (this.state.selectedVehicleType !== "")
          this.setState({
            indentQuantity:
              parseInt(value) * parseInt(this.state.selectedIndentQuantity),
          });
        else {
          value = "";
          this.basicAlert("Please Select Vehicle Type..!!");
        }
      }
    }
    if (event.target.id === "stock") {
      console.log("val " + this.state.indentQuantity);
      this.setState({
        stock: this.state.stock,
      });
    }

    if (event.target.id === "quantity") {
      console.log("val " + value);
      if (value !== null && value !== "") {
        // if (this.state.unitConversion.value === 3) {
        //     var calWeight = parseInt(this.state.indentQuantity) * parseInt(value) / 100;
        // } else {
        //     var calWeight = parseInt(value) * parseInt(150) / 100;
        // }
        var calWeight = (parseInt(value) * parseInt(150)) / 100;
        var Calrate = this.state.rate;

        if (Calrate !== "") {
          var calAmount = parseFloat(Calrate) * parseFloat(calWeight);
          this.setState({
            amount: calAmount,
          });
        }
        this.setState({
          weight: calWeight,
        });
      } else {
        this.setState({
          weight: "",
          amount: "",
        });
      }
    }
    // var invoiceTotalAmount = this.state.invoiceAmount;
    // var invoiceTdsAmount = this.state.tdsAmount;
    // console.log(`printing tds amount ${invoiceTotalAmount - invoiceTdsAmount}`);

    // invoice amount logic

    if (event.target.id === "tdsAmount") {
      var amount = parseFloat(this.state.invoiceAmount);
      if (event.target.value !== "") {
        amount = amount - parseFloat(event.target.value);
        this.setState({ invoiceAmount: amount.toFixed(2) });
      } else {
        this.setState({ invoiceAmount: amount.toFixed(2) });
      }
    }

    if (event.target.id === "claimAmount") {
      if (event.target.value !== "") {
        this.setState({
          netTotal: Math.round(
            this.state.totalAmount - parseFloat(event.target.value)
          ).toFixed(2),
        });
      } else {
        this.setState({
          netTotal: Math.round(this.state.totalAmount).toFixed(2),
        });
      }
    }
    if (event.target.id === "roundoff") {
      var round =
        this.state.totalAmount > 0 && this.state.tcsAmount > 0
          ? (
              parseFloat(this.state.totalAmount) -
              this.claimAmountFunction() +
              parseFloat(this.state.tcsAmount)
            ).toFixed(2)
          : (
              parseFloat(this.state.totalAmount) - this.claimAmountFunction()
            ).toFixed(2);

      round = round.split(".");
      var total = parseFloat(round[0]);
      round = "0." + round[1];
      var max = 1 - parseFloat(round);
      if (value !== "") {
        if (parseFloat(value) > 0) {
          if (parseFloat(value) <= max) {
            this.setState({
              [event.target.id]: value,
            });
          }
        } else {
          console.log("--round", parseFloat(value));
          if (value === "-")
            this.setState({
              [event.target.id]: value,
            });

          if (parseFloat(value) >= parseFloat("-" + round)) {
            this.setState({
              [event.target.id]: value,
            });
          }
        }
      }
    } else
      this.setState({
        [event.target.id]: value,
      });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/
    if (stateKey === "tcsPercentage") {
      var tcsAmount =
        ((parseFloat(this.state.totalAmount) -
          getDecimal(this.claimAmountFunction())) *
          selectedValue) /
        100;
      this.setState({ tcsAmount: Math.round(tcsAmount).toFixed(2) });
    }

    if (stateKey === "suppielr_id") {
      this.setState({
        loadGateEntry: true,
      });
    }
    if (stateKey === "item") {
      this.setState({
        loadquality: true,
      });
    }
    if (
      stateKey === "selectedJuteType" &&
      selectedValue > 0 &&
      selectedValue !== type
    ) {
      this.setState({
        hideJuteType: true,
      });
    }
    if (
      stateKey === "selectedMukam" &&
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeMukam
    ) {
      this.unitConvertionAlert(
        "Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!"
      );
    } else if (stateKey === "selectedMukam")
      this.setState({
        [stateKey]: selectedValue,
        canChangeMukam: false,
      });
    else
      this.setState({
        [stateKey]: selectedValue,
      });
  };
  afterRefreshGateEntry = (val) => {
    this.setState({
      loadGateEntry: val,
    });
  };
  getGateEntryDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      form_poNO: values[0],
      poDate: values[1],
      challanNo: values[2],
      challanDate: values[3],
      form_vehicleNo: values[4],
    });
    // console.log("indentQuantity " + this.state.stock);
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      if (this.state.deleteChallan) {
        var arr = this.state.challanList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          challanList: arr,
          deleteChallan: false,
          fileId: "",
        });
      } else if (this.state.deleteSupportFile) {
        var arr = this.state.supportFilesList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          supportFilesList: arr,
          deleteSupportFile: false,
          fileId: "",
        });
      } else {
        var arr = this.state.filesList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          filesList: arr,
          deleteInvoice: false,
          fileId: "",
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };

  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;
      if (this.state.uploadChallan) {
        var arr = this.state.challanList;
        data = {
          fileUploadId: data.id,
          fileName: this.state.fileName,
          fileExtension: this.state.fileType,
          retrievalPath: data.fileUrl,
          sourceMenu: 3,
        };
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadChallan: false,
          challanList: arr,
          basicAlert: null,
        });
      } else if (this.state.uploadSupportFile) {
        var arr = this.state.supportFilesList;
        data = {
          fileUploadId: data.id,
          fileName: this.state.fileName,
          fileExtension: this.state.fileType,
          retrievalPath: data.fileUrl,
          sourceMenu: 2,
        };
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadSupportFile: false,
          supportFilesList: arr,
          basicAlert: null,
        });
      } else {
        var arr = this.state.filesList;
        data = {
          fileUploadId: data.id,
          fileName: this.state.fileName,
          fileExtension: this.state.fileType,
          retrievalPath: data.fileUrl,
          sourceMenu: 2,
        };
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadInvoice: false,
          filesList: arr,
          basicAlert: null,
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      billPassNo,
      uploadSupportFile,
    } = this.state;

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        2,
        fileName,
        billPassNo,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (uploadSupportFile) {
      uploadFile(
        fileName,
        file,
        fileType,
        29,
        fileName,
        billPassNo,
        this.handleInvoiceResp
      );
    }
    if (uploadChallan) {
      uploadFile(
        fileName,
        file,
        fileType,
        3,
        fileName,
        billPassNo,
        this.handleInvoiceResp
      );
      //   uploadInvoice(serverApi.UPLOAD_CHALLAN, "C", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }

    if (this.state.deleteChallan) {
      // var url = serverApi.DELETE_BILL_INVOICE + this.state.challanId + "/" + getCookie(cons.USER_NAME) + "/" + getCookie(cons.CIPHER);
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.challanId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteSupportFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.supportfileId,
        this.handleDeleteInvoiceResp
      );
    }

    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      // poDate:'',
      // challanNo:'',
      // challanDate:'',
      // contractNo:'',
      // contractDate:'',
      // exstn:'',
      // agentId:'',
      // form_vehicleNo:'',
      //
      // form_poNO:'',
      // grnNo:'',
      // form_supplierId:'',

      //   unitConvertionId:"0",
      //  unitConvertion:"",
      quantity: "",
      stock: "",
    });
  }
  //log

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getList();
    }
    this.setState({
      billPassStatus: "",
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      lineItemId: "",
      materialHdrId: "",
      totalAmount: "",
      frieghtCharges: "",
      warehouseName: "",
      tdsPayable: "N",
      tdsReason: "",
      tdsAmount: "",
      branchId: "",
      tcsPercentage: "",
      tcsAmount: "",
      fileType: "",
      filesList: [],
      challanList: [],
      roundoff: null,
      supportFilesList: [],
    });
  }
  dateChanged = (myDate) => {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  claimAmountFunction = () => {
    var calAmount = 0;
    var totalClaimAmount = 0;
    this.state.invoiceList.map((prop, key) => {
      calAmount = prop.weight * prop.claimRate;
      totalClaimAmount += calAmount;
    });
    totalClaimAmount = totalClaimAmount.toFixed(2);
    totalClaimAmount = parseFloat(totalClaimAmount);
    return totalClaimAmount;
  };
  roundOffAmountFunction = () => {
    var round =
      this.state.netTotal > 0 && this.state.tcsAmount > 0
        ? (
            parseFloat(this.state.netTotal) -
            this.claimAmountFunction() +
            parseFloat(this.state.tcsAmount)
          ).toFixed(2)
        : (
            parseFloat(this.state.netTotal) - this.claimAmountFunction()
          ).toFixed(2);
    round = round.split(".");
    round = round[1];
    return round;
  };
  onRejectButtonClick = () => {
    /*        var invoiceList = this.state.invoiceList.map((prop, key) => {
            return ({
                "id": prop.lineItemId,
                "materialGrnHdrId": prop.materialHdrId,
                "quantity": prop.quantity,
                "marka": '',
                "advisedQuality": prop.qualityCode,
                "actualQuality": prop.qualityCode,
                "advisedWeight": prop.weight,
                "actualWeight": prop.weight,
                "deviation": 0,
                "rate": prop.rate,
                "claimsQuality": "",
                "claimsCondition": "16.6",
                "warehouseNo": prop.warehouseno,
                "remarks": "",
                "totalPrice": prop.amount,
                "itemId": prop.itemId,
                "debitNotesFlag": "",
                "quantityUnit": this.state.uom,
                "issuableWeight": "0",
                "bale": 0,
                "loose": 0,
                "actualBale": 0,
                "actualLoose": 0,
                "advisedItemId": this.state.itemId
            })
        });

        var data = {
            "materialGoodReceiveHeader": {
                "id": this.state.mrNo,
                "goodReceiptDate": this.dateChanged(this.state.mrDate),
                "supplierId": this.state.form_supplierId,
                "poId": this.state.form_poNO,
                "poDate": this.dateChanged(this.state.poDate),
                "contractNo": this.state.contractNo,
                "contractDate": this.dateChanged(this.state.contractDate),
                "chalanNo": this.state.challanNo,
                "chalanDate": this.dateChanged(this.state.challanDate),
                "warehouseNo": '',
                "exSTN": this.state.exstn,
                "submitter": getCookie(cons.USERID),
                "agentId": this.state.agentId,
                "agentName": this.state.agentName,
                "sourceId": "64",
                "vehicleNo": this.state.form_vehicleNo,
                "weight": 0,
                "mukamId": this.state.mukamId,
                "modBy": getCookie(cons.USER_NAME),
                "status": "4"
            },
            "materialGRItemList": invoiceList
        };*/
    var data = {
      id: this.state.billPassNo,
      status: "4",
      userName: getCookie(cons.USER_NAME),
      userId: getCookie(cons.USERID),
    };

    this.handleLoading();
    requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent);
  };
  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        billPassNo,
        invoiceNo,
        invoiceDate,
        paymentDueDate,
        invoiceAmount,
        invoiceReceivedDate,
        ledger,
        filesList,
        netTotal,
        payableTax,
        tdsPayable,
        tdsReason,
        tdsAmount,
        tcsPercentage,
        tcsAmount,
        challanList,
        billPassStatus,
        roundoff,
        mrPrintNo,
      } = this.state;
      /* var total=(parseFloat(this.state.netTotal)-this.claimAmountFunction()).toFixed(2);
            if(tcsAmount>0)
                total=(parseFloat(this.state.netTotal)+parseFloat(tcsAmount)).toFixed(2);*/

      var round =
        this.state.totalAmount > 0 && tcsAmount > 0
          ? (
              parseFloat(this.state.totalAmount) -
              this.claimAmountFunction() +
              parseFloat(tcsAmount)
            ).toFixed(2)
          : (
              parseFloat(this.state.totalAmount) - this.claimAmountFunction()
            ).toFixed(2);

      round = round.split(".");
      var total = "";
      if (this.state.tdsAmount != "") {
        total = parseFloat(round[0]) - this.state.tdsAmount;
      } else {
        total = parseFloat(round[0]);
      }
      round = "0." + round[1];
      var showRound = false;
      if (parseFloat(round) > 0) {
        showRound = true;
      }
      var max = (1 - parseFloat(round)).toFixed(2);
      round = parseFloat("-" + round);

      var totalRound = this.state.roundoff;
      if (roundoff !== null && roundoff !== undefined) {
        console.log("round", round, this.state.roundoff);

        if (parseFloat(this.state.roundoff) === parseFloat(max))
          total = Math.round(
            total + parseFloat(this.state.roundoff) - round
          ).toFixed(2);
        else if (parseFloat(this.state.roundoff) === parseFloat(round))
          total = Math.round(total).toFixed(2);
        else {
          if (parseFloat(max) <= 0.5) {
            totalRound = max;
            total = Math.round(total + parseFloat(max) - round).toFixed(2);
          } else {
            totalRound = round;
            total = Math.round(total).toFixed(2);
          }
        }
      } else {
        if (parseFloat(max) <= 0.5) {
          totalRound = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          totalRound = round;
          total = Math.round(total).toFixed(2);
        }
      }

      var data = {
        id: this.state.billPassNo,
        status: "",
        userName: getCookie(cons.USER_NAME),
        invoiceNo: this.state.invoiceNo,
        invoiceDate: this.dateChanged(this.state.invoiceDate),
        paymentDueDate: this.dateChanged(this.state.paymentDueDate),
        invoiceAmount: this.state.invoiceAmount,
        ledger: this.state.ledger,
        invoiceRecievedDate: this.dateChanged(this.state.invoiceReceivedDate),
        userId: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        claimAmount: this.claimAmountFunction(),
        netAmount: total,
        finnacialYear: this.state.year,
        tdsPayable: tdsPayable,
        tdsReason: tdsReason,
        tdsAmount: tdsAmount,
        branchId: this.state.branchId,
        tcsPercentage: tcsPercentage,
        tcsAmount: parseFloat(tcsAmount),
        roundOff: totalRound,
        mrNo: mrPrintNo,
      };
      if (
        (filesList.length > 0 || challanList.length > 0) &&
        billPassStatus === "1"
      ) {
        data.status = "23";
      }

      console.log("data", JSON.stringify(data));
      if (
        billPassNo !== "" &&
        invoiceNo !== "" &&
        invoiceDate !== "" &&
        paymentDueDate !== "" &&
        invoiceAmount > 0 &&
        invoiceReceivedDate !== "" &&
        tdsPayable !== ""
      ) {
        console.log("total----------", total);
        console.log("invoiceAmount-------------", invoiceAmount);

        if (
          parseFloat(total).toFixed(2) == parseFloat(invoiceAmount).toFixed(2)
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent);
        } else {
          this.basicAlert("The net total and invoice amount should be equal!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onApproveButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        billPassNo,
        invoiceNo,
        invoiceDate,
        paymentDueDate,
        invoiceAmount,
        invoiceReceivedDate,
        ledger,
        filesList,
        netTotal,
        payableTax,
        tdsPayable,
        tdsReason,
        tdsAmount,
        tcsPercentage,
        tcsAmount,
        roundoff,
        mrPrintNo,
      } = this.state;
      /*   var total=(parseFloat(this.state.netTotal)-this.claimAmountFunction()).toFixed(2);
            if(tcsAmount>0)
                total=(parseFloat(this.state.netTotal)+parseFloat(tcsAmount)).toFixed(2);*/
      var round =
        this.state.totalAmount > 0 && tcsAmount > 0
          ? (
              parseFloat(this.state.totalAmount) -
              this.claimAmountFunction() +
              parseFloat(tcsAmount)
            ).toFixed(2)
          : (
              parseFloat(this.state.totalAmount) - this.claimAmountFunction()
            ).toFixed(2);

      round = round.split(".");
      var total = "";
      if (this.state.tdsAmount != "") {
        total = parseFloat(round[0]) - this.state.tdsAmount;
      } else {
        total = parseFloat(round[0]);
      }
      round = "0." + round[1];
      var showRound = false;
      if (parseFloat(round) > 0) {
        showRound = true;
      }
      var max = (1 - parseFloat(round)).toFixed(2);
      round = parseFloat("-" + round);

      var totalRound = this.state.roundoff;
      if (roundoff !== null && roundoff !== undefined) {
        console.log("round", round, this.state.roundoff);

        if (parseFloat(this.state.roundoff) === parseFloat(max))
          total = Math.round(
            total + parseFloat(this.state.roundoff) - round
          ).toFixed(2);
        else if (parseFloat(this.state.roundoff) === parseFloat(round))
          total = Math.round(total).toFixed(2);
        else {
          if (parseFloat(max) <= 0.5) {
            totalRound = max;
            total = Math.round(total + parseFloat(max) - round).toFixed(2);
          } else {
            totalRound = round;
            total = Math.round(total).toFixed(2);
          }
        }
      } else {
        if (parseFloat(max) <= 0.5) {
          totalRound = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          totalRound = round;
          total = Math.round(total).toFixed(2);
        }
      }

      var data = {
        id: this.state.billPassNo,
        status: this.state.billPassStatus,
        userName: getCookie(cons.USER_NAME),
        invoiceNo: this.state.invoiceNo,
        invoiceDate: this.dateChanged(this.state.invoiceDate),
        paymentDueDate: this.dateChanged(this.state.paymentDueDate),
        invoiceAmount: this.state.invoiceAmount,
        ledger: this.state.ledger,
        invoiceRecievedDate: this.dateChanged(this.state.invoiceReceivedDate),
        userId: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        claimAmount: this.claimAmountFunction(),
        netAmount: total,
        finnacialYear: this.state.year,
        tdsPayable: tdsPayable,
        tdsReason: tdsReason,
        tdsAmount: tdsAmount,
        branchId: this.state.branchId,
        tcsPercentage: tcsPercentage,
        tcsAmount: parseFloat(tcsAmount),
        approveStatus: true,
        roundOff: totalRound,
        mrNo: mrPrintNo,
      };

      console.log("data", JSON.stringify(data));

      if (
        billPassNo !== "" &&
        invoiceNo !== "" &&
        invoiceDate !== "" &&
        paymentDueDate !== "" &&
        invoiceAmount > 0 &&
        invoiceReceivedDate !== "" &&
        filesList.length > 0 &&
        tdsPayable !== ""
      ) {
        if (
          parseFloat(total).toFixed(2) == parseFloat(invoiceAmount).toFixed(2)
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent);
        } else {
          this.basicAlert("The net total and invoice amount should be equal!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { tcsPercentage, tcsAmount } = this.state;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          itemId: prop.juteType,
          itemGroupId: prop.grpId,
          qualityCode: prop.quality,
          deptId: prop.deptId,
          stock: prop.stockValue,
          indentQuantity: prop.quantity,
          unitId: prop.unit,
          additionalRequirement: "ra",
        };
      });

      var data = {
        indentHeader: {
          type: "J",
          mukam: this.state.selectedMukam,
          vehicleTypeId: this.state.selectedVehicleType,
          vehicleQuantity: this.state.vehicleQuantiy,
          submitter: getCookie(cons.USERID),
          indentDate: this.dateChanged(),
          finnacialYear: this.state.year,
          unitConversion: this.state.unitConvertionName,
          companyId: getCookie(cons.COMPANY_ID),
          branchId: this.state.branchId,
          tcsPercentage: tcsPercentage,
          tcsAmount: parseFloat(tcsAmount),
        },
        indentItems: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (
        this.state.selectedMukam !== "0" &&
        this.state.selectedMukam !== "" &&
        this.state.unitConvertion !== "" &&
        this.state.selectedVehicleType !== "" &&
        this.state.invoiceList.length >= 1
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_INDENT, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var sumOfQty =
      parseInt(this.state.sumOFQuantities) + parseInt(this.state.quantity);
    console.log("quantity " + sumOfQty);
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    var addItemObj = {
      id: itemIndex,
      itemId: this.state.item,
      itemDesc: this.state.itemDesc,
      // unitConvertion:"",
      quantity: this.state.quantity,
      qualityCode: this.state.selectedJuteQuality,
      quality: this.state.selectedJuteQuality,
      unit: this.state.quantity,
      weight: this.state.weight,
      uom: this.state.uom,
      warehouseno: this.state.warehouseNo,
      warehouseName: this.state.warehouseName,
      rate: this.state.rate,
      amount: this.state.amount,
      itemGroupId: this.state.itemGroupId,
      depId: this.state.depId,
      lineItemId: this.state.lineItemId,
      materialHdrId: this.state.materialHdrId,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to remove the data row */}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // alert(o.qualityId);
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  //  data.splice(i,1);
                  this.setState({
                    alert: true,
                    id: o.id,
                    lineItemId: o.lineItemId,
                    materialHdrId: o.materialGrnHdrId,
                    updateItem: true,
                    reloadSelect: true,
                    item: o.itemId,
                    itemDesc: o.itemDesc,
                    quantity: o.unit,
                    selectedJuteQuality: o.quality,
                    quality: o.quality,
                    rate: o.rate,
                    weight: o.weight,
                    amount: o.amount,
                    itemGroupId: o.itemGroupId,
                    depId: o.depId,
                    warehouseNo: o.warehouseno,
                    warehouseName: o.warehouseName,
                    uom: o.uom,
                  });

                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="edit"
          >
            <Edit />
          </IconButton>{" "}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  data.splice(i, 1);
                  return true;
                }
              });
              this.setState({
                invoiceList: data,
                selectedJuteType: "",
                selectedJuteName: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                quantity: "",
                selectedUnit: "",
                selectedDeptId: "",
                selectedGrpId: "",
                stock: "",
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };

    if (
      this.state.item !== "" &&
      this.state.selectedJuteQuality !== "" &&
      this.state.quantity !== "" &&
      this.state.warehouseNo !== "" &&
      this.state.weight !== "" &&
      this.state.amount !== ""
    ) {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else arr.splice(arr.length, 0, addItemObj);

      this.setState({
        hideJuteType: false,
        loading: true,
        invoiceList: arr,
      });
      this.hideAlert();
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        if (this.state.uploadChallan) {
          this.setState({
            file: file,
            fileName: file.name,
            fileType: fileName[1],
            //filesList: arr,
            // imagePreviewUrl: reader.result
          });
          this.InvoicebasicAlert(
            "Do you want to upload challan " + file.name + "?"
          );
        } else if (this.state.uploadSupportFile) {
          this.setState({
            file: file,
            fileName: file.name,
            fileType: fileName[1],
            //filesList: arr,
            uploadSupportFile: true,
            // imagePreviewUrl: reader.result
          });

          this.InvoicebasicAlert(
            "Do you want to upload file " + file.name + "?"
          );
        } else {
          this.setState({
            file: file,
            fileName: file.name,
            fileType: fileName[1],
            //filesList: arr,
            uploadInvoice: true,
            // imagePreviewUrl: reader.result
          });
        }

        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    console.log(this.state);
    //if(this.state.selectedMukam !=='0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType!== "")
    this.setState({
      loading: true,
      alert: true,
      updateItem: false,
    });
    // else {
    //     this.basicAlert("Please Enter All Mandatory Fields..!!");
    // }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  handleSelectedValues = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeUnit &&
      this.state.unitConversion.value !== defValue.value
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConvertion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };

  render() {
    const { classes } = this.props;
    const { tcsAmount } = this.state;
    //console.log("jute type",selectedJuteType);
    //add item button click
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    const statusOptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 3, label: "APPROVED", name: "APPROVED" },
      { value: 4, label: "REJECTED", name: "REJECTED" },
      { value: 5, label: "CLOSED", name: "CLOSED" },
      { value: 6, label: "CANCELLED", name: "CANCELLED" },
      { value: 25, label: "PAID", name: "PAID" },
    ];
    const inputFocus = {
      /*classes: {
                root: classes.cssOutlinedInput,
                focused: classes.notchedOutline,
                notchedOutline: classes.notchedOutline,
            },*/
      autoComplete: "off",
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    var round =
      this.state.totalAmount > 0 && tcsAmount > 0
        ? (
            parseFloat(this.state.totalAmount) -
            this.claimAmountFunction() +
            parseFloat(tcsAmount)
          ).toFixed(2)
        : (
            parseFloat(this.state.totalAmount) - this.claimAmountFunction()
          ).toFixed(2);

    round = round.split(".");
    var total = "";
    if (this.state.tdsAmount != "") {
      total = parseFloat(round[0]) - this.state.tdsAmount;
    } else {
      total = parseFloat(round[0]);
    }
    round = "0." + round[1];
    var showRound = false;
    if (parseFloat(round) > 0) {
      showRound = true;
    }
    var max = (1 - parseFloat(round)).toFixed(2);
    round = parseFloat("-" + round);

    var roundoff = this.state.roundoff;
    if (roundoff !== null && roundoff !== undefined) {
      console.log("round", round, this.state.roundoff);

      if (parseFloat(this.state.roundoff) === parseFloat(max))
        total = Math.round(
          total + parseFloat(this.state.roundoff) - round
        ).toFixed(2);
      else if (parseFloat(this.state.roundoff) === parseFloat(round))
        total = Math.round(total).toFixed(2);
      else {
        if (parseFloat(max) <= 0.5) {
          roundoff = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          roundoff = round;
          total = Math.round(total).toFixed(2);
        }
      }
    } else {
      if (parseFloat(max) <= 0.5) {
        roundoff = max;
        total = Math.round(total + parseFloat(max) - round).toFixed(2);
      } else {
        roundoff = round;
        total = Math.round(total).toFixed(2);
      }
    }

    /*  var roundoff=0;
        if(parseFloat(round)>0){
            if(parseFloat(round)>0.5){
                var val=(1-parseFloat(round)).toFixed(2);
                roundoff=parseFloat("+"+val)
            }else {
                roundoff=parseFloat("-"+round);

            }
        }
        if(this.state.roundoff!==null&&this.state.roundoff!==undefined){
            console.log("round off",this.state.roundoff);
            total=Math.round(total+parseFloat(this.state.roundoff)+parseFloat(round)).toFixed(2);
        }else {
            if(parseFloat(round)>0.5){
                total=total+parseFloat(round);
            }
            total=Math.round(total).toFixed(2);
        }
*/
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"item"}
                                        defValue={this.state.item}
                                        onSelect={this.onSelect}
                                        getName={this.getitemDetails.bind(this)}
                                        stateKey="item"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          32 +
                                          "/getAllItemByMukam/" +
                                          getCookie(cons.COMPANY_ID)
                                        }
                                        value={this.state.item}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quality
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={
                                          this.state.selectedJuteQuality
                                        }
                                        id={"selectedJuteQuality"}
                                        onSelect={this.onSelect}
                                        getName={this.getQualityDetails}
                                        isRefresh={this.state.loadquality}
                                        setIsRefresh={this.afterRefreshquality}
                                        stateKey="selectedJuteQuality"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.item +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/getQualityByItem"
                                        }
                                        //value={this.state.selectedJuteQuality}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Stock
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        classes={{
                                          root: classes.cssOutlinedInput,
                                          focused: classes.cssFocused,
                                          notchedOutline:
                                            classes.notchedOutline,
                                        }}
                                        disabled={true}
                                        id="stock"
                                        className={classes.textField}
                                        //  onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.stock}
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        value={this.state.quantity}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        iType={"number"}
                                        inputProps={{
                                          style: {
                                            height: 30,
                                            color: "#000",
                                          },
                                          classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline:
                                              classes.notchedOutline,
                                          },
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        iType={"number"}
                                        value={this.state.rate}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Warehouse No
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="warehouseNo"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        getName={(name) => {
                                          this.setState({
                                            warehouseName: name,
                                          });
                                        }}
                                        value={this.state.warehouseNo}
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="weight"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.weight}
                                    iType={"number"}
                                    inputProps={{
                                      style: { height: 30, color: "#000" },
                                      classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                      },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            QTL
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.amount}
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Bill Pass Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            /*
                                                          onChange={(dateObj) => {
                                                              this.timeChanged(dateObj, "billPassDate")
                                                          }}
  */
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "billPassDate",
                              value: this.state.billPassDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Bill Pass No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="billPassNo"
                          value={this.state.billPassNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          MR No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="mrNo"
                          value={this.state.mrPrintNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.updateView}
                          id={"form_supplierId"}
                          defValue={this.state.form_supplierId}
                          onSelect={this.onSelect}
                          stateKey="form_supplierId"
                          staticData={this.state.supplierList}
                          isRefresh={this.state.loadSupplier}
                          setIsRefresh={(val) => {
                            this.setState({ loadSupplier: false });
                          }}
                          // url={serverApi.SEARCH_SUPPLIER+"/"+getCookie(cons.COMPANY_ID)}

                          value={this.state.form_supplierId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Gate Entry No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="grnNo"
                          value={this.state.grnNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />

                        {/*
                                                <SelectboxTemp id={"grnNo"}
                                                               defValue={this.state.grnNo}
                                                               onSelect={this.onSelect}
                                                               isRefresh={this.state.loadGateEntry}
                                                               setIsRefresh={this.afterRefreshGateEntry}
                                                               getName={this.getGateEntryDetails}
                                                               stateKey="grnNo"
                                                               url={serverApi.GET_GATE_ENTRY_LIST_BY_SUP + this.state.form_supplierId}
                                                    // value={this.state.grnNo}
                                                />
*/}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          PO NO
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_poNO"
                          value={this.state.form_poNO}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          PO Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            /*
                                                          onChange={(dateObj) => {
                                                              this.timeChanged(dateObj, "poDate")
                                                          }}
  */
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "poDate",
                              value: this.state.poDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan NO
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="challanNo"
                          value={this.state.challanNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            /*
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "challanDate")
                                                        }}
*/
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "challanDate",
                              value: this.state.challanDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Agent
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.updateView}
                          id={"agentId"}
                          defValue={this.state.agentId}
                          onSelect={this.onSelect}
                          stateKey="agentId"
                          getName={this.getAgentName}
                          url={
                            serverApi.AGENTS_LIST + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.agentId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_vehicleNo"
                          value={this.state.form_vehicleNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          inputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {console.log(this.state.invoiceList.length + " length")}
                        <LineItemTable
                          data={this.state.invoiceList}
                          loading={this.state.loading}
                          columns={[
                            {
                              Header: "Item Desc",
                              accessor: "itemDesc",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Quality",
                              accessor: "quality",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "Quantity",
                              accessor: "quantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Claim Rate",
                              accessor: "claimRate",
                              minWidth: 80,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Weight",
                              accessor: "weight",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "UOM",
                              accessor: "uom",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Warehouse No",
                              accessor: "warehouseName",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "Rate",
                              accessor: "rate",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Amount",
                              accessor: "amount",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Prem Amt",
                              accessor: "premiumAmount",
                              minWidth: 100,
                              style: {
                                color: "#000",
                                textAlign: "right",
                              },
                            },
                            {
                              Header: "Water Dmg Amt",
                              accessor: "waterDamageAmount",
                              minWidth: 100,
                              style: {
                                color: "#000",
                                textAlign: "right",
                              },
                            },
                            /*
                                                        {
                                                            Header: "Actions",
                                                            minWidth: 80,
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
*/
                          ]}
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={8} />
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              Total Amount
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <TextField
                              disabled={true}
                              id="totalAmount"
                              inputClass={classes.textFieldRight}
                              value={getDecimal(this.state.totalAmount)}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={{
                                autoComplete: "off",
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={8} />
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              Claim Amount
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <TextField
                              id="claimAmount"
                              disabled={true}
                              className={classes.textField}
                              inputClass={classes.textFieldRight}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              value={getDecimal(this.claimAmountFunction())}
                              variant="outlined"
                              style={{ height: 30 }}
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      {showRound ? (
                        <ItemGrid xs={12} sm={12}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={8} />
                            <ItemGrid
                              xs={12}
                              sm={2}
                              style={{ marginTop: "10px" }}
                            >
                              <InputLabel className={classes.label}>
                                Round Off
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={1}>
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={
                                      parseFloat(roundoff) === parseFloat(max)
                                        ? 1
                                        : 0
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        roundoff: max,
                                      });
                                    }}
                                    name="radio button demo"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord
                                        className={classes.radioChecked}
                                      />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                }}
                                label={max + ""}
                              />
                            </ItemGrid>
                            <ItemGrid xs={12} sm={1}>
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={
                                      parseFloat(roundoff) === parseFloat(round)
                                        ? 1
                                        : 0
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        roundoff: round,
                                      });
                                    }}
                                    name="radio button demo"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord
                                        className={classes.radioChecked}
                                      />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                }}
                                label={round}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={8} />
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              TCS Amount
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <TextField
                              disabled={true}
                              id="tcsAmount"
                              defValue={this.state.tcsAmount}
                              value={this.state.tcsAmount}
                              inputClass={classes.textFieldRight}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={8} />
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              TDS Amount
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <TextField
                              disabled={true}
                              id="tdsAmount"
                              defValue={this.state.tdsAmount}
                              value={this.state.tdsAmount}
                              inputClass={classes.textFieldRight}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      {/*
                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={8}>

                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>
                                                        <InputLabel className={classes.label}>
                                                            Round Off amount
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={2}>
                                                        <TextField
                                                            defValue={roundoff}
                                                            id="roundoff"
                                                            className={classes.textField}
                                                            inputClass={classes.textFieldRight}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            value={this.state.roundoff}
                                                            variant="outlined"
                                                            iType={"number"}
                                                            InputProps={inputFocus}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
*/}

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={8} />
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              Net Total
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <TextField
                              disabled={true}
                              id="netTotal"
                              inputClass={classes.textFieldRight}
                              value={total}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={{
                                autoComplete: "off",
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={8} />
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              Freight Charges
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <TextField
                              disabled={true}
                              id="frieghtCharges"
                              className={classes.textField}
                              inputClass={classes.textFieldRight}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              value={this.state.frieghtCharges}
                              variant="outlined"
                              style={{ height: 30 }}
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid
                        xs={12}
                        style={{
                          height: 2,
                          backgroundColor: "#00acc1",
                          marginTop: "15px",
                        }}
                      />

                      {this.state.updateView ? (
                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12}>
                              <InputLabel className={classes.label}>
                                Vendor Invoice Details :
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Invoice No
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={
                                          getCookie(cons.USER_GRP_ID) == "99"
                                        }
                                        id="invoiceNo"
                                        value={this.state.invoiceNo}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Invoice Date
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <FormControl variant="" fullWidth>
                                        <Datetime
                                          closeOnSelect={true}
                                          timeFormat={false}
                                          onChange={(dateObj) => {
                                            this.timeChanged(
                                              dateObj,
                                              "invoiceDate"
                                            );
                                          }}
                                          height={"15px"}
                                          formControlProps={{
                                            height: "15px",
                                            inlineBlock: "none",
                                          }}
                                          inputProps={{
                                            id: "invoiceDate",
                                            value: this.state.invoiceDate,
                                            autoComplete: "off",
                                          }}
                                        />
                                      </FormControl>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Payment Due Date
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <FormControl variant="" fullWidth>
                                        <Datetime
                                          closeOnSelect={true}
                                          timeFormat={false}
                                          onChange={(dateObj) => {
                                            this.timeChanged(
                                              dateObj,
                                              "paymentDueDate"
                                            );
                                          }}
                                          height={"15px"}
                                          formControlProps={{
                                            height: "15px",
                                            inlineBlock: "none",
                                          }}
                                          inputProps={{
                                            id: "paymentDueDate",
                                            value: this.state.paymentDueDate,
                                            autoComplete: "off",
                                          }}
                                        />
                                      </FormControl>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Invoice Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={
                                          getCookie(cons.USER_GRP_ID) == "99"
                                        }
                                        id="invoiceAmount"
                                        value={this.state.invoiceAmount}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Invoice Received Date
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <FormControl variant="" fullWidth>
                                        <Datetime
                                          closeOnSelect={true}
                                          timeFormat={false}
                                          onChange={(dateObj) => {
                                            this.timeChanged(
                                              dateObj,
                                              "invoiceReceivedDate"
                                            );
                                          }}
                                          height={"15px"}
                                          formControlProps={{
                                            height: "15px",
                                            inlineBlock: "none",
                                          }}
                                          inputProps={{
                                            id: "invoiceReceivedDate",
                                            value: this.state
                                              .invoiceReceivedDate,
                                            autoComplete: "off",
                                          }}
                                        />
                                      </FormControl>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Ledger
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        id="ledgerName"
                                        value={this.state.ledgerName}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                      />
                                      {this.state.ledgerGroupDesc != "" ? (
                                        <ItemGrid xs={12}>
                                          <GridContainer>
                                            <ItemGrid xs={12}>
                                              <span
                                                style={{
                                                  fontStyle: "italic",
                                                  fontWeight: 400,
                                                  color: "red",
                                                }}
                                              >
                                                Ledger Group:
                                              </span>
                                              <span
                                                style={{
                                                  fontSize: "12px",
                                                  color: "red",
                                                }}
                                              >
                                                {this.state.ledgerGroupDesc}
                                                <br />
                                              </span>
                                            </ItemGrid>
                                          </GridContainer>
                                        </ItemGrid>
                                      ) : null}

                                      {/*
                                                                            <SelectboxTemp id={"ledger"}
                                                                                           defValue={this.state.ledger}
                                                                                           onSelect={this.onSelect}
                                                                                           getName={this.getGateEntryDetails}
                                                                                           stateKey="ledger"
                                                                                           url={""}
                                                                                // value={this.state.grnNo}
                                                                            />
*/}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        TDS Payable
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={
                                          getCookie(cons.USER_GRP_ID) == "99"
                                        }
                                        defValue={this.state.tdsPayable}
                                        onSelect={this.onSelect}
                                        stateKey="tdsPayable"
                                        staticData={yesOrNo}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {this.state.tdsPayable === "Y" ? (
                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          TDS Amount
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={
                                            getCookie(cons.USER_GRP_ID) == "99"
                                          }
                                          id="tdsAmount"
                                          value={this.state.tdsAmount}
                                          className={classes.tdsAmount}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {this.state.tdsPayable === "Y" ? (
                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          TDS Reason
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="tdsReason"
                                          value={this.state.tdsReason}
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={2}>
                                      <InputLabel className={classes.label}>
                                        TCS %
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={4}>
                                      <SelectboxTemp
                                        selectDisabled={
                                          getCookie(cons.USER_GRP_ID) == "99"
                                        }
                                        defValue={this.state.tcsPercentage}
                                        onSelect={this.onSelect}
                                        stateKey="tcsPercentage"
                                        staticData={TCSPercentages}
                                      />
                                    </ItemGrid>

                                    {this.state.tcsPercentage == "" &&
                                    this.state.tcsPercentage !== 0 ? (
                                      <>
                                        {/* <ItemGrid xs={12} sm={3}>
                                          <InputLabel className={classes.label}>
                                            Enter TCS Amount
                                          </InputLabel>
                                        </ItemGrid> */}
                                        <ItemGrid xs={12} sm={3}>
                                          <TextField
                                            disabled={
                                              getCookie(cons.USER_GRP_ID) ==
                                              "99"
                                            }
                                            placeHolder="enter amount"
                                            id="tcsPercentageO"
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            InputProps={inputFocus}
                                          />
                                        </ItemGrid>
                                        <Button
                                          xs={12}
                                          sm={3}
                                          color="primary"
                                          stateKey="tcsPercentage"
                                          onClick={() => {
                                            this.setState({
                                              tcsAmount: this.state.tcsOther,
                                            });
                                          }}
                                        >
                                          Calculate
                                        </Button>
                                      </>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>

                                {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                TCS Amount
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <TextField
                                                                                disabled={true}
                                                                                id="tcsAmount"
                                                                                value={this.state.tcsAmount}
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                InputProps={inputFocus}

                                                                            />
                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid> */}
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              style={{
                                height: 2,
                                backgroundColor: "#00acc1",
                                marginTop: "5px",
                              }}
                            />
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid xs={12} sm={12}>
                                <InputLabel className={classes.label}>
                                  Invoices
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *{" "}
                                  </span>{" "}
                                  :
                                </InputLabel>
                              </ItemGrid>
                            ) : null}
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid
                                xs={8}
                                style={{ overflowY: "auto", maxHeight: 250 }}
                              >
                                {this.state.filesList.map((r, i) => (
                                  <GridContainer style={{ marginTop: "10px" }}>
                                    <ItemGrid xs={12} sm={2}>
                                      {i + 1}
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                      <ItemGrid xs={12} sm={6}>
                                        <a
                                          onClick={() => {
                                            getFileDownload(
                                              serverConfig.SERVER_URL +
                                                r.retrievalPath,
                                              r.fileName,
                                              r.fileExtension,
                                              this.handleDownloadedFile
                                            );
                                          }}
                                        >
                                          {r.fileName !== null &&
                                          r.fileName !== ""
                                            ? r.fileName
                                            : "invoice" + (i + 1)}
                                        </a>
                                      </ItemGrid>
                                    </ItemGrid>
                                    <ItemGrid xs={4}>
                                      <IconButton
                                        right={true}
                                        onClick={() => {
                                          this.setState({
                                            deleteInvoice: true,
                                            invoiceId: r.fileUploadId,
                                            fileId: i,
                                          });
                                          this.InvoicebasicAlert(
                                            "Do You Like To Delete This Item?"
                                          );
                                        }}
                                        color="dangerNoBackground"
                                      >
                                        <Close />
                                      </IconButton>{" "}
                                      {""}
                                    </ItemGrid>
                                  </GridContainer>
                                ))}
                              </ItemGrid>
                            ) : null}
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid xs={4}>
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleFileClick}
                                >
                                  Upload Invoice
                                </Button>
                              </ItemGrid>
                            ) : null}
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid
                                xs={12}
                                style={{
                                  height: 2,
                                  backgroundColor: "#00acc1",
                                  marginTop: "5px",
                                }}
                              />
                            ) : null}
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid xs={12} sm={12}>
                                <InputLabel className={classes.label}>
                                  Challan:
                                </InputLabel>
                              </ItemGrid>
                            ) : null}
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid
                                xs={8}
                                style={{ overflowY: "auto", maxHeight: 250 }}
                              >
                                {this.state.challanList.map((r, i) => (
                                  <GridContainer style={{ marginTop: "10px" }}>
                                    <ItemGrid xs={12} sm={2}>
                                      {i + 1}
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                      <a
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              r.retrievalPath,
                                            r.fileName,
                                            r.fileExtension,
                                            this.handleDownloadedFile
                                          );
                                        }}
                                      >
                                        {r.fileName !== null &&
                                        r.fileName !== ""
                                          ? r.fileName
                                          : "challan" + (i + 1)}
                                      </a>
                                    </ItemGrid>
                                    <ItemGrid xs={4}>
                                      <IconButton
                                        right={true}
                                        onClick={() => {
                                          this.setState({
                                            deleteChallan: true,
                                            challanId: r.fileUploadId,
                                            fileId: i,
                                          });
                                          this.InvoicebasicAlert(
                                            "Do You Like To Delete This Item?"
                                          );
                                        }}
                                        color="dangerNoBackground"
                                      >
                                        <Close />
                                      </IconButton>{" "}
                                      {""}
                                    </ItemGrid>
                                  </GridContainer>
                                ))}
                              </ItemGrid>
                            ) : null}
                            {getCookie(cons.USER_GRP_ID) !== "99" ? (
                              <ItemGrid xs={4}>
                                <Button
                                  color={"info"}
                                  onClick={() => {
                                    this.setState({
                                      uploadChallan: true,
                                    });
                                    this.handleFileClick();
                                  }}
                                >
                                  Upload Challan
                                </Button>
                              </ItemGrid>
                            ) : null}
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_GRP_ID) !== "99" ? (
                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />
                  ) : null}
                  {getCookie(cons.USER_GRP_ID) !== "99" ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents:
                      </InputLabel>
                    </ItemGrid>
                  ) : null}
                  {getCookie(cons.USER_GRP_ID) !== "99" ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.supportFilesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                              /*title="ImageName" target={"_/"}*/
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "challan" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteSupportFile: true,
                                  supportfileId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This Item?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}
                  {getCookie(cons.USER_GRP_ID) !== "99" ? (
                    <ItemGrid xs={4}>
                      <Button
                        color={"info"}
                        onClick={() => {
                          this.setState({
                            uploadSupportFile: true,
                          });
                          this.handleFileClick();
                        }}
                      >
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onApproveButtonClick}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={this.onRejectButtonClick}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.approveStatus &&
                        this.state.billPassStatus !== 3) ||
                      this.state.billPassStatus === "1" ||
                      this.state.billPassStatus === "23" ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_GRP_ID) !== "99" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  staticData={this.state.supplierList}
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  // url={serverApi.SEARCH_SUPPLIER+"/"+getCookie(cons.COMPANY_ID)}
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                MR No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="searchMrNo"
                                value={this.state.searchMrNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Gate Entry No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="gateEntryNo"
                                value={this.state.gateEntryNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                staticData={
                                  getCookie(cons.USER_GRP_ID) == "99"
                                    ? statusOptions
                                    : null
                                }
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {/*
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        //onClick={this.handleCreate}
                                                        // round color="white"
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
*/}
                        <ItemGrid xs={12}>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {/*
                                            <ReactTable
                                                data={this.state.data}
                                                filterable
                                                // resizable={false}
                                                style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}
                                                columns={[
                                                    {
                                                        Header: "PO Number",
                                                        accessor: "po_num",
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Date",
                                                        accessor: "po_date",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Suplier Name",
                                                        accessor: "suplier_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Broker Name",
                                                        accessor: "broker_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Mukam",
                                                        accessor: "mukam",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Type",
                                                        accessor: "vehicle_type",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Quantity",
                                                        accessor: "vehicle_quantity",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Qualities",
                                                        accessor: "qualities",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                //showPaginationTop
                                                showPaginationBottom
                                                className="-striped -highlight"
                                            >

                                            </ReactTable>
*/}

                      <TableComponent
                        staticData={this.state.tableList}
                        // url={serverApi.DATATABLE_SERVICE_URL + "getAllBillPass"}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"jute_billpass_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  textFieldRight: {
    textAlign: "right",
    paddingRight: "5px",
    paddingBottom: "10px",
  },
  paddingZero: { padding: "0" },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },

  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(JuteBillPass);
