import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  serverVars,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  requestList,
  uploadFile,
  uploadFormdataWithImage,
  uploadInvoice,
} from "../../server/server";
import moment from "moment";
import { getCookie, setCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import { subMenus } from "../../routes/routeIcons";
import LineItemTable from "../CustumComponents/LineItemTable";

class Quotations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      billPassNo: "",
      customerList: [],
      file: null,
      fileName: "No file Chosen",
      isRefresh: false,
      isLoading: false,
      itemViewType: "quoteUniqueNo",
      //itemViewType: "mrNo",
      approveStatus: false,
      updateView: false,
      statusDesc: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      alert: false,
      invoiceList: [],
      lineItemId: "0",
      billPassStatus: "",
      form_supplierId: "",
      date1: moment().format("DD-MM-YYYY"),
      customerId: "",
      freightCharges: "",
      footerNotes: "",
      paymentTerms: "",
      termsConditions: "",
      customer: "",
      customerName: "",
      billingAddress: "",
      shippingAddress: "",
      quoteDate: "",
      expireDate: "",
      taxType: "",
      totalAmount: "",
      netTotal: "",
      sumOfamt: "",
      invoiceNo: "",
      invoiceDate: "",
      paymentDueDate: "",
      invoiceAmount: "",
      invoiceReceivedDate: "",
      quantity: "",
      taxId: "",
      uom: "",
      amount: "",
      rate: "",
      description: "",
      itemCode: "",
      itemGroup: "",
      branchId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.CUSTOMER_MASTER, data, this.handelCustomer);
  }
  handelCustomer = (resp) => {
    var data = resp.data,
      customerList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      customerList.push(obj);
      data.forEach((prop) => {
        var name = prop.name + "^" + prop.address + "^" + prop.shippingAddress;
        var obj = { label: prop.name, value: prop.id, name: name };
        customerList.push(obj);
      });
      this.setState({
        customerList: customerList,
        isCustomer: true,
      });
    }
  };

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  getDefaultSideConvertion = (name) => {
    var val = 0,
      label = "Select..",
      data = "";
    if (name !== null && name !== 0 && name !== "") {
      if (name === 1) {
        val = 1;
        label = "IGST";
      } else if (name === 2) {
        val = 2;
        label = "CGST & SGST";
      } else {
        val = 3;
        label = "VAT";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.QUOTATIONS_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.COMPANY_ID) +
          "/" +
          getCookie(cons.ACADEMIC_YEAR),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    var header = resp.quoteHdr;
    var list = resp.quoteLineItems;
    var indentlist = [],
      filesList = [],
      totalValue = 0;
    var taxType = 0;
    /*
                if(header.billDocsUrl!==null){
                    filesList=header.billDocsUrl
                }
        */

    if (list !== null)
      list.map((prop, key) => {
        var itemAmount = parseFloat(prop.amount);
        var taxPercentage = 0;
        if (prop.igstPer > 0) {
          // taxType=1;
          taxPercentage = prop.igstPer;
        } else {
          // taxType=2;
          taxPercentage = prop.cgstPer + prop.sgstPer;
        }

        var obj = {
          id: key,
          itemCode: prop.itemId + "",
          itemName: prop.itemName,
          description: prop.itemDescription,
          quantity: prop.quantity,
          uom: prop.uom,
          rate: parseFloat(prop.rate).toFixed(2),
          hsn: prop.hsnCode,
          amount: parseFloat(prop.amountWithoutTax).toFixed(2),
          lineItemId: prop.quoteLineitemId,
          isgst: prop.igstPer,
          ssgst: prop.sgstPer,
          csgst: prop.cgstPer,
          taxId: prop.taxId + "",
          make: prop.make,
          taxPercentage: taxPercentage,
          taxamount: prop.taxAmt,
          isActive: prop.isActive,
          itemGroup: prop.itemGroup,
          itemGroupName: prop.itemGroupDesc,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to remove the data row */}
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === obj.id) {
                      // alert(o.qualityId);
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      //  data.splice(i,1);
                      if (
                        this.state.form_supplierId !== "0" &&
                        this.state.taxType.value > 0
                      ) {
                        this.basicItemAlert("Do You Like To Update This Item?");
                        this.setState({
                          //alert: true,
                          updateItem: true,
                          updateItemAlert: true,
                          id: o.id,
                          itemCode: o.itemCode,
                          itemName: o.itemName,
                          itemGroup: o.itemGroup,
                          itemGroupName: o.itemGroupName,
                          description: o.description,
                          quantity: o.quantity,
                          uom: o.uom,
                          rate: o.rate,
                          hsn: o.hsn,
                          amount: o.amount,
                          lineItemId: o.lineItemId,
                          isgst: o.igstPercent,
                          ssgst: o.sgstPercent,
                          csgst: o.cgstPercent,
                          taxId: o.taxId,
                          taxPercentage: o.taxPercentage,
                          itemTaxAmt: o.taxamount,
                          make: o.make,
                        });
                      } else {
                        this.basicAlert(
                          "Please Enter All Mandatory Fields..!!"
                        );
                      }

                      return true;
                    }
                  });
                }}
                color="primaryNoBackground"
                customClass="edit"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === obj.id) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      this.setState({ deleteItem: true, id: o.id });
                      this.basicItemAlert("Do You Like To Delete This Item?");
                      return true;
                    }
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            </div>
          ),
        };
        indentlist.push(obj);
      });
    // var invoiceUrls=resp.billDocsUrl;

    // console.log("indentWeight " + header.indentWeight);
    if (header !== null) {
      //  alert(header.agentId);
      this.setState({
        updateView: true,
        isCreated: true,
        approveStatus: header.approveStatus,
        statusDesc: header.statusDesc,
        //  mrDate: header.goodReceiptDateDesc,
        year: header.acYear,
        createdBy: header.userDesc,
        createdOn: header.createDateDesc,
        //  frieghtCharges:header.frieghtCharges,
        invoiceList: indentlist,
        // filesList:filesList,
        // challanList:header.billChallanUrl,
        quoteStatus: header.status,
        quotation: header.quoteUniqueNo,
        quoteId: header.quoteId,
        quoteNoString: header.quoteNoString,
        freightCharges: header.freightCharges,
        footerNotes: header.footerNotes,
        paymentTerms: header.paymentTerms,
        termsConditions: header.termsConditions,
        customer: header.customerId,
        customerName: header.customerName,
        billingAddress: header.billingAddress,
        shippingAddress: header.shippingAddress,
        quoteDate: header.quoteDateDesc,
        expireDate: header.expireDateDesc,
        totalAmount: header.quoteAmount,
        netTotal: Math.round(header.grandTotal).toFixed(2),
        sumOfamt: Math.round(header.grandTotal).toFixed(2),
        taxType: this.getDefaultSideConvertion(header.intraInterState),
        branchId: header.branchId,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getAgentName = (name) => {
    this.setState({
      agentName: name,
    });
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");

    this.setState({
      itemName: values[0],
      uom: values[1],
    });
    if (values[0] !== this.state.itemName) {
      this.setState({
        rate: parseFloat(values[3]),
        hsn: values[4] !== "null" ? values[4] : "",
      });
    }
    // console.log("indentQuantity " + this.state.indentQuantity);
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      isCustomer: val,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      cusId: this.state.customerId,
      quoteNo: this.state.quotationNo,
      status: this.state.status,
      companyId: getCookie(cons.COMPANY_ID),
      acYear: this.state.year,
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });

    //  this.dateChanged();
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  onCreateInvoiceButtonClick = () => {
    console.log("quotation_id", this.state.quotation);
    setCookie(cons.QUOTATION_ID, this.state.quotation);
    this.props.history.push(subMenus.SALES_INVOICE);
  };
  onCreateSaleOrderButtonClick = () => {
    console.log("quotation_id", this.state.quotation);
    setCookie(cons.QUOTATION_ID, this.state.quotation);
    this.props.history.push(subMenus.SALES_ORDER);
  };
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / 150))
      );
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;

    if (event.target.id === "freightCharges") {
      var sumOfamt = this.state.sumOfamt;
      if (value !== "") {
        var total = Math.round(
          parseFloat(this.state.sumOfamt) + parseFloat(value)
        ).toFixed(2);
        this.setState({
          netTotal: total,
        });
      } else {
        this.setState({ netTotal: sumOfamt });
      }
    }

    if (
      event.target.id === "rate" &&
      this.state.quantity !== "" &&
      this.state.quantity !== null
    ) {
      console.log("val " + value);
      if (value !== null && value !== "") {
        var Calrate = this.state.quantity;
        var amount = parseFloat(Calrate) * parseFloat(value);
        this.setState({
          amount: amount.toFixed(2),
        });
      } else {
        this.setState({
          amount: 0,
        });
      }
    }
    if (
      event.target.id === "quantity" &&
      this.state.rate !== "" &&
      this.state.rate !== null
    ) {
      console.log("val " + value);
      if (value !== null && value !== "") {
        var Calrate = this.state.rate;
        var amount = parseFloat(Calrate) * parseFloat(value);
        this.setState({
          amount: amount.toFixed(2),
        });
      } else {
        this.setState({
          amount: 0,
        });
      }
    }

    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/
    if (stateKey === "suppielr_id") {
      this.setState({
        loadGateEntry: true,
      });
    }
    if (stateKey === "itemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "Y",
        consumable: "",
        companyId: getCookie(cons.COMPANY_ID),
      };

      requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
      // this.setState({
      //     loadItem: true
      // })
    }
    if (
      stateKey === "selectedJuteType" &&
      selectedValue > 0 &&
      selectedValue !== type
    ) {
      this.setState({
        hideJuteType: true,
      });
    }
    if (
      stateKey === "selectedMukam" &&
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeMukam
    ) {
      this.unitConvertionAlert(
        "Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!"
      );
    } else if (stateKey === "selectedMukam")
      this.setState({
        [stateKey]: selectedValue,
        canChangeMukam: false,
      });
    else
      this.setState({
        [stateKey]: selectedValue,
      });
  };

  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterRefreshGateEntry = (val) => {
    this.setState({
      loadGateEntry: val,
    });
  };
  getGateEntryDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      form_poNO: values[0],
      poDate: values[1],
      challanNo: values[2],
      challanDate: values[3],
      form_vehicleNo: values[4],
    });
    // console.log("indentQuantity " + this.state.stock);
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      if (this.state.deleteChallan) {
        var arr = this.state.challanList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          challanList: arr,
          deleteChallan: false,
          fileId: "",
        });
      } else {
        var arr = this.state.filesList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          filesList: arr,
          deleteInvoice: false,
          fileId: "",
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };

  handleInvoiceResp = (resp) => {
    if (resp.status) {
      var data = resp.data;
      if (this.state.uploadChallan) {
        var arr = this.state.challanList;
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadChallan: false,
          challanList: arr,
          basicAlert: null,
        });
      } else {
        var arr = this.state.filesList;
        arr.splice(arr.length, 0, resp.data);
        this.setState({
          uploadInvoice: false,
          filesList: arr,
          basicAlert: null,
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      quantity: "",
      itemCode: "",
      itemName: "",
      taxId: "",
      uom: "",
      amount: "",
      rate: "",
      description: "",
      make: "",
      taxPercentage: "",
      itemGroup: "",
      itemTaxAmt: "",
      igstPercent: "",
      cgstPercent: "",
      sgstPercent: "",
      itemGroupName: "",
    });
  }
  //log

  hideAlertMessage() {
    this.setState({
      billPassStatus: "",
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      sumOfamt: "",
      createdBy: "",
      createdOn: "",
      invoiceList: [],
      totalAmount: "",
      form_supplierId: "",
      quoteStatus: "",
      quoteId: "",
      quotation: "",
      billPassDate: moment().format("DD-MM-YYYY"),
      taxType: "",
      netTotal: "",
      invoiceNo: "",
      invoiceDate: "",
      paymentDueDate: "",
      invoiceAmount: "",
      invoiceReceivedDate: "",
      costCenterId: "",
      costCenter: "",
      taxId: "",
      uom: "",
      amount: "",
      rate: "",
      description: "",
      make: "",
      freightCharges: "",
      footerNotes: "",
      paymentTerms: "",
      termsConditions: "",
      customer: "",
      customerName: "",
      billingAddress: "",
      shippingAddress: "",
      quoteDate: "",
      expireDate: "",
      branchId: "",
    });
  }
  dateChanged = (myDate) => {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        freightCharges,
        footerNotes,
        date1,
        paymentTerms,
        termsConditions,
        customer,
        customerName,
        billingAddress,
        shippingAddress,
        taxType,
        totalAmount,
        quoteDate,
        expireDate,
        netTotal,
        branchId,
      } = this.state;
      var invoiceList = invoiceList.map((prop, key) => {
        var cgstAmount =
            (parseFloat(prop.amount) * parseFloat(prop.csgst)) / 100,
          sgstAmount = (parseFloat(prop.amount) * parseFloat(prop.isgst)) / 100,
          igstAmount = (parseFloat(prop.amount) * parseFloat(prop.ssgst)) / 100;
        return {
          quoteLineitemId: prop.lineItemId,
          quoteId: this.state.quoteId,
          itemId: prop.itemCode,
          itemName: prop.itemName,
          itemGroup: prop.itemGroup,
          itemGroupDesc: prop.itemGroupName,
          itemDescription: prop.description,
          hsnCode: prop.hsn,
          make: prop.make,
          quantity: prop.quantity,
          uom: prop.uom,
          rate: prop.rate,
          amountWithoutTax: prop.amount,
          taxId: prop.taxId,
          sgstPer: prop.ssgst,
          cgstPer: prop.csgst,
          igstPer: prop.isgst,
          sgstAmt: sgstAmount,
          cgstAmt: cgstAmount,
          igstAmt: igstAmount,
          taxAmt: prop.taxamount,

          totAmt: parseFloat(prop.taxamount) + parseFloat(prop.amount),
          companyId: getCookie(cons.COMPANY_ID),
          isActive: prop.isActive,
        };
      });

      var data = {
        quoteHdr: {
          quoteId: this.state.quoteId,
          quoteUniqueNo: this.state.quotation,
          quoteNoString: this.state.quoteNoString,
          customerId: customer,
          customerName: customerName,
          billingAddress: billingAddress,
          shippingAddress: shippingAddress,
          quoteDate: this.dateChanged(quoteDate),
          expireDate: this.dateChanged(expireDate),
          quoteAmount: totalAmount,
          taxAmount: this.getTotalTax("total"),
          grandTotal: netTotal,
          intraInterState: taxType.value,
          freightCharges: freightCharges,
          footerNotes: footerNotes,
          paymentTerms: paymentTerms,
          termsConditions: termsConditions,
          //"createdDate":this.dateChanged(this.state.createdBy),
          isActive: 1,
          status: status,
          createdDate: this.dateChanged(this.state.createdOn),
          //  "createdBy":this.state.createdBy,
          updatedDate: this.dateChanged(date1),
          userId: getCookie(cons.USERID),
          updatedBy: getCookie(cons.USERID),
          acYear: getCookie(cons.ACADEMIC_YEAR),
          companyId: getCookie(cons.COMPANY_ID),
          taxType: taxType.value,
          branchId: branchId,
        },
        quoteLineItems: invoiceList,
      };
      if (
        customer !== "" &&
        taxType !== 0 &&
        taxType !== "" &&
        quoteDate !== "" &&
        expireDate !== "" &&
        invoiceList.length > 0 &&
        branchId > 0
      ) {
        this.handleLoading();
        requestList(serverApi.QUOTATIONS_UPDATE, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        freightCharges,
        footerNotes,
        date1,
        paymentTerms,
        termsConditions,
        customer,
        customerName,
        billingAddress,
        shippingAddress,
        taxType,
        totalAmount,
        quoteDate,
        expireDate,
        netTotal,
        invoiceNo,
        invoiceDate,
        paymentDueDate,
        invoiceAmount,
        invoiceReceivedDate,
        branchId,
      } = this.state;
      var invoiceList = invoiceList.map((prop, key) => {
        var cgstAmount =
            (parseFloat(prop.amount) * parseFloat(prop.csgst)) / 100,
          sgstAmount = (parseFloat(prop.amount) * parseFloat(prop.ssgst)) / 100,
          igstAmount = (parseFloat(prop.amount) * parseFloat(prop.isgst)) / 100;
        return {
          quoteLineitemId: "",
          //   "quoteId":0,
          itemId: prop.itemCode,
          itemName: prop.itemName,
          itemGroup: prop.itemGroup,
          itemGroupDesc: prop.itemGroupName,
          itemDescription: prop.description,
          hsnCode: prop.hsn,
          make: prop.make,
          quantity: prop.quantity,
          uom: prop.uom,
          rate: prop.rate,
          amountWithoutTax: prop.amount,
          taxId: prop.taxId,
          sgstPer: prop.ssgst,
          cgstPer: prop.csgst,
          igstPer: prop.isgst,
          sgstAmt: sgstAmount,
          cgstAmt: cgstAmount,
          igstAmt: igstAmount,
          taxAmt: prop.taxamount,
          totAmt: parseFloat(prop.taxamount) + parseFloat(prop.amount),
          companyId: getCookie(cons.COMPANY_ID),
          isActive: prop.isActive,
        };
      });

      var data = {
        quoteHdr: {
          customerId: customer,
          customerName: customerName,
          billingAddress: billingAddress,
          shippingAddress: shippingAddress,
          quoteDate: this.dateChanged(quoteDate),
          expireDate: this.dateChanged(expireDate),
          quoteAmount: totalAmount,
          taxAmount: this.getTotalTax("total"),
          grandTotal: netTotal,
          intraInterState: taxType.value,
          freightCharges: freightCharges,
          footerNotes: footerNotes,
          paymentTerms: paymentTerms,
          termsConditions: termsConditions,
          createdDate: this.dateChanged(date1),
          createdBy: getCookie(cons.USERID),
          acYear: getCookie(cons.ACADEMIC_YEAR),
          companyId: getCookie(cons.COMPANY_ID),
          userId: getCookie(cons.USERID),
          branchId: branchId,
        },
        quoteLineItems: invoiceList,
      };

      if (
        customer !== "" &&
        taxType !== 0 &&
        taxType !== "" &&
        quoteDate !== "" &&
        expireDate !== "" &&
        invoiceList.length > 0 &&
        shippingAddress !== "" &&
        billingAddress !== "" &&
        branchId > 0
      ) {
        this.handleLoading();
        requestList(serverApi.QUOTATIONS_ADD, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.deleteBill) console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    if (this.state.deleteBill) {
      this.setState({ billId: "", deleteBill: false, isRefresh: true });
    } else this.handleLoading();
  };
  handleDeleteBill = (resp) => {};
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteBill) {
      //this.handleLoading();
      getDataFromUrl(
        serverApi.ACCOUNT_BILLS_DELETE +
          this.state.billId +
          "/" +
          getCookie(cons.COMPANY_ID) +
          "/" +
          getCookie(cons.ACADEMIC_YEAR),
        this.handleAddIndent
      );
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList,
        sum_of_tax = 0,
        sumOfamt = 0;
      //  var totalAmount = this.state.totalAmount - parseFloat(data[this.state.id].amount);
      //  var totalTax = this.state.totalTax -parseFloat(data[this.state.id].taxamount);
      //  var netTotal = this.state.netTotal -parseFloat(data[this.state.id].amount) - parseFloat(data[this.state.id].taxamount);
      // data.splice(this.state.id, 1);
      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        } else {
          if (o.isActive === 1) {
            sum_of_tax = sum_of_tax + parseFloat(o.taxamount);
            sumOfamt = sumOfamt + parseFloat(o.amount);
          }
        }
      });

      /*
            data.map((o,key)=>{
                o.id=key;
                sum_of_tax = sum_of_tax +parseFloat(o.taxamount) ;
                sumOfamt = sumOfamt + parseFloat(o.amount);
            });
*/

      //  if(this.state.approveStatus)

      this.setState({
        totalTax: sum_of_tax.toFixed(2),
        totalAmount: sumOfamt.toFixed(2),
        netTotal: Math.round(sumOfamt + sum_of_tax),
        sumOfamt: Math.round(sumOfamt + sum_of_tax),
      });

      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    console.log("quantity " + this.state.quantity);
    var itemIndex = 0,
      sum_of_tax = 0,
      sumOfamt = 0,
      hasItem = false;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      if (this.state.itemTaxAmt > 0)
        arr[itemIndex].taxamount = parseFloat(this.state.itemTaxAmt);
      arr[itemIndex].amount = parseFloat(this.state.amount);
    } else {
      itemIndex = this.state.invoiceList.length;
      console.log("itemTaxAmt", this.state.itemTaxAmt + " bb");

      if (this.state.itemTaxAmt > 0)
        sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);

      sumOfamt = sumOfamt + parseFloat(this.state.amount);
    }
    arr.map((q) => {
      if (q.isActive === 1) {
        console.log("amount", q.taxamount + " aa");
        // sumOfQty = sumOfQty+parseInt(q.quantityType);
        if (q.taxamount > 0) sum_of_tax = sum_of_tax + parseFloat(q.taxamount);
        sumOfamt = sumOfamt + parseFloat(q.amount);
      }
    });

    var addItemObj = {
      id: itemIndex,
      itemCode: this.state.itemCode,
      itemName: this.state.itemName,
      itemGroup: this.state.itemGroup,
      itemGroupName: this.state.itemGroupName,
      description: this.state.description,
      quantity: this.state.quantity,
      uom: this.state.uom,
      rate:
        this.state.rate !== "" ? parseFloat(this.state.rate).toFixed(2) : 0.0,
      hsn: this.state.hsn,
      amount:
        this.state.amount !== ""
          ? parseFloat(this.state.amount).toFixed(2)
          : 0.0,
      lineItemId: this.state.lineItemId,
      isgst: this.state.igstPercent,
      ssgst: this.state.sgstPercent,
      csgst: this.state.cgstPercent,
      taxId: this.state.taxId,
      taxPercentage: this.state.taxPercentage,
      taxamount: this.state.itemTaxAmt,
      make: this.state.make,
      isActive: 1,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to remove the data row */}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // alert(o.qualityId);
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  //  data.splice(i,1);
                  if (this.state.taxType.value > 0) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    this.setState({
                      //alert: true,
                      updateItem: true,
                      updateItemAlert: true,
                      id: o.id,
                      itemCode: o.itemCode,
                      itemName: o.itemName,
                      itemGroup: o.itemGroup,
                      itemGroupName: o.itemGroupName,
                      description: o.description,
                      quantity: o.quantity,
                      uom: o.uom,
                      rate: o.rate,
                      hsn: o.hsn,
                      amount: o.amount,
                      lineItemId: o.lineItemId,
                      isgst: o.igstPercent,
                      ssgst: o.sgstPercent,
                      csgst: o.cgstPercent,
                      taxId: o.taxId,
                      taxPercentage: o.taxPercentage,
                      itemTaxAmt: o.taxamount,
                      make: o.make,
                    });
                  } else {
                    this.basicAlert("Please Enter All Mandatory Fields..!!");
                  }

                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.setState({ deleteItem: true, id: o.id });
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  return true;
                }
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>
        </div>
      ),
    };

    if (
      this.state.rate !== "" &&
      this.state.quantity !== "" &&
      this.state.itemCode !== "" &&
      this.state.itemCode !== 0 &&
      this.state.itemGroup !== "" &&
      this.state.itemGroup !== 0 &&
      this.state.amount !== ""
    ) {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else arr.splice(arr.length, 0, addItemObj);

      console.log("sumOfamt", sumOfamt);
      console.log("sum_of_tax", sum_of_tax);

      this.setState({
        loading: true,
        invoiceList: arr,
        totalTax: sum_of_tax.toFixed(2),
        totalAmount: sumOfamt.toFixed(2),
        netTotal: Math.round(sumOfamt + sum_of_tax),
        sumOfamt: Math.round(sumOfamt + sum_of_tax),
        updateItem: false,
      });
      this.hideAlert();
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        if (this.state.uploadChallan) {
          this.setState({
            file: file,
            fileName: file.name,
            //filesList: arr,
            // imagePreviewUrl: reader.result
          });
          this.InvoicebasicAlert(
            "Do you want to upload challan " + file.name + "?"
          );
        } else {
          this.setState({
            file: file,
            fileName: file.name,
            //filesList: arr,
            uploadInvoice: true,
            // imagePreviewUrl: reader.result
          });
        }

        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }

  getfileName = (i) => {
    var arr = this.state.filesList;
    return arr[i].docUrl;
  };
  getchallanName = (i) => {
    var arr = this.state.challanList;
    console.log("img", arr[i].docUrl);
    return arr[i].docUrl;
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    console.log(this.state);
    if (this.state.taxType !== null && this.state.taxType !== 0)
      this.setState({
        loading: true,
        alert: true,
        updateItem: false,
      });
    else {
      this.basicAlert("Please Select Tax Type..!!");
    }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  handleSelectedValues = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeUnit &&
      this.state.unitConversion.value !== defValue.value
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConvertion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      if (prop.isActive === 1) {
        console.log("itemTaxAmt", prop.itemTaxAmt);
        if (prop.ssgst !== undefined && prop.ssgst > 0)
          totSgst =
            totSgst + (parseFloat(prop.amount) * parseFloat(prop.ssgst)) / 100;
        if (prop.isgst !== undefined && prop.isgst > 0)
          totIgst =
            totIgst + (parseFloat(prop.amount) * parseFloat(prop.isgst)) / 100;
        if (prop.csgst !== undefined && prop.csgst > 0)
          totcgst =
            totcgst + (parseFloat(prop.amount) * parseFloat(prop.csgst)) / 100;
      }
    });
    var totalTax = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    } else if (tax === "total") {
      if (totIgst > 0) totalTax = totIgst;
      else totalTax = totSgst + totcgst;
    }
    return parseFloat(totalTax).toFixed(2);
  }
  handleSelectedUnitValue = (defValue) => {
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    data.map((prop, i) => {
      var SlectedTaxType = defValue.value;
      if (SlectedTaxType === 1 || SlectedTaxType === 3) {
        prop.isgst = prop.taxPercentage;
        prop.ssgst = 0;
        prop.csgst = 0;
      } else if (SlectedTaxType === 2) {
        var taxPercent = prop.taxPercentage / 2;
        prop.isgst = 0;
        prop.ssgst = taxPercent;
        prop.csgst = taxPercent;
      } else {
        prop.isgst = 0;
        prop.ssgst = 0;
        prop.csgst = 0;
      }
    });
    this.setState({
      invoiceList: data,
      taxType: defValue,
    });
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = values[1];

    var itemAmount = this.state.amount;
    if (taxPercent !== "" && itemAmount !== "") {
      var itemtaxAmount =
        (parseFloat(itemAmount) * parseFloat(taxPercent)) / 100;
      //  itemtaxAmount = Math.round(itemtaxAmount);
      this.setState({
        itemTaxAmt: itemtaxAmount,
        taxPercentage: taxPercent,
      });
    }
    var SlectedTaxType = this.state.taxType.value;
    //alert(SlectedTaxType);
    if (SlectedTaxType === 1 || SlectedTaxType === 3) {
      this.setState({
        igstPercent: taxPercent,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    } else {
      taxPercent = taxPercent / 2;

      this.setState({
        igstPercent: 0,
        sgstPercent: taxPercent,
        cgstPercent: taxPercent,
      });
    }
  };
  onDeleteBill = (id) => {
    this.setState({ deleteBill: true, billId: id });
    this.basicItemAlert("Do You Like To Delete This Bill?");
  };

  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.forEach((prop, key) => {
        //console.log("prop",prop.isActive);
        if (prop.isActive === 1) {
          data.push(prop);
        }
      });
    return data;
  };
  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      quoteStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onUpdateButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onUpdateButtonClick(quoteStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        22,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }

  render() {
    const { classes } = this.props;
    const { taxType } = this.state;
    //console.log("jute type",selectedJuteType);
    //add item button click
    const ucoptions = [{ value: 0, label: "Select...", name: "Select..." }];

    var taxTypes = getConfigValue(configNames.TAX_TYPES);
    if (taxTypes !== "1") {
      taxTypes = JSON.parse(taxTypes);
      if (taxTypes.gst === 1) {
        ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
        ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
      }
      if (taxTypes.vat === 1) {
        ucoptions.push({ value: 3, label: "VAT", name: "VAT" });
      }
      if (taxTypes.noTax === 1) {
        ucoptions.push({ value: 4, label: "NO TAX", name: "NO TAX" });
      }
    } else {
      ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
      ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
    }
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Item Group
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.itemGroup}
                                    id={"itemGroup"}
                                    onSelect={this.onSelect}
                                    getName={(name) => {
                                      this.setState({ itemGroupName: name });
                                    }}
                                    isRefresh={this.state.loadItemGroup}
                                    stateKey="itemGroup"
                                    setIsRefresh={this.afterRefreshItemGrp}
                                    url={
                                      serverApi.SEARCH_ITEM_GROUPS +
                                      getCookie(cons.COMPANY_ID) +
                                      "/" +
                                      getCookie(cons.CIPHER)
                                    }
                                    value={this.state.itemGroup}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid xs={6} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Item
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.itemCode}
                                    staticData={this.state.ItemOptions}
                                    id={"itemCode"}
                                    onSelect={this.onSelect}
                                    getName={this.getitemDetails}
                                    isRefresh={this.state.loadItem}
                                    stateKey="itemCode"
                                    setIsRefresh={(val) => {
                                      this.setState({ loadItem: val });
                                    }}
                                    //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                    value={this.state.itemCode}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={6} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Description
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="description"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.description}
                                    fullWidth={true}
                                    InputProps={{
                                      autoComplete: "off",
                                    }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        iType="number"
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        value={this.state.rate}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        value={this.state.quantity}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        style={{ height: 30 }}
                                        iType="number"
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Make
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="make"
                                        className={classes.textField}
                                        value={this.state.make}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        iType="number"
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.amount}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Tax
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.taxId}
                                        id={"taxId"}
                                        onSelect={this.onSelect}
                                        stateKey="taxId"
                                        getName={this.gettaxpercent}
                                        isReload={this.state.loadTax}
                                        setIsReload={this.afterRefreshTax}
                                        url={
                                          serverApi.TAX_MASTER_LIST +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.taxId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Customer
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.customer}
                          id={"customer"}
                          onSelect={this.onSelect}
                          stateKey="customer"
                          staticData={this.state.customerList}
                          isRefresh={this.state.isCustomer}
                          getName={(name) => {
                            var values = name.split("^");
                            if (this.state.customerName !== values[0])
                              this.setState({
                                customerName: values[0],
                                billingAddress:
                                  values[1] === "null" ? "" : values[1],
                                shippingAddress:
                                  values[2] === "null" ? "" : values[2],
                              });
                          }}
                          setIsRefresh={this.afterRefreshquality}
                          //  url={serverApi.SEARCH_JUTE_STATUS}
                          value={this.state.customer}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tax Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          defValue={this.state.taxType}
                          name={"taxType"}
                          value={this.state.taxType}
                          onChange={this.handleSelectedUnitValue}
                          options={ucoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            colors: {
                              ...theme.colors,
                              primary: "#00ABDC",
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Billing To
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="billingAddress"
                          value={this.state.billingAddress}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Shipping To
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="shippingAddress"
                          value={this.state.shippingAddress}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Quotation No
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="quotation"
                            value={this.state.quotation}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Quote Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "quoteDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "quoteDate",
                              value: this.state.quoteDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Expire Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "expireDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "expireDate",
                              value: this.state.expireDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created Date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Status
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="statusDesc"
                            value={this.state.statusDesc}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          disableActions={
                            this.state.quoteStatus === 3 ||
                            this.state.quoteStatus === 4 ||
                            this.state.quoteStatus === 5 ||
                            this.state.quoteStatus === 6
                          }
                          columns={[
                            {
                              Header: "ITEM GROUP",
                              accessor: "itemGroupName",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "ITEM CODE",
                              accessor: "itemCode",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "NAME",
                              accessor: "itemName",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "REMARKS",
                              accessor: "description",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "HSN",
                              accessor: "hsn",
                              minWidth: 80,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "MAKE",
                              accessor: "make",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "QTY",
                              accessor: "quantity",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "RATE",
                              accessor: "rate",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Tax(%)",
                              accessor: "taxPercentage",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Amount",
                              accessor: "amount",
                              minWidth: 80,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Actions",
                              minWidth: 80,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{ fontSize: "12px", color: "#003F66" }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                            </ItemGrid>
                            <ItemGrid xs={6}>
                              {this.state.quoteStatus !== 3 &&
                              this.state.quoteStatus !== 4 &&
                              this.state.quoteStatus !== 5 &&
                              this.state.quoteStatus !== 6 ? (
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              ) : null}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          {this.state.quoteStatus !== 3 &&
                          this.state.quoteStatus !== 4 &&
                          this.state.quoteStatus !== 5 &&
                          this.state.quoteStatus !== 6 ? (
                            <Button
                              color={"info"}
                              right={true}
                              onClick={this.handleAddItem}
                            >
                              Add Item
                            </Button>
                          ) : null}
                        </ItemGrid>
                      )}

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={8}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                      Frieght charges
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="freightCharges"
                                      value={this.state.freightCharges}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      iType="number"
                                      variant="outlined"
                                      inputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                      Footer Notes
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="footerNotes"
                                      value={this.state.footerNotes}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      height={60}
                                      multiline={true}
                                      rowsMax={3}
                                      inputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                      Payment Term
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="paymentTerms"
                                      value={this.state.paymentTerms}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      iType={"number"}
                                      InputProps={{
                                        autoComplete: "off",
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Days
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                      Other Terms
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="termsConditions"
                                      value={this.state.termsConditions}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      height={60}
                                      multiline={true}
                                      rowsMax={3}
                                      inputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid xs={12} sm={4}>
                            <GridContainer>
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total Amount :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.totalAmount !== ""
                                      ? parseFloat(
                                          this.state.totalAmount
                                        ).toFixed(2)
                                      : 0.0}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      {labelType === "3"
                                        ? "Total VAT :"
                                        : " Total IGST :"}
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.getTotalTax("igst")}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              {labelType !== "3" ? (
                                <ItemGrid xs={12}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={5}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Total CGST :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={7}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.getTotalTax("cgst")}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              {labelType !== "3" ? (
                                <ItemGrid xs={12}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={5}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Total SGST :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={7}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.getTotalTax("sgst")}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Net Total:
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.netTotal !== ""
                                      ? parseFloat(this.state.netTotal).toFixed(
                                          2
                                        )
                                      : 0.0}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({ isApprove: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({ isReject: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.quoteStatus !== 3 &&
                      this.state.updateView &&
                      this.state.quoteStatus !== 5 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("");
                            }}
                          >
                            save
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {!this.state.updateView ? (
                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                          <GridContainer justify="center">
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={this.onCreateButtonClick}
                              >
                                save
                              </Button>
                            </ItemGrid>
                            <ItemGrid>
                              <Button
                                color="danger"
                                onClick={this.handleCreate}
                              >
                                Cancel
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                      {this.state.quoteStatus === 3 ? (
                        <ItemGrid xs={11}>
                          <GridContainer justify="center">
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={this.onCreateSaleOrderButtonClick}
                              >
                                Create Sale Order
                              </Button>
                            </ItemGrid>
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={this.onCreateInvoiceButtonClick}
                              >
                                Create Invoice
                              </Button>
                            </ItemGrid>

                            <ItemGrid>
                              <Button
                                color="danger"
                                onClick={() => {
                                  this.onUpdateButtonClick("5");
                                }}
                              >
                                Close
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                          <SamplePDF
                            invoiceType={2}
                            printResp={this.state.printResp}
                            sendMail={true}
                            handlePOMail={this.handleAddIndent}
                          />
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <SamplePDF
                            invoiceType={2}
                            printResp={this.state.printResp}
                          />
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Quotation No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="quotationNo"
                                value={this.state.quotationNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                inputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Customer Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"customerId"}
                                onSelect={this.onSelect}
                                stateKey="customerId"
                                staticData={this.state.customerList}
                                isRefresh={this.state.isCustomer}
                                setIsRefresh={this.afterRefreshquality}
                                //  url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.customerName}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            // round color="white"
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>

                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {/*
                                            <ReactTable
                                                data={this.state.data}
                                                filterable
                                                // resizable={false}
                                                style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}
                                                columns={[
                                                    {
                                                        Header: "PO Number",
                                                        accessor: "po_num",
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Date",
                                                        accessor: "po_date",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Suplier Name",
                                                        accessor: "suplier_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Broker Name",
                                                        accessor: "broker_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Mukam",
                                                        accessor: "mukam",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Type",
                                                        accessor: "vehicle_type",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Quantity",
                                                        accessor: "vehicle_quantity",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Qualities",
                                                        accessor: "qualities",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                //showPaginationTop
                                                showPaginationBottom
                                                className="-striped -highlight"
                                            >

                                            </ReactTable>
*/}

                      <TableComponent
                        url={serverApi.QUOTATIONS_LIST}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={getCookie(cons.COMPANY_NAME) + "_QUOTATIONS"}
                        // showDelete={true}
                        //  onDeleteClick={this.onDeleteBill}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.QUOTATIONS_DETAILS_BY_ID +
          this.state.quotation +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handlePrintResp
      );
    }
  }
  handlePrintResp1 = (resp) => {
    var printHeaderValues = resp.quoteHdr;
    var modules = [],
      totalIgst = 0,
      totalCgst = 0,
      totalSgst = 0;
    var lineItems = resp.quoteLineItems;
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var iAmount = "",
          cAmount = "",
          sAmount = "";
        if (prop.iTax > 0) {
          iAmount = (parseFloat(prop.valueWithoutTax) * prop.iTax) / 100;
          totalIgst = totalIgst + iAmount;

          iAmount = iAmount.toFixed(2);
        }
        if (prop.cTax > 0) {
          cAmount = (parseFloat(prop.valueWithoutTax) * prop.cTax) / 100;
          totalCgst = totalCgst + cAmount;

          cAmount = cAmount.toFixed(2);
        }
        if (prop.sTax > 0) {
          sAmount = (parseFloat(prop.valueWithoutTax) * prop.sTax) / 100;
          totalSgst = totalSgst + sAmount;

          cAmount = cAmount.toFixed(2);
        }

        var obj = {
          itemId: prop.itemId,
          juteType: prop.itemDesc,
          hsnCode: prop.hsnCode,
          quantity: prop.quantity,
          unit: prop.unitId,
          rate: parseFloat(prop.rate).toFixed(2),
          amount: parseFloat(prop.valueWithoutTax).toFixed(2),
          igstPer: prop.iTax > 0 ? prop.iTax : "",
          igstAmt: iAmount,
          cgstPer: prop.cTax > 0 ? prop.cTax : "",
          cgstAmt: cAmount,
          sgstPer: prop.sTax > 0 ? prop.sTax : "",
          sgstAmt: sAmount,
        };
        modules.push(obj);
      });
    }
    var pageNo = 1;
    var printTotalvalues = resp.totalValues;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.poDate;
    mr_date = mr_date + " ";
    var by_date = printHeaderValues.exceptedDate;
    if (by_date !== null) by_date = by_date + " ";
    else by_date = " ";
    var credit_term = printHeaderValues.creditTerm + " ";
    if (printHeaderValues.creditTerm > 0) credit_term = credit_term + "DAYS";
    var poId = printHeaderValues.id;
    poId = poId + " ";
    var challan_no = printHeaderValues.challanNo;
    challan_no = challan_no + " ";
    var challan_date = printHeaderValues.challanDate;
    challan_date = challan_date + " ";
    var vehicleNo = printHeaderValues.lorryNo;
    vehicleNo = vehicleNo + " ";
    /*  var SupplierName = this.state.supplierName;
          SupplierName = SupplierName + ' ';*/
    var brokerName = printHeaderValues.brokerName + " ";
    if (printHeaderValues.brokerName === null) brokerName = " ";
    var frightPaid = printHeaderValues.frieghtCharge;
    frightPaid = frightPaid + " ";
    var gateEntryDate = printHeaderValues.gateEntryDate;
    gateEntryDate = gateEntryDate + " ";
    var mukamName = this.state.supplierName;
    mukamName = mukamName + " ";
    var address1 = "",
      address2 = "";
    var companyName = getCookie(cons.COMPANY_TITLE) + " ";
    if (
      getCookie(cons.COMPANY_ADDRESS) !== "null" &&
      getCookie(cons.COMPANY_ADDRESS) !== "undefined"
    )
      address1 = getCookie(cons.COMPANY_ADDRESS) + " ";
    if (
      getCookie(cons.COMPANY_ADDRESS2) !== "null" &&
      getCookie(cons.COMPANY_ADDRESS2) !== "undefined"
    )
      address2 = getCookie(cons.COMPANY_ADDRESS2) + " ";
    var noOfLorries = printHeaderValues.vehicleQuantity + " ";

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    if (
      getCookie(cons.COMPANY_ICON) !== "undefined" &&
      getCookie(cons.COMPANY_ICON) !== "null"
    ) {
      var imgData = getCookie(cons.COMPANY_ICON);
      doc.setFontSize(16);
      doc.addImage(imgData, "JPEG", 15, 10, 120, 50);
    }
    var k = 20;

    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    doc.text(companyName, xOffset, k);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, k, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    k = k + 20;
    var Add1Off =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(address1) * doc.internal.getFontSize()) / 2;
    //  doc.text(address1, Add1Off, k);
    var Add2Off =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(address2) * doc.internal.getFontSize()) / 2;
    // k = k + 20;
    //doc.text(address2, Add2Off, k);
    if (address1 !== "") {
      address1 = doc.splitTextToSize(address1, 300);
      var ht = doc.getTextDimensions(address1).h;
      doc.text(address1, 400, k, "center");
      k = k + ht + 10;
    }
    if (address2 !== "") {
      address2 = doc.splitTextToSize(address2, 300);
      var ht = doc.getTextDimensions(address2).h;
      doc.text(150, k, address2);
      k = k + ht + 10;
    }

    var address3 = "";
    if (
      getCookie(cons.COMPANY_CONTACT_NO) !== "undefined" &&
      getCookie(cons.COMPANY_CONTACT_NO) !== "null"
    ) {
      address3 = "Ph.No: " + getCookie(cons.COMPANY_CONTACT_NO) + " ";
    }
    if (
      getCookie(cons.COMPANY_MAIL_ID) !== "undefined" &&
      getCookie(cons.COMPANY_MAIL_ID) !== "null"
    ) {
      address3 = address3 + "Email: " + getCookie(cons.COMPANY_MAIL_ID) + " ";
    }
    if (
      getCookie(cons.COMPANY_GST) !== "undefined" &&
      getCookie(cons.COMPANY_GST) !== "null"
    ) {
      address3 = address3 + "GST No: " + getCookie(cons.COMPANY_GST); //doc.setFontType("bold");
    }
    if (address3 !== "") {
      k = k + 20;
      var Add3Off =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(address3) * doc.internal.getFontSize()) / 2;
      doc.text(address3, Add3Off, k);
    }
    var po = "QUOTATION";
    var xOffset1 =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(po) * doc.internal.getFontSize()) / 2;
    k = k + 20;
    doc.setFontSize(16);
    doc.text(230, k, po);
    k = k + 15;

    doc.line(20, k, 550, k);

    doc.setFontSize(9);
    doc.setFontType("bold");
    k = k + 20;
    doc.text(30, k, "PURCHASE ORDER NO");
    doc.text(140, k, ":");
    doc.setFontType("normal");
    doc.text(150, k, poId);
    doc.setFontType("bold");
    doc.text(435, k, "PO DATE    ");
    doc.text(500, k, ":");

    doc.setFontType("normal");
    doc.text(505, k, mr_date);
    doc.setFontType("bold");
    k = k + 20;
    doc.text(30, k, "M/S PARTNER");
    doc.text(140, k, ":");
    doc.setFontType("normal");
    var SupplierName = "";
    doc.setFontType("bold");
    doc.text(435, k, "EXP.DATE ");
    doc.text(500, k, ":");

    doc.setFontType("normal");
    doc.text(505, k, by_date);
    if (printHeaderValues.customerName !== null) {
      SupplierName = doc.splitTextToSize(printHeaderValues.customerName, 250);
      var ht = doc.getTextDimensions(SupplierName).h;
      doc.text(150, k, SupplierName);
      k = k + ht + 10;
    } else k = k + 20;
    doc.setFontType("bold");
    doc.text(30, k, "PARTNER ADDRESS");
    doc.text(140, k, ":");
    doc.setFontType("normal");
    doc.setFontType("bold");
    doc.text(435, k, "PAYMENT TERM : ");
    doc.setFontType("normal");
    doc.text(515, k, credit_term);
    var suppaAdd = "";
    if (printHeaderValues.billingAddress !== null) {
      suppaAdd = doc.splitTextToSize(printHeaderValues.billingAddress, 250);
      var ht = doc.getTextDimensions(suppaAdd).h;
      doc.text(150, k, suppaAdd);
      k = k + ht;
      k = k + 10;
    } else {
      k = k + 20;
    }
    doc.setFontType("bold");
    doc.text(30, k, "PARTNER GST");
    doc.text(140, k, ":");
    doc.setFontType("normal");
    if (
      this.state.supplierGst !== "null" &&
      this.state.supplierGst !== undefined
    ) {
      suppaAdd = doc.splitTextToSize(this.state.supplierGst, 250);
      var ht = doc.getTextDimensions(suppaAdd).h;
      doc.text(150, k, suppaAdd);
      k = k + ht;
    }
    doc.setFontType("bold");
    k = k + 20;
    doc.text(30, k, "Dear Sir/Madam,");
    doc.setFontSize(9);
    k = k + 20;
    doc.text(30, k, "We would like to place the following order : ");
    k = k + 20;
    doc.setFontSize(8);
    doc.setFontType("bold");
    doc.cellInitialize();
    var itemCode = doc.splitTextToSize("ITEM CODE", 38);
    doc.cell(20, k, 40, 20, itemCode, "", "center");
    var desc = doc.splitTextToSize("ITEM", 130);
    doc.cell(50, k, 130, 20, desc, "", "center");
    var hsnCode = doc.splitTextToSize("HSN CODE", 50);
    doc.cell(100, k, 50, 20, hsnCode, "", "center");
    var weight = doc.splitTextToSize("QTY", 30);
    doc.cell(150, k, 30, 20, weight, "", "C");
    var uom = doc.splitTextToSize("UOM", 30);
    doc.cell(150, k, 30, 20, uom, "", "C");
    var bales_drums = doc.splitTextToSize("RATE", 40);
    doc.cell(200, k, 40, 20, bales_drums, "", "center");
    var moisture = doc.splitTextToSize("AMOUNT", 50);
    doc.cell(300, k, 50, 20, moisture, "", "center");
    doc.cell(350, k, 60, 10, "CGST", "", "center");
    doc.cell(400, k, 60, 10, "SGST", "", "center");
    doc.cell(450, k, 60, 10, "IGST", "", "center");
    doc.cellInitialize();
    k = k + 10;
    doc.cell(390, k, 25, 10, "Rate", "", "center");
    doc.cell(420, k, 35, 10, "Amt", "", "center");
    doc.cell(450, k, 25, 10, "Rate", "", "center");
    doc.cell(480, k, 35, 10, "Amt", "", "center");
    doc.cell(510, k, 25, 10, "Rate", "", "center");
    doc.cell(530, k, 35, 10, "Amt", "", "center");
    var cellWidths = [
      "",
      40,
      130,
      50,
      30,
      30,
      40,
      50,
      25,
      35,
      25,
      35,
      25,
      35,
      30,
    ];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = k + 10;
      var i = 0;
      var totalQty = 0;
      var height = 25;

      modules.map((prop, key) => {
        if (i > 0) k = k + height;
        i = i + 1;
        totalQty = totalQty + parseFloat(prop.bales_drums);
        doc.cellInitialize();
        if (k > 810) {
          k = 30;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 825, newdat);
          doc.text(500, 825, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var itemCode = doc.splitTextToSize("ITEM CODE", 38);
          doc.cell(20, k, 40, 20, itemCode, "", "center");
          var desc = doc.splitTextToSize("ITEM", 160);
          doc.cell(50, k, 160, 20, desc, "", "center");
          var hsnCode = doc.splitTextToSize("HSN CODE", 50);
          doc.cell(100, k, 50, 20, hsnCode, "", "center");
          var weight = doc.splitTextToSize("QTY", 30);
          doc.cell(150, k, 30, 20, weight, "", "C");
          var uom = doc.splitTextToSize("UOM", 30);
          doc.cell(150, k, 30, 20, uom, "", "C");
          var bales_drums = doc.splitTextToSize("RATE", 40);
          doc.cell(200, k, 40, 20, bales_drums, "", "center");
          var moisture = doc.splitTextToSize("AMOUNT", 50);
          doc.cell(300, k, 50, 20, moisture, "", "center");
          doc.cell(350, k, 60, 10, "CGST", "", "center");
          doc.cell(400, k, 60, 10, "SGST", "", "center");
          doc.cell(450, k, 60, 10, "IGST", "", "center");
          doc.cellInitialize();
          k = k + 10;
          doc.cell(390, k, 25, 10, "Rate", "", "center");
          doc.cell(420, k, 35, 10, "Amt", "", "center");
          doc.cell(450, k, 25, 10, "Rate", "", "center");
          doc.cell(480, k, 35, 10, "Amt", "", "center");
          doc.cell(510, k, 25, 10, "Rate", "", "center");
          doc.cell(530, k, 35, 10, "Amt", "", "center");
          doc.cellInitialize();
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;

        var row = 1;
        Object.keys(prop).map((key, index) => {
          var myItem = prop[key] + " ";
          var item1 = doc.splitTextToSize(myItem, cellWidths[i + 1] - 1);
          //console.log("getTextDimensions",doc.getTextDimensions(item1).h);
          var row1 = parseInt(doc.getTextDimensions(item1).h / 25);
          if (row1 > row) {
            row = row1;
          }
        });
        // console.log("row",row);
        if (row > 1) {
          height = 10 + row * 10;
          // k=k+row*10;
        } else {
          height = 25;
        }

        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            var myItem = prop[key] + " ";

            myItem = doc.splitTextToSize(myItem, cellWidths[a] - 1);
          }

          return doc.cell(
            20,
            k,
            cellWidths[a],
            height,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    k = k + height;

    console.log("kval", k);
    doc.setFontSize(9);

    var totalamount = doc.splitTextToSize(
      parseFloat(printHeaderValues.quoteAmount).toFixed(2) + " ",
      320
    );
    var totalamountWithTax = doc.splitTextToSize(
      parseFloat(printHeaderValues.grandTotal).toFixed(2) + " ",
      300
    );

    doc.cellInitialize();
    doc.setFontType("bold");
    if (k > 810) {
      doc.addPage("a4", "p");
      k = 30;
    }
    if (totalIgst > 0)
      totalIgst = doc.splitTextToSize(totalIgst.toFixed(2) + " ", 60);
    else totalIgst = " ";
    if (totalCgst > 0)
      totalCgst = doc.splitTextToSize(totalCgst.toFixed(2) + " ", 60);
    else totalCgst = " ";
    if (totalSgst > 0)
      totalSgst = doc.splitTextToSize(totalSgst.toFixed(2) + " ", 60);
    else totalSgst = " ";
    console.log("igst", totalIgst + " kk");

    doc.cell(20, k, 40, 15, "Total", "", "left");
    doc.cell(105, k, 330, 15, totalamount + " ", "", "right");
    doc.cell(330, k, 60, 15, totalIgst + " ", "", "right");
    doc.cell(390, k, 60, 15, totalCgst + " ", "", "right");
    doc.cell(420, k, 60, 15, totalSgst + " ", "", "right");

    doc.cellInitialize();
    k = k + 15;
    if (k > 810) {
      doc.addPage("a4", "p");
      k = 30;
    }

    doc.cell(20, k, 170, 15, "Total Invoice Value", "", "left");
    doc.cell(160, k, 380, 15, totalamountWithTax, "", "left");
    k = k + 15;
    if (k > 810) {
      doc.addPage("a4", "p");
      k = 30;
    }

    doc.cellInitialize();

    doc.cell(20, k, 170, 15, "Total Invoice Value (In Words)", "", "left");

    function withDecimal(n) {
      var nums = n.toString().split(".");
      var whole = inWords(nums[0]);
      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          var fraction = inWords(parseInt(nums[1]));
          return whole + "Rupees and " + fraction + "Paisa";
        } else {
          return whole + "Rupees";
        }
      } else {
        return whole + "Rupees";
      }
    }
    var a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            " "
          : "";
      return str;
    }
    var amount = withDecimal(printHeaderValues.grandTotal);
    doc.cell(160, k, 380, 15, amount + " ", "", "left");
    /*  doc.cell(30, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(105, k + 25, 100, 30, ' ', '', 'right');
          doc.cell(147, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(189, k + 25, 60, 30, ' ', '', 'right');
          doc.cell(231, k + 25, 30, 30, ' ', '', 'right');
          doc.cell(189, k + 25, 60, 30, ' ', '', 'right');
          doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(322, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(364, k + 25, 70, 30, totalamount, '', 'right');*/
    doc.setFontType("normal");
    doc.setFontSize(9);

    k = k + 30;
    if (k > 810) {
      doc.addPage("a4", "p");
      k = 30;
    }
    if (
      printHeaderValues.frieghtCharge !== null &&
      printHeaderValues.frieghtCharge > 0
    ) {
      doc.setFontType("italic");
      var freight = doc.splitTextToSize(
        "*Frieght charges will be " + printHeaderValues.frieghtCharge + " INR",
        400
      );
      var ht = doc.getTextDimensions(freight).h;
      if (k + 20 + ht > 830) {
        doc.addPage("a4", "p");
        k = 30;
      }
      doc.text(30, k, freight);
      k = k + ht;
    }
    k = k + 10;

    var footer =
      "1. Please submit invoice in tripilicate.\n2. Late delivery will be subject to penality.";
    var note = " ";
    if (printHeaderValues.footerNotes !== null) {
      doc.setFontType("bold");
      doc.setFontSize(10);
      note = doc.splitTextToSize(printHeaderValues.footerNotes, 300);
      var ht = doc.getTextDimensions(note).h;
      if (k + 20 + ht > 830) {
        doc.addPage("a4", "p");
        k = 30;
      }

      doc.text(30, k, "Note : ");
      doc.setFontType("normal");
      k = k + 20;

      doc.text(30, k, note);
      k = k + ht;
    }
    k = k + 10;
    if (k > 810) {
      doc.addPage("a4", "p");
      k = 30;
    }

    var deliveryAddress = " ";
    if (printHeaderValues.shippingAddress !== null) {
      deliveryAddress = doc.splitTextToSize(
        printHeaderValues.shippingAddress,
        300
      );
      var ht = doc.getTextDimensions(deliveryAddress).h;
      doc.setFontType("bold");
      if (k + 20 + ht > 830) {
        doc.addPage("a4", "p");
        k = 30;
      }
      doc.text(30, k, "Delivery Address : ");
      k = k + 20;
      doc.setFontType("normal");
      doc.text(30, k, deliveryAddress);
      k = k + ht;
    }

    k = k + 10;
    if (k > 810) {
      doc.addPage("a4", "p");
      k = 30;
    }

    var remarks = " ";
    if (printHeaderValues.termsConditions !== null) {
      remarks = doc.splitTextToSize(printHeaderValues.termsConditions, 300);
      var ht = doc.getTextDimensions(remarks).h;
      doc.setFontType("bold");
      if (k + 20 + ht > 830) {
        doc.addPage("a4", "p");
        k = 30;
      }
      doc.text(30, k, "Remarks : ");
      k = k + 20;

      doc.setFontType("normal");

      doc.text(30, k, remarks);
      k = k + ht;
    }
    k = k + 20;
    if (k > 830) {
      doc.addPage("a4", "p");
      k = 30;
    }

    doc.text(350, k, "FOR" + " " + companyName);
    //doc.text(400, k + 180, 'Authorised Signature');
    doc.setFontSize(8);
    doc.setFontType("bold");
    k = k + 50;
    if (k > 830) {
      doc.addPage("a4", "p");
      k = 30;
    }
    doc.setFontSize(7);

    var note =
      "Note* : This is a computer generated print, Signature is not required.";
    //   var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
    doc.text(30, k, note);
    k = k + 10;
    if (k > 830) {
      doc.addPage("a4", "p");
      k = 30;
    }

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = "Printed on: " + today;
    var printBy = "Printed By: " + name;
    doc.text(30, k, newdat);
    doc.text(500, k, printBy);
    //addWaterMark(doc,printcopy);

    if (this.state.sendMail) {
      const filename = "QUOTATION_" + printHeaderValues.quoteNoString + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      const formData = new FormData();
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", getCookie(cons.COMPANY_ID));
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("quoteId", this.state.quoteId);
      formData.append("fileName", filename);

      var URL = serverApi.QUOTATIONS_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handleAddIndent);
    } else {
      doc.save("QUOTATION_" + printHeaderValues.quoteNoString + ".pdf");
    }
  };

  handlePrintResp = (resp) => {
    var printHeaderValues = resp.quoteHdr;
    var modules = [];
    var lineItems = resp.quoteLineItems;
    var totalIgst = 0,
      totalSgst = 0,
      totalCgst = 0;

    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var tax = 0;
        if (prop.igstAmt > 0) totalIgst = totalIgst + parseFloat(prop.igstAmt);
        if (prop.sgstAmt > 0) totalSgst = totalSgst + parseFloat(prop.sgstAmt);
        if (prop.cgstAmt > 0) totalCgst = totalCgst + parseFloat(prop.cgstAmt);
        if (prop.cgstPer > 0)
          tax = parseFloat(prop.cgstPer) + parseFloat(prop.sgstPer);
        var desc = " ";
        if (prop.itemDescription !== null) {
          desc = prop.itemDescription;
        }
        var obj = {
          itemId: prop.itemId,
          itemName: prop.itemName + "\n" + desc,
          hsnCode: prop.hsnCode,
          rate: parseFloat(prop.rate).toFixed(2),
          quantity: prop.quantity,
          tax: prop.igstPer > 0 ? prop.igstPer + "%" : tax + "%",
          amount:
            prop.amountWithoutTax > 0
              ? parseFloat(prop.amountWithoutTax).toFixed(2)
              : 0.0,
        };
        modules.push(obj);
      });
    }
    var poId = printHeaderValues.quoteUniqueNo;
    poId = poId + " ";
    const doc = new Jspdf("p", "pt", "a4");

    var pageNo = 1;

    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.setFontSize(8);

    doc.text(500, 20, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(16);
    doc.setFontType("bold");
    doc.text(450, 40, "QUOTATION");
    doc.setFontSize(8);
    var company = doc.splitTextToSize(" " + getCookie(cons.COMPANY_TITLE), 180);
    doc.text(550, 60, company, { align: "right" });
    doc.setFontType("normal");
    doc.setLineHeightFactor(2);
    var add1 = doc.splitTextToSize(
      " " +
        getCookie(cons.COMPANY_ADDRESS) +
        "\n" +
        getCookie(cons.COMPANY_ADDRESS2),
      180
    );
    doc.text(550, 80, add1, { align: "right" });
    doc.setLineHeightFactor(1);

    doc.line(20, 120, 550, 120);
    doc.text(20, 130, "BILL TO : ");
    doc.setFontType("bold");
    var billingAddress = doc.splitTextToSize(
      printHeaderValues.billingAddress,
      100
    );
    doc.text(21, 145, billingAddress);
    doc.line(200, 120, 200, 200);
    doc.setFontType("normal");
    doc.text(200, 130, "SHIP TO : ");
    doc.setFontType("bold");
    var shippingAddress = doc.splitTextToSize(
      printHeaderValues.shippingAddress,
      100
    );
    doc.text(201, 145, shippingAddress);
    doc.line(400, 120, 400, 200);
    doc.setFontSize(8);
    var quote = doc.splitTextToSize(
      "Quote No : " + printHeaderValues.quoteNoString,
      150
    );
    doc.text(550, 131, quote, { align: "right" });
    var quoteDate = doc.splitTextToSize(
      "Quote Date : " + printHeaderValues.quoteDateDesc,
      100
    );
    doc.text(550, 145, quoteDate, { align: "right" });
    var expiryDate = doc.splitTextToSize(
      "Expiry Date : " + printHeaderValues.expireDateDesc,
      100
    );
    doc.text(550, 160, expiryDate, { align: "right" });
    var grandTotal = doc.splitTextToSize(
      "GRAND TOTAL(INR) : " +
        parseFloat(printHeaderValues.grandTotal).toFixed(2),
      200
    );
    doc.text(550, 174, grandTotal, { align: "right" });

    doc.line(20, 200, 550, 200);

    doc.cellInitialize();

    var itemCode = doc.splitTextToSize("ITEM CODE", 100);
    doc.cell(20, 200, 60, 20, itemCode, "", "center");
    var desc = doc.splitTextToSize("ITEM", 180);
    doc.cell(50, 200, 180, 20, desc, "", "center");
    var hsnCode = doc.splitTextToSize("HSN CODE", 50);
    doc.cell(100, 200, 60, 20, hsnCode, "", "center");
    var bales_drums = doc.splitTextToSize("RATE", 50);
    doc.cell(150, 200, 60, 20, bales_drums, "", "center");
    var weight = doc.splitTextToSize("QUANTITY", 50);
    doc.cell(200, 200, 60, 20, weight, "", "C");
    var unit = doc.splitTextToSize("TAX", 40);
    doc.cell(250, 200, 30, 20, unit, "", "center");
    var moisture = doc.splitTextToSize("AMOUNT", 80);
    doc.cell(300, 200, 80, 20, moisture, "", "center");
    doc.cellInitialize();

    var cellWidths = ["", 60, 180, 60, 60, 60, 30, 80, 40, 40, 50];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "left",
      "right",
      "left",
      "right",
      "right",
      "left",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(8);

    {
      var k = 195;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;

        doc.cellInitialize();
        if (i === 27) {
          k = 190;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var itemCode = doc.splitTextToSize("ITEM CODE", 50);
          doc.cell(20, 200, 60, 20, itemCode, "", "C");
          var desc = doc.splitTextToSize("ITEM", 50);
          doc.cell(50, 200, 100, 20, desc, "", "C");
          var hsnCode = doc.splitTextToSize("HSN CODE", 50);
          doc.cell(100, 200, 60, 20, hsnCode, "", "C");
          var bales_drums = doc.splitTextToSize("RATE", 50);
          doc.cell(150, 200, 60, 20, bales_drums, "", "C");
          var weight = doc.splitTextToSize("QUANTITY", 100);
          doc.cell(200, 200, 100, 20, weight, "", "C");
          var unit = doc.splitTextToSize("TAX", 40);
          doc.cell(250, 200, 40, 20, unit, "", "C");
          var moisture = doc.splitTextToSize("AMOUNT", 100);
          doc.cell(300, 200, 100, 20, moisture, "", "C");
          doc.cellInitialize();
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemDescription") {
              doc.setFontStyle("bold");
            } else {
              doc.setFontStyle("normal");
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, cellTextaligns[a]);
          }

          return doc.cell(
            20,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    var totalamount = doc.splitTextToSize(
      parseFloat(printHeaderValues.quoteAmount).toFixed(2) + " ",
      50
    );
    totalCgst = doc.splitTextToSize(parseFloat(totalCgst).toFixed(2) + " ", 50);
    totalIgst = doc.splitTextToSize(parseFloat(totalIgst).toFixed(2) + " ", 50);
    totalSgst = doc.splitTextToSize(parseFloat(totalSgst).toFixed(2) + " ", 50);

    var totaltax = doc.splitTextToSize(
      parseFloat(printHeaderValues.taxAmount).toFixed(2) + " ",
      50
    );
    doc.cellInitialize();
    doc.setFontStyle("bolditalic");
    doc.cell(20, k + 25, 450, 20, "SUB TOTAL ", "", "right");
    doc.setFontStyle("normal");
    doc.cell(450, k + 25, 80, 20, totalamount, "", "right");
    doc.cellInitialize();
    doc.setFontStyle("bolditalic");
    doc.cell(20, k + 45, 65, 20, "IGST", "", "right");
    doc.setFontStyle("normal");
    doc.cell(60, k + 45, 60, 20, " " + totalIgst, "", "right");
    doc.setFontStyle("bolditalic");

    doc.cell(60, k + 45, 65, 20, "CGST", "", "right");
    doc.setFontStyle("normal");

    doc.cell(120, k + 45, 60, 20, " " + totalCgst, "", "right");
    doc.setFontStyle("bolditalic");

    doc.cell(180, k + 45, 65, 20, "SGST", "", "right");
    doc.setFontStyle("normal");

    doc.cell(250, k + 45, 60, 20, " " + totalSgst, "", "right");
    doc.setFontStyle("bolditalic");

    doc.cell(310, k + 45, 75, 20, "TAX TOTAL", "", "right");
    doc.setFontStyle("normal");

    doc.cell(370, k + 45, 80, 20, totaltax, "", "right");
    doc.cellInitialize();
    doc.setFontStyle("bolditalic");
    doc.cell(20, k + 65, 450, 20, "Freight Charges ", "", "right");
    doc.setFontStyle("normal");
    doc.cell(
      370,
      k + 65,
      80,
      20,
      +printHeaderValues.freightCharges > 0
        ? parseFloat(printHeaderValues.freightCharges).toFixed(2) + " "
        : 0.0 + " ",
      "",
      "right"
    );
    doc.cellInitialize();
    doc.setFontStyle("bolditalic");
    doc.cell(20, k + 85, 450, 20, "GRAND TOTAL ", "", "right");
    doc.setFontStyle("normal");
    doc.cell(
      370,
      k + 85,
      80,
      20,
      +printHeaderValues.grandTotal > 0
        ? parseFloat(printHeaderValues.grandTotal).toFixed(2) + " "
        : 0.0 + " ",
      "",
      "right"
    );
    doc.setFontType("normal");
    // doc.setFontSize(9);

    var a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str;
    }
    //  doc.text(30,k + 120,'IN WORDS : '+inWords(printHeaderValues.quoteAmount)+ ' RUPEES ONLY.');
    doc.cellInitialize();

    doc.setFontStyle("bold");
    doc.cell(20, k + 105, 60, 20, "In Words: ", "", "right");
    doc.cell(
      100,
      k + 105,
      470,
      20,
      inWords(printHeaderValues.quoteAmount) + "Rupees Only. ",
      "",
      "left"
    );
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text(30, k + 140, "Payment Terms : ");
    doc.text(50, k + 160, printHeaderValues.paymentTerms + " ");
    //  doc.text(30, k + 180, '2. This purchase order can be cancelled by us at any time. ');
    doc.text(30, k + 200, "Other Terms  : ");
    doc.text(50, k + 220, printHeaderValues.termsConditions + " ");
    doc.setFontStyle("bold");
    doc.text(30, k + 280, "Note*: ");
    doc.text(
      70,
      k + 280,
      printHeaderValues.footerNotes !== null
        ? printHeaderValues.footerNotes
        : " "
    );

    //  doc.text(30, k + 220, '4. Taxes as applicable and the prices mentioned are excluded taxes');

    doc.text(30, k + 350, "FOR" + " " + getCookie(cons.COMPANY_TITLE));
    doc.text(30, k + 400, "Authorised Signature" + " ");

    //doc.text(400, k + 180, 'Authorised Signature');
    /*  doc.setFontSize(8);
        doc.setFontType("bold");
        var note='Note* : This is a computer generated print, Signature is not required.';
        var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
        doc.text(note, NotexOffset, k + 290);

        //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');*/
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 450, newdat);
    doc.text(500, k + 450, printBy);
    // addWaterMark(doc,printcopy);
    if (this.state.sendMail) {
      const filename = "QUOTATION_" + printHeaderValues.quoteNoString + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      const formData = new FormData();
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", getCookie(cons.COMPANY_ID));
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("quoteId", this.state.quoteId);
      formData.append("fileName", filename);

      var URL = serverApi.QUOTATIONS_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handleAddIndent);

      this.setState({
        sendMail: false,
      });
    } else {
      doc.save("QUOTATION_" + printHeaderValues.quoteNoString + ".pdf");
    }
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  textFieldRight: {
    textAlign: "right",
    paddingRight: "5px",
    paddingBottom: "10px",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(Quotations);
