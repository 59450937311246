import React from "react";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import {DataTypes} from "../TableData";


class ProductProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            isRefresh: false,
            isLoading: false,
            itemViewType: "id",
            "dataType": "",
            "defaultValue": "",
            "maxLenght": 0,
            "property": "",
            "required": true,
            "variantId": 0,
            "productId":0,
            dataTable:null,
            ItemOptions1:[],
            itemGroupsList:[]
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);

    }
    componentDidMount() {
        var table={"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"hrmsAccess":false,"approveButton":false,"totalRecords":0,"column":[{"filterable":"true","Header":"Property","accessor":"property","style":{"textAlign":"center"},"sortable":"true"},{"filterable":"true","Header":"Varient","accessor":"variantName","style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Product","accessor":"productName","style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Default Value","accessor":"defaultValue","style":{"textAlign":"left"},"sortable":"true"}]}
        this.setState({dataTable:table,isRfresh:true});
        this.getTableData();
        this.getItemGroups();
    }
    getItemGroups=()=>{
        getDataFromUrl(serverApi.SEARCH_ITEM_GROUPS + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER),this.handleItemGroups)
    };
    handleItemGroups=(resp)=>{
        console.log("itemGroups",resp);
        var list=[];
        resp.forEach(prop=>{
            var obj={label:prop.label,value:prop.id,name:prop.name+"^"+prop.value};
            list.push(obj);
        });
        this.setState({itemGroupsList:list,loadItemGroup:true})
    };
    getTableData=()=>{
        var data=this.getData();
        requestList(serverApi.PRODUCT_PROPERTY_LIST,data,this.handleTableData)
    };
    handleTableData=(resp)=>{
        if(resp.data){
            var table=this.state.dataTable;
            table.data=resp.data;
            this.setState({dataTable:table,isRefresh:true});
        }/*else {
            var table={"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"hrmsAccess":false,"approveButton":false,"totalRecords":0,"column":[{"filterable":"true","Header":"Property","accessor":"id","style":{"textAlign":"center"},"sortable":"true"},{"filterable":"true","Header":"Varient","accessor":"variantName","style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Product","accessor":"productName","style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Default Value","accessor":"defaultValue","style":{"textAlign":"left"},"sortable":"true"}]}
            this.setState({dataTable:table,isRfresh:true});
        }*/
    };

    handleSeacrh = () => {
        var loading = false;
        if (!this.state.isRefresh) {
          this.getTableData();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            this.handleLoading()
           getDataFromUrl(serverApi.PRODUCT_PROPERTY_BY_ID+id,this.handleViewClickResp)
        }
    };



    handleViewClickResp = (resp) => {
        var header = resp.data;
        if(resp.status)
        if (header !== null) {

            this.setState({
                updateView: true,
                isCreated: true,
                //taxId: header.taxId,
                createdBy: header.createdBy,
                createdOn: header.createdDateTime,
                "id":header.id,
                "dataType": header.dataType,
                "defaultValue":  header.defaultValue,
                "maxLenght":  header.maxLenght,
                "property":  header.property,
                "required":  header.required,
                "variantId":  header.variantId,
                "productId": header.productId,
            });
            this.handleLoading();
        }

    };


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
            this.handleLoading();
            getDataFromUrl(serverApi.SEARCH_DEPATMENTS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER),this.handleItems)
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    handleItems =(resp)=>{
        var list=[];
        resp.map((prop,key)=>{
            var val={
                deptId:prop.value,
                deptName:prop.label,
             //   mapId:0,
                isEnabled:false
            };
            if(this.state.updateView){
                this.state.departmentList.map((o,key)=>{
                    if(o.deptId+""===val.deptId+""&& o.isMapped){
                        val.isEnabled=true;
                      //  val.mapId=o.mapId;
                    }
                })

            }
            if(key>0)
                list.push(val)
        });
        this.setState({
            departmentList:list
        });
        this.handleLoading();
    };
    handleChange= (index )=> event => {

        var arr=this.state.departmentList;
        arr[index].isEnabled=!arr[index].isEnabled;
        this.setState({
            departmentList:arr
        })

    };


    getData = () => {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "productId":this.state.productId1
        };
        return data;
    };




    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {

/*
        if (stateKey === 'variantId') {
            var data = {
                "itemGroupId": selectedValue,
                "saleable": "Y",
                "consumable": "",
                "companyId": getCookie(cons.COMPANY_ID)
            };

            requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
            // this.setState({
            //     loadItem: true
            // })

        }
*/
        if (stateKey === 'varientId1') {

            var data = {
                "itemGroupId": selectedValue,
                "saleable": "Y",
                "consumable": "",
                "companyId": getCookie(cons.COMPANY_ID)
            };

            requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleProduct);
        };



        this.setState({
                [stateKey]: selectedValue
            });
    };
    handleItem = (resp) => {
        var list=[];
        resp.forEach(prop=>{
            var obj={label:prop.label,value:prop.itemId,name:prop.name};
            list.push(obj);
        });
        this.setState({ItemOptions: list, loadItem: true})

    };
    handleProduct = (resp) => {
        var list=[];
        resp.forEach(prop=>{
            var obj={label:prop.label,value:prop.itemId,name:prop.name};
            list.push(obj);
        });
        this.setState({ItemOptions1: list, loadItem: true})

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,

        });
    }

    hideAlertMessage() {
        this.getTableData();
        this.setState({
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            "dataType": "",
            "defaultValue": "",
            "maxLenght": 0,
            "property": "",
            "required": true,
            "variantId": 0,
            "productId":0
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {id,dataType, defaultValue,maxLenght,property,required,variantId,productId} = this.state;
            var data = [{
                "id":id,
                "dataType": dataType,
                "defaultValue": defaultValue,
                "maxLenght": maxLenght,
                "property": property,
                "required": required,
                "variantId": variantId,
                "productId":productId,
                "companyId": getCookie(cons.COMPANY_ID),
                "createdBy": getCookie(cons.USERID),
            }];

            if (dataType !== '' &&defaultValue!==""&&maxLenght>0&&property!==""&&required!==""&&variantId!==""&&productId!=="") {
                this.handleLoading();
                requestList(serverApi.PRODUCT_PROPERTY_UPDATE, data, this.handleAddItem)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {dataType, defaultValue,maxLenght,property,required,variantId,productId} = this.state;
            var data = [{
                "dataType": dataType,
                "defaultValue": defaultValue,
                "maxLenght": maxLenght,
                "property": property,
                "required": required,
                "variantId": variantId,
                "productId":productId,
                "companyId": getCookie(cons.COMPANY_ID),
                "createdBy": getCookie(cons.USERID),
            }];

            if (dataType !== '' &&defaultValue!==""&&maxLenght>0&&property!==""&&required!==""&&variantId!==""&&productId!=="") {
                this.handleLoading();
                requestList(serverApi.PRODUCT_PROPERTY_ADD, data, this.handleAddItem)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddItem = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    render() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;

        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Property<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="property"
                                                    value={this.state.property}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Data Type<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.dataType}
                                                    staticData={DataTypes}
                                                    onSelect={this.onSelect}
                                                    stateKey="dataType"

                                                    //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                />
{/*
                                                <TextField
                                                    id="dataType"
                                                    value={this.state.dataType}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
*/}
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Default Value<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="defaultValue"
                                                    value={this.state.defaultValue}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Max Length<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="maxLenght"
                                                    value={this.state.maxLenght}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    iType={"number"}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Variant<span style={{
                                                    color: "red",
                                                    fontSize: "12px"
                                                }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.variantId}
                                                    onSelect={this.onSelect}
                                                    stateKey="variantId"
                                                    staticData={this.state.itemGroupsList}
                                                    isRefresh={this.state.loadItemGroup}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({ loadItemGroup:false})
                                                    }}
                                                    getName={(name) => {
                                                        name=name.split("^");
                                                        this.setState({variantDesc: name[0]})
                                                        var data = {
                                                            "itemGroupId": name[1],
                                                            "saleable": "",
                                                            "consumable": "",
                                                            "companyId": getCookie(cons.COMPANY_ID)
                                                        };
                                                        requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
                                                    }}

                                                    // url={serverApi.SEARCH_ITEM_GROUPS + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={6} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Product<span style={{
                                                    color: "red",
                                                    fontSize: "12px"
                                                }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.productId}
                                                    staticData={this.state.ItemOptions}
                                                    onSelect={this.onSelect}
                                                    stateKey="productId"
                                                    isRefresh={this.state.loadItem}
                                                    setIsRefresh={(val) => {
                                                        this.setState({loadItem: val})
                                                    }}
                                                    //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>

                                                    <ItemGrid xs={12} sm={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Variant
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.varientId1}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="varientId1"
                                                                    url={serverApi.SEARCH_ITEM_GROUPS + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                                />
                                                            </ItemGrid>
                                                        </GridContainer>

                                                    </ItemGrid>
                                                    <ItemGrid xs={6} sm={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Product
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.productId1}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="productId1"
                                                                    staticData={this.state.ItemOptions1}
                                                                    isRefresh={this.state.loadItem}
                                                                    setIsRefresh={(val) => {
                                                                        this.setState({loadItem: val})
                                                                    }}
                                                                />

                                                            </ItemGrid>
                                                        </GridContainer>


                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>
                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>


                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            {this.state.dataTable!==null?
                                                <TableComponent
                                                    staticData={this.state.dataTable}
                                                   // url={serverApi.SPELLMASTER_DATATABLE}
                                                    searchData={this.getData()}
                                                    isRefresh={this.state.isRefresh}
                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                    isLoading={this.state.isRefresh}
                                                    onActionClick={this.handleViewClick}
                                                    itemViewType={this.state.itemViewType}
                                                    actionType={VIEW}
                                                    showDelete={false}
                                                />
:null}

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(ProductProperties);
