import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {dataTable} from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import cons, { serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";



class PayAdvice  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            isRefresh: false,
            isLoading: false,
            itemViewType: "recId",
            approveStatus: false,
            date1:moment().format("DD-MM-YYYY"),
            ebIds:getCookie(cons.EB_NO),
            adjustmentAmountAdd:"",
            adjustmentAmountDed: "",
            adjustmentDate: "",
            adjustmentNarrationAdd: "",
            adjustmentNarrationDed: "",
            employeeName: "",
            ebNo:getCookie(cons.EB_NO)
        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);

    }

    handleSeacrh = () => {
        // var loading = false;
        // if (this.state.isRefresh) {
        //     loading = true
        // }
        if(!this.state.isRefresh){
            this.callSalaryAdjustmentList();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            getDataFromUrl(serverApi.GET_BY_ID_SALARY_ADJUSTMENT+"/"+id+"/"+getCookie(cons.USERID), this.handleViewClickResp);
        }
    };



    handleViewClickResp = (resp) => {
        var o = resp;
        if (o !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                recId:o.recId,
                ebIds:o.ebNo,
                ebId:o.ebId,
                adjustmentAmountAdd:o.adjustmentAmountAdd,
                adjustmentAmountDed:o.adjustmentAmountDed,
                adjustmentDate:o.adjustmentDate,
                adjustmentNarrationAdd:o.adjustmentNarrationAdd,
                adjustmentNarrationDed:o.adjustmentNarrationDed,
                employeeName:o.employeeName,
                status:o.status,
                approveButton:o.approveButton
            });
        }


    };


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.callSalaryAdjustmentList();
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
            // ebIds:getCookie()
        });

        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {


        this.setState({
            [stateKey]: selectedValue
        });
    };

    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            updateItemAlert: false,
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if(this.state.deleteList){
            this.handleLoading();
            getDataFromUrl(serverApi.DELETE_SALARY_ADJUSTMENT+"/"+this.state.recId+"/"+getCookie(cons.USERID),this.handleAddSalaryAdjustment);

        }

        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideAlertMessage() {
        if(this.state.isCreated){
            this.callSalaryAdjustmentList();
        }
        this.setState({
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            ebId:'',
          //  ebIds:"",
            adjustmentAmountAdd:"",
            adjustmentAmountDed: "",
            adjustmentDate: "",
            adjustmentNarrationAdd: "",
            adjustmentNarrationDed: "",
            employeeName: "",
            ebNo:getCookie(cons.EB_NO)
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = (status) => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {recId,ebId, employeeName, adjustmentAmountAdd,adjustmentAmountDed,adjustmentDate,adjustmentNarrationAdd,adjustmentNarrationDed}=this.state;
            var data = {
                "recId":recId,
                "adjustmentAmountAdd": adjustmentAmountAdd,
                "adjustmentAmountDed": adjustmentAmountDed,
                "adjustmentDate": adjustmentDate,
                "adjustmentNarrationAdd": adjustmentNarrationAdd,
                "adjustmentNarrationDed": adjustmentNarrationDed,
                "ebId":ebId ,
                "employeeName":employeeName,
                "status":status===""?"":this.state.status,
                "updatedBy":getCookie(cons.USERID),
                "companyId": getCookie(cons.COMPANY_ID)
            };

            if (recId !== ""&& ebId > 0 && adjustmentDate!=="") {
                this.handleLoading();
                console.log("updateeeeeeeee",data)
                requestList(serverApi.UPDATE_SALARY_ADJUSTMENT, data, this.handleAddSalaryAdjustment)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {ebId, employeeName, adjustmentAmountAdd,adjustmentAmountDed,adjustmentDate,adjustmentNarrationAdd,adjustmentNarrationDed}=this.state;
            var data = {
                "adjustmentAmountAdd": adjustmentAmountAdd,
                "adjustmentAmountDed": adjustmentAmountDed,
                "adjustmentDate": adjustmentDate,
                "adjustmentNarrationAdd": adjustmentNarrationAdd,
                "adjustmentNarrationDed": adjustmentNarrationDed,
                "ebId":ebId ,
                "employeeName":employeeName,
                "createdBy":getCookie(cons.USERID),
                "companyId": getCookie(cons.COMPANY_ID)
            };

            if (ebId >0 && adjustmentDate!=="") {
                this.handleLoading();
                requestList(serverApi.ADD_SALARY_ADJUSTMENT, data, this.handleAddSalaryAdjustment)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddSalaryAdjustment = (resp) => {
        if (resp.status) {
            if(this.state.deleteList)
                this.callSalaryAdjustmentList();
            this.setState({
                deleteList:false,
                basicAlert:null
            });
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    onDeleteLeavePolicy=(id)=>{

        this.setState({deleteList: true,recId:id});

        this.basicItemAlert("Do You Like To Delete This Record?");
    };
    componentDidMount() {

        this.callSalaryAdjustmentList();
        this.getWorkersList();

    }

    callSalaryAdjustmentList(){
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "ebNo":this.state.ebIds,
            "userId":getCookie(cons.USERID)
        };

        console.log('sssssssssss',data);
        requestList(serverApi.GET_ALL_SALARY_ADJUSTMENT,data,this.handleSalaryAdjustment)
    }

    handleSalaryAdjustment =(resp)=>{
        this.setState({tableList:resp,isRefresh:true})
    }
    getWorkersList=()=>{
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "deptId":0,
            "empCode": "",
            "empName": "",
            "esiNo":"",
            "payScheme":"",
            "pfNo": "",
            "reporting":0,
            "userId":getCookie(cons.USERID)
        };

        requestList(serverApi.TABLE_WORKERS_LIST,data,this.handleworkers)
    };
    handleworkers = (resp) =>{
        var data=[];
        data= resp.data.map(prop=>{
            var obj={label:prop.workerName,value:prop.ebId,name:prop.workerName+"^"+prop.departmentId};
            return obj;
        });

        this.setState({
            empList:data,
            loadEmp:true
        });
        //}
        //  this.handleLoading();

    };
    getEmpDetails=()=>{
        const {ebNo} = this.state;
        if(ebNo!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+ebNo+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if(ebNo===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
                employeeName: header.workerName,
                dept: header.deptId,
                loadDept:true,
                ebId:header.ebId
            });

        }else {
            this.showMessage(resp.message,false)
        }


    };


    render() {
        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    EB No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="ebNo"
                                                    value={this.state.ebNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}


                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

{/*
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Employee<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.ebId}
                                                    id={"ebId"}
                                                    onSelect={this.onSelect}
                                                    getName={(name)=>{
                                                        var val=name.split("^")
                                                        if(val[1]!==null)
                                                            this.setState({employeeName:val[0]})
                                                    }}
                                                    isRefresh={this.state.loadEmp}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadEmp:false})
                                                    }}
                                                    stateKey="ebId"
                                                    staticData={this.state.empList}
                                                    //  url={serverApi.DATATABLE_SERVICE_URL + this.state.item + "/getQualityByItem"}
                                                    value={this.state.ebId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "adjustmentDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"adjustmentDate",
                                                            value:this.state.adjustmentDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Add Amount
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="adjustmentAmountAdd"
                                                    value={this.state.adjustmentAmountAdd}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Ded Amount
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="adjustmentAmountDed"
                                                    value={this.state.adjustmentAmountDed}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Add Narration
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="adjustmentNarrationAdd"
                                                    value={this.state.adjustmentNarrationAdd}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Ded Narration
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="adjustmentNarrationDed"
                                                    value={this.state.adjustmentNarrationDed}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{this.onUpdateButtonClick("")}}>Update</Button>
                                                </ItemGrid>
                                                {this.state.approveButton?
                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={this.onUpdateButtonClick}>Approve</Button>
                                                    </ItemGrid>
                                                    :null}
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={4} md={4} >
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        EB No
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        id="ebIds"
                                                        value={this.state.ebIds}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}


                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>

{/*
                                        <ItemGrid xs={4} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={3}>
                                                    <InputLabel className={classes.label}>
                                                        Employee
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={9}>
                                                    <SelectboxTemp
                                                        defValue={this.state.ebIds}
                                                        id={"ebIds"}
                                                        onSelect={this.onSelect}
                                                        getName={(name)=>{
                                                            var val=name.split("^")
                                                            if(val[1]!==null)
                                                                this.setState({dept:parseInt(val[1]),loadDept:true})
                                                        }}
                                                        isRefresh={this.state.loadEmp}
                                                        setIsRefresh={(val)=>{
                                                            this.setState({loadEmp:false})
                                                        }}
                                                        stateKey="ebIds"
                                                        staticData={this.state.empList}
                                                        //  url={serverApi.DATATABLE_SERVICE_URL + this.state.item + "/getQualityByItem"}
                                                        value={this.state.ebIds}
                                                    />

                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
*/}
                                        <ItemGrid xs={2} sm={2}>

                                                <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>

                                        </ItemGrid>

                                        <ItemGrid xs={6} sm={6}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                // url={serverApi.CLIENT_VISIT_LIST+"/"+getCookie(cons.COMPANY_ID)}
                                                staticData={this.state.tableList}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showDelete={true}
                                                onDeleteClick={this.onDeleteLeavePolicy}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(PayAdvice );
