import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Print from "@material-ui/icons/Print";
import BackArrow from "@material-ui/icons/ArrowBack";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
//import {Redirect} from "react-router-dom";
//import IconCard from "../../components/Cards/IconCard";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { defaultFont } from "../../assets/jss/material-dashboard-pro-react";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Close from "@material-ui/icons/Close";

class StoreDebitCredit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mrNo: "",
      searchMrNo: "",
      vehicleNo: "",
      poNO: "",
      grnNo: "",

      selectedDepartment: "",
      selectedItemGroup: "",
      date1: moment().format("DD-MM-YYYY"),
      form_supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      gateEntryNo: "",
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      contractNo: "",
      contractDate: "",
      exstn: "",
      agentId: "",
      form_vehicleNo: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "srNo",
      approveStatus: false,
      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",

      indentNo: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      loadGateEntry: false,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      alert: false,
      warehouseNo: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      materialHdrId: "0",
      lineItemId: "0",
      debitQuantity: "",
      creditQuantity: "",
      reason: "",
      type: "",
      debitCreditQty: "",
      debitCreditValue: 0,
      projectIds: "",
      branchIds: "",
      branchId: "",
      projectId: "",
      internalNote: "",
      autoCreate: "",
      filesList: [],
      make: ""
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    this.handleLoading();
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "getStoreMaterialGoodReceiveDetailsById/" +
          id +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    this.setState({ srPrintResp: resp });
    var header = resp.storeGoodReceiveHeader;
    var list = resp.storeGrnItemList;
    var indentlist = list.map((prop, key) => {
      var hdr = {
        title: prop.debitQuantity > 0 ? "DEBIT NOTE" : "CREDIT NOTE",
        id: prop.id,
        goodReceiptDateDesc: header.goodReceiptDateDesc,
        supplierId: header.supplierName + "(" + header.supplierId + ")",
        srNo: header.stprePrintNo,
        debitCreditDate: prop.debitQuantity > 0 ? prop.drDate : prop.crDate,
        storeGrnItemList: [prop],
        debitCreditValue:
          prop.debitQuantity > 0 ? prop.debitValue : prop.creditValue,
      };
      return {
        id: key,
        lineItemId: prop.id,
        storeGrnHdrId: prop.storeGrnHdrId,
        // itemId: parseInt(prop.itemId),
        itemId: prop.itemId,
        itemDesc: prop.itemDesc,
        itemGroupId: parseInt(prop.itemGrpId),
        itemGrpDesc: prop.itemGrpDesc,
        itemGrp: prop.itemGrpId + "" + prop.itemId,
        quantity: prop.actualQuantity,
        //quality: prop.actualQuantity,
        hsnCode: "",
        unit: prop.bale,
        igst: prop.iTax,
        sgst: prop.sTax,
        cgst: prop.cTax,
        uom: prop.uomCode,
        warehouseno: prop.wareHouseNo,
        rate: prop.rate,
        amount: prop.amountWithOutTax,
        depId: prop.deptId,
        qualityCode: prop.actualQuality,
        creditQuantity: prop.creditQuantity,
        debitQuantity: prop.debitQuantity,
        reason:
          prop.drNote !== null && prop.drNote !== ""
            ? prop.drNote
            : prop.crNote,
        // debitCreditValue:parseInt(prop.debitQuantity)*parseInt( prop.rate),
        debitValue: prop.debitValue,
        creditValue: prop.creditValue,
        debitCreditDate:
          prop.debitQuantity > 0 ? prop.drDateDesc : prop.crDateDesc,
        debitCreditValue:
          prop.debitValue > 0 ? prop.debitValue : prop.creditValue,
        printResp: hdr,
        taxPercentage: prop.taxPercentage,
        autoCreate: prop.autoCreate,
        //reason: prop.drNote,
        actions: (
          // we've added some custom button actions
          <div>
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;

                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.basicItemAlert("Do You Like To Update This Item?");
                    this.setState({
                      // alert: true,
                      updateItemAlert: true,
                      id: o.id,
                      lineItemId: prop.id,
                      materialHdrId: prop.materialGrnHdrId,
                      updateItem: true,
                      reloadSelect: true,
                      item: o.itemId,
                      itemDesc: o.itemDesc,
                      quantity: o.quantity,
                      selectedJuteQuality: o.qualityCode,
                      quality: o.quality,
                      hsnCode: o.hsnCode,
                      rate: o.rate,
                      weight: o.weight,
                      amount: o.amount,
                      selectedItemGroup: o.itemGroupId,
                      selectedDepartment: o.depId,
                      warehouseNo: o.warehouseno,
                      igst: o.igst,
                      sgst: o.sgst,
                      cgst: o.cgst,
                      type: this.getDefaultindentConvertion(
                        o.debitQuantity > 0 ? "DN" : "CN"
                      ),
                      reason: o.reason,
                      creditQuantity: o.creditQuantity,
                      debitQuantity: o.debitQuantity,
                      debitCreditQty:
                        o.debitQuantity > 0
                          ? o.debitQuantity
                          : o.creditQuantity,
                      debitCreditValue: o.debitCreditValue,
                      debitValue: o.debitValue,
                      creditValue: o.creditValue,
                      printResp: o.printResp,
                      taxPercentage: o.taxPercentage,
                      itemGrpDesc: o.itemGrpDesc,
                      autoCreate: o.autoCreate,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="edit"
            >
              {prop.autoCreate !== "Y" ? <Edit /> : null}
            </IconButton>

            <SamplePDF invoiceType={13} printResp={hdr} />
            {/*
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;

                                    data.find((o, i) => {
                                        if (o.id === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            //  data.splice(i,1);
                                            this.setState({
                                                // alert: true,
                                                id: o.id,
                                                lineItemId: prop.id,
                                                materialHdrId: prop.materialGrnHdrId,
                                                item: o.itemId,
                                                itemDesc: o.itemDesc,
                                                quantity: o.quantity,
                                                quality: o.quality,
                                                rate: o.rate,
                                                weight: o.weight,
                                                amount: o.amount,
                                                reason: o.reason,
                                                creditQuantity: o.creditQuantity,
                                                debitQuantity: o.debitQuantity,
                                                noteType: (o.debitQuantity > 0) ? 'DEBIT NOTE' : 'CREDIT NOTE',
                                                debitCreditQty: (o.debitQuantity > 0) ? o.debitQuantity : o.creditQuantity,
                                                debitCreditValue: o.debitCreditValue,
                                                debitValue: o.debitValue,
                                                creditValue: o.creditValue,
                                            });


                                            console.log("note type", (o.debitQuantity > 0) ? 'DEBIT NOTE' : 'CREDIT NOTE');
                                            this.handlePrintResp(o);
                                            return true;

                                        }
                                    });
                                    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getStoreMaterialGoodReceiveDetailsById/" + header.id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handlePrintResp)
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Print/>
                            </IconButton>
*/}
          </div>
        ),
      };
    });
    //  console.log("indentWeight " + header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        approveStatus: header.approveStatus,
        form_srNo: header.id,
        statusDesc: header.statusDesc,
        srDate: header.goodReceiptDateDesc,
        form_supplierId: header.supplierId,
        gateEntryNo: parseInt(header.chalanNo),
        form_poNO: header.poId,
        poDate: header.poDateDesc,
        challanNo: header.challanNo,
        challanDate: header.chalanDateDesc,
        contractNo: header.contractNo,
        contractDate: header.contractDateDesc,
        exstn: header.exSTN,
        srId: header.storeNo,
        form_vehicleNo: header.vehicleNo,
        //year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateDesc,
        date1: header.createDateDesc,
        mukamId: header.mukamId,
        invoiceList: indentlist,
        branchId: header.branchId,
        projectId: header.projectId,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "29/" +
          header.id +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
    }
    this.handleLoading();
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };

  handleViewClickRespBySrNo = (resp) => {
    var header = resp.storeGoodReceiveHeader;
    var list = resp.storeGrnItemList;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        lineItemId: prop.id,
        storeGrnHdrId: prop.storeGrnHdrId,
        // itemId: parseInt(prop.itemId),
        itemId: prop.itemId,
        itemDesc: prop.itemDesc,
        itemGrpDesc: prop.itemGrpDesc,
        itemGroupId: prop.itemGrpId,
        itemGrp: prop.itemGrpId + "" + prop.itemId,
        quantity: prop.actualQuantity,
        //quality: prop.actualQuantity,
        hsnCode: "",
        unit: prop.bale,
        igst: prop.iTax,
        sgst: prop.sTax,
        cgst: prop.cTax,
        uom: prop.uomCode,
        warehouseno: prop.wareHouseNo,
        rate: prop.rate,
        amount: prop.amountWithOutTax,
        depId: prop.deptId,
        qualityCode: prop.actualQuality,
        creditQuantity: prop.creditQuantity,
        debitQuantity: prop.debitQuantity,
        reason:
          prop.drNote !== null && prop.drNote !== ""
            ? prop.drNote
            : prop.crNote,
        // debitCreditValue:parseInt(prop.debitQuantity)*parseInt( prop.rate),
        debitValue: prop.debitValue,
        creditValue: prop.creditValue,
        debitCreditValue:
          prop.debitQuantity > 0 ? prop.debitValue : prop.creditValue,
        taxPercentage: prop.taxPercentage,
        autoCreate: prop.autoCreate,
        //reason: prop.drNote,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;

                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.basicItemAlert("Do You Like To Update This Item?");
                    this.setState({
                      // alert: true,
                      updateItemAlert: true,
                      id: o.id,
                      lineItemId: prop.id,
                      materialHdrId: prop.materialGrnHdrId,
                      updateItem: true,
                      reloadSelect: true,
                      item: o.itemId,
                      itemDesc: o.itemDesc,
                      quantity: o.quantity,
                      selectedJuteQuality: o.qualityCode,
                      quality: o.quality,
                      hsnCode: o.hsnCode,
                      rate: o.rate,
                      weight: o.weight,
                      amount: o.amount,
                      selectedItemGroup: o.itemGroupId,
                      selectedDepartment: o.depId,
                      warehouseNo: o.warehouseno,
                      igst: o.igst,
                      sgst: o.sgst,
                      cgst: o.cgst,
                      type: this.getDefaultindentConvertion(
                        o.debitQuantity > 0 ? "DN" : "CN"
                      ),
                      reason: o.reason,
                      creditQuantity: o.creditQuantity,
                      debitQuantity: o.debitQuantity,
                      debitCreditQty:
                        o.debitQuantity > 0
                          ? o.debitQuantity
                          : o.creditQuantity,
                      debitCreditValue: o.debitCreditValue,
                      debitValue: o.debitValue,
                      creditValue: o.creditValue,
                      taxPercentage: o.taxPercentage,
                      itemGrpDesc: o.itemGrpDesc,
                      autoCreate: o.autoCreate,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="edit"
            >
              {header.autoCreate !== "Y" ? <Edit /> : null}
            </IconButton>
            {/*
                            <IconButton
                                onClick={()=>{
                                    var data = this.state.invoiceList;

                                    data.find((o, i) => {
                                        if (o.id === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            //  data.splice(i,1);
                                            this.setState({
                                                // alert: true,
                                                lineItemId: prop.id,
                                                materialHdrId:prop.materialGrnHdrId,
                                                item: o.itemId,
                                                itemDesc: o.itemDesc,
                                                quantity: o.quantity,
                                                quality: o.quality,
                                                rate: o.rate,
                                                weight: o.weight,
                                                amount: o.amount,
                                                reason:o.reason,
                                                creditQuantity:o.creditQuantity,
                                                debitQuantity:o.debitQuantity,
                                                noteType:(o.debitQuantity > 0) ? 'DEBIT NOTE' : 'CREDIT NOTE',
                                                debitCreditQty:(o.debitQuantity>0)? o.debitQuantity : o.creditQuantity,
                                                debitCreditValue:o.debitCreditValue,
                                                debitValue:o.debitValue,
                                                creditValue:o.creditValue,
                                            });
                                            console.log("note type",(o.debitQuantity > 0) ? 'DEBIT NOTE' : 'CREDIT NOTE');
                                            this.handlePrintResp(o);
                                            return true;

                                        }
                                    });
                                    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getStoreMaterialGoodReceiveDetailsById/" + header.id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handlePrintResp)
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Print/>
                            </IconButton>
*/}
          </div>
        ),
      };
    });
    //  console.log("indentWeight " + header.indentWeight);
    if (header !== null) {
      this.setState({
        //updateView: true,
        isLoading: false,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        approveStatus: header.approveStatus,
        form_srNo: header.id,
        statusDesc: header.statusDesc,
        srDate: header.goodReceiptDateDesc,
        form_supplierId: header.supplierId,
        gateEntryNo: parseInt(header.chalanNo),
        form_poNO: header.poId,
        poDate: header.poDateDesc,
        challanNo: header.challanNo,
        challanDate: header.chalanDateDesc,
        contractNo: header.contractNo,
        contractDate: header.contractDateDesc,
        exstn: header.exSTN,
        srId: header.storeNo,
        form_vehicleNo: header.vehicleNo,
        //  year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateDesc,
        mukamId: header.mukamId,
        type: "",
        invoiceList: indentlist,
        branchId: header.branchId,
        projectId: header.projectId,
        loadBranch: true,
        loadProject: true,
        autoCreate: header.autoCreate,
      });
    }
  };

  getDefaultindentConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "DN") {
        val = "DN";
        label = "Debit";
      } else {
        val = "CN";
        label = "Credit";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getAgentName = (name) => {
    this.setState({
      agentName: name,
    });
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemDesc: values[0],
      itemGroupId: values[1],
      uom: values[2],
      hsnCode: values[4],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      loadquality: val,
    });
  };
  getQualityDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      quality: values[0],
      stock: values[1],
      rate: values[2],
      lastPurchase: values[3],
      allowableMoisture: values[4],
    });
    console.log("indentQuantity " + this.state.stock);
  };
  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      grnNo: this.state.grnNo,
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      poNo: this.state.poNo,
      supplier: this.state.supplierId,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      erpType: getConfigValue(configNames.LABELS_TYPE),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else {
      this.setState({
        [id]: "",
      });
    }
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  getYearFromDate = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[2];
    return newDate;
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / 150))
      );
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;

    if (event.target.id === "stock") {
      console.log("val " + this.state.indentQuantity);
      this.setState({
        stock: this.state.stock,
      });
    }
    if (event.target.id === "debitCreditQty") {
      if (value !== "") {
        var val = parseFloat(value) * parseFloat(this.state.rate);
        var taxPercenage = parseFloat(this.state.taxPercentage);
        if (taxPercenage > 0) {
          var taxamount = (val * taxPercenage) / 100;
          val = parseFloat(val + taxamount).toFixed(2);
        }

        if (
          this.state.type.value === "DN" &&
          parseFloat(value) <= parseFloat(this.state.quantity)
        )
          this.setState({
            debitCreditValue: val,
            debitValue: val,
            [event.target.id]: value,
          });
        else if (this.state.type.value === "CN") {
          this.setState({
            debitCreditValue: val,
            creditValue: val,
            [event.target.id]: value,
          });
        } else if (
          this.state.type.value === "DN" &&
          parseFloat(value) > parseFloat(this.state.quantity)
        ) {
          this.basicAlert("Debit Quantity Should Not Exceed Quantity..!!");
        }
      }
    } else
      this.setState({
        [event.target.id]: value,
      });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "form_supplierId") {
      this.setState({
        loadSr: true,
      });
    }
    if (stateKey === "branchIds" || stateKey === "branchId") {
      this.setState({ loadProject: true });
    }
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
      });
    }
    if (stateKey === "selectedItemGroup") {
      this.setState({
        loadItem: true,
      });
    }
    if (stateKey === "form_srNo") {
      if (selectedValue !== 0 && selectedValue !== "") {
        if (selectedValue !== this.state.form_srNo)
          getDataFromUrl(
            serverApi.DATATABLE_SERVICE_URL +
              "getStoreMaterialGoodReceiveDetailsById/" +
              selectedValue +
              "/" +
              getCookie(cons.USERID) +
              "/" +
              getCookie(cons.CIPHER),
            this.handleViewClickRespBySrNo
          );
      } else {
        this.removeStates();
      }
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
    });
  };
  getDeptName = (selectedValue) => {
    this.setState({
      deptName: selectedValue,
    });
  };
  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemName: values[0],
      // selectedItemId:values[1],
      uom: values[1],
      stock: values[2],
    });
  };

  afterRefreshGateEntry = (val) => {
    this.setState({
      loadSr: val,
    });
  };
  getGateEntryDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    /*
                this.setState({
                    form_poNO: values[1],
                });
        */
    // console.log("indentQuantity " + this.state.stock);
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) {
      this.hideItemAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      alert: false,
      showMessagealert: false,
      loading: false,

      reloadSelect: true,
      item: "",
      itemDesc: "",
      selectedJuteQuality: "",
      quality: "",
      hsnCode: "",
      rate: "",
      weight: "",
      amount: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      warehouseNo: "",
      igst: "",
      sgst: "",
      cgst: "",
      quantity: "",
      stock: "",
      type: "",
      reason: "",
      debitCreditQty: "",
      printResp: "",
    });
  }

  hideItemAlert() {
    console.log("alert");
    this.setState({
      updateItemAlert: false,
      loading: false,
      reloadSelect: true,
      item: "",
      itemDesc: "",
      selectedJuteQuality: "",
      quality: "",
      hsnCode: "",
      rate: "",
      weight: "",
      amount: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      warehouseNo: "",
      igst: "",
      sgst: "",
      cgst: "",
      quantity: "",
      stock: "",
      reason: "",
      debitCreditQty: "",
      type: "",
      debitValue: "",
      creditValue: "",
    });
  }

  removeStates() {
    this.setState({
      srNo: 0,
      statusDesc: "",
      srDate: "",
      form_supplierId: 0,
      gateEntryNo: 0,
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      srId: 0,
      form_vehicleNo: "",
      invoiceList: [],
    });
  }

  hideAlertMessage() {
    this.setState({
      isLoading: false,
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      lineItemId: "",
      materialHdrId: "",
      type: "",
      date1: moment().format("DD-MM-YYYY"),
      srNo: 0,
      statusDesc: "",
      srDate: "",
      form_supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      gateEntryNo: 0,
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      srId: 0,
      form_vehicleNo: "",
      form_srNo: "",
      branchId: "",
      projectId: "",
      autoCreate: "",
      filesList: [],
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert(type) {
    this.setState({
      form_srNo: 0,
      form_supplierId: 0,
      basicAlert: null,
      type: type,
    });
    this.removeStates();
  }

  unitConvertionAlert(msg, value) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert(value)}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.lineItemId,
        materialGrnHdrId: prop.materialHdrId,
        quantity: prop.quantity,
        marka: "",
        advisedQuality: prop.qualityCode,
        actualQuality: prop.qualityCode,
        advisedWeight: prop.weight,
        actualWeight: prop.weight,
        deviation: 0,
        rate: prop.rate,
        claimsQuality: "",
        claimsCondition: "16.6",
        warehouseNo: prop.warehouseno,
        remarks: "",
        totalPrice: prop.amount,
        itemId: prop.itemId,
        debitNotesFlag: "",
        quantityUnit: this.state.uom,
        issuableWeight: "0",
        bale: 0,
        loose: 0,
        actualBale: 0,
        actualLoose: 0,
        advisedItemId: this.state.itemId,
      };
    });

    var data = {
      materialGoodReceiveHeader: {
        id: this.state.mrNo,
        goodReceiptDate: this.dateChanged(this.state.mrDate),
        supplierId: this.state.form_supplierId,
        poId: this.state.form_poNO,
        poDate: this.dateChanged(this.state.poDate),
        contractNo: this.state.contractNo,
        contractDate: this.dateChanged(this.state.contractDate),
        chalanNo: this.state.challanNo,
        chalanDate: this.dateChanged(this.state.challanDate),
        warehouseNo: "",
        exSTN: this.state.exstn,
        submitter: getCookie(cons.USERID),
        agentId: this.state.agentId,
        agentName: this.state.agentName,
        sourceId: "64",
        vehicleNo: this.state.form_vehicleNo,
        weight: 0,
        mukamId: this.state.mukamId,
        modBy: getCookie(cons.USER_NAME),
        status: "4",
      },
      materialGRItemList: invoiceList,
    };
    this.handleLoading();
    requestList(serverApi.UPDATE_JUTE_MR, data, this.handleAddIndent);
  };
  onCommitButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denined. Please contact administrator!!!");
    } else {
      this.setState({
        commitClicked: true,
      });
      this.onApproveButtonClick();
      // requestList(serverApi.COMMIT_STORE_MATERIAL_INSPECTION,data,this.handleAddStoreGate)
    }
  };

  onApproveButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          id: prop.lineItemId,
          debitQuantity: prop.debitQuantity,
          creditQuantity: prop.creditQuantity,
          debitValue: parseFloat(prop.debitValue),
          creditValue: parseFloat(prop.creditValue),
          quantity: prop.quantity,
          reason: prop.reason,
          make: prop.make
        };
      });

      var data = {
        adjustmentDate: this.dateChanged(this.state.date1),
        finYear: this.state.year,
        supCode: this.state.form_supplierId,
        grnDate: this.dateChanged(this.state.srDate),
        grnYear: this.getYearFromDate(this.state.srDate),
        createdBy: getCookie(cons.USER_NAME),
        srLineItems: invoiceList,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        projectId: this.state.projectId,
        branchId: this.state.branchId,
        make: this.state.make
      };
      if (
        this.state.form_supplierId !== "0" &&
        this.state.form_supplierId !== "" &&
        this.state.form_srNo !== "" &&
        this.state.form_srNo !== "" &&
        this.state.invoiceList.length >= 1 &&
        this.state.projectId !== "" &&
        this.state.branchId !== ""
      ) {
        this.handleLoading();
        requestList(
          serverApi.ADD_DEBIT_CREDIT_NOTE,
          data,
          this.handleAddIndent
        );
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          id: prop.lineItemId,
          debitQuantity: prop.debitQuantity,
          creditQuantity: prop.creditQuantity,
          debitValue: parseFloat(prop.debitValue),
          creditValue: parseFloat(prop.creditValue),
          quantity: prop.quantity,
          reason: prop.reason,
          make: prop.make
        };
      });

      var data = {
        adjustmentDate: this.dateChanged(this.state.date1),
        finYear: this.state.year,
        supCode: this.state.form_supplierId,
        grnDate: this.dateChanged(this.state.srDate),
        grnYear: this.getYearFromDate(this.state.srDate),
        createdBy: getCookie(cons.USER_NAME),
        srLineItems: invoiceList,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        projectId: this.state.projectId,
        branchId: this.state.branchId,
        make: this.state.make
      };
      if (
        this.state.form_supplierId !== "0" &&
        this.state.form_supplierId !== "" &&
        this.state.form_srNo !== "" &&
        this.state.form_srNo !== "" &&
        this.state.invoiceList.length >= 1 &&
        this.state.projectId !== "" &&
        this.state.branchId !== ""
      ) {
        this.handleLoading();
        requestList(
          serverApi.ADD_DEBIT_CREDIT_NOTE,
          data,
          this.handleAddIndent
        );
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      /* if (this.state.commitClicked) {
                var url = serverApi.COMMIT_STORE_DEBIT_CREDIT + this.state.form_srNo + "/" + getCookie(cons.CIPHER);
                //getDataFromUrl(url,this.handleAddJuteGate);
                getDataFromUrl(url, this.handleAddIndent);
                this.setState({
                    commitClicked: false
                })
            } else{
                this.handleLoading();
                this.showMessage(resp.message, resp.status)
            }*/
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var sumOfQty =
      parseInt(this.state.sumOFQuantities) + parseInt(this.state.quantity);
    console.log("debitCreditValue " + this.state.debitCreditValue);
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    var addItemObj = {
      id: itemIndex,
      itemId: this.state.item,
      itemDesc: this.state.itemDesc,
      itemGrp: this.state.selectedItemGroup + "" + this.state.item,
      // unitConvertion:"",
      quantity: this.state.quantity,
      qualityCode: this.state.selectedJuteQuality,
      quality: this.state.selectedJuteQuality,
      unit: this.state.quantity,
      weight: this.state.weight,
      uom: this.state.uom,
      warehouseno: this.state.warehouseNo,
      rate: this.state.rate,
      amount: this.state.amount,
      itemGroupId: this.state.selectedItemGroup,
      depId: this.state.depId,
      lineItemId: this.state.lineItemId,
      materialHdrId: this.state.materialHdrId,
      debitCreditQty: this.state.debitCreditQty,
      debitQuantity:
        this.state.type.value === "DN" ? this.state.debitCreditQty : "0",
      creditQuantity:
        this.state.type.value === "CN" ? this.state.debitCreditQty : "0",
      igst: this.state.igst,
      sgst: this.state.igst,
      cgst: this.state.igst,
      type: this.state.type,
      reason: this.state.reason,
      debitCreditValue: this.state.debitCreditValue,
      debitValue: this.state.type.value === "DN" ? this.state.debitValue : 0,
      creditValue: this.state.type.value === "CN" ? this.state.creditValue : 0,
      printResp: this.state.printResp,
      taxPercentage: this.state.taxPercentage,
      itemGrpDesc: this.state.itemGrpDesc,
      make: this.state.make,
      actions: (
        // we've added some custom button actions
        <div>
          {/* use this button to remove the data row */}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // alert(o.qualityId);
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    // alert: true,
                    updateItemAlert: true,
                    id: o.id,
                    lineItemId: o.lineItemId,
                    materialHdrId: o.materialGrnHdrId,
                    updateItem: true,
                    reloadSelect: true,
                    item: o.itemId,
                    itemDesc: o.itemDesc,
                    quantity: o.unit,
                    selectedJuteQuality: o.quality,
                    quality: o.quality,
                    rate: o.rate,
                    weight: o.weight,
                    amount: o.amount,
                    hsnCode: o.hsnCode,
                    selectedItemGroup: o.itemGroupId,
                    selectedDepartment: o.depId,
                    warehouseNo: o.warehouseno,
                    igst: o.igst,
                    sgst: o.igst,
                    cgst: o.igst,
                    type: o.type,
                    reason: o.reason,
                    debitCreditQty: o.debitCreditQty,
                    debitCreditValue: o.debitCreditValue,
                    debitValue: o.debitValue,
                    creditValue: o.creditValue,
                    printResp: o.printResp,
                    taxPercentage: o.taxPercentage,
                    itemGrpDesc: o.itemGrpDesc,
                    make: o.make
                  });

                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="edit"
          >
            <Edit />
          </IconButton>{" "}
          {this.state.updateView ? (
            <SamplePDF invoiceType={13} printResp={this.state.printResp} />
          ) : null}
          {/* {this.state.updateView? */}
          {/*
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;

                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    //  data.splice(i,1);
                                    this.setState({
                                        // alert: true,
                                        lineItemId: o.id,
                                        materialHdrId: o.materialGrnHdrId,
                                        item: o.itemId,
                                        itemDesc: o.itemDesc,
                                        quantity: o.quantity,
                                        quality: o.quality,
                                        rate: o.rate,
                                        weight: o.weight,
                                        amount: o.amount,
                                        reason: o.reason,
                                        creditQuantity: o.creditQuantity,
                                        debitQuantity: o.debitQuantity,
                                        noteType: (o.debitQuantity > 0) ? 'DEBIT NOTE' : 'CREDIT NOTE',
                                        debitCreditQty: (o.debitQuantity > 0) ? o.debitQuantity : o.creditQuantity,
                                        debitCreditValue: o.debitCreditValue,
                                        debitValue: o.debitValue,
                                        creditValue: o.creditValue,
                                    });
                                    console.log("note type", (o.debitQuantity > 0) ? 'DEBIT NOTE' : 'CREDIT NOTE');
                                    this.handlePrintResp(o);

                                    return true;

                                }
                            });
                            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getStoreMaterialGoodReceiveDetailsById/" + header.id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handlePrintResp)
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Print/>
                    </IconButton>
*/}
          {/*  }*/}
          {/*                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    data.splice(i, 1);
                                    return true;
                                }
                            });
                            this.setState({
                                invoiceList: data,
                                selectedJuteType: "",
                                selectedJuteName: "",
                                selectedJuteQuality: "",
                                selectedQualityName: "",
                                quantity: "",
                                selectedUnit: "",
                                selectedDeptId: "",
                                selectedGrpId: "",
                                stock: "",
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close/>
                    </IconButton>*/}
        </div>
      ),
    };

    if (
      this.state.item !== "" &&
      this.state.quantity !== "" &&
      this.state.weight !== "" &&
      this.state.amount !== "" &&
      this.state.debitCreditQty !== "" &&
      this.state.reason !== "" &&
      this.state.type.value !== ""
    ) {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else arr.splice(arr.length, 0, addItemObj);

      this.setState({
        hideJuteType: false,
        loading: true,
        invoiceList: arr,
      });
      this.hideAlert();
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    //console.log("stock " + this.state.stock);
  };

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  getSupplierName = (val) => {
    var name = val.split("^");
    this.setState({
      supplierName: name[1],
    });
  };
  handleSelectedValues = (defValue) => {
    /*    if (this.state.invoiceList.length > 0  && this.state.type !== defValue && this.state.type !== '') {
                this.unitConvertionAlert("Changing of type will remove all fields. Click yes if you want to continue..!!",defValue)
            } else*/
    this.setState({
      type: defValue,
    });
  };
  getQuantityType = () => {
    if (this.state.type !== "") {
      var val = this.state.type.value;
      if (val === "DN") {
        return "Debit Quantity";
      } else if (val === "CN") {
        return "Credit Quantity";
      } else {
        return "Debit/Credit Quantity";
      }
    }
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert1: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      form_srNo,
      isReject,
      isApprove,
      srStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(this.state.srStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        29,
        fileName,
        form_srNo,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  render() {
    const { classes } = this.props;
    var companyId = getCookie(cons.COMPANY_ID);
    console.log("autoCreate", this.state.autoCreate);

    const options = [
      { value: "", label: "Select...", name: "Select..." },
      { value: "DN", label: "Debit", name: "Debit" },
      { value: "CN", label: "Credit", name: "Credit" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        id="itemGrpDesc"
                                        className={classes.textField}
                                        value={this.state.itemGrpDesc}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        decimal={3}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        id="itemDesc"
                                        className={classes.textField}
                                        value={this.state.itemDesc}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        decimal={3}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Make
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        id="make"
                                        className={classes.textField}
                                        value={this.state.make}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        style={{ height: 30 }}
                                        variant="outlined"
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={this.state.autoCreate === "Y"}
                                        id="quantity"
                                        className={classes.textField}
                                        value={this.state.quantity}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        decimal={
                                          companyId === "1" ||
                                          companyId === "2" ||
                                          companyId === "116"
                                            ? 3
                                            : 2
                                        }
                                        inputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Type
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <Select
                                        autoFocus={true}
                                        defValue={this.state.type}
                                        name={"type"}
                                        value={this.state.type}
                                        onChange={this.handleSelectedValues}
                                        options={options}
                                        className={classes.selectFormControl}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "4px",
                                          colors: {
                                            ...theme.colors,
                                            primary: "#00ABDC",
                                          },
                                        })}
                                        styles={{
                                          control: (base, state) => ({
                                            ...base,
                                            height: "30px",
                                            "min-height": "30px",
                                          }),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        {this.getQuantityType()}
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="debitCreditQty"
                                        className={classes.textField}
                                        value={this.state.debitCreditQty}
                                        onChange={this.onChangeValues}
                                        iType={"number"}
                                        decimal={
                                          companyId === "1" ||
                                          companyId === "2" ||
                                          companyId === "116"
                                            ? 3
                                            : 2
                                        }
                                        inputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Credit/Debit Value
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        defValue={
                                          parseInt(this.state.debitCreditQty) *
                                          parseInt(this.state.rate)
                                        }
                                        id="debitCreditValue"
                                        className={classes.textField}
                                        value={this.state.debitCreditValue}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        iType={"number"}
                                        decimal={2}
                                        inputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Reason
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="reason"
                                        className={classes.textField}
                                        value={this.state.reason}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={6}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={12}>
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                          <InputLabel className={classes.label}>
                                            Price
                                          </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                          <TextField
                                            disabled={
                                              this.state.autoCreate === "Y"
                                            }
                                            // disabled={true}
                                            id="rate"
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                            InputProps={inputFocus}
                                            value={this.state.rate}
                                          />
                                        </ItemGrid>
                                        {this.state.selectedJuteQuality > 0 ? (
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              marginLeft: "150px",
                                              color: "red",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            {"Last purchase at  " +
                                              this.state.lastPurchase}
                                          </span>
                                        ) : null}
                                      </GridContainer>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={6}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={12}>
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                          <InputLabel className={classes.label}>
                                            Warehouse No
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              *
                                            </span>
                                          </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                          <TextField
                                            disabled={true}
                                            id="warehouseNo"
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                            value={this.state.warehouseNo}
                                            inputProps={inputFocus}
                                            style={{ height: 30 }}
                                          />
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={6}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={12}>
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                          <InputLabel className={classes.label}>
                                            Amount
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              *
                                            </span>
                                          </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                          <TextField
                                            disabled={true}
                                            id="amount"
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                            inputProps={inputFocus}
                                            style={{ height: 30 }}
                                            value={this.state.amount}
                                          />
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Date</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              disabled: true,
                              id: "mrDate",
                              value: this.state.date1,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            selectDisabled={this.state.updateView}
                            id={"form_supplierId"}
                            defValue={this.state.form_supplierId}
                            onSelect={this.onSelect}
                            stateKey="form_supplierId"
                            url={
                              serverApi.SEARCH_STORE_SUPPLIER +
                              "/" +
                              getCookie(cons.COMPANY_ID)
                            }
                            value={this.state.form_supplierId}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          SR No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"form_srNo"}
                          selectDisabled={this.state.updateView}
                          defValue={this.state.form_srNo}
                          onSelect={this.onSelect}
                          isRefresh={this.state.loadSr}
                          setIsRefresh={this.afterRefreshGateEntry}
                          getName={this.getSupplierName}
                          stateKey="form_srNo"
                          url={
                            serverApi.GET_SR_BY_SUPPLIER +
                            this.state.form_supplierId +
                            "/" +
                            getCookie(cons.COMPANY_ID) +
                            "/" +
                            getCookie(cons.CIPHER)
                          }
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          SR Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "srDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              disabled: true,
                              id: "mrDate",
                              value: this.state.srDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>PO NO</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_poNO"
                          value={this.state.form_poNO}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          PO Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "poDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              disabled: true,
                              id: "poDate",
                              value: this.state.poDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="challanNo"
                          value={this.state.challanNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "challanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              disabled: true,
                              id: "challanDate",
                              value: this.state.challanDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_vehicleNo"
                          value={this.state.form_vehicleNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          inputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          isRefresh={this.state.loadBranch}
                          setIsRefresh={() => {
                            this.setState({ loadBranch: false });
                          }}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Phase" : "Project"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          url={
                            serverApi.PROJECT_MASTER_SELECTION_LIST +
                            getCookie(cons.COMPANY_ID) +
                            "/customer/0/branch/" +
                            this.state.branchId
                          }
                          defValue={this.state.projectId}
                          // staticData={this.state.ProjectOptions}
                          onSelect={this.onSelect}
                          stateKey="projectId"
                          value={this.state.projectId}
                          isRefresh={this.state.loadProject}
                          setIsRefresh={(val) => {
                            this.setState({ loadProject: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {console.log(this.state.invoiceList.length + " length")}
                        <ReactTable
                          data={this.state.invoiceList}
                          filterable={false}
                          resizable={false}
                          loading={this.state.loading}
                          getTableProps={() => {
                            return {
                              style: {
                                border: "2px solid #00ABDC",
                                borderRadius: "4px",
                              },
                            };
                          }}
                          getTheadProps={() => {
                            return {
                              style: {
                                borderBottom: "2px solid #00ABDC",
                              },
                            };
                          }}
                          getTheadThProps={() => {
                            return {
                              style: {
                                borderRight: "1px solid #999999",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                                lineHeight: 1.3,
                              },
                            };
                          }}
                          style={{
                            color: "#00ABDC",
                            textAlign: "center",
                            overflowY: "auto",
                          }}
                          columns={[
                            {
                              Header: "Item Code",
                              accessor: "itemGrp",
                              minWidth: 70,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              id: "itemDesc",
                              Header: "Item Desc",
                              accessor: row => <>
                                  <span style={{fontStyle: "bold"}}>{`${row.itemDesc}`}</span><br></br>
                                      {this.state.make !== "" && this.state.make!== null ? 
                                       <>
                                          <span style={{fontStyle: "italic", color: "#00ABDC"}}>Make:- {`${row.make}`}</span><br></br>
                                      </> : null}
        
                                  </>,
                              filterMethod: (filter, row) =>
                              row._original.itemDesc.startsWith(filter.value) ||
                              row._original.make.startsWith(filter.value),
                              minWidth: 200,
        
                              style: {
                                  color: "#000",
                                  textAlign: "left",
                                  textOverflow: "unset",
                              },
                             
                          },
                            // {
                            //   Header: "Make",
                            //   accessor: "make",
                            //   minWidth: 130,
                            //   style: { color: "#000", textAlign: "left" },
                            // },

                            {
                              Header: "Quantity",
                              accessor: "quantity",
                              minWidth: 80,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "UOM",
                              accessor: "uom",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Warehouse No",
                              accessor: "warehouseno",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Rate",
                              accessor: "rate",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "IGST",
                              accessor: "igst",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "CGST",
                              accessor: "cgst",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "SGST",
                              accessor: "sgst",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Amount",
                              accessor: "amount",
                              minWidth: 80,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Debit Quantity",
                              accessor: "debitQuantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Credit Quantity",
                              accessor: "creditQuantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Debit/Credit Value",
                              accessor: "debitCreditValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Reason",
                              accessor: "reason",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },
                            {
                              Header: "Actions",
                              minWidth: 100,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                          showPagination={false}
                          pageSize={this.getInvoiceTablePageSize()}
                          //  defaultPageSize={5}
                          sortable={false}
                          getNoDataProps={() => {
                            return {
                              style: {
                                display: "none",
                              },
                            };
                          }}
                          className="-striped -highlight"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onApproveButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        {/*  <ItemGrid>
                                                    <Button color="success"
                                                            onClick={this.onCommitButtonClick}>Commit</Button>
                                                </ItemGrid>*/}
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      {" "}
                      <SamplePDF
                        invoiceType={10}
                        printResp={this.state.srPrintResp}
                      />{" "}
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                GRN No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="grnNo"
                                value={this.state.grnNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                PO No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="poNo"
                                value={this.state.poNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  url={
                                    serverApi.SEARCH_STORE_SUPPLIER +
                                    "/" +
                                    getCookie(cons.COMPANY_ID)
                                  }
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Project" : "Branch"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                // isRefresh={this.state.loadQuality}
                                // setIsRefresh={this.afterRefreshQuality}
                                url={
                                  serverApi.SELECT_BRANCH +
                                  getCookie(cons.COMPANY_ID)
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Phase" : "Project"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                url={
                                  serverApi.PROJECT_MASTER_SELECTION_LIST +
                                  getCookie(cons.COMPANY_ID) +
                                  "/customer/0/branch/" +
                                  this.state.branchIds
                                }
                                defValue={this.state.projectIds}
                                // staticData={this.state.ProjectOptions}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <br />
                          <br />
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={
                          serverApi.DATATABLE_SERVICE_URL +
                          "getAllDebitCreditNotes"
                        }
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_debit_credit_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "getStoreMaterialGoodReceiveDetailsById/" +
          this.state.form_srNo +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handlePrintResp
      );

      //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
    }
  }

  handlePrintResp = (resp) => {
    // var printHeaderValues=resp.poHeader;
    var pageNo = 1;
    // var printTotalvalues=resp.totalValues;

    var companyName = "NELLIARLA JUTE MILLS CO. LTD " + " ";
    var noteType = resp.debitQuantity > 0 ? "DEBIT NOTE" : "CREDIT NOTE" + " ";
    var noteNo = " ";

    var printcopy = "";
    printcopy = "COPY" + " ";
    //}

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(12);
    //  var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize() / 2);
    doc.text(companyName, 30, 60);
    doc.text(noteType, 450, 60);

    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    doc.setFontType("bold");
    doc.text(30, 90, noteType + " NO  : ");
    doc.setFontType("normal");
    doc.text(130, 90, resp.lineItemId + " ");
    doc.setFontType("bold");

    doc.text(450, 90, "Dated : ");
    doc.setFontType("normal");
    doc.text(485, 90, resp.debitCreditDate + " ");
    doc.setFontType("bold");

    doc.text(30, 110, "SUPPLIER                :");
    doc.setFontType("normal");
    doc.text(130, 110, this.state.supplierName + " ");

    // doc.setFontType("bold");
    //doc.text(400, 180, 'DATE : ');
    //doc.setFontType("normal");
    // doc.text(440, 180, gateEntryDate );
    doc.setFontType("bold");
    doc.cellInitialize();
    var sr_no = doc.splitTextToSize("SR No", 40);
    doc.cell(30, 140, 50, 20, sr_no, "", "C");
    var sr_date = doc.splitTextToSize("SR Date", 50);
    doc.cell(70, 140, 50, 20, sr_date, "", "C");
    var icode = doc.splitTextToSize("I/Code", 45);
    doc.cell(170, 140, 50, 20, icode, "", "C");
    var desc = doc.splitTextToSize("Description", 80);
    doc.cell(210, 140, 100, 20, desc, "", "C");
    var unit = doc.splitTextToSize("Unit", 30);
    doc.cell(310, 140, 30, 20, unit, "", "C");
    var quantity = doc.splitTextToSize("Quantity", 50);
    doc.cell(250, 140, 60, 20, quantity, "", "C");
    var amount = doc.splitTextToSize("Amount", 70);
    doc.cell(400, 140, 80, 20, amount, "", "C");
    var reason = doc.splitTextToSize("Reason", 90);
    doc.cell(450, 140, 100, 20, reason, "", "C");

    doc.cellInitialize();
    doc.setFontType("normal");
    doc.setFontSize(8);
    var sr_no1 = doc.splitTextToSize(this.state.form_srNo + " ", 40);
    doc.cell(30, 160, 50, 40, sr_no1, "", "C");
    var sr_date1 = doc.splitTextToSize(this.state.srDate + " ", 50);
    doc.cell(70, 160, 50, 40, sr_date1, "", "C");
    var icode1 = doc.splitTextToSize(resp.itemId + " ", 45);
    doc.cell(170, 160, 50, 40, icode1, "", "C");
    var desc1 = doc.splitTextToSize(resp.itemDesc + " ", 80);
    doc.cell(210, 160, 100, 40, desc1, "", "C");
    var unit1 = doc.splitTextToSize(resp.uom + " ", 30);
    doc.cell(310, 160, 30, 40, unit1, "", "C");
    var quantity1 = doc.splitTextToSize(
      resp.debitQuantity > 0
        ? resp.debitQuantity + " "
        : resp.creditQuantity + " ",
      50
    );
    doc.cell(250, 160, 60, 40, quantity1, "", "right");
    var amount1 = doc.splitTextToSize(resp.debitCreditValue + " ", 70);
    doc.cell(400, 160, 80, 40, amount1, "", "right");
    var reason1 = doc.splitTextToSize(resp.reason + " ", 90);
    doc.cell(450, 160, 100, 40, reason1, "", "left");

    doc.setFontSize(10);
    doc.setFontType("bold");

    doc.text(300, 220, "Total Amount");
    // doc.text(400,220,amount1);
    doc.text(amount1, 450, 220, "right");
    doc.line(30, 225, 550, 225);

    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str.toUpperCase();
    }

    doc.text(30, 240, "RUPEES " + inWords(resp.debitCreditValue) + " ONLY.");
    doc.text(
      470,
      240,
      resp.debitQuantity > 0
        ? "D/NO : " + resp.lineItemId
        : "C/NO :" + resp.lineItemId
    );

    doc.text(30, 300, "Checked By");
    doc.text(250, 300, "Stores Signatory");
    doc.text(430, 300, "Authorised Signatory");

    var cellWidths = ["", 40, 100, 40, 40, 40, 42, 40, 50, 50, 50];
    var cellTextaligns = [
      "",
      "right",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "left",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    var k = 200;
    /*
                {
                    var k = 325;
                    var i = 0;
                    modules.map((prop, key) => {
                        k = k + 25;
                        i = i + 1;
                        doc.cellInitialize();
                        if (i === 27) {
                            k = 325;
                            i=0;
                            pageNo = pageNo + 1;
                            var today = moment().format('DD/MM/YYYY H:mm:ss');
                            var name = 'Sgoli';
                            var newdat = " Printed on: " + today;
                            var printBy = " Printed By: " + name;
                            doc.text(30, 800, newdat);
                            doc.text(500, 800, printBy);
                            doc.addPage('a4', 'p');

                            doc.setFontType("bold");
                            doc.cellInitialize();
                            var Bales_drums = doc.splitTextToSize('Bales/drums', 28);
                            doc.cell(30, 310, 40, 40, Bales_drums, '', 'C');
                            var Marks_Qualiy = doc.splitTextToSize('Marks&Quality', 33);
                            doc.cell(70, 310, 100, 40, Marks_Qualiy, '', 'C');
                            var advised_weight = doc.splitTextToSize('Advised weight in Kgs', 35);
                            doc.cell(170, 310, 40, 40, advised_weight, '', 'C');
                            var mill_weight = doc.splitTextToSize('Mill weight in Kgs', 35);
                            doc.cell(210, 310, 40, 40, mill_weight, '', 'C');
                            var shortage = doc.splitTextToSize('Claim in Kgs',40);
                            doc.cell(310, 310, 40, 40, shortage, '', 'C')
                            var Approvedweight = doc.splitTextToSize('Approved  Weight', 40);
                            doc.cell(250, 310, 42, 40, Approvedweight, '', 'C');
                            var rate = doc.splitTextToSize('Rate per Qtls Rs', 35);
                            doc.cell(400, 310, 40, 40, rate, '', 'C');

                            doc.cell(450, 310, 100, 20, '                 CLAIM FOR        ', '', 'C');
                            doc.cell(460, 310, 50, 20, ' ', '', 'C');
                            /!*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                              doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*!/
                            doc.cellInitialize();
                            doc.cell(372, 330, 50, 20, 'QUALITY', '', 'C');
                            doc.cell(500, 330, 50, 20, 'CONDITION', '', 'C');
                            doc.cell(550, 330, 50, 20, 'INITIAL', '', 'C');

                            doc.setFontType("normal");
                            doc.setFontSize(7);
                        }
                        var a = 0;
                        return (
                            Object.keys(prop).map((key, index) => {
                                a = a + 1;
                                if(prop[key]==0 || prop[key]==0.0){
                                    var myItem=" ";
                                }

                                else{
                                    if(key==='itemCode'){
                                        var size=90;
                                    }else{
                                        var size=45;
                                    }
                                    var myItem = prop[key]+" ";
                                    myItem = doc.splitTextToSize(myItem,size);
                                }


                                return (
                                    doc.cell(30, k, cellWidths[a], 25, myItem, 2, cellTextaligns[a])
                                )
                            })

                        )

                    });

                }
        */
    /*  if(grandTotals.balesTot==0 || grandTotals.balesTot==0.0){
              var balesTot=' ';
          }else{
              var balesTot=grandTotals.balesTot;
          }
          if(grandTotals.advisedWeightTot==0 || grandTotals.advisedWeightTot==0.0){
              var advisedWeightTot=' ';
          }else{
              var advisedWeightTot=grandTotals.advisedWeightTot;
          }
          if(grandTotals.millWeightTot==0 || grandTotals.millWeightTot==0.0){
              var millWeightTot=' ';
          }else{
              var  millWeightTot=grandTotals.millWeightTot;
          }
          if(grandTotals.shortageTot==0 || grandTotals.shortageTot==0.0){
              var shortageTot=' ';
          }
          else{
              var shortageTot=grandTotals.shortageTot;
          }
          if(grandTotals.approvedWeightTot==0 || grandTotals.approvedWeightTot==0.0){
              var approvedWeightTot=' ';
          }
          else{
              var approvedWeightTot=grandTotals.approvedWeightTot;
          }
  */

    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 250, newdat);
    doc.text(500, k + 250, printBy);
    //        addWaterMark(doc,printcopy);

    doc.save(noteType + "_" + resp.lineItemId + ".pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {
    border: "1px solid #00ADBC",
    borderRadius: "4px",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      border: "2px solid #0ADBC",
      // borderColor: '#D2D2D2',
    },
  },

  notchedOutline: {},
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(StoreDebitCredit);
