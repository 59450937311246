import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  requestList,
  uploadFile,
  uploadFormdataWithImage,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import { Status, UserStatus } from "../TableData";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

class TaxMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      file: null,
      isRefresh: false,
      isLoading: false,
      itemViewType: "issueObj",
      approveStatus: false,
      date1: moment().format("DD-MM-YYYY"),
      year: moment().format("YYYY"),
      issueStatus: "3",
      itemName: "",
      grpCode: "",
      uom: "",
      hsn_code: "",
      directIndirect: "",
      taxId: "",
      legacyCode: "",
      maxValue: "",
      maxOrderValue: "",
      fileName: "",
      itemId: "",
      saleable: "Y",
      consumable: "Y",
      tangible: "Y",
      standBy: "Y",
      id: "",
      startIndex: 1,
      lastIndex: 10,
      groupsList: [],
      groupCode: "",
      maxNumber: "",
      expiryDate: "",
      srRate: "",
      balesPerBag: "",
      weight: "",
      isActive: 1,
      indentTypesList: [],
      finishedGoodSaleable: "N",
      itemCode: "",
      itemName1: "",
      isActive1: "",
      itemGroupName: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    //   getDataFromUrl(serverApi.SEARCH_ITEM_GROUPS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER),this.handleItemGroups);
    getDataFromUrl(serverApi.GET_STORE_INDENT_TYPES, this.handleIndentTypes);
  }
  handleIndentTypes = (resp) => {
    var list = [];
    if (resp) {
      resp.map((prop, key) => {
        if (key > 0) {
          var id = prop.name.split("_");
          id = parseInt(id[1]);
          var val = {
            id: id,
            name: prop.label,
            isEnabled: false,
            autoId: 0,
          };

          if (this.state.updateView) {
            this.state.indentTypesList.map((o, key) => {
              if (o.indentTypeId === id) {
                val.isEnabled = true;
                val.autoId = o.autoId;
                val.isActive = o.isActive;
              }
            });
          }
          console.log("indentTypeIdval", val);
          list.push(val);
        }
      });
    }
    console.log("indentTypesList", list);

    this.setState({
      indentTypesList: list,
    });
  };
  handleItemGroups = (resp) => {
    var data = resp;
    data[0].label = "All";
    this.setState({ groupsList: data, loadGroup: true });
  };

  handleSeacrh = () => {
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handlePage = (start, end) => {
    this.setState({
      startIndex: start,
      lastIndex: end,
    });
    console.log("start11", start, end);

    this.handleSeacrh();
  };

  handleViewClick = (obj) => {
    // var data = this.state.dataTable;
    console.log(`printing id----------${JSON.parse(obj).itemCode}`);
    var id = JSON.parse(obj).itemCode;
    var grpCode = JSON.parse(obj).grpCode;
    var juteId = "";
    console.log("jute  " + "  " + JSON.stringify(id));
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      /*
            var data={
                "taxId": id,
                "cipher":getCookie(cons.CIPHER)
            }
*/

      getDataFromUrl(
        serverApi.ITEM_MASTER_DETAILS_BYID +
          grpCode +
          "/" +
          id +
          "/" +
          getCookie(cons.COMPANY_ID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp.data;
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        itemId: header.itemId,
        id: header.id,
        itemCode: header.itemCode,
        itemName: header.itemDsc,
        grpCode: header.grpCode,
        uom: header.uomCode,
        hsn_code: header.hsnCode,
        directIndirect: header.directIndirectTag,
        taxId: parseInt(header.taxId),
        legacyCode: header.legacyItemCode,
        maxValue: header.max,
        maxOrderValue: header.maxOrderValue,
        saleable: header.saleable,
        consumable: header.consumable,
        tangible: header.tangible,
        createdBy: header.createdBy,
        createdOn: header.autoInsertDateTime,
        expiryDate: header.expiryDate,
        srRate: header.srRate,
        balesPerBag: header.balesPerBag !== null ? header.balesPerBag : "",
        weight: header.weight !== null ? header.weight : "",
        isActive: header.isActive,
        indentTypesList: header.mappDto,
        finishedGoodSaleable: header.finishedGoodSaleable === "Y" ? "Y" : "N",
      });
      getDataFromUrl(serverApi.GET_STORE_INDENT_TYPES, this.handleIndentTypes);
    }
  };

  handleLoading = () => {
    console.log("sssss");
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
      getDataFromUrl(serverApi.GET_STORE_INDENT_TYPES, this.handleIndentTypes);
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    const { itemCode, itemName1, isActive1, itemGroupName } = this.state;
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      groupCode: this.state.groupCode,
      itemCode: itemCode,
      itemName: itemName1,
      isActive: isActive1,
      itemGroupName: itemGroupName,
    };
    return data;
  };

  handleChange = (index) => (event) => {
    var arr = this.state.indentTypesList;
    arr[index].isEnabled = !arr[index].isEnabled;
    this.setState({
      indentTypesList: arr,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });

    if (stateKey === "grpCode" && !this.state.updateView) {
      getDataFromUrl(
        serverApi.ITEM_MAX_NUMBER +
          selectedValue +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleItemCode
      );
    }
    if (stateKey === "groupCode" && selectedValue !== this.state.groupCode) {
      this.setState({ isRefresh: true });
    }
  };
  handleItemCode = (resp) => {
    console.log("resp...", resp);
    if (resp.status === true) this.setState({ id: resp.data });
    else this.setState({ id: "" });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      //  itemName:'',
      /*  grpCode:'',
            uom:'',
            hsn_code:'',
            directIndirect:'',
            taxId:'',
            legacyCode:'',
            maxValue:'',
            maxOrderValue:'',*/
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      itemName: "",
      grpCode: "",
      uom: "",
      hsn_code: "",
      directIndirect: "",
      taxId: "",
      legacyCode: "",
      maxValue: "",
      maxOrderValue: "",
      id: "",
      expiryDate: "",
      srRate: "",
      balesPerBag: "",
      weight: "",
      isActive: 1,
      finishedGoodSaleable: "N",
      indentTypesList: [],
      /*    startIndex: 1,
            lastIndex: 10,*/
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  handleDeleteClick = (obj) => {
    var id = JSON.parse(obj);
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    var header = id;

    const formData = new FormData();
    formData.append("id", header.id);
    formData.append("itemId", header.itemId);
    formData.append("grpCode", header.grpCode + "");
    formData.append("companyId", getCookie(cons.COMPANY_ID));
    //   formData.append( "createdBy",getCookie(cons.USERID));
    formData.append("isActive", 0);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    uploadFormdataWithImage(
      serverApi.ITEM_MASTER_UPDATE,
      formData,
      this.handleDeleteClickResp
    );

    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
  }
  handleDeleteClickResp = (resp) => {
    console.log("resp", resp);
    if (resp.status === true) {
      this.setState({
        isRefresh: true,
      });
      this.showMessage("Item Deleted Successfully..!!!");
    }
  };

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        id,
        grpCode,
        itemName,
        uom,
        hsn_code,
        directIndirect,
        legacyCode,
        saleable,
        consumable,
        standBy,
        tangible,
        maxValue,
        maxOrderValue,
        taxId,
        expiryDate,
        srRate,
        balesPerBag,
        weight,
        indentTypesList,
        itemId,
        isActive,
        finishedGoodSaleable,
      } = this.state;
      var types = [];
      indentTypesList.forEach((prop) => {
        var obj = { indentTypeId: prop.id, autoId: prop.autoId, isActive: 1 };

        if (prop.isEnabled) types.push(obj);
        /*  if(prop.autoId!==0){
                    if(prop.isEnabled)
                    obj.isActive=1;
                    types.push(obj)
                }else {
                    if(prop.isEnabled){
                        obj.isActive=1;
                        types.push(obj)
                    }
                }*/
      });
      var data = {
        activeFlag: "Y",
        balesPerBag: balesPerBag,
        itemId: itemId,
        //  "centralStock": 0,
        //"centralStockValue": 0,
        companyId: getCookie(cons.COMPANY_ID),
        consumable: consumable,
        modBy: getCookie(cons.USERID),
        // "date1": "2021-03-23",
        // "date2": "2021-03-23",
        // "date3": "2021-03-23",
        // "date4": "2021-03-23",
        deptId: 1,
        directIndirectTag: directIndirect,
        //"dtlItemDsc1": "string",
        //"dtlItemDsc2": "string",
        expiryDate: expiryDate,
        grpCode: grpCode,
        hsnCode: hsn_code,
        id: id,
        //"imageType": "string",
        isActive: isActive,
        // "issuableTag": "string",
        itemDsc: itemName,
        //"itemPhoto": "base64 image",
        // "itemmaxNo": 0,
        legacyItemCode: legacyCode,
        // "location": "string",
        //  "make": "string",
        mappDto: types,
        max: maxValue,
        maxOrderValue: maxOrderValue,
        multiFact: 0,
        op2018: 0,
        saleable: saleable,
        // "splItemTag": "string",
        srRate: srRate,
        standBy: standBy,
        tangible: tangible,
        taxId: taxId,
        threshold: 0,
        uomCode: uom,
        //"uomPur": "string",
        weight: weight,
        finishedGoodSaleable: finishedGoodSaleable,
      };

      /*  const formData = new FormData();
            formData.append("id", this.state.id);
            formData.append("itemId", this.state.itemId);
            formData.append("grpCode", this.state.grpCode+"");
            formData.append("itemDsc", this.state.itemName+"");
            formData.append("uomCode", this.state.uom+"");
            formData.append("hsnCode", this.state.hsn_code+"");
            formData.append("directIndirectTag", this.state.directIndirect+'');
            formData.append("legacyItemCode",this.state.legacyCode+'');
            formData.append("saleable",this.state.saleable);
            formData.append("consumable",this.state.consumable);
            formData.append("tangible",this.state.tangible);
            formData.append("standBy",this.state.standBy);
            formData.append("isActive",this.state.isActive);

            if(this.state.maxValue!=='' && this.state.maxValue!==null && this.state.maxValue!==undefined)
            formData.append( "max", this.state.maxValue);
            else
                formData.append( "max", 0);

            if(this.state.maxOrderValue!=='' && this.state.maxOrderValue!==null && this.state.maxOrderValue!==undefined)
            formData.append( "maxOrderValue", this.state.maxOrderValue);
            else
                formData.append( "maxOrderValue",0);

            formData.append(  "taxId", this.state.taxId+"");
            formData.append(  "companyId", getCookie(cons.COMPANY_ID));
            formData.append( "createdBy",getCookie(cons.USERID));
            formData.append("isActive",1);
            if(this.state.expiryDate!==""&&this.state.expiryDate!==null)
            formData.append("expiryDate",this.state.expiryDate);
            formData.append("srRate",this.state.srRate);

            formData.append("balesPerBag",this.state.balesPerBag);
            formData.append("weight",this.state.weight);
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }
*/
      if (
        itemId !== "" &&
        id !== "" &&
        itemName !== "" &&
        grpCode !== "" &&
        grpCode !== "0" &&
        srRate !== "" &&
        srRate !== null &&
        taxId !== "" &&
        uom !== "" &&
        types.length > 0
      ) {
        if (hsn_code !== "") {
          if (hsn_code.length >= 6) {
            this.handleLoading();
            requestList(
              serverApi.ITEM_MASTER_UPDATE,
              data,
              this.handleAddItemMaster
            );
          } else {
            this.basicAlert("Please Enter Atleast 6 Digit HSN Code..!!");
          }
        } else {
          this.handleLoading();
          requestList(
            serverApi.ITEM_MASTER_UPDATE,
            data,
            this.handleAddItemMaster
          );
          /*   var URL=serverApi.ITEM_MASTER_UPDATE;
                uploadFormdataWithImage(URL,formData, this.handleAddItemMaster)*/
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        id,
        grpCode,
        itemName,
        uom,
        hsn_code,
        directIndirect,
        legacyCode,
        saleable,
        consumable,
        standBy,
        tangible,
        maxValue,
        maxOrderValue,
        taxId,
        expiryDate,
        srRate,
        balesPerBag,
        weight,
        indentTypesList,
        finishedGoodSaleable,
      } = this.state;
      var types = [];
      indentTypesList.forEach((prop) => {
        if (prop.isEnabled) {
          var obj = { indentTypeId: prop.id };
          types.push(obj);
        }
      });
      var data = {
        activeFlag: "Y",
        balesPerBag: balesPerBag,
        //  "centralStock": 0,
        //"centralStockValue": 0,
        companyId: getCookie(cons.COMPANY_ID),
        consumable: consumable,
        createdBy: getCookie(cons.USERID),
        // "date1": "2021-03-23",
        // "date2": "2021-03-23",
        // "date3": "2021-03-23",
        // "date4": "2021-03-23",
        deptId: 1,
        directIndirectTag: directIndirect,
        //"dtlItemDsc1": "string",
        //"dtlItemDsc2": "string",
        expiryDate: expiryDate,
        grpCode: grpCode,
        hsnCode: hsn_code,
        id: id,
        //"imageType": "string",
        isActive: 1,
        // "issuableTag": "string",
        itemDsc: itemName,
        //"itemPhoto": "base64 image",
        // "itemmaxNo": 0,
        legacyItemCode: legacyCode,
        // "location": "string",
        //  "make": "string",
        mappDto: types,
        max: maxValue,
        maxOrderValue: maxOrderValue,
        modBy: "string",
        multiFact: 0,
        op2018: 0,
        saleable: saleable,
        // "splItemTag": "string",
        srRate: srRate,
        standBy: standBy,
        tangible: tangible,
        taxId: taxId,
        threshold: 0,
        uomCode: uom,
        //"uomPur": "string",
        weight: weight,
        finishedGoodSaleable: finishedGoodSaleable,
      };

      /*const formData = new FormData();
            formData.append("id", this.state.id);
            formData.append("grpCode", this.state.grpCode+"");
            formData.append("itemDsc", this.state.itemName+"");
            formData.append("uomCode", this.state.uom+"");
            formData.append("hsnCode", this.state.hsn_code+"");
            formData.append("directIndirectTag", this.state.directIndirect+'');
            formData.append("legacyItemCode",this.state.legacyCode+'');
            formData.append("saleable",this.state.saleable);
            formData.append("consumable",this.state.consumable);
            formData.append("tangible",this.state.tangible);
            formData.append("standBy",this.state.standBy);

                formData.append( "max", this.state.maxValue);
            formData.append( "maxOrderValue", this.state.maxOrderValue);
            formData.append(  "taxId", this.state.taxId+"");
            formData.append(  "companyId", getCookie(cons.COMPANY_ID));
            formData.append( "createdBy",getCookie(cons.USERID));
            formData.append("isActive",1);
            if(this.state.expiryDate!==""&&this.state.expiryDate!==null)
            formData.append("expiryDate",this.state.expiryDate);
            formData.append("srRate",this.state.srRate);
            formData.append("balesPerBag",this.state.balesPerBag);
            formData.append("weight",this.state.weight);
            formData.append("isActive",this.state.isActive);
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

             console.log('staet',this.state);*/
      if (
        id !== "" &&
        itemName !== "" &&
        grpCode !== "" &&
        grpCode !== "0" &&
        srRate !== "" &&
        srRate !== null &&
        taxId !== "" &&
        uom !== "" &&
        types.length > 0
      ) {
        //validation
        if (hsn_code !== "") {
          if (hsn_code.length >= 6) {
            this.handleLoading();
            requestList(
              serverApi.ITEM_MASTER_ADD,
              data,
              this.handleAddItemMaster
            );
          } else {
            this.basicAlert("Please Enter Atleast 6 Digit HSN Code..!!");
          }
        } else {
          this.handleLoading();
          requestList(
            serverApi.ITEM_MASTER_ADD,
            data,
            this.handleAddItemMaster
          );
        }
        //uploadFormdataWithImage(URL,formData, this.handleAddItemMaster)
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddItemMaster = (resp) => {
    console.log("resp " + JSON.stringify(resp));
    const { fileName, fileType, file } = this.state;

    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      if (this.state.file !== "" && this.state.file !== null)
        uploadFile(
          fileName,
          file,
          fileType,
          7,
          fileName,
          resp.id,
          this.handleImageResp
        );
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  handleImageResp = (resp) => {};
  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (fileType === "JPG" || fileType === "JPEG" || fileType === "PNG") {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          imagePreviewUrl: reader.result,
        });
        //  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //  this.dateChanged();
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    const inputFocus = {
      autoComplete: "off",
    };
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: "D", label: "Direct", name: "Direct" },
      { value: "I", label: "Indirect", name: "Indirect" },
    ];
    const selectoptions = [
      { value: "Y", label: "YES", name: "YES" },
      { value: "N", label: "NO", name: "NO" },
    ];

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  {/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Max Number
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="maxNumber"
                                                    value={this.state.maxNumber}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
*/}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Item Group
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.grpCode}
                          onSelect={this.onSelect}
                          //     getName={this.getItemGroupName}
                          //  isRefresh={this.state.loadItemGroup}
                          stateKey="grpCode"
                          // setIsRefresh={this.afterRefreshItemGrp}
                          //  url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedDepartment+"/"+getCookie(cons.COMPANY_ID)+"/getAllItemGroupsByDepartmentId/"+getCookie(cons.CIPHER)}
                          url={
                            serverApi.SEARCH_ITEM_GROUPS +
                            getCookie(cons.COMPANY_ID) +
                            "/" +
                            getCookie(cons.CIPHER)
                          }
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Item Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={this.state.updateView}
                          id="id"
                          value={this.state.id}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Item Description
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="itemName"
                          value={this.state.itemName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          UOM Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="uom"
                          value={this.state.uom}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          HSN Code
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="hsn_code"
                          value={this.state.hsn_code}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Item Rate
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="srRate"
                          value={this.state.srRate}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Item Expiry Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "expiryDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "expiryDate",
                              value: this.state.expiryDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Direct/Indirect
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.directIndirect}
                          staticData={options}
                          onSelect={this.onSelect}
                          value={this.state.directIndirect}
                          stateKey={"directIndirect"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tax
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.taxId}
                          id={"taxId"}
                          onSelect={this.onSelect}
                          stateKey="taxId"
                          getName={this.gettaxpercent}
                          url={
                            serverApi.TAX_MASTER_LIST +
                            "/" +
                            getCookie(cons.COMPANY_ID) +
                            "/" +
                            getCookie(cons.CIPHER)
                          }
                          value={this.state.taxId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Legacy Item Code
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="legacyCode"
                          value={this.state.legacyCode}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Max Value
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="maxValue"
                          value={this.state.maxValue}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Min Order Value
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="maxOrderValue"
                          value={this.state.maxOrderValue}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Item Photo
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="fileName"
                          value={this.state.fileName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#003F66",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handleFileClick}
                                >
                                  BROWSE
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      {/*
                                            <ItemGrid xs={12} sm={1}>
                                                <Button color={"defaultNoBackground"}
                                                        onClick={this.handleFileClick} style={{fontSize:'16px',marginRight:20}}>Browse
                                                </Button>

                                            </ItemGrid>
*/}
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Consumable
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.consumable}
                          staticData={selectoptions}
                          onSelect={this.onSelect}
                          value={this.state.consumable}
                          stateKey={"consumable"}
                          getName={(name) => {
                            this.setState({ consumableName: name });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Saleable
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.saleable}
                          staticData={selectoptions}
                          onSelect={this.onSelect}
                          value={this.state.saleable}
                          stateKey={"saleable"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Finished Goods Saleable
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.finishedGoodSaleable}
                          staticData={selectoptions}
                          onSelect={this.onSelect}
                          value={this.state.finishedGoodSaleable}
                          stateKey={"finishedGoodSaleable"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tangible
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.tangible}
                          staticData={selectoptions}
                          onSelect={this.onSelect}
                          value={this.state.tangible}
                          stateKey={"tangible"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Stand By
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.standBy}
                          staticData={selectoptions}
                          onSelect={this.onSelect}
                          value={this.state.standBy}
                          stateKey={"standBy"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.finishedGoodSaleable === "Y" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            No of Bags per Bale
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="balesPerBag"
                            value={this.state.balesPerBag}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            iType={"number"}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.finishedGoodSaleable === "Y" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Weight of the Bag
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="weight"
                            value={this.state.weight}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            iType={"number"}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Status
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.isActive}
                          staticData={Status}
                          onSelect={this.onSelect}
                          value={this.state.isActive}
                          stateKey={"isActive"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={2}>
                        <InputLabel className={classes.label}>
                          Item Types
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} md={10}>
                        <GridContainer>
                          {this.state.indentTypesList.map((prop, key) => {
                            return (
                              <ItemGrid xs={12} sm={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      value={prop.isEnabled ? 1 : 0}
                                      checked={prop.isEnabled ? 1 : 0}
                                      onChange={this.handleChange(key)}
                                      // onClick={() => this.handleToggle(prop)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  }
                                  classes={{
                                    label: classes.menulabel,
                                  }}
                                  label={prop.name}
                                />
                              </ItemGrid>
                            );
                          })}
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        {/*
                                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.groupCode}
                                                                id={"groupCode"}
                                                                onSelect={this.onSelect}
                                                                stateKey="groupCode"
                                                                isRefresh={this.state.loadGroup}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadGroup:false})
                                                                }}
                                                                staticData={this.state.groupsList}
                                                            />


                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
*/}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Item Group Code
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="groupCode"
                                value={this.state.groupCode}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Item Group Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="itemGroupName"
                                value={this.state.itemGroupName}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Item Code
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="itemCode"
                                value={this.state.itemCode}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Item Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="itemName1"
                                value={this.state.itemName1}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.isActive1}
                                staticData={Status}
                                onSelect={this.onSelect}
                                value={this.state.isActive1}
                                stateKey={"isActive1"}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            Legacy
                            Item
                            Code
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                          <Button
                            color="primary"
                            right={true}
                            onClick={() => {
                              this.handlePage(1, 10);
                            }}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <ReportsTableComponent
                        url={serverApi.ITEM_MASTER_TABLE}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={true}
                        onDeleteClick={this.handleDeleteClick}
                        fileName={"ITEM_MASTER"}
                        showPagination
                        handlePage={this.handlePage.bind(this)}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(TaxMaster);
