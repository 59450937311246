import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import InputAdornment from "material-ui/Input/InputAdornment";
import Popup from '../../components/Popup/PopUp';
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {configNames, getConfigValue, serverApi, serverConfig} from "../../helper/Consts";
import { VIEW} from "../../ui-actions/UiActions";
import moment from "moment";
import {getDataFromUrl, getFileDownload, requestList, uploadFile} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import cons from "../../helper/Consts";
import Select from 'react-select';
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import {GoodTypes, ReasonTypes} from "../TableData";
class StoreMaterialInsc extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isCreated: false,
            fromDate:"",
            todate:"",
            status:'',
            vehicleNo:'',
            gateEntryNO:'',
            selectMukam:"",
            supplierId:'',
            brokerId:'',
            poNo:'',
            itemStatus: {value: 0, label: 'Select...', name: 'Select...'},
            outDateTime:"",
            itemViewType:"entryNo",
            updateView:false,
            showMessagealert:false,
            alert:false,
            basicAlert:null,
            indentAlert:false,
            entryId:"",
            selectedPO:"",
            selectedDepartment:"",
            selectedItemGroup:"",
            selectedItem:"",
            quantity:"",
            itemGrpName:"",
            itemName:"",
            deptName:"",
            uom:"",

            chalanNo:"",
            chalanDate:moment().format('DD-MM-YYYY'),
            vehicleNum:"",
            driverName:"",
            SelectedChalanType:"",
            selectedSupplier:"",
            remarks:"",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            time1:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
            outDate:"",
            outTime:"",
            invoiceList:[],
            approvedQTY:'',
            rejectedQTY:'',
            inspectionChk:"N",
            projectIds:"",
            branchIds:'',
            internalNote:'',
            poQuantity:"",
            goodType:"GRN",
            filesList:[],
            make: ""
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
        this.handleSelectedValues=this.handleSelectedValues.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    hideAlert() {
        this.setState({
            updateItem:false,
            indentAlert:false,
            alert: false,
            loading:false,
            showMessagealert:false,
            deptName:"",
            itemGrpName:"",
            itemName:"",
            uom:"",
            stock: "",
            quantity:"",
            selectedDepartment: "",
            selectedItemGroup:"",
            selectedItem:"",
            itemStatus:"",
            approvedQTY:"",
            id:'',
            reason:"",
            poQuantity:"",
            poNo:"",

        });
    }
    hideAlertMessage(){
        this.setState({
            isCreated:false,
            updateView:false,
            showMessagealert:false,
            alert:false,
            basicAlert:null,
            indentAlert:false,
            selectedPO:"",
            selectedDepartment:"",
            selectedItemGroup:"",
            selectedItem:"",
            quantity:"",
            itemGrpName:"",
            itemName:"",
            deptName:"",
            uom:"",
            chalanNo:"",
            chalanDate:moment().format('DD-MM-YYYY'),
            vehicleNum:"",
            driverName:"",
            SelectedChalanType:"",
            selectedSupplier:"",
            remarks:"",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            time1:moment().format('hh:mm:ss A'),
            invoiceList:[],
            itemStatus:"",
            approvedQTY:"",inspectionChk:"N",
            internalNote:'',
            goodType:"GRN",
            costDesc:"",
            costFactor:"",
            description:"",
            filesList:[]
        });

    }

    handleCreate=()=>{
        //alert();
        if(!this.state.isCreated){
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data={
            "fromDate":this.state.fromDate,
            "toDate":this.state.todate,
            "supplierCode":this.state.supplierId,
            "companyId":getCookie(cons.COMPANY_ID),
            "cipher":getCookie(cons.CIPHER),
            "financialYear": getCookie(cons.ACADEMIC_YEAR),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
            "branchId":this.state.branchIds!==0?this.state.branchIds:"",
            "projectId":this.state.projectIds!==0?this.state.projectIds:""

        };
        return data;
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id] :''
            });

    };
    timeChanged1 = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('hh:mm:ss A'),
                time2:dateObj.format('HH:mm:ss')
            });
    };
    //dateFormate
    dateChanged = (myDate) => {
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };
    timeChangedFormat = (myDate,time) => {
        console.log("time",time);

        myDate=myDate.split("-");
        var timeParts = time.split(":");
        return new Date(myDate[2], myDate[1]-1, myDate[0], timeParts[0], timeParts[1],timeParts[2]).getTime();
    };

    onChangeValues=(event)=> {
        var value= event.target.value;

        this.setState({
            [event.target.id]: value
        });
    };
    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };
    handleSeacrh = () => {
        //   console.log(this.state);
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    onSelect = (selectedValue, stateKey) => {

     /*   if(stateKey === 'selectedDepartment'){
            this.setState({
                loadItemGroup:true,
                loadItem:true
            })
        }*/
        if(stateKey==='branchId' ||stateKey==='branchIds'){
            this.setState({loadProject:true})
        }

/*
        if(stateKey === 'selectedItemGroup'){
            var data={
                "itemGroupId":selectedValue,
                "saleable":"",
                "consumable":"",
                "companyId":getCookie(cons.COMPANY_ID)
            };

            requestList(serverApi.GET_ITEMS_BY_GRPID,data,this.handleItem);
            // this.setState({
            //     loadItem:true
            // })
        }
*/

        this.setState({
            [stateKey]: selectedValue
        });
    };
    handleItem = (resp) => {
        this.setState({ItemOptions:resp,loadItem:true})

    }
    afterReloadSelect = (val) => {

        this.setState({
            reloadSelect: val
        });
    };
    afterRefreshItem = (val) => {

        this.setState({
            loadItem: val
        });
    };

    afterRefreshItemGrp = (val) => {

        this.setState({
            loadItemGroup: val,
            loadItem:val
        });
    };
    getSupplierName = (selectedValue) => {
        this.setState({
            supplierName:selectedValue
        });
    };
    getDeptName = (selectedValue) => {
        this.setState({
            deptName:selectedValue
        });
    };

    getItemGroupName = (selectedValue) => {
        this.setState({
            itemGrpName:selectedValue
        });
    };

    getItemDetailsByName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            itemName:values[0],
            // selectedItemId:values[1],
            uom:values[1],
            stock:values[2],
        });
    };
    handleTableLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleViewClick =(id)=>{
        var juteId="";
        console.log("jute  "+"  "+id);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.GET_STORE_GATE_ENTRY + id+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
        }
    };
    handleViewClickResp = (resp) => {
        this.setState({printResp:resp});
        var header = resp.entryHeader;
        var list = resp.gateStoreLineItems;
        var indentlist = [];
            list.forEach((prop,key)=>{
                if(!prop.deleteRec) {
                    var rejectedqty = 0;
                    if (prop.approvedQTY > 0) {
                        rejectedqty = parseFloat(prop.quantity) - parseFloat(prop.approvedQTY);
                    }
                    console.log("rejectedqty", rejectedqty);
                   var obj=
                        {
                            id: key,
                            lineId: prop.dtlId,
                            department: prop.deptDesc,
                            itemGroup: prop.itemGroupDesc,
                            item: prop.itemDesc,
                            quantity: prop.quantity,
                            poQuantity: prop.poQuantity,
                            poNo: prop.poNo,
                            unit: prop.uom,
                            //stockValue: prop.stock,
                            deptId: parseInt(prop.dept),
                            itemGroupId: prop.itemGroupId,
                            itemId: prop.itemCode,
                            itemGrp: prop.itemGroupId + "" + prop.itemCode,
                            itemStatus: prop.approveReject,
                            approvedQTY: prop.approvedQTY,
                            reason: prop.reason,
                            rejectedQTY: prop.approveReject === 2 ? prop.quantity : rejectedqty,
                            costDesc:prop.costDesc,
                            costFactor:prop.costFactor,
                            description:prop.description
                        }
                    indentlist.push(obj)
                }
        });
        if(header !== null){
            console.log("challanNo",header.challanNo);
            this.setState({
                updateItem:false,
                updateView:true,
                isCreated:true,
                approveStatus:header.approveStatus,
                entryId:header.hdrId,
                chalanNo:header.challanNo,
                chalanDate:header.challanDateDesc,
                vehicleNum:header.vehicleNo,
                driverName:header.driverName,
                selectedSupplier:header.suppCode,
                supplierName:header.supplierName,
                remarks:header.remarks,
                date1:header.createDateDesc,
                time2:header.createdTimeDesc,
                outDate:header.outDateDesc,
                outTime:header.outTimeDesc,
                selectedPO:header.poNo,
                createdBy:header.createdBy,
                invoiceList:indentlist,
                inspectionChk:header.inspectionChk,
                branchId:header.branchId,
                projectId:header.projectId,
                internalNote:header.internalNote,
                goodType: header.goodType
            });
            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"22/"+header.hdrId+"/"+getCookie(cons.COMPANY_ID),this.handleInvoiceFiles);

        }
    };
    handleInvoiceFiles=(resp)=>{
        var list=[];
        if(resp.data){
            list=resp.data;
            if(this.state.filesList.length>0)
                list.push(...this.state.filesList);
            this.setState({filesList:list})
        }
    };

    onRejectButtonClick=()=>{
        var invoiceList= this.state.invoiceList.map((prop,key) => {
            return ({
                itemId:prop.itemId,
                itemGroupId:prop.itemGroupId,
                // qualityCode:prop.quality,
                deptId:prop.deptId,
                stock:prop.stockValue,
                indentQuantity:prop.quantity,
                unitId:prop.unit,
                reason:prop.reason
            })
        });

        var data={
            "indentHeader": {
                "type": this.state.selectedIndentType,
                "submitter": getCookie(cons.USERID),
                "indentDate": this.dateChanged(this.state.chalanDate),
                "finnacialYear": this.state.year,
                "status":"4"

            },
            "indentItems":invoiceList
        };
        this.handleLoading();
        requestList(serverApi.UPDATE_INDENT,data,this.handleAddIndent)
    };
    //approve
    onUpdateButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var aprroveStatus=false,emptyQty=false;
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                if(prop.itemStatus===0|| prop.itemStatus===''){
                    aprroveStatus=true
                }
                if(prop.itemStatus===1&&(prop.approvedQTY===""||prop.approvedQTY===null||parseFloat(prop.approvedQTY)<=0)){
                    emptyQty=true
                }
                return ({
                    dtlId: prop.lineId,
                    itemCode: prop.itemId,
                    itemDesc: prop.item,
                    itemGroupId: prop.itemGroupId,
                    // qualityCode:prop.quality,
                    dept: prop.deptId,
                    //stock:prop.stockValue,
                    quantity: prop.quantity,
                    uom: prop.unit,
                    approveReject: prop.itemStatus,
                    approvedQTY:prop.approvedQTY,
                    reason:prop.reason
                })
            });

            var data = {
                "entryHeader": {
                    hdrId: this.state.entryId,
                    challanNo: this.state.chalanNo,
                    challanDate: this.dateChanged(this.state.chalanDate),
                    vehicleNo: this.state.vehicleNum,
                    driverName: this.state.driverName,
                    suppCode: this.state.selectedSupplier,
                    supplierName: this.state.supplierName,
                    remarks: this.state.remarks,
                    inDate: this.dateChanged(this.state.date1),
                    inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
                    poNo: this.state.selectedPO,
                    createdBy: getCookie(cons.USER_NAME),
                    "companyId":getCookie(cons.COMPANY_ID),
                    branchId:this.state.branchId,
                    projectId:this.state.projectId,
                    internalNote:this.state.internalNote
                },
                "gateStoreLineItems": invoiceList,
                "cipher":getCookie(cons.CIPHER)

            };
            if(!aprroveStatus){
                if (this.state.invoiceList.length >= 1 &&this.state.branchId!==""&&this.state.branchId!==0&&this.state.projectId!==""&&this.state.projectId!==0) {
                    if(!emptyQty){
                        this.handleLoading();
                        requestList(serverApi.ADD_STORE_MATERIAL_INSPECTION, data, this.handleAddStoreGate)
                    }else {
                        this.basicAlert("Please Enter Approve Quantity For Approved Items..!!");
                    }


                } else if (this.state.invoiceList.length < 1) {
                    this.basicAlert("Please Add Atleast One Item..!!");
                }else {
                    this.basicAlert("Please Enter All Mandatory Fields..!!");

                }
            }else {
                this.basicAlert("Please Add Status To All Line Items..!!");

            }

            /*else {
                        this.basicAlert("Please Enter Out Date & Time..!!");
                    }*/
        }
    };

    onCommitButtonClick=()=>{
        this.setState({
            commitClicked:true
        });
        this.onUpdateButtonClick();

    };

    handleAddStoreGate = (resp) => {
        if (resp.status) {
            if(this.state.commitClicked){
                var data={
                    "userId":getCookie(cons.USERID),
                    "userName":getCookie(cons.USER_NAME),
                    "hdrId":this.state.entryId,
                    "cipher":getCookie(cons.CIPHER)
                };
                this.handleLoading();
                requestList(serverApi.COMMIT_STORE_MATERIAL_INSPECTION,data,this.handleAddStoreGate)

                this.setState({
                    commitClicked:false
                })
            }else
                this.showMessage(resp.message,resp.status);

        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };
    handleAddItem () {
        const {vehicleNum,driverName,selectedSupplier}=this.state;
        console.log(this.state);
        if( selectedSupplier !== 0 && selectedSupplier!=='')
            this.setState({
                loading:true,
                alert:true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };

    onClickAddItem(){
        const inputFocus={
            autoComplete: 'off'
        };
        var arr = this.state.invoiceList;

        var itemIndex = 0,lineId=0,itemStatus=0;

        if (this.state.updateItem) {
            itemIndex = this.state.id;
            lineId=this.state.lineId
        } else {
            itemIndex = this.state.invoiceList.length
        }
        var rejectedqty=0;
        if(this.state.approvedQTY>0){
            rejectedqty=parseFloat(this.state.quantity)-parseFloat(this.state.approvedQTY);
        }

        var addItemObj={
            lineId:lineId,
            id:itemIndex,
            department:this.state.deptName,
            itemGroup:this.state.itemGrpName,
            item:this.state.itemName,
            quantity:parseFloat(this.state.quantity),
            unit: this.state.uom,
            stockValue: this.state.stock,
            deptId:this.state.selectedDepartment,
            itemGroupId:this.state.selectedItemGroup,
            itemId:this.state.selectedItem,
            itemGrp:this.state.selectedItemGroup+""+this.state.selectedItem,
            itemStatus:this.state.itemStatus,
            approvedQTY:parseFloat(this.state.approvedQTY),
            reason:this.state.reason,
            rejectedQTY:this.state.itemStatus===2?this.state.quantity:rejectedqty,


        };

        const  {selectedDepartment,selectedItemGroup,selectedItem,quantity} = this.state;

        if(selectedDepartment!=="" && selectedItemGroup !== "" && selectedItem!== "" && quantity!== ""){

            if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
            } else
                arr.splice(arr.length, 0, addItemObj);

            this.setState({
                loading:true,
                invoiceList:arr,
                updateItem:false
            });
            this.hideAlert();
        } else {
            this.basicAlert("Please Select All Mandatory Fields..!!");
        }

    }
    //invoiceTableSize
    getInvoiceTablePageSize=()=>{
        var length =this.state.invoiceList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };
    //addItem
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
                updateItemAlert:false
            });
        }
        if(this.state.deleteItem){
            const options = [
                {value: 0, label: 'Select...', name: 'Select...'},
                {value: 1, label: 'APPROVE', name: 'LOOSE'},
                {value: 2, label: 'REJECT', name: 'BALE'},
            ];
            const inputFocus={
                autoComplete: 'off'
            };
            const {classes} = this.props;

            var data = this.state.invoiceList;
            data.splice(this.state.id,1);
            this.setState({invoiceList: data,
                id:0,
                deleteItem:false});

            this.setState({invoiceList: data,
                id:0,
                deleteItem:false});

            //  if(this.state.approveStatus)
        }
        this.setState({
            // id:0,
            basicAlert: null,
            deleteItem:false,
            updateItemAlert:false
        });

    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    hideBasicAlert(){
        if(this.state.updateItemAlert)
            this.hideAlert();

        this.setState({
            basicAlert: null,
            isReject:false,isApprove:false
        });

    }
    //onselect challan
/*
    handleSelectedValues = (selectedValue) => {
        this.setState({
            SelectedChalanType: selectedValue,
        });
    };
*/
    handleSelectedValues = (defValue) => {
        this.setState(
            {
                itemStatus: defValue,
            });
        console.log(`Option selected:`, defValue);
    };

    indenthideAlert() {

        this.setState({
            indentAlert: false,
            loading:false,
            SelectedChalanType:'',
        });
    }

    afterSelectPO =()=>{
        var juteId="";
        console.log("jute  "+"  "+this.state.selectedPO);
        getDataFromUrl(serverApi.GET_PO_DETAILS+this.state.selectedPO+"/"+getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER),this.handlePOResp)
    };

    handlePOResp = (resp)=>{

        var header = resp.poHeader;
        var list = resp.poLineItemVo;
        var indentlist = list.map((prop,key)=>{
            return(
                {
                    id:key,
                    //dataId:prop.itemId,
                    department:prop.deptName,
                    itemGroup:prop.itemGroupName,
                    item:prop.itemDesc,
                    quantity:prop.quantity,
                    unit: prop.unitId,
                    // stockValue: prop.stock,
                    deptId:prop.deptId,
                    itemGroupId:prop.itemGroupId,
                    itemId:prop.itemId,
                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === i){
                                            this.setState({
                                                alert:true,
                                                id:o.id,
                                                updateItem: true,
                                                deptName:o.department,
                                                itemGrpName:o.itemGroup,
                                                itemName:o.item,
                                                uom:o.unit,
                                                stock: o.stockValue,
                                                quantity:o.quantity,
                                                selectedDepartment: o.deptId,
                                                selectedItemGroup:o.itemGroupId,
                                                selectedItem:o.itemId,

                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            data.splice(i,1);
                                            return true;
                                        }
                                    });
                                    this.setState({invoiceList: data});
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>
                        </div>

                    )
                }
            )
        });
        console.log("indentWeight "+header.supplierId);
        if(header!== null){
            this.setState({
                loading:false,
                indentAlert:false,
                reloadSelect:true,
                selectedSupplier:header.supplierId,
                selectedPO:header.id,
                invoiceList:indentlist,
            });

        }

    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleDeleteInvoiceResp = (resp) => {
        if (resp.status) {
            var arr = this.state.filesList;
            arr.splice(this.state.fileId, 1);
            this.setState({
                filesList: arr,
                deleteInvoice: false,
                fileId: ""
            });
        }

    };

    handleDownloadedFile=(url)=>{
        window.open(url);
        //  console.log("url...",url)
    };
    handleInvoiceResp = (resp) => {
        console.log("file resp",resp);
        var data = resp;
        if (resp.status) {

//            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

            var arr = this.state.filesList;
            data={"fileUploadId": data.id,
                "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:2};
            arr.splice(arr.length, 0, data);
            this.setState({
                uploadInvoice: false,
                filesList: arr,
                basicAlert1: null
            });


        }
        console.log("resp", JSON.stringify(resp));

    };
    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,entryId,isReject,isApprove,poStatus}=this.state;

        if(isReject){
            this.setState({isReject:false});
            this.onApproveButtonClick("4")
        }
        if(isApprove){
            this.setState({isApprove:false});
            this.onCommitButtonClick()
        }

        if (uploadInvoice) {
            uploadFile(fileName,file,fileType,22,fileName,entryId, this.handleInvoiceResp);
            //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
            //  this.uploadInvoiceFile();
        }
        if (this.state.deleteInvoice) {

            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert1: null,
        });

    }

    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'PDF' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG' || fileType === 'XLSX') {
                this.setState({
                    file: file,
                    fileName: file.name,
                    fileType:fileName[1],
                    //filesList: arr,
                    uploadInvoice: true
                    // imagePreviewUrl: reader.result
                });
                this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
            }


        };
        reader.readAsDataURL(file);
    }

    getDataTable =()=> {
        var data = [];

        const options = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'APPROVE', name: 'APPROVE'},
            {value: 2, label: 'REJECT', name: 'REJECT'},
        ];
        const inputFocus={
            autoComplete: 'off'
        };
        var companyId=getCookie(cons.COMPANY_ID);
        const {classes} = this.props;
        //  console.log("dta",JSON.stringify(this.state.invoiceList))
        if (this.state.invoiceList.length > 0) {
        this.state.invoiceList.forEach((prop, key) => {
            prop.id = key;
            console.log("options",options[prop.itemStatus])
           prop.status=(
                <div className="actions-right">
                    <Select
                        isDisabled={this.state.inspectionChk==="Y"}
                        defaultValue={options[prop.itemStatus]}
                        name={"itemStatus"}
                        // value={this.state.itemStatus}
                        onChange={(defValue) => {
                            var data=this.state.invoiceList;
                            prop.itemStatus=defValue.value;
                            data[key].itemStatus=defValue.value;
                            if(defValue.value===2){
                                prop.rejectedQTY=data[key].quantity;
                                data[key].rejectedQTY=data[key].quantity;
                            }
                            if(defValue.value===1){
                                prop.approvedQTY=data[key].quantity;

                                data[key].approvedQTY=data[key].quantity;
                                data[key].rejectedQTY=(parseFloat(data[key].quantity)-parseFloat(data[key].approvedQTY));
                            }
                            this.setState(
                                {
                                    itemStatus: defValue,
                                    invoiceList:data
                                });

                            //  console.log(`Option selected:`, defValue);
                        }}
                        options={options}
                        className={classes.selectFormControl}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: '4px',
                            height:"20px",
                            colors: {
                                ...theme.colors,
                                //  primary25: '#00ABDC',
                                primary: '#00ABDC',
                                //   primary50:"#00ABDC"
                            },
                        })}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                height: '30px',
                                marginBottom:"30px"
                            }),

                        }}                    />

                </div>
            )
            prop.approvedReason = (
                <div>
                    <Select
                            isDisabled={this.state.inspectionChk==="Y"}

                        //isDisabled={this.state.updateView}
                        defaultValue={{label:prop.reason,value:prop.reason}}
                        name={"reason"}
                        // value={this.state.itemStatus}
                        onChange={(defValue) => {
                            var data=this.state.invoiceList;
                            data[key].reason = defValue.value;
                            prop.reason =defValue.value;
                            this.setState(
                                {
                                    reason: defValue.value,
                                    invoiceList: data
                                });

                            //  console.log(`Option selected:`, defValue);
                        }}
                        options={ReasonTypes}
                        className={classes.selectFormControl}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: '4px',
                            height:"20px",
                            colors: {
                                ...theme.colors,
                                //  primary25: '#00ABDC',
                                primary: '#00ABDC',
                                //   primary50:"#00ABDC"
                            },
                        })}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                height: '30px',
                                marginBottom:"30px"
                            }),

                        }}                    />


{/*
                    <TextField
                        defValue={prop.reason}
                        //  disabled={this.state.invoiceList.length>0&&this.state.invoiceList[key].itemStatus!==1}
                        id="reason"
                        // className={classes.textField}
                        // value={prop.approvedQTY}
                        onChange={(event) => {
                            var data = this.state.invoiceList;
                            // if(parseInt(event.target.value) > data[key].reason){
                            //     this.basicAlert("Approved Quantity Should Not Exceed Quantity..!!")
                            // }else {
                            data[key].reason = event.target.value;
                            prop.reason = event.target.value;
                            this.setState(
                                {
                                    reason: event.target.value,
                                    invoiceList: data
                                });
                            // }

                            //  console.log(`Option selected:`, defValue);
                        }}
                        fullWidth={true}
                        variant="outlined"
                        autoFocus={true}
                        InputProps={inputFocus}

                    />
*/}

                </div>
            )

/*
                prop.actions = (
                    <div className="actions-right">
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o, i) => {
                                    if (o.id === key) {
                                        this.basicItemAlert("Do You Like To Update This Item?");

                                        this.setState({
                                            updateItemAlert: true,
                                            //   alert:true,
                                            id: o.id,
                                            lineId: o.dtlId,
                                            updateItem: true,
                                            deptName: o.department,
                                            itemGrpName: o.itemGroup,
                                            itemName: o.item,
                                            uom: o.unit,
                                            stock: o.stockValue,
                                            quantity: o.quantity,
                                            selectedDepartment: o.deptId,
                                            selectedItemGroup: o.itemGroupId,
                                            selectedItem: o.itemId,
                                            approvedQTY: o.approvedQTY,
                                            itemStatus: o.itemStatus,
                                            reason: o.reason,
                                            loadItemGroup:true,
                                            loadItem:true,
                                            poQuantity:o.poQuantity,
                                            poNo:o.poNo
                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <Edit/>
                        </IconButton>{" "}

                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o, i) => {
                                    if (o.id === key) {
                                        this.setState({
                                            deleteItem: true,
                                            id: o.id,
                                        });

                                        this.basicItemAlert("Do You Like To Delete This Item?");

                                        //   data.splice(i,1);
                                        return true;
                                    }
                                });
                                this.setState({invoiceList: data});
                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close/>
                        </IconButton>{" "}
                    </div>

                )
*/
            if (prop.itemStatus === 2) {
                prop.approvedQuantity = 0;
            }
        if(prop.itemStatus === 1){
            console.log("approvedQTY", prop.approvedQTY);

                prop.approvedQuantity = (
                    <div>
                        <TextField
                            defValue={prop.approvedQTY+""}
                              disabled={this.state.inspectionChk==="Y"}
                            id="approvedQTY"
                            // className={classes.textField}
                            //  value={prop.approvedQTY}
                            iType={"number"}
                            onChange={(event) => {
                                var data = this.state.invoiceList;
                                if (parseInt(event.target.value) > data[key].quantity) {
                                    this.basicAlert("Approved Quantity Should Not Exceed Quantity..!!")
                                } else {
                                    data[key].approvedQTY = event.target.value;
                                    if (data[key].approvedQTY !== '')
                                        data[key].rejectedQTY = (parseFloat(data[key].quantity) - parseFloat(data[key].approvedQTY));
                                    else {
                                        data[key].rejectedQTY = 0;
                                        data[key].approvedQTY = 0;
                                    }
                                    prop.approvedQTY = event.target.value;
                                    this.setState(
                                        {
                                            approvedQTY: event.target.value,
                                            invoiceList: data,
                                        });
                                }

                                //  console.log(`Option selected:`, defValue);
                            }}
                            decimal={companyId === '1' || companyId === '2' || companyId === '116' ? 3 : 2}
                            fullWidth={true}
                            variant="outlined"
                            autoFocus={true}
                            InputProps={inputFocus}

                        />

                    </div>
                )
            }
            data.push(prop)

        });
    }
        return data;
    };


    render() {
        const {classes} = this.props;
        const {selectedPO} = this.state;
        var labelType=getConfigValue(configNames.LABELS_TYPE);

        const inputFocus={
            autoComplete: 'off'
        };
        const options = [
            { value: 0, label: 'Select...' },
            { value: 1, label: 'With PO'},
            { value: 2, label: 'Without PO'}
        ];
        var companyId=getCookie(cons.COMPANY_ID);

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                {this.state.isCreated ?
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>


                                    {
                                        this.state.alert?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"Add"}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <br/>

                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Department<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.updateView}
                                                                                    id={"selectedDepartment"}
                                                                                    onSelect={this.onSelect}
                                                                                    defValue={this.state.selectedDepartment}
                                                                                    getName={this.getDeptName}
                                                                                    stateKey="selectedDepartment"
                                                                                    url={serverApi.SEARCH_DEPATMENTS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                                    value={this.state.selectedDepartment}
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <br/>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.updateView}

                                                                                    defValue={this.state.selectedItemGroup}
                                                                                    id={"selectedItemGroup"}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getItemGroupName}
                                                                                    isRefresh={this.state.loadItemGroup}
                                                                                    stateKey="selectedItemGroup"
                                                                                    setIsRefresh={this.afterRefreshItemGrp}
                                                                                    url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedDepartment+"/"+getCookie(cons.COMPANY_ID)+"/getAllItemGroupsByDepartmentId/"+getCookie(cons.CIPHER)}
                                                                                    value={this.state.selectedItemGroup}
                                                                                />


                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>


                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} md={12}>
                                                                <br/>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.updateView}
                                                                                    defValue={this.state.selectedItem}
                                                                                    id={"selectedItem"}
                                                                                   // staticData={this.state.ItemOptions}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getItemDetailsByName}
                                                                                    isRefresh={this.state.loadItem}
                                                                                    stateKey="selectedItem"
                                                                                    setIsRefresh={this.afterRefreshItem}
                                                                                    postData={{
                                                                                        "itemGroupId":this.state.selectedItemGroup,
                                                                                        "saleable":"",
                                                                                        "consumable":"",
                                                                                        "companyId":getCookie(cons.COMPANY_ID)
                                                                                    }}
                                                                                    url={serverApi.GET_ITEMS_BY_GRPID}
                                                                                    value={this.state.selectedItem}
                                                                                />


                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Quantity<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="quantity"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    value={this.state.quantity}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    iType={"number"}
                                                                                    decimal={companyId==='1'||companyId==='2'||companyId==='116'?3:2}
                                                                                    InputProps={{
                                                                                        autoComplete: 'off',
                                                                                        endAdornment: <InputAdornment position="end">
                                                                                            <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>{this.state.uom}</span>
                                                                                        </InputAdornment>,
                                                                                    }}

                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>

                                                        </GridContainer>



                                                    </form>

                                                }
                                            >
                                            </Popup>:null
                                    }
                                    {this.state.basicAlert1}
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "date1")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"date1",
                                                            disabled:true,
                                                            value:this.state.date1,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        defaultValue={new Date()}
                                                        dateFormat={false}
                                                        onChange={(timeObj) => {
                                                            this.timeChanged1(timeObj, "time1")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"time1",
                                                            value:this.state.time1,
                                                            autoComplete: 'off',
                                                            disabled:true
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.updateView?                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Entry No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="entryId"
                                                    className={classes.textField}
                                                    value={this.state.entryId}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null
                                    }
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Challan No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                      disabled={true}
                                                    id="chalanNo"
                                                    className={classes.textField}
                                                    value={this.state.chalanNo}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Challan Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}

                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "chalanDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"chalanDate",
                                                            value:this.state.chalanDate,
                                                            autoComplete: 'off',
                                                            disabled:true
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Vehicle No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                     disabled={true}
                                                    id="vehicleNum"
                                                    value={this.state.vehicleNum}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Driver Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                      disabled={true}
                                                    id="driverName"
                                                    className={classes.textField}
                                                    value={this.state.driverName}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

{/*
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>

                                                <InputLabel className={classes.label}>
                                                    Challan Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>


                                                <Select
                                                    defValue={this.state.SelectedChalanType}
                                                    name ={"SelectedChalanType"}
                                                    value={this.state.SelectedChalanType}
                                                    onChange={this.handleSelectedValues}
                                                    options={options}
                                                    className={classes.selectFormControl}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: '4px',
                                                        // height:"20px",
                                                        colors: {
                                                            ...theme.colors,
                                                            //  primary25: '#00ABDC',
                                                            primary: '#00ABDC',
                                                            //   primary50:"#00ABDC"
                                                        },
                                                    })}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            height: '30px',
                                                            'min-height': '30px',
                                                            marginBottom:"10px"
                                                        }),

                                                    }}
                                                />


                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <SelectboxTemp id={"selectedSupplier"}
                                                               selectDisabled={this.state.updateView}
                                                               defValue={this.state.selectedSupplier}
                                                               isReload={this.state.reloadSelect}
                                                               setIsReload={this.afterReloadSelect}
                                                               onSelect={this.onSelect}
                                                               getName={this.getSupplierName}
                                                               stateKey="selectedSupplier"
                                                               url={serverApi.SELECT_SUPPLIER_STORE+getCookie(cons.COMPANY_ID)}
                                                               value={this.state.selectedSupplier}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Project":"Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={true}
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    stateKey="branchId"
                                                    // isRefresh={this.state.loadQuality}
                                                    // setIsRefresh={this.afterRefreshQuality}
                                                    url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                    value={this.state.branchId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Phase":"Project"}<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={true}
                                                    url={serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/0/branch/"+this.state.branchId}
                                                    defValue={this.state.projectId}
                                                    // staticData={this.state.ProjectOptions}
                                                    onSelect={this.onSelect}
                                                    stateKey="projectId"
                                                    value={this.state.projectId}
                                                    isRefresh={this.state.loadProject}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadProject:false})
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Goods Type<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={true}
                                                    id={"goodType"}
                                                    defValue={this.state.goodType}
                                                    onSelect={this.onSelect}
                                                    stateKey="goodType"
                                                    staticData={GoodTypes}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Remarks
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="remarks"
                                                    // label="Multiline"
                                                    fullWidth
                                                    multiline={true}
                                                    rowsMax="3"
                                                    value={this.state.remarks}
                                                    onChange={this.onChangeValues}
                                                    className={classes.textField}
                                                    height={60}
                                                    // helperText="hello"
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
{/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Out Date & Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <FormControl variant="" fullWidth>
                                                        <Datetime
                                                            defaultValue={new Date()}
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "outDate")
                                                            }}
                                                            height={"15px"}
                                                            formControlProps={
                                                                {height: "15px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id:"outDate",
                                                                value:this.state.outDate,
                                                                autoComplete: 'off'
                                                            }}

                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <FormControl variant="" fullWidth>
                                                        <Datetime
                                                            defaultValue={new Date()}
                                                            dateFormat={false}
                                                            onChange={(timeObj) => {
                                                                this.timeChanged1(timeObj, "outTime")
                                                            }}
                                                            height={"15px"}
                                                            formControlProps={
                                                                {height: "15px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id:"outTime",
                                                                value:this.state.outTime,
                                                                autoComplete: 'off'
                                                            }}

                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null }
*/}

                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>

                                                <LineItemTable
                                                    data={this.getDataTable()}
                                                    loading={this.state.loading}
                                                    disableActions={true}
                                                    columns={this.state.goodType === "SRN" ?
                                                        [
                                                            {
                                                                Header: "Cost Factor",
                                                                accessor: "costDesc",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Description",
                                                                accessor: "description",
                                                                minWidth: 80,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },

                                                            {
                                                                Header: "Quantity",
                                                                accessor: "quantity",
                                                                minWidth:70,
                                                                style: {color: "#000", textAlign: "right"}
                                                            },
                                                            {
                                                                Header: "Unit",
                                                                accessor: "unit",
                                                                minWidth: 50,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Status",
                                                                accessor: "status",
                                                                minWidth: 120,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Approved Quantity",
                                                                accessor: "approvedQuantity",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Rejected Quantity",
                                                                accessor: "rejectedQTY",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Reason",
                                                                accessor: "approvedReason",
                                                                minWidth: 130,
                                                                style: {color: "#000", textAlign: "left"}
                                                            },
                                                            {
                                                                Header: "Actions",
                                                                accessor: "actions",
                                                                style: {color: "#000", textAlign: "center"},
                                                                minWidth: 100,
                                                                sortable: false,
                                                                filterable: false,
                                                            }
                                                        ]:
                                                        [
                                                        {
                                                            Header: "Item Code",
                                                            accessor: "itemGrp",
                                                            minWidth:70,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Department",
                                                            accessor: "department",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Item Group",
                                                            accessor: "itemGroup",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            id: "item",
                                                            Header: "Item",
                                                            accessor: row => <>
                                                                <span style={{fontStyle: "bold"}}>{`${row.item}`}</span><br></br>
                                                                    {this.state.make !== "" && this.state.make!== null ? 
                                                                     <>
                                                                        <span style={{fontStyle: "italic", color: "#00ABDC"}}>Make:- {`${row.make}`}</span><br></br>
                                                                    </> : null}
                                      
                                                                </>,
                                                            filterMethod: (filter, row) =>
                                                            row._original.item.startsWith(filter.value) ||
                                                            row._original.make.startsWith(filter.value),
                                                            minWidth: 200,
                                      
                                                            style: {
                                                                color: "#000",
                                                                textAlign: "left",
                                                                textOverflow: "unset",
                                                            },
                                                           
                                                        },
                                                        // {
                                                        //     Header: "Make",
                                                        //     accessor: "make",
                                                        //     minWidth:150,
                                                        //     style: {color: "#000", textAlign: "left",textOverflow: 'unset'}
                                                        // },
                                                        {
                                                            Header: "Quantity",
                                                            accessor: "quantity",
                                                            minWidth:70,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "Unit",
                                                            accessor: "unit",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Status",
                                                            accessor: "status",
                                                            minWidth: 120,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                    {
                                                        Header: "Approved Quantity",
                                                        accessor: "approvedQuantity",
                                                        minWidth: 100,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                        {
                                                            Header: "Rejected Quantity",
                                                            accessor: "rejectedQTY",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Reason",
                                                            accessor: "approvedReason",
                                                            minWidth: 130,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            minWidth: 100,
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                />


                                            </ItemGrid>
{/*
                                            <ItemGrid xs={12}>
                                                {this.state.inspectionChk!=="Y"?  <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>:null}
                                            </ItemGrid>
*/}

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12}  style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={6}>
                                                <GridContainer>

                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Internal Note
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="internalNote"
                                                            fullWidth
                                                            multiline
                                                            rowsMax="3"
                                                            height={60}
                                                            value={this.state.internalNote}
                                                            onChange={this.onChangeValues}
                                                            className={classes.textField}
                                                        />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView?  <ItemGrid xs={12} sm={12}>
                                        <InputLabel className={classes.label}>
                                            Support Documents :
                                        </InputLabel>
                                    </ItemGrid>:null}

                                    {this.state.updateView?<ItemGrid xs={8} style={{overflowY: "auto", maxHeight: 250}}>
                                        {this.state.filesList.map((r, i) => (
                                            <GridContainer style={{marginTop: "10px"}}>
                                                <ItemGrid xs={12} sm={2}>
                                                    {i + 1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a onClick={()=>{
                                                        getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                    }}>
                                                        {r.fileName!==null&&r.fileName!==""?r.fileName:"indent"+(i+1)}
                                                    </a>
                                                </ItemGrid>
                                                <ItemGrid xs={4}>
                                                    <IconButton
                                                        right={true}
                                                        onClick={() => {
                                                            this.setState({
                                                                deleteInvoice: true,
                                                                invoiceId: r.fileUploadId,
                                                                fileId: i
                                                            });
                                                            this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                        }}

                                                        color="dangerNoBackground"
                                                    >
                                                        <Close/>
                                                    </IconButton> {""}

                                                </ItemGrid>

                                            </GridContainer>

                                        ))}

                                    </ItemGrid>:null}


                                    {this.state.updateView&&this.state.inspectionChk!=="Y"? <ItemGrid xs={4}>
                                        <Button color={"info"}
                                                onClick={this.handleFileClick}>Upload File</Button>
                                    </ItemGrid>:null}

                                    {this.state.updateView&&this.state.inspectionChk!=="Y"?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="primary" onClick={this.onUpdateButtonClick}>update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="success" onClick={()=>{
                                                        this.setState({isApprove:true});
                                                        this.InvoicebasicAlert("Do You Like To Commit..!!");

                                                    }}>Commit</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
                                    {this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"20px"}}>
                                            <SamplePDF
                                                invoiceType={8} printResp={this.state.printResp}/>
                                        </ItemGrid>:null}

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                            <GridContainer>

                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"fromDate",
                                                                        value:this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}

                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"toDate",
                                                                        value:this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Supplier
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8} >
                                                            <SelectboxTemp
                                                                defValue={this.state.supplierId}
                                                                id={"supplierId"}
                                                                onSelect={this.onSelect}
                                                                stateKey="supplierId"
                                                                url={serverApi.SEARCH_STORE_SUPPLIER+"/"+getCookie(cons.COMPANY_ID)}
                                                                value={this.state.supplierId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Project":"Branch"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                // selectDisabled={true}
                                                                defValue={this.state.branchIds}
                                                                onSelect={this.onSelect}
                                                                stateKey="branchIds"
                                                                // isRefresh={this.state.loadQuality}
                                                                // setIsRefresh={this.afterRefreshQuality}
                                                                url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                                value={this.state.branchIds}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Phase":"Project"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                url={serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/0/branch/"+this.state.branchIds}
                                                                defValue={this.state.projectIds}
                                                                // staticData={this.state.ProjectOptions}
                                                                onSelect={this.onSelect}
                                                                stateKey="projectIds"
                                                                value={this.state.projectIds}
                                                                isRefresh={this.state.loadProject}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadProject:false})
                                                                }}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>


                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
{/*
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                         onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
*/}
                                                <ItemGrid xs={12}>
                                                    <br/>
                                                    <br/>
                                                    <br/>

                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "15px"}}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                url={serverApi.GET_MATERIAL_INSPECTION_TABLE}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isLoading}
                                                handleLoading={this.handleTableLoading.bind(this)}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                fileName={'store_mi_currentdate'}

                                                actionType={VIEW}/>

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    textField:{
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    cssFocused: {},
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#00ADBC',
        },
        '&:after': {
            borderColor: '#D2D2D2',
        },

    },

    notchedOutline: {},
    ...customSelectStyle,
    ...extendedTablesStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(StoreMaterialInsc);
