import React from "react";
// react component for creating dynamic tables
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {
  getDataFromUrl,
  requestList,
  deleteDataFromUrl,
} from "../../server/server";
import { getCookie, removeCookies } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import { Check } from "@material-ui/icons";
import Checkbox from "material-ui/Checkbox/Checkbox";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { yesOrNo } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

class UomMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "uomCode",
      approveStatus: false,
      name: "",
      contactPerson: "",
      uomDsc: "",
      uomCode: "",
      alternateuomDsc: "",
      emailId: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      gstNo: "",
      panNo: "",
      cinNo: "",
      invoiceList: [],
      shippingAddress: "",
      ledgerGroup: "",
      billingStateCode: "",
      shippingStateCode: "",
      city2: "",
      state2: "",
      pincode2: "",
      gstNo2: "",
      openningBalance: "",
      itemsList: [],
      statesList: [],
      companyList: [],
      finalStatus: "N",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (uomCode, id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("uomcode" + "  " + uomCode);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.UOM_MASTER_BY_ID + uomCode,
        this.handleViewClickResp
      );

      // getDataFromUrl(
      //   serverApi.DATATABLE_SERVICE_URL +
      //     "getMaterialGoodReceiveDetailsById/" +
      //     id +
      //     "/" +
      //     getCookie(cons.USERID) +
      //     "/" +
      //     getCookie(cons.CIPHER),
      //   this.handleViewClickResp
      // );
    }
  };

  handleViewClickResp = (resp) => {
    var o = resp;
    var list = [];
    if (o !== null) {
      if (resp) {
        resp.forEach((prop, key) => {
          console.log("finding prop data", this.state.companyList);
          var obj = {
            id: key,
            relation: prop.relation != 0 ? prop.relation : null,
            relationValue: prop.relationValue !== 0 ? prop.relationValue : null,
            uomCode: prop.uomCode,
            uomDsc: prop.uomDsc,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.companyList;
                    alert("hello1");
                    data.find((o, i) => {
                      if (o.id === key) {
                        if (this.state.approveButton) {
                          this.setState({ deleteItem: true, id: o.id });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                          //  data.splice(i,1);
                        }
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </div>
            ),
          };
        });
      }
      console.log("we are printing the most", o[0].uomCode);
      var branchArray = this.state.itemsList;
      branchArray.map((t, i) => {
        o.mappedBranchIds.forEach((id) => {
          if (t.bId === id) {
            t.isEnabled = true;
          }
        });
      });

      this.setState({
        updateView: true,
        isCreated: true,
        id: o[0].id,
        uomCode: o[0].uomCode,
        uomDsc: o[0].uomDsc !== null ? o[0].uomDsc : o[1].uomDsc,
        companyList: list,
      });

      var data = {
        recordType: 2,
        companyID: getCookie(cons.COMPANY_ID),
        relation: this.state.id,
      };
      console.log("getting the retion as id", this.state.id);
      var req = requestList(
        serverApi.UOM_GET_RELATION_DATA,
        data,
        this.handleData
      );

      this.setState({
        companyList: this.state.companyList,
      });

      console.log("Id has been printed", this.state.companyList);
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      companyID: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      recordType: 1,
    };
    return data;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onCheckBranch = (index) => (event) => {
    var arr = this.state.itemsList;
    arr[index].isEnabled = !arr[index].isEnabled;
    this.setState({
      itemsList: arr,
    });
  };

  onChangeValues = (event, type) => {
    var value = event.target.value;
    console.log("printing........... " + value);
    if (type === "AN") {
      if (/[^0-9a-zA-Z\s]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "N") {
      if (/[^0-9]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "D") {
      this.setState({
        [event.target.id]: value,
      });
    } else {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleSelectedLGValues = (value) => {
    this.setState({
      ledgerGroup: value,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    console.log("basic alert here");
    //removeCookies();
    //window.location.reload();
    this.handleCreate();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      designationName: "",
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.companyList;
      if (this.state.updateView) data[this.state.id].activityFlag = 0;
      else data.splice(this.state.mid, 1);
      //  if(this.state.approveStatus)
      this.setState({
        companyList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,

      alert: false,
      showMessagealert: false,
      loading: false,
      uomCode: "",
      uomDsc: "",
      relation: "",
      relationValue: "",
    });
  }

  hideAlertMessage() {
    var list = this.state.itemsList.map((prop, i) => {
      prop.isEnabled = false;
      return prop;
    });
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      id: null,
      // customerCode: null,
      //name: "",
      //contactPerson: "",
      uomDsc: "",
      uomCode: "",
      //alternateuomDsc: "",
      //emailId: "",
      //address: "",
      //city: "",
      // state: "",
      // pincode: "",
      // gstNo: "",
      // panNo: "",
      // cinNo: "",
      // billingStateCode: "",
      // shippingAddress: "",
      // ledgerGroup: "",
      // shippingStateCode: "",
      // city2: "",
      // state2: "",
      // pincode2: "",
      // gstNo2: "",
      createdBy: null,
      createdOn: null,
      //openningBalance: "",
      itemsList: list,
      //finalStatus: "N",
      // internalCompanyId: "",
      invoiceList: [],
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }
  handleAddItem(e) {
    const { selectedJuteType } = this.state;
    console.log("unitConversionName " + this.state.unitConvertion);
    //  var id="";
    console.log(this.state);

    this.setState({
      loading: true,
      alert: true,
    });
  }
  // getDataTable = (e) => {
  //   var data = [];

  //   //  console.log("dta",JSON.stringify(this.state.invoiceList))
  //   if (this.state.invoiceList.length > 0)
  //     this.state.invoiceList.forEach((prop, key) => {
  //       prop.id = key;
  //       if (prop.isActive === 1) {
  //         var obj = {
  //           ...prop,
  //           actions: (
  //             // we've added some custom button actions
  //             <div className="actions-right">
  //               {/* use this button to remove the data row */}
  //               <IconButton
  //                 onClick={() => {
  //                   var data = this.state.invoiceList;
  //                   data.find((o, i) => {
  //                     if (o.id === obj.id) {
  //                       // alert(o.qualityId);
  //                       // here you should add some custom code so you can delete the data
  //                       // from this component and from your server as well
  //                       //  data.splice(i,1);

  //                       this.basicItemAlert("Do You Like To Update This Item?");

  //                       this.setState({
  //                         //alert: true,
  //                         updateItem: true,
  //                         updateItemAlert: true,
  //                         mid: o.id,
  //                         customerLineId: o.customerLineId,
  //                         autoId: o.autoId,
  //                         branchAddress: o.branchAddress,
  //                         cgstNo: o.gstNo,
  //                         pinCode: o.pinCode,
  //                         stateCode: o.stateCode,
  //                         stateName: o.stateName,
  //                         city: o.city,
  //                         relation: o.relation,
  //                         relationValue: o.relationValue,
  //                       });

  //                       return true;
  //                     }
  //                   });
  //                 }}
  //                 color="primaryNoBackground"
  //                 customClass="edit"
  //               >
  //                 <Edit />
  //               </IconButton>
  //               {/* <IconButton
  //                 onClick={() => {
  //                   var data = this.state.invoiceList;
  //                   data.find((o, i) => {
  //                     if (o.id === obj.id) {
  //                       // here you should add some custom code so you can delete the data
  //                       // from this component and from your server as well
  //                       this.setState({
  //                         deleteItem: true,
  //                         mid: o.id,
  //                       });
  //                       this.basicItemAlert("Do You Like To Delete This Item?");
  //                       return true;
  //                     }
  //                   });
  //                 }}
  //                 color="dangerNoBackground"
  //                 customClass="remove"
  //               >
  //                 <Close />
  //               </IconButton> */}
  //             </div>
  //           ),
  //         };

  //         data.push(obj);
  //       }
  //     });
  //   return data;
  // };
  onClickAddItem(e) {
    // e.preventDefault();
    var arr = this.state.companyList;
    var sumOfQty = 0,
      quantityInItem = 0;
    var itemIndex = 0,
      quantity = 0,
      hasItem = false,
      indentItemId = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      // indentItemId = this.state.indentItemId;
      // quantityInItem = arr[itemIndex].quantityType;
      // arr[itemIndex].quantityType = this.state.quantity;
    } else {
      itemIndex = this.state.companyList.length;
    }
    //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
    // arr.map((q) => {
    //   sumOfQty = sumOfQty + parseInt(q.quantityType);
    //   if (
    //     q.quality === this.state.selectedJuteQuality &&
    //     !this.state.updateItem
    //   )
    //     hasItem = true;
    // });

    console.log("quantity " + quantity);
    var addItemObj = {
      id: itemIndex,
      indentItemId: indentItemId,
      juteType: this.state.selectedJuteType,
      relation: this.state.relation,
      relationValue: this.state.relationValue,

      actions: (
        <div className="actions-right">
          {/* <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    indentItemId: o.indentItemId,
                    updateItemAlert: true,
                    updateItem: true,
                    relation: o.relation,
                    relationValue: o.relationValue,
                    // selectedJuteType: o.juteType,
                    // selectedJuteName: o.juteName,
                    // selectedJuteQuality: o.quality,
                    // selectedQualityName: o.qualityName,
                    // quantity: o.quantityType,
                    // selectedUnit: o.unit,
                    // selectedDeptId: o.deptId,
                    // selectedGrpId: o.grpId,
                    // stock: o.stockValue,
                    // alert:true,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton> */}
          <IconButton
            onClick={() => {
              var data = this.state.companyList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({ deleteItem: true, id: o.id });

                  //  data.splice(i,1);
                  return true;
                }
              });
              this.setState({
                selectedJuteType: "",
                selectedJuteName: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                quantity: "",
                selectedUnit: "",
                selectedDeptId: "",
                selectedGrpId: "",
                stock: "",
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };

    if (!hasItem) {
      if (
        this.state.relation !== "" &&
        this.state.relationValue !== "" &&
        this.state.relationValue > 0
      ) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);
        //console.log(arr.toString());

        this.setState({
          loading: true,
          companyList: arr,
          //sumOFQuantities: sumOfQty,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This Quality. Please Select Another One..!!"
      );
    }
  }

  // onUpdateButtonClick = () => {
  //   if (!getMenuPermission(1)) {
  //     this.basicAlert("Access Denied. Please contact administrator!!!");
  //   } else {
  //     const {
  //       id,
  //       invoiceList,
  //       relation,
  //       relationValue,
  //       uomDsc,
  //       uomCode,
  //       itemsList,
  //     } = this.state;
  //     var list = [];
  //     itemsList.map((prop, i) => {
  //       if (prop.isEnabled) {
  //         list.push(prop.bId);
  //       }
  //     });
  //     var invoiceList = invoiceList.map((prop, key) => {
  //       return {
  //         id: key,
  //         relation: prop.relation,
  //         relationValue: prop.relationValue,
  //         recordType: 2,
  //         companyID: getCookie(cons.COMPANY_ID),
  //       };
  //     });

  //     //var obj = Object.assign({}, invoiceList);
  //     // console.log("here..........................", invoiceList[0].id);

  //     var data = {
  //       id: id,
  //       uomCode: uomCode,
  //       uomDsc: uomDsc,
  //       //createdBy: getCookie(cons.USERID),
  //       companyID: getCookie(cons.COMPANY_ID),
  //       recordType: 1,
  //       uomLineitems: {
  //         id: invoiceList[1].id,
  //         uomDsc: uomDsc,
  //         uomCode: uomCode,
  //         relation: invoiceList[1].relation,
  //         relationValue: invoiceList[1].relationValue,
  //         recordType: 2,
  //         companyID: getCookie(cons.COMPANY_ID),
  //       },
  //     };

  //     if (uomDsc !== "" && uomCode !== "") {
  //       if (invoiceList.length > 0) {
  //         //var cName = this.state.name;

  //         this.handleLoading();
  //         requestList(serverApi.UOM_MASTER_UPDATE, data, this.handleAddIndent);
  //       } else {
  //         this.basicAlert("Please Add Atleast One Item..!!");
  //       }
  //     } else {
  //       this.basicAlert("Please Enter All Mandatory Fields..!!");
  //     }
  //   }
  // };

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        id,
        invoiceList,
        relation,
        relationValue,
        uomDsc,
        uomCode,
        itemsList,
      } = this.state;

      var invoiceList = invoiceList.map((prop, key) => {
        return {
          id: key,
          relation: prop.relation,
          relationValue: prop.relationValue,
          recordType: 2,
          companyID: getCookie(cons.COMPANY_ID),
        };
      });

      console.log("we are pasting invoice", invoiceList[0]);

      var data = {
        // id: id,
        // uomCode: uomCode,
        // uomDsc: uomDsc,
        // updatedBy: getCookie(cons.USERID),
        // //createdBy: getCookie(cons.USERID),
        // companyID: getCookie(cons.COMPANY_ID),
        // recordType: 1,
        // uomLineitems: {
        //   id: id,
        //   uomDsc: uomDsc,
        //   uomCode: uomCode,
        //   relation: relation,
        //   relationValue: relationValue,
        //   recordType: 2,
        //   companyID: getCookie(cons.COMPANY_ID),
        //},
        companyID: getCookie(cons.COMPANY_ID),
        createdBy: getCookie(cons.USERID),
        recordType: 2,
        relation: relation,
        relation_to: id,
        relationValue: relationValue,
      };
      if (
        this.state.companyId !== "" &&
        this.state.uomDsc !== "" &&
        this.state.uomCode !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.UOM_MASTER_UPDATE, data, this.handleAddIndent);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onRelationClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        companyID: getCookie(cons.COMPANY_ID),
        recordType: 2,
        createdBy: getCookie(cons.USERID),
        relation: this.state.id,
        relationValue: this.state.relationValue,
        relationTo: this.state.relation,
        activityFlag: 1,
      };

      if (this.state.relationValue !== null && this.state.relation !== null) {
        //const uomCode = this.state.uomDesc;
        //alert(uomCode);
        // this.handleLoading();
        requestList(
          //add here uom api
          serverApi.UOM_MASTER_UPDATE,
          data
        );

        this.basicAlert("Relation Created successfully");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        uomCode: this.state.uomCode,
        uomDsc: this.state.uomDsc,
        companyID: getCookie(cons.COMPANY_ID),
        createdBy: getCookie(cons.USERID),
        recordType: 1,
        relation: 0,
        relationValue: 0,
        relationTo: 0,
      };

      if (
        this.state.companyId !== "" &&
        this.state.uomCode !== undefined &&
        this.state.uomDsc !== undefined &&
        this.state.uomCode !== "" &&
        this.state.uomDsc !== ""
      ) {
        //const uomCode = this.state.uomDesc;
        //alert(uomCode);
        this.handleLoading();
        requestList(
          //add here uom api
          serverApi.UOM_MASTER_ADD,
          data,
          this.handleAddIndent
        );
        // alert("Created successfully");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  // onCreateButtonClick = () => {
  //   if (!getMenuPermission(2)) {
  //     this.basicAlert("Access Denied. Please contact administrator!!!");
  //   } else {
  //     const {
  //       invoiceList,
  //       name,
  //       contactPerson,
  //       uomDsc,
  //       alternateuomDsc,
  //       emailId,
  //       shippingAddress,
  //       address,
  //       city,
  //       state,
  //       billingStateCode,
  //       pincode,
  //       gstNo,
  //       panNo,
  //       cinNo,
  //       ledgerGroup,
  //       shippingStateCode,
  //       city2,
  //       state2,
  //       pincode2,
  //       gstNo2,
  //       openningBalance,
  //       itemsList,
  //       internalCompanyId,
  //       finalStatus,
  //     } = this.state;
  //     var list = [];
  //     itemsList.map((prop, i) => {
  //       if (prop.isEnabled) {
  //         list.push(prop.bId);
  //       }
  //     });
  //     var invoiceList = invoiceList.map((prop, key) => {
  //       return {
  //         address: prop.branchAddress,
  //         city: prop.city,
  //         stateCode: prop.stateCode,
  //         relation: prop.relation,
  //         pincode: prop.pinCode,
  //         gstNo: prop.gstNo,
  //         isActive: 1,
  //         createdBy: getCookie(cons.USERID),
  //       };
  //     });

  //     var ldata = {
  //       name: name,
  //       contactPerson: contactPerson,
  //       uomDsc: uomDsc,
  //       alternateuomDsc: alternateuomDsc,
  //       emailId: emailId,
  //       // "address":address,
  //       // "city":city,
  //       // "state":state,
  //       // "billingStateCode":billingStateCode,
  //       // "pincode":pincode,
  //       // "gstNo":gstNo,
  //       panNo: panNo,
  //       cinNo: cinNo,
  //       // "shippingAddress":shippingAddress,
  //       // "shippingStateCode":shippingStateCode,
  //       // "city2":city2,
  //       // "state2":state2,
  //       // "pincode2":pincode2,
  //       // "gstNo2":gstNo2,
  //       ledgerGroup: ledgerGroup,
  //       openningBalance: openningBalance,
  //       createdBy: getCookie(cons.USERID),
  //       companyId: getCookie(cons.COMPANY_ID),
  //       mappedBranchIds: list,
  //       internalCompanyId: internalCompanyId,
  //       finalStatus: finalStatus,
  //     };
  //     var data = {
  //       customerHdr: ldata,
  //       customerLineitems: invoiceList,
  //     };

  //     if (name !== "" && list.length > 0) {
  //       if (invoiceList.length > 0) {
  //         if (panNo !== "") {
  //           //var cName = this.state.name;

  //           if (this.state.panNo.length === 10) {
  //             this.handleLoading();
  //             requestList(
  //               serverApi.CUSTOMER_MASTER_ADD,
  //               data,
  //               this.handleAddTax
  //             );
  //             // } else if (
  //             //   this.state.panNo.match(
  //             //     /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
  //             //   )
  //             // ) {
  //             //   this.basicAlert(" Donot Enter any special character !!");
  //           } else {
  //             this.basicAlert("Enter 10 character pan no !!");
  //           }

  //           // if (panNo.substring(4, 5) === cName.substring(0, 1)) {

  //           // } else {
  //           //   this.basicAlert("Enter Correct PAN No.!!");
  //           // }
  //         } //else {
  //         //this.handleLoading();
  //         //requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax);
  //         //}
  //         else {
  //           this.basicAlert("Please enter the mandatory fields");
  //         }
  //       } else {
  //         this.basicAlert("Please Add Atleast One Item..!!");
  //       }
  //     } else {
  //       this.basicAlert("Please Enter All Mandatory Fields..!!");
  //     }
  //   }
  // };
  // handleAddTax = (resp) => {
  //   if (resp.status) {
  //     console.log("resp " + JSON.stringify(resp));
  //     this.showMessage(resp.message, resp.status);
  //   } else {
  //     this.showMessage(resp.message, resp.status);
  //   }
  //   this.handleLoading();
  // };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  componentDidMount() {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
    };

    var data2 = {
      recordType: 1,
      companyID: getCookie(cons.COMPANY_ID),
    };
    // fetch("https://jsonplaceholder.typicode.com/posts")
    //   .then((response) => response.json())
    //   .then((json) => console.log("we are printing the posts here.....", json));
    // requestList(serverApi.UOM_MASTER_RELATION_LIST);
    requestList(serverApi.UOM_GET_RELATION_DATA, data2, this.handleData);

    requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);
    requestList(serverApi.BRANCHMASTER_DATATABLE, data, this.handleBranches);
    getDataFromUrl(serverApi.GET_ALL_STATES, this.handleStates);

    if (getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) === "2") {
      data = {
        companyId: getCookie(cons.COMPANY_ID),
        //cipher: getCookie(cons.CIPHER),
        recordType: 2,
      };
      requestList(
        serverApi.UOM_GET_RELATION_DATA,
        data2,

        this.handleStates
      );
    }
  }

  deleteUom(rid) {
    console.log("nmmmmmmmm", rid);

    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      // console.log("data is printed",);

      //alert(uomCode);
      this.handleLoading();
      deleteDataFromUrl(serverApi.UOM_DELETE_DATA + rid, this.handleAddIndent);
    }

    //this.basicAlert("Do You Like To Delete This Item?");
  }

  handleData = (resp) => {
    console.log("geting uom relation data...........", resp);
    var companyList = [];
    if (resp) {
      resp.forEach((prop, key) => {
        companyList.push({
          rid: key,
          id: prop.id,
          relation: prop.relationTo,
          relationValue: prop.relationValue,
          activityFlag: prop.activityFlag,
          actions:
            prop.activityFlag !== "0" ? (
              <IconButton
                onClick={() => {
                  var data = this.state.companyList;

                  data.find((o, i) => {
                    if (o.rid === key) {
                      //console.log("heeeeeelooooooooo", o.id);
                      this.setState({ deleteItem: true, id: o.id });
                      this.deleteUom(o.id);
                      return true;
                    }
                  });
                }}
                color="black"
                customClass="remove"
              >
                <Close />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  var data = this.state.companyList;

                  data.find((o, i) => {
                    if (o.rid === key) {
                      //console.log("heeeeeelooooooooo", o.id);
                      this.setState({ deleteItem: true, id: o.id });
                      this.deleteUom(o.id);
                      return true;
                    }
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            ),
        });
      });
      this.setState({ companyList: companyList });
      console.log(companyList);
    }
  };

  handleCompanies = (resp) => {
    console.log("printing for data...........", resp);
    var companyList = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        companyList.push({
          label: prop.uomCode,
          value: prop.id,
          name: prop.uomCode,
        });
      });
      this.setState({ companyList: companyList });
    }
  };
  handleStates = (resp) => {
    var list = [];
    // if (resp.status === undefined) {
    //   resp.forEach((prop) => {
    //     var obj = prop;
    //     if (prop.value !== 0) obj.value = prop.label;
    //     else obj.value = "";
    //     list.push(obj);
    //   });
    // }
    // this.setState({
    //   statesList: list,
    // });
    if (resp) {
      resp.forEach((prop) => {
        list.push({
          label: prop.uomCode,
          value: prop.id,
          name: prop.uomCode,
        });
      });
      this.setState({ statesList: list });
    }
  };

  handleLedgerGroup = (resp) => {
    const lgoptions = [];
    var ledger_data = resp.data;
    if (ledger_data !== null || ledger_data !== undefined) {
      ledger_data.forEach((r) => {
        const { name, id } = r;
        lgoptions.push({
          value: r.id,
          label: r.name,
          name: r.name,
        });
      });
    }
    this.setState({ ledger_data: lgoptions });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType, ledger_data } = this.state;
    const options = ledger_data;
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              {/* <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Relation Uom
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid> */}
                              <ItemGrid xs={12} sm={8}>
                                {/* <SelectboxTemp
                                  onSelect={this.onSelect}
                                  defValue={this.state.relation}
                                  getName={(name) => {
                                    var val = name.split("^");
                                    this.setState({
                                      stateCode:
                                        val[1] !== undefined ? val[1] : "",
                                    });
                                  }}
                                  stateKey="relation"
                                  staticData={this.state.statesList}
                                  value={this.state.relation}
                                  // url={serverApi.GET_ALL_STATES}
                                /> */}
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Value
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="relationValue"
                                  value={this.state.relationValue}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}
                        </GridContainer>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Uom Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="uomCode"
                          value={this.state.uomCode}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Uom Desc
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="uomDsc"
                          value={this.state.uomDsc}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <>
                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Relation Uom
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              onSelect={this.onSelect}
                              defValue={this.state.relation}
                              // getName={(name) => {
                              //   var val = name.split("^");
                              //   this.setState({
                              //     stateCode: val[1] !== undefined ? val[1] : "",
                              //   });
                              // }}
                              stateKey="relation"
                              staticData={this.state.statesList}
                              value={this.state.relation}
                              // url={serverApi.GET_ALL_STATES}
                            />
                            {/* <SelectboxTemp
                                  id={"relation"}
                                  defValue={this.state.relation}
                                  onSelect={this.onSelect}
                                  stateKey="relation"
                                  //staticData={this.state.statesList}
                                  url={
                                    serverApi.SEARCH_MUKAM +
                                    getCookie(cons.COMPANY_ID)
                                  }
                                  value={this.state.relation}
                                /> */}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Value
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="relationValue"
                              value={this.state.relationValue}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            //onClick={this.onRelationClick}
                            onClick={() => {
                              this.onRelationClick();
                              this.onClickAddItem();
                            }}
                          >
                            Save Relation
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                      <br />
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <>
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.companyList}
                          loading={this.state.loading}
                          columns={[
                            {
                              Header: "Relation",
                              accessor: "relation",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Relation Value",
                              accessor: "relationValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "Actions",
                              minWidth: 100,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              // sortable: false,
                              // filterable: false,
                            },
                          ]}
                        />
                      </ItemGrid>

                      {/* <ItemGrid xs={12}>
                        <Button
                          color={"info"}
                          right={true}
                          onClick={this.handleAddItem}
                        >
                          Add Item
                        </Button>
                      </ItemGrid> */}
                    </>
                  ) : null}

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {/* <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid> */}
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.UOM_MASTER_TABLE_LIST}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={false}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(UomMaster);
