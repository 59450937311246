import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import cons, {serverConfig, serverApi, getConfigValue, configNames} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxMultiple";
import Selectbox from "../CustumComponents/SelectboxTemp";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";

class ApprovalHirarchy extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,

            itemViewType:"indentNo",
            approveStatus:false,


            updateView:false,
            statusDesc:"",

            createdBy:"",
            createdOn:"",

            tasksList:[],
            approveList:[],
            usersList:[],
            modules1: [{
                id:0,
                name:"Jute",
                checked:false,
                subMenus:[{id:0,name:"jute indent",sub_subMenu:[]},{id:1,name:"jute PO",sub_subMenu:[]},{id:2,name:"Quality check",sub_subMenu:[]}]
            },{
                id:1,
                name:"Purchase",
                subMenus:[{id:1,name:"purchase order",sub_subMenu:[]}]
            }, {
                id:2,
                name:"Store",
                subMenus:[{id:2,name:"store indent",sub_subMenu:[]}]
            },{
                id:3,
                name:"Security",
                subMenus:[{id:3,name:"JUTE GATE ENTRY",sub_subMenu:[]}]
            },{
                id:4,
                name:"Reports",
                subMenus:[{id:4,name:"Jute Reports",sub_subMenu:[{id:1,name:"jute"},{id:1,name:"store"}]}]
            } ],
            checked:[],
            branchList:[],
            branchId:0
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange=this.handleChange.bind(this)
    }
    componentDidMount() {

        getDataFromUrl(serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID),this.handleBranches);

      //  getDataFromUrl(serverApi.GET_TASKS_LIST+'/'+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER),this.handleMenus)
    }
    getTasksList=(branchId)=>{
        this.handleLoading();
        var data={"companyId":getCookie(cons.COMPANY_ID),"branchId":branchId};
        requestList(serverApi.GET_TASKS_LIST,data,this.handleMenus)
    };
    handleBranches=(resp)=>{
        console.log("loadBranch",resp);
        if(resp[1]!==undefined){
            this.setState({branchId:resp[1].value});
            this.getTasksList(resp[1].value)
        }
        this.setState({loadBranch:true,branchList:resp})
      //  this.handleLoading();


    };
    handleUsers=(resp)=> {
        this.setState({
            usersList:resp,
        });
      //  this.handleLoading();
    };

    handleMenus=(resp)=> {
        console.log("reeeee",resp);

            getDataFromUrl(serverApi.GET_ALL_USERS, this.handleUsers);

            var approveList =[];
            var tasksList=getConfigValue(configNames.APPROVAL_TASKS_LIST);
            if(tasksList!=="1"){
                tasksList=JSON.parse(tasksList);
                tasksList.forEach((t,i)=>{
                    var l1User = [],l2User = [],l3User = [],l4User = [],l5User = [],id=0;

                    if(resp.length>0) {
                        resp.forEach((prop, key) => {

                            if (prop.taskId === t.taskId) {
                                id = prop.id;
                                if (prop.l1User1 !== null)
                                    l1User.push(parseInt(prop.l1User1.id));
                                if (prop.l1User2 !== null)
                                    l1User.push(parseInt(prop.l1User2.id));
                                if (prop.l1User3 !== null)
                                    l1User.push(parseInt(prop.l1User3.id));


                                if (prop.l2User1 !== null)
                                    l2User.push(parseInt(prop.l2User1.id));
                                if (prop.l2User2 !== null)
                                    l2User.push(parseInt(prop.l2User2.id));
                                if (prop.l2User3 !== null)
                                    l2User.push(parseInt(prop.l2User3.id));

                                if (prop.l3User1 !== null)
                                    l3User.push(parseInt(prop.l3User1.id));
                                if (prop.l3User2 !== null)
                                    l3User.push(parseInt(prop.l3User2.id));
                                if (prop.l3User3 !== null)
                                    l3User.push(parseInt(prop.l3User3.id));

                                if (prop.l4User1 !== null)
                                    l4User.push(parseInt(prop.l4User1.id));
                                if (prop.l4User2 !== null)
                                    l4User.push(parseInt(prop.l4User2.id));
                                if (prop.l4User3 !== null)
                                    l4User.push(parseInt(prop.l4User3.id));

                                if (prop.l5User1 !== null)
                                    l5User.push(parseInt(prop.l5User1.id));
                                if (prop.l5User2 !== null)
                                    l5User.push(parseInt(prop.l5User2.id));
                                if (prop.l5User3 !== null)
                                    l5User.push(parseInt(prop.l5User3.id));

                            }

                        });
                    }
                    var obj = {
                        taskDesc: t.taskName,
                        taskId: t.taskId,
                        id:id,
                        user1: l1User,
                        user2: l2User,
                        user3: l3User,
                        user4: l4User,
                        user5: l5User,
                    };
                    approveList.push(obj);

                });
                this.setState({
                    tasksList: approveList,
                    approveList: approveList,
                    reloadUser:true
                });
            }else
            this.setState({
                tasksList: [],
                approveList: [],
                reloadUser:true
            });

        this.handleLoading();

    };



    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
/*
    handleChange = name => event => {

        this.setState({ [name]: event.target.checked });
    };
*/


    onChangeValues =(event)=> {
        var value= event.target.value;

        this.setState({
            [event.target.id]: value
        });

    };
    onSelectBranch = (selectedValue, stateKey) => {
        if(stateKey === 'branchId'&&this.state.branchId!==selectedValue){
            this.getTasksList(selectedValue)

            // getDataFromUrl(serverApi.GET_CUSTOMER_BY_BRANCH+getCookie(cons.COMPANY_ID)+"/"+selectedValue,this.handelCustomer);
        }


        this.setState({
            [stateKey]: selectedValue
        });
    };

    onSelect = (selectedValue, stateKey) => {
        var key=stateKey;
        key=key.split("-");


        var list=this.state.tasksList;
        var tasksList=this.state.approveList;
        var obj=tasksList[key[0]];

        if(key[1]==='user1' && selectedValue===0){
            obj["user2"]=selectedValue;
            obj["user3"]=selectedValue;
            obj["user4"]=selectedValue;
            obj["user5"]=selectedValue;
        }else if(key[1]==='user2'  && selectedValue===0){
            obj["user3"]=selectedValue;
            obj["user4"]=selectedValue;
            obj["user5"]=selectedValue;
        }else if(key[1]==='user3'&& selectedValue===0){
            obj["user4"]=selectedValue;
            obj["user5"]=selectedValue;

        }else if(key[1]==='user4'&& selectedValue===0 ){
            obj["user5"]=selectedValue;
        }

            obj[key[1]]=selectedValue;
            this.setState({
                tasksList:list,
                approveList:tasksList,
                [stateKey]: selectedValue,
                //  reloadSelect:true
            });

    };
    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };


    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert:false,
            basicAlert:null
        });
    }
    hideBasicAlert(key,index){
        var tasksList=this.state.approveList;
        var obj=tasksList[index];
        obj[key]=0;
        this.setState({
            showMessagealert:false,
            tasksList:this.state.tasksList,
            approveList:tasksList,
        });
    }
    basicAlert(msg,key,index) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert(key,index)}
                    onCancel={() => this.hideAlert(key,index)}
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };


    onCreateButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var list=[];
            this.state.approveList.map((prop,key)=>{

                var l1User1="",l1User2="",l1User3="",l2User1="", l2User2="", l2User3="", l3User1="",l3User2="",l3User3="", l4User1="",l4User2="",l4User3="",l5User1="", l5User2="",l5User3=""

                if(prop.user1.length>0){
                   if(prop.user1[0]!==undefined)
                       l1User1=prop.user1[0]+"";
                    if(prop.user1[1]!==undefined)
                        l1User2=prop.user1[1]+"";
                    if(prop.user1[2]!==undefined)
                        l1User3=prop.user1[2]+""
                }
                if(prop.user2.length>0){
                    if(prop.user2[0]!==undefined)
                        l2User1=prop.user2[0]+"";
                    if(prop.user2[1]!==undefined)
                        l2User2=prop.user2[1]+"";
                    if(prop.user2[2]!==undefined)
                        l2User3=prop.user2[2]+""
                }
                if(prop.user3.length>0){
                    if(prop.user3[0]!==undefined)
                        l3User1=prop.user3[0]+"";
                    if(prop.user3[1]!==undefined)
                        l3User2=prop.user3[1]+"";
                    if(prop.user3[2]!==undefined)
                        l3User3=prop.user3[2]+""
                }
                if(prop.user4.length>0){
                    if(prop.user4[0]!==undefined)
                        l4User1=prop.user4[0]+"";
                    if(prop.user4[1]!==undefined)
                        l4User2=prop.user4[1]+"";
                    if(prop.user4[2]!==undefined)
                        l4User3=prop.user4[2]+""
                }

                if(prop.user5.length>0){
                    if(prop.user5[0]!==undefined)
                        l5User1=prop.user5[0]+"";
                    if(prop.user5[1]!==undefined)
                        l5User2=prop.user5[1]+"";
                    if(prop.user5[2]!==undefined)
                        l5User3=prop.user5[2]+""
                }


                var obj={
                    id: prop.id,
                    taskId:prop.taskId,
                    l1User1: l1User1,
                    l1User2: l1User2,
                    l1User3: l1User3,
                    l2User1: l2User1,
                    l2User2: l2User2,
                    l2User3: l2User3,
                    l3User1: l3User1,
                    l3User2: l3User2,
                    l3User3: l3User3,
                    l4User1: l4User1,
                    l4User2: l4User2,
                    l4User3: l4User3,
                    l5User1: l5User1,
                    l5User2: l5User2,
                    l5User3: l5User3,
                    modifiedBy:getCookie(cons.USERID),
                    companyId:getCookie(cons.COMPANY_ID),
                    branchId:this.state.branchId
                };
                list.push(obj)
            });


           // console.log("data",JSON.stringify(list));
            var data = {
                approveList: list
            };
            this.handleLoading();
            requestList(serverApi.UPDATE_APPOVE_HIERACHY, data, this.handleAddIndent)
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status){
                            this.hideAlert();
                            this.getTasksList(this.state.branchId);

                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
        console.log("checked ",JSON.stringify(newChecked));
    }


    afterReloadSelect = (val) => {

        this.setState({
            reloadSelect: val
        });
    };
    handleChange=(event)=> {
        this.setState(event.value);
    }

    render(){
        const { classes } = this.props;
        var {usersList,tasksList}=this.state;
        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: 0, label: 'App Role' ,name:'App Role'},
            { value: 1, label: 'Web role',name: 'Web role' }
        ];
        const inputFocus={
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                    <ItemGrid xs={12}  >
                        <RegularCard
                            isLoading={this.state.isLoading}
/*
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
*/
                            content={
                                <GridContainer>

                                        <ItemGrid xs={12} style={{overflowX:"auto"}}>
                                            {this.state.showMessagealert}
                                            {this.state.basicAlert}
                                            <GridContainer>
                                                <ItemGrid xs={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Branch
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <Selectbox
                                                                    defValue={this.state.branchId}
                                                                    onSelect={this.onSelectBranch}
                                                                    stateKey="branchId"
                                                                    isRefresh={this.state.loadBranch}
                                                                    setIsRefresh={(val)=>{
                                                                        this.setState({loadBranch:false})
                                                                    }}
                                                                    staticData={this.state.branchList}
                                                                   // url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                                />

                                                            </ItemGrid>

                                                        </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={12} style={{marginTop:"10px",minWidth:1900,width:"auto"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={2}>
                                                            <InputLabel className={classes.label}>
                                                                Task Name
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={10} >
                                                            <GridContainer>
                                                                <ItemGrid xs={2} style={{minWidth:300}}>
                                                                    <InputLabel  className={classes.label}>
                                                                        Level 1
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={2} style={{minWidth:300}}>
                                                                    <InputLabel  className={classes.label}>
                                                                        Level 2
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={2} style={{minWidth:300}}>
                                                                    <InputLabel  className={classes.label}>
                                                                        Level 3
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={2} style={{minWidth:300}}>
                                                                    <InputLabel className={classes.label}>
                                                                        Level 4
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={2} style={{minWidth:300}}>
                                                                    <InputLabel className={classes.label}>
                                                                        Level 5
                                                                    </InputLabel>
                                                                </ItemGrid>

                                                            </GridContainer>
                                                        </ItemGrid>

                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12}
                                                          style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px",minWidth:1500}}></ItemGrid>

                                    <ItemGrid xs={12} style={{maxHeight:400,overflowY:"auto",overflowX:"hidden",minWidth:1900,marginTop:"10px",minHeight:200}}>
                                        <GridContainer>
                                                {this.state.usersList.length>0?tasksList.map((prop, key) => {
                                                   var approve= this.state.approveList;
                                                        //console.log("approve",approve);
                                                    var menuID=prop.menuId;
                                            return(
                                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={2}>
                                                                {prop.taskDesc}
                                                            </ItemGrid>
                                                            <ItemGrid xs={10} >
                                                                <GridContainer>
                                                                    <ItemGrid xs={2} style={{minWidth:300}} >
{/*
                                                                        <Select
                                                                            isDisabled={this.props.selectDisabled}
                                                                             isMulti={true}
                                                                            name ={"users"}
                                                                         //   value={(this.props.unSelect!==undefined && this.props.unSelect)?'':this.state.defValue}
                                                                            onChange={this.handleChange}
                                                                            options={usersList}
                                                                          //  className={classes.selectFormControl}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: '4px',
                                                                                height:"30px",
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    //  primary25: '#00ABDC',
                                                                                    primary: '#00ABDC',
                                                                                    //   primary50:"#00ABDC"
                                                                                },
                                                                            })}
                                                                            styles={{
                                                                                control: (base, state) => ({
                                                                                    ...base,
                                                                                  //  height: '30px',
                                                                                    minHeight:'30px',
                                                                                    marginBottom:"10px"
                                                                                }),

                                                                            }}

                                                                        />
*/}


                                                                        <SelectboxTemp
                                                                            staticData={usersList}
                                                                            width={"200px"}
                                                                           // selectDisabled={true}
                                                                           // id={"selectedDepartment"}
                                                                            onSelect={this.onSelect}
                                                                            isReload={this.state.reloadUser}
                                                                            setIsReload={(val)=>{
                                                                                this.setState({reloadUser:false})
                                                                            }}
                                                                            defValue={prop.user1!==null?prop.user1:0}
                                                                            getName={this.getDeptName}
                                                                            stateKey={key+"-"+"user1"}
                                                                         //   url={serverApi.GET_ALL_USERS}
                                                                            value={prop.user1!==null?prop.user1:0}
                                                                        />

                                                                    </ItemGrid>
                                                                    <ItemGrid xs={2} style={{minWidth:300}}>

                                                                        <SelectboxTemp
                                                                            staticData={usersList}

                                                                            selectDisabled={approve[key].user1===0}
                                                                            unSelect={approve[key].user1===0}
                                                                           // id={"selectedDepartment"}
                                                                            onSelect={this.onSelect}
                                                                            isReload={this.state.reloadUser}
                                                                            setIsReload={(val)=>{
                                                                                this.setState({reloadUser:false})
                                                                            }}
                                                                            defValue={prop.user2!==null?prop.user2:0}
                                                                            getName={this.getDeptName}
                                                                            stateKey={key+"-"+"user2"}
                                                                           // url={serverApi.GET_ALL_USERS}
                                                                            value={prop.user2!==null?prop.user2:0}
                                                                        />
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={2} style={{minWidth:300}}>
                                                                        <SelectboxTemp
                                                                            staticData={usersList}

                                                                            selectDisabled={approve[key].user1===0||approve[key].user2===0}
                                                                            unSelect={approve[key].user1===0||approve[key].user2===0}

                                                                            /* isReload={this.state.reloadSelect}
                                                                             setIsReload={this.afterReloadSelect}*/
                                                                           // id={"selectedDepartment"}
                                                                            onSelect={this.onSelect}
                                                                            isReload={this.state.reloadUser}
                                                                            setIsReload={(val)=>{
                                                                                this.setState({reloadUser:false})
                                                                            }}
                                                                            defValue={prop.user3!==null?prop.user3:0}
                                                                            getName={this.getDeptName}
                                                                            stateKey={key+"-"+"user3"}
                                                                           // url={serverApi.GET_ALL_USERS}
                                                                            value={prop.user3!==null?prop.user3:0}
                                                                        />
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={2} style={{minWidth:300}}>
                                                                        <SelectboxTemp
                                                                            staticData={usersList}

                                                                            selectDisabled={approve[key].user1===0||approve[key].user2===0||approve[key].user3===0}
                                                                            unSelect={approve[key].user1===0||approve[key].user2===0||approve[key].user3===0}
                                                                          //  id={"selectedDepartment"}
                                                                           /* isReload={this.state.reloadSelect}
                                                                            setIsReload={this.afterReloadSelect}*/
                                                                            onSelect={this.onSelect}
                                                                            defValue={prop.user4!==null?prop.user4:0}
                                                                            isReload={this.state.reloadUser}
                                                                            setIsReload={(val)=>{
                                                                                this.setState({reloadUser:false})
                                                                            }}

                                                                            getName={this.getDeptName}
                                                                            stateKey={key+"-"+"user4"}
                                                                          //  url={serverApi.GET_ALL_USERS}
                                                                            value={prop.user4!==null?prop.user4:0}
                                                                        />
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={2} style={{minWidth:300}}>
                                                                        <SelectboxTemp
                                                                            staticData={usersList}
                                                                            selectDisabled={approve[key].user1===0||approve[key].user2===0||approve[key].user3===0||approve[key].user4===0}
                                                                            unSelect={approve[key].user1===0||approve[key].user2===0||approve[key].user3===0||approve[key].user4===0}
                                                                            // id={"selectedDepartment"}
                                                                            onSelect={this.onSelect}
                                                                            isReload={this.state.reloadUser}
                                                                            setIsReload={(val)=>{
                                                                                this.setState({reloadUser:false})
                                                                            }}
                                                                           /* isReload={this.state.reloadSelect}
                                                                            setIsReload={this.afterReloadSelect}*/
                                                                            defValue={prop.user5!==null?prop.user5:0}
                                                                            getName={this.getDeptName}
                                                                            stateKey={key+"-"+"user5"}
                                                                            //url={serverApi.GET_ALL_USERS}
                                                                            value={prop.user5!==null?prop.user5:0}
                                                                        />
                                                                    </ItemGrid>

                                                                </GridContainer>


                                                            </ItemGrid>
                                                        </GridContainer>

                                                    </ItemGrid>


                                            )
                                        })
                                        :null}

                                            </GridContainer>
                                        </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>


                                    {!this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>update</Button>

                                                </ItemGrid>
{/*
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
*/}

                                            </GridContainer>


                                        </ItemGrid>:null
                                    }
{/*
                                    {this.state.approveStatus?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onApproveButtonClick}>update</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
*/}
                                </GridContainer>
                            }/>

                    </ItemGrid>
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
export default  withStyles(extendedFormsStyle)(ApprovalHirarchy);


