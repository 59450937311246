import React from "react";
import ReactTable from "react-table";
import {
    infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";

class LineItemTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionsTable: null,
            dataTable: null,
            columns:[]
        }
    }

    getInvoiceTablePageSize = (data) => {
        var length=0;
        if(data!==null&&data!==undefined)
         length = data.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };


    render() {
        const {columns,loading,data,printDoc,disableActions,tableSize} = this.props;
        if(disableActions){
            columns.splice(columns.length-1,1)
        }

        return (
            <div>
                {(this.state.columns !== null) ?
                    <div>
                        <ReactTable
                            data={data}
                            filterable={false}
                            resizable={false}
                            loading={loading}
                            getTableProps={() => {
                                return {
                                    style: {
                                        border: "2px solid #00ABDC",
                                        borderRadius: "4px",
                                        overflowX:'auto',
                                        height:tableSize!==undefined?tableSize:null
                                    }
                                }}}

                            getTheadProps={() => {
                                return {
                                    style: {
                                        borderBottom: "2px solid #00ABDC",
                                        //height:45
                                        //lineHeight:"1.3"
                                    }
                                }}}
                            getTheadThProps={() => {
                                return {
                                    style: {
                                        borderRight: "1px solid #999999",
                                        textAlign:"center",
                                        whiteSpace:"pre-wrap !important",
                                        lineHeight:1.3,
                                    }
                                }}}
                            getTdProps={()=>{
                                return {
                                    style: {
                                        wordWrap:"break-word",
                                        lineHeight:3
                                    }
                                }
                            }}
                            getNoDataProps={() => {
                                return {
                                    style: {
                                        display: "none"
                                    }

                                };
                            }}
                            // resizable={false}
                            style={{color: infoColor,fontWidth:400, textAlign: "center", overflow:"auto"}}
                            columns={columns}
                            showPagination={false}
                            pageSize={this.getInvoiceTablePageSize(data)}
                            //  defaultPageSize={5}
                            sortable={false}
                            className="-striped -highlight"

                        />
                    </div> : null}

            </div>
        );
    }

}

export default LineItemTable;