import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
//import Search from "material-ui/SvgIcon/SvgIcon";
import Search from "@material-ui/icons/Search";
import TableComponent from "../CustumComponents/TableComponent";
import { red } from "material-ui/colors";

class AppDataFinshingEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      date: moment().format("DD-MM-YYYY"),
      time: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      itemViewType: "id",
      spell: "",
      yarnType: "",
      workType: "",
      empNo: "",
      production: "",
      empName: "",
      dept: "",
      occupation: "",
      beamMcNo: "",
      pType: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  getItem(val) {
    console.log("ghjkl;", val);
    //
    //console.log("ghjkl;", this.state.machineType);

    if (val === 1) {
      return 1;
    } else if (val === 2) {
      return 2;
    } else {
      return "";
    }
  }

  componentDidMount() {
    getDataFromUrl(
      serverApi.SELECT_PROCESS_TYPE +
        "/" +
        getCookie(cons.COMPANY_ID) +
        "/" +
        getCookie(cons.CIPHER),
      this.handleStates
    );

    getDataFromUrl(
      serverApi.SELECT_PROCESS_TYPE +
        "/" +
        getCookie(cons.COMPANY_ID) +
        "/" +
        getCookie(cons.CIPHER),
      this.data
    );

    console.log("go goa gone............", this.state.machineType);
    // var data2 = {
    //   companyId: getCookie(cons.COMPANY_ID),
    //   machineType1: 5,
    //   machineType2: 0,
    //   //qualityType: 2,
    // };

    // requestList(serverApi.GET_MACHINE_BY_ID, data2, this.handleData);
    //post
    // var data2 = {
    //   companyId: getCookie(cons.COMPANY_ID),
    //   qualityType: 2,
    // };

    // requestList(serverApi.WEAVING_QUALITY_MASTER, data2, this.handleStates);
    // get request
    // getDataFromUrl(
    //   serverApi.GET_FRAME_NUMBERS + getCookie(cons.COMPANY_ID) + "/SPINNING",
    //   this.handleFrameNos
    // );
  }
  handleData = (resp) => {
    var list = [];
    console.log("geting response from here only", resp);
    if (resp) {
      resp.forEach((prop) => {
        list.push({
          label: prop.machineName,
          value: prop.value,
          name: prop.machineName,
        });
      });
      this.setState({ masterList: list });
      console.log(
        "helllllllloooooooooo.................",
        this.state.masterList
      );
    }
  };
  // handleFrameNos = (resp) => {
  //   this.setState({ frameNoList: resp, loadFrames: true });
  // };

  // handleData = (resp) => {
  //   var lists = [];
  //   console.log("geting response from here only", resp.data);
  //   if (resp.data) {
  //     resp.data.forEach((prop) => {
  //       lists.push({
  //         label: prop.qualityName,
  //         value: prop.qualityId,
  //         name: prop.qualityName,
  //       });
  //     });
  //     this.setState({ masterList: lists });
  //     console.log(
  //       "helllllllloooooooooo.................",
  //       this.state.statesList
  //     );
  //   }
  // };

  getItem(val) {
    console.log("ghjkl;", val);

    if (val === 1) {
      return 1;
    } else if (val === 2) {
      return 2;
    } else {
      return "";
    }
  }

  handleStates = (resp) => {
    var list = [];
    console.log("hellllloooo...................", resp);
    if (resp) {
      resp.forEach((prop) => {
        var obj = prop;
        if (prop.value !== 0) {
          obj.value = prop.value;
          obj.machineType = prop.machineType;
          obj.prodType = prop.prodType;
          //console.log("machiennmnmn...........", obj.machineType);
          this.getAllMachine(obj.machineType);
          //this.check(obj.prodType);
          // this.setState({
          //   workType: obj.value,
          //   statesList: prop.prodType,
          // });
        } else obj.value = "";
        list.push(obj);
      });
    }
    this.setState({
      statesList: list,
      loadState: true,
    });
    console.log("hehehehehehehehe", this.state.workType);
  };

  getAllMachine = (value) => {
    console.log("go goa gone............", value);
    var data2 = {
      companyId: getCookie(cons.COMPANY_ID),
      machineType1: value,
      machineType2: 0,
      //qualityType: 2,
    };

    requestList(serverApi.GET_MACHINE_BY_ID, data2, this.handleData);
  };

  check(prodType) {
    var data = prodType;

    if (data < 2) {
      return 1;
      // this.setState({
      //   workType: 1,
      // });
    } else {
      return 2;
      // this.setState({
      //   workType: 2,
      // });
    }

    // console.log("I m going ", this.state.workType);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      //  getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp;

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
      });
    }
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    if (this.state.fromDate !== "" || this.state.todate !== "") {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
      };
    } else
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        cipher: getCookie(cons.CIPHER),
      };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/
    var pdType = "";
    if (stateKey === "workType") {
      console.log("stateList.............", this.state.statesList);
      this.state.statesList.forEach((d, i) => {
        console.log(
          "printttttttttttttttt",
          +"value" + d.value + "selected" + selectedValue + d.prodType
        );
        if (d.value != "")
          if (d.value === selectedValue && d.prodType === 2) {
            // this.setState({
            //   pType: d.prodType,
            // });
            pdType = d.prodType;
            console.log("showingggggggg.........", pdType);
          }
      });
    }

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    this.setState({
      [stateKey]: selectedValue,
      pType: pdType,
    });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      spell: "",
      yarnType: "",
      workType: "",
      empNo: "",
      production: "",
      beamMcNo: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      // spell: '0',
      //workType: 0,
      empNo: "",
      production: "",
      // date: moment().format('DD-MM-YYYY'),
      time: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      empName: "",
      dept: "",
      occupation: "",
      reloadSelect: true,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "deleteFinishingEntry/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        issueId: this.state.indentNo,
        juteType: this.state.selectedJuteType,
        finYear: this.state.year,
        issueDate: this.dateChanged(),
        juteQuality: this.state.form_QualityId,
        baleLoose: this.state.unitConvertionName,
        noBales: this.state.balesCount,
        quantity: this.state.form_quantity,
        uomCode: this.state.uom,
        openStock: "",
        closeStock: "",
        issueStatus: "",
        godownNo: this.state.form_godownNo,
        side: this.state.processSideName,
        createdBy: getCookie(cons.USER_NAME),
        wastageId: this.state.wastageType,
      };
      /*  if (this.state.date1 !== '' && this.state.unitConvertion !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                  this.handleLoading();
                  requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
              }
              else {
                  this.basicAlert("Please Enter All Mandatory Fields..!!");
              }*/
    }
  };
  timeChangedFormat = (myDate, time) => {
    console.log("time", myDate + time);

    myDate = myDate.split("-");
    var timeParts = time.split(":");
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { spell, workType, empNo, production, beamMcNo } = this.state;
      var data = {
        spell: spell,
        workType: workType,
        ebNo: empNo,
        production: production,
        machineId: beamMcNo,
        entryDate: this.timeChangedFormat(this.state.date, this.state.time2),
        createdBy: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
      };
      if (
        this.state.date !== "" &&
        this.state.spell !== "" &&
        this.state.spell !== "0" &&
        this.state.workType !== "" &&
        this.state.workType !== "0" &&
        //this.state.empNo !== "" &&
        production !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_FINISHING_ENTRY, data, this.handleFinishing);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleFinishing = (resp) => {
    if (resp.status) {
      this.setState({
        isRefresh: true,
      });
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        //  time2:dateObj.format('HH:mm:ss')
      });
  };
  getEmpDetails = () => {
    const { empNo } = this.state;
    if (empNo !== "") {
      getDataFromUrl(
        serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
          empNo +
          "/" +
          getCookie(cons.COMPANY_ID) +
          "/" +
          getCookie(cons.USERID),
        this.handleEmpDetails
      );
    } else {
      this.basicAlert("Please Enter Employee No..!!");
    }
  };
  handleEmpDetails = (resp) => {
    if (resp.status) {
      var header = resp.data;
      this.setState({
        empName: header.workerName,
        dept: header.deptIdDesc,
        occupation: header.designationIdDesc,
      });
    } else {
      this.setState({
        empName: "",
        dept: "",
        occupation: "",
      });
      this.showMessage(resp.message, false);
    }
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };

  render() {
    const { classes } = this.props;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Date
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "date");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "date",
                            value: this.state.date,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Time
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="" fullWidth>
                        <Datetime
                          defaultValue={new Date()}
                          closeOnSelect={true}
                          // defaultValue={this.state.time1}
                          dateFormat={false}
                          onChange={(timeObj) => {
                            this.timeChanged1(timeObj, "time");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "outTime",
                            value: this.state.time,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Spell
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.spell}
                        id={"spell"}
                        getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="spell"
                        url={
                          serverApi.GET_ALL_SPELL_LIST +
                          getCookie(cons.COMPANY_ID) +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        value={this.state.spell}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                {/*<ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>*/}
                {/*<GridContainer>*/}
                {/*<ItemGrid xs={12} sm={4}>*/}
                {/*<InputLabel className={classes.label}>*/}
                {/*Yarn Type<span style={{color: "red", fontSize: "12px"}}>*</span>*/}
                {/*</InputLabel>*/}
                {/*</ItemGrid>*/}
                {/*<ItemGrid xs={12} sm={8}>*/}
                {/*<SelectboxComponent*/}
                {/*defValue={this.state.yarnType}*/}
                {/*id={"yarnType"}*/}
                {/*getName={this.getQualityDetails}*/}
                {/*onSelect={this.onSelect}*/}
                {/*stateKey="yarnType"*/}
                {/*url={serverApi.SELECT_QUALITIES1}*/}
                {/*value={this.state.yarnType}*/}
                {/*/>*/}
                {/*</ItemGrid>*/}
                {/*</GridContainer>*/}
                {/*</ItemGrid>*/}
                {/* { === 1 ? (
                  <>
                    <h1>Hello</h1>
                  </>
                ) : (
                  <h1>Hello2</h1>
                )} */}
                {/* <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Frame No
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.frameNo}
                        id={"frameNo"}
                        // getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="frameNo"
                        isRefresh={this.state.loadFrames}
                        setIsRefresh={() => {
                          this.setState({ loadFrames: false });
                        }}
                        staticData={this.state.frameNoList}
                        // url={serverApi.GET_FRAME_NUMBERS+getCookie(cons.COMPANY_ID)+"/SPINNING"}
                        value={this.state.frameNo}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid> */}
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Work Type
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        onSelect={this.onSelect}
                        //defValue={this.state.workType}
                        getName={(name) => {
                          var val = name.split("^");
                          if (!this.state.updateView)
                            this.setState({
                              stateCode: val[1] !== "null" ? val[1] : "",
                            });
                        }}
                        stateKey="workType"
                        staticData={this.state.statesList}
                        isRefresh={this.state.loadState}
                        setIsRefresh={(val) => {
                          this.setState({ loadState: false });
                        }}
                        value={this.state.workType}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                {this.state.pType !== 2 ? (
                  <>
                    {/* <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        work Type
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.workType}
                        id={"spell"}
                        //getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="workType"
                        // url={
                        //   serverApi.SELECT_PROCESS_TYPE +
                        //   "/" +
                        //   getCookie(cons.COMPANY_ID) +
                        //   "/" +
                        //   getCookie(cons.CIPHER)
                        // }
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        value={this.state.workType}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid> */}
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Emp No
                            {/* <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span> */}
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="empNo"
                            // label="Multiline"
                            value={this.state.empNo}
                            onChange={this.onChangeValues}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <Search
                                      className={classes.inputAdornmentIcon}
                                      onClick={this.getEmpDetails}
                                    />
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <p>Name : {this.state.empName}</p>
                      <p>Department : {this.state.dept}</p>
                      <p>Occupation : {this.state.occupation}</p>
                    </ItemGrid>
                  </>
                ) : (
                  <>
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Machine No's
                            {/* <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span> */}
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"beamMcNo"}
                            onSelect={this.onSelect}
                            defValue={this.state.beamMcNo}
                            getName={this.getMachineName}
                            stateKey="beamMcNo"
                            isReload={this.state.loadMachine}
                            setIsReload={(val) => {
                              this.setState({
                                loadMachine: false,
                              });
                            }}
                            // url={
                            //   serverApi.GET_FRAME_NUMBERS +
                            //   getCookie(cons.COMPANY_ID) +
                            //   "/BEAMING"
                            // }
                            staticData={this.state.masterList}
                            value={this.state.beamMcNo}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </>
                )}
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Production
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="production"
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        iType="number"
                        value={this.state.production}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                {!this.state.updateView ? (
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      <ItemGrid>
                        <Button
                          color="primary"
                          onClick={this.onCreateButtonClick}
                        >
                          Create
                        </Button>
                      </ItemGrid>

                      <ItemGrid>
                        <Button color="danger" onClick={this.handleCreate}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                ) : null}
                {
                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />
                }{" "}
                <ItemGrid xs={10} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={6}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            From date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            To date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "todate");
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.todate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={2}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <br />
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                      <br />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />
                <ItemGrid xs={12}>
                  {
                    <TableComponent
                      url={serverApi.FINISHING_ENTRY_TABLE}
                      searchData={this.getData()}
                      isRefresh={this.state.isRefresh}
                      handleSearch={this.handleSeacrh.bind(this)}
                      isLoading={this.state.isRefresh}
                      onActionClick={this.handleDeleteClick}
                      itemViewType={this.state.itemViewType}
                      actionType={DELETE}
                      fileName={"FinishingEntry"}

                      //showDelete={true}
                      // onDeleteClick={this.handleDeleteClick}
                    />
                  }
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  inputAdornmentIcon: {
    color: "#00ABDC",
    cursor: "pointer",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(AppDataFinshingEntry);
