import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {dataTable} from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, getConfigValue, configNames} from '../../helper/Consts';
import FormControl from "material-ui/Form/FormControl";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import {JobType, JobStatus,ServiceType} from "../TableData";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import Search from '@material-ui/icons/Search';
import Radio from 'material-ui/Radio/Radio';
import RadioGroup from 'material-ui/Radio/RadioGroup';
import InputAdornment from "material-ui/Input/InputAdornment";
import PaymentComponent from "../CustumComponents/PaymentComponent";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import moment from "moment";




class Jobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            toDate: "",
            isRefresh: false,
            isLoading: false,
            itemViewType: "hdrId",
            approveStatus: false,
            custumerPopup:false,
            checkBoxPopUp:false,
            addCustomerPopUp:false,
            packagePopUp:false,
            generatePaymentVocher:false,
            packageSkip:false,
            isDisabled:false,
            checkboxId:null,
            jobTypeId:"",
            branchId:"",
            serviceType:"",
            customerId:"",
            itemId:"",
            itemGroupid:"",
            problemId:"",
            manufacturer:"",
            serialNo:"",
            jobdescription:"",
            jobDate:"",
            primeAnalysisDate:"",
            readyDeliveryDate:"",
            closeDate:"",
            feedbackDate:"",
            status:"",
            dependencyJobId:"",
            remark:"",serviceCharge:"",slaDate:"",branchs:"",jobType:"",
            serviceTypes:"",jobNo:"",fromDates:"",toDates:"",itemGroupids:"",
            itemIds:"",problemIds:""

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        //this.onChangeRadioValues =this.onChangeRadioValues.bind(this)

    }

    handleSeacrh = () => {
        if(!this.state.isRefresh) {
             this.callCustomerMappingList();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {

            getDataFromUrl(serverApi.CUSTOMER_MAPPING_GET_BYID+"/"+id, this.handleViewClickResp);
        }
    };
    handleViewClickResp = (resp) => {
        var o = resp;

        if (o !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                packagePopUp:false,
                hdrId:o.hdrId,
                carrierName:o.carrierName,
                declaration:o.declaration,
                itemGroupCode:o.itemGroupCode,
            });
        }
    };

    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            if(id==="date1"){
                this.setState({
                    [id] :dateObj.format('YYYY-MM-DD')
                });
            }else{
                this.setState({
                    [id] :dateObj.format('DD-MM-YYYY')
                });
            }

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };

    handleCustomerPopup=()=>{
        this.setState({
            customerPopup:false

        })
    };

    onCustomerButtonClick=()=>{
        this.setState({
            customerPopup:false

        })
    }

    handlePackagePopup=()=>{
        this.setState({
            packagePopUp:false
        })
    };

    handlecheckboxPopup=()=>{
        var itemlist=this.state.itemsList;
        if(this.state.checkboxId!==null)
        itemlist[this.state.checkboxId].isEnabled=false
        this.setState({
            checkBoxPopUp:false,
            checkboxId:null,
            itemsList:itemlist
        })
    };
    getData = () => {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };


    onChangeValues = (event,type) => {
        var value = event.target.value;
        //console.log("val "+value);
        if(type==='AN'){
            if (/[^0-9a-zA-Z\s]/.test(value)){
                value=this.state[event.target.id]
            }else
                this.setState({
                    [event.target.id]: value
                });
        }else if(type==='N'){
            if (/[^0-9]/.test(value)){
                value=this.state[event.target.id]
            }else
                this.setState({
                    [event.target.id]: value
                });
        }else if(type==='D'){
            this.setState({
                [event.target.id]: value
            });
        }else if(type==='PH'){

            if(/[^0-9]/.test(value) ){
                this.basicAlert("Please Enter Valid phone Number..!!");
            }else{
                if(value.length>10)
                    this.basicAlert("Please Enter Valid phone Number..!!");
                    this.setState({
                    [event.target.id]: value
                });
            }

        } else{
            this.setState({
                [event.target.id]: value
            });

        }



    };

    onChangeRadioValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.name]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        if (stateKey === 'itemGroupid' || stateKey === 'itemGroupids') {
            var data = {
                "itemGroupId": selectedValue,
                "saleable": "",
                "consumable": "",
                "companyId": getCookie(cons.COMPANY_ID)
            };
            requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
        }
        if(stateKey === 'itemId' && selectedValue!==this.state.itemId){
            this.callProblemList(selectedValue);
           // this.setState({loadProblems:true});
//if(this.state.customerId!==""){
    getDataFromUrl(serverApi.GET_PACKAGE_DETAILS+"/"+this.state.customerId+"/"+this.state.itemGroupid+"/"+selectedValue,this.handlePackageDetails)

// }else{
//     this.basicAlert("Please Select Customer By entering Mobile Number..!!");
// }


        }

        this.setState({
            [stateKey]: selectedValue
        });
    };

    handlePackageDetails=(resp)=>{

        if(resp.length>0)
        this.setState({packagePopUp:true,packageDetails:resp})
    };

    handleItem = (resp) => {
        this.setState({ItemOptions: resp, loadItem: true})

    };

    afterRefreshItemGrp = (val) => {

        this.setState({
            isRefresh: val
        });
    };
    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false,
            itemGroupCode:"",
            declaration:"",
            carrierName:""
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        this.setState({
            basicAlert: null
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideAlertMessage() {
        if(this.state.isCreated){
            this.callCustomerMappingList()
        }
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            itemGroupCode:"",
            declaration:"",
            carrierName:"",
            isRefresh: false,
            isLoading: false,
            custumerPopup:false,
            checkBoxPopUp:false,
            addCustomerPopUp:false,
            packagePopUp:false,
            generatePaymentVocher:false,
            packageSkip:false,
            isDisabled:false,
            checkboxId:null,
            jobTypeId:"",
            branchId:"",
            serviceType:"",
            customerId:"",
            itemId:"",
            itemGroupid:"",
            problemId:"",
            manufacturer:"",
            serialNo:"",
            jobdescription:"",
            jobDate:"",
            primeAnalysisDate:"",
            readyDeliveryDate:"",
            closeDate:"",
            feedbackDate:"",
            status:"",
            dependencyJobId:"",
            remark:"",serviceCharge:"",slaDate:"",branchs:"",jobType:"",
            serviceTypes:"",jobNo:"",fromDates:"",toDates:"",itemGroupids:"",
            itemIds:"",problemIds:""


        });



    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {amount,customerId,customerHdrId,fromDate,packageHdrId,paid,status,toDate,hdrId}=this.state;
            var data = {
                "hdrId":hdrId,
                "amount": amount,
                "customerHdrId":customerHdrId,
                "fromDate": fromDate,
                "packageHdrId": packageHdrId,
                "paid": paid,
                "status": status,
                "toDate": toDate,
                "activeStatus": 1,
                "updatedBy":getCookie(cons.USERID),
                "companyId":getCookie(cons.COMPANY_ID)
            };
            if (amount !=='' && customerHdrId !== '' && packageHdrId!='' ) {
                this.handleLoading();
                requestList(serverApi.UPDATE_CUSTOMER_MAPPING, data, this.handleAddCustomerMapping)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var list=[];
            const {branchId,itemsList,customerId,manufacturer,jobTypeId,problemId,serialNo,serviceType,jobDate,itemGroupid,itemId,jobdescription,generatePaymentVocher,
                serviceAmount,account,bankRefnumber,ledger,description,date1,paymentSource}=this.state;
            itemsList.map((prop,i)=>{
                var obj={
                    "withCarriersId":prop.itemId,
                    "declaration":prop.remark,
                    "createdBy":getCookie(cons.USERID),
                };
                if(prop.isEnabled){
                    list.push(obj)
                }

            });
            var data = {
                "jobDTO": {
                    "acYear": moment().format("YYYY"),
                    "branchId":branchId,
                    "custId": customerId,
                    "manufacturer": manufacturer,
                    "jobTypeId": jobTypeId,
                    "problemId":problemId,
                    "serialNo":serialNo,
                    "serviceType":serviceType,
                    "jobDate": jobDate,
                    "itemGroupCode": itemGroupid,
                    "itemCode": itemId,
                    "remarks": jobdescription,
                    "bankId":account===undefined?"":account,
                    "bankRefnumber":bankRefnumber===undefined?"":bankRefnumber,
                    "ledgerId":ledger,
                    "description":description===undefined?"":description,
                    "paymentSource":paymentSource===undefined?"":paymentSource,
                    "generatePaymentVocher":generatePaymentVocher,
                    "serviceChargeAmmount":serviceAmount,
                    "transactionDate":date1===undefined?"":date1,
                    "status":"1",
                    "createdBy": getCookie(cons.USERID),
                    "companyId": getCookie(cons.COMPANY_ID)

                },
                "jobCarrierslist": list
            };
            if (jobTypeId !=='' && customerId !== '' && problemId!=='' && branchId!=="" && jobTypeId!=="" && serviceType!=="" && jobDate!==""
            && itemGroupid!=="" && itemId!=="") {
                this.handleLoading();
               requestList(serverApi.ADD_JOB, data, this.handleAddJob);
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddJob = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleChange= (index )=> event => {
        this.setState({remark:""});
        var arr=this.state.itemsList;
        arr[index].isEnabled=!arr[index].isEnabled;
        this.setState({
            itemsList:arr
        });
        if(arr[index].declaration==='Y' && arr[index].isEnabled)
        this.setState({
            checkBoxPopUp:true,checkboxId:index
        })

        if(!arr[index].isEnabled)
        arr[index].remark=""

        this.setState({
            itemsList:arr
        });

    };


    componentDidMount(){
        this.callCustomerMappingList();
        this.callWithCarriers();

        this.callBranchList();
    }

    callBranchList(){
        // getDataFromUrl(serverApi.)
    }
    callProblemList(itemId){
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "itemGroupid": this.state.itemGroupid,
            "itemId":itemId,
            "problemName":""
        };
        requestList(serverApi.PROBLEM_MASTER_LIST,data,this.handleProblems)
    }
    handleProblems=(resp)=>{
        var problems = [];
        problems = resp.data.map(prop => {
            var name= prop.problemName +"^"+ prop.inwardCharges +"^"+ prop.outwardCharger;
            var obj = {
                value: prop.problemId,
                label: prop.problemName,
                name: name
            };
            return obj;
        });
        this.setState({problemList:problems,loadProblems:true});
    };


    callWithCarriers(){
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "itemGroupCode":""

        }
        requestList(serverApi.CARRIER_MASTER_LIST,data,this.handleCarriersList)
    }
    handleCarriersList=(resp)=>{
        var list=[];
        resp.data.map((prop,key)=>{
            var val={
                id:0,
                itemId:prop.hdrId,
                itemName:prop.carrierName,
                declaration:prop.declaration,
                remark:"",
                isEnabled:false
            };
            // if(this.state.updateView){
            //     this.state.itemsList.map((o,i)=>{
            //
            //         if(o.itemJuteCode===val.itemId+''){
            //             val.isEnabled=true;
            //             val.id=o.id
            //         }
            //     })
            // }

                list.push(val)
        });
        this.setState({
            itemsList:list
        });
        // this.handleLoading();

    }


    callCustomerMappingList(){
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "acYear": moment().format('YYYY'),
            "branchId": this.state.branchs,
            "fromDate": this.state.fromDates,
            "itemCode":  this.state.itemIds,
            "itemGroupCode":  this.state.itemGroupids,
            "jobId":  this.state.jobNo,
            "problemId": this.state.problemIds,
            "serviceType": this.state.serviceTypes,
            "status":  this.state.status,
            "toDate":  this.state.toDates,
            "jobTypeId":  this.state.jobType,

        }
        requestList(serverApi.JOBS_LIST,data,this.handleList)
    }
    handleList=(resp)=>{
        this.setState({tableList:resp,isRefresh:true});
    };


    getCustomerDetails=(phone)=>{
        this.callCustomerList(phone);

    }
    callCustomerList(phone){
        // if(this.state.customer!==undefined && phone!=="" ){
            var data = {
                "companyId": getCookie(cons.COMPANY_ID),
                "contactNo":this.state.customer===""?phone:this.state.customer
            };
            requestList(serverApi.CUSTOMER_MASTER_BY_CONTACT,data,this.handelCustomer)
        // }else{
        //     this.basicAlert("Please Enter Registered Mobile Number..!! ")
        // }

    }


    handelCustomer=(resp)=>{

        var data=resp.data  ,customerList=[];
            data.forEach(prop=>{
                var obj={
                    id:prop.id,
                    contactNo:prop.contactNo,
                    emailId:prop.emailId,
                    name:prop.name,
                   ledger:prop.ledgerId
                };
                customerList.push(obj)
            });
            this.setState({
                customerPopup:true,customerList:customerList,customer:this.state.contactNo,packagePopUp:false,isRefresh:true})

    };
    CheckBoxButtonClick=()=>{
        var checkarry=this.state.itemsList;
        checkarry[this.state.checkboxId].remark=this.state.remark
        this.setState({
            itemList:checkarry,checkBoxPopUp:false

        });
    };

    createCustomer=()=> {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.LEDGER_GROUP_MASTER,data,this.handleLedgerGroup);


        this.setState({
            customerPopup:false,
            addCustomerPopUp: true,
            "name":"",
            "contactPerson":"",
            "contactNo":"",
            "emailId":"",
            "address":"",
            ledgerGroup:""
        })

    };


    handleLedgerGroup =(resp)=>{
        const lgoptions=[];
        var ledger_data=resp.data;
        if (ledger_data !== null || ledger_data !== undefined) {
            ledger_data.forEach(r => {
                const {name, id} = r;
                lgoptions.push({
                    value: r.id,
                    label: r.name,
                    name:r.name
                })
            })
        }
        this.setState({ledger_data:lgoptions});
    }
    closeCustomerPopUp=()=> {
        this.setState({addCustomerPopUp: false})

    };
    onCreateCustomerClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {  name, contactPerson, contactNo, emailId,address, ledgerGroup}=this.state;
            var data = {
                "name":name,
                "contactPerson":contactPerson,
                "contactNo":contactNo,
                "emailId":emailId,
                "address":address,
                "ledgerGroup":ledgerGroup,
                "createdBy":getCookie(cons.USERID),
                "companyId": getCookie(cons.COMPANY_ID)
            };

            if (name !== ""&& contactPerson!==""&& contactNo!==""&&address!=="" ) {
                this.handleLoading();
                requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handlecreateCustomer)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handlecreateCustomer = (resp) => {
        if (resp.status) {
           // this.showMessage(resp.message, resp.status)
            this.setState({addCustomerPopUp:false,customer:""})
            this.getCustomerDetails(this.state.contactNo);

        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    onSkipButtonClick =()=>{
        this.setState({
            packageSkip:true,packagePopUp:false,manufacturer:"",serialNo:""
        })
    }

    handleRadioChange(event) {
        this.setState({ selectedValue: event.target.value });
    }
    setIsRefresh=(key,val)=>{
        this.setState({[key]:val})
    };

    render() {
        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract(1, 'day');
        var tomorrow = Datetime.moment().add( 0, 'day' );

        var isBackDate=getConfigValue(configNames.BACK_DATE_ALLOWABLE);
        var isfutureDate=getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);
        var valid = function( current ){
            if(isBackDate==="2"&&isfutureDate==="2")
                return current.isAfter( yesterday )&&current.isBefore( tomorrow );
            else if(isfutureDate==="2"&&isBackDate==="1"){
                return current.isBefore( tomorrow );
            } else if(isfutureDate==="1"&&isBackDate==="2"){
                return current.isAfter( yesterday );
            }
            else
                return true;
        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.addCustomerPopUp ?
                    <Popup
                        onClickSave={() => this.onCreateCustomerClick()}
                        closePopup={() => this.closeCustomerPopUp()}
                        title="Post Temperature"
                        isShowSave={true}
                        isShowCancel={true}
                        save_btn_txt={"Add"}
                        cancel_button_text={"Cancel"}
                        content={

                            <GridContainer>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={4}>
                                            <InputLabel className={classes.label}>
                                                Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={8}>
                                    <TextField
                                        id="name"
                                        value={this.state.name}
                                        className={classes.textField}
                                        onChange={(event)=>{
                                            this.onChangeValues(event,'AN')
                                        }}
                                        fullWidth={true}
                                        variant="outlined"
                                    />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={4}>
                                            <InputLabel className={classes.label}>
                                                Contact Person<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={8}>
                                    <TextField
                                        id="contactPerson"
                                        value={this.state.contactPerson}
                                        className={classes.textField}
                                        onChange={(event)=>{
                                            this.onChangeValues(event,'AN')
                                        }}
                                        fullWidth={true}
                                        variant="outlined"
                                    />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={4}>
                                            <InputLabel className={classes.label}>
                                                Contact No<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={8}>
                                    <TextField
                                        id="contactNo"
                                        value={this.state.contactNo}
                                        className={classes.textField}
                                        onChange={(event)=>{
                                            this.onChangeValues(event,'AN')
                                        }}
                                        fullWidth={true}
                                        variant="outlined"
                                    />
                                        </ItemGrid>
                                    </GridContainer>

                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={4}>
                                            <InputLabel className={classes.label}>
                                                Billing Address<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={8}>
                                    <TextField
                                        id="address"
                                        value={this.state.address}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                    />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={4}>
                                            <InputLabel className={classes.label}>
                                                Email ID
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={8}>
                                    <TextField
                                        id="emailId"
                                        value={this.state.emailId}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                    />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>

                                {this.state.ledger_data!==undefined?
                                <ItemGrid xs={12} md={6}  >
                                    <GridContainer>
                                        <ItemGrid xs={12} md={4}>
                                    <InputLabel className={classes.label}>
                                        Ledger Group
                                    </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={8}>
                                    <SelectboxTemp
                                        // isDisabled={this.state.updateView}
                                        defValue={this.state.ledgerGroup}
                                        name={"ledgerGroup"}
                                        value={this.state.ledgerGroup}
                                        onSelect={this.onSelect}
                                        staticData={this.state.ledger_data}
                                        stateKey="ledgerGroup"
                                    />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                    :null}

                            </GridContainer>
                        }
                    >
                    </Popup> :null}
                {this.state.customerPopup ?
                    <Popup
                        onClickSave={() => this.onCustomerButtonClick()}
                        closePopup={() => this.handleCustomerPopup()}
                        title="Post Temperature"
                        isShowSave={true}
                        isShowCancel={true}
                        save_btn_txt={"Add"}
                        cancel_button_text={"Cancel"}
                        content={

                            <GridContainer>
                                {this.state.customerList.length>0?this.state.customerList.map((prop,key)=>{
                                        return(
                                            <ItemGrid xs={12} sm={12}>

                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={this.state.selectedValue===prop.id+""}
                                                            onChange={(event)=>{
                                                                this.setState({
                                                                    selectedValue: event.target.value,
                                                                    customerId:prop.id,
                                                                    ledger:prop.ledger,
                                                                    reloadLedger:true});
                                                            }}
                                                            value={prop.id}
                                                            name="radio button demo"
                                                            aria-label="A"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label
                                                    }}
                                                    label={prop.name +" | "+ prop.emailId +" | "+ prop.contactNo}
                                                />
                                            </ItemGrid>
                                        )
                                    })
                                    :
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={12}>
                                            <span> No registered Customer found on the mobile number. Please click on Plus Icon and create Customer. </span>
                                            <IconButton
                                                onClick={this.createCustomer}
                                                color="success" right={true}>
                                                <ExpandMoreIcon/>
                                            </IconButton>
                                        </ItemGrid>
                                    </GridContainer>
                                }

                            </GridContainer>


                        }
                    >
                    </Popup> :null}
                {this.state.packagePopUp ?
                    <Popup
                        onClickSave={() => this.onSkipButtonClick()}
                        closePopup={() => this.handlePackagePopup()}
                        title="Post Temperature"
                        isShowSave={true}
                        isShowCancel={true}
                        save_btn_txt={"Skip"}
                        cancel_button_text={"Cancel"}
                        content={

                                <GridContainer>
                                    {this.state.packageDetails.length>0?this.state.packageDetails.map((prop,key)=>{
                                            return(
                                                <ItemGrid xs={12} sm={12}>

                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={this.state.selectedPackageValue===prop.dtlId+""}
                                                                onChange={(event)=>{
                                                                    this.setState({
                                                                        selectedPackageValue: event.target.value,
                                                                        manufacturer:prop.make,
                                                                        serialNo:prop.serialNo,
                                                                        packagePopUp:false
                                                                        });
                                                                }}
                                                                value={prop.dtlId}
                                                                name="radio button demo"
                                                                aria-label="A"
                                                                icon={
                                                                    <FiberManualRecord
                                                                        className={classes.radioUnchecked}
                                                                    />
                                                                }
                                                                checkedIcon={
                                                                    <FiberManualRecord
                                                                        className={classes.radioChecked}
                                                                    />
                                                                }
                                                                classes={{
                                                                    checked: classes.radio
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label
                                                        }}
                                                        label={prop.make +" | "+ prop.serialNo }
                                                    />
                                                </ItemGrid>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </GridContainer>
                        }
                    >
                    </Popup> :null}
                {this.state.checkBoxPopUp ?
                    <Popup
                        onClickSave={() => this.CheckBoxButtonClick()}
                        closePopup={() => this.handlecheckboxPopup()}
                        title="Post Temperature"
                        isShowSave={true}
                        isShowCancel={true}
                        save_btn_txt={"Add"}
                        cancel_button_text={"Cancel"}
                        content={

                            <GridContainer>
                                <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={2}>
                                            <InputLabel className={classes.label}>
                                                Remark
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={10}>
                                    <TextField
                                        id="remark"
                                        value={this.state.remark}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                    />
                                        </ItemGrid>
                                    </GridContainer>

                                </ItemGrid>
                            </GridContainer>

                        }
                    >
                    </Popup> :null}
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}

                                    <ItemGrid xs={12} sm={4} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Job Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <FormControl variant="outlined" fullWidth>
                                            <Datetime
                                                isValidDate={valid}
                                                closeOnSelect={true}
                                                timeFormat={false}
                                                onChange={(dateObj) => {
                                                    this.timeChanged(dateObj, "jobDate")
                                                }}
                                                formControlProps={
                                                    {height:"10px",inlineBlock: "none"}
                                                }

                                                inputProps={{
                                                    id:"jobDate",
                                                    value:this.state.jobDate,
                                                    autoComplete: 'off'
                                                }}
                                            />
                                        </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} md={4} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Branch<span style={{color: "red", fontSize: "12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <SelectboxTemp
                                            defValue={this.state.branchId}
                                            onSelect={this.onSelect}
                                            //  getName={this.getQualityName}
                                            stateKey="branchId"
                                            // isRefresh={this.state.loadQuality}
                                            // setIsRefresh={this.afterRefreshQuality}
                                            url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                            value={this.state.branchId}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Job Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <SelectboxTemp
                                            defValue={this.state.jobTypeId}
                                            id={"jobTypeId"}
                                            onSelect={this.onSelect}
                                            stateKey="jobTypeId"
                                            staticData={JobType}
                                            value={this.state.jobTypeId}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Service Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <SelectboxTemp
                                            defValue={this.state.serviceType}
                                            id={"serviceType"}
                                            onSelect={this.onSelect}
                                            stateKey="serviceType"
                                            staticData={ServiceType}
                                            value={this.state.serviceType}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                                <InputLabel className={classes.label}>
                                                    Customer Phone<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <TextField
                                            id="customer"
                                            value={this.state.customer}
                                            className={classes.textField}
                                            onChange={(event)=>{this.onChangeValues(event,"PH")}}
                                            fullWidth={true}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getCustomerDetails}
                                                                />
                                                                </span>
                                                    </InputAdornment>
                                                ),
                                                placeholder:"Enter Phone and click on search",
                                                maxLength:"10"
                                            }}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Variant<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <SelectboxTemp
                                            defValue={this.state.itemGroupid}
                                            id={"itemGroupid"}
                                            onSelect={this.onSelect}
                                            getName={(name)=>{
                                                this.setState({itemGroupName:name})
                                            }}
                                            isRefresh={this.state.isRefresh}
                                            stateKey="itemGroupid"
                                            setIsRefresh={this.afterRefreshItemGrp}
                                            url={serverApi.SEARCH_ITEM_GROUPS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                            value={this.state.itemGroupid}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={6} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Product<span style={{
                                            color: "red",
                                            fontSize: "12px"
                                        }}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <SelectboxTemp
                                            defValue={this.state.itemId}
                                            id={"itemId"}
                                            onSelect={this.onSelect}
                                            getName={this.getitemDetails}
                                            isRefresh={this.state.loadItem}
                                            stateKey="itemId"
                                            setIsRefresh={(val)=>{
                                                this.setState({ loadItem:val})
                                            }}
                                            staticData={this.state.ItemOptions}
                                            //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                            value={this.state.itemId}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                        <InputLabel className={classes.label}>
                                            Problem<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <SelectboxTemp
                                            defValue={this.state.problemId}
                                            id={"problemId"}
                                            onSelect={this.onSelect}
                                            getName={(name)=>{
                                                var values=name.split('^');
                                                // if(!this.state.updateView)
                                                if(this.state.serviceType==="IN"){
                                                    this.setState({
                                                        serviceAmount:values[1]==='null'?"":values[1],
                                                        description:"Paid towards  "+values[0],

                                                    })
                                                }else{
                                                    this.setState({
                                                        serviceAmount:values[1]==='null'?"":values[1],
                                                        description:"Paid towards  "+values[0],
                                                    })
                                                }

                                            }}
                                            stateKey="problemId"

                                            isRefresh={this.state.loadProblems}
                                            setIsRefresh={(val)=>{
                                                this.setState({loadProblems:false})
                                            }}
                                           // url={serverApi.PROBLEM_MASTER_LIST}
                                            staticData={this.state.problemList}
                                            value={this.state.problemId}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>



                                    <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                                <InputLabel className={classes.label}>
                                                    Manufacturer
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <TextField
                                            labelText={"Manufacturer"}
                                           // isMandatory={true}
                                            id="manufacturer"
                                            value={this.state.manufacturer}
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                        />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                                <InputLabel className={classes.label}>
                                                    Serial No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <TextField
                                            id="serialNo"
                                            value={this.state.serialNo}
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                        />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                                <InputLabel className={classes.label}>
                                                    Description
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                        <TextField
                                            id="jobdescription"
                                            value={this.state.jobdescription}
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                        />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>


                                    <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>

                                        <InputLabel className={classes.label}>
                                            Carriers<span style={{color: "red", fontSize: "12px"}}>*</span>
                                        </InputLabel>
                                        <GridContainer>
                                            {this.state.itemsList.map((prop,key)=>{
                                                return(
                                                    <ItemGrid xs={12} sm={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    tabIndex={-1}
                                                                    value={prop.isEnabled?1:0}
                                                                    checked={prop.isEnabled?1:0}
                                                                    onChange={
                                                                        this.handleChange(key)
                                                                    }
                                                                    // onClick={() => this.handleToggle(prop)}
                                                                    checkedIcon={
                                                                        <Check className={classes.checkedIcon} />
                                                                    }
                                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                                    classes={{
                                                                        checked: classes.checked
                                                                    }}
                                                                    style={{marginLeft:10}}

                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.menulabel
                                                            }}
                                                            label={prop.itemName}
                                                        />
                                                        <br/>
                                                        <span>{prop.remark}</span>
                                                    </ItemGrid>


                                                )
                                            })}
                                        </GridContainer>
                                    </ItemGrid>

                                    {this.state.packageSkip ?

                                    <ItemGrid xs={12}  style={{marginBottom: "10px"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    value={this.state.generatePaymentVocher}
                                                    checked={this.state.generatePaymentVocher}
                                                    onChange={()=>{
                                                      //  console.log("hrmsFullAccess",this.state.generatePaymentVocher);
                                                        this.setState({generatePaymentVocher:!this.state.generatePaymentVocher})
                                                    }}
                                                    // onClick={() => this.handleToggle(prop)}
                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon} />
                                                    }
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked
                                                    }}
                                                    style={{marginLeft:10}}

                                                />
                                            }
                                            classes={{
                                                label: classes.menulabel
                                            }}
                                            label={"Generate Payment Vocher for Rs."+this.state.serviceAmount+" as Minimum charges"}
                                        />

                                    </ItemGrid>
                                        :null}

                                    {this.state.generatePaymentVocher?
                                        <PaymentComponent
                                            reloadLedger={this.state.reloadLedger}
                                            setIsRefresh={this.setIsRefresh}
                                            divSize={4}
                                            isDisabled={this.state.isDisabled}
                                            ledger={this.state.ledger}
                                            account={this.state.account}
                                            date1={this.state.date1}
                                            description={this.state.description}
                                            amount={this.state.amount}
                                            bankRefnumber={this.state.bankRefnumber}
                                            paymentSource={this.state.paymentSource}
                                            onSelect={this.onSelect}
                                            onChange={this.onChangeValues}
                                            onTimeChange={this.timeChanged}
                                        />
                                        :null}


                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Branch
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.branchs}
                                                        onSelect={this.onSelect}
                                                        stateKey="branchs"
                                                        url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                        value={this.state.branchs}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Job Type
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.jobType}
                                                        onSelect={this.onSelect}
                                                        stateKey="jobType"
                                                        staticData={JobType}
                                                        value={this.state.jobType}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>

                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Service Type
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.serviceTypes}
                                                        id={"serviceTypes"}
                                                        onSelect={this.onSelect}
                                                        stateKey="serviceTypes"
                                                        staticData={ServiceType}
                                                        value={this.state.serviceTypes}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                            <InputLabel className={classes.label}>
                                                                Job No
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <TextField
                                                        id="jobNo"
                                                        value={this.state.jobNo}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        From Date
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "fromDates")
                                                            }}
                                                            formControlProps={
                                                                {height:"10px",inlineBlock: "none"}
                                                            }

                                                            inputProps={{
                                                                id:"fromDates",
                                                                value:this.state.fromDates,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        To Date
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "toDates")
                                                            }}
                                                            formControlProps={
                                                                {height:"10px",inlineBlock: "none"}
                                                            }

                                                            inputProps={{
                                                                id:"toDates",
                                                                value:this.state.toDates,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>



                                                <ItemGrid xs={12} sm={4}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Varaint
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.itemGroupids}
                                                        id={"itemGroupids"}
                                                        onSelect={this.onSelect}
                                                        getName={(name)=>{
                                                            this.setState({itemGroupName:name})
                                                        }}
                                                        // isRefresh={this.state.loadItemGroup}
                                                        stateKey="itemGroupids"
                                                        // setIsRefresh={this.afterRefreshItemGrp}
                                                        url={serverApi.SEARCH_ITEM_GROUPS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                        value={this.state.itemGroupid}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={6} sm={4}>
                                                    <GridContainer>
                                                    <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Product
                                                    </InputLabel>
                                                    </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.itemIds}
                                                        id={"itemIds"}
                                                        onSelect={this.onSelect}
                                                        getName={this.getitemDetails}
                                                        isRefresh={this.state.loadItem}
                                                        stateKey="itemIds"
                                                        setIsRefresh={(val)=>{
                                                            this.setState({ loadItem:val})
                                                        }}
                                                        staticData={this.state.ItemOptions}
                                                        //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                        value={this.state.itemIds}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                {this.state.problemList!==undefined?
                                                <ItemGrid xs={12} md={4} >
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Problem
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.problemIds}
                                                        onSelect={this.onSelect}
                                                        stateKey="problemIds"
                                                        staticData={this.state.problemList}
                                                        value={this.state.problemIds}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>:null}

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                    <InputLabel className={classes.label}>
                                                        Status
                                                    </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>

                                                    <SelectboxTemp
                                                        defValue={this.state.status}
                                                        onSelect={this.onSelect}
                                                        stateKey="status"
                                                        staticData={JobStatus}
                                                        value={this.state.status}
                                                    />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>

                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <IconButton
                                                    onClick={this.handleCreate}
                                                    color="success" right={true}>
                                                    <ExpandMoreIcon/>
                                                </IconButton>

                                            </GridContainer>
                                            <Button color="primary" right={true}
                                                    onClick={this.handleSeacrh}>Search</Button>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                //url={serverApi.LEAVE_TYPE_DATATABLE+"/"+getCookie(cons.COMPANY_ID)}
                                                searchData={this.getData()}
                                                staticData={this.state.tableList}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showDelete={false}
                                                // onDeleteClick={this.onDeleteParamater}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    inputAdornmentIcon: {
        color: "#1e4646",
        cursor: 'pointer'

    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,

};
export default withStyles(extendedFormsStyle)(Jobs);
