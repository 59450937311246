import Cookies from "universal-cookie";
import cons, { serverConfig, serverApi, getTenantId } from "../helper/Consts";
import { post } from "axios";
import { getCookie, removeCookies } from "../helper/Cookies";

const ck = new Cookies();

/* export function loginRequest(taskCode, data, callback) {
    var request = {
        taskCode: taskCode,
        payloadSize: Object.keys(data).length,
        data: data,
    };
    console.log("request--->" + JSON.stringify(request));

    fetch(serverConfig.SERVER_URL + 'vowjute/portalApi', {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3030',
            'Content-Type': 'application/json'
        },

    }).then((response) => response.json())
        .then(json => {
            console.log('Success:', JSON.stringify(json));
            callback(json);
        })
        .catch((error) => {
            console.log(error);
           // console.error('Error:', error);
        });
}
export function request(taskCode, data, callback) {
    var request = {
        taskCode: taskCode,
        payloadSize: Object.keys(data).length,
        data: data,
        userId:ck.get(cons.USERID),
        cipher:ck.get(cons.CIPHER)
    };
    console.log("logout request---> " + JSON.stringify(request));

    fetch(serverConfig.SERVER_URL + 'vowjute/portalApi', {
        method: 'POST',
        //  mode: 'no-cors',
        body: JSON.stringify(request),
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3030',
            //           'Content-Type': 'application/json'
            'Content-Type': 'application/json'
        },

    }).then((response) => response.json())
        .then(json => {
            console.log('Success:', JSON.stringify(json));
            callback(json);
        })
        .catch((error) => {
            console.log(error);
        });
}
export function getDataFromUrl(url,callback) {
    console.log("url ",url);
    return fetch(url)

        .then((response) => response.json())
        .then((responseJson) => {
            console.log('Success:', JSON.stringify(responseJson));
            if(responseJson.status !==400)
            callback(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function loginUrl(url,callback) {
    console.log('url:', serverConfig.SERVER_URL +url);
    return fetch(serverConfig.SERVER_URL +url)

        .then((response) => response.json())
        .then((responseJson) => {
            console.log('Successresponse:', JSON.stringify(responseJson));

            callback(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}
export function requestList(url,data,callback) {


    console.log("request--->" + JSON.stringify(data));
    console.log(url);
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': 'localhost:3000',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((response) => response.json())
        .then(json => {
            console.log("resp "+JSON.stringify(json));
            callback(json);
        })
        .catch((error) => {
            console.log(error);
        });
}
export function oAuthList(url, data, callback) {
    // var url = serverApi.OAUTH_LOGIN;
    console.log(data.password);
    var values =
        "grant_type=" +
        "password" +
        "&username=" +
        data.userName +
        "&password=" +
        data.password;

    console.log("request",values);
    console.log("url",url);
    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic dHV0b3JpYWxzcG9pbnQ6bXktc2VjcmV0LWtleQ=="
        },
        body: values
    })
        .then(response => response.json())
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            console.log(error);
        });
}
export function getUserDetailsFromUserName(uname,callback) {
    return fetch(serverApi.API_USER+serverApi.GET_USR_BY_USRNM +uname)

        .then((response) => response.json())
        .then((responseJson) => {
            console.log('Success:', JSON.stringify(responseJson));

            callback(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}
export function getUserGroupDetailsById(id,callback) {
    console.log('getUserMenuById:', serverApi.API_USER +serverApi.GET_USER_GROUP+id);
    return fetch(serverApi.API_USER +serverApi.GET_USER_GROUP+id)
        .then((response) => response.json())
        .then((json) => {
            callback(json);
            //  console.log('getUserMenuById:', JSON.stringify(json));
        })
        .catch((error) => {
            console.error(error);
        });

}
export function getUserMenuById(id,callback) {
    return fetch(serverApi.API_USER +serverApi.GET_MENU_BY_GROUP+id)
        .then((response) => response.json())
        .then((json) => {
            callback(json);
          //  console.log('getUserMenuById:', JSON.stringify(json));
        })
        .catch((error) => {
            console.error(error);
        });

}
export function getUsersubMenuById(id,callback) {
    console.log("menusUrl",serverApi.API_USER +serverApi.GET_MENUS_SUBMENUS_BY_GROUP+id);

    return fetch(serverApi.API_USER +serverApi.GET_MENUS_SUBMENUS_BY_GROUP+id)
        .then((response) => response.json())
        .then((json) => {
           // console.log('getUsersubMenuById:', JSON.stringify(json));

            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });

}
export function requestAppToken(data, callback) {
    var request = {
        loginToken: data
    };
    console.log("requestAppToken---> " + JSON.stringify(request));

    fetch(serverConfig.API_PO + serverConfig.ADD_TOKEN, {
        method: 'POST',
        //  mode: 'no-cors',
        body: JSON.stringify(request),
        headers: {
            Accept: 'application/json',
           // 'Access-Control-Allow-Origin': 'http://localhost:3030',
            //           'Content-Type': 'application/json'
            'Content-Type': 'application/json'
        },

    }).then((response) => response.json())
        .then(json => {
            console.log('AppToken:', JSON.stringify(json));
            callback(json);
        })
        .catch((error) => {
            console.log(error);
        });
}
export const uploadInvoice = (url, val,uId, file,cb) => {
   // const url = serverConfig.SERVER_URL + '/po_api-v2/api/v1/uploadBillPassDoc';
    console.log("type",val);

    const formData = new FormData();
    formData.append('imageFile', file);
    formData.append('billId', uId);
    formData.append('docFile', val);
    formData.append("createdBy",getCookie(cons.USER_NAME));
   // formData.append('imgType', type);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    post(url, formData, config).then(
        function (response) {
          //  console.log("response",response+"  faa");
            cb(response.data);
        }
    ).catch(function (err) {
        console.log(err);
    })
};
export const uploadProfilePic = (uId, file,cb) => {
    const url = serverApi.PROFILE_PIC_UPLOAD;
  //console.log("imageUrl",file);
    const formData = new FormData();
    formData.append('profilePic', file);
    formData.append('id', uId);
    formData.append("createdBy",getCookie(cons.USER_NAME));
    formData.append('cipher',getCookie(cons.CIPHER));
    // formData.append('imgType', type);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    post(url, formData, config).then(
        function (response) {
            console.log("response",response+"  faa");
            cb(response.data);
        }
    ).catch(function (err) {
        console.log(err);
    })
};

export const uploadFormdataWithImage = (url, formData,cb) => {
   // const Url = url;
   // console.log("formData",formData);

    console.log("url", url);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    post(url, formData, config).then(
        function (response) {
            console.log("response",response+"  faa");
            cb(response.data);
        }
    ).catch(function (err) {
        console.log(err);
    })
};
//
export const uploadFormdata = (url, formData,cb) => {
    // const Url = url;
    console.log("formData",formData);

    console.log("url",url);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    post(url, formData, config).then(
        function (response) {
            console.log("response",response+"  faa");
            cb(response.data);
        }
    ).catch(function (err) {
        console.log(err);
    })
};



export const postMessage = (userId, distId, pDesc, imgType, file) => {
    const url = serverConfig.SERVER_URL + 'media/postMessage';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);
    formData.append('distId', distId);
    formData.append('pDesc', pDesc);
    formData.append('imgType', imgType);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    return post(url, formData, config);
};*/

export function getDataFromUrl(url, callback) {
	console.log("url ", url);
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	return fetch(url, {
		headers: headers,
	})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log("Success:", JSON.stringify(responseJson));
			if (
				responseJson.error === "unauthorized" ||
				responseJson.error === "invalid_token"
			) {
				removeCookies();
				window.location.reload();
			}
			if (responseJson.status !== 400) callback(responseJson);
		})
		.catch((error) => {
			console.error(error);
		});
}
export function getFile(url, callback) {
	console.log("url ", url);
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	return fetch(url, {
		headers: headers,
	})
		.then((response) =>
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = "PayPeriod.xlsx";
				a.click();
			})
		)

		.catch((error) => {
			console.error(error);
		});
}

export function postDownload(url, data, callback) {
	console.log("request--->" + JSON.stringify(data));
	console.log(url);
	console.log(getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN));
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
		Accept: "application/json",
		// "Access-Control-Allow-Origin": "localhost:3000",
		"Content-Type": "application/json",
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": getTenantId(window.location.hostname),
			Accept: "application/json",
			// "Access-Control-Allow-Origin": "localhost:3000",
			"Content-Type": "application/json",
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	})
		.then((response) =>
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = "PayRoll.xlsx";
				a.click();
			})
		)

		.catch((error) => {
			console.error(error);
		});
}

export function requestList(url, data, callback, IsCompanyId) {
	console.log("request--->" + JSON.stringify(data) + "---" + data.tenantId);
	console.log(url);
	console.log(getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN));
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
		Accept: "application/json",
		// "Access-Control-Allow-Origin": "localhost:3000",
		"Content-Type": "application/json",
	};
	var tenant = "";
	if (data.tenantId !== undefined) {
		tenant = data.tenantId;
	} else {
		tenant = getTenantId(window.location.hostname);
	}
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": tenant,
			Accept: "application/json",
			// "Access-Control-Allow-Origin": "localhost:3000",
			"Content-Type": "application/json",
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	})
		.then((response) => response.json())
		.then((json) => {
			console.log("resp " + JSON.stringify(json));
			if (json.error === "unauthorized" || json.error === "invalid_token") {
				//  removeCookies();
				//  window.location.reload();
			}
			callback(json);
		})
		.catch((error) => {
			console.log(error);
		});
}

export function oAuthList(url, data, callback) {
	// var url = serverApi.OAUTH_LOGIN;
	console.log(data.password);
	var values =
		"grant_type=" +
		"password" +
		"&username=" +
		data.userName +
		"&password=" +
		data.password;
	console.log("request", values);
	var headers = {
		Authorization: "Basic dHV0b3JpYWxzcG9pbnQ6bXktc2VjcmV0LWtleQ==",
		// "Access-Control-Allow-Origin": "localhost:3000",
		"Content-Type": "application/x-www-form-urlencoded",
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: "Basic dHV0b3JpYWxzcG9pbnQ6bXktc2VjcmV0LWtleQ==",
			"X-TenantId": getTenantId(window.location.hostname),
			//  Accept: "application/json",
			// "Access-Control-Allow-Origin": "localhost:3000",
			"Content-Type": "application/x-www-form-urlencoded",
		};
	}
	console.log("request", headers);

	fetch(url, {
		method: "POST",
		headers: headers,
		body: values,
	})
		.then((response) => response.json())
		.then((responseJson) => {
			if (
				responseJson.error === "unauthorized" ||
				responseJson.error === "invalid_token"
			) {
				removeCookies();
				window.location.reload();
			}
			callback(responseJson);
			console.log("resp", responseJson);
		})
		.catch((error) => {
			console.log(error);
		});
}
export function getUsersubMenuById(id, callback) {
	console.log(
		"menusUrl",
		serverApi.API_USER + serverApi.GET_MENUS_SUBMENUS_BY_GROUP + id
	);
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	return fetch(
		serverApi.API_USER + serverApi.GET_MENUS_SUBMENUS_BY_GROUP + id,
		{
			headers: headers,
		}
	)
		.then((response) => response.json())
		.then((json) => {
			// console.log('getUsersubMenuById:', JSON.stringify(json));
			if (json.error === "unauthorized" || json.error === "invalid_token") {
				removeCookies();
				window.location.reload();
			}

			callback(json);
		})
		.catch((error) => {
			console.error(error);
		});
}
export const uploadInvoice = (url, val, uId, file, cb, fileName) => {
	// const url = serverConfig.SERVER_URL + '/po_api-v2/api/v1/uploadBillPassDoc';
	console.log("type", uId, file, val);

	const formData = new FormData();
	formData.append("imageFile", file);
	formData.append("billId", uId);
	formData.append("docFile", val);
	formData.append("fileName", fileName);
	formData.append("createdBy", getCookie(cons.USER_NAME));
	// formData.append('imgType', type);
	var headers = {
		"Content-Type": "multipart/form-data",
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			"X-TenantId": getTenantId(window.location.hostname),
			// Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"Content-Type": "multipart/form-data",
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	const config = {
		headers: headers,
	};
	post(url, formData, config)
		.then(function(response) {
			//  console.log("response",response+"  faa");
			/* if(response.error==='unauthorized'||response.error==='invalid_token'){
                removeCookies();
                window.location.reload();
            }*/

			cb(response.data);
		})
		.catch(function(err) {
			console.log(err);
		});
};
export const uploadProfilePic = (uId, file, cb) => {
	const url = serverApi.PROFILE_PIC_UPLOAD;
	//console.log("imageUrl",file);
	const formData = new FormData();
	formData.append("profilePic", file);
	formData.append("id", uId);
	formData.append("createdBy", getCookie(cons.USER_NAME));
	formData.append("cipher", getCookie(cons.CIPHER));
	// formData.append('imgType', type);
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
		"Content-Type": "multipart/form-data",
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"Content-Type": "multipart/form-data",
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	const config = {
		headers: headers,
	};
	post(url, formData, config)
		.then(function(response) {
			console.log("response", response + "  faa");
			if (
				response.error === "unauthorized" ||
				response.error === "invalid_token"
			) {
				removeCookies();
				window.location.reload();
			}

			cb(response.data);
		})
		.catch(function(err) {
			console.log(err);
		});
};

export const uploadFormdataWithImage = (url, formData, cb) => {
	// const Url = url;
	// console.log("formData",formData);

	console.log("url", url);
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
		"Content-Type": "multipart/form-data",
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"Content-Type": "multipart/form-data",
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	const config = {
		headers: headers,
	};
	post(url, formData, config)
		.then(function(response) {
			console.log("response", JSON.stringify(response) + "  ");
			if (
				response.error === "unauthorized" ||
				response.error === "invalid_token"
			) {
				removeCookies();
				window.location.reload();
			}

			cb(response.data);
		})
		.catch(function(err) {
			console.log(err);
		});
};

export const uploadFile = (
	fileName,
	file,
	fileExtension,
	sourceMenu,
	displayName,
	taskUniqueId,
	cb,
	ebId
) => {
	// const url = serverConfig.SERVER_URL + '/po_api-v2/api/v1/uploadBillPassDoc';

	const formData = new FormData();
	formData.append("fileName", fileName);
	formData.append("fileUpload", file);
	formData.append("fileExtension", fileExtension);
	formData.append("sourceMenu", sourceMenu);
	formData.append("displayName", displayName);
	formData.append("taskUniqueId", taskUniqueId);
	formData.append("createdBy", getCookie(cons.USERID));
	if (ebId) formData.append("ebId", ebId);
	formData.append("fileCompanyId", getCookie(cons.COMPANY_ID));
	// formData.append('imgType', type);
	for (var pair of formData.entries()) {
		console.log(pair[0] + ", " + pair[1] + "");
	}
	var headers = {
		"Content-Type": "multipart/form-data",
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			"X-TenantId": getTenantId(window.location.hostname),
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"Content-Type": "multipart/form-data",
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	const config = {
		headers: headers,
	};
	post(serverApi.FILE_UPLOAD, formData, config)
		.then(function(response) {
			console.log("response", response.data + "  faa");
			/* if(response.error==='unauthorized'||response.error==='invalid_token'){
                 removeCookies();
                 window.location.reload();
             }*/

			cb(response.data);
		})
		.catch(function(err) {
			console.log(err);
		});
};
export function getFileDownload(url, name, type, callback) {
	console.log("url ", url);
	console.log("type... ", type);

	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	return fetch(url, {
		headers: headers,
	})
		.then((response) =>
			response.blob().then((blob) => {
				// let url = window.URL.createObjectURL(blob);
				var fileType = "image/" + type;
				if (type === "pdf" || type === "xlsx") fileType = "application/" + type;
				var file = new Blob([blob], { type: fileType });
				// file.name=name;
				var fileURL = URL.createObjectURL(file);
				console.log("url", fileURL);

				callback(fileURL);
				//window.open(url);
			})
		)

		.catch((error) => {
			console.error(error);
		});
}
export function deleteDataFromUrl(url, callback) {
	console.log("url ", url);
	var headers = {
		Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
	};
	if (getTenantId(window.location.hostname) !== null) {
		headers = {
			Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
			"X-TenantId": getTenantId(window.location.hostname),
			CompanyID: getCookie(cons.COMPANY_ID),
		};
	}
	return fetch(url, {
		method: "DELETE",
		headers: headers,
		body: {},
	})
		.then((response) => response)
		.then((responseJson) => {
			console.log("Success:", responseJson);
			if (
				responseJson.error === "unauthorized" ||
				responseJson.error === "invalid_token"
			) {
				removeCookies();
				window.location.reload();
			}
			if (responseJson.status === 200) {
				callback({ status: true, message: "Deleted Successfully..!!" });
			}
		})
		.catch((error) => {
			console.error(error);
		});
}
