import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";

import withStyles from "material-ui/styles/withStyles";
import {
  defaultFont,
  infoColor,
} from "../../assets/jss/material-dashboard-pro-react";

import TextField from "../../components/CustomInput/CustomTextField";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";

import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Close from "@material-ui/icons/Close";
import Dvr from "@material-ui/icons/Dvr";
import {
  configNames,
  getConfigValue,
  serverApi,
  serverConfig,
} from "../../helper/Consts";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
  uploadFormdataWithImage,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import InputAdornment from "material-ui/Input/InputAdornment";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import PrintData from "../CustumComponents/printData";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Email from "@material-ui/icons/Email";
import { ChannelTypes } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";

class JutePO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      basicAlert: null,
      isRefresh: false,
      isLoading: false,
      approveStatus: false,
      showMessagealert: false,
      fromDate: "",
      todate: "",
      poStatus: "",
      selectMukam: "",
      mukamId: "",
      supplierId: "",
      supplier_Id: "",
      broker_Id: "",
      poNo: "",
      hdrId: "",
      statusDesc: "",
      itemViewType: "poNo",
      SelectindentType: this.getDefaultindentConvertion(2),
      itemtype: "",
      PoDate: moment().format("DD-MM-YYYY"),
      expecDate:
        getCookie(cons.COMPANY_ID) === "116"
          ? moment()
              .add("days", 10)
              .format("DD-MM-YYYY")
          : moment()
              .add("days", 15)
              .format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      createPO: "",
      date: "",
      unitConversion: "",
      unitConvertionId: "",
      poQuantity: "",
      creditTerm: 60,
      deliveryTimeLine: getCookie(cons.COMPANY_ID) === "116" ? 10 : 15,
      expectedDate: "",
      selectedVehicleType: "",
      vehicleQuantiy: "",
      selectedSupplier: "",
      selectedMukam: "",
      selectedBroker: "",
      frieghtCharges: "",
      selectedJuteType: "",
      selectedIndent: "",
      netTotal: "",
      termConditions: "",
      selectedJutePO: "",
      selectedPOQuality: "",
      POQuantity: "",
      selectedTax: "",
      alert: false,
      loadsupCode: "",
      loadBorker: "",
      loadquality: "",
      indentAlert: false,
      allowMositure: 18,
      selectedIndentQuantity: "",
      indentNum: "",
      serach_poNo: "",
      sumOfAmount: 0,
      sumOfweight: 0,
      depId: "",
      invoiceList: [],
      item: "",
      itemId: "",
      lineItemId: "",
      itemDesc: "",
      quantity: "",
      quality: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      allowableMoisture: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      vehicleTypes: [],
      indentLineId: "",
      defaultVehicleType: "",
      channelCode: "D",
      contrctNo: "",
      contractDate: "",
      currencyCode: "",
      conversationRate: "",
      brokerageRate: "",
      brokeragePercentage: "",
      penality: "",
      marka: "",
      cropYear: "",
      branchId: "",
      withOrWithout: 0,
      filesList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    var data = {
      cipher: getCookie(cons.CIPHER),
      companyId: getCookie(cons.COMPANY_ID),
      userId: getCookie(cons.USERID),
    };
    requestList(serverApi.SELECT_VEHICLE_TYPE, data, this.handleVehicles);
  }
  handleVehicles = (resp) => {
    var type = 0;
    if (resp.data.length > 0) {
      var vehicleTypes = resp.data.map((prop, i) => {
        if (i === 0) {
          type = prop.id;
        }
        return {
          label: prop.vehicleType + "_" + prop.weight,
          value: prop.id,
          name: prop.vehicleType + "^^" + prop.weight,
        };
      });
      this.setState({
        vehicleTypes: vehicleTypes,
        selectedVehicleType: type,
        defaultVehicleType: type,
        reloadSelect: true,
      });
    }
  };

  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };
  getmukamName = (selectedValue) => {
    this.setState({
      mukamName: selectedValue,
    });
  };
  getsupplierName = (name) => {
    this.setState({
      supplierName: name,
    });
  };

  brokername = (name) => {
    this.setState({
      brokerName: name,
    });
  };
  handleCreate = () => {
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  dateChanged = (dateObj, key) => {
    if (typeof dateObj === "object") {
      this.setState({
        [key]: dateObj.format("DD-MM-YYYY"),
        expecDate:
          getCookie(cons.COMPANY_ID) === "116"
            ? moment(dateObj.format("DD-MM-YYYY"), "DD-MM-YYYY")
                .add(10, "days")
                .format("DD-MM-YYYY")
            : moment(dateObj.format("DD-MM-YYYY"), "DD-MM-YYYY")
                .add(15, "days")
                .format("DD-MM-YYYY"),
      });
    }
  };
  getData = () => {
    var data = {
      purchaseType: "J",
      frmDt: this.state.fromDate,
      toDt: this.state.todate,
      mukam: this.state.mukamId,
      statusId: this.state.status,
      brokerId: this.state.brokerId,
      supplierId: this.state.supplierId,
      poNum: this.state.serach_poNo,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
    };
    return data;
  };
  getSupplierData = () => {
    var data = {
      purchaseType: "J",
      frmDt: this.state.fromDate,
      toDt: this.state.todate,
      mukam: this.state.mukamId,
      statusId: !this.state.isRefresh ? 3 : this.state.status,
      brokerId: this.state.brokerId,
      supplierId: getCookie(cons.SUPP_CODE),
      poNum: this.state.serach_poNo,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  onChangeValues = (event) => {
    var value = event.target.value;
    if (event.target.id === "frieghtCharges") {
      var netAmount = this.state.sumOfamt;
      if (value !== null && value !== "") {
        //  alert( parseFloat(value));
        var totalAmount = parseFloat(value) + netAmount;
        this.setState({
          netTotal: parseFloat(totalAmount).toFixed(2), // Fixed To to 2 decimal Value
        });
      } else {
        this.setState({
          netTotal: netAmount,
        });
      }
    }
    if (event.target.id === "deliveryTimeLine") {
      var value = event.target.value;
      var podate = this.state.PoDate;
      this.setState({
        expecDate: moment(podate, "DD-MM-YYYY")
          .add(value, "days")
          .format("DD-MM-YYYY"),
      });
    }
    if (event.target.id === "quantity") {
      if (value !== null && value !== "") {
        if (
          this.state.unitConversion.value === 1 &&
          getCookie(cons.COMPANY_ID) !== "116"
        ) {
          //     alert(this.state.indentQuantity);

          console.log("val " + this.state.indentQuantity);
          if (parseFloat(value) <= 100)
            var calWeight =
              (parseFloat(this.state.indentQuantity) * parseFloat(value)) / 100;
          else {
            var calWeight =
              (parseFloat(this.state.indentQuantity) *
                parseFloat(this.state.quantity)) /
              100;
          }
        } else {
          var baleWeight = 150;
          /*   if(getCookie(cons.COMPANY_ID)==='116')
                        baleWeight=180;*/

          if (this.state.unitConversion.value === 1) {
            baleWeight = 45;
          }
          var calWeight = (parseFloat(value) * baleWeight) / 100;
        }
        var Calrate = this.state.rate;

        if (Calrate !== "") {
          var calAmount = parseFloat(Calrate) * parseFloat(calWeight);
          this.setState({
            amount: parseFloat(calAmount).toFixed(2), // Fixed To to 2 decimal Value
          });
        }
        this.setState({
          weight: calWeight.toFixed(2),
        });
      } else {
        this.setState({
          weight: "",
          amount: "",
        });
      }
    }
    if (event.target.id === "rate") {
      if (value !== null && value !== "") {
        if (Calrate !== "") {
          if (
            this.state.unitConversion.value === 1 &&
            getCookie(cons.COMPANY_ID) !== "116"
          ) {
            //     alert(this.state.indentQuantity);
            console.log("val " + this.state.indentQuantity);
            var calWeight =
              (parseFloat(this.state.indentQuantity) *
                parseFloat(this.state.quantity)) /
              100;
          } else {
            var baleWeight = 150;
            /*  if(getCookie(cons.COMPANY_ID)==='116')
                            baleWeight=180;*/
            if (this.state.unitConversion.value === 1) {
              baleWeight = 45;
            }

            var calWeight =
              (parseFloat(this.state.quantity) * baleWeight) / 100;
          }

          var calAmount = parseFloat(value) * parseFloat(calWeight);
          this.setState({
            amount: parseFloat(calAmount).toFixed(2), // Fixed To to 2 decimal Value
          });
        }
      } else {
        this.setState({
          amount: "",
        });
      }
    }
    if (event.target.id === "weight") {
      if (value !== null && value !== "") {
        if (Calrate !== "") {
          var calAmount = parseFloat(value) * parseFloat(this.state.rate);
          this.setState({
            amount: parseFloat(calAmount).toFixed(2), // Fixed To to 2 decimal Value
          });
        }
      } else {
        this.setState({
          amount: "",
        });
      }
    }

    if (event.target.id === "vehicleQuantity") {
      console.log("val " + value);
      if (value !== null && value !== "") {
        if (this.state.selectedVehicleType !== "")
          this.setState({
            indentQuantity:
              parseInt(value) * parseInt(this.state.selectedIndentQuantity),
          });
        else {
          value = "";
          this.basicAlert("Please Select Vehicle Type..!!");
        }
      }
    }
    if (
      this.state.unitConversion.value === 1 &&
      event.target.id === "quantity" &&
      getCookie(cons.COMPANY_ID) !== "116"
    ) {
      if (parseFloat(value) <= 100) {
        this.setState({
          [event.target.id]: value,
        });
      } else {
        this.basicAlert("Quantity should not be greater than 100..!!");
      }
    } else
      this.setState({
        [event.target.id]: value,
      });
  };
  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity:
        this.state.vehicleQuantity > 0
          ? parseFloat(values[1]) * parseFloat(this.state.vehicleQuantity)
          : parseFloat(values[1]),
    });
    console.log("indentQuantity " + values[1]);
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedJuteQuality") {
      getDataFromUrl(
        serverApi.GET_LATEST_MARKET_RATE +
          "/" +
          selectedValue +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleRate
      );
    }
    if (stateKey === "selectedMukam") {
      this.setState({
        loadsupCode: true,
      });
    }
    if (stateKey === "supplier_Id") {
      this.setState({
        loadBorker: true,
      });
    }

    if (stateKey === "item") {
      this.setState({
        loadquality: true,
      });
    }
    if (stateKey === "branchId") {
      this.setState({ loadIndent: true });
    }
    this.setState({
      [stateKey]: selectedValue,
    });
    if (stateKey === "status") {
    }
  };
  afterRefreshItemGrp = (val) => {
    this.setState({
      loadsupCode: val,
    });
  };
  afterRefreshbroker = (val) => {
    this.setState({
      loadBorker: val,
    });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      loadquality: val,
    });
  };
  handleSelectedUnitValue = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      this.state.unitConversion.value !== defValue.value
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConversion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  handleRate = (resp) => {
    this.setState({
      latestRate: resp.data.latestRate,
      latestRateDate: resp.data.latestRateDate,
    });
  };
  handleSelectedValues = (selectedValue, stateKey) => {
    //const {classes}=this.props;

    this.setState({
      SelectindentType: selectedValue,
    });
    // const {selectedJuteType}=this.state;
    if (selectedValue.value === 1) {
      this.setState({
        loading: true,
        indentAlert: true,
      });
    } else {
      this.setState({
        indentNo: "",
        unitConversion: "",
        unitConvertionId: 0,
        indentQuantity: "",
        indentQuantityTon: "",
        indentBales: "",
        createdBy: "",
        createdOn: "",
        selectedVehicleType: this.state.defaultVehicleType,
        quantity: "",
        stock: "",
        selectedJuteQuality: "",
        selectedQualityName: "",
        // itemGroupId: '',
        selectedMukam: 0,
        selectedTax: "",
        invoiceList: [],
        selectedJuteType: "",
        selectedJuteName: "",
        selectedIndentQuantity: "",
        sumOFQuantities: 0,
        brokerName: "",
        selectedDeptId: "",
        selectedIndent: "",
        selectedGrpId: "",
        selectedUnit: "",
        netTotal: 0,
        frieghtCharges: 0,
        //  year: "",
        // date1:"",
        vehicleQuantity: "",
        reloadSelect: true,
      });
    }

    //console.log(`Option selected:`, defValue);
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getConvertedQty = () => {
    console.log("indentQuantity 11 " + this.state.indentQuantity);

    if (this.state.unitConversion.value === 2) {
      var baleWeight = 150;
      /*  if(getCookie(cons.COMPANY_ID)==='116')
                baleWeight=180;*/
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / baleWeight))
      );
    } else {
      if (getCookie(cons.COMPANY_ID) === "116") {
        var baleWeight = 45;
        return Math.round(
          parseFloat(parseFloat((this.state.indentQuantity * 100) / baleWeight))
        );
      } else return this.state.indentQuantity;
      // return Math.round(parseFloat(parseFloat((this.state.indentQuantity * 100) / 150)));
    }
  };

  // Create PO
  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var sumofWeight = 0;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        sumofWeight = sumofWeight + parseFloat(prop.weight);
        var baleValue = "",
          percentValue = "";
        if (this.state.unitConversion.value === 2) {
          baleValue = prop.quantity;
          percentValue = "";
        } else {
          percentValue = prop.quantity;
          baleValue = "";
        }
        return {
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: prop.qualityCode,
          quantity: prop.quantity,
          unitId: prop.uom,
          rate: prop.rate,
          allowableMoisturePercentage: prop.allowableMoisture,
          cTax: "",
          sTax: "",
          iTax: "",
          bale: baleValue,
          loose: percentValue,
          amount: prop.amount,
          deptId: prop.depId,
          valueWithoutTax: prop.amount,
          valueWithTax: "",
          tax: "",
          indentId: prop.indentLineId,
          // "actualQuantity": prop.quantity,
          actualQuantity: prop.weight,
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
          marka: prop.marka,
          cropYear: prop.cropYear,
        };
      });
      const {
        channelCode,
        contrctNo,
        contractDate,
        currencyCode,
        conversationRate,
        brokerageRate,
        brokeragePercentage,
        penality,
        branchId,
      } = this.state;

      var data = {
        poHeader: {
          type: "J",
          poDate: this.state.PoDate,
          creditTerm: this.state.creditTerm,
          deliveryTimeline: this.state.deliveryTimeLine,
          vehicleTypeId: this.state.selectedVehicleType,
          vehicleQuantity: this.state.vehicleQuantity,
          supplierId: this.state.supplier_Id,
          mukam: this.state.selectedMukam,
          brokerId: this.state.broker_Id,
          frieghtCharge: this.state.frieghtCharges,
          remarks: "",
          juteUnit: this.state.unitConversion.label,
          exceptedDate: this.state.expecDate,
          cTax: "",
          sTax: "",
          iTax: "",
          valueWithoutTax: this.state.netTotal,
          submitter: getCookie(cons.USERID),
          indentNum: this.state.indentNo,
          finnacialYear: this.state.year,
          footerNote: "",
          valueWithTax: this.state.netTotal,
          tax: "",
          deliveryAddress: "",
          discount: "",
          weight: sumofWeight,
          brokerName: this.state.brokerName,
          companyId: getCookie(cons.COMPANY_ID),
          withOrWithout: 0,
          channelCode: channelCode,
          contrctNo: contrctNo,
          contractDate: contractDate,
          currencyCode: currencyCode,
          conversationRate: conversationRate,
          brokerageRate: brokerageRate,
          brokeragePercentage: brokeragePercentage,
          penality: penality,
          branchId: branchId,
        },
        poLineItem: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      console.log(data);
      if (
        this.state.selectedMukam !== "0" &&
        this.state.selectedMukam !== "" &&
        this.state.selectedVehicleType !== "" &&
        this.state.invoiceList.length >= 1 &&
        this.state.netTotal > 0 &&
        this.state.supplier_Id !== "" &&
        this.state.supplier_Id !== "0" &&
        channelCode !== "" &&
        branchId !== ""
      ) {
        if (channelCode === "I") {
          if (contrctNo !== "" && contractDate !== "") {
            this.handleLoading();
            requestList(serverApi.ADD_JUTE_PO, data, this.handleAddIndent);
          } else {
            this.basicAlert("Please Enter Contract No and Date..!!");
          }
        } else {
          this.handleLoading();
          requestList(serverApi.ADD_JUTE_PO, data, this.handleAddIndent);
        }
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  handleUpdateIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));

      this.createShowMessage(resp.message, resp.status);

      //  this.createShowMessage(resp.message, resp.status)
    } else {
      this.createShowMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      if (this.state.finalApprover && this.state.poStatus !== "3") {
        this.setState({
          sendMail: true,
          finalApprovalMsg: resp.message,
        });
        this.printDocument();
      } else {
        this.createShowMessage(resp.message, resp.status);
      }

      //  this.createShowMessage(resp.message, resp.status)
    } else {
      this.createShowMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //update PO (approve)

  onApproveButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var sumofWeight = 0;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        sumofWeight = sumofWeight + parseFloat(prop.weight);

        var baleValue = "",
          percentValue = "";
        if (this.state.unitConversion.value === 2) {
          baleValue = prop.unit;
          percentValue = "";
        } else {
          percentValue = prop.unit;
          baleValue = "";
        }
        return {
          id: prop.lineItemId,
          poId: this.state.poNo,
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: prop.qualityCode,
          quantity: prop.quantity,
          unitId: prop.uom,
          rate: prop.rate,
          allowableMoisturePercentage: prop.allowableMoisture,
          cTax: "",
          sTax: "",
          iTax: "",
          bale: baleValue,
          loose: percentValue,
          amount: prop.amount,
          deptId: prop.depId,
          valueWithoutTax: prop.amount,
          valueWithTax: "",
          tax: "",
          indentId: prop.indentLineId,
          actualQuantity: prop.weight,
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
          marka: prop.marka,
          cropYear: prop.cropYear,
        };
      });
      const {
        channelCode,
        contrctNo,
        contractDate,
        currencyCode,
        conversationRate,
        brokerageRate,
        brokeragePercentage,
        penality,
        branchId,
      } = this.state;

      var data = {
        poHeader: {
          hdrId: this.state.hdrId,
          type: "J",
          poDate: this.state.PoDate,
          creditTerm: this.state.creditTerm,
          deliveryTimeline: this.state.deliveryTimeLine,
          vehicleTypeId: this.state.selectedVehicleType,
          vehicleQuantity: this.state.vehicleQuantity,
          supplierId: this.state.supplier_Id,
          mukam: this.state.selectedMukam,
          brokerId: this.state.broker_Id,
          frieghtCharge: this.state.frieghtCharges,
          remarks: "",
          juteUnit: this.state.unitConvertionName,
          exceptedDate: this.state.expecDate,
          cTax: "",
          sTax: "",
          iTax: "",
          valueWithoutTax: this.state.netTotal,
          submitter: getCookie(cons.USERID),
          modBy: getCookie(cons.USERID),
          indentNum: this.state.indentNo,
          finnacialYear: this.state.year,
          footerNote: "",
          valueWithTax: this.state.netTotal,
          tax: "",
          deliveryAddress: "",
          discount: "",
          weight: sumofWeight,
          brokerName: this.state.brokerName,
          status: status,
          companyId: getCookie(cons.COMPANY_ID),
          channelCode: channelCode,
          contrctNo: contrctNo,
          contractDate: contractDate,
          currencyCode: currencyCode,
          conversationRate: conversationRate,
          brokerageRate: brokerageRate,
          brokeragePercentage: brokeragePercentage,
          penality: penality,
          branchId: branchId,
        },
        poLineItem: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      console.log(data);
      if (
        this.state.selectedMukam !== "0" &&
        this.state.selectedMukam !== "" &&
        this.state.selectedVehicleType !== "" &&
        this.state.invoiceList.length >= 1 &&
        this.state.netTotal > 0 &&
        channelCode !== "" &&
        branchId !== ""
      ) {
        this.handleLoading();
        if (status === "")
          requestList(serverApi.UPDATE_JUTE_PO, data, this.handleUpdateIndent);
        else requestList(serverApi.UPDATE_JUTE_PO, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  //update PO (Reject)

  onRejectButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var sumofWeight = 0;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        sumofWeight = sumofWeight + parseFloat(prop.weight);

        var baleValue = "",
          percentValue = "";
        if (this.state.unitConversion.value === 2) {
          baleValue = prop.unit;
          percentValue = "";
        } else {
          percentValue = prop.unit;
          baleValue = "";
        }
        return {
          id: prop.lineItemId,
          poId: this.state.poNo,
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: prop.qualityCode,
          quantity: prop.quantity,
          unitId: prop.uom,
          rate: prop.rate,
          allowableMoisturePercentage: prop.allowableMoisture,
          cTax: "",
          sTax: "",
          iTax: "",
          bale: baleValue,
          loose: percentValue,
          amount: prop.amount,
          marka: "",
          deptId: prop.depId,
          valueWithoutTax: prop.amount,
          valueWithTax: "",
          tax: "",
          indentId: "",
          actualQuantity: prop.weight,
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
        };
      });

      var data = {
        poHeader: {
          hdrId: this.state.hdrId,
          type: "J",
          poDate: this.state.PoDate,
          creditTerm: this.state.creditTerm,
          deliveryTimeline: this.state.deliveryTimeLine,
          vehicleTypeId: this.state.selectedVehicleType,
          vehicleQuantity: this.state.vehicleQuantity,
          supplierId: this.state.supplier_Id,
          mukam: this.state.selectedMukam,
          brokerId: this.state.broker_Id,
          frieghtCharge: this.state.frieghtCharges,
          remarks: "",
          juteUnit: this.state.unitConvertionName,
          exceptedDate: this.state.expecDate,
          cTax: "",
          sTax: "",
          iTax: "",
          valueWithoutTax: this.state.netTotal,
          submitter: getCookie(cons.USERID),
          indentNum: this.state.indentNo,
          finnacialYear: this.state.year,
          footerNote: "",
          valueWithTax: this.state.netTotal,
          tax: "",
          deliveryAddress: "",
          discount: "",
          weight: this.state.sumOfweight,
          brokerName: this.state.brokerName,
          status: "4",
        },
        poLineItem: invoiceList,
      };

      this.handleLoading();
      requestList(serverApi.UPDATE_JUTE_PO, data, this.handleAddIndent);
    }
  };

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }
  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getDefaultindentConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === 1) {
        val = 1;
        label = "With Indent";
      } else {
        val = 2;
        label = "Without Indent";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  /// view indent code
  handleViewClick = (id) => {
    var juteId = "";
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      // getDataFromUrl(serverApi.GET_PO_DETAILS + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
      var data = {
        poNum: id,
        userId: getCookie(cons.USERID),
        cipher: getCookie(cons.CIPHER),
      };
      requestList(serverApi.GET_PO_DETAILS, data, this.handleViewClickResp);
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.poHeader;
    var list = resp.poLineItemVo;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        lineItemId: prop.id,
        indentLineId: prop.indentId,
        itemId: parseInt(prop.itemId),
        itemDesc: prop.itemDesc,
        itemGroupId: prop.itemGroupId,
        quantity: prop.quantity,
        quality: prop.juteQuality,
        hsnCode: prop.hsnCode,
        unit: prop.bale,
        weight: prop.actualQuantity,
        uom: prop.unitId,
        allowableMoisture: prop.allowableMoisturePercentage,
        rate: prop.rate,
        amount: prop.valueWithoutTax,
        depId: prop.deptId,
        qualityCode: prop.qualityCode,
        marka: prop.marka,
        cropYear: prop.cropYear,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;

                data.find((o, i) => {
                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    // alert(o.qualityId);
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.setState({
                      //alert: true,
                      indentLineId: o.indentLineId,
                      lineItemId: o.lineItemId,
                      id: o.id,
                      updateItem: true,
                      itemDesc: o.itemDesc,
                      updateItemAlert: true,
                      quantity: o.quantity,
                      selectedJuteQuality: o.qualityCode,
                      quality: o.quality,
                      hsnCode: o.hsnCode,
                      rate: o.rate,
                      weight: o.weight,
                      amount: o.amount,
                      item: o.itemId,
                      itemGroupId: o.itemGroupId,
                      depId: o.depId,
                      allowableMoisture: o.allowableMoisture,
                      uom: o.uom,
                      marka: o.marka,
                      cropYear: o.cropYear,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="edit"
            >
              <Edit />
            </IconButton>{" "}
            {""}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    if (this.state.approveStatus) {
                      this.setState({ deleteItem: true, id: o.id });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                      //  data.splice(i,1);
                    }
                    return true;
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });
    //  console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        approveStatus: header.approveButton,
        // itemViewType:header.id,
        hdrId: header.hdrId,
        poNo: header.id,
        statusDesc: header.statusName,

        // unitConvertionId:"0",
        //  indentQuantity:header.indentWeight,
        //    year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateTime,
        PoDate: header.poDate,
        indentNo: header.indentNum,
        unitConvertionName: header.juteUnit,
        //   indentNum: (header.indentNum !== '' && header.indentNum!==null) ? 1 : 2,
        SelectindentType: this.getDefaultindentConvertion(
          header.indentNum !== "" && header.indentNum !== null ? 1 : 2
        ),
        unitConversion: this.getDefaultUnitConvertion(header.juteUnit),
        creditTerm: header.creditTerm,
        deliveryTimeLine: header.deliveryTimeline,
        expecDate: header.exceptedDate,
        selectedVehicleType: header.vehicleTypeId,
        vehicleQuantity: header.vehicleQuantity,
        selectedMukam: parseInt(header.mukam),
        // supplierId: header.supplierId,
        supplier_Id: header.supplierId,
        broker_Id: header.brokerId !== null ? header.brokerId : "",
        frieghtCharges: header.frieghtCharge,
        netTotal: header.valueWithoutTax,
        sumOfamt: header.valueWithoutTax,
        quantity: "",
        stock: "",
        selectedJuteQuality: "",
        selectedQualityName: "",
        invoiceList: indentlist,
        poStatus: header.status,
        finalApprover: resp.finalApprover,
        channelCode: header.channelCode !== null ? header.channelCode : "D",
        contrctNo: header.contrctNo,
        contractDate: header.contracDatedes,
        currencyCode: header.currencyCode,
        conversationRate: header.conversationRate,
        brokerageRate: header.brokerageRate,
        brokeragePercentage: header.brokeragePercentage,
        penality: header.penality,
        branchId: header.branchId,
        withOrWithout: header.withOrWithout,
        modOndes: header.modOndes,
        modBy: header.modBy,
      });
      if (header.indentHdrId !== null && header.indentHdrId !== "")
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "8/" +
            header.indentHdrId +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleInvoiceFiles
        );

      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "12/" +
          header.hdrId +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
    }
    if (getCookie(cons.USER_GRP_ID) == "99") {
      this.printDocument();
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };

  onSelectIndent = () => {
    getDataFromUrl(
      serverApi.GET_INDENT_DETAILS_BY_ID +
        "userid/" +
        getCookie(cons.USERID) +
        "/company/" +
        getCookie(cons.COMPANY_ID) +
        "/year/" +
        getCookie(cons.ACADEMIC_YEAR) +
        "?indentId=" +
        this.state.selectedIndent,
      this.handleIndentSelectResp
    );

    //  getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + this.state.selectedIndent + "/" + getCookie(cons.USERID) + "/getIndentDetails/"+getCookie(cons.CIPHER), this.handleIndentSelectResp)
  };
  handleIndentSelectResp = (resp) => {
    var header = resp.indentHeader;
    var list = resp.indentItems;
    var totalAmount = 0;
    //  console.log(list);
    var indentlist = list.map((prop, key) => {
      totalAmount =
        totalAmount + parseFloat(prop.rate) * parseFloat(prop.indentQuantity);
      return {
        indentLineId: prop.indentLineItemId,
        id: key,
        itemId: prop.itemId,
        itemDesc: prop.itemIdDesc,
        itemGroupId: prop.itemGroupId,
        quantity: prop.noOfBales,
        quality: prop.qualityCodeDesc,
        qualityId: prop.qualityCode,
        qualityCode: prop.qualityCode,
        hsnCode: prop.hsnCode,
        // unit: prop.noOfBales,
        weight: prop.indentQuantity,
        uom: prop.unitId,
        allowableMoisture: prop.moisture,
        rate: prop.rate,
        depId: prop.deptId,
        amount: parseFloat(prop.rate) * parseFloat(prop.indentQuantity),
        // totAmt:parseFloat(totAmt)+parseFloat(parseFloat(prop.rate)*parseFloat(prop.indentQuantity)),

        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");

                    // alert(o.qualityId);
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.setState({
                      // alert: true,
                      id: o.id,
                      indentLineId: o.indentLineId,
                      updateItemAlert: true,
                      updateItem: true,
                      reloadSelect: true,
                      item: parseInt(o.itemId),
                      itemDesc: o.itemIdDesc,
                      quantity: o.quantity,
                      selectedJuteQuality: parseInt(o.qualityId),
                      quality: o.qualityCodeDesc,
                      hsnCode: o.hsnCode,
                      unit: o.unitId,
                      weight: o.weight,
                      uom: o.unitId,
                      allowableMoisture: o.allowableMoisture,
                      rate: o.rate,
                      amount: o.amount,
                      itemGroupId: o.itemGroupId,
                      depId: prop.deptId,
                      qualityCode: prop.qualityCode,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>
            {""}

            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    this.setState({ deleteItem: true, id: o.id });
                    this.basicItemAlert("Do You Like To Delete This Item?");

                    return true;
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>
          </div>
        ),
      };
    });
    console.log("indentWeight " + header.mukamNo);
    if (header !== null) {
      this.setState({
        indentAlert: false,
        loading: false,

        //updateView:true,
        // isCreated:true,
        canChangeMukam: true,
        canChangeUnit: true,
        approveStatus: header.approveButton,
        indentNo: header.id,
        statusDesc: header.statusDesc,
        unitConversion: this.getDefaultUnitConvertion(header.unitConversion),
        // unitConvertionId:"0",
        indentQuantity: header.indentWeight,
        // year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDate,
        date1: header.indentDateDesc,
        selectedMukam: header.mukamNo,
        selectedVehicleType: parseInt(header.vehicleTypeId),
        vehicleQuantity: header.vehicleQuantity,
        reloadSelect: true,
        quantity: "",
        stock: "",
        netTotal: parseFloat(totalAmount).toFixed(2),
        sumOfamt: parseFloat(totalAmount).toFixed(2),
        frieghtCharges: 0,
        invoiceList: indentlist,
      });
    }
    //this.indenthideAlert();
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");

    this.setState({
      itemDesc: values[0],
      itemGroupId: values[1],
      uom: values[2],
      depId: values[3],
      hsnCode: values[4],
    });

    console.log("indentQuantity " + this.state.indentQuantity);
  };
  getQualityDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      quality: values[0],
      stock: values[1],
      lastPurchase: values[3],
    });
    if (this.state.quality !== values[0]) {
      this.setState({
        rate: values[2],
        allowableMoisture: values[4],
      });
    }
  };
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };
  getQuantityType = () => {
    if (this.state.unitConversion !== "") {
      var val = this.state.unitConversion.value;
      if (val === 2) {
        return "BALE";
      } else {
        return "DRUM";
      }
    } else return "";
  };
  createShowMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  showMessage = (msg, status) => {
    this.setState({
      indentAlert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  hideItemAlert() {
    console.log("alert");
    this.setState({
      //   alert: null,
      id: 0,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      itemId: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      taxId: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      purchaseType: "",
      lineItemId: 0,
    });
  }
  hideAlert() {
    console.log("alert");
    this.setState({
      latestRate: "",
      latestRateDate: "",
      alert: null,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      itemId: "",
      updateItem: false,

      itemDesc: "",
      quantity: "",
      quality: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      allowableMoisture: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      lineItemId: 0,
      indentLineId: "",
      marka: "",
      cropYear: "",
    });
  }

  //on select indent no

  indenthideAlert() {
    this.setState({
      indentAlert: false,
      loading: false,
      SelectindentType: this.getDefaultindentConvertion(2),
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConversion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: this.state.defaultVehicleType,
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      itemGroupId: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      brokerName: "",
      SelectindentType: this.getDefaultindentConvertion(2),
      selectedDeptId: "",
      selectedIndent: "",
      selectedGrpId: "",
      selectedUnit: "",
      vehicleQuantiy: "",
      netTotal: "",
      updateItem: false,
      frieghtCharges: "",
      broker_Id: "",
      supplier_Id: "",
      sumOfamt: "",
      PoDate: moment().format("DD-MM-YYYY"),
      expecDate: moment()
        .add("days", 15)
        .format("DD-MM-YYYY"),
      creditTerm: 60,
      deliveryTimeLine: getCookie(cons.COMPANY_ID) === "116" ? 10 : 15,
      sumOfweight: 0,
      lineItemId: 0,
      allowableMoisture: "",
      mukamName: "",
      supplierName: "",
      finalApprover: false,
      poStatus: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      channelCode: "D",
      contrctNo: "",
      contractDate: "",
      currencyCode: "",
      conversationRate: "",
      brokerageRate: "",
      brokeragePercentage: "",
      penality: "",
      marka: "",
      cropYear: "",
      branchId: "",
      withOrWithout: 0,
      filesList: [],
    });
  }

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    //   var sumOfwgt = parseInt(this.state.sumOfweight) + parseInt(this.state.weight);
    var sumOfwgt = 0,
      sumOfamt = 0;

    //var sumOfamt = parseInt(this.state.sumOfAmount) + parseInt(this.state.amount);
    console.log("quantity ", this.state.quantity);
    var itemIndex = 0,
      hasItem = false;

    if (this.state.updateItem) {
      itemIndex = this.state.id;
      arr[itemIndex].weight = this.state.weight;
      arr[itemIndex].amount = this.state.amount;
    } else {
      itemIndex = this.state.invoiceList.length;
    }

    arr.map((q) => {
      if (
        q.qualityCode === this.state.selectedJuteQuality &&
        !this.state.updateItem
      ) {
        hasItem = true;
        console.log("updateItem", this.state.id + " dd");
      }
      sumOfwgt = sumOfwgt + parseFloat(q.weight);
      sumOfamt = parseFloat(sumOfamt) + parseFloat(q.amount);
      sumOfamt = parseFloat(sumOfamt).toFixed(2);
    });
    if (!this.state.updateItem) {
      sumOfwgt = parseFloat(sumOfwgt) + parseFloat(this.state.weight);
      sumOfamt = parseFloat(sumOfamt) + parseFloat(this.state.amount);
      sumOfamt = sumOfamt.toFixed(2);
    }

    var addItemObj = {
      id: itemIndex,
      lineItemId: this.state.lineItemId,
      indentLineId: this.state.indentLineId,
      itemId: this.state.item,
      itemDesc: this.state.itemDesc,
      // unitConvertion:"",
      quantity: this.state.quantity,
      qualityCode: this.state.selectedJuteQuality,
      quality: this.state.quality,
      hsnCode: this.state.hsnCode,
      //   unit: this.state.quantity,
      weight: this.state.weight,
      uom: this.state.uom,
      allowableMoisture: this.state.allowableMoisture,
      rate: this.state.rate,
      amount: this.state.amount,
      itemGroupId: this.state.itemGroupId,
      depId: this.state.depId,
      marka: this.state.marka,
      cropYear: this.state.cropYear,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to remove the data row */}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");
                  // alert(o.qualityId);
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  //  data.splice(i,1);
                  this.setState({
                    //alert: true,
                    id: o.id,
                    updateItemAlert: true,
                    updateItem: true,
                    reloadSelect: true,
                    item: o.itemId,
                    itemDesc: o.itemDesc,
                    quantity: o.quantity,
                    selectedJuteQuality: o.qualityCode,
                    quality: o.quality,
                    hsnCode: o.hsnCode,
                    rate: o.rate,
                    weight: o.weight,
                    amount: o.amount,
                    itemGroupId: o.itemGroupId,
                    depId: o.depId,
                    allowableMoisture: o.allowableMoisture,
                    uom: o.uom,
                    lineItemId: o.lineItemId,
                    indentLineId: o.indentLineId,
                    cropYear: o.cropYear,
                    marka: o.marka,
                  });

                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="edit"
          >
            <Edit />
          </IconButton>
          {""}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.setState({ deleteItem: true, id: o.id });
                  this.basicItemAlert("Do You Like To Delete This Item?");

                  //  data.splice(i,1);
                  return true;
                  /*
                                    this.setState({
                                        id: '',
                                        updateItemAlert:true,
                                        itemId: this.state.item,
                                        itemDesc: this.state.itemDesc,
                                        quantity: this.state.quantity,
                                        quality: this.state.quality,
                                        hsnCode: this.state.hsnCode,
                                        unit: this.state.quantity,
                                        weight: this.state.weight,
                                        uom: this.state.uom,
                                        allowableMoisture: this.state.allowableMoisture,
                                        rate: this.state.rate,
                                        amount: this.state.amount,
                                        itemGroupId: this.state.itemGroupId,
                                        depId: this.state.depId,

                                    });
*/
                }
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>
        </div>
      ),
    };

    if (
      (this.state.item !== "") & (this.state.selectedJuteQuality !== "") &&
      this.state.quantity !== "" &&
      this.state.quantity > 0 &&
      this.state.allowMositure !== "" &&
      this.state.amount !== "" &&
      this.state.amount !== "0" &&
      this.state.weight !== ""
    ) {
      if (!hasItem) {
        console.log("sumOfwgt", sumOfwgt);
        console.log("totalQuantiy", this.state.selectedIndentQuantity);
        var totalQuantiy =
          this.state.selectedIndentQuantity *
          parseInt(this.state.vehicleQuantity);
        console.log("totalQuantiy", totalQuantiy);
        if (getCookie(cons.COMPANY_ID) !== "116") {
          if (sumOfwgt <= totalQuantiy) {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);
            this.setState({
              hideJuteType: false,
              loading: true,
              invoiceList: arr,
              netTotal: parseFloat(sumOfamt).toFixed(2),
              sumOfamt: parseFloat(sumOfamt).toFixed(2),
              sumOfweight: sumOfwgt,
              updateItem: false,
            });
            this.hideAlert();
          } else {
            this.basicAlert(
              "Weight Should Not Exceed PO Quantity..!!" +
                this.getConvertedQty()
            );
          }
        } else {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);
          this.setState({
            hideJuteType: false,
            loading: true,
            invoiceList: arr,
            netTotal: sumOfamt,
            sumOfamt: sumOfamt,
            sumOfweight: sumOfwgt,
            updateItem: false,
          });
          this.hideAlert();
        }
      } else {
        this.basicAlert(
          "Item Already Saved For This Quality. Please Select Another One..!!"
        );
      }
    } else if (this.state.quantity == 0 || this.state.quantity < 0) {
      this.basicAlert("Do not enter Negative Values and Zero in Quantity..!!");
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideItemAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      /* updateItem:false,
            updateItemAlert:false*/
    });
  }
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      var totalAmount =
        parseFloat(this.state.netTotal) - data[this.state.id].amount;

      data.splice(this.state.id, 1);
      data.map((o, key) => {
        o.id = key;
        o.actions = (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;

                data.find((o, i) => {
                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    // alert(o.qualityId);
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.setState({
                      //alert: true,
                      indentLineId: o.indentLineId,
                      lineItemId: o.lineItemId,
                      id: o.id,
                      updateItem: true,
                      itemDesc: o.itemDesc,
                      updateItemAlert: true,
                      quantity: o.quantity,
                      selectedJuteQuality: o.qualityCode,
                      quality: o.quality,
                      hsnCode: o.hsnCode,
                      rate: o.rate,
                      weight: o.weight,
                      amount: o.amount,
                      item: o.itemId,
                      itemGroupId: o.itemGroupId,
                      depId: o.depId,
                      allowableMoisture: o.allowableMoisture,
                      uom: o.uom,
                      marka: o.marka,
                      cropYear: o.cropYear,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="edit"
            >
              <Edit />
            </IconButton>{" "}
            {""}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    if (this.state.approveStatus) {
                      this.setState({ deleteItem: true, id: o.id });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                      //  data.splice(i,1);
                    }
                    return true;
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        );
      });

      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        netTotal: totalAmount,
        sumOfamt: totalAmount,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }
  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    console.log("unitConversionName " + this.state.unitConversion);
    //  var id="";
    console.log(this.state);

    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConversion !== "" &&
      this.state.selectedVehicleType !== "" &&
      this.state.vehicleQuantity !== "" &&
      this.state.supplier_Id !== "" &&
      this.state.supplier_Id !== "0"
    )
      this.setState({
        loading: true,
        alert: true,
      });
    else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  print = function() {
    window.print();
  };

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      netTotal: 0,
      frieghtCharges: 0,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert1: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      isCancel,
      poStatus,
      isClose,
      hdrId,
      isReject,
      isApprove,
      srStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onRejectButtonClick();
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(poStatus);
    }
    if (isCancel) {
      this.setState({ isCancel: false });
      this.onApproveButtonClick("6");
    }
    if (isClose) {
      this.setState({ isClose: false });
      this.onApproveButtonClick("5");
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        12,
        fileName,
        hdrId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
      isCancel: false,
      isClose: false,
    });
  }

  //

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  render() {
    const { classes } = this.props;
    const {
      selectedJuteType,
      selectedIndent,
      selectedVehicleType,
    } = this.state;
    const inputFocus = {
      autoComplete: "off",
    };
    var columns = [
      {
        Header: "Jute Type/Region",
        accessor: "itemDesc",
        minWidth: 150,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Quality/Grade",
        accessor: "quality",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },

      {
        Header: "HSN/SAC Code",
        accessor: "hsnCode",
        width: 150,
        style: { color: "#000", textAlign: "left", overflow: "unset" },
      },
      {
        Header: this.getQuantityType(),
        accessor: "quantity",
        minWidth: 50,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Weight",
        accessor: "weight",
        minWidth: 50,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "UOM",
        accessor: "uom",
        minWidth: 50,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Moisture",
        accessor: "allowableMoisture",
        minWidth: 70,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Rate",
        accessor: "rate",
        minWidth: 50,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Amount",
        accessor: "amount",
        minWidth: 70,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Actions",
        accessor: "actions",
        style: { color: "#000", textAlign: "center" },
        sortable: false,
        filterable: false,
      },
    ];
    var hasCrop = false;
    this.state.invoiceList.forEach((prop) => {
      if (
        prop.cropYear !== null &&
        prop.cropYear !== "" &&
        prop.cropYear !== undefined
      )
        hasCrop = true;
    });
    if (hasCrop) {
      columns = [
        {
          Header: "Crop Year",
          accessor: "cropYear",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Jute Type/Region",
          accessor: "itemDesc",
          minWidth: 150,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Quality/Grade",
          accessor: "quality",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: "HSN/SAC Code",
          accessor: "hsnCode",
          width: 150,
          style: { color: "#000", textAlign: "left", overflow: "unset" },
        },
        {
          Header: this.getQuantityType(),
          accessor: "quantity",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Weight",
          accessor: "weight",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "UOM",
          accessor: "uom",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Moisture",
          accessor: "allowableMoisture",
          minWidth: 70,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Rate",
          accessor: "rate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Amount",
          accessor: "amount",
          minWidth: 70,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Actions",
          accessor: "actions",
          style: { color: "#000", textAlign: "center" },
          sortable: false,
          filterable: false,
        },
      ];
    }
    console.log("selectedVehicleType", selectedVehicleType);
    var year = parseInt(getCookie(cons.ACADEMIC_YEAR));
    const yearOptions = [
      { value: year, label: year + "-" + (year + 1), name: year },
      { value: year - 1, label: year - 1 + "-" + year, name: year - 1 },
      { value: year - 2, label: year - 2 + "-" + (year - 1), name: year - 2 },
    ];
    const statusOptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 3, label: "APPROVED", name: "APPROVED" },
      { value: 4, label: "REJECTED", name: "REJECTED" },
      { value: 5, label: "CLOSED", name: "CLOSED" },
      { value: 6, label: "CANCELLED", name: "CANCELLED" },
      { value: 25, label: "PAID", name: "PAID" },
    ];
    const ucoptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];

    const options = [
      { value: 1, label: "With Indent", name: "With Indent" },
      { value: 2, label: "Without Indent", name: "Without Indent" },
    ];

    var yesterday = Datetime.moment().subtract(1, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var valid = function(current) {
      if (isBackDate === "2") return current.isAfter(yesterday);
      else return true;
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated && getCookie(cons.USER_GRP_ID) !== "99" ? (
          <ItemGrid xs={12}>
            <RegularCard
              //   cardTitle={"Create Jute PO"}
              // headerColor={"blue"}
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {//get indent alert
                  this.state.indentAlert ? (
                    <Popup
                      onClickSave={() => this.onSelectIndent()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  md={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        {labelType === "2"
                                          ? "Project"
                                          : "Branch"}
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={this.state.isDisabled}
                                        defValue={this.state.branchId}
                                        onSelect={this.onSelect}
                                        //  getName={this.getQualityName}
                                        stateKey="branchId"
                                        // isRefresh={this.state.loadQuality}
                                        // setIsRefresh={this.afterRefreshQuality}
                                        url={
                                          serverApi.SELECT_BRANCH +
                                          getCookie(cons.COMPANY_ID)
                                        }
                                        value={this.state.branchId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Indent List
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedIndent"}
                                        defValue={selectedIndent}
                                        onSelect={this.onSelect}
                                        getName={(name) => {}}
                                        isRefresh={this.state.loadIndent}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadIndent: false });
                                        }}
                                        stateKey="selectedIndent"
                                        url={
                                          serverApi.APPROVED_INDENTS +
                                          "j/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.ACADEMIC_YEAR) +
                                          "/" +
                                          this.state.branchId +
                                          "/999"
                                        }
                                        value={selectedIndent}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {//add item alert
                  this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Jute Type/Region
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"item"}
                                        defValue={this.state.item}
                                        onSelect={this.onSelect}
                                        isReload={this.state.reloadSelect}
                                        setIsReload={this.afterReloadSelect}
                                        getName={this.getitemDetails.bind(this)}
                                        stateKey="item"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.selectedMukam +
                                          "/getAllItemByMukam/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.item}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quality/Grade
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedJuteQuality"}
                                        defValue={
                                          this.state.selectedJuteQuality
                                        }
                                        onSelect={this.onSelect}
                                        isReload={this.state.reloadSelect}
                                        setIsReload={this.afterReloadSelect}
                                        getName={this.getQualityDetails}
                                        isRefresh={this.state.loadquality}
                                        setIsRefresh={this.afterRefreshquality}
                                        stateKey="selectedJuteQuality"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.item +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/getQualityByItem"
                                        }
                                        value={this.state.selectedJuteQuality}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            {getCookie(cons.COMPANY_ID) !== "116" ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Stock
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={true}
                                          id="stock"
                                          className={classes.textField}
                                          //  onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          iType={"number"}
                                          value={this.state.stock}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={
                                          getCookie(cons.USER_GRP_ID) == "99"
                                            ? true
                                            : false
                                        }
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.quantity}
                                        iType={"number"}
                                        decimal={2}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.getQuantityType()}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={2}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {this.state.latestRate !== ""
                                          ? "Last MarketRate at  " +
                                            this.state.latestRate +
                                            " on " +
                                            this.state.latestRateDate
                                          : null}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Allowable Moisture
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="allowableMoisture"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.allowableMoisture}
                                        InputProps={inputFocus}
                                        iType={"number"}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="weight"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.weight}
                                    iType={"number"}
                                    decimal={2}
                                    InputProps={{
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            QTL
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        iType={"number"}
                                        value={this.state.amount}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Marka
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="marka"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.marka}
                                        InputProps={{
                                          autoComplete: "on",
                                        }}
                                        //  iType={"number"}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Crop Year
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.cropYear}
                                    onSelect={this.onSelect}
                                    // getName={this.getVehicleQlty.bind(this)}
                                    stateKey="cropYear"
                                    staticData={yearOptions}
                                    value={this.state.cropYear}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={
                            this.state.isDisabled ||
                            getCookie(cons.USER_GRP_ID) == "99"
                          }
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        {this.state.alert}
                        {this.state.basicAlert1}
                        {this.state.basicAlert}
                        {this.state.showMessagealert}
                        <InputLabel className={classes.label}>
                          Create PO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          defValue={this.state.SelectindentType}
                          name={"SelectindentType"}
                          value={this.state.SelectindentType}
                          onChange={this.handleSelectedValues}
                          options={options}
                          // statekey={"SelectindentType"}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Date</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            // onChange={(dateObj) => {
                            //     this.timeChanged(dateObj, "PoDate")
                            // }}
                            onChange={(dateObj) => {
                              this.dateChanged(dateObj, "PoDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "PoDate",
                              value: this.state.PoDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.indentNo !== "" &&
                  this.state.indentNo !== null ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Indent No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="indentNo"
                            className={classes.textField}
                            value={this.state.indentNo}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            style={{ height: 30 }}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Unit Conversion
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          defValue={this.state.unitConversion}
                          name={"unitConversion"}
                          value={this.state.unitConversion}
                          onChange={this.handleSelectedUnitValue}
                          // statekey={"unitConvertion"}
                          options={ucoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Credit Term
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={
                            getCookie(cons.USER_GRP_ID) == "99" ? true : false
                          }
                          id="creditTerm"
                          value={this.state.creditTerm}
                          className={classes.textField}
                          onChange={(event) => {
                            if (
                              event.target.value !== "" &&
                              Number.isInteger(Number(event.target.value)) &&
                              Number(event.target.value) < 366
                            ) {
                              this.setState({
                                creditTerm: event.target.value,
                              });
                            } else if (event.target.value === "") {
                              this.setState({
                                creditTerm: 0,
                              });
                            }
                          }}
                          fullWidth={true}
                          variant="outlined"
                          style={{ height: 30 }}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Days
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Delivery Timeline
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={
                            getCookie(cons.USER_GRP_ID) == "99" ? true : false
                          }
                          id="deliveryTimeLine"
                          className={classes.textField}
                          value={this.state.deliveryTimeLine}
                          onChange={this.onChangeValues}
                          iType={"number"}
                          fullWidth={true}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Days
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Expected Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            isDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "expecDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "expecDate",
                              value: this.state.expecDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Penality
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={
                            getCookie(cons.USER_GRP_ID) == "99" ? true : false
                          }
                          id="penality"
                          className={classes.textField}
                          value={this.state.penality}
                          onChange={this.onChangeValues}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"selectedVehicleType"}
                          defValue={this.state.selectedVehicleType}
                          isRefresh={this.state.reloadSelect}
                          setIsRefresh={this.afterReloadSelect}
                          onSelect={this.onSelect}
                          getName={this.getVehicleQlty.bind(this)}
                          stateKey="selectedVehicleType"
                          staticData={this.state.vehicleTypes}
                          //  url={serverApi.SELECT_VEHICLE_TYPE+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                          value={this.state.selectedVehicleType}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle Quantity
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={
                            getCookie(cons.USER_GRP_ID) == "99" ? true : false
                          }
                          id="vehicleQuantity"
                          className={classes.textField}
                          value={this.state.vehicleQuantity}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          style={{ height: 30 }}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Channel Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          defValue={this.state.channelCode}
                          onSelect={this.onSelect}
                          // getName={this.getVehicleQlty.bind(this)}
                          stateKey="channelCode"
                          staticData={ChannelTypes}
                          value={this.state.channelCode}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.channelCode === "I" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Contract No
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="contrctNo"
                            className={classes.textField}
                            value={this.state.contrctNo}
                            onChange={this.onChangeValues}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.channelCode === "I" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Contract Date
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "contractDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "contractDate",
                                value: this.state.contractDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.channelCode === "I" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Corrency Code
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="currencyCode"
                            className={classes.textField}
                            value={this.state.currencyCode}
                            onChange={this.onChangeValues}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.channelCode === "I" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Conversion Rate
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="conversationRate"
                            className={classes.textField}
                            value={this.state.conversationRate}
                            onChange={this.onChangeValues}
                            InputProps={inputFocus}
                            iType={"number"}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Mukam
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"selectedMukam"}
                          defValue={this.state.selectedMukam}
                          onSelect={this.onSelect}
                          isReload={this.state.reloadSelect}
                          setIsReload={this.afterReloadSelect}
                          getName={this.getmukamName}
                          // isRefresh={this.state.loading}
                          stateKey="selectedMukam"
                          url={
                            serverApi.SEARCH_MUKAM + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.selectedMukam}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"supplier_Id"}
                          defValue={this.state.supplier_Id}
                          onSelect={this.onSelect}
                          getName={this.getsupplierName}
                          isRefresh={this.state.loadsupCode}
                          stateKey="supplier_Id"
                          setIsRefresh={this.afterRefreshItemGrp}
                          url={
                            serverApi.DATATABLE_SERVICE_URL +
                            "getSuppListByMukamId/" +
                            this.state.selectedMukam +
                            "/company/" +
                            getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.supplier_Id}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Broker
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"broker_Id"}
                          defValue={this.state.broker_Id}
                          onSelect={this.onSelect}
                          stateKey="broker_Id"
                          isRefresh={this.state.loadBorker}
                          getName={this.brokername}
                          setIsRefresh={this.afterRefreshbroker}
                          url={
                            serverApi.GET_BROKERS_BY_SUPPLIER +
                            this.state.supplier_Id
                          }
                          value={this.state.broker_Id}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.broker_Id > 0 ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Brokerage Rate
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="brokerageRate"
                            className={classes.textField}
                            value={this.state.brokerageRate}
                            onChange={this.onChangeValues}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.broker_Id > 0 ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Brokerage Percentage
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="brokeragePercentage"
                            className={classes.textField}
                            value={this.state.brokeragePercentage}
                            onChange={this.onChangeValues}
                            InputProps={inputFocus}
                            iType={"number"}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            //iType={"Number"}
                            // style={{height: 30}}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            //  style={{height: 30}}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <br />

                  <br />
                  {/*
                                    <ItemGrid xs={12} md={6}>
                                        <br/>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    To
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>

                                                <p></p>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}>
                                        <br/>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={12}>
                                                <InputLabel className={classes.label}>
                                                    Shipping Address:
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={12}>
                                                <p>
                                                    NELLIMARLA JUTE MILLS CO LTD
                                                    <br/>
                                                    Admin.Office:- 3A,Shakeseare Sarani,Kolkata-70017
                                                    <br/>
                                                    Mills: Cheepurupalli - Nellimarla Rd, Nellimarla,AndraPradesh 535217, India
                                                    <br/>
                                                    Tel:Office : +91-033-4010300 Mill:2501-18365
                                                    <br/>

                                                </p>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                  <br />

                  <ItemGrid xs={12} sm={12}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.invoiceList}
                          loading={this.state.loading}
                          disableActions={
                            this.state.poStatus === "3" ||
                            this.state.poStatus === "4" ||
                            this.state.poStatus === "5" ||
                            this.state.poStatus === "6"
                          }
                          columns={columns}
                        />
                      </ItemGrid>

                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.withOrWithout === 1
                                  ? this.state.statusDesc + " AUTO CREATED"
                                  : this.state.statusDesc}
                                <br />
                              </span>
                              {/* <br /> */}
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Modified:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.withOrWithout === 1
                                  ? this.state.modOndes + " AUTO CREATED"
                                  : " On " +
                                    this.state.modOndes +
                                    " By " +
                                    this.state.modBy}
                                <br />
                              </span>

                              {/*<span style={{fontStyle:"italic",fontWeight:400,color:"red"}}>Note:</span>*/}
                              {/*<span style={{fontSize:"12px",color:"red",fontStyle:"italic"}}>{"Unit & Mukam can't modified"}</span>*/}
                            </ItemGrid>

                            <ItemGrid xs={6}>
                              {this.state.poStatus !== "3" &&
                              this.state.poStatus !== "4" &&
                              this.state.poStatus !== "5" &&
                              this.state.poStatus !== "6" ? (
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              ) : null}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          {this.state.poStatus !== "3" ||
                          (this.state.poStatus !== "4" &&
                            this.state.poStatus !== "5" &&
                            this.state.poStatus !== "6") ? (
                            <Button
                              color={"info"}
                              right={true}
                              onClick={this.handleAddItem}
                            >
                              Add Item
                            </Button>
                          ) : null}
                        </ItemGrid>
                      )}
                      <ItemGrid
                        xs={12}
                        style={{
                          height: 1,
                          backgroundColor: "#00acc1",
                          marginTop: "15px",
                        }}
                      />
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={7} />
                      <ItemGrid xs={12} sm={2}>
                        <InputLabel className={classes.label}>
                          Freight Charges ( If Any )
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2}>
                        <TextField
                          id="frieghtCharges"
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          value={this.state.frieghtCharges}
                          variant="outlined"
                          style={{ height: 30 }}
                          iType={"number"}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={7} />
                      <ItemGrid xs={12} sm={2}>
                        <InputLabel className={classes.label}>
                          Net Total
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2}>
                        <TextField
                          disabled={true}
                          id="netTotal"
                          className={classes.textField}
                          value={this.state.netTotal}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          style={{ height: 30 }}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView &&
                  this.state.poStatus !== "4" &&
                  this.state.poStatus !== "3" ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}

                  {!this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <br />
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                          <br />
                        </ItemGrid>
                        <ItemGrid>
                          <br />
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({
                                isApprove: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve This PO..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isReject: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject This PO..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.updateView &&
                        this.state.poStatus !== "3" &&
                        this.state.poStatus !== "6" &&
                        this.state.approveStatus) ||
                      (this.state.updateView && this.state.poStatus === "1") ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onApproveButtonClick("");
                            }}
                          >
                            update
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {this.state.finalApprover &&
                      this.state.poStatus === "3" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isCancel: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Cancel This PO..!!"
                              );
                            }}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.finalApprover &&
                      this.state.poStatus === "3" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isClose: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Close This PO..!!"
                              );
                            }}
                          >
                            Close
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView && this.state.poStatus !== "6" ? (
                        <ItemGrid xs={12}>
                          <IconButton
                            color={"primary"}
                            right={true}
                            onClick={() => {
                              this.setState({
                                sendMail: true,
                              });
                              this.printDocument();
                            }}
                          >
                            <Email />
                          </IconButton>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView && this.state.poStatus !== "6" ? (
                        <ItemGrid xs={11}>
                          {" "}
                          <SamplePDF
                            printDocument={this.printDocument.bind(this)}
                          />{" "}
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                                     iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  autoOk={this.state.autoOk}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  preventOpenOnFocus={true}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                              >
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_GRP_ID) !== "99" &&
                        getCookie(cons.USER_GRP_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  url={
                                    serverApi.SEARCH_SUPPLIER +
                                    "/" +
                                    getCookie(cons.COMPANY_ID)
                                  }
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                PO No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="serach_poNo"
                                value={this.state.serach_poNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Mukam
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.mukamId}
                                id={"mukamId"}
                                onSelect={this.onSelect}
                                stateKey="mukamId"
                                url={
                                  serverApi.SEARCH_MUKAM +
                                  getCookie(cons.COMPANY_ID)
                                }
                                value={this.state.mukamId}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                staticData={
                                  getCookie(cons.USER_GRP_ID) == "99"
                                    ? statusOptions
                                    : null
                                }
                                defValue={this.state.status}
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {getCookie(cons.USER_GRP_ID) !== "99" ? (
                          <ItemGrid xs={12}>
                            <IconButton
                              onClick={this.handleCreate}
                              // round color="white"
                              color="success"
                              right={true}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12}>
                          <br />
                          <br />
                          <br />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.DATATABLE_SERVICE_URL + "getAllPOList"}
                        searchData={
                          getCookie(cons.USER_GRP_ID) === "99"
                            ? this.getSupplierData()
                            : this.getData()
                        }
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"jute_po_currentdate"}
                        actionType={VIEW}
                      />

                      {/* <Button onClick={()=>{
                                                       // this.props.history.push("/print")

                                                    }
                                                    }> print</Button>*/}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        poNum: this.state.poNo,
        userId: getCookie(cons.USERID),
        cipher: getCookie(cons.CIPHER),
      };
      if (getCookie(cons.COMPANY_ID) === "116") {
        requestList(
          serverApi.GET_PO_DETAILS,
          data,
          this.handlePrintRespForCompany116
        );
      } else requestList(serverApi.GET_PO_DETAILS, data, this.handlePrintResp);
      // getDataFromUrl(serverApi.GET_PO_DETAILS + this.state.hdrId + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handlePrintResp)

      //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
    }
  }

  handlePrintResp = (resp) => {
    var printHeaderValues = resp.poHeader;
    var modules = [];
    var lineItems = resp.poLineItemVo;
    var hasCrop = false;
    resp.poLineItemVo.forEach((prop) => {
      if (
        prop.cropYear !== null &&
        prop.cropYear !== "" &&
        prop.cropYear !== undefined
      )
        hasCrop = true;
    });
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var obj = {
          itemId: prop.itemId,
          juteType: prop.itemDesc,
          quality: prop.juteQuality,
          hsnCode: prop.hsnCode,
          bales_drums:
            printHeaderValues.juteUnit !== "LOOSE" ? prop.quantity : "0",
          weight: prop.actualQuantity,
          unit: prop.unitId,
          moisture: prop.allowableMoisturePercentage,
          rate: parseFloat(prop.rate).toFixed(2),
          amount: parseFloat(prop.valueWithoutTax).toFixed(2),
        };
        if (hasCrop) {
          obj = {
            cropYear: prop.cropYear,
            itemId: prop.itemId,
            juteType: prop.itemDesc,
            quality: prop.juteQuality,
            hsnCode: prop.hsnCode,
            bales_drums:
              printHeaderValues.juteUnit !== "LOOSE" ? prop.quantity : "0",
            weight: prop.actualQuantity,
            unit: prop.unitId,
            moisture: prop.allowableMoisturePercentage,
            rate: parseFloat(prop.rate).toFixed(2),
            amount: parseFloat(prop.valueWithoutTax).toFixed(2),
          };
        }
        modules.push(obj);
      });
    }
    var pageNo = 1;
    var companyName = getCookie(cons.COMPANY_TITLE);
    var address1 = getCookie(cons.COMPANY_ADDRESS);
    var address2 = getCookie(cons.COMPANY_ADDRESS2);
    var cinNo = getCookie(cons.COMPANY_CIN);
    var printTotalvalues = resp.totalValues;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.poDate;
    mr_date = mr_date + " ";
    var by_date = printHeaderValues.exceptedDate;
    by_date = by_date + " ";
    var credit_term = printHeaderValues.creditTerm + " ";
    if (printHeaderValues.creditTerm > 0) credit_term = credit_term + "DAYS";
    var poId = printHeaderValues.id;
    poId = poId + " ";
    var challan_no = printHeaderValues.challanNo;
    challan_no = challan_no + " ";
    var challan_date = printHeaderValues.challanDate;
    challan_date = challan_date + " ";
    var vehicleNo = printHeaderValues.lorryNo;
    vehicleNo = vehicleNo + " ";
    var SupplierName = " ";
    if (
      printHeaderValues.suppName !== undefined &&
      printHeaderValues.suppName !== null
    )
      SupplierName = printHeaderValues.suppName + " ";
    var brokerName = printHeaderValues.brokerName + " ";
    if (printHeaderValues.brokerName === null) brokerName = " ";
    var frightPaid = printHeaderValues.frieghtCharge;
    frightPaid = frightPaid + " ";
    var gateEntryDate = printHeaderValues.gateEntryDate;
    gateEntryDate = gateEntryDate + " ";
    var mukamName = this.state.mukamName;
    mukamName = mukamName + " ";

    var companyName = resp.companyName + " ";
    var address1 = resp.cAddr1 + " ";
    var address2 = resp.cAddr2 + " ";
    var noOfLorries = printHeaderValues.vehicleQuantity + " ";

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt === 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt === 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt === 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt === 3) {
      printcopy = "Triplicate";
    } else if (printCounnt === 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " ";
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    doc.text(companyName, xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    doc.text(190, 60, address1);
    doc.text(250, 80, address2);
    doc.setFontSize(16);
    //doc.setFontType("bold");
    var xOffset1 =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth("PURCHASE ORDER ") * doc.internal.getFontSize()) /
        2;

    doc.text("PURCHASE ORDER ", xOffset1, 110);

    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(30, 140, "PURCHASE ORDER NO");
    doc.text(140, 140, ":");
    doc.setFontType("normal");
    doc.text(150, 140, poId);
    doc.setFontType("bold");
    doc.text(420, 140, "PO DATE    ");
    doc.text(485, 140, ":");

    doc.setFontType("normal");
    doc.text(490, 140, mr_date);
    doc.setFontType("bold");
    doc.text(30, 160, "M/S");
    doc.text(140, 160, ":");
    doc.setFontType("normal");
    doc.text(150, 160, SupplierName);
    doc.setFontType("bold");
    doc.text(420, 160, "EXP.DATE ");
    doc.text(485, 160, ":");

    doc.setFontType("normal");
    doc.text(490, 160, by_date);
    doc.setFontType("bold");

    doc.text(30, 180, "SELLER/BROKER");
    doc.text(140, 180, ":");
    doc.setFontType("normal");
    doc.text(150, 180, brokerName);
    doc.setFontType("bold");
    doc.text(420, 180, "CREDIT TERM : ");
    doc.setFontType("normal");
    doc.text(490, 180, credit_term);
    doc.setFontType("bold");
    doc.text(30, 200, "NO OF LORRIES");
    doc.text(140, 200, ":");
    doc.setFontType("normal");
    doc.text(150, 200, noOfLorries);
    // doc.setFontType("bold");
    //doc.text(400, 180, 'DATE : ');
    //doc.setFontType("normal");
    // doc.text(440, 180, gateEntryDate );
    doc.setFontType("bold");

    /*  doc.text(30, 200, 'CHALLAN NO');
        doc.text(120, 200, ':');
        doc.setFontType("normal");
        doc.text(130, 200, challan_no);
        doc.setFontType("bold");
        doc.text(365, 200, 'CHALLAN DATE :');
        doc.setFontType("normal");
        doc.text(445, 200, challan_date);*/
    doc.setFontType("bold");
    /*   doc.text(30, 200, 'LORRY NO');
        doc.text(120, 200, ':');
        doc.setFontType("normal");
        doc.text(130, 200, vehicleNo);*/
    doc.setFontType("bold");
    doc.text(250, 220, "EX :");
    doc.setFontType("normal");
    doc.text(270, 220, mukamName);
    //    doc.text(60, 240, 'BALANCE LORRY FRIGHT PAID AT MILL RS'+' '+frightPaid);
    doc.text(30, 250, "Dear Sir/Madam,");
    doc.setFontSize(9);
    doc.text(30, 270, "We would like to place the following order : ");
    doc.setFontSize(8);
    doc.setFontType("bold");
    doc.cellInitialize();
    if (hasCrop) {
      var crop = doc.splitTextToSize("Crop Year", 28);
      doc.cell(30, 290, 30, 40, crop, "", "C");
    }
    var itemCode = doc.splitTextToSize("Item Code", 28);
    doc.cell(30, 290, 40, 40, itemCode, "", "C");
    var desc = doc.splitTextToSize("Description", 45);
    doc.cell(70, 290, 100, 40, desc, "", "C");
    var quality = doc.splitTextToSize("Quality", 35);
    doc.cell(170, 290, 60, 40, quality, "", "C");
    var hsnCode = doc.splitTextToSize("HSN/SAC Code", 40);
    doc.cell(210, 290, 40, 40, hsnCode, "", "C");
    var bales_drums = doc.splitTextToSize("Bales", 48);
    doc.cell(310, 290, 50, 40, bales_drums, "", "C");
    var weight = doc.splitTextToSize("Weight", 40);
    doc.cell(250, 290, 60, 40, weight, "", "C");
    var unit = doc.splitTextToSize("UOM", 35);
    doc.cell(350, 290, 30, 40, unit, "", "C");
    var moisture = doc.splitTextToSize("Moisture Allowed(%)", 38);
    doc.cell(350, 290, 40, 40, moisture, "", "C");
    var rate = doc.splitTextToSize("Rate per QNT (INR)", 28);
    doc.cell(400, 290, 30, 40, rate, "", "C");
    var amount = doc.splitTextToSize("Amount", 58);
    doc.cell(450, 290, 60, 40, amount, "", "C");
    doc.cellInitialize();

    var cellWidths = ["", 40, 100, 60, 40, 50, 60, 30, 40, 30, 60];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "left",
      "right",
      "right",
      "left",
      "right",
      "left",
      "right",
    ];
    if (hasCrop) {
      cellWidths = ["", 30, 40, 100, 60, 40, 50, 60, 30, 40, 30, 60];
      cellTextaligns = [
        "",
        "left",
        "left",
        "left",
        "left",
        "right",
        "right",
        "left",
        "right",
        "right",
      ];
    }
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 305;
      var i = 0;
      var totalQty = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        totalQty = totalQty + parseFloat(prop.bales_drums);
        doc.cellInitialize();
        if (i === 27) {
          k = 325;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          if (hasCrop) {
            var crop = doc.splitTextToSize("Crop Year", 28);
            doc.cell(30, 290, 30, 40, crop, "", "C");
          }
          var itemCode = doc.splitTextToSize("Item Code", 28);
          doc.cell(30, 290, 40, 40, itemCode, "", "C");
          var desc = doc.splitTextToSize("Description", 45);
          doc.cell(70, 290, 100, 40, desc, "", "C");
          var quality = doc.splitTextToSize("Quality", 35);
          doc.cell(170, 290, 60, 40, quality, "", "C");
          var hsnCode = doc.splitTextToSize("HSN/SAC Code", 40);
          doc.cell(210, 290, 40, 40, hsnCode, "", "C");
          var bales_drums = doc.splitTextToSize("Bales", 50);
          doc.cell(310, 290, 60, 40, bales_drums, "", "C");
          var weight = doc.splitTextToSize("Weight", 40);
          doc.cell(250, 290, 60, 40, weight, "", "C");
          var unit = doc.splitTextToSize("UOM", 35);
          doc.cell(350, 290, 30, 40, unit, "", "C");
          var moisture = doc.splitTextToSize("Moisture Allowed(%)", 38);
          doc.cell(350, 290, 40, 40, moisture, "", "C");
          var rate = doc.splitTextToSize("Rate per QNT (INR)", 28);
          doc.cell(400, 290, 30, 40, rate, "", "C");
          var amount = doc.splitTextToSize("Amount", 58);
          doc.cell(450, 290, 60, 40, amount, "", "C");
          doc.cellInitialize();
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemCode") {
              var size = 90;
            } else {
              var size = 45;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
          }

          return doc.cell(
            30,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    var grandTotals = printTotalvalues;
    /*  if(grandTotals.balesTot==0 || grandTotals.balesTot==0.0){
            var balesTot=' ';
        }else{
            var balesTot=grandTotals.balesTot;
        }
        if(grandTotals.advisedWeightTot==0 || grandTotals.advisedWeightTot==0.0){
            var advisedWeightTot=' ';
        }else{
            var advisedWeightTot=grandTotals.advisedWeightTot;
        }
        if(grandTotals.millWeightTot==0 || grandTotals.millWeightTot==0.0){
            var millWeightTot=' ';
        }else{
            var  millWeightTot=grandTotals.millWeightTot;
        }
        if(grandTotals.shortageTot==0 || grandTotals.shortageTot==0.0){
            var shortageTot=' ';
        }
        else{
            var shortageTot=grandTotals.shortageTot;
        }
        if(grandTotals.approvedWeightTot==0 || grandTotals.approvedWeightTot==0.0){
            var approvedWeightTot=' ';
        }
        else{
            var approvedWeightTot=grandTotals.approvedWeightTot;
        }
*/
    var totalWeight = doc.splitTextToSize(printHeaderValues.weight + " ", 50);
    var totalamount = doc.splitTextToSize(
      parseFloat(printHeaderValues.valueWithoutTax).toFixed(2) + " ",
      50
    );
    var totqty = doc.splitTextToSize(totalQty + " ", 50);

    doc.cellInitialize();
    doc.setFontType("bold");
    if (hasCrop) {
      doc.cell(30, k + 25, 30, 30, " ", "", "C");
    }
    doc.cell(30, k + 25, 40, 30, " ", "", "right");
    doc.cell(105, k + 25, 100, 30, " ", "", "right");
    doc.cell(147, k + 25, 60, 30, " ", "", "right");
    doc.cell(189, k + 25, 40, 30, " ", "", "right");
    doc.cell(231, k + 25, 50, 30, totqty, "", "right");
    doc.cell(189, k + 25, 60, 30, totalWeight, "", "right");
    doc.cell(271, k + 25, 30, 30, " ", "", "right");
    doc.cell(271, k + 25, 40, 30, " ", "", "right");
    doc.cell(322, k + 25, 30, 30, " ", "", "right");
    doc.cell(364, k + 25, 60, 30, totalamount, "", "right");
    doc.setFontType("normal");
    doc.setFontSize(9);

    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str.toUpperCase();
    }
    doc.text(
      30,
      k + 70,
      "IN WORDS : " +
        inWords(printHeaderValues.valueWithoutTax) +
        " RUPEES ONLY."
    );

    doc.setFontSize(10);
    doc.text(30, k + 140, "Note : ");
    doc.text(30, k + 160, "1. Please submit invoice in tripilicate.");
    //  doc.text(30, k + 180, '2. This purchase order can be cancelled by us at any time. ');
    doc.text(30, k + 200, "2. Late delivery will be subject to penality.");
    //  doc.text(30, k + 220, '4. Taxes as applicable and the prices mentioned are excluded taxes');

    doc.text(350, k + 260, "FOR" + " " + companyName);

    //doc.text(400, k + 180, 'Authorised Signature');
    doc.setFontSize(8);
    doc.setFontType("bold");
    var note =
      "Note* : This is a computer generated print, Signature is not required.";
    var NotexOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(note) * doc.internal.getFontSize()) / 2;
    doc.text(note, NotexOffset, k + 290);

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 330, newdat);
    doc.text(500, k + 330, printBy);
    addWaterMark(doc, printcopy);

    if (!this.state.sendMail) doc.save("PO_" + poId + ".pdf");
    else {
      const filename = "PO_" + poId + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      console.log("poId", this.state.poNo);

      const formData = new FormData();
      formData.append("poId", this.state.poNo);
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", getCookie(cons.COMPANY_ID));
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("fileName", filename);

      var URL = serverApi.PO_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handlePOMail);

      this.setState({
        sendMail: false,
      });
    }
  };
  handlePrintRespForCompany116 = (resp) => {
    var printHeaderValues = resp.poHeader;
    var modules = [];
    var lineItems = resp.poLineItemVo,
      hasCrop = false;
    var marka = [];
    resp.poLineItemVo.forEach((prop) => {
      if (
        prop.cropYear !== null &&
        prop.cropYear !== "" &&
        prop.cropYear !== undefined
      )
        hasCrop = true;
    });
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        if (prop.marka !== "" && prop.marka !== null) {
          var has = false;
          marka.forEach((m) => {
            if (m === prop.marka) has = true;
          });
          if (!has) marka.push(prop.marka);
        }
        var obj = {
          itemId: prop.itemId,
          juteType: prop.itemDesc,
          quality: prop.juteQuality,
          //   hsnCode:prop.hsnCode,
          bales_drums:
            printHeaderValues.juteUnit !== "LOOSE" ? prop.quantity : "0",
          weight: prop.actualQuantity,
          unit: prop.unitId,
          // moisture:prop.allowableMoisturePercentage,
          rate: parseFloat(prop.rate).toFixed(2),
          amount: parseFloat(prop.valueWithoutTax).toFixed(2),
        };
        if (hasCrop) {
          obj = {
            cropYear: prop.cropYear,
            itemId: prop.itemId,
            juteType: prop.itemDesc,
            quality: prop.juteQuality,
            //   hsnCode:prop.hsnCode,
            bales_drums:
              printHeaderValues.juteUnit !== "LOOSE" ? prop.quantity : "0",
            weight: prop.actualQuantity,
            unit: prop.unitId,
            // moisture:prop.allowableMoisturePercentage,
            rate: parseFloat(prop.rate).toFixed(2),
            amount: parseFloat(prop.valueWithoutTax).toFixed(2),
          };
        }
        modules.push(obj);
      });
    }
    var pageNo = 1;
    var companyName = getCookie(cons.COMPANY_TITLE);
    var address1 = getCookie(cons.COMPANY_ADDRESS);
    var address2 = getCookie(cons.COMPANY_ADDRESS2);
    var cinNo = getCookie(cons.COMPANY_CIN);
    var printTotalvalues = resp.totalValues;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.poDate;
    mr_date = mr_date + " ";
    var by_date = printHeaderValues.exceptedDate;
    by_date = by_date + " ";
    var credit_term = printHeaderValues.creditTerm + " ";
    if (printHeaderValues.creditTerm > 0) credit_term = credit_term + "DAYS";
    var poId = printHeaderValues.id;
    poId = poId + " " + "/45D";
    var challan_no = printHeaderValues.challanNo;
    challan_no = challan_no + " ";
    var challan_date = printHeaderValues.challanDate;
    challan_date = challan_date + " ";
    var vehicleNo = printHeaderValues.lorryNo;
    vehicleNo = vehicleNo + " ";
    var SupplierName = " ";
    if (
      printHeaderValues.suppName !== undefined &&
      printHeaderValues.suppName !== null
    )
      SupplierName = printHeaderValues.suppName + " ";
    var brokerName = printHeaderValues.brokerName + " ";
    if (printHeaderValues.brokerName === null) brokerName = " ";
    var frightPaid = printHeaderValues.frieghtCharge;
    frightPaid = frightPaid + " ";
    var gateEntryDate = printHeaderValues.gateEntryDate;
    gateEntryDate = gateEntryDate + " ";
    var mukamName = this.state.mukamName;
    mukamName = mukamName + " ";

    var companyName = resp.companyName + " ";
    var address1 = resp.cAddr1 + " ";
    var address2 = resp.cAddr2 + " ";
    var noOfLorries = printHeaderValues.vehicleQuantity + " ";

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt === 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt === 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt === 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt === 3) {
      printcopy = "Triplicate";
    } else if (printCounnt === 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " ";
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    doc.text(companyName, xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    doc.text(190, 60, address1);
    doc.text(250, 80, address2);
    doc.setFontSize(16);
    //doc.setFontType("bold");
    var xOffset1 =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth("PURCHASE ORDER ") * doc.internal.getFontSize()) /
        2;

    doc.text("PURCHASE ORDER ", xOffset1, 110);

    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(30, 140, "PURCHASE ORDER NO");
    doc.text(140, 140, ":");
    doc.setFontType("normal");
    doc.text(150, 140, poId);
    doc.setFontType("bold");
    doc.text(420, 140, "PO DATE    ");
    doc.text(485, 140, ":");

    doc.setFontType("normal");
    doc.text(490, 140, mr_date);
    doc.setFontType("bold");
    doc.text(30, 160, "M/S");
    doc.text(140, 160, ":");
    doc.setFontType("normal");
    doc.text(150, 160, SupplierName);
    doc.setFontType("bold");
    doc.text(420, 160, "EXP.DATE ");
    doc.text(485, 160, ":");

    doc.setFontType("normal");
    doc.text(490, 160, by_date);
    doc.setFontType("bold");

    doc.text(30, 180, "SELLER/BROKER");
    doc.text(140, 180, ":");
    doc.setFontType("normal");
    doc.text(150, 180, brokerName);
    /*  doc.setFontType("bold");
        doc.text(420, 180, 'CREDIT TERM : ');
        doc.setFontType("normal");
        doc.text(490, 180, credit_term);*/
    doc.setFontType("bold");
    doc.text(30, 200, "NO OF LORRIES");
    doc.text(140, 200, ":");
    doc.setFontType("normal");
    doc.text(150, 200, noOfLorries);
    // doc.setFontType("bold");
    //doc.text(400, 180, 'DATE : ');
    //doc.setFontType("normal");
    // doc.text(440, 180, gateEntryDate );
    doc.setFontType("bold");
    doc.text(30, 220, "Marka");
    doc.text(140, 220, ":");
    doc.setFontType("normal");
    var marVal = "";
    marka.forEach((val, i) => {
      if (i === 0) marVal = val;
      else marVal = marVal + "," + val;
    });
    doc.text(150, 220, marVal);
    /*  doc.text(30, 200, 'CHALLAN NO');
          doc.text(120, 200, ':');
          doc.setFontType("normal");
          doc.text(130, 200, challan_no);
          doc.setFontType("bold");
          doc.text(365, 200, 'CHALLAN DATE :');
          doc.setFontType("normal");
          doc.text(445, 200, challan_date);*/
    doc.setFontType("bold");
    /*   doc.text(30, 200, 'LORRY NO');
           doc.text(120, 200, ':');
           doc.setFontType("normal");
           doc.text(130, 200, vehicleNo);*/
    doc.setFontType("bold");
    doc.text(250, 240, "EX :");
    doc.setFontType("normal");
    doc.text(270, 240, mukamName);
    //    doc.text(60, 240, 'BALANCE LORRY FRIGHT PAID AT MILL RS'+' '+frightPaid);
    doc.text(30, 250, "Dear Sir/Madam,");
    doc.setFontSize(9);
    doc.text(30, 270, "We would like to place the following order : ");
    doc.setFontSize(8);
    doc.setFontType("bold");
    doc.cellInitialize();
    if (hasCrop) {
      var crop = doc.splitTextToSize("Crop Year", 28);
      doc.cell(30, 290, 30, 40, crop, "", "C");
    }

    var itemCode = doc.splitTextToSize("Item Code", 28);
    doc.cell(30, 290, 40, 40, itemCode, "", "C");
    var desc = doc.splitTextToSize("Description", 45);
    doc.cell(70, 290, 100, 40, desc, "", "C");
    var quality = doc.splitTextToSize("Quality", 35);
    doc.cell(170, 290, 70, 40, quality, "", "C");
    /*   var hsnCode = doc.splitTextToSize('HSN/SAC Code', 40);
        doc.cell(210, 290, 40, 40, hsnCode, '', 'C');*/
    var bales_drums = doc.splitTextToSize("Bales", 50);
    doc.cell(310, 290, 60, 40, bales_drums, "", "C");
    var weight = doc.splitTextToSize("Weight", 40);
    doc.cell(250, 290, 70, 40, weight, "", "C");
    var unit = doc.splitTextToSize("UOM", 35);
    doc.cell(350, 290, 30, 40, unit, "", "C");
    /* var moisture = doc.splitTextToSize('Moisture Allowed(%)', 38);
        doc.cell(350, 290, 40, 40, moisture, '', 'C');*/
    var rate = doc.splitTextToSize("Rate per QNT (INR)", 35);
    doc.cell(400, 290, 60, 40, rate, "", "C");
    var amount = doc.splitTextToSize("Amount", 35);
    doc.cell(450, 290, 70, 40, amount, "", "C");
    doc.cellInitialize();

    var cellWidths = ["", 40, 100, 70, 60, 70, 30, 60, 70];

    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "right",
      "right",
      "left",
      "right",
      "right",
    ];
    if (hasCrop) {
      cellWidths = ["", 30, 40, 100, 70, 60, 70, 30, 60, 70];
      cellTextaligns = [
        "",
        "left",
        "left",
        "left",
        "left",
        "right",
        "right",
        "left",
        "right",
        "right",
      ];
    }
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 305;
      var i = 0;
      var totalQty = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        if (prop.bales_drums > 0)
          totalQty = totalQty + parseFloat(prop.bales_drums);
        doc.cellInitialize();
        if (i === 27) {
          k = 325;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          if (hasCrop) {
            var crop = doc.splitTextToSize("Crop Year", 28);
            doc.cell(30, 290, 30, 40, crop, "", "C");
          }

          var itemCode = doc.splitTextToSize("Item Code", 28);
          doc.cell(30, 290, 40, 40, itemCode, "", "C");
          var desc = doc.splitTextToSize("Description", 45);
          doc.cell(70, 290, 100, 40, desc, "", "C");
          var quality = doc.splitTextToSize("Quality", 35);
          doc.cell(170, 290, 70, 40, quality, "", "C");
          /*   var hsnCode = doc.splitTextToSize('HSN/SAC Code', 40);
                       doc.cell(210, 290, 40, 40, hsnCode, '', 'C');*/
          var bales_drums = doc.splitTextToSize("Bales", 50);
          doc.cell(310, 290, 60, 40, bales_drums, "", "C");
          var weight = doc.splitTextToSize("Weight", 40);
          doc.cell(250, 290, 70, 40, weight, "", "C");
          var unit = doc.splitTextToSize("UOM", 35);
          doc.cell(350, 290, 30, 40, unit, "", "C");
          /* var moisture = doc.splitTextToSize('Moisture Allowed(%)', 38);
                     doc.cell(350, 290, 40, 40, moisture, '', 'C');*/
          var rate = doc.splitTextToSize("Rate per QNT (INR)", 35);
          doc.cell(400, 290, 60, 40, rate, "", "C");
          var amount = doc.splitTextToSize("Amount", 35);
          doc.cell(450, 290, 70, 40, amount, "", "C");
          doc.cellInitialize();
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemCode") {
              var size = 90;
            } else {
              var size = 45;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
          }

          return doc.cell(
            30,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    var grandTotals = printTotalvalues;
    /*  if(grandTotals.balesTot==0 || grandTotals.balesTot==0.0){
              var balesTot=' ';
          }else{
              var balesTot=grandTotals.balesTot;
          }
          if(grandTotals.advisedWeightTot==0 || grandTotals.advisedWeightTot==0.0){
              var advisedWeightTot=' ';
          }else{
              var advisedWeightTot=grandTotals.advisedWeightTot;
          }
          if(grandTotals.millWeightTot==0 || grandTotals.millWeightTot==0.0){
              var millWeightTot=' ';
          }else{
              var  millWeightTot=grandTotals.millWeightTot;
          }
          if(grandTotals.shortageTot==0 || grandTotals.shortageTot==0.0){
              var shortageTot=' ';
          }
          else{
              var shortageTot=grandTotals.shortageTot;
          }
          if(grandTotals.approvedWeightTot==0 || grandTotals.approvedWeightTot==0.0){
              var approvedWeightTot=' ';
          }
          else{
              var approvedWeightTot=grandTotals.approvedWeightTot;
          }
  */

    var totalWeight = doc.splitTextToSize(
      printHeaderValues.weight.toFixed(2) + " ",
      50
    );
    var totalamount = doc.splitTextToSize(
      parseFloat(printHeaderValues.valueWithoutTax).toFixed(2) + " ",
      50
    );
    var totqty = doc.splitTextToSize(totalQty + " ", 50);

    doc.cellInitialize();
    doc.setFontType("bold");
    if (hasCrop) {
      doc.cell(30, k + 25, 30, 30, " ", "", "right");
    }
    doc.cell(30, k + 25, 40, 30, " ", "", "right");
    doc.cell(105, k + 25, 100, 30, " ", "", "right");
    doc.cell(147, k + 25, 70, 30, " ", "", "right");
    doc.cell(231, k + 25, 60, 30, totqty, "", "right");
    doc.cell(189, k + 25, 70, 30, totalWeight, "", "right");
    doc.cell(271, k + 25, 30, 30, " ", "", "right");
    //   doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
    doc.cell(322, k + 25, 60, 30, " ", "", "right");
    doc.cell(364, k + 25, 70, 30, totalamount, "", "right");
    doc.setFontType("normal");
    doc.setFontSize(9);

    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str.toUpperCase();
    }
    doc.text(
      30,
      k + 70,
      "IN WORDS : " +
        inWords(printHeaderValues.valueWithoutTax) +
        " RUPEES ONLY."
    );

    doc.setFontSize(10);
    doc.text(30, k + 140, "Note : ");
    doc.text(30, k + 160, "1. Please submit invoice in tripilicate.");
    //  doc.text(30, k + 180, '2. This purchase order can be cancelled by us at any time. ');
    doc.text(30, k + 200, "2. Late delivery will be subject to penality.");
    //  doc.text(30, k + 220, '4. Taxes as applicable and the prices mentioned are excluded taxes');

    doc.text(350, k + 260, "FOR" + " " + companyName);

    //doc.text(400, k + 180, 'Authorised Signature');
    doc.setFontSize(8);
    doc.setFontType("bold");
    var note =
      "Note* : This is a computer generated print, Signature is not required.";
    var NotexOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(note) * doc.internal.getFontSize()) / 2;
    doc.text(note, NotexOffset, k + 290);

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 330, newdat);
    doc.text(500, k + 330, printBy);
    addWaterMark(doc, printcopy);

    if (!this.state.sendMail) doc.save("PO_" + poId + ".pdf");
    else {
      const filename = "PO_" + poId + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      console.log("poId", this.state.poNo);

      const formData = new FormData();
      formData.append("poId", this.state.poNo);
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", getCookie(cons.COMPANY_ID));
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("fileName", filename);

      var URL = serverApi.PO_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handlePOMail);

      this.setState({
        sendMail: false,
      });
    }
  };

  handlePOMail = (resp) => {
    console.log(resp);
    if (resp.status) {
      if (this.state.finalApprover && this.state.poStatus !== "3") {
        this.createShowMessage(this.state.finalApprovalMsg, resp.status);
        this.setState({
          finalApprovalMsg: "",
        });
      } else {
        this.createShowMessage(resp.message, false);
      }
    } else {
      if (this.state.finalApprover && this.state.poStatus !== "3") {
        this.createShowMessage(this.state.finalApprovalMsg, resp.status);
        this.setState({
          finalApprovalMsg: "",
        });
      } else {
        this.createShowMessage(resp.message, resp.status);
      }
      // this.createShowMessage(resp.message,resp.status)
    }
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  textField: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },
  notchedOutline: {},
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(JutePO);
