import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";


class DeptMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "recId",
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }

    handleViewClick = (id) => {

        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data =  {
                "recId": id,
                "cipher":getCookie(cons.CIPHER)
            };
            requestList(serverApi.DEPARTMENT_MASTER_DETAILS_BY_ID,data,this.handleViewClickResp)
           // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
        }
    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            })
        }else {
            this.showMessage(resp.message,resp.status);
        }
    };

    handleViewClickResp = (resp) => {
        var header = resp.data;

        if(resp.status){
            if (header !== null) {
                this.setState({
                    updateView: true,
                    isCreated: true,
                    deptCode:header.id,
                    deptName:header.departmentName,
                    recId:header.recId,
                    createdBy: header.createdBy,
                    createdOn: header.createdOnn,
                });

            }

        }


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data = {
            "companyId":getCookie(cons.COMPANY_ID),
            "cipher":getCookie(cons.CIPHER)
        };
        return data;
    };



    onChangeValues = (event) => {
        var value = event.target.value;

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {

            this.setState({
                [stateKey]: selectedValue
            });
    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            deptCode:"",
            deptName:"",
            createdBy:"",
            createdOn: ""
        });
    }

    hideAlertMessage() {
        this.setState({

            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            deptCode:"",
            deptName:"",
            createdBy:"",
            createdOn: ""

        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id+"/"+getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    onUpdateButtonClick = () => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data = {
                 "id": this.state.deptCode,
                 "orgId": getCookie(cons.COMPANY_ID),
                "departmentName":this.state.deptName,
                "companyId": getCookie(cons.COMPANY_ID),
                "updatedBy": getCookie(cons.USERID),
                "cipher":getCookie(cons.CIPHER),
                "recId":this.state.recId
                /* "department": {
                     "id": this.state.deptCode,
                     "orgId": getCookie(cons.COMPANY_ID),
                     "departmentName":this.state.deptName,
                    // "companyId": getCookie(cons.COMPANY_ID)
                 },
                 "postAuthenticationDto": {
                     "cipher":getCookie(cons.CIPHER)                }*/

            };
            if (this.state.companyId !== '' && this.state.deptName !== "") {
                this.handleLoading();
                requestList(serverApi.DEPARTMENT_MASTER_UPDATE, data, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data = {
               // "id": this.state.deptCode,
               // "orgId": getCookie(cons.COMPANY_ID),
                "departmentName":this.state.deptName,
                "companyId": getCookie(cons.COMPANY_ID),
                "createdBy": getCookie(cons.USERID)
              /*  "department": {
                   // "orgId": this.state.companyId,
                    "departmentName": this.state.deptName,
                    "createdBy": getCookie(cons.USERID)
                },
                "postAuthenticationDto": {
                    "companyId": getCookie(cons.COMPANY_ID),
                    "cipher":getCookie(cons.CIPHER)
                }*/
            };
                if (this.state.companyId !== '' && this.state.deptName !== "") {
                    this.handleLoading();
                    requestList(serverApi.DEPARTMENT_MASTER_ADD, data, this.handleAddIndent)
                }
                else {
                    this.basicAlert("Please Enter All Mandatory Fields..!!");
                }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };


    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    render() {
        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {

                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}



                                    {this.state.updateView? <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Department Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="deptCode"
                                                    value={this.state.deptCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>:null}

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Department Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="deptName"
                                                    value={this.state.deptName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

{/*
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Company<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"companyId"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.companyId}
                                                    // getName={this.getDeptName}
                                                    stateKey="companyId"
                                                    staticData={JSON.parse(getCookie(cons.COMPANIES_LIST))}
                                                    value={this.state.companyId}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}



                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>


                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                {!this.state.updateView ?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>:
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Update</Button>

                                                }
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                url={serverApi.DEPARTMENT_MASTER_TABLE_LIST}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                               // showDelete={true}
                                              //  onDeleteClick={this.handleDeleteClick}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(DeptMaster);
