import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import PersonalIcon from "../../assets/icons/personal-information.png";
import OfficialIcon from "../../assets/icons/info.png";
import EducationIcon from "../../assets/icons/educational.png";
import ExperienceIcon from "../../assets/icons/experience.png";
import DocumentationIcon from "../../assets/icons/documents.png";
import CriminalIcon from "../../assets/icons/criminal-record.png";
import SalaryIcon from "../../assets/icons/salary.png";
import ShiftIcon from "../../assets/icons/shifts.png";
import LeaveIcon from "../../assets/icons/leave.png";
import EnrollmentIcon from "../../assets/icons/enrollment.png";
import LetterIcon from "../../assets/icons/letter.png";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import Attachment from "@material-ui/icons/AttachFile";
import Add from "@material-ui/icons/Add";
import Tick from "@material-ui/icons/CheckCircle";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import cons, { serverConfig, serverApi, serverVars } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import "jspdf-autotable";
import {
	getDataFromUrl,
	getFileDownload,
	requestList,
	uploadFile,
	uploadFormdataWithImage,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";

import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import NavPills from "../../components/NavPills/NavPills";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import {
	acomadationType,
	BloodGroups,
	EducationTypes,
	GenderType,
	MaritalStatus,
	UserStatus,
	Weeks,
} from "../TableData";
import { VIEW } from "../../ui-actions/UiActions";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox";
import { Check } from "@material-ui/icons";
import LineItemTable from "../CustumComponents/LineItemTable";
import Edit from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import $ from "jquery";
import { liteGreen } from "../../assets/jss/material-dashboard-pro-react";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

class EmployeeDatabase extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			basicAlert: null,
			isCreated: false,
			fromDate: moment().format("DD-MM-YYYY"),
			todate: moment().format("DD-MM-YYYY"),
			status: "",
			isRefresh: false,
			isLoading: false,
			itemViewType: "ebId",
			payCall: true,
			year: moment().format("YYYY"),
			dataTable: null,
			workerEducation: [],
			workerDocuments: [],
			salaryList: [],
			bioMetricEmpMapList: [],
			employeeShiftMapList: [],
			leavePolicyList: [],
			leavePolicyId: "",
			mappingId: "",
			deptCode: 0,
			empCode: "",
			empName: "",
			esiNum: "",
			payScheme: 0,
			pfNum: "",
			reporting: 0,
			hrmsAccess: false,
			ebNo: "",
			workerName: "",
			middleName: "",
			lastName: "",
			sex: "",
			dateOfBirth: "",
			fatherOrHusbandName: "",
			empCity: "",
			empPincode: "",
			empAddr: "",
			phoneNo: "",
			mobileNo: "",
			emergencyMobileNo: "",
			officeMobileNo: "",
			maritalStatus: "",
			panNo: "",
			bloodGroup: "",
			nomineeName: "",
			nomineeRelationship: "",
			religion: "",
			personalBankName: "",
			personalBankIFSC: "",
			personalBankAcNo: "",
			personalBankBranch: "",
			imageFile: null,
			imagePreviewUrl: null,
			deptId: "",
			branchId: "",
			paySchemeId: "",
			dateOfJoining: "",
			empLevelId: 0,
			workLocation: "",
			cataId: 0,
			designationId: 0,
			pfNo: "",
			esiNo: "",
			officialEmailId: "",
			reportingToEbId: 0,
			appRoleId: 0,
			portalRoleId: 0,
			pfUANName: "",
			empStatus: "",
			active: "",
			dateOfPfJoining: "",
			contractorId: 0,
			licNo: "",
			retiredDate: "",
			officeBankName: "",
			officeBankBranch: "",
			officeBankIFSC: "",
			officeBankAcNo: "",
			quarterNo: "",
			userName: "",
			hrmsFullAccess: false,
			ledgerGroupId: "",
			parentLedger: "",
			facebookId: "",
			state: "",
			country: "",
			nationality: "",
			accommodatioType: "",
			contractDurationInMonths: "",
			prohibitionInMonths: "",
			passportNo: "",
			passportIssueDate: "",
			passportExpiryDate: "",
			drivingLicenseNo: "",
			drivingIssueDate: "",
			drivingExpiryDate: "",
			visaNo: "",
			visaIssueDate: "",
			effectFrom: "",
			startIndex: 1,
			lastIndex: 10,
			empCode1: "",
			approverButton: false,
			leavePolicieList: [],
			paySchemeList: [],
			paySchemeListNoCalData: [],
			payComponentData: [],
			empPayschemeId: "",
			paySchemeListEarningsData: [],
			paySchemeListDeductionData: [],
			paySchemeListSummaryData: [],
			paySchemeLineData: [],
			paySchemeIdNew: "",
			loadAll: true,
			faceImageTypeId: "",
			fileName: "",
			designationIdDesc: "",
			locationList: [],
			criminalRecordList: [],
			criminalQuestions: [],
			workerExperience: [],
		};
		this.hideAlert = this.hideAlert.bind(this);
		this.hideAlertMessage = this.hideAlertMessage.bind(this);
		this.onChangeValues = this.onChangeValues.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleFileClick = this.handleFileClick.bind(this);
	}

	componentDidMount() {
		var dataTable = {
			data: [],
			filterTable: true,
			defaultPageSize: 5,
			showPaginationTop: false,
			showPaginationBottom: true,
			column: [
				{
					filterable: false,
					Header: "Emp Code",
					accessor: "indentNo",
					minWidth: 30,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Name",
					accessor: "indentLineId",
					minWidth: 25,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Dept",
					accessor: "indentDate",
					minWidth: 30,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Designation",
					accessor: "itemCode",
					minWidth: 30,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Date Of Join",
					accessor: "itemDesc",
					minWidth: 30,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Category",
					accessor: "uom",
					minWidth: 25,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Pay Scheme Name",
					accessor: "indentQty",
					minWidth: 30,
					style: { textAlign: "left" },
					sortable: false,
				},
				{
					filterable: false,
					Header: "Actions",
					accessor: "indentQty",
					minWidth: 30,
					style: { textAlign: "center" },
					sortable: false,
				},
			],
		};

		this.setState({
			dataTable: dataTable,
			isRefresh: true,
		});
		//  this.handleLoading();
		this.getReportsTable(this.state.lastIndex);
		getDataFromUrl(
			serverApi.LEAVE_POLICIES_LIST + "/" + getCookie(cons.COMPANY_ID),
			this.handleleavePolicy
		);
		this.callCustomerBranch();
		this.getCriminalRecordsList();
	}
	getCriminalRecordsList = () => {
		getDataFromUrl(
			serverApi.GET_CREMINAL_RECORDS_LIST + getCookie(cons.COMPANY_ID),
			this.handleCriminalRecordsList
		);
	};
	getCriminalRecordsListByEmpId = (ebId) => {
		getDataFromUrl(
			serverApi.GET_CRIMINAL_RECORD_LIST_BY_EMP + ebId,
			this.handleCriminalRecordsListByEmp
		);
	};
	handleCriminalRecordsListByEmp = (resp) => {
		this.setState({ criminalRecordList: resp });
	};
	handleCriminalRecordsList = (resp) => {
		this.setState({ criminalQuestions: resp });
	};
	callCustomerBranch() {
		getDataFromUrl(
			serverApi.SELECT_CUSTOMER_BRANCH + getCookie(cons.COMPANY_ID),
			this.handleCustomerBranch
		);
	}

	handleCustomerBranch = (resp) => {
		var branchid = [];
		resp.forEach((prop, i) => {
			var obj = {
				value: prop.customerLineId + "",
				name: prop.address + "^" + prop.customerId,
				label: prop.address,
			};
			branchid.push(obj);
		});
		this.setState({ locationList: branchid, loadLocation: true });
	};
	callPaySchemeComponentList() {
		getDataFromUrl(serverApi.PAY_SCHEME_DATATABLE, this.handlePayComponent);
	}
	handlePayComponent = (resp) => {
		var componentList = [];
		console.log(resp);
		this.setState({ payComponentData: resp });
	};
	getComponentName = (id, name) => {
		var componentvalue = "";
		this.state.payComponentData.forEach(function(value) {
			if (id === value.id && name === "name") {
				componentvalue = value.name;
			}
			if (id === value.id && name === "type") {
				componentvalue = value.type;
			}
			if (id === value.id && name === "formula") {
				componentvalue = value.formula;
			}
		});

		return componentvalue;
	};
	//pdf for appointment and offer letter

	generatePdfHandler = () => {
		var offerLetter = new Jspdf("p", "mm", "a4");
		offerLetter.setFont("helvetica", "normal");
		offerLetter.setFontSize(20);
		offerLetter.text(`Offer Letter`, 90, 30);
		offerLetter.setFontSize(12);
		// offerLetter.text(`Date:`, 150, 50);
		// offerLetter.table(`1431`,50,100)

		offerLetter.text(
			`
      Date:${moment().format("DD-MM-YYYY")}
      ${this.state.workerName},
      C/o.${this.state.fatherOrHusbandName},
      ${this.state.empAddr},
      
      Dear ${this.state.workerName},
      
     
      Congratulations!!!`,
			3,
			50
		);
		var t1 = offerLetter.splitTextToSize(
			`Reference to our series  of discussions, ${getCookie(
				cons.COMPANY_TITLE
			)} is pleased to extend you an offer as ${
				this.state.designationIdDesc
			} based out of ${getCookie(cons.COMPANY_ADDRESS)}.`,
			180
		);
		var t2 = offerLetter.splitTextToSize(
			"Please find the “Proposed Terms” for your reference. Kindly note the terms are confidential and subject to internal approval.",
			180
		);
		var t3 = offerLetter.splitTextToSize(
			"You are required to sign and return to us the duplicate copy of this letter clearly indicating your acceptance and date of joining.",
			180
		);
		var t4 = offerLetter.splitTextToSize(
			`The offer stands valid subject to your date of joining i.e. ${
				this.state.dateOfJoining
			},`,
			180
		);
		var t5 = offerLetter.splitTextToSize("Terms and conditions:", 180);
		var t6 = offerLetter.splitTextToSize(
			`•	Commencement of Employment – With effect from the date of joining i.e. from ${
				this.state.dateOfJoining
			} .`,
			180
		);
		var t7 = offerLetter.splitTextToSize(
			`•	Salary – You will be entitled to a consolidated CTC of Rs.3,70,000/-, the detail of your CTC mentioned in Annexure – I enclosed with this offer letter.`,
			180
		);
		var t8 = offerLetter.splitTextToSize(
			"•	Duties and Responsibilities – You will perform your duties as assigned to you by your superiors and will do all incidental jobs connected with or incidental to such duties.",
			180
		);
		var t9 = offerLetter.splitTextToSize(
			"•	Validity of appointment – Your appointment is subject to your acceptance of terms and conditions contained herein. Please sign in the place provided and return to us. This appointment letter will be valid up to 3Years and  its renewal will be based on your performance.",
			180
		);
		var t10 = offerLetter.splitTextToSize(
			"•	Bonus – The fixed bonus amount mentioned in the annexure will be paid by the company along with the March month salary of every year subject to the 1-year completion of the service.",
			180
		);
		var t11 = offerLetter.splitTextToSize(
			"•	Performance Pay – This benefit will be finalized along with the salary once in last month of every quarter and will be paid with the next quarter salary. Company will pay the said money subject to the no of days salary paid for each quarter.",
			180
		);
		var t12 = offerLetter.splitTextToSize(
			"•	Working Hours – Company standard working hours will be from 10AM to 7PM including the lunch time of 30Minutes.",
			180
		);
		var t13 = offerLetter.splitTextToSize(
			"•	Work from Home – Your supervisor will be the approver for facilitating the work from home. There will be no laptop, or any other assets provided by the company. The supervisor has a right to call off the work from home at any point of time which required to attend the office from the next day.",
			180
		);
		var t14 = offerLetter.splitTextToSize(
			"•	Leave Policy – You are eligible for the following leaves per year mentioned in the below table:",
			180
		);

		offerLetter.text(t1, 10, 105);
		offerLetter.text(t2, 10, 125);
		offerLetter.text(t3, 10, 140);
		offerLetter.text(t4, 10, 150);
		offerLetter.text(t5, 10, 160);
		offerLetter.text(t6, 10, 170);
		offerLetter.text(t7, 10, 180);
		offerLetter.text(t8, 10, 195);
		offerLetter.text(t9, 10, 210);
		offerLetter.text(t10, 10, 230);
		offerLetter.text(t11, 10, 245);
		offerLetter.text(t12, 10, 267);
		offerLetter.addPage(2);

		offerLetter.text(t13, 10, 15);
		offerLetter.text(t14, 10, 38);

		const doc = new Jspdf();
		doc.setFont("helvetica", "normal");
		doc.setTextColor(0);
		// It can parse html:
		// <table id="my-table"><!-- ... --></table>
		// doc.autoTable({ html: '#my-table' })

		// Or use javascript directly:
		var columns = [
			"S.No",
			"Leave Type",
			"No of Leaves",
			"Encashment",
			"Carry forward",
		];
		var rows = [
			["1", "Paid Leave", "15", "Yes", "Yes"],
			[
				"2",
				"Sick Leave(Only 1 Leave per month without doctor prescription)",
				"16",
				"No",
				"No",
			],
			["3", "Half days(Only 1 half day per month)", "06", "No", "No"],
		];

		offerLetter.autoTable(columns, rows, {
			startY: 42,
			theme: "grid",
			tableWidth: "auto",
			columnWidth: "wrap",
			showHeader: "everyPage",
			tableLineColor: 50,
			tableLineWidth: 0,
			columnStyles: {
				0: {
					columnWidth: 30,
				},
				1: {
					columnWidth: "auto",
				},
				2: {
					columnWidth: 30,
				},
				3: {
					columnWidth: 30,
				},
				4: {
					columnWidth: 30,
				},
			},
			ColumnsStyles: {
				theme: "grid",
			},
			styles: {
				overflow: "linebreak",
				columnWidth: "wrap",
				//font: 'arial',
				fontSize: 12,
				cellPadding: 2,
				overflowColumns: "linebreak",
			},
		});

		let finalY = offerLetter.lastAutoTable.finalY + 10;

		var t1 = offerLetter.splitTextToSize(
			`•	Resignation – 3 month(s) is the notice period to resign from the employment, in case if you are not serving the notice, 3 Months equivalent gross salary need to be paid. If you would like to resign the job within 2 Years, you must pay back the salary paid till that moment.`,
			180
		);
		var t2 = offerLetter.splitTextToSize(
			"•	Termination – The Company reserves the right to terminate your services, by providing you with 30 days’ notice. The company reserves the right to terminate your employment without any notice or salary in lieu thereof for reasons including but not limited to misconduct, negligence of duty, disloyalty, dishonesty, insubordination, misrepresentation, indiscipline, disobedience, breach of your obligations under the non-disclosure agreement, irregular attendance, inefficiency, poor performance.",
			180
		);
		var t3 = offerLetter.splitTextToSize(
			`•	Probation – You will be having a probation period for ${
				this.state.prohibitionInMonths
			}. The employee side notice should be 3 Months even in the probation and the resignation terms will be applicable. The pay will be fixed of Rs.23,000/- in the probation period.`,
			180
		);
		var t4 = offerLetter.splitTextToSize(
			"We look forward to working together to build growth of the organization.",
			180
		);
		var t5 = offerLetter.splitTextToSize("Sincerely yours,", 180);
		var t6 = offerLetter.splitTextToSize(
			`${getCookie(cons.COMPANY_TITLE)}`,
			180
		);
		var t7 = offerLetter.splitTextToSize("Manager-Human Resources", 180);
		var t8 = offerLetter.splitTextToSize(
			`I have read and understood all the terms and conditions as set forth herein and I agree to be bound by them. I hereby accept my appointment with ${getCookie(
				cons.COMPANY_TITLE
			)}`,
			180
		);
		var t9 = offerLetter.splitTextToSize(
			"Name _________________ 		Signature ______________	",
			180
		);
		var t10 = offerLetter.splitTextToSize("Date of Joining	___________", 180);
		var t11 = offerLetter.splitTextToSize(
			`${moment().format("DD-MM-YYYY")}`,
			180
		);
		var t12 = offerLetter.splitTextToSize(`${this.state.workerName},`, 180);
		var t13 = offerLetter.splitTextToSize(`${this.state.deptIdDesc}`);
		var t14 = offerLetter.splitTextToSize(
			`${this.state.designationIdDesc}`,
			180
		);

		offerLetter.text(t1, 10, 110);
		offerLetter.text(t2, 10, 130);
		offerLetter.text(t3, 10, 163);
		offerLetter.text(t4, 10, 180);
		offerLetter.text(t5, 10, 190);
		offerLetter.text(t6, 10, 200);
		offerLetter.text(t7, 10, 210);
		offerLetter.text(t8, 10, 220);
		offerLetter.text(t9, 10, 245);
		offerLetter.text(t10, 10, 255);
		offerLetter.addPage(3);
		offerLetter.text(`Annexure – I`, 90, 30);
		offerLetter.text(t11, 10, 50);
		offerLetter.text(t12, 10, 55);
		offerLetter.text(t13, 10, 60);
		offerLetter.text(t14, 10, 65);

		// offerLetter.text(20, finalY,t1)

		const docAnnexure = new Jspdf();
		docAnnexure.setFont("helvetica", "normal");
		docAnnexure.setTextColor(0);

		// It can parse html:
		// <table id="my-table"><!-- ... --></table>
		// doc.autoTable({ html: '#my-table' })

		// Or use javascript directly:
		var columns = ["Description", "Monthly", "Yearly"];
		var rows = [
			["Basic", "10,153.68", "121,844.16"],
			["HRA", "5,076.84", "60,922.08"],
			["Special Allowance", "4,153.68", "49,844.16"],
			["Leave Travel Allowance", "3,000.00", "36,000.00"],
			["Food Allowance", "3,000.00", "36,000.00"],
			["Gross Salary", "25,384.20", "04,610.40"],
			["Quarterly Performance Pay", "2,115.35", "25,384.20"],
			["Bonus	", "", "25,384.20"],
			["Employer PF", "1,218.44", "14,621.30"],
			["CTC	", "", "370,000.10"],
			["Employee PF", "1,218.44", "14,621.30"],
			["Professional Tax", "200.00", "2,400.00"],
		];

		offerLetter.autoTable(columns, rows, {
			docAnnexure: function(columns, data) {
				if (columns.raw === "Monthly") {
					//paint.Name header red
					columns.styles.fontSize = 15;
					columns.styles.textColor = [255, 0, 0];
				} else {
					columns.styles.textColor = 255;
					columns.styles.fontSize = 10;
				}
			},
			//startY: 60,
			margin: { top: 70, right: 10, bottom: 0, left: 10 },
			theme: "grid",
			tableWidth: "auto",
			columnWidth: "wrap",
			showHeader: "everyPage",
			tableLineColor: 50,
			tableLineWidth: 0,
			columnStyles: {
				0: {
					columnWidth: "auto",
				},
				1: {
					columnWidth: 30,
				},
				2: {
					columnWidth: 30,
				},
			},
			ColumnsStyles: {
				theme: "grid",
			},
			styles: {
				overflow: "linebreak",
				columnWidth: "wrap",
				//font: 'arial',
				fontSize: 12,
				cellPadding: 2,
				overflowColumns: "linebreak",
			},
		});

		let finalZ = offerLetter.lastAutoTable.finalZ + 10;

		var t15 = offerLetter.splitTextToSize(
			"Note*: Employee was entitled for personal accidental insurance of 25,00,000 & Group health insurance coverage for 3,00,000.,",
			180
		);
		var t16 = offerLetter.splitTextToSize(
			`${getCookie(cons.COMPANY_TITLE)}`,
			180
		);
		var t17 = offerLetter.splitTextToSize("Manager-Human Resources", 180);

		offerLetter.text(t15, 10, 200);
		offerLetter.text(t16, 10, 215);
		offerLetter.text(t17, 10, 240);

		offerLetter.save(`${this.state.workerName}_Offer Letter`);
	};

	handleleavePolicy = (resp) => {
		var list = [];
		if (resp.data.length > 0) {
			resp.data.forEach((prop) => {
				var obj = {
					label: prop.leavePolicyDesc,
					value: prop.leavePolicyId,
					name: prop.leavePolicyDesc,
				};
				list.push(obj);
			});
		}
		this.setState({ leavePolicyList: list });
	};
	getReportsTable = (startIndex, lastIndex) => {
		var data = this.getData(startIndex, lastIndex);

		requestList(serverApi.TABLE_WORKERS_LIST, data, this.handleReports);
	};
	handleReports = (resp) => {
		console.log("hrmsAccess", resp.hrmsAccess);
		var dataTable = resp;
		this.setState({
			//dataTable: dataTable,
			//    totalRecords: resp.totalRecords,
			hrmsAccess: resp.hrmsAccess,
			//  isRefresh: true,
		});

		/*
    if (this.state.isExcel) {
      this.setState({
        dataTableAll: dataTable,
        isExcel: false,
        isRefresh: false,
      });
    } else {
      this.setState({
        dataTable: dataTable,
        //    totalRecords: resp.totalRecords,
        hrmsAccess: resp.hrmsAccess,
        isRefresh: true,
      });
      // this.handleLoading();
      if (this.state.loadAll) {
        this.setState({ isExcel: true, loadAll: false });

        this.getReportsTable(this.state.startIndex, resp.totalRecords);
      }
    }
*/
		//}
	};

	handleSeacrh = () => {
		var loading = false;
		console.log("ssss", this.state.isRefresh);
		/* if (!this.state.isRefresh){
           // this.handleLoading();
            this.setState({loadAll:true});
            this.getReportsTable(this.state.startIndex,this.state.lastIndex);
        }*/
		this.setState({
			isRefresh: !this.state.isRefresh,
		});
	};
	getDefaultUnitConvertion = (name) => {
		var val = "",
			label = "",
			data = "";
		if (name !== null) {
			if (name === "LOOSE") {
				val = 1;
				label = "LOOSE";
			} else {
				val = 2;
				label = "BALE";
			}
			data = {
				value: val,
				label: label,
			};
		}
		return data;
	};
	handleLoading = () => {
		this.setState({
			isLoading: !this.state.isLoading,
		});
	};

	handleCreate = () => {
		//alert();
		if (this.state.isCreated) {
			this.hideAlertMessage();
		}
		if (this.state.isCreated) {
			this.setState({
				leavePolicyId: "",
				canChangeMukam: true,
				canChangeUnit: true,
			});
		}
		console.log(this.state);
		this.setState({
			isCreated: !this.state.isCreated,
		});
		//  this.hideAlertMessage();
	};
	getData = (startIndex, lastIndex) => {
		var data = {
			companyId: getCookie(cons.COMPANY_ID),
			deptId: this.state.deptCode,
			ebNo: this.state.empCode1,
			empName: this.state.empName,
			esiNo: this.state.esiNum,
			payScheme: this.state.payScheme,
			pfNo: this.state.pfNum,
			reporting: this.state.reporting,
			userId: getCookie(cons.USERID),
			startIndex: this.state.startIndex,
			lastIndex: this.state.lastIndex,
			cataId: this.state.cataIdList,
			designationId: this.state.designationIdList,
		};
		return data;
	};
	timeChanged = (dateObj, id) => {
		if (typeof dateObj === "object")
			this.setState({
				[id]: dateObj.format("DD-MM-YYYY"),
			});
		else if (id !== "date1")
			this.setState({
				[id]: "",
			});

		// this.dateChanged();
	};
	dateChanged = () => {
		var myDate = this.state.date1;
		myDate = myDate.split("-");
		var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
		return new Date(newDate).getTime();
	};

	onChangeValues = (event) => {
		var value = event.target.value;

		this.setState({
			[event.target.id]: value,
		});
	};

	hideBasicAlert() {
		if (this.state.updateItemAlert) this.hideAlert();
		this.setState({
			basicAlert: null,
			deleteItem: false,
			updateItemAlert: false,
		});
	}

	hideAlert() {
		console.log("alert");
		this.setState({
			updateItem: false,
			isJuteSelected: false,
			hideJuteType: false,
			showEduPopup: false,
			showDocPopup: false,
			alert: false,
			showMessagealert: false,
			loading: false,
			showBioPopup: false,
			showShiftPopup: false,
			showExpPopup: false,
		});
	}

	hideAlertMessage() {
		this.setState({
			isAdd: false,
			isCreated: false,
			approveStatus: false,
			showMessagealert: false,
			updateView: false,
			alert: false,
			basicAlert: false,
			payCall: false,
			empCode1: "",

			ebNo: "",
			workerName: "",
			middleName: "",
			lastName: "",
			sex: "",
			dateOfBirth: "",
			fatherOrHusbandName: "",
			empCity: "",
			empPincode: "",
			empAddr: "",
			phoneNo: "",
			mobileNo: "",
			emergencyMobileNo: "",
			officeMobileNo: "",
			maritalStatus: "",
			panNo: "",
			bloodGroup: "",
			nomineeName: "",
			nomineeRelationship: "",
			religion: "",
			personalBankName: "",
			personalBankIFSC: "",
			personalBankAcNo: "",
			personalBankBranch: "",
			imageFile: null,
			imagePreviewUrl: null,
			deptId: "",
			branchId: "",
			paySchemeId: "",
			dateOfJoining: "",
			empLevelId: 0,
			workLocation: "",
			cataId: 0,
			designationId: 0,
			pfNo: "",
			esiNo: "",
			officialEmailId: "",
			reportingToEbId: 0,
			appRoleId: 0,
			portalRoleId: 0,
			pfUANName: "",
			empStatus: "",
			dateOfPfJoining: "",
			contractorId: 0,
			licNo: "",
			retiredDate: "",
			officeBankName: "",
			officeBankBranch: "",
			officeBankIFSC: "",
			officeBankAcNo: "",
			quarterNo: "",
			userName: "",
			mappingId: "",
			ledgerGroupId: "",
			parentLedger: "",
			facebookId: "",
			state: "",
			country: "",
			nationality: "",
			accommodatioType: "",
			contractDurationInMonths: "",
			prohibitionInMonths: "",
			passportNo: "",
			passportIssueDate: "",
			passportExpiryDate: "",
			drivingLicenseNo: "",
			drivingIssueDate: "",
			drivingExpiryDate: "",
			visaNo: "",
			visaIssueDate: "",
			effectiveFrom: "",
			leavePolicyId: "",
			approverButton: false,
			updateButton: false,
			leavePolicieList: [],
			paySchemeListEarningsData: [],
			paySchemeListDeductionData: [],
			paySchemeListNoCalData: [],
			empPayschemeId: "",
			paySchemeListSummaryData: [],
			paySchemeLineData: [],
			paySchemeIdNew: "",
			startIndex: 1,
			lastIndex: 10,
			// loadAll:true,
			workerEducation: [],
			workerDocuments: [],
			salaryList: [],
			bioMetricEmpMapList: [],
			employeeShiftMapList: [],
			leavePolicyList: [],
			empId: "",
			empCodeSeqNo: "",
			ebId: "",
			ebFixedId: "",
			empCode: "",
			category: "",
			active: 1,
			hrmsFullAccess: false,
			createdOn: "",
			empDocument: "",
			docFileName: "",
			docType: "",
			faceImageTypeId: "",
			fileName: "",
			criminalRecordList: [],
			workerExperience: [],
		});
		//window.location.reload(false);
	}
	hideAlertMessageMenu() {
		this.setState({
			showMessagealert: false,
			updateView: true,
			alert: false,
			basicAlert: false,
			tabPosition: "",
			isTabOpen: false,
			loading:false
		});
		//window.location.reload(false);
	}

	basicAlert(msg) {
		this.setState({
			basicAlert: (
				<SweetAlert
					style={{
						display: "block",
						marginTop: "-100px",
						marginLeft: "-80px",
						fontSize: "8px",
						color: "red",
					}}
					title={msg}
					onConfirm={() => this.hideBasicAlert()}
					onCancel={() => this.hideBasicAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
				/>
			),
		});
	}

	getInvoiceTablePageSize = () => {
		var length = this.state.workerEducation.length;
		if (length > 8) {
			return length;
		} else {
			return 8;
		}
	};
	onSelect = (selectedValue, stateKey) => {
		if (
			stateKey === "paySchemeId" &&
			this.state.paySchemeId !== selectedValue
		) {
			// this.setState({payCall:true});
			//   if(this.state.payCall){
			/*  this.setState({
                payschmedataEarnings: [],
                payschmedataDeductions: [],
                payschmedatayearEarnings: [],
                payschmedatayearDeductions: []
            });*/
			getDataFromUrl(
				serverApi.PAY_SCHEME_GET_BYID + "/" + selectedValue,
				this.handleViewClickRespPayscheme
			);

			// }
		}
		if (stateKey === "leavePolicyId") {
			this.handleLeavePolicyClick(selectedValue);
		}
		if (
			stateKey === "workLocation" &&
			this.state.workLocation != selectedValue
		) {
			if (this.state.designationId) {
				this.setState({ autoPayscheme: true });
			}
		}
		// if (
		// 	stateKey === "designationId" &&
		// 	this.state.designationId != selectedValue
		// ) {
		// 	if (this.state.workLocation) {
		// 		this.setState({ autoPayscheme: true });
		// 		this.callPaySchemeList(selectedValue, this.state.workLocation);
		// 	}
		// }
		this.setState({
			[stateKey]: selectedValue,
		});
	};
	handlePayschemeById = (resp) => {
		var data = resp.payschemeParam;
		const earnings = [];
		const deductions = [];
		const yearlyearnings = [];
		const yearlydeductions = [];
		data.map((prop, i) => {
			const inputFocus = {
				autoComplete: "off",
			};
			if (prop.param.parameterCategory === 1) {
				var eobj = {
					id: earnings.length,
					paramId: prop.param.paramId,
					ParamCode: prop.param.paramCode,
					name: prop.param.paramName,
					paramValue: prop.formula.expression,
					earniningText:
						prop.param.numericFormula === 1 ? (
							<TextField
								defValue={prop.formula.expression}
								id="defnumaricValue"
								onChange={(event) => {
									var payschmedataEarnings = this.state.payschmedataEarnings;
									payschmedataEarnings[eobj.id].paramValue = event.target.value;
									this.setState({
										payschmedataEarnings: payschmedataEarnings,
									});
								}}
								//value={}
								fullWidth={true}
								iType={"number"}
								variant="outlined"
								autoFocus={true}
								InputProps={inputFocus}
							/>
						) : (
							prop.formula.expression
						),
				};
				earnings.push(eobj);
			}

			if (prop.param.parameterCategory === 2) {
				var dobj = {
					id: deductions.length,
					paramId: prop.param.paramId,
					ParamCode: prop.param.paramCode,
					name: prop.param.paramName,
					paramValue: prop.formula.expression,
					deductionText:
						prop.param.numericFormula === 1 ? (
							<TextField
								// defValue={prop.formula.expression}
								id="defnumaricValue"
								onChange={(event) => {
									var payschmedataDeductions = this.state
										.payschmedataDeductions;
									payschmedataDeductions[dobj.id].paramValue =
										event.target.value;
									this.setState({
										payschmedataDeductions: payschmedataDeductions,
									});
								}}
								fullWidth={true}
								iType={"number"}
								variant="outlined"
								autoFocus={true}
								InputProps={inputFocus}
							/>
						) : (
							prop.formula.expression
						),
				};
				deductions.push(dobj);
			}

			if (prop.param.parameterCategory === 3) {
				var yeobj = {
					id: yearlyearnings.length,
					paramId: prop.param.paramId,
					ParamCode: prop.param.paramCode,
					name: prop.param.paramName,
					paramValue: prop.formula.expression,
					yearlyearningText:
						prop.param.numericFormula === 1 ? (
							<TextField
								// defValue={prop.formula.expression}
								id="defnumaricValue"
								onChange={(event) => {
									var payschmedatayearEarnings = this.state
										.payschmedatayearEarnings;
									payschmedatayearEarnings[yeobj.id].paramValue =
										event.target.value;
									this.setState({
										payschmedatayearEarnings: payschmedatayearEarnings,
									});
								}}
								fullWidth={true}
								iType={"number"}
								variant="outlined"
								autoFocus={true}
								InputProps={inputFocus}
							/>
						) : (
							prop.formula.expression
						),
				};
				yearlyearnings.push(yeobj);
			}

			if (prop.param.parameterCategory === 4) {
				var ydobj = {
					id: yearlydeductions.length,
					paramId: prop.param.paramId,
					ParamCode: prop.param.paramCode,
					name: prop.param.paramName,
					paramValue: prop.formula.expression,
					yearlydeductionText:
						prop.param.numericFormula === 1 ? (
							<TextField
								//defValue={prop.formula.expression}
								id="defnumaricValue"
								onChange={(event) => {
									var payschmedatayearDeductions = this.state
										.payschmedatayearDeductions;
									payschmedatayearDeductions[ydobj.id].paramValue =
										event.target.value;
									this.setState({
										payschmedatayearDeductions: payschmedatayearDeductions,
									});
								}}
								fullWidth={true}
								iType={"number"}
								variant="outlined"
								autoFocus={true}
								InputProps={inputFocus}
							/>
						) : (
							prop.formula.expression
						),
				};
				yearlydeductions.push(ydobj);
			}
		});

		this.setState({
			payschmedataEarnings: earnings,
			payschmedataDeductions: deductions,
			payschmedatayearEarnings: yearlyearnings,
			payschmedatayearDeductions: yearlydeductions,
		});
	};
	onCreateButtonClick = (status) => {
		const {
			ebId,
			ebFixedId,
			empCodeSeqNo,
			ebNo,
			empCode,
			workerName,
			middleName,
			lastName,
			sex,
			dateOfBirth,
			fatherOrHusbandName,
			empCity,
			empPincode,
			empAddr,
			phoneNo,
			mobileNo,
			emergencyMobileNo,
			officeMobileNo,
			maritalStatus,
			panNo,
			bloodGroup,
			nomineeName,
			nomineeRelationship,
			religion,
			personalBankName,
			personalBankIFSC,
			personalBankAcNo,
			personalBankBranch,
			imageFile,
			deptId,
			branchId,
			paySchemeId,
			dateOfJoining,
			empLevelId,
			workLocation,
			cataId,
			designationId,
			pfNo,
			esiNo,
			officialEmailId,
			reportingToEbId,
			appRoleId,
			portalRoleId,
			pfUANName,
			empStatus,
			dateOfPfJoining,
			contractorId,
			licNo,
			retiredDate,
			officeBankName,
			officeBankBranch,
			officeBankIFSC,
			officeBankAcNo,
			userName,
			quarterNo,
			active,
			hrmsAccess,
			hrmsFullAccess,
			faceImageTypeId,
			ledgerGroupId,
			parentLedger,
			facebookId,
			state,
			country,
			nationality,
			accommodatioType,
			contractDurationInMonths,
			prohibitionInMonths,
			passportNo,
			passportIssueDate,
			passportExpiryDate,
			drivingLicenseNo,
			drivingIssueDate,
			drivingExpiryDate,
			visaNo,
			visaIssueDate,
		} = this.state;
		var obj = {
			ebNo: ebNo,
			empCode: ebNo,
			workerName: workerName,
			middleName: middleName,
			lastName: lastName,
			sex: sex,
			dateOfBirth: dateOfBirth !== "" ? dateOfBirth : null,
			fatherOrHusbandName: fatherOrHusbandName,
			empCity: empCity,
			empPincode: empPincode,
			empAddr: empAddr,
			phoneNo: phoneNo,
			mobileNo: mobileNo,
			emergencyMobileNo: emergencyMobileNo,
			officeMobileNo: officeMobileNo,
			maritalStatus: maritalStatus,
			panNo: panNo,
			bloodGroup: bloodGroup,
			nomineeName: nomineeName,
			nomineeRelationship: nomineeRelationship,
			religion: religion,
			personalBankName: personalBankName,
			personalBankIFSC: personalBankIFSC,
			personalBankAcNo: personalBankAcNo,
			personalBankBranch: personalBankBranch,
			deptId: deptId,
			branchId: branchId,
			paySchemeId: paySchemeId,
			dateOfJoining: dateOfJoining !== "" ? dateOfJoining : null,
			empLevelId: empLevelId,
			workLocation: workLocation,
			cataId: cataId,
			designationId: designationId,
			pfNo: pfNo,
			esiNo: esiNo,
			officialEmailId: officialEmailId,
			reportingToEbId: reportingToEbId,
			appRoleId: appRoleId,
			portalRoleId: portalRoleId,
			pfUANName: pfUANName,
			empStatus: empStatus,
			dateOfPfJoining: dateOfPfJoining !== "" ? dateOfPfJoining : null,
			contractorId: contractorId,
			licNo: licNo,
			officeBankName: officeBankName,
			officeBankBranch: officeBankBranch,
			officeBankIFSC: officeBankIFSC,
			officeBankAcNo: officeBankAcNo,
			userName: userName ? userName : ebNo,
			quarterNo: quarterNo,
			active: active,
			hrmsAccess: hrmsAccess,
			ledgerGroupId: ledgerGroupId,
			parentLedger: parentLedger,
			facebookId: facebookId,
			state: state,
			country: country,
			nationality: nationality,
			accommodatioType: accommodatioType,
			contractDurationInMonths: contractDurationInMonths,
			prohibitionInMonths: prohibitionInMonths,
			passportNo: passportNo,
			passportIssueDate: passportIssueDate !== "" ? passportIssueDate : null,
			passportExpiryDate: passportExpiryDate !== "" ? passportExpiryDate : null,
			drivingLicenseNo: drivingLicenseNo,
			drivingIssueDate: drivingIssueDate !== "" ? drivingIssueDate : null,
			drivingExpiryDate: drivingExpiryDate !== "" ? drivingExpiryDate : null,
			visaNo: visaNo,
			visaIssueDate: visaIssueDate !== "" ? visaIssueDate : null,
			createdBy: getCookie(cons.USERID),
			companyId: getCookie(cons.COMPANY_ID),
		};
		if (this.state.updateView) {
			obj = {
				ebId: ebId,
				"ebFixedId:": ebFixedId,
				empCodeSeqNo: empCodeSeqNo,
				ebNo: ebNo,
				empCode: ebNo,
				workerName: workerName,
				middleName: middleName,
				lastName: lastName,
				sex: sex,
				dateOfBirth: dateOfBirth !== "" ? dateOfBirth : null,
				fatherOrHusbandName: fatherOrHusbandName,
				empCity: empCity,
				empPincode: empPincode,
				empAddr: empAddr,
				phoneNo: phoneNo,
				mobileNo: mobileNo,
				emergencyMobileNo: emergencyMobileNo,
				officeMobileNo: officeMobileNo,
				maritalStatus: maritalStatus,
				panNo: panNo,
				bloodGroup: bloodGroup,
				nomineeName: nomineeName,
				nomineeRelationship: nomineeRelationship,
				religion: religion,
				personalBankName: personalBankName,
				personalBankIFSC: personalBankIFSC,
				personalBankAcNo: personalBankAcNo,
				personalBankBranch: personalBankBranch,
				deptId: deptId,
				branchId: branchId,
				paySchemeId: paySchemeId,
				dateOfJoining: dateOfJoining !== "" ? dateOfJoining : null,
				empLevelId: empLevelId,
				workLocation: workLocation,
				cataId: cataId,
				designationId: designationId,
				pfNo: pfNo,
				esiNo: esiNo,
				officialEmailId: officialEmailId,
				reportingToEbId: reportingToEbId,
				appRoleId: appRoleId,
				portalRoleId: portalRoleId,
				pfUANName: pfUANName,
				dateOfPfJoining: dateOfPfJoining !== "" ? dateOfPfJoining : null,
				contractorId: contractorId,
				licNo: licNo,
				retiredDate: retiredDate !== "" ? retiredDate : null,
				officeBankName: officeBankName,
				officeBankBranch: officeBankBranch,
				officeBankIFSC: officeBankIFSC,
				officeBankAcNo: officeBankAcNo,
				userName: userName,
				quarterNo: quarterNo,
				active: active,
				hrmsAccess: hrmsAccess,
				ledgerGroupId: ledgerGroupId,
				parentLedger: parentLedger,
				facebookId: facebookId,
				state: state,
				country: country,
				nationality: nationality,
				accommodatioType: accommodatioType,
				contractDurationInMonths: contractDurationInMonths,
				prohibitionInMonths: prohibitionInMonths,
				passportNo: passportNo,
				passportIssueDate: passportIssueDate !== "" ? passportIssueDate : null,
				passportExpiryDate:
					passportExpiryDate !== "" ? passportExpiryDate : null,
				drivingLicenseNo: drivingLicenseNo,
				drivingIssueDate: drivingIssueDate !== "" ? drivingIssueDate : null,
				drivingExpiryDate: drivingExpiryDate !== "" ? drivingExpiryDate : null,
				visaNo: visaNo,
				visaIssueDate: visaIssueDate !== "" ? visaIssueDate : null,
				updatedBy: getCookie(cons.USERID),
				companyId: getCookie(cons.COMPANY_ID),
				empStatus: status,
			};
		}
		if (imageFile !== null)
			this.setState({
				uploadFile: true,
			});

		if (this.state.updateView) {
			this.setState({ loadAll: true });
			this.handleLoading();

			requestList(serverApi.WORKER_MASTER_UPDATE, obj, this.handleAddIndent);
			if (this.state.autoPayscheme) {
				this.callPaySchemeList(
					this.state.designationId,
					this.state.workLocation
				);
			}
		} else {
			/*
                        for (var pair of formData.entries()) {
                            console.log(pair[0]+ ', ' + pair[1]+"");
                        }

*/
			if (!getMenuPermission(2)) {
				this.basicAlert("Access Denied. Please contact administrator!!!");
			} else {
				this.setState({ loadAll: true });

				this.handleLoading();
				//uploadFormdataWithImage(serverApi.WORKER_MASTER_ADD, formData, this.handleAddIndent);
				requestList(serverApi.WORKER_MASTER_ADD, obj, this.handleAddIndent);
				if (this.state.autoPayscheme) {
					this.callPaySchemeList(
						this.state.designationId,
						this.state.workLocation
					);
				}
			}
		}
	};
	onClickUpdateEmpPaySchemeMapping = () => {
		if (!getMenuPermission(2)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			var payscheme = [
				...this.state.paySchemeListEarningsData,
				...this.state.paySchemeListDeductionData,
				...this.state.paySchemeListNoCalData,
				...this.state.paySchemeListSummaryData,
			];

			var payschemedata = payscheme.map((prop, key) => {
				return {
					amount: prop.value,
					componentId: prop.componentId,
					effectiveFrom: this.state.effectiveFrom,
					employeeid: this.state.empId,
					endsOn: "",
					payschemeId: this.state.paySchemeId,
					remarks: "",
				};
			});

			var data = {
				payEmployeeDTO: {
					id: this.state.empPayschemeId,
					employeeid: this.state.empId,
					paySchemeId: this.state.paySchemeId,
				},
				payEmployeeStructureDTO: payschemedata,
			};

			if (this.state.paySchemeId > 0) {
				this.handleLoading();
				requestList(
					serverApi.UPDATE_EMP_PAYSCHME,
					data,
					this.handleAddEmpPayscheme
				);
			} else {
				this.basicAlert("Please Enter All Mandatory Fields..!!");
			}
		}
	};

	onClickEmpPaySchemeMapping = (data) => {
		if (!getMenuPermission(2)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			var payscheme = [
				...this.state.paySchemeListEarningsData,
				...this.state.paySchemeListDeductionData,
				...this.state.paySchemeListNoCalData,
				...this.state.paySchemeListSummaryData,
			];
			if (this.state.autoPayscheme && data) {
				payscheme = data;
			}

			var payschemedata = payscheme.map((prop, key) => {
				return {
					amount: prop.value,
					componentId: prop.componentId,
					effectiveFrom: this.state.effectiveFrom,
					employeeid: this.state.empId,
					endsOn: "",
					payschemeId: this.state.paySchemeId,
					remarks: "",
				};
			});

			var data = {
				payEmployeeDTO: {
					employeeid: this.state.empId,
					paySchemeId: this.state.paySchemeId,
				},
				payEmployeeStructureDTO: payschemedata,
			};

			if (this.state.paySchemeId > 0) {
				this.handleLoading();
				requestList(
					serverApi.ADD_EMP_PAYSCHME,
					data,
					this.handleAddEmpPayscheme
				);
			} else {
				this.basicAlert("Please Enter All Mandatory Fields..!!");
			}
		}
	};
	handleAddEmpPayscheme = (resp) => {
		if (resp.status) {
			console.log("resp " + JSON.stringify(resp));
			if (this.state.autoPayscheme) this.setState({ autoPayscheme: false });
			else this.showMessage(resp.message, resp.status);
		} else {
			this.showMessage(resp.message, resp.status);
		}
		this.handleLoading();
	};
	handleAddImage = (resp) => {
		console.log("respoiii", resp);
		if (resp.fileUrl !== null)
			getFileDownload(
				serverConfig.SERVER_URL + resp.fileUrl,
				"",
				this.state.faceImageTypeId,
				this.handleDownloadedFile
			);

		// this.setState({imagePreviewUrl:resp.fileUrl})
		// this.setState({uploadFile:false})
		//this.showMessage(resp.message, false)
	};

	handleAddIndent = (resp) => {
		if (resp.status) {
			if (this.state.uploadFile && !this.state.updateView) {
				const { faceImageTypeId, imageFile, fileName } = this.state;
				/*   var formData=new FormData();
                formData.append("ebId", resp.id);
                formData.append("fileCompanyId", getCookie(cons.COMPANY_ID)+"");
                formData.append("fileExtension", faceImageTypeId);
                formData.append("fileUpload", imageFile);*/
				uploadFile(
					fileName,
					imageFile,
					faceImageTypeId,
					1,
					fileName,
					resp.id,
					this.handleAddImage
				);

				//  uploadFormdataWithImage(serverApi.UPLOAD_EMP_IMAGE,formData,this.handleAddImage)
			}
			if (!this.state.updateView) {
				this.handleViewClick(resp.id);
			}
			this.showMessage(resp.message, resp.status);
		} else {
			this.showMessage(resp.message, resp.status);
		}
		this.handleLoading();
	};
	showMessage = (msg, status) => {
		this.setState({
			showMessagealert: (
				<Popup
					//  onClickSave={() => this.onClickAddItem()}
					onClickSave={() => {
						if (status) {
							/* if(this.state.updateView)
              this.hideAlertMessage();
                else*/
							this.hideAlertMessageMenu();
						} else {
							this.hideAlert();
						}
					}}
					isShowSave={true}
					save_btn_txt={"OK"}
					content={
						<form>
							<legend style={{ textAlign: "center" }}>{msg}</legend>
						</form>
					}
				/>
			),
		});
	};
	changeProfilePic = (file, image) => {
		var fileName = file.name.split(".");
		var fileType = fileName[1];
		this.setState({
			imageFile: file,
			imagePreviewUrl: image,
			faceImageTypeId: fileType,
			fileName: file.name,
		});
		if (this.state.updateView) {
			uploadFile(
				file.name,
				file,
				fileType,
				1,
				fileName,
				this.state.ebId,
				this.handleAddImage
			);
		}
	};
	handleEducationItem = () => {
		this.setState({ showEduPopup: true });
	};
	onClickAddEducation = () => {
		const {
			ebId,
			eduInstitute,
			eduPercentage,
			eduType,
			empCode,
			yearOfPassing,
		} = this.state;
		var data = {
			companyId: getCookie(cons.COMPANY_ID),
			createdBy: getCookie(cons.USERID),
			ebId: ebId,
			eduInstitute: eduInstitute,
			eduPercentage: eduPercentage,
			eduType: eduType,
			empCode: empCode,
			yearOfPassing: yearOfPassing,
		};
		requestList(
			serverApi.WORKER_EDUCATION_DETAILS_ADD,
			data,
			this.handleAddEdu
		);
	};
	onClickAddExperience = () => {
		const {
			ebId,
			companyName,
			joinedDate,
			releavedDate,
			role,
			contact,
			project,
		} = this.state;
		var data = [
			{
				autoId: null,
				project: project,
				companyName: companyName,
				designation: role,
				fromDate: joinedDate,
				toDate: releavedDate,
				empId: ebId,
				isActive: 1,
				contact: contact,
				companyId: getCookie(cons.COMPANY_ID),
				createdBy: getCookie(cons.USERID),
				createdDate: moment().format("DD-MM-YYYY"),
			},
		];
		this.setState({ AddExp: true });

		requestList(serverApi.ADD_EXPERIENCE, data, this.handleAddExp);
	};
	handleAddExp = (resp) => {
		if (resp.status) {
			const {
				ebId,
				companyName,
				joinedDate,
				releavedDate,
				role,
				contact,
				docFileName,
				empDocument,
				docType,
				docName,
				project,
			} = this.state;

			if (this.state.deleteExp) {
				var data = this.state.workerExperience;
				data.splice(this.state.id, 1);
				this.setState({
					workerExperience: data,
					deleteExp: false,
					loading: true,
					id: "",
				});
			} else {
				var workerExperience = this.state.workerExperience;
				this.getExperienceDetailsById(this.state.ebId);

				this.setState({
					companyName: "",
					joinedDate: "",
					releavedDate: "",
					role: "",
					contact: "",
					workerExperience: workerExperience,
					project: "",
					showExpPopup: false,
					loading: true,
				});
			}
		}
		this.showMessage(resp.message, false);
	};
	onClickAddShiftMapping = () => {
		const {
			ebId,
			lateMins1,
			lateMins2,
			roasterDays,
			roasterGapDays,
			roasterStartDate,
			spell1,
			spell2,
			weekOffDay,
			weekOffDay2,
			weekOffHalfDay,
		} = this.state;
		var data = {
			companyId: getCookie(cons.COMPANY_ID),
			createdBy: getCookie(cons.USERID),
			ebId: ebId,
			lateMins1: lateMins1,
			lateMins2: lateMins2,
			roasterDays: roasterDays,
			roasterGapDays: roasterGapDays,
			roasterStartDate: roasterStartDate,
			spell1: spell1,
			spell2: spell2,
			weekOffDay: weekOffDay,
			weekOffDay2: weekOffDay2,
			weekOffHalfDay: weekOffHalfDay,
			userId: getCookie(cons.COMPANY_ID),
		};
		if (
			roasterDays >= 0 &&
			roasterGapDays >= 0 &&
			roasterStartDate !== "" &&
			(spell1 !== "0" && spell1 !== undefined)
		)
			requestList(
				serverApi.WORKER_SHIFT_MAPPING_ADD,
				data,
				this.handleAddShiftMapping
			);
		else {
			this.basicAlert("Please Enter All Mandatory Fields..!!");
		}
	};
	handleAddShiftMapping = (resp) => {
		if (resp.status) {
			const {
				ebId,
				lateMins1,
				lateMins2,
				roasterDays,
				roasterGapDays,
				roasterStartDate,
				spell1,
				spell2,
				weekOffDay,
				weekOffDay2,
				weekOffHalfDay,
			} = this.state;

			if (this.state.deleteShift) {
				var data = this.state.employeeShiftMapList;
				data.splice(this.state.id, 1);
				this.setState({
					employeeShiftMapList: data,
					deleteShift: false,
					loading: true,
					id: "",
				});
			} else {
				var employeeShiftMapList = this.state.employeeShiftMapList;
				var obj = {
					shiftId: resp.id,
					lateMins1: lateMins1,
					lateMins2: lateMins2,
					roasterDays: roasterDays,
					roasterGapDays: roasterGapDays,
					roasterStartDate: roasterStartDate,
					spell1: spell1,
					spell2: spell2,
					weekOffDay: weekOffDay,
					weekOffDay2: weekOffDay2,
					weekOffHalfDay: weekOffHalfDay,
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.employeeShiftMapList;
									data.find((o, i) => {
										if (o.shiftId === obj.shiftId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteShift: true,
												shiftId: o.shiftId,
											});
											this.basicItemAlert("Do You Like To Delete This Item?");

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						</div>
					),
				};
				employeeShiftMapList.push(obj);
				this.setState({
					lateMins1: "",
					lateMins2: "",
					roasterDays: "",
					roasterGapDays: "",
					roasterStartDate: "",
					spell1: "",
					spell2: "",
					weekOffDay: "",
					weekOffDay2: "",
					weekOffHalfDay: "",
					employeeShiftMapList: employeeShiftMapList,
					showShiftPopup: false,
					loading: true,
				});
			}
		}
		this.showMessage(resp.message, false);
	};

	onClickAddBioMetric = () => {
		const { ebId, bioDeviceId, empBioDeviceId, empRFNo } = this.state;
		var data = {
			companyId: getCookie(cons.COMPANY_ID),
			createdBy: getCookie(cons.USERID),
			bioDeviceId: bioDeviceId,
			ebId: ebId,
			empBioDeviceId: empBioDeviceId,
			empRFNo: empRFNo,
			userId: getCookie(cons.COMPANY_ID),
		};
		requestList(serverApi.WORKER_BIOMETRIC_ADD, data, this.handleAddBioMetric);
	};
	handleAddBioMetric = (resp) => {
		if (resp.status) {
			const {
				ebId,
				bioDeviceId,
				empBioDeviceId,
				empRFNo,
				bioDeviceName,
			} = this.state;

			if (this.state.deleteBio) {
				var data = this.state.bioMetricEmpMapList;
				data.splice(this.state.id, 1);
				this.setState({
					bioMetricEmpMapList: data,
					deleteBio: false,
					loading: true,
					id: "",
				});
			} else {
				var bioMetricEmpMapList = this.state.bioMetricEmpMapList;
				var obj = {
					bioId: resp.id,
					bioDeviceId: bioDeviceId,
					empBioDeviceId: empBioDeviceId,
					empRFNo: empRFNo,
					bioDeviceName: bioDeviceName,
					activeStatus: "Active",
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.bioMetricEmpMapList;
									data.find((o, i) => {
										if (o.bioId === obj.bioId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteBio: true,
												bioId: o.bioId,
											});
											this.basicItemAlert("Do You Like To Delete This Item?");

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						</div>
					),
				};
				bioMetricEmpMapList.push(obj);
				this.setState({
					bioDeviceId: "",
					empBioDeviceId: "",
					empRFNo: "",
					bioDeviceName: "",
					bioMetricEmpMapList: bioMetricEmpMapList,
					showBioPopup: false,
					loading: true,
				});
			}
		}
		this.showMessage(resp.message, false);
	};

	onClickAddDocument = () => {
		const {
			ebId,
			docName,
			empDocument,
			docType,
			empCode,
			docFileName,
		} = this.state;
		//  console.log("state", ebId, docName, empDocument, docType, empCode);
		/*  const data = new FormData();
        data.append("companyId", getCookie(cons.COMPANY_ID));
        data.append("createdBy", getCookie(cons.USERID));
        data.append("ebId", ebId);
        data.append("empCode", empCode);
        data.append("docName", docName);
        data.append("docType", docType);*/
		//  data.append("empDocument", empDocument);

		if (
			docName !== "" &&
			docName !== undefined &&
			empDocument !== undefined &&
			empDocument !== null
		) {
			uploadFile(
				docFileName,
				empDocument,
				docType,
				6,
				docName,
				ebId,
				this.handleAddDoc
			);

			//uploadFormdataWithImage(serverApi.WORKER_DOCUMENT_DETAILS_ADD, data, this.handleAddDoc)
		} else {
			this.basicAlert("Please Enter Mandatory Fields..!!");
		}
	};

	handleAttachment(attachmentUrl) {
		console.log("print --------------", attachmentUrl);
		if (attachmentUrl !== null && attachmentUrl !== undefined)
			//  window.open(attachmentUrl, "_/");
			window.open(attachmentUrl);
		// window.open(Sample1);

		// window.location.assign(attachmentUrl);
		//window.location.hash(attachmentUrl);
	}

	handleAddDoc = (resp) => {
		console.log("respdoc..", resp);
		if (resp.status) {
			const { docName, empDocument, docType, docFileName } = this.state;

			if (this.state.deleteDoc) {
				var data = this.state.workerDocuments;
				data.splice(this.state.id, 1);
				this.setState({
					workerDocuments: data,
					deleteDoc: false,
					loading: true,
					id: "",
					docType: "",
					docId: "",
				});
			} else {
				var workerDocuments = this.state.workerDocuments;

				var obj = {
					docId: resp.id,
					docName: docName,
					docType: docType,
					empDocument: empDocument,
					docPath: resp.fileUrl,
					docFileName: docFileName,
					fileStatus: resp.fileStatus,
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.workerDocuments;
									console.log(
										"printing-------------------------",
										this.state.handleAttachment
									);
									data.find((o, i) => {
										if (o.docId == obj.docId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											//  this.basicItemAlert("Do You Like To Delete This Item?");
											getFileDownload(
												serverConfig.SERVER_URL + o.docPath,
												o.docFileName,
												docType,
												this.handleAttachment
											);
											// this.handleAttachment(serverConfig.SERVER_URL + o.docPath);
											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Attachment />
							</IconButton>
							{this.state.hrmsFullAccess &&
							obj.fileStatus != 3 &&
							obj.fileStatus != 4 ? (
								<Button
									color="primary"
									onClick={() => {
										var data = this.state.workerDocuments;
										data.find((o, i) => {
											if (o.docId == obj.docId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												this.setState({
													id: i,
													approveDoc: true,
													docId: o.docId,
												});
												this.basicItemAlert(
													"Do You Like To Approve This Document?"
												);

												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Verify
								</Button>
							) : null}
							{this.state.hrmsFullAccess &&
							obj.fileStatus != 3 &&
							obj.fileStatus != 4 ? (
								<Button
									color="danger"
									onClick={() => {
										var data = this.state.workerDocuments;
										data.find((o, i) => {
											if (o.docId == obj.docId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												this.setState({
													id: i,
													rejectDoc: true,
													docId: o.docId,
												});
												this.basicItemAlert(
													"Do You Like To Reject This Document?"
												);
												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Reject
								</Button>
							) : null}
							{this.state.hrmsFullAccess &&
							(obj.fileStatus == 3 || obj.fileStatus == 4) ? (
								<Button
									color={
										obj.fileStatus == 3
											? "primaryNoBackground"
											: "dangerNoBackground"
									}
								>
									{obj.fileStatus == 3 ? "Approved" : "Rejected"}
								</Button>
							) : null}
							<IconButton
								onClick={() => {
									var data = this.state.workerDocuments;
									data.find((o, i) => {
										if (o.docId === obj.docId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteDoc: true,
												docId: o.docId,
												docType: o.docType,
											});
											this.basicItemAlert(
												"Do You Like To Delete This Document?"
											);

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						</div>
					),
				};
				workerDocuments.push(obj);
				this.setState({
					docName: "",
					docType: "",
					empDocument: "",
					workerDocuments: workerDocuments,
					showDocPopup: false,
					loading: true,
					docFileName: "",
					// docID:resp.id
				});

				//  uploadFile(docFileName,empDocument,docType,6,docFileName, resp.id, this.handleAddDocument);
			}
		}
		this.showMessage(resp.message, false);
	};

	handleAddDocument = (resp) => {
		var data = this.state.workerDocuments;
		data.find((o, i) => {
			if (o.docId === this.state.docID) {
				data[i].docPath = resp.fileUrl;
				this.setState({
					workerDocuments: data,
				});
				return true;
			}
		});
	};
	handleAddEdu = (resp) => {
		if (resp.status) {
			const {
				ebId,
				eduInstitute,
				eduPercentage,
				eduType,
				empCode,
				yearOfPassing,
				docFileName,
				empDocument,
				docType,
				docName,
			} = this.state;

			if (this.state.deleteEdu) {
				var data = this.state.workerEducation;
				data.splice(this.state.id, 1);
				this.setState({
					workerEducation: data,
					deleteEdu: false,
					loading: true,
					id: "",
				});
			} else {
				var workerEducation = this.state.workerEducation;
				var obj = {
					educationId: resp.id,
					eduInstitute: eduInstitute,
					eduPercentage: eduPercentage,
					eduType: eduType,
					yearOfPassing: yearOfPassing,
					docPath: "",
					docName: docName,
					docType: docType,
					docFileName: docFileName,
					fileStatus: 1,
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.workerEducation;
									data.find((o, i) => {
										if (o.educationId === obj.educationId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											//  this.basicItemAlert("Do You Like To Delete This Item?");
											getFileDownload(
												serverConfig.SERVER_URL + o.docPath,
												o.docFileName,
												docType,
												this.handleAttachment
											);
											// this.handleAttachment(serverConfig.SERVER_URL + o.docPath);
											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Attachment />
							</IconButton>
							{this.state.hrmsFullAccess ? (
								<Button
									color="primary"
									onClick={() => {
										var data = this.state.workerEducation;
										data.find((o, i) => {
											if (o.educationId === obj.educationId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												var data = {
													fileUploadId: o.docId,
													fileStatus: 3,
												};
												requestList(
													serverApi.UPDATE_FILE_INFO,
													data,
													this.handleVerify
												);
												// this.handleAttachment(serverConfig.SERVER_URL + o.docPath);
												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Verify
								</Button>
							) : null}

							<IconButton
								onClick={() => {
									var data = this.state.workerEducation;
									data.find((o, i) => {
										if (o.educationId === obj.educationId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteEdu: true,
												educationId: o.educationId,
											});
											this.basicItemAlert("Do You Like To Delete This Item?");

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						</div>
					),
				};
				workerEducation.push(obj);
				if (empDocument !== undefined && empDocument !== null) {
					uploadFile(
						docFileName,
						empDocument,
						docType,
						42,
						docName,
						resp.id,
						this.handleAddEduDoc
					);

					//uploadFormdataWithImage(serverApi.WORKER_DOCUMENT_DETAILS_ADD, data, this.handleAddDoc)
				} else {
					this.basicAlert("Please Enter Mandatory Fields..!!");
				}
				this.setState({
					eduInstitute: "",
					eduPercentage: "",
					eduType: "",
					yearOfPassing: "",
					workerEducation: workerEducation,
					showEduPopup: false,
					loading: true,
				});
			}
		}
		this.showMessage(resp.message, false);
	};
	handleAddEduDoc = (resp) => {
		if (resp.status && this.state.AddExp) {
			var workerEducation1 = this.state.workerEducation;
			workerEducation1[workerEducation1.length - 1].docPath = resp.fileUrl;
			this.setState({ workerEducation: workerEducation1, AddExp: false });
		}
		this.setState({
			docFileName: "",
			empDocument: null,
			docType: "",
			docName: "",
		});
	};
	handleAddExpDoc = (resp) => {
		if (resp.status) {
			console.log("workerExperience", this.state.workerExperience);
			var workerEducation1 = this.state.workerExperience;
			workerEducation1[workerEducation1.length - 1].docPath = resp.fileUrl;
			this.setState({ workerExperience: workerEducation1, AddExp: false });
		}
		this.setState({
			docFileName: "",
			empDocument: null,
			docType: "",
			docName: "",
		});
	};
	handleViewClick = (id) => {
		if (!getMenuPermission(0)) {
			this.basicAlert("Access Denied. Please contact administrator!!!");
		} else {
			getDataFromUrl(
				serverApi.WORKER_MASTER_DETAIL_BY_ID +
					id +
					"/userId/" +
					getCookie(cons.USERID),
				this.handleViewClickResp
			);
		}
	};

	handleViewClickResp = (resp) => {
		this.callPaySchemeComponentList();
		this.callPaySchemeList();
		var header = resp.data.worker;
		if (resp.status) {
			if (header !== null) {
				this.setState({
					updateView: true,
					isCreated: true,
					empId: resp.id,
					empCodeSeqNo: header.empCodeSeqNo,
					ebId: header.ebId,
					ebFixedId: header.ebFixedId,
					empCode: header.edId,
					category: header.cataId,
					ebNo: header.ebNo,
					workerName: header.workerName,
					middleName: header.middleName,
					lastName: header.lastName,
					sex: header.sex,
					dateOfBirth: header.dateOfBirth,
					fatherOrHusbandName: header.fatherOrHusbandName,
					empCity: header.empCity,
					empPincode: header.empPincode,
					empAddr: header.empAddr,
					phoneNo: header.empAddr,
					mobileNo: header.mobileNo,
					emergencyMobileNo: header.emergencyMobileNo,
					officeMobileNo: header.officeMobileNo,
					maritalStatus: header.maritalStatus,
					panNo: header.panNo,
					bloodGroup: header.bloodGroup,
					nomineeName: header.nomineeName,
					nomineeRelationship: header.nomineeRelationship,
					religion: header.religion,
					personalBankName: header.personalBankName,
					personalBankIFSC: header.personalBankIFSC,
					personalBankAcNo: header.personalBankAcNo,
					personalBankBranch: header.personalBankBranch,
					deptId: header.deptId,
					branchId: header.branchId,
					// paySchemeId: header.paySchemeId,
					dateOfJoining: header.dateOfJoining,
					empLevelId: header.empLevelId,
					workLocation: header.workLocation,
					cataId: header.cataId,
					designationId: header.designationId,
					pfNo: header.pfNo,
					esiNo: header.esiNo,
					officialEmailId: header.officialEmailId,
					reportingToEbId: header.reportingToEbId,
					//appRoleId: header.appRoleId,
					//portalRoleId: header.portalRoleId,
					pfUANName: header.pfUANName,
					empStatus: header.empStatus,
					active: header.active,
					dateOfPfJoining: header.dateOfPfJoining,
					contractorId: header.contractorId,
					licNo: header.licNo,
					retiredDate: header.retiredDate,
					officeBankName: header.officeBankName,
					officeBankBranch: header.officeBankBranch,
					officeBankIFSC: header.officeBankIFSC,
					officeBankAcNo: header.officeBankAcNo,
					quarterNo: header.quarterNo,
					// userName: header.userName,
					//  imageFile: header.workerImage,
					hrmsFullAccess: header.hrmsFullAccess,
					// faceImageTypeId: header.faceImageTypeId,
					//  phtoUrl:header.photoUrl,
					//  imagePreviewUrl:  header.profilePicPath!==""?serverConfig.SERVER_URL + header.profilePicPath:null,
					createdOn: header.updatedOnDesc,
					ledgerGroupId: header.ledgerGroupId,
					parentLedger: header.parentLedger,
					facebookId: header.facebookId,
					state: header.state,
					country: header.country,
					nationality: header.nationality,
					accommodatioType: header.accommodatioType,
					contractDurationInMonths: header.contractDurationInMonths,
					prohibitionInMonths: header.prohibitionInMonths,
					passportNo: header.passportNo,
					passportIssueDate: header.passportIssueDate,
					passportExpiryDate: header.passportExpiryDate,
					drivingLicenseNo: header.drivingLicenseNo,
					drivingIssueDate: header.drivingIssueDate,
					drivingExpiryDate: header.drivingExpiryDate,
					visaNo: header.visaNo,
					visaIssueDate: header.visaIssueDate,
					approverButton: resp.data.approverButton,
					updateButton: resp.data.updateButton,
				});
			}
			var eduList = resp.data.workerEducation.map((prop, key) => {
				var obj = {
					educationId: prop.educationId,
					eduType: prop.eduType,
					yearOfPassing: prop.yearOfPassing,
					eduInstitute: prop.eduInstitute,
					eduPercentage: prop.eduPercentage,
					docId: "",
					docPath: "",
					docName: "",
					docType: "",
					docFileName: "",
					fileStatus: "",
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.workerEducation;
									data.find((o, i) => {
										if (o.educationId === obj.educationId) {
											if (o.docPath) {
												getFileDownload(
													serverConfig.SERVER_URL + o.docPath,
													o.docFileName,
													o.docType,
													this.handleAttachment
												);
											} else {
												getDataFromUrl(
													serverApi.GET_FILE_BY_SOURCE +
														"42/" +
														o.educationId +
														"/" +
														getCookie(cons.COMPANY_ID),
													(resp) => {
														if (resp.data) {
															var prop = resp.data[0];
															if (prop) {
																data[i].docId = prop.fileUploadId;
																data[i].docName = prop.displayName;
																data[i].docType = prop.fileExtension;
																data[i].docFileName = prop.fileName;
																data[i].docPath = prop.retrievalPath;
																data[i].fileStatus = prop.fileStatus;
																getFileDownload(
																	serverConfig.SERVER_URL + prop.retrievalPath,
																	prop.fileName,
																	prop.fileExtension,
																	this.handleAttachment
																);
															} else {
																this.basicAlert("No Files Attached..!!");
															}
														}
													}
												);
											}

											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											//  this.basicItemAlert("Do You Like To Delete This Item?");

											// this.handleAttachment(serverConfig.SERVER_URL + o.docPath);
											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Attachment />
							</IconButton>

							{this.state.hrmsFullAccess ? (
								<Button
									color="primary"
									onClick={() => {
										var data = this.state.workerEducation;
										data.find((o, i) => {
											if (o.educationId === obj.educationId) {
												if (o.docId) {
													var data = {
														fileUploadId: o.docId,
														fileStatus: 3,
													};
													requestList(
														serverApi.UPDATE_FILE_INFO,
														data,
														this.handleVerify
													);
												} else {
													getDataFromUrl(
														serverApi.GET_FILE_BY_SOURCE +
															"42/" +
															o.educationId +
															"/" +
															getCookie(cons.COMPANY_ID),
														(resp) => {
															if (resp.data) {
																var prop = resp.data[0];
																if (prop) {
																	data[i].docId = prop.fileUploadId;
																	data[i].docName = prop.displayName;
																	data[i].docType = prop.fileExtension;
																	data[i].docFileName = prop.fileName;
																	data[i].docPath = prop.retrievalPath;
																	data[i].fileStatus = prop.fileStatus;
																	var data = {
																		fileUploadId: prop.fileUploadId,
																		fileStatus: 3,
																	};
																	requestList(
																		serverApi.UPDATE_FILE_INFO,
																		data,
																		this.handleVerify
																	);
																} else {
																	this.basicAlert("No Files Attached..!!");
																}
															}
														}
													);
												}

												return true;
											}
										});
									}}
								>
									Verify
								</Button>
							) : null}
							<IconButton
								onClick={() => {
									var data = this.state.workerEducation;
									data.find((o, i) => {
										if (o.educationId === prop["educationId"]) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteEdu: true,
												educationId: o.educationId,
											});
											this.basicItemAlert("Do You Like To Delete This Item?");

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						</div>
					),
				};
				return obj;
			});
			this.setState({ workerEducation: eduList });
			getDataFromUrl(
				serverApi.GET_FILE_BY_SOURCE +
					"1/" +
					header.ebId +
					"/" +
					getCookie(cons.COMPANY_ID),
				this.handleProfileImage
			);
			getDataFromUrl(
				serverApi.GET_FILE_BY_SOURCE +
					"6/" +
					header.ebId +
					"/" +
					getCookie(cons.COMPANY_ID),
				this.handleDocFiles
			);
			this.getCriminalRecordsListByEmpId(header.ebId);
			this.getExperienceDetailsById(header.ebId);

			var bioMetricEmpMapList = resp.data.bioMetricEmpMapList.map(
				(prop, key) => {
					var obj = {
						bioId: prop.mappingId,
						bioDeviceId: prop.bioDeviceId,
						empBioDeviceId: prop.empBioDeviceId,
						empRFNo: prop.empRFNo,
						bioDeviceName: prop.bioDeviceName,
						activeStatus: prop.activeStatus === 1 ? "Active" : "InActive",
						actions: (
							<div className="actions-right">
								<IconButton
									onClick={() => {
										var data = this.state.bioMetricEmpMapList;
										data.find((o, i) => {
											if (o.bioId === prop["mappingId"]) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												this.setState({
													id: i,
													deleteBio: true,
													bioId: o.bioId,
												});
												this.basicItemAlert("Do You Like To Delete This Item?");

												//  data.splice(i,1);
												return true;
											}
										});
									}}
									color="dangerNoBackground"
									customClass="remove"
								>
									<Close />
								</IconButton>
							</div>
						),
					};
					return obj;
				}
			);
			var employeeShiftMapList = resp.data.employeeShiftMapList.map(
				(prop, key) => {
					var obj = {
						shiftId: prop.empShiftMapId,
						lateMins1: prop.lateMins1,
						lateMins2: prop.lateMins2,
						roasterDays: prop.roasterDays,
						roasterGapDays: prop.roasterGapDays,
						roasterStartDate: prop.roasterStartDate,
						spell1: prop.spell1,
						spell2: prop.spell2,
						weekOffDay: prop.weekOffDay,
						weekOffDay2: prop.weekOffDay2,
						weekOffHalfDay: prop.weekOffHalfDay,
						actions: (
							<div className="actions-right">
								<IconButton
									onClick={() => {
										var data = this.state.employeeShiftMapList;
										data.find((o, i) => {
											if (o.shiftId === prop["empShiftMapId"]) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												this.setState({
													id: i,
													deleteShift: true,
													shiftId: o.shiftId,
												});
												this.basicItemAlert("Do You Like To Delete This Item?");

												//  data.splice(i,1);
												return true;
											}
										});
									}}
									color="dangerNoBackground"
									customClass="remove"
								>
									<Close />
								</IconButton>
							</div>
						),
					};
					return obj;
				}
			);

			var EmpPayschme = resp.data.employeePascheme;
			//  this.handleViewClickRespPayscheme(EmpPayschme);
			if (EmpPayschme.payEmployeeDTO !== null) {
				var payheader = EmpPayschme.payEmployeeDTO;
				/*  var list = EmpPayschme.payEmployeeStructureDTO, paySchemeListEarningsData=[],
                    paySchemeListDeductionData=[],paySchemeListNoCalData=[];
                list.forEach((prop,key)=>{
                    var obj={
                        componentId:prop.componentId,
                        componentvalue:this.getComponentName(prop.componentId,"name"),
                       // formula:this.getComponentName(prop.componentId,"formula"),
                        type:this.getComponentName(prop.componentId,"type"),
                        value:prop.amount
                    };
                    if(obj.type===0&&prop.status===1){
                        paySchemeListNoCalData.push( obj);
                    }
                    if(obj.type===1&&prop.status===1){
                        paySchemeListEarningsData.push( obj);
                    }
                    if(obj.type===2&&prop.status===1){
                        paySchemeListDeductionData.push(obj);
                    }
                });*/
				//console.log("indentWeight "+header.indentWeight);
				if (header !== null) {
					this.setState({
						paySchemeId: payheader.paySchemeId,
						empPayschemeId: payheader.id,
						paySchemeIdNew: payheader.paySchemeId,
						// loadPayscheme:true,
						effectiveFrom: payheader.effectiveFrom,
						paySchemeLineData: EmpPayschme.payEmployeeStructureDTO,
						/*  paySchemeListEarningsData:paySchemeListEarningsData,
                        paySchemeListDeductionData:paySchemeListDeductionData,paySchemeListNoCalData:paySchemeListNoCalData
*/
					});
				}
				getDataFromUrl(
					serverApi.PAY_SCHEME_GET_BYID + "/" + payheader.paySchemeId,
					this.handleViewClickRespPayscheme
				);
			}

			var empLeavePolicyMapping = resp.data.empLeavePolicyMapping;
			if (empLeavePolicyMapping !== undefined) {
				this.setState({
					mappingId: empLeavePolicyMapping.mappingId,
					leavePolicyId: parseInt(empLeavePolicyMapping.leavePolicyId),
					reloadLeave: true,
				});
			}
			this.setState({
				//workerEducation: eduList,
				//  workerDocuments: docList,
				bioMetricEmpMapList: bioMetricEmpMapList,
				employeeShiftMapList: employeeShiftMapList,
			});
		}
	};
	getExperienceDetailsById = (id) => {
		getDataFromUrl(
			serverApi.GET_EXPERIENCE_BY_EMP + "/" + id,
			this.handleExperienceDetailsById
		);
	};
	handleExperienceDetailsById = (resp) => {
		//add file details here
		if (resp) {
			var list = [];
			const {
				ebId,
				docFileName,
				empDocument,
				docType,
				docName,
				AddExp,
			} = this.state;
			if (resp.length > 0) {
				//var list = resp;
				var obj = {};
				resp.forEach((prop, i) => {
					obj = {
						...prop,
						docId: "",

						docType: "",
						docName: "",
						docFileName: "",
						docPath: "",
						fileStatus: "",
					};

					if (prop.filesDto.length > 0) {
						obj.docType = prop.filesDto[0].fileExtension;
						obj.docName = prop.filesDto[0].fileName;
						obj.docFileName = prop.filesDto[0].fileName;
						obj.fileStatus = prop.filesDto[0].fileStatus;
						obj.docPath = prop.filesDto[0].retrievalPath;
						obj.docId = prop.filesDto[0].fileUploadId;
					}
					list.push(obj);
				});
				if (AddExp && docFileName && empDocument) {
					obj = resp[resp.length - 1];
					obj = {
						...obj,

						docType: docType,
						docName: docName,
						docFileName: docFileName,
						docPath: "",
						fileStatus: "",
					};

					list[resp.length - 1] = obj;
					uploadFile(
						docFileName,
						empDocument,
						docType,
						43,
						docName,
						obj.autoId,
						this.handleAddExpDoc,
						ebId
					);
				}
			}
			console.log("workerExperience", list);

			this.setState({ workerExperience: list });
		}
	};
	handleDocFiles = (resp) => {
		console.log("doc resp", resp);
		var docList = resp.data.map((prop, key) => {
			var obj = {
				docId: prop.fileUploadId,
				docName: prop.displayName,
				docPath: prop.retrievalPath,
				empDocument: "",
				docType: prop.fileExtension,
				docFileName: prop.fileName,
				fileStatus: prop.fileStatus,
				actions: (
					<div className="actions-right">
						<IconButton
							onClick={() => {
								var data = this.state.workerDocuments;
								data.find((o, i) => {
									if (o.docId === prop["fileUploadId"]) {
										// here you should add some custom code so you can delete the data
										// from this component and from your server as well
										//  this.basicItemAlert("Do You Like To Delete This Item?");
										getFileDownload(
											serverConfig.SERVER_URL + o.docPath,
											o.docFileName,
											o.docType,
											this.handleAttachment
										);
										//  data.splice(i,1);
										return true;
									}
								});
							}}
							color="dangerNoBackground"
							customClass="remove"
						>
							<Attachment />
						</IconButton>
						{this.state.hrmsFullAccess &&
						prop.fileStatus != 3 &&
						prop.fileStatus != 4 ? (
							<Button
								color="primary"
								onClick={() => {
									var data = this.state.workerDocuments;
									data.find((o, i) => {
										if (o.docId === prop["fileUploadId"]) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											//  this.basicItemAlert("Do You Like To Delete This Item?");
											this.setState({
												id: i,
												approveDoc: true,
												docId: o.docId,
											});
											this.basicItemAlert(
												"Do You Like To Approve This Document?"
											);

											//  data.splice(i,1);
											return true;
										}
									});
								}}
							>
								Verify
							</Button>
						) : null}
						{this.state.hrmsFullAccess &&
						prop.fileStatus != 3 &&
						prop.fileStatus != 4 ? (
							<Button
								color="danger"
								onClick={() => {
									var data = this.state.workerDocuments;
									data.find((o, i) => {
										if (o.docId === prop["fileUploadId"]) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											//  this.basicItemAlert("Do You Like To Delete This Item?");
											this.setState({
												id: i,
												rejectDoc: true,
												docId: o.docId,
											});
											this.basicItemAlert(
												"Do You Like To Reject This Document?"
											);
											//  data.splice(i,1);
											return true;
										}
									});
								}}
							>
								Reject
							</Button>
						) : null}
						{this.state.hrmsFullAccess &&
						(prop.fileStatus == 3 || prop.fileStatus == 4) ? (
							<Button
								color={
									prop.fileStatus == 3
										? "primaryNoBackground"
										: "dangerNoBackground"
								}
							>
								{prop.fileStatus == 3 ? "Approved" : "Rejected"}
							</Button>
						) : null}
						{this.state.updateButton ? (
							<IconButton
								onClick={() => {
									var data = this.state.workerDocuments;
									data.find((o, i) => {
										if (o.docId === prop["fileUploadId"]) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteDoc: true,
												docId: o.docId,
												docType: o.docType,
											});
											this.basicItemAlert(
												"Do You Like To Delete This Document?"
											);

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						) : null}
					</div>
				),
			};
			return obj;
		});
		this.setState({ workerDocuments: docList });
	};
	handleProfileImage = (resp) => {
		if (resp.status) {
			if (resp.data.length > 0)
				getFileDownload(
					serverConfig.SERVER_URL + resp.data[0].retrievalPath,
					"",
					resp.data[0].fileExtension,
					this.handleDownloadedFile
				);
		}
	};
	handleDownloadedFile = (url) => {
		this.setState({ imagePreviewUrl: url });
	};

	handleViewClickRespPayscheme = (resp) => {
		var header = resp.paySchemeDTO;
		var list = resp.paySchemeDetailsDTO,
			paySchemeListEarningsData = [],
			paySchemeListDeductionData = [],
			paySchemeListNoCalData = [],
			paySchemeListSummaryData = [];
		list.forEach((prop, key) => {
			var obj = {
				id: paySchemeListNoCalData.length,
				lineItemId: prop.id,
				componentId: prop.componentId,
				componentvalue: this.getComponentName(prop.componentId, "name"),
				formula: prop.formula,
				type: parseInt(prop.type),
				value: "",
			};
			console.log("paySchemeLineData", this.state.paySchemeLineData);
			if (
				this.state.paySchemeLineData.length > 0 &&
				this.state.paySchemeIdNew === this.state.paySchemeId
			) {
				this.state.paySchemeLineData.forEach((p) => {
					if (p.componentId === prop.componentId) {
						obj.value = p.amount;
						if (obj.type === 0 && prop.status === 1) {
							obj.formula = p.amount;
						}
					}
				});
			}
			if (obj.type === 0 && prop.status === 1) {
				paySchemeListNoCalData.push(obj);
			}
			if (obj.type === 1 && prop.status === 1) {
				paySchemeListEarningsData.push(obj);
			}
			if (obj.type === 2 && prop.status === 1) {
				paySchemeListDeductionData.push(obj);
			}
			if (obj.type === 3 && prop.status === 1) {
				paySchemeListSummaryData.push(obj);
			}
		});
		//console.log("indentWeight "+header.indentWeight);
		if (header !== null) {
			this.setState({
				// loadPayscheme:true,
				//  effectiveFrom:header.effectiveFrom,
				paySchemeListEarningsData: paySchemeListEarningsData,
				paySchemeListDeductionData: paySchemeListDeductionData,
				paySchemeListNoCalData: paySchemeListNoCalData,
				paySchemeListSummaryData: paySchemeListSummaryData,
			});
		}
		if (this.state.paySchemeLineData.length === 0 || this.state.autoPayscheme) {
			this.onClaculationButtonClick();
		}
	};
	onClaculationButtonClick = () => {
		const {
			payschemeId,
			description,
			effectiveFrom,
			payschemeCode,
			payschemeName,
			wageType,
			paySchemeListEarningsData,
			paySchemeListDeductionData,
			paySchemeListNoCalData,
			paySchemeListSummaryData,
		} = this.state;

		var PayschemeList = [
			...paySchemeListEarningsData,
			...paySchemeListDeductionData,
			...paySchemeListNoCalData,
			...paySchemeListSummaryData,
		];

		var obj1 = {};
		PayschemeList.forEach(function(prop, keyIndex) {
			obj1 = { ...obj1, [prop.componentId]: prop.formula };
		});
		requestList(
			serverApi.CHECK_PAYSCHEME + "/1111/" + this.state.paySchemeId,
			obj1,
			this.handleCheckPayscheme
		);
	};

	handleCheckPayscheme = (resp) => {
		if (resp.status === true) {
			console.log("checkPayscheme", resp);
			const {
				paySchemeListEarningsData,
				paySchemeListDeductionData,
				paySchemeListNoCalData,
				paySchemeListSummaryData,
			} = this.state;
			var paySchemeListEarningsData1 = paySchemeListEarningsData.map((prop) => {
				var amount = "";
				if (resp.list)
					resp.list.find((o) => {
						if (o.componentId === prop.componentId) {
							amount = o.amount;
							return true;
						}
					});
				return { ...prop, value: amount };
			});
			var paySchemeListDeductionData1 = paySchemeListDeductionData.map(
				(prop) => {
					var amount = "";
					if (resp.list)
						resp.list.find((o) => {
							if (o.componentId === prop.componentId) {
								amount = o.amount;
								return true;
							}
						});
					return { ...prop, value: amount };
				}
			);
			var paySchemeListNoCalData1 = paySchemeListNoCalData.map((prop) => {
				var amount = "";
				if (resp.list)
					resp.list.find((o) => {
						if (o.componentId === prop.componentId) {
							amount = o.amount;
							return true;
						}
					});
				return { ...prop, value: amount };
			});
			var paySchemeListSummaryData1 = paySchemeListSummaryData.map((prop) => {
				var amount = "";
				if (resp.list)
					resp.list.find((o) => {
						if (o.componentId === prop.componentId) {
							amount = o.amount;
							return true;
						}
					});
				return { ...prop, value: amount };
			});

			this.setState({
				paySchemeListEarningsData: paySchemeListEarningsData1,
				paySchemeListDeductionData: paySchemeListDeductionData1,
				paySchemeListNoCalData: paySchemeListNoCalData1,
				paySchemeListSummaryData: paySchemeListSummaryData1,
			});
			if (this.state.autoPayscheme) {
				var payscheme = [
					...paySchemeListEarningsData1,
					...paySchemeListDeductionData1,
					...paySchemeListNoCalData1,
					...paySchemeListSummaryData1,
				];
				this.onClickEmpPaySchemeMapping(payscheme);
			}
		}

		// console.log("paySchemeListEarningsData1",paySchemeListEarningsData1);
	};

	callPaySchemeList(designationId, workLocation) {
		var data = {
			status: "32",
			activeStatus: "1",
			branchId: workLocation,
			designationId: designationId,
		};
		requestList(serverApi.PAY_SCHEME_GET_LIST, data, this.handlePaySchemeList);
	}

	handlePaySchemeList = (resp) => {
		console.log("payresp", resp);
		if (resp.status === undefined) {
			var payscheme = [];
			resp.map((prop, i) => {
				var obj = {
					value: prop.id,
					name: prop.name,
					label: prop.name,
				};
				payscheme.push(obj);
			});
			if (payscheme.length > 0 && this.state.autoPayscheme) {
				this.setState({ paySchemeId: payscheme[0].value });
				getDataFromUrl(
					serverApi.PAY_SCHEME_GET_BYID + "/" + payscheme[0].value,
					this.handleViewClickRespPayscheme
				);
			}

			this.setState({ paySchemeList: payscheme, loadPayscheme: true });
		}
	};

	basicItemAlert(msg) {
		this.setState({
			basicAlert: (
				<SweetAlert
					style={{
						display: "block",
						marginTop: "-100px",
						marginLeft: "-80px",
						fontSize: "8px",
						color: "red",
					}}
					title={msg}
					onConfirm={() => this.hideItemBasicAlert()}
					onCancel={() => this.hideBasicAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
					cancelBtnCssClass={
						this.props.classes.button + " " + this.props.classes.danger
					}
					confirmBtnText="Yes"
					cancelBtnText="No"
					showCancel
				/>
			),
		});
	}

	hideItemBasicAlert() {
		if (this.state.updateItemAlert) {
			this.setState({
				alert: true,
				updateItemAlert: false,
			});
		}

		if (this.state.deleteEdu) {
			getDataFromUrl(
				serverApi.WORKER_EDUCATION_DETAILS_DELETE + this.state.educationId,
				this.handleAddEdu
			);
			getDataFromUrl(
				serverApi.DELETE_FILE_BI_ID + this.state.docId,
				this.handleVerify
			);
		}
		if (this.state.approveDoc || this.state.approveExp) {
			var data = {
				fileUploadId: this.state.docId,
				fileStatus: 3,
			};
			requestList(serverApi.UPDATE_FILE_INFO, data, this.handleVerify);
		}
		if (this.state.rejectDoc || this.state.rejectExp) {
			var data = {
				fileUploadId: this.state.docId,
				fileStatus: 4,
			};
			requestList(serverApi.UPDATE_FILE_INFO, data, this.handleVerify);
		}
		if (this.state.deleteDoc) {
			getDataFromUrl(
				serverApi.DELETE_FILE_BI_ID + this.state.docId,
				this.handleAddDoc
			);
			//getDataFromUrl(serverApi.WORKER_DOCUMENT_DETAILS_DELETE + this.state.docId + '/ebId/' + this.state.ebId + "/" + this.state.docType, this.handleAddDoc);
		}
		if (this.state.deleteBio) {
			getDataFromUrl(
				serverApi.WORKER_BIOMETRIC_DELETE +
					this.state.bioId +
					"/" +
					getCookie(cons.USERID),
				this.handleAddBioMetric
			);
		}
		if (this.state.deleteShift) {
			getDataFromUrl(
				serverApi.WORKER_SHIFT_MAPPING_DELETE +
					this.state.shiftId +
					"/" +
					getCookie(cons.COMPANY_ID),
				this.handleAddShiftMapping
			);
		}
		if (this.state.deleteExp) {
			var data = [];
			var workerExperience = this.state.workerExperience;
			workerExperience[this.state.id].isActive = 0;
			data.push(workerExperience[this.state.id]);

			requestList(serverApi.ADD_EXPERIENCE, data, this.handleAddExp);
			getDataFromUrl(
				serverApi.DELETE_FILE_BI_ID + this.state.docId,
				this.handleVerify
			);
		}

		this.setState({
			basicAlert: null,
		});
	}
	handleVerify = (resp) => {
		if (resp.status) {
			var workerDocuments = this.state.workerDocuments;
			if (this.state.approveExp) {
				workerDocuments = this.state.workerExperience;
				workerDocuments[this.state.id].fileStatus = 3;
				this.setState({ workerExperience: workerDocuments });
			} else if (this.state.approveDoc) {
				workerDocuments[this.state.id].fileStatus = 3;
				this.setState({ workerDocuments: workerDocuments });
			} else if (this.state.rejectDoc) {
				workerDocuments[this.state.id].fileStatus = 4;
				this.setState({ workerDocuments: workerDocuments });
			} else if (this.state.rejectExp) {
				workerDocuments = this.state.workerExperience;
				workerDocuments[this.state.id].fileStatus = 4;
				this.setState({ workerExperience: workerDocuments });
			}
		}
		this.showMessage(resp.message, resp.status);
	};

	handleFileClick() {
		var input = document.createElement("input");
		input.type = "file";
		input.onchange = this.handleFileChange;
		input.click();
	}

	handleFileChange(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		//  console.log("file type",file.type);
		var fileName = file.name,
			fileType;
		fileName = fileName.split(".");
		fileType = fileName[1];
		reader.onloadend = () => {
			// if(fileType==='PDF'||fileType==='JPG'||fileType==='JPEG'||fileType==='PNG'||fileType==='XLSX'){

			this.setState({
				empDocument: file,
				docFileName: file.name,
				docType: fileType,
			});

			/*  }else {
                    this.setState({
                        file: file,
                        fileName: file.name,
                        //filesList: arr,
                        uploadInvoice: true
                        // imagePreviewUrl: reader.result
                    });
                }*/
		};
		reader.readAsDataURL(file);
	}

	handleLeavePolicyMapping = (resp) => {
		this.showMessage(resp.message, false);
	};
	handleLeavePolicyClick = (id) => {
		getDataFromUrl(
			serverApi.LEAVE_POLICIES_GET_BYID + "/" + id,
			this.handleLeavePolicyResp
		);
	};
	handleLeavePolicyResp = (resp) => {
		var header = resp.hdr;
		var list = resp.dtlList;
		var policylist = list.map((prop, key) => {
			return {
				id: key,
				leavePolicyDtlId: prop.leavePolicyDtlId,
				leavePolicyId: prop.leavePolicyId,
				carryFrwStatus: prop.carryFrwStatus,
				carryFrwStatusName: prop.carryFrwStatus === "Y" ? "YES" : "NO",
				encashmentStatus: prop.encashmentStatus,
				encashmentStatusName: prop.encashmentStatus === "Y" ? "YES" : "NO",
				leaveTypeName: prop.leaveTypeName,
				leaveTypeId: prop.leaveTypeId,
				leavesPerYear: prop.leavesPerYear,
				maxConsecutiveCount: prop.maxConsecutiveCount,
				maxLeavesPerYear: prop.maxLeavesPerYear,
				yearEndDate: prop.yearEndStateDesc,
				yearStartDate: prop.yearStartDateDesc,
			};
		});
		//console.log("indentWeight "+header.indentWeight);
		if (header !== null) {
			this.setState({
				leavePolicieList: policylist,
			});
		}
	};

	handleLoading = () => {
		this.setState({
			isLoading: !this.state.isLoading,
		});
	};

	handlePage = (start, end) => {
		this.setState({
			startIndex: start,
			lastIndex: end,
		});
		// this.getReportsTable(start,end);
		this.handleSeacrh();
	};
	getIsFilledTab = (name) => {
		const {
			ebId,
			ebFixedId,
			empCodeSeqNo,
			ebNo,
			empCode,
			workerName,
			middleName,
			lastName,
			sex,
			dateOfBirth,
			fatherOrHusbandName,
			empCity,
			empPincode,
			empAddr,
			phoneNo,
			mobileNo,
			emergencyMobileNo,
			officeMobileNo,
			maritalStatus,
			panNo,
			bloodGroup,
			nomineeName,
			nomineeRelationship,
			religion,
			personalBankName,
			personalBankIFSC,
			personalBankAcNo,
			personalBankBranch,
			imageFile,
			deptId,
			branchId,
			paySchemeId,
			dateOfJoining,
			empLevelId,
			workLocation,
			cataId,
			designationId,
			pfNo,
			esiNo,
			officialEmailId,
			reportingToEbId,
			appRoleId,
			portalRoleId,
			pfUANName,
			empStatus,
			dateOfPfJoining,
			contractorId,
			licNo,
			retiredDate,
			officeBankName,
			officeBankBranch,
			officeBankIFSC,
			officeBankAcNo,
			userName,
			quarterNo,
			active,
			hrmsAccess,
			hrmsFullAccess,
			faceImageTypeId,
			ledgerGroupId,
			parentLedger,
			facebookId,
			state,
			country,
			nationality,
			accommodatioType,
			contractDurationInMonths,
			prohibitionInMonths,
			passportNo,
			passportIssueDate,
			passportExpiryDate,
			drivingLicenseNo,
			drivingIssueDate,
			drivingExpiryDate,
			visaNo,
			visaIssueDate,
		} = this.state;

		var val = false;
		if (name === "Personal") {
			if (
				workerName &&
				lastName &&
				ebNo &&
				dateOfBirth &&
				empAddr &&
				empCity &&
				empPincode &&
				phoneNo &&
				mobileNo
			)
				val = true;
		} else if (name === "Official") {
			if (
				deptId &&
				branchId &&
				dateOfJoining &&
				cataId > 0 &&
				designationId > 0
			)
				val = true;
		}
		return val;
	};

	getDocTable = () => {
		var data = [];
		//  console.log("dta",JSON.stringify(this.state.invoiceList))
		if (this.state.workerDocuments.length > 0)
			this.state.workerDocuments.map((prop, key) => {
				prop.id = key;
				var obj = {
					...prop,
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.workerDocuments;
									data.find((o, i) => {
										if (o.docId === prop.docId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											//  this.basicItemAlert("Do You Like To Delete This Item?");
											getFileDownload(
												serverConfig.SERVER_URL + o.docPath,
												o.docFileName,
												o.docType,
												this.handleAttachment
											);
											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Attachment />
							</IconButton>
							{this.state.hrmsFullAccess &&
							prop.fileStatus != 3 &&
							prop.fileStatus != 4 ? (
								<Button
									color="primary"
									onClick={() => {
										var data = this.state.workerDocuments;
										data.find((o, i) => {
											if (o.docId === prop.docId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												this.setState({
													id: i,
													approveDoc: true,
													docId: o.docId,
												});
												this.basicItemAlert(
													"Do You Like To Approve This Document?"
												);

												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Verify
								</Button>
							) : null}
							{this.state.hrmsFullAccess &&
							prop.fileStatus != 3 &&
							prop.fileStatus != 4 ? (
								<Button
									color="danger"
									onClick={() => {
										var data = this.state.workerDocuments;
										data.find((o, i) => {
											if (o.docId === prop.docId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												this.setState({
													id: i,
													rejectDoc: true,
													docId: o.docId,
												});
												this.basicItemAlert(
													"Do You Like To Reject This Document?"
												);
												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Reject
								</Button>
							) : null}
							{this.state.hrmsFullAccess &&
							(prop.fileStatus == 3 || prop.fileStatus == 4) ? (
								<Button
									color={
										prop.fileStatus == 3
											? "primaryNoBackground"
											: "dangerNoBackground"
									}
								>
									{prop.fileStatus == 3 ? "Approved" : "Rejected"}
								</Button>
							) : null}
							{this.state.updateButton ? (
								<IconButton
									onClick={() => {
										var data = this.state.workerDocuments;
										data.find((o, i) => {
											if (o.docId === prop.docId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												this.setState({
													id: i,
													deleteDoc: true,
													docId: o.docId,
													docType: o.docType,
												});
												this.basicItemAlert(
													"Do You Like To Delete This Document?"
												);

												//  data.splice(i,1);
												return true;
											}
										});
									}}
									color="dangerNoBackground"
									customClass="remove"
								>
									<Close />
								</IconButton>
							) : null}
						</div>
					),
				};
				data.push(obj);
			});
		return data;
	};
	getExpTable = () => {
		var data = [];
		//  console.log("dta",JSON.stringify(this.state.invoiceList))
		if (this.state.workerExperience.length > 0)
			this.state.workerExperience.map((prop, key) => {
				prop.id = key;
				var obj = {
					...prop,
					actions: (
						<div className="actions-right">
							<IconButton
								onClick={() => {
									var data = this.state.workerExperience;
									data.find((o, i) => {
										if (o.autoId === prop.autoId) {
											if (o.docPath) {
												getFileDownload(
													serverConfig.SERVER_URL + o.docPath,
													o.docFileName,
													o.docType,
													this.handleAttachment
												);
											} else {
												getDataFromUrl(
													serverApi.GET_FILE_BY_SOURCE +
														"43/" +
														o.autoId +
														"/" +
														getCookie(cons.COMPANY_ID),
													(resp) => {
														if (resp.data) {
															var prop = resp.data[0];
															if (prop) {
																data[i].docId = prop.fileUploadId;
																data[i].docName = prop.displayName;
																data[i].docType = prop.fileExtension;
																data[i].docFileName = prop.fileName;
																data[i].docPath = prop.retrievalPath;
																data[i].fileStatus = prop.fileStatus;
																getFileDownload(
																	serverConfig.SERVER_URL + prop.retrievalPath,
																	prop.fileName,
																	prop.fileExtension,
																	this.handleAttachment
																);
															} else {
																this.basicAlert("No Files Attached..!!");
															}
														}
													}
												);
											}
											// this.handleAttachment(serverConfig.SERVER_URL + o.docPath);
											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Attachment />
							</IconButton>
							{this.state.hrmsFullAccess &&
							prop.fileStatus != 3 &&
							prop.fileStatus != 4 ? (
								<Button
									color="primary"
									onClick={() => {
										var data = this.state.workerExperience;
										data.find((o, i) => {
											if (o.autoId === prop.autoId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												this.setState({
													id: i,
													approveExp: true,
													docId: o.docId,
												});
												this.basicItemAlert(
													"Do You Like To Approve This Document?"
												);

												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Verify
								</Button>
							) : null}
							{this.state.hrmsFullAccess &&
							prop.fileStatus != 3 &&
							prop.fileStatus != 4 ? (
								<Button
									color="danger"
									onClick={() => {
										var data = this.state.workerExperience;
										data.find((o, i) => {
											if (o.autoId === prop.autoId) {
												// here you should add some custom code so you can delete the data
												// from this component and from your server as well
												//  this.basicItemAlert("Do You Like To Delete This Item?");
												this.setState({
													id: i,
													rejectExp: true,
													docId: o.docId,
												});
												this.basicItemAlert(
													"Do You Like To Reject This Document?"
												);
												//  data.splice(i,1);
												return true;
											}
										});
									}}
								>
									Reject
								</Button>
							) : null}
							{this.state.hrmsFullAccess &&
							(prop.fileStatus == 3 || prop.fileStatus == 4) ? (
								<Button
									color={
										prop.fileStatus == 3
											? "primaryNoBackground"
											: "dangerNoBackground"
									}
								>
									{prop.fileStatus == 3 ? "Approved" : "Rejected"}
								</Button>
							) : null}
							<IconButton
								onClick={() => {
									var data = this.state.workerExperience;
									data.find((o, i) => {
										if (o.autoId === obj.autoId) {
											// here you should add some custom code so you can delete the data
											// from this component and from your server as well
											this.setState({
												id: i,
												deleteExp: true,
												docId: o.docId,
											});
											this.basicItemAlert("Do You Like To Delete This Item?");

											//  data.splice(i,1);
											return true;
										}
									});
								}}
								color="dangerNoBackground"
								customClass="remove"
							>
								<Close />
							</IconButton>
						</div>
					),
				};
				data.push(obj);
			});
		return data;
	};
	getCriminalQuestionAswer = (questionId, answerType) => {
		var val = false;
		var list = this.state.criminalRecordList;
		if (list.length > 0) {
			list.find((o, i) => {
				if (o.questionId === questionId) {
					if (answerType == "Y") {
						if (o.answer == "Yes") val = true;
					}
					if (answerType == "N") {
						if (o.answer == "No") val = true;
					}

					return true;
				}
			});
		}
		//console.log("annn", val);

		return val;
	};
	handleAddCriminalRecords = (resp) => {
		this.showMessage(resp.message, resp.status);
	};

	render() {
		const { classes } = this.props;
		const {
			tabPosition,
			isTabOpen,
			workerEducation,
			employeeShiftMapList,
			bioMetricEmpMapList,
			leavePolicieList,
			paySchemeLineData,
			workerDocuments,
			workerExperience,
			criminalQuestions,
			criminalRecordList,
		} = this.state;
		const inputFocus = {
			autoComplete: "off",
		};
		var yesterday = Datetime.moment().subtract(1, "day");

		var valid = function(current) {
			if (getCookie(cons.COMPANY_ID) !== "2") return current.isAfter(yesterday);
			else return true;
		};
		var letterData = [
			{
				sno: 1,
				letter: "Offer Letter",
				actions: (
					<div className="actions-right">
						{this.state.empStatus != 34 ? (
							<Button
								color="primary"
								onClick={() => {
									if (this.state.empStatus == 3) {
										this.onCreateButtonClick("33");
									} else if (this.state.empStatus == 33) {
										this.onCreateButtonClick("34");
									} else if (this.state.empStatus == 34) {
										this.onCreateButtonClick("35");
									}
								}}
							>
								{" "}
								{this.state.empStatus == 3 ? "Issue Offer" : null}
								{this.state.empStatus == 33 ? "Accept Offer" : null}
								{this.state.empStatus == 34 ? "Joining" : null}
							</Button>
						) : null}
						<IconButton
							onClick={this.generatePdfHandler}
							color="dangerNoBackground"
							customClass="remove"
						>
							<PrintIcon />
						</IconButton>
					</div>
				),
			},
		];
		var Tabs;
		Tabs = [
			{
				tabButton: "Personal",
				icon: PersonalIcon,
				isFilled: this.getIsFilledTab("Personal"),

				tabContent: (
					<ItemGrid xs={12} style={{ overflow: "hidden" }}>
						<GridContainer>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<ImageUpload
									btnTitle={"Upload"}
									imagePreviewUrl={this.state.imagePreviewUrl}
									changeProfilePic={this.changeProfilePic}
								/>
							</ItemGrid>
							<ItemGrid
								xs={12}
								md={9}
								style={{ border: "2px solid #aeafb0", borderRadius: 10 }}
							>
								<GridContainer>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													First Name
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="workerName"
													value={this.state.workerName}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Middle Name
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="middleName"
													value={this.state.middleName}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Last Name
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="lastName"
													value={this.state.lastName}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={8}>
										<GridContainer>
											<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
												<GridContainer>
													<ItemGrid xs={12} sm={3}>
														<InputLabel className={classes.label}>
															EB No
															<span style={{ color: "red", fontSize: "12px" }}>
																*
															</span>
														</InputLabel>
													</ItemGrid>
													<ItemGrid xs={12} sm={9}>
														<TextField
															id="ebNo"
															value={this.state.ebNo}
															className={classes.textField}
															onChange={this.onChangeValues}
															fullWidth={true}
															variant="outlined"
														/>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>

											<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
												<GridContainer>
													<ItemGrid xs={12} sm={3}>
														<InputLabel className={classes.label}>
															DOB
														</InputLabel>
													</ItemGrid>
													<ItemGrid xs={12} sm={9}>
														<FormControl variant="" fullWidth>
															<Datetime
																closeOnSelect={true}
																timeFormat={false}
																onChange={(dateObj) => {
																	this.timeChanged(dateObj, "dateOfBirth");
																}}
																height={"15px"}
																formControlProps={{
																	height: "15px",
																	inlineBlock: "none",
																}}
																inputProps={{
																	id: "dateOfBirth",
																	value: this.state.dateOfBirth,
																	autoComplete: "off",
																}}
															/>
														</FormControl>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>

											<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
												<GridContainer>
													<ItemGrid xs={12} sm={3}>
														<InputLabel className={classes.label}>
															City
														</InputLabel>
													</ItemGrid>
													<ItemGrid xs={12} sm={9}>
														<TextField
															id="empCity"
															value={this.state.empCity}
															className={classes.textField}
															onChange={this.onChangeValues}
															fullWidth={true}
															variant="outlined"
														/>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
											<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
												<GridContainer>
													<ItemGrid xs={12} sm={3}>
														<InputLabel className={classes.label}>
															Pin Code
														</InputLabel>
													</ItemGrid>
													<ItemGrid xs={12} sm={9}>
														<TextField
															id="empPincode"
															value={this.state.empPincode}
															className={classes.textField}
															onChange={this.onChangeValues}
															fullWidth={true}
															variant="outlined"
														/>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Address
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="empAddr"
													value={this.state.empAddr}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													height={80}
													rowsMax={4}
													multiline={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Res Ph
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="phoneNo"
													value={this.state.phoneNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Mobile
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="mobileNo"
													value={this.state.mobileNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Emergency
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="emergencyMobileNo"
													value={this.state.emergencyMobileNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Gender
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.sex}
													onSelect={this.onSelect}
													//  getName={this.getQualityName}
													stateKey="sex"
													// isRefresh={this.state.loadQuality}
													// setIsRefresh={this.afterRefreshQuality}
													staticData={GenderType}
													///   url={serverApi.DATATABLE_SERVICE_URL + '0' + "/getQualityByItem"}
													value={this.state.sex}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Nominee
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="nomineeName"
													value={this.state.nomineeName}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Relation
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="nomineeRelationship"
													value={this.state.nomineeRelationship}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12}>
								<GridContainer>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Blood Group
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.bloodGroup}
													onSelect={this.onSelect}
													stateKey="bloodGroup"
													staticData={BloodGroups}
													value={this.state.bloodGroup}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Office Mobile
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="officeMobileNo"
													value={this.state.officeMobileNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													PAN No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="panNo"
													value={this.state.panNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Religion
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="religion"
													value={this.state.religion}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Marital status
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.maritalStatus}
													onSelect={this.onSelect}
													//  getName={this.getQualityName}
													stateKey="maritalStatus"
													// isRefresh={this.state.loadQuality}
													// setIsRefresh={this.afterRefreshQuality}
													staticData={MaritalStatus}
													// url={serverApi.DATATABLE_SERVICE_URL + '0' + "/getQualityByItem"}
													value={this.state.maritalStatus}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Facebook Id
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="facebookId"
													value={this.state.facebookId}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>State</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="state"
													value={this.state.state}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Country
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="country"
													value={this.state.country}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Nationality
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="nationality"
													value={this.state.nationality}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Passport No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="passportNo"
													value={this.state.passportNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Passport Issue Date
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<FormControl variant="" fullWidth>
													<Datetime
														closeOnSelect={true}
														// isValidDate={valid}
														timeFormat={false}
														onChange={(dateObj) => {
															this.timeChanged(dateObj, "passportIssueDate");
														}}
														height={"15px"}
														formControlProps={{
															height: "15px",
															inlineBlock: "none",
														}}
														inputProps={{
															id: "passportIssueDate",
															value: this.state.passportIssueDate,
															autoComplete: "off",
														}}
													/>
												</FormControl>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Passport Expiry Date
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<FormControl variant="" fullWidth>
													<Datetime
														closeOnSelect={true}
														// isValidDate={valid}
														timeFormat={false}
														onChange={(dateObj) => {
															this.timeChanged(dateObj, "passportExpiryDate");
														}}
														height={"15px"}
														formControlProps={{
															height: "15px",
															inlineBlock: "none",
														}}
														inputProps={{
															id: "passportExpiryDate",
															value: this.state.passportExpiryDate,
															autoComplete: "off",
														}}
													/>
												</FormControl>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Driving License No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="drivingLicenseNo"
													value={this.state.drivingLicenseNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Driving Issue Date
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<FormControl variant="" fullWidth>
													<Datetime
														closeOnSelect={true}
														// isValidDate={valid}
														timeFormat={false}
														onChange={(dateObj) => {
															this.timeChanged(dateObj, "drivingIssueDate");
														}}
														height={"15px"}
														formControlProps={{
															height: "15px",
															inlineBlock: "none",
														}}
														inputProps={{
															id: "drivingIssueDate",
															value: this.state.drivingIssueDate,
															autoComplete: "off",
														}}
													/>
												</FormControl>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Driving Expiry Date
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<FormControl variant="" fullWidth>
													<Datetime
														closeOnSelect={true}
														// isValidDate={valid}
														timeFormat={false}
														onChange={(dateObj) => {
															this.timeChanged(dateObj, "drivingExpiryDate");
														}}
														height={"15px"}
														formControlProps={{
															height: "15px",
															inlineBlock: "none",
														}}
														inputProps={{
															id: "drivingExpiryDate",
															value: this.state.drivingExpiryDate,
															autoComplete: "off",
														}}
													/>
												</FormControl>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Visa No
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<TextField
													id="visaNo"
													value={this.state.visaNo}
													className={classes.textField}
													onChange={this.onChangeValues}
													fullWidth={true}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Visa Issue Date
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<FormControl variant="" fullWidth>
													<Datetime
														closeOnSelect={true}
														// isValidDate={valid}
														timeFormat={false}
														onChange={(dateObj) => {
															this.timeChanged(dateObj, "visaIssueDate");
														}}
														height={"15px"}
														formControlProps={{
															height: "15px",
															inlineBlock: "none",
														}}
														inputProps={{
															id: "drivingIssueDate",
															value: this.state.visaIssueDate,
															autoComplete: "off",
														}}
													/>
												</FormControl>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid
								xs={12}
								style={{
									height: 2,
									backgroundColor: "#00acc1",
									marginTop: "5px",
								}}
							/>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>Bank</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="personalBankName"
											value={this.state.personalBankName}
											className={classes.textField}
											onChange={this.onChangeValues}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>IFSC</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="personalBankIFSC"
											value={this.state.personalBankIFSC}
											className={classes.textField}
											onChange={this.onChangeValues}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>A/C No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="personalBankAcNo"
											value={this.state.personalBankAcNo}
											className={classes.textField}
											onChange={this.onChangeValues}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>Branch</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="personalBankBranch"
											value={this.state.personalBankBranch}
											className={classes.textField}
											onChange={this.onChangeValues}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} style={{ marginTop: "10px" }}>
								<GridContainer justify="center">
									{this.state.updateView &&
									this.state.empStatus !== "3" &&
									this.state.updateButton === true ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													if (!getMenuPermission(1)) {
														this.basicAlert(
															"Access Denied. Please contact administrator!!!"
														);
													} else {
														if (
															this.state.workerName !== "" &&
															this.state.nomineeName !== "" &&
															this.state.nomineeRelationship !== 0 &&
															this.state.sex !== "" &&
															this.state.ebNo !== ""
														) {
															this.onCreateButtonClick("");
														} else {
															this.basicAlert(
																"Please Enter All Mandatory Fields..!!"
															);
														}
													}
												}}
											>
												update
											</Button>
										</ItemGrid>
									) : null}
									{!this.state.updateView ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													if (
														this.state.workerName !== "" &&
														this.state.nomineeName !== "" &&
														this.state.nomineeRelationship !== 0 &&
														this.state.sex !== "" &&
														this.state.ebNo !== ""
														//   (this.state.portalRoleId > 0 ||
														//     this.state.appRoleId)
													) {
														this.onCreateButtonClick("21");
													} else {
														this.basicAlert(
															"Please Enter All Mandatory Fields Of Personal Details..!!"
														);
													}
												}}
											>
												save
											</Button>
										</ItemGrid>
									) : null}
									{this.state.updateView && this.state.approverButton ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													if (
														this.state.workerName !== "" &&
														this.state.nomineeName !== "" &&
														this.state.nomineeRelationship !== 0 &&
														this.state.sex !== "" &&
														this.state.ebNo !== ""
													) {
														this.onCreateButtonClick(this.state.empStatus);
													} else {
														this.basicAlert(
															"Please Enter All Mandatory Fields..!!"
														);
													}
												}}
											>
												approve
											</Button>
										</ItemGrid>
									) : null}
									<ItemGrid>
										<Button color="danger" onClick={this.handleCreate}>
											Cancel
										</Button>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "Official",
				icon: OfficialIcon,
				isFilled: this.getIsFilledTab("Official"),

				tabContent: (
					<ItemGrid xs={12} style={{ overflow: "hidden" }}>
						<GridContainer>
							<ItemGrid
								xs={12}
								md={12}
								style={{ border: "2px solid #aeafb0", borderRadius: 10 }}
							>
								<GridContainer>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Dept
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.deptId}
													onSelect={this.onSelect}
													//  getName={this.getQualityName}
													stateKey="deptId"
													// isRefresh={this.state.loadQuality}
													// setIsRefresh={this.afterRefreshQuality}
													url={
														serverApi.SELECT_SUB_DEPARTMENT_MASTER +
														getCookie(cons.COMPANY_ID)
													}
													value={this.state.deptId}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Branch
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.branchId}
													onSelect={this.onSelect}
													//  getName={this.getQualityName}
													stateKey="branchId"
													// isRefresh={this.state.loadQuality}
													// setIsRefresh={this.afterRefreshQuality}
													url={
														serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
													}
													value={this.state.branchId}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													DOJ
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<FormControl variant="" fullWidth>
													<Datetime
														closeOnSelect={true}
														//isValidDate={valid}
														timeFormat={false}
														onChange={(dateObj) => {
															this.timeChanged(dateObj, "dateOfJoining");
														}}
														height={"15px"}
														formControlProps={{
															height: "15px",
															inlineBlock: "none",
														}}
														inputProps={{
															id: "dateOfJoining",
															value: this.state.dateOfJoining,
															autoComplete: "off",
														}}
													/>
												</FormControl>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Category
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.cataId}
													onSelect={this.onSelect}
													//  getName={this.getQualityName}
													stateKey="cataId"
													// isRefresh={this.state.loadQuality}
													// setIsRefresh={this.afterRefreshQuality}
													url={
														serverApi.SELECT_WORKER_CATEGORY +
														getCookie(cons.COMPANY_ID)
													}
													value={this.state.category}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
									<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={3}>
												<InputLabel className={classes.label}>
													Designation
													<span style={{ color: "red", fontSize: "12px" }}>
														*
													</span>
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={9}>
												<SelectboxTemp
													defValue={this.state.designationId}
													onSelect={this.onSelect}
													//  getName={this.getQualityName}
													stateKey="designationId"
													// isRefresh={this.state.loadQuality}
													// setIsRefresh={this.afterRefreshQuality}
													url={
														serverApi.SELECT_DESIGNATION +
														getCookie(cons.COMPANY_ID)
													}
													value={this.state.designationId}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>

									{this.state.isAdd ? (
										<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={3}>
													<InputLabel className={classes.label}>
														User Name
														<span style={{ color: "red", fontSize: "12px" }}>
															*
														</span>
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={9}>
													<TextField
														id="userName"
														value={this.state.userName}
														className={classes.textField}
														onChange={(event) => {
															this.onChangeValues(event, "AN");
														}}
														fullWidth={true}
														variant="outlined"
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}

									{this.state.isAdd ? (
										<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={3}>
													<InputLabel className={classes.label}>
														App Role
														<span style={{ color: "red", fontSize: "12px" }}>
															*
														</span>
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={9}>
													<SelectboxTemp
														defValue={this.state.appRoleId}
														onSelect={this.onSelect}
														//  getName={this.getQualityName}
														stateKey="appRoleId"
														// isRefresh={this.state.loadQuality}
														// setIsRefresh={this.afterRefreshQuality}
														url={
															serverApi.GET_ROLES_LIST_BY_TYPE +
															"1/" +
															getCookie(cons.COMPANY_ID) +
															"/" +
															getCookie(cons.CIPHER)
														}
														value={this.state.appRoleId}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}

									{this.state.isAdd ? (
										<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
											<GridContainer>
												<ItemGrid xs={12} sm={3}>
													<InputLabel className={classes.label}>
														Portal Role
														<span style={{ color: "red", fontSize: "12px" }}>
															*
														</span>
													</InputLabel>
												</ItemGrid>
												<ItemGrid xs={12} sm={9}>
													<SelectboxTemp
														defValue={this.state.portalRoleId}
														onSelect={this.onSelect}
														//  getName={this.getQualityName}
														stateKey="portalRoleId"
														// isRefresh={this.state.loadQuality}
														// setIsRefresh={this.afterRefreshQuality}
														url={
															serverApi.GET_ROLES_LIST_BY_TYPE +
															"0/" +
															getCookie(cons.COMPANY_ID) +
															"/" +
															getCookie(cons.CIPHER)
														}
														value={this.state.portalRoleId}
													/>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
									) : null}
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>EMP Level</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.empLevelId}
											onSelect={this.onSelect}
											//  getName={this.getQualityName}
											stateKey="empLevelId"
											// isRefresh={this.state.loadQuality}
											// setIsRefresh={this.afterRefreshQuality}
											url={
												serverApi.WORKER_LEVEL_SELECTION +
												getCookie(cons.COMPANY_ID)
											}
											value={this.state.empLevelId}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Work Location
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.workLocation}
											onSelect={this.onSelect}
											stateKey="workLocation"
											staticData={this.state.locationList}
											isRefresh={this.state.loadLocation}
											setIsRefresh={(val) => {
												this.setState({
													loadLocation: false,
												});
											}}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Official Email ID
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="officialEmailId"
											value={this.state.officialEmailId}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Reporting Manager
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.reportingToEbId}
											onSelect={this.onSelect}
											//  getName={this.getQualityName}
											stateKey="reportingToEbId"
											// isRefresh={this.state.loadQuality}
											// setIsRefresh={this.afterRefreshQuality}
											url={serverApi.GET_ALL_USERS}
											value={this.state.reportingToEbId}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>PF A/C No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="pfNo"
											value={this.state.pfNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>DO PF</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<FormControl variant="" fullWidth>
											<Datetime
												closeOnSelect={true}
												// isValidDate={valid}

												timeFormat={false}
												onChange={(dateObj) => {
													this.timeChanged(dateObj, "dateOfPfJoining");
												}}
												height={"15px"}
												formControlProps={{
													height: "15px",
													inlineBlock: "none",
												}}
												inputProps={{
													id: "dateOfPfJoining",
													value: this.state.dateOfPfJoining,
													autoComplete: "off",
												}}
											/>
										</FormControl>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>ESI No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="esiNo"
											value={this.state.esiNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											PF UAN No.
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="pfUANName"
											value={this.state.pfUANName}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							{this.state.updateView ? (
								<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
									<GridContainer>
										<ItemGrid xs={12} sm={3}>
											<InputLabel className={classes.label}>
												Emp Code
											</InputLabel>
										</ItemGrid>
										<ItemGrid xs={12} sm={9}>
											<TextField
												disabled={true}
												id="empCode"
												value={this.state.empCode}
												className={classes.textField}
												onChange={(event) => {
													this.onChangeValues(event, "AN");
												}}
												fullWidth={true}
												variant="outlined"
											/>
										</ItemGrid>
									</GridContainer>
								</ItemGrid>
							) : null}
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											User Status
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.active}
											onSelect={this.onSelect}
											//  getName={this.getQualityName}
											stateKey="active"
											// isRefresh={this.state.loadQuality}
											// setIsRefresh={this.afterRefreshQuality}
											staticData={UserStatus}
											value={this.state.active}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Contractor
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.contractorId}
											onSelect={this.onSelect}
											//  getName={this.getQualityName}
											stateKey="contractorId"
											// isRefresh={this.state.loadQuality}
											// setIsRefresh={this.afterRefreshQuality}
											url={
												serverApi.SELECT_CONTRACTOR + getCookie(cons.COMPANY_ID)
											}
											value={this.state.contractorId}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>House No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="quarterNo"
											value={this.state.quarterNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>LIC No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="licNo"
											value={this.state.licNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							{this.state.updateView ? (
								<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
									<GridContainer>
										<ItemGrid xs={12} sm={3}>
											<InputLabel className={classes.label}>
												Retired Date
											</InputLabel>
										</ItemGrid>
										<ItemGrid xs={12} sm={9}>
											<FormControl variant="" fullWidth>
												<Datetime
													closeOnSelect={true}
													// isValidDate={valid}

													timeFormat={false}
													onChange={(dateObj) => {
														this.timeChanged(dateObj, "retiredDate");
													}}
													height={"15px"}
													formControlProps={{
														height: "15px",
														inlineBlock: "none",
													}}
													inputProps={{
														id: "retiredDate",
														value: this.state.retiredDate,
														autoComplete: "off",
													}}
												/>
											</FormControl>
										</ItemGrid>
									</GridContainer>
								</ItemGrid>
							) : null}
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Contract Duration (Months)
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="contractDurationInMonths"
											value={this.state.contractDurationInMonths}
											className={classes.textField}
											onChange={this.onChangeValues}
											iType={"number"}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={4}>
										<InputLabel className={classes.label}>
											Prohibition (Months)
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={8}>
										<TextField
											id="prohibitionInMonths"
											value={this.state.prohibitionInMonths}
											className={classes.textField}
											onChange={this.onChangeValues}
											iType={"number"}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Acomadation Type
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.accommodatioType}
											onSelect={this.onSelect}
											//  getName={this.getQualityName}
											stateKey="accommodatioType"
											// isRefresh={this.state.loadQuality}
											// setIsRefresh={this.afterRefreshQuality}
											staticData={acomadationType}
											value={this.state.ledgerGroupId}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid
								xs={12}
								style={{
									height: 2,
									backgroundColor: "#00acc1",
									marginTop: "5px",
								}}
							/>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>Bank</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="officeBankName"
											value={this.state.officeBankName}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											IFSC
											<span style={{ color: "red", fontSize: "12px" }}>*</span>
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="officeBankIFSC"
											value={this.state.officeBankIFSC}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>A/C No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="officeBankAcNo"
											value={this.state.officeBankAcNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>Branch</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="officeBankBranch"
											value={this.state.officeBankBranch}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} style={{ marginTop: "5px" }}>
								<GridContainer justify="center">
									{this.state.updateView &&
									this.state.empStatus !== "3" &&
									this.state.updateButton === true ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													if (
														this.state.deptId &&
														this.state.branchId &&
														this.state.dateOfJoining &&
														this.state.cataId > 0 &&
														this.state.designationId > 0
													)
														this.onCreateButtonClick("");
													else
														this.basicAlert(
															"Please Enter All Mandatory Fields..!!"
														);
												}}
											>
												update
											</Button>
										</ItemGrid>
									) : null}
									{!this.state.updateView ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													if (
														this.state.deptId &&
														this.state.branchId &&
														this.state.dateOfJoining &&
														this.state.cataId > 0 &&
														this.state.designationId > 0 &&
														this.state.userName &&
														(this.state.portalRoleId > 0 ||
															this.state.appRoleId > 0)
													)
														this.onCreateButtonClick("");
													else
														this.basicAlert(
															"Please Enter All Mandatory Fields..!!"
														);
												}}
											>
												save
											</Button>
										</ItemGrid>
									) : null}
									{this.state.updateView && this.state.approverButton ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													if (
														this.state.deptId &&
														this.state.branchId &&
														this.state.dateOfJoining &&
														this.state.cataId > 0 &&
														this.state.designationId > 0
													)
														this.onCreateButtonClick(this.state.empStatus);
													else
														this.basicAlert(
															"Please Enter All Mandatory Fields..!!"
														);
												}}
											>
												approve
											</Button>
										</ItemGrid>
									) : null}
									<ItemGrid>
										<Button color="danger" onClick={this.handleCreate}>
											Cancel
										</Button>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				isFilled: workerEducation.length > 0,
				tabButton: "Education",
				icon: EducationIcon,
				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer>
							<ItemGrid xs={12}>
								<LineItemTable
									data={workerEducation}
									loading={this.state.loading}
									columns={[
										{
											Header: "Type",
											accessor: "eduType",
											minWidth: 60,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Year Of Passing",
											accessor: "yearOfPassing",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Institute",
											accessor: "eduInstitute",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Percentage",
											accessor: "eduPercentage",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Actions",
											accessor: "actions",
											style: { color: "#000", textAlign: "center" },
											sortable: false,
											filterable: false,
										},
									]}
								/>
							</ItemGrid>
							{this.state.updateButton === true ? (
								<ItemGrid xs={12}>
									<Button
										color={"info"}
										right={true}
										onClick={this.handleEducationItem}
									>
										Add Item
									</Button>
								</ItemGrid>
							) : null}
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				isFilled: workerExperience.length > 0,
				tabButton: "Experience",
				icon: ExperienceIcon,
				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer>
							<ItemGrid xs={12}>
								<LineItemTable
									data={this.getExpTable()}
									loading={this.state.loading}
									columns={[
										{
											Header: "company",
											accessor: "companyName",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Joined Date",
											accessor: "fromDate",
											minWidth: 50,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Releaved Date",
											accessor: "toDate",
											minWidth: 50,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Designation",
											accessor: "designation",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Project",
											accessor: "project",
											minWidth: 50,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Actions",
											accessor: "actions",
											minWidth: 150,
											style: { color: "#000", textAlign: "center" },
											sortable: false,
											filterable: false,
										},
									]}
								/>
							</ItemGrid>
							{this.state.updateButton === true ? (
								<ItemGrid xs={12}>
									<Button
										color={"info"}
										right={true}
										onClick={() => {
											this.setState({ showExpPopup: true });
										}}
									>
										Add Item
									</Button>
								</ItemGrid>
							) : null}
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "Documentation",
				icon: DocumentationIcon,
				isFilled: workerDocuments.length > 0,

				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer>
							<ItemGrid xs={12}>
								<LineItemTable
									data={this.getDocTable()}
									loading={this.state.loading}
									disableActions={!this.state.updateButton}
									columns={[
										{
											Header: "Document Code",
											accessor: "docId",
											minWidth: 60,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Document Name",
											accessor: "docName",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},

										{
											Header: "Attachment",
											accessor: "actions",
											style: { color: "#000", textAlign: "center" },
											sortable: false,
											filterable: false,
										},
									]}
								/>
							</ItemGrid>
							{this.state.updateButton === true ? (
								<ItemGrid xs={12}>
									<Button
										color={"info"}
										right={true}
										onClick={() => {
											this.setState({ showDocPopup: true });
										}}
									>
										Add
									</Button>

									<Button color="primary" onClick={this.generatePdfHandler}>
										download Offer Letter
									</Button>
								</ItemGrid>
							) : null}
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "Record Verification",
				icon: CriminalIcon,
				isFilled: false,

				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer justify="center">
							{criminalQuestions.map((prop, i) => {
								return (
									<ItemGrid xs={12}>
										<GridContainer>
											<ItemGrid xs={12} sm={8} style={{ marginTop: "10px" }}>
												<InputLabel className={classes.label}>
													{prop.questionType}
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={4}>
												<FormControlLabel
													control={
														<Radio
															checked={this.getCriminalQuestionAswer(
																prop.questionId,
																"Y"
															)}
															onChange={(event) => {
																var list = this.state.criminalRecordList;
																var recordFound = false;
																if (list.length > 0) {
																	list.find((o, i) => {
																		if (o.questionId === prop.questionId) {
																			list[i].answer = "Yes";
																			recordFound = true;
																			this.setState({
																				criminalRecordList: list,
																			});
																			return true;
																		}
																	});
																}

																if (!recordFound) {
																	list.push({
																		autoId: null,
																		questionType: prop.questionType,
																		questionId: prop.questionId,
																		answer: "Yes",
																		empId: this.state.ebId,
																		companyId: getCookie(cons.COMPANY_ID),
																		createdBy: getCookie(cons.USERID),
																		createdDate: moment().format("DD-MM-YYYY"),
																		isActive: 1,
																	});
																	this.setState({ criminalRecordList: list });
																}
															}}
															value={2}
															name="radio button demo"
															aria-label="A"
															icon={
																<FiberManualRecord
																	className={classes.radioUnchecked}
																/>
															}
															checkedIcon={
																<FiberManualRecord
																	className={classes.radioChecked}
																/>
															}
															classes={{
																checked: classes.radio,
															}}
														/>
													}
													label={"Yes"}
												/>

												<FormControlLabel
													control={
														<Radio
															checked={this.getCriminalQuestionAswer(
																prop.questionId,
																"N"
															)}
															onChange={(event) => {
																var list = this.state.criminalRecordList;
																var recordFound = false;
																if (list.length > 0) {
																	list.find((o, i) => {
																		if (o.questionId === prop.questionId) {
																			list[i].answer = "No";
																			recordFound = true;
																			this.setState({
																				criminalRecordList: list,
																			});
																			return true;
																		}
																	});
																}

																if (!recordFound) {
																	list.push({
																		autoId: null,
																		questionType: prop.questionType,
																		questionId: prop.questionId,
																		answer: "No",
																		empId: this.state.ebId,
																		companyId: getCookie(cons.COMPANY_ID),
																		createdBy: getCookie(cons.USERID),
																		createdDate: moment().format("DD-MM-YYYY"),
																		isActive: 1,
																	});
																	this.setState({ criminalRecordList: list });
																}
															}}
															value={1}
															name="radio button demo"
															aria-label="A"
															icon={
																<FiberManualRecord
																	className={classes.radioUnchecked}
																/>
															}
															checkedIcon={
																<FiberManualRecord
																	className={classes.radioChecked}
																/>
															}
															classes={{
																checked: classes.radio,
															}}
														/>
													}
													label={"No"}
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid>
								);
							})}
							{this.state.updateView ? (
								<ItemGrid>
									<Button
										color="primary"
										onClick={() => {
											var list = this.state.criminalRecordList;
											requestList(
												serverApi.ADD_VERIFICATION_ANSWAERS,
												list,
												this.handleAddCriminalRecords
											);
										}}
									>
										save
									</Button>
								</ItemGrid>
							) : null}
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "Salary",
				icon: SalaryIcon,
				isFilled: this.state.empPayschemeId !== "",

				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer style={{ alignContent: "center" }}>
							<ItemGrid xs={12} md={6}>
								<GridContainer>
									<ItemGrid xs={12} sm={4}>
										<InputLabel className={classes.label}>
											Pay Scheme Name
											<span style={{ color: "red", fontSize: "12px" }}>*</span>
										</InputLabel>
									</ItemGrid>

									<ItemGrid xs={12} sm={8}>
										<SelectboxTemp
											defValue={this.state.paySchemeId}
											id={"paySchemeId"}
											onSelect={this.onSelect}
											//getName={this.getQualityDetails}
											isRefresh={this.state.loadPayscheme}
											setIsRefresh={(val) => {
												this.setState({ loadPayscheme: false });
											}}
											stateKey="paySchemeId"
											staticData={this.state.paySchemeList}
											//url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}
											//value={this.state.selectedJuteQuality}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} sm={6}>
								<GridContainer>
									<ItemGrid xs={12} sm={4}>
										<InputLabel className={classes.label}>
											Effective Date
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={8}>
										<FormControl variant="outlined" fullWidth>
											<Datetime
												closeOnSelect={true}
												timeFormat={false}
												onChange={(dateObj) => {
													this.timeChanged(dateObj, "effectiveFrom");
												}}
												formControlProps={{
													height: "10px",
													inlineBlock: "none",
												}}
												inputProps={{
													id: "effectiveFrom",
													value: this.state.effectiveFrom,
													autoComplete: "off",
												}}
											/>
										</FormControl>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							{this.state.paySchemeId !== "" &&
							this.state.paySchemeId !== null ? (
								<ItemGrid xs={12}>
									<GridContainer>
										<ItemGrid
											xs={12}
											style={{
												marginTop: "10px",
												height: 2,
												backgroundColor: "#00acc1",
											}}
										/>
										{this.state.paySchemeListNoCalData.length > 0 ? (
											<ItemGrid xs={12} style={{ marginTop: "10px" }}>
												<GridContainer>
													<ItemGrid xs={12}>
														<InputLabel className={classes.label}>
															Input Values
														</InputLabel>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										) : null}
										<ItemGrid xs={12}>
											<GridContainer>
												{this.state.paySchemeListNoCalData.map((value, key) => {
													//console.log(value,key)
													return (
														<ItemGrid
															xs={12}
															sm={6}
															style={{ marginTop: "10px" }}
														>
															<GridContainer>
																<ItemGrid xs={12} sm={4}>
																	<InputLabel className={classes.label}>
																		{value.componentvalue}
																		<span
																			style={{
																				color: "red",
																				fontSize: "12px",
																			}}
																		>
																			*
																		</span>
																	</InputLabel>
																</ItemGrid>
																<ItemGrid xs={12} sm={8}>
																	<TextField
																		id={key}
																		//defaultValue={value.defaultValue}
																		value={value.formula}
																		className={classes.textField}
																		onChange={(event) => {
																			var data = this.state
																				.paySchemeListNoCalData;
																			data[key].formula = event.target.value;
																			data[key].value = event.target.value;
																			this.setState({
																				paySchemeListNoCalData: data,
																			});
																		}}
																		fullWidth={true}
																		variant="outlined"
																		// getName={this.getDeptName}
																		iType={"number"}
																	/>
																</ItemGrid>
															</GridContainer>
														</ItemGrid>
													);
												})}
											</GridContainer>
										</ItemGrid>
										{this.state.paySchemeListNoCalData.length > 0 ? (
											<ItemGrid xs={12}>
												<Button
													color="primary"
													right={true}
													style={{ width: 130 }}
													onClick={this.onClaculationButtonClick}
												>
													Check Calculation
												</Button>
											</ItemGrid>
										) : null}
										<ItemGrid
											xs={12}
											style={{
												height: 2,
												backgroundColor: "#00acc1",
												marginTop: "5px",
											}}
										/>
										<ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
											<InputLabel className={classes.label}>
												Earnings :
											</InputLabel>
										</ItemGrid>
										<ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
											<InputLabel className={classes.label}>
												Deductions :
											</InputLabel>
										</ItemGrid>
										<ItemGrid xs={6} style={{ margin: 0 }}>
											<LineItemTable
												data={this.state.paySchemeListEarningsData}
												loading={this.state.loading}
												tableSize={350}
												columns={[
													{
														Header: "Component",
														accessor: "componentvalue",
														minWidth: 100,
														style: { color: "#000", textAlign: "left" },
													},
													{
														Header: "Formula",
														accessor: "formula",
														minWidth: 100,
														style: { color: "#000", textAlign: "left" },
													},

													{
														Header: "Value",
														accessor: "value",
														minWidth: 50,
														style: { color: "#000", textAlign: "left" },
													},
												]}
											/>
										</ItemGrid>
										<ItemGrid xs={6}>
											<LineItemTable
												data={this.state.paySchemeListDeductionData}
												loading={this.state.loading}
												tableSize={350}
												columns={[
													{
														Header: "Component",
														accessor: "componentvalue",
														minWidth: 100,
														style: { color: "#000", textAlign: "left" },
													},
													{
														Header: "Formula",
														accessor: "formula",
														minWidth: 100,
														style: { color: "#000", textAlign: "left" },
													},

													{
														Header: "Value",
														accessor: "value",
														minWidth: 50,
														style: { color: "#000", textAlign: "left" },
													},
												]}
											/>
										</ItemGrid>
										<ItemGrid xs={12}>
											<InputLabel className={classes.label}>
												Summary:
											</InputLabel>
										</ItemGrid>

										{this.state.paySchemeListSummaryData.map((value, key) => {
											//console.log(value,key)
											return (
												<ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																{value.componentvalue}
																<span
																	style={{ color: "red", fontSize: "12px" }}
																>
																	*
																</span>
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<TextField
																disabled={true}
																id={key}
																//defaultValue={value.defaultValue}
																value={value.value}
																className={classes.textField}
																onChange={(event) => {
																	var data = this.state
																		.paySchemeListSummaryData;
																	data[key].formula = event.target.value;
																	this.setState({
																		paySchemeListSummaryData: data,
																	});
																}}
																fullWidth={true}
																variant="outlined"
																// getName={this.getDeptName}
																iType={"number"}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
											);
										})}
									</GridContainer>
								</ItemGrid>
							) : null}

							<ItemGrid xs={12} style={{ marginTop: "5px" }}>
								<GridContainer justify="center">
									{this.state.updateView &&
									this.state.updateButton === true &&
									this.state.empPayschemeId !== "" ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={this.onClickUpdateEmpPaySchemeMapping}
											>
												Update
											</Button>
										</ItemGrid>
									) : null}
									{this.state.empPayschemeId === "" ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={this.onClickEmpPaySchemeMapping}
											>
												Save
											</Button>
										</ItemGrid>
									) : null}

									<ItemGrid>
										<Button color="danger" onClick={this.handleCreate}>
											Cancel
										</Button>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "General Letters",
				icon: LetterIcon,

				tabContent: (
					<ItemGrid xs={12}>
						<LineItemTable
							data={letterData}
							//loading={this.state.loading}
							//  disableActions={!this.state.updateButton}
							columns={[
								{
									Header: "S.No",
									accessor: "sno",
									minWidth: 60,
									style: { color: "#000", textAlign: "left" },
								},
								{
									Header: "Letter Type",
									accessor: "letter",
									minWidth: 100,
									style: { color: "#000", textAlign: "left" },
								},

								{
									Header: "Actions",
									accessor: "actions",
									style: { color: "#000", textAlign: "center" },
									sortable: false,
									filterable: false,
								},
							]}
						/>
					</ItemGrid>
				),
			},

			{
				tabButton: "Shift",
				icon: ShiftIcon,
				isFilled:
					employeeShiftMapList.length > 0 || bioMetricEmpMapList.length > 0,

				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer style={{ alignContent: "center" }}>
							<ItemGrid xs={12}>
								<ReactTable
									data={this.state.bioMetricEmpMapList}
									filterable={false}
									resizable={false}
									loading={this.state.loading}
									getTableProps={() => {
										return {
											style: {
												border: "2px solid #00ABDC",
												borderRadius: "4px",
											},
										};
									}}
									getTheadProps={() => {
										return {
											style: {
												borderBottom: "2px solid #00ABDC",
												//height:45
												//lineHeight:"1.3"
											},
										};
									}}
									getTheadThProps={() => {
										return {
											style: {
												borderRight: "1px solid #999999",
												textAlign: "center",
												whiteSpace: "pre-wrap",
												lineHeight: 1.3,
											},
										};
									}}
									style={{
										color: "#00ABDC",
										textAlign: "center",
										overflowY: "auto",
									}}
									columns={[
										{
											Header: "Bio Metric Device",
											accessor: "bioDeviceName",
											//  minWidth:60,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "RF ID Card",
											accessor: "empRFNo",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Emp BioMetric Id",
											accessor: "empBioDeviceId",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Status",
											accessor: "activeStatus",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Actions",
											accessor: "actions",
											style: { color: "#000", textAlign: "center" },
											sortable: false,
											filterable: false,
										},
									]}
									showPagination={false}
									pageSize={this.getInvoiceTablePageSize()}
									//  defaultPageSize={5}
									sortable={false}
									getNoDataProps={() => {
										return {
											style: {
												display: "none",
											},
										};
									}}
									className="-striped -highlight"
								/>
							</ItemGrid>
							{this.state.updateButton ? (
								<ItemGrid xs={12}>
									<Button
										color={"info"}
										right={true}
										onClick={() => {
											this.setState({ showBioPopup: true });
										}}
									>
										Add
									</Button>
								</ItemGrid>
							) : null}

							<ItemGrid xs={12} style={{ marginTop: 10 }}>
								<ReactTable
									data={this.state.employeeShiftMapList}
									filterable={false}
									resizable={false}
									loading={this.state.loading}
									getTableProps={() => {
										return {
											style: {
												border: "2px solid #00ABDC",
												borderRadius: "4px",
											},
										};
									}}
									getTheadProps={() => {
										return {
											style: {
												borderBottom: "2px solid #00ABDC",
												//height:45
												lineHeight: "1.5",
											},
										};
									}}
									getTheadThProps={() => {
										return {
											style: {
												borderRight: "1px solid #999999",
												textAlign: "center",
												whiteSpace: "pre-wrap",
												lineHeight: "2",
											},
										};
									}}
									style={{
										color: "#00ABDC",
										textAlign: "center",
										overflowY: "auto",
									}}
									columns={[
										{
											Header: "Late Minutes1",
											accessor: "lateMins1",
											//  minWidth:60,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Late Minutes2",
											accessor: "lateMins2",
											//  minWidth:60,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Roaster Days",
											accessor: "roasterDays",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Roaster Gap Days",
											accessor: "roasterGapDays",
											minWidth: 100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Roaster Date",
											accessor: "roasterStartDate",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Spell1",
											accessor: "spell1",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Spell2",
											accessor: "spell2",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Week Off Day",
											accessor: "weekOffDay",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Week Off Day2",
											accessor: "weekOffDay2",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Week Off Half Day ",
											accessor: "weekOffHalfDay",
											//  minWidth:100,
											style: { color: "#000", textAlign: "left" },
										},
										{
											Header: "Actions",
											accessor: "actions",
											style: { color: "#000", textAlign: "center" },
											sortable: false,
											filterable: false,
										},
									]}
									showPagination={false}
									pageSize={this.getInvoiceTablePageSize()}
									//  defaultPageSize={5}
									sortable={false}
									getNoDataProps={() => {
										return {
											style: {
												display: "none",
											},
										};
									}}
									className="-striped -highlight"
								/>
							</ItemGrid>

							{this.state.updateButton ? (
								<ItemGrid xs={12}>
									<Button
										color={"info"}
										right={true}
										onClick={() => {
											this.setState({ showShiftPopup: true });
										}}
									>
										Add
									</Button>
								</ItemGrid>
							) : null}
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "Leave Policy Mapping",
				icon: LeaveIcon,
				isFilled: leavePolicieList.length > 0,

				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer style={{ alignContent: "center" }}>
							<ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											Leave Policy
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<SelectboxTemp
											defValue={this.state.leavePolicyId}
											isRefresh={this.state.reloadLeave}
											setIsRefresh={(val) => {
												this.setState({ reloadLeave: false });
											}}
											onSelect={this.onSelect}
											stateKey="leavePolicyId"
											staticData={this.state.leavePolicyList}
											value={this.state.leavePolicyId}
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							{this.state.leavePolicyId > 0 ? (
								<ItemGrid xs={12}>
									<LineItemTable
										data={this.state.leavePolicieList}
										loading={this.state.loading}
										columns={[
											{
												Header: "Leave Type",
												accessor: "leaveTypeName",
												minWidth: 160,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Carry forward Status",
												accessor: "carryFrwStatusName",
												minWidth: 100,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Encashment Status",
												accessor: "encashmentStatusName",
												minWidth: 100,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Leaves Per year",
												accessor: "leavesPerYear",
												minWidth: 100,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Max Consecutive Count",
												accessor: "maxConsecutiveCount",
												width: 100,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Max Leaves Per Year.",
												accessor: "maxLeavesPerYear",
												minWidth: 100,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Year Start date",
												accessor: "yearStartDate",
												minWidth: 100,
												style: { color: "#000", textAlign: "left" },
											},
											{
												Header: "Year End date",
												accessor: "yearEndDate",
												minWidth: 100,
												style: { color: "#000", textAlign: "left" },
											},
										]}
									/>
								</ItemGrid>
							) : null}

							{this.state.updateButton ? (
								<ItemGrid xs={7}>
									<Button
										color={"danger"}
										right={true}
										onClick={() => {
											if (this.state.leavePolicyId !== "") {
												if (this.state.mappingId !== "") {
													requestList(
														serverApi.LEAVE_POLICY_MAPPING_DELETE +
															this.state.mappingId,
														this.handleLeavePolicyMapping
													);
												}
											} else {
												this.basicAlert("Please Select Leave Policy..!!");
											}
										}}
									>
										Delete
									</Button>

									<Button
										color={"info"}
										right={true}
										onClick={() => {
											if (this.state.leavePolicyId !== "") {
												if (this.state.mappingId === "") {
													var data = {
														companyId: getCookie(cons.COMPANY_ID),
														createdBy: getCookie(cons.USERID),
														ebId: this.state.ebId,
														leavePolicyId: this.state.leavePolicyId,
													};
													requestList(
														serverApi.LEAVE_POLICY_MAPPING_ADD,
														data,
														this.handleLeavePolicyMapping
													);
												} else {
													var data = {
														companyId: getCookie(cons.COMPANY_ID),
														updatedBy: getCookie(cons.USERID),
														ebId: this.state.ebId,
														leavePolicyId: this.state.leavePolicyId,
														mappingId: this.state.mappingId,
													};
													requestList(
														serverApi.LEAVE_POLICY_MAPPING_UPDATE,
														data,
														this.handleLeavePolicyMapping
													);
												}
											} else {
												this.basicAlert("Please Select Leave Policy..!!");
											}
										}}
									>
										{this.state.mappingId !== "" ? "Update" : "Add"}
									</Button>
								</ItemGrid>
							) : null}
						</GridContainer>
					</ItemGrid>
				),
			},
			{
				tabButton: "Enrollment,ESI,PF",
				icon: EnrollmentIcon,

				tabContent: (
					<ItemGrid xs={12}>
						<GridContainer>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>
											PF UAN No.
										</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="pfUANName"
											value={this.state.pfUANName}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>PF A/C No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="pfNo"
											value={this.state.pfNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>DO PF</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<FormControl variant="" fullWidth>
											<Datetime
												closeOnSelect={true}
												//isValidDate={valid}

												timeFormat={false}
												onChange={(dateObj) => {
													this.timeChanged(dateObj, "dateOfPfJoining");
												}}
												height={"15px"}
												formControlProps={{
													height: "15px",
													inlineBlock: "none",
												}}
												inputProps={{
													id: "dateOfPfJoining",
													value: this.state.dateOfPfJoining,
													autoComplete: "off",
												}}
											/>
										</FormControl>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>

							<ItemGrid xs={12} md={3} style={{ marginTop: "10px" }}>
								<GridContainer>
									<ItemGrid xs={12} sm={3}>
										<InputLabel className={classes.label}>ESI No</InputLabel>
									</ItemGrid>
									<ItemGrid xs={12} sm={9}>
										<TextField
											id="esiNo"
											value={this.state.esiNo}
											className={classes.textField}
											onChange={(event) => {
												this.onChangeValues(event, "AN");
											}}
											fullWidth={true}
											variant="outlined"
										/>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
							<ItemGrid xs={12} style={{ marginTop: "5px" }}>
								<GridContainer justify="center">
									{this.state.updateView &&
									this.state.empStatus !== "3" &&
									this.state.updateButton === true ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													this.onCreateButtonClick("");
												}}
											>
												update
											</Button>
										</ItemGrid>
									) : null}
									{!this.state.updateView ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													this.onCreateButtonClick("");
												}}
											>
												save
											</Button>
										</ItemGrid>
									) : null}
									{this.state.updateView && this.state.approverButton ? (
										<ItemGrid>
											<Button
												color="primary"
												onClick={() => {
													this.onCreateButtonClick(this.state.empStatus);
												}}
											>
												approve
											</Button>
										</ItemGrid>
									) : null}
									<ItemGrid>
										<Button color="danger" onClick={this.handleCreate}>
											Cancel
										</Button>
									</ItemGrid>
								</GridContainer>
							</ItemGrid>
						</GridContainer>
					</ItemGrid>
				),
			},
			/*
                                                    {
                                                        tabButton: "Resign Details",
                                                        tabContent: (

                                                            <ItemGrid xs={12} style={{overflow:'hidden'}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12}

                                                                              style={{/!*height: 30, backgroundColor: "#85d7ee63",*!/marginTop: "5px", backgroundColor: "#00abdc30"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12}>
                                                                                <span style={{fontSize:"16px",fontWeight:"400",borderBottom:'1px solid #000000'}}> Resign Details</span>

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="type"
                                                                                    value={this.state.type}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}

                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",textAlign:"left",width:120,fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Type Of Resign</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                            <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="dateOfRelease"
                                                                                    value={this.state.dateOfRelease}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}

                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",textAlign:"left",width:120,fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Release Date</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                            <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="releaseDate"
                                                                                    value={this.state.releaseDate}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}

                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",textAlign:"left",width:120,fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Release Date</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="dateOfSettlement"
                                                                                    value={this.state.dateOfSettlement}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}

                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Date Of full&final Settlement</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={12} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="reason"
                                                                                    value={this.state.reason}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={60}
                                                                                    multiline={true}
                                                                                    rowsMax={3}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",width:130,fontSize:"16px",textAlign:"left",height:60,marginTop:10,paddingTop:20,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Reason Of Resign</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={12} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="remarks"
                                                                                    value={this.state.remarks}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={60}
                                                                                    multiline={true}
                                                                                    rowsMax={3}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",textAlign:"left",width:130,fontSize:"16px",height:60,marginTop:10,paddingTop:20,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Remarks</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12}>
                                                                                <span style={{fontSize:"16px",fontWeight:"400",borderBottom:'1px solid #000000'}}> Amount Settlement</span>

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="salary"
                                                                                    value={this.state.salary}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Salary</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="leaveEncashment"
                                                                                    value={this.state.leaveEncashment}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Leave Encashment</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="medicalBonus"
                                                                                    value={this.state.medicalBonus}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Medical Bonus</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="bonus"
                                                                                    value={this.state.bonus}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Bonus</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="advanceAdjust"
                                                                                    value={this.state.advanceAdjust}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Advance Adjustment</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="other"
                                                                                    value={this.state.other}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Other Deductions</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                            <ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>
                                                                                <TextField
                                                                                    //  disabled={true}
                                                                                    id="netAmount"
                                                                                    value={this.state.netAmount}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    backgroundColor={'#ffffff'}
                                                                                    height={40}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" variant="filled" >
                                                                                            <span style={{color:"#00ADBC",marginTop:10,textAlign:"left",width:120,fontSize:"16px",height:40,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Net PayableAmount</span>
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                                                        <GridContainer justify="center">
                                                                            <ItemGrid>
                                                                                <Button color="primary" onClick={this.onCreateButtonClick1}>Approve</Button>
                                                                            </ItemGrid>
                                                                            <ItemGrid>
                                                                                <Button color="danger" onClick={this.handleCreate1}>Reject</Button>
                                                                            </ItemGrid>

                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>

                                                        )
                                                    }
            */
		];

		return (
			<GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
				{this.state.isCreated ? (
					<ItemGrid xs={12}>
						{this.state.showMessagealert}
						{this.state.basicAlert}

						{this.state.showDocPopup ? (
							<Popup
								onClickSave={() => this.onClickAddDocument()}
								closePopup={() => this.hideAlert()}
								title="Post Temperature"
								isShowSave={true}
								isShowCancel={true}
								save_btn_txt={"Add"}
								cancel_button_text={"Cancel"}
								content={
									<form>
										<GridContainer>
											<ItemGrid xs={12} md={12}>
												<GridContainer>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Document Name
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="docName"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.docName}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={12}>
														<InputLabel style={{ color: "#000" }}>
															{this.state.docFileName}
														</InputLabel>

														<Button
															color={"info"}
															right={true}
															onClick={this.handleFileClick}
														>
															Upload File
														</Button>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</form>
								}
							/>
						) : null}

						{this.state.showEduPopup ? (
							<Popup
								onClickSave={() => this.onClickAddEducation()}
								closePopup={() => this.hideAlert()}
								title="Post Temperature"
								isShowSave={true}
								isShowCancel={true}
								save_btn_txt={"Add"}
								cancel_button_text={"Cancel"}
								content={
									<form>
										<GridContainer>
											<ItemGrid xs={12} md={12}>
												<GridContainer>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Type
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<SelectboxTemp
																	defValue={this.state.eduType}
																	onSelect={this.onSelect}
																	stateKey="eduType"
																	staticData={EducationTypes}
																	value={this.state.eduType}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Year Of Passing
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="yearOfPassing"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.yearOfPassing}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Institute
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="eduInstitute"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.eduInstitute}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Percentage
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="eduPercentage"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.eduPercentage}
																	fullWidth={true}
																	InputProps={{
																		autoComplete: "off",
																		endAdornment: (
																			<InputAdornment position="end">
																				<span
																					style={{
																						color: "#00ABDC",
																						fontSize: "16px",
																						fontWeight: "400",
																						marginTop: 15,
																					}}
																				>
																					%
																				</span>
																			</InputAdornment>
																		),
																	}}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={12}>
														<InputLabel style={{ color: "#000" }}>
															{this.state.docFileName}
														</InputLabel>

														<Button
															color={"info"}
															right={true}
															onClick={this.handleFileClick}
														>
															Upload File
														</Button>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</form>
								}
							/>
						) : null}
						{this.state.showExpPopup ? (
							<Popup
								onClickSave={() => this.onClickAddExperience()}
								closePopup={() => this.hideAlert()}
								title="Post Temperature"
								isShowSave={true}
								isShowCancel={true}
								save_btn_txt={"Add"}
								cancel_button_text={"Cancel"}
								content={
									<form>
										<GridContainer>
											<ItemGrid xs={12} md={12}>
												<GridContainer>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Company
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="companyName"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.companyName}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Joined Date
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<FormControl variant="" fullWidth>
																	<Datetime
																		closeOnSelect={true}
																		// isValidDate={valid}
																		timeFormat={false}
																		onChange={(dateObj) => {
																			this.timeChanged(dateObj, "joinedDate");
																		}}
																		height={"15px"}
																		formControlProps={{
																			height: "15px",
																			inlineBlock: "none",
																		}}
																		inputProps={{
																			id: "joinedDate",
																			value: this.state.joinedDate,
																			autoComplete: "off",
																		}}
																	/>
																</FormControl>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Releaved Date
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<FormControl variant="" fullWidth>
																	<Datetime
																		closeOnSelect={true}
																		// isValidDate={valid}
																		timeFormat={false}
																		onChange={(dateObj) => {
																			this.timeChanged(dateObj, "releavedDate");
																		}}
																		height={"15px"}
																		formControlProps={{
																			height: "15px",
																			inlineBlock: "none",
																		}}
																		inputProps={{
																			id: "releavedDate",
																			value: this.state.releavedDate,
																			autoComplete: "off",
																		}}
																	/>
																</FormControl>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Designation
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="role"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.eduInstitute}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Project
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="project"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.project}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Contacts
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="contact"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.contact}
																	fullWidth={true}
																	InputProps={{
																		autoComplete: "off",
																	}}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={12}>
														<InputLabel style={{ color: "#000" }}>
															{this.state.docFileName}
														</InputLabel>

														<Button
															color={"info"}
															right={true}
															onClick={this.handleFileClick}
														>
															Upload File
														</Button>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</form>
								}
							/>
						) : null}
						{this.state.showShiftPopup ? (
							<Popup
								onClickSave={() => this.onClickAddShiftMapping()}
								closePopup={() => this.hideAlert()}
								// title="Post Temperature"
								isShowSave={true}
								isShowCancel={true}
								save_btn_txt={"Add"}
								cancel_button_text={"Cancel"}
								content={
									<form>
										<GridContainer>
											<ItemGrid xs={12} md={12}>
												<GridContainer>
													<ItemGrid xs={4} sm={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Late Mins1
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<TextField
																	id="lateMins1"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.lateMins1}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={4} sm={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Late Mins2
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<TextField
																	id="lateMins2"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.lateMins2}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid xs={4} sm={4}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Roaster Days
																	<span
																		style={{ color: "red", fontSize: "12px" }}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<TextField
																	id="roasterDays"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.roasterDays}
																	fullWidth={true}
																	autoFocus={false}
																	iType="number"
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={4} sm={4}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Roaster Gap Days
																	<span
																		style={{ color: "red", fontSize: "12px" }}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<TextField
																	id="roasterGapDays"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.roasterGapDays}
																	iType="number"
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={4} sm={4}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Roaster Start Date
																	<span
																		style={{ color: "red", fontSize: "12px" }}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<FormControl variant="" fullWidth>
																	<Datetime
																		closeOnSelect={true}
																		timeFormat={false}
																		//  isValidDate={valid}

																		// onChange={(dateObj) => {
																		//     this.timeChanged(dateObj, "PoDate")
																		// }}
																		onChange={(dateObj) => {
																			this.timeChanged(
																				dateObj,
																				"roasterStartDate"
																			);
																		}}
																		height={"15px"}
																		formControlProps={{
																			height: "15px",
																			inlineBlock: "none",
																		}}
																		inputProps={{
																			id: "roasterStartDate",
																			value: this.state.roasterStartDate,
																			autoComplete: "off",
																		}}
																	/>
																</FormControl>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Spell1
																	<span
																		style={{ color: "red", fontSize: "12px" }}
																	>
																		*
																	</span>
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<SelectboxTemp
																	defValue={this.state.spell1}
																	id={"spell1"}
																	getName={this.getQualityDetails}
																	onSelect={this.onSelect}
																	isReload={this.state.reloadSelect}
																	setIsReload={this.afterReloadSelect}
																	stateKey="spell1"
																	url={
																		serverApi.GET_ALL_SPELL_LIST +
																		getCookie(cons.COMPANY_ID) +
																		"/" +
																		getCookie(cons.CIPHER)
																	}
																	value={this.state.spell1}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Spell2
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<SelectboxTemp
																	defValue={this.state.spell2}
																	id={"spell2"}
																	getName={this.getQualityDetails}
																	onSelect={this.onSelect}
																	isReload={this.state.reloadSelect}
																	setIsReload={this.afterReloadSelect}
																	stateKey="spell2"
																	url={
																		serverApi.GET_ALL_SPELL_LIST +
																		getCookie(cons.COMPANY_ID) +
																		"/" +
																		getCookie(cons.CIPHER)
																	}
																	value={this.state.spell1}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>

													<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Week Off Day
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<SelectboxTemp
																	defValue={this.state.weekOffDay}
																	id={"weekOffDay"}
																	onSelect={this.onSelect}
																	stateKey="weekOffDay"
																	staticData={Weeks}
																	// url={serverApi.GET_ALL_SPELL_LIST+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
																	value={this.state.weekOffDay}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Week Off Day2
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<SelectboxTemp
																	defValue={this.state.weekOffDay2}
																	id={"weekOffDay2"}
																	onSelect={this.onSelect}
																	stateKey="weekOffDay2"
																	staticData={Weeks}
																	// url={serverApi.GET_ALL_SPELL_LIST+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
																	value={this.state.weekOffDay2}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={3}>
																<InputLabel className={classes.label}>
																	Week Off Half Day
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={9}>
																<SelectboxTemp
																	defValue={this.state.weekOffHalfDay}
																	id={"weekOffHalfDay"}
																	onSelect={this.onSelect}
																	stateKey="weekOffHalfDay"
																	staticData={Weeks}
																	// url={serverApi.GET_ALL_SPELL_LIST+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
																	value={this.state.weekOffHalfDay}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</form>
								}
							/>
						) : null}

						{this.state.showBioPopup ? (
							<Popup
								onClickSave={() => this.onClickAddBioMetric()}
								closePopup={() => this.hideAlert()}
								// title="Post Temperature"
								isShowSave={true}
								isShowCancel={true}
								save_btn_txt={"Add"}
								cancel_button_text={"Cancel"}
								content={
									<form>
										<GridContainer>
											<ItemGrid xs={12} md={12}>
												<GridContainer>
													<ItemGrid
														xs={12}
														md={6}
														style={{ marginTop: "10px" }}
													>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Bio Metric Device
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<SelectboxTemp
																	defValue={this.state.bioDeviceId}
																	onSelect={this.onSelect}
																	getName={(name) => {
																		this.setState({ bioDeviceName: name });
																	}}
																	stateKey="bioDeviceId"
																	// isRefresh={this.state.loadQuality}
																	// setIsRefresh={this.afterRefreshQuality}
																	url={serverApi.SELECT_WORKER_BIO_METRIC}
																	value={this.state.bioDeviceId}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6} style={{ marginTop: "10px" }}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	RF ID Card
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="empRFNo"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.empRFNo}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
													<ItemGrid xs={6} sm={6}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Emp Bio Metric Id
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<TextField
																	id="empBioDeviceId"
																	className={classes.textField}
																	onChange={this.onChangeValues}
																	value={this.state.empBioDeviceId}
																	fullWidth={true}
																	autoFocus={false}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
												</GridContainer>
											</ItemGrid>
										</GridContainer>
									</form>
								}
							/>
						) : null}

						<RegularCard
							isLoading={this.state.isLoading}
							cardTitle={
								isTabOpen ? (
									<span>
										<Button
											color={"info"}
											onClick={() => {
												this.setState({ isTabOpen: false, tabPosition: "" });
											}}
										>
											Back To Menus
										</Button>
										{Tabs[tabPosition].tabButton}{" "}
									</span>
								) : (
									<Button color={"info"} onClick={this.handleCreate}>
										Back To List
									</Button>
								)
							}
							content={
								<GridContainer justify="center">
									{!this.state.isTabOpen ? (
										<ItemGrid xs={10}>
											<GridContainer>
												{Tabs.map((prop, i) => {
													return (
														<ItemGrid
															xs={4}
															justify="center"
															style={{
																marginTop: "10px",
																textAlign: "center",
																cursor: "pointer",
															}}
															onClick={() => {
																// if (i > 0 && Tabs[i - 1].isFilled) {
																//   if (i < 5)
																//     this.setState({
																//       isTabOpen: true,
																//       tabPosition: i,
																//     });
																//   else {
																//     if (this.state.empStatus == 3) {
																//       this.setState({
																//         isTabOpen: true,
																//         tabPosition: i,
																//       });
																//     }
																//   }
																// } else if (i === 0)
																this.setState({
																	isTabOpen: true,
																	tabPosition: i,
																});
															}}
														>
															{prop.isFilled ? (
																<Tick
																	fontSize="small"
																	style={{ color: liteGreen }}
																/>
															) : null}
															<img
																src={prop.icon}
																width={70}
																height={70}
																// style={{
																//   marginLeft: prop.isFilled ? null : "10px",
																//   filter:
																//     i > 0 && Tabs[i - 1].isFilled
																//       ? null
																//       : i > 0
																//       ? "grayscale(100%)"
																//       : null,
																// }}
															/>
															<br />

															<InputLabel
																className={classes.label}
																style={{
																	marginLeft: prop.isFilled ? null : "10px",
																}}
															>
																{prop.tabButton}
															</InputLabel>
														</ItemGrid>
													);
												})}
											</GridContainer>
										</ItemGrid>
									) : (
										<ItemGrid xs={12}>
											{tabPosition > -1 ? Tabs[tabPosition].tabContent : null}
										</ItemGrid>
									)}
								</GridContainer>
							}
						/>
					</ItemGrid>
				) : (
					<ItemGrid xs={12}>
						<RegularCard
							isLoading={this.state.isLoading}
							content={
								<div>
									<GridContainer>
										<ItemGrid xs={10}>
											<GridContainer>
												<ItemGrid xs={4} sm={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Department
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.deptCode}
																id={"deptCode"}
																onSelect={this.onSelect}
																getName={this.getQualityDetails}
																// isRefresh={this.state.loadquality}
																//setIsRefresh={this.afterRefreshquality}
																stateKey="deptCode"
																url={
																	serverApi.SELECT_SUB_DEPARTMENT_MASTER +
																	getCookie(cons.COMPANY_ID)
																}
																//value={this.state.selectedJuteQuality}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												{/*<ItemGrid xs={4} sm={4}>*/}
												{/*<GridContainer>*/}
												{/*<ItemGrid xs={12} sm={4}>*/}
												{/*<InputLabel className={classes.label}>*/}
												{/*Pay Scheme*/}
												{/*</InputLabel>*/}
												{/*</ItemGrid>*/}
												{/*<ItemGrid xs={12} sm={8}>*/}
												{/*<SelectboxTemp*/}
												{/*defValue={this.state.payScheme}*/}
												{/*id={"payScheme"}*/}
												{/*onSelect={this.onSelect}*/}
												{/*getName={this.getQualityDetails}*/}
												{/*// isRefresh={this.state.loadquality}*/}
												{/*//setIsRefresh={this.afterRefreshquality}*/}
												{/*stateKey="payScheme"*/}
												{/*url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}*/}
												{/*//value={this.state.selectedJuteQuality}*/}
												{/*/>*/}

												{/*</ItemGrid>*/}
												{/*</GridContainer>*/}

												{/*</ItemGrid>*/}
												{this.state.hrmsAccess ? (
													<ItemGrid xs={4} sm={4}>
														<GridContainer>
															<ItemGrid xs={12} sm={4}>
																<InputLabel className={classes.label}>
																	Reporting
																</InputLabel>
															</ItemGrid>
															<ItemGrid xs={12} sm={8}>
																<SelectboxTemp
																	defValue={this.state.reporting}
																	id={"reporting"}
																	onSelect={this.onSelect}
																	//  getName={this.getQualityDetails}
																	// isRefresh={this.state.loadquality}
																	//setIsRefresh={this.afterRefreshquality}
																	stateKey="reporting"
																	url={serverApi.GET_ALL_USERS}
																	//value={this.state.selectedJuteQuality}
																/>
															</ItemGrid>
														</GridContainer>
													</ItemGrid>
												) : null}

												<ItemGrid xs={4} sm={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																ESI No
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<TextField
																id="esiNum"
																className={classes.textField}
																onChange={this.onChangeValues}
																value={this.state.esiNum}
																fullWidth={true}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={4} sm={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																PF No
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<TextField
																id="pfNum"
																className={classes.textField}
																onChange={this.onChangeValues}
																value={this.state.pfNum}
																fullWidth={true}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={4} sm={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Emp Code
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<TextField
																id="empCode1"
																className={classes.textField}
																onChange={this.onChangeValues}
																value={this.state.empCode1}
																fullWidth={true}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={4} sm={4}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Emp Name
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<TextField
																id="empName"
																className={classes.textField}
																onChange={this.onChangeValues}
																value={this.state.empName}
																fullWidth={true}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Category
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.cataIdList}
																onSelect={this.onSelect}
																//  getName={this.getQualityName}
																stateKey="cataIdList"
																// isRefresh={this.state.loadQuality}
																// setIsRefresh={this.afterRefreshQuality}
																url={
																	serverApi.SELECT_WORKER_CATEGORY +
																	getCookie(cons.COMPANY_ID)
																}
																value={this.state.category}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
												<ItemGrid xs={4} md={4} style={{ marginTop: "10px" }}>
													<GridContainer>
														<ItemGrid xs={12} sm={4}>
															<InputLabel className={classes.label}>
																Designation
															</InputLabel>
														</ItemGrid>
														<ItemGrid xs={12} sm={8}>
															<SelectboxTemp
																defValue={this.state.designationIdList}
																onSelect={this.onSelect}
																//  getName={this.getQualityName}
																stateKey="designationIdList"
																// isRefresh={this.state.loadQuality}
																// setIsRefresh={this.afterRefreshQuality}
																url={
																	serverApi.SELECT_DESIGNATION +
																	getCookie(cons.COMPANY_ID)
																}
																value={this.state.designationId}
															/>
														</ItemGrid>
													</GridContainer>
												</ItemGrid>
											</GridContainer>
										</ItemGrid>
										<ItemGrid xs={12} sm={2}>
											<GridContainer>
												{this.state.hrmsAccess ? (
													<ItemGrid xs={12}>
														<IconButton
															onClick={() => {
																this.setState({ isAdd: true });
																this.handleCreate();
															}}
															color="success"
															right={true}
														>
															<Add />
														</IconButton>
													</ItemGrid>
												) : null}
												<ItemGrid xs={12}>
													<br />
													<br />
													<Button
														color="primary"
														right={true}
														onClick={() => {
															this.handlePage(1, 10);
														}}
													>
														Search
													</Button>
													<br />
												</ItemGrid>
											</GridContainer>
										</ItemGrid>

										<ItemGrid
											xs={12}
											style={{
												height: 2,
												backgroundColor: "#00acc1",
												marginTop: "5px",
											}}
										/>

										<ItemGrid xs={12}>
											<ReportsTableComponent
												url={serverApi.TABLE_WORKERS_LIST}
												//staticData={this.state.dataTable}
												borderRight={true}
												searchData={this.getData()}
												isRefresh={this.state.isRefresh}
												handleSearch={this.handleSeacrh.bind(this)}
												handleLoading={this.handleLoading.bind(this)}
												// isLoading={this.state.isRefresh}
												onActionClick={this.handleViewClick}
												actionType={VIEW}
												fileName={"EmployeeDatabase"}
												handlePage={this.handlePage.bind(this)}
												showPagination={true}
												// dataTableAll={this.state.dataTableAll}
												itemViewType={this.state.itemViewType}
											/>

											{/*
                                            <ExportToExcel posts={this.state.dataTable.data}
                                                           dataTable={this.state.dataTable}
                                                           fileName={"Jute indent"}
                                                           sheetName={"Order1"}/>
*/}
											{/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}

											{/*
                                            <TableComponent
                                                url={serverApi.DATATABLE_SERVICE_URL + "/getAllIndent"}
                                                searchData={this.getData()}
                                                stat
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                printDoc={this.printDocument}
                                                actionType={VIEW}/>
*/}
										</ItemGrid>
										{/*<ItemGrid xs={12} sm={6}></ItemGrid>*/}
										{/*<ItemGrid xs={12} sm={4}><SamplePDF printDocument={this.printDocument}/></ItemGrid>*/}
									</GridContainer>
								</div>
							}
						/>
					</ItemGrid>
				)}
			</GridContainer>
		);
	}

	printDocument = () => {
		var data = {
			taskCode: 1013,
			payloadSize: 2,
			data: {
				fromDate: this.state.fromDate,
				toDate: this.state.todate,
			},
			cipher: getCookie(cons.CIPHER),
			userId: getCookie(cons.USERID),
			companyId: getCookie(cons.COMPANY_ID),
		};
		requestList(
			serverApi.REPORTS_JUTE_WITH_VALUE,
			data,
			this.handlePrintReports
		);
	};
	handlePrintReports = (resp) => {
		var pageNo = 1;
		var modules = [];
		// if(this.state.dataTable!==undefined &&this.state.dataTable!==null ){
		//     console.log("print ",JSON.stringify(this.state.dataTable.data.data));
		//     modules=this.state.dataTable.data.data;
		//     reportValues
		// }
		modules = resp.data.reportValues;
		var grandTotals = resp.data.totalValues;
		var companyName = resp.data.companyName;
		console.log("total", grandTotals);
		var config = {
			printHeaders: true,
			autoSize: true,
			margins: { left: 0, top: 0, bottom: 0, width: 0 },
			fontSize: 10,
		};
		const doc = new Jspdf("p", "pt", "a4");
		doc.setFontSize(14);
		doc.text(170, 40, companyName);
		doc.setFontSize(10);
		var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);

		doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
		// doc.setFont("courier");
		// doc.setFontType("normal");
		doc.setFontSize(10);
		// doc.table(50, 100,modules,modulesHeader,config);
		doc.text(220, 60, "Report No.:[JUTE/01]");
		doc.text(
			170,
			80,
			"Daily Jute With Value Report From  " +
				this.state.fromDate +
				" to " +
				this.state.todate +
				"           Quantity in :Quintal"
		);
		doc.setFontSize(9);
		doc.setFontType("normal");
		doc.cellInitialize();
		doc.cell(30, 100, 30, 30, "Jcode", "", "C");
		doc.cell(60, 100, 45, 30, "Quality", "", "C");
		var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
		doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
		var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
		doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
		var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
		doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
		doc.cell(231, 100, 40, 30, "Rate", "", "C");
		var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
		doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
		var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
		doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
		doc.cell(
			364,
			100,
			155,
			15,
			"<--------------------M T D----------------->",
			"",
			"C"
		);
		/*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
		doc.cellInitialize();
		doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
		doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
		doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
		// doc.cellInitialize();
		var cellWidths = ["", 30, 45, 42, 42, 42, 40, 51, 42, 50, 50, 55, 45];
		var cellTextaligns = [
			"",
			"center",
			"left",
			"right",
			"right",
			"right",
			"right",
			"right",
			"right",
			"right",
			"right",
			"right",
			"center",
		];
		doc.setFontType("normal");
		doc.setFontSize(7);
		{
			var k = 110;
			var i = 0;
			modules.map((prop, key) => {
				k = k + 20;
				i = i + 1;
				doc.cellInitialize();
				if (i === 27) {
					k = 130;
					i = 0;
					pageNo = pageNo + 1;
					var today = moment().format("DD/MM/YYYY H:mm:ss");
					var name = getCookie(cons.USERID);
					var newdat = " Printed on: " + today;
					var printBy = " Printed By: " + name;
					doc.text(30, 800, newdat);
					doc.text(500, 800, printBy);
					doc.addPage("a4", "p");
					doc.setFontSize(14);
					doc.text(170, 40, companyName);
					// doc.setFont("courier");
					// doc.setFontType("normal");
					doc.setFontSize(10);
					doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
					// doc.table(50, 100,modules,modulesHeader,config);
					doc.text(220, 60, "Report No.:[JUTE/01]");
					doc.text(
						170,
						80,
						"Daily Jute With Value Report From  " +
							this.state.fromDate +
							" to " +
							this.state.todate +
							"           Quantity in :Quintal"
					);
					doc.setFontSize(9);
					doc.setFontType("normal");
					doc.cellInitialize();
					doc.cell(30, 100, 30, 30, "Jcode", "", "C");
					doc.cell(60, 100, 45, 30, "Quality", "", "C");
					var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
					doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
					var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
					doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
					var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
					doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
					doc.cell(231, 100, 40, 30, "Rate", "", "C");
					var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
					doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
					var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
					doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
					doc.cell(
						364,
						100,
						155,
						15,
						"<--------------------M T D----------------->",
						"",
						"C"
					);
					/* var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                     doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
					doc.cellInitialize();
					doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
					doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
					doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
					doc.setFontType("normal");
					doc.setFontSize(7);
				}
				var a = 0;
				return Object.keys(prop).map((key, index) => {
					a = a + 1;
					var myItem = prop[key] + " ";
					return doc.cell(
						30,
						k,
						cellWidths[a],
						20,
						myItem,
						2,
						cellTextaligns[a]
					);
				});
			});
		}
		// var grandTotals = {
		//     "OpeningWeight": 25455 + "",
		//     "ReceiptWeight": 0.0 + "",
		//     "IssueWeight": 200 + "",
		//     "Rate": " ",
		//     "IssueAmount": 1300000 + "",
		//     "Closing": 2555005 + "",
		//     "ReptQty": 5544 + "",
		//     "IssueQty": 2455 + "",
		//     "IsueAmount": 25555 + "",
		//   //  "StockForDays": " "
		// };

		doc.cellInitialize();
		doc.setFontType("bold");
		doc.cell(30, k + 20, 75, 30, "Grand Total", "", "left");
		doc.cell(
			105,
			k + 20,
			42,
			30,
			grandTotals.openingWeightTot + " ",
			"",
			"right"
		);
		doc.cell(
			147,
			k + 20,
			42,
			30,
			grandTotals.receivedWeightTot + " ",
			"",
			"right"
		);
		doc.cell(
			189,
			k + 20,
			42,
			30,
			grandTotals.issuedWeightTot + " ",
			"",
			"right"
		);
		doc.cell(231, k + 20, 40, 30, " ", "", "right");
		doc.cell(
			271,
			k + 20,
			51,
			30,
			grandTotals.issueAmountTot + " ",
			"",
			"right"
		);
		doc.cell(
			322,
			k + 20,
			42,
			30,
			grandTotals.closingweightcTot + " ",
			"",
			"right"
		);
		doc.cell(
			364,
			k + 20,
			50,
			30,
			grandTotals.receivedWeightToDateTot + " ",
			"",
			"right"
		);
		doc.cell(
			414,
			k + 20,
			50,
			30,
			grandTotals.issuedWeightToDateTot + " ",
			"",
			"right"
		);
		doc.cell(
			464,
			k + 20,
			55,
			30,
			grandTotals.issueAmountToDateTot + " ",
			"",
			"right"
		);
		//  doc.cell(519, k + 25, 45, 30, grandTotals.StockForDays, '', 'right');

		var Avgs = {
			AvgIssuePriceToday: 4240.74 + "",
			AvgIssuePriceTodate: 4240.74 + "",
			batchPriceToday: "4284.00",
			batchPriceTodate: " 4339.59",
		};
		doc.setFontType("normal");
		doc.setFontSize(10);

		// doc.text(30, k + 75, 'Avg. Issue Price : Today');
		// doc.text(170, k + 75, Avgs.AvgIssuePriceToday);
		// doc.text(220, k + 75, 'Todate');
		// doc.text(260, k + 75, Avgs.AvgIssuePriceTodate);
		// doc.text(30, k + 90, 'Batch Price        : Today');
		// doc.text(170, k + 90, Avgs.batchPriceToday);
		// doc.text(220, k + 90, 'Todate');
		// doc.text(260, k + 90, Avgs.batchPriceTodate);

		doc.text(30, k + 150, "Dept. Incharge");
		doc.text(400, k + 150, "Authorised Signature");
		doc.setFontType("normal");
		doc.setFontSize(7);
		var today = moment().format("DD/MM/YYYY H:mm:ss");
		var name = getCookie(cons.USERID);
		var newdat = " Printed on: " + today;
		var printBy = " Printed By: " + name;
		doc.text(30, k + 200, newdat);
		doc.text(500, k + 200, printBy);

		doc.save("JuteValueReport.pdf");
	};
}

const extendedFormsStyle = {
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#00ABDC",
		fontSize: "15px",
		//  lineHeight: "1.0",
		fontWeight: "400",
		display: "inline-flex",
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	textField: {},
	input: {
		border: "1px solid #ccc",
		borderRadius: "4px",
	},

	input_group_addonunit: {
		padding: 0,
		width: "70px",
		borderTopRightRadius: "4px",
		borderBottomRightRadius: "4px",
		overflow: "hidden",
	},
	buttontxt: {
		textTransform: "none",
		borderRadius: "4px",
		lineHeight: "1.2",
	},
	...customSelectStyle,
	...sweetAlertStyle,
	...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(EmployeeDatabase);
