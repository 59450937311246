 import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import PdfImage from "../../assets/img/pdf_image.png";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {getDecimal, serverApi, serverConfig} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
 import IconButton from "../../components/CustomButtons/IconButton";
 import Print from "@material-ui/icons/Print";
 import Close from "@material-ui/icons/Close";
 import TextField from "../../components/CustomInput/CustomTextField";
 import InputAdornment from "material-ui/Input/InputAdornment";
 import Search from "@material-ui/icons/Search";


class PaySlip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate:  moment().startOf('month').format("DD-MM-YYYY"),
            todate: moment().endOf("month").format("DD-MM-YYYY"),
            isRefresh: false,
            isLoading: false,
            dataTable:null,
            empList:[],
            paySlipsList:[],
            yearsList:[],
            ebId:"",
            payList:[],year:""


        };

    }
    componentDidMount(){
         this.getReportsTable();
         if(getCookie(cons.EB_ID)!=='null'){
             var ebid=parseInt(getCookie(cons.EB_ID));
             this.setState({ebId:ebid});
             this.getAvailablePaySlips(ebid,"")
         }
    }

    getReportsTable=()=>{
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "deptId":0,
            "empCode": "",
            "empName": "",
            "esiNo":"",
            "payScheme":"",
            "pfNo": "",
            "reporting":0,
            "userId":getCookie(cons.USERID)
        };

       // this.handleLoading();
        requestList(serverApi.TABLE_WORKERS_LIST,data,this.handleReports)
    };
    handleReports = (resp) =>{
        var data=[];
        data= resp.data.map(prop=>{
            var obj={label:prop.workerName,value:prop.ebId,name:prop.workerName+"_"+prop.ebNo};
            return obj;
        });

        this.setState({
            empList:data,
            loadEmp:true
        });
        //}
       //   this.handleLoading();

    };
    getAvailablePaySlips(ebId,ebNo){
        var data={
            "companyId": getCookie(cons.COMPANY_ID),
            "ebId": ebId,
          //  "ebNo": ebNo
        };
        this.handleLoading();

        requestList(serverApi.GET_AVAILABLE_PAYSLIPS,data,this.handleAvailablePaySlips)
    }
    handleAvailablePaySlips=(resp)=>{
        this.setState({paySlipsList:resp});
        var yearlist=[];
        resp.forEach(prop=>{
            var year=prop.name.split("_"),hasyear=false;
            year=year[0].split("-");
            year=year[2];

            yearlist.forEach(prop=>{
                if(prop.value===year){
                    hasyear=true
                }
            });

            if(!hasyear&&year!==undefined)
            yearlist.push({label:year,value:year})
        });
        this.setState({yearsList:yearlist,loadYear:true});
        this.handleLoading();

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    onSelect = (selectedValue, stateKey) => {
        if(stateKey==='ebId'){
            this.getAvailablePaySlips(selectedValue,"")
        }
        if(stateKey==='year'){
            var payList=[];
            var list=this.state.paySlipsList;
            list.forEach(prop=>{
                var year=prop.name.split("_"),hasyear=false;
                year=year[0].split("-");
                year=year[2];
                if(year===selectedValue){
                    payList.push(prop);
                }
            });
            console.log("paylist",payList);
            this.setState({
                payList:payList
            })
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };

    getEmpDetails=()=>{
        const {ebNo} = this.state;
        if(ebNo!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+ebNo+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if(ebNo===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
                empName: header.workerName,
                dept: header.deptId,
                loadDept:true,
                ebId:header.ebId
            });
            this.getAvailablePaySlips(header.ebId,header.ebNo)


        }else {
            this.showMessage(resp.message,false)
        }


    };
    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };


        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                <ItemGrid xs={12}>
                    <RegularCard
                        isLoading={this.state.isLoading}
                        content={
                            <div>
                                <GridContainer>
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={6} >
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            EB No
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="ebNo"
                                                            value={this.state.ebNo}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                                    </InputAdornment>
                                                                )
                                                            }}


                                                        />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

{/*
                                            <ItemGrid xs={6} sm={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Employee
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <SelectboxTemp
                                                            id={"ebId"}
                                                            defValue={this.state.ebId}
                                                            onSelect={this.onSelect}
                                                            getName={this.ebId}
                                                            stateKey="ebId"
                                                            isRefresh={this.state.loadEmp}
                                                            setIsRefresh={(val)=>{
                                                                this.setState({loadEmp:false})
                                                            }}
                                                            staticData={this.state.empList}
                                                            value={this.state.ebId}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
*/}
                                            <ItemGrid xs={6} sm={6} >
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Year
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <SelectboxTemp
                                                            id={"year"}
                                                            defValue={this.state.year}
                                                            onSelect={this.onSelect}
                                                            getName={this.year}
                                                            stateKey="year"
                                                            isRefresh={this.state.loadYear}
                                                            setIsRefresh={(val)=>{
                                                                this.setState({loadYear:false})
                                                            }}
                                                            staticData={this.state.yearsList}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.payList.map((prop,i) => (
                                        <ItemGrid xs={12} sm={3} style={{alignContent:"center",marginTop:"5px"}}>
                                            <img  src={PdfImage} color={"#00ABDC"} style={{cursor:"pointer",tintColor:"#00ABDC",marginLeft:50}} width={100} height={100} onClick={()=>{this.printDocument(prop.value,prop.name)}}/>

                                          {/*  <IconButton color={"primaryNoBackground"}  onClick={()=>{this.printDocument(prop.value)}}>
                                                <Print/>
                                            </IconButton>*/}

                                            <InputLabel className={classes.label} onClick={()=>{this.printDocument(prop.value,prop.name)}}>
                                                {prop.label}
                                            </InputLabel>


                                        </ItemGrid>


                                    ))}

{/*
                                    <ItemGrid xs={12} sm={6}>
                                        <Button color="primary" right={true}
                                                onClick={this.handleSeacrh}>print</Button>

                                    </ItemGrid>
*/}


                                </GridContainer>
                            </div>
                        }
                    />
                </ItemGrid>

            </GridContainer>
        );
    }

    printDocument=(id,name)=> {
        var dates=name.split("_");
        var data = {
            "ebId":this.state.ebId,
           // "componentId":id,
            "companyId": getCookie(cons.COMPANY_ID),
            "ebNo":this.state.ebNo,
            "fromDate":dates[0],
            "toDate":dates[1],
            "payPeriodId":id
        };
        requestList(serverApi.GET_PAYSLIP, data, this.handlePrintReports)
      //  this.handlePrintReports("");
    };
    handlePrintReports = (resp) =>{
        var data=resp.data, earnings=[],deductions=[],grossEarnings=0,grossDeductions=0,noCalculation=[],summary=[];
        data.components.forEach(prop=>{
            if(prop.isDisplayableInPayslip&&prop.id!==20&&prop.id!==25)
            {
                var obj={paramCode: prop.name, paramValue: prop.value};
                if (prop.type === 1) {
                    earnings.push(obj)
                } else if (prop.type === 2) {
                    deductions.push(obj)
                }else if(prop.type===0){
                    noCalculation.push(obj)
                }else if(prop.type===3){
                    summary.push(obj)
                }
            }
            if(prop.id===20)
                grossEarnings=getDecimal(prop.value);
            if(prop.id===25)
                grossDeductions=getDecimal(prop.value);


        });
        var companyName = getCookie(cons.COMPANY_TITLE) ;
        var address1 = getCookie(cons.COMPANY_ADDRESS);
        var address2 =getCookie(cons.COMPANY_ADDRESS2) ;
        var pageNo=1;
        const doc = new Jspdf('p', 'pt', 'a4');
        if(getCookie(cons.COMPANY_LOGO)!=="undefined"&&getCookie(cons.COMPANY_LOGO)!=="null"&&getCookie(cons.COMPANY_LOGO)!==undefined){
            var imgData=getCookie(cons.COMPANY_LOGO);
            doc.setFontSize(16);
            doc.addImage(imgData, 'JPEG', 15, 20, 120, 50);
        }
        var k = 30;
        companyName = doc.splitTextToSize(companyName.toUpperCase() , 300);
        doc.setFontType('bold');
        doc.text(580, k,companyName, 'right');
        doc.setFontSize(10);
        var TotalPgaeNo = doc.internal.getNumberOfPages();
        k=k+10;
        if(address1!=='null'&&address1!==null&&address1!==undefined){
            address1 = doc.splitTextToSize(address1.toUpperCase() , 300);
            doc.text(580, k,address1, 'right');
            var ht  =doc.getTextDimensions(address1).h;
            k=k+ht;
        }
        if(address2!==null&&address2!=='null'&&address2!==undefined){
            address2 = doc.splitTextToSize(address2.toUpperCase() , 300);
            doc.text(580, k,address2, 'right');
            var ht  =doc.getTextDimensions(address2).h;
            k=k+ht;
        }


        var address3 = "";
        if (getCookie(cons.COMPANY_CONTACT_NO) !== 'undefined' && getCookie(cons.COMPANY_CONTACT_NO) !== 'null'&&getCookie(cons.COMPANY_CONTACT_NO) !== undefined) {
            if (address3!=="")
                address3 = "\nPh.No: " + getCookie(cons.COMPANY_CONTACT_NO) + " "
            else
                address3 = "Ph.No: " + getCookie(cons.COMPANY_CONTACT_NO) + " "

        }
        if (getCookie(cons.COMPANY_MAIL_ID) !== 'undefined' && getCookie(cons.COMPANY_MAIL_ID) !== 'null'&&getCookie(cons.COMPANY_MAIL_ID)!==undefined) {
            address3 = address3 + "Email: " + getCookie(cons.COMPANY_MAIL_ID)+ ' '
        }
        console.log("gsrt","kk"+ getCookie(cons.COMPANY_GST)+"kkk");
        if (getCookie(cons.COMPANY_GST) !== 'undefined' && getCookie(cons.COMPANY_GST) !== 'null'&&getCookie(cons.COMPANY_GST) !== undefined&&getCookie(cons.COMPANY_GST) !== ""&&getCookie(cons.COMPANY_GST).trim.length>0) {
            if (address3!=="")
                address3 = address3 + "\nGST No: " + getCookie(cons.COMPANY_GST)//doc.setFontType("bold");
            else
                address3 = address3 + "GST No: " + getCookie(cons.COMPANY_GST)//doc.setFontType("bold");

        }
        if(address3!=="") {
            var Add3Off = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(address3) * doc.internal.getFontSize() / 2);
            address3 = doc.splitTextToSize(address3.toUpperCase(), 300);
            doc.text(580, k,address3, 'right');
            var ht  =doc.getTextDimensions(address3).h;
            k=k+ht;
        }
        var title="SALARY SLIP";
        doc.setFontSize(16);

        var xOffset1 = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(title) * doc.internal.getFontSize() / 2);
        k=k+10;
        doc.text(title, xOffset1, k);
        k=k+15;

        doc.line(10, k, 585, k);
        doc.setFontSize(8);
        doc.setFontType("bold");
        //doc.setFillColor(0, 172,193,1);
       // doc.rect(10, 10, 150, 160, "F");

        doc.cellInitialize();
        ht=15;
        var color="#00ABDC";
       // doc.setDrawColor(0,172,193,1);
        doc.setFillColor(color);
        doc.rect(10, k, 70, ht, "F");
        doc.cell(10, k, 70, ht, "Name", '', 'center');
        doc.setFontType("normal");
        doc.cell(70, k, 350, ht, data.workerName+" ", '', 'center');
        doc.setFontType("bold");
        doc.setFillColor(color);
        doc.rect(430, k, 70, ht, "F");
        doc.cell(430, k, 70, ht, "EB No ", '', 'center');
        doc.setFontType("normal");
        doc.cell(490, k, 85, ht, data.ebNo+" ", '', 'center');
        k=k+15;
        doc.cellInitialize();
        doc.setFillColor(color);
        doc.setFontType("bold");
        doc.rect(10, k, 70, ht, "F");
        doc.cell(10, k, 70, ht, "From Date", '', 'center');
        doc.setFontType("normal");
        doc.cell(70, k, 140, ht, data.fromDate+" ", '', 'center');
        doc.setFontType("bold");
        doc.setFillColor(color);
        doc.rect(220, k, 70, ht, "F");
        doc.cell(220, k, 70, ht, "To Date", '', 'center');
        doc.setFontType("normal");
        doc.cell(280, k, 140, ht, data.toDate+" ", '', 'center');
        doc.setFillColor(color);
        doc.rect(430, k, 70, ht, "F");
        doc.setFontType("bold");
        doc.cell(430, k, 70, ht, "Payment Mode", '', 'center');
        doc.setFontType("normal");
        var paymentmode=" ";
        if(data.paymentMode!==null&&data.paymentMode!==undefined)
            paymentmode=data.paymentMode+"";
        doc.cell(490, k, 85, ht, paymentmode, '', 'center');
        k=k+15;
        doc.cellInitialize();
        doc.setFillColor(color);
        doc.setFontType("bold");
        doc.rect(10, k, 70, ht, "F");
        doc.cell(10, k, 70, ht, "Bank A/C No", '', 'center');
        doc.setFontType("normal");
        var acNo=" ";
        if(data.bankACNo!==null)
            acNo=data.bankACNo+"";
        doc.cell(70, k, 140, ht, acNo+" ", '', 'center');
        doc.setFontType("bold");
        doc.setFillColor(color);
        doc.rect(220, k, 70, ht, "F");
        var bankName=" ";
        if(data.bankName!==null)
            bankName=data.bankName+"";
        doc.cell(220, k, 70, ht, "Bank Name", '', 'center');
        doc.setFontType("normal");
        doc.cell(280, k, 140, ht, bankName, '', 'center');
        doc.setFillColor(color);
        doc.rect(430, k, 70, ht, "F");
        doc.setFontType("bold");
        var ifsc=" ";
        if(data.ifsc!==null)
            ifsc=data.ifscCode+""
        doc.cell(430, k, 70, ht, "IFSC Code", '', 'center');
        doc.setFontType("normal");
        doc.cell(490, k, 85, ht,ifsc, '', 'center');

        k=k+15;
        doc.cellInitialize();
        doc.setFillColor(color);
        doc.setFontType("bold");
        doc.rect(10, k, 70, ht, "F");
        //doc.cell(10, k, 70, ht, "Total Days", '', 'center');
       /* doc.setFontType("normal");
        var totalDays=" ";
        if(data.totalDays!==null&&data.totalDays!==undefined)
            totalDays=data.totalDays+"";
        doc.cell(70, k, 140, ht, totalDays, '', 'center');
        doc.setFontType("bold");
        doc.setFillColor(color);
        doc.rect(220, k, 70, ht, "F");
        doc.cell(220, k, 70, ht, "Payable Days", '', 'center');
        doc.setFontType("normal");
        var payableDays=" ";
        if(data.payableDays!==null&&data.payableDays!==undefined)
            payableDays=data.payableDays+"";
        doc.cell(280, k, 140, ht, payableDays, '', 'center');*/
        doc.setFillColor(color);
      //  doc.rect(430, k, 70, ht, "F");
        doc.setFontType("bold");
        doc.cell(10, k, 70, ht, "PAN No", '', 'center');
        doc.setFontType("normal");
        var panNo=" ";
        if(data.panNo!==null&&data.panNo!==undefined)
            panNo=data.panNo+"";
        doc.cell(70, k, 505, ht, panNo, '', 'center');
        k=k+15;
        doc.cellInitialize();
        doc.setFillColor(color);
        doc.setFontType("bold");
        doc.rect(10, k, 70, ht, "F");
        doc.cell(10, k, 70, ht, "ESI No.", '', 'center');
        doc.setFontType("normal");
        doc.cell(70, k, 505, ht, data.esiNo+" ", '', 'center');
        k=k+15;
        doc.cellInitialize();
        doc.setFillColor(color);
        doc.setFontType("bold");
        doc.rect(10, k, 70, ht, "F");
        doc.cell(10, k, 70, ht, "PF No.", '', 'center');
        doc.setFontType("normal");
        doc.cell(70, k, 505, ht, data.pfNo+" ", '', 'center');
        k=k+15;
        noCalculation.forEach((prop,i)=>{
            console.log("iiiii",i % 2 );

            if(i % 2===0){
                doc.rect(10, k, 575, ht);
                doc.cellInitialize();
                doc.setFontType("bold");
                doc.setFillColor(color);
                doc.rect(10, k, 160, ht, "F");
                doc.cell(10, k, 160, ht, prop.paramCode+" ", '', 'center');
                doc.cell(160, k, 127, ht,prop.paramValue+" " , '', 'right');
            }else {
                doc.setFillColor(color);
                doc.rect(297, k, 160, ht, "F");
                doc.cell(297, k, 160, ht, prop.paramCode+" ", '', 'center');
                doc.cell(447, k, 128, ht, prop.paramValue+" ", '', 'right');
                k=k+15;
            }
        });
        k=k+15;
        //doc.line(10, k, 585, k);
        doc.cellInitialize();
        doc.setFillColor(color);
        doc.setFontType("bold");
        doc.rect(10, k, 287, ht, "F");
        var xOffset = (287 / 2) - (doc.getStringUnitWidth("Earnings") * doc.internal.getFontSize() / 2);
        doc.cell(10, k, 287, ht, " ", '', 'center');
        doc.text("Earnings", xOffset, k+10);

        doc.setFillColor(color);
        doc.rect(297, k, 288, ht, "F");
        var xOffset = ((575+288) / 2) - (doc.getStringUnitWidth("Deductions") * doc.internal.getFontSize() / 2);
        doc.text("Deductions", xOffset, k+10);
        doc.cell(297, k, 288, ht, " ", '', 'center');
        doc.setFontType("normal");

        k=k+15;
        var length=10,kval=k,kval1=k,earningTotal=0.00,deductionTotal=0.00;
        earnings.forEach(prop=>{
            doc.cellInitialize();
            doc.cell(10, kval, 160, ht, prop.paramCode+" ", '', 'center');
            doc.cell(160, kval, 127, ht, prop.paramValue+" ", '', 'right');
            if(prop.paramValue!==null&&prop.paramValue!=="")
            earningTotal=earningTotal+parseFloat(prop.paramValue);
            kval=kval+15
        });
        deductions.forEach(prop=>{
            doc.cellInitialize();
            doc.cell(297, kval1, 160, ht, prop.paramCode+" ", '', 'center');
            doc.cell(447, kval1, 128, ht, prop.paramValue+" ", '', 'right');
            if(prop.paramValue!==null&&prop.paramValue!=="")
            deductionTotal=deductionTotal+parseFloat(prop.paramValue);

            kval1=kval1+15
        });
        if(earnings.length>=length){
            length=earnings.length+1
        }
        if(deductions.length>=length){
            length=deductions.length+1
        }
        doc.line(297, k, 297, k+length*15);
        k=k+length*15;
        doc.line(10, k, 585, k);
        doc.cellInitialize();
        doc.setFontType("bold");
        doc.setFillColor(color);
        doc.rect(10, k, 160, ht, "F");
        doc.cell(10, k, 160, ht, "Total Earnings", '', 'center');
        doc.cell(160, k, 127, ht,grossEarnings+" " , '', 'right');
        doc.setFillColor(color);
        doc.rect(297, k, 160, ht, "F");
        doc.cell(297, k, 160, ht, "Total Deductions", '', 'center');
        doc.cell(447, k, 128, ht, grossDeductions+" ", '', 'right');
        k=k+15;

        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        function inWords(num) {
            if ((num = num.toString()).length > 9) return 'overflow';
            var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
            if (!n) return;
            var str = '';
            str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
            str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
            str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
            str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
            str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
            return str;
        }
        function withDecimal(n) {
            var nums = n.toString().split('.')
            var whole = inWords(nums[0]);
            if (nums.length == 2) {
                if (parseInt(nums[1]) > 0) {
                    var fraction = inWords(parseInt(nums[1]));
                    return whole + 'Rupees and ' + fraction + 'Paisa';
                } else {
                    return whole + 'Rupees';
                }

            } else {
                return whole + 'Rupees';
            }
        }
        summary.forEach(prop=>{
            ht=20;
            doc.cellInitialize();
            doc.setFontType("bold");
            doc.setFillColor(color);
            var label=doc.splitTextToSize(prop.paramCode+" ", 50);
           var ht1 = doc.getTextDimensions(label).h;
           if(ht1>ht)
               ht=ht1;
            doc.rect(10, k, 60, ht, "F");

            doc.cell(10, k, 60, ht, label, '', 'center');
            doc.cell(60, k, 100, ht, Math.round(prop.paramValue).toFixed(2)+"", '', 'center');
            doc.setFillColor(color);
            doc.rect(170, k, 127, ht, "F");
            doc.cell(160, k, 127, ht,prop.paramCode+" IN WORDS " , '', 'right');
            var total=Math.round(prop.paramValue).toFixed(2);
            console.log("total",total);
            var amount = withDecimal(total);
            doc.cell(297, k, 288, ht, amount, '', 'center');
            k=k+ht
        });


        k=k+60;
        doc.setFontType("bold");
        doc.text(580, k, 'Authorised Signature', 'right');
        k=k+20;
        if(k>830){
            doc.addPage('a4', 'p');
            k=30
        }

        //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
        doc.setFontSize(7);

        var note = 'Note* : \n1. This document is intented to be personnal use only.\n' +
            '2. This is Computer generated document and requires no signature.\n' +
            '3. Values are in Indian Currency';
        //   var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
        note = doc.splitTextToSize(note, 400);

        var ht = doc.getTextDimensions(note).h;
        doc.setFontType('bold');
        if (k + 20 + ht > 830) {
            doc.addPage('a4', 'p');
            k = 30
        }
        doc.text(15, k, note );
        k=k+ht+10;
        if(k>830){
            doc.addPage('a4', 'p');
            k=30
        }
        doc.text(580, k, "Page " + pageNo + " of " + TotalPgaeNo + "","right");
        k=k+10
        if(k>830){
            doc.addPage('a4', 'p');
            k=30
        }

        doc.setFontType("normal");

        var today = moment().format('DD/MM/YYYY H:mm:ss');
        var name = getCookie(cons.USERID);
        var newdat = "Printed on: " + today;
        var printBy = "Printed By: " + name;
        doc.text(15, k , newdat);
        doc.text(580, k , printBy,"right");
        //  k=k+10;
        //addWaterMark(doc,printcopy);
        doc.rect(10, 10, doc.internal.pageSize.width - 20, k, 'S');


        doc.save('payslip.pdf');



    }

}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(PaySlip);
