import React from "react";
import {getDataFromUrl, requestList} from "../../server/server";
import withStyles from "material-ui/styles/withStyles";
import {getCookie} from "../../helper/Cookies";
import cons, {serverApi} from "../../helper/Consts";
import RegularCard from "../../components/Cards/RegularCard";
import Table from "../../components/Table/Table";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import ItemGrid from "../../components/Grid/ItemGrid1";
import ReportsTableComponent from "./ReportsTableComponent";
import moment from "moment";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import TextField from "../../components/CustomInput/CustomTextField";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import ReactEcharts from "../Components/ReactECharts";
import CHARTCONFIG from "../Components/ReactECharts/ChartConfig";
import {bartheme} from "../Components/ReactECharts/ChartThemes";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import Refresh from "@material-ui/icons/Refresh";
import IconCustomButton from "../../components/CustomButtons/IconButton";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ExpandMoreIcon from "@material-ui/icons/FileDownload";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {subMenus} from "../../routes/routeIcons";
import exportFromJSON from "export-from-json";

class GenericWidgetComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionsTable: null,
            dataTable: null,
            startIndex:1,lastIndex:10,
            date1:  "",
            date2:"",
            date3:"",
            date4:"",
            date5:"",

            col1: "",
            col1Type: "",
            col2: "",
            col2Type: "",
            col3: "",
            col4:"",
            col5:"",
            col3Type: "",
            col4Type:"",
            col5Type:"",
            col1Label: "",
            col2Label: "",
            col3Label: "",
            col4Label: "",
            col5Label: "",
            hasHeader:false,selectedValue:1,
            dataTableAll:null,
            isExcel:false,
            loadAll:true,
            showMore:null
        }
    }
    getSearchFields=(menuId)=>{
        getDataFromUrl(serverApi.GET_SEARCH_FIELDS+menuId,this.handleSearchFields)
    };
    handleSearchFields=(resp)=>{
        if(resp.status===true&&resp.data!==null){
            var data=resp.data;
            this.setState({
                col1: data.col1,
                col1Type: data.col1Type,
                col2: data.col2,
                col2Type: data.col2Type,
                col3: data.col3,
                col3Type: data.col3Type,
                col4:data.col4,
                col5:data.col5,
                col4Type:data.col4Type,
                col5Type:data.col5Type,
                col1Label: data.col1Label,
                col2Label: data.col2Label,
                col3Label: data.col3Label,
                col4Label: data.col4Label,
                col5Label: data.col5Label,
                hasHeader:true,
                date1:data.col1Type==="date"?moment().days(-10).format("YYYY-MM-DD"):"",
                date2:data.col2Type==="date"?moment().format("YYYY-MM-DD"):""
            })
        }
        console.log("handleSearchFields",resp);
        this.getData(this.state.startIndex,this.state.lastIndex);
    };

    getData = (start,end,isExcel) => {
        const {menuType,menuId,classes} = this.props;
        var data={};
        if(menuType===3){
            var condition="";
            if(this.state.col1!==""){
                condition=  this.state.col1+"='"+getCookie(cons.ACADEMIC_YEAR)+"' "
            }
             data= {"taskCode":1025,
                "payloadSize":3,
                "data":{ "menuId": menuId,
                    "condition":condition,
                    "menuType":menuType}
                ,"cipher":getCookie(cons.CIPHER),
                "userId":getCookie(cons.USERID),"companyId":getCookie(cons.COMPANY_ID),"ac_year":getCookie(cons.ACADEMIC_YEAR)};
            requestList(serverApi.REPORTS_JUTE_WITH_VALUE,data,this.handleWidgets)


        }else if(menuType===4||menuType===5){
            var condition="";
            if(this.state.col1!==""){
                condition=  this.state.col1+"='"+getCookie(cons.ACADEMIC_YEAR)+"' "
            }
            data= {"taskCode":1025,
                "payloadSize":2,
                "data":{ "menuId": menuId,"condition":condition}
                ,"cipher":getCookie(cons.CIPHER),
                "userId":getCookie(cons.USERID),"companyId":getCookie(cons.COMPANY_ID),"ac_year":getCookie(cons.ACADEMIC_YEAR)};
            requestList(serverApi.GET_GRAPHS_DATA,data,this.handleWidgets)

        }
       else if(getCookie(cons.MENU_TYPE)==='2'||menuType===2){
            const { date1, date2, col1, col1Type, col2, col2Type, col3, col3Type,col4, col5, col4Type, col5Type,hasHeader}=this.state;

            var condition={
                "menuId":menuType===2?menuId: parseInt(getCookie(cons.MENU_ID)),
                "condition":"",
                "menuType":menuType===2?menuType:parseInt(getCookie(cons.MENU_TYPE))
            };
            if(col1!==""){
                if(col1Type==="date"&&this.state.date1!=="" )
                condition.condition=col1+">='"+this.state.date1+"' "
                else if(col1Type==="text"&&this.state.date1!==""){
                    condition.condition=col1+"='"+this.state.date1+"' ";
                }
            }
            if(col2!==""){
                if(col2Type==="date"&&this.state.date2!=="")
                    condition.condition= condition.condition+" and "+col2+"<='"+this.state.date2+"' "
                else if(col2Type==="text"&&this.state.date1!==""){
                        condition.condition=condition.condition+" and "+col2+"='"+this.state.date2+"' ";
                }
            }
            if(col3!==""){
                if(this.state.date3!=="") {
                    if(col3Type==="date"){
                        if(condition.condition!=="")
                            condition.condition=condition.condition+" and "+col3+"='"+this.state.date3+"' ";
                        else
                            condition.condition=col3+"='"+this.state.date3+"' ";

                    }
                    if(col3Type==="text") {
                        if(condition.condition!=="")
                        condition.condition = condition.condition + " and " + col3 + " like '%" + this.state.date3 + "%' ";
                        else
                            condition.condition = col3 + " like '%" + this.state.date3 + "%' ";
                    }
                }
            }
            if(col4!==""){
                if(this.state.date4!==""){
                    if(col4Type==="date"){
                        if(condition.condition!=="")
                            condition.condition=condition.condition+" and "+col4+"='"+this.state.date4+"' ";
                        else
                            condition.condition=col4+"='"+this.state.date4+"' ";

                    }
                    if(col4Type==="text"){
                        if(condition.condition!=="")
                            condition.condition=condition.condition+" and "+col4+" like '%"+this.state.date4+"%' ";
                        else
                            condition.condition=col4+" like '%"+this.state.date4+"%' ";


                    }
                }
            }
            if(col5!==""){
                if(this.state.date5!==""){
                    if(col5Type==="date"){
                        if(condition.condition!=="")
                            condition.condition=condition.condition+" and "+col5+"='"+this.state.date5+"' ";
                        else
                            condition.condition=col5+"='"+this.state.date5+"' ";
                    }
                    if(col5Type==="text"){
                        if(condition.condition!=="")
                            condition.condition=condition.condition+" and "+col5+" like '%"+this.state.date5+"%' ";
                        else
                            condition.condition=col5+" like '%"+this.state.date5+"%' ";

                    }
                }
            }

            data={
                "taskCode": 1025,
                "payloadSize": 3,
                "data": condition,
                "cipher":getCookie(cons.CIPHER),
                "userId":getCookie(cons.USERID),"companyId":getCookie(cons.COMPANY_ID),"ac_year":getCookie(cons.ACADEMIC_YEAR)
                ,"startIndex":start,"lastIndex":end
            };

            if(isExcel)
                requestList(serverApi.REPORTS_JUTE_WITH_VALUE,data,this.handleAllData);
            else
            requestList(serverApi.REPORTS_JUTE_WITH_VALUE,data,this.handleWidgets)

        }

    };
    handleAllData=(resp)=>{
        resp= resp.data;
        var exportType = 'xls',fileName=this.props.menuName?this.props.menuName:getCookie(cons.MENU_NAME);
        fileName= fileName.replace(' ','_');
        console.log("filenn",fileName);
        var data=resp.data.map(prop=>{
            var obj={};
            resp.column.forEach(o=>{
                obj[o.Header]=prop[o.accessor]
            });
            return obj;
        });
        exportFromJSON({ data, fileName, exportType })
        this.handleLoading();
    };
    handleWidgets = (resp) => {
        const {menuType,menuId,classes} = this.props;
        var showMore=resp.reportPath;
        console.log("showMore",showMore,menuType,menuId);

        if(menuType===3){
            console.log("generic widget",resp,showMore);

            var respData=resp.data;
            var header=[],data=[];
            respData.column.forEach(prop=>{
                header.push(prop.Header)
            });
            respData.data.forEach(prop=>{
                var obj=[];
                header.forEach(val=>{
                    obj.push(prop[val])
                });
                data.push(obj)

            });

                this.setState({dataTable:{column:header,data:data,showMore:showMore}});

        }else if(menuType===4||menuType===5){
            console.log("generic graph",resp);
            var option={};
            var labelOption = {
                show: true,
                formatter: '{c}  {name|{a}}',
                fontSize: 16,
                rich: {
                    name: {
                        textBorderColor: '#fff'
                    }
                }
            };


            if(resp.status===true){
                var xAxis=[],legendData=[],xName="",yName="";
                xName=resp.data.cols[0];
                yName=resp.data.cols[2];
                resp.data.data.forEach((prop,i)=>{
                    if(i===0){
                        xAxis.push(prop[0]);
                        legendData.push(prop[1])
                    }else {
                        xAxis.forEach(p=>{
                            if(!(xAxis.indexOf(prop[0]) > -1))
                                xAxis.push(prop[0])
                        });
                        legendData.forEach(p=>{
                            if(!(legendData.indexOf(prop[1]) > -1))
                                legendData.push(prop[1])
                        })
                    }


                });
                var series=[];
                legendData.forEach((l,i)=>{
                    var data1=[];
                    xAxis.forEach((m,k)=>{
                        data1.push(0);
                        resp.data.data.forEach(prop=>{
                            if(m===prop[0]&&l===prop[1]){
                              data1[k]=prop[2]
                            }
                        });

                    });
                    var obj= {
                        name:l,
                        type: menuType===4?'bar':'line',
                        barGap: 0,
                        label: labelOption,
                        data: data1
                    }
                    series.push(obj)
                });

                 option={
                    color: ['#00ABDC','#94BA33','#003F66', '#e5323e','#ff9800','#e91e63','#4caf50',"#f44336"],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data:legendData
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {show: true},
                            dataView: {show: false, readOnly: false,title:"Data View"},
                            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled'],title:{ line:"Line" , bar:"Bar" , stack:"Stack" , tiled:"tiled" }},
                            restore: {show: true,title:"Restore"},
                            saveAsImage: {show: true,title:"Save Image"},
                        }
                    },
                    xAxis: [
                        {
                            name:xName,
                            nameLocation:"center",
                            type: 'category',
                            axisTick: {show: false},
                            data:xAxis,
                            axisLabel: {
                                fontSize:10,
                                showMinLabel:true,

                            },
                            nameTextStyle:{ padding: [5, 0, 0, 0],fontSize:14}

                        },

                    ],
                    yAxis: [
                        {
                            name:yName,
                            nameLocation:"end",
                            type: 'value',
                            nameTextStyle:{
                               // fontWeight:400,
                               // fontSize:12,
                                shadowColor:"#000000",
                                padding:0
                            },
                            axisLabel: {
                                textStyle: {
                                    color: CHARTCONFIG.color.text
                                },
                                fontSize:7,
                                //formatter: '{value} K'

                            }
                        }
                    ],
                    series:series
                }
            }
            option.showMore=showMore;
            console.log("option",option);
            this.setState({dataTable:option})


        }
        else if(getCookie(cons.MENU_TYPE)==="2"||menuType===2){
            this.setState({showMore:showMore});
            /*if(this.state.isExcel){
                this.setState({isExcel:false});
                console.log("totalDataaaaaaaaaaa",resp.data);

                this.setState({dataTableAll:resp.data});
            }
            else{*/
                this.setState({dataTable:resp.data,isRefresh:true,totalRecords:resp.data.totalRecords});
                console.log("dataTable",resp.data);

               /* // if(data.length>10){
                if(this.state.loadAll){
                    this.setState({isExcel:true,loadAll:false});
                    console.log("totalRecordsmmmmmm",resp.data.totalRecords);
                    this.getData(1,resp.data.totalRecords);
                }

                //}

            }*/

          //  this.setState({dataTable:resp.data,isRefresh:true})
        }

    };
    handleSeacrh = () => {
        var loading = false;
        if(!this.state.isRefresh){
            this.setState({loadAll:true});
            this.getData(this.state.startIndex,this.state.lastIndex);
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };

    componentDidMount() {
        console.log("menuIdjjjjjjjjjjjjjj");
        if(getCookie(cons.MENU_TYPE)==='2'){

            this.getSearchFields(getCookie(cons.MENU_ID))
        }else if(this.props.menuType===4||this.props.menuType===5||this.props.menuType===3||this.props.menuType===2){
            this.getSearchFields(this.props.menuId)
        }else
            this.getData();
    }
    componentWillReceiveProps(nextProps) {
       // console.log("nextProps",nextProps);

        if( this.props.location!==undefined){

            if( this.props.location.pathname!=="/Home")
            if (nextProps.location.pathname !== this.props.location.pathname) {

                if(getCookie(cons.MENU_TYPE)==='2'){

                    this.getSearchFields(getCookie(cons.MENU_ID))
                }else if(this.props.menuType===4||this.props.menuType===5||this.props.menuType===3||this.props.menuType===2){
                    this.getSearchFields(this.props.menuId)
                }else
                    this.getData();
                //take action here
            }

        }else {

            if(nextProps.menuType===2){
                //console.log("menuType",nextProps.menuType,nextProps.menuId);

                this.getSearchFields(nextProps.menuId)
            }
        }
    }
    handlePage=(start,end)=>{
        this.setState({
            startIndex:start,
            lastIndex:end,
            isPagination:true
        });
        console.log("start",start,end);
        this.getData(start,end);

        //this.handleSeacrh();
    };
    handleExcelData=(start,end)=>{
        this.setState({
            startIndex:start,
            lastIndex:end,
            isExcel:true
        });
        console.log("start",start,end);
        this.getData(start,end);

        //this.handleSeacrh();
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('YYYY-MM-DD')
            });
        else
            this.setState({
                [id] :''
            });
    };
    onChangeValues = (event) => {
        var value = event.target.value;
        this.setState({
            [event.target.id]: value
        });

    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    render() {
        const {menuType,menuId,classes,menuName,onClickShowMore} = this.props;
        const { date1, date2, col1, col1Type, col2, col2Type, col3, col3Type, col4, col5, col4Type, col5Type, col1Label, col2Label,
            col3Label, col4Label, col5Label,hasHeader,showMore}=this.state;

        return (
            <div>
                {menuType===3?
                    <RegularCard
                    isLoading={this.state.isLoading}
                    content={
                        <GridContainer  >
                            <ItemGrid xs={12} sm={7} >
                                <div>
                                    <legend style={{color: "#003F66", fontSize: "18px"}}>{menuName}
                                    </legend>
                                </div>
                            </ItemGrid>
                            <ItemGrid xs={12} md={5}>
                                {console.log("menuName",menuName,showMore)}
                                <IconCustomButton color={"primaryNoBackground"} onClick={this.getData}>
                                    <Refresh/>
                                </IconCustomButton>
                                {this.state.dataTable!==null&&this.state.dataTable.showMore!==null? <Button color={"primaryNoBackground"} style={{marginRight:"20px"}} onClick={()=>{
                                    onClickShowMore(this.state.dataTable.showMore);
                                }}>Show More</Button>:null}

                                {this.state.dataTable!==null? <div>
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="export"
                                        table="table-to-xls"
                                        filename={menuName}
                                        //  sheet={sheetName}
                                        buttonText={
                                            <IconCustomButton
                                                color="successNoBackground"  right={true}>
                                                <ExpandMoreIcon/>
                                            </IconCustomButton>
                                        }
                                    />

                                    <table id="table-to-xls" style={{display: "none"}}>

                                        <tr>
                                            {
                                                this.state.dataTable.column.map(post => {
                                                        return (
                                                            <th>{post}</th>
                                                        )
                                                    }
                                                )
                                            }
                                        </tr>
                                        <tbody>

                                        {
                                            this.state.dataTable.data.map(post => {
                                                    return (
                                                        <tr>
                                                            {
                                                                post.map(prop => {
                                                                        return (
                                                                            <td>{prop}</td>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>:null}

                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={12} style={{height:235,overflow: 'auto', overflowScrolling: "touch"}}>

                                    <div >
                                        {this.state.dataTable!==null?<Table
                                            tableHead={this.state.dataTable.column}
                                            tableData={this.state.dataTable.data}
                                        />:null}

                                    </div>

                            </ItemGrid>

                        </GridContainer>
                    }
                />:null}
                {menuType===4||menuType===5?
                    <RegularCard
                        isLoading={this.state.isLoading}
                        content={
                            <div>
                                <legend style={{color: "#003F66", fontSize: "18px"}}>{menuName}
                                </legend>
                                {this.state.dataTable!==null&&this.state.dataTable.showMore!==null? <Button color={"primaryNoBackground"} right={true} style={{marginTop:"-25px"}} onClick={()=>{
                                    onClickShowMore(this.state.dataTable.showMore);
                                }}>Show More</Button>:null}
                                <IconCustomButton style={{marginTop:"-30px"}} color={"primaryNoBackground"} right={true} onClick={this.getData} >
                                    <Refresh/>
                                </IconCustomButton>

                                {this.state.dataTable!==null?
                                    <ReactEcharts
                                        option={this.state.dataTable}
                                        showLoading={false}/> :null}

                            </div>
                        }
                    /> :null}

                {getCookie(cons.MENU_TYPE)==="2"||menuType===2?

                    <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>




                        <ItemGrid xs={12}>
                    <RegularCard
                        isLoading={this.state.isLoading}
                        content={
                            <GridContainer>
                                <ItemGrid xs={10}>
                                    <GridContainer>
                                {col1!==""&&col1!==null?
                                    <ItemGrid xs={12} sm={6}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {col1Label}
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                {col1Type==="date"?  <FormControl variant="outlined" fullWidth >
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "date1")
                                                            }}
                                                            formControlProps={
                                                                {height:"10px",inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                // id:"toDate",
                                                                value:this.state.date1,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>:
                                                    <TextField
                                                        id="date1"
                                                        value={this.state.date1}
                                                        className={classes.textField}
                                                          onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                }
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>:null}
                                {col2!==""&&col2!==null?
                                    <ItemGrid xs={12} sm={6}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {col2Label}
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                {col2Type==="date"?  <FormControl variant="outlined" fullWidth >
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "date2")
                                                            }}
                                                            formControlProps={
                                                                {height:"10px",inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                // id:"toDate",
                                                                value:this.state.date2,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>:
                                                    <TextField
                                                        id="date2"
                                                       // disabled={true}
                                                        value={this.state.date2}
                                                        className={classes.textField}
                                                          onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                }
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>:null}


                                        {col3!==""&&col3!==null?
                                    <ItemGrid xs={12} sm={6}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {col3Label}
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                {col3Type==="date"?  <FormControl variant="outlined" fullWidth >
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "date3")
                                                            }}
                                                            formControlProps={
                                                                {height:"10px",inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                // id:"toDate",
                                                                value:this.state.date3,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>:
                                                    <TextField
                                                        id="date3"
                                                       // disabled={true}
                                                        value={this.state.date3}
                                                        className={classes.textField}
                                                          onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                }
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>:null}
                                        {col4!==""&&col4!==null?
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            {col4Label}
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        {col4Type==="date"?  <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "date4")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        // id:"toDate",
                                                                        value:this.state.date4,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>:
                                                            <TextField
                                                                id="date4"
                                                                // disabled={true}
                                                                value={this.state.date4}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                            />
                                                        }
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>:null}
                                        {col5!==""&&col5!==null?
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            {col5Label}
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        {col5Type==="date"?  <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "date5")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        // id:"toDate",
                                                                        value:this.state.date5,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>:
                                                            <TextField
                                                                id="date5"
                                                                // disabled={true}
                                                                value={this.state.date5}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                            />
                                                        }
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>:null}

                                    </GridContainer>
                                </ItemGrid>
                                {hasHeader?
                                    <ItemGrid xs={2}>
                                        <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                    </ItemGrid>:null
                                }
                                <ItemGrid xs={12} className={classes.line}/>
                                <ItemGrid xs={12} sm={12} md={12} >
                                    <ReportsTableComponent
                                        // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                        staticData={this.state.dataTable}
                                       // dataTableAll={this.state.dataTableAll}

                                         // searchData={this.getData()}
                                        isRefresh={this.state.isRefresh}
                                        handleSearch={this.handleSeacrh.bind(this)}
                                        handleLoading={this.handleLoading.bind(this)}
                                        // isLoading={this.state.isRefresh}
                                        //  onActionClick={this.handleViewClick}
                                        fileName={getCookie(cons.MENU_NAME)}
                                        handlePage={this.handlePage.bind(this)}
                                        showPagination={true}
                                        itemViewType={this.state.itemViewType}/>
                                    {this.state.totalRecords?  <IconButton right={true} style={{marginTop:"-40px"}}
                                                                           color="success" onClick={()=>{
                                                                               this.handleLoading()
                                        this.getData(1,this.state.totalRecords,true);
                                    }}>
                                        <ExpandMoreIcon/>
                                    </IconButton>:null}

                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                        </ItemGrid>
                    </GridContainer>:null}

            </div>
        );
    }

}
const formStyle={
    ...sweetAlertStyle
}

export default withStyles(formStyle) (GenericWidgetComponent);