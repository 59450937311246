import React from "react";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Add from '@material-ui/icons/Add';
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, getFile, requestList, uploadFormdataWithImage} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";
import TextField from "../../components/CustomInput/CustomTextField";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {VIEW} from "../../ui-actions/UiActions";
import InputAdornment from "material-ui/Input/InputAdornment";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import {roseColor} from "../../assets/jss/material-dashboard-pro-react";


class EmpBillingPayRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: moment().format('YYYY-MM-DD'),
            todate: moment().format('DD-MM-YYYY'),
            status: '',
            isRefresh: false,
            isLoading: false,
            showPopUp: false,
            itemViewType: "issueObj",
            year: moment().format('YYYY'),
            dataTable: null,
            name: "",
            payschemeId: '',
            payschemeIds: '',
            fDate: '',
            tDate: '',
            branchId:'',
            EducationList: [],
            documentsList: [],
            startIndex:1,lastIndex:10,
            payPeriodTable:null,
            customerList:[],
            "customerId": "",
            "paySchemeId": "" ,
            "fromDates":"",
            "toDates":""

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    handlePage=(start,end)=>{
        this.setState({
            startIndex:start,
            lastIndex:end
        });
        this.callgetPayschemeProcessingRequestList(start,end);
        //this.handleSeacrh();
    };
    componentDidMount() {
        this.callPaySchemeList();
        this.callgetPayschemeProcessingRequestList(this.state.startIndex,this.state.lastIndex);
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.CUSTOMER_MASTER,data,this.handelCustomer);
    }

    handelCustomer=(resp)=>{
        var data=resp.data,customerList=[];
        if(data.length>0){
            var obj={label:"Select",value:null,name:"null"};
            customerList.push(obj);
            data.forEach(prop=>{
                var obj={label:prop.name,value:prop.id,name:prop.name};
                customerList.push(obj)
            });
            this.setState({
                customerList:customerList,
                isCustomer:true
            })
        }
    };

    callPaySchemeList() {
        var data={
            "status":"32","activeStatus":""
        }
        requestList(serverApi.PAY_SCHEME_GET_LIST,data, this.handlePaySchemeList);    }

    handlePaySchemeList = (resp) => {
        var payscheme = [];
        resp.map((prop, i) => {
            var obj = {
                "value": prop.id,
                "name": prop.name,
                "label": prop.name

            };
            payscheme.push(obj);
        });
        this.setState({paySchemeList: payscheme});

    }

    callgetPayschemeProcessingRequestList(startIndex,lastIndex) {
        const {branchId,customerId,paySchemeId,fromDates,toDates}=this.state;
        var data = {
            "branchId":branchId ,
            "companyId": getCookie(cons.COMPANY_ID),
            "customerId": "",
            "paySchemeId": "" ,
            "fromDate":fromDates,
            "toDate":toDates
        };
        requestList(serverApi.EMPLOYEE_BILLING_PAYRGISTER_LIST,data, this.handleList);
    };

    handleList = (resp) => {
        var TableData = {
            "data": resp.data,
            "filterTable": true,
            "totalRecords":resp.totRecs,
            "column": [{
                "filterable": false,
                "Header": "Rec Id",
                "accessor": "id",
                "minWidth": 40,
                "style": {"textAlign": "left"},
                "sortable": false
            }
                , {
                    "filterable": false,
                    "Header": "Payscheme Name",
                    "accessor": "payscheme",
                    "minWidth": 80,
                    "style": {"textAlign": "left"},
                    "sortable": false
                },
                {
                    "filterable": false,
                    "Header": "Customer Name",
                    "accessor": "customerName",
                    "minWidth": 100,
                    "style": {"textAlign": "left"},
                    "sortable": false
                },
                {
                    "filterable": false,
                    "Header": "FromDate",
                    "accessor": "fromDateDesc",
                    "minWidth": 60,
                    "style": {"textAlign": "left"},
                    "sortable": false
                },
                {
                    "filterable": false,
                    "Header": "ToDate",
                    "accessor": "toDateDesc",
                    "minWidth": 60,
                    "style": {"textAlign": "left"},
                    "sortable": false
                },
                {
                    "filterable": false,
                    "Header": "Wage Type",
                    "accessor": "wageType",
                    "minWidth": 60,
                    "style": {"textAlign": "left"},
                    "sortable": false
                },
                {
                    "filterable": false,
                    "Header": "Status",
                    "accessor": "status",
                    "minWidth": 60,
                    "style": {"textAlign": "left"},
                    "sortable": false
                }]
        };
        this.setState({
            dataTable: TableData,
            isRefresh: true,
        })
    };

    handleViewClick = (obj) => {

        var obj = JSON.parse(obj)
        getDataFromUrl(serverApi.GET_EMP_BILL_PAY_PROCESSED_LIST + "/" + obj.paySchemeId + "/" + obj.id, this.handlePayProcessedList);
        getDataFromUrl(serverApi.EMPLOYEE_BILLING_PAYRGISTER_BY_ID + obj.id + "/" +getCookie(cons.USERID), this.handleGetById);

    };
    handleGetById=(resp)=>{
        this.setState({
            approveButton:resp.approveButton,
            status:resp.status,
            id:resp.id
        })
    }

    handlePayProcessedList = (resp) => {
        console.log("TableDataPayPeriod",resp);

        var TableDataPayPeriod =null;
        if(resp.status===undefined){
            this.setState({
                payPeriodTable: resp,
                isRefresh: true,
                showPopUp: true, isCreated: !this.state.isCreated,
            })

        }

    }

    handleSeacrh = () => {
        var loading = false;
        if (!this.state.isRefresh)

            this.callgetPayschemeProcessingRequestList(this.state.startIndex,this.state.lastIndex);

        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }


    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
            showPopUp: false
        });
        //  this.hideAlertMessage();
    };

    timeChanged = (dateObj, id) => {

        if (typeof dateObj === 'object') {
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        } else if (id !== 'date1') {
            this.setState({
                [id]: ''
            });
        }
        // this.dateChanged();
    };
    dateChanged = (date) => {
        var myDate = date;
        myDate = myDate.split("-");
        var newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
         return newDate;
    }

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            isCreated: false,
            payschemeId: '',
            fDate: '',
            tDate: '',
            branchId:''
        });
    }

    hideAlertMessage() {
        this.callgetPayschemeProcessingRequestList(this.state.startIndex,this.state.lastIndex);
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            payschemeId: '',
            name: '',
            fDate: '',
            tDate: '',
            branchId:''
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {fDate, tDate, payschemeId,branchId,customerId} = this.state;
            var data = {

                "fromDate": this.dateChanged(fDate),
                "toDate": this.dateChanged(tDate),
                "paySchemeId": payschemeId,
                "customerId":customerId,
                "createdBy": getCookie(cons.USERID),
                branchId:branchId
            };

            if (fDate !== "" && tDate !== "" && payschemeId !== ""&& customerId>0) {
                this.handleLoading();
                requestList(serverApi.ADD_EMPLOYEE_BILLING_PAYRGISTER, data, this.handleAddPayRegister)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    onApproveButtonClick=(status)=>{

        var data = {
            "id": this.state.id,
            "userId": getCookie(cons.USERID),
            "status": status
        }

        requestList(serverApi.UPDATE_PAY_PERIOD,data,this.handleUpddatePayPeriod);
    };

    handleUpddatePayPeriod=(resp)=>{
        if (resp.status) {
            this.setState({showPopUp:false,isCreated:false});
            this.callgetPayschemeProcessingRequestList(this.state.startIndex,this.state.lastIndex);

            // console.log("resp " + JSON.stringify(resp));
            // this.showMessage(resp.message, resp.status)
        }
    };

    handleAddPayRegister = (resp) => {
        if (resp.status) {
            var data=new FormData();
            data.append("multipartFile",this.state.file);
            uploadFormdataWithImage(serverApi.UPLOAD_EMP_BULLING_PAY_PROCESS_PAY  + this.state.payschemeId + "/" + resp.id+"/"+this.state.customerId, data, this.handleAddPayPeriod)

            this.showMessage(resp.message, true);

        } else {
            this.showMessage(resp.message, resp.status)
        }
         this.handleLoading();
    };
    handleAddPayPeriod = (resp) => {
       // this.showMessage(resp.message, true);
    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 8) {
            return length
        } else {
            return 8;
        }
    };
    onSelect = (selectedValue, stateKey) => {
        this.setState({
            [stateKey]: selectedValue
        });
    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        //  console.log("file type",file.type);
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'XLSX' || fileType === 'XLS') {
                this.setState({
                    file: file,
                    itemName: file.name,
                    fileType:fileType,
                    //filesList: arr,
                    imagePreviewUrl: reader.result
                });
                console.log("filename",this.state.itemName)
                //  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
            } else {
                this.basicAlert("File type you have selected can not be upload. Please select another file!!!")
            }


        };
        reader.readAsDataURL(file);
    }


    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract(0, 'day');

        var valid = function (current) {
            if (getCookie(cons.COMPANY_ID) !== '2')
                return current.isBefore(yesterday);
            else
                return true;
        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?
                    <Popup
                        onClickSave={() => this.onCreateButtonClick()}
                        closePopup={() => this.handleCreate()}
                        title="Post Temperature"
                        isShowSave={true}
                        isShowCancel={true}
                        save_btn_txt={"Add"}
                        cancel_button_text={"Cancel"}
                        content={

                                <GridContainer>


                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    From<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>

                                                    <Datetime
                                                        closeOnSelect={true}
                                                        isValidDate={valid}

                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "fDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "fDate",
                                                            value: this.state.fDate,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    To<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        isValidDate={valid}

                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "tDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "tDate",
                                                            value: this.state.tDate,
                                                            autoComplete: 'off',
                                                        }}

                                                    />
                                                </FormControl>

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Pay Scheme<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            {this.state.paySchemeList !== undefined ?
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.payschemeId}
                                                        id={"payschemeId"}
                                                        onSelect={this.onSelect}
                                                        getName={this.getQualityDetails}
                                                        // isRefresh={this.state.loadquality}
                                                        //setIsRefresh={this.afterRefreshquality}
                                                        stateKey="payschemeId"
                                                        staticData={this.state.paySchemeList}
                                                        //url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}
                                                        //value={this.state.selectedJuteQuality}
                                                    />

                                                </ItemGrid>
                                                : null}
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} >

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Customer<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.customerId}
                                                    id={"customerId"}
                                                    onSelect={this.onSelect}
                                                    stateKey="customerId"
                                                    staticData={this.state.customerList}
                                                    isRefresh={this.state.isCustomer}
                                                    getName={(name)=>{

                                                    }}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({isCustomer:false})
                                                    }}
                                                    //  url={serverApi.SEARCH_JUTE_STATUS}
                                                    value={this.state.customer}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Branch<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    //  getName={this.getQualityName}
                                                    stateKey="branchId"
                                                    // isRefresh={this.state.loadQuality}
                                                    // setIsRefresh={this.afterRefreshQuality}
                                                    url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                    value={this.state.branchId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}} >
                                              <span style={{float:"right",color:roseColor,fontWidth:400,cursor:"pointer"}}  onClick={() => {
                                                  if(this.state.payschemeId!==""&&this.state.payschemeId!==null)
                                                  getFile(serverApi.GET_EMP_BILLING_PAY_PROCESS_TEMPLATE+this.state.payschemeId+"/"+this.state.customerId);
                                                  else{
                                                      this.basicAlert("Please Select Payscheme..!!")
                                                  }
                                              }}>  Click Here To Get Template File</span>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={2}>
                                                <InputLabel className={classes.label}>
                                                    Upload File<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={10}>
                                                <TextField
                                                    id="itemName"
                                                    disabled={true}
                                                    value={this.state.itemName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        autoComplete: 'off',
                                                        endAdornment: <InputAdornment
                                                            position="end">
                                                            <span style={{
                                                                color: "#003F66",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                cursor:"pointer",
                                                            }} onClick={this.handleFileClick}>BROWSE</span>
                                                        </InputAdornment>,
                                                    }}


                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                </GridContainer>

                        }
                    >
                    </Popup> : null}
                <ItemGrid xs={12}>
                    <RegularCard
                        isLoading={this.state.isLoading}
                        content={
                            <div>
                                <GridContainer>
                                    <ItemGrid xs={8}>
                                        {this.state.showPopUp ?
                                            <Popup
                                                onClickSave={() => this.onApproveButtonClick("3")}
                                                closePopup={() => this.handleCreate()}
                                                onClickReject={() => this.onApproveButtonClick("4")}
                                                title="Post Temperature"
                                                 isShowSave={this.state.approveButton==true && this.state.status!=='3'?true:false}
                                                isShowCancel={true}
                                                isShowReject={this.state.approveButton==true && this.state.status!=='3'?true:false}
                                                save_btn_txt={"Approve"}
                                                reject_btn_txt={"Reject"}
                                                tablePop={true}
                                                // maxHeight={500}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} style={{
                                                                maxHeight: 400,
                                                                overflowY: "auto",
                                                                overflowX: "hidden"
                                                            }}>
                                                              <ReportsTableComponent
                                                                    // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                                                    staticData={this.state.payPeriodTable}
                                                                    borderRight={true}

                                                                    //  searchData={this.getData()}
                                                                    isRefresh={this.state.isRefresh}
                                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                                    // isLoading={this.state.isRefresh}
                                                                    // onActionClick={this.handleViewClick}
                                                                    // actionType={VIEW}
                                                                    fileName={"Pay_Processed_List"}
                                                                    itemViewType={this.state.itemViewType}/>


                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </form>

                                                }
                                            >
                                            </Popup> : null}

                                        <GridContainer>

                                            {/*<ItemGrid xs={12} sm={6}>*/}
                                                {/*<GridContainer>*/}
                                                    {/*<ItemGrid xs={12} sm={4}>*/}
                                                        {/*<InputLabel className={classes.label}>*/}
                                                            {/*Pay Scheme*/}
                                                        {/*</InputLabel>*/}
                                                    {/*</ItemGrid>*/}
                                                    {/*{this.state.paySchemeList !== undefined ?*/}
                                                        {/*<ItemGrid xs={12} sm={8}>*/}
                                                            {/*<SelectboxTemp*/}
                                                                {/*defValue={this.state.payschemeIds}*/}
                                                                {/*id={"payschemeIds"}*/}
                                                                {/*onSelect={this.onSelect}*/}
                                                                {/*getName={this.getQualityDetails}*/}
                                                                {/*isRefresh={this.state.isRefresh}*/}
                                                                {/*setIsRefresh={(val) => {*/}
                                                                    {/*this.setState({isRefresh: false})*/}
                                                                {/*}}*/}
                                                                {/*stateKey="payschemeIds"*/}
                                                                {/*staticData={this.state.paySchemeList}*/}
                                                                {/*//url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}*/}
                                                                {/*//value={this.state.selectedJuteQuality}*/}
                                                            {/*/>*/}

                                                        {/*</ItemGrid>*/}
                                                        {/*: null}*/}

                                                {/*</GridContainer>*/}

                                            {/*</ItemGrid>*/}
                                            <ItemGrid xs={12} sm={5}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            From
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="" fullWidth>

                                                            <Datetime
                                                                closeOnSelect={true}
                                                                isValidDate={valid}

                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "fromDates")
                                                                }}
                                                                height={"15px"}
                                                                formControlProps={
                                                                    {height: "15px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "fDate",
                                                                    value: this.state.fromDates,
                                                                    autoComplete: 'off'
                                                                }}

                                                            />
                                                        </FormControl>

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={5}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            To
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                isValidDate={valid}

                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "toDates")
                                                                }}
                                                                height={"15px"}
                                                                formControlProps={
                                                                    {height: "15px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "tDate",
                                                                    value: this.state.toDates,
                                                                    autoComplete: 'off',
                                                                }}

                                                            />
                                                        </FormControl>

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>

                                            {/*<ItemGrid xs={4} sm={4}>*/}
                                            {/*<GridContainer>*/}
                                            {/*<ItemGrid xs={12} sm={4}>*/}
                                            {/*<InputLabel className={classes.label}>*/}
                                            {/*Pay Period*/}
                                            {/*</InputLabel>*/}
                                            {/*</ItemGrid>*/}
                                            {/*<ItemGrid xs={12} sm={8}>*/}
                                            {/*<SelectboxTemp*/}
                                            {/*id={"payPeriod"}*/}
                                            {/*defValue={this.state.payPeriod}*/}
                                            {/*onSelect={this.onSelect}*/}
                                            {/*getName={this.getName}*/}
                                            {/*stateKey="payPeriod"*/}
                                            {/*url={serverApi.GET_PO_BY_SUPPLIER+this.state.selectedSupplier+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}*/}
                                            {/*value={this.state.payPeriod}*/}
                                            {/*/>*/}

                                            {/*</ItemGrid>*/}
                                            {/*</GridContainer>*/}

                                            {/*</ItemGrid>*/}


                                            <Button color="primary" right={true}
                                                    onClick={this.handleSeacrh}>Search</Button>

                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={4}>
                                        <IconButton
                                            onClick={this.handleCreate}
                                            color="success" right={true}>
                                            <Add/>
                                        </IconButton>


                                    </ItemGrid>

                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>

                                    <ItemGrid xs={12}>
                                        {this.state.dataTable !== null && this.state.dataTable !== undefined ?
                                            <ReportsTableComponent
                                                // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                                staticData={this.state.dataTable}
                                                borderRight={true}

                                                  // searchData={this.getData()}
                                                 isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                               //  isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                actionType={VIEW}
                                                fileName={"payPeriod"}
                                                itemViewType={this.state.itemViewType}
                                                showPagination
                                                handlePage={this.handlePage.bind(this)}/> : null

                                        }
                                        {/*{this.state.dataTable !== null && this.state.dataTable !== undefined ?*/}
                                        {/*<ExportToExcel*/}
                                        {/*btnName={"Download Pay Processed List"}*/}
                                        {/*dataTable={this.state.dataTable}*/}
                                        {/*posts={this.state.dataTable.data}/>:null*/}

                                        {/*}*/}

                                        {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}


                                    </ItemGrid>
                                </GridContainer>
                            </div>
                        }
                    />
                </ItemGrid>

            </GridContainer>
        );
    }


}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(EmpBillingPayRegister);
