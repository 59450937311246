import React from "react";
// @material-ui/icons

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig,serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
class UserManagement extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,

            itemViewType:"userId",
            approveStatus:false,
            suppCode:"",
            alert:false,
            modules1: [{
                id:0,
                name:"Jute",
                subMenus:[{id:0,name:"jute indent",sub_subMenu:[]},{id:1,name:"jute PO",sub_subMenu:[]},{id:2,name:"Quality check",sub_subMenu:[]}]
            },{
                id:1,
                name:"Purchase",
                subMenus:[{id:1,name:"purchase order",sub_subMenu:[]}]
            }, {
                id:2,
                name:"Store",
                subMenus:[{id:2,name:"store indent",sub_subMenu:[]}]
            },{
                id:3,
                name:"Security",
                subMenus:[{id:3,name:"JUTE GATE ENTRY",sub_subMenu:[]}]
            },{
                id:4,
                name:"Reports",
                subMenus:[{id:4,name:"Jute Reports",sub_subMenu:[{id:1,name:"jute"},{id:1,name:"store"}]}]
            } ],
            checked:"",
            selectedApprole:0,
            selectedWebrole:0,
            contactNo:'',
            activeStatus:'',
            activeStatusId:'',
            emailID:'',
            profileName:"",
            userName:'',
            userId:'',
            createdBy:"",
            createdOn:"",
            companyId:getCookie(cons.COMPANY_ID),
            branchList:[],
            supplierList:[]
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }
    componentDidMount() {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };

        requestList(serverApi.BRANCHMASTER_DATATABLE,data,this.handleBranches);
    }
    handleBranches=(resp)=>{
        var list=[];
        resp.data.map((prop,key)=>{
            var val={
                id:0,
                bId:prop.id,
                bName:prop.brname,
                isEnabled:false
            };


            // if(key>0)
            list.push(val)
        });
        this.setState({
            branchList:list
        });
    };


    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleViewClick =(id)=>{
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            //   console.log("jute  "+"  "+id);
            getDataFromUrl(serverApi.GET_USER_DETAILS_BY_ID + id + "/" + getCookie(cons.USERID) + "/" + this.state.companyId + "/" + getCookie(cons.CIPHER), this.handleViewClickResp)
        }
    };
    resetPassword=(id)=>{
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
             getDataFromUrl(serverApi.USER_MANAGEMENT_RESET_PASSWORD  + "/" + id, this.handleAddIndent)
        }
    }

    handleViewClickResp = (resp) => {
        var header = resp.data;
        var branchArray=this.state.branchList;
        if( header.brancList!==undefined)
        branchArray.map((t,i)=>{
            header.brancList.forEach(id=>{
                if(t.bId===id){
                    t.isEnabled=true
                }
            });
        });
      //  var list = resp.data;
        //console.log("indentWeight "+header.indentWeight);
        if(header !== null){
            this.setState({
                updateView:true,
                isCreated:true,
                selectedApprole:header.appRole,
                selectedWebrole:header.portalRole,
                userId:header.userId,
                userName:header.userName,
                profileName:header.profileName,
                contactNo:header.mobile,
                emailID:header.email,
                activeStatus:this.getActiveStatus(header.status),
                activeStatusId:header.status,
                createdBy:header.createdBy,
                createdOn:header.createdOn,
                branchList:branchArray
            });
            if(this.state.showUseralert){
                this.setState({
                    selectedApprole:0,
                    selectedWebrole:0,
                    userSlected:true,
                    showUseralert:false,
                })
            }
        }
    };
    getDefaultUnitConvertion=(name)=>{
        var val="",label="",data="";
        if(name !== null){
            if(name === 'LOOSE'){
                val=1;
                label="LOOSE";
            }else {
                val=2;
                label='BALE';
            }
            data={
                value:val,label:label
            };
        }
        return data;
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleCreate=()=>{
        if(!this.state.isCreated)
            this.hideAlertMessage();

        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data={
            "comId":getCookie(cons.COMPANY_ID),
            "cipher":getCookie(cons.CIPHER)

        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
        if (id !== 'date1')
            this.setState({
                [id] :''
            });

        this.dateChanged();
    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };


    onChangeValues =(event)=> {
        var value= event.target.value;

        this.setState({
            [event.target.id]: value
        });

    };
    handleSupplier=(resp)=>{
        if(resp.status===undefined){
            this.setState({supplierList:resp,loadSupplier:true})
        }
    }
    onSelect = (selectedValue, stateKey) => {
        if(stateKey==='selectedWebrole'&&selectedValue===100){
            getDataFromUrl(serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID),this.handleSupplier);
        }
        if(stateKey==='companyId'){
            if(selectedValue!==parseInt(getCookie(cons.COMPANY_ID))){
                this.setState({
                    loadUsers:true,
                    updateView: false,
                    selectedApprole:0,
                    selectedWebrole:0,
                    contactNo:'',
                    activeStatus:'',
                    emailID:'',
                    profileName:"",
                    userName:'',
                    userId:'',
                    createdBy:"",
                    createdOn:"",
                    reloadSelect:true
                });
            }
            else {
                this.basicAlert("Please Select Different Company..!!")
            }
           // this.hideAlertMessage();
        }
        this.setState({
            [stateKey]: selectedValue,
        });


    };
    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };


    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert:false,
            loading:false,
            showUseralert:false
        });
    }
    hideAlertMessage() {
        var list=this.state.branchList.map((prop,i)=>{
            prop.isEnabled=false;
            return prop;
        });

        console.log("alert");
        this.setState({
            updateView: false,
            isJuteSelected:false,
            alert: false,
            showMessagealert:false,
            loading:false,
            isCreated:false,
            selectedApprole:0,
            selectedWebrole:0,
            contactNo:'',
            activeStatus:'',
            emailID:'',
            profileName:"",
            userName:'',
            userId:'',
            createdBy:"",
            createdOn:"",
            userSlected:false,
            companyId:getCookie(cons.COMPANY_ID),
            branchList:list,
            suppCode:""

        });
    }

    hideBasicAlert() {
        this.setState({
            basicAlert: null,
        });
    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    onUpdateButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var list=[];
            this.state.branchList.map((prop,i)=>{
                if(prop.isEnabled){
                    list.push(prop.bId)
                }
            });
            var data = {
                "id": this.state.userId,
                "firstName": this.state.profileName,
                "userName": this.state.userName,
                "email": this.state.emailID,
                "mobile": this.state.contactNo,
                "appUserGroupId": this.state.selectedApprole>0?this.state.selectedApprole:null,
                "userGroup": this.state.selectedWebrole,
                "isEnabled": this.state.activeStatusId,
                "companyId": getCookie(cons.COMPANY_ID),
                "cipher": getCookie(cons.CIPHER),
                "brancList":list,
                "suppCode":this.state.suppCode
            };
            if (this.state.userName !== '' && this.state.emailID !== "" && this.state.contactNo !== "" && (this.state.selectedApprole !== 0 || this.state.selectedWebrole !== 0) && this.state.activeStatus !== '') {
                this.handleLoading();
                requestList(serverApi.UPDATE_USER, data, this.handleAddIndent)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }

    };

    onCreateButtonClick=()=>{
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var list=[];
            this.state.branchList.map((prop,i)=>{
                if(prop.isEnabled){
                    list.push(prop.bId)
                }
            });
            var data = {
                "profileName": this.state.profileName,
                "userName": this.state.userName,
                "emailId": this.state.emailID,
                "conatctNum": this.state.contactNo,
                "appRoleId": this.state.selectedApprole,
                "webRoleId": this.state.selectedWebrole,
                "createdBy": getCookie(cons.USER_NAME),
                "companyId": getCookie(cons.COMPANY_ID),
                "cipher": getCookie(cons.CIPHER),
                "brancList":list,
                "suppCode":this.state.suppCode

            };
            if (this.state.profileName !== '' && this.state.userName !== "" && this.state.contactNo !== "" && (this.state.selectedApprole !== 0 || this.state.selectedWebrole !== 0)) {
                this.handleLoading();

                if (this.state.userSlected) {
                    data = {
                        "userId": this.state.userId,
                        "userGrpId": this.state.selectedWebrole,
                        "appGrpId": this.state.selectedApprole,
                        "companyId": getCookie(cons.COMPANY_ID),
                        "cipher": getCookie(cons.CIPHER)
                    };
                    requestList(serverApi.ADD_USER_MAP_GRP, data, this.handleAddIndent)

                } else {
                    requestList(serverApi.ADD_USER, data, this.handleAddIndent)
                }
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}

                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    showUserSelectionByCompany = (msg,status) => {
        this.setState({
            showUseralert: true
        });

    };

    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }

    handleSelectedValues = (defValue) => {
        this.setState(
            {
                activeStatus:defValue,
                activeStatusId:defValue.value,
            });
    };
    getActiveStatus = (defValue) => {
        if(defValue ==='0'){
            return { value: 0, label: 'InActive',name: 'InActive' }
        }else  if(defValue==='1'){
            return { value: 1, label: 'Active' ,name:'Active'}

        }else return""
    };
    afterRefreshUsers = (val) => {

        this.setState({
            loadUsers: val
        });
    };

    afterReloadSelect=(val)=>{
        this.setState({
            reloadSelect:val
        })
    };
    onCheckBranch= (index )=> event => {

        var arr=this.state.branchList;
        arr[index].isEnabled=!arr[index].isEnabled;
        this.setState({
            branchList:arr
        })

    };


    render(){
        const { classes } = this.props;
        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: 1, label: 'Active' ,name:'Active'},
            { value: 0, label: 'InActive',name: 'InActive' }

        ];
        const inputFocus={
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        {this.state.basicAlert}
                        {this.state.showMessagealert}
                        {this.state.showUseralert}
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.showUseralert?
                                        <Popup
                                        onClickSave={() => {
                                            if(this.state.userId!==''&& this.state.companyId!==''){
                                                    this.handleViewClick(this.state.userId);

                                            }
                                            else
                                                this.basicAlert("Please Select All Mandatory Fields..!!")
                                        }}
                                        closePopup={() => {
                                            this.hideAlert();

                                        }}
                                        isShowSave={true}
                                        isShowCancel={true}
                                        cancel_button_text={"Cancel"}
                                        save_btn_txt={"OK"}
                                        content={
                                            <GridContainer>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Company<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                // selectDisabled={true}
                                                                id={"companyId"}
                                                                onSelect={this.onSelect}
                                                                   defValue={this.state.companyId}
                                                                // getName={this.getDeptName}
                                                                stateKey="companyId"
                                                                //staticData={JSON.parse(getCookie(cons.COMPANIES_LIST))}
                                                                url={serverApi.COMPANY_LIST_BY_ORG_ID+"/"+getCookie(cons.ORG_ID)+"/"+getCookie(cons.CIPHER)}
                                                                value={this.state.companyId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                User Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                // selectDisabled={true}
                                                                id={"userId"}
                                                                onSelect={this.onSelect}
                                                                defValue={this.state.userId}
                                                                isRefresh={this.state.loadUsers}
                                                                setIsRefresh={this.afterRefreshUsers}
                                                                // getName={this.getDeptName}
                                                                stateKey="userId"
                                                                url={serverApi.GET_ALL_USERS_LIST_BY_COMPANY+this.state.companyId+"/"+getCookie(cons.CIPHER)}
                                                                value={this.state.userId}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>


                                            </GridContainer>
                                        }
                                    >
                                    </Popup>:null
                                    }

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    User Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={this.state.userId!==''}
                                                        id="userName"
                                                        className={classes.textField}
                                                        value={this.state.userName}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}
                                                    />
                                                {!this.state.updateView||this.state.userSlected?
                                                    <span style={{color:"red",float:"right",cursor:"pointer",fontWeight:400}} onClick={this.showUserSelectionByCompany}>Select user from group company</span>:null
                                                }




                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Profile Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                      disabled={getCookie(cons.COMPANY_ID)!==this.state.companyId+''}
                                                    id="profileName"
                                                    className={classes.textField}
                                                    value={this.state.profileName}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Email ID<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={getCookie(cons.COMPANY_ID)!==this.state.companyId+''}
                                                    id="emailID"
                                                    className={classes.textField}
                                                    value={this.state.emailID}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Contact No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={getCookie(cons.COMPANY_ID)!==this.state.companyId+''}
                                                    id="contactNo"
                                                    className={classes.textField}
                                                    value={this.state.contactNo}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    App Role<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"selectedApprole"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.selectedApprole}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                   // getName={this.getDeptName}
                                                    stateKey="selectedApprole"
                                                    url={serverApi.GET_ROLES_LIST_BY_TYPE+1+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.selectedApprole}
                                                />



                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                  Portal Role<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"selectedWebrole"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.selectedWebrole}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    // getName={this.getDeptName}
                                                    stateKey="selectedWebrole"
                                                    url={serverApi.GET_ROLES_LIST_BY_TYPE+0+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.selectedWebrole}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.selectedWebrole===100?<ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.suppCode}
                                                    id={"suppCode"}
                                                    onSelect={this.onSelect}
                                                    stateKey="suppCode"
                                                    // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID)}
                                                    isRefresh={this.state.loadSupplier}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadSupplier:false})
                                                    }}
                                                    staticData={this.state.supplierList}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}


                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                       Status<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <Select
                                                      //  isDisabled={this.state.updateView}
                                                        value={this.state.activeStatus}
                                                        name ={"activeStatus"}
                                                        onChange={this.handleSelectedValues}
                                                        options={options}
                                                        className={classes.selectFormControl}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: '4px',
                                                            colors: {
                                                                ...theme.colors,
                                                                //  primary25: '#00ABDC',
                                                                primary: '#00ABDC',
                                                                //   primary50:"#00ABDC"
                                                            },
                                                        })}
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                height: '30px',
                                                                'min-height': '30px',
                                                                marginBottom:"10px"
                                                            }),

                                                        }}
                                                    />



                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>
:null}

                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
                                    {this.state.updateView?
                                        <ItemGrid xs={12} sm={6}  style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
                                    <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={2}>
                                                <InputLabel className={classes.label}>
                                                    Branches:<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={10} >
                                                <GridContainer>

                                                    {this.state.branchList.map((prop,key)=>{
                                                        return(
                                                            <ItemGrid xs={12} sm={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            tabIndex={-1}
                                                                            value={prop.isEnabled?1:0}
                                                                            checked={prop.isEnabled?1:0}
                                                                            onChange={
                                                                                this.onCheckBranch(key)
                                                                            }
                                                                            // onClick={() => this.handleToggle(prop)}
                                                                            checkedIcon={
                                                                                <Check className={classes.checkedIcon} />
                                                                            }
                                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                                            classes={{
                                                                                checked: classes.checked
                                                                            }}
                                                                            style={{marginLeft:10}}

                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classes.menulabel
                                                                    }}
                                                                    label={prop.bName}
                                                                />

                                                            </ItemGrid>

                                                        )
                                                    })}
                                                </GridContainer>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>




                                    {!this.state.updateView || this.state.userSlected?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <br/>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
                                    {this.state.updateView&&!this.state.userSlected?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onUpdateButtonClick}>update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            cardTitle={
                                <div>
                                    <IconButton
                                        onClick={this.handleCreate}
                                        color="success" right={true}>
                                        <ExpandMoreIcon/>
                                    </IconButton>

                                </div>
                            }
                            content={
                                <div>
                                    <GridContainer >

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <TableComponent
                                                url={serverApi.GET_ALL_USERS_LIST}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showPasswordReset={true}
                                                onClickResetPassword={this.resetPassword}

                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch
};
export default  withStyles(extendedFormsStyle)(UserManagement);
