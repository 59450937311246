import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, getConfigValue, configNames} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import {getDataFromUrl, requestList} from "../../../server/server";
import moment from "moment";
import {getCookie} from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SamplePDF from "../../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import TextField from "../../../components/CustomInput/CustomTextField";


class ItemGroupStockReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate:moment().days(-6).format('DD-MM-YYYY'),
            todate: moment().format('DD-MM-YYYY'),
            status: '',
            isRefresh: false,
            isLoading: false,
            year: moment().format('YYYY'),
            dataTable:null,
            "itemCode":"","groupCode":"","itemDesc":""

        };

        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount(){
        this.getReportsTable();
    }
    getReportsTable=()=>{
        const {fromDate,groupCode,todate}=this.state;
        var data={"startDate":fromDate,"toDate":todate,"companyId":getCookie(cons.COMPANY_ID),"finYear": getCookie(cons.ACADEMIC_YEAR),"groupCode":groupCode};
        this.handleLoading();
        requestList(serverApi.ITEM_GROUP_STOCK_REPORT,data,this.handleReports)
    };
    handleReports = (resp) =>{
        // console.log("reports",JSON.stringify(resp));
        var dataTable1=null;

          if(resp.status===true){
               dataTable1=resp.data;
        this.setState({
            dataTable:dataTable1,
            isRefresh: true,
        });
        }
        this.handleLoading();

    };

    handleSeacrh = () => {
        var loading = false;
        if(!this.state.isRefresh)
            this.getReportsTable();
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }



    onChangeValues = (event) => {
        var value = event.target.value;
        this.setState({
            [event.target.id]: value
        });

    };








    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

    };

    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    //   closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height: "10px", inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id: "fromDate",
                                                                        value: this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime

                                                                    //  closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height: "10px", inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id: "toDate",
                                                                        value: this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>


                                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Item Group Code
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="groupCode"
                                                                value={this.state.groupCode}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>



                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <br/>
                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>



                                                <ReportsTableComponent
                                                    // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                                    staticData={this.state.dataTable}
                                                    //  searchData={this.getData()}
                                                    isRefresh={this.state.isRefresh}
                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                    // isLoading={this.state.isRefresh}
                                                    //  onActionClick={this.handleViewClick}
                                                    fileName={"StockViewReport"}
                                                    itemViewType={this.state.itemViewType}/>


                                        </ItemGrid>

                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }


}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(ItemGroupStockReport);
