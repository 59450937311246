import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import InputAdornment from "material-ui/Input/InputAdornment";
import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {
  configNames,
  getConfigValue,
  serverApi,
  serverConfig,
} from "../../helper/Consts";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import moment from "moment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import Select from "react-select";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import MultiSelect from "../CustumComponents/SelectboxMultiple";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import { GoodTypes } from "../TableData";

class StoreGateEntry extends React.Component {
  /*
        handleCreate = () => {
            //alert();
            console.log(this.state);
            this.setState({
                isCreated: !this.state.isCreated,
            });
        };
    */
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      vehicleNo: "",
      gateEntryNO: "",
      selectMukam: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      brokerId: "",
      poNo: "",
      isDisabled: false,
      outDateTime: "",
      itemViewType: "entryNo",
      updateView: false,
      showMessagealert: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      entryId: "",
      selectedPO: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      uom: "",
      branchId: "",
      chalanNo: "",
      chalanDate: "",
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      outDate: "",
      outTime: "",
      invoiceList: [],
      projectIds: "",
      branchIds: "",
      internalNote: "",
      goodType: "GRN",
      costDesc: "",
      costFactor: "",
      description: "",
      supplierList: [],
      filesList: [],
      make: ""
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  componentDidMount() {
    if (getCookie(cons.USER_ROLE_ID) !== "100")
      getDataFromUrl(
        serverApi.SELECT_SUPPLIER_STORE + getCookie(cons.COMPANY_ID),
        this.handleSuppliers
      );
  }
  handleSuppliers = (resp) => {
    this.setState({ supplierList: resp, loadSupplier: true });
  };
  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };

  hideAlert() {
    this.setState({
      id: 0,
      indentAlert: false,
      alert: false,
      loading: false,
      costAlert: false,
      showMessagealert: false,
      deptName: "",
      itemGrpName: "",
      itemName: "",
      uom: "",
      stock: "",
      rate: "",
      quantity: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      poNo: "",
      poLineItemId: "",
      costDesc: "",
      costFactor: "",
      description: "",
    });
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      if (this.state.goodType === "SRN")
        this.setState({
          costAlert: true,
          updateItemAlert: false,
        });
      else
        this.setState({
          alert: true,
          updateItemAlert: false,
        });
      //  this.hideAlert();
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      // data.splice(this.state.id, 1);
      if (this.state.updateView) data[this.state.id].isActive = 0;
      else data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      updateView: false,
      showMessagealert: false,
      isDisabled: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      selectedPO: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      uom: "",
      chalanNo: "",
      chalanDate: "",
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      invoiceList: [],
      outDateTime: "",
      id: 0,
      branchId: "",
      poLineItemId: "",
      consignmentNo: "",
      consignmentDate: "",
      inspectionChk: "",
      projectId: "",
      projectIds: "",
      branchIds: "",
      internalNote: "",
      goodType: "GRN",
      filesList: [],
    });
  }

  /*
        handleViewClick =(id)=>{
            // var data = this.state.dataTable;
            var juteId="";
            console.log("jute  "+"  "+id);
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL+id+"/"+getCookie(cons.USERID)+"/getIndentDetails",this.handleViewClickResp)
        };
    */
  /*
        <span style={{color:"red" ,fontSize:"12px"}}>*</span>(){
            var arr = this.state.invoiceList;
            /!*
                                                                            data.find((o,i) => {
                                                                                if(o.id === key){
                                                                                    // here you should add some custom code so you can delete the data
                                                                                    // from this component and from your server as well
                                                                                    data.splice(i,1);
                                                                                    return true;
                                                                                }
                                                                                return false;
                                                                            });
            *!/

            arr.splice(arr.length, 0, this.state.addItemObj);
            console.log(arr.toString());

            this.setState({
                loading:true,
                invoiceList:arr
            });
            this.hideAlert();
        }
    */
  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      supplierCode: this.state.supplierId,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      erpType: getConfigValue(configNames.LABELS_TYPE),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
    };
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };
  //dateFormate
  dateChanged = (myDate) => {
    if (myDate !== null && myDate !== undefined && myDate !== "") {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };
  timeChangedFormat = (myDate, time) => {
    console.log("time", time);
    // var time = new Date(myDate+" "+time).getTime();
    //var localDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2], timeParts[0], timeParts[1]);
    // console.log("time",time)
    myDate = myDate.split("-");
    var timeParts = time.split(":");
    // var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2] + timeParts[0]+ timeParts[1]+timeParts[2];
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    this.setState({
      [event.target.id]: value,
    });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
        loadItem: true,
      });
    }
    if (stateKey === "branchId" || stateKey === "branchIds") {
      this.setState({ loadProject: true });
    }
    if (stateKey === "selectedItemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: getCookie(cons.COMPANY_ID),
      };

      requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
      // this.setState({
      //     loadItem:true
      // })
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
      loadItem: val,
    });
  };
  getSupplierName = (selectedValue) => {
    var name = selectedValue.split("_");
    name = name[0];
    this.setState({
      supplierName: name,
    });
  };
  getDeptName = (selectedValue) => {
    this.setState({
      deptName: selectedValue,
    });
  };

  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    if (selectedValue !== null) {
      var values = selectedValue;
      values = values.split("^");
      this.setState({
        itemName: values[0],
        // selectedItemId:values[1],
        uom: values[1],
        stock: values[2],
        rate: this.state.itemName === values[0] ? this.state.rate : values[3],
      });
    }
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_STORE_GATE_ENTRY + id + "/" + getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };

  getDefaultChalanType = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== "" && name !== null) {
      val = 1;
      label = "With PO";
    } else {
      val = 2;
      label = "Without PO";
    }
    data = {
      value: val,
      label: label,
    };
    return data;
  };

  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    var header = resp.entryHeader;
    var list = resp.gateStoreLineItems;
    var indentlist = [],
      poNo = [],
      indentNo = [];
    list.forEach((prop, key) => {
      if (poNo.indexOf(prop.poHdrid) <= -1 && prop.poHdrid) {
        poNo.push(prop.poHdrid);
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "14/" +
            prop.poHdrid +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleInvoiceFiles
        );
      }
      if (indentNo.indexOf(prop.indentHdrid) <= -1 && prop.indentHdrid) {
        indentNo.push(prop.indentHdrid);
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "10/" +
            prop.indentHdrid +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleInvoiceFiles
        );
      }

      var obj = {
        id: key,
        lineId: prop.dtlId,
        department: prop.deptDesc,
        itemGroup: prop.itemGroupDesc,
        item: prop.itemDesc,
        quantity: prop.quantity,
        poQuantity: parseFloat(prop.poQuantity) + parseFloat(prop.quantity),
        unit: prop.uom,
        //stockValue: prop.stock,
        deptId: parseInt(prop.dept),
        itemGroupId: prop.itemGroupId,
        itemId: prop.itemCode,
        itemGrp: prop.itemGroupId + "" + prop.itemCode,
        poNo: prop.poNo,
        rate: prop.rate,
        poLineItemId: prop.poLineItemId,
        isActive: prop.deleteRec ? 0 : 1,
        costDesc: prop.costDesc,
        costFactor: prop.costFactor,
        description: prop.description,
        /*
                                    actions: (
                                        // we've added some custom button actions

                                        <div className="actions-right">
                                            <IconButton
                                                onClick={() => {
                                                    var data = this.state.invoiceList;
                                                    data.find((o,i) => {
                                                        if(o.id === key){
                                                            this.basicItemAlert("Do You Like To Update This Item?");
                                                            // here you should add some custom code so you can delete the data
                                                            // from this component and from your server as well
                                                            this.setState({
                                                              //  alert:true,
                                                                id:key,
                                                                updateItemAlert:true,
                                                                lineId:o.lineId,
                                                                updateItem: true,
                                                                deptName:o.department,
                                                                itemGrpName:o.itemGroup,
                                                                itemName:o.item,
                                                                uom:o.unit,
                                                                stock: o.stockValue,
                                                                quantity:o.quantity,
                                                                selectedDepartment: o.deptId,
                                                                selectedItemGroup:o.itemGroupId,
                                                                selectedItem:o.itemId,
                                                                poNo:o.poNo,
                                                                rate:o.rate,
                                                                poQuantity:o.poQuantity,
                                                                poLineItemId:o.poLineItemId,
                                                                loadItem:true,
                                                                loadItemGroup:true
                                                            });
                                                            return true;
                                                        }
                                                    });
                                                }}
                                                color="primaryNoBackground"
                                                customClass="remove">
                                                <Edit />
                                            </IconButton>{" "}

                                            { /!* use this button to remove the data row *!/ }
                                            <IconButton
                                                onClick={() => {
                                                    var data = this.state.invoiceList;
                                                    data.find((o,i) => {
                                                        if(o.id === key){
                                                            // here you should add some custom code so you can delete the data
                                                            // from this component and from your server as well
                                                            //if (this.state.approveStatus) {

                                                            this.setState({deleteItem: true,
                                                                id:key,});

                                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                                            //  data.splice(i,1);
                                                            // }
                                                            return true;
                                                        }
                                                    });
                                                    this.setState({invoiceList: data});
                                                }}
                                                color="dangerNoBackground"
                                                customClass="remove">
                                                <Close />
                                            </IconButton>{" "}
                                        </div>

                                    )
                */
      };
      //  if(!prop.deleteRec)
      indentlist.push(obj);
    });
    //  console.log("indentWeight "+header.indentWeight);
    if (header.poNo !== "") var poNo = header.poNo;

    if (header !== null) {
      this.setState({
        updateItem: false,
        updateView: true,
        isCreated: true,
        isDisabled: true,
        approveStatus: header.approveStatus,
        entryId: header.hdrId,
        chalanNo: header.challanNo,
        chalanDate: header.challanDateDesc,
        vehicleNum: header.vehicleNo,
        driverName: header.driverName,
        selectedSupplier: header.suppCode,
        supplierName: header.supplierName,
        remarks: header.remarks,
        date1: header.createDateDesc,
        time2: header.createdTimeDesc,
        outDate: header.outDateDesc,
        outTime: header.outTimeDesc,
        outDateTime: header.outDateDesc + header.outTimeDesc,
        selectedPO: poNo,
        poDates:
          header.podatedescription !== "" ? header.podatedescription : [],
        createdBy: header.createdBy,
        invoiceList: indentlist,
        SelectedChalanType: this.getDefaultChalanType(header.poNo),
        consignmentNo: header.consignmentNo,
        consignmentDate: header.consignmentDateDesc,
        branchId: header.branchId,
        inspectionChk: header.inspectionChk,
        projectId: header.projectId,
        internalNote: header.internalNote,
        goodType: header.goodType,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "18/" +
          header.hdrId +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };
  //out
  onOutButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          dtlId: prop.lineId,
          itemCode: prop.itemId,
          itemDesc: prop.item,
          itemGroupId: prop.itemGroupId,
          // qualityCode:prop.quality,
          dept: prop.deptId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          //stock:prop.stockValue,
          quantity: Number(prop.quantity),
          uom: prop.unit,
          poLineItemId: prop.poLineItemId,
          poNo: prop.poNo,
          deleteRec: prop.isActive === 0,
          costFactor: prop.costFactor,
          description: prop.description,
        };
      });

      var data = {
        entryHeader: {
          hdrId: this.state.entryId,
          challanNo: this.state.chalanNo,
          challanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          remarks: this.state.remarks,
          finYear: this.state.year,
          consignmentNo: this.state.consignmentNo,
          consignmentDate: this.dateChanged(this.state.consignmentDate),
          branchId: this.state.branchId,
          projectId: this.state.projectId,
          /*inDate: this.dateChanged(this.state.date1),
                    inTime: this.timeChangedFormat(this.state.date1, this.state.time2),*/
          outDate: this.dateChanged(this.state.outDate),
          outTime: this.timeChangedFormat(this.state.outDate, this.state.time2),
          //  pos: this.state.selectedPO,
          updateBy: getCookie(cons.USER_NAME),
          internalNote: this.state.internalNote,
          companyId: getCookie(cons.COMPANY_ID),
        },
        gateStoreLineItems: invoiceList,
      };
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== ""
      ) {
        if (
          this.state.invoiceList.length >= 1 &&
          this.state.outDate !== "" &&
          this.state.outTime &&
          branchId !== "" &&
          branchId !== 0 &&
          projectId !== "" &&
          projectId !== 0
        ) {
          this.handleLoading();
          requestList(
            serverApi.UPDATE_STORE_GATE_ENTRY,
            data,
            this.handleAddStoreGate
          );
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        } else {
          this.basicAlert("Please Enter Out Date & Time..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  //approve
  onApproveButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;

      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          dtlId: prop.lineId,
          itemCode: prop.itemId,
          itemDesc: prop.item,
          itemGroupId: prop.itemGroupId,
          // qualityCode:prop.quality,
          dept: prop.deptId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          //stock:prop.stockValue,
          quantity: Number(prop.quantity),
          uom: prop.unit,
          poLineItemId: prop.poLineItemId,
          poNo: prop.poNo,
          deleteRec: prop.isActive === 0,
          costFactor: prop.costFactor,
          description: prop.description,
        };
      });

      var data = {
        entryHeader: {
          hdrId: this.state.entryId,
          challanNo: this.state.chalanNo,
          challanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          remarks: this.state.remarks,
          branchId: this.state.branchId,
          inDate: this.dateChanged(this.state.date1),
          inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
          finYear: this.state.year,
          consignmentNo: this.state.consignmentNo,
          consignmentDate: this.dateChanged(this.state.consignmentDate),
          /* outDate:this.dateChanged(this.state.outDate),
                     outTime:this.timeChangedFormat(this.state.outDate,this.state.time2),*/
          // pos: this.state.selectedPO,
          updateBy: getCookie(cons.USER_NAME),
          projectId: this.state.projectId,
          internalNote: this.state.internalNote,
          companyId: getCookie(cons.COMPANY_ID),
          goodType: this.state.goodType,
        },
        gateStoreLineItems: invoiceList,
      };
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== ""
      ) {
        if (this.state.invoiceList.length >= 1) {
          this.handleLoading();
          requestList(
            serverApi.UPDATE_STORE_GATE_ENTRY,
            data,
            this.handleAddStoreGate
          );
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          lineId: 0,
          itemCode: prop.itemId,
          itemDesc: prop.item,
          itemGroupId: prop.itemGroupId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          // qualityCode:prop.quality,
          dept: prop.deptId,
          //stock:prop.stockValue,
          quantity: Number(prop.quantity),
          uom: prop.unit,
          poNo: prop.poNo,
          poLineItemId: prop.poLineItemId,
          costFactor: prop.costFactor,
          description: prop.description,
          make: prop.make
        };
      });

      var data = {
        entryHeader: {
          challanNo: this.state.chalanNo,
          challanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          remarks: this.state.remarks,
          branchId: this.state.branchId,
          inDate: this.dateChanged(this.state.date1),
          inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
          finYear: this.state.year,
          //   poNo: this.state.selectedPO,
          createdBy: getCookie(cons.USER_NAME),
          companyId: getCookie(cons.COMPANY_ID),
          consignmentNo: this.state.consignmentNo,
          consignmentDate: this.dateChanged(this.state.consignmentDate),
          pos: this.state.selectedPO !== "" ? this.state.selectedPO : [],
          poDates: this.state.poDates !== "" ? this.state.poDates : [],
          projectId: projectId,
          internalNote: this.state.internalNote,
          goodType: this.state.goodType,
        },
        gateStoreLineItems: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      console.log("dataaaa", data);
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== "" &&
        branchId !== "" &&
        branchId !== 0 &&
        projectId !== "" &&
        projectId !== 0
      ) {
        if (this.state.invoiceList.length >= 1) {
          /* if (this.state.selectedPO !== "") {*/
          this.handleLoading();
          requestList(
            serverApi.ADD_STORE_GATE_ENTRY,
            data,
            this.handleAddStoreGate
          );
          /*} else {
                    this.onCreatePO();
                }*/
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  handleAddStoreGate = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  onCreatePO = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: "",
          quantity: prop.quantity,
          unitId: prop.unit,
          rate: prop.rate,
          allowableMoisturePercentage: "",
          cTax: "",
          sTax: "",
          iTax: "",
          bale: "",
          loose: "",
          amount: parseFloat(prop.quantity) * parseFloat(prop.rate),
          marka: "",
          deptId: prop.depId,
          valueWithoutTax: parseFloat(prop.quantity) * parseFloat(prop.rate),
          valueWithTax: "",
          tax: "",
          indentId: "",
          actualQuantity: "",
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
        };
      });

      var data = {
        poHeader: {
          type: "S",
          poDate: this.state.date1,
          creditTerm: 60,
          deliveryTimeline: 15,
          vehicleTypeId: "",
          vehicleQuantity: "",
          supplierId: this.state.selectedSupplier,
          mukam: "",
          brokerId: "",
          frieghtCharge: "",
          remarks: this.state.remarks,
          juteUnit: "",
          exceptedDate: moment()
            .add("days", 15)
            .format("DD-MM-YYYY"),
          cTax: "",
          sTax: "",
          iTax: "",
          taxType: "",
          valueWithoutTax: "",
          submitter: getCookie(cons.USERID),
          indentNum: "",
          finnacialYear: this.state.year,
          footerNote: "",
          valueWithTax: "",
          tax: "",
          deliveryAddress: "",
          discount: "",
          weight: 0,
          brokerName: "",
          companyId: getCookie(cons.COMPANY_ID),
        },
        poLineItem: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      console.log(data);
      if (
        this.state.invoiceList.length >= 1 &&
        this.state.supplier_Id !== "" &&
        this.state.taxType !== "" &&
        this.state.form_purchaseType !== "" &&
        this.state.totalTax !== "" &&
        this.state.totalAmount !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_JUTE_PO, data, this.handlePOcreate);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handlePOcreate = (resp) => {
    if (resp.status) {
      this.setState({
        selectedPO: resp.id,
      });
      if (!getMenuPermission(2)) {
        this.basicAlert("Access Denied. Please contact administrator!!!");
      } else {
        const {
          vehicleNum,
          driverName,
          SelectedChalanType,
          selectedSupplier,
        } = this.state;
        var invoiceList = this.state.invoiceList.map((prop, key) => {
          return {
            lineId: 0,
            itemCode: prop.itemId,
            itemDesc: prop.item,
            itemGroupId: prop.itemGroupId,
            rate: prop.rate,
            // qualityCode:prop.quality,
            dept: prop.deptId,
            //stock:prop.stockValue,
            quantity: prop.quantity,
            uom: prop.unit,
            poNo: resp.id,
          };
        });

        var data = {
          entryHeader: {
            challanNo: this.state.chalanNo,
            challanDate: this.dateChanged(this.state.chalanDate),
            vehicleNo: this.state.vehicleNum,
            driverName: this.state.driverName,
            suppCode: this.state.selectedSupplier,
            supplierName: this.state.supplierName,
            remarks: this.state.remarks,
            inDate: this.dateChanged(this.state.date1),
            inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
            poNo: resp.id,
            createdBy: getCookie(cons.USER_NAME),
            companyId: getCookie(cons.COMPANY_ID),
          },
          gateStoreLineItems: invoiceList,
          cipher: getCookie(cons.CIPHER),
        };
        if (
          SelectedChalanType !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== 0 &&
          selectedSupplier !== ""
        ) {
          if (this.state.invoiceList.length >= 1) {
            //  this.handleLoading();
            requestList(
              serverApi.ADD_STORE_GATE_ENTRY,
              data,
              this.handleAddStoreGate
            );
          } else if (this.state.invoiceList.length < 1) {
            this.basicAlert("Please Add Atleast One Item..!!");
          }
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      }
    }
  };

  handleAddItem() {
    // const {classes}=this.props;
    const {
      vehicleNum,
      driverName,
      SelectedChalanType,
      selectedSupplier,
      goodType,
    } = this.state;
    // console.log("unitConversionName "+ this.state.unitConvertion);
    //  var id="";
    console.log(this.state);
    if (
      SelectedChalanType !== 0 &&
      SelectedChalanType !== "" &&
      selectedSupplier !== 0 &&
      selectedSupplier !== "" &&
      goodType !== null
    ) {
      if (goodType === "SRN") {
        this.setState({ costAlert: true });
      } else
        this.setState({
          loading: true,
          alert: true,
        });
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  onClickAddItem() {
    var arr = this.state.invoiceList;

    var itemIndex = 0,
      hasItem = false,
      lineId = 0;

    if (this.state.updateItem) {
      itemIndex = this.state.id;
      lineId = this.state.lineId;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    arr.map((q) => {
      if (q.item === this.state.itemName && !this.state.updateItem)
        hasItem = true;
    });
    var addItemObj = {
      id: itemIndex,
      lineId: lineId,
      department: this.state.deptName,
      itemGroup: this.state.itemGrpName,
      item: this.state.itemName,
      quantity: this.state.quantity,
      unit: this.state.uom,
      stockValue: this.state.stock,
      rate: this.state.rate,
      deptId: this.state.selectedDepartment,
      itemGroupId: this.state.selectedItemGroup,
      itemId: this.state.selectedItem,
      itemGrp: this.state.selectedItemGroup + "" + this.state.selectedItem,
      poNo: this.state.poNo,
      poLineItemId: this.state.poLineItemId,
      poQuantity: this.state.poQuantity,
      isActive: 1,
      costDesc: this.state.costDesc,
      costFactor: this.state.costFactor,
      description: this.state.description,
      make: this.state.make,
    };

    const {
      selectedDepartment,
      selectedItemGroup,
      selectedItem,
      quantity,
      poQuantity,
      updateView,
      SelectedChalanType,
      poNo,
      goodType,
      rate,
      uom,
    } = this.state;
    if (!hasItem) {
      if (goodType === "SRN") {
        if (quantity !== "" && rate !== "" && uom !== "") {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            loading: true,
            invoiceList: arr,
            updateItem: false,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Please Select All Mandatory Fields..!!");
        }
      } else {
        if (
          selectedDepartment !== "" &&
          selectedItemGroup !== "" &&
          selectedItem !== "" &&
          quantity !== "" &&
          quantity > 0
        ) {
          if (SelectedChalanType.value === 1 && poNo !== "" && poNo !== null) {
            if (parseFloat(quantity) > parseFloat(poQuantity)) {
              this.basicAlert(
                "Quantity Should Not Be Greater Than PO Quantity " +
                  poQuantity +
                  "..!!"
              );
            } else {
              if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
              } else arr.splice(arr.length, 0, addItemObj);

              this.setState({
                loading: true,
                invoiceList: arr,
                updateItem: false,
              });
              this.hideAlert();
            }
          } else {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);

            this.setState({
              loading: true,
              invoiceList: arr,
              updateItem: false,
            });
            this.hideAlert();
          }
        } else if (quantity < 0 || quantity == 0) {
          this.basicAlert(
            "Do not enter Negative values & Zero values in Quantity..!!"
          );
        } else {
          this.basicAlert("Please Select All Mandatory Fields..!!");
        }
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This item Group. Please Select Another One..!!"
      );
    }
  }

  //invoiceTableSize
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  //addItem
  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  //onselect challan
  handleSelectedValues = (selectedValue) => {
    //const {classes}=this.props;

    // const {selectedJuteType}=this.state;
    if (selectedValue.value === 1)
      this.setState({
        loading: true,
        indentAlert: true,
      });

    this.setState({
      SelectedChalanType: selectedValue,
    });

    //console.log(`Option selected:`, defValue);
  };

  indenthideAlert() {
    this.setState({
      indentAlert: false,
      loading: false,
      SelectedChalanType: "",
    });
  }

  afterSelectPO = () => {
    // var data = this.state.dataTable;
    var juteId = "";
    var data = {
      poNo: this.state.selectedPO,
    };
    console.log("jute  " + "  " + this.state.selectedPO);
    requestList(serverApi.GET_PO_LINE_ITEMS, data, this.handlePOResp);
    // getDataFromUrl(serverApi.GET_PO_DETAILS+this.state.selectedPO+"/"+getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER),this.handlePOResp)
  };

  handlePOResp = (resp) => {
    console.log("resp", resp);
    // var header = resp.poHeader;
    var list = resp;
    var indentlist = [];
    list.map((prop, key) => {
      if (prop.remaingQuantity > 0)
        indentlist.push({
          id: key,
          //dataId:prop.itemId,
          department: prop.deptName,
          itemGroup: prop.itemGroupName,
          item: prop.itemDesc,
          itemGrp: prop.itemGroupId + "" + prop.itemId,
          poQuantity: prop.remaingQuantity,
          quantity: prop.remaingQuantity,
          unit: prop.unitId,
          rate: prop.rate,
          // stockValue: prop.stock,
          deptId: prop.deptId,
          itemGroupId: prop.itemGroupId,
          itemId: prop.itemId,
          poNo: prop.poId,
          poLineItemId: prop.id,
          isActive: 1,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.basicItemAlert("Do You Like To Update This Item?");
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      this.setState({
                        // alert:true,
                        id: o.id,
                        updateItemAlert: true,
                        updateItem: true,
                        deptName: o.department,
                        itemGrpName: o.itemGroup,
                        itemName: o.item,
                        uom: o.unit,
                        stock: o.stockValue,
                        quantity: o.quantity,
                        selectedDepartment: o.deptId,
                        selectedItemGroup: o.itemGroupId,
                        selectedItem: o.itemId,
                        poNo: o.poNo,
                        rate: o.rate,
                        poLineItemId: o.poLineItemId,
                        poQuantity: o.poQuantity,
                        loadItem: true,
                        loadItemGroup: true,
                      });
                      return true;
                    }
                  });
                }}
                color="primaryNoBackground"
                customClass="remove"
              >
                <Edit />
              </IconButton>
              {/* use this button to remove the data row */}
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.setState({
                        // alert:true,
                        id: o.id,
                        deleteItem: true,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");

                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      // data.splice(i,1);
                      return true;
                    }
                  });
                  this.setState({ invoiceList: data });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            </div>
          ),
        });
    });
    //   console.log("indentWeight "+header.supplierId);
    // if(header!== null){
    this.setState({
      loading: false,
      indentAlert: false,
      reloadSelect: true,
      //   selectedSupplier:header.supplierId,
      //  selectedPO:header.id,
      invoiceList: indentlist,
    });

    //  }
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      poStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(poStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        18,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        this.basicItemAlert("Do You Like To Update This Item?");
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          //  alert:true,
                          id: key,
                          updateItemAlert: true,
                          lineId: o.lineId,
                          updateItem: true,
                          deptName: o.department,
                          itemGrpName: o.itemGroup,
                          itemName: o.item,
                          uom: o.unit,
                          stock: o.stockValue,
                          quantity: o.quantity,
                          selectedDepartment: o.deptId,
                          selectedItemGroup: o.itemGroupId,
                          selectedItem: o.itemId,
                          poNo: o.poNo,
                          rate: o.rate,
                          poQuantity: o.poQuantity,
                          poLineItemId: o.poLineItemId,
                          loadItem: true,
                          loadItemGroup: true,
                          costDesc: o.costDesc,
                          costFactor: o.costFactor,
                          description: o.description,
                        });
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="remove"
                >
                  <Edit />
                </IconButton>{" "}
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        //if (this.state.approveStatus) {

                        this.setState({
                          deleteItem: true,
                          id: key,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        //  data.splice(i,1);
                        // }
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </div>
            ),
          };
          data.push(obj);
        }
      });
    return data;
  };

  render() {
    const { classes } = this.props;
    const { selectedPO } = this.state;
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");

    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);

    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };
    const inputFocus = {
      autoComplete: "off",
    };
    var options = [
      { value: 0, label: "Select..." },
      { value: 1, label: "With PO" },
      { value: 2, label: "Without PO" },
    ];
    var isoutButton = getConfigValue(configNames.GATE_ENTRY_OUT);
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    var withoutpo = getConfigValue(configNames.GATE_ENTRY_WITHOUT_PO);
    if (withoutpo === "2") {
      options = [
        { value: 0, label: "Select..." },
        { value: 1, label: "With PO" },
      ];
    }

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {//get indent alert
                  this.state.indentAlert ? (
                    <Popup
                      onClickSave={() => this.afterSelectPO()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        PO List
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <MultiSelect
                                        id={"selectedPO"}
                                        defValue={selectedPO}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          var dates = name;
                                          console.log("dates", dates);
                                          if (dates)
                                            dates = dates.map((prop) => {
                                              var val = prop.split("^");
                                              return val[1];
                                            });
                                          this.setState({ poDates: dates });
                                        }}
                                        stateKey="selectedPO"
                                        postData={{
                                          supid: this.state.selectedSupplier,
                                          companyId: getCookie(cons.COMPANY_ID),
                                          branchId: this.state.branchId,
                                          projectId:
                                            this.state.projectId > 0
                                              ? this.state.projectId
                                              : "",
                                        }}
                                        url={serverApi.GET_PO_BY_SUPPLIER}
                                        value={selectedPO}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.costAlert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Cost Factor
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    id={"costFactor"}
                                    onSelect={this.onSelect}
                                    defValue={this.state.costFactor}
                                    getName={(name) => {
                                      this.setState({ costDesc: name });
                                    }}
                                    stateKey="costFactor"
                                    url={
                                      serverApi.SEARCH_COST_FACTOR +
                                      getCookie(cons.COMPANY_ID) +
                                      "/" +
                                      getCookie(cons.CIPHER)
                                    }
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    decimal={3}
                                    iType={"number"}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    UOM
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="uom"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.uom}
                                    fullWidth={true}
                                    variant="outlined"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={3}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Description
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="description"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    height={60}
                                    value={this.state.description}
                                    onChange={this.onChangeValues}
                                    className={classes.textField}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Department
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={
                                          this.state.SelectedChalanType
                                            .value === 1
                                        }
                                        id={"selectedDepartment"}
                                        onSelect={this.onSelect}
                                        defValue={this.state.selectedDepartment}
                                        getName={this.getDeptName}
                                        stateKey="selectedDepartment"
                                        url={
                                          serverApi.SEARCH_DEPATMENTS +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.selectedDepartment}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={
                                          this.state.SelectedChalanType
                                            .value === 1
                                        }
                                        defValue={this.state.selectedItemGroup}
                                        id={"selectedItemGroup"}
                                        onSelect={this.onSelect}
                                        getName={this.getItemGroupName}
                                        isRefresh={this.state.loadItemGroup}
                                        stateKey="selectedItemGroup"
                                        setIsRefresh={this.afterRefreshItemGrp}
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.selectedDepartment +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/getAllItemGroupsByDepartmentId/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.selectedItemGroup}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} md={12}>
                              <br />
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={
                                          this.state.SelectedChalanType
                                            .value === 1
                                        }
                                        defValue={this.state.selectedItem}
                                        id={"selectedItem"}
                                        onSelect={this.onSelect}
                                        staticData={this.state.ItemOptions}
                                        getName={this.getItemDetailsByName}
                                        isRefresh={this.state.loadItem}
                                        stateKey="selectedItem"
                                        setIsRefresh={this.afterRefreshItem}
                                        //   url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                        value={this.state.selectedItem}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Make
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        selectDisabled={
                                          this.state.SelectedChalanType
                                            .value === 1
                                        }
                                        defValue={this.state.make}
                                        id={"make"}
                                        onSelect={this.onSelect}
                                        staticData={this.state.make}
                                        getName={this.getItemDetailsByName}
                                        isRefresh={this.state.loadItem}
                                        stateKey="make"
                                        setIsRefresh={this.afterRefreshItem}
                                        //   url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                        value={this.state.make}
                                        onChange={this.onChangeValues}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.quantity}
                                        fullWidth={true}
                                        variant="outlined"
                                        decimal={3}
                                        iType={"number"}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Time
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            defaultValue={new Date()}
                            dateFormat={false}
                            onChange={(timeObj) => {
                              this.timeChanged1(timeObj, "time1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "time1",
                              value: this.state.time1,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="chalanNo"
                          className={classes.textField}
                          value={this.state.chalanNo}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "chalanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "chalanDate",
                              value: this.state.chalanDate,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="vehicleNum"
                          // className={classes.textField}
                          value={this.state.vehicleNum}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Driver Name
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="driverName"
                          className={classes.textField}
                          value={this.state.driverName}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Phase" : "Project"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.isDisabled}
                          url={
                            serverApi.PROJECT_MASTER_SELECTION_LIST +
                            getCookie(cons.COMPANY_ID) +
                            "/customer/0/branch/" +
                            this.state.branchId
                          }
                          defValue={this.state.projectId}
                          // staticData={this.state.ProjectOptions}
                          onSelect={this.onSelect}
                          stateKey="projectId"
                          value={this.state.projectId}
                          isRefresh={this.state.loadProject}
                          setIsRefresh={(val) => {
                            this.setState({ loadProject: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedSupplier"}
                            selectDisabled={
                              this.state.updateView &&
                              this.state.SelectedChalanType.value !== 2 &&
                              this.state.outDateTime === ""
                            }
                            defValue={this.state.selectedSupplier}
                            isReload={this.state.reloadSelect}
                            setIsReload={this.afterReloadSelect}
                            onSelect={this.onSelect}
                            getName={this.getSupplierName}
                            stateKey="selectedSupplier"
                            staticData={this.state.supplierList}
                            isRefresh={this.state.loadSupplier}
                            setIsRefresh={(val) => {
                              this.setState({ loadSupplier: false });
                            }}
                            // url={serverApi.SELECT_SUPPLIER_STORE + getCookie(cons.COMPANY_ID)}
                            value={this.state.selectedSupplier}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateView}
                          defValue={this.state.SelectedChalanType}
                          name={"SelectedChalanType"}
                          value={this.state.SelectedChalanType}
                          onChange={this.handleSelectedValues}
                          options={options}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                              marginBottom: "10px",
                            }),
                          }}
                        />
                        <span>{this.state.selectedPO}</span>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {labelType !== "2" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Consignment Date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              //  isValidDate={valid}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "consignmentDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "consignmentDate",
                                value: this.state.consignmentDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {labelType !== "2" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Consignment No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            //  disabled={true}
                            id="consignmentNo"
                            // className={classes.textField}
                            value={this.state.consignmentNo}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView && isoutButton !== "2" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Out Date & Time
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={4}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              defaultValue={new Date()}
                              closeOnSelect={true}
                              isValidDate={valid}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "outDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "outDate",
                                value: this.state.outDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={4}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              defaultValue={new Date()}
                              dateFormat={false}
                              onChange={(timeObj) => {
                                this.timeChanged1(timeObj, "outTime");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "outTime",
                                value: this.state.outTime,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Remarks
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="remarks"
                          // label="Multiline"
                          fullWidth
                          multiline={true}
                          rowsMax="3"
                          value={this.state.remarks}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                          height={60}
                          // helperText="hello"
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Goods Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"goodType"}
                          selectDisabled={
                            this.state.updateView ||
                            this.state.SelectedChalanType.value === 1
                          }
                          defValue={this.state.goodType}
                          onSelect={this.onSelect}
                          stateKey="goodType"
                          staticData={GoodTypes}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {/* <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Out Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="" fullWidth>
                                                        <Datetime
                                                            defaultValue={new Date()}
                                                            dateFormat={false}
                                                            onChange={(timeObj) => {
                                                                this.timeChanged1(timeObj, "outTime")
                                                            }}
                                                            height={"15px"}
                                                            formControlProps={
                                                                {height: "15px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id:"outTime",
                                                                value:this.state.outTime,
                                                                autoComplete: 'off'
                                                            }}

                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>:null
*/}

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {/* {console.log(this.state.invoiceList.length+" length")}*/}
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          disableActions={this.state.inspectionChk === "Y"}
                          columns={
                            this.state.goodType === "SRN"
                              ? [
                                  {
                                    Header: "Cost Factor",
                                    accessor: "costDesc",
                                    minWidth: 100,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Description",
                                    accessor: "description",
                                    minWidth: 80,
                                    style: { color: "#000", textAlign: "left" },
                                  },

                                  {
                                    Header: "Quantity",
                                    accessor: "quantity",
                                    minWidth: 80,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Unit",
                                    accessor: "unit",
                                    width: 80,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Rate",
                                    accessor: "rate",
                                    width: 50,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                    style: {
                                      color: "#000",
                                      textAlign: "center",
                                    },
                                    sortable: false,
                                    filterable: false,
                                  },
                                ]
                              : [
                                  {
                                    Header: "Item Code",
                                    accessor: "itemGrp",
                                    minWidth: 50,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "PO No",
                                    accessor: "poNo",
                                    minWidth: 80,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Department",
                                    accessor: "department",
                                    minWidth: 100,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Item Group",
                                    accessor: "itemGroup",
                                    minWidth: 100,
                                    style: { color: "#000", textAlign: "left" },
                                  }, 
                                  {
                                  
                                      id: "item",
                                      Header: "Item",
                                      accessor: row => <>
                                      <span style={{fontStyle: "bold"}}>{`${row.item}`}</span><br></br>
                                      {this.state.make !== "" && this.state.make!== null ? 
                                        <><span style={{fontStyle: "italic", color: "#00ABDC"}}>Make:- {`${row.make}`}</span><br></br>
                                      </> : null}
                                      
                                    </>,
                                    filterMethod: (filter, row) =>
                                      row._original.itemDesc.startsWith(filter.value) ||
                                      row._original.make.startsWith(filter.value),
                                      minWidth: 200,
                                      
                                    style: {
                                      color: "#000",
                                      textAlign: "left",
                                      textOverflow: "unset",
                                    },
                                  },
                                  // {
                                  //   Header: "Make",
                                  //   accessor: "make",
                                  //   minWidth: 170,
                                  //   style: {
                                  //     color: "#000",
                                  //     textAlign: "left",
                                  //     textOverflow: "unset",
                                  //   },
                                  // },
                                  {
                                    Header: "Quantity",
                                    accessor: "quantity",
                                    minWidth: 70,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Unit",
                                    accessor: "unit",
                                    width: 50,
                                    style: { color: "#000", textAlign: "left" },
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                    style: {
                                      color: "#000",
                                      textAlign: "center",
                                    },
                                    sortable: false,
                                    filterable: false,
                                  },
                                ]
                          }
                        />
                      </ItemGrid>
                      {/*           {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={6}>
                                                            <span style={{fontStyle:"italic",fontWeight:400,color:"#003F66"}}>Status:</span>
                                                            <span style={{fontSize:"12px",color:"#003F66"}}>{this.state.statusDesc}<br/></span>
                                                            <span style={{fontStyle:"italic",fontWeight:400,color:"red"}}>Note:</span>
                                                            <span style={{fontSize:"12px",color:"red",fontStyle:"italic"}}>{"Indent Type can't be modified"}</span>
                                                        </ItemGrid>
                                                        <ItemGrid xs={6}>
                                                            <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                :*/}
                      <ItemGrid xs={12}>
                        {this.state.inspectionChk !== "Y" &&
                        this.state.SelectedChalanType.value === 2 ? (
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        ) : null}
                      </ItemGrid>

                      {/* }*/}

                      {/*
                                            <ItemGrid xs={12}
                                                      style={{height: 1, backgroundColor: "#00acc1", marginTop: "10px"}}/>
*/}
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} md={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Internal Note
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="internalNote"
                              fullWidth
                              multiline
                              rowsMax="3"
                              height={60}
                              value={this.state.internalNote}
                              onChange={this.onChangeValues}
                              className={classes.textField}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView && this.state.outDateTime === "" ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            style={{ width: 80 }}
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            In
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button
                            style={{ width: 80 }}
                            color="danger"
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView && this.state.outDateTime === "" ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {this.state.inspectionChk !== "Y" ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              style={{ width: 80 }}
                              onClick={this.onApproveButtonClick}
                            >
                              Update
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {isoutButton !== "2" ? (
                          <ItemGrid>
                            <Button
                              color="success"
                              style={{ width: 80 }}
                              onClick={this.onOutButtonClick}
                            >
                              Out
                            </Button>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid>
                          <Button
                            color="danger"
                            style={{ width: 80 }}
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <SamplePDF
                        invoiceType={7}
                        printResp={this.state.printResp}
                      />
                    </ItemGrid>
                  ) : null}

                  {/*
                                    {this.state.updateView && this.state.outDateTime !== ""?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="primary" onClick={this.onApproveButtonClick}>update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
*/}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  staticData={this.state.supplierList}
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID)}
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Project" : "Branch"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                url={
                                  serverApi.SELECT_BRANCH +
                                  getCookie(cons.COMPANY_ID)
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Phase" : "Project"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                url={
                                  serverApi.PROJECT_MASTER_SELECTION_LIST +
                                  getCookie(cons.COMPANY_ID) +
                                  "/customer/0/branch/" +
                                  this.state.branchIds
                                }
                                defValue={this.state.projectIds}
                                // staticData={this.state.ProjectOptions}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        {/*<ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>*/}

                        {/*<GridContainer>*/}
                        {/*<ItemGrid xs={12} sm={4}>*/}
                        {/*<InputLabel className={classes.label}>*/}
                        {/*Gate Entry No*/}
                        {/*</InputLabel>*/}
                        {/*</ItemGrid>*/}
                        {/*<ItemGrid xs={12} sm={8}>*/}
                        {/*<TextField*/}
                        {/*id="gateEntryNO"*/}
                        {/*value={this.state.gateEntryNO}*/}
                        {/*className={classes.textField}*/}
                        {/*onChange={this.onChangeValues}*/}
                        {/*fullWidth={true}*/}
                        {/*variant="outlined"*/}
                        {/*style={{height: 30}}*/}
                        {/*InputProps={inputFocus}*/}
                        {/*/>*/}

                        {/*</ItemGrid>*/}
                        {/*</GridContainer>*/}
                        {/*</ItemGrid>*/}
                        {/*<ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>*/}

                        {/*<GridContainer>*/}
                        {/*<ItemGrid xs={12} sm={4}>*/}
                        {/*<InputLabel className={classes.label}>*/}
                        {/*Vehicle No*/}
                        {/*</InputLabel>*/}
                        {/*</ItemGrid>*/}
                        {/*<ItemGrid xs={12} sm={8}>*/}
                        {/*<SelectboxTemp id={"vehicleNo"}*/}
                        {/*onSelect={this.onSelect}*/}
                        {/*stateKey="supplierId"*/}
                        {/*url={serverApi.SEARCH_SUPPLIER1}*/}
                        {/*value={this.state.vehicleNo}*/}
                        {/*/>*/}

                        {/*</ItemGrid>*/}
                        {/*</GridContainer>*/}
                        {/*</ItemGrid>*/}
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <br />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.GET_STORE_GATE_ENTRY_TABLE}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_gate_entry_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textField: {},

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },

  notchedOutline: {},
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(StoreGateEntry);
