import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import BackArrow from "@material-ui/icons/ArrowBack";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";

//import {Redirect} from "react-router-dom";
//import IconCard from "../../components/Cards/IconCard";
import cons, {
  serverConfig,
  serverApi,
  getDecimal,
  getConfigValue,
  configNames,
} from "../../helper/Consts";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
  uploadFormdataWithImage,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { defaultFont } from "../../assets/jss/material-dashboard-pro-react";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import Email from "@material-ui/icons/Email";
import Print from "@material-ui/icons/LocalPrintshop";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import LineItemTable from "../CustumComponents/LineItemTable";
import { TCSPercentages } from "../TableData";

class JuteMR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      entryNo: "",
      fromDate: "",
      todate: "",
      mrNo: "",
      searchMrNo: "",
      vehicleNo: "",
      poNO: "",
      status: "",
      mrDate: moment().format("DD-MM-YYYY"),
      form_supplierId: "",
      supplierId: "",
      gateEntryNo: "",
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      contractNo: "",
      contractDate: "",
      exstn: "",
      agentId: "",
      form_vehicleNo: "",
      mukamId: "",
      mukamName: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "mrNo",
      approveStatus: false,
      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",
      indentNo: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      loadGateEntry: false,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      alert: false,
      warehouseNo: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      materialHdrId: "0",
      lineItemId: "0",
      mrStatus: "",
      printHeaderValues: null,
      printLineItems: null,
      printTotalvalues: null,
      frieghtCharges: 0,
      variableshortage: "",
      approvedWeight: "",
      variableShortageEnabled: false,
      actual_supplierId: "",
      advised_broker_Id: "",
      actual_broker_Id: "",
      transporter: "",
      driverName: "",
      supplierName: "",
      agentMrAlert: false,
      agentMrDate: "",
      agentMrList: [],
      remarks: "",
      branchId: "",
      filesList: [],
      juteIssueRate: "",
      // dustAmount: 0,
      waterDamageAmount: 0,
      premiumAmount: 0,
      //amount: ''
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "getMaterialGoodReceiveDetailsById/" +
          id +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    var header = resp.materialGoodReceiveHeader;
    var list = resp.materialGRItemList;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        lineItemId: prop.id,
        materialHdrId: prop.materialGrnHdrId,
        wareHouseName: prop.wareHouseName,
        // itemId: parseInt(prop.itemId),
        itemId: parseInt(prop.itemId),
        itemDesc: prop.itemDesc,
        itemGroupId: prop.itemGroupId,
        quantity: prop.quantity,
        quality: prop.qualityDesc,
        hsnCode: "",
        unit: prop.bale,
        weight: prop.actualWeight,

        uom: prop.quantityUnit,
        warehouseno:
          prop.warehouseNo !== "" && prop.warehouseNo !== null
            ? prop.warehouseNo
            : "",
        rate: prop.rate,
        amount: prop.totalPrice,
        dupAmount: prop.totalPrice,
        premiumAmount:
          prop.premiumAmount !== "" && prop.premiumAmount !== null
            ? parseFloat(prop.premiumAmount)
            : 0,
        waterDamageAmount:
          prop.waterDamageAmount !== "" && prop.waterDamageAmount !== null
            ? parseFloat(prop.waterDamageAmount)
            : 0,
        subtotal: this.getAmount(
          parseFloat(prop.totalPrice),
          parseFloat(prop.premiumAmount),
          parseFloat(prop.waterDamageAmount)
        ),

        depId: prop.deptId,
        qualityCode: prop.actualQuality,
        claimQuality: prop.claimQuality,
        claimDust: prop.claimDust,
        allowableMoisture: prop.allowableMoisture,
        advisedWeight: prop.advisedWeight,
        shortageWeight: prop.shortageKgs,
        qcMoisture: prop.claimsCondition,
        variableshortage: prop.variableShortage,
        approvedWeight: prop.acceptedWeight,
        variableShortageEnabled: prop.variableShortage > 0 ? true : false,
        marka: prop.marka,
        pota: prop.pota,
        cropYear: prop.cropYear,
        claimRate: prop.claimRate,
        convertedBale: prop.convertedBale,
        convertedDrum: prop.convertedDrum,
        convertedWeight: prop.convertedWeight,
        juteIssueRate: prop.juteIssueRate,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;

                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.basicItemAlert("Do You Like To Update This Item?");
                    this.setState({
                      // alert: true,
                      id: o.id,
                      lineItemId: o.lineItemId,
                      updateItemAlert: true,
                      materialHdrId: o.materialGrnHdrId,
                      updateItem: true,
                      reloadSelect: true,
                      item: o.itemId,
                      itemDesc: o.itemDesc,
                      quantity: o.quantity,
                      selectedJuteQuality: o.qualityCode,
                      quality: o.quality,
                      hsnCode: o.hsnCode,
                      rate: o.rate,
                      weight: o.weight,
                      premiumAmount: parseFloat(o.premiumAmount),
                      waterDamageAmount: parseFloat(o.waterDamageAmount),

                      amount: o.amount,
                      dupAmount: o.amount,
                      itemGroupId: o.itemGroupId,
                      depId: o.depId,
                      warehouseNo: o.warehouseno,
                      wareHouseName: o.wareHouseName,
                      uom: o.uom,
                      claimQuality: o.claimQuality,
                      claimDust: o.claimDust,
                      allowableMoisture: o.allowableMoisture,
                      advisedWeight: o.advisedWeight,
                      shortageWeight: o.shortageWeight,
                      qcMoisture: o.qcMoisture,
                      variableshortage: o.variableshortage,
                      approvedWeight: o.approvedWeight,
                      variableShortageEnabled: o.variableShortageEnabled,
                      marka: o.marka,
                      pota: o.pota,
                      cropYear: o.cropYear,
                      claimRate: o.claimRate,
                      juteIssueRate: o.juteIssueRate,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="edit"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    // if (this.state.approveStatus) {

                    this.setState({ deleteItem: true, id: o.id });

                    this.basicItemAlert("Do You Like To Delete This Item?");
                    //  data.splice(i,1);
                    //  }
                    return true;
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });
    console.log("supplierId " + header.supplierId[0]);
    if (header !== null) {
      //  alert(header.agentId);
      console.log("getting the status here to display", header);
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        approveStatus: header.approveStatus,
        mrNo: header.id,
        statusDesc: header.statusDesc,
        mrDate: header.goodReceiptDateDesc,
        form_supplierId: header.supplierId,
        actual_supplierId: header.actualSupplier,
        actual_broker_Id: parseInt(header.actualBroker),
        advised_broker_Id: parseInt(header.advisedBroker),
        gateEntryNo: parseInt(header.gateEntryNo),
        form_poNO: header.poId,
        poDate: header.poDateDesc,
        challanNo: header.challanNo,
        challanDate: header.chalanDateDesc,
        contractNo: header.contractNo,
        contractDate: header.contractDateDesc,
        exstn: header.exSTN,
        agentId: header.agentId,
        form_vehicleNo: header.vehicleNo,
        // year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateDesc,
        mukamId: header.mukamId,
        mukamName: header.mukamName,
        unitConversionName: header.unitConversion,
        unitConversion: this.getDefaultUnitConvertion(header.unitConversion),
        mrStatus: header.status,
        //actualWeightQTL:header.weight,
        actualWeightQTL: header.juteGateNetWeight,
        invoiceList: indentlist,
        openButtonStatus: header.createStatus,
        frieghtCharges: header.frieghtPaid,
        frieghtPaid: header.frieghtPaid,
        finalApprover: resp.finalApprover,
        companyName: header.companyName,
        driverName: header.driverName,
        agentName: header.agentName,
        supplierName: header.supplierName,
        transporter: header.transporter,
        remarks: header.remarks,
        branchId: header.branchId,
        tcsPercentage: header.tcsPercentage,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "26/" +
          header.id +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  afterRefreshbroker = (val) => {
    this.setState({
      loadBroker: val,
    });
  };
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      data.map((o, key) => {
        o.id = key;
        o.actions = (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;

                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //  data.splice(i,1);
                    this.basicItemAlert("Do You Like To Update This Item?");
                    this.setState({
                      // alert: true,
                      id: o.id,
                      lineItemId: o.lineItemId,
                      updateItemAlert: true,
                      materialHdrId: o.materialGrnHdrId,
                      updateItem: true,
                      reloadSelect: true,
                      item: o.itemId,
                      itemDesc: o.itemDesc,
                      quantity: o.quantity,
                      selectedJuteQuality: o.qualityCode,
                      quality: o.quality,
                      hsnCode: o.hsnCode,
                      rate: o.rate,
                      weight: o.weight,
                      premiumAmount: parseFloat(o.premiumAmount),
                      waterDamageAmount: parseFloat(o.waterDamageAmount),
                      dustAmount: o.dustAmount,
                      amount: o.amount,
                      dupAmount: o.dupAmount,
                      itemGroupId: o.itemGroupId,
                      depId: o.depId,
                      warehouseNo: o.warehouseno,
                      wareHouseName: o.wareHouseName,
                      uom: o.uom,
                      claimQuality: o.claimQuality,
                      claimDust: o.claimDust,
                      allowableMoisture: o.allowableMoisture,
                      advisedWeight: o.advisedWeight,
                      shortageWeight: o.shortageWeight,
                      qcMoisture: o.qcMoisture,
                      variableshortage: o.variableshortage,
                      approvedWeight: o.approvedWeight,
                      variableShortageEnabled: o.variableShortageEnabled,
                    });

                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="edit"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    if (this.state.approveStatus) {
                      this.setState({ deleteItem: true, id: o.id });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                      //  data.splice(i,1);
                    }
                    return true;
                  }
                });
                this.setState({ invoiceList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        );
      });

      //  if(this.state.approveStatus)
      this.setState({ invoiceList: data, deleteItem: false });
    }
    this.setState({
      basicAlert: null,
    });
  }
  getAgentName = (name) => {
    var val = name.split("_");
    this.setState({
      agentName: val[0],
    });
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemDesc: values[0],
      itemGroupId: values[1],
      uom: values[2],
      hsnCode: values[4],
    });
    console.log("uom " + values[2]);
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      loadquality: val,
    });
  };
  getQualityDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      quality: values[0],
      stock: values[1],
      lastPurchase: values[3],
    });
    if (values[0] !== this.state.quality) {
      this.setState({
        rate: values[2],
        allowableMoisture: values[4],
      });
    }
    console.log("get quality details " + this.state.stock);
  };
  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      status: this.state.status,
      supplierCode: this.state.supplierId,
      mrNo: this.state.searchMrNo,
      vehicleNo: this.state.vehicleNo,
      poNum: this.state.poNo,
      agentId: this.state.form_agentId,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      acYear: getCookie(cons.ACADEMIC_YEAR),
      gateEntryNo: this.state.entryNo,
    };
    return data;
  };
  getSupplierData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      status: !this.state.isRefresh ? 3 : this.state.status,
      supplierCode: getCookie(cons.SUPP_CODE),
      mrNo: this.state.searchMrNo,
      vehicleNo: this.state.vehicleNo,
      poNum: this.state.poNo,
      agentId: this.state.form_agentId,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      acYear: getCookie(cons.ACADEMIC_YEAR),
      gateEntryNo: this.state.entryNo,
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });

    //  this.dateChanged();
  };
  dateChanged = (date) => {
    if (myDate !== null) {
      var myDate = date;
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    if (event.target.checked) {
      if (this.state.unitConversionName === "LOOSE") {
        this.setState({
          variableshortage: 110,
        });
      }
      if (this.state.unitConversionName === "BALE") {
        if (this.state.quantity > 0)
          this.setState({
            variableshortage: parseFloat(this.state.quantity) * 1,
          });
      }
    } else {
      this.setState({
        variableshortage: 0,
        approvedWeight: 0,
      });
    }
    this.setState({
      variableShortageEnabled: !this.state.variableShortageEnabled,
    });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / 150))
      );
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    var id = event.target.id;
    if (id === "weight") {
      this.setState({
        shortageWeight: this.getShortageWeight(
          value,
          this.state.qcMoisture,
          this.state.allowableMoisture,
          this.state.claimDust
        ),
      });
    }
    if (id === "qcMoisture") {
      this.setState({
        shortageWeight: this.getShortageWeight(
          this.state.weight,
          value,
          this.state.allowableMoisture,
          this.state.claimDust
        ),
      });
    }
    if (id === "allowableMoisture") {
      this.setState({
        shortageWeight: this.getShortageWeight(
          this.state.weight,
          this.state.qcMoisture,
          value,
          this.state.claimDust
        ),
      });
    }
    if (id === "claimDust") {
      this.setState({
        shortageWeight: this.getShortageWeight(
          this.state.weight,
          this.state.qcMoisture,
          this.state.allowableMoisture,
          value
        ),
      });
    }

    if (event.target.id === "vehicleQuantity") {
      console.log("val " + value);
      if (value !== null && value !== "") {
        if (this.state.selectedVehicleType !== "")
          this.setState({
            indentQuantity:
              parseInt(value) * parseInt(this.state.selectedIndentQuantity),
          });
        else {
          value = "";
          this.basicAlert("Please Select Vehicle Type..!!");
        }
      }
    }
    if (event.target.id === "stock") {
      console.log("val " + this.state.indentQuantity);
      this.setState({
        stock: this.state.stock,
      });
    }
    if (value === "amount" || id === "amount") {
      if (value < 0) {
        // this.basicAlert("Negative value not allowed !!");
        value = value > 0 ? value : 0;
        this.setState({
          amount: value,
        });
      }
    }

    if (event.target.id === "quantity") {
      console.log("val " + value);
      if (value !== null && value !== "") {
        if (value < 0) {
          //this.basicAlert("Negative value not allowed !!")
          value = value > 0 ? value : "";
          this.setState({
            //premiumAmount: value,
            //amount: value,
            waterDamageAmount: value,
          });
        }
        if (!(value < 0)) {
          if (this.state.unitConversion.value === 2) {
            var calWeight = parseFloat((parseFloat(value) * 150) / 100).toFixed(
              2
            );
            var Calrate = this.state.rate;

            if (Calrate !== "") {
              var calAmount = parseFloat(
                parseFloat(Calrate) * calWeight
              ).toFixed(2);

              this.setState({
                amount: calAmount,
              });
            }
            this.setState({
              weight: calWeight,
            });
          }
        } else {
          var PoQuantity = this.state.actualWeightQTL;
          //     alert(this.state.indentQuantity);
          var calWeight = (parseFloat(PoQuantity) * parseFloat(value)) / 100;
        }
        //  var calWeight = parseFloat(value) *150 / 100;
      }
      //    else {
      //     var pAmount = this.state.premiumAmount;
      //     var wAmount = this.state.waterDamageAmount;
      //     if (wAmount != 0) {
      //       var trr = pAmount - wAmount;
      //       this.setState({
      //         amount: trr,
      //         dupAmount: trr,
      //         dupAmount2: trr,
      //         weight: "",
      //       });
      //     } else {
      //       this.setState({
      //         amount: pAmount,
      //         dupAmount: pAmount,
      //         dupAmount2: pAmount,
      //       });
      //     }
      //   }
    }

    if (event.target.id === "rate") {
      if (value !== null && value !== "") {
        if (value < 0) {
          //this.basicAlert('Negative value not allowed !!');
          value = value > 0 ? value : "";
          this.setState({
            rate: value,
          });
        } else {
          if (Calrate !== "") {
            var calAmount = parseFloat(value) * parseFloat(this.state.weight);
            calAmount = Number.parseFloat(calAmount).toFixed(2);
            this.setState({
              amount: calAmount,
            });

            // if (calAmount == 0) {
            //   var pAmount = this.state.premiumAmount;
            //   var wAmount = this.state.waterDamageAmount;
            //   if (wAmount != 0) {
            //     var trr = pAmount - wAmount;
            //     this.setState({
            //       amount: trr,
            //       dupAmount: trr,
            //       dupAmount2: trr,
            //     });
            //   } else {
            //     this.setState({
            //       amount: pAmount,
            //       dupAmount: pAmount,
            //       dupAmount2: pAmount,
            //     });
            //   }
            // } else {
            //   this.setState({
            //     amount: calAmount,
            //     dupAmount: calAmount,
            //     dupAmount2: calAmount,
            //   });
            // }
          }
        }
      } else {
        this.setState({
          amount: "",
        });
      }
    }

    if (event.target.id === "weight") {
      if (value !== null && value !== "") {
        if (Calrate !== "") {
          var calAmount = parseFloat(value) * parseFloat(this.state.rate);
          calAmount = Number.parseFloat(calAmount).toFixed(2);
          this.setState({
            amount: calAmount,
            dupAmount: calAmount,
          });
        }
      }
    }

    if (event.target.id === "premiumAmount") {
      if (parseFloat(value) < 0) {
        value = value > 0 ? value : "";
        this.setState({
          premiumAmount: value,
        });
      }
    }

    if (event.target.id === "waterDamageAmount") {
      if (parseFloat(value) < 0) {
        value = value > 0 ? value : "";
        this.setState({
          waterDamageAmount: value,
        });
      }
    }
    //if(value !== null && value !== "") {
    // if (event.target.id === "premiumAmount") {
    //   var tempAmount = "";
    //   if (value != null && value != "") {
    //     if (parseFloat(value) < 0) {
    //       //this.basicAlert("Negative value are not allowed !!");
    //       value = value > 0 ? value : "";
    //       this.setState({
    //         premiumAmount: value,
    //         //amount: value,
    //         //waterDamageAmount: value
    //       });
    //     } else {
    //       var amt = parseFloat(this.state.dupAmount);
    //       tempAmount = amt + parseFloat(value);
    //       tempAmount = Number.parseFloat(tempAmount).toFixed(2);
    //       this.setState({
    //         amount: tempAmount,
    //         dupAmount2: tempAmount,
    //       });
    //     }
    //   } else {
    //     var pAmount = this.state.premiumAmount;
    //     var wAmount = this.state.waterDamageAmount;
    //     var amt = parseFloat(this.state.dupAmount);
    //     if (wAmount != 0) {
    //       var tAmount = amt - parseFloat(wAmount);
    //       this.setState({
    //         amount: tAmount,
    //         dupAmount: tAmount,
    //         dupAmount2: tAmount,
    //       });
    //       // } else {
    //       //   var tAmount = amt + parseFloat(wAmount);
    //       //   this.setState({
    //       //     amount: tAmount,
    //       //   });
    //     } else {
    //       this.setState({
    //         amount: this.state.dupAmount,
    //       });
    //     }
    //   }
    // }
    // if (event.target.id === "waterDamageAmount") {
    //   var tempAmount = "";
    //   if (value != null && value != "") {
    //     if (parseFloat(value) < 0) {
    //       //this.basicAlert('Negative value are not allowed !!');
    //       value = value > 0 ? value : "";
    //       this.setState({
    //         //premiumAmount: value,
    //         //amount: value,
    //         waterDamageAmount: value,
    //       });
    //     } else {
    //       var amt = 0;
    //       if (this.state.dupAmount) {
    //         amt = parseFloat(this.state.dupAmount);
    //       }
    //       if (this.state.premiumAmount > 0)
    //         amt = amt + parseFloat(this.state.premiumAmount);

    //       tempAmount = amt - parseFloat(value);
    //       tempAmount = Number.parseFloat(tempAmount).toFixed(2);
    //       this.setState({
    //         amount: tempAmount,
    //         //dupAmount: tempAmount
    //       });
    //     }
    //   } else {
    //     this.setState({
    //       amount: this.state.dupAmount2,
    //     });
    //   }
    // }

    // var price = this.state.rate
    // var quantity= this.state.quantity
    // var premAmt = this.state.premiumAmount
    // var waterAmt = this.state.waterDamageAmount
    // if(price < 0 || quantity < 0 || premAmt < 0 || waterAmt < 0) {
    //     alert("Negative value not allowed")
    // }

    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/
    if (
      stateKey === "actual_supplierId" &&
      this.state.actual_supplierId !== selectedValue
    ) {
      this.setState({
        loadBroker: true,
        actual_broker_Id: 0,
      });
    }
    if (stateKey === "suppielr_id") {
      this.setState({
        loadGateEntry: true,
      });
    }
    if (stateKey === "item") {
      this.setState({
        loadquality: true,
      });
    }
    if (
      stateKey === "selectedJuteType" &&
      selectedValue > 0 &&
      selectedValue !== type
    ) {
      this.setState({
        hideJuteType: true,
      });
    }
    if (
      stateKey === "selectedMukam" &&
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeMukam
    ) {
      this.unitConvertionAlert(
        "Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!"
      );
    } else if (stateKey === "selectedMukam")
      this.setState({
        [stateKey]: selectedValue,
        canChangeMukam: false,
      });
    else
      this.setState({
        [stateKey]: selectedValue,
      });
  };
  afterRefreshGateEntry = (val) => {
    this.setState({
      loadGateEntry: val,
    });
  };
  getGateEntryDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      form_poNO: values[0],
      poDate: values[1],
      challanNo: values[2],
      challanDate: values[3],
      form_vehicleNo: values[4],
    });
    // console.log("indentQuantity " + this.state.stock);
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      advisedWeight: "",
      qcMoisture: "",
      shortageWeight: "",
      wareHouseName: "",
      // poDate:'',
      // challanNo:'',
      // challanDate:'',
      // contractNo:'',
      // contractDate:'',
      // exstn:'',
      // agentId:'',
      // form_vehicleNo:'',
      //
      // form_poNO:'',
      // gateEntryNo:'',

      //   unitConvertionId:"0",
      //  unitConvertion:"",
      quantity: "",
      stock: "",
      claimQuality: "",
      claimDust: "",
      allowableMoisture: "",
      variableShortageEnabled: false,
      variableshortage: "",
      id: 0,
      lineItemId: 0,
      materialHdrId: "",
      updateItem: false,
      reloadSelect: false,
      item: "",
      itemDesc: "",
      quality: "",
      rate: "",
      weight: "",
      premiumAmount: "",
      waterDamageAmount: "",
      amount: "",
      itemGroupId: "",
      depId: "",
      warehouseNo: "",

      uom: "",
      agentMrAlert: false,
      agentMrList: [],
      agentMrDate: "",
      marka: "",
      pota: "",
      cropYear: "",
      juteIssueRate: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      mrStatus: "",
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      lineItemId: 0,
      materialHdrId: "",
      actualWeightQTL: "",
      openButtonStatus: false,
      allowableMoisture: "",
      advisedWeight: "",
      qcMoisture: "",
      shortageWeight: "",
      frieghtCharges: 0,
      variableShortageEnabled: false,
      variableshortage: "",
      id: 0,
      updateItem: false,
      reloadSelect: false,
      item: "",
      itemDesc: "",
      quality: "",
      rate: "",
      weight: "",
      premiumAmount: "",
      waterDamageAmount: "",
      amount: "",
      itemGroupId: "",
      depId: "",
      warehouseNo: "",
      uom: "",
      companyName: "",
      frieghtPaid: "",
      transporter: "",
      driverName: "",
      supplierName: "",
      agentMrAlert: false,
      agentMrList: [],
      agentMrDate: "",
      wareHouseName: "",
      remarks: "",
      branchId: "",
      filesList: [],
      juteIssueRate: "",
    });
  }

  dateChanged = (myDate) => {
    if (myDate !== null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.lineItemId,
        materialGrnHdrId: prop.materialHdrId,
        quantity: prop.quantity,
        advisedQuality: prop.qualityCode,
        actualQuality: prop.qualityCode,
        actualWeight: prop.weight,
        premiumAmount: prop.premiumAmount,
        waterDamageAmount: prop.waterDamageAmount,
        deviation: 0,
        rate: prop.rate,
        claimsQuality: "",
        claimsCondition: prop.qcMoisture,
        warehouseNo: prop.warehouseno,
        remarks: "",
        totalPrice: prop.subtotal,
        itemId: prop.itemId,
        debitNotesFlag: "",
        quantityUnit: this.state.uom,
        issuableWeight: "0",
        bale: 0,
        loose: 0,
        actualBale: 0,
        actualLoose: 0,
        advisedItemId: this.state.itemId,
        allowableMoisture: this.state.allowableMoisture,
        claimQuality: prop.claimQuality,
        claimDust: prop.claimDust,
        advisedWeight: prop.advisedWeight,
        qcMoisture: prop.qcMoisture,
        shortageKgs: prop.shortageWeight,
        marka: prop.marka,
        pota: prop.pota,
        cropYear: prop.cropYear,
        claimRate: prop.claimRate,
        convertedBale: prop.convertedBale,
        convertedDrum: prop.convertedDrum,
        convertedWeight: prop.convertedWeight,
      };
    });

    var data = {
      materialGoodReceiveHeader: {
        id: this.state.mrNo,
        goodReceiptDate: this.dateChanged(this.state.mrDate),
        supplierId: this.state.form_supplierId,
        poId: this.state.form_poNO,
        poDate: this.dateChanged(this.state.poDate),
        // "contractNo": this.state.contractNo,
        // "contractDate": this.dateChanged(this.state.contractDate),
        chalanNo: this.state.challanNo,
        chalanDate: this.dateChanged(this.state.challanDate),
        warehouseNo: "",
        exSTN: this.state.exstn,
        userId: getCookie(cons.USERID),
        agentId: this.state.agentId,
        agentName: this.state.agentName,
        sourceId: "64",
        vehicleNo: this.state.form_vehicleNo,
        weight: 0,
        mukamId: this.state.mukamId,
        frieghtPaid: this.state.frieghtCharges,
        modBy: getCookie(cons.USERID),
        status: "4",
        createStatus: false,
      },
      materialGRItemList: invoiceList,
    };
    this.handleLoading();
    requestList(serverApi.UPDATE_JUTE_MR, data, this.handleAddIndent);
  };
  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var hasWareHouseNo = false;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        console.log("warehouseNo", prop.warehouseno);

        if (
          prop.warehouseno === "" ||
          prop.warehouseno === 0 ||
          prop.warehouseno === undefined
        ) {
          hasWareHouseNo = true;
        }

        return {
          id: prop.lineItemId,
          materialGrnHdrId: prop.materialHdrId,
          quantity: prop.quantity,
          advisedQuality: prop.qualityCode,
          actualQuality: prop.qualityCode,
          actualWeight: prop.weight,
          premiumAmount:
            parseFloat(prop.premiumAmount) !== ""
              ? parseFloat(prop.premiumAmount)
              : 0,
          //premiumAmount: prop.premiumAmount,
          waterDamageAmount: parseFloat(prop.waterDamageAmount),
          deviation: 0,
          rate: prop.rate,
          claimsQuality: "",
          claimsCondition: prop.qcMoisture,
          claimQuality: prop.claimQuality,
          claimDust: prop.claimDust,
          warehouseNo: prop.warehouseno,
          remarks: "",
          totalPrice: prop.subtotal,
          itemId: prop.itemId,
          debitNotesFlag: "",
          quantityUnit: this.state.uom,
          issuableWeight: "0",
          bale: 0,
          loose: 0,
          actualBale: 0,
          actualLoose: 0,
          advisedItemId: prop.itemId,
          allowableMoisture: prop.allowableMoisture,
          advisedWeight: prop.advisedWeight,
          shortageKgs: prop.shortageWeight,
          variableShortage: prop.variableshortage,
          acceptedWeight: prop.approvedWeight,
          marka: prop.marka,
          pota: prop.pota,
          cropYear: prop.cropYear,
          claimRate: prop.claimRate,
          convertedBale: prop.convertedBale,
          convertedDrum: prop.convertedDrum,
          convertedWeight: prop.convertedWeight,
        };
      });

      var data = {
        materialGoodReceiveHeader: {
          id: this.state.mrNo,
          goodReceiptDate: this.dateChanged(this.state.mrDate),
          supplierId: this.state.form_supplierId,
          actualSupplier: this.state.actual_supplierId,
          advisedBroker: this.state.advised_broker_Id,
          actualBroker: this.state.actual_broker_Id,
          poId: this.state.form_poNO,
          poDate: this.dateChanged(this.state.poDate),
          // "contractNo": this.state.contractNo,
          // "contractDate": this.dateChanged(this.state.contractDate),
          chalanNo: this.state.challanNo,
          chalanDate: this.dateChanged(this.state.challanDate),
          warehouseNo: "",
          exSTN: this.state.exstn,
          userId: getCookie(cons.USERID),
          agentId: this.state.mrStatus === "24" ? "" : this.state.agentId,
          agentName: this.state.mrStatus === "24" ? "" : this.state.agentName,
          sourceId: "",
          vehicleNo: this.state.form_vehicleNo,
          weight: this.state.actualWeightQTL,
          mukamId: this.state.mukamId,
          frieghtPaid: this.state.frieghtCharges,
          modBy: getCookie(cons.USER_NAME),
          status: "",
          createStatus: false,
          remarks: this.state.remarks,
          branchId: this.state.branchId,
          tcsPercentage: this.state.tcsPercentage,
          tcsAmount: this.getTcsAmount(),
        },
        materialGRItemList: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (!hasWareHouseNo) {
        if (
          this.state.mrDate !== "0" &&
          this.state.form_supplierId !== "" &&
          this.state.gateEntryNo !== "" &&
          this.state.exstn !== "" &&
          this.state.invoiceList.length >= 1 &&
          this.state.branchId > 0
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_JUTE_MR, data, this.handleAddIndent);
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else {
        this.basicAlert("Please Enter Warehouse No. For All Line Items..!!");
      }
    }
  };

  onOpenButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var hasWareHouseNo = false;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        console.log("warehouseNo", prop.warehouseno);

        if (
          prop.warehouseno === "" ||
          prop.warehouseno === 0 ||
          prop.warehouseno === undefined
        ) {
          hasWareHouseNo = true;
        }
        return {
          id: prop.lineItemId,
          materialGrnHdrId: prop.materialHdrId,
          quantity: prop.quantity,
          advisedQuality: prop.qualityCode,
          actualQuality: prop.qualityCode,
          actualWeight: prop.weight,
          deviation: 0,
          rate: prop.rate,
          claimsQuality: "",
          premiumAmount: parseFloat(prop.premiumAmount),
          waterDamageAmount: prop.waterDamageAmount,
          claimsCondition: prop.qcMoisture,
          warehouseNo: prop.warehouseno,
          remarks: "",
          totalPrice: prop.subtotal,
          itemId: prop.itemId,
          debitNotesFlag: "",
          quantityUnit: this.state.uom,
          issuableWeight: "0",
          bale: 0,
          loose: 0,
          actualBale: 0,
          actualLoose: 0,
          advisedItemId: this.state.itemId,
          claimQuality: prop.claimQuality,
          claimDust: prop.claimDust,
          allowableMoisture: prop.allowableMoisture,
          advisedWeight: prop.advisedWeight,
          shortageKgs: prop.shortageWeight,

          variableShortage: prop.variableshortage,
          acceptedWeight: prop.approvedWeight,
          marka: prop.marka,
          pota: prop.pota,
          cropYear: prop.cropYear,
          claimRate: prop.claimRate,
        };
      });

      var data = {
        materialGoodReceiveHeader: {
          id: this.state.mrNo,
          goodReceiptDate: this.dateChanged(this.state.mrDate),
          supplierId: this.state.form_supplierId,
          actualSupplier: this.state.actual_supplierId,
          advisedBroker: this.state.advised_broker_Id,
          actualBroker: this.state.actual_broker_Id,
          poId: this.state.form_poNO,
          poDate: this.dateChanged(this.state.poDate),
          // "contractNo": this.state.contractNo,
          // "contractDate": this.dateChanged(this.state.contractDate),
          chalanNo: this.state.challanNo,
          chalanDate: this.dateChanged(this.state.challanDate),
          warehouseNo: "",
          exSTN: this.state.exstn,
          userId: getCookie(cons.USERID),
          agentId: this.state.agentId,
          agentName: this.state.agentName,
          sourceId: "",
          vehicleNo: this.state.form_vehicleNo,
          weight: this.state.actualWeightQTL,
          mukamId: this.state.mukamId,
          frieghtPaid: this.state.frieghtCharges,
          modBy: getCookie(cons.USER_NAME),
          status: "1",
          createStatus: true,
          remarks: this.state.remarks,
          branchId: this.state.branchId,
          tcsPercentage: this.state.tcsPercentage,
          tcsAmount: this.getTcsAmount(),
        },
        materialGRItemList: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (!hasWareHouseNo) {
        if (
          this.state.mrDate !== "0" &&
          this.state.form_supplierId !== "" &&
          this.state.gateEntryNo !== "" &&
          this.state.exstn !== "" &&
          this.state.invoiceList.length >= 1 &&
          this.state.branchId > 0
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_JUTE_MR, data, this.handleAddIndent);
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else {
        this.basicAlert("Please Enter Warehouse No. For All Line Items..!!");
      }
    }
  };

  onApproveButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var hasWareHouseNo = false;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        //    console.log("warehouseNo",prop.warehouseno);

        if (
          prop.warehouseno === "" ||
          prop.warehouseno === 0 ||
          prop.warehouseno === undefined
        ) {
          hasWareHouseNo = true;
        }
        return {
          id: prop.lineItemId,
          materialGrnHdrId: prop.materialHdrId,
          quantity: prop.quantity,
          advisedQuality: prop.qualityCode,
          actualQuality: prop.qualityCode,
          actualWeight: prop.weight,
          deviation: 0,
          rate: prop.rate,
          claimsQuality: "",
          claimsCondition: prop.qcMoisture,
          claimQuality: prop.claimQuality,
          claimDust: prop.claimDust,
          warehouseNo: prop.warehouseno,
          remarks: "",
          totalPrice: prop.subtotal,
          itemId: prop.itemId,
          debitNotesFlag: "",
          quantityUnit: this.state.uom,
          issuableWeight: "0",
          bale: 0,
          loose: 0,
          actualBale: 0,
          actualLoose: 0,
          advisedItemId: prop.itemId,
          allowableMoisture: prop.allowableMoisture,
          advisedWeight: prop.advisedWeight,
          shortageKgs: prop.shortageWeight,
          variableShortage: prop.variableshortage,
          acceptedWeight: prop.approvedWeight,
          marka: prop.marka,
          pota: prop.pota,
          cropYear: prop.cropYear,
          claimRate: prop.claimRate,
          convertedBale: prop.convertedBale,
          convertedDrum: prop.convertedDrum,
          convertedWeight: prop.convertedWeight,
          premiumAmount: prop.premiumAmount,
          waterDamageAmount: prop.waterDamageAmount,
        };
      });

      var data = {
        materialGoodReceiveHeader: {
          id: this.state.mrNo,
          goodReceiptDate: this.dateChanged(this.state.mrDate),
          supplierId: this.state.form_supplierId,
          actualSupplier: this.state.actual_supplierId,
          advisedBroker: this.state.advised_broker_Id,
          actualBroker: this.state.actual_broker_Id,
          poId: this.state.form_poNO,
          poDate: this.dateChanged(this.state.poDate),
          // "contractNo": this.state.contractNo,
          // "contractDate": this.dateChanged(this.state.contractDate),
          chalanNo: this.state.challanNo,
          chalanDate: this.dateChanged(this.state.challanDate),
          warehouseNo: "",
          exSTN: this.state.exstn,
          userId: getCookie(cons.USERID),
          agentId: this.state.mrStatus === "24" ? "" : this.state.agentId,
          agentName: this.state.mrStatus === "24" ? "" : this.state.agentName,
          sourceId: "",
          vehicleNo: this.state.form_vehicleNo,
          weight: this.state.actualWeightQTL,
          mukamId: this.state.mukamId,
          frieghtPaid: this.state.frieghtCharges,
          modBy: getCookie(cons.USER_NAME),
          status: this.state.mrStatus,
          createStatus: false,
          remarks: this.state.remarks,
          branchId: this.state.branchId,
          tcsPercentage: this.state.tcsPercentage,
          tcsAmount: this.getTcsAmount(),
        },
        materialGRItemList: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (!hasWareHouseNo) {
        if (
          this.state.mrDate !== "0" &&
          this.state.form_supplierId !== "" &&
          this.state.gateEntryNo !== "" &&
          this.state.exstn !== "" &&
          this.state.invoiceList.length >= 1 &&
          this.state.branchId > 0
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_JUTE_MR, data, this.handleAddIndent);
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else {
        this.basicAlert("Please Enter Warehouse No. For All Line Items..!!");
      }
    }
  };

  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.finalApprover && this.state.mrStatus !== "3") {
        this.setState({
          sendMail: true,
          finalApprovalMsg: resp.message,
        });
        this.printDocument();
      } else {
        this.showMessage(resp.message, resp.status);
      }
      console.log("resp " + JSON.stringify(resp));
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList,
      PoWeight = 0,
      hasItem = false,
      quantityInItem = 0,
      weightInItem = 0,
      amountInItem = 0;
    var sumOfWeight = 0;
    //console.log("sumOfWeight " + sumOfWeight);
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      quantityInItem = arr[itemIndex].quantity;
      weightInItem = arr[itemIndex].weight;
      amountInItem = arr[itemIndex].amount;
      arr[itemIndex].weight = this.state.weight;
      arr[itemIndex].quantity = this.state.quantity;
      arr[itemIndex].amount = this.state.amount;
      // arr[itemIndex].premiumAmount = this.state.premiumAmount
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    arr.map((q) => {
      sumOfWeight = sumOfWeight + parseFloat(q.weight);

      if (
        q.quality === this.state.selectedJuteQuality &&
        !this.state.updateItem
      )
        hasItem = true;
    });
    if (!this.state.updateItem) {
      sumOfWeight = sumOfWeight + parseFloat(this.state.weight);
    }
    sumOfWeight = Number.parseFloat(sumOfWeight).toFixed(2);
    console.log("state", this.state);
    var addItemObj = {
      id: itemIndex,
      itemId: this.state.item,
      wareHouseName: this.state.wareHouseName,
      itemDesc: this.state.itemDesc,
      // unitConvertion:"",
      quantity: this.state.quantity,
      qualityCode: this.state.selectedJuteQuality,
      quality: this.state.quality,
      unit: this.state.uom,
      weight: this.state.weight,
      uom: this.state.uom,
      warehouseno: this.state.warehouseNo,
      rate: this.state.rate,
      amount: this.state.amount,
      dupAmount: this.state.dupAmount,
      itemGroupId: this.state.itemGroupId,
      depId: this.state.depId,
      lineItemId: this.state.lineItemId,
      materialHdrId: this.state.materialHdrId,
      claimQuality: this.state.claimQuality,
      claimDust: this.state.claimDust,
      allowableMoisture: this.state.allowableMoisture,
      advisedWeight: this.state.advisedWeight,
      qcMoisture: this.state.qcMoisture,
      shortageWeight: this.state.shortageWeight,
      variableshortage: this.state.variableshortage,
      approvedWeight: this.getApprovedWeight(),
      variableShortageEnabled: this.state.variableShortageEnabled,
      marka: this.state.marka,
      pota: this.state.pota,
      cropYear: this.state.cropYear,
      claimRate: this.state.claimRate,
      premiumAmount:
        parseFloat(this.state.premiumAmount) !== null &&
        parseFloat(this.state.premiumAmount) !== ""
          ? parseFloat(this.state.premiumAmount)
          : 0,
      waterDamageAmount: this.state.waterDamageAmount,
      subtotal: this.getAmount(
        this.state.amount,
        this.state.premiumAmount,
        this.state.waterDamageAmount
      ),

      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to remove the data row */}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // alert(o.qualityId);
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  //  data.splice(i,1);
                  this.basicItemAlert("Do You Like To Update This Item?");
                  this.setState({
                    // alert: true,
                    updateItemAlert: true,
                    id: o.id,
                    lineItemId: o.lineItemId,
                    materialHdrId: o.materialGrnHdrId,
                    updateItem: true,
                    reloadSelect: true,
                    item: o.itemId,
                    itemDesc: o.itemDesc,
                    quantity: o.quantity,
                    selectedJuteQuality: o.qualityCode,
                    quality: o.quality,
                    rate: o.rate,
                    premiumAmount: parseFloat(o.premiumAmount),
                    waterDamageAmount: o.waterDamageAmount,
                    weight: o.weight,
                    amount: o.amount,
                    dupAmount: o.dupAmount,
                    itemGroupId: o.itemGroupId,
                    depId: o.depId,
                    warehouseNo: o.warehouseno,
                    wareHouseName: o.wareHouseName,
                    uom: o.uom,
                    claimQuality: o.claimQuality,
                    claimDust: o.claimDust,
                    allowableMoisture: o.allowableMoisture,
                    advisedWeight: o.advisedWeight,
                    qcMoisture: o.qcMoisture,
                    shortageWeight: o.shortageWeight,
                    variableshortage: o.variableshortage,
                    variableShortageEnabled: o.variableShortageEnabled,
                    marka: o.marka,
                    pota: o.pota,
                    cropYear: o.cropYear,
                    claimRate: o.claimRate,
                  });

                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="edit"
          >
            <Edit />
          </IconButton>{" "}
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.setState({ deleteItem: true, id: o.id });
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  return true;
                }
              });
              this.setState({
                invoiceList: data,
                selectedJuteType: "",
                selectedJuteName: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                quantity: "",
                selectedUnit: "",
                selectedDeptId: "",
                selectedGrpId: "",
                stock: "",
                claimQuality: "",
                claimDust: "",
                allowableMoisture: "",
                advisedWeight: "",
                premiumAmount: 0,
                waterDamageAmount: 0,
                qcMoisture: "",
                shortageWeight: "",
                variableshortage: "",
                id: 0,
                lineItemId: 0,
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };

    if (!hasItem) {
      if (
        this.state.item !== "" &&
        this.state.selectedJuteQuality !== "" &&
        this.state.quantity !== "" &&
        this.state.warehouseNo !== "" &&
        this.state.warehouseNo !== 0 &&
        this.state.weight !== "" &&
        this.state.amount !== "" &&
        this.state.claimDust !== "" &&
        this.state.allowableMoisture !== "" &&
        this.state.qcMoisture !== ""
      ) {
        PoWeight = this.state.actualWeightQTL;
        PoWeight = Number.parseFloat(PoWeight).toFixed(2);
        if (parseFloat(sumOfWeight) <= parseFloat(PoWeight)) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            hideJuteType: false,
            loading: true,
            invoiceList: arr,
          });
          this.hideAlert();
        } else {
          this.basicAlert(
            "Sum Of Weight should not exceed " + PoWeight + "..!!"
          );
          if (this.state.updateItem) {
            arr[itemIndex].weight = weightInItem;
            arr[itemIndex].quantity = quantityInItem;
            arr[itemIndex].amount = amountInItem;
          }
        }
      } else if (
        this.state.rate < 0 ||
        Number(this.state.amount) < 0 ||
        this.state.premiumAmount < 0 ||
        this.state.quantity < 0 ||
        this.state.waterDamageAmount < 0
      ) {
        this.basicAlert("Negative Values are not allowed");
        //alert("Negative value not allowed")
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This Quality. Please Select Another One..!!"
      );
    }
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    console.log(this.state);
    if (
      this.state.form_supplierId !== "0" &&
      this.state.mrDate !== "" &&
      this.state.gateEntryNo !== "0" &&
      this.state.form_vehicleNo !== "0"
    ) {
      this.setState({
        loading: true,
        alert: true,
        updateItem: false,
      });
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  handleSelectedValues = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeUnit &&
      this.state.unitConversion !== defValue
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConvertion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getQuantityType = () => {
    if (
      this.state.unitConversion !== "" &&
      this.state.unitConversion !== null &&
      this.state.unitConversion !== undefined
    ) {
      var val = this.state.unitConversion.value;
      if (val === 2) {
        return "BALES";
      } else if (val === 1) {
        return "DRUMS";
      }
    } else return "";
  };
  getShortageWeight = (weight, qcMoisture, AllowableMoisture, climaDust) => {
    var Moisture = parseFloat(qcMoisture) - parseFloat(AllowableMoisture);
    if (Moisture > 0 && weight !== "") {
      Moisture = Moisture + parseFloat(climaDust);
      return Math.round(parseFloat(Moisture) * parseFloat(weight));
    } else if (parseFloat(climaDust) > 0 && weight !== "") {
      return Math.round(parseFloat(climaDust) * parseFloat(weight));
    } else return 0;
  };
  getApprovedWeight = () => {
    var weight = this.state.weight * 100,
      variableshortage = this.state.variableshortage,
      shortageWeight = this.state.shortageWeight;
    if (weight > 0 && variableshortage !== "" && shortageWeight !== "")
      return Math.round(
        parseFloat(weight) -
          parseFloat(variableshortage) -
          parseFloat(shortageWeight),
        2
      );
    else return 0;
  };
  getTotalMillWeight() {
    var arr = this.state.invoiceList;
    var millWeigt = 0;
    arr.map((prop, key) => {
      millWeigt = millWeigt + parseFloat(prop.weight);
    });
    return parseFloat(millWeigt).toFixed(2);
  }
  onUpadateAgentList = () => {
    var hasRate = true;
    this.state.agentMrList.forEach((prop, i) => {
      if (prop.rate === "" || prop.rate <= 0) {
        hasRate = false;
      }
    });

    if (this.state.agentMrDate !== "" && hasRate) {
      var data = {
        mrId: this.state.mrNo,
        agentMrDate: this.dateChanged(this.state.agentMrDate),
        lineItems: this.state.agentMrList,
      };
      requestList(
        serverApi.UPDATE_AGENT_MR_DETAILS,
        data,
        this.handleAgentMrListResp
      );
    } else {
      this.basicAlert("Please Enter All Fields !!!");
    }
  };
  handleAgentMrListResp = (resp) => {
    if (resp.status) {
      this.setState({
        agentMrAlert: false,
        agentMrDate: "",
        agentMrList: [],
      });
      this.onApproveButtonClick();
    }
    //  this.showMessage(resp.message, false)
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert1: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      mrNo,
      isReject,
      isApprove,
      srStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(this.state.srStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        26,
        fileName,
        mrNo,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  getTcsAmount() {
    var totalAmount = 0;
    var data = this.state.invoiceList;
    if (this.state.tcsPercentage > 0)
      data.forEach((prop, key) => {
        totalAmount =
          totalAmount +
          (parseFloat(prop.amount) * parseFloat(this.state.tcsPercentage)) /
            100;
      });
    return totalAmount.toFixed(2);
  }
  getAmount = (amount, premium, waterdamage) => {
    var totalamount = 0;
    if (amount && parseFloat(amount) > 0)
      totalamount = parseFloat(totalamount) + parseFloat(amount);
    if (premium && parseFloat(premium) > 0)
      totalamount = parseFloat(totalamount) + parseFloat(premium);

    if (waterdamage && parseFloat(waterdamage) > 0) {
      totalamount = parseFloat(totalamount) - parseFloat(waterdamage);
    }
    return totalamount.toFixed(2);
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    //console.log("jute type",selectedJuteType);
    // add item button click
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    const ucoptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    var year = parseInt(getCookie(cons.ACADEMIC_YEAR));
    const yearOptions = [
      { value: year, label: year + "-" + (year + 1), name: year },
      { value: year - 1, label: year - 1 + "-" + year, name: year - 1 },
      { value: year - 2, label: year - 2 + "-" + (year - 1), name: year - 2 },
    ];
    const statusOptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 3, label: "APPROVED", name: "APPROVED" },
      { value: 4, label: "REJECTED", name: "REJECTED" },
      { value: 5, label: "CLOSED", name: "CLOSED" },
      { value: 6, label: "CANCELLED", name: "CANCELLED" },
      { value: 25, label: "PAID", name: "PAID" },
    ];
    var columns = [
      {
        Header: "Item Desc",
        accessor: "itemDesc",
        minWidth: 150,

        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Quality",
        accessor: "quality",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Rate",
        accessor: "rate",
        minWidth: 50,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Original Rate",
        accessor: "juteIssueRate",
        minWidth: 50,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Warehouse No",
        accessor: "wareHouseName",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },

      {
        Header: this.getQuantityType(),
        accessor: "quantity",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Advised Weight",
        accessor: "advisedWeight",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Mill Weight",
        accessor: "weight",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "UOM",
        accessor: "uom",
        minWidth: 50,
        style: { color: "#000", textAlign: "left" },
      },

      {
        Header: "Amount",
        accessor: "subtotal",
        minWidth: 70,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Prem Amt",
        accessor: "premiumAmount",
        minWidth: 70,
        style: {
          color: "#000",
          textAlign: "right",
        },
      },

      {
        Header: "Water Dmg Amt",
        accessor: "waterDamageAmount",
        minWidth: 70,
        style: {
          color: "#000",
          textAlign: "right",
        },
      },

      {
        Header: "Claim Quality",
        accessor: "claimQuality",
        minWidth: 80,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Claim Rate",
        accessor: "claimRate",
        minWidth: 80,
        style: { color: "#000", textAlign: "right" },
      },

      {
        Header: "Claim Dust",
        accessor: "claimDust",
        minWidth: 80,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "QC Moisture",
        accessor: "qcMoisture",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Allowable Moisture",
        accessor: "allowableMoisture",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },

      {
        Header: "Variable Shortage",
        accessor: "variableshortage",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },
      {
        Header: "Shortage In.Wt",
        accessor: "shortageWeight",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },

      {
        Header: "Approved Weight",
        accessor: "approvedWeight",
        minWidth: 100,
        style: { color: "#000", textAlign: "right" },
      },

      {
        Header: "Actions",
        minWidth: 100,
        accessor: "actions",
        style: { color: "#000", textAlign: "center" },
        sortable: false,
        filterable: false,
      },
    ];

    var hasCrop = false;
    this.state.invoiceList.forEach((prop) => {
      if (
        prop.cropYear !== null &&
        prop.cropYear !== "" &&
        prop.cropYear !== undefined
      )
        hasCrop = true;
    });
    if (hasCrop) {
      columns = [
        {
          Header: "Crop Year",
          accessor: "cropYear",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Item Desc",
          accessor: "itemDesc",
          minWidth: 150,

          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Quality",
          accessor: "quality",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Rate",
          accessor: "rate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Warehouse No",
          accessor: "wareHouseName",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: this.getQuantityType(),
          accessor: "quantity",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Advised Weight",
          accessor: "advisedWeight",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Mill Weight",
          accessor: "weight",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "UOM",
          accessor: "uom",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: "Amount",
          accessor: "amount",
          minWidth: 70,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Prem Amt",
          accessor: "premiumAmount",
          minWidth: 70,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Water Dmg Amt",
          accessor: "waterDamageAmount",
          minWidth: 70,
          style: {
            color: "#000",
            textAlign: "right",
          },
        },

        {
          Header: "Claim Quality",
          accessor: "claimQuality",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Claim Rate",
          accessor: "claimRate",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Claim Dust",
          accessor: "claimDust",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "QC Moisture",
          accessor: "qcMoisture",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Allowable Moisture",
          accessor: "allowableMoisture",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Variable Shortage",
          accessor: "variableshortage",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Shortage In.Wt",
          accessor: "shortageWeight",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Approved Weight",
          accessor: "approvedWeight",
          minWidth: 100,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Actions",
          minWidth: 100,
          accessor: "actions",
          style: { color: "#000", textAlign: "center" },
          sortable: false,
          filterable: false,
        },
      ];
    }
    const inputFocus = {
      /*classes: {
                root: classes.cssOutlinedInput,
                focused: classes.notchedOutline,
                notchedOutline: classes.notchedOutline,
            },*/
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var valid = function(current) {
      if (isBackDate === "2") return current.isAfter(yesterday);
      else return true;
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.agentMrAlert ? (
                    <Popup
                      onClickSave={() => this.onUpadateAgentList()}
                      closePopup={() => this.hideAlert()}
                      //  title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Update"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Date
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <FormControl variant="" fullWidth>
                                        <Datetime
                                          isDisabled={true}
                                          closeOnSelect={true}
                                          timeFormat={false}
                                          //  isValidDate={valid}
                                          onChange={(dateObj) => {
                                            this.timeChanged(
                                              dateObj,
                                              "agentMrDate"
                                            );
                                          }}
                                          height={"15px"}
                                          formControlProps={{
                                            height: "15px",
                                            inlineBlock: "none",
                                          }}
                                          inputProps={{
                                            id: "mrDate",
                                            value: this.state.agentMrDate,
                                            autoComplete: "off",
                                          }}
                                        />
                                      </FormControl>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              style={{ overflowY: "auto", maxHeight: 250 }}
                            >
                              <GridContainer>
                                {this.state.agentMrList.map((prop, i) => {
                                  return (
                                    <ItemGrid
                                      xs={6}
                                      md={6}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={12}>
                                          <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                              <InputLabel
                                                className={classes.label}
                                              >
                                                {prop.name}
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  *
                                                </span>
                                              </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                              <TextField
                                                id="rate"
                                                //  className={classes.textField}
                                                onChange={(event) => {
                                                  var data = this.state
                                                    .agentMrList;
                                                  data[i].rate =
                                                    event.target.value;
                                                  this.setState({
                                                    agentMrList: data,
                                                  });
                                                }}
                                                fullWidth={true}
                                                variant="outlined"
                                                InputProps={inputFocus}
                                                style={{ height: 30 }}
                                                value={prop.rate}
                                                iType={"number"}
                                              />
                                            </ItemGrid>
                                          </GridContainer>
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  );
                                })}
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      //  title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      maxHeight={300}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"item"}
                                        defValue={this.state.item}
                                        onSelect={this.onSelect}
                                        getName={this.getitemDetails.bind(this)}
                                        stateKey="item"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.mukamId +
                                          "/getAllItemByMukam/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.item}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quality
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={
                                          this.state.selectedJuteQuality
                                        }
                                        id={"selectedJuteQuality"}
                                        onSelect={this.onSelect}
                                        getName={this.getQualityDetails}
                                        isRefresh={this.state.loadquality}
                                        setIsRefresh={this.afterRefreshquality}
                                        stateKey="selectedJuteQuality"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.item +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/getQualityByItem"
                                        }
                                        //value={this.state.selectedJuteQuality}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Stock
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        id="stock"
                                        //  className={classes.textField}
                                        //  onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.stock}
                                        // iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        //className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        iType={"number"}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Warehouse No
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.warehouseNo}
                                        id={"warehouseNo"}
                                        onSelect={this.onSelect}
                                        stateKey="warehouseNo"
                                        getName={(name) => {
                                          this.setState({
                                            wareHouseName: name,
                                          });
                                        }}
                                        postData={{
                                          companyId: getCookie(cons.COMPANY_ID),
                                          type: "J",
                                          branchId: "",
                                        }}
                                        url={serverApi.SELECT_GODOWN_LIST}
                                        value={this.state.warehouseNo}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        //  className={classes.textField}
                                        value={this.state.quantity}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        iType={"number"}
                                        InputProps={{
                                          style: {
                                            height: 30,
                                            color: "#000",
                                          },
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.getQuantityType()}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Advised Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="advisedWeight"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.advisedWeight}
                                    iType={"number"}
                                    InputProps={{
                                      style: { height: 30, color: "#000" },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            QTL
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Mill Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="weight"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.weight}
                                    iType={"number"}
                                    InputProps={{
                                      style: { height: 30, color: "#000" },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            QTL
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        // className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.getAmount(
                                          this.state.amount,
                                          this.state.premiumAmount,
                                          this.state.waterDamageAmount
                                        )}
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Claim Quality
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="claimQuality"
                                        // className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.claimQuality}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Claim Dust
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="claimDust"
                                        // className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                        iType={"number"}
                                        value={this.state.claimDust}
                                        decimal={2}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                %
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Claim Rate
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="claimRate"
                                        // className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        // iType={"number"}
                                        value={this.state.claimRate}
                                        decimal={2}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        QC Moisture
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="qcMoisture"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.qcMoisture}
                                        // iType={"number"}
                                        style={{ height: 30 }}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                %
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Allowable Moisture
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="allowableMoisture"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.allowableMoisture}
                                        // iType={"number"}
                                        style={{ height: 30 }}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                %
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Prem Amt
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        //disabled={true}
                                        InputProps={{
                                          autoComplete: "off",
                                        }}
                                        id="premiumAmount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.premiumAmount}
                                        iType={"number"}
                                        style={{ height: 30 }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Water Dmg Amt
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="waterDamageAmount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={this.state.waterDamageAmount}
                                        iType={"number"}
                                        style={{ height: 30 }}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end" />
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        value={
                                          this.state.variableShortageEnabled
                                        }
                                        checked={
                                          this.state.variableShortageEnabled
                                        }
                                        onChange={this.handleChange("")}
                                        // onClick={() => this.handleToggle(prop)}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                        }}
                                        style={{ marginLeft: 10 }}
                                      />
                                    }
                                    classes={{
                                      label: classes.menulabel,
                                    }}
                                    label={
                                      <InputLabel className={classes.label}>
                                        Variable Shortage
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    }
                                  />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    // disabled={true}
                                    id="variableshortage"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.variableshortage}
                                    InputProps={{
                                      style: { height: 30, color: "#000" },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            KG
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Shortage in Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    // disabled={true}
                                    id="shortageWeight"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.shortageWeight}
                                    iType={"number"}
                                    InputProps={{
                                      style: { height: 30, color: "#000" },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            KG
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Approved Weight
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    // disabled={true}
                                    id="Approved Weight"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.getApprovedWeight()}
                                    iType={"number"}
                                    InputProps={{
                                      style: { height: 30, color: "#000" },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            KG
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Marka
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    // disabled={true}
                                    id="marka"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.marka}
                                    InputProps={{
                                      autoComplete: "off",
                                    }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Pota
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    // disabled={true}
                                    id="pota"
                                    //  className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.state.pota}
                                    InputProps={{
                                      autoComplete: "off",
                                    }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Crop Year
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.cropYear}
                                    onSelect={this.onSelect}
                                    // getName={this.getVehicleQlty.bind(this)}
                                    stateKey="cropYear"
                                    staticData={yearOptions}
                                    value={this.state.cropYear}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          MR Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            isDisabled={true}
                            closeOnSelect={true}
                            timeFormat={false}
                            //  isValidDate={valid}
                            /*
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "mrDate")
                                                        }}
*/
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "mrDate",
                              value: this.state.mrDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Advised Supplier
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.updateView}
                          id={"form_supplierId"}
                          defValue={this.state.form_supplierId}
                          onSelect={this.onSelect}
                          stateKey="form_supplierId"
                          url={
                            serverApi.SEARCH_SUPPLIER +
                            "/" +
                            getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.form_supplierId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Actual Supplier
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"actual_supplierId"}
                          defValue={this.state.actual_supplierId}
                          onSelect={this.onSelect}
                          stateKey="actual_supplierId"
                          url={
                            serverApi.SEARCH_MR_SUPPLIER +
                            "/" +
                            getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.actual_supplierId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Advised Broker
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"advised_broker_Id"}
                          defValue={this.state.advised_broker_Id}
                          onSelect={this.onSelect}
                          stateKey="advised_broker_Id"
                          getName={this.brokername}
                          //  setIsRefresh={this.afterRefreshbroker}
                          url={
                            serverApi.GET_BROKERS_BY_SUPPLIER +
                            this.state.form_supplierId
                          }
                          // url={serverApi.SEARCH_BROKER+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Actual Broker
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"actual_broker_Id"}
                          defValue={this.state.actual_broker_Id}
                          onSelect={this.onSelect}
                          stateKey="actual_broker_Id"
                          isRefresh={this.state.loadBroker}
                          getName={this.brokername}
                          setIsRefresh={this.afterRefreshbroker}
                          url={
                            serverApi.GET_BROKERS_BY_SUPPLIER +
                            this.state.actual_supplierId
                          }
                          // url={serverApi.SEARCH_BROKER+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Gate Entry No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="gateEntryNo"
                          value={this.state.gateEntryNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Mukam
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="mukamName"
                          value={this.state.mukamName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>PO NO</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_poNO"
                          value={this.state.form_poNO}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          PO Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              //this.timeChanged(dateObj, "poDate")
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "poDate",
                              value: this.state.poDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="challanNo"
                          value={this.state.challanNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "challanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "challanDate",
                              value: this.state.challanDate,
                              autoComplete: "off",
                              disabled:
                                getCookie(cons.USER_GRP_ID) == "99"
                                  ? true
                                  : false,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Agent</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id={"agentId"}
                          defValue={this.state.agentId}
                          onSelect={this.onSelect}
                          stateKey="agentId"
                          getName={this.getAgentName}
                          url={
                            serverApi.AGENTS_LIST + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.agentId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_vehicleNo"
                          value={this.state.form_vehicleNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          inputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Unit Conversion
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={true}
                          defValue={this.state.unitConversion}
                          name={"unitConversion"}
                          value={this.state.unitConversion}
                          onChange={this.handleSelectedUnitValue}
                          // statekey={"unitConvertion"}
                          options={ucoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Total Mill Weight
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="millWeight"
                          value={this.getTotalMillWeight()}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={
                            this.state.isDisabled ||
                            getCookie(cons.USER_GRP_ID) == "99"
                          }
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Remarks
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={getCookie(cons.USER_GRP_ID) == "99"}
                          id="remarks"
                          // label="Multiline"
                          fullWidth
                          multiline={true}
                          rowsMax="3"
                          value={this.state.remarks}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                          height={60}
                          // helperText="hello"
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.invoiceList}
                          loading={this.state.loading}
                          disableActions={
                            this.state.mrStatus === "3" ||
                            this.state.mrStatus === "4" ||
                            this.state.mrStatus === "5" ||
                            this.state.mrStatus === "6"
                          }
                          columns={columns}
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>

                              {/*<span style={{fontSize:"12px",color:"red",fontStyle:"italic"}}>{"Unit & Mukam can't modified"}</span>*/}
                            </ItemGrid>

                            <ItemGrid xs={12}>
                              {this.state.mrStatus !== "3" &&
                              this.state.mrStatus !== "4" &&
                              this.state.mrStatus !== "5" &&
                              this.state.mrStatus !== "6" ? (
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              ) : null}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        </ItemGrid>
                      )}
                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={7}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  TCS Percentage
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  selectDisabled={
                                    getCookie(cons.USER_GRP_ID) == "99"
                                  }
                                  defValue={this.state.tcsPercentage}
                                  onSelect={this.onSelect}
                                  stateKey="tcsPercentage"
                                  staticData={TCSPercentages}
                                  //value={this.state.tcsPercentage}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              TCS Amount :
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={3}>
                            <TextField
                              disabled={getCookie(cons.USER_GRP_ID) == "99"}
                              id="tcsAmount"
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              value={this.getTcsAmount()}
                              variant="outlined"
                              style={{ height: 30 }}
                              iType={"number"}
                            />
                          </ItemGrid>
                          <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                            <GridContainer>
                              <ItemGrid
                                xs={12}
                                sm={9}
                                style={{ textAlign: "right" }}
                              >
                                <InputLabel className={classes.label}>
                                  Freight Charges ( If Any )
                                </InputLabel>
                              </ItemGrid>

                              <ItemGrid xs={12} sm={3}>
                                <TextField
                                  disabled={getCookie(cons.USER_GRP_ID) == "99"}
                                  id="frieghtCharges"
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  value={this.state.frieghtCharges}
                                  variant="outlined"
                                  style={{ height: 30 }}
                                  iType={"number"}
                                  InputProps={{
                                    autoComplete: "off",
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.frieghtPaid > 0 ? (
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "18px",
                                              fontWeight: "400",
                                              cursor: "pointer",
                                            }}
                                            onClick={
                                              this.printFreightChagesVocher
                                            }
                                          >
                                            <Print />
                                          </span>
                                        ) : null}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      {/*
                                            <ItemGrid xs={12}
                                                      style={{height: 1, backgroundColor: "#00acc1", marginTop: "10px"}}/>
*/}
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView &&
                  this.state.mrStatus !== "3" &&
                  this.state.mrStatus !== "4" ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onApproveButtonClick}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {console.log(
                        "getting the approve status",
                        this.state.mrStatus
                      )}
                      {/* {this.state.approveStatus ? ( */}
                      {this.state.mrStatus === "1" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={this.onRejectButtonClick}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {/* {this.state.mrStatus==='24'?
                                                <Button color="primary"
                                                        onClick={()=>{
                                                            var agentMrList=[];
                                                            this.state.invoiceList.forEach((prop,i)=>{
                                                                var obj={
                                                                    name:prop.itemDesc+" "+prop.quality,
                                                                    lineItemId:prop.lineItemId,
                                                                    rate:prop.rate
                                                                };
                                                                agentMrList.push(obj)
                                                            });
                                                            this.setState({
                                                                agentMrAlert:true,
                                                                agentMrDate:this.state.mrDate,
                                                                agentMrList:agentMrList
                                                            });
                                                        }
                                                        }>Transfer</Button>:null
                                            } */}

                      {this.state.openButtonStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onOpenButtonClick}
                          >
                            Open
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.mrStatus !== "3" &&
                        this.state.approveStatus) ||
                      this.state.mrStatus === "1" ||
                      this.state.openButtonStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      <ItemGrid xs={12}>
                        <GridContainer>
                          {this.state.mrStatus !== "21" &&
                          this.state.mrStatus !== "4" &&
                          getConfigValue(configNames.MR_PRINT_MODE) !== "2" ? (
                            <ItemGrid xs={10}>
                              <IconButton
                                color={"primary"}
                                right={true}
                                onClick={() => {
                                  this.setState({
                                    sendMail: true,
                                  });
                                  this.printDocument();
                                }}
                              >
                                <Email />
                              </IconButton>
                            </ItemGrid>
                          ) : null}
                          {this.state.mrStatus !== "21" &&
                          this.state.mrStatus !== "4" &&
                          getConfigValue(configNames.MR_PRINT_MODE) === "2" ? (
                            <ItemGrid xs={10} style={{ marginTop: 50 }}>
                              <SamplePDF
                                invoiceType={18}
                                printResp={this.state.printResp}
                                sendMail={true}
                                handlePOMail={this.handleMRMail}
                                mailId={this.state.mrNo}
                              />
                            </ItemGrid>
                          ) : null}

                          {this.state.mrStatus !== "21" &&
                          this.state.agentId !== "0" &&
                          this.state.agentId !== "" &&
                          this.state.agentId !== null &&
                          this.state.mrStatus !== "4" ? (
                            <ItemGrid xs={1}>
                              <IconButton
                                color={"info"}
                                right={true}
                                onClick={this.printAgentDocument.bind(this)}
                              >
                                <Print />
                              </IconButton>
                            </ItemGrid>
                          ) : null}

                          {this.state.mrStatus !== "21" ? (
                            <ItemGrid
                              xs={
                                this.state.agentId !== "0" &&
                                this.state.agentId !== "" &&
                                this.state.agentId !== null
                                  ? 12
                                  : 12
                              }
                            >
                              {getConfigValue(configNames.MR_PRINT_MODE) ===
                              "2" ? (
                                <SamplePDF
                                  printResp={this.state.printResp}
                                  invoiceType={18}
                                />
                              ) : (
                                <SamplePDF
                                  printDocument={this.printDocument.bind(this)}
                                />
                              )}
                            </ItemGrid>
                          ) : null}
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  timeFormat={false}
                                  closeOnSelect={true}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_GRP_ID) !== "99" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  url={
                                    serverApi.SEARCH_MR_SUPPLIER +
                                    "/" +
                                    getCookie(cons.COMPANY_ID)
                                  }
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                MR No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="searchMrNo"
                                value={this.state.searchMrNo}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                inputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_GRP_ID) !== "99" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Gate Entry No
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="entryNo"
                                  value={this.state.entryNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  variant="outlined"
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Vehicle No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.vehicleNo}
                                id={"vehicleNo"}
                                onSelect={this.onSelect}
                                stateKey="vehicleNo"
                                url={
                                  serverApi.SELCET_VEHICLE_LIST +
                                  getCookie(cons.COMPANY_ID) +
                                  "/" +
                                  getCookie(cons.CIPHER)
                                }
                                value={this.state.vehicleNo}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                PO No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="poNo"
                                value={this.state.poNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_GRP_ID) !== "99" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Agent
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.form_agentId}
                                  onSelect={this.onSelect}
                                  stateKey="form_agentId"
                                  // getName={this.getAgentName}
                                  url={
                                    serverApi.AGENTS_LIST +
                                    getCookie(cons.COMPANY_ID)
                                  }
                                  value={this.state.form_agentId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                staticData={
                                  getCookie(cons.USER_GRP_ID) == "99"
                                    ? statusOptions
                                    : null
                                }
                                defValue={this.state.status}
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.GET_MR_LIST}
                        searchData={
                          getCookie(cons.USER_GRP_ID) == "99"
                            ? this.getSupplierData()
                            : this.getData()
                        }
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"jute_mr_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  //print code
  printFreightChagesVocher = () => {
    var companyName = "Payment Vocher";

    var name = " ";
    if (this.state.transporter !== "" && this.state.transporter !== null) {
      name = this.state.transporter + "";
    } else if (this.state.driverName !== "" && this.state.driverName !== null)
      name = this.state.driverName + "";
    else if (this.state.supplierName !== "" && this.state.supplierName !== null)
      name = this.state.supplierName + "";

    var amount = this.state.frieghtPaid;

    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str.toUpperCase();
    }
    // doc.text(30,240,'RUPEES '+inWords(resp.debitCreditValue)+ ' ONLY.');

    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    doc.setFontType("bold");
    /*    doc.setDrawColor(0);
        doc.setFillColor(255,255,255);
        doc.rect(10, 5, 550, 300, 'FD');*/
    doc.text(20, 20, this.state.companyName);
    doc.setFontSize(14);

    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    doc.text(companyName, xOffset, 40);
    doc.setFontSize(10);
    doc.setFontType("normal");
    doc.text(450, 60, "MR No : ");
    doc.text(485, 60, this.state.mrNo + "");

    doc.line(485, 62, 550, 62);
    doc.text(450, 80, "Date : ");
    doc.text(480, 80, this.state.mrDate + "");

    doc.line(480, 82, 550, 82);
    doc.text(20, 110, "Paid To Sri/Smt/M/S");
    doc.text(125, 110, name);
    doc.line(120, 112, 550, 110);
    doc.text(20, 140, "A Sum Of Rupees");
    doc.text(105, 140, inWords(amount));
    doc.line(100, 142, 550, 142);
    doc.text(20, 170, "Towars(A/C)");
    doc.text(80, 170, name);
    doc.line(80, 172, 250, 172);
    doc.text(250, 170, "Cash/Check/DD no.");
    doc.text(350, 170, "Cash");
    doc.line(345, 172, 435, 172);
    doc.text(440, 170, "Rs.");
    doc.text(460, 170, amount + "");

    doc.line(455, 172, 550, 172);
    doc.text(20, 250, "Paid By signature");
    doc.text(230, 250, "Checked By signature");
    doc.text(450, 250, "Recipients By signature");

    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var user = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + user;
    doc.text(20, 300, newdat);
    doc.text(500, 300, printBy);

    doc.save("FreightVocher.pdf");
  };

  printAgentDocument() {
    getDataFromUrl(
      serverApi.JUUTE_MR_AGENT_PRINT_DETAILS +
        "/" +
        this.state.mrNo +
        "/" +
        getCookie(cons.CIPHER),
      this.handleAgentPrintResp
    );
  }

  printDocument() {
    getDataFromUrl(
      serverApi.JUUTE_MR_PRINT_DETAILS +
        "/" +
        this.state.mrNo +
        "/" +
        getCookie(cons.CIPHER),
      this.handlePrintResp
    );
  }
  handleAgentPrintResp = (resp) => {
    if (resp.agentPrintStatus) {
      var agentMrList = [];
      this.state.invoiceList.forEach((prop, i) => {
        var obj = {
          name: prop.itemDesc + " " + prop.quality,
          lineItemId: prop.lineItemId,
          rate: prop.rate,
        };
        agentMrList.push(obj);
      });
      this.setState({
        agentMrAlert: true,
        agentMrDate: this.state.mrDate,
        agentMrList: agentMrList,
      });
    } else {
      var printHeaderValues = resp.hdr;
      var modules = [];
      var modules = resp.lineItems;
      modules = modules.map((prop) => {
        var val1 = parseFloat(prop.claimCondition) + parseFloat(prop.claimDust);
        val1 = getDecimal(val1);
        var val2 = 0;
        if (
          parseFloat(prop.claimCondition) > parseFloat(prop.allowableMoisture)
        ) {
          val2 =
            parseFloat(prop.claimCondition) -
            parseFloat(prop.allowableMoisture) +
            parseFloat(prop.claimDust);
        } else {
          val2 = prop.claimDust;
        }
        if (val2 > 0)
          val1 = getDecimal(val1) + "\n (" + getDecimal(val2) + "%)";
        return {
          bales: prop.bales > 0 ? prop.bales : prop.loose,
          itemCode: prop.itemCode,
          advisedWeightKgs: prop.advisedWeightKgs,
          millWeightKgs: prop.millWeightKgs,
          shortageKgs: prop.shortageKgs,
          approvedWeight: prop.approvedWeight,
          ratePerQtls: prop.ratePerQtls,
          quality: prop.quality,
          claimCondition: val1,
          initial: prop.initial,
        };
      });
      // console.log('lineitems', modules);
      var printTotalvalues = resp.totalValues;
      // this.setState({
      //     printHeaderValues:header,
      //     printLineItems:printLineItems,
      //     printTotalvalues:totalvalues,
      // })

      // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
      //     var printHeaderValues=this.state.printHeaderValues;
      // }
      var pageNo = 1;

      var mrid = printHeaderValues.juteRecptNo;
      var mrid = mrid + " ";
      var mr_date = printHeaderValues.mrDate;
      mr_date = mr_date + " ";
      var poId = printHeaderValues.poNo;
      poId = poId + " ";
      var challan_no = printHeaderValues.challanNo;
      challan_no = challan_no + " ";
      var challan_date = printHeaderValues.challanDate;
      challan_date = challan_date + " ";
      var vehicleNo = printHeaderValues.lorryNo;
      vehicleNo = vehicleNo + " ";
      var SupplierName = printHeaderValues.supplierName;
      SupplierName = SupplierName + " ";
      var brokerName = printHeaderValues.brokerName;
      brokerName = brokerName + " ";
      var frightPaid = printHeaderValues.frightPaid;
      frightPaid = frightPaid + " ";
      var gateEntryDate = printHeaderValues.gateEntryDate;
      gateEntryDate = gateEntryDate + " ";
      var mukamName = printHeaderValues.mukamName;
      mukamName = mukamName + " ";

      var companyName =
        /* (printHeaderValues.agentName !== '') ? printHeaderValues.agentName + ' ' : */ printHeaderValues.companyName;
      var address1 =
        /*(printHeaderValues.agentName !== '') ? printHeaderValues.agentAddress1 + ' ' :*/ printHeaderValues.cAddress1;
      var address2 =
        /*(printHeaderValues.agentName !== '') ? printHeaderValues.agentAddress2 + ' ' :*/ printHeaderValues.cAddress2;

      var printcopy = "";
      var printCounnt = printHeaderValues.printCount;

      if (printCounnt == 0) {
        printcopy = "NOT A VALID";
      } else if (printCounnt == 1) {
        printcopy = "ORIGINAL";
      } else if (printCounnt == 2) {
        printcopy = "DUPLICATE";
      } else if (printCounnt == 3) {
        printcopy = "Triplicate";
      } else if (printCounnt == 4) {
        printcopy = "Quadruplicate";
      } else {
        printcopy = "COPY" + " " + printCounnt;
      }

      // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
      //      modules=this.state.printLineItems;
      //  }
      // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
      //   var  printTotalvalues=this.state.printTotalvalues;
      // }

      var addWaterMark = (doc, printcopy) => {
        var totalPages = doc.internal.getNumberOfPages();

        for (i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
          doc.setFontSize(24);
          doc.setTextColor(200);
          doc.globalAlpha = 0.5;
          //doc.zIndex(-1);
          doc.text(10, 30, printcopy);

          // doc.text(150, 600, printcopy,null,45);
        }

        return doc;
      };

      var config = {
        printHeaders: true,
        autoSize: true,
        margins: { left: 0, top: 0, bottom: 0, width: 0 },
        fontSize: 10,
      };
      const doc = new Jspdf("p", "pt", "a4");
      doc.setFontSize(16);
      var xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
      doc.text(companyName, xOffset, 40);
      doc.setFontSize(10);
      //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
      var TotalPgaeNo = doc.internal.getNumberOfPages();
      doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
      doc.setFontSize(10);
      doc.text(190, 60, address1);
      doc.text(250, 80, address2);
      doc.setFontSize(9);
      doc.setFontType("bold");
      doc.text(30, 120, "JUTE RECEIPT NO");
      doc.text(120, 120, ":");
      doc.setFontType("normal");
      doc.text(130, 120, mrid);
      doc.setFontType("bold");
      doc.text(400, 120, "MR DATE : ");
      doc.setFontType("normal");
      doc.text(450, 120, mr_date);
      doc.setFontType("bold");
      doc.text(30, 140, "M/S");
      doc.text(120, 140, ":");
      doc.setFontType("normal");
      doc.text(130, 140, SupplierName);
      doc.setFontType("bold");
      doc.text(30, 160, "SELLER/BROKER");
      doc.text(120, 160, ":");
      doc.setFontType("normal");
      doc.text(130, 160, brokerName);
      doc.setFontType("bold");
      doc.text(30, 180, "PO NO");
      doc.text(120, 180, ":");
      doc.setFontType("normal");
      doc.text(130, 180, poId);
      // doc.setFontType("bold");
      //doc.text(400, 180, 'DATE : ');
      //doc.setFontType("normal");
      // doc.text(440, 180, gateEntryDate );
      doc.setFontType("bold");
      doc.text(30, 200, "CHALLAN NO");
      doc.text(120, 200, ":");
      doc.setFontType("normal");
      doc.text(130, 200, challan_no);
      doc.setFontType("bold");
      doc.text(365, 200, "CHALLAN DATE :");
      doc.setFontType("normal");
      doc.text(445, 200, challan_date);
      doc.setFontType("bold");
      doc.text(30, 220, "LORRY NO");
      doc.text(120, 220, ":");
      doc.setFontType("normal");
      doc.text(130, 220, vehicleNo);
      doc.setFontType("bold");
      doc.text(250, 240, "EX :");
      doc.setFontType("normal");
      doc.text(270, 240, mukamName);
      doc.text(
        60,
        260,
        "BALANCE LORRY FRIGHT PAID AT MILL RS" + " " + frightPaid
      );
      doc.text(30, 280, "Dear Sir,");
      doc.setFontSize(9);
      doc.text(
        30,
        300,
        "Against the above we have received the consigment as follow:"
      );
      doc.setFontSize(8);
      doc.setFontType("bold");
      doc.cellInitialize();
      var Bales_drums = doc.splitTextToSize("Bales/drums", 28);
      doc.cell(30, 310, 40, 40, Bales_drums, "", "C");
      var Marks_Qualiy = doc.splitTextToSize("Marks&Quality", 33);
      doc.cell(70, 310, 100, 40, Marks_Qualiy, "", "C");
      var advised_weight = doc.splitTextToSize("Advised weight in Kgs", 35);
      doc.cell(170, 310, 40, 40, advised_weight, "", "C");
      var mill_weight = doc.splitTextToSize("Mill weight in Kgs", 35);
      doc.cell(210, 310, 40, 40, mill_weight, "", "C");
      var shortage = doc.splitTextToSize("Claim in Kgs", 40);
      doc.cell(310, 310, 40, 40, shortage, "", "C");
      var Approvedweight = doc.splitTextToSize("Approved  Weight", 40);
      doc.cell(250, 310, 42, 40, Approvedweight, "", "C");
      var rate = doc.splitTextToSize("Rate per Qtls Rs", 35);
      doc.cell(400, 310, 40, 40, rate, "", "C");

      doc.cell(
        450,
        310,
        100,
        20,
        "                 CLAIM FOR        ",
        "",
        "C"
      );
      doc.cell(460, 310, 50, 20, " ", "", "C");
      /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
              doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
      doc.cellInitialize();
      doc.cell(372, 330, 50, 20, "QUALITY", "", "C");
      doc.cell(500, 330, 50, 20, "CONDITION", "", "C");
      doc.cell(550, 330, 50, 20, "INITIAL", "", "C");
      doc.cellInitialize();

      var cellWidths = ["", 40, 100, 40, 40, 40, 42, 40, 50, 50, 50, 50, 50];
      var cellTextaligns = [
        "",
        "right",
        "left",
        "right",
        "right",
        "right",
        "right",
        "right",
        "right",
        "left",
        "right",
        "right",
        "right",
      ];
      doc.setFontType("normal");
      doc.setFontSize(7);
      {
        var k = 325;
        var i = 0;
        modules.map((prop, key) => {
          k = k + 25;
          i = i + 1;
          doc.cellInitialize();
          if (i === 27) {
            k = 325;
            i = 0;
            pageNo = pageNo + 1;
            var today = moment().format("DD/MM/YYYY H:mm:ss");
            var name = getCookie(cons.USERID);
            var newdat = " Printed on: " + today;
            var printBy = " Printed By: " + name;
            doc.text(30, 800, newdat);
            doc.text(500, 800, printBy);
            doc.addPage("a4", "p");

            doc.setFontType("bold");
            doc.cellInitialize();
            var Bales_drums = doc.splitTextToSize("Bales/drums", 28);
            doc.cell(30, 310, 40, 40, Bales_drums, "", "C");
            var Marks_Qualiy = doc.splitTextToSize("Marks&Quality", 33);
            doc.cell(70, 310, 100, 40, Marks_Qualiy, "", "C");
            var advised_weight = doc.splitTextToSize(
              "Advised weight in Kgs",
              35
            );
            doc.cell(170, 310, 40, 40, advised_weight, "", "C");
            var mill_weight = doc.splitTextToSize("Mill weight in Kgs", 35);
            doc.cell(210, 310, 40, 40, mill_weight, "", "C");
            var shortage = doc.splitTextToSize("Claim in Kgs", 40);
            doc.cell(310, 310, 40, 40, shortage, "", "C");
            var Approvedweight = doc.splitTextToSize("Approved  Weight", 40);
            doc.cell(250, 310, 42, 40, Approvedweight, "", "C");
            var rate = doc.splitTextToSize("Rate per Qtls Rs", 35);
            doc.cell(400, 310, 40, 40, rate, "", "C");

            doc.cell(
              450,
              310,
              100,
              20,
              "                 CLAIM FOR        ",
              "",
              "C"
            );
            doc.cell(460, 310, 50, 20, " ", "", "C");
            /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
            doc.cellInitialize();
            doc.cell(372, 330, 50, 20, "QUALITY", "", "C");
            doc.cell(500, 330, 50, 20, "CONDITION", "", "C");
            doc.cell(550, 330, 50, 20, "INITIAL", "", "C");

            doc.setFontType("normal");
            doc.setFontSize(7);
          }
          var a = 0;
          return Object.keys(prop).map((key, index) => {
            a = a + 1;
            if (prop[key] == 0 || prop[key] == 0.0) {
              var myItem = " ";
            } else {
              if (key === "itemCode") {
                var size = 90;
              } else {
                var size = 45;
              }
              var myItem = prop[key] + " ";
              myItem = doc.splitTextToSize(myItem, size);
            }

            return doc.cell(
              30,
              k,
              cellWidths[a],
              25,
              myItem,
              2,
              cellTextaligns[a]
            );
          });
        });
      }
      var grandTotals = printTotalvalues;
      if (grandTotals.balesTot == 0 || grandTotals.balesTot == 0.0) {
        var balesTot = " ";
      } else {
        var balesTot = getDecimal(grandTotals.balesTot);
      }
      if (
        grandTotals.advisedWeightTot == 0 ||
        grandTotals.advisedWeightTot == 0.0
      ) {
        var advisedWeightTot = " ";
      } else {
        var advisedWeightTot = getDecimal(grandTotals.advisedWeightTot);
      }
      if (grandTotals.millWeightTot == 0 || grandTotals.millWeightTot == 0.0) {
        var millWeightTot = " ";
      } else {
        var millWeightTot = getDecimal(grandTotals.millWeightTot);
      }
      if (grandTotals.shortageTot == 0 || grandTotals.shortageTot == 0.0) {
        var shortageTot = " ";
      } else {
        var shortageTot = getDecimal(grandTotals.shortageTot);
      }
      if (
        grandTotals.approvedWeightTot == 0 ||
        grandTotals.approvedWeightTot == 0.0
      ) {
        var approvedWeightTot = " ";
      } else {
        var approvedWeightTot = getDecimal(grandTotals.approvedWeightTot);
      }

      doc.cellInitialize();
      doc.setFontType("bold");
      doc.cell(30, k + 25, 40, 30, balesTot + " ", "", "right");
      doc.cell(105, k + 25, 100, 30, " ", "", "right");
      doc.cell(147, k + 25, 40, 30, advisedWeightTot + " ", "", "right");
      doc.cell(189, k + 25, 40, 30, millWeightTot + " ", "", "right");
      doc.cell(231, k + 25, 40, 30, shortageTot + " ", "", "right");
      doc.cell(189, k + 25, 42, 30, approvedWeightTot + " ", "", "right");
      doc.cell(271, k + 25, 40, 30, " ", "", "right");
      doc.cell(271, k + 25, 50, 30, " ", "", "right");
      doc.cell(322, k + 25, 50, 30, " ", "", "right");
      doc.cell(364, k + 25, 50, 30, " ", "", "right");

      doc.setFontType("normal");
      doc.setFontSize(10);

      doc.text(350, k + 120, "FOR" + " " + companyName);
      //doc.text(400, k + 180, 'Authorised Signature');
      doc.setFontSize(8);
      doc.setFontType("bold");
      var note =
        "Note* : This is a computer generated print, Signature is not required.";
      var NotexOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(note) * doc.internal.getFontSize()) / 2;
      doc.text(note, NotexOffset, k + 200);

      //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
      doc.setFontType("normal");
      doc.setFontSize(7);
      var today = moment().format("DD/MM/YYYY H:mm:ss");
      var name = getCookie(cons.USERID);
      var newdat = " Printed on: " + today;
      var printBy = " Printed By: " + name;
      doc.text(30, k + 250, newdat);
      doc.text(500, k + 250, printBy);
      addWaterMark(doc, printcopy);
      doc.save("MR_" + mrid + ".pdf");
    }
  };

  handlePrintResp = (resp) => {
    this.setState({ printResp: resp });
    var printHeaderValues = resp.hdr;
    var modules = [];
    var modules = resp.lineItems.map((prop) => {
      var val1 = parseFloat(prop.claimCondition) + parseFloat(prop.claimDust);
      val1 = getDecimal(val1);
      var val2 = 0;
      if (
        parseFloat(prop.claimCondition) > parseFloat(prop.allowableMoisture)
      ) {
        val2 =
          parseFloat(prop.claimCondition) -
          parseFloat(prop.allowableMoisture) +
          parseFloat(prop.claimDust);
      } else {
        val2 = prop.claimDust;
      }
      if (val2 > 0) val1 = getDecimal(val1) + "\n (" + getDecimal(val2) + "%)";
      return {
        bales: prop.bales > 0 ? prop.bales : prop.loose,
        itemCode: prop.itemCode,
        advisedWeightKgs: getDecimal(prop.advisedWeightKgs, 2),
        millWeightKgs: getDecimal(prop.millWeightKgs, 2),
        shortageKgs: 0,
        approvedWeight: getDecimal(prop.approvedWeight, 2),
        ratePerQtls: getDecimal(prop.ratePerQtls, 2),
        quality: prop.quality,
        claimCondition: val1,
        initial: "",
      };
    });
    console.log("lineitems", modules);
    var printTotalvalues = resp.totalValues;
    // this.setState({
    //     printHeaderValues:header,
    //     printLineItems:printLineItems,
    //     printTotalvalues:totalvalues,
    // })

    // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
    //     var printHeaderValues=this.state.printHeaderValues;
    // }
    var pageNo = 1;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.mrDate;
    mr_date = mr_date + " ";
    var poId = printHeaderValues.poNo;
    poId = poId + " ";
    var challan_no = printHeaderValues.challanNo;
    challan_no = challan_no + " ";
    var challan_date = printHeaderValues.challanDate;
    challan_date = challan_date + " ";
    var vehicleNo = printHeaderValues.lorryNo;
    vehicleNo = vehicleNo + " ";
    var SupplierName = printHeaderValues.supplierName;
    SupplierName = SupplierName + " ";
    var brokerName = printHeaderValues.brokerName;
    brokerName = brokerName + " ";
    var frightPaid = printHeaderValues.frightPaid;
    frightPaid = frightPaid + " ";
    var gateEntryDate = printHeaderValues.gateEntryDate;
    gateEntryDate = gateEntryDate + " ";
    var mukamName = printHeaderValues.mukamName;
    mukamName = mukamName + " ";
    var companyName = getCookie(cons.COMPANY_TITLE) + " ";
    var address1 = getCookie(cons.COMPANY_ADDRESS);
    var address2 = getCookie(cons.COMPANY_ADDRESS2);
    var cinNo = getCookie(cons.COMPANY_CIN);

    /* var companyName=/!*(printHeaderValues.agentName!=='' )? printHeaderValues.agentName +' ' :*!/printHeaderValues.companyName;
        var address1=/!*(printHeaderValues.agentName!=='')? printHeaderValues.agentAddress1 +' ' :*!/printHeaderValues.cAddress1;
        var address2=/!*(printHeaderValues.agentName!=='')? printHeaderValues.agentAddress2 + ' ' :*!/printHeaderValues.cAddress2;
*/
    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt == 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt == 1) {
      printcopy = "HO COPY";
    } else if (printCounnt == 2) {
      printcopy = "MILL COPY";
    } else if (printCounnt == 3) {
      printcopy = "PARTY COPY 1";
    } else if (printCounnt == 4) {
      printcopy = "PARTY COPY 2";
    } else {
      printcopy = "COPY" + " " + printCounnt;
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(16);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 20, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    if (
      getCookie(cons.COMPANY_LOGO) !== "undefined" &&
      getCookie(cons.COMPANY_LOGO) !== "null" &&
      getCookie(cons.COMPANY_LOGO) !== undefined
    ) {
      var imgData = getCookie(cons.COMPANY_LOGO);
      doc.setFontSize(16);
      doc.addImage(imgData, "JPEG", 15, 20, 120, 50);
    }
    var k = 30;

    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    companyName = doc.splitTextToSize(companyName, 400);
    doc.setFontType("bold");
    doc.text(580, k, companyName, "right");
    var ht = doc.getTextDimensions(companyName).h;
    k = k + ht;

    //doc.text(companyName, xOffset, k);
    doc.setFontSize(10);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    //   doc.text(500, k, "Page " + pageNo + " of " + TotalPgaeNo + "");
    //   k=k+10;
    if (address1 !== "null" && address1 !== null && address1 !== undefined) {
      var Add1Off =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(address1) * doc.internal.getFontSize()) / 2;
      address1 = doc.splitTextToSize(address1, 400);
      doc.text(580, k, address1, "right");
      //  doc.text(address1, Add1Off, k);
      var ht = doc.getTextDimensions(address1).h;
      k = k + ht;
    }
    if (address2 !== null && address2 !== "null" && address2 !== undefined) {
      var Add2Off =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(address2) * doc.internal.getFontSize()) / 2;
      address2 = doc.splitTextToSize(address2, 400);
      doc.text(580, k, address2, "right");
      var ht = doc.getTextDimensions(address2).h;
      k = k + ht;
      //  doc.text(address2, Add2Off, k);
      //  k = k + 10;
    }

    var address3 = "";
    if (
      getCookie(cons.COMPANY_CONTACT_NO) !== "undefined" &&
      getCookie(cons.COMPANY_CONTACT_NO) !== "null" &&
      getCookie(cons.COMPANY_CONTACT_NO) !== undefined
    ) {
      address3 = "Ph.No: " + getCookie(cons.COMPANY_CONTACT_NO) + " ";
    }
    if (
      getCookie(cons.COMPANY_MAIL_ID) !== "undefined" &&
      getCookie(cons.COMPANY_MAIL_ID) !== "null" &&
      getCookie(cons.COMPANY_MAIL_ID) !== undefined
    ) {
      address3 = address3 + "Email: " + getCookie(cons.COMPANY_MAIL_ID) + " ";
    }
    if (
      getCookie(cons.COMPANY_CIN) !== "undefined" &&
      getCookie(cons.COMPANY_CIN) !== "null" &&
      getCookie(cons.COMPANY_CIN) !== undefined
    ) {
      address3 = address3 + "\nCIN No: " + cinNo; //doc.setFontType("bold");
    }
    if (
      getCookie(cons.COMPANY_GST) !== "undefined" &&
      getCookie(cons.COMPANY_GST) !== "null" &&
      getCookie(cons.COMPANY_GST) !== undefined
    ) {
      address3 = address3 + "\nGST No: " + getCookie(cons.COMPANY_GST); //doc.setFontType("bold");
    }
    if (address3 !== "") {
      var Add3Off =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(address3) * doc.internal.getFontSize()) / 2;
      address3 = doc.splitTextToSize(address3, 400);
      doc.text(580, k, address3, "right");
      var ht = doc.getTextDimensions(address3).h;
      k = k + ht;
      //  doc.text(address3, Add3Off, k);
      //k = k + 10;
    }
    doc.setFontSize(16);
    var title = "Material Receipt";
    var xOffset1 =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
    k = k + 10;

    doc.text(title, xOffset1, k);
    /*  var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize() / 2);
        doc.text(companyName, xOffset, 40);
        doc.setFontSize(10);
        //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
        var TotalPgaeNo = doc.internal.getNumberOfPages();
        doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
        doc.setFontSize(10);
        var xOffset1 = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(address1) * doc.internal.getFontSize() / 2);
        address1=doc.splitTextToSize(address1,300);
        doc.text(address1, xOffset1, 60);
      //  doc.text(190, 60, address1);
        doc.text(250, 80, address2);*/
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(30, 120, "MR NO");
    doc.text(120, 120, ":");
    doc.setFontType("normal");
    doc.text(130, 120, mrid);
    doc.setFontType("bold");
    doc.text(400, 120, "MR DATE : ");
    doc.setFontType("normal");
    doc.text(450, 120, mr_date);
    doc.setFontType("bold");
    doc.text(30, 140, "M/S");
    doc.text(120, 140, ":");
    doc.setFontType("normal");
    doc.text(130, 140, SupplierName);
    doc.setFontType("bold");
    doc.text(30, 160, "SELLER/BROKER");
    doc.text(120, 160, ":");
    doc.setFontType("normal");
    doc.text(130, 160, brokerName);
    doc.setFontType("bold");
    doc.text(30, 180, "PO NO");
    doc.text(120, 180, ":");
    doc.setFontType("normal");
    doc.text(130, 180, poId);
    doc.setFontType("bold");
    doc.text(400, 180, "PO DATE :");
    doc.setFontType("normal");
    doc.text(450, 180, this.state.poDate + "");
    // doc.setFontType("bold");
    //doc.text(400, 180, 'DATE : ');
    //doc.setFontType("normal");
    // doc.text(440, 180, gateEntryDate );
    if (this.state.agentId === "" || this.state.agentId === null) {
      doc.setFontType("bold");
      doc.text(30, 200, "CHALLAN NO");
      doc.text(120, 200, ":");
      doc.setFontType("normal");
      doc.text(130, 200, challan_no);
      doc.setFontType("bold");

      doc.text(365, 200, "CHALLAN DATE :");
      doc.setFontType("normal");
      doc.text(445, 200, challan_date);
    }
    doc.setFontType("bold");
    if (this.state.agentId !== "" && this.state.agentId !== null)
      doc.text(30, 220, "VEHICLE NO");
    else doc.text(30, 220, "LORRY NO");
    doc.text(120, 220, ":");
    doc.setFontType("normal");
    if (this.state.agentId !== "" && this.state.agentId !== null)
      doc.text(130, 220, "HAND CART");
    else doc.text(130, 220, vehicleNo);
    doc.setFontType("bold");
    doc.text(250, 240, "EX :");
    doc.setFontType("normal");
    doc.text(270, 240, mukamName);
    doc.text(
      60,
      260,
      "BALANCE LORRY FRIGHT PAID AT MILL RS" + " " + frightPaid
    );
    doc.text(30, 280, "Dear Sir,");
    doc.setFontSize(9);
    doc.text(
      30,
      300,
      "Against the above we have received the consigment as follow:"
    );
    doc.setFontSize(8);
    doc.setFontType("bold");
    doc.cellInitialize();
    var Bales_drums = doc.splitTextToSize("Bales/drums", 28);
    doc.cell(30, 310, 40, 40, Bales_drums, "", "C");
    var Marks_Qualiy = doc.splitTextToSize("Marks&Quality", 33);
    doc.cell(70, 310, 100, 40, Marks_Qualiy, "", "C");
    var advised_weight = doc.splitTextToSize("Advised weight in Kgs", 35);
    doc.cell(170, 310, 40, 40, advised_weight, "", "C");
    var mill_weight = doc.splitTextToSize("Mill weight in Kgs", 35);
    doc.cell(210, 310, 40, 40, mill_weight, "", "C");
    var shortage = doc.splitTextToSize("Claim in Kgs", 40);
    doc.cell(310, 310, 40, 40, shortage, "", "C");
    var Approvedweight = doc.splitTextToSize("Approved  Weight", 40);
    doc.cell(250, 310, 42, 40, Approvedweight, "", "C");
    var rate = doc.splitTextToSize("Rate per Qtls Rs", 35);
    doc.cell(400, 310, 40, 40, rate, "", "C");

    doc.cell(450, 310, 130, 20, "                 CLAIM FOR        ", "", "C");
    doc.cell(460, 310, 50, 20, " ", "", "C");
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.cell(372, 330, 80, 20, "QUALITY", "", "C");
    doc.cell(500, 330, 50, 20, "CONDITION", "", "C");
    doc.cell(550, 330, 50, 20, "INITIAL", "", "C");
    doc.cellInitialize();

    var cellWidths = ["", 40, 100, 40, 40, 40, 42, 40, 80, 50, 50];
    var cellTextaligns = [
      "",
      "right",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "left",
      "left",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 320;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 30;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 320;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var Bales_drums = doc.splitTextToSize("Bales/drums", 28);
          doc.cell(30, 310, 40, 40, Bales_drums, "", "C");
          var Marks_Qualiy = doc.splitTextToSize("Marks&Quality", 33);
          doc.cell(70, 310, 100, 40, Marks_Qualiy, "", "C");
          var advised_weight = doc.splitTextToSize("Advised weight in Kgs", 35);
          doc.cell(170, 310, 40, 40, advised_weight, "", "C");
          var mill_weight = doc.splitTextToSize("Mill weight in Kgs", 35);
          doc.cell(210, 310, 40, 40, mill_weight, "", "C");
          var shortage = doc.splitTextToSize("Claim in Kgs", 40);
          doc.cell(310, 310, 40, 40, shortage, "", "C");
          var Approvedweight = doc.splitTextToSize("Approved  Weight", 40);
          doc.cell(250, 310, 42, 40, Approvedweight, "", "C");
          var rate = doc.splitTextToSize("Rate per Qtls Rs", 35);
          doc.cell(400, 310, 40, 40, rate, "", "C");

          doc.cell(
            450,
            310,
            130,
            20,
            "                 CLAIM FOR        ",
            "",
            "C"
          );
          doc.cell(460, 310, 50, 20, " ", "", "C");
          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.cell(372, 330, 80, 20, "QUALITY", "", "C");
          doc.cell(500, 330, 50, 20, "CONDITION", "", "C");
          doc.cell(550, 330, 50, 20, "INITIAL", "", "C");

          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          var size = 45;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemCode") {
              var size = 90;
            } else if (key === "quality") {
              size = 80;
            } else {
              size = 45;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
          }

          return doc.cell(
            30,
            k,
            cellWidths[a],
            30,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    var grandTotals = printTotalvalues;
    if (grandTotals.balesTot == 0 || grandTotals.balesTot == 0.0) {
      var balesTot = " ";
    } else {
      var balesTot = getDecimal(grandTotals.balesTot);
    }
    if (
      grandTotals.advisedWeightTot == 0 ||
      grandTotals.advisedWeightTot == 0.0
    ) {
      var advisedWeightTot = " ";
    } else {
      var advisedWeightTot = getDecimal(grandTotals.advisedWeightTot);
    }
    if (grandTotals.millWeightTot == 0 || grandTotals.millWeightTot == 0.0) {
      var millWeightTot = " ";
    } else {
      var millWeightTot = getDecimal(grandTotals.millWeightTot);
    }
    if (grandTotals.shortageTot == 0 || grandTotals.shortageTot == 0.0) {
      var shortageTot = " ";
    } else {
      var shortageTot = getDecimal(grandTotals.shortageTot);
    }
    if (
      grandTotals.approvedWeightTot == 0 ||
      grandTotals.approvedWeightTot == 0.0
    ) {
      var approvedWeightTot = " ";
    } else {
      var approvedWeightTot = getDecimal(grandTotals.approvedWeightTot);
    }

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(30, k + 30, 40, 30, balesTot + " ", "", "right");
    doc.cell(105, k + 30, 100, 30, " ", "", "right");
    doc.cell(147, k + 30, 40, 30, advisedWeightTot + " ", "", "right");
    doc.cell(189, k + 30, 40, 30, millWeightTot + " ", "", "right");
    doc.cell(231, k + 30, 40, 30, shortageTot + " ", "", "right");
    doc.cell(189, k + 30, 42, 30, approvedWeightTot + " ", "", "right");
    doc.cell(271, k + 30, 40, 30, " ", "", "right");
    doc.cell(271, k + 30, 80, 30, " ", "", "right");
    doc.cell(322, k + 30, 50, 30, " ", "", "right");
    doc.cell(364, k + 30, 50, 30, " ", "", "right");

    doc.setFontType("normal");
    doc.setFontSize(10);

    doc.text(350, k + 120, "FOR" + " " + companyName);
    //doc.text(400, k + 180, 'Authorised Signature');
    doc.setFontSize(8);
    doc.setFontType("bold");
    var note =
      "Note* : This is a computer generated print, Signature is not required.";
    var NotexOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(note) * doc.internal.getFontSize()) / 2;
    doc.text(note, NotexOffset, k + 200);

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 250, newdat);
    doc.text(500, k + 250, printBy);
    addWaterMark(doc, printcopy);

    if (this.state.sendMail) {
      const filename = "MR_" + mrid + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      const formData = new FormData();
      formData.append("mrId", this.state.mrNo);
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", getCookie(cons.COMPANY_ID));
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("mrId", this.state.mrNo);
      formData.append("fileName", filename);

      var URL = serverApi.MR_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handleMRMail);

      this.setState({
        sendMail: false,
      });
    } else {
      doc.save("MR_" + mrid + ".pdf");
    }
  };
  handleMRMail = (resp) => {
    console.log(resp);
    if (resp.status) {
      if (this.state.finalApprover && this.state.mrStatus !== "3") {
        this.showMessage(this.state.finalApprovalMsg, resp.status);
        this.setState({
          finalApprovalMsg: "",
        });
      } else this.showMessage(resp.message, resp.status);
    } else {
      if (this.state.finalApprover && this.state.mrStatus !== "3") {
        this.showMessage(this.state.finalApprovalMsg, true);
        this.setState({
          finalApprovalMsg: "",
        });
      } else {
        this.showMessage(resp.message, resp.status);
      }
    }
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {
    border: "1px solid #00ADBC",
    borderRadius: "4px",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      border: "2px solid #0ADBC",
      // borderColor: '#D2D2D2',
    },
  },
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(JuteMR);
