import React from "react";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Add from "@material-ui/icons/Add";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import Popup from "../../components/Popup/PopUp";
import {
  getDataFromUrl,
  getFile,
  requestList,
  uploadFormdataWithImage,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import TextField from "../../components/CustomInput/CustomTextField";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import { VIEW } from "../../ui-actions/UiActions";
import InputAdornment from "material-ui/Input/InputAdornment";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import { roseColor } from "../../assets/jss/material-dashboard-pro-react";
import exportFromJSON from "export-from-json";

class PayRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment().format("YYYY-MM-DD"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      isRefresh: false,
      isLoading: false,
      showPopUp: false,
      itemViewType: "issueObj",
      year: moment().format("YYYY"),
      dataTable: null,
      name: "",
      payschemeId: "",
      payschemeIds: "",
      fDate: "",
      tDate: "",
      branchId: "",
      EducationList: [],
      documentsList: [],
      startIndex: 1,
      lastIndex: 10,
      payPeriodTable: null,
      statusList: "",
      selectedPayscheme: "",
      selectedPayStatus: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  handlePage = (start, end) => {
    this.setState({
      startIndex: start,
      lastIndex: end,
    });
    this.callgetPayschemeProcessingRequestList(start, end);
    //this.handleSeacrh();
  };
  componentDidMount() {
    this.callPaySchemeList();
    this.callgetPayschemeProcessingRequestList(
      this.state.startIndex,
      this.state.lastIndex
    );
  }

  callPaySchemeList() {
    var data = {
      status: "32",
      activeStatus: "",
      isCompanyFiltered: "Y",
    };
    requestList(serverApi.PAY_SCHEME_GET_LIST, data, this.handlePaySchemeList);
  }

  handlePaySchemeList = (resp) => {
    var payscheme = [];
    resp.map((prop, i) => {
      var obj = {
        value: prop.id,
        name: prop.name,
        label: prop.name,
      };
      payscheme.push(obj);
    });
    this.setState({ paySchemeList: payscheme });
  };

  callgetPayschemeProcessingRequestList(startIndex, lastIndex) {
    var data = {
      fromDate:
        this.state.fromDates !== undefined
          ? this.dateChanged(this.state.fromDates)
          : "",
      toDate:
        this.state.toDates !== undefined
          ? this.dateChanged(this.state.toDates)
          : "",
      startIndex: startIndex,
      lastIndex: lastIndex,
      status: this.state.statusList,
    };
    requestList(serverApi.GET_PAYPERIOD_LIST, data, this.handleList);
  }

  handleList = (resp) => {
    var TableData = {
      data: resp.data,
      filterTable: true,
      totalRecords: resp.totRecs,
      column: [
        {
          filterable: false,
          Header: "Rec Id",
          accessor: "id",
          minWidth: 40,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Payscheme Name",
          accessor: "payscheme",
          minWidth: 80,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "FromDate",
          accessor: "fromDateDesc",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "ToDate",
          accessor: "toDateDesc",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Wage Type",
          accessor: "wageType",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Net Pay",
          accessor: "netPay",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Status",
          accessor: "status",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: false,
        },
      ],
    };
    this.setState({
      dataTable: TableData,
      isRefresh: true,
    });
  };

  handleViewClick = (obj) => {
    var obj = JSON.parse(obj);
    this.setState({
      selectedPayscheme: obj.paySchemeId,
      selectedPayStatus: obj.status,
    });

    getDataFromUrl(
      serverApi.PAY_PERIOD_BYID + "/" + obj.id + "/" + getCookie(cons.USERID),
      this.handleGetById
    );
    getDataFromUrl(
      serverApi.GET_PAY_PROCESSED_LIST + "/" + obj.paySchemeId + "/" + obj.id,
      this.handlePopUp
    );
  };
  handleExcelExport = (obj) => {
    var obj = JSON.parse(obj);
    getDataFromUrl(
      serverApi.GET_PAY_PROCESSED_LIST + "/" + obj.paySchemeId + "/" + obj.id,
      this.handlePayProcessedList
    );
  };
  handleGetById = (resp) => {
    this.setState({
      approveButton: resp.approveButton,
      status: resp.status,
      id: resp.id,
      //isCreated: !this.state.isCreated,
      // fDate:frDate,
      // tDate:toDate,
      // payschemeId:resp.paySchemeId,
      // branchId:resp.branchId
    });
  };
  handlePopUp = (resp) => {
    console.log("&&&", resp);
    var dataTable = resp;
    if (dataTable !== null) {
      var columns = dataTable.column.map((prop, key) => {
        var obj = prop;
        obj.filterable = true;
        obj.sortable = false;
        obj.minWidth = 150;
        obj.maxWidth = 250;
        // delete obj.minWidth;
        // delete obj.maxWidth;
        return obj;
      });
    }
    dataTable.data.column = columns;
    if (resp.status === undefined) {
      this.setState({
        payPeriodTable: dataTable,
        isRefresh: true,
        showPopUp: true,
      });
    }
  };

  handlePayProcessedList = (resp) => {
    console.log("TableDataPayPeriod", resp);
    if (resp.data.length > 0) {
      var data = resp.data;
      var fileName = "Pay_List_By_Id";
      var exportType = "xls";
      exportFromJSON({ data, fileName, exportType });
    } else {
      this.basicAlert("There is No data!!!");
    }
    // if(resp.status===undefined){
    //     this.setState({
    //         payPeriodTable: resp,
    //         isRefresh: true,
    //         showPopUp: true
    //     })
    // }
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh)
      this.callgetPayschemeProcessingRequestList(
        this.state.startIndex,
        this.state.lastIndex
      );

    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      showPopUp: false,
      fDate: "",
      tDate: "",
    });
    //  this.hideAlertMessage();
  };
  handlePopUP = () => {
    this.setState({
      // isCreated: !this.state.isCreated,
      showPopUp: false,
    });
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object") {
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    } else if (id !== "date1") {
      this.setState({
        [id]: "",
      });
    }
    // this.dateChanged();
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    // if
    // var day=myDate[2].split("T")
    var newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
    return newDate;
  };
  // dateFormat=(date)=>{
  //     var myDate = date;
  //     myDate = myDate.split("-");
  //     var day=myDate[2].split("T")
  //     var newDate = day[0] + "-" + myDate[1] + "-" + myDate[0];
  //      return newDate;

  // }

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      isCreated: false,
      payschemeId: "",
      fDate: "",
      tDate: "",
      branchId: "",
    });
  }

  hideAlertMessage() {
    this.callgetPayschemeProcessingRequestList(
      this.state.startIndex,
      this.state.lastIndex
    );
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      payschemeId: "",
      name: "",
      fDate: "",
      tDate: "",
      branchId: "",
      selectedPayscheme: "",
      selectedPayStatus: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { fDate, tDate, payschemeId, branchId } = this.state;
      var data = {
        fromDate: this.dateChanged(fDate),
        toDate: this.dateChanged(tDate),
        paySchemeId: payschemeId,
        createdBy: getCookie(cons.USERID),
        branchId: branchId,
      };

      if (
        fDate !== "" &&
        tDate !== "" &&
        payschemeId !== "" &&
        branchId !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.PAY_PERIOD_ADD, data, this.handleAddPayRegister);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  onApproveButtonClick = (status) => {
    var data = {
      id: this.state.id,
      userId: getCookie(cons.USERID),
      status: status,
    };

    requestList(serverApi.UPDATE_PAY_PERIOD, data, this.handleUpddatePayPeriod);
  };

  handleUpddatePayPeriod = (resp) => {
    if (resp.status) {
      this.setState({ showPopUp: false, isCreated: false });
      this.callgetPayschemeProcessingRequestList(
        this.state.startIndex,
        this.state.lastIndex
      );

      // console.log("resp " + JSON.stringify(resp));
      // this.showMessage(resp.message, resp.status)
    }
  };

  handleAddPayRegister = (resp) => {
    if (resp.status) {
      var data = new FormData();
      data.append("multipartFile", this.state.file);
      this.showMessage(resp.message, true);

      uploadFormdataWithImage(
        serverApi.UPLOAD_OR_PAY_PROCESS_PAY +
          "/" +
          this.state.payschemeId +
          "/" +
          resp.id,
        data,
        this.handleAddPayPeriod
      );
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  handleAddPayPeriod = (resp) => {
    // this.showMessage(resp.message, true);
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 8) {
      return length;
    } else {
      return 8;
    }
  };
  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (fileType === "XLSX" || fileType === "XLS") {
        this.setState({
          file: file,
          itemName: file.name,
          fileType: fileType,
          //filesList: arr,
          imagePreviewUrl: reader.result,
        });
        console.log("filename", this.state.itemName);
        //  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }
  onGenerateInvoiceClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { payPeriodTable, selectedPayscheme } = this.state;
      var list = [];
      payPeriodTable.data.forEach((prop, i) => {
        var obj = {};
        if (i === 0) {
          obj = {
            designation: prop.DESIGNATION,
            qty: 1,
            amount: parseFloat(prop["NET PAYBLE"]),
          };
          list.push(obj);
        } else {
          var hasDesig = false;
          list.forEach((p, k) => {
            if (p.designation == prop.DESIGNATION) {
              list[k].qty = list[k].qty + 1;
              list[k].amount = list[k].amount + parseFloat(prop["NET PAYBLE"]);
              hasDesig = true;
            }
          });
          if (!hasDesig) {
            obj = {
              designation: prop.DESIGNATION,
              qty: 1,
              amount: parseFloat(prop["NET PAYBLE"]),
            };
            list.push(obj);
          }
        }
      });
      var data = {
        paySchemeId: selectedPayscheme,
        userId: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        designation: list,
      };
      requestList(serverApi.GENERATE_INVOICE, data, this.handleInvoice);
    }
  };
  handleInvoice = (resp) => {
    this.showMessage(resp.message, resp.status);
  };

  render() {
    const { classes } = this.props;
    const inputFocus = {
      autoComplete: "off",
    };
    const statusoptions = [
      { value: "", name: "select...", label: "select..." },
      { value: 2, name: "INPROGRESS", label: "INPROGRESS" },
      { value: 3, name: "APPROVED", label: "APPROVED" },
      { value: 28, name: "PROCESSED", label: "PROCESSED" },
      { value: 30, name: "DATA COLLECTED", label: "DATA COLLECTED" },
      { value: 32, name: "LOCKED", label: "LOCKED" },
    ];

    var yesterday = Datetime.moment().subtract(0, "day");

    var valid = function(current) {
      if (getCookie(cons.COMPANY_ID) !== "2")
        return current.isBefore(yesterday);
      else return true;
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.basicAlert}
        {this.state.isCreated ? (
          <Popup
            onClickSave={() => this.onCreateButtonClick()}
            closePopup={() => this.handleCreate()}
            title="Post Temperature"
            isShowSave={true}
            isShowCancel={true}
            save_btn_txt={"Add"}
            cancel_button_text={"Cancel"}
            content={
              <GridContainer>
                <ItemGrid xs={12} sm={6}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        From
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          isValidDate={valid}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "fDate");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "fDate",
                            value: this.state.fDate,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        To
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          isValidDate={valid}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "tDate");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "tDate",
                            value: this.state.tDate,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Pay Scheme
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    {this.state.paySchemeList !== undefined ? (
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.payschemeId}
                          id={"payschemeId"}
                          onSelect={this.onSelect}
                          getName={this.getQualityDetails}
                          // isRefresh={this.state.loadquality}
                          //setIsRefresh={this.afterRefreshquality}
                          stateKey="payschemeId"
                          staticData={this.state.paySchemeList}
                          //url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}
                          //value={this.state.selectedJuteQuality}
                        />
                      </ItemGrid>
                    ) : null}
                    {this.state.basicAlert}
                    {this.state.showMessagealert}
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Branch
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.branchId}
                        onSelect={this.onSelect}
                        //  getName={this.getQualityName}
                        stateKey="branchId"
                        // isRefresh={this.state.loadQuality}
                        // setIsRefresh={this.afterRefreshQuality}
                        url={
                          serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                        }
                        value={this.state.branchId}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                {/* <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}} >
                                              <span style={{float:"right",color:roseColor,fontWidth:400,cursor:"pointer"}}  onClick={() => {
                                                  if(this.state.payschemeId!==""&&this.state.payschemeId!==null)
                                                  getFile(serverApi.GET_PAY_PROCESS_TEMPLATE+"/"+this.state.payschemeId);
                                                  else{
                                                      this.basicAlert("Please Select Payscheme..!!")
                                                  }
                                              }}>  Click Here To Get Template File</span>
                                    </ItemGrid> */}

                {/* <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={2}>
                                                <InputLabel className={classes.label}>
                                                    Upload File<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={10}>
                                                <TextField
                                                    id="itemName"
                                                    disabled={true}
                                                    value={this.state.itemName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        autoComplete: 'off',
                                                        endAdornment: <InputAdornment
                                                            position="end">
                                                            <span style={{
                                                                color: "#003F66",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                cursor:"pointer",
                                                            }} onClick={this.handleFileClick}>BROWSE</span>
                                                        </InputAdornment>,
                                                    }}


                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
              </GridContainer>
            }
          />
        ) : null}
        {this.state.showPopUp ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button
                  color={"info"}
                  onClick={() =>
                    this.setState({
                      showPopUp: false,
                    })
                  }
                >
                  Back To List
                </Button>
              }
              content={
                <div>
                  <GridContainer>
                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <ReportsTableComponent
                        // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                        staticData={this.state.payPeriodTable}
                        borderRight={true}
                        //  searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        // isLoading={this.state.isRefresh}
                        // onActionClick={this.handleViewClick}
                        // actionType={VIEW}
                        fileName={"Pay_Processed_List"}
                        itemViewType={this.state.itemViewType}
                      />

                      {/*{this.state.dataTable !== null && this.state.dataTable !== undefined ?*/}
                      {/*<ExportToExcel*/}
                      {/*btnName={"Download Pay Processed List"}*/}
                      {/*dataTable={this.state.dataTable}*/}
                      {/*posts={this.state.dataTable.data}/>:null*/}

                      {/*}*/}

                      {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}
                    </ItemGrid>
                    {this.state.selectedPayStatus == "PROCESSED" ? (
                      <ItemGrid xs={11} style={{ marginTop: "-40px" }}>
                        <itemGrid>
                          <Button
                            color="info"
                            style={{ width: 130 }}
                            right={true}
                            onClick={this.onGenerateInvoiceClick}
                          >
                            Generate Invoice
                          </Button>
                        </itemGrid>
                        {this.state.approveButton ? (
                          <div>
                            <itemGrid>
                              <Button
                                color="danger"
                                style={{ width: 100 }}
                                right={true}
                                onClick={() => this.onApproveButtonClick("4")}
                              >
                                Reject
                              </Button>
                            </itemGrid>
                            <itemGrid>
                              <Button
                                color="primary"
                                style={{ width: 100 }}
                                right={true}
                                onClick={() => this.onApproveButtonClick("3")}
                              >
                                Approve
                              </Button>
                            </itemGrid>
                          </div>
                        ) : null}
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.showPopUp}
                      {/* {this.state.showPopUp ?
                                            <Popup
                                                onClickSave={() => this.onApproveButtonClick("3")}
                                                closePopup={() => this.handlePopUP()}
                                                onClickReject={() => this.onApproveButtonClick("4")}
                                                title="Post Temperature"
                                                 isShowSave={this.state.approveButton==true && this.state.status!=='3'?true:false}
                                                isShowCancel={true}
                                                isShowReject={this.state.approveButton==true && this.state.status!=='3'?true:false}
                                                save_btn_txt={"Approve"}
                                                reject_btn_txt={"Reject"}
                                                tablePop={true}
                                                // maxHeight={500}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} style={{
                                                                maxHeight: 400,
                                                                overflowY: "auto",
                                                                overflowX: "hidden"
                                                            }}>
                                                              <ReportsTableComponent
                                                                    // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                                                    staticData={this.state.payPeriodTable}
                                                                    borderRight={true}

                                                                    //  searchData={this.getData()}
                                                                    isRefresh={this.state.isRefresh}
                                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                                    // isLoading={this.state.isRefresh}
                                                                    // onActionClick={this.handleViewClick}
                                                                    // actionType={VIEW}
                                                                    fileName={"Pay_Processed_List"}
                                                                    itemViewType={this.state.itemViewType}/>


                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </form>

                                                }
                                            >
                                            </Popup> : null} */}

                      <GridContainer>
                        {/*<ItemGrid xs={12} sm={6}>*/}
                        {/*<GridContainer>*/}
                        {/*<ItemGrid xs={12} sm={4}>*/}
                        {/*<InputLabel className={classes.label}>*/}
                        {/*Pay Scheme*/}
                        {/*</InputLabel>*/}
                        {/*</ItemGrid>*/}
                        {/*{this.state.paySchemeList !== undefined ?*/}
                        {/*<ItemGrid xs={12} sm={8}>*/}
                        {/*<SelectboxTemp*/}
                        {/*defValue={this.state.payschemeIds}*/}
                        {/*id={"payschemeIds"}*/}
                        {/*onSelect={this.onSelect}*/}
                        {/*getName={this.getQualityDetails}*/}
                        {/*isRefresh={this.state.isRefresh}*/}
                        {/*setIsRefresh={(val) => {*/}
                        {/*this.setState({isRefresh: false})*/}
                        {/*}}*/}
                        {/*stateKey="payschemeIds"*/}
                        {/*staticData={this.state.paySchemeList}*/}
                        {/*//url={serverApi.SELECTION_PAY_SCHEME+getCookie(cons.COMPANY_ID)}*/}
                        {/*//value={this.state.selectedJuteQuality}*/}
                        {/*/>*/}

                        {/*</ItemGrid>*/}
                        {/*: null}*/}

                        {/*</GridContainer>*/}

                        {/*</ItemGrid>*/}
                        <ItemGrid xs={12} sm={4}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  isValidDate={valid}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDates");
                                  }}
                                  height={"15px"}
                                  formControlProps={{
                                    height: "15px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fDate",
                                    value: this.state.fromDates,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={4}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  isValidDate={valid}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "toDates");
                                  }}
                                  height={"15px"}
                                  formControlProps={{
                                    height: "15px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "tDate",
                                    value: this.state.toDates,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={5} sm={4}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.statusList}
                                id={"statusList"}
                                onSelect={this.onSelect}
                                // isRefresh={this.state.loadquality}
                                //setIsRefresh={this.afterRefreshquality}
                                stateKey="statusList"
                                staticData={statusoptions}
                                value={this.state.statusList}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <IconButton
                        onClick={this.handleCreate}
                        color="success"
                        right={true}
                      >
                        <Add />
                      </IconButton>

                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {this.state.dataTable !== null &&
                      this.state.dataTable !== undefined ? (
                        <ReportsTableComponent
                          // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                          staticData={this.state.dataTable}
                          borderRight={true}
                          isExcel={true}
                          onExcelExport={this.handleExcelExport}
                          // searchData={this.getData()}
                          isRefresh={this.state.isRefresh}
                          handleSearch={this.handleSeacrh.bind(this)}
                          //  isLoading={this.state.isRefresh}
                          onActionClick={this.handleViewClick}
                          actionType={VIEW}
                          fileName={"payPeriod"}
                          itemViewType={this.state.itemViewType}
                          showPagination
                          handlePage={this.handlePage.bind(this)}
                        />
                      ) : null}
                      {/*{this.state.dataTable !== null && this.state.dataTable !== undefined ?*/}
                      {/*<ExportToExcel*/}
                      {/*btnName={"Download Pay Processed List"}*/}
                      {/*dataTable={this.state.dataTable}*/}
                      {/*posts={this.state.dataTable.data}/>:null*/}

                      {/*}*/}

                      {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument = () => {
    var data = {
      taskCode: 1013,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: getCookie(cons.COMPANY_ID),
    };
    requestList(
      serverApi.REPORTS_JUTE_WITH_VALUE,
      data,
      this.handlePrintReports
    );
  };
  handlePrintReports = (resp) => {
    var pageNo = 1;
    var modules = [];
    // if(this.state.dataTable!==undefined &&this.state.dataTable!==null ){
    //     console.log("print ",JSON.stringify(this.state.dataTable.data.data));
    //     modules=this.state.dataTable.data.data;
    //     reportValues
    // }
    modules = resp.data.reportValues;
    var grandTotals = resp.data.totalValues;
    var companyName = resp.data.companyName;
    console.log("total", grandTotals);
    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(14);
    doc.text(170, 40, companyName);
    doc.setFontSize(10);
    var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);

    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    // doc.setFont("courier");
    // doc.setFontType("normal");
    doc.setFontSize(10);
    // doc.table(50, 100,modules,modulesHeader,config);
    doc.text(220, 60, "Report No.:[JUTE/01]");
    doc.text(
      170,
      80,
      "Daily Jute With Value Report From  " +
        this.state.fromDate +
        " to " +
        this.state.todate +
        "           Quantity in :Quintal"
    );
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.cellInitialize();
    doc.cell(30, 100, 30, 30, "Jcode", "", "C");
    doc.cell(60, 100, 45, 30, "Quality", "", "C");
    var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
    doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
    var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
    doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
    var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
    doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
    doc.cell(231, 100, 40, 30, "Rate", "", "C");
    var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
    doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
    var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
    doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
    doc.cell(
      364,
      100,
      155,
      15,
      "<--------------------M T D----------------->",
      "",
      "C"
    );
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
    doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
    doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
    // doc.cellInitialize();
    var cellWidths = ["", 30, 45, 42, 42, 42, 40, 51, 42, 50, 50, 55, 45];
    var cellTextaligns = [
      "",
      "center",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "center",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 110;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 20;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 130;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");
          doc.setFontSize(14);
          doc.text(170, 40, companyName);
          // doc.setFont("courier");
          // doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
          // doc.table(50, 100,modules,modulesHeader,config);
          doc.text(220, 60, "Report No.:[JUTE/01]");
          doc.text(
            170,
            80,
            "Daily Jute With Value Report From  " +
              this.state.fromDate +
              " to " +
              this.state.todate +
              "           Quantity in :Quintal"
          );
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.cellInitialize();
          doc.cell(30, 100, 30, 30, "Jcode", "", "C");
          doc.cell(60, 100, 45, 30, "Quality", "", "C");
          var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
          doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
          var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
          doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
          var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
          doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
          doc.cell(231, 100, 40, 30, "Rate", "", "C");
          var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
          doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
          var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
          doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
          doc.cell(
            364,
            100,
            155,
            15,
            "<--------------------M T D----------------->",
            "",
            "C"
          );
          /* var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                     doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
          doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
          doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          var myItem = prop[key] + " ";
          return doc.cell(
            30,
            k,
            cellWidths[a],
            20,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    // var grandTotals = {
    //     "OpeningWeight": 25455 + "",
    //     "ReceiptWeight": 0.0 + "",
    //     "IssueWeight": 200 + "",
    //     "Rate": " ",
    //     "IssueAmount": 1300000 + "",
    //     "Closing": 2555005 + "",
    //     "ReptQty": 5544 + "",
    //     "IssueQty": 2455 + "",
    //     "IsueAmount": 25555 + "",
    //   //  "StockForDays": " "
    // };

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(30, k + 20, 75, 30, "Grand Total", "", "left");
    doc.cell(
      105,
      k + 20,
      42,
      30,
      grandTotals.openingWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      147,
      k + 20,
      42,
      30,
      grandTotals.receivedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      189,
      k + 20,
      42,
      30,
      grandTotals.issuedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(231, k + 20, 40, 30, " ", "", "right");
    doc.cell(
      271,
      k + 20,
      51,
      30,
      grandTotals.issueAmountTot + " ",
      "",
      "right"
    );
    doc.cell(
      322,
      k + 20,
      42,
      30,
      grandTotals.closingweightcTot + " ",
      "",
      "right"
    );
    doc.cell(
      364,
      k + 20,
      50,
      30,
      grandTotals.receivedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      414,
      k + 20,
      50,
      30,
      grandTotals.issuedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      464,
      k + 20,
      55,
      30,
      grandTotals.issueAmountToDateTot + " ",
      "",
      "right"
    );
    //  doc.cell(519, k + 25, 45, 30, grandTotals.StockForDays, '', 'right');

    var Avgs = {
      AvgIssuePriceToday: 4240.74 + "",
      AvgIssuePriceTodate: 4240.74 + "",
      batchPriceToday: "4284.00",
      batchPriceTodate: " 4339.59",
    };
    doc.setFontType("normal");
    doc.setFontSize(10);

    // doc.text(30, k + 75, 'Avg. Issue Price : Today');
    // doc.text(170, k + 75, Avgs.AvgIssuePriceToday);
    // doc.text(220, k + 75, 'Todate');
    // doc.text(260, k + 75, Avgs.AvgIssuePriceTodate);
    // doc.text(30, k + 90, 'Batch Price        : Today');
    // doc.text(170, k + 90, Avgs.batchPriceToday);
    // doc.text(220, k + 90, 'Todate');
    // doc.text(260, k + 90, Avgs.batchPriceTodate);

    doc.text(30, k + 150, "Dept. Incharge");
    doc.text(400, k + 150, "Authorised Signature");
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 200, newdat);
    doc.text(500, k + 200, printBy);

    doc.save("JuteValueReport.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(PayRegister);
