import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig,serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import {ParameterTypes} from "../TableData";

class LeavePolicies extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,
            isRefresh:false,
            isLoading:false,
            itemViewType:"leavePolicyId",
            updateView:false,
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            alert:false,
            leavePolicieList:[],
            carryFrwStatus:"",
            encashmentStatus:"",
            leaveTypeId:"",
            leavesPerYear:"",
            maxConsecutiveCount:"",
            maxLeavesPerYear:"",
            yearEndDate:"",yearStartDate:"",leavePolicyDesc:""

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
    }
    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleViewClick =(id)=>{
        // var data = this.state.dataTable;
        var juteId="";
        console.log("jute  "+"  "+!getMenuPermission(0));
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.LEAVE_POLICIES_GET_BYID +"/"+ id, this.handleViewClickResp);
        }
    };
    handleViewClickResp = (resp) => {
        var header = resp.hdr;
        var list = resp.dtlList;
        var policylist = list.map((prop,key)=>{
            return(
                {
                    id:key,
                    leavePolicyDtlId:prop.leavePolicyDtlId,
                    leavePolicyId:prop.leavePolicyId,
                    carryFrwStatus: prop.carryFrwStatus,
                    carryFrwStatusName:prop.carryFrwStatus==="Y"?"YES":"NO",
                    encashmentStatus:prop.encashmentStatus,
                    encashmentStatusName:prop.encashmentStatus==="Y"?"YES":"NO",
                    leaveTypeName:prop.leaveTypeName,
                    leaveTypeId: prop.leaveTypeId,
                    leavesPerYear: prop.leavesPerYear,
                    maxConsecutiveCount: prop.maxConsecutiveCount,
                    maxLeavesPerYear:prop.maxLeavesPerYear,
                    yearEndDate: prop.yearEndStateDesc,
                    yearStartDate: prop.yearStartDateDesc,

                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.leavePolicieList;
                                        data.find((o,i) => {
                                            if(o.id === key){
                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                // console.log("no of bales",o.quantityType);
                                                this.setState({
                                                    id:o.id,
                                                    updateItemAlert:true,
                                                    updateItem: true,
                                                    leavePolicyDtlId:o.leavePolicyDtlId,
                                                    leavePolicyId:o.leavePolicyId,
                                                    carryFrwStatus: o.carryFrwStatus,
                                                    encashmentStatus:o.encashmentStatus,
                                                    leaveTypeId: o.leaveTypeId,
                                                    leavesPerYear: o.leavesPerYear,
                                                    maxConsecutiveCount: o.maxConsecutiveCount,
                                                    maxLeavesPerYear:o.maxLeavesPerYear,
                                                    yearEndDate: o.yearEndDate,
                                                    yearStartDate: o.yearStartDate,
                                                    // alert:true,

                                                });
                                                return true;
                                            }
                                        });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>

                            <IconButton
                                onClick={() => {

                                    var data = this.state.leavePolicieList;
                                    data.find((o,i) => {

                                        if(o.id === key) {

                                                this.setState({deleteItem: true,
                                                    id:o.id,});

                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                //  data.splice(i,1);
                                            return true;
                                        }
                                    });

                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>{" "}
                        </div>

                    )
                }
            )
        });
        //console.log("indentWeight "+header.indentWeight);
        if(header !== null){
            this.setState({
                updateView:true,
                isCreated:true,
                leavePolicyId:header.leavePolicyId,
                leavePolicyDesc:header.leavePolicyDesc,
                leavePolicieList:policylist,

            });

        }
    };

    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    }

    handleCreate=()=>{
        //alert();
        if(!this.state.isCreated){
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
            // leavePolicieList:""
        });
        //  this.hideAlertMessage();
    };

    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
    };
    dateChanged = (date) => {
        var myDate=date;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };

    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
          this.setState({
              [stateKey]: selectedValue,
          });
    };

    hideBasicAlert(){
        if(this.state.updateItemAlert)
            this.hideAlert();
            this.setState({
            basicAlert: null,
            deleteItem:false,
            updateItem:false,
            updateItemAlert:false
        });

    }
    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
              //  updateItem: false,
                updateItemAlert:false
            });
        }
        // if(this.state.deleteList)
        if(this.state.deleteList){
                this.handleLoading();
                getDataFromUrl(serverApi.LEAVE_POLICIES_DELETE_BYID+"/"+this.state.leavePolicyId,this.handleAddIndent);

        }

        if(this.state.deleteItem){
            var data = this.state.leavePolicieList;
            data.splice(this.state.id,1);
            data.map((o,key)=>{
                o.id=key;
                o.actions= (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <IconButton
                            onClick={() => {
                                var data = this.state.leavePolicieList;
                                data.find((o,i) => {
                                    console.log("key in edit",key);

                                    if(o.id === key){
                                        this.basicItemAlert("Do You Like To Update This Item?");
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        console.log("id in edit",o.id);
                                        this.setState({
                                            // alert:true,
                                            id:o.id,
                                            updateItemAlert:true,
                                            updateItem: true,
                                            leavePolicyDtlId:o.leavePolicyDtlId,
                                            leavePolicyId:o.leavePolicyId,
                                            carryFrwStatus: o.carryFrwStatus,
                                            encashmentStatus:o.encashmentStatus,
                                            leaveTypeId: o.leaveTypeId,
                                            leavesPerYear: o.leavesPerYear,
                                            maxConsecutiveCount: o.maxConsecutiveCount,
                                            maxLeavesPerYear:o.maxLeavesPerYear,
                                            yearEndDate: o.yearEndDate,
                                            yearStartDate: o.yearStartDate,

                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <Edit />
                        </IconButton>{" "}
                        { /* use this button to remove the data row */ }
                        <IconButton
                            onClick={() => {
                                var data = this.state.leavePolicieList;
                                data.find((o,i) => {
                                    if(o.id === key){

                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        //if (this.state.approveStatus) {
                                        console.log("id in delete",o.id);
                                        if (this.state.approveButton && this.state.updateView) {


                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                            });

                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                        }else if(!this.state.updateView){
                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                            });

                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                        }
                                        return true;
                                        //  data.splice(i,1);
                                        // }
                                    }
                                });
                                this.setState({leavePolicieList: data});
                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close />
                        </IconButton>
                    </div>

                );

            });

            //  if(this.state.approveStatus)
            // this.setState({
            //     deleteItem:false});
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            loading:false,
            alert: false,
            showMessagealert:false,
            carryFrwStatus:"",
            encashmentStatus:"",
            leaveTypeId:"",
            leavesPerYear:"",
            maxConsecutiveCount:"",
            maxLeavesPerYear:"",
            yearEndDate:"",yearStartDate:""
        });
    }
    hideAlertMessage(){
        if(this.state.isCreated){
            this.callLeavepoliciesList();
        }
        this.setState({
            isCreated:false,
            showMessagealert:false,
            updateView:false,
            alert: false,
            basicAlert:false,
            carryFrwStatus:"",
            encashmentStatus:"",
            leaveTypeId:"",
            leavesPerYear:"",
            maxConsecutiveCount:"",
            maxLeavesPerYear:"",
            yearEndDate:"",yearStartDate:"",leavePolicyDesc:"",leavePolicieList:[]
        });

    }
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    onCreateButtonClick=()=>{
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var leavePolicieList = this.state.leavePolicieList.map((o, key) => {
                return ({

                    carryFrwStatus: o.carryFrwStatus,
                    encashmentStatus:o.encashmentStatus,
                    leaveTypeId: o.leaveTypeId,
                    leavesPerYear: o.leavesPerYear,
                    maxConsecutiveCount: o.maxConsecutiveCount,
                    maxLeavesPerYear:o.maxLeavesPerYear,
                    yearEndDate:  this.dateChanged(o.yearEndDate),
                    yearStartDate:this.dateChanged(o.yearStartDate) ,
                })
            });

            var data = {
                "dtlList": leavePolicieList,
                "hdr": {
                    "companyId": getCookie(cons.COMPANY_ID),
                    "leavePolicyDesc":this.state.leavePolicyDesc
                }
            };
            if ( this.state.leavePolicieList.length >= 1) {
                this.handleLoading();

                requestList(serverApi.LEAVE_POLICIES_ADD, data, this.handleAddIndent)
            } else if (this.state.leavePolicieList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onUpdateButtonClick=()=>{
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            console.log("sasasa",this.state.leavePolicieList);
            var leavePolicieList = this.state.leavePolicieList.map((o, key) => {
                return ({

                    carryFrwStatus: o.carryFrwStatus,
                    encashmentStatus:o.encashmentStatus,
                    leaveTypeId: o.leaveTypeId,
                    leavesPerYear: o.leavesPerYear,
                    maxConsecutiveCount: o.maxConsecutiveCount,
                    maxLeavesPerYear:o.maxLeavesPerYear,
                    yearEndDate:this.dateChanged(o.yearEndDate),
                    yearStartDate:this.dateChanged(o.yearStartDate),
                    "leavePolicyDtlId": o.leavePolicyDtlId,
                    "leavePolicyId": o.leavePolicyId,
                    "isActive": 1,
                })
            });

            var data = {
                "dtlList": leavePolicieList,
                "hdr": {
                    "companyId": getCookie(cons.COMPANY_ID),
                    "leavePolicyDesc":this.state.leavePolicyDesc,
                    "isActive": 1,
                    "leavePolicyId": this.state.leavePolicyId
                }
            };
            if ( this.state.leavePolicieList.length >= 1) {
                this.handleLoading();
               requestList(serverApi.LEAVE_POLICIES_UPDATE, data, this.handleAddIndent)
            } else if (this.state.leavePolicieList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            if(this.state.deleteList)
                this.callLeavepoliciesList();
            this.setState({
                deleteList:false,
                basicAlert:null
            });
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item
    onClickAddItem(){
var arr=this.state.leavePolicieList;

        var itemIndex = 0,leavePoliciesItemId=0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            leavePoliciesItemId=this.state.leavePoliciesItemId
            //   arr[itemIndex].quantity = this.state.quantity
        } else {
            itemIndex = this.state.leavePolicieList.length
        }
        var addItemObj={
            id:itemIndex,
            leavePoliciesItemId:leavePoliciesItemId,
            carryFrwStatus: this.state.carryFrwStatus,
            encashmentStatus: this.state.encashmentStatus,
            leaveTypeId: this.state.leaveTypeId,
            leavesPerYear: this.state.leavesPerYear,
            maxConsecutiveCount: this.state.maxConsecutiveCount,
            maxLeavesPerYear: this.state.maxLeavesPerYear,
            yearEndDate: this.state.yearEndDate,
            yearStartDate: this.state.yearStartDate,
            carryFrwStatusName:this.state.carryFrwStatusName,
            leaveTypeName:this.state.leaveTypeName,
            encashmentStatusName:this.state.encashmentStatusName,
            actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.leavePolicieList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id:o.id,
                                        leavePoliciesItemId:o.leavePoliciesItemId,
                                        updateItemAlert:true,
                                        updateItem: true,
                                        carryFrwStatus: o.carryFrwStatus,
                                        encashmentStatus:o.encashmentStatus,
                                        leaveTypeId: o.leaveTypeId,
                                        leavesPerYear: o.leavesPerYear,
                                        maxConsecutiveCount: o.maxConsecutiveCount,
                                        maxLeavesPerYear:o.maxLeavesPerYear,
                                        yearEndDate: o.yearEndDate,
                                        yearStartDate: o.yearStartDate,
                                        // alert:true,

                                    });
                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.leavePolicieList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({deleteItem: true,
                                        id:o.id,});

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close />
                    </IconButton>{" "}
                </div>

            )
        };
            if (this.state.carryFrwStatus!=='' && this.state.encashmentStatus!=='' && this.state.leaveTypeId!==0 && this.state.leavesPerYear!==''
            && this.state.maxConsecutiveCount !=='' && this.state.maxLeavesPerYear !=='' && this.state.yearEndDate!=='' && this.state.yearStartDate!=='') {
                if (this.state.updateItem) {
                    arr[itemIndex] = addItemObj;
                } else{
                    arr.splice(arr.length, 0, addItemObj);

                }
                this.setState({
                    //loading:,
                    leavePolicieList:arr
                });
                this.hideAlert();
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

    }
    getInvoiceTablePageSize=()=>{
        var length =this.state.leavePolicieList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };

    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    handleAddItem () {
        const {classes}=this.props;
        const {leavePolicyDesc}=this.state;

        //  var id="";
        console.log(this.state);
        if(leavePolicyDesc !== "")
            this.setState({
                loading:true,
                alert:true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };

    onDeleteLeavePolicy=(id)=>{

        this.setState({deleteList: true,leavePolicyId:id});

        this.basicItemAlert("Do You Like To Delete This Record?");
    };

    componentDidMount(){
        this.callPaySchemeParameters();
         this.callLeavepoliciesList();
    }
    callPaySchemeParameters(){
        getDataFromUrl(serverApi.LEAVE_TYPE_SELECTION_LIST+"/"+getCookie(cons.COMPANY_ID),this.handleleaveType)
    }
    handleleaveType =(resp)=>{
         this.setState({leave_Types:resp});
    };
    callLeavepoliciesList(){
        getDataFromUrl(serverApi.LEAVE_POLICIES_LIST+"/"+getCookie(cons.COMPANY_ID),this.handleleavePolicy)

    }
    handleleavePolicy=(resp)=>{
        this.setState({tableList:resp,isRefresh:true})
    };

    render(){
        const { classes } = this.props;
        const {leave_Types}=this.state;
        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: "Y", label: 'YES' ,name:'YES'},
            { value: "N", label: 'NO',name: 'NO' }
        ];
        const LeaveTypes=leave_Types;
        const inputFocus={
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var valid = function( current ){
            if(getCookie(cons.COMPANY_ID)!=='2')
                return current.isAfter( yesterday );
            else
                return true;        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"Add"}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer xs={12} md={12}>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={6} >
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Carry Forward Status<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8} >
                                                                                <SelectboxTemp id={"carryFrwStatus"}

                                                                                               defValue={this.state.carryFrwStatus}
                                                                                               onSelect={this.onSelect}
                                                                                               getName={(name)=>{
                                                                                                   this.setState({carryFrwStatusName:name})
                                                                                               }}
                                                                                               stateKey="carryFrwStatus"
                                                                                               staticData={options}
                                                                                               //url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedMukam +"/getAllItemByMukam/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                                               value={this.state.carryFrwStatus}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6} >
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Encashment Status<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8} >
                                                                                <SelectboxTemp id={"encashmentStatus"}
                                                                                               defValue={this.state.encashmentStatus}
                                                                                               onSelect={this.onSelect}
                                                                                               getName={(name)=>{
                                                                                                   this.setState({encashmentStatusName:name})
                                                                                               }}
                                                                                               stateKey="encashmentStatus"
                                                                                                staticData={options}
                                                                                  //  url={serverApi.LEAVE_TYPE_SELECTION_LIST+"/"+getCookie(cons.COMPANY_ID)}
                                                                                               value={this.state.encashmentStatus}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <br/>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Leave Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                {/* {(selectedJuteType >0) ?*/}
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.leaveTypeId}
                                                                                    id={"leaveTypeId"}
                                                                                    getName={(name)=>{
                                                                                        this.setState({leaveTypeName:name})
                                                                                    }}
                                                                                    onSelect={this.onSelect}
                                                                                    stateKey="leaveTypeId"
                                                                                    isRefresh={this.state.LoadleaveTypeId}
                                                                                    setIsRefresh={(val)=>{this.setState({LoadleaveTypeId:false})}}
                                                                                    staticData={LeaveTypes}
                                                                                    value={this.state.leaveTypeId}
                                                                                />{/*:<legend style={{color:"red",fontSize:"12px"}}>Select Jute Type To Display Qualities</legend>
                                                                            }*/}

                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Leave Per Year <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="leavesPerYear"
                                                                                    value={this.state.leavesPerYear}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Max Consecutive Count <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="maxConsecutiveCount"
                                                                                    value={this.state.maxConsecutiveCount}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                   Max Leave Per Year <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="maxLeavesPerYear"
                                                                                    value={this.state.maxLeavesPerYear}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Year Start Date<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <FormControl variant="outlined" fullWidth>
                                                                                    <Datetime
                                                                                        closeOnSelect={true}
                                                                                        timeFormat={false}
                                                                                        onChange={(dateObj) => {
                                                                                            this.timeChanged(dateObj, "yearStartDate")
                                                                                        }}
                                                                                        formControlProps={
                                                                                            {height:"10px",inlineBlock: "none"}
                                                                                        }

                                                                                        inputProps={{
                                                                                            id:"yearStartDate",
                                                                                            value:this.state.yearStartDate,
                                                                                            autoComplete: 'off'
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6}>

                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Year End date<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <FormControl variant="outlined" fullWidth >
                                                                                    <Datetime
                                                                                        closeOnSelect={true}
                                                                                        timeFormat={false}
                                                                                        onChange={(dateObj) => {
                                                                                            this.timeChanged(dateObj, "yearEndDate")
                                                                                        }}
                                                                                        formControlProps={
                                                                                            {height:"10px",inlineBlock: "none"}
                                                                                        }
                                                                                        inputProps={{
                                                                                            id:"yearEndDate",
                                                                                            value:this.state.yearEndDate,
                                                                                            autoComplete: 'off'
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </form>

                                                }
                                            >
                                            </Popup>:null
                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Leave Policy Desc <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    // disabled={true}
                                                    id="leavePolicyDesc"
                                                    value={this.state.leavePolicyDesc}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    {/*{this.state.updateView?*/}
                                        {/*<ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>*/}
                                            {/*<GridContainer>*/}
                                                {/*<ItemGrid xs={12} sm={4}>*/}
                                                    {/*<InputLabel className={classes.label}>*/}
                                                        {/*Created By*/}
                                                    {/*</InputLabel>*/}
                                                {/*</ItemGrid>*/}
                                                {/*<ItemGrid xs={12} sm={8}>*/}
                                                    {/*<TextField*/}
                                                        {/*disabled={true}*/}
                                                        {/*id="createdBy"*/}
                                                        {/*value={this.state.createdBy}*/}
                                                        {/*className={classes.textField}*/}
                                                        {/*onChange={this.onChangeValues}*/}
                                                        {/*fullWidth={true}*/}
                                                        {/*InputProps={inputFocus}*/}

                                                    {/*/>*/}

                                                {/*</ItemGrid>*/}
                                            {/*</GridContainer>*/}
                                        {/*</ItemGrid>:null}*/}
                                    {/*{this.state.updateView?*/}
                                        {/*<ItemGrid xs={12} sm={6}  style={{marginTop:"10px"}}>*/}
                                            {/*<GridContainer>*/}
                                                {/*<ItemGrid xs={12} sm={4}>*/}
                                                    {/*<InputLabel className={classes.label}>*/}
                                                        {/*Created On*/}
                                                    {/*</InputLabel>*/}
                                                {/*</ItemGrid>*/}
                                                {/*<ItemGrid xs={12} sm={8}>*/}
                                                    {/*<TextField*/}
                                                        {/*disabled={true}*/}
                                                        {/*id="createdOn"*/}
                                                        {/*value={this.state.createdOn}*/}
                                                        {/*className={classes.textField}*/}
                                                        {/*onChange={this.onChangeValues}*/}
                                                        {/*fullWidth={true}*/}
                                                        {/*variant="outlined"*/}
                                                        {/*InputProps={inputFocus}*/}

                                                    {/*/>*/}
                                                {/*</ItemGrid>*/}
                                            {/*</GridContainer>*/}
                                        {/*</ItemGrid>:null}*/}
                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {console.log(this.state.leavePolicieList.length+" length")}
                                                <ReactTable
                                                    data={this.state.leavePolicieList}
                                                    filterable={false}
                                                    resizable={false}
                                                    loading={this.state.loading}
                                                    getTableProps={() => {
                                                        return {
                                                            style: {
                                                                border: "2px solid #00ABDC",
                                                                borderRadius: "4px"
                                                            }
                                                        }}}

                                                    getTheadProps={() => {
                                                        return {
                                                            style: {
                                                                borderBottom: "2px solid #00ABDC",
                                                                //height:45
                                                                //lineHeight:"1.3"
                                                            }
                                                        }}}
                                                    getTheadThProps={() => {
                                                        return {
                                                            style: {
                                                                borderRight: "1px solid #999999",
                                                                textAlign:"center",
                                                                whiteSpace:"pre-wrap",
                                                                lineHeight:1.3,
                                                            }
                                                        }}}
                                                    style={{color: "#00ABDC", textAlign: "center",overflowY: "auto"}}
                                                    columns={[
                                                        {
                                                            Header: "Leave Type",
                                                            accessor: "leaveTypeName",
                                                            minWidth:160,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Carry forward Status",
                                                            accessor: "carryFrwStatusName",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Encashment Status",
                                                            accessor: "encashmentStatusName",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Leaves Per year",
                                                            accessor: "leavesPerYear",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Max Consecutive Count",
                                                            accessor: "maxConsecutiveCount",
                                                            width: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Max Leaves Per Year.",
                                                            accessor: "maxLeavesPerYear",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Year Start date",
                                                            accessor: "yearStartDate",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Year End date",
                                                            accessor: "yearEndDate",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    showPagination={false}
                                                    pageSize={this.getInvoiceTablePageSize()}
                                                    //  defaultPageSize={5}
                                                    sortable={false}
                                                    getNoDataProps={()=>{
                                                        return{
                                                            style:{
                                                                display:"none"
                                                            }

                                                        };
                                                    }}
                                                    className="-striped -highlight"
                                                />
                                            </ItemGrid>
                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        {this.state.approveButton?
                                                            <ItemGrid xs={6}>
                                                                <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                            </ItemGrid>:null}
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                </ItemGrid>

                                            }
                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null

                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            <TableComponent
                                                //url={serverApi.DATATABLE_SERVICE_URL+"/getAllIndent"}
                                             //   searchData={this.getData()}
                                                staticData={this.state.tableList}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                fileName={'jute_indent_currentdate'}
                                                actionType={VIEW}
                                                showDelete={true}
                                                onDeleteClick={this.onDeleteLeavePolicy}/>
                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
    printDocument() {
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + this.state.indentNo + "/" + getCookie(cons.USERID) + "/getIndentDetails/"+getCookie(cons.CIPHER), this.handlePrintResp);

            //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
        }
    };
    handlePrintResp = (resp)=> {
        var printHeaderValues=resp.indentHeader;
        var modules=[];
        var lineItems=resp.indentItems;
        if(lineItems.length>0){
            lineItems.map((prop,key)=>{
                var obj={
                    srNo:key+1,
                    itemId:prop.itemId,
                    itemIdDesc:prop.itemIdDesc,
                    unitId:prop.unitId,
                    stockquantity:prop.stock,
                    avgQty:' ',
                    indentQuantity:prop.indentQuantity,
                    deptId:prop.deptIdDesc,
                    pono:' ',
                    rate:prop.rate+' ',
                    supName:' ',
                    clmn12:' ',
                    clmn13:' ',
                    clmn14:' ',
                    clmn15:' ',
                    clmn16:' ',
                    orderNo:' ',
                    ctrlStock:' ',
                    deliveryStatus:'15 days'
                };
                modules.push(obj)
            })
        }
        console.log('lineitems',modules);
        var printTotalvalues=resp.totalValues;
        // this.setState({
        //     printHeaderValues:header,
        //     printLineItems:printLineItems,
        //     printTotalvalues:totalvalues,
        // })


        // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
        //     var printHeaderValues=this.state.printHeaderValues;
        // }
        var pageNo = 1;

        var mrid=printHeaderValues.juteRecptNo;
        var mrid=mrid+' ';
        var mr_date=printHeaderValues.indentDateDesc;
        mr_date=mr_date+' ';
        var poId=printHeaderValues.id;
        poId=poId+' ';
        var challan_no=printHeaderValues.challanNo;
        challan_no=challan_no+' ';
        var challan_date=printHeaderValues.challanDate;
        challan_date=challan_date+' ';
        var vehicleNo=printHeaderValues.lorryNo;
        vehicleNo=vehicleNo+' ';
        var SupplierName=printHeaderValues.supplierName;
        SupplierName=SupplierName+' ';
        var brokerName=printHeaderValues.brokerName;
        brokerName=brokerName+' ';
        var frightPaid=printHeaderValues.frightPaid;
        frightPaid=frightPaid+' ';
        var gateEntryDate=printHeaderValues.gateEntryDate;
        gateEntryDate=gateEntryDate+' ';
        var mukamName=printHeaderValues.mukamName;
        mukamName=mukamName+' ';

        var companyName=getCookie(cons.COMPANY_NAME);
        var address1=(printHeaderValues.agentName!=='')? printHeaderValues.agentAddress1 +' ' :printHeaderValues.cAddress1;
        var address2=(printHeaderValues.agentName!=='')? printHeaderValues.agentAddress2 + ' ' :printHeaderValues.cAddress2;

        var printcopy='';
        var printCounnt=printHeaderValues.printCount;

        if(printCounnt==0){
            printcopy='NOT A VALID';
        }
        else if(printCounnt==1){
            printcopy='ORIGINAL';
        }else if(printCounnt==2){
            printcopy='DUPLICATE';
        }
        else if(printCounnt==3){
            printcopy='Triplicate';
        }
        else if(printCounnt==4){
            printcopy='Quadruplicate';
        }else{
            printcopy='COPY'+' '+printCounnt;
        }


        // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
        //      modules=this.state.printLineItems;
        //  }
        // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
        //   var  printTotalvalues=this.state.printTotalvalues;
        // }

        function addWaterMark(doc,printcopy) {
            var totalPages = doc.internal.getNumberOfPages();

            for (i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
                doc.setFontSize(24);
                doc.setTextColor(200);
                doc.globalAlpha = 0.5
                //doc.zIndex(-1);
                doc.text(10, 30, printcopy);

                // doc.text(150, 600, printcopy,null,45);
            }

            return doc;
        }

        var config = {
            printHeaders: true,
            autoSize: true,
            margins: {left: 0, top: 0, bottom: 0, width: 0},
            fontSize: 10
        };
        const doc = new Jspdf('p', 'pt', 'a4');
        doc.setFontSize(16);
        var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize() / 2);
        doc.text(companyName, xOffset, 40);
        doc.setFontSize(10);
        //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
        var TotalPgaeNo = doc.internal.getNumberOfPages();
        doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
        doc.setFontSize(10);
        /* doc.text(190, 60, address1+'');
         doc.text(250, 80, address2+'');
         doc.setFontSize(9);
        */
        doc.setFontType("bold");
        doc.text(30, 80, 'JUTE REPORT NO');
        doc.text(120, 80, ':');
        doc.setFontType("normal");
        doc.text(130, 80, mrid);
        doc.setFontType("bold");
        doc.text(400, 120, 'INDENT DATE : ');
        doc.setFontType("normal");
        doc.text(480, 120, mr_date);
        doc.setFontType("bold");
        doc.text(30, 120, 'INDENT NO');
        doc.text(120, 120, ':');
        doc.setFontType("normal");
        doc.text(130, 120, poId);
        var srno = doc.splitTextToSize('Srl no', 20);
        doc.cell(10, 150, 20, 40, srno, '', 'C');
        var itemCode = doc.splitTextToSize('Item Code', 30);
        doc.cell(30, 150, 30, 40, itemCode, '', 'C');
        var description = doc.splitTextToSize('Description', 50);
        doc.cell(90, 150, 70, 40, description, '', 'C');
        var unit = doc.splitTextToSize('Unit', 50);
        doc.cell(180, 150, 20, 40, unit, '', 'C');
        var stockqty = doc.splitTextToSize('Stock/Appr Qty', 30);
        doc.cell(220, 150, 40, 40, stockqty, '', 'C');
        var avg = doc.splitTextToSize('Avg Cons Last Mth',40);
        doc.cell(260, 150, 40, 40, avg, '', 'C');
        var indentQty = doc.splitTextToSize('Indent Quantity',35);
        doc.cell(290, 150, 40, 40, indentQty, '', 'C');
        var dept = doc.splitTextToSize('Dept', 40);
        doc.cell(310, 150, 25, 40, dept, '', 'C');
        doc.cell(340, 150, 100, 20, '       Previous Details     ', '', 'C');
        //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

        /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
        doc.cellInitialize();
        doc.setFontSize(9);
        doc.setFontType('normal');

        var pono = doc.splitTextToSize('PO No/Dt', 30);

        doc.cell(295, 170, 30, 20, pono, '', 'C');
        doc.cell(320, 170, 30, 20, 'Rate', '', 'C');
        var supName = doc.splitTextToSize('Supplier Name', 36);

        doc.cell(350, 170, 40, 20, supName, '', 'C');
        doc.cellInitialize();
        doc.setFontSize(10);
        doc.setFontType('normal');
        doc.cell(395, 150, 100, 20, '          Comp Status          ', '', 'C');
        //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
        /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
        doc.cellInitialize();
        doc.setFontSize(9);
        doc.setFontType('normal');
        doc.cell(395, 170, 20, 20, '<12>', '', 'C');
        doc.cell(420, 170, 20, 20, '<13>', '', 'C');
        doc.cell(450, 170, 20, 20, '<14>', '', 'C');
        doc.cell(470, 170, 20, 20, '<15>', '', 'C');
        doc.cell(490, 170, 20, 20, '<16>', '', 'C');
        doc.cellInitialize();
        doc.setFontSize(10);
        doc.setFontType('normal');
        var order = doc.splitTextToSize('Order No', 30);
        doc.cell(495, 150, 30, 40, order, '', 'C');
        var cntrl = doc.splitTextToSize('Cntrl Stock',30);
        doc.cell(525, 150, 30, 40, cntrl, '', 'C');
        var delivery = doc.splitTextToSize('Delivery Status',38);
        doc.cell(555, 150, 36, 40, delivery, '', 'C');


        var cellWidths = ['', 20, 30, 70, 20, 40, 40, 40, 25, 30, 30, 40, 20, 20, 20, 20, 20, 30, 30, 36];
        var cellTextaligns = ['', 'left', 'left', 'right', 'right', 'right', 'right', 'right', 'right', 'left', 'right'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k = 165;
            var i = 0;
            modules.map((prop, key) => {
                k = k + 25;
                i = i + 1;
                doc.cellInitialize();
                if (i === 27) {
                    k = 165;
                    i=0;
                    pageNo = pageNo + 1;
                    var today = moment().format('DD/MM/YYYY H:mm:ss');
                    var name = getCookie(cons.USERID);
                    var newdat = " Printed on: " + today;
                    var printBy = " Printed By: " + name;
                    doc.text(30, 800, newdat);
                    doc.text(500, 800, printBy);
                    doc.addPage('a4', 'p');

                    doc.setFontType("bold");
                    doc.cellInitialize();
                    var srno = doc.splitTextToSize('Srno', 20);
                    doc.cell(10, 150, 20, 40, srno, '', 'C');
                    var itemCode = doc.splitTextToSize('Item Code', 30);
                    doc.cell(30, 150, 30, 40, itemCode, '', 'C');
                    var description = doc.splitTextToSize('Description', 50);
                    doc.cell(90, 150, 70, 40, description, '', 'C');
                    var unit = doc.splitTextToSize('Unit', 50);
                    doc.cell(180, 150, 20, 40, unit, '', 'C');
                    var stockqty = doc.splitTextToSize('Stock/Appr Qty', 30);
                    doc.cell(220, 150, 40, 40, stockqty, '', 'C');
                    var avg = doc.splitTextToSize('Avg Cons Last Mth',40);
                    doc.cell(260, 150, 40, 40, avg, '', 'C');
                    var indentQty = doc.splitTextToSize('Indent Quantity',35);
                    doc.cell(290, 150, 40, 40, indentQty, '', 'C');
                    var dept = doc.splitTextToSize('Dept', 40);
                    doc.cell(310, 150, 25, 40, dept, '', 'C');
                    doc.cell(340, 150, 100, 20, '       Previous Details     ', '', 'C');
                    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

                    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
                    doc.cellInitialize();
                    doc.setFontSize(9);
                    doc.setFontType('normal');

                    var pono = doc.splitTextToSize('PO No/Dt', 30);

                    doc.cell(295, 170, 30, 20, pono, '', 'C');
                    doc.cell(320, 170, 30, 20, 'Rate', '', 'C');
                    var supName = doc.splitTextToSize('Supplier Name', 36);

                    doc.cell(350, 170, 40, 20, supName, '', 'C');
                    doc.cellInitialize();
                    doc.setFontSize(10);
                    doc.setFontType('normal');
                    doc.cell(395, 150, 100, 20, '          Comp Status          ', '', 'C');
                    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
                    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
                    doc.cellInitialize();
                    doc.setFontSize(9);
                    doc.setFontType('normal');
                    doc.cell(395, 170, 20, 20, '<12>', '', 'C');
                    doc.cell(420, 170, 20, 20, '<13>', '', 'C');
                    doc.cell(450, 170, 20, 20, '<14>', '', 'C');
                    doc.cell(470, 170, 20, 20, '<15>', '', 'C');
                    doc.cell(490, 170, 20, 20, '<16>', '', 'C');
                    doc.cellInitialize();
                    doc.setFontSize(10);
                    doc.setFontType('normal');
                    var order = doc.splitTextToSize('Order No', 30);
                    doc.cell(495, 150, 30, 40, order, '', 'C');
                    var cntrl = doc.splitTextToSize('Cntrl Stock',30);
                    doc.cell(525, 150, 30, 40, cntrl, '', 'C');
                    var delivery = doc.splitTextToSize('Delivery Status',38);
                    doc.cell(555, 150, 36, 40, delivery, '', 'C');
                    doc.setFontSize(7);
                }
                var mytext="Max Quantity :30.00    Min Quantity: 15.00";

                var a = 0;
                return ([
                    Object.keys(prop).map((key, index) => {

                        a = a + 1;
                        var size=30;

                        /*    if(key==='itemCode'){
                                var size=90;
                            }else{
                                var size=45;
                            }*/
                        var myItem = prop[key]+" ";
                        myItem = doc.splitTextToSize(myItem,size);
                        // }


                        return (
                            doc.cell(10, k, cellWidths[a], 25, myItem, 2, cellTextaligns[a])

                        )
                    }),

                    // doc.text(30, k+10, mytext)-+-
                ])

            });

        }
        doc.cellInitialize();
        doc.setFontType("bold");
        doc.cell(10, k + 25, 20, 30, ' ', '', 'right');

        doc.cell(30, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(90, k + 25, 70, 30, ' ', '', 'right');
        doc.cell(180, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(220, k + 25, 40, 30,  ' ', '', 'right');
        doc.cell(260, k + 25, 40, 30,  ' ', '', 'right');
        doc.cell(290, k + 25, 40, 30,  ' ', '', 'right');
        doc.cell(310, k + 25, 25, 30, ' ', '', 'right');
        doc.cell(340, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(295, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(320, k + 25, 40, 30, ' ', '', 'right');
        doc.cell(350, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(395, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(420, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(450, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(490, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(495, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(525, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(555, k + 25, 36, 30, ' ', '', 'right');

        doc.setFontType("bold");
        doc.setFontSize(10);
        doc.text(30, k+150,'Checked By',);

        doc.text(200, k+150,'Store Signatory',);
        doc.text(350, k+150,'Authorised Signatory',);
        doc.text(30, k+170,'Note For Head Office',);
        doc.setFontType("normal");
        doc.text(30, k+190,'1. This indent copy is made only for office use, No other outside persons are entitled to see that.',);
        doc.text(30, k+210,'2. From column No. 1 to 11 to be filled from computer ot stores department and balance 12 to 18 are kept blank',);
        doc.text(50, k+230,'<a> 12 to 17 for rate comparison <b> 18 for writing order no after placing to supplier',);
        doc.text(30, k+250,'3. Pl also make sure that order item code & unit should be tallyed with Indent',);
        doc.text(30, k+270,'4. Pl also give indent & indent Srl No on order. This will help a lot for Stores job processing',);
        doc.text(30, k+290,'5.Column No 19 is showing Central Store Stock. So,Please see carefully before sending indent to HO',);
        doc.text(30, k+310,'3. Option available a) General Indent   b) O/H Indent   c) Open Indent',);







        //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today = moment().format('DD/MM/YYYY H:mm:ss');
        var name =getCookie(cons.USERID);
        var newdat = " Printed on: " + today;
        var printBy = " Printed By: " + name;
        doc.text(30, k + 350, newdat);
        doc.text(500, k + 350, printBy);
        addWaterMark(doc,printcopy);

        doc.save('Indent_'+mrid+'.pdf');


    }


}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default  withStyles(extendedFormsStyle)(LeavePolicies);

