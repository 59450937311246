import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "../../components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  configNames,
  getConfigValue,
  serverApi,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { supplierTypesList } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";

class SupplierMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      date1: moment().format("DD-MM-YYYY"),
      year: moment().format("YYYY"),
      suppCode: "",
      createdBy: "",
      supName: "",
      address: "",
      suppTyp: "",
      phone1: "",
      phone2: "",
      fax: "",
      cellPhone: "",
      gstno: "",
      panNo: "",
      cin: "",
      pincode: "",
      district: "",
      country: "",
      state: "",
      mukamList: [],
      ledgerGroup: "",
      openningBalance: "",
      invoiceList: [],
      autoId: "",
      email: "",
      branchAddress: "",
      gstNo: "",
      contactPerson: "",
      contactNo: "",
      stateCode: "",
      stateName: "",
      city: "",
      statesList: [],
      branchId: "",
      status: "",
      approverButton: false,
      statusDesc: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleSelectedPSValues = (defValue) => {
    console.log("sssssssssssssss", defValue);

    this.setState({
      // unitConvertion: defValue,
      suppTyp: defValue,
    });
    if (defValue.value === "J") {
      this.handleLoading();
      getDataFromUrl(
        serverApi.SEARCH_MUKAM + getCookie(cons.COMPANY_ID),
        this.handleItems
      );
    } else {
      this.setState({
        mukamList: [],
      });
    }
    //  console.log(`Option selected:`, this.state.unitConvertionName);
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_SIPPLIER_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.COMPANY_ID) +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp,
      list = [];

    if (header !== null) {
      if (resp.supLineItems && resp.supLineItems.length > 0) {
        resp.supLineItems.forEach((prop, key) => {
          var obj = { id: key, ...prop };
          list.push(obj);
        });
      }
      this.setState({
        updateView: true,
        isCreated: true,
        suppId: header.suppId,
        suppCode: header.id,
        createdBy: header.createdBy,
        createdOn: header.createdOn,
        mukamList: header.mukamsList,
        supName: header.suppName,
        address: header.address1,
        suppTyp: this.getDefaultSupType(header.suppTyp),
        phone1: header.phone1,
        phone2: header.phone2,
        fax: header.fax,
        cellPhone: header.cellPhone,
        email: header.email,
        vatno: header.vatno,
        ledgerGroup: header.ledgerGroup,
        contactPerson: header.contactPerson,
        designation: header.designation,
        openningBalance: header.openningBalance,
        gstno: header.gstNo,
        panNo: header.panNo,
        cin: header.cin,
        pincode: header.pincode,
        district: header.district,
        country: header.country,
        state: header.state,
        invoiceList: list,
        approverButton: header.approverButton,
        status: header.status,
        branchId: header.branchId,
        statusDesc: header.statusDesc,
      });
      if (header.suppTyp === "JUTE") {
        this.handleLoading();
        getDataFromUrl(
          serverApi.SEARCH_MUKAM + getCookie(cons.COMPANY_ID),
          this.handleItems
        );
      }
    }
  };
  getDefaultSupType = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "JUTE") {
        val = "J";
        label = "JUTE";
      } else if (name === "STORE") {
        val = "S";
        label = "STORE";
      } else if (name === "AGENT") {
        val = "O";
        label = "AGENT";
      } else {
        val = "V";
        label = "VENDOR";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });

    //  this.hideAlertMessage();
  };

  componentDidMount() {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);
    getDataFromUrl(serverApi.GET_ALL_STATES, this.handleStates);
  }
  handleStates = (resp) => {
    var list = [];
    if (resp.status === undefined) {
      resp.forEach((prop) => {
        var obj = prop;
        if (prop.value !== 0) obj.value = prop.label;
        else obj.value = "";
        list.push(obj);
      });
    }
    this.setState({
      statesList: list,
    });
  };

  handleLedgerGroup = (resp) => {
    const lgoptions = [];
    var ledger_data = resp.data;
    if (ledger_data !== null || ledger_data !== undefined) {
      ledger_data.forEach((r) => {
        const { name, id } = r;
        lgoptions.push({
          value: r.id,
          label: r.name,
          name: r.name,
        });
      });
    }
    this.setState({ ledger_data: lgoptions });
  };
  handleItems = (resp) => {
    var list = [];
    resp.map((prop, key) => {
      var val = {
        mukamId: prop.value,
        mukamName: prop.label,
        isEnabled: false,
      };
      if (this.state.updateView) {
        this.state.mukamList.map((o, i) => {
          if (o === val.mukamId) {
            val.isEnabled = true;
          }
        });
      }
      //console.log("mukamId", val);
      //  console.log("mukamId oo",o);
      if (key > 0) list.push(val);
    });
    this.setState({
      mukamList: list,
    });
    this.handleLoading();
  };

  getData = () => {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (index) => (event) => {
    var arr = this.state.mukamList;
    arr[index].isEnabled = !arr[index].isEnabled;
    this.setState({
      mukamList: arr,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      if (this.state.updateView) data[this.state.id].isActive = 0;
      else data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      autoId: "",
      branchAddress: "",
      gstNo: "",
      contactPerson: "",
      contactNo: "",
      stateCode: "",
      stateName: "",
      city: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      suppCode: "",
      createdBy: "",
      supName: "",
      address: "",
      suppTyp: "",
      phone1: "",
      phone2: "",
      fax: "",
      cellPhone: "",
      email: "",
      vatno: "",
      contactPerson: "",
      designation: "",
      gstno: "",
      panNo: "",
      cin: "",
      pincode: "",
      district: "",
      country: "",
      state: "",
      openningBalance: "",
      mukamList: [],
      invoiceList: [],
      branchId: "",
      status: "",
      approverButton: false,
      statusDesc: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        openningBalance,
        suppCode,
        suppId,
        mukamList,
        supName,
        address,
        suppTyp,
        phone1,
        phone2,
        fax,
        cellPhone,
        email,
        contactPerson,
        designation,
        gstno,
        panNo,
        cin,
        pincode,
        district,
        country,
        state,
        ledgerGroup,
        branchId,
      } = this.state;

      var list = [];
      mukamList.map((prop, key) => {
        if (prop.isEnabled) {
          var val = {
            id: prop.mukamId,
            mukamName: prop.mukamName,
          };
          list.push(val);
        }
      });
      var lineItem = [];
      invoiceList.forEach((prop) => {
        lineItem.push({
          ...prop,
          suppId: suppId,
          createdBy: getCookie(cons.USERID),
        });
      });
      var data = {
        suppId: suppId,
        id: suppCode,
        suppName: supName,
        address1: address,
        suppTyp: suppTyp.value,
        phone1: phone1,
        phone2: phone2,
        fax: fax,
        cellPhone: cellPhone,
        email: email,
        activeFlag: "",
        vatno: "",
        contactPerson: contactPerson,
        designation: designation,
        cin: cin,
        gstNo: gstno,
        panNo: panNo,
        district: district,
        state: state,
        country: country,
        pincode: pincode,
        createdBy: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        address2: address,
        cipher: getCookie(cons.CIPHER),
        ledgerGroup: ledgerGroup,
        openningBalance: openningBalance,
        mukam: list,
        status: status,
        branchId: branchId,
        userId: getCookie(cons.USERID),
        supLineItems: lineItem,
      };

      if (suppTyp !== "" && suppTyp !== 0 && supName !== "" && email !== "") {
        if (panNo.length === 10) {
          if (suppTyp.value === "J") {
            if (mukamList.length > 0) {
              this.handleLoading();
              requestList(
                serverApi.SUPPLIER_MASTER_UPDATE,
                data,
                this.handleAddIndent
              );
            } else {
              this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
          } else {
            this.handleLoading();
            requestList(
              serverApi.SUPPLIER_MASTER_UPDATE,
              data,
              this.handleAddIndent
            );
          }
        } else {
          this.basicAlert("Enter 10 character pan no.");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        openningBalance,
        mukamList,
        supName,
        address,
        suppTyp,
        phone1,
        phone2,
        fax,
        cellPhone,
        email,
        contactPerson,
        designation,
        gstno,
        panNo,
        cin,
        pincode,
        district,
        country,
        state,
        ledgerGroup,
        branchId,
      } = this.state;
      var list = [];
      mukamList.map((prop, key) => {
        if (prop.isEnabled) {
          var val = {
            id: prop.mukamId,
            mukamName: prop.mukamName,
          };
          list.push(val);
        }
      });
      var lineItem = [];
      invoiceList.forEach((prop) => {
        lineItem.push({ ...prop, createdBy: getCookie(cons.USERID) });
      });
      var data = {
        suppName: supName,
        address1: address,
        suppTyp: suppTyp.value,
        phone1: phone1,
        phone2: phone2,
        fax: fax,
        cellPhone: cellPhone,
        email: email,
        activeFlag: "",
        vatno: "",
        contactPerson: contactPerson,
        designation: designation,
        cin: cin,
        gstNo: gstno,
        panNo: panNo,
        district: district,
        state: state,
        country: country,
        pincode: pincode,
        createdBy: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        address2: address,
        cipher: getCookie(cons.CIPHER),
        mukam: list,
        ledgerGroup: ledgerGroup,
        autoCreate: false,
        openningBalance: openningBalance,
        status: 1,
        branchId: branchId,
        userId: getCookie(cons.USERID),
        supLineItems: lineItem,
      };
      if (
        suppTyp !== "" &&
        suppTyp !== 0 &&
        supName !== "" &&
        email !== "" &&
        branchId
      ) {
        if (suppTyp.value === "J") {
          if (mukamList.length > 0) {
            this.handleLoading();
            requestList(
              serverApi.SUPPLIER_MASTER_ADD,
              data,
              this.handleAddIndent
            );
          } else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
          }
        } else {
          if (this.state.panNo.length === 10) {
            this.handleLoading();
            requestList(
              serverApi.SUPPLIER_MASTER_ADD,
              data,
              this.handleAddIndent
            );
          } else {
            this.basicAlert("Enter 10 character pan no.. !!");
          }
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    const {
      autoId,
      email,
      branchAddress,
      gstNo,
      contactPerson,
      contactNo,
      stateCode,
      stateName,
      city,
    } = this.state;

    var addItemObj = {
      id: itemIndex,
      autoId: autoId,
      isActive: 1,
      email: email,
      branchAddress: branchAddress,
      gstNo: gstNo,
      contactPerson: contactPerson,
      contactNo: contactNo,
      stateCode: stateCode,
      stateName: stateName,
      city: city,
    };

    if (
      branchAddress !== "" &&
      email !== "" &&
      contactNo !== "" &&
      gstNo !== "" &&
      gstNo !== undefined &&
      stateCode !== undefined &&
      stateCode !== "" &&
      stateName !== "" &&
      stateName !== undefined
    ) {
      if (gstNo.slice(0, 2) === stateCode.slice(0, 2)) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);
        //console.log(arr.toString());

        this.setState({
          loading: true,
          invoiceList: arr,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Enter the correct gst no");
      }
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    console.log("unitConversionName " + this.state.unitConvertion);
    //  var id="";
    console.log(this.state);
    this.setState({
      loading: true,
      alert: true,
    });
  }

  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.forEach((prop, key) => {
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // alert(o.qualityId);
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        //  data.splice(i,1);

                        this.basicItemAlert("Do You Like To Update This Item?");
                        this.setState({
                          //alert: true,
                          updateItem: true,
                          updateItemAlert: true,
                          id: o.id,
                          autoId: o.autoId,
                          email: o.email,
                          branchAddress: o.branchAddress,
                          gstNo: o.gstNo,
                          contactPerson: o.contactPerson,
                          contactNo: o.contactNo,
                          stateCode: o.stateCode,
                          stateName: o.stateName,
                          city: o.city,
                        });

                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });
                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };

  render() {
    const { classes } = this.props;
    const { ledger_data } = this.state;

    const psoptions =
      getConfigValue(configNames.SUPPLIER_TYPES) === "1"
        ? supplierTypesList
        : JSON.parse(getConfigValue(configNames.SUPPLIER_TYPES));
    //   console.log("SUPPLIER_TYPES", psoptions);
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    const options = ledger_data;
    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Address
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="branchAddress"
                                  value={this.state.branchAddress}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  multiline={true}
                                  rowsMax={3}
                                  height={60}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  City
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="city"
                                  value={this.state.city}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  State
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  onSelect={this.onSelect}
                                  defValue={this.state.stateName}
                                  getName={(name) => {
                                    var val = name.split("^");
                                    this.setState({
                                      stateCode:
                                        val[1] !== "null" ? val[1] : "",
                                    });
                                  }}
                                  stateKey="stateName"
                                  staticData={this.state.statesList}
                                  // url={serverApi.GET_ALL_STATES}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  State Code
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  disabled={true}
                                  id="stateCode"
                                  value={this.state.stateCode}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  GST No
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="gstNo"
                                  value={this.state.gstNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Contact No
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="contactNo"
                                  value={this.state.contactNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Contact Person
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="contactPerson"
                                  value={this.state.contactPerson}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Email
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="email"
                                  value={this.state.email}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      }
                    />
                  ) : null}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="supName"
                          value={this.state.supName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier Code
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="suppCode"
                            value={this.state.suppCode}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          // selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Address
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="address"
                          value={this.state.address}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          District
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="district"
                          value={this.state.district}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>State</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="state"
                          value={this.state.state}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Country
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="country"
                          value={this.state.country}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Pincode
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="pincode"
                          value={this.state.pincode}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateView}
                          defValue={this.state.suppTyp}
                          name={"suppTyp"}
                          value={this.state.suppTyp}
                          onChange={this.handleSelectedPSValues}
                          options={psoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",

                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Phone1
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="phone1"
                          value={this.state.phone1}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Phone2
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="phone2"
                          value={this.state.phone2}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Fax</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="fax"
                          value={this.state.fax}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Cell Phone
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="cellPhone"
                          value={this.state.cellPhone}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Email
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="email"
                          value={this.state.email}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Contact Person
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="contactPerson"
                          value={this.state.contactPerson}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "3" ? "VAT No" : "GST No"}
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="gstno"
                          value={this.state.gstno}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "3" ? "CR No" : "PAN No"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <input
                          id="panNo"
                          value={this.state.panNo
                            .toUpperCase()
                            .replace(/[^\w\s]/gi, "")}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                          maxLength={10}
                          style={{
                            color: "#000",
                            // fontFamily: defaultFont,
                            paddingLeft: "10px",
                            //textAlign: "right",
                            // width: '20%',
                            height: "30px",
                            width: "260px",
                            borderRadius: 4,
                            // backgroundColor: 'white',
                            border: "1px solid #D2D2D2",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>CIN</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="cin"
                          value={this.state.cin}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ledger Group
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"ledgerGroup"}
                          defValue={this.state.ledgerGroup}
                          onSelect={this.onSelect}
                          //  getName={this.getGateEntryDetails}
                          stateKey="ledgerGroup"
                          staticData={options}
                          // value={this.state.gateEntryNo}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Opening Balance
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="openningBalance"
                          value={this.state.openningBalance}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.suppTyp.value === "J" ? (
                    <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={2}>
                          <InputLabel className={classes.label}>
                            Mukam List
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} md={10}>
                          <GridContainer>
                            {this.state.mukamList.map((prop, key) => {
                              return (
                                <ItemGrid xs={12} sm={4}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        value={prop.isEnabled ? 1 : 0}
                                        checked={prop.isEnabled ? 1 : 0}
                                        onChange={this.handleChange(key)}
                                        // onClick={() => this.handleToggle(prop)}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                        }}
                                        style={{ marginLeft: 10 }}
                                      />
                                    }
                                    classes={{
                                      label: classes.menulabel,
                                    }}
                                    label={prop.mukamName}
                                  />
                                </ItemGrid>
                              );
                            })}
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12}>
                    <LineItemTable
                      data={this.getDataTable()}
                      loading={this.state.loading}
                      columns={[
                        {
                          Header: "Branch Address",
                          accessor: "branchAddress",
                          minWidth: 150,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "GST Number",
                          accessor: "gstNo",
                          minWidth: 150,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Contact Number",
                          accessor: "contactNo",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Contact Person",
                          accessor: "contactPerson",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Actions",
                          minWidth: 100,
                          accessor: "actions",
                          style: { color: "#000", textAlign: "center" },
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                    />
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={6}>
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: 400,
                              color: "#003F66",
                            }}
                          >
                            Status:
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#003F66",
                            }}
                          >
                            {this.state.statusDesc}
                            <br />
                          </span>
                        </ItemGrid>
                        <ItemGrid xs={6}>
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12}>
                      <Button
                        color={"info"}
                        right={true}
                        onClick={this.handleAddItem}
                      >
                        Add Item
                      </Button>
                    </ItemGrid>
                  )}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approverButton ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick(this.state.status);
                              /* this.setState({
                                                                    isApprove: true,
                                                                });
                                                                this.InvoicebasicAlert("Do You Like To Approve This PO..!!");*/
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {!this.state.updateView ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => {
                            this.onUpdateButtonClick("");
                          }}
                        >
                          Update
                        </Button>
                      )}
                      <ItemGrid>
                        <Button color="danger" onClick={this.handleCreate}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.SUPPLIER_MASTER_TABLE_LIST}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(SupplierMaster);
