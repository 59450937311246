import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";


class FormulaMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,

            isRefresh: false,
            isLoading: false,
            itemViewType: "paramId",
            approveStatus: false,

            expression:'',
            description:'',
            formulaName:'',
            decodedExpression:'',
            dataTable:null,
            tableList:[]


        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);

    }


    handleSeacrh = () => {
        var loading = false;
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("paramId  " + "  " + id);
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var data={
                "paramId": id,
                "cipher":getCookie(cons.CIPHER)
            }
            getDataFromUrl(serverApi.PAY_SCHEME_PARAMETERS_DETAILS_BYID + "/" + id, this.handleViewClickResp)
        }
    };




    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };

    mainMenu=()=>{
        this.props.history.push("/payscheme");
    };
    getData = () => {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };

    hasWhiteSpace(s) {

        let reWhiteSpace = new RegExp(/\s/);
        // Check for white space
        if (reWhiteSpace.test(s)) {
            this.basicAlert(" Space is not Allowed..!!");
            return false;
        }else{
            this.setState({
                formulaName: s
            });
        }
        return true;
    }

    onChangeValues = (event) => {
        var key= event.target.id;
        var value = event.target.value;

        if(key==='formulaName'){
            this.hasWhiteSpace(value);

        }else{
            this.setState({
                [event.target.id]: value
            });
        }
        //console.log("val "+value);


    };

    onSelect = (selectedValue, stateKey) => {


            this.setState({
                [stateKey]: selectedValue
            });
    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            //spellName:'',
           // workingHours:'',
            //startTime:'',
            //endTime:''

        });
    }

    hideAlertMessage() {
        // if(this.state.isCreated){
            this.callFormulaList();
        // }
        this.setState({

            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            description:'',
            expression:'',
            formulaName:'',
            decodedExpression:''

        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }




    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {description, expression,formulaName} = this.state;
            var data = {
                "description":description,
                "expression":expression,
                "formulaName":formulaName,
                "decodedExpression":expression,
                "createdBy":getCookie(cons.USERID),
                "companyId":getCookie(cons.COMPANY_ID)
            };

            if (expression !== '' && description !== "" && formulaName!=='' ) {
                this.handleLoading();
                requestList(serverApi.FORMULA_MASTER_ADD, data, this.handleAddParameter)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddParameter = (resp) => {
        if (resp.status) {

            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.msg, resp.status)
        } else {
            this.showMessage(resp.msg, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    componentDidMount(){
        this.callFormulaList();
    }
    callFormulaList(){
        getDataFromUrl(serverApi.FORMULA_MASTER_LIST+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.USERID),this.handleFormulaList)
    }

    handleFormulaList=(resp)=>{
         var tableList={"data":resp,"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"hrmsAccess":false,"column":[{"filterable":"true","Header":"Formula Name","accessor":"formulaName","minWidth":100,"style":{"textAlign":"center"},"sortable":"true"},
                 {"filterable":"true","Header":"Expression","accessor":"expression","minWidth":150,"style":{"textAlign":"center"},"sortable":"true"},
                 {"filterable":"true","Header":"Description","accessor":"description","minWidth":100,"style":{"textAlign":"center"},"sortable":"true"}]}

         this.setState({isRefresh:true,tdata:tableList});


    };


    render() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;

        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.mainMenu}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Formula Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="formulaName"
                                                    value={this.state.formulaName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Description<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="description"
                                                    value={this.state.description}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Expression<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="expression"
                                                    value={this.state.expression}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    height={80}
                                                    rowsMax={5}
                                                    multiline={true}
                                                    InputProps={inputFocus}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.mainMenu}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }


                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>

                                    <ItemGrid xs={12}>


                                        {/*{this.state.dataTable!==null?*/}
                                        <TableComponent
                                            //  url={serverApi.ACCOUNT_BILLS_TABLE}
                                            //searchData={this.getData()}
                                            isRefresh={this.state.isRefresh}
                                            handleSearch={this.handleSeacrh.bind(this)}
                                           staticData={this.state.tdata}
                                            isLoading={this.state.isRefresh}
                                            // onActionClick={this.handleViewClick}
                                            // itemViewType={this.state.itemViewType}
                                            // // fileName={getCookie(cons.COMPANY_NAME)+'_PAYMENTS'}
                                            // showDelete={true}
                                            // onDeleteClick={this.onDeleteParamater}
                                            // actionType={VIEW}
                                        />

                                    </ItemGrid>
                                </GridContainer>
                            }/>

                    </ItemGrid>

            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(FormulaMaster);
