import React from "react";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import TableComponent from "../CustumComponents/TableComponent";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, serverVars} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";


class JuteIssue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            itemViewType: "issueNo",
            date1: moment().format('DD-MM-YYYY'),
            time: moment().format('hh:mm:ss A'),
            time2: moment().format('HH:mm:ss'),
            selectedJuteQuality: "",
            selectedJuteType: "",
            godownNo: "",
            trollyId: "",
            noOfBales: "",
            grossWeightKG: "",
            tareWeightKG: "",
            netWeightKG: "",
            netWeightTotalKG: "",
            unitConversion: "",
            yarnId: "",
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }

    componentDidMount() {

        getDataFromUrl(serverApi.GET_JUTE_ISSUE_NET_WEIGHT_BY_DATE + this.state.date1 + "/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER), this.handleTotalNetWeight)
        this.callJuteType();

    }


    callJuteType() {

        var data = {
            "itemGroupId": "999",
            "companyId": getCookie(cons.COMPANY_ID)
        };
        requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleJuteType);

    }

    handleJuteType = (resp) => {
        console.log("rrrrrrrrrrrrrrrrrrr", resp);
        this.setState({juteTypeItems: resp})


    }
    handleTotalNetWeight = (resp) => {
        if (resp.status) {
            var weight = 0;
            if (resp.data > 0) {
                weight = (resp.data * 100).toFixed(2);
                //    weight=parseFloat(weight).toFixed(2)
            }
            this.setState({
                netWeightTotalKG: weight
            })
        }
        // var data = this.state.dataTable;

    };

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            getDataFromUrl(serverVars.PO_API + "getJuteIssueById/" + id + "/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER), this.handleViewClickResp)
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
        }
    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?", id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp = (resp) => {
        if (resp.status) {
            this.showMessage(resp.message, resp.status);
            this.setState({
                isRefresh: true
            })
        } else {
            this.showMessage(resp.message, resp.status);
        }
    };

    handleViewClickResp = (resp) => {
        if (resp.status) {
            var header = resp.data;

            if (header !== null) {
                this.setState({
                    updateView: true,
                    isCreated: true,
                    issueId: header.issueNo,
                    selectedJuteType: parseInt(header.juteType),
                    godownNo: header.godownNo,
                    trollyId: header.trollyNo,
                    noOfBales: header.noOfBales,
                    grossWeightKG: header.grossWeight,
                    tareWeightKG: header.tareWeight,
                    netWeightKG: header.netWeight,
                    date1: header.issueDateDesc,
                    //   time:moment().format('hh:mm:ss A'),
                    time2: moment().format('HH:mm:ss'),
                    netWeightTotalKG: "",
                    unitConversion: "",
                    yarnId: header.yarnId

                });

            }

        }


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        if (this.state.fromDate !== "" || this.state.todate !== "") {
            var data = {
                "fromDate": this.state.fromDate
                , "toDate": this.state.todate,
                "companyId": getCookie(cons.COMPANY_ID),
                "cipher": getCookie(cons.CIPHER)
            };
        } else
            var data = {
                "fromDate": this.state.fromDate
                , "toDate": this.state.todate,
                "cipher": getCookie(cons.CIPHER)
            };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

        if (id === 'date1') {
            getDataFromUrl(serverApi.GET_JUTE_ISSUE_NET_WEIGHT_BY_DATE + dateObj.format('DD-MM-YYYY') + "/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER), this.handleTotalNetWeight)
        }

        //this.dateChanged();
    };
    dateChanged = () => {
        var myDate = this.state.date1;
        if (myDate != null) {
            myDate = myDate.split("-");
            var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
            return new Date(newDate).getTime();
        }

    }
    getName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            selectedJuteName: values[0],
            uom: values[1],

        });
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        var type = this.state.selectedJuteType;
        /*  this.setState({
              [stateKey]: selectedValue,
          });*/
        if(stateKey==='unitConversion'&&selectedValue!==this.state.unitConversion){
            this.getMRList(this.state.selectedJuteType,this.state.form_QualityId,selectedValue,this.state.godownNo)
        }
        if(stateKey==='form_QualityId'&&selectedValue!==this.state.form_QualityId){
            this.getMRList(this.state.selectedJuteType,selectedValue,this.state.unitConversion,this.state.godownNo)
        }
        if(stateKey==='godownNo'&&selectedValue!==this.state.godownNo){
            this.getMRList(this.state.selectedJuteType,this.state.form_QualityId,this.state.unitConversion,selectedValue)
        }
        if (stateKey === 'selectedJuteType') {
            this.setState({
                loadQuality: true,
            });
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }

    hideAlertMessage() {
        this.setState({
            isRefresh: true,
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            // date1:moment().format('DD-MM-YYYY'),
            time: moment().format('hh:mm:ss A'),
            time2: moment().format('HH:mm:ss'),
            form_QualityId: 0,
            //  selectedJuteType:"0",
            // godownNo:0,
            trollyId: 0,
            noOfBales: "",
            grossWeightKG: "",
            tareWeightKG: "",
            netWeightKG: "",
            netWeightTotalKG: "",
            //  unitConversion:0,
            // yarnId:0,
            reloadSelect: true
        });
        getDataFromUrl(serverApi.GET_JUTE_ISSUE_NET_WEIGHT_BY_DATE + moment().format('DD-MM-YYYY') + "/" + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER), this.handleTotalNetWeight)

    }


    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverVars.PO_API + "deleteJuteIssueById/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: [],
            sumOFQuantities: 0

        });

    }

    timeChangedFormat = (myDate, time) => {
        console.log("time", myDate + time);

        myDate = myDate.split("-");
        var timeParts = time.split(":");
        return new Date(myDate[2], myDate[1] - 1, myDate[0], timeParts[0], timeParts[1], timeParts[2]).getTime();
    };


    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var data = {
                "issueId": this.state.indentNo,
                "juteType": this.state.selectedJuteType,
                "finYear": this.state.year,
                "issueDate": this.timeChangedFormat(this.state.date1, this.state.time2),
                "juteQuality": this.state.form_QualityId,
                "baleOrLoose": this.state.unitConvertionName,
                "noOfBales": this.state.noOfBales,
                //"quantity": this.state.form_quantity,
                "uomCode": this.state.uom,
                "openStock": "",
                "closeStock": "",
                "issueStatus": '',
                "godownNo": this.state.godownNo,
                "side": this.state.processSideName,
                "createdBy": getCookie(cons.USER_NAME),
                "wastageId": this.state.wastageType,
                "yarnId": this.state.yarnId,
                "mrLineItemId":this.state.mrLineItemId

            };
            if (this.state.date1 !== '' && this.state.noOfBales !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                this.handleLoading();
                requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };


    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {date1, noOfBales, selectedJuteType, form_QualityId, godownNo, grossWeightKG, tareWeightKG, yarnId} = this.state;
            var data = {
                "juteType": this.state.selectedJuteType,
                "issueDate": this.timeChangedFormat(this.state.date1, this.state.time2),
                "juteQuality": this.state.form_QualityId,
                "baleOrLoose": this.state.unitConvertionName,
                "noOfBales": this.state.noOfBales,
                "grossWeight": parseFloat(this.state.grossWeightKG) / 100,
                "tareWeight": parseFloat(this.state.tareWeightKG) / 100,
                "netWeight": (parseFloat(this.state.grossWeightKG) - parseFloat(this.state.tareWeightKG)) / 100,
                "godownNo": this.state.godownNo,
                "side": this.state.processSideName,
                "yarnId": this.state.yarnId,
                "createdBy": getCookie(cons.USER_NAME),
                "trollyNo": this.state.trollyId,
                "companyId": getCookie(cons.COMPANY_ID),
                "cipher": getCookie(cons.CIPHER),
                "mrLineItemId":this.state.mrLineItemId
            };
            if (date1 !== '' && noOfBales !== "" && selectedJuteType !== "" && selectedJuteType !== "0" &&
                form_QualityId !== "" && form_QualityId !== "0" && godownNo !== '' && godownNo !== '0' && grossWeightKG !== '' && tareWeightKG !== '' && yarnId !== "" && yarnId !== 0) {
                if (this.getNetWeight() > 0) {
                    this.handleLoading();
                    requestList(serverApi.ADD_APP_DATA_JUTE_ISSUE, data, this.handleAddIndent)
                } else
                    this.basicAlert("Gross Weight Should Be Greater Than Tare Weight..!!");

            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            this.hideAlertMessage();
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };


    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlert();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    timeChanged1 = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('hh:mm:ss A'),
                time2: dateObj.format('HH:mm:ss')
            });
    };

    getNameOfUnitConversion() {
        var val = this.state.unitConversion;
        if (val === 1) {
            return "No Of Bundles"
        } else if (val === 2) {
            return "No Of Bales"
        } else {
            return "No Of Bales/Bundles"
        }
    }

    getDefaultSideConvertion = (name) => {
        var val = "", label = "", data = "";
        if (name !== null) {
            if (name === 'WARP') {
                val = 1;
                label = "WARP";
            } else {
                val = 2;
                label = 'WEFT';
            }

            data = {
                value: val, label: label
            };
        }
        return data;
    }
    getUnitName = (selectedValue) => {
        var values = selectedValue;
        this.setState({
            unitConvertionName: selectedValue

        });
    };
    getProcessName = (selectedValue) => {
        var values = selectedValue;
        this.setState({
            processSideName: selectedValue
        });
    };
    getTrollyDetails = (selectedValue) => {
        var val = selectedValue.split("^");
        this.setState({
            tareWeightKG: val[1]
        });

    }

    afterReloadSelect = (val) => {
        this.setState({
            reloadSelect: val
        })
    };

    getNetWeight() {
        if (this.state.grossWeightKG !== '' && this.state.tareWeightKG !== '')
            return parseFloat(this.state.grossWeightKG) - parseFloat(this.state.tareWeightKG);
        else return 0
    }

    getMRList = (selectedJuteType, form_QualityId, unit, godownNo) => {
        var unitConversion="";
        if(unit===1){
            unitConversion="LOOSE"
        }
        if(unit===2)
            unitConversion="BALE"
        var data = {
            "godownNo": godownNo,
            "juteType": selectedJuteType,
            "qualityId": form_QualityId > 0 ? form_QualityId : "",
            "unitConversion": unitConversion,
            "cipher": getCookie(cons.CIPHER),
            "companyId": getCookie(cons.COMPANY_ID)

        };
        requestList(serverApi.GET_STOCK_DETAILS_BY_GODOWN_NO, data, this.handleMrList)
    };
    handleMrList = (resp) => {
        if (resp.length > 0) {
            this.setState({
                mrOptions: resp,
                loadMr: true,
            });
        }

    };

    render() {
        const {classes} = this.props;
        const psoptions = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'WARP', name: 'WARP'},
            {value: 2, label: 'WEFT', name: 'WEFT'},

        ];
        const options = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'LOOSE', name: 'LOOSE'},
            {value: 2, label: 'BALE', name: 'BALE'},
        ];

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                <ItemGrid xs={12}>
                    <RegularCard
                        isLoading={this.state.isLoading}
                        content={
                            <GridContainer>
                                {this.state.basicAlert}
                                {this.state.showMessagealert}

                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Datetime
                                                    defaultValue={new Date()}
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    onChange={(dateObj) => {
                                                        this.timeChanged(dateObj, "date1")
                                                    }}
                                                    formControlProps={
                                                        {height: "10px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id: "date1",
                                                        value: this.state.date1,
                                                        autoComplete: 'off'
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Time<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <FormControl variant="" fullWidth>
                                                <Datetime
                                                    defaultValue={new Date()}
                                                    closeOnSelect={true}
                                                    // defaultValue={this.state.time1}
                                                    dateFormat={false}
                                                    onChange={(timeObj) => {
                                                        this.timeChanged1(timeObj, "time")
                                                    }}
                                                    height={"15px"}
                                                    formControlProps={
                                                        {height: "15px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id: "time",
                                                        value: this.state.time,
                                                        autoComplete: 'off'
                                                    }}

                                                />
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>

                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Unit Conversion<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                id={"unitConversion"}
                                                selectDisabled={this.state.updateView}
                                                defValue={this.state.unitConversion}
                                                onSelect={this.onSelect}
                                                getName={this.getUnitName}
                                                stateKey="unitConversion"
                                                staticData={options}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                // url={serverConfig.SERVER_URL + "master-v2/itemmaster/getItemsByItemGroupId/999"}
                                                value={this.state.unitConversion}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Yarn Type<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                id={"yarnId"}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                selectDisabled={this.state.updateView}
                                                defValue={this.state.yarnId}
                                                onSelect={this.onSelect}
                                                //getName={this.getProcessName}
                                                stateKey="yarnId"
                                                // staticData={psoptions}
                                                url={serverApi.YARN_MASTER_TYPE_LIST + getCookie(cons.COMPANY_ID)}
                                                value={this.state.yarnId}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>


                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Jute Type<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        {this.state.juteTypeItems !== undefined ?

                                            <ItemGrid xs={12} sm={8}>

                                                <SelectboxTemp
                                                    id={"selectedJuteType"}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    selectDisabled={this.state.updateView}
                                                    defValue={this.state.selectedJuteType}
                                                    onSelect={this.onSelect}
                                                    // getName={this.getName}
                                                    stateKey="selectedJuteType"
                                                    staticData={this.state.juteTypeItems}
                                                    //  url={serverApi.GET_ITEMS_BY_GRPID + "999/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.selectedJuteType}

                                                />
                                            </ItemGrid> : null
                                        }
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Quality<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.form_QualityId}
                                                id={"form_QualityId"}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                isRefresh={this.state.loadQuality}
                                                setIsRefresh={this.afterRefreshQuality}
                                                //  getName={this.getQualityDetails}
                                                onSelect={this.onSelect}
                                                stateKey="form_QualityId"
                                                url={serverVars.PO_API + 'api/v1/' + this.state.selectedJuteType + "/" + getCookie(cons.COMPANY_ID) + '/getQualityByItem'}
                                                value={this.state.form_QualityId}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Godown No<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.godownNo}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                id={"godownNo"}
                                                //  getName={this.getQualityDetails}
                                                onSelect={this.onSelect}
                                                stateKey="godownNo"
                                                postData={{"companyId":getCookie(cons.COMPANY_ID),"type":"J","branchId":""}}
                                                url={serverApi.SELECT_GODOWN_LIST}
                                                value={this.state.godownNo}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                MR No.
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>


                                            <SelectboxTemp
                                                defValue={this.state.mrLineItemId}
                                                id={"mrLineItemId"}
                                                getName={this.handleSelectedMrValues}
                                                onSelect={this.onSelect}
                                                isRefresh={this.state.loadMr}
                                                setIsRefresh={(val) => {
                                                    this.setState({loadMr: false})
                                                }}
                                                stateKey="mrLineItemId"
                                                staticData={this.state.mrOptions}
                                            />


                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Jute Trolly<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.trollyId}
                                                id={"trollyId"}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                getName={this.getTrollyDetails}
                                                onSelect={this.onSelect}
                                                stateKey="trollyId"
                                                url={serverApi.GET_TROLLY_LIST + '10/' + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.CIPHER)}
                                                value={this.state.trollyId}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                {this.getNameOfUnitConversion()}<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="noOfBales"
                                                // label="Multiline"
                                                value={this.state.noOfBales}
                                                onChange={this.onChangeValues}
                                                //className={classes.textField}
                                            />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Gross Weight<span style={{color: "red"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="grossWeightKG"
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.grossWeightKG}

                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{
                                                            color: "#00ABDC",
                                                            fontSize: "16px",
                                                            fontWeight: "400"
                                                        }}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Tare Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="tareWeightKG"
                                                disabled={true}
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.tareWeightKG}
                                                fullWidth={true}
                                                variant="outlined"

                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{
                                                            color: "#00ABDC",
                                                            fontSize: "16px",
                                                            fontWeight: "400"
                                                        }}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Net Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                //id="netWeightKG"
                                                disabled={true}
                                                className={classes.textField}
                                                // onChange={this.onChangeValues}
                                                value={this.getNetWeight()}
                                                fullWidth={true}
                                                variant="outlined"

                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{
                                                            color: "#00ABDC",
                                                            fontSize: "16px",
                                                            fontWeight: "400"
                                                        }}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Total Net Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="netWeightTotalKG"
                                                disabled={true}
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.netWeightTotalKG}
                                                fullWidth={true}
                                                variant="outlined"

                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{
                                                            color: "#00ABDC",
                                                            fontSize: "16px",
                                                            fontWeight: "400"
                                                        }}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>


                                {!this.state.updateView ?
                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid>
                                                <Button color="primary"
                                                        onClick={this.onCreateButtonClick}>Create</Button>
                                            </ItemGrid>

                                            <ItemGrid>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid> : null
                                }
                                <ItemGrid xs={12}
                                          style={{
                                              height: 2,
                                              backgroundColor: "#00acc1",
                                              marginTop: "5px"
                                          }}></ItemGrid>
                                <ItemGrid xs={10} style={{marginTop: "20px"}}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={6}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        From date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            defaultValue={new Date()}

                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "fromDate")
                                                            }}
                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "fromDate",
                                                                value: this.state.fromDate,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        To date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            defaultValue={new Date()}

                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "todate")
                                                            }}
                                                            inputProps={{
                                                                id: "toDate",
                                                                value: this.state.todate,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={2}>
                                    <GridContainer>
                                        <ItemGrid xs={12} style={{marginTop: "10px"}}>
                                            <br/>
                                            <br/>
                                            <Button color="primary" right={true}
                                                    onClick={this.handleSeacrh}>Search</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>

                                {
                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>
                                }

                                <ItemGrid xs={12}>

                                    {
                                        <TableComponent
                                            url={serverApi.APP_DATA_JUTE_ISSUE_TABLE}
                                            searchData={this.getData()}
                                            isRefresh={this.state.isRefresh}
                                            handleSearch={this.handleSeacrh.bind(this)}
                                            isLoading={this.state.isRefresh}
                                            onActionClick={this.handleDeleteClick}
                                            itemViewType={this.state.itemViewType}
                                            actionType={DELETE}
                                            showDelete={false}
                                            fileName={'JuteToFloor'}

                                            onDeleteClick={this.handleDeleteClick}
                                        />
                                    }

                                </ItemGrid>


                            </GridContainer>
                        }/>

                </ItemGrid>

            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(JuteIssue);
