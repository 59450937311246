import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Add from "@material-ui/icons/Add";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import withStyles from "material-ui/styles/withStyles";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import FormControl from "material-ui/Form/FormControl";

class PaySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment().format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "indentNo",
      year: moment().format("YYYY"),
      dataTable: null,
      EducationList: [],
      documentsList: [],
      paySchemeList: [],
      payschemeId: "",
      reportType: 0,
      branchId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    /*  var dataTable= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":false,"Header":"Pay Scheme","accessor":"indentNo","minWidth":30,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"No Of Employee","accessor":"indentLineId","minWidth":25,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"Gross","accessor":"indentDate","minWidth":30,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"Net","accessor":"itemCode","minWidth":30,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"PF","accessor":"itemDesc","minWidth":30,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"ESI","accessor":"indentQty","minWidth":30,"style":{"textAlign":"left"},"sortable":false}]};

        this.setState({
            dataTable:dataTable,
            isRefresh: true,
        })*/

    this.callPaySchemeList();
  }
  callPaySchemeList() {
    var data = {
      status: "",
      activeStatus: "",
    };
    requestList(serverApi.PAY_SCHEME_GET_LIST, data, this.handlePaySchemeList);
  }

  handlePaySchemeList = (resp) => {
    var payscheme = [];
    resp.data.map((prop, i) => {
      var obj = {
        value: prop.payschemeId,
        name: prop.payschemeName,
        label: prop.payschemeName,
      };
      payscheme.push(obj);
    });
    this.setState({ paySchemeList: payscheme, loadPayscheme: true });
  };

  getReportsTable = () => {
    var data = {
      taskCode: 1011,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        branchId: this.state.branchId,
        reportType: this.state.reportType,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: getCookie(cons.COMPANY_ID),
    };
    //  this.handleLoading();
    //  requestList(serverApi.REPORTS_JUTE_WITH_VALUE,data,this.handleReports)
  };
  handleReports = (resp) => {
    // console.log("reports",JSON.stringify(resp));
    var dataTable = resp;
    if (dataTable !== null) {
      var columns = dataTable.data.column.map((prop, key) => {
        var obj = prop;
        obj.filterable = false;
        obj.sortable = false;
        obj.minWidth = 30;
        //  obj.maxWidth=50;
        // delete obj.minWidth;
        delete obj.maxWidth;
        return obj;
      });
    }
    dataTable.data.column = columns;
    console.log("columns", JSON.stringify(dataTable.data.column));

    if (resp.status) {
      this.setState({
        dataTable: dataTable,
        isRefresh: true,
      });
    }
    this.handleLoading();
  };

  handleSeacrh = () => {
    var loading = false;
    /*  if(!this.state.isRefresh)
            this.getReportsTable();*/
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      fromDate: this.state.fromDate,
      branchId: this.state.branchId,
      reportType: this.state.reportType,
      payschemeId: this.state.payschemeId,
      toDate: this.state.toDate,
      userId: getCookie(cons.USERID),
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    // this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      quantity: "",
      stock: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 8) {
      return length;
    } else {
      return 8;
    }
  };
  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  render() {
    const { classes } = this.props;
    const inputFocus = {
      autoComplete: "off",
    };
    const reportType = [
      { value: 0, label: "Both", name: "Both" },
      { value: 1, label: "Category Wise", name: "category" },
      { value: 2, label: "Pay Scheme Wise", name: "Pay Scheme Wise" },
    ];
    var yesterday = Datetime.moment().subtract(1, "day"),
      payschemeOptions = [];

    var valid = function(current) {
      if (getCookie(cons.COMPANY_ID) !== "2") return current.isAfter(yesterday);
      else return true;
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <div>
                <GridContainer>
                  <ItemGrid xs={10}>
                    <GridContainer>
                      <ItemGrid xs={4}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              From date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="outlined" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "fromDate");
                                }}
                                formControlProps={{
                                  height: "10px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "fromDate",
                                  value: this.state.fromDate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={4}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              To Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="outlined" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "todate");
                                }}
                                formControlProps={{
                                  height: "10px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "toDate",
                                  value: this.state.todate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={4}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Pay Scheme
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              //   defValue={this.state.payschemeId}
                              //   onSelect={this.onSelectBranch}
                              //   stateKey="payschemeId"
                              //   staticData="payschemeOptions"
                              //   isRefresh={this.state.loadPayscheme}
                              //   setIsRefresh={(val) => {
                              //     this.setState({ loadPayscheme: false });
                              //   }}
                              //   staticData={this.state.paySchemeList}
                              //   // url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                              defValue={this.state.paySchemeList}
                              id={"paySchemeList"}
                              onSelect={this.onSelect}
                              stateKey="oldPayscheme"
                              staticData={payschemeOptions}
                              value={this.state.paySchemeList}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      {/*
                                            <ItemGrid xs={4} sm={4}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Pay Period
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <SelectboxTemp
                                                            id={"payPeriod"}
                                                            defValue={this.state.payPeriod}
                                                            onSelect={this.onSelect}
                                                            getName={this.getName}
                                                            stateKey="payPeriod"
                                                            url={serverApi.GET_PO_BY_SUPPLIER+this.state.selectedSupplier+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                            value={this.state.payPeriod}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
*/}
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={10}>
                    <GridContainer>
                      <ItemGrid xs={4}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Branch
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              selectDisabled={false}
                              defValue={this.state.branchId}
                              onSelect={this.onSelect}
                              //  getName={this.getQualityName}
                              stateKey="branchId"
                              // isRefresh={this.state.loadQuality}
                              // setIsRefresh={this.afterRefreshQuality}
                              url={
                                serverApi.SELECT_BRANCH +
                                getCookie(cons.COMPANY_ID)
                              }
                              value={this.state.branchId}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={4}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Report Type
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.reportType}
                              staticData={reportType}
                              stateKey="reportType"
                              onSelect={this.onSelect}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={4}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label} />
                          </ItemGrid>
                          <ItemGrid xs={12} sm={2}>
                            {/*
                                        <IconButton
                                            onClick={this.handleCreate}
                                            color="success" right={true}>
                                            <Add/>
                                        </IconButton>
*/}
                            <Button
                              color="primary"
                              right={false}
                              onClick={this.handleSeacrh}
                            >
                              Search
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />

                  <ItemGrid xs={12}>
                    <ReportsTableComponent
                      url={serverApi.GET_PAY_SUMMARY_LIST}
                      // staticData={this.state.dataTable}
                      borderRight={true}
                      searchData={this.getData()}
                      isRefresh={this.state.isRefresh}
                      handleSearch={this.handleSeacrh.bind(this)}
                      // isLoading={this.state.isRefresh}
                      //  onActionClick={this.handleViewClick}
                      fileName={"jute_with_value"}
                      itemViewType={this.state.itemViewType}
                    />

                    <SamplePDF printDocument={this.printDocument.bind(this)} />
                  </ItemGrid>
                </GridContainer>
              </div>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }

  printDocument = () => {
    var data = {
      taskCode: 1013,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: getCookie(cons.COMPANY_ID),
    };
    requestList(
      serverApi.REPORTS_JUTE_WITH_VALUE,
      data,
      this.handlePrintReports
    );
  };
  handlePrintReports = (resp) => {
    var pageNo = 1;
    var modules = [];
    // if(this.state.dataTable!==undefined &&this.state.dataTable!==null ){
    //     console.log("print ",JSON.stringify(this.state.dataTable.data.data));
    //     modules=this.state.dataTable.data.data;
    //     reportValues
    // }
    modules = resp.data.reportValues;
    var grandTotals = resp.data.totalValues;
    var companyName = resp.data.companyName;
    console.log("total", grandTotals);
    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(14);
    doc.text(170, 40, companyName);
    doc.setFontSize(10);
    var TotalPageNo = Math.ceil(parseFloat(modules.length) / 26);

    doc.text(500, 40, "Page " + pageNo + " of " + TotalPageNo + "");
    // doc.setFont("courier");
    // doc.setFontType("normal");
    doc.setFontSize(10);
    // doc.table(50, 100,modules,modulesHeader,config);
    doc.text(220, 60, "PAY SUMMARY REPORT");
    doc.text(
      170,
      80,
      "Pay Summary Report From  " +
        this.state.fromDate +
        " to " +
        this.state.todate +
        "  " +
        this.state.reportType
    );
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.cellInitialize();
    doc.cell(30, 100, 30, 30, "Jcode", "", "C");
    doc.cell(60, 100, 45, 30, "Quality", "", "C");
    var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
    doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
    var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
    doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
    var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
    doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
    doc.cell(231, 100, 40, 30, "Rate", "", "C");
    var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
    doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
    var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
    doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
    doc.cell(
      364,
      100,
      155,
      15,
      "<--------------------M T D----------------->",
      "",
      "C"
    );
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
    doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
    doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
    // doc.cellInitialize();
    var cellWidths = ["", 30, 45, 42, 42, 42, 40, 51, 42, 50, 50, 55, 45];
    var cellTextaligns = [
      "",
      "center",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "center",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 110;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 20;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 130;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");
          doc.setFontSize(14);
          doc.text(170, 40, companyName);
          // doc.setFont("courier");
          // doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(500, 40, "Page " + pageNo + " of " + TotalPageNo + "");
          // doc.table(50, 100,modules,modulesHeader,config);
          doc.text(220, 60, "Report No.:[JUTE/01]");
          doc.text(
            170,
            80,
            "Daily Jute With Value Report From  " +
              this.state.fromDate +
              " to " +
              this.state.todate +
              "           Quantity in :Quintal"
          );
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.cellInitialize();
          doc.cell(30, 100, 30, 30, "Jcode", "", "C");
          doc.cell(60, 100, 45, 30, "Quality", "", "C");
          var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
          doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
          var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
          doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
          var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
          doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
          doc.cell(231, 100, 40, 30, "Rate", "", "C");
          var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
          doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
          var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
          doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
          doc.cell(
            364,
            100,
            155,
            15,
            "<--------------------M T D----------------->",
            "",
            "C"
          );
          /* var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                     doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
          doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
          doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          var myItem = prop[key] + " ";
          return doc.cell(
            30,
            k,
            cellWidths[a],
            20,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    // var grandTotals = {
    //     "OpeningWeight": 25455 + "",
    //     "ReceiptWeight": 0.0 + "",
    //     "IssueWeight": 200 + "",
    //     "Rate": " ",
    //     "IssueAmount": 1300000 + "",
    //     "Closing": 2555005 + "",
    //     "ReptQty": 5544 + "",
    //     "IssueQty": 2455 + "",
    //     "IsueAmount": 25555 + "",
    //   //  "StockForDays": " "
    // };

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(30, k + 20, 75, 30, "Grand Total", "", "left");
    doc.cell(
      105,
      k + 20,
      42,
      30,
      grandTotals.openingWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      147,
      k + 20,
      42,
      30,
      grandTotals.receivedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      189,
      k + 20,
      42,
      30,
      grandTotals.issuedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(231, k + 20, 40, 30, " ", "", "right");
    doc.cell(
      271,
      k + 20,
      51,
      30,
      grandTotals.issueAmountTot + " ",
      "",
      "right"
    );
    doc.cell(
      322,
      k + 20,
      42,
      30,
      grandTotals.closingweightcTot + " ",
      "",
      "right"
    );
    doc.cell(
      364,
      k + 20,
      50,
      30,
      grandTotals.receivedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      414,
      k + 20,
      50,
      30,
      grandTotals.issuedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      464,
      k + 20,
      55,
      30,
      grandTotals.issueAmountToDateTot + " ",
      "",
      "right"
    );
    //  doc.cell(519, k + 25, 45, 30, grandTotals.StockForDays, '', 'right');

    var Avgs = {
      AvgIssuePriceToday: 4240.74 + "",
      AvgIssuePriceTodate: 4240.74 + "",
      batchPriceToday: "4284.00",
      batchPriceTodate: " 4339.59",
    };
    doc.setFontType("normal");
    doc.setFontSize(10);

    // doc.text(30, k + 75, 'Avg. Issue Price : Today');
    // doc.text(170, k + 75, Avgs.AvgIssuePriceToday);
    // doc.text(220, k + 75, 'Todate');
    // doc.text(260, k + 75, Avgs.AvgIssuePriceTodate);
    // doc.text(30, k + 90, 'Batch Price        : Today');
    // doc.text(170, k + 90, Avgs.batchPriceToday);
    // doc.text(220, k + 90, 'Todate');
    // doc.text(260, k + 90, Avgs.batchPriceTodate);

    doc.text(30, k + 150, "Dept. Incharge");
    doc.text(400, k + 150, "Authorised Signature");
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 200, newdat);
    doc.text(500, k + 200, printBy);

    doc.save("Pay_Summary_Report.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(PaySummary);
