import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../../components/Cards/RegularCard";
import Button from "../../../components/CustomButtons/Button";
import { InputLabel } from "material-ui";
import { FormControl } from "material-ui";
import Datetime from "react-datetime";

import withStyles from "material-ui/styles/withStyles";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import { getCookie } from "../../../helper/Cookies";
import { getDataFromUrl, requestList } from "../../../server/server";
import SweetAlert from "react-bootstrap-sweetalert";
import cons, { serverApi } from "../../../helper/Consts";
import moment from "moment";






class SundayAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment()
        .days(-6)
        .format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "indentNo",
      approveStatus: false,

      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",

      date1: moment().format("YYYY-MM-DD"),
      indentNo: "",
      year: moment().format("YYYY"),
      unitConvertion: "",
      unitConvertionId: "",
      unitConversionName: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantiy: "",
      selectedMukam: "",
      alert: false,
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      id1 : 1,
      id2 : 2,
      id3 : 3,
      id4 : 4
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      branchAddress: "",
      city: "",
      stateName: "",
      stateCode: "",
      cgstNo: "",
      pinCode: "",
    });
  }
  

  basicAlert(msg) {
    
    
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
    
    
  }

  hideBasicAlert() {
    // if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      designationName: "",
    });
  }

  handleSearch = (e) => {
    
    var id = e.currentTarget.id;
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      userId: getCookie(cons.USERID),
    }
    if(id === "1"){
      requestList(serverApi.SUNDAY_WEAVING_SUMMARY, data, this.handleReports);      
    } else if(id === "2"){
      requestList(serverApi.SUNDAY_WEAVING_TICKETS, data, this.handleReports);      
    } else if(id === "3"){
      requestList(serverApi.SUNDAY_WINDING_SUMMARY, data, this.handleReports);      
    } else if(id === "4"){
      requestList(serverApi.SUNDAY_WINDING_TICKETS, data, this.handleReports);      
    } 
    
  }  

  

  handleReports = (resp) => {
    if(resp){
        if(resp.status==true){
            this.basicAlert(resp.message);
        }
    }
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("YYYY-MM-DD"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From Date :
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To Date :
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "todate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                            {this.state.basicAlert}
                          <GridContainer>
                            <ItemGrid xs={12} sm={6}>
                              <Button 
                              id= {1}
                              color="primary"
                              onClick={this.handleSearch}
                              >
                                Weaving Sunday Statements
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={6}>
                              <Button
                                id={2}
                                color="primary"
                                onClick={this.handleSearch}
                              >
                                Weaving Sunday Tickets
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={6}>
                              <Button 
                              color="primary" 
                              id={3}
                              onClick={this.handleSearch}
                              >
                                Winding Sunday Statements
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={6}>
                              <Button 
                               color="primary" 
                               id={4}
                               onClick={this.handleSearch}
                               >
                                Winding Sunday Tickets
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}
const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};

export default withStyles(extendedFormsStyle)(SundayAttendance);
