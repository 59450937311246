
import cons from './Consts';
import isLogin from './isLoggedIn';
import {reactLocalStorage} from 'reactjs-localstorage';


export const getCookie = (key) => {
    return reactLocalStorage.get(key);
};

export const setCookie = (key, value) => {
    reactLocalStorage.set(key, value);
};
export const setCookieObj = (key, value) => {
    reactLocalStorage.setObject(key, value);
};
export const removeCookie = (key) => {
    reactLocalStorage.remove(key);
};
export const removeCookies = () => {
    reactLocalStorage.remove(cons.UDATA);
    // ck.remove(cons.AUTH, '/');
    reactLocalStorage.remove(cons.USERID);
    reactLocalStorage.remove(cons.USER_NAME);
    reactLocalStorage.remove(cons.USER_GRP_ID);
    reactLocalStorage.remove(cons.USER_GDATA);
    reactLocalStorage.remove(cons.MENUS_DATA);
    reactLocalStorage.remove(cons.MENU_ID);
    reactLocalStorage.remove(cons.COMPANIES_LIST);
    reactLocalStorage.remove(cons.COMPANY_NAME);
    reactLocalStorage.remove(cons.COMPANY_ID);
    reactLocalStorage.remove(cons.COMPANY_TITLE);
    reactLocalStorage.remove(cons.COMPANY_ICON);
    reactLocalStorage.remove(cons.COMPANY_GST);
    reactLocalStorage.remove(cons.COMPANY_MAIL_ID);
    reactLocalStorage.remove(cons.COMPANY_ADDRESS);
    reactLocalStorage.remove(cons.COMPANY_ADDRESS2);
    reactLocalStorage.remove(cons.COMPANY_CONTACT_NO);
    reactLocalStorage.remove(cons.EMAIL);
    reactLocalStorage.remove(cons.ORG_ID);
    reactLocalStorage.remove(cons.EB_ID);
    reactLocalStorage.remove(cons.EB_NO);
    reactLocalStorage.remove(cons.USER_IMG);
    reactLocalStorage.remove(cons.CIPHER);
    reactLocalStorage.remove(cons.ACCESS_TOKEN);
    reactLocalStorage.remove(cons.TOKEN_TYPE);
    reactLocalStorage.remove(cons.ACADEMIC_YEAR_LIST);
    reactLocalStorage.remove(cons.ACADEMIC_YEAR);
    reactLocalStorage.remove(cons.COMPANY_CIN);
    reactLocalStorage.remove(cons.COMPANY_GST2);
    reactLocalStorage.remove(cons.COMPANY_STATECODE2);
    reactLocalStorage.remove(cons.SHIPPING_ADDRESS);
    reactLocalStorage.remove(cons.WIDGETMENUS);
    reactLocalStorage.remove(cons.CONFIG_LIST);
    reactLocalStorage.remove(cons.COMPANY_STATECODE);
    reactLocalStorage.remove(cons.COMPANY_STATE);
    reactLocalStorage.remove(cons.COMPANY_STATE2);
    reactLocalStorage.remove(cons.CONTACT_PERSON);
    reactLocalStorage.remove(cons.USER_ROLE_ID);
    reactLocalStorage.remove(cons.SUPP_CODE);
    reactLocalStorage.remove(cons.HRMS_FULL_ACCESS)


    // ck.remove(cons.CIPHER,'/');
    return true;
};


export const USER_NAME = () => {
    return reactLocalStorage.get(cons.USER_NAME)
    /* if (isLogin())
         return getCookie(cons.UDATA).dispName;
     else
         return "";*/
};

export const CIPHER=()=>{
    if(isLogin()){
        return getCookie(cons.CIPHER);
    }else{
        return -1;
    }
};
export const USER_ID=()=>{
    if(isLogin()){
        return getCookie(cons.USERID);
    }else{
        return -1;
    }
};
