import React from "react";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
  uploadInvoice,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import {
  getMenuPermission,
  IsTallyAvailable,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import { GoodTypes, saleTypes, TCSPercentages, yesOrNo } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import TallyIcon from "../../assets/img/tally_icon.png";
import TallyRedIcon from "../../assets/img/tally_red.png";

class StoreBillPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isDisabled: false,
      fromDate: "",
      todate: "",
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      srNo: "",
      searchsrNo: "",
      vehicleNo: "",
      poNO: "",

      selectedDepartment: "",
      selectedItemGroup: "",
      srDate: moment().format("DD-MM-YYYY"),
      form_supplierId: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      gateEntryNo: "",
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      contractNo: "",
      contractDate: "",
      exstn: "",
      agentId: "",
      form_vehicleNo: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "billPassNo",
      approveStatus: false,
      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",

      indentNo: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      loadGateEntry: false,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      alert: false,
      warehouseNo: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      filesList: [],
      challanList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      materialHdrId: "0",
      lineItemId: "0",
      billPassStatus: "",
      invoiceNo: "",
      invoiceDate: "",
      paymentDueDate: "",
      invoiceAmount: "",
      invoiceReceivedDate: "",
      billPassNo: "",
      tdsPayable: "N",
      tdsReason: "",
      tdsAmount: "",
      projectIds: "",
      branchIds: "",
      branchId: "",
      projectId: "",
      costDesc: "",
      costFactor: "",
      description: "",
      goodType: "GRN",
      tcsPercentage: "",
      tcsAmount: "",
      supplierList: [],
      fileType: "",
      ledgerGroupDesc: "",
      supportFilesList: [],
      printResp: "",
      make: ""
      // saleType:""
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    getDataFromUrl(
      serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID),
      this.handleSupplier
    );
    this.getList();
  }
  getList = () => {
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "getAllBillPass",
      this.getData(),
      this.handleList
    );
  };
  handleList = (resp) => {
    var list = resp;
    if (IsTallyAvailable("Tally Sync")) {
      list.column.push({
        Header: "Tally",
        accessor: "tally",
        sortable: false,
        filterable: false,
        minWidth: 100,
      });
      list.data = resp.data.map((prop, i) => {
        var obj = {
          ...prop,
          tally: (
            <div className="actions-right">
              {prop.tallySync === "Y" ? (
                <img src={TallyIcon} color={"danger"} width={50} height={50} />
              ) : null}
              {prop.tallySync === "R" ? (
                <img
                  src={TallyRedIcon}
                  width={50}
                  height={50}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.showMessage(prop.tallyMessage, false);
                  }}
                />
              ) : null}
            </div>
          ),
        };
        return obj;
      });
    }

    if (resp) {
      this.setState({ tableList: list, isRefresh: true });
    }
  };
  handleSupplier = (resp) => {
    if (resp.status === undefined) {
      this.setState({ supplierList: resp, loadSupplier: true });
    }
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_BILL_PASS_DETAILS_BY_ID +
          "S/" +
          id +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    console.log(`printing resonse----------${this.state.printResp}`);
    this.setState({ printResp: resp });
    var header = resp;
    var list = resp.srLineItems;
    var indentlist = [],
      filesList = [],
      challanList = [];
    var sum_of_tax = 0,
      sumOfamt = 0,
      netTotal = 0,
      amountWithTax = 0;

    if (header.billDocsUrl !== null) {
      var doc = header.billDocsUrl;
      filesList = doc;
    }
    if (header.billChallanUrl !== null) {
      challanList = header.billChallanUrl;
    }

    if (list !== null)
      var indentlist = list.map((prop, key) => {
        var itemAmount = prop.totalPrice;
        var amount = parseFloat(prop.rate) * parseFloat(prop.quantity);
        var taxPercentage = 0;
        if (prop.iTax > 0) {
          taxPercentage = prop.iTax;
        } else {
          taxPercentage = prop.cTax + prop.sTax;
        }
        var itemtaxAmount = (amount * parseFloat(taxPercentage)) / 100; //11 is tax percent
        if (taxPercentage > 0) {
          var taxamount = (
            (parseFloat(amount) * parseFloat(taxPercentage)) /
            100
          ).toFixed(2);

          var amt = parseFloat(amount) + parseFloat(taxamount);
          amountWithTax = amountWithTax + amt;
        } else {
          amountWithTax = amountWithTax + parseFloat(amount);
        }
        //  itemtaxAmount = Math.round(itemtaxAmount);
        prop.taxamount = itemtaxAmount;
        sum_of_tax = sum_of_tax + itemtaxAmount;
        if (itemAmount > 0) sumOfamt = sumOfamt + itemAmount;
        return {
          id: key,
          lineItemId: prop.lineItemNo,
          materialHdrId: prop.materialGrnHdrId,
          // itemId: parseInt(prop.itemId),
          itemId: parseInt(prop.itemCode),
          itemDesc: prop.itemDesc,
          itemGroup: prop.groupDesc,
          itemGroupId: prop.itemGroupId,
          itemGrp: prop.itemGroupId + "" + prop.itemCode,
          quantity: prop.quantity,
          quality: prop.qualityDesc,
          hsnCode: prop.hsnCode,
          // unit: prop.uom,
          taxId: prop.taxId,
          taxPercentage: taxPercentage,
          cgst: prop.cTax,
          sgst: prop.sTax,
          igst: prop.iTax,
          weight: prop.weight,
          uom: prop.uom,
          warehouseno: prop.warehouseName,
          rate: prop.rate,
          amount: parseFloat(prop.totalPrice).toFixed(2),
          depId: prop.deptId,
          qualityCode: prop.qualityId,
          costDesc: prop.costDesc,
          costFactor: prop.costFactor,
          description: prop.description,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to remove the data row */}
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                }}
                color="primaryNoBackground"
                customClass="edit"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {}}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>{" "}
            </div>
          ),
        };
      });
    console.log("sumOfamt + sum_of_tax ", amountWithTax);
    if (header !== null) {
      //  alert(header.agentId);
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        isDisabled: true,

        billPassNo: header.billPassNo,
        billPassDate: header.billpassDate,
        approveStatus: header.approveStatus,
        srNo: header.srPrintNo,
        statusDesc: header.statusDesc,
        srDate: header.srMrDate,
        form_supplierId: header.supplierCode,
        gateEntryNo: header.grnNo,
        form_poNO: header.poNum,
        poDate: header.poDate,
        challanNo: header.chalanNo,
        challanDate: header.chalanDate,
        contractNo: header.contractNo,
        contractDate: header.contractDateDesc,
        exstn: header.exSTN,
        agentId: header.agentId,
        form_vehicleNo: header.vehicleNo,
        //  year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateDesc,
        mukamId: header.mukamId,
        billPassStatus: header.status,
        invoiceList: indentlist,
        /*  filesList: filesList,
                challanList: challanList,*/
        totalTax: sum_of_tax.toFixed(2),
        totalAmount: sumOfamt.toFixed(2),
        netTotal: amountWithTax,
        invoiceNo: header.invoiceNo,
        invoiceDate: header.invoiceDate,
        paymentDueDate: header.paymentDueDate,
        invoiceAmount: header.invoiceAmount,
        invoiceReceivedDate: header.invoiceRecievedDate,
        tdsPayable: header.tdsPayable !== null ? header.tdsPayable : "N",
        tdsReason: header.tdsReason,
        tdsAmount: header.tdsAmount,
        ledgerName: header.ledgerName,
        ledgerGroupDesc: header.ledgerGroupDesc,
        branchId: header.branchId,
        projectId: header.projectId,
        goodType: header.goodType,
        roundoff: header.roundOff,
        suppliedPoValue: header.suppliedPoValue,
        supplierPoval: header.supplierPoval,
        suppliedSrValue: header.suppliedSrValue,
        supplierSoval: header.supplierSoval,
        supplierPendingPo: header.supplierPendingPo,
        pendingPoval: header.pendingPoval,
        supplierPaidAmount: header.supplierPaidAmount,
        supplierPayment: header.supplierPayment,
        ledgerBalance: header.ledgerBalance,
        ledegerBal: header.ledegerBal,
        status: header.status,
        tcsPercentage: header.tcsPercentage,
        tcsAmount:
          header.tcsAmount !== null
            ? parseFloat(header.tcsAmount).toFixed(2)
            : 0.0,
        //saleType:header.saleType
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "2/" +
          header.billPassNo +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "3/" +
          header.billPassNo +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceChallanFiles
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "29/" +
          header.billPassNo +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceSupportFiles
      );
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleInvoiceFiles = (resp) => {
    if (resp.status && resp.data !== undefined) {
      this.setState({ filesList: resp.data });
    }
  };
  handleInvoiceChallanFiles = (resp) => {
    if (resp.status && resp.data !== undefined) {
      this.setState({ challanList: resp.data });
    }
  };
  handleInvoiceSupportFiles = (resp) => {
    if (resp.status && resp.data !== undefined) {
      this.setState({ supportFilesList: resp.data });
    }
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      supplier: this.state.supplierId,
      srNo: this.state.srNo,
      type: "S",
      status: this.state.status,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
      erpType: getConfigValue(configNames.LABELS_TYPE),
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else {
      this.setState({
        [id]: "",
      });
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;

    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
      });
    }
    if (stateKey === "branchId") {
      this.setState({ loadProject: true });
    }
    if (stateKey === "selectedItemGroup") {
      this.setState({
        loadItem: true,
      });
    }
    if (stateKey === "tcsPercentage") {
      var total =
        parseFloat(this.getTotalTax("cgst")) +
        parseFloat(this.getTotalTax("sgst")) +
        parseFloat(this.getTotalTax("igst")) +
        parseFloat(this.state.totalAmount);
      var tcsAmount = (parseFloat(total) * selectedValue) / 100;
      this.setState({ tcsAmount: Math.round(tcsAmount).toFixed(2) });
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  getGateEntryDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      form_poNO: values[0],
      poDate: values[1],
      challanNo: values[2],
      challanDate: values[3],
      form_vehicleNo: values[4],
    });
    // console.log("indentQuantity " + this.state.stock);
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
      isReject: false,
      isApprove: false,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      // poDate:'',
      // challanNo:'',
      // challanDate:'',
      // contractNo:'',
      // contractDate:'',
      // exstn:'',
      // agentId:'',
      // form_vehicleNo:'',
      //
      // form_poNO:'',
      // gateEntryNo:'',
      // form_supplierId:'',

      //   unitConvertionId:"0",
      //  unitConvertion:"",
      quantity: "",
      stock: "",
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getList();
    }
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      isDisabled: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      lineItemId: "",
      materialHdrId: "",
      billPassStatus: "",
      tdsPayable: "N",
      tdsReason: "",
      tdsAmount: "",
      branchId: "",
      projectId: "",
      saleType: "",
      costDesc: "",
      costFactor: "",
      description: "",
      goodType: "GRN",
      tcsPercentage: "",
      tcsAmount: "",
      fileType: "",
      filesList: [],
      challanList: [],
      roundoff: null,
      ledgerGroupDesc: "",
      supportFilesList: [],
    });
  }

  dateChanged = (myDate) => {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      if (this.state.deleteChallan) {
        var arr = this.state.challanList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          challanList: arr,
          deleteChallan: false,
          fileId: "",
        });
      } else if (this.state.deleteSupportFile) {
        var arr = this.state.supportFilesList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          supportFilesList: arr,
          deleteSupportFile: false,
          fileId: "",
        });
      } else {
        var arr = this.state.filesList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          filesList: arr,
          deleteInvoice: false,
          fileId: "",
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };

  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;
      if (this.state.uploadChallan) {
        var arr = this.state.challanList;
        data = {
          fileUploadId: data.id,
          fileName: this.state.fileName,
          fileExtension: this.state.fileType,
          retrievalPath: data.fileUrl,
          sourceMenu: 3,
        };
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadChallan: false,
          challanList: arr,
          basicAlert: null,
        });
      } else if (this.state.uploadSupportFile) {
        var arr = this.state.supportFilesList;
        data = {
          fileUploadId: data.id,
          fileName: this.state.fileName,
          fileExtension: this.state.fileType,
          retrievalPath: data.fileUrl,
          sourceMenu: 2,
        };
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadSupportFile: false,
          supportFilesList: arr,
          basicAlert: null,
        });
      } else {
        var arr = this.state.filesList;
        data = {
          fileUploadId: data.id,
          fileName: this.state.fileName,
          fileExtension: this.state.fileType,
          retrievalPath: data.fileUrl,
          sourceMenu: 2,
        };
        arr.splice(arr.length, 0, data);
        this.setState({
          uploadInvoice: false,
          filesList: arr,
          basicAlert: null,
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      billPassNo,
      isReject,
      isApprove,
      uploadSupportFile,
      supportfileId,
    } = this.state;
    if (isReject) {
      this.setState({ isReject: false });
      this.onRejectButtonClick();
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(this.state.billPassStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        2,
        fileName,
        billPassNo,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (uploadChallan) {
      uploadFile(
        fileName,
        file,
        fileType,
        3,
        fileName,
        billPassNo,
        this.handleInvoiceResp
      );
      //   uploadInvoice(serverApi.UPLOAD_CHALLAN, "C", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (uploadSupportFile) {
      uploadFile(
        fileName,
        file,
        fileType,
        29,
        fileName,
        billPassNo,
        this.handleInvoiceResp
      );
    }

    if (this.state.deleteChallan) {
      // var url = serverApi.DELETE_BILL_INVOICE + this.state.challanId + "/" + getCookie(cons.USER_NAME) + "/" + getCookie(cons.CIPHER);
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.challanId,
        this.handleDeleteInvoiceResp
      );
    }

    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteSupportFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.supportfileId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert: null,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => {
            this.setState({
              basicAlert1: null,
            });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
    });
  }

  onRejectButtonClick = () => {
    var data = {
      id: this.state.billPassNo,
      status: "4",
      userName: getCookie(cons.USER_NAME),
      userId: getCookie(cons.USERID),
    };

    this.handleLoading();
    requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent);
  };

  onApproveButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        saleType,
        invoiceNo,
        invoiceDate,
        paymentDueDate,
        invoiceAmount,
        invoiceReceivedDate,
        billPassNo,
        filesList,
        netTotal,
        payableTax,
        tdsPayable,
        tdsReason,
        tdsAmount,
        tcsPercentage,
        tcsAmount,
        roundoff,
        srNo,
      } = this.state;
      var tcs = 0;
      if (tcsAmount !== null && tcsAmount !== "") tcs = parseFloat(tcsAmount);
      var round = (parseFloat(this.state.netTotal) + tcs).toFixed(2);

      round = round.split(".");
      var total = parseFloat(round[0]);
      round = "0." + round[1];
      var showRound = false;
      if (parseFloat(round) > 0) {
        showRound = true;
      }
      var max = (1 - parseFloat(round)).toFixed(2);
      round = parseFloat("-" + round);
      var totalRound = roundoff;
      console.log("round", round);
      if (roundoff !== null && roundoff !== undefined && roundoff !== 0) {
        console.log("round", round, this.state.roundoff);

        if (parseFloat(this.state.roundoff) === parseFloat(max))
          total = Math.round(
            total + parseFloat(this.state.roundoff) - round
          ).toFixed(2);
        else if (parseFloat(this.state.roundoff) === parseFloat(round))
          total = Math.round(total).toFixed(2);
        else {
          if (parseFloat(max) <= 0.5) {
            total = Math.round(total + parseFloat(max) - round).toFixed(2);
          } else {
            total = Math.round(total).toFixed(2);
          }
        }
      } else {
        if (parseFloat(max) <= 0.5) {
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
          totalRound = max;
        } else {
          total = Math.round(total).toFixed(2);
          totalRound = round;
        }
      }
      var data = {
        id: this.state.billPassNo,
        status: status,
        userName: getCookie(cons.USER_NAME),
        userId: getCookie(cons.USERID),
        invoiceNo: this.state.invoiceNo,
        invoiceDate: this.dateChanged(this.state.invoiceDate),
        paymentDueDate: this.dateChanged(this.state.paymentDueDate),
        invoiceAmount: this.state.invoiceAmount,
        ledger: this.state.ledger,
        invoiceRecievedDate: this.dateChanged(this.state.invoiceReceivedDate),
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        tdsPayable: tdsPayable,
        tdsReason: tdsReason,
        tdsAmount: tdsAmount,
        projectId: this.state.projectId,
        branchId: this.state.branchId,
        tcsPercentage: tcsPercentage,
        tcsAmount: parseFloat(tcsAmount),
        approveStatus: true,
        roundOff: roundoff,
        srNo: srNo,
        suppliedPoValue: this.state.suppliedPoValue,
        suppliedSrValue: this.state.suppliedSrValue,
        supplierPendingPo: this.state.supplierPendingPo,
        supplierPaidAmount: this.state.supplierPaidAmount,
        ledgerBalance: this.state.ledgerBalance,

        // "saleType":saleType
      };

      if (
        invoiceAmount !== 0 &&
        invoiceAmount !== "" &&
        invoiceNo !== "" &&
        invoiceDate !== "" &&
        invoiceReceivedDate !== "" &&
        billPassNo !== "" &&
        filesList.length > 0 &&
        tdsPayable !== "" &&
        this.state.projectId !== "" &&
        this.state.branchId > 0
      ) {
        if (
          parseFloat(total).toFixed(2) === parseFloat(invoiceAmount).toFixed(2)
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent);
        } else {
          this.basicAlert("The net total and invoice amount should be equal!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        saleType,
        invoiceNo,
        invoiceDate,
        paymentDueDate,
        invoiceAmount,
        invoiceReceivedDate,
        billPassNo,
        filesList,
        netTotal,
        payableTax,
        tdsPayable,
        tdsReason,
        tdsAmount,
        tcsPercentage,
        tcsAmount,
        billPassStatus,
        challanList,
        roundoff,
        srNo,
      } = this.state;
      var tcs = 0;
      if (tcsAmount !== null && tcsAmount !== "") tcs = parseFloat(tcsAmount);
      var round = (parseFloat(this.state.netTotal) + tcs).toFixed(2);

      round = round.split(".");
      var total = parseFloat(round[0]);
      round = "0." + round[1];
      var showRound = false;
      if (parseFloat(round) > 0) {
        showRound = true;
      }
      var max = (1 - parseFloat(round)).toFixed(2);
      round = parseFloat("-" + round);
      var totalRound = roundoff;
      if (roundoff !== null && roundoff !== undefined && roundoff !== 0) {
        console.log("round", round, this.state.roundoff);

        if (parseFloat(this.state.roundoff) === parseFloat(max))
          total = Math.round(
            total + parseFloat(this.state.roundoff) - round
          ).toFixed(2);
        else if (parseFloat(this.state.roundoff) === parseFloat(round))
          total = Math.round(total).toFixed(2);
        else {
          if (parseFloat(max) <= 0.5) {
            total = Math.round(total + parseFloat(max) - round).toFixed(2);
          } else {
            total = Math.round(total).toFixed(2);
          }
        }
      } else {
        if (parseFloat(max) <= 0.5) {
          totalRound = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          totalRound = round;
          total = Math.round(total).toFixed(2);
        }
      }
      var data = {
        id: this.state.billPassNo,
        status: "",
        userName: getCookie(cons.USER_NAME),
        userId: getCookie(cons.USERID),
        invoiceNo: this.state.invoiceNo,
        invoiceDate: this.dateChanged(this.state.invoiceDate),
        paymentDueDate: this.dateChanged(this.state.paymentDueDate),
        invoiceAmount: this.state.invoiceAmount,
        ledger: this.state.ledger,
        invoiceRecievedDate: this.dateChanged(this.state.invoiceReceivedDate),
        companyId: getCookie(cons.COMPANY_ID),
        cipher: getCookie(cons.CIPHER),
        tdsPayable: tdsPayable,
        tdsReason: tdsReason,
        tdsAmount: tdsAmount,
        projectId: this.state.projectId,
        branchId: this.state.branchId,
        tcsPercentage: tcsPercentage,
        tcsAmount: parseFloat(tcsAmount),
        approveStatus: false,
        roundOff: totalRound,
        srNo: srNo,
        suppliedPoValue: this.state.suppliedPoValue,
        suppliedSrValue: this.state.suppliedSrValue,
        supplierPendingPo: this.state.supplierPendingPo,
        supplierPaidAmount: this.state.supplierPaidAmount,
        ledgerBalance: this.state.ledgerBalance,
        //   "saleType":saleType
      };
      if (
        (filesList.length > 0 || challanList.length > 0) &&
        billPassStatus === "1"
      ) {
        data.status = "23";
      }

      if (
        invoiceAmount !== 0 &&
        invoiceAmount !== "" &&
        invoiceNo !== "" &&
        invoiceDate !== "" &&
        invoiceReceivedDate !== "" &&
        billPassNo !== "" &&
        tdsPayable !== "" &&
        this.state.projectId !== "" &&
        this.state.branchId > 0
      ) {
        if (
          parseFloat(total).toFixed(2) === parseFloat(invoiceAmount).toFixed(2)
        ) {
          this.handleLoading();
          requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent);
        } else {
          this.basicAlert("The net total and invoice amount should be equal!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        if (this.state.uploadChallan) {
          this.setState({
            file: file,
            fileName: file.name,
            fileType: fileName[1],
            //filesList: arr,
            // imagePreviewUrl: reader.result
          });
          this.InvoicebasicAlert(
            "Do you want to upload challan " + file.name + "?"
          );
        } else if (this.state.uploadSupportFile) {
          this.setState({
            file: file,
            fileName: file.name,
            fileType: fileName[1],
            //filesList: arr,
            uploadSupportFile: true,
            // imagePreviewUrl: reader.result
          });

          this.InvoicebasicAlert(
            "Do you want to upload file " + file.name + "?"
          );
        } else {
          this.setState({
            file: file,
            fileName: file.name,
            fileType: fileName[1],
            //filesList: arr,
            uploadInvoice: true,
            // imagePreviewUrl: reader.result
          });

          this.InvoicebasicAlert(
            "Do you want to upload file " + file.name + "?"
          );
        }
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      console.log("itemTaxAmt", prop.itemTaxAmt);
      if (prop.sgst !== undefined)
        totSgst =
          totSgst + (parseFloat(prop.amount) * parseFloat(prop.sgst)) / 100;
      if (prop.igst !== undefined)
        totIgst =
          totIgst + (parseFloat(prop.amount) * parseFloat(prop.igst)) / 100;
      if (prop.cgst !== undefined)
        totcgst =
          totcgst + (parseFloat(prop.amount) * parseFloat(prop.cgst)) / 100;
    });
    var totalTax = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    }
    return totalTax.toFixed(2);
  }
  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType, tcsAmount } = this.state;

    var tcs = 0;
    if (tcsAmount !== null && tcsAmount !== "") tcs = parseFloat(tcsAmount);
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    var round = (parseFloat(this.state.netTotal) + tcs).toFixed(2);

    round = round.split(".");
    var total = parseFloat(round[0]);
    round = "0." + round[1];
    var showRound = false;
    if (parseFloat(round) > 0) {
      showRound = true;
    }
    var max = (1 - parseFloat(round)).toFixed(2);
    round = parseFloat("-" + round);

    console.log("round", round);
    var roundoff = this.state.roundoff;
    if (roundoff !== null && roundoff !== undefined) {
      console.log("round", round, this.state.roundoff);

      if (parseFloat(this.state.roundoff) === parseFloat(max))
        total = Math.round(
          total + parseFloat(this.state.roundoff) - round
        ).toFixed(2);
      else if (parseFloat(this.state.roundoff) === parseFloat(round))
        total = Math.round(total).toFixed(2);
      else {
        if (parseFloat(max) <= 0.5) {
          roundoff = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          roundoff = round;
          total = Math.round(total).toFixed(2);
        }
      }
    } else {
      if (parseFloat(max) <= 0.5) {
        roundoff = max;
        total = Math.round(total + parseFloat(max) - round).toFixed(2);
      } else {
        roundoff = round;
        total = Math.round(total).toFixed(2);
      }
    }
    var columns = [],
      labelType = getConfigValue(configNames.LABELS_TYPE);

    if (this.state.goodType === "SRN")
      columns = [
        {
          Header: "Cost Factor",
          accessor: "costDesc",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Description",
          accessor: "description",
          minWidth: 150,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: "Quantity",
          accessor: "quantity",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "UOM",
          accessor: "uom",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Warehouse No",
          accessor: "warehouseno",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Rate",
          accessor: "rate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: labelType === "3" ? "VAT" : "IGST",
          accessor: "igst",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "CGST",
          accessor: "cgst",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "SGST",
          accessor: "sgst",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Amount",
          accessor: "amount",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
      ];
    else {
      if (labelType === "3")
        columns = [
          {
            Header: "Item Code",
            accessor: "itemGrp",
            minWidth: 50,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Item Group",
            accessor: "itemGroup",
            minWidth: 150,
            style: { color: "#000", textAlign: "left" },
          },
          {
            id: "itemDesc",
            Header: "Item Desc",
            accessor: row => <>
                <span style={{fontStyle: "bold"}}>{`${row.itemDesc!== null ? row.itemDesc : row.description}`}</span><br></br>
                    {this.state.make !== "" && this.state.make!== null ? 
                     <>
                        <span style={{fontStyle: "italic", color: "#00ABDC"}}>Make:- {`${row.make}`}</span><br></br>
                    </> : null}

                </>,
            filterMethod: (filter, row) =>
            row._original.itemDesc.startsWith(filter.value) ||
            row._original.make.startsWith(filter.value),
            minWidth: 200,

            style: {
                color: "#000",
                textAlign: "left",
                textOverflow: "unset",
            },
           
        },
          // {
          //   Header: "Make",
          //   accessor: "make",
          //   minWidth: 150,
          //   style: { color: "#000", textAlign: "left" },
          // },
          {
            Header: "HSN Code",
            accessor: "hsnCode",
            minWidth: 80,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Quantity",
            accessor: "quantity",
            minWidth: 80,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: "UOM",
            accessor: "uom",
            minWidth: 50,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Warehouse No",
            accessor: "warehouseno",
            minWidth: 100,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Rate",
            accessor: "rate",
            minWidth: 50,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: labelType === "3" ? "VAT" : "IGST",
            accessor: "igst",
            minWidth: 50,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: "Amount",
            accessor: "amount",
            minWidth: 80,
            style: { color: "#000", textAlign: "right" },
          },
        ];
      else
      
        columns = [
          {
            Header: "Item Code",
            accessor: "itemGrp",
            minWidth: 50,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Item Group",
            accessor: "itemGroup",
            minWidth: 150,
            style: { color: "#000", textAlign: "left" },
          },

          {
            id: "itemDesc",
            Header: "Item Desc",
            accessor: row => <>
                <span style={{fontStyle: "bold"}}>{`${row.itemDesc!== null ? row.itemDesc : row.description}`}</span><br></br>
                    {this.state.make !== "" && this.state.make!== null ? 
                     <>
                        <span style={{fontStyle: "italic", color: "#00ABDC"}}>Make:- {`${row.make}`}</span><br></br>
                    </> : null}


                </>,
            filterMethod: (filter, row) =>
            row._original.itemDesc.startsWith(filter.value) ||
            row._original.make.startsWith(filter.value),
            minWidth: 200,

            style: {
                color: "#000",
                textAlign: "left",
                textOverflow: "unset",
            },
           
        },
          // {
          //   Header: "Item Desc",
          //   accessor: "itemDesc",
          //   minWidth: 150,
          //   style: { color: "#000", textAlign: "left" },
          // },

          // {
          //   Header: "Make",
          //   accessor: "make",
          //   minWidth: 150,
          //   style: { color: "#000", textAlign: "left" },
          // },
          {
            Header: "HSN Code",
            accessor: "hsnCode",
            minWidth: 80,
            style: { color: "#000", textAlign: "left" },
          },

          {
            Header: "Quantity",
            accessor: "quantity",
            minWidth: 80,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: "UOM",
            accessor: "uom",
            minWidth: 50,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Warehouse No",
            accessor: "warehouseno",
            minWidth: 100,
            style: { color: "#000", textAlign: "left" },
          },
          {
            Header: "Rate",
            accessor: "rate",
            minWidth: 50,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: labelType === "3" ? "VAT" : "IGST",
            accessor: "igst",
            minWidth: 50,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: "CGST",
            accessor: "cgst",
            minWidth: 50,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: "SGST",
            accessor: "sgst",
            minWidth: 50,
            style: { color: "#000", textAlign: "right" },
          },
          {
            Header: "Amount",
            accessor: "amount",
            minWidth: 80,
            style: { color: "#000", textAlign: "right" },
          },
        ];
    }

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Bill Pass Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "billPassDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "billPassDate",
                              value: this.state.billPassDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Bill Pass No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="billPassNo"
                          value={this.state.billPassNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>SR No</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="srNo"
                          value={this.state.srNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          SR Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              //  this.timeChanged(dateObj, "srDate")
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "srDate",
                              value: this.state.srDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            selectDisabled={this.state.updateView}
                            id={"form_supplierId"}
                            defValue={this.state.form_supplierId}
                            onSelect={this.onSelect}
                            stateKey="form_supplierId"
                            isRefresh={this.state.loadSupplier}
                            setIsRefresh={(val) => {
                              this.setState({ loadSupplier: false });
                            }}
                            staticData={this.state.supplierList}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Gate Entry No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="gateEntryNo"
                          value={this.state.gateEntryNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />

                        {/*
                                                <SelectboxTemp id={"gateEntryNo"}
                                                               defValue={this.state.gateEntryNo}
                                                               onSelect={this.onSelect}
                                                               isRefresh={this.state.loadGateEntry}
                                                               setIsRefresh={this.afterRefreshGateEntry}
                                                               getName={this.getGateEntryDetails}
                                                               stateKey="gateEntryNo"
                                                               url={serverApi.GET_GATE_ENTRY_LIST_BY_SUP + this.state.form_supplierId}
                                                    // value={this.state.gateEntryNo}
                                                />
*/}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>PO NO</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_poNO"
                          value={this.state.form_poNO}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          PO Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              //  this.timeChanged(dateObj, "poDate")
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "poDate",
                              value: this.state.poDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="challanNo"
                          value={this.state.challanNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "challanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "challanDate",
                              value: this.state.challanDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_vehicleNo"
                          value={this.state.form_vehicleNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          inputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          //  selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Phase" : "Project"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          // selectDisabled={this.state.isDisabled}
                          url={
                            serverApi.PROJECT_MASTER_SELECTION_LIST +
                            getCookie(cons.COMPANY_ID) +
                            "/customer/0/branch/" +
                            this.state.branchId
                          }
                          defValue={this.state.projectId}
                          // staticData={this.state.ProjectOptions}
                          onSelect={this.onSelect}
                          stateKey="projectId"
                          value={this.state.projectId}
                          isRefresh={this.state.loadProject}
                          setIsRefresh={(val) => {
                            this.setState({ loadProject: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Goods Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          id={"goodType"}
                          //selectDisabled={this.state.updateView || this.state.SelectedChalanType.value === 1}
                          defValue={this.state.goodType}
                          onSelect={this.onSelect}
                          stateKey="goodType"
                          staticData={GoodTypes}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {console.log(this.state.invoiceList.length + " length")}
                        <LineItemTable
                          data={this.state.invoiceList}
                          loading={this.state.loading}
                          columns={columns}
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          {/* Left Side */}
                          <ItemGrid xs={12} sm={4}>
                            <GridContainer>
                              {/* 1st Item */}
                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total PO Val in this FY :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.status === "3"
                                      ? this.state.supplierPoval
                                      : this.state.suppliedPoValue}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              {/* 1st Item End */}

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total Supplied in this FY :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.statestatus === "3"
                                      ? this.state.supplierSoval
                                      : this.state.suppliedSrValue}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Pending Supply by now :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.status === "3"
                                      ? this.state.pendingPoval
                                      : this.state.supplierPendingPo}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total Payment made in this FY :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.status === "3"
                                      ? this.state.supplierPayment
                                      : this.state.supplierPaidAmount}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={7}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Ledger Balance :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.status === "3"
                                      ? this.state.ledegerBal
                                      : this.state.ledgerBalance}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          {/* Left Side End */}
                          {/* Middle Item */}
                          <ItemGrid xs={12} sm={4}>
                            {this.state.status === "3" ? (
                              <GridContainer>
                                <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={8}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Cur. Total PO Val. in this FY :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.state.suppliedPoValue}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={8}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Cur. Total Supplied in this FY :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.state.suppliedSrValue}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={8}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Cur. Pending Supply by now :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.state.supplierPendingPo}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={8}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Cur. Total Payment made in this FY :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.state.supplierPaidAmount}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={8}
                                      style={{ textAlign: "right" }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Cur. Ledger Balance :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      {this.state.ledgerBalance}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            ) : null}
                          </ItemGrid>
                          {/* Middle Item End */}
                          {/* Last Item */}
                          <ItemGrid xs={12} sm={4}>
                            <GridContainer>
                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total IGST :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.getTotalTax("igst")}
                                    {/*
                                                                            <TextField
                                                                            disabled={true}
                                                                            id="totalIgst"
                                                                            className={classes.textField}
                                                                            value={this.getTotalTax("igst")}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                            InputProps={inputFocus}
                                                                            />
                                                                            */}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total SGST :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.getTotalTax("sgst")}
                                    {/*
                                                                        <TextField
                                                                            disabled={true}
                                                                            id="totalSgst"
                                                                            className={classes.textField}
                                                                            value={this.getTotalTax("sgst")}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                            InputProps={inputFocus}
                                                                        />
                                                                        */}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total CGST :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.getTotalTax("cgst")}
                                    {/*
                                                                            <TextField
                                                                                disabled={true}
                                                                                id="totalCgst"
                                                                                className={classes.textField}
                                                                                value={this.getTotalTax("cgst")}
                                                                                onChange={this.onChangeValues}
                                                                                fullWidth={true}
                                                                                InputProps={inputFocus}
                                                                            />
                                                                        */}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Total Amount :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.totalAmount}
                                    {/*
                                                                                <TextField
                                                                                    disabled={true}
                                                                                    id="totalAmount"
                                                                                    className={classes.textField}
                                                                                    value={this.state.totalAmount}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    InputProps={inputFocus}
                                                                                />
                                                                        */}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              {showRound ? (
                                <ItemGrid xs={12}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={5}
                                      style={{
                                        marginTop: "10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      <InputLabel className={classes.label}>
                                        Round Off :
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checked={
                                              parseFloat(roundoff) ===
                                              parseFloat(max)
                                                ? 1
                                                : 0
                                            }
                                            onChange={(event) => {
                                              this.setState({
                                                roundoff: max,
                                              });
                                            }}
                                            name="radio button demo"
                                            aria-label="A"
                                            icon={
                                              <FiberManualRecord
                                                className={
                                                  classes.radioUnchecked
                                                }
                                              />
                                            }
                                            checkedIcon={
                                              <FiberManualRecord
                                                className={classes.radioChecked}
                                              />
                                            }
                                            classes={{
                                              checked: classes.radio,
                                            }}
                                          />
                                        }
                                        classes={{
                                          label: classes.label,
                                        }}
                                        label={max + ""}
                                      />
                                    </ItemGrid>
                                    <ItemGrid
                                      xs={12}
                                      sm={3}
                                      style={{ textAlign: "right" }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checked={roundoff === round ? 1 : 0}
                                            onChange={(event) => {
                                              this.setState({
                                                roundoff: round,
                                              });
                                            }}
                                            name="radio button demo"
                                            aria-label="A"
                                            icon={
                                              <FiberManualRecord
                                                className={
                                                  classes.radioUnchecked
                                                }
                                              />
                                            }
                                            checkedIcon={
                                              <FiberManualRecord
                                                className={classes.radioChecked}
                                              />
                                            }
                                            classes={{
                                              checked: classes.radio,
                                            }}
                                          />
                                        }
                                        classes={{
                                          label: classes.label,
                                        }}
                                        label={round}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      TCS Amount :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {this.state.tcsAmount}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={5}
                                    style={{ textAlign: "right" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      Net Total :
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={12}
                                    sm={3}
                                    style={{ textAlign: "right" }}
                                  >
                                    {total}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          {/* Last Item End */}
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12}>
                        <InputLabel className={classes.label}>
                          Vendor Invoice Details :
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12}>
                        <GridContainer>
                          {/*
                                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Type Of Sale<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.saleType}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="saleType"
                                                                    staticData={saleTypes}
                                                                />

                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>
*/}

                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Invoice No
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="invoiceNo"
                                  value={this.state.invoiceNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  variant="outlined"
                                  inputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Invoice Date
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.timeChanged(dateObj, "invoiceDate");
                                    }}
                                    height={"15px"}
                                    formControlProps={{
                                      height: "15px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "invoiceDate",
                                      value: this.state.invoiceDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Payment Due Date
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.timeChanged(
                                        dateObj,
                                        "paymentDueDate"
                                      );
                                    }}
                                    height={"15px"}
                                    formControlProps={{
                                      height: "15px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "invoiceDate",
                                      value: this.state.paymentDueDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Invoice Amount
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="invoiceAmount"
                                  value={this.state.invoiceAmount}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  variant="outlined"
                                  inputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Invoice Received Date
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.timeChanged(
                                        dateObj,
                                        "invoiceReceivedDate"
                                      );
                                    }}
                                    height={"15px"}
                                    formControlProps={{
                                      height: "15px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "invoiceDate",
                                      value: this.state.invoiceReceivedDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Ledger
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  disabled={true}
                                  id="ledgerName"
                                  value={this.state.ledgerName}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  variant="outlined"
                                  inputProps={inputFocus}
                                />
                                {this.state.ledgerGroupDesc != "" ? (
                                  <ItemGrid xs={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12}>
                                        <span
                                          style={{
                                            fontStyle: "italic",
                                            fontWeight: 400,
                                            color: "red",
                                          }}
                                        >
                                          Ledger Group:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "red",
                                          }}
                                        >
                                          {this.state.ledgerGroupDesc}
                                          <br />
                                        </span>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {/* <SelectboxTemp id={"ledger"}
                                                                               defValue={this.state.ledger}
                                                                               onSelect={this.onSelect}
                                                                               getName={this.getGateEntryDetails}
                                                                               stateKey="ledger"
                                                                               url={""}
                                                                    // value={this.state.gateEntryNo}
                                                                />*/}
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  TDS Payable
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.tdsPayable}
                                  onSelect={this.onSelect}
                                  stateKey="tdsPayable"
                                  staticData={yesOrNo}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          {this.state.tdsPayable === "Y" ? (
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    TDS Amount
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="tdsAmount"
                                    value={this.state.tdsAmount}
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          ) : null}

                          {this.state.tdsPayable === "Y" ? (
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    TDS Reason
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="tdsReason"
                                    value={this.state.tdsReason}
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          ) : null}
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  TCS Percentage
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  selectDisabled={true}
                                  defValue={this.state.tcsPercentage}
                                  onSelect={this.onSelect}
                                  stateKey="tcsPercentage"
                                  staticData={TCSPercentages}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    TCS Amount
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <TextField
                                                                    disabled={true}
                                                                    id="tcsAmount"
                                                                    value={this.state.tcsAmount}
                                                                    className={classes.textField}
                                                                    onChange={this.onChangeValues}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    InputProps={inputFocus}

                                                                />
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid> */}
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid
                        xs={12}
                        style={{
                          height: 2,
                          backgroundColor: "#00acc1",
                          marginTop: "5px",
                        }}
                      />

                      <ItemGrid xs={12} sm={12}>
                        <InputLabel className={classes.label}>
                          Invoices
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *{" "}
                          </span>{" "}
                          :
                        </InputLabel>
                      </ItemGrid>

                      <ItemGrid
                        xs={8}
                        style={{ overflowY: "auto", maxHeight: 250 }}
                      >
                        {this.state.filesList.map((r, i) => (
                          <GridContainer style={{ marginTop: "10px" }}>
                            <ItemGrid xs={12} sm={2}>
                              {i + 1}
                            </ItemGrid>
                            <ItemGrid xs={12} sm={6}>
                              <a
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL + r.retrievalPath,
                                    r.fileName,
                                    r.fileExtension,
                                    this.handleDownloadedFile
                                  );
                                }}
                              >
                                {r.fileName !== null && r.fileName !== ""
                                  ? r.fileName
                                  : "invoice" + (i + 1)}
                              </a>
                            </ItemGrid>
                            <ItemGrid xs={4}>
                              <IconButton
                                right={true}
                                onClick={() => {
                                  this.setState({
                                    deleteInvoice: true,
                                    invoiceId: r.fileUploadId,
                                    fileId: i,
                                  });
                                  this.InvoicebasicAlert(
                                    "Do You Like To Delete This Item?"
                                  );
                                }}
                                color="dangerNoBackground"
                              >
                                <Close />
                              </IconButton>{" "}
                              {""}
                            </ItemGrid>
                          </GridContainer>
                        ))}
                      </ItemGrid>

                      <ItemGrid xs={4}>
                        <Button color={"info"} onClick={this.handleFileClick}>
                          Upload Invoice
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />

                  <ItemGrid xs={12} sm={12}>
                    <InputLabel className={classes.label}>Challan:</InputLabel>
                  </ItemGrid>

                  <ItemGrid
                    xs={8}
                    style={{ overflowY: "auto", maxHeight: 250 }}
                  >
                    {this.state.challanList.map((r, i) => (
                      <GridContainer style={{ marginTop: "10px" }}>
                        <ItemGrid xs={12} sm={2}>
                          {i + 1}
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <a
                            onClick={() => {
                              getFileDownload(
                                serverConfig.SERVER_URL + r.retrievalPath,
                                r.fileName,
                                r.fileExtension,
                                this.handleDownloadedFile
                              );
                            }}
                            /*title="ImageName" target={"_/"}*/
                          >
                            {r.fileName !== null && r.fileName !== ""
                              ? r.fileName
                              : "challan" + (i + 1)}
                          </a>
                        </ItemGrid>
                        <ItemGrid xs={4}>
                          <IconButton
                            right={true}
                            onClick={() => {
                              this.setState({
                                deleteChallan: true,
                                challanId: r.fileUploadId,
                                fileId: i,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Delete This Item?"
                              );
                            }}
                            color="dangerNoBackground"
                          >
                            <Close />
                          </IconButton>{" "}
                          {""}
                        </ItemGrid>
                      </GridContainer>
                    ))}
                  </ItemGrid>

                  <ItemGrid xs={4}>
                    <Button
                      color={"info"}
                      onClick={() => {
                        this.setState({
                          uploadChallan: true,
                        });
                        this.handleFileClick();
                      }}
                    >
                      Upload Challan
                    </Button>
                  </ItemGrid>
                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />

                  <ItemGrid xs={12} sm={12}>
                    <InputLabel className={classes.label}>
                      Support Documents:
                    </InputLabel>
                  </ItemGrid>

                  <ItemGrid
                    xs={8}
                    style={{ overflowY: "auto", maxHeight: 250 }}
                  >
                    {this.state.supportFilesList.map((r, i) => (
                      <GridContainer style={{ marginTop: "10px" }}>
                        <ItemGrid xs={12} sm={2}>
                          {i + 1}
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <a
                            onClick={() => {
                              getFileDownload(
                                serverConfig.SERVER_URL + r.retrievalPath,
                                r.fileName,
                                r.fileExtension,
                                this.handleDownloadedFile
                              );
                            }}
                            /*title="ImageName" target={"_/"}*/
                          >
                            {r.fileName !== null && r.fileName !== ""
                              ? r.fileName
                              : "challan" + (i + 1)}
                          </a>
                        </ItemGrid>
                        <ItemGrid xs={4}>
                          <IconButton
                            right={true}
                            onClick={() => {
                              this.setState({
                                deleteSupportFile: true,
                                supportfileId: r.fileUploadId,
                                fileId: i,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Delete This Item?"
                              );
                            }}
                            color="dangerNoBackground"
                          >
                            <Close />
                          </IconButton>{" "}
                          {""}
                        </ItemGrid>
                      </GridContainer>
                    ))}
                  </ItemGrid>

                  <ItemGrid xs={4}>
                    <Button
                      color={"info"}
                      onClick={() => {
                        this.setState({
                          uploadSupportFile: true,
                        });
                        this.handleFileClick();
                      }}
                    >
                      Upload File
                    </Button>
                  </ItemGrid>
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus &&
                      this.state.billPassStatus !== "1" ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({ isApprove: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({ isReject: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.approveStatus &&
                        this.state.billPassStatus !== "3") ||
                      this.state.billPassStatus === "1" ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                  {this.state.billPassStatus !== 21 ? (
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <SamplePDF
                        invoiceType={12}
                        printResp={this.state.printResp}
                      />
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  staticData={this.state.supplierList}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                SR No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="srNo"
                                value={this.state.srNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"status"}
                                defValue={this.state.status}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_STORE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Project" : "Branch"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                // isRefresh={this.state.loadQuality}
                                // setIsRefresh={this.afterRefreshQuality}
                                url={
                                  serverApi.SELECT_BRANCH +
                                  getCookie(cons.COMPANY_ID)
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Phase" : "Project"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                url={
                                  serverApi.PROJECT_MASTER_SELECTION_LIST +
                                  getCookie(cons.COMPANY_ID) +
                                  "/customer/0/branch/" +
                                  this.state.branchIds
                                }
                                defValue={this.state.projectIds}
                                // staticData={this.state.ProjectOptions}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12} style={{ marginTop: "80px" }}>
                          <br />
                          <br />

                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        staticData={this.state.tableList}
                        // url={serverApi.DATATABLE_SERVICE_URL + "getAllBillPass"}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_billpass_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {
    border: "1px solid #00ADBC",
    borderRadius: "4px",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      border: "2px solid #0ADBC",
    },
  },

  notchedOutline: {},
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(StoreBillPass);
