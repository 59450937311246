import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
//import {Redirect} from "react-router-dom";
//import IconCard from "../../components/Cards/IconCard";
import cons, { serverConfig, serverApi, serverVars } from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, { getMenuPermission } from "../CustumComponents/PermissionAlert";
import LineItemTable from "../CustumComponents/LineItemTable";

export class JuteMarketRate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            entryDate: moment().format('DD-MM-YYYY'),
            tableResp: [],
            visible: false,
            ispop:false


        }
    }
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

        //this.dateChanged();
    };
    handleGoClick = () => {
        this.setState({
            visible: true

        })
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER),
            "entryDate": this.state.entryDate,
        };
        requestList(serverApi.RATE_QUALITY_LIST, data, this.handleRateQuality)
    }
    handleRateQuality = (resp) => {
        this.setState({ printResp: resp });
        var header = resp.hdrdto;
        var list = resp.data;
        var tabData = []
        list.forEach((prop, key) => {
            var obj = {
                qualityCode: prop.id,
                juteType: prop.itemDesc,
                qualityName: prop.juteQuality,
                rate: prop.rate,
            }
            tabData.push(obj)
        });
        this.setState({
            tableResp: tabData
        })



    }
    rateUpdateButtonClick = () => {
        var rate=[]
        this.state.tableResp.map((prop,i)=>{
            var obj1=
                {
                    "qualityId": prop.qualityCode,
                    "juteRate": prop.rate
                }
            rate.push(obj1)
        })
        var datas={
            "companyId": getCookie(cons.COMPANY_ID),
            "userId":  getCookie(cons.USERID),
            "entryDate": this.state.entryDate,
            "juteRateList": rate
        }
        requestList(serverApi.Add_Daily_Jute_Rate, datas, this.handleAddIndent)

    }
    handleAddIndent = (resp) => {
        if (resp.status) {
            //console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
    }

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    hideAlertMessage() {
        this.setState({
            showMessagealert: false,
            visible:false,
            ispop:false
        });

    }
    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert: false,
            loading: false,
            ispop:false
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
                <ItemGrid xs={12}>
                    <RegularCard
                        content={
                            <div>
                                <GridContainer>
                                {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={10}>
                                        <GridContainer >
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Date
                                                            </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "entryDate")
                                                                }}
                                                                formControlProps={
                                                                    { height: "10px", inlineBlock: "none" }
                                                                }
                                                                inputProps={{
                                                                    id: "entryDate",
                                                                    value: this.state.entryDate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>

                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <Button color="primary" onClick={() => { this.handleGoClick() }}>Go</Button>
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

                                            {this.state.visible ?
                                                <ItemGrid xs={12} style={{ marginTop: "15px", marginLeft: "14%" }}>
                                                    <GridContainer >
                                                        <ItemGrid xs={2}>
                                                            <InputLabel className={classes.label}>
                                                                Quality_Code
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={2}>
                                                            <InputLabel className={classes.label}>
                                                                Jute_Type
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={3}>
                                                            <InputLabel className={classes.label}>
                                                                Quality_Name
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={2}>
                                                            <InputLabel className={classes.label}>
                                                                Rate
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={3}>
                                                            <InputLabel className={classes.label}>

                                                            </InputLabel>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                : null}
                                            <ItemGrid xs={12} style={{ maxHeight: 395, overflowY: "auto", overflowX: "hidden" }}>
                                                <GridContainer>
                                                    {this.state.visible ?
                                                        this.state.tableResp.map((prop, i) => {
                                                            return (<ItemGrid xs={12} style={{ marginTop: "10px", marginLeft: "15%" }}>
                                                                <GridContainer >
                                                                    <ItemGrid  xs={2}>
                                                                        {prop.qualityCode}
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={2}>
                                                                        {prop.juteType}
                                                                    </ItemGrid>
                                                                    <ItemGrid  xs={3}>
                                                                        {prop.qualityName}
                                                                    </ItemGrid>
                                                                    <ItemGrid  xs={2}>
                                                                        <TextField
                                                                            value={prop.rate}
                                                                            onChange={(val) => {
                                                                                var l = this.state.tableResp
                                                                                l[i].rate = parseInt(val.target.value)
                                                                                this.setState({ tableResp: l })
                                                                            }}
                                                                            className={classes.textField}
                                                                            fullWidth={true}
                                                                            iType={"number"}
                                                                            decimal={3}
                                                                        />
                                                                    </ItemGrid>
                                                                    <ItemGrid  xs={3}>
                                                                        {/* <Button color="primary"onClick={() => { this.rateUpdateButtonClick(i) }}>Update</Button> */}
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>)



                                                        })



                                                        : null}
                                                </GridContainer>
                                            </ItemGrid>
                                            {this.state.visible ?
                                                <ItemGrid xs={12} style={{ marginTop: "15px" }} >
                                                    <GridContainer justify="center">
                                                        <ItemGrid>
                                                            <Button color="primary" onClick={() => { this.rateUpdateButtonClick() }} >Save</Button>
                                                        </ItemGrid>
                                                        <ItemGrid>
                                                            <Button color="danger" onClick={() => {
                                                                this.setState({
                                                                    visible: false
                                                                })
                                                            }}>Cancel</Button>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid> : null}






                                        </GridContainer>
                                    </ItemGrid>
                                </GridContainer>
                            </div>
                        }

                    />
                </ItemGrid>


            </GridContainer>

        )
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    gridcontainer: {
        padding: '0 0 0 0',
        spacing: '2px'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(JuteMarketRate);
