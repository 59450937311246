import React from "react";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi,  getConfigValue, configNames} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList, uploadFile} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import Edit from '@material-ui/icons/Edit';
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import ExpandMoreIcon from '@material-ui/icons/Add';
import { VIEW} from "../../ui-actions/UiActions";
import TableComponent from "../CustumComponents/TableComponent";

class StoreIssue extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,
            IssuePopUp:false,
            fromDate:"",
            todate:"",
            deptId:"",
            itemGroup:'',
            item:'',
            costFactor:'',
            status:'',
            isRefresh:false,
            isLoading:false,
            itemViewType:"hdrId",
            approveStatus:false,
            hideViews:"none",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            createdBy:"",
            createdOn:"",
            alert:false,
            url:"",
            uom:'',
            selectedDepartment:'',
            selectedItemGroup:'',
            selectedItem:'',
            quantity:'',
            selectedCostCenter:'',
            ProcessType:'',
            stock:'',
            issueNo:'0',
            invoiceList:[],
            srLineId:[],
            customerList:[],
            showUpdate:false,
            indentTypeId:"",
            fromDates:'',
            toDates:"",
            IssueNos:'',
            statuss:'',
            customerName:'',
            indentTypeIds:'',
            branchId:"",
            customerId:0,
            projectId:'',
            branchIds:'',
            customerIds:"",
            projectIds:'',
            internalNote:"",
            srStock:0,
            srPrintNo:"",
            srQuantity:0,
            typeId:0

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
    }
    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            })
        }else {
            this.showMessage(resp.message,resp.status);
        }
    };
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "deleteStoreIssue/" + id+"/"+getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    handleViewClick =(id)=>{
        // var data = this.state.dataTable;
        //   var obj= JSON.parse(id);
        //  console.log("jute  "+"  "+obj);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
           // if(this.state.hdrId!=="") {
                var data = {
                    hdrId: id,
                    companyId: getCookie(cons.COMPANY_ID),
                    finnacialYear: getCookie(cons.ACADEMIC_YEAR),
                    userId:getCookie(cons.USERID)
                };

                //   var data={"date":obj.date,"deptCost":obj.deptCost,"uom":obj.uom,"userId":getCookie(cons.USERID),"companyId":getCookie(cons.COMPANY_ID),"cipher":getCookie(cons.CIPHER)};
                // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getStoreIssueById/" + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp);
                requestList(serverApi.DATATABLE_SERVICE_URL + "getStoreIssueById", data, this.handleViewClickResp)
          /*  }else {
                this.basicAlert("Please Enter Issue No..!!")
            }*/
        }
    };
    handleViewClickResp = (resp) => {
        this.setState({printResp:resp});
        var header = resp, createdBy = "", createdOn = "", date = "", finYear = "", selectedDepartment, selectedItemGroup,
            approveButton = false, issueStatus = false,showUpdate=false,hdrId,indentTypeId,branchId,customerId,projectId,internalNote;
        if(header !== null&&header.length > 0){
            var invoicelist = [];
            header.map((prop, key) => {
                selectedDepartment=parseInt(prop.deptId);
                selectedItemGroup=prop.groupCode;

                createdBy = prop.createdBy;
                createdOn = prop.createDateDesc;
                date = prop.issueDateDesc;
                finYear = prop.finnacialYear;
                branchId=prop.branchId!==null?prop.branchId:0;
                projectId=prop.projectId;
                internalNote=prop.internalNote;

                customerId=prop.customerId!==null?prop.customerId:0;
                approveButton = prop.approveButton;
                indentTypeId=prop.indentTypeId;
                hdrId=prop.hdrId;
                if(prop.status!=="3"&&prop.status!=="4"){
                    showUpdate=true
                }

                issueStatus = prop.status;
                var rate=parseFloat(prop.issueValue)/parseFloat(prop.quantity);
                rate=rate.toFixed(2);
                // issueId=prop.issueId;

                var obj= {
                    id: key,
                    issueId: prop.id,
                    department:prop.deptDesc,
                    itemGroupId:prop.groupCode,
                    itemGroup: prop.groupDesc,
                    itemId:prop.itemCode,
                    itemGrp:prop.groupCode+""+prop.itemCode,
                    item: prop.itemDesc,
                    quantity:prop.quantity,
                    srQuantity:prop.quantity,
                    requestedQty:prop.status==="29"?prop.quantity:"",
                    uom: prop.uomCode,
                    stock:prop.stock,
                    deptId: parseInt(prop.deptId),
                    deleteStatus:false,
                    costFactorId:parseInt(prop.deptCost),
                    costFactor:prop.costDesc,
                    srLineId:parseInt(prop.srLineId),
                    srNo:prop.srNo,
                    issueValue:prop.issueValue,
                    machineId:prop.machineId,
                    srPrintNo:prop.srPrintNo,
                    rate:rate,
                    machineName:prop.machineName,

                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === obj.id) {
                                            this.basicItemAlert("Do You Like To Update This Item?");

                                            this.setState({
                                                id: o.id,
                                                issueId:o.issueId,
                                                indentItemId: o.indentItemId,
                                                updateItemAlert: true,
                                                updateItem: true,
                                                selectedDepartment:o.deptId,
                                                quantity:o.quantity,
                                                selectedCostCenter:o.costFactorId,
                                                stock: o.stock,
                                                uom:o.uom,
                                                selectedItemGroup:o.itemGroupId,
                                                selectedItem:o.itemId,
                                                itemGroupName:o.itemGroup,
                                                item:o.item,
                                                deptName:o.department,
                                                costFactorName:o.costFactor,
                                                srLineId:o.srLineId,
                                                srNo:o.srNo,
                                                issueValue:o.issueValue,
                                                machineId:o.machineId,
                                                requestedQty:o.requestedQty,
                                                srPrintNo:o.srPrintNo,
                                                showTextSrno:this.isSrisThere(o.srLineId,this.state.srList,true),
                                                rate:o.rate,
                                                srQuantity:o.srQuantity,
                                                loadItemGroup:true,
                                                machineName:o.machineName

                                                // alert:true,

                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === obj.id) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                                issueId: o.issueId,
                                            });

                                            //  data.splice(i,1);
                                            return true;
                                        }
                                    });
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>{" "}
                        </div>

                    )
                }
                invoicelist.push(obj)
            });


            this.setState({
                updateView: true,
                isCreated:true,
                hideViews:"block",
                // unitConvertionId:"0",
                branchId:branchId,
                customerId:customerId,
                projectId:projectId,
                date1:date,
                createdOn:createdOn,
                createdBy:createdBy,
                issueStatus:issueStatus,
                showUpdate:showUpdate,
                approveStatus:approveButton,
                invoiceList:invoicelist,
                hdrId:hdrId,
                indentTypeId:indentTypeId,
                loadCustomer:true,
                loadBranch:true,
                loadProject:true,
                loadType:true,
                internalNote:internalNote,

            });
            if(getConfigValue(configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS)==="2")
                this.setState({disableDept:true, selectedDepartment:selectedDepartment,
                    selectedItemGroup:selectedItemGroup})

        }


    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleCreate=()=>{
        //alert();
        if(!this.state.isCreated){
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getIssueListData=()=>{
        const {fromDates,toDates,IssueNos,customerIds,indentTypeIds,branchIds,projectIds,statuss}=this.state;
        var data={
            "fromDate":fromDates,
            "toDate":toDates,
            "hdrId":IssueNos,
            "customerId":customerIds,
            "indentTypeId":indentTypeIds,
            "branchId":branchIds,
            "status":statuss!==0?statuss:"",
            "companyId":getCookie(cons.COMPANY_ID),
            "projectId":projectIds,
            "finnacialYear":getCookie(cons.ACADEMIC_YEAR),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
        }
        return data;
    }
    getData = () => {
        var data={
            "fromDate":this.state.fromDate
            ,"toDate":this.state.todate,
            /*  ,"deptId":this.state.deptId,
              "groupId":this.state.itemGroup,
              "itemId":this.state.item,*/
            // "costFactor":this.state.costFactor,
            "companyId":getCookie(cons.COMPANY_ID),
            "cipher":getCookie(cons.CIPHER),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else {
            this.setState({
                [id] :''
            });

        }
        // this.dateChanged();
    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    }



    onChangeValues =(event)=> {
        var value= event.target.value;

        if(event.target.id==="stock"){
            console.log("val "+this.state.indentQuantity);
            this.setState({
                stock: this.state.stock
            })
        }
        if(event.target.id==='quantity'&&value!==''){
            if(this.state.issueStatus==="29"){
                if(parseFloat(value)>parseFloat(this.state.requestedQty)){
                    this.basicAlert("Quantity Should not be greater than requested quantity..!!")
                }else {
                    var issueValue=parseFloat(value)*parseFloat(this.state.rate);
                    this.setState({issueValue:issueValue.toFixed(2)})
                    this.setState({
                        [event.target.id]: value
                    });

                }
            }else {
                if(this.state.updateView&&this.state.updateItem){
                    var stock=parseFloat(this.state.srStock)+parseFloat(this.state.srQuantity);
                    if(parseFloat(value)>stock){
                        this.basicAlert("Quantity Should not Exceed "+stock+"..!!")
                    }else {
                        var issueValue=parseFloat(value)*parseFloat(this.state.rate);
                        this.setState({issueValue:issueValue.toFixed(2)});
                        this.setState({
                            [event.target.id]: value
                        });

                    }
                }else {
                    var issueValue = parseFloat(value) * parseFloat(this.state.rate);
                    this.setState({issueValue: issueValue.toFixed(2)});
                    this.setState({
                        [event.target.id]: value
                    });
                }

            }

        }else
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        if(stateKey === 'selectedDepartment'&&this.state.selectedDepartment!==selectedValue){
            this.setState({
                loadItemGroup:true
            })
            //  this.getSrAStockByItemDept(selectedValue,this.state.selectedItemGroup,this.state.selectedItem)
        }
        if(stateKey === 'selectedItemGroup'){
            var data={
                "itemGroupId":selectedValue,
                "saleable":"",
                "consumable":"Y",
                "companyId":getCookie(cons.COMPANY_ID),
                "indentTypeId":this.state.typeId,
                "inActiveItems":true

            };

            requestList(serverApi.GET_ITEMS_BY_GRPID,data,this.handleItem);
            this.setState({
                loadItem:true
            })
            //  this.getSrAStockByItemDept(this.state.selectedDepartment,selectedValue,this.state.selectedItem)

        }
        if(stateKey==='selectedItem'){
            this.getSrAStockByItemDept(this.state.selectedDepartment,this.state.selectedItemGroup,selectedValue) ;
            var data={"itemGrpId":this.state.selectedItemGroup,"itemId":selectedValue,"companyId":getCookie(cons.COMPANY_ID),"branchId":this.state.branchId};
            requestList(serverApi.GET_BRANCH_WISE_STOCK,data,this.handleBrachStock);


        }
        if(stateKey === 'branchIds' || stateKey === 'branchId'){
            this.setState({loadCustomer:true,loadProject:true});
            // getDataFromUrl(serverApi.GET_CUSTOMER_BY_BRANCH+getCookie(cons.COMPANY_ID)+"/"+selectedValue,this.handelCustomer);
        }
        if(stateKey === 'customerIds'  ){
            this.setState({loadProject:true});

           // getDataFromUrl(serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/"+selectedValue+"/branch/"+this.state.branchIds,this.handleProjects);
        }

        if( stateKey === 'customerId' ){
            this.setState({loadProject:true});
          //  getDataFromUrl(serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/"+selectedValue+"/branch/"+this.state.branchId,this.handleProjects);
        }

        this.setState({
            [stateKey]: selectedValue
        });
    };
    handleBrachStock=(resp)=>{
        this.setState({branchStock:resp.data})
    };

    handleItem = (resp) => {
        this.setState({ItemOptions:resp,loadItem:true})

    }
    afterRefreshItem = (val) => {

        this.setState({
            loadItem: val
        });
    };

    afterRefreshItemGrp = (val) => {

        this.setState({
            loadItemGroup: val
        });
    };
    getDeptName = (selectedValue) => {
        this.setState({
            deptName:selectedValue
        });
    };
    getItemGroupName = (selectedValue) => {
        this.setState({
            itemGroupName:selectedValue
        });
    };
    getCostFactorName=(selectedValue)=>{
        this.setState({
            costFactorName:selectedValue
        });

    };

    getItemDetailsByName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            item:values[0],
            // selectedItemId:values[1],
            uom:values[1],
            //stock:values[2]!=='null'?values[2]:0.00,
        });
    };

    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };

    hideBasicAlert(){
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false,
            isReject:false,isApprove:false,isAccept:false
        });

    }
    hideAlert() {
        console.log("alert");
        this.setState({
            isJuteSelected:false,
            alert: false,
            updateItem: false,
            showMessagealert:false,
            IssuePopUp:false,
            loading:false,
            id:"",
            issueId:"",
            quantity:"",
            selectedCostCenter:"",
            stock: "",
            uom:"",
            selectedItem:"",
            deptName:"",
            itemGroupName:"",
            item:"",
            costFactorName:"",
            machineName:"",
            machineId:"",
            srLineId:"",
            srNo:"",
            issueValue:"",
            srList:[],
            srStock:0,
            fromDates:'',
            toDates:"",
            IssueNos:'',
            statuss:'',
            customerName:'',
            indentTypeIds:'',
            srPrintNo:"",
            showTextSrno:false,
            srQuantity:0
        });
    }
    hideAlertMessage(){
        this.setState({
            isCreated:false,
            hideViews:"none",
            approveStatus:false,
            showMessagealert:false,
            IssuePopUp:false,
            alert: false,
            indentNo:"",
            selectedDepartment:'',
            selectedItemGroup:'',
            selectedItem:'',
            quantity:'',
            selectedCostCenter:'',
            ProcessType:'',
            stock:'',
            updateView:false,
            machineName:"",
            machineId:"",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            invoiceList:[],
            showUpdate:false,
            hdrId:"",
            indentTypeId:"",
            branchId:"",
            customerId:0,
            projectId:'',
            loadType:true,
            loadCustomer:true,
            loadProject:true,
            loadBranch:true,
            internalNote:"",
            disableDept:false,
            typeId:0
        });

    }
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }


    onRejectButtonClick = () => {
        var data = {
            "id": this.state.issueNo,
            "finnacialYear": this.state.year,
            "issueDate": this.dateChanged(),
            "deptId": this.state.selectedDepartment,
            "itemCode": this.state.selectedItem,
            "groupCode": this.state.selectedItemGroup,
            "quantity": this.state.quantity,
            "deptCost": this.state.selectedCostCenter,
            "stock": this.state.stock,
            "processType": this.state.ProcessType,
            "uomCode": this.state.uom,
            "status":4,
            "submitter": getCookie(cons.USERID),
            "lastModifiedUser": getCookie(cons.USER_NAME),


        };
        this.handleLoading();
        requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
    };

    onApproveButtonClick=(status)=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                return ({
                    "id":prop.issueId,
                    "hdrId":this.state.hdrId,
                    "finnacialYear": this.state.year,
                    "indentTypeId":this.state.indentTypeId,
                    "issueDate": this.state.date1,
                    "branchId":this.state.branchId,
                    "customerId":this.state.customerId,
                    "projectId":this.state.projectId,
                    "deptId": prop.deptId,
                    "itemCode": prop.itemId,
                    "groupCode": prop.itemGroupId,
                    "quantity": prop.quantity,
                    "deptCost": prop.costFactorId,
                    "stock": prop.stock,
                    // "processType": this.state.ProcessType,
                    "uomCode": prop.uom,
                    "status":status,
                    "deleteStatus":prop.deleteStatus,
                    "machineId":prop.machineId,
                    "lastModifiedUser": getCookie(cons.USERID),
                    "submitter": getCookie(cons.USERID),
                    //"createdBy": getCookie(cons.USER_NAME),
                    "companyId":getCookie(cons.COMPANY_ID),
                    "cipher":getCookie(cons.CIPHER),
                    "issueSrlNo":key+1,
                    "srNo":prop.srNo,
                    "srLineId":prop.srLineId,
                    "issueValue":prop.issueValue,
                    internalNote:this.state.internalNote,
                    "srPrintNo":prop.srPrintNo
                })
            });
            if (this.state.date1 !== ''&& this.state.invoiceList.length>0&&this.state.indentTypeId!==""&&this.state.indentTypeId!=="0"&&this.state.branchId!==""&&this.state.branchId!==0) {
                this.handleLoading();
                requestList(serverApi.UPDATE_STORE_ISSUE, invoiceList, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick=()=>{
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                return ({
                    "finnacialYear": this.state.year,
                    "indentTypeId":this.state.indentTypeId,
                    "issueDate": this.state.date1,
                    "branchId":this.state.branchId,
                    "customerId":this.state.customerId,
                    "projectId":this.state.projectId,
                    "deptId": prop.deptId,
                    "itemCode": prop.itemId,
                    "groupCode": prop.itemGroupId,
                    "quantity": prop.quantity,
                    "deptCost": prop.costFactorId,
                    "stock": prop.stock,
                    // "processType": this.state.ProcessType,
                    "uomCode": prop.uom,
                    "submitter": getCookie(cons.USERID),
                    "createdBy": getCookie(cons.USER_NAME),
                    "companyId":getCookie(cons.COMPANY_ID),
                    "cipher":getCookie(cons.CIPHER),
                    "machineId":prop.machineId,
                    "issueSrlNo":key+1,
                    "srNo":prop.srNo,
                    "srPrintNo":prop.srPrintNo,
                    "srLineId":prop.srLineId,
                    "issueValue":prop.issueValue,
                    "status":"1",
                    internalNote:this.state.internalNote
                })
            });

            /*
                        var data = {
                            invoiceList
                        };
            */
            if (this.state.date1 !== ''&& this.state.invoiceList.length>0&&this.state.indentTypeId!==""&&this.state.indentTypeId!=="0"&&this.state.branchId!==""&&this.state.branchId!==0) {
                this.handleLoading();
                requestList(serverApi.ADD_STORE_ISSUE, invoiceList, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please add atleast one line item..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item



    getInvoiceTablePageSize=()=>{
        var length =this.state.invoiceList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };


    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    /* const onSelect = (selectedValue, stateKey) => {
         if(this.state.isJuteSelected){
             this.setState({
                 isJuteSelected:false
             });

         }
         this.setState({
             [stateKey]: selectedValue,
         });

         if(stateKey==="selectedJuteType"){
             //   id=selectedValue;
             console.log(" is "+selectedValue);

             if(selectedValue !==0)
                 this.setState({
                     isJuteSelected:true,
                     //  alert:null
                 });
             //  return id;
         }
         //  handleAddItem();
     };*/

    handleAddItem () {
        const {classes}=this.props;
        const {selectedJuteType}=this.state;
        console.log("unitConversionName "+ this.state.unitConvertion);
        //  var id="";
        console.log(this.state);
        if(this.state.selectedMukam !=='0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType!== "")
            this.setState({
                loading:true,
                alert:true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };

    isSrisThere=(srLineId,srList,updateItem)=>{
        var val=true;
        if(this.state.updateView&&updateItem){
            if(srList)
            srList.forEach(prop=>{
                if(prop.value+""===srLineId+""){
                    val=false
                }
            })
        }else {
            val=false
        }
        console.log("srLineId",val);

        return val;
    };
    onClickAddItem() {
        var arr = this.state.invoiceList;
        var sumOfQty = 0, quantityInItem = 0;
        var itemIndex = 0, quantity = 0, hasItem = false, issueId = 0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            issueId = this.state.issueId;
            quantityInItem = arr[itemIndex].quantity;
            arr[itemIndex].quantity = this.state.quantity
        } else {
            itemIndex = this.state.invoiceList.length
        }

        //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
        arr.map(q => {
            sumOfQty = sumOfQty + parseInt(q.quantity);
        });
        if (!this.state.updateItem) {
            sumOfQty = sumOfQty + parseInt(this.state.quantity);
        }
        var issueValue=parseFloat(this.state.rate)*parseFloat(this.state.quantity);

        var addItemObj = {
            id: itemIndex,
            issueId:issueId,
            department: this.state.deptName,
            itemGroupId:this.state.selectedItemGroup,
            itemGroup: this.state.itemGroupName,
            itemId: this.state.selectedItem,
            item: this.state.item,
            itemGrp:this.state.selectedItemGroup+""+this.state.selectedItem,
            quantity:this.state.quantity,
            requestedQty:this.state.issueStatus==="29"?this.state.requestedQty:"",
            uom: this.state.uom,
            stock:this.state.stock,
            deptId: this.state.selectedDepartment,
            deleteStatus:false,
            costFactorId:this.state.selectedCostCenter,
            costFactor:this.state.costFactorName,
            machineId:this.state.machineId,
            machineName:this.state.machineName,
            srNo:this.state.srNo,
            srLineId: this.state.srLineId,
            srPrintNo:this.state.srPrintNo,
            issueValue:issueValue.toFixed(2),
            rate:this.state.rate,
            srStock:this.state.srStock,
            srQuantity:this.state.srQuantity,
            actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id: o.id,
                                        issueId: o.issueId,
                                        updateItemAlert: true,
                                        updateItem: true,
                                        selectedDepartment:o.deptId,
                                        quantity:o.quantity,
                                        selectedCostCenter:o.costFactorId,
                                        stock: o.stock,
                                        uom:o.uom,
                                        selectedItemGroup:o.itemGroupId,
                                        selectedItem:o.itemId,
                                        deptName:o.department,
                                        itemGroupName:o.itemGroup,
                                        item:o.item,
                                        costFactorName:o.costFactor,
                                        machineId:o.machineId,
                                        machineName:o.machineName,
                                        srNo:o.srNo,
                                        srLineId: o.srLineId,
                                        issueValue:o.issueValue,
                                        requestedQty:o.requestedQty,
                                        srPrintNo:o.srPrintNo,
                                        showTextSrno:this.isSrisThere(o.srLineId,this.state.srList,true),
                                        rate:o.rate,
                                        srStock:o.srStock,
                                        srQuantity:o.srQuantity,
                                        loadItemGroup:true
                                        // alert:true,

                                    });
                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="edit">
                        <Edit/>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({
                                        deleteItem: true,
                                        id: o.id,
                                        issueId: o.issueId,
                                    });

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close/>
                    </IconButton>{" "}
                </div>

            )
        };

        var stock=parseFloat(this.state.srStock);
        if(this.state.updateView&&this.state.updateItem){
            stock=parseFloat(this.state.srStock)+parseFloat(this.state.srQuantity);
        }
        arr.map(q=>{
            //sumOfQty = sumOfQty+parseInt(q.quantityType);
            if(q.itemId ===  this.state.selectedItem && q.itemGroupId === this.state.selectedItemGroup&&q.srLineId===this.state.srLineId && !this.state.updateItem)
                hasItem = true;
        });

         if (!hasItem) {
        if (this.state.date1 !== '' && this.state.selectedDepartment !== "0" && this.state.selectedItemGroup !== "0" && this.state.selectedItem !== "0" && this.state.quantity !== ""&&this.state.srLineId>0 && this.state.quantity >0) {

            if (parseFloat(addItemObj.quantity) <= stock) {
                if (this.state.updateItem) {
                    arr[itemIndex] = addItemObj;
                } else
                    arr.splice(arr.length, 0, addItemObj);

                this.setState({
                    hideJuteType: false,
                    loading: true,
                    invoiceList: arr,
                });
                if(getConfigValue(configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS)==="2")
                    this.setState({disableDept:true});

                this.hideAlert();
            } 
            else {
                this.basicAlert("Quantity Should Not Exceed SR Stock..!!");
            }
        }else if(this.state.quantity <0 || this.state.quantity == 0) {
            this.basicAlert("Do not Enter Negative values and Zero in Quantity..!!");
        }
        
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
         }else
             this.basicAlert("Item Already Saved For This SR. Please Select Another One..!!");
    }
    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }
    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.forEach((prop,i)=>{
                if(prop.id===this.state.id){
                    if(this.state.updateView && this.state.issueId!==0)
                        data[this.state.id].deleteStatus=true;
                    else
                        data.splice(this.state.id, 1);
                }

            });

            var disableDept=false;

            if(getConfigValue(configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS)==="2") {
                data.forEach((prop, i) => {
                    if (!prop.deleteStatus) {
                        disableDept = true
                    }
                });
            }

            this.setState({
                invoiceList: data,
                deleteItem: false,
                disableDept:disableDept
            });
        }
        this.setState({
            basicAlert: null,
        });

    }


    getDataTable =()=>{
        var data=[];
        //  console.log("dta",JSON.stringify(this.state.invoiceList))
        if(this.state.invoiceList.length>0)
            this.state.invoiceList.map((prop,key)=>{
                if(!prop.deleteStatus){
                    data.push(prop)
                }
            });
        return data;
    };
    getMachineName=(val)=>{
        this.setState({
            machineName:val
        })
    };

    getSrAStockByItemDept=(deptId,itemGrpId,itemId)=>{
        if(deptId!==""&&itemGrpId!==""&&itemId!==""){
            var data={
                //"deptId":deptId,
                "itemGrpId":itemGrpId,
                "itemId":itemId,
                "branchId":this.state.branchId,
                "companyId":getCookie(cons.COMPANY_ID)
            };
            requestList(serverApi.GET_SR_STOCK_LIST,data,this.handleSrStock);
            requestList(serverApi.GET_BALANCE_STOCK_LIST,data,this.handleStock);
        }else {
            this.setState({srList:[],loadSr:true})

        }

    };
    handleStock=(resp)=>{
        if(resp.status===true)
            this.setState({stock:resp.data});
        else {
            this.setState({stock:0})

        }
    };
    handleSrStock=(resp)=>{
        if(resp!==null){

            console.log("srStock",this.state.srStock);
          /* if(this.isSrisThere(this.state.srLineId,resp,this.state.updateItem)&&this.state.srStock===0){

               this.setState({srStock:this.state.quantity})
           }*/
            this.setState({srList:resp,loadSr:true,showTextSrno:this.isSrisThere(this.state.srLineId,resp,this.state.updateItem)})

        }
    };
    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,entryId,isReject,isApprove,isAccept}=this.state;

        if(isAccept){
            this.setState({isAccept:false});
            this.onApproveButtonClick("29")

        }
        if(isReject){
            this.setState({isReject:false});
            this.onApproveButtonClick("4")
        }
        if(isApprove){
            this.setState({isApprove:false});
            this.onApproveButtonClick(this.state.issueStatus)
        }

        if (uploadInvoice) {
            uploadFile(fileName,file,fileType,22,fileName,entryId, this.handleInvoiceResp);
            //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
            //  this.uploadInvoiceFile();
        }
        if (this.state.deleteInvoice) {

            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert1: null,
        });

    }

    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }
    render(){
        const { classes } = this.props;
        const {selectedJuteType}=this.state;
        const inputFocus={
            autoComplete: 'off'
        };
        var labelType=getConfigValue(configNames.LABELS_TYPE);

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                   {this.state.isCreated ?

                <ItemGrid xs={12}>
                    <RegularCard
                        isLoading={this.state.isLoading}
                                                    cardTitle={
                                                        <Button color={"info"} onClick={this.handleCreate}>
                                                            Back To List</Button>
                                                    }
                        content={
                            <GridContainer>
                                {this.state.basicAlert1}

                                {this.state.basicAlert}
                                {this.state.showMessagealert}
                                {
                                    this.state.alert ?
                                        <Popup
                                            onClickSave={() => this.onClickAddItem()}
                                            closePopup={() => this.hideAlert()}
                                            title="Post Temperature"
                                            isShowSave={true}
                                            isShowCancel={true}
                                            save_btn_txt={this.state.updateItem ? "update" : "Add"}
                                            cancel_button_text={"Cancel"}
                                            // juteType={selectedJuteType}
                                            content={
                                                <form>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Department<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        selectDisabled={(this.state.updateView&&this.state.updateItem)||this.state.disableDept}
                                                                        id={"selectedDepartment"}
                                                                        onSelect={this.onSelect}
                                                                        defValue={this.state.selectedDepartment}
                                                                        getName={this.getDeptName}
                                                                        stateKey="selectedDepartment"
                                                                        url={serverApi.SEARCH_DEPATMENTS+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                        value={this.state.selectedDepartment}
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        selectDisabled={(this.state.updateView&&this.state.updateItem)||this.state.disableDept}
                                                                        id={"selectedItemGroup"}
                                                                        defValue={this.state.selectedItemGroup}
                                                                        onSelect={this.onSelect}
                                                                        getName={this.getItemGroupName}
                                                                        isRefresh={this.state.loadItemGroup}
                                                                        stateKey="selectedItemGroup"
                                                                        setIsRefresh={this.afterRefreshItemGrp}
                                                                        url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedDepartment+"/"+getCookie(cons.COMPANY_ID)+"/getAllItemGroupsByDepartmentId/"+getCookie(cons.CIPHER)}
                                                                        value={this.state.selectedItemGroup}
                                                                    />


                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        selectDisabled={this.state.updateView&&this.state.updateItem}
                                                                        id={"selectedItem"}
                                                                        defValue={this.state.selectedItem}
                                                                        onSelect={this.onSelect}
                                                                        getName={this.getItemDetailsByName}
                                                                        isRefresh={this.state.loadItem}
                                                                        stateKey="selectedItem"
                                                                        setIsRefresh={this.afterRefreshItem}
                                                                        staticData={this.state.ItemOptions}
                                                                        //url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                        value={this.state.selectedItem}
                                                                    />


                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        SR No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    {this.state.showTextSrno?
                                                                        <TextField
                                                                            disabled={true}
                                                                            id="srPrintNo"
                                                                            value={this.state.srPrintNo}
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                        />

                                                                        :  <SelectboxTemp
                                                                        id={"srLineId"}
                                                                        onSelect={this.onSelect}
                                                                        defValue={this.state.srLineId}
                                                                        getName={(val)=>{
                                                                            var name=val.split('_');

                                                                            var srStock=name[2],qty=name[2],rate=name[1],srPrintNo="";
                                                                            srStock=parseFloat(srStock);
                                                                            if(this.state.updateItem){
                                                                                qty=this.state.quantity;
                                                                                rate=this.state.rate
                                                                            }

                                                                            var issueval=parseFloat(qty)*parseFloat(rate);
                                                                            console.log("issueval",issueval);
                                                                            this.setState({
                                                                                srNo:name[0],
                                                                                rate:name[1],
                                                                                quantity:qty,
                                                                                srStock:srStock,
                                                                                srPrintNo:name[5]!=="null"?name[5]:"",
                                                                                issueValue:issueval.toFixed(2)
                                                                            })
                                                                        }}
                                                                        isRefresh={this.state.loadSr}
                                                                        setIsRefresh={(val)=>{
                                                                            this.setState({loadSr:false})
                                                                        }}
                                                                        stateKey="srLineId"
                                                                        staticData={this.state.srList}
                                                                        value={this.state.srLineId}
                                                                    />}
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>

                                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Quantity<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        id="quantity"
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        value={this.state.quantity}
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        decimal={3}
                                                                        InputProps={{

                                                                            autoComplete: 'off',
                                                                            endAdornment: <InputAdornment position="end">
                                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>{this.state.uom}</span>
                                                                            </InputAdornment>,
                                                                        }}

                                                                    />

                                                                </ItemGrid>

                                                            </GridContainer>
                                                        </ItemGrid>
                                                        {labelType!=="2"?
                                                            <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Cost Factor
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        id={"selectedCostCenter"}
                                                                        onSelect={this.onSelect}
                                                                        defValue={this.state.selectedCostCenter}
                                                                        getName={this.getCostFactorName}
                                                                        stateKey="selectedCostCenter"
                                                                        url={serverApi.SEARCH_COST_FACTOR+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                        value={this.state.selectedCostCenter}
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>:null}
                                                        <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Machine No
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        id={"machineId"}
                                                                        onSelect={this.onSelect}
                                                                        defValue={this.state.machineId}
                                                                        getName={this.getMachineName}
                                                                        stateKey="machineId"
                                                                        url={serverApi.MACHINE_MASTER_SELECTION+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                        value={this.state.machineId}
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>


                                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        {labelType==="2"?"City Stock":"Central Stock"}
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        disabled={true}
                                                                        id="stock"
                                                                        value={this.state.stock}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        InputProps={inputFocus}
                                                                        iType={"number"}
                                                                        decimal={3}
                                                                    />


                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        {labelType==="2"?"Project Stock":" Branch Stock"}

                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        id="branchStock"
                                                                        disabled={true}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        value={this.state.branchStock}
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        InputProps={inputFocus}
                                                                    />

                                                                </ItemGrid>

                                                            </GridContainer>
                                                        </ItemGrid>


                                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        SR Stock
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        disabled={true}
                                                                        id="srStock"
                                                                        value={this.state.srStock}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        InputProps={inputFocus}
                                                                        iType={"number"}
                                                                        decimal={3}
                                                                    />


                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Issue Value
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        disabled={true}
                                                                        id="issueValue"
                                                                        value={this.state.issueValue}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        InputProps={inputFocus}
                                                                        iType={"number"}
                                                                        decimal={3}
                                                                    />


                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>


                                                    </GridContainer>


                                                </form>

                                            }
                                        >
                                        </Popup> : null
                                }

{/*
                                {
                                    this.state.IssuePopUp ?
                                        <Popup
                                            onClickSave={() => this.onClickAddItem()}
                                            closePopup={() => this.hideAlert()}
                                            title="Post Temperature"
                                            isShowSave={false}
                                            isShowCancel={false}
                                            tablePop={true}
                                            // maxHeight={500}
                                            // save_btn_txt={this.state.updateItem ? "update" : "Add"}
                                            cancel_button_text={"Cancel"}
                                            // juteType={selectedJuteType}
                                            content={
                                                <form>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Issue No
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        id="IssueNos"
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        value={this.state.IssueNos}
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            autoComplete: 'off'
                                                                        }}
                                                                    />

                                                                </ItemGrid>

                                                            </GridContainer>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} md={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        From Date
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} md={8}>
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <Datetime
                                                                            closeOnSelect={true}
                                                                            timeFormat={false}
                                                                            onChange={(dateObj) => {
                                                                                this.timeChanged(dateObj, "fromDates")
                                                                            }}
                                                                            formControlProps={
                                                                                {height:"10px",inlineBlock: "none"}
                                                                            }

                                                                            inputProps={{
                                                                                id:"fromDates",
                                                                                value:this.state.fromDates,
                                                                                autoComplete: 'off'
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} md={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        To Date
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} md={8}>
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <Datetime
                                                                            closeOnSelect={true}
                                                                            timeFormat={false}
                                                                            onChange={(dateObj) => {
                                                                                this.timeChanged(dateObj, "toDates")
                                                                            }}
                                                                            formControlProps={
                                                                                {height:"10px",inlineBlock: "none"}
                                                                            }

                                                                            inputProps={{
                                                                                id:"toDates",
                                                                                value:this.state.toDates,
                                                                                autoComplete: 'off'
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>

                                                        <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Status
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        defValue={this.state.statuss}
                                                                        id={"statuss"}
                                                                        onSelect={this.onSelect}
                                                                        stateKey="statuss"
                                                                        url={serverApi.SEARCH_STORE_STATUS}
                                                                        value={this.state.statuss}
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>

                                                        <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        {labelType==="2"?"Project":"Branch"}
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        defValue={this.state.branchIds}
                                                                        onSelect={this.onSelect}
                                                                        //  getName={this.getQualityName}
                                                                        stateKey="branchIds"
                                                                        // isRefresh={this.state.loadQuality}
                                                                        // setIsRefresh={this.afterRefreshQuality}
                                                                        url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                                        value={this.state.branchIds}
                                                                    />

                                                                </ItemGrid>

                                                            </GridContainer>
                                                        </ItemGrid>
                                                        {labelType!=="2"?  <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>

                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Customer Name
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        id={"customerIds"}
                                                                        onSelect={this.onSelect}
                                                                        stateKey="customerIds"
                                                                        // staticData={this.state.customerList}
                                                                        isRefresh={this.state.loadCustomer}
                                                                        setIsRefresh={(val)=>{
                                                                            this.setState({loadCustomer:false})
                                                                        }}
                                                                        url={serverApi.GET_CUSTOMER_BY_BRANCH+getCookie(cons.COMPANY_ID)+"/"+this.state.branchIds}
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>:null}

                                                        <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        {labelType==="2"?"Phase":"Project"}
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <SelectboxTemp
                                                                        defValue={this.state.projectIds}
                                                                        url={serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/"+this.state.customerIds+"/branch/"+this.state.branchIds}

                                                                        //staticData={this.state.ProjectOptions}
                                                                        onSelect={this.onSelect}
                                                                        stateKey="projectIds"
                                                                        value={this.state.projectIds}
                                                                        isRefresh={this.state.loadItem}
                                                                        setIsRefresh={this.afterRefreshItem}
                                                                    />

                                                                </ItemGrid>

                                                            </GridContainer>
                                                        </ItemGrid>

                                                        <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        Expenses Type
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8} >
                                                                    <SelectboxTemp id={"indentTypeIds"}
                                                                        // selectDisabled={this.state.updateView}
                                                                                   defValue={this.state.indentTypeIds}
                                                                                   onSelect={this.onSelect}
                                                                                   stateKey="indentTypeIds"
                                                                                   isReload={this.state.loadType}
                                                                                   setIsReload={(val)=>{
                                                                                       this.setState({loadType:false})
                                                                                   }}
                                                                                   url={serverApi.GET_STORE_INDENT_TYPES}
                                                                    />

                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>
                                                        <ItemGrid xs={2}  style={{marginTop:"5px"}}>
                                                            <Button color="primary" onClick={this.handleSeacrh}>Search</Button>
                                                        </ItemGrid>
                                                        <ItemGrid xs={2}  style={{marginTop:"5px"}}>
                                                            <Button color="danger" onClick={this.hideAlert}>Cancel</Button>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                    <ItemGrid xs={12} className={classes.line}/>

                                                    <ItemGrid xs={12} style={{maxHeight:300,overflowY:"auto",overflowX:"hidden"}}>


                                                        <ReportsTableComponent
                                                            url={serverApi.GET_STORE_ISSUE_LIST}
                                                            searchData={this.getIssueListData()}
                                                          //  staticData={this.state.tableList}
                                                            isRefresh={this.state.isRefresh}
                                                            handleSearch={this.handleSeacrh.bind(this)}
                                                            isLoading={this.state.isRefresh}
                                                            onRowClick={this.handleRowclick}
                                                            // onActionClick={this.handleViewClick}
                                                            // itemViewType={this.state.itemViewType}
                                                            // actionType={VIEW}
                                                            // showDelete={false}
                                                            // onDeleteClick={this.onDeleteParamater}
                                                        />

                                                    </ItemGrid>

                                                </form>


                                            }
                                        >
                                        </Popup> : null
                                }
*/}

                                {this.state.updateView? <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Issue No
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                disabled={true}
                                                id="hdrId"
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.hdrId}
                                                fullWidth={true}
                                                variant="outlined"
/*
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getIssueList}
                                                                />
                                                                </span>
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: 'off'
                                                }}
*/

                                            />

                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>:null}
                             {/*   <ItemGrid xs={6}>
                                    <Button color="primary" onClick={this.handleViewClick}>Search</Button>
                                </ItemGrid>
*/}

                                <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Issue Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <FormControl variant="" fullWidth>
                                                <Datetime
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    onChange={(dateObj) => {
                                                        this.timeChanged(dateObj, "date1")
                                                    }}
                                                    height={"15px"}
                                                    formControlProps={
                                                        {height: "15px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id: "date1",
                                                        value: this.state.date1,
                                                        autoComplete: 'off',
                                                        disabled:this.state.updateView
                                                    }}

                                                />
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>

                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Expenses Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8} >
                                            <SelectboxTemp id={"indentTypeId"}
                                                 selectDisabled={this.state.updateView}
                                                           defValue={this.state.indentTypeId}
                                                           onSelect={this.onSelect}
                                                           stateKey="indentTypeId"
                                                           isReload={this.state.loadType}
                                                           setIsReload={(val)=>{
                                                               this.setState({loadType:false})
                                                           }}
                                                           getName={(name)=>{
                                                               var typeId=name.split("_");
                                                               typeId=typeId[1];
                                                               this.setState({typeId:typeId})
                                                           }}

                                                           url={serverApi.GET_STORE_INDENT_TYPES}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                {labelType==="2"?"Project":"Branch"}<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                selectDisabled={this.state.updateView}
                                                defValue={this.state.branchId}
                                                onSelect={this.onSelect}
                                                //  getName={this.getQualityName}
                                                stateKey="branchId"
                                                 isRefresh={this.state.loadBranch}
                                                 setIsRefresh={(val)=>{
                                                     this.setState({loadBranch:false})
                                                 }}
                                                url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                value={this.state.branchId}
                                            />

                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>
                                {/*{this.state.customerList.length>0?*/}
                                {labelType!=="2"? <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Customer Name
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                selectDisabled={this.state.updateView}
                                                defValue={this.state.customerId}
                                                id={"customerId"}
                                                onSelect={this.onSelect}
                                                stateKey="customerId"
                                                // staticData={this.state.customerList}
                                                isRefresh={this.state.loadCustomer}
                                                setIsRefresh={(val)=>{
                                                    this.setState({loadCustomer:false})
                                                }}
                                                url={serverApi.GET_CUSTOMER_BY_BRANCH+getCookie(cons.COMPANY_ID)+"/"+this.state.branchId}
                                                // value={this.state.customerName}
                                            />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>:null}
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                {labelType==="2"?"Phase":"Project"}<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                selectDisabled={this.state.updateView}
                                                defValue={this.state.projectId}
                                                url={serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/"+this.state.customerId+"/branch/"+this.state.branchId}

                                                //  staticData={this.state.ProjectOptions}
                                                onSelect={this.onSelect}
                                                stateKey="projectId"
                                                value={this.state.projectId}
                                                isRefresh={this.state.loadProject}
                                                setIsRefresh={(val)=>{
                                                    this.setState({loadProject:false})
                                                }}
                                            />

                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Internal Note
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="internalNote"
                                                fullWidth
                                                multiline
                                                rowsMax="3"
                                                height={60}
                                                value={this.state.internalNote}
                                                onChange={this.onChangeValues}
                                                className={classes.textField}
                                            />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>



                                <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                    <GridContainer justify="center">
                                        <ItemGrid xs={12}>
                                            {/* {console.log(this.state.invoiceList.length+" length")} this.state.issueStatus*/}
                                            <LineItemTable
                                                data={this.getDataTable()}
                                                loading={this.state.loading}
                                                disableActions={this.state.issueStatus==='3'||this.state.issueStatus==='4'||this.state.issueStatus==='5'||this.state.issueStatus==='6'}
                                                columns={[
                                                    {
                                                        Header:"Item Code",
                                                        accessor:"itemGrp",
                                                        minWidth: 70,
                                                        style: {color: "#000", textAlign: "left"},
                                                    },
                                                    {
                                                        Header: "Department",
                                                        accessor: "department",
                                                        minWidth: 100,
                                                        style: {color: "#000", textAlign: "left"},


                                                    },
                                                    {
                                                        Header: "Item Group",
                                                        accessor: "itemGroup",
                                                        minWidth: 150,
                                                        style: {
                                                            color: "#000",
                                                            textAlign: "left",
                                                            textOverflow: 'unset'
                                                        }
                                                    },
                                                    {
                                                        Header: "Item",
                                                        accessor: "item",
                                                        minWidth: 150,
                                                        style: {
                                                            color: "#000",
                                                            textAlign: "left",
                                                            textOverflow: 'unset'
                                                        }
                                                    },
                                                    {
                                                        Header: "Machine No",
                                                        accessor: "machineName",
                                                        minWidth: 50,
                                                        style: {
                                                            color: "#000",
                                                            textAlign: "left",
                                                            textOverflow: 'unset'
                                                        }
                                                    },
                                                    {
                                                        Header: "Quantity",
                                                        accessor: "quantity",
                                                        minWidth: 100,
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    /*  {
                                                          Header: "Quantity",
                                                          accessor: "quantity",
                                                          minWidth:100,
11211212200000000000038..
                                                          .
                                                          .5
                                                          ..5.style: {color: "#000", textAlign: "left"}
1                                                          },*/
                                                    {
                                                        Header: "Unit",
                                                        accessor: "uom",
                                                        minWidth: 50,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Issue Value",
                                                        accessor: "issueValue",
                                                        minWidth: 100,
                                                        style: {color: "#000", textAlign: "right"}
                                                    },
                                                    {
                                                        Header: "Sr No",
                                                        accessor: "srPrintNo",
                                                        minWidth: 100,
                                                        style: {color: "#000", textAlign: "right"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                            />



                                        </ItemGrid>

                                        <ItemGrid xs={12}>
                                            <Button color={"info"} right={true} onClick={this.handleAddItem}>Add
                                                Item</Button>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>






                                {/*   <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"selectedItemGroup"}
                                                    onSelect={this.onSelect}
                                                    getName={this.getItemGroupName}
                                                    isRefresh={this.state.loadItemGroup}
                                                    stateKey="selectedItemGroup"
                                                    setIsRefresh={this.afterRefreshItemGrp}
                                                    url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedDepartment+"/getAllItemGroupsByDepartmentId"}
                                                    value={this.state.selectedItemGroup}
                                                />


                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <SelectboxTemp
                                                            id={"selectedItem"}
                                                            onSelect={this.onSelect}
                                                            getName={this.getItemDetailsByName}
                                                            isRefresh={this.state.loadItem}
                                                            stateKey="selectedItem"
                                                            setIsRefresh={this.afterRefreshItem}
                                                            url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup}
                                                            value={this.state.selectedItem}
                                                        />


                                                    </ItemGrid>

                                                    <ItemGrid xs={12} sm={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    id={"selectedItem"}
                                                                    onSelect={this.onSelect}
                                                                    getName={this.getItemDetailsByName}
                                                                    isRefresh={this.state.loadItem}
                                                                    stateKey="selectedItem"
                                                                    setIsRefresh={this.afterRefreshItem}
                                                                    url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup}
                                                                    value={this.state.selectedItem}
                                                                />


                                                            </ItemGrid>


                                                        </GridContainer>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Quality<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    id={"selectedItem"}
                                                                    onSelect={this.onSelect}
                                                                    getName={this.getItemDetailsByName}
                                                                    isRefresh={this.state.loadItem}
                                                                    stateKey="selectedItem"
                                                                    setIsRefresh={this.afterRefreshItem}
                                                                    url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup}
                                                                    value={this.state.selectedItem}
                                                                />


                                                            </ItemGrid>


                                                        </GridContainer>
                                                    </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px",marginBottom: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Cost center<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp id={"selectedJuteType"}
                                                                    selectDisabled={this.state.updateView}
                                                                    defValue={this.state.selectedJuteType}
                                                                    onSelect={this.onSelect}
                                                    // isChangeValue={this.state.canChangeMukam}
                                                                    stateKey="selectedJuteType"
                                                                    url={serverApi.SELECT_ALL_JUTE_TYPE}
                                                                    value={this.state.selectedJuteType}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px",marginBottom: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Process Type<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp id={"selectedJuteType"}
                                                                    selectDisabled={this.state.updateView}
                                                                    defValue={this.state.selectedJuteType}
                                                                    onSelect={this.onSelect}
                                                    // isChangeValue={this.state.canChangeMukam}
                                                                    stateKey="selectedJuteType"
                                                                    url={serverApi.SELECT_ALL_JUTE_TYPE}
                                                                    value={this.state.selectedJuteType}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Stock
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="stock"
                                                    value={this.state.stock}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>*/}

                                {this.state.updateView ?
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Requested By
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="createdBy"
                                                    value={this.state.createdBy}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> : null}
                                {this.state.updateView ?
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Requested On
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="createdOn"
                                                    value={this.state.createdOn}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> : null}
                                <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                    <GridContainer justify="center">

                                    </GridContainer>
                                </ItemGrid>

                                {!this.state.updateView ?
                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid>
                                                <Button color="primary"
                                                        onClick={this.onCreateButtonClick}>Create</Button>

                                            </ItemGrid>
                                            <ItemGrid>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid> : null
                                }

                                <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                    <GridContainer justify="center">
                                        {this.state.approveStatus ?  <ItemGrid>
                                            <Button color="primary"
                                                    onClick={()=>{
                                                        this.setState({isApprove:true})
                                                        this.InvoicebasicAlert("Do You Like To Approve..!!");
                                                       }}>Approve</Button>
                                        </ItemGrid>: null
                                        }
                                        {this.state.approveStatus ? <ItemGrid>
                                            <Button color="danger"
                                                    onClick={()=>{
                                                        this.setState({isReject:true})
                                                        this.InvoicebasicAlert("Do You Like To Reject..!!");
                                                    }}>Reject</Button>
                                        </ItemGrid>: null
                                        }
                                        {this.state.updateView && this.state.showUpdate?

                                            <ItemGrid>
                                                {this.state.issueStatus==="29"?<Button color="primary"
                                                        onClick={()=>{
                                                            this.setState({isAccept:true})
                                                            this.InvoicebasicAlert("Do You Like To Accept..!!");
                                                          }}>Accept</Button>:
                                                    <Button color="primary"
                                                            onClick={()=>{this.onApproveButtonClick("")}}>Update</Button>}
                                            </ItemGrid>

                                            : null
                                        }

                                        {this.state.updateView? <ItemGrid>
                                            <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                        </ItemGrid>:null}

                                    </GridContainer>

                                </ItemGrid>
                                {this.state.updateView?

                                    <ItemGrid xs={12} style={{marginTop:"15px"}}>
                                        <SamplePDF
                                            invoiceType={16} printResp={this.state.printResp}/>
                                    </ItemGrid>
                                    :null}
                            </GridContainer>
                        }/>

                </ItemGrid>:

                    <ItemGrid xs={12}>

                        <RegularCard
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                                {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <GridContainer>

                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Issue No
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="IssueNos"
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                value={this.state.IssueNos}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    autoComplete: 'off'
                                                                }}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                            <InputLabel className={classes.label}>
                                                                From Date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDates")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id:"fromDates",
                                                                        value:this.state.fromDates,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                            <InputLabel className={classes.label}>
                                                                To Date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "toDates")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id:"toDates",
                                                                        value:this.state.toDates,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Status
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.statuss}
                                                                id={"statuss"}
                                                                onSelect={this.onSelect}
                                                                stateKey="statuss"
                                                                url={serverApi.SEARCH_STORE_STATUS}
                                                                value={this.state.statuss}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Project":"Branch"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.branchIds}
                                                                onSelect={this.onSelect}
                                                                //  getName={this.getQualityName}
                                                                stateKey="branchIds"
                                                                // isRefresh={this.state.loadQuality}
                                                                // setIsRefresh={this.afterRefreshQuality}
                                                                url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                                value={this.state.branchIds}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>
                                                {labelType!=="2"?  <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Customer Name
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                id={"customerIds"}
                                                                onSelect={this.onSelect}
                                                                stateKey="customerIds"
                                                                // staticData={this.state.customerList}
                                                                isRefresh={this.state.loadCustomer}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadCustomer:false})
                                                                }}
                                                                url={serverApi.GET_CUSTOMER_BY_BRANCH+getCookie(cons.COMPANY_ID)+"/"+this.state.branchIds}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>:null}

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Phase":"Project"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.projectIds}
                                                                url={serverApi.PROJECT_MASTER_SELECTION_LIST+getCookie(cons.COMPANY_ID)+"/customer/"+this.state.customerIds+"/branch/"+this.state.branchIds}

                                                                //staticData={this.state.ProjectOptions}
                                                                onSelect={this.onSelect}
                                                                stateKey="projectIds"
                                                                value={this.state.projectIds}
                                                                isRefresh={this.state.loadItem}
                                                                setIsRefresh={this.afterRefreshItem}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Expenses Type
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8} >
                                                            <SelectboxTemp id={"indentTypeIds"}
                                                                // selectDisabled={this.state.updateView}
                                                                           defValue={this.state.indentTypeIds}
                                                                           onSelect={this.onSelect}
                                                                           stateKey="indentTypeIds"
                                                                           isReload={this.state.loadType}
                                                                           setIsReload={(val)=>{
                                                                               this.setState({loadType:false})
                                                                           }}
                                                                           url={serverApi.GET_STORE_INDENT_TYPES}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
                                                <ItemGrid><br/></ItemGrid>
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            <TableComponent
                                                url={serverApi.GET_STORE_ISSUE_LIST}
                                                searchData={this.getIssueListData()}
                                                //  staticData={this.state.tableList}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                              //  onRowClick={this.handleRowclick}
                                                 onActionClick={this.handleViewClick}
                                                 itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                // showDelete={false}
                                                // onDeleteClick={this.onDeleteParamater}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>}


            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    cssFocused: {},
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#00ADBC',
        },
        '&:after': {
            borderColor: '#D2D2D2',
        },

    },

    notchedOutline: {},
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default  withStyles(extendedFormsStyle)(StoreIssue);
