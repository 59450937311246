import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxComponent from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";

import withStyles from "material-ui/styles/withStyles";

import TextField from "../../components/CustomInput/CustomTextField";

import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import {
  configNames,
  getConfigValue,
  getDecimal,
  serverApi,
  serverConfig,
} from "../../helper/Consts";
import { VIEW } from "../../ui-actions/UiActions";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
  uploadFormdataWithImage,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import InputAdornment from "material-ui/Input/InputAdornment";
import moment from "moment";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import {
  dangerColor,
  roseColor,
} from "../../assets/jss/material-dashboard-pro-react";
// import HardwareKeyboardReturn from "material-ui/svg-icons/hardware/keyboard-return";

class JutePO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      basicAlert: null,
      isRefresh: false,
      isLoading: false,
      approveStatus: false,
      showMessagealert: false,
      fromDate: "",
      todate: "",
      status: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      supplier_Id:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      poNo: "",
      hdrId: "",
      statusDesc: "",
      form_purchaseType: "",
      itemViewType: "poNo",
      taxType: "",
      SelectindentType: this.getDefaultindentConvertion(2),
      selectedItem: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      itemtype: "",
      itemTaxAmt: 0,
      igstPercent: 0,
      cgstPercent: 0,
      sgstPercent: 0,
      totalTax: 0,
      sumOftax: 0,
      totalAmount: 0,
      PoDate: moment().format("DD-MM-YYYY"),
      expecDate:
        getCookie(cons.COMPANY_ID) === "116"
          ? moment()
              .add("days", 10)
              .format("DD-MM-YYYY")
          : moment()
              .add("days", 15)
              .format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      createPO: "",
      date: "",
      poQuantity: "",
      creditTerm: 60,
      deliveryTimeLine: getCookie(cons.COMPANY_ID) === "116" ? 10 : 15,
      expectedDate: "",
      vehicleQuantiy: "",
      selectedSupplier: "",
      selectedJuteType: "",
      selectedIndent: "",
      netTotal: "",
      termConditions: "",
      selectedJutePO: "",
      POQuantity: "",
      selectedTax: "",
      alert: false,
      loadsupCode: "",
      indentAlert: false,
      allowMositure: 18,
      selectedIndentQuantity: "",
      indentNum: "",
      sumOfAmount: 0,
      sumOfweight: 0,
      depId: "",
      remarks: "",
      make: "",
      footerNote: "",
      delAddress:
        getCookie(cons.SHIPPING_ADDRESS) === null
          ? ""
          : getCookie(cons.SHIPPING_ADDRESS),
      invoiceList: [],
      item: "",
      itemId: "",
      lineItemId: "",
      indentLineId: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      deducAmount: 0,
      duductaxAmount: 0,
      poStatus: "",
      taxId: "",
      branchId: "",
      isMail: false,
      lastPurchaseRate: "",
      poPurchasRate: "",
      lastPurchaseDate: "",
      projectIds: "",
      branchIds: "",
      internalNote: "",
      indentlist: null,
      pendingStock: 0,
      minStock: 0,
      maxStock: 0,
      billingBranchId: "",
      rqName: "",
      poValidDate: "",
      supplierList: [],
      indentType: "",
      filesList: [],
      typeId: 0,
      dataTable: null,
      addPendQty: true,
      latestPrice: "",
      latestPriceDate: "",
      indentHdrId: "",
      approvedIndents: [],
      serviceUrl: "",
      tenantId: "",
      indentTypeId: "",
      projectId: "",
      date1: moment().format("DD-MM-YYYY"),
      srcCompanyId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.BRANCHMASTER_DATATABLE, data, this.handelbranch);
    if (getCookie(cons.USER_ROLE_ID) !== "100")
      getDataFromUrl(
        serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID),
        this.handleSupplier
      );
    this.getList();
  }
  getList = () => {
    var data = this.getData();
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "getAllPOList",
      data,
      this.handleList
    );
  };
  handleList = (resp) => {
    var list = resp;
    var data = list.data;
    if (getCookie(cons.USER_ROLE_ID) === "100") {
      data = [];
      resp.data.forEach((prop) => {
        if (
          prop.status === "APPROVED" ||
          prop.status === "CANCELLED" ||
          prop.status === "REJECTED"
        )
          data.push(prop);
      });
      list.data = data;
    }
    if (resp) {
      this.setState({
        dataTable: list,
        isRefresh: true,
      });
    }
  };
  handleSupplier = (resp) => {
    if (resp.status === undefined) {
      this.setState({ supplierList: resp, loadSupplier: true });
    }
  };
  handelbranch = (resp) => {
    console.log("respppppppp", resp);

    var branchArray = [];
    resp.data.map((p, i) => {
      var obj = {
        value: p.id,
        name: p.brname + "^" + p.address,
        label: p.brname,
        address: p.address,
        contactPerson: p.personName,
        contactNo: p.contactNo,
        gstNo: p.gstNo,
        email: p.email,
      };
      branchArray.push(obj);
    });

    this.setState({ branchOptions: branchArray });
  };
  getBranchDetailsFromId = (branchId) => {
    var val = null;
    this.state.branchOptions.forEach((prop) => {
      if (prop.value === branchId) {
        val = prop;
      }
    });
    return val;
  };
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };

  handleCreate = () => {
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  dateChanged = (dateObj, key) => {
    if (typeof dateObj === "object") {
      this.setState({
        [key]: dateObj.format("DD-MM-YYYY"),
        expecDate:
          getCookie(cons.COMPANY_ID) === "116"
            ? moment(dateObj.format("DD-MM-YYYY"), "DD-MM-YYYY")
                .add(10, "days")
                .format("DD-MM-YYYY")
            : moment(dateObj.format("DD-MM-YYYY"), "DD-MM-YYYY")
                .add(15, "days")
                .format("DD-MM-YYYY"),
      });
    }
  };
  dateChanged2 = () => {
    var myDate = moment().format("DD-MM-YYYY");
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  getDefaultTaxConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === 1) {
        val = 1;
        label = "IGST";
      } else if (name === 2) {
        val = 2;
        label = "CGST & SGST";
      } else if (name === 3) {
        val = 3;
        label = "VAT";
      } else {
        val = 4;
        label = "NO TAX";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getDefaultindentConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === 1) {
        val = 1;
        label = "With Indent";
      } else {
        val = 2;
        label = "Without Indent";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getData = () => {
    var data = {
      purchaseType: "store",
      frmDt: this.state.fromDate,
      toDt: this.state.todate,
      statusId: this.state.status,
      supplierId: this.state.supplierId,
      poNum: this.state.searchpoNo,
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      erpType: getConfigValue(configNames.LABELS_TYPE),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  onChangeValues = (event) => {
    var value = event.target.value;
    const { itemStock, pendingStock } = this.state;

    if (event.target.id === "quantity" && value !== "" && itemStock > 0) {
      if (
        parseFloat(value) >
        parseFloat(itemStock) + parseFloat(pendingStock)
      ) {
        this.basicAlert("Item cannot be placed , Stock is already pending");
      } else {
        if (
          this.state.SelectindentType.value === 1 &&
          this.state.updateItem &&
          this.state.indentType !== "O"
        ) {
          if (this.state.stRemQty >= parseFloat(value))
            this.setState({
              [event.target.id]: value,
            });
          else {
            this.basicAlert(
              "Quantity should not be greater than Indent quantity " +
                this.state.stRemQty
            );
          }
        } else {
          this.setState({
            [event.target.id]: value,
          });
        }
      }
    } else {
      if (event.target.id === "quantity" && !this.state.printingPop) {
        if (this.state.SelectindentType.value !== 1)
          this.setState({
            [event.target.id]: value,
          });
        else {
          if (this.state.indentType !== "O") {
            if (this.state.stRemQty >= parseFloat(value))
              this.setState({
                [event.target.id]: value,
              });
            else {
              this.basicAlert(
                "Quantity should not be greater than Indent quantity " +
                  this.state.stRemQty
              );
            }
          } else
            this.setState({
              [event.target.id]: value,
            });
        }
      } else {
        this.setState({
          [event.target.id]: value,
        });
      }
    }

    if (event.target.id === "deliveryTimeLine") {
      var value = event.target.value;
      var podate = this.state.PoDate;
      this.setState({
        expecDate: moment(podate, "DD-MM-YYYY")
          .add(value, "days")
          .format("DD-MM-YYYY"),
      });
    }
    if (event.target.id === "quantity" || event.target.id === "rate") {
      var value = event.target.value;
      if (event.target.id === "rate") {
        var Calrate = this.state.quantity;
      } else {
        var Calrate = this.state.rate;
      }
      if (Calrate !== "" && value != "") {
        var calAmount = parseFloat(Calrate) * parseFloat(value);
        calAmount = calAmount.toFixed(2);
        this.setState({
          amount: calAmount,
          itemTaxAmt:
            this.state.taxPercent > 0
              ? (calAmount * this.state.taxPercent) / 100
              : this.state.itemTaxAmt,
        });
      } else {
        this.setState({
          amount: "",
        });
      }
    }
  };
  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    if (selectedValue !== null) {
      var values = selectedValue;
      values = values.split("^");
      var amout = 0;
      var rate = values[3] !== "null" ? values[3] : 0;
      if (values[10] !== "0" && values[10] !== undefined) {
        rate = values[10];
      }
      if (this.state.quantity !== "")
        amout = (parseFloat(rate) * parseFloat(this.state.quantity)).toFixed(2);

      this.setState({
        itemDesc: values[0],
        // selectedItemId:values[1],
        stock: values[2] !== "null" ? values[2] : 0,
        uom: values[1],
        rate:
          this.state.itemDesc === values[0] && this.state.updateItem
            ? this.state.rate
            : rate,
        hsnCode: values[4] !== "null" ? values[4] : "",
        taxId:
          this.state.itemDesc === values[0] && this.state.updateItem
            ? this.state.taxId
            : values[5],
        amount:
          this.state.itemDesc === values[0] && this.state.updateItem
            ? this.state.amount
            : amout,
        itemStock:
          values[6] !== undefined && values[6] !== null ? values[6] : 0,
        minStock: values[7] !== undefined && values[7] !== null ? values[7] : 0,
      });
      console.log("taxis", this.state.taxId);
      if (this.state.itemDesc !== values[0] && !this.state.updateItem) {
        this.setState({
          loadTax: true,
        });
      }
    }
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    /*   var loading = false;
           if (this.state.isRefresh) {
               loading = true
           }*/
    //  this.handleLoading();
    if (!this.state.isRefresh) {
      this.getList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
      });
    }
    if (stateKey === "selectedItem") {
      if (!this.state.updateItem)
        getDataFromUrl(
          serverApi.GET_LAST_PURCHASE_RATE +
            this.state.selectedItemGroup +
            "/" +
            selectedValue +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleRate
        );
      var data = {
        itemGrpId: this.state.selectedItemGroup,
        itemId: selectedValue,
        companyId: getCookie(cons.COMPANY_ID),
        branchId: this.state.branchId,
      };
      requestList(serverApi.GET_BALANCE_STOCK_LIST, data, this.handleStock);
      requestList(serverApi.GET_BRANCH_WISE_STOCK, data, this.handleBrachStock);
      var data1 = {
        itemId: selectedValue,
        companyId: getCookie(cons.COMPANY_ID),
      };
      requestList(serverApi.PENDING_STOCK, data1, this.handlePendingStock);
      getDataFromUrl(
        serverApi.GET_SUPP_ITEM_RATE +
          this.state.supplier_Id +
          "/" +
          selectedValue +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleItemRate
      );
      getDataFromUrl(
        serverApi.GET_PENDING_RCPT__QUANTITY +
          this.state.selectedItemGroup +
          "/" +
          selectedValue +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handlePendingQuantity
      );
    }
    if (stateKey === "branchId" || stateKey === "branchIds") {
      this.setState({ loadProject: true });
      if (!this.state.updateView) {
        this.setState({ loadIndent: true });
      }
    }
    if (stateKey === "selectedItemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: getCookie(cons.COMPANY_ID),
        indentTypeId: this.state.typeId,
      };

      requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
      // this.setState({
      //     loadItem: true
      // })
    }
    if (
      stateKey === "selectedIndent" &&
      this.state.selectedIndent !== selectedValue
    ) {
      this.setState({
        reloadSelect: true,
      });
    }
    if (stateKey === "itemGroup" && !this.state.updateView) {
      getDataFromUrl(
        serverApi.APPROVED_INDENTS +
          "s/" +
          getCookie(cons.COMPANY_ID) +
          "/" +
          getCookie(cons.ACADEMIC_YEAR) +
          "/" +
          this.state.branchId +
          "/" +
          selectedValue,
        this.handleApprovedIndents
      );

      // this.setState({loadIndent:true})
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handlePendingQuantity = (resp) => {
    if (resp.data !== null) {
      this.setState({
        pendingAtRcpt: resp.data,
      });
    }
  };
  handleItemRate = (resp) => {
    if (resp.data) {
      this.setState({
        poPurchasRate: resp.data.poPurchasRate,
        latestPrice: resp.data.latestPrice,
        latestPriceDate: resp.data.latestPriceDate,
      });
    }
  };
  handlePendingStock = (resp) => {
    if (resp.data > 0) this.setState({ pendingStock: resp.data });
  };

  handleStock = (resp) => {
    this.setState({ stock: resp.data });
  };
  handleBrachStock = (resp) => {
    this.setState({ branchStock: resp.data });
  };
  handleRate = (resp) => {
    var data = "",
      date = "";
    if (resp.data !== null) {
      data = resp.data.poPurchasRate;
      date = resp.data.poPurchaseDate;
      console.log(`po rate-----
      ${resp.data.poPurchasRate}`);
    }

    this.setState({ lastPurchaseRate: data, lastPurchaseDate: date });
  };
  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };
  afterRefreshTax = (val) => {
    this.setState({
      loadTax: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
    });
  };
  handleSelectedUnitValue = (defValue) => {
    /* if (this.state.invoiceList.length > 0 && this.state.taxType !== defValue && this.state.changeTaxType) {
             this.unitConvertionAlert("Changing of Tax Type will remove all the line items. Click yes if you want to continue..!!")
         } else{*/
    // if(this.state.SelectindentType.value===1&& !this.state.updateView){
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    data.map((prop, i) => {
      var SlectedTaxType = defValue.value;
      if (SlectedTaxType === 1 || SlectedTaxType === 3) {
        prop.isgst = prop.taxPercent;
        prop.ssgst = 0;
        prop.csgst = 0;
      } else {
        var taxPercent = prop.taxPercent / 2;
        prop.isgst = 0;
        prop.ssgst = taxPercent;
        prop.csgst = taxPercent;
      }
      /*  var itemAmount =parseFloat(prop.amount);

              var itemtaxAmount =itemAmount * parseFloat(prop.taxPercent) / 100;
              itemtaxAmount = Math.round(itemtaxAmount);
              prop.taxamount=itemtaxAmount;
              sum_of_tax=sum_of_tax+itemtaxAmount;
              sumOfamt=sumOfamt+itemAmount*/
    });
    this.setState({
      invoiceList: data,
      /*  totalTax: sum_of_tax,
              totalAmount: sumOfamt.toFixed(2),
              netTotal: parseFloat(sumOfamt+ sum_of_tax).toFixed(2),*/
    });
    //alert(SlectedTaxType);
    //  }
    this.setState({
      //changeTaxType:false,
      //unitConversion: defValue,
      taxType: defValue,
    });

    //  }
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = values[1];

    var itemAmount = this.state.amount;
    if (taxPercent !== "" && itemAmount !== "") {
      var itemtaxAmount =
        (parseFloat(itemAmount) * parseFloat(taxPercent)) / 100;
      //  itemtaxAmount = Math.round(itemtaxAmount);
      this.setState({
        itemTaxAmt: itemtaxAmount,
        taxPercent: taxPercent,
      });
    }
    var SlectedTaxType = this.state.taxType.value;
    //alert(SlectedTaxType);
    if (SlectedTaxType === 1 || SlectedTaxType === 3) {
      this.setState({
        igstPercent: taxPercent,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    } else {
      taxPercent = taxPercent / 2;

      this.setState({
        igstPercent: 0,
        sgstPercent: taxPercent,
        cgstPercent: taxPercent,
      });
    }
  };
  handleSelectedValues = (selectedValue, stateKey) => {
    //const {classes}=this.props;

    this.setState({
      SelectindentType: selectedValue,
    });
    // const {selectedJuteType}=this.state;
    if (selectedValue.value === 1) {
      this.setState({
        loading: true,
        indentAlert: true,
      });
    } else {
      this.setState({
        indentNo: "",
        indentQuantity: "",
        indentQuantityTon: "",
        indentBales: "",
        createdBy: "",
        createdOn: "",
        quantity: "",
        stock: "",
        // itemGroupId: '',
        selectedTax: "",
        invoiceList: [],
        selectedJuteType: "",
        selectedJuteName: "",
        selectedIndentQuantity: "",
        sumOFQuantities: 0,
        selectedDeptId: "",
        selectedIndent: "",
        selectedGrpId: "",
        selectedUnit: "",
        totalTax: 0,
        totalAmount: 0,
        netTotal: 0,
        indentlist: null,
      });
    }

    //console.log(`Option selected:`, defValue);
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  // Create PO
  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          indentLineId: prop.indentLineId,
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: "",
          quantity: prop.quantity,
          unitId: prop.unit,
          rate: parseFloat(prop.rate),
          allowableMoisturePercentage: "",
          cTax: prop.csgst,
          sTax: prop.ssgst,
          iTax: prop.isgst,
          bale: "",
          loose: "",
          amount: prop.amount,
          marka: "",
          deptId: prop.depId,
          valueWithoutTax: prop.amount,
          valueWithTax: (
            parseFloat(prop.amount) + parseFloat(prop.taxamount)
          ).toFixed(2),
          tax: prop.taxamount,
          indentId: prop.indentLineId,
          actualQuantity: prop.quantity,
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: prop.taxid,
          taxPercentage: parseFloat(prop.taxPercent),
          lastPurchaseRate:
            prop.lastPurchaseRate !== 0 && prop.lastPurchaseRate !== null
              ? prop.lastPurchaseRate
              : prop.rate,
          lastPurchaseDate:
            prop.lastPurchaseDate !== "" && prop.lastPurchaseDate !== null
              ? prop.lastPurchaseDate
              : this.state.PoDate,
          billingBranchId: this.state.billingBranchId,
          rqName: this.state.rqName,
          poValidDate: this.state.poValidDate,
          pendingAtRcpt: prop.pendingAtRcpt,
          description: prop.description,
          make: prop.make,
        };
      });

      var data = {
        poHeader: {
          type: this.state.form_purchaseType,
          poDate: this.state.PoDate,
          creditTerm: this.state.creditTerm,
          deliveryTimeline: this.state.deliveryTimeLine,
          vehicleTypeId: "",
          vehicleQuantity: "",
          supplierId: this.state.supplier_Id,
          branchId: this.state.branchId,
          projectId: this.state.projectId,
          mukam: "",
          brokerId: "",
          frieghtCharge: this.state.frieghtCharge,
          remarks: this.state.remarks,
          juteUnit: "",
          exceptedDate: this.state.expecDate,
          cTax: this.state.taxType.value,
          sTax: this.state.taxType.value,
          iTax: this.state.taxType.value,
          taxType: this.state.taxType.value,
          valueWithoutTax: this.state.totalAmount,
          submitter: getCookie(cons.USERID),
          indentNum: this.state.indentNo,
          finnacialYear: this.state.year,
          footerNote: this.state.footerNote,
          valueWithTax: this.state.netTotal,
          tax: this.state.totalTax,
          deliveryAddress: this.state.delAddress,
          discount: "",
          weight: 0,
          brokerName: "",
          withOrWithout: this.state.SelectindentType.value,
          companyId: getCookie(cons.COMPANY_ID),
          internalNote: this.state.internalNote,
          createdBy: getCookie(cons.USERID),
          billingBranchId: this.state.billingBranchId,
          rqName: this.state.rqName,
          poValidDate: this.state.poValidDate,
        },
        poLineItem: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      if (
        this.state.invoiceList.length >= 1 &&
        this.state.supplier_Id !== "" &&
        this.state.supplier_Id !== 0 &&
        this.state.taxType !== "" &&
        this.state.taxType !== 0 &&
        this.state.form_purchaseType !== "" &&
        this.state.totalTax !== "" &&
        this.state.totalAmount > 0 &&
        this.state.branchId !== "" &&
        this.state.branchId !== 0 &&
        this.state.projectId !== "" &&
        this.state.projectId !== 0 &&
        this.state.billingBranchId !== "" &&
        this.state.billingBranchId !== 0
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_JUTE_PO, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  handleAddIndent = (resp) => {
    console.log(`printing response ${JSON.stringify(resp)}`);
    if (resp.status) {
      if (
        this.state.finalApprover &&
        this.state.poStatus !== "3" &&
        !this.state.isUpdate
      ) {
        this.setState({
          sendMail: true,
          finalApprovalMsg: resp.message,
        });

        this.printDocument();
      } /* else {
                this.createShowMessage(resp.message, resp.status)
            }*/
      this.createShowMessage(resp.message, resp.status);

      console.log("resp " + JSON.stringify(resp));
      //  this.createShowMessage(resp.message, resp.status)
    } else {
      this.createShowMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //update PO (approve)

  onApproveButtonClick = (status) => {
    console.log(`printing status${status}`);

    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          indentLineId: prop.indentLineId,
          id: prop.lineItemId,
          poId: this.state.poNo,
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: "",
          quantity: prop.quantity,
          unitId: prop.unit,
          rate: parseFloat(prop.rate),
          allowableMoisturePercentage: "",
          cTax: prop.csgst,
          sTax: prop.ssgst,
          iTax: prop.isgst,
          bale: "",
          loose: "",
          amount: prop.amount,
          marka: "",
          deptId: prop.depId,
          valueWithoutTax: prop.amount,
          valueWithTax: (
            parseFloat(prop.amount) + parseFloat(prop.taxamount)
          ).toFixed(2),
          tax: prop.taxamount,
          indentId: prop.indentLineId,
          actualQuantity: prop.quantity,
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: prop.taxid,
          taxPercentage: parseFloat(prop.taxPercent),
          deleteRec: prop.isActive === 0,
          lastPurchaseRate:
            prop.lastPurchaseRate !== 0 && prop.lastPurchaseRate !== null
              ? prop.lastPurchaseRate
              : prop.rate,
          lastPurchaseDate:
            prop.lastPurchaseDate !== "" && prop.lastPurchaseDate !== null
              ? prop.lastPurchaseDate
              : this.state.PoDate,
          description: prop.description,
          pendingAtRcpt: prop.pendingAtRcpt,
        };
      });

      var data = {
        poHeader: {
          addPendQty: this.state.addPendQty,
          hdrId: this.state.hdrId,
          type: this.state.form_purchaseType,
          poDate: this.state.PoDate,
          creditTerm: this.state.creditTerm,
          deliveryTimeline: this.state.deliveryTimeLine,
          vehicleTypeId: "",
          vehicleQuantity: "",
          supplierId: this.state.supplier_Id,
          branchId: this.state.branchId,
          projectId: this.state.projectId,
          mukam: "",
          brokerId: "",
          frieghtCharge: this.state.frieghtCharge,
          remarks: this.state.remarks,
          juteUnit: "",
          exceptedDate: this.state.expecDate,
          cTax: this.state.taxType.value,
          sTax: this.state.taxType.value,
          iTax: this.state.taxType.value,
          taxType: this.state.taxType.value,
          valueWithoutTax: this.state.totalAmount,
          submitter: getCookie(cons.USERID),
          modBy: getCookie(cons.USERID),
          indentNum: this.state.indentNo,
          finnacialYear: this.state.year,
          footerNote: this.state.footerNote,
          valueWithTax: this.state.netTotal,
          tax: this.state.totalTax,
          deliveryAddress: this.state.delAddress,
          discount: "",
          weight: 0,
          brokerName: "",
          status: status,
          companyId: getCookie(cons.COMPANY_ID),
          internalNote: this.state.internalNote,
          billingBranchId: this.state.billingBranchId,
          rqName: this.state.rqName,
          poValidDate: this.state.poValidDate,
        },
        poLineItem: invoiceList,

        cipher: getCookie(cons.CIPHER),
      };
      console.log(data);
      if (
        this.state.invoiceList.length >= 1 &&
        this.state.branchId !== "" &&
        this.state.branchId !== 0 &&
        this.state.projectId !== "" &&
        this.state.projectId !== 0
      ) {
        this.handleLoading();
        requestList(serverApi.UPDATE_JUTE_PO, data, this.handleAddIndent);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  //update PO (Reject)

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.lineItemId,
        indentLineId: prop.indentLineId,
        poId: this.state.poNo,
        itemId: prop.itemId,
        itemDesc: prop.itemDesc,
        qualityCode: "",
        quantity: prop.quantity,
        unitId: prop.unit,
        rate: prop.rate,
        allowableMoisturePercentage: "",
        cTax: prop.csgst,
        sTax: prop.ssgst,
        iTax: prop.isgst,
        bale: "",
        loose: "",
        amount: prop.amount,
        marka: "",
        deptId: prop.depId,
        valueWithoutTax: prop.amount,
        valueWithTax: parseFloat(prop.amount) + parseFloat(prop.taxamount),
        tax: prop.taxamount,
        indentId: "",
        actualQuantity: "",
        itemGroupId: prop.itemGroupId,
        discount: "",
        taxId: prop.taxid,

        taxPercentage: "",
      };
    });

    var data = {
      poHeader: {
        addPendQty: this.state.addPendQty,
        hdrId: this.state.hdrId,
        type: this.state.form_purchaseType,
        poDate: this.state.PoDate,
        creditTerm: this.state.creditTerm,
        deliveryTimeline: this.state.deliveryTimeLine,
        vehicleTypeId: "",
        vehicleQuantity: "",
        supplierId: this.state.supplier_Id,
        branchId: this.state.branchId,
        mukam: "",
        brokerId: "",
        frieghtCharge: "",
        remarks: this.state.remarks,
        juteUnit: "",
        exceptedDate: this.state.expecDate,
        cTax: this.state.taxType.value,
        sTax: this.state.taxType.value,
        iTax: this.state.taxType.value,
        taxType: this.state.taxType.value,
        valueWithoutTax: this.state.totalAmount,
        submitter: getCookie(cons.USERID),
        indentNum: this.state.indentNo,
        finnacialYear: this.state.year,
        footerNote: this.state.footerNote,
        valueWithTax: this.state.netTotal,
        tax: this.state.totalTax,
        deliveryAddress: this.state.delAddress,
        discount: "",
        weight: 0,
        brokerName: "",
        status: "4",
        internalNote: this.state.internalNote,
      },
      poLineItem: invoiceList,
    };

    this.handleLoading();
    requestList(serverApi.UPDATE_JUTE_PO, data, this.handleAddIndent);
  };

  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  /// view indent code
  handleViewClick = (id) => {
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      //getDataFromUrl(serverApi.GET_PO_DETAILS + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
      var data = {
        poNum: id,
        userId: getCookie(cons.USERID),
        cipher: getCookie(cons.CIPHER),
      };
      requestList(serverApi.GET_PO_DETAILS, data, this.handleViewClickResp);
    }
  };
  handleViewClickResp = (resp) => {
    var billingBranchName = "",
      billingBranchAddress = "",
      billContactNo = "",
      billingContactPerson = "",
      billingGST = "",
      billingEmail = "";
    var shippingBranchName = "",
      shippingBranchAddress = "",
      shippingContactNo = "",
      shippingContactPerson = "",
      shippingGST = "",
      shippingEmail = "";

    var branchData = this.getBranchDetailsFromId(resp.poHeader.billingBranchId);
    var shipData = this.getBranchDetailsFromId(resp.poHeader.branchId);
    if (branchData !== null) {
      billingBranchAddress = branchData.address;
      billContactNo = branchData.contactNo;
      billingContactPerson = branchData.contactPerson;
      billingGST = branchData.gstNo;
      billingBranchName = branchData.label;
      billingEmail = branchData.email;
    }
    if (shipData !== null) {
      shippingBranchAddress = shipData.address;
      shippingContactNo = shipData.contactNo;
      shippingContactPerson = shipData.contactPerson;
      shippingGST = shipData.gstNo;
      shippingBranchName = shipData.label;
      shippingEmail = shipData.email;
    }

    var hdr = {
      ...resp.poHeader,
      billingBranchName: billingBranchName,
      billingBranchAddress: billingBranchAddress,
      billContactNo: billContactNo,
      billingContactPerson: billingContactPerson,
      billingEmail: billingEmail,
      billingGST: billingGST,
      shippingBranchAddress: shippingBranchAddress,
      shippingContactPerson: shippingContactPerson,
      shippingContactNo: shippingContactNo,
      shippingGST: shippingGST,
      shippingBranchName: shippingBranchName,
      shippingEmail: shippingEmail,
    };
    var data1 = resp;
    data1.poHeader = hdr;
    this.setState({ printResp: data1 });
    var header = resp.poHeader;
    var list = resp.poLineItemVo,
      selectedDepartment,
      selectedItemGroup;
    if (list !== null)
      var indentlist = list.map((prop, key) => {
        selectedDepartment = prop.deptId;
        selectedItemGroup = prop.itemGroupId;
        var desc = "";
        if (
          prop.description === "Printing Charges" ||
          prop.description === "Labour Charges" ||
          prop.description === "Transport Charges" ||
          prop.description === "Other Charges" ||
          prop.description === "Delivery Charges" ||
          prop.description === "Packing & Forwarding Charges"
        )
          desc = prop.description;
        return {
          id: key,
          indentLineId: prop.indentId,
          lineItemId: prop.id,
          itemId: prop.itemId,
          itemDesc: desc !== null && desc !== "" ? desc : prop.itemDesc,
          itemGroupId: prop.itemGroupId,
          itemGrp: prop.itemGroupId + "" + prop.itemId,
          quantity: prop.quantity,
          hsnCode: prop.hsnCode,
          unit: prop.unitId,
          rate: prop.rate,
          amount: getDecimal(prop.valueWithoutTax),
          depId: prop.deptId,
          isgst: prop.iTax,
          ssgst: prop.sTax,
          csgst: prop.cTax,
          taxid: prop.taxId,
          taxamount: prop.tax,
          taxPercent: prop.taxPercentage,
          isActive: prop.deleteRec ? 0 : 1,
          lastPurchaseRate: prop.lastPurchaseRate,
          lastPurchaseDate:
            prop.lastPurchaseDate !== null ? prop.lastPurchaseDate : "",
          stRemQty: prop.stRemQty + prop.quantity,
          indentType: header.indentTypeId,
          pendingAtRcpt: prop.pendingAtRcpt,
          description: prop.itemDesc,
        };
      });
    //  console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeUnit: true,
        approveStatus: header.approveButton,
        hdrId: header.hdrId,
        poNo: header.id,
        statusDesc: header.statusName,
        year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateTime,
        PoDate: header.poDate,
        indentNo: header.indentNum,
        indentNum: header.indentNum,
        SelectindentType: this.getDefaultindentConvertion(header.withOrWithout),
        creditTerm: header.creditTerm,
        deliveryTimeLine: header.deliveryTimeline,
        expecDate: header.exceptedDate,
        selectedVehicleType: "",
        // supplierId: header.supplierId,
        supplier_Id: header.supplierId,
        totalTax: header.tax,
        totalAmount: parseFloat(header.valueWithoutTax).toFixed(2),
        netTotal: parseFloat(header.valueWithTax).toFixed(2),
        quantity: "",
        stock: "",
        branchId: header.branchId,
        projectId: header.projectId,
        delAddress: header.deliveryAddress,
        footerNote: header.footerNote,
        frieghtCharge: header.frieghtCharge,
        remarks: header.remarks,
        taxType: this.getDefaultTaxConvertion(header.taxType),
        form_purchaseType: header.type,
        invoiceList: indentlist,
        poStatus: header.status,
        finalApprover: resp.finalApprover,
        billingBranchId: header.billingBranchId,
        rqName: header.rqName,
        poValidDate: header.poValidDateDes,
        billingBranchAddress: billingBranchAddress,
        internalNote: header.internalNote,
        selectedIndent: header.indentNum,
        indentHdrId: header.indentHdrId,
        tenantId: header.tenantId,
        serviceUrl: header.serviceUrl,
        indentTypeId: header.indentTypeId,
        srcCompanyId: header.srcCompanyId,
      });
      if (header.withOrWithout === 1) {
        this.setState({ itemGroup: selectedItemGroup });
        //  getDataFromUrl(serverApi.GET_INDENT_DETAILS_BY_ID + header.indentNum + "/userid/" + getCookie(cons.USERID) + "/company/" + getCookie(cons.COMPANY_ID)+"/year/"+getCookie(cons.ACADEMIC_YEAR), this.handleIndentSelectResp1)
      }
      if (
        getConfigValue(
          configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
        ) === "2"
      ) {
        console.log("INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS", true);
        this.setState({
          disableDept: true,
          selectedDepartment: selectedDepartment,
          selectedItemGroup: selectedItemGroup,
        });
      }
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "14/" +
          header.hdrId +
          "/" +
          getCookie(cons.COMPANY_ID),
        this.handleInvoiceFiles
      );
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };

  onSelectIndent = (selectedIndent, year) => {
    getDataFromUrl(
      serverApi.GET_INDENT_DETAILS_BY_ID +
        "userid/" +
        getCookie(cons.USERID) +
        "/company/" +
        getCookie(cons.COMPANY_ID) +
        "/year/" +
        year +
        "?indentId=" +
        selectedIndent,
      this.handleIndentSelectResp1
    );
  };
  handleIndentSelectResp1 = (resp) => {
    var header = resp.indentHeader;
    var list = resp.indentItems;
    console.log(list);
    var sum_of_tax = 0,
      sumOfamt = 0,
      netTotal = 0,
      indentlist = [],
      key = 0;
    list.map((prop, i) => {
      //  if(prop.itemGroupId===this.state.itemGroup){
      prop = { ...prop, isActive: 0 };
      if (this.state.updateView) {
        this.state.invoiceList.forEach((o) => {
          if (o.itemGroupId === prop.itemGroupId && o.itemId === prop.itemId)
            prop.isActive = 1;
        });
      }
      if (prop.indentType === "O") {
        var qty = prop.remainingQuantity;
        if (qty <= 0) {
          prop.indentQuantity = 0;
        }
        var pushitem = true;
        if (qty <= 0) {
          if (this.state.updateView) {
            this.state.invoiceList.forEach((o) => {
              if (
                o.itemGroupId === prop.itemGroupId &&
                o.itemId === prop.itemId
              )
                pushitem = false;
            });
          }
        }
        if (pushitem) indentlist.push(prop);
      } else {
        if (prop.remainingQuantity > 0) {
          prop.indentQuantity = prop.remainingQuantity;
          indentlist.push(prop);
        }
      }
      // }
    });
    resp.indentItems = indentlist;

    if (header !== null) {
      if (this.state.updateView) {
        if (this.state.indentHdrId === header.hdrId) {
          this.setState({
            //   indentAlert: false,

            indentlist: resp,
          });
          getDataFromUrl(
            serverApi.GET_FILE_BY_SOURCE +
              "10/" +
              header.hdrId +
              "/" +
              getCookie(cons.COMPANY_ID),
            this.handleInvoiceFiles
          );
        }
      } else {
        this.setState({
          //   indentAlert: false,

          indentlist: resp,
        });
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "10/" +
            header.hdrId +
            "/" +
            getCookie(cons.COMPANY_ID),
          this.handleInvoiceFiles
        );
      }
    }
    //this.indenthideAlert();
  };

  handleIndentSelectResp = () => {
    var resp = this.state.indentlist;
    if (resp !== null) {
      var header = resp.indentHeader;
      var list = resp.indentItems;
      console.log(list);
      var sum_of_tax = 0,
        sumOfamt = 0,
        netTotal = 0,
        indentlist = [],
        key = 0;
      var invoiceList = this.state.invoiceList;
      if (this.state.updateView) {
        indentlist = invoiceList;
      }
      list.map((prop, i) => {
        if (prop.isActive === 1) {
          var itemAmount = parseFloat(prop.indentQuantity * prop.rate);
          var itemtaxAmount =
            (itemAmount * parseFloat(prop.taxPercentage)) / 100; //11 is tax percent
          // itemtaxAmount = Math.round(itemtaxAmount);
          // prop.taxamount = itemtaxAmount;
          sum_of_tax = sum_of_tax + itemtaxAmount;
          sumOfamt = sumOfamt + itemAmount;
          var SlectedTaxType = this.state.taxType;
          if (SlectedTaxType !== "") {
            if (SlectedTaxType.value === 1) {
              prop.iTax = prop.taxPercentage;
              prop.sTax = 0;
              prop.cTax = 0;
            } else if (SlectedTaxType.value === 2) {
              var taxPercent = prop.taxPercentage / 2;
              prop.iTax = 0;
              prop.sTax = taxPercent;
              prop.cTax = taxPercent;
            }
          }

          if (prop.indentType === "O") {
            if (i > 0) {
              key = key + 1;
            }
            var obj = {
              lineItemId: 0,
              indentLineId: prop.indentLineItemId,
              id: key,
              itemId: prop.itemId,
              itemDesc: prop.itemIdDesc,
              itemGroupId: prop.itemGroupId,
              itemGrp: prop.itemGroupId + "" + prop.itemId,
              quantity: prop.indentQuantity,
              hsnCode: prop.hsnCode,
              unit: prop.unitId,
              weight: prop.indentQuantity,
              rate: prop.rate, //rate
              // amount: parseInt(prop.indentQuantity)*parseInt(prop.rate),
              depId: prop.deptId,
              isgst: prop.iTax,
              ssgst: prop.sTax,
              csgst: prop.cTax,
              taxid: prop.taxId,
              taxamount: itemtaxAmount,
              taxPercent: prop.taxPercentage,
              amount: parseFloat(prop.indentQuantity * prop.rate).toFixed(2),
              isActive: 1,
              lastPurchaseRate: 0,
              lastPurchaseDate: "",
              stRemQty: prop.remainingQuantity,
              indentType: prop.indentType,
            };

            /* var qty = prop.remainingQuantity;
                        if (qty <= 0) {
                            obj.quantity = 0;
                            obj.amount = 0.00;
                        }*/
            if (this.state.updateView) {
              obj.id = indentlist.length;
              indentlist.push(obj);
              /*  invoiceList.forEach(o=>{
                                console.log("tttt",o.itemGroupId,prop.itemGroupId,o.itemId,prop.itemId);

                                if(o.itemGroupId===prop.itemGroupId&&o.itemId===prop.itemId){
                                    o.id=indentlist.length;
                                    indentlist.push(o)
                                }else{
                                    if(indentlist.indexOf(o)<-1){
                                        o.id=indentlist.length;
                                        indentlist.push(o);
                                    }

                                    obj.id=indentlist.length;
                                    indentlist.push(obj)
                                }

                            })*/
            } else indentlist.push(obj);
          } else {
            if (prop.remainingQuantity > 0) {
              if (i > 0) {
                key = key + 1;
              }
              console.log("key", key);

              var obj = {
                lineItemId: 0,
                indentLineId: prop.indentLineItemId,
                id: key,
                itemId: prop.itemId,
                itemDesc: prop.itemIdDesc,
                itemGroupId: prop.itemGroupId,
                itemGrp: prop.itemGroupId + "" + prop.itemId,
                quantity: prop.indentQuantity,
                hsnCode: prop.hsnCode !== null ? prop.hsnCode : "",
                unit: prop.unitId,
                weight: prop.indentQuantity,
                rate: prop.rate, //rate
                // amount: parseInt(prop.indentQuantity)*parseInt(prop.rate),
                depId: prop.deptId,
                isgst: prop.iTax,
                ssgst: prop.sTax,
                csgst: prop.cTax,
                taxid: prop.taxId,
                taxamount: itemtaxAmount,
                taxPercent: prop.taxPercentage,
                amount: parseFloat(prop.indentQuantity * prop.rate).toFixed(2),
                isActive: 1,
                lastPurchaseRate: 0,
                lastPurchaseDate: "",
                stRemQty: prop.remainingQuantity,
                indentType: prop.indentType,
              };
              if (this.state.updateView) {
                obj.id = indentlist.length;
                indentlist.push(obj);
                /*
                                invoiceList.forEach(o=>{
                                    console.log("tttt",o.itemGroupId,prop.itemGroupId,o.itemId,prop.itemId);

                                    if(o.itemGroupId===prop.itemGroupId&&o.itemId===prop.itemId){
                                        o.id=indentlist.length;
                                        indentlist.push(o)
                                    }else{
                                        if(indentlist.indexOf(o)<-1){
                                            o.id=indentlist.length
                                            indentlist.push(o);
                                        }

                                        obj.id=indentlist.length;
                                        indentlist.push(obj)
                                    }

                                })
*/
              } else indentlist.push(obj);
            }
          }
        }
      });
      if (indentlist.length <= 0) {
        this.basicAlert("No line items available for the purchase order.");
      }

      if (header !== null) {
        this.setState({
          indentAlert: false,
          loading: false,
          //updateView:true,
          // isCreated:true,
          changeTaxType: false,
          reloadSelect: true,
          approveStatus: header.approveButton,
          indentNo: header.id,
          statusDesc: header.statusDesc,
          branchId: header.branchId,
          projectId: header.projectId,
          loadBranch: true,
          loadProject: true,
          indentQuantity: header.indentWeight,
          //  year: header.finnacialYear,
          createdBy: header.submitter,
          createdOn: header.createDateDesc,
          date1: header.indentDateDesc,
          selectedVehicleType: "",
          quantity: "",
          stock: "",
          invoiceList: indentlist,
          // indentlist: null,
          totalTax: sum_of_tax,
          totalAmount: sumOfamt.toFixed(2),
          netTotal: parseFloat(sumOfamt + sum_of_tax).toFixed(2),
          form_purchaseType: header.type,
          //  itemGroup: ""
        });
        indentlist.forEach((prop, i) => {
          this.getLastPurchaseRate(i, prop.itemId, prop.itemGroupId);
        });
      }
    }
    //this.indenthideAlert();
  };
  getLastPurchaseRate = (id, itemId, itemGroupId) => {
    getDataFromUrl(
      serverApi.GET_LAST_PURCHASE_RATE +
        itemGroupId +
        "/" +
        itemId +
        "/" +
        getCookie(cons.COMPANY_ID),
      (resp) => {
        this.handlePRate(resp, id);
      }
    );
  };
  handlePRate = (resp, id) => {
    console.log("handlePRate", resp);
    var list = this.state.invoiceList;

    if (resp.data) {
      list[id].lastPurchaseRate = resp.data.poPurchasRate;
      list[id].lastPurchaseDate = resp.data.poPurchaseDate;
      this.setState({ invoiceList: list });
    }
  };
  /*
    getitemDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");

        this.setState({

            itemDesc: values[0],
            itemGroupId: values[1],
            uom: values[2],
            depId: values[3],
            hsnCode: values[4],

        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
*/

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  createShowMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  showMessage = (msg, status) => {
    this.setState({
      indentAlert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
        changeTaxType: true,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList,
        sum_of_tax = 0,
        sumOfamt = 0;
      var deducAmount = this.state.deducAmount;
      var duductaxAmount = this.state.duductaxAmount;
      data.forEach((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        }
      });
      var disableDept = false;
      data.forEach((o, key) => {
        console.log("isass", o.isActive);
        if (o.isActive === 1 || o.isActive === "1") {
          if (
            getConfigValue(
              configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
            ) === "2"
          )
            disableDept = true;
          var itemtaxAmount =
            (parseFloat(o.amount) * parseFloat(o.taxPercent)) / 100; //11 is tax percent

          sum_of_tax = sum_of_tax + itemtaxAmount;
          sumOfamt = sumOfamt + parseFloat(o.amount);
        }
      });
      var netTotal = Math.round(sum_of_tax + sumOfamt).toFixed(2);

      console.log("totalAmount", sumOfamt, sum_of_tax);

      this.setState({
        totalAmount: sumOfamt.toFixed(2),
        totalTax: sum_of_tax.toFixed(2),
        netTotal: netTotal,
        invoiceList: data,
        deleteItem: false,
        disableDept: disableDept,
      });

      this.hideItemAlert();
      //  if(this.state.approveStatus)
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideItemAlert() {
    console.log("alert");
    /*
        if(!this.state.disableDept){
            this.setState({  selectedItemGroup: '',
                selectedDepartment: '',})
        }
*/

    this.setState({
      //   alert: null,
      id: 0,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      itemId: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      taxId: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      purchaseType: "",
      lineItemId: 0,
      indentLineId: 0,
      taxPercent: "",
    });
  }

  hideAlert() {
    console.log("alert");
    /* if(!this.state.disableDept){
          this.setState({  selectedItemGroup: '',
              selectedDepartment: '',})
        }*/
    this.setState({
      alert: null,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      selectedItemGroup: "",
      itemId: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      taxId: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      purchaseType: "",
      lineItemId: 0,
      indentLineId: 0,
      price: "",
      taxPercent: "",
      isActive: 1,
      lastPurchaseRate: "",
      lastPurchaseDate: "",
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      indentType: "",
      pendingAtRcpt: "",
      printingPop: false,
      description: "",
      updateItem: false,
      itemTaxAmt: 0,
    });
  }

  //on select indent no

  indenthideAlert() {
    if (this.state.invoiceList.length === 0) {
      this.setState({
        SelectindentType: this.getDefaultindentConvertion(2),
      });
    }

    this.setState({
      indentAlert: false,
      loading: false,
      //SelectindentType: this.getDefaultindentConvertion(2)
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getList();
    }
    this.setState({
      // searchpoNo: '',
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      quantity: "",
      stock: "",
      itemGroupId: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      SelectindentType: this.getDefaultindentConvertion(2),
      taxType: "",
      selectedDeptId: "",
      selectedIndent: "",
      selectedGrpId: "",
      selectedUnit: "",
      vehicleQuantiy: "",
      netTotal: "",
      itemTaxAmt: 0,
      isgst: "",
      csgst: "",
      ssgst: "",
      supplier_Id: "",
      updateItem: false,
      selectedDepartment: "",
      PoDate: moment().format("DD-MM-YYYY"),
      expecDate:
        getCookie(cons.COMPANY_ID) === "116"
          ? moment()
              .add("days", 10)
              .format("DD-MM-YYYY")
          : moment()
              .add("days", 15)
              .format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      creditTerm: 60,
      deliveryTimeLine: getCookie(cons.COMPANY_ID) === "116" ? 10 : 15,
      totalTax: "",
      totalAmount: "",
      footerNote: "",
      delAddress: getCookie(cons.SHIPPING_ADDRESS),
      remarks: "",
      form_purchaseType: "",
      lineItemId: 0,
      indentLineId: 0,
      finalApprover: false,
      taxId: "",
      branchId: "",
      frieghtCharge: "",
      projectId: "",
      internalNote: "",
      indentlist: null,
      poStatus: "",
      itemGroup: "",
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      selectedItemGroup: "",
      disableDept: false,
      billingBranchId: "",
      rqName: "",
      poValidDate: "",
      filesList: [],
      typeId: 0,
      addPendQty: true,
      indentHdrId: "",
      approvedIndents: [],
      isUpdate: false,
      isMail: false,
    });
  }

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    /* var sum_of_tax = parseInt(this.state.sumOftax) + parseInt(this.state.itemTaxAmt);
         var sumOfamt = parseInt(this.state.sumOfAmount) + parseInt(this.state.amount);*/
    console.log("itemTaxAmt " + this.state.itemTaxAmt);
    var itemIndex = 0,
      hasItem = false,
      sum_of_tax = 0,
      sumOfamt = 0;
    if (this.state.updateItem) {
      /* itemIndex=this.state.id;
             arr[itemIndex].taxamount = parseFloat(this.state.itemTaxAmt);
             arr[itemIndex].amount = parseFloat(this.state.amount)*/
      arr.forEach((prop, i) => {
        if (prop.id === this.state.id) {
          arr[i].taxamount = parseFloat(this.state.itemTaxAmt);
          arr[i].amount = parseFloat(this.state.amount);
          itemIndex = i;
        }
      });
    } else {
      itemIndex = this.state.invoiceList.length;
      if (parseFloat(this.state.amount) > 0)
        sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);
      sumOfamt = sumOfamt + parseFloat(this.state.amount);
    }
    arr.forEach((q) => {
      // sumOfQty = sumOfQty+parseInt(q.quantityType);
      if (q.isActive === 1) {
        sum_of_tax = sum_of_tax + parseFloat(q.taxamount);
        sumOfamt = sumOfamt + parseFloat(q.amount);
        if (
          (this.state.selectedDepartment === q.depId) &
            (this.state.selectedItemGroup === q.itemGroupId) &&
          this.state.selectedItem === q.itemId &&
          !this.state.updateItem
        )
          hasItem = true;
      }
    });

    var addItemObj = {
      id: itemIndex,
      indentLineId: this.state.indentLineId,
      lineItemId: this.state.lineItemId,
      itemId: this.state.selectedItem,
      itemDesc: this.state.itemDesc,
      itemGrp: this.state.selectedItemGroup + "" + this.state.selectedItem,
      quantity: this.state.quantity,
      hsnCode: this.state.hsnCode,
      unit: this.state.uom,
      rate: this.state.rate,
      amount: this.state.amount,
      itemGroupId: this.state.selectedItemGroup,
      depId: this.state.selectedDepartment,
      isgst: this.state.igstPercent,
      ssgst: this.state.sgstPercent,
      csgst: this.state.cgstPercent,
      taxid: this.state.taxId,
      taxamount: this.state.itemTaxAmt !== "" ? this.state.itemTaxAmt : 0,
      taxPercent: this.state.taxPercent,
      itemStock: this.state.itemStock,
      isActive: 1,
      lastPurchaseRate: this.state.lastPurchaseRate,
      lastPurchaseDate: this.state.lastPurchaseDate,
      stRemQty: this.state.stRemQty,
      indentType: this.state.indentType,
      pendingAtRcpt: this.state.pendingAtRcpt,
      description: this.state.description,
      make: this.state.make,
    };

    if (this.state.printingPop) {
      if (this.state.amount !== "" && this.state.amount > 0) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);

        this.setState({
          hideJuteType: false,
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: Math.round(sumOfamt).toFixed(2),
          netTotal: Math.round(
            parseFloat(sumOfamt) + parseFloat(sum_of_tax)
          ).toFixed(2),
          updateItem: false,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else if (
      (this.state.selectedDepartment !== "") &
        (this.state.selectedItemGroup !== "") &&
      this.state.selectedItem !== "" &&
      this.state.quantity !== "" &&
      this.state.quantity !== "0" &&
      this.state.amount !== "" &&
      this.state.amount !== "0" &&
      this.state.taxId !== "0" &&
      this.state.taxId > 0 &&
      this.state.quantity > 0
    ) {
      if (!hasItem) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);

        this.setState({
          hideJuteType: false,
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: Math.round(sumOfamt).toFixed(2),
          netTotal: Math.round(
            parseFloat(sumOfamt) + parseFloat(sum_of_tax)
          ).toFixed(2),
          updateItem: false,
        });
        if (
          getConfigValue(
            configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
          ) === "2"
        )
          this.setState({ disableDept: true });

        this.hideAlert();
      } else {
        this.basicAlert("Item Already Saved. Please Select Another One..!!");
      }
    } else if (this.state.quantity < 0 || this.state.quantity == 0) {
      this.basicAlert("Do not enter negative values & zero in Quantity..!!");
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideItemAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      isReject: false,
      isApprove: false,
    });
  }
  handleApprovedIndents = (resp) => {
    var list = [];
    if (resp) {
      resp.forEach((prop) => {
        if (this.state.updateView) {
          var name = prop.name.split("^");
          if (this.state.indentHdrId + "" === name[4]) {
            list.push(prop);
          }
        } else {
          list.push(prop);
        }
      });
    }
    this.setState({ approvedIndents: list, loadIndent: true });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    console.log(this.state);
    if (
      this.state.supplier_Id !== "" &&
      this.state.taxType !== "" &&
      this.state.taxType.value !== 0 &&
      this.state.form_purchaseType !== ""
    )
      if (this.state.SelectindentType.value === 1) {
        if (this.state.updateView) {
          getDataFromUrl(
            serverApi.APPROVED_INDENTS +
              "s/" +
              getCookie(cons.COMPANY_ID) +
              "/" +
              getCookie(cons.ACADEMIC_YEAR) +
              "/" +
              this.state.branchId +
              "/" +
              this.state.itemGroup,
            this.handleApprovedIndents
          );
        }
        this.setState({
          loading: true,
          indentAlert: true,
          updateItem: false,
        });
      } else
        this.setState({
          loading: true,
          alert: true,
          updateItem: false,
        });
    else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      totalTax: 0,
      totalAmount: 0,
      netTotal: 0,
      basicAlert: null,
      //  canChangeUnit: true,
      invoiceList: [],
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      if (prop.isActive === 1) {
        if (prop.ssgst !== undefined)
          totSgst =
            totSgst + (parseFloat(prop.amount) * parseFloat(prop.ssgst)) / 100;
        if (prop.isgst !== undefined)
          totIgst =
            totIgst + (parseFloat(prop.amount) * parseFloat(prop.isgst)) / 100;
        if (prop.csgst !== undefined)
          totcgst =
            totcgst + (parseFloat(prop.amount) * parseFloat(prop.csgst)) / 100;
      }
    });
    var totalTax = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    }
    return totalTax.toFixed(2);
  }
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      hdrId,
      isCancel,
      isClose,
      isReject,
      isApprove,
      poStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(poStatus);
    }
    if (isCancel) {
      this.setState({ isCancel: false });
      this.onApproveButtonClick("6");
    }
    if (isClose) {
      this.setState({ isClose: false });
      this.onApproveButtonClick("5");
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        14,
        fileName,
        hdrId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
      isCancel: false,
      isClose: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }
  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        if (prop.isActive === 1) {
          prop.id = key;
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;

                    data.find((o, i) => {
                      if (o.id === key) {
                        if (
                          o.description === "Printing Charges" ||
                          o.description === "Labour Charges" ||
                          o.description === "Transport Charges" ||
                          o.description === "Other Charges" ||
                          o.description === "Delivery Charges" ||
                          o.description === "Packing & Forwarding Charges"
                        ) {
                          this.setState({ printingPop: true, alert: true });
                        } else
                          this.basicItemAlert(
                            "Do You Like To Update This Item?"
                          );

                        if (
                          this.state.supplier_Id !== "" &&
                          this.state.taxType !== "" &&
                          this.state.taxType.value !== 0 &&
                          this.state.form_purchaseType !== ""
                        ) {
                          // alert(o.qualityId);
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //  data.splice(i,1);
                          this.setState({
                            //alert: true,
                            id: o.id,
                            indentLineId: o.indentLineId,
                            lineItemId: o.lineItemId,
                            updateItem: true,
                            updateItemAlert: true,
                            reloadSelect: true,
                            selectedDepartment: o.depId,
                            selectedItemGroup: o.itemGroupId,
                            selectedItem: o.itemId,
                            quantity: o.quantity,
                            hsnCode: o.hsnCode,
                            rate: o.rate,
                            amount: o.amount,
                            taxId: o.taxid,
                            itemDesc: o.itemDesc,
                            igstPercent: o.isgst,
                            sgstPercent: o.ssgst,
                            cgstPercent: o.csgst,
                            taxPercent: o.taxPercent,
                            itemTaxAmt: o.taxamount,
                            isActive: o.isActive,
                            lastPurchaseRate: o.lastPurchaseRate,
                            lastPurchaseDate: o.lastPurchaseDate,
                            loadItemGroup: true,
                            loadItem: true,
                            stRemQty: o.stRemQty,
                            indentType: o.indentType,
                            pendingAtRcpt: o.pendingAtRcpt,
                            description: o.description,
                          });
                        } else {
                          this.basicAlert(
                            "Please Enter All Mandatory Fields..!!"
                          );
                        }
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>{" "}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color={
                    parseFloat(prop.rate) > parseFloat(prop.lastPurchaseRate) &&
                    prop.lastPurchaseRate > 0
                      ? "danger"
                      : "dangerNoBackground"
                  }
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };
          data.push(obj);
        }
      });
    return data;
  };

  //post to my portal handle
  postToMyPortalHandle = () => {
    console.log(
      `printing Data ---------${this.state.tenantId} ${this.state.serviceUrl} ${
        this.state.indentTypeId
      }`
    );

    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        itemId: prop.itemId,
        itemGroupId: prop.itemGroupId,
        // qualityCode:prop.quality,
        dtlItemDesc: prop.itemDesc,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        finYear: this.state.year,
        itemStock:
          prop.itemStock !== "0" && prop.itemStock > 0 ? prop.itemStock : "",
        rate: prop.rate,
        branchStock: prop.branchStock,
        pendingAtPo: prop.pendingAtPo,
        projectId: this.state.projectId,
      };
    });

    var data = {
      indentHeader: {
        type: this.state.indentTypeId,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged2(),
        finnacialYear: this.state.year,
        companyId: this.state.srcCompanyId,
        internalNote: this.state.internalNote,
        remarks: this.state.remarks,
        projectId: this.state.projectId,
        status: "21",
      },
      indentItems: invoiceList,
      tenantId: this.state.tenantId,
      cipher: getCookie(cons.CIPHER),
      projectId: this.state.projectId,
    };

    {
      this.handleLoading();
      requestList(serverApi.ADD_INDENT, data, this.handleAddIndent);
    }
  };
  render() {
    const { classes } = this.props;
    const { selectedJuteType, selectedIndent, taxType } = this.state;
    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");

    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);
    var poPrintBeforeApproval = getConfigValue(
      configNames.PO_PRINT_BEFORE_APPROVE
    );
    if (poPrintBeforeApproval === "2") {
      if (this.state.poStatus === "3") {
        poPrintBeforeApproval = true;
      } else {
        poPrintBeforeApproval = false;
      }
    } else {
      poPrintBeforeApproval = true;
    }

    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };
    var valid1 = function(current) {
      return current.isAfter(yesterday);
    };

    const ucoptions = [{ value: 0, label: "Select...", name: "Select..." }];
    var taxTypes = getConfigValue(configNames.TAX_TYPES);
    if (taxTypes !== "1") {
      taxTypes = JSON.parse(taxTypes);
      if (taxTypes.gst === 1) {
        ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
        ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
      }
      if (taxTypes.vat === 1) {
        ucoptions.push({ value: 3, label: "VAT", name: "VAT" });
      }
      if (taxTypes.noTax === 1) {
        ucoptions.push({ value: 4, label: "NO TAX", name: "NO TAX" });
      }
    } else {
      ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
      ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
    }

    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "With Indent", name: "With Indent" },
      { value: 2, label: "Without Indent", name: "Without Indent" },
    ];
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              //   cardTitle={"Create Jute PO"}
              // headerColor={"blue"}
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {//get indent alert
                  this.state.indentAlert ? (
                    <Popup
                      onClickSave={() => this.handleIndentSelectResp()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        {labelType === "2"
                                          ? "Project"
                                          : "Branch"}
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        // selectDisabled={this.state.isDisabled}
                                        defValue={this.state.branchId}
                                        onSelect={this.onSelect}
                                        stateKey="branchId"
                                        // isRefresh={this.state.loadQuality}
                                        // setIsRefresh={this.afterRefreshQuality}
                                        url={
                                          serverApi.SELECT_BRANCH +
                                          getCookie(cons.COMPANY_ID)
                                        }
                                        value={this.state.branchId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        //selectDisabled={false}
                                        defValue={this.state.itemGroup}
                                        onSelect={this.onSelect}
                                        //  getName={this.getItemGroupName}
                                        stateKey="itemGroup"
                                        url={
                                          serverApi.SEARCH_ITEM_GROUPS +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={2}>
                                      <InputLabel className={classes.label}>
                                        Indent List
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={10}>
                                      <SelectboxComponent
                                        selectDisabled={this.state.updateView}
                                        id={"selectedIndent"}
                                        defValue={selectedIndent}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          var name = name.split("^");
                                          if (
                                            this.state.selectedIndent !==
                                            name[2]
                                          )
                                            this.onSelectIndent(
                                              name[2],
                                              name[3]
                                            );
                                          else {
                                            if (
                                              this.state.selectedIndent ===
                                                name[2] &&
                                              this.state.updateView
                                            ) {
                                              this.onSelectIndent(
                                                name[2],
                                                name[3]
                                              );
                                            }
                                          }
                                        }}
                                        isRefresh={this.state.loadIndent}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadIndent: false });
                                        }}
                                        stateKey="selectedIndent"
                                        staticData={this.state.approvedIndents}
                                        //  url={serverApi.APPROVED_INDENTS + 's/' + getCookie(cons.COMPANY_ID) + "/" + getCookie(cons.ACADEMIC_YEAR)+"/"+this.state.branchId+"/"+this.state.itemGroup}
                                        value={selectedIndent}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {this.state.indentlist !== null ? (
                                  <ItemGrid xs={12}>
                                    <GridContainer>
                                      <ItemGrid>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              tabIndex={
                                                -1
                                              } /*
                                                                                            value={this.state.selectAll?1:0}
                                                                                            checked={this.state.selectAll?1:0}*/
                                              onChange={() => {
                                                var list = this.state
                                                  .indentlist;
                                                var data = list.indentItems;
                                                console.log("indentlist", data);
                                                data.forEach((o, k) => {
                                                  if (o.isActive === 0)
                                                    data[k].isActive = 1;
                                                  else data[k].isActive = 0;
                                                });
                                                list.indentItems = data;

                                                this.setState({
                                                  indentlist: list,
                                                });
                                              }}
                                              // onClick={() => this.handleToggle(prop)}
                                              checkedIcon={
                                                <Check
                                                  className={
                                                    classes.checkedIcon
                                                  }
                                                />
                                              }
                                              icon={
                                                <Check
                                                  className={
                                                    classes.uncheckedIcon
                                                  }
                                                />
                                              }
                                              classes={{
                                                checked: classes.checked,
                                              }}
                                              style={{ marginLeft: 10 }}
                                            />
                                          }
                                          classes={{
                                            label: classes.menulabel,
                                          }}
                                          label={""}
                                        />
                                      </ItemGrid>
                                      <ItemGrid xs={1}>
                                        <InputLabel className={classes.label}>
                                          Item Code
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={3}>
                                        <InputLabel className={classes.label}>
                                          Item Description
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={3}>
                                        <InputLabel className={classes.label}>
                                          Quantity
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={3}>
                                        <InputLabel className={classes.label}>
                                          Rate
                                        </InputLabel>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                <ItemGrid
                                  xs={12}
                                  style={{
                                    maxHeight: 250,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <GridContainer>
                                    {this.state.indentlist !== null
                                      ? this.state.indentlist.indentItems.map(
                                          (prop, i) => {
                                            return (
                                              <ItemGrid xs={12}>
                                                <GridContainer>
                                                  <ItemGrid>
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          tabIndex={-1}
                                                          value={prop.isActive}
                                                          checked={
                                                            prop.isActive
                                                          }
                                                          onChange={() => {
                                                            var list = this
                                                              .state.indentlist;
                                                            var data =
                                                              list.indentItems;
                                                            if (
                                                              data[i]
                                                                .isActive === 0
                                                            )
                                                              data[
                                                                i
                                                              ].isActive = 1;
                                                            else
                                                              data[
                                                                i
                                                              ].isActive = 0;
                                                            list.indentItems = data;
                                                            this.setState({
                                                              indentlist: list,
                                                            });
                                                          }}
                                                          // onClick={() => this.handleToggle(prop)}
                                                          checkedIcon={
                                                            <Check
                                                              className={
                                                                classes.checkedIcon
                                                              }
                                                            />
                                                          }
                                                          icon={
                                                            <Check
                                                              className={
                                                                classes.uncheckedIcon
                                                              }
                                                            />
                                                          }
                                                          classes={{
                                                            checked:
                                                              classes.checked,
                                                          }}
                                                          style={{
                                                            marginLeft: 10,
                                                          }}
                                                        />
                                                      }
                                                      classes={{
                                                        label:
                                                          classes.menulabel,
                                                      }}
                                                      label={""}
                                                    />
                                                  </ItemGrid>
                                                  <ItemGrid xs={1}>
                                                    {prop.itemId}
                                                  </ItemGrid>
                                                  <ItemGrid xs={3}>
                                                    {prop.itemIdDesc}
                                                  </ItemGrid>
                                                  <ItemGrid xs={3}>
                                                    <TextField
                                                      id="quantity"
                                                      className={
                                                        classes.textField
                                                      }
                                                      onChange={(event) => {
                                                        var list = this.state
                                                          .indentlist;
                                                        var data =
                                                          list.indentItems;
                                                        if (
                                                          event.target.value !==
                                                          ""
                                                        ) {
                                                          if (
                                                            prop.indentType !==
                                                            "O"
                                                          ) {
                                                            if (
                                                              parseFloat(
                                                                event.target
                                                                  .value
                                                              ) <=
                                                              prop.remainingQuantity
                                                            ) {
                                                              data[
                                                                i
                                                              ].indentQuantity = parseFloat(
                                                                event.target
                                                                  .value
                                                              );
                                                              data[i].stRemQty =
                                                                prop.remainingQuantity;
                                                            } else {
                                                              this.basicAlert(
                                                                "Quantity should not be greater than indent quantity..!!"
                                                              );
                                                            }
                                                          } else {
                                                            data[
                                                              i
                                                            ].indentQuantity = parseFloat(
                                                              event.target.value
                                                            );
                                                            data[i].stRemQty =
                                                              prop.remainingQuantity;
                                                          }
                                                        } else
                                                          data[
                                                            i
                                                          ].indentQuantity = 0;
                                                        list.indentItems = data;
                                                        this.setState({
                                                          indentlist: list,
                                                        });
                                                      }}
                                                      value={
                                                        prop.indentQuantity
                                                      }
                                                      fullWidth={true}
                                                      iType={"number"}
                                                      decimal={3}
                                                    />
                                                  </ItemGrid>
                                                  <ItemGrid xs={3}>
                                                    {prop.rate}
                                                  </ItemGrid>
                                                </GridContainer>
                                              </ItemGrid>
                                            );
                                          }
                                        )
                                      : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {//add item alert
                  this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <br />
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Department
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          // selectDisabled={
                                          //   (this.state.SelectindentType
                                          //     .value === 1 &&
                                          //     this.state.updateItem) ||
                                          //   this.state.disableDept
                                          // }
                                          id={"selectedDepartment"}
                                          onSelect={this.onSelect}
                                          defValue={
                                            this.state.selectedDepartment
                                          }
                                          getName={this.getDeptName}
                                          stateKey="selectedDepartment"
                                          url={
                                            serverApi.SEARCH_DEPATMENTS +
                                            getCookie(cons.COMPANY_ID) +
                                            "/" +
                                            getCookie(cons.CIPHER)
                                          }
                                          value={this.state.selectedDepartment}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <br />
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Item Group
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          // selectDisabled={
                                          //   (this.state.SelectindentType
                                          //     .value === 1 &&
                                          //     this.state.updateItem) ||
                                          //   this.state.disableDept
                                          // }
                                          defValue={
                                            this.state.selectedItemGroup
                                          }
                                          id={"selectedItemGroup"}
                                          onSelect={this.onSelect}
                                          getName={this.getItemGroupName}
                                          isRefresh={this.state.loadItemGroup}
                                          stateKey="selectedItemGroup"
                                          setIsRefresh={
                                            this.afterRefreshItemGrp
                                          }
                                          url={
                                            serverApi.DATATABLE_SERVICE_URL +
                                            this.state.selectedDepartment +
                                            "/" +
                                            getCookie(cons.COMPANY_ID) +
                                            "/getAllItemGroupsByDepartmentId/" +
                                            getCookie(cons.CIPHER)
                                          }
                                          value={this.state.selectedItemGroup}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} md={12}>
                              <br />
                              <GridContainer>
                                {this.state.printingPop ? (
                                  <ItemGrid xs={6} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Item
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          id={"description"}
                                          defValue={this.state.description}
                                          staticData={[
                                            {
                                              label: "Printing Charges",
                                              value: "Printing Charges",
                                              name: "Printing Charges",
                                            },
                                            {
                                              label: "Labour Charges",
                                              value: "Labour Charges",
                                              name: "Labour Charges",
                                            },
                                            {
                                              label: "Transport Charges",
                                              value: "Transport Charges",
                                              name: "Transport Charges",
                                            },
                                            {
                                              label: "Delivery Charges",
                                              value: "Delivery Charges",
                                              name: "Delivery Charges",
                                            },
                                            {
                                              label:
                                                "Packing & Forwarding Charges",
                                              value:
                                                "Packing & Forwarding Charges",
                                              name:
                                                "Packing & Forwarding Charges",
                                            },
                                            {
                                              label: "Other Charges",
                                              value: "Other Charges",
                                              name: "Other Charges",
                                            },
                                          ]}
                                          onSelect={this.onSelect}
                                          getName={(val) => {
                                            this.setState({ itemDesc: val });
                                          }}
                                          stateKey="description"
                                          //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {!this.state.printingPop ? (
                                  <>
                                    <ItemGrid xs={12} sm={6}>
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                          <InputLabel className={classes.label}>
                                            Item
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              *
                                            </span>
                                          </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                          <SelectboxTemp
                                            selectDisabled={
                                              this.state.SelectindentType
                                                .value === 1 &&
                                              this.state.updateItem
                                            }
                                            defValue={this.state.selectedItem}
                                            id={"selectedItem"}
                                            staticData={this.state.ItemOptions}
                                            onSelect={this.onSelect}
                                            getName={this.getItemDetailsByName}
                                            isRefresh={this.state.loadItem}
                                            setIsRefresh={this.afterRefreshItem}
                                            stateKey="selectedItem"
                                            // url={serverApi.GET_ITEMS_BY_GRPID + this.state.selectedItemGroup+"/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                            value={this.state.selectedItem}
                                          />
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  </>
                                ) : null}

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Make
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="make"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.make}
                                        fullWidth={true}
                                        inputType={"text"}
                                        value={this.state.make}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.quantity}
                                        fullWidth={true}
                                        iType={"number"}
                                        decimal={3}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                    {/* {this.state.minStock > 0 &&
                                    this.state.itemStock > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "100px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Quantity Range (" +
                                          this.state.minStock +
                                          " - " +
                                          this.state.itemStock +
                                          ")"}
                                      </span>
                                    ) : null} */}
                                  </GridContainer>
                                </ItemGrid>
                                {this.state.printingPop ? (
                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          UOM
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="uom"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          value={this.state.uom}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                              </GridContainer>
                            </ItemGrid>
                            {!this.state.printingPop ? (
                              <ItemGrid xs={12} md={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          {labelType === "2"
                                            ? "City Stock"
                                            : "Central Stock"}
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={true}
                                          id="stock"
                                          className={classes.textField}
                                          //  onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.stock}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={12}
                                sm={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      {labelType === "2"
                                        ? "Project Stock"
                                        : " Branch Stock"}
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="branchStock"
                                      disabled={true}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      value={this.state.branchStock}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Tax
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.taxId}
                                        id={"taxId"}
                                        onSelect={this.onSelect}
                                        stateKey="taxId"
                                        getName={this.gettaxpercent}
                                        isReload={this.state.loadTax}
                                        setIsReload={this.afterRefreshTax}
                                        url={
                                          serverApi.TAX_MASTER_LIST +
                                          "/" +
                                          getCookie(cons.COMPANY_ID) +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.taxId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedItem !== "" ? (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "100px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {/* {this.state.lastPurchaseRate !== ""
                                          ? "Last purchase at  " +
                                            this.stae.poPurchasRate +
                                            " on " +
                                            this.state.latestPriceDate
                                          : "Last purchase not found"}
                                      </span>
                                    ) : null} */}

                                        {this.state.lastPurchaseRate !== ""
                                          ? "Last purchase at  " +
                                            this.state.lastPurchaseRate +
                                            " on " +
                                            this.state.lastPurchaseDate
                                          : "Last purchase not found"}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.amount}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Qty pending for Receipt
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={true}
                                          id="pendingAtRcpt"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.pendingAtRcpt}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  <ItemGrid xs={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        {this.state.alert}
                        {this.state.basicAlert}
                        {this.state.basicAlert1}

                        {this.state.showMessagealert}
                        <InputLabel className={classes.label}>
                          Create PO
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateView}
                          defValue={this.state.SelectindentType}
                          name={"SelectindentType"}
                          value={this.state.SelectindentType}
                          onChange={this.handleSelectedValues}
                          options={options}
                          // statekey={"SelectindentType"}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Date</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            // onChange={(dateObj) => {
                            //     this.timeChanged(dateObj, "PoDate")
                            // }}
                            onChange={(dateObj) => {
                              this.dateChanged(dateObj, "PoDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "PoDate",
                              value: this.state.PoDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.SelectindentType.value === 1 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Indent No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="indentNo"
                            value={this.state.indentNo}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tax Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={
                            this.state.updateView && this.state.poStatus !== "1"
                          }
                          defValue={this.state.taxType}
                          name={"taxType"}
                          value={this.state.taxType}
                          onChange={this.handleSelectedUnitValue}
                          options={ucoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            colors: {
                              ...theme.colors,
                              primary: "#00ABDC",
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Credit Term
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="creditTerm"
                          value={this.state.creditTerm}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Days
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Delivery Timeline
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="deliveryTimeLine"
                          className={classes.textField}
                          value={this.state.deliveryTimeLine}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Days
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Expected Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "expecDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "expecDate",
                              value: this.state.expecDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={
                            this.state.updateView && this.state.poStatus !== "1"
                          }
                          id={"supplier_Id"}
                          defValue={this.state.supplier_Id}
                          onSelect={this.onSelect}
                          getName={(val) => {
                            var name = val.split("_");
                            this.setState({
                              supplierName: name[0],
                              supplierAddress: name[1],
                              supplierPhno: name[3],
                              supplierGst: name[2],
                            });
                          }}
                          stateKey="supplier_Id"
                          isRefresh={this.state.loadSupplier}
                          setIsRefresh={(val) => {
                            this.setState({ loadSupplier: false });
                          }}
                          staticData={this.state.supplierList}
                          //  url={serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID)}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Purchase Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={
                            this.state.SelectindentType.value === 1 ||
                            (this.state.updateView &&
                              this.state.poStatus !== "1")
                          }
                          id={"form_purchaseType"}
                          defValue={this.state.form_purchaseType}
                          isReload={this.state.reloadSelect}
                          setIsReload={this.afterReloadSelect}
                          onSelect={this.onSelect}
                          getName={(name) => {
                            var typeId = name.split("_");
                            typeId = typeId[1];
                            this.setState({ typeId: typeId });
                          }}
                          stateKey="form_purchaseType"
                          url={serverApi.STORE_PURCHASE_TYPES}
                          value={this.state.form_purchaseType}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          PO Till Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid1}
                            onChange={(dateObj) => {
                              this.dateChanged(dateObj, "poValidDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "poValidDate",
                              value: this.state.poValidDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          RQN Name
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="rqName"
                          value={this.state.rqName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          //  style={{height: 30}}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Delivery Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.branchId}
                          selectDisabled={
                            this.state.updateView ||
                            this.state.SelectindentType.value === 1
                          }
                          onSelect={this.onSelect}
                          getName={(name) => {
                            var val = name.split("^");
                            if (val[1] !== null)
                              this.setState({ delAddress: val[1] });
                          }}
                          stateKey="branchId"
                          staticData={this.state.branchOptions}
                          isRefresh={this.state.loadBranch}
                          setIsRefresh={(val) => {
                            this.setState({ loadBranch: false });
                          }}
                          // url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        // style={{height: 30}}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
*/}
                  {/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        //  style={{height: 30}}
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
*/}

                  <br />

                  <br />

                  <br />

                  <ItemGrid xs={12} sm={12}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          disableActions={
                            this.state.poStatus === "3" ||
                            this.state.poStatus === "4" ||
                            this.state.poStatus === "5" ||
                            this.state.poStatus === "6"
                          }
                          columns={
                            taxType.value === 3
                              ? [
                                  {
                                    Header: "Item Code",
                                    accessor: "itemGrp",
                                    minWidth: 70,
                                    style: { color: "#000", textAlign: "left" },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    id: "itemDesc",
                                    Header: "Item Desc",
                                    accessor: (row) => (
                                      <>
                                        <span style={{ fontStyle: "bold" }}>{`${
                                          row.itemDesc
                                        }`}</span>
                                        <br />
                                        {this.state.make !== "" &&
                                        this.state.make !== null ? (
                                          <>
                                            <span
                                              style={{
                                                fontStyle: "italic",
                                                color: "#00ABDC",
                                              }}
                                            >
                                              Make:- {`${row.make}`}
                                            </span>
                                            <br />
                                          </>
                                        ) : null}
                                      </>
                                    ),
                                    filterMethod: (filter, row) =>
                                      row._original.itemDesc.startsWith(
                                        filter.value
                                      ) ||
                                      row._original.make.startsWith(
                                        filter.value
                                      ),
                                    minWidth: 200,
                                    style: { color: "#000", textAlign: "left" },

                                    getProps: (state, rowInfo, column) => {
                                      console.log("rowInfo", rowInfo);
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "HSN/SAC Code",
                                    accessor: "hsnCode",
                                    width: 150,
                                    style: {
                                      color: "#000",
                                      textAlign: "left",
                                      overflow: "unset",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  // {
                                  //   Header: "Make",
                                  //   accessor: "make",
                                  //   width: 150,
                                  //   style: {
                                  //     color: "#000",
                                  //     textAlign: "left",
                                  //     overflow: "unset",
                                  //   },
                                  //   getProps: (state, rowInfo, column) => {
                                  //     return {
                                  //       style: {
                                  //         background:
                                  //           rowInfo &&
                                  //           parseFloat(
                                  //             rowInfo.row._original.rate
                                  //           ) >
                                  //             rowInfo.row._original
                                  //               .lastPurchaseRate &&
                                  //           rowInfo.row._original
                                  //             .lastPurchaseRate > 0
                                  //             ? dangerColor
                                  //             : null,
                                  //         color:
                                  //           rowInfo &&
                                  //           parseFloat(
                                  //             rowInfo.row._original.rate
                                  //           ) >
                                  //             rowInfo.row._original
                                  //               .lastPurchaseRate &&
                                  //           rowInfo.row._original
                                  //             .lastPurchaseRate > 0
                                  //             ? "#FFF"
                                  //             : "#000",
                                  //       },
                                  //     };
                                  //   },
                                  // },

                                  {
                                    Header: "Quantity",
                                    accessor: "quantity",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "Rate",
                                    accessor: "rate",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "VAT (%)",
                                    accessor: "isgst",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },

                                  {
                                    Header: "Amount",
                                    accessor: "amount",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                    minWidth: 80,
                                    style: {
                                      color: "#000",
                                      textAlign: "center",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                    sortable: false,
                                    filterable: false,
                                  },
                                ]
                              : [
                                  {
                                    Header: "Item Code",
                                    accessor: "itemGrp",
                                    minWidth: 70,
                                    style: { color: "#000", textAlign: "left" },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    id: "itemDesc",
                                    Header: "Item Desc",
                                    accessor: (row) => (
                                      <>
                                        <span style={{ fontStyle: "bold" }}>{`${
                                          row.itemDesc
                                        }`}</span>
                                        <br />
                                        {this.state.make !== "" &&
                                        this.state.make !== null ? (
                                          <>
                                            <span
                                              style={{
                                                fontStyle: "italic",
                                                color: "#00ABDC",
                                              }}
                                            >
                                              Make:- {`${row.make}`}
                                            </span>
                                            <br />
                                          </>
                                        ) : null}
                                      </>
                                    ),
                                    filterMethod: (filter, row) =>
                                      row._original.itemDesc.startsWith(
                                        filter.value
                                      ) ||
                                      row._original.make.startsWith(
                                        filter.value
                                      ),
                                    minWidth: 200,
                                    style: { color: "#000", textAlign: "left" },

                                    getProps: (state, rowInfo, column) => {
                                      console.log("rowInfo", rowInfo);
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "HSN/SAC Code",
                                    accessor: "hsnCode",
                                    width: 150,
                                    style: {
                                      color: "#000",
                                      textAlign: "left",
                                      overflow: "unset",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  // {
                                  //   Header: "Make",
                                  //   accessor: "make",
                                  //   width: 70,
                                  //   style: {
                                  //     color: "#000",
                                  //     textAlign: "left",
                                  //     overflow: "unset",
                                  //   },
                                  //   getProps: (state, rowInfo, column) => {
                                  //     return {
                                  //       style: {
                                  //         background:
                                  //           rowInfo &&
                                  //           parseFloat(
                                  //             rowInfo.row._original.rate
                                  //           ) >
                                  //             rowInfo.row._original
                                  //               .lastPurchaseRate &&
                                  //           rowInfo.row._original
                                  //             .lastPurchaseRate > 0
                                  //             ? dangerColor
                                  //             : null,
                                  //         color:
                                  //           rowInfo &&
                                  //           parseFloat(
                                  //             rowInfo.row._original.rate
                                  //           ) >
                                  //             rowInfo.row._original
                                  //               .lastPurchaseRate &&
                                  //           rowInfo.row._original
                                  //             .lastPurchaseRate > 0
                                  //             ? "#FFF"
                                  //             : "#000",
                                  //       },
                                  //     };
                                  //   },
                                  // },

                                  {
                                    Header: "Quantity",
                                    accessor: "quantity",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "Pending For Receipt",
                                    accessor: "pendingAtRcpt",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "Rate",
                                    accessor: "rate",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "IGST (%)",
                                    accessor: "isgst",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "SGST (%)",
                                    accessor: "ssgst",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "CGST (%)",
                                    accessor: "csgst",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },

                                  {
                                    Header: "Amount",
                                    accessor: "amount",
                                    minWidth: 40,
                                    style: {
                                      color: "#000",
                                      textAlign: "right",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                    minWidth: 80,
                                    style: {
                                      color: "#000",
                                      textAlign: "center",
                                    },
                                    getProps: (state, rowInfo, column) => {
                                      return {
                                        style: {
                                          background:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? dangerColor
                                              : null,
                                          color:
                                            rowInfo &&
                                            parseFloat(
                                              rowInfo.row._original.rate
                                            ) >
                                              rowInfo.row._original
                                                .lastPurchaseRate &&
                                            rowInfo.row._original
                                              .lastPurchaseRate > 0
                                              ? "#FFF"
                                              : "#000",
                                        },
                                      };
                                    },
                                    sortable: false,
                                    filterable: false,
                                  },
                                ]
                          }
                        />
                      </ItemGrid>
                      {this.state.poStatus !== "3" &&
                      this.state.poStatus !== "4" &&
                      this.state.poStatus !== "5" &&
                      this.state.poStatus !== "6" ? (
                        <ItemGrid
                          xs={12}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ printingPop: true, alert: true });
                          }}
                        >
                          Click Here To Add Printing Charges/Labour
                          Charges/Transport Charges/Delivery Charges/Packing &
                          Forwarding Charges/Other Charges
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                            </ItemGrid>
                            <ItemGrid xs={6}>
                              {this.state.poStatus !== "3" &&
                              this.state.poStatus !== "4" &&
                              this.state.poStatus !== "5" &&
                              this.state.poStatus !== "6" ? (
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              ) : null}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        </ItemGrid>
                      )}
                      <ItemGrid
                        xs={12}
                        style={{
                          height: 1,
                          backgroundColor: "#00acc1",
                          marginTop: "15px",
                        }}
                      />
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          {taxType.value === 3 ? "Total VAT :" : "Total IGST :"}
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {this.getTotalTax("igst")}
                        {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalIgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("igst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {taxType.value !== 3 ? (
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                          <InputLabel className={classes.label}>
                            Total SGST :
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                          {this.getTotalTax("sgst")}
                          {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalSgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("sgst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {taxType.value !== 3 ? (
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                          <InputLabel className={classes.label}>
                            Total CGST :
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                          {this.getTotalTax("cgst")}
                          {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalCgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("cgst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          Total Amount :
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {this.state.totalAmount}
                        {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalAmount"
                                                    className={classes.textField}
                                                    value={this.state.totalAmount}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          Net Total:
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {this.state.netTotal}
                        {/*
                                                <TextField
                                                    disabled={true}
                                                    id="netTotal"
                                                    className={classes.textField}
                                                    value={this.state.netTotal}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2"
                            ? "Billing Project"
                            : "Billing Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.billingBranchId}
                          //  selectDisabled={this.state.updateView}
                          onSelect={this.onSelect}
                          getName={(name) => {
                            var val = name.split("^");
                            if (val[1] !== null)
                              this.setState({ delAddress: val[1] });
                          }}
                          stateKey="billingBranchId"
                          staticData={this.state.branchOptions}
                          isRefresh={this.state.loadBranch}
                          setIsRefresh={(val) => {
                            this.setState({ loadBranch: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Phase" : "Project"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={
                            this.state.SelectindentType.value === 1 ||
                            this.state.updateView
                          }
                          url={
                            serverApi.PROJECT_MASTER_SELECTION_LIST +
                            getCookie(cons.COMPANY_ID) +
                            "/customer/0/branch/" +
                            this.state.branchId
                          }
                          defValue={this.state.projectId}
                          // staticData={this.state.ProjectOptions}
                          onSelect={this.onSelect}
                          stateKey="projectId"
                          value={this.state.projectId}
                          isRefresh={this.state.loadProject}
                          setIsRefresh={(val) => {
                            this.setState({ loadProject: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Delivery Address
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={
                            this.state.branchId !== "" &&
                            this.state.branchId !== null
                          }
                          id="delAddress"
                          fullWidth
                          value={this.state.delAddress}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Freight Charges
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="frieghtCharge"
                          fullWidth
                          iType={"number"}
                          value={this.state.frieghtCharge}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Footer Note
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="footerNote"
                          fullWidth
                          multiline
                          rowsMax="3"
                          height={60}
                          value={this.state.footerNote}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Terms & Conditions
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="remarks"
                          fullWidth
                          multiline
                          rowsMax="3"
                          height={60}
                          value={this.state.remarks}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Internal Note
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="internalNote"
                          fullWidth
                          multiline
                          rowsMax="3"
                          height={60}
                          value={this.state.internalNote}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {/* post to my portal button  */}
                  {(this.state.tenantId !== "" || null) &&
                  getCookie(cons.USER_GRP_ID) == 100 &&
                  (this.state.serviceUrl !== "" || null) ? (
                    <Button color="primary" onClick={this.postToMyPortalHandle}>
                      Post to My Portal
                    </Button>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView &&
                  this.state.poStatus !== "4" &&
                  this.state.poStatus !== "3" ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}
                  {this.state.finalApprover && this.state.poStatus === "3" ? (
                    <ItemGrid xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            //value={this.state.addPendQty?true:false}
                            checked={this.state.addPendQty ? true : false}
                            onChange={() => {
                              {
                                this.state.addPendQty
                                  ? this.setState({
                                      addPendQty: false,
                                    })
                                  : this.setState({
                                      addPendQty: true,
                                    });
                              }
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                            }}
                          />
                        }
                      />
                      <InputLabel
                        className={classes.label}
                        style={{ color: "Red" }}
                      >
                        Add pending qty back to indent
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {!this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <br />
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                          <br />
                        </ItemGrid>
                        <ItemGrid>
                          <br />
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({
                                isApprove: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve This PO..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isReject: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject This PO..!!"
                              );
                              // this.onApproveButtonClick("4")
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.updateView &&
                        this.state.poStatus !== "3" &&
                        this.state.poStatus !== "6" &&
                        this.state.approveStatus) ||
                      (this.state.updateView && this.state.poStatus === "1") ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({ isUpdate: true });
                              this.onApproveButtonClick("");
                            }}
                          >
                            update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.finalApprover &&
                      this.state.poStatus === "3" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isCancel: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Cancel This PO..!!"
                              );
                              //this.onApproveButtonClick("6")
                            }}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.finalApprover &&
                      this.state.poStatus === "3" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isClose: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Close This PO..!!"
                              );
                              //this.onApproveButtonClick("6")
                            }}
                          >
                            Close
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView &&
                      poPrintBeforeApproval &&
                      this.state.poStatus !== "6" ? (
                        <ItemGrid xs={12}>
                          <SamplePDF
                            invoiceType={1}
                            printResp={this.state.printResp}
                            sendMail={true}
                            handlePOMail={this.handlePOMail}
                            isMail={this.state.isMail}
                            handleMail={this.handleMail}
                          />
                          {/*
                                                        <IconButton color={"primary"} right={true} onClick={() => {
                                                        this.setState({
                                                            sendMail: true
                                                        });
                                                        this.printDocument();
                                                    }
                                                    }>
                                                        <Email/>
                                                    </IconButton>
*/}
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView &&
                      poPrintBeforeApproval &&
                      this.state.poStatus !== "6" ? (
                        <ItemGrid xs={11}>
                          {" "}
                          <SamplePDF
                            invoiceType={1}
                            printResp={this.state.printResp}
                          />{" "}
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxComponent
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + getCookie(cons.COMPANY_ID)}
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  staticData={this.state.supplierList}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                PO No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="searchpoNo"
                                value={this.state.searchpoNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Status
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxComponent
                                  id={"status"}
                                  onSelect={this.onSelect}
                                  stateKey="status"
                                  url={serverApi.SEARCH_STORE_STATUS}
                                  value={this.state.status}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Purchase Type
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"purchaseType"}
                                onSelect={this.onSelect}
                                stateKey="purchaseType"
                                url={serverApi.STORE_PURCHASE_TYPES}
                                value={this.state.purchaseType}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Project" : "Branch"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                // isRefresh={this.state.loadQuality}
                                // setIsRefresh={this.afterRefreshQuality}
                                url={
                                  serverApi.SELECT_BRANCH +
                                  getCookie(cons.COMPANY_ID)
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Phase" : "Project"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                url={
                                  serverApi.PROJECT_MASTER_SELECTION_LIST +
                                  getCookie(cons.COMPANY_ID) +
                                  "/customer/0/branch/" +
                                  this.state.branchIds
                                }
                                defValue={this.state.projectIds}
                                // staticData={this.state.ProjectOptions}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid xs={12}>
                            <IconButton
                              onClick={this.handleCreate}
                              color="success"
                              right={true}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12}>
                          <br />
                          <br />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {this.state.dataTable ? (
                        <TableComponent
                          staticData={this.state.dataTable}
                          //url={serverApi.DATATABLE_SERVICE_URL + "getAllPOList"}
                          searchData={this.getData()}
                          isRefresh={this.state.isRefresh}
                          handleSearch={this.handleSeacrh.bind(this)}
                          isLoading={this.state.isLoading}
                          handleLoading={this.handleTableLoading.bind(this)}
                          onActionClick={this.handleViewClick}
                          itemViewType={this.state.itemViewType}
                          actionType={VIEW}
                        />
                      ) : null}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        poNum: this.state.poNo,
        userId: getCookie(cons.USERID),
        cipher: getCookie(cons.CIPHER),
      };
      requestList(serverApi.GET_PO_DETAILS, data, this.handlePrintResp);
      //   getDataFromUrl(serverApi.GET_PO_DETAILS + this.state.hdrId + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handlePrintResp)

      //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
    }
  }

  handlePrintResp = (resp) => {
    var billingBranchName = "",
      billingBranchAddress = "",
      billContactNo = "",
      billingContactPerson = "",
      billingGST = "",
      billingEmail = "";
    var shippingBranchName = "",
      shippingBranchAddress = "",
      shippingContactNo = "",
      shippingContactPerson = "",
      shippingGST = "",
      shippingEmail = "";

    var branchData = this.getBranchDetailsFromId(resp.poHeader.billingBranchId);
    var shipData = this.getBranchDetailsFromId(resp.poHeader.branchId);
    if (branchData !== null) {
      billingBranchAddress = branchData.address;
      billContactNo = branchData.contactNo;
      billingContactPerson = branchData.contactPerson;
      billingGST = branchData.gstNo;
      billingBranchName = branchData.label;
      billingEmail = branchData.email;
    }
    if (shipData !== null) {
      shippingBranchAddress = shipData.address;
      shippingContactNo = shipData.contactNo;
      shippingContactPerson = shipData.contactPerson;
      shippingGST = shipData.gstNo;
      shippingBranchName = shipData.label;
      shippingEmail = shipData.email;
    }

    var hdr = {
      ...resp.poHeader,
      billingBranchName: billingBranchName,
      billingBranchAddress: billingBranchAddress,
      billContactNo: billContactNo,
      billingContactPerson: billingContactPerson,
      billingEmail: billingEmail,
      billingGST: billingGST,
      shippingBranchAddress: shippingBranchAddress,
      shippingContactPerson: shippingContactPerson,
      shippingContactNo: shippingContactNo,
      shippingGST: shippingGST,
      shippingBranchName: shippingBranchName,
      shippingEmail: shippingEmail,
    };
    var data1 = resp;
    data1.poHeader = hdr;

    console.log(`print mail status ${JSON.stringify(resp)}`);
    if (resp.poHeader["status"] === "3" || resp.poHeader["status"] === 3) {
      console.log(`print mail start`);

      this.setState({ printResp: data1, isMail: true, autoMail: true });
    } else {
      console.log(`print mail stop`);
      this.setState({ printResp: data1, isMail: false });
    }
  };

  handlePrintResp1 = (resp) => {
    var printHeaderValues = resp.poHeader;
    var modules = [];
    var lineItems = resp.poLineItemVo;
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var obj = {
          srlNo: key + 1,
          itemId: prop.itemId,
          juteType: prop.itemDesc,
          unit: prop.unitId,
          make: " ",
          indentNo: prop.id,
          bales_drums: prop.quantity,
          rate: prop.rate,
          disc: " ",
          vat: " ",
          excise: " ",
          Schrg: " ",
          delivery: "10 days",
        };
        modules.push(obj);
      });
    }
    console.log("lineitems", modules);
    var printTotalvalues = resp.totalValues;
    // this.setState({
    //     printHeaderValues:header,
    //     printLineItems:printLineItems,
    //     printTotalvalues:totalvalues,
    // })

    // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
    //     var printHeaderValues=this.state.printHeaderValues;
    // }
    var pageNo = 1;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.poDate;
    mr_date = mr_date + " ";
    var poId = printHeaderValues.id;
    poId = poId + " ";

    var brokerName = printHeaderValues.brokerName;
    brokerName = brokerName + " ";
    var frightPaid = printHeaderValues.frightPaid;
    frightPaid = frightPaid + " ";

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt == 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt == 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt == 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt == 3) {
      printcopy = "Triplicate";
    } else if (printCounnt == 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " " + printCounnt;
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth("PURCHASE ORDER") * doc.internal.getFontSize()) /
        2;
    doc.text("PURCHASE ORDER", xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(20, 70, "PURCHASE ORDER NO :" + poId);
    doc.setFontType("normal");
    doc.setFontType("bold");
    doc.text(400, 70, "PO DATE : ");
    doc.setFontType("normal");
    doc.text(470, 70, mr_date);
    doc.cellInitialize();
    doc.setFontSize(10);
    doc.setFontType("bold");

    var companyName = doc.splitTextToSize(
      "NELLIMARLA JUTE MILLS CO. LTD    ",
      200
    );
    var address1 = doc.splitTextToSize(
      "P.O  NELLIMARLA Dist: VIZAYANAGARAM, ANDRAPRADESH",
      250
    );
    var Pno = doc.splitTextToSize("PHONE NO : 73866556665", 250);
    var vatno = doc.splitTextToSize("VAT NO : 566844556", 250);
    var party = doc.splitTextToSize("Party Name", 200);

    doc.cell(20, 90, 250, 80, companyName, "", "C");

    doc.setFontSize(8);
    doc.setFontType("normal");

    doc.text(25, 120, address1);
    doc.text(25, 140, Pno);
    doc.text(25, 160, vatno);
    doc.setFontSize(10);
    doc.setFontType("bold");
    doc.cell(300, 90, 1, 1, " ", "", "right");
    doc.cellInitialize();

    doc.cell(320, 90, 250, 80, party, "", "C");

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text(330, 120, "BALAJI TRADING CO");
    doc.text(470, 120, "B076");
    doc.text(330, 140, vatno);

    doc.cellInitialize();
    var greeting = doc.splitTextToSize("Dear Sir/Madam,", 200);

    doc.cell(20, 180, 550, 100, greeting, "", "C");

    doc.cellInitialize();
    var srlno = doc.splitTextToSize("Srl No", 30);
    doc.cell(20, 290, 30, 40, srlno, "", "C");

    var item_code = doc.splitTextToSize("Item Code", 50);
    doc.cell(40, 290, 50, 40, item_code, "", "C");
    var desc = doc.splitTextToSize("Description", 50);
    doc.cell(80, 290, 70, 40, desc, "", "C");
    var unit = doc.splitTextToSize("Unit", 40);
    doc.cell(100, 290, 30, 40, unit, "", "C");
    var make = doc.splitTextToSize("Make", 50);
    doc.cell(130, 290, 60, 40, make, "", "C");
    var indentno = doc.splitTextToSize("Indent No/Srl No", 40);
    doc.cell(160, 290, 50, 40, indentno, "", "C");
    var quantity = doc.splitTextToSize("Quantity", 40);
    doc.cell(200, 290, 50, 40, quantity, "", "C");
    var moisture = doc.splitTextToSize("Rate", 40);
    doc.cell(230, 290, 50, 40, moisture, "", "C");
    var rate = doc.splitTextToSize("Disc", 40);
    doc.cell(260, 290, 30, 40, rate, "", "C");
    var stock = doc.splitTextToSize("VAT/CST", 20);
    doc.cell(290, 290, 30, 40, stock, "", "C");
    var schrg = doc.splitTextToSize("S Chrg", 30);
    doc.cell(320, 290, 30, 40, schrg, "", "C");
    var excise = doc.splitTextToSize("Excise", 30);
    doc.cell(320, 290, 30, 40, excise, "", "C");
    var delivery = doc.splitTextToSize("Delivery Schedule", 35);
    doc.cell(320, 290, 40, 40, delivery, "", "C");

    var cellWidths = ["", 30, 50, 70, 30, 60, 50, 50, 50, 30, 30, 30, 30, 40];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "left",
      "right",
      "left",
      "right",
      "right",
      "right",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 305;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 300;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var srlno = doc.splitTextToSize("Srl No", 30);
          doc.cell(20, 290, 30, 40, srlno, "", "C");

          var item_code = doc.splitTextToSize("Item Code", 50);
          doc.cell(40, 290, 50, 40, item_code, "", "C");
          var desc = doc.splitTextToSize("Description", 50);
          doc.cell(80, 290, 70, 40, desc, "", "C");
          var unit = doc.splitTextToSize("Unit", 40);
          doc.cell(100, 290, 30, 40, unit, "", "C");
          var make = doc.splitTextToSize("Make", 50);
          doc.cell(130, 290, 60, 40, make, "", "C");
          var indentno = doc.splitTextToSize("Indent No/Srl No", 40);
          doc.cell(160, 290, 50, 40, indentno, "", "C");
          var quantity = doc.splitTextToSize("Quantity", 40);
          doc.cell(200, 290, 50, 40, quantity, "", "C");
          var moisture = doc.splitTextToSize("Rate", 40);
          doc.cell(230, 290, 50, 40, moisture, "", "C");
          var rate = doc.splitTextToSize("Disc", 40);
          doc.cell(260, 290, 30, 40, rate, "", "C");
          var stock = doc.splitTextToSize("VAT/CST", 20);
          doc.cell(290, 290, 30, 40, stock, "", "C");
          var schrg = doc.splitTextToSize("S Chrg", 30);
          doc.cell(320, 290, 30, 40, schrg, "", "C");
          var excise = doc.splitTextToSize("Excise", 30);
          doc.cell(320, 290, 30, 40, excise, "", "C");
          var delivery = doc.splitTextToSize("Delivery Schedule", 35);
          doc.cell(320, 290, 40, 40, delivery, "", "C");
          /*  doc.cell(450, 310, 100, 20, '                 CLAIM FOR        ', '', 'C');
                      doc.cell(460, 310, 50, 20, ' ', '', 'C');*/
          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          /*   doc.cellInitialize();
                       doc.cell(372, 330, 50, 20, 'QUALITY', '', 'C');
                       doc.cell(500, 330, 50, 20, 'CONDITION', '', 'C');
                       doc.cell(550, 330, 50, 20, 'INITIAL', '', 'C');

                       doc.setFontType("normal");*/
          // doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemCode") {
              var size = 90;
            } else {
              var size = 45;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
          }
          return doc.cell(
            20,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    doc.cellInitialize();
    doc.setFontType("bold");
    var jobno = doc.splitTextToSize("Job No :    ", 200);
    var footerNote = doc.splitTextToSize("footer note  ...AS PER QTN    ", 200);
    var freedelivery = doc.splitTextToSize("FREE DELIVERY AT :", 200);
    var address = doc.splitTextToSize(
      "MITTAL TRANSPORT Co., 10A Chitpur Spur(Behind Bungur Building Kotkata 300 002)",
      200
    );

    doc.cell(20, k + 25, 550, 20, jobno, "", "C");
    doc.cellInitialize();
    doc.setFontType("normal");

    doc.cell(20, k + 45, 550, 50, footerNote, "", "C");
    doc.text(
      20,
      k + 105,
      "N.B : ITEM CODE, ORDER NO, INDENT NO MUST BE INDICATED ON CHALLAN"
    );
    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(20, k + 115, 250, 90, freedelivery, "", "C");
    doc.setFontType("normal");

    doc.text(20, k + 135, address);

    /*  doc.cellInitialize();
          doc.setFontType("bold");
          doc.cell(30, k + 25, 60, 30, ' ', '', 'right');
          doc.cell(105, k + 25, 60, 30, ' ', '', 'right');
          doc.cell(147, k + 25, 60, 30,' ', '', 'right');
          doc.cell(189, k + 25, 60, 30,  ' ', '', 'right');
          doc.cell(231, k + 25, 60, 30,  ' ', '', 'right');
          doc.cell(189, k + 25, 40, 30,  ' ', '', 'right');
          doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(322, k + 25, 60, 30, printHeaderValues.valueWithoutTax+'', '', 'right');*/

    doc.setFontType("normal");
    doc.setFontSize(10);

    //doc.text(400, k + 180, 'Authorised Signature');
    /*    doc.setFontSize(8);
            doc.setFontType("bold");
            var note='Note* : This is a computer generated print, Signature is not required.';
            var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
            doc.text(note, NotexOffset, k + 200);

            //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
            doc.setFontType("normal");
            doc.setFontSize(7);*/
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 250, newdat);
    doc.text(500, k + 250, printBy);
    addWaterMark(doc, printcopy);
    if (!this.state.sendMail) doc.save("PO_" + poId + ".pdf");
    else {
      const filename = "PO_" + poId + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      console.log("poId", this.state.poNo);

      const formData = new FormData();
      formData.append("poId", this.state.poNo);
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", getCookie(cons.COMPANY_ID));
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("fileName", filename);

      var URL = serverApi.PO_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handlePOMail);

      this.setState({
        sendMail: false,
      });
    }
  };
  handleMail = () => {
    this.setState({ isMail: false });
  };
  handlePOMail = (resp) => {
    console.log(resp);
    this.setState({
      sendMail: false,
      isMail: false,
    });
    if (!this.state.autoMail) {
      if (resp.status) {
        if (this.state.finalApprover && this.state.poStatus !== "3") {
          this.createShowMessage(this.state.finalApprovalMsg, resp.status);
          this.setState({
            finalApprovalMsg: "",
          });
        } else {
          this.createShowMessage(resp.message, false);
        }
      } else {
        if (this.state.finalApprover && this.state.poStatus !== "3") {
          this.createShowMessage(this.state.finalApprovalMsg, resp.status);
          this.setState({
            finalApprovalMsg: "",
          });
        } else {
          this.createShowMessage(resp.message, resp.status);
        }
        // this.createShowMessage(resp.message,resp.status)
      }
    } else {
      this.setState({
        finalApprovalMsg: "",
        autoMail: false,
      });
    }
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  textField: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },
  notchedOutline: {},
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(JutePO);
