import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Select from "react-select";

class SpinningQualityMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "qualityId",
      approveStatus: false,
      qualityId: "",
      qualityCode: null,
      qualityName: null,
      yarnCount: null,
      spindleCount: null,
      tarEff: null,
      /*"lrs":32.5,
            "leadLength":117,
            "finishedLength":113,*/
      tpi: null,
      speed: null,
      stdDoff: null,
      /* "stdstdDoff":10.582,
             "actualtarEff":6.79,*/
      stdDoffWt: null,
      TypeList: [],
      yarnType: "",
      machineType: "",
      jboRbo: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  componentDidMount() {
    getDataFromUrl(
      serverApi.YARN_MASTER_TYPE_LIST + getCookie(cons.COMPANY_ID),
      this.handleType
    );
  }
  handleType = (resp) => {
    var list = [];

    if (resp.status === undefined) {
      resp.forEach((prop) => {
        var obj = prop;
        if (prop.value !== 0) obj.value = prop.label;
        else obj.value = "";
        list.push(obj);
        console.log("Printing the get all state.......... ", prop.label);
      });
    }
    this.setState({
      TypeList: list,
      loadState: true,
    });
  };

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";

    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        qualityId: id,
        cipher: getCookie(cons.CIPHER),
      };
      requestList(
        serverApi.WEAVING_QUALITY_MASTER_DETAILS_BY_ID,
        data,
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    var o = resp.data;

    if (o !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        ledgerId: o.id,
        qualityId: o.qualityId,
        qualityCode: o.qualityCode,
        qualityName: o.qualityName,
        yarnCount: o.yarnCount,
        spindleCount: o.spindleCount,
        tarEff: o.tarEff,
        tpi: o.tpi,
        speed: o.speed,
        stdDoff: o.stdDoff,
        stdDoffWt: o.stdDoffWt,
        createdBy: o.createdBy,
        createdOn: o.createDateTime,
        yarnType: o.yarnType,
        machineType: o.machineType,
        jboRbo: o.jboRbo,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      companyId: getCookie(cons.COMPANY_ID),
      cipher: getCookie(cons.CIPHER),
      qualityType: 2,
    };
    return data;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onChangeNumberValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onChangeValues = (event, type) => {
    var value = event.target.value;
    console.log(value);
    if (type === "AN") {
      // if (/[^0-9a-zA-Z\s]/.test(value)) {
      //   value = this.state[event.target.id];
      // } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "N") {
      if (/[^0-9]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "D") {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      designationName: "",
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      qualityId: "",
      qualityCode: null,
      qualityName: null,
      yarnCount: null,
      spindleCount: null,
      tarEff: null,
      tpi: null,
      speed: null,
      stdDoff: null,
      stdDoffWt: null,
      yarnType: "",
      machineType: "",
      jboRbo: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        qualityId,
        qualityCode,
        qualityName,
        yarnCount,
        spindleCount,
        tarEff,
        tpi,
        speed,
        stdDoff,
        stdDoffWt,
        yarnType,
        machineType,
        jboRbo,
        frameType,
        shots,
      } = this.state;
      var data = {
        qualityId: qualityId,
        qualityCode: qualityCode,
        qualityName: qualityName,
        yarnCount: parseFloat(yarnCount),
        spindleCount: parseFloat(spindleCount),
        tarEff: parseFloat(tarEff),
        shots: 0,
        /*"lrs":32.5,
                "leadLength":117,
                "finishedLength":113,*/
        tpi: parseFloat(tpi),
        speed: speed,
        stdDoff: parseFloat(stdDoff),
        /* "stdstdDoff":10.582,
                 "actualtarEff":6.79,*/
        stdDoffWt: parseFloat(stdDoffWt),
        createdBy: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        qualityType: 2,
        yarnType: yarnType,
        machineType: machineType,
        jboRbo: jboRbo,
      };

      if (
        yarnType !== undefined &&
        yarnType !== "" &&
        yarnCount !== undefined &&
        yarnCount !== "" &&
        spindleCount !== undefined &&
        spindleCount !== "" &&
        tarEff !== undefined &&
        tarEff !== "" &&
        tpi !== undefined &&
        tpi !== "" &&
        speed !== undefined &&
        speed !== "" &&
        stdDoff !== undefined &&
        stdDoff !== "" &&
        stdDoffWt !== undefined &&
        stdDoffWt !== "" &&
        machineType !== undefined &&
        machineType !== "" &&
        qualityName !== undefined &&
        qualityName !== "" &&
        qualityCode !== "" &&
        qualityCode !== undefined
      ) {
        this.handleLoading();
        requestList(
          serverApi.WEAVING_QUALITY_MASTER_UPDATE,
          data,
          this.handleAddTax
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        qualityCode,
        qualityName,
        yarnCount,
        spindleCount,
        tarEff,
        tpi,
        speed,
        stdDoff,
        stdDoffWt,
        yarnType,
        machineType,
        jboRbo,
      } = this.state;
      var data = {
        qualityCode: qualityCode,
        qualityName: qualityName,
        yarnCount: parseFloat(yarnCount),
        spindleCount: parseFloat(spindleCount),
        tarEff: parseFloat(tarEff),
        /*"lrs":32.5,
                "leadLength":117,
                "finishedLength":113,*/
        tpi: parseFloat(tpi),
        speed: speed,
        stdDoff: parseFloat(stdDoff),
        /* "stdstdDoff":10.582,
                "actualtarEff":6.79,*/
        stdDoffWt: parseFloat(stdDoffWt),
        //createdBy: getCookie(cons.USERID),
        companyId: getCookie(cons.COMPANY_ID),
        qualityType: 2,
        yarnType: yarnType,
        machineType: machineType,
        jboRbo: jboRbo,
      };
      console.log("state", this.state);

      if (
        yarnType !== "" &&
        jboRbo !== "" &&
        machineType !== "" &&
        stdDoff !== "" &&
        stdDoffWt !== null &&
        stdDoffWt !== "" &&
        yarnCount !== "" &&
        tpi !== "" &&
        speed !== "" &&
        spindleCount !== "" &&
        tarEff !== "" &&
        qualityCode !== "" &&
        qualityName !== ""
      ) {
        this.handleLoading();
        requestList(
          serverApi.WEAVING_QUALITY_MASTER_ADD,
          data,
          this.handleAddTax
        );
        //alert(stdDoffWt);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddTax = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    const inputFocus = {
      autoComplete: "off",
    };

    const options = [
      {
        value: 0,
        label: "Select...",
        name: "Select...",
      },
      {
        value: "JBO",
        label: "JBO",
        name: "JBO",
      },
      {
        value: "RBO",
        label: "RBO",
        name: "RBO",
      },
    ];

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  {/* {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Quality Id
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            diabled={true}
                            id="qualityId"
                            value={this.state.qualityId}
                            className={classes.textField}
                            onChange={(event) => {
                              this.onChangeValues(event, "AN");
                            }}
                            fullWidth={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null} */}

                  {/* quality code and quality name added */}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Quality Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          diabled={true}
                          id="qualityCode"
                          value={this.state.qualityCode}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Quality Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="qualityName"
                          value={this.state.qualityName}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          onSelect={this.onSelect}
                          defValue={this.state.yarnType}
                          getName={(name) => {
                            var val = name.split("^");
                            if (!this.state.updateView)
                              this.setState({
                                stateCode: val[1] !== "null" ? val[1] : "",
                              });
                          }}
                          stateKey="yarnType"
                          staticData={this.state.TypeList}
                          isRefresh={this.state.loadState}
                          setIsRefresh={(val) => {
                            this.setState({ loadState: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Frame Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.machineType}
                          id={"machineType"}
                          onSelect={this.onSelect}
                          stateKey="machineType"
                          url={serverApi.SELECT_SPINNING_MACHINE_TYPE + "/SPN"}
                          value={this.state.machineType}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          JBO/RBO
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"jboRbo"}
                          stateKey="jboRbo"
                          defValue={this.state.jboRbo}
                          onSelect={this.onSelect}
                          staticData={options}
                          value={this.state.jboRbo}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Count</InputLabel>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="yarnCount"
                          value={this.state.yarnCount}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          /*
                                                    onChange={(event)=>{
                                                        if(!Number.isInteger(Number(event.target.value)))
                                                            this.setState({
                                                                width:Number(event.target.value)
                                                            });
                                                       else if(event.target.value==='')
                                                            this.setState({
                                                                width:0.0
                                                            });

                                                    }}
*/
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Spindle
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="spindleCount"
                          value={this.state.spindleCount}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          /*
                                                                                                        onChange={(event)=>{
                                                                                                            if(!Number.isInteger(Number(event.target.value)))
                                                                                                                this.setState({
                                                                                                                    spindleCount:Number(event.target.value)
                                                                                                                });
                                                                                                            else if(event.target.value==='')
                                                                                                                this.setState({
                                                                                                                    spindleCount:0.0
                                                                                                                });

                                                                                                        }}
                                                    */
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          TarEff
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="tarEff"
                          value={this.state.tarEff}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          /*
                                                                                                        onChange={(event)=>{
                                                                                                            if(!Number.isInteger(Number(event.target.value)))
                                                                                                                this.setState({
                                                                                                                    tarEff:Number(event.target.value)
                                                                                                                });
                                                                                                            else if(event.target.value==='')
                                                                                                                this.setState({
                                                                                                                    tarEff:0.0
                                                                                                                });
                                                                                                        }}
                                                    */
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          TPI
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="tpi"
                          value={this.state.tpi}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Speed
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="speed"
                          value={this.state.speed}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          StdDoff
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="stdDoff"
                          value={this.state.stdDoff}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          StdWtDoff
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="stdDoffWt"
                          value={this.state.stdDoffWt}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.WEAVING_QUALITY_MASTER}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={false}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(SpinningQualityMaster);
