import React from "react";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {configNames, getConfigValue, serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";


class BrokerMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "brokerId",
            brokerId:"",
            brokerName:"",
            address:"",
            email:"",
            pan:"",
            gst:"",
            supplierList:[],
            type:"JUTE"
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    handleItems =(resp)=>{
        var list=[];
        resp.map((prop,key)=>{
            var val={
                id:0,
                supplierId:prop.value,
                supplierName:prop.label,
                isEnabled:false
            };
            if(this.state.updateView){
                this.state.supplierList.map((o,i)=>{

                    if(o.suppCode===val.supplierId){
                        val.isEnabled=true;
                        val.id=o.mapId
                    }
                })
            }

            if(key>0)
                list.push(val)
        });
        this.setState({
            supplierList:list
        });
        this.handleLoading();
    };


    handleViewClick = (id) => {

        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data =  {
                "userId": getCookie(cons.USERID),
                "cipher":getCookie(cons.CIPHER),
                "broker":{"brokerId":id}
            };
            requestList(serverApi.BROKER_MASTER_DETAILS_BY_ID,data,this.handleViewClickResp)
        }
    };

    handleViewClickResp = (resp) => {
        var header = resp.data;

        if(resp.status){
            if (header !== null) {
                this.setState({
                    updateView: true,
                    isCreated: true,
                    createdBy: header.createdBy,
                    createdOn: header.createdOn,
                    brokerId:header.brokerId,
                    brokerName:header.brokerName,
                    address:header.address,
                    email:header.email,
                    pan:header.pan,
                    gst:parseInt(header.gst),
                    supplierList:header.supplierDtl,
                    type:header.type!==null?header.type:"JUTE"

                });
                this.handleLoading();
                getDataFromUrl(serverApi.SEARCH_SUPPLIER+"/"+getCookie(cons.COMPANY_ID),this.handleItems)

            }

        }/*else {
            this.showMessage(resp.message,resp.status);
        }*/


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
            this.handleLoading();
            getDataFromUrl(serverApi.SEARCH_SUPPLIER+"/"+getCookie(cons.COMPANY_ID),this.handleItems)

        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data = {
            "userId":getCookie(cons.USERID),
            "companyId":getCookie(cons.COMPANY_ID),
            "cipher":getCookie(cons.CIPHER)
        };
        return data;
    };



    onChangeValues = (event) => {
        var value = event.target.value;

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {

        this.setState({
            [stateKey]: selectedValue
        });
    };


    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }

    hideAlertMessage() {
        this.setState({

            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            deptName:"",
            createdBy:"",
            createdOn: "",
            brokerId:"",
            brokerName:"",
            address:"",
            email:"",
            pan:"",
            gst:"",
            supplierList:[],
            type:"JUTE"
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    onUpdateButtonClick = () => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {brokerName,address,email,pan,gst,brokerId,supplierList,type}=this.state;
            var list=[];
            if(type!=="SALE")
            supplierList.map((prop,i)=>{
                var obj={
                    "suppCode":prop.supplierId,"isMapped":prop.isEnabled+""
                };
                if(prop.isEnabled)
                    list.push(obj)
            });

            var data = {
                "broker": {
                    "brokerId":brokerId,
                    "brokerName": brokerName,
                    "address":address,
                    "email":email,
                    "pan":pan,
                    "gst":gst,
                    "companyId": getCookie(cons.COMPANY_ID),
                    "supplierDtl":list,
                    "type":type
                },
                "postAuthenticationDto": {
                    "userId":getCookie(cons.USERID),
                    "cipher":getCookie(cons.CIPHER)
                }
            };
            if (brokerName !== '' && address !== "" && email !== "") {
                this.handleLoading();
                requestList(serverApi.BROKER_MASTER_UPDATE, data, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {brokerName,address,email,pan,gst,supplierList,type}=this.state;
            var list=[];
            if(type!=="SALE")
            supplierList.map((prop,i)=>{
                var obj={
                    "suppCode":prop.supplierId,"isMapped":prop.isEnabled
                };
                if(prop.isEnabled)
                    list.push(obj)
            });

            var data = {
                    "broker": {
                        "brokerName": brokerName,
                        "address":address,
                        "email":email,
                        "pan":pan,
                        "gst":gst,
                        "companyId": getCookie(cons.COMPANY_ID),
                        "supplierDtl":list,
                        "type":type
                    },
                "postAuthenticationDto": {
                    "userId":getCookie(cons.USERID),
                    "cipher":getCookie(cons.CIPHER)
                }
            };
            if(type==="SALE"){
                if (brokerName !== '' && address !== "" && email !== "") {
                    this.handleLoading();
                    requestList(serverApi.BROKER_MASTER_ADD, data, this.handleAddIndent)
                }
                else {
                    this.basicAlert("Please Enter All Mandatory Fields..!!");
                }

            }
            else{
                if (brokerName !== '' && address !== "" && email !== ""&&list.length>0) {
                    this.handleLoading();
                    requestList(serverApi.BROKER_MASTER_ADD, data, this.handleAddIndent)
                }
                else {
                    this.basicAlert("Please Enter All Mandatory Fields..!!");
                }

            }
            
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };



    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleChange= (index )=> event => {

        var arr=this.state.supplierList;
        arr[index].isEnabled=!arr[index].isEnabled;
        this.setState({
            supplierList:arr
        })

    };


    render() {
        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        };
        const ucoptions = [];

        var taxTypes=getConfigValue(configNames.BROKER_TYPES);
        if(taxTypes!=="1"){
            taxTypes=JSON.parse(taxTypes);
            if(taxTypes.jute===1){
                ucoptions.push({value: "JUTE", label: 'JUTE', name: 'JUTE'});
            }
            if(taxTypes.sales===1){
                ucoptions.push({value: "SALE", label: 'SALE', name: 'SALE'});
            }
        }else {
            ucoptions.push({value: "JUTE", label: 'JUTE', name: 'JUTE'});
            ucoptions.push({value: "SALE", label: 'SALE', name: 'SALE'});
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {

                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}


                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    BrokerName Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="brokerName"
                                                    value={this.state.brokerName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        BrokerId<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="brokerId"
                                                        value={this.state.brokerId}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}


                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null
                                    }
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Address<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="address"
                                                    value={this.state.address}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Email<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="email"
                                                    value={this.state.email}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    PAN No.
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="pan"
                                                    value={this.state.pan}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>



                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Broker Type<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"type"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.type}
                                                    stateKey="type"
                                                    staticData={ucoptions}
                                                />
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>



                                    {this.state.type==="JUTE"?
                                        <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={2}>
                                                <InputLabel className={classes.label}>
                                                    Supplier List<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={10} >
                                                <GridContainer>

                                                    {this.state.supplierList.map((prop,key)=>{
                                                        return(
                                                            <ItemGrid xs={12} sm={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            tabIndex={-1}
                                                                            value={prop.isEnabled?1:0}
                                                                            checked={prop.isEnabled?1:0}
                                                                            onChange={
                                                                                this.handleChange(key)
                                                                            }
                                                                            // onClick={() => this.handleToggle(prop)}
                                                                            checkedIcon={
                                                                                <Check className={classes.checkedIcon} />
                                                                            }
                                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                                            classes={{
                                                                                checked: classes.checked
                                                                            }}
                                                                            style={{marginLeft:10}}

                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classes.menulabel
                                                                    }}
                                                                    label={prop.supplierName}
                                                                />

                                                            </ItemGrid>

                                                        )
                                                    })}
                                                </GridContainer>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}

                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            {!this.state.updateView ?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>:
                                                <Button color="primary"
                                                        onClick={this.onUpdateButtonClick}>Update</Button>

                                            }
                                            <ItemGrid>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            <TableComponent
                                                url={serverApi.BROKER_MASTER_TABLE_LIST}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(BrokerMaster);
