import React from "react";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Select from 'react-select';



class QualityMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "id",
            item: "",
            qualityName: "",
            rate: "",
            currency: "",
            lpRate: "",
            stock: "",
            allowableMoisture: "",
            openedWeight: "",
            qualityOp: [],
            dependantQualityId: "",
            difference: ""

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }


    componentDidMount() {
        var data1 = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.QUALITY_MASTER_DATA_TABLE, data1, this.handleData)
        var data = {
            "itemGroupId": "999",
            "saleable": "",
            "consumable": "",
            "companyId": getCookie(cons.COMPANY_ID),
            "finshedSaleable": "Y"
        };
        requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
    }

    handleData = (resp) => {
        var list = resp.data;
        var tabData = []
        list.forEach((prop, key) => {
            var obj = {
                value: prop.id,
                label: prop.juteQuality,
                name: prop.juteQuality
            }
            tabData.push(obj)
        })
        this.setState({
            tqualityOp: tabData
        })
        //console.log("MMMMMMMMMMMMM",this.state.tqualityOp)
    }

    handleItem = (resp) => {
        this.setState({ ItemOptions: resp })

    }
    handleViewClick = (id) => {    

        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var data = {
                "id": id,
                "cipher": getCookie(cons.CIPHER)
            };
            requestList(serverApi.QUALITY_MASTER_DETAILS_BY_ID, data, this.handleViewClickResp)
        }
        var data1 = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.QUALITY_MASTER_DATA_TABLE, data1, this.handleData)
    };

    handleViewClickResp = (resp) => {
        var header = resp.data;

        if (resp.status) {
            if (header !== null) {
                this.setState({
                    updateView: true,
                    isCreated: true,
                    qualityCode: header.id,
                    createdBy: header.createdBy,
                    createdOn: header.createdOn,
                    item: header.itemCode,
                    qualityName: header.juteQuality,
                    rate: header.rate,
                    currency: header.currency,
                    lpRate: header.lastPurchaseRate,
                    stock: header.stock,
                    allowableMoisture: header.allowableMoisture,
                    openedWeight: header.op2019,
                    batchCategory: header.batchCategory,
                    inputRate: header.inputRate,
                    dependantQualityId: header.dependantQualityId,
                    difference: header.difference
                });

            }

        } else {
            this.showMessage(resp.message, resp.status);
        }


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        var data1 = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.QUALITY_MASTER_DATA_TABLE, data1, this.handleData)
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data = {
            "companyId": getCookie(cons.COMPANY_ID),
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };



    onChangeValues = (event) => {
        var value = event.target.value;

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        this.setState({
            [stateKey]: selectedValue
        });
    };


    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }

    hideAlertMessage() {
        this.setState({

            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            deptCode: "",
            deptName: "",
            createdBy: "",
            createdOn: "",
            item: "",
            qualityName: "",
            rate: "",
            currency: "",
            lpRate: "",
            stock: "",
            allowableMoisture: "",
            openedWeight: "",
            batchCategory: "",
            inputRate: "",
            difference: "",
            dependantQualityId: ""


        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red" }}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const { item, qualityName, rate, currency, lpRate, stock, allowableMoisture, openedWeight, batchCategory, dependantQualityId, inputRate, difference } = this.state;

            var data = {
                "juteQualityPriceMaster": {
                    "id": this.state.qualityCode,
                    "itemCode": item + "",
                    "juteQuality": qualityName,
                    "rate": parseFloat(rate),
                    "currency": currency,
                    "lastPurchaseRate": parseFloat(lpRate),
                    "stock": parseInt(stock),
                    "createdBy": getCookie(cons.USERID),
                    "allowableMoisture": parseInt(allowableMoisture),
                    "op2019": parseInt(openedWeight),
                    "batchCategory": batchCategory,
                    "inputRate": inputRate,
                    "dependantQualityId": dependantQualityId,
                    "difference": difference,
                },
                "postAuthenticationDto": {
                    "companyId": getCookie(cons.COMPANY_ID),
                    "cipher": getCookie(cons.CIPHER)
                }

            };
            if (item !== '' && item !== "0" && qualityName !== "" && rate !== '' && inputRate !== "") {
                this.handleLoading();
                requestList(serverApi.QUALITY_MASTER_UPDATE, data, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const { item, qualityName, rate, currency, lpRate, stock, allowableMoisture, openedWeight, batchCategory, dependantQualityId, inputRate, difference } = this.state;

            var data = {
                "juteQualityPriceMaster": {
                    "itemCode": item + "",
                    "juteQuality": qualityName,
                    "rate": parseFloat(rate),
                    "currency": currency,
                    "lastPurchaseRate": parseFloat(lpRate),
                    "stock": parseInt(stock),
                    "createdBy": getCookie(cons.USERID),
                    "allowableMoisture": parseInt(allowableMoisture),
                    "op2019": parseInt(openedWeight),
                    "batchCategory": batchCategory,
                    "inputRate": inputRate,
                    "dependantQualityId": dependantQualityId,
                    "difference": difference,
                },
                "postAuthenticationDto": {
                    "companyId": getCookie(cons.COMPANY_ID),
                    "cipher": getCookie(cons.CIPHER)
                }
            };
            if (item !== '' && item !== "0" && qualityName !== "" && rate !== '' && inputRate !== "") {
                if (inputRate === "N") {
                    if (dependantQualityId !== "" && difference !== "") {
                        this.handleLoading();
                        requestList(serverApi.QUALITY_MASTER_ADD, data, this.handleAddIndent)
                    }
                    else {
                        this.basicAlert("Please Enter All Mandatory Fields..!!");
                    }
                }
                else {
                    this.handleLoading();
                    requestList(serverApi.QUALITY_MASTER_ADD, data, this.handleAddIndent)
                }
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };



    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{ textAlign: "center" }}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    render() {
        const { classes } = this.props;
        const inpvalue = [
            { value: null, label: 'Select..' },
            { value: 'Y', label: 'YES', name: 'YES' },
            { value: "N", label: 'NO', name: 'NO' }
        ];

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {

                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}



                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"item"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.item}
                                                    stateKey="item"
                                                    staticData={this.state.ItemOptions}
                                                    // url={serverVars.MASTER_API + "itemmaster/getItemsByItemGroupId/999/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.item}
                                                />
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Quality Name<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="qualityName"
                                                    value={this.state.qualityName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Rate<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rate"
                                                    value={this.state.rate}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    {/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Currency<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="currency"
                                                    value={this.state.currency}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                                    {/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Last Purchase Rate
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="lpRate"
                                                    value={this.state.lpRate}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                                    {/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Stock
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="stock"
                                                    value={this.state.stock}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Allowable Moisture
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="allowableMoisture"
                                                    value={this.state.allowableMoisture}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Batch Category
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="batchCategory"
                                                    value={this.state.batchCategory}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Input Type<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"inputRate"}
                                                    stateKey="inputRate"
                                                    defValue={this.state.inputRate}
                                                    onSelect={this.onSelect}
                                                    staticData={inpvalue}
                                                    value={this.state.inputRate}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    {this.state.inputRate == "N" ?
                                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label} >
                                                        Rate<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <SelectboxTemp
                                                        id={"dependantQualityId"}
                                                        stateKey="dependantQualityId"
                                                        staticData={this.state.tqualityOp}
                                                        onSelect={this.onSelect}
                                                        placeholder={"Select Quality"}
                                                        defValue={this.state.dependantQualityId}
                                                        value={this.state.dependantQualityId}

                                                    />
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={1}>
                                                    <InputLabel className={classes.label} style={{ color: "red" }}>+</InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={3}>
                                                    <TextField
                                                        id={"difference"}
                                                        value={this.state.difference}
                                                        onChange={this.onChangeValues}
                                                        iType={"number"}
                                                    />
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid> : null}




                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                        <GridContainer justify="center">
                                            {!this.state.updateView ?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                        onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid> :
                                                <Button color="primary"
                                                    onClick={this.onUpdateButtonClick}>Update</Button>

                                            }
                                            <ItemGrid>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            } />

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon />
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                            style={{
                                                height: 2,
                                                backgroundColor: "#00acc1",
                                                marginTop: "5px"
                                            }}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            <TableComponent
                                                url={serverApi.QUALITY_MASTER_DATA_TABLE}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(QualityMaster);
