import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
import Select from 'react-select';
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from '../../components/Popup/PopUp';
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {configNames, getConfigValue, serverApi, serverConfig} from "../../helper/Consts";
import {VIEW} from "../../ui-actions/UiActions";
import moment from "moment";
import {getDataFromUrl, getFileDownload, requestList, uploadFile} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import cons from "../../helper/Consts";
import InputAdornment from "material-ui/Input/InputAdornment";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Reading from "../../assets/icons/gauge.png";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import LineItemTable from "../CustumComponents/LineItemTable";


class JuteQualityCheck extends React.Component {
    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        this.setState({ [event.target.name]: event.target.value});
    };

    constructor(props) {
        super(props);
        this.state = {
            isCreated: false,
            fromDate:"",
            todate:"",
            status:'',
            vehicleNo:'',
            gateEntryNO:'',
            selectMukam:"",
            supplierId:'',
            brokerId:'',
            poNo:'',
            itemViewType:"gateEntryNum",
            entryNo:'',
            updateView:false,
            showMessagealert:false,
            alert:false,
            basicAlert:null,
            indentAlert:false,
            selectedPO:"",
            selectedDepartment:"",
            selectedItemGroup:"",
            selectedItem:"",
            quantity:"",
            itemGrpName:"",
            itemName:"",
            deptName:"",
            selectedJuteQuality:"",
            qualityName:"",
            uom:"",
            stopName:"",
            readingAlert:false,
            chalanNo:"",
            chalanDate:moment().format('DD-MM-YYYY'),
            vehicleNum:"",
            driverName:"",
            SelectedChalanType:"",
            selectedSupplier:"",
            selectedMukam:"",
            transporter:"",
            readingList:[{id:0,reading:0}],
            lineItemsReadings:[],
            grossWeightKG:0,
            grossWeightQTL:0,
            chalanWeightKG:0,
            chalanWeightQTL:0,
            tareWeightKG:0,
            tareWeightQTL:0,
            actualWeightKG:0,
            actualWeightQTL:0,
            remarks:"",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            time1:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
            outDate:"",
            outTime:"",
            invoiceList:[],
            branchId:"",
            filesList:[],
            itemfilesList:[]
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeReadingValues = this.onChangeReadingValues.bind(this);
        this.hideReadingAlert = this.hideReadingAlert.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    getDefaultValue = i => {
        var arr = this.state.invoiceList;
        return arr[i];
    };

    hideAlert() {
        this.setState({
            updateItem:false,
            id:0,
            alert: false,
            loading:false,
            indentAlert:false,
            showMessagealert:false,
            selectedDepartment:"",
            selectedItemGroup:"",
            selectedItem:"",
            quantity:"",
            selectedJuteQuality:"",
            qualityName:"",
            readingList:[{id:0,reading:0}],
            avgReadings:0,
            readingAlert:false,
            weight:'',
            advisedJuteTyp:"",
            advisedQuality:"",
            advisedQuantity:"",
            advisedWeight:"",
            itemfilesList:[]

        });
    }
    hideAlertMessage =()=>{
        console.log("hide ",true);
        this.setState({

            isCreated:false,
            updateView:false,
            showMessagealert:false,
            alert:false,
            basicAlert:null,
            indentAlert:false,
            selectedPO:"",
            unitConversionName:"",
            selectedDepartment:"",
            selectedItemGroup:"",
            selectedItem:"",
            quantity:"",
            itemGrpName:"",
            itemName:"",
            deptName:"",
            uom:"",
            readingList:[{id:0,reading:0}],
            avgReadings:0,
            chalanNo:"",
            chalanDate:moment().format('DD-MM-YYYY'),
            vehicleNum:"",
            driverName:"",
            SelectedChalanType:"",
            selectedSupplier:"",
            selectedMukam:"",
            selectedJuteQuality:"",
            qualityName:"",
            transporter:"",
            remarks:"",
            grossWeightKG:0,
            grossWeightQTL:0,
            chalanWeightKG:0,
            chalanWeightQTL:0,
            tareWeightKG:0,
            tareWeightQTL:0,
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            time1:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
            invoiceList:[],
            weight:'',
            advisedJuteTyp:"",
            advisedQuality:"",
            advisedQuantity:"",
            advisedWeight:"",
            qcCheck:"",
            branchId:"",
            itemfilesList:[]
        });

    };

    handleViewClick =(id)=>{
        console.log("jute  "+"  "+id);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denined. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.GET_JUTE_GATE_ENTRY + id+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
        }
    };
    handleViewClickResp = (resp) => {
        var header = resp.entryHeader;
        var list = resp.juteGateLineItems;
        var readings =[""];
        var indentlist = list.map((prop,key)=>{

            if(prop.readings.length > 0)
                readings =prop.readings;
            else
                readings=[{id:0,reading:0}];
            return(
                {
                    id:key,
                    dataId:prop.recId,
                    readings:prop.avgReadings,
                    item:prop.qcJuteTypeDesc,
                    quantity:prop.qcJuteQuantity,
                    unit: prop.uom,
                    qualityCode:parseInt(prop.qcJuteQuality),
                    quality:prop.qcJuteQualityDesc,
                    deptId:prop.dept,
                    itemGroupId:prop.itemGroupId,
                    itemId:parseInt(prop.qcJuteType),
                    weight:prop.qcJuteWeight,
                    advisedJuteTyp:prop.advisedJuteTyp,
                    advisedQuality:prop.advisedQuality,
                    advisedQuantity:prop.advisedQuantity,
                    advisedWeight:prop.advisedWeight,
                    readingsList:readings,
                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            console.log("o.readingsList",o.readingsList);

                                           // getDataFromUrl(serverApi.GET_JUTE_READINGS_BY_LINE_ITEM+this.state.selectedPO+"/"+o.dataId,this.handleViewReadingsResp);

                                            this.setState({
                                                readingAlert:true,
                                                id:o.id,
                                                updateItem: true,
                                                lineItemId:o.dataId,
                                              /*  deptName:o.department,
                                                itemGrpName:o.itemGroup,
                                                itemName:o.item,
                                                uom:o.unit,
                                                stock: o.stockValue,
                                                quantity:o.quantity,
                                                selectedDepartment: o.deptId,
                                                selectedItemGroup:o.itemGroupId,
                                                selectedItem:o.itemId,
                                                selectedJuteQuality:o.qualityCode,
                                                qualityName:o.quality,*/
                                                avgReadings:o.readings,
                                                readingList:o.readingsList,
                                            });

                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                               <img src={Reading} style={{height:20,width:20}}/>
                            </IconButton>{""}
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        this.basicItemAlert("Do You Like To Update This Item?");

                                        if(o.id === key){
                                            this.setState({
                                               // alert:true,
                                                id:o.id,
                                                lineItemId:o.dataId,
                                                updateItemAlert:true,
                                                updateItem: true,
                                                deptName:o.department,
                                                itemGrpName:o.itemGroup,
                                                itemName:o.item,
                                                uom:o.unit,
                                                stock: o.stockValue,
                                                quantity:o.quantity,
                                                selectedDepartment: o.deptId,
                                                selectedItemGroup:o.itemGroupId,
                                                selectedItem:o.itemId,
                                                selectedJuteQuality:o.qualityCode,
                                                qualityName:o.quality,
                                                avgReadings:o.readings,
                                                readingList:o.readingsList,
                                                weight:o.weight,
                                                advisedJuteTyp:o.advisedJuteTyp,
                                                advisedQuality:o.advisedQuality,
                                                advisedQuantity:o.advisedQuantity,
                                                advisedWeight:o.advisedWeight,
                                            });
                                            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"21/"+o.dataId+"/"+getCookie(cons.COMPANY_ID),this.handleItemInvoiceFiles);


                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>{""}
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key) {
                                          //  if (this.state.approveStatus) {

                                                this.setState({deleteItem: true,
                                                    id:o.id,});

                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                //  data.splice(i,1);
                                           // }
                                            return true;
                                        }
                                    });
                                   // this.setState({invoiceList: data});
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>
                        </div>

                    )
                }
            )
        });
     //   console.log("indentWeight "+header.indentWeight);
        if(header !== null){
            this.setState({
                updateView:true,
                isCreated:true,
                approveStatus:header.approveStatus,
                entryNo:header.id,
                chalanNo:header.chalanNo,
                chalanDate:header.challanDateDesc,
                vehicleNum:header.vehicleNo,
                driverName:header.driverName,
                transporter:header.transporter,
                selectedMukam:parseInt(header.mukam),
                selectedSupplier:header.suppCode,
                supplierName:header.supplierName,
                remarks:header.remarks,
                date1:header.createdDate,
                time1:header.createdTime,
                time2:header.createdTime,
                outDate:header.outDateDesc,
                outTime:header.outTimeDesc,
                //year:header.finYear,
                selectedPO:header.poNo,
                createdBy:header.createdBy,
                tareWeightQTL:header.tareWeight,
                grossWeightQTL:header.grossWeight,
                actualWeightQTL:header.actualWeight,
                chalanWeightQTL:header.challanWeight,
                tareWeightKG:parseFloat(header.tareWeight)*100,
                grossWeightKG:parseFloat(header.grossWeight)*100,
                actualWeightKG:parseFloat(header.actualWeight)*100,
                chalanWeightKG:parseFloat(header.challanWeight)*100,
                unitConversionName:header.unitConversion,
                unitConversion:this.getDefaultUnitConvertion(header.unitConversion),
                invoiceList:indentlist,
                qcCheck:header.qcCheck,
                branchId:header.branchId
            });
            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"20/"+header.id+"/"+getCookie(cons.COMPANY_ID),this.handleInvoiceFiles);

        }
    };
    handleItemInvoiceFiles=(resp)=>{
        var list=[];
        if(resp.data){
            list=resp.data;
            this.setState({itemfilesList:list})
        }
    };

    handleInvoiceFiles=(resp)=>{
        var list=[];
        if(resp.data){
            list=resp.data;
            if(this.state.filesList.length>0)
                list.push(...this.state.filesList);
            this.setState({filesList:list})
        }
    };

    saveReading() {
       // console.log("readingList",JSON.stringify(this.state.readingList));
        var readings=0,i=0,list2=[];
        var list=this.state.readingList.map(r=>{
            if(r.reading!==''&&r.reading!==0&&r.reading!==null&& r.reading!==undefined){
                i=i+1;
                readings =parseFloat(r.reading)+readings;
                list2.push(r);
                return r;
            }else {
                r.reading=0;
                return r;
            }
        });
        var  data = this.state.invoiceList;
        data[this.state.id].readingsList=list;
        data[this.state.id].readings = parseFloat(readings/i).toFixed(2);
       if(list2.length>=3){
           this.setState({
               invoiceList:data,
               readingList:[],
           });
           this.hideAlert();
       }else {
           this.basicAlert("Atleast 3 Readings Mandatory..!!")
       }
      //
    }
    handleCreate=()=>{
        if( !this.state.isCreated)
            this.hideAlertMessage();

        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data={
            "frmDt":this.state.fromDate,
            "toDt":this.state.todate,
            "status":this.state.status,
            "supCode":this.state.supplierId,
            "gateEntryNum":this.state.gateEntryNO,
            "vehicleNum":this.state.vehicleNo,
            "companyId":getCookie(cons.COMPANY_ID),
            "cipher":getCookie(cons.CIPHER),
            "financialYear": getCookie(cons.ACADEMIC_YEAR),
        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id] :''
            });

    };
    timeChanged1 = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('hh:mm:ss A'),
                time2:dateObj.format('HH:mm:ss')
            });
    };
    //dateFormate
    dateChanged = (myDate) => {
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };
    timeChangedFormat = (myDate,time) => {
        console.log("time",myDate + time);
        // var time = new Date(myDate+" "+time).getTime();
        //var localDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2], timeParts[0], timeParts[1]);
        // console.log("time",time)
        myDate=myDate.split("-");
        var timeParts = time.split(":");
        // var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2] + timeParts[0]+ timeParts[1]+timeParts[2];
        return new Date(myDate[2], myDate[1]-1, myDate[0], timeParts[0], timeParts[1],timeParts[2]).getTime();
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };
    getDefaultReadingValue = i => {
        var arr = this.state.readingList;
        return arr[i]!==undefined?arr[i].reading:"";
    };
    onChangeReadingValues(event) {
        var arr = this.state.readingList;

      //  if(arr[ event.target.id]!==undefined)
        arr[event.target.id].reading = event.target.value;

        var data={
            id:0,
            reading:0
        };
        console.log("rea list",parseInt(event.target.id)+1);

        if(arr[ event.target.id].reading!==0&&  event.target.value.length===1 && arr[parseInt(event.target.id)+1]===undefined){
            arr.push(data);
        }
        this.setState({
            readingList:arr
        });

    }

    onChangeValues=(event)=> {
        var value= event.target.value;
        if(event.target.id === 'grossWeightKG' ){
            if(value !=='')
                this.setState({
                    grossWeightQTL:parseFloat(parseInt(value)/100).toFixed(2)
                }) ;
            else
                this.setState({
                    grossWeightQTL:''
                });
        }
        if(event.target.id === 'chalanWeightKG'){
            if(value !=='')
                this.setState({
                    chalanWeightQTL:parseFloat(parseInt(value)/100).toFixed(2)
                });
            else
                this.setState({
                    chalanWeightQTL:''
                });
        }
        if (event.target.id === "quantity") {

            if (value !== null && value !== "") {
                if (this.state.unitConversion.value === 2) {
                    var calWeight =parseFloat(parseFloat(value) * 150 / 100).toFixed(2);
                    this.setState({
                        weight: calWeight,
                    });

                }
            } else {
                this.setState({
                    weight: '',
                });
            }
        }

        this.setState({
            [event.target.id]: value
        });
    };
    getActualWeight=()=>{
        var tare = this.state.tareWeightKG;
        var gross = this.state.grossWeightKG;
        if(tare!== 0 && tare!=="" && gross !==0 && gross!==""){
            return  gross-tare;
        }else {
            return gross;
        }
    };
    getActualWeightQTL=()=>{
        var tare = this.state.tareWeightKG;
        var gross = this.state.grossWeightKG;
        if(tare!== 0 && tare!=="" && gross !==0 && gross!==""){

            return  parseFloat(parseInt(gross-tare)/100);
        }else if(gross!==""){
            return parseFloat(parseInt(gross)/100);
        }else  if(gross===""){
            return ''
        }
    };

    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };
    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleTableLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };
    onSelect = (selectedValue, stateKey) => {
        if(stateKey === 'selectedMukam'){
            this.setState({
                loadSupplier:true
            })
        }
        if(stateKey === 'selectedDepartment'){
            this.setState({
                loadItemGroup:true,
                loadItem:true,
            })
        }
        if(stateKey === 'selectedItemGroup'){
            this.setState({
                loadItem:true
            })
        }
        if(stateKey === 'selectedItem'){
            this.setState({
                loadQuality:true
            })
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };
    afterRefreshItem = (val) => {

        this.setState({
            loadItem: val
        });
    };
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };
    afterRefreshsupplier = (val) => {

        this.setState({
            loadSupplier: val
        });
    };


    afterRefreshItemGrp = (val) => {

        this.setState({
            loadItemGroup: val,
            loadItem:val
        });
    };
    getSupplierName = (selectedValue) => {
        this.setState({
            supplierName:selectedValue
        });
    };
    getDeptName = (selectedValue) => {
        this.setState({
            deptName:selectedValue
        });
    };

    getItemGroupName = (selectedValue) => {
        this.setState({
            itemGrpName:selectedValue
        });
    };
    getQualityDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            qualityName: values[0],
            stock: values[1],
            rate: values[2],
            lastPurchase: values[3],
            allowableMoisture: values[4],
        });
        console.log("indentQuantity " + this.state.stock);

    };


    getItemDetailsByName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            itemName:values[0],
            selectedItemGroup:values[1],
            uom:values[2],
            stock:values[3],
        });
    };
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if(status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    //basic Alert
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }
    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
                updateItemAlert:false
            });
        }
        if(this.state.deleteItem){
            var data = this.state.invoiceList;
            data.splice(this.state.id,1);
            //  if(this.state.approveStatus)
            data.map((o,key)=>{
                o.id=key;
                o.actions= (
                    <div className="actions-right">
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    if(o.id === key){
                                        console.log("o.readingsList",o.readingsList);

                                        // getDataFromUrl(serverApi.GET_JUTE_READINGS_BY_LINE_ITEM+this.state.selectedPO+"/"+o.dataId,this.handleViewReadingsResp);

                                        this.setState({
                                            readingAlert:true,
                                            id:o.id,
                                            updateItem: true,
                                            lineItemId:o.dataId,
                                            /*  deptName:o.department,
                                              itemGrpName:o.itemGroup,
                                              itemName:o.item,
                                              uom:o.unit,
                                              stock: o.stockValue,
                                              quantity:o.quantity,
                                              selectedDepartment: o.deptId,
                                              selectedItemGroup:o.itemGroupId,
                                              selectedItem:o.itemId,
                                              selectedJuteQuality:o.qualityCode,
                                              qualityName:o.quality,*/
                                            avgReadings:o.readings,
                                            readingList:o.readingsList,
                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <img src={Reading} style={{height:20,width:20}}/>
                        </IconButton>{""}
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    if(o.id === key){
                                        this.setState({
                                            // alert:true,
                                            id:o.id,
                                            lineItemId:o.dataId,
                                            updateItemAlert:true,
                                            updateItem: true,
                                            deptName:o.department,
                                            itemGrpName:o.itemGroup,
                                            itemName:o.item,
                                            uom:o.unit,
                                            stock: o.stockValue,
                                            quantity:o.quantity,
                                            selectedDepartment: o.deptId,
                                            selectedItemGroup:o.itemGroupId,
                                            selectedItem:o.itemId,
                                            selectedJuteQuality:o.qualityCode,
                                            qualityName:o.quality,
                                            avgReadings:o.readings,
                                            readingList:o.readingsList,
                                            weight:o.weight,
                                            advisedJuteTyp:o.advisedJuteTyp,
                                            advisedQuality:o.advisedQuality,
                                            advisedQuantity:o.advisedQuantity,
                                            advisedWeight:o.advisedWeight,
                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <Edit />
                        </IconButton>{""}
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    if(o.id === key) {
                                        //  if (this.state.approveStatus) {

                                        this.setState({deleteItem: true,
                                            id:o.id,});

                                        this.basicItemAlert("Do You Like To Delete This Item?");
                                        //  data.splice(i,1);
                                        // }
                                        return true;
                                    }
                                });
                                // this.setState({invoiceList: data});
                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close />
                        </IconButton>
                    </div>

                )

            });

            this.setState({invoiceList: data,
                id:0,
                deleteItem:false});
        }
        this.setState({
           // id:0,
            basicAlert: null,
            deleteItem:false,
            updateItemAlert:false
        });

    }


    hideBasicAlert(){
        if(this.state.updateItemAlert)
            this.hideAlert();

        this.setState({
            basicAlert: null,
        });

    }
    handleSelectedValues = (selectedValue) => {

        if(selectedValue.value === 1)
            this.setState({
                loading:true,
                indentAlert:true
            });
        if(selectedValue.value === 2)
            this.setState({
                selectedPO:"",
            });

        this.setState({
            SelectedChalanType: selectedValue,
        });

    };
    indenthideAlert() {

        this.setState({
            indentAlert: false,
            loading:false,
            SelectedChalanType:''
        });
    }
    hideReadingAlert() {

        var arr=[{
            id:0,reading:""
        }];
        this.setState({
            readingAlert: false,
            loading:false,
            readingList:arr,
        });
        this.hideAlert();
    }

    afterReloadSelect = (val) => {

        this.setState({
            reloadSelect: val
        });
    };
    afterSelectPO =()=>{
        var juteId="";
        console.log("jute  "+"  "+this.state.selectedPO);
        getDataFromUrl(serverApi.GET_PO_DETAILS+this.state.selectedPO+"/"+getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER),this.handlePOResp)
    };
    handlePOResp = (resp)=>{

        var header = resp.poHeader;
        var list = resp.poLineItemVo;
        var indentlist = list.map((prop,key)=>{
            return(
                {
                    id:key,
                    //dataId:prop.itemId,
                    department:prop.deptName,
                    itemGroup:prop.itemGroupName,
                    item:prop.itemDesc,
                    quantity:prop.quantity,
                    unit: prop.unitId,
                    quality:prop.juteQuality,
                    qualityCode:prop.qualityCode,
                    // stockValue: prop.stock,
                    deptId:prop.deptId,
                    itemGroupId:prop.itemGroupId,
                    itemId:parseInt(prop.itemId),
                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            this.setState({
                                                alert:true,
                                                id:o.id,
                                                updateItem: true,
                                                deptName:o.department,
                                                itemGrpName:o.itemGroup,
                                                itemName:o.item,
                                                uom:o.unit,
                                                stock: o.stockValue,
                                                quantity:o.quantity,
                                                selectedDepartment: o.deptId,
                                                selectedItemGroup:o.itemGroupId,
                                                selectedItem:o.itemId,
                                                selectedJuteQuality:o.qualityCode,
                                                qualityName:o.quality

                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>{" "}
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            data.splice(i,1);
                                            return true;
                                        }
                                    });
                                    this.setState({invoiceList: data});
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>
                        </div>

                    )
                }
            )
        });

        if(header!== null){
            this.setState({
                loading:false,
                indentAlert:false,
                reloadSelect:true,
                selectedSupplier:header.supplierId,
                selectedMukam:parseInt(header.mukam),
                selectedPO:header.id,
                juteType:header.type,
                unitConversionName:header.juteUnit,
                invoiceList:indentlist,

            });

        }

    };

    getInvoiceTablePageSize=()=>{
        var length =this.state.invoiceList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };


    handleCommit = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    handleAddJuteGate = (resp) => {
        if (resp.status) {
            if(this.state.commitClicked){
                this.handleLoading();

                var url=serverApi.COMMIT_QUALITY_CHECK_READINGS+this.state.entryNo+"/"+getCookie(cons.CIPHER);
               //getDataFromUrl(url,this.handleAddJuteGate);
                getDataFromUrl(url,this.handleCommit);
                this.setState({
                    commitClicked:false
                })
            }else
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };
    onCommitButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denined. Please contact administrator!!!")
        }else {
            var sumOfQty=0;
            this.state.invoiceList.forEach((prop)=>{
                sumOfQty=sumOfQty+parseFloat(prop.weight);

            });
               // console.log("this.state.actualWeightKG",this.state.actualWeightKG/100);

            sumOfQty=  parseFloat(sumOfQty).toFixed(2);
             console.log("sumOfQty",sumOfQty);
            var quality=parseFloat(this.state.actualWeightQTL).toFixed(2);

            if(quality === sumOfQty){
                this.setState({
                    commitClicked: true
                });

                this.onUpdateButtonClick();
            }else {
                this.basicAlert("Sum of weights should be equal to "+quality+" . Please adjust line items..!!")
            }
            // requestList(serverApi.COMMIT_STORE_MATERIAL_INSPECTION,data,this.handleAddStoreGate)
        }
        };

    onUpdateButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denined. Please contact administrator!!!")
        }else {
            var hasReadings=false;
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                if(prop.readingsList.length<1){
                    hasReadings=true
                }
                return ({
                    recId: prop.dataId,
                    qcJuteType: prop.itemId,
                    // itemDesc:prop.item,
                    // itemGroupId:prop.itemGroupId,
                    advisedQuality: prop.qualityCode,
                    qcJuteQuality: prop.qualityCode,
                    advisedQuantity: '',
                  //  actualQuantity: '',
                    receivedIn: this.state.unitConversionName,
                    // dept:prop.deptId,
                    //stock:prop.stockValue,
                    qcJuteQuantity: prop.quantity,
                    qcJuteWeight: prop.weight,
                    advisedJuteTyp: this.state.juteType,
                    actualJuteTyp: this.state.juteType,
                    uom: prop.unit,
                    // "poLineItemNum":"123",
                    createdBy: getCookie(cons.USER_NAME),
                    isPoAmment: '',
                    juteGateSrlNo: '',
                    kgs: '',
                    poLineItemNum: prop.dataId,
                    remarks: this.state.remarks,
                    readings: prop.readingsList,
                })
            });

            var data = {
                "entryHeader": {
                    id: this.state.entryNo,
                    chalanNo: this.state.chalanNo,
                    chalanDate: this.dateChanged(this.state.chalanDate),
                    vehicleNo: this.state.vehicleNum,
                    driverName: this.state.driverName,
                    suppCode: this.state.selectedSupplier,
                    supplierName: this.state.supplierName,
                    transporter: this.state.transporter,
                    mukam: this.state.selectedMukam,
                    brokerName: this.state.brokerName,
                    brokerAddress: this.state.brokerAddress,
                    netWeight: this.state.netWeight,
                    grossWeight: this.state.grossWeightQTL,
                    actualWeight: this.state.actualWeightQTL,
                    challanWeight: this.state.chalanWeightQTL,
                    //  remarks:this.state.remarks,
                    inDate: this.dateChanged(this.state.date1),
                    inTime: this.timeChangedFormat(this.state.date1,this.state.time2),
                    poNo: this.state.selectedPO,
                    mrNo: '',
                    createdBy: getCookie(cons.USER_NAME),
                    "companyId":getCookie(cons.COMPANY_ID),
                    branchId:this.state.branchId
                },
                "juteGateLineItems": invoiceList,
                "cipher":getCookie(cons.CIPHER)
            };

            if (this.state.invoiceList.length >= 1) {
               // console.log("selectedPO " + this.state.selectedPO);
                if (!hasReadings) {
                    this.handleLoading();
                    requestList(serverApi.ADD_OR_UPDATE_JUTE_QUALITY_CHECK, data, this.handleAddJuteGate)
                }else {
                    this.basicAlert("Please Enter Readings for all line Items..!!");

                }


            } else if (this.state.invoiceList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            }
        }

    };


    onClickAddItem(){
        var arr = this.state.invoiceList;
        var itemIndex = 0,lineItemId;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            lineItemId = this.state.lineItemId
        } else {
            itemIndex = this.state.invoiceList.length;
                lineItemId=""
        }
        console.log("weight",this.state.weight);

        var addItemObj={
            id: itemIndex,
            dataId:lineItemId,
            department:this.state.deptName,
            itemGroup:this.state.itemGrpName,
            item:this.state.itemName,
            quantity:this.state.quantity,
            unit: this.state.uom,
            quality:this.state.qualityName,
            qualityCode:this.state.selectedJuteQuality,
            stockValue: this.state.stock,
            deptId:this.state.selectedDepartment,
            itemGroupId:this.state.selectedItemGroup,
            itemId:this.state.selectedItem,
            readings:this.state.avgReadings,
            readingsList:this.state.readingList,
            weight:this.state.weight,
            advisedJuteTyp:this.state.advisedJuteTyp,
            advisedQuality:this.state.advisedQuality,
            advisedQuantity:this.state.advisedQuantity,
            advisedWeight:this.state.advisedWeight,
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){

                                  //  getDataFromUrl(serverApi.GET_JUTE_READINGS_BY_LINE_ITEM+this.state.selectedPO+"/"+o.dataId,this.handleViewReadingsResp);

                                    this.setState({
                                        readingAlert:true,
                                        id:o.id,
                                        updateItem: true,
                                        lineItemId:o.dataId,
                                       /* deptName:o.department,
                                        itemGrpName:o.itemGroup,
                                        itemName:o.item,
                                        uom:o.unit,
                                        stock: o.stockValue,
                                        quantity:o.quantity,
                                        selectedDepartment: o.deptId,
                                        selectedItemGroup:o.itemGroupId,
                                        selectedItem:o.itemId,
                                        selectedJuteQuality:o.qualityCode,
                                        qualityName:o.quality,*/
                                        readingList:o.readingsList,
                                        avgReadings:o.readings,
                                    });

                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <img src={Reading} style={{height:20,width:20}}/>
                    </IconButton>{""}
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id:o.id,
                                        lineItemId:o.dataId,
                                        updateItemAlert:true,
                                        updateItem: true,
                                        deptName:o.department,
                                        itemGrpName:o.itemGroup,
                                        itemName:o.item,
                                        uom:o.unit,
                                        stock: o.stockValue,
                                        quantity:o.quantity,
                                        selectedDepartment: o.deptId,
                                        selectedItemGroup:o.itemGroupId,
                                        selectedItem:o.itemId,
                                        selectedJuteQuality:o.qualityCode,
                                        qualityName:o.quality,
                                        readingList:o.readingsList,
                                        avgReadings:o.readings,
                                        weight:o.weight,
                                        advisedJuteTyp:o.advisedJuteTyp,
                                        advisedQuality:o.advisedQuality,
                                        advisedQuantity:o.advisedQuantity,
                                        advisedWeight:o.advisedWeight,
                                       // alert:true,

                                    });
                                    getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"21/"+o.dataId+"/"+getCookie(cons.COMPANY_ID),this.handleItemInvoiceFiles);

                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Edit />
                    </IconButton>{""}
                    { /* use this button to remove the data row */ }
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.setState({deleteItem: true,
                                        id:o.id,});
                                    this.basicItemAlert("Do You Like To Delete This Item?");

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                            this.setState({
                                invoiceList: data,
                                deptName:"",
                                itemGrpName:"",
                                itemName:"",
                                uom:"",
                                stock: "",
                                quantity:"",
                                selectedDepartment: "",
                                selectedItemGroup:"",
                                selectedItem:"",
                                readingList:"",
                                avgReadings:0,
                                weight:'',
                                advisedJuteTyp:'',
                                advisedQuality:'',
                                advisedQuantity:'',
                                advisedWeight:'',

                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close />
                    </IconButton>
                </div>

            )
        };


        const  {selectedItemGroup,selectedItem,quantity,selectedJuteQuality} = this.state;

        if(selectedItemGroup !== "" && selectedItem!== "" && quantity!== ""&& selectedJuteQuality !==""){
            if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
            } else
                arr.splice(arr.length, 0, addItemObj);
            // console.log(arr.toString());

            this.setState({
                loading:true,
                invoiceList:arr,
                updateItem: false
            });
            this.hideAlert();
        } else {
            this.basicAlert("Please Select All Mandatory Fields..!!");
        }

    }

    handleAddItem= () =>{
        const {vehicleNum,driverName,SelectedChalanType,selectedSupplier,selectedMukam,grossWeightKG,chalanWeightKG,branchId}=this.state;

        if(vehicleNum !=='' && SelectedChalanType!==0 && selectedMukam !== ''&& selectedMukam !== 0 &&
            SelectedChalanType !=='' && selectedSupplier !== '0' && selectedSupplier!=='' && grossWeightKG !==0 && chalanWeightKG !==0&&branchId!=="")
            this.setState({
                loading:true,
                alert:true
            });
        else if(this.state.updateView && vehicleNum !=='' && selectedMukam !== ''&& selectedMukam !== 0 && selectedSupplier !== '0' && selectedSupplier!=='' ){
            this.setState({
                loading:true,
                alert:true
            });
        }
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };
    getDefaultUnitConvertion = (name) => {
        var val = "", label = "", data = "";
        if (name !== null) {
            if (name === 'LOOSE') {
                val = 1;
                label = "LOOSE";
            } else {
                val = 2;
                label = 'BALE';
            }
            data = {
                value: val, label: label
            };
        }
        return data;
    };
    getQuantityType = () => {
        if (this.state.unitConversion !== '' && this.state.unitConversion !== null) {
            var val = this.state.unitConversion.value;
            if (val === 2) {
                return "BALE"
            } else if(val === 1){
                return "DRUMS";
            }
        } else return ''

    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleDeleteInvoiceResp = (resp) => {
        if (resp.status) {
            var arr = this.state.filesList;
            arr.splice(this.state.fileId, 1);
            this.setState({
                filesList: arr,
                deleteInvoice: false,
                fileId: ""
            });
        }

    };

    handleDownloadedFile=(url)=>{
        window.open(url);
        //  console.log("url...",url)
    };
    handleInvoiceResp = (resp) => {
        console.log("file resp",resp);
        var data = resp;
        if (resp.status) {

//            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

            if(this.state.uploadInvoiceItem){
                var arr = this.state.itemfilesList;
                data={"fileUploadId": data.id,
                    "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:2};
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadInvoice: false,
                    uploadInvoiceItem:false,
                    itemfilesList: arr,
                    basicAlert1: null
                });
            }else {
                var arr = this.state.filesList;
                data={"fileUploadId": data.id,
                    "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:2};
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadInvoice: false,
                    filesList: arr,
                    basicAlert1: null
                });
            }



        }
        console.log("resp", JSON.stringify(resp));

    };
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'PDF' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG' || fileType === 'XLSX') {
                this.setState({
                    file: file,
                    fileName: file.name,
                    fileType:fileName[1],
                    //filesList: arr,
                    uploadInvoice: true
                    // imagePreviewUrl: reader.result
                });
                this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
            }


        };
        reader.readAsDataURL(file);
    }

    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,uploadInvoiceItem,entryNo,lineItemId,isReject,isApprove,srStatus}=this.state;

        if(isReject){
            this.setState({isReject:false});
            this.onApproveButtonClick("4")
        }
        if(isApprove){
            this.setState({isApprove:false});
            this.onApproveButtonClick(this.state.srStatus)
        }

        if (uploadInvoice) {
            if(uploadInvoiceItem)
                uploadFile(fileName,file,fileType,21,fileName,lineItemId, this.handleInvoiceResp);
            else
            uploadFile(fileName,file,fileType,20,fileName,entryNo, this.handleInvoiceResp);

        }
        if (this.state.deleteInvoice) {

            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert1: null,
        });

    }

    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }
    render() {
        const {classes} = this.props;
        const {selectedPO} = this.state;
        const inputFocus={
            autoComplete: 'off'
        };

        const options = [
            { value: 0, label: 'Select...' },
            { value: 1, label: 'With PO'},
            { value: 2, label: 'Without PO'}
        ];
        const ucoptions = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'LOOSE', name: 'LOOSE'},
            {value: 2, label: 'BALE', name: 'BALE'}
        ];
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var isBackDate=getConfigValue(configNames.BACK_DATE_ALLOWABLE);
        var valid = function( current ){
            if(isBackDate==="2")
                return current.isAfter( yesterday );
            else
                return true;
        };
        var labelType=getConfigValue(configNames.LABELS_TYPE);

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {
                                        //get indent alert
                                        this.state.readingAlert?
                                            <Popup
                                                onClickSave={() => this.saveReading()}
                                                closePopup={() => this.hideReadingAlert()}
                                                title=""
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"save"}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                        <GridContainer >
                                                            <ItemGrid xs={12}>
                                                                <GridContainer>
                                                                    <ItemGrid  xs={12} sm={2}>
                                                                        <br/>
                                                                        <InputLabel className={classes.label}>
                                                                            S.No
                                                                        </InputLabel>

                                                                    </ItemGrid>
                                                                    <ItemGrid  xs={12} sm={10}>
                                                                        <br/>
                                                                        <InputLabel className={classes.label}>
                                                                            Readings
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>

                                                            <ItemGrid xs={12} style={{overflowY: "auto" ,maxHeight:250}}>
                                                                {this.state.readingList.map((r,i) => (

                                                                    <GridContainer style={{marginTop:"10px"}}>
                                                                        <ItemGrid  xs={12} sm={2}>
                                                                            {i+1}
                                                                        </ItemGrid>
                                                                        <ItemGrid  xs={12} sm={6}>
                                                                            <TextField
                                                                             //   autoFocus={true}
                                                                                id={i}
                                                                                margin="normal"
                                                                                value={this.getDefaultReadingValue(i)}
                                                                                onChange={this.onChangeReadingValues}
                                                                                fullWidth={true}
                                                                            />
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={4}>
                                                                            <IconButton
                                                                                right={true}
                                                                                onClick={() => {
                                                                                    if(i>0){
                                                                                        var arr = this.state.readingList;

                                                                                        arr.splice(i, 1);

                                                                                        this.setState({
                                                                                            readingList:arr
                                                                                        });
                                                                                    }

                                                                                }}

                                                                                color="dangerNoBackground"
                                                                            >
                                                                                <Close/>
                                                                            </IconButton>   {" "}

                                                                            {i+1 === this.state.readingList.length?
                                                                                <IconButton
                                                                                    right={true}
                                                                                onClick={() => {
                                                                                var arr = this.state.readingList;
                                                                                var data={
                                                                                    id:0,
                                                                                    reading:0
                                                                                };
                                                                                arr.splice(i+1, 0, data);
                                                                                console.log(arr.toString());

                                                                                this.setState({
                                                                                    readingList:arr
                                                                                });
                                                                            }}

                                                                                color="infoNoBackground">
                                                                                <ExpandMoreIcon />
                                                                                </IconButton>:null
                                                                            }



                                                                        </ItemGrid>

                                                                    </GridContainer>

                                                                ))}

                                                            </ItemGrid>



                                                        </GridContainer>


                                                }
                                            >
                                            </Popup>:null
                                    }

                                    {
                                        //get indent alert
                                        this.state.indentAlert?
                                            <Popup
                                                onClickSave={() => this.afterSelectPO()}
                                                closePopup={() => this.indenthideAlert()}
                                                title=""
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"OK"}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    PO List<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp id={"selectedPO"}
                                                                                                    defValue={selectedPO}
                                                                                                    onSelect={this.onSelect}
                                                                                                    getName={this.getName}
                                                                                                    stateKey="selectedPO"
                                                                                                    url={serverApi.APPROVED_POS+"J/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                                                    value={selectedPO}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>


                                                        </GridContainer>



                                                    </form>

                                                }
                                            >
                                            </Popup>:null
                                    }

                                    {
                                        this.state.alert?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={this.state.updateItem?"update":"Add"}
                                                cancel_button_text={"Cancel"}
                                                // juteType={selectedJuteType}
                                                content={
                                                    <form>
                                                        <GridContainer>

                                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            defValue={this.state.selectedItem}
                                                                            id={"selectedItem"}
                                                                            onSelect={this.onSelect}
                                                                            getName={this.getItemDetailsByName}
                                                                            // isRefresh={this.state.loadItem}
                                                                            stateKey="selectedItem"
                                                                            // setIsRefresh={this.afterRefreshItem}
                                                                            url={serverApi.DATATABLE_SERVICE_URL + this.state.selectedMukam + "/getAllItemByMukam/"+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                            value={this.state.selectedItem}
                                                                        />

                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>

                                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Quality<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            defValue={this.state.selectedJuteQuality}
                                                                            id={"selectedJuteQuality"}
                                                                            onSelect={this.onSelect}
                                                                            getName={this.getQualityDetails}
                                                                            isRefresh={this.state.loadQuality}
                                                                            stateKey="selectedJuteQuality"
                                                                            setIsRefresh={this.afterRefreshQuality}
                                                                            url={serverApi.DATATABLE_SERVICE_URL + this.state.selectedItem +"/"+getCookie(cons.COMPANY_ID)+ "/getQualityByItem"}
                                                                            value={this.state.selectedJuteQuality}
                                                                        />


                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>


                                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Quantity<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="quantity"
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            value={this.state.quantity}
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            iType={"number"}
                                                                            InputProps={{
                                                                                autoComplete: 'off',
                                                                                endAdornment: <InputAdornment position="end">
                                                                                    <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>{this.getQuantityType()}</span>
                                                                                </InputAdornment>,
                                                                            }}

                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>
                                                            <ItemGrid xs={6} sm={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Weight<span style={{
                                                                            color: "red",
                                                                            fontSize: "12px"
                                                                        }}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="weight"
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            value={this.state.weight}
                                                                            iType={"number"}
                                                                            InputProps={{
                                                                                autoComplete: 'off',
                                                                                endAdornment: <InputAdornment
                                                                                    position="end">
                                                                                    <span style={{
                                                                                        color: "#00ABDC",
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "400",
                                                                                    }}>QTL</span>
                                                                                </InputAdornment>,
                                                                            }}
                                                                            style={{height: 30}}
                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>
                                                            {this.state.updateView&&this.state.updateItem?  <ItemGrid xs={12} sm={12}>
                                                                <InputLabel className={classes.label}>
                                                                    Support Documents :
                                                                </InputLabel>
                                                            </ItemGrid>:null}

                                                            {this.state.updateView&&this.state.updateItem?<ItemGrid xs={8} style={{overflowY: "auto", maxHeight: 250}}>
                                                                {this.state.itemfilesList.map((r, i) => (
                                                                    <GridContainer style={{marginTop: "10px"}}>
                                                                        <ItemGrid xs={12} sm={2}>
                                                                            {i + 1}
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={6}>
                                                                            <a onClick={()=>{
                                                                                getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                                            }}>
                                                                                {r.fileName!==null&&r.fileName!==""?r.fileName:"indent"+(i+1)}
                                                                            </a>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={4}>
                                                                            <IconButton
                                                                                right={true}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        deleteInvoice: true,
                                                                                        invoiceId: r.fileUploadId,
                                                                                        fileId: i
                                                                                    });
                                                                                    this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                                                }}

                                                                                color="dangerNoBackground"
                                                                            >
                                                                                <Close/>
                                                                            </IconButton> {""}

                                                                        </ItemGrid>

                                                                    </GridContainer>

                                                                ))}

                                                            </ItemGrid>:null}


                                                            {this.state.updateView&&this.state.updateItem? <ItemGrid xs={4}>
                                                                <Button color={"info"}
                                                                        onClick={()=>{
                                                                            this.setState({uploadInvoiceItem:true})
                                                                            this.handleFileClick()
                                                                        }}>Upload File</Button>
                                                            </ItemGrid>:null}
                                                        </GridContainer>

                                                    </form>

                                                }
                                            >
                                            </Popup>:null
                                    }
                                    {this.state.basicAlert1}

                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        isValidDate={valid}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "date1")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"date1",
                                                            value:this.state.date1,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        defaultValue={new Date()}
                                                        dateFormat={false}
                                                        onChange={(timeObj) => {
                                                            this.timeChanged1(timeObj, "time1")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"time1",
                                                            value:this.state.time1,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.updateView? <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Entry No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="entryNo"
                                                    className={classes.textField}
                                                    value={this.state.entryNo}
                                                    onChange={this.onChangeValues}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Challan No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                      disabled={true}
                                                    id="chalanNo"
                                                    className={classes.textField}
                                                    value={this.state.chalanNo}
                                                    onChange={this.onChangeValues}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Challan Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}

                                                        timeFormat={false}
/*
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "chalanDate")
                                                        }}
*/
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"chalanDate",
                                                            value:this.state.chalanDate,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Vehicle No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                      disabled={true}
                                                    id="vehicleNum"
                                                    // className={classes.textField}
                                                    value={this.state.vehicleNum}
                                                    onChange={this.onChangeValues}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Driver Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                      disabled={true}
                                                    id="driverName"
                                                    className={classes.textField}
                                                    value={this.state.driverName}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Transporter
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                     disabled={true}
                                                    id="transporter"
                                                    className={classes.textField}
                                                    value={this.state.transporter}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>



{/*
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>

                                                <InputLabel className={classes.label}>
                                                    Challan Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>


                                                <Select
                                                    isDisabled={this.state.updateView}
                                                    defValue={this.state.SelectedChalanType}
                                                    name ={"SelectedChalanType"}
                                                    value={this.state.SelectedChalanType}
                                                    onChange={this.handleSelectedValues}
                                                    options={options}
                                                    className={classes.selectFormControl}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: '4px',
                                                        // height:"20px",
                                                        colors: {
                                                            ...theme.colors,
                                                            //  primary25: '#00ABDC',
                                                            primary: '#00ABDC',
                                                            //   primary50:"#00ABDC"
                                                        },
                                                    })}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            height: '30px',
                                                            'min-height': '30px',
                                                        }),

                                                    }}
                                                />


                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Mukam<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <SelectboxTemp id={"selectedMukam"}
                                                               selectDisabled={this.state.updateView}
                                                               defValue={this.state.selectedMukam}
                                                               isReload={this.state.reloadSelect}
                                                               setIsReload={this.afterReloadSelect}
                                                               onSelect={this.onSelect}
                                                               getName={this.getSupplierName}
                                                               stateKey="selectedMukam"
                                                               url={serverApi.SEARCH_MUKAM+getCookie(cons.COMPANY_ID)}
                                                               value={this.state.selectedMukam}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp id={"selectedSupplier"}
                                                               selectDisabled={this.state.updateView}
                                                               defValue={this.state.selectedSupplier}
                                                               isReload={this.state.reloadSelect}
                                                               setIsReload={this.afterReloadSelect}
                                                               onSelect={this.onSelect}
                                                               isRefresh={this.state.loadSupplier}
                                                               setIsRefresh={this.afterRefreshsupplier}

                                                               getName={this.getSupplierName}
                                                               stateKey="selectedSupplier"
                                                               url={serverApi.DATATABLE_SERVICE_URL + "getSuppListByMukamId/" + this.state.selectedMukam+"/company/"+getCookie(cons.COMPANY_ID)}
                                                               value={this.state.selectedSupplier}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Unit Conversion<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <Select
                                                    isDisabled={true}
                                                    defValue={this.state.unitConversion}
                                                    name={"unitConversion"}
                                                    value={this.state.unitConversion}
                                                    onChange={this.handleSelectedUnitValue}
                                                    // statekey={"unitConvertion"}
                                                    options={ucoptions}
                                                    className={classes.selectFormControl}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: '4px',
                                                        // height:"20px",
                                                        colors: {
                                                            ...theme.colors,
                                                            //  primary25: '#00ABDC',
                                                            primary: '#00ABDC',
                                                            //   primary50:"#00ABDC"
                                                        },
                                                    })}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            height: '30px',
                                                            'min-height': '30px',
                                                        }),

                                                    }}
                                                />


                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Project":"Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={this.state.updateView}
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    //  getName={this.getQualityName}
                                                    stateKey="branchId"
                                                    // isRefresh={this.state.loadQuality}
                                                    // setIsRefresh={this.afterRefreshQuality}
                                                    url={serverApi.SELECT_BRANCH + getCookie(cons.COMPANY_ID)}
                                                    value={this.state.branchId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Remarks
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="remarks"
                                                    // label="Multiline"
                                                    multiline={true}
                                                    rowsMax="3"
                                                    value={this.state.remarks}
                                                    onChange={this.onChangeValues}
                                                   // className={classes.textField}
                                                    height={60}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {/* {console.log(this.state.invoiceList.length+" length")}*/}
                                                <LineItemTable
                                                    data={this.state.invoiceList}
                                                    loading={this.state.loading}
                                                    disableActions={this.state.qcCheck==="Y"}
                                                    columns={[
                                                        {
                                                            Header: "Item",
                                                            accessor: "item",
                                                            minWidth:180,
                                                            style: {color: "#000", textAlign: "left",textOverflow: 'unset'}
                                                        },
                                                        {
                                                            Header: "Quality",
                                                            accessor: "quality",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: this.getQuantityType(),
                                                            accessor: "quantity",
                                                            minWidth:80,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Weight",
                                                            accessor: "weight",
                                                            minWidth:80,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Unit",
                                                            accessor: "unit",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Average Reading",
                                                            accessor: "readings",
                                                            minWidth: 80,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            minWidth: 120,
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}

                                                />


                                            </ItemGrid>

                                            <ItemGrid xs={12}>
                                                {this.state.qcCheck!=="Y"?<Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>:null}
                                            </ItemGrid>


                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView?  <ItemGrid xs={12} sm={12}>
                                        <InputLabel className={classes.label}>
                                            Support Documents :
                                        </InputLabel>
                                    </ItemGrid>:null}

                                    {this.state.updateView?<ItemGrid xs={8} style={{overflowY: "auto", maxHeight: 250}}>
                                        {this.state.filesList.map((r, i) => (
                                            <GridContainer style={{marginTop: "10px"}}>
                                                <ItemGrid xs={12} sm={2}>
                                                    {i + 1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a onClick={()=>{
                                                        getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                    }}>
                                                        {r.fileName!==null&&r.fileName!==""?r.fileName:"indent"+(i+1)}
                                                    </a>
                                                </ItemGrid>
                                                <ItemGrid xs={4}>
                                                    <IconButton
                                                        right={true}
                                                        onClick={() => {
                                                            this.setState({
                                                                deleteInvoice: true,
                                                                invoiceId: r.fileUploadId,
                                                                fileId: i
                                                            });
                                                            this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                        }}

                                                        color="dangerNoBackground"
                                                    >
                                                        <Close/>
                                                    </IconButton> {""}

                                                </ItemGrid>

                                            </GridContainer>

                                        ))}

                                    </ItemGrid>:null}


                                    {this.state.updateView&&this.state.mrStatus!=='3'&&this.state.mrStatus!=='4'? <ItemGrid xs={4}>
                                        <Button color={"info"}
                                                onClick={this.handleFileClick}>Upload File</Button>
                                    </ItemGrid>:null}

                                    {this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" style={{width:80}} onClick={this.onUpdateButtonClick}>update</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="success" onClick={this.onCommitButtonClick}>Commit</Button>
                                                </ItemGrid>

                                                <ItemGrid>
                                                    <Button color="danger" style={{width:80}} onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
{/**/}

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                            <GridContainer>

                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"fromDate",
                                                                        value:this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}

                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"toDate",
                                                                        value:this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Supplier
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8} >
                                                            <SelectboxTemp
                                                                defValue={this.state.supplierId}
                                                                id={"supplierId"}
                                                                onSelect={this.onSelect}
                                                                stateKey="supplierId"
                                                                url={serverApi.SEARCH_SUPPLIER+"/"+getCookie(cons.COMPANY_ID)}
                                                                value={this.state.supplierId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>



                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Gate Entry No
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="gateEntryNO"
                                                                value={this.state.gateEntryNO}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                style={{height: 30}}
                                                                InputProps={inputFocus}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Vehicle No
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.vehicleNo}
                                                                id={"vehicleNo"}
                                                                                onSelect={this.onSelect}
                                                                                stateKey="vehicleNo"
                                                                                url={serverApi.SELCET_VEHICLE_LIST+getCookie(cons.COMPANY_ID)+"/"+getCookie(cons.CIPHER)}
                                                                                value={this.state.vehicleNo}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                {/*<ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        // round color="white"
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>*/}
                                                <ItemGrid xs={12}><br/></ItemGrid>
                                                <ItemGrid xs={12}>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "15px"}}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                url={serverApi.GET_QUALITY_CHECK_DETAILS_TABLE}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isLoading}
                                                handleLoading={this.handleTableLoading.bind(this)}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                fileName={'jute_quality_check_currentdate'}

                                                actionType={VIEW}/>


                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    textField:{
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    cssFocused: {},
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#00ADBC',
        },
        '&:after': {
            borderColor: '#D2D2D2',
        },

    },

    notchedOutline: {},
    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    ...customSelectStyle,
    ...extendedTablesStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(JuteQualityCheck);
